import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";

import InputForm from "../input/input.component";
import SelectForm from "../select/select.component";
import ButtonPrimary from "../button/buttonPrimary.component";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { useFormComplete } from "../../hooks/useFormComplete";
import { getCompanyAction } from "../../actions/basicDataActions";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  text: {
    padding: "6px 10px 20px 10px",
    textAlign: "justify",
  },
  textBlue: {
    color: "#16B1F3",
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "0.8035714285714286rem",
    padding: "6px 10px",
  },
}));

export const CertificateRenewalForm = ({
  data,
  handleRequestCertificate,
  loadingRequestCertificate,
}) => {
  const dispatch = useDispatch();
  /**
   * Consulta la información actual de la empresa
   */
  useEffect(() => {
    dispatch(getCompanyAction(true));
  }, [dispatch]);

  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const [showNameError, setShowNameError] = useState(false);

  const { requestCompany } = useSelector((s) => s.basicDataReducer);
  const { getDocumentTypesCodeResponse } = useSelector((s) => s.configReducer);

  const { values, handleInputChange, setValue } = useFormComplete({
    documentType: data.tipoDocumento ?? "",
    document: data.numeroDocumento ?? "",
    name: data.nombre ?? "",
    lastname: data.apellido ?? "",
    email: data.correoElectronico ?? "",
    phoneNumber: data.telefono ?? "",
  });

  let { documentType, document, name, lastname, email, phoneNumber } = values;

  /**
   * Si el tipo de persona es natural se asigna el documento de la empresa como el documento del representante legal
   */
  if (requestCompany?.diantipopersonaid === 2 && !!!document) {
    setValue(requestCompany.documento, "document");
  }

  useEffect(() => {
    if (
      document === data.numeroDocumento &&
      documentType === data.tipoDocumento &&
      !!data.correoElectronico
    )
      setValue(data.correoElectronico, "email");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, documentType]);

  /**
   * Valida formulario y confirma petición
   * @param {*} e
   * @returns
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
      return;
    }

    if (
      (name + " " + lastname)
        .toLowerCase()
        .trim()
        .replace(/\s+/g, " ") !==
        requestCompany?.razonsocial
          ?.toLowerCase()
          ?.trim()
          ?.replace(/\s+/g, " ") &&
      !!requestCompany.razonsocial &&
      requestCompany?.diantipopersonaid === 2
    ) {
      setShowNameError(true);
      return;
    }

    handleRequestCertificate(
      values,
      requestCompany.diantipopersonaid,
      requestCompany.razonsocial
    );
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container justify="center">
        <Grid item lg={12} xs={12} className={classes.text}>
          Para realizar la renovación de tu certificado debes seguir estos
          pasos, lee atentamente.
          <br></br>
          <br></br>
          <span className={classes.textBlue}>1.</span> Debes confirmar la
          información del representante legal, verifica que los datos sean
          correctos.
          <br></br>
          <br></br>
          <span className={classes.textBlue}>2.</span> En caso de que la
          información del representante legal haya cambiado, deberás
          actualizarla; ten en cuenta que esta debe coincidir con la consignada
          en el RUT de la empresa, de lo contrario rechazarán la solicitud.
          <br></br>
          <br></br>
          <span className={classes.textBlue}>3.</span> Al correo electrónico del
          representante legal, llegará un mensaje de solicitud de certificado;
          por medio este mensaje debe ingresar a la plataforma, diligenciar el
          formulario y adjuntar la documentación solicitada. Es indispensable
          que el representante legal realice este proceso, de lo contrario no se
          renovará el certificado y no será posible emitir documentos.
          <br></br>
          <br></br>
          <span className={classes.textBlue}>4.</span> Si requiere realizar la
          actualización de la Razón Social o del correo electrónico y el sistema
          no lo permite, usted debe comunicarse con la Mesa de Servicios al
          número 4050082 - OPCIÓN 2 en Bogotá y solicitar el cambio.
        </Grid>
        <Grid item lg={6} xs={6}>
          <SelectForm
            label={"Tipo de documento"}
            name="documentType"
            onChange={(e) => handleInputChange(e)}
            options={getDocumentTypesCodeResponse}
            value={documentType}
            validator={validator}
            validateOptions={"required"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            disabled={requestCompany?.diantipopersonaid === 2 ? true : false}
            label={"Número de documento"}
            maxLength={15}
            name="document"
            onChange={(e) =>
              handleInputChange(
                e,
                documentType === "41" ? "alphanumeric2" : "number"
              )
            }
            value={document}
            validator={validator}
            validateOptions={"required|min:1|max:15"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Nombre(s)"}
            maxLength={100}
            name="name"
            onChange={(e) => handleInputChange(e, "lettersSpace")}
            value={name}
            validator={validator}
            validateOptions={"required|min:3|max:100"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Apellido(s)"}
            maxLength={100}
            name="lastname"
            onChange={(e) => handleInputChange(e, "lettersSpace")}
            value={lastname}
            validator={validator}
            validateOptions={"required|min:3|max:100"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            disabled={
              document === data.numeroDocumento &&
              documentType === data.tipoDocumento &&
              !!data.correoElectronico
            }
            label={"Correo electrónico"}
            maxLength={100}
            name="email"
            onChange={(e) => handleInputChange(e, "email")}
            value={email}
            validator={validator}
            validateOptions={"required|email|min:1|max:100"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Teléfono"}
            maxLength={15}
            name="phoneNumber"
            onChange={(e) => handleInputChange(e, "phone")}
            value={phoneNumber}
            validator={validator}
            validateOptions={"required|min:7|max:15"}
          />
        </Grid>
        {showNameError && (
          <Grid item lg={12} xs={12} className={classes.errorMessage}>
            Recuerda que para una empresa con tipo de persona natural, el nombre
            completo del representante legal debe ser el mismo de la
            razonsocial.
          </Grid>
        )}
        <Grid item lg={6} md={6} xs={12}>
          <ButtonPrimary
            text={"Confirmar"}
            type={"submit"}
            loading={loadingRequestCertificate}
          />
        </Grid>
      </Grid>
    </form>
  );
};
