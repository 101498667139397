import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import { ValueInfoCard } from "./";

import CustomCardAditionalData from "../../../../invoice/aditional-data-card.component";
import SimpleModal from "../../../../../components/modal/simpleModal.component";
import ModalFileForm from "../../../../invoice/modal-file-form.component";
import { initialModalData, mailErrorDataModal } from "../../footerActions";
import ResponseModal from "../../../../../components/modal/responseModal.component";

export const AdditionalDocInfoComponent = () => {
  const [openModalMail, setOpenModalMail] = useState(false);
  const [mailData, setMailData] = useState({});
  const [modalData, setModalData] = useState(initialModalData);
  const { sendmail } = useSelector((state) => state.invoiceReducer);

  useEffect(() => {
    cleanMail();
  }, [sendmail]);

  const {
    acuseDIANDate,
    cude,
    dateDocEquivalent,
    observationText,
    paymentDate,
    paymentMethodDescription,
    paymentMethodDetailDescription,
  } = useSelector(
    (state) => state.equivalentDocReducer.additionalDocInfo,
    (prev, next) => prev === next
  );

  const { equivalentDocDetail } = useSelector((state) => state.equivalentDocReducer);

  const { correoElectronico, documentompresa, razonsocialempresa, tipodocumentoidemisor, diantipomodalidadid } = equivalentDocDetail;

  /**
   * * Datos adicionales para mostrar en una sección de información adicional.
   * @property {string} title - El título del dato.
   * @property {string} value - El valor del dato.
   * @property {boolean} [show] - Indica si el dato debe mostrarse (opcional).
   * @property {number} [md] - El tamaño del grid en dispositivos de tamaño medio (md) (opcional).
   * @property {number} [lg] - El tamaño del grid en dispositivos de tamaño grande (lg) (opcional).
   */
  const additionalInfoData = [
    {
      title: "CUDE",
      value: cude,
      show: !!cude,
    },
    {
      title: "Fecha de documento",
      value: dateDocEquivalent,
      md: 4,
      lg: 4,
    },
    {
      title: "Forma de pago",
      value: paymentMethodDescription,
      md: 4,
      lg: 4,
    },
    {
      title: "Medio de pago",
      value: paymentMethodDetailDescription,
      md: 4,
      lg: 4,
    },
    {
      title: "Fecha de pago",
      value: paymentDate,
      md: 6,
      lg: 6,
    },
    {
      title: "Fecha validación DIAN",
      value: acuseDIANDate,
      md: 6,
      lg: 6,
    },
    {
      title: "Observaciones",
      value: observationText,
    },
  ];

  const handleOpenModalSend = (
    email,
    isInvoiceMail,
    tipodocumentoentidad,
    documentompresa,
    codigounico,
    numero,
    razonsocialempresa,
    razonsocialcliente,
    tipoModalidad,
    documentoid,
    data
  ) => {
    if (codigounico) {
      setOpenModalMail(true);
      setMailData({
        TipoDocumento: tipodocumentoentidad,
        Documento: documentompresa,
        CodigoUnico: codigounico,
        NumeroDocumento: numero,
        RazonSocialEmpresa: razonsocialempresa,
        RazonSocialCliente: razonsocialcliente,
        DocumentoId: documentoid,
        TipoModalidad: tipoModalidad,
      });
    } else {
      setModalData(mailErrorDataModal);
    }
  };

  const modalInfoComponents = [
    {
      onClose: () => setOpenModalMail(false),
      open: openModalMail,
      title: "Envio de Nota",
      width: 365,
      showCloseButton: false,
      component: () => <ModalFileForm email={correoElectronico} data={mailData} isDocEquivalentMail type={""} />,
    },
  ];

  /**
   * * Cierra Modal, Notificación certificado no valido
   *  @function
   * @returns {void}
   */
  const handleResponseModal = () => {
    setModalData(initialModalData);
  };

  /**
   * * Limpia los datos del correo electrónico y cierra el modal.
   */
  const cleanMail = () => {
    setMailData({});
    setOpenModalMail(false);
  };

  return (
    <div>
      <Grid
        container
        style={{
          background: "#5E5E5E",
          padding: "1.5%",
          borderRadius: "5px",
        }}
      >
        {additionalInfoData.map((infoData, index) => infoData.show !== false && <ValueInfoCard key={index} {...infoData} />)}
      </Grid>
      <div className="spacingInvoice"></div>
      <div className="titleAdicitional">Notas</div>
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        {equivalentDocDetail.opdocumentonotas !== undefined
          ? equivalentDocDetail.opdocumentonotas !== null
            ? equivalentDocDetail.opdocumentonotas.map((data, index) => (
                <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                  <CustomCardAditionalData
                    data={data}
                    type="notes"
                    diantipomodalidadid={diantipomodalidadid}
                    tipodocumentoentidad={tipodocumentoidemisor}
                    documentompresa={documentompresa}
                    razonsocialempresa={razonsocialempresa}
                    openMailModal={handleOpenModalSend}
                    handleShowDetailNote={() => {}}
                    loadingGetNoteId={0}
                    hideActionsSend={false}
                    hideActionsView={true}
                    // openFileModal={OpenFileModal}
                    // handleOpenModalWarning={handleOpenModalWarning}
                    // onClick={notificationNote}
                  />
                </Grid>
              ))
            : ""
          : ""}
        <div className="spacingInvoice"></div>
      </Grid>
      <ResponseModal {...modalData} onCloseElement={handleResponseModal} />
      {modalInfoComponents.map((modalInfo, index) => (
        <SimpleModal key={index} {...modalInfo} />
      ))}
    </div>
  );
};
