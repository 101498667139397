import { types } from "../types/types";


const initialState = {
  loadingSave: false,
  getReasonDiscount:[]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_DISCOUNT:
      return {
        ...state,
        currentDiscount: action.payload,
      };

    case types.GET_DISCOUNTLIST:
      return {
        ...state,
        getDiscountsEnterprise: action.payload,
      };

    case types.SAVE_DISCOUNT:
      return {
        ...state,
        createDiscount: action.payload,
      };

    case types.UPDATE_DISCOUNT:
      return {
        ...state,
        updateDiscount: action.payload,
      };

    case types.DELETE_DISCOUNT:
      return {
        ...state,
        deleteDiscount: action.payload,
      };

    case types.GET_REASON_DISCOUNT:
      return {
        ...state,
        reasonDiscount: action.payload,
        getReasonDiscount: action.payload.result.map(item => {
          return {
            ...item,
            value: item.id,
            text: item.descripcion
          }
        })
      };

    case types.GET_FILTER_DISCOUNTS:
      return {
        ...state,
        getFilterDiscounts: action.payload,
      };

    case types.GET_FILTER_DISCOUNT:
      return {
        ...state,
        currentDiscount: action.payload,
        editingDiscountData: !!action.payload ? true : false,
      };

    case types.CHANGE_EDITING_DISCOUNT_STATUS:
      return {
        ...state,
        editingDiscountData: action.payload,
      };
    case types.LOADING_SAVE_DISCOUNT:
      return {
        ...state,
        loadingSave: action.payload,
      }
    case types.LOADING_GET_DISCOUNTS:
      return {
        ...state,
        loadingGetDiscountList: action.payload
      }
    default:
      return state;
  }
}
