import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { format } from "date-fns";
import { FormatDecimal } from "../../../components/common/formatDecimal";

const styles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.thirdBackgroundColor,
		color: theme.palette.primaryColor,
		"& .MuiCardContent-root": {
			paddingTop: 0,
			paddingBottom: 5,
			marginBottom: 0,
			marginTop: 0,
			paddingLeft: 55,
		},
	},
	header: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	content: {
		fontSize: 14,
	},
	title: {
		color: theme.palette.primaryColor,
	},
	icon: {
		color: theme.palette.secundaryColor,
	},
	iconAction: {
		color: theme.palette.primaryColor,
	},
	valueText: {
		color: "#F5D13D",
		fontWeight: 500,
	},
	help: {
		marginTop: 10,
	},
	actions: {
		display: "flex",
	},
	wordBreak: {
		wordBreak: "break-all",
		fontSize: 12,
	},
}));

export const ReferenceDocumentCard = React.memo(
	({
		id,
		tipodocumentodescripcion,
		numero,
		tipodocumentoid,
		valorapagar,
		fecha,
		handleLoad,
		handleDelete,
	}) => {
		const classes = styles();
		const [disabledDelete, setDisabledDelete] = useState(false);
		const { opdocumentosreferencia } = useSelector(
			(s) => s.documentReferenceReducer
		);

		useEffect(() => {
			const contingency = opdocumentosreferencia.filter(
				(c) => c.tipodocumentoid.toString() === "9"
			);

			if (contingency.length === 1 && tipodocumentoid?.toString() === "9") {
				setDisabledDelete(true);
			} else {
				setDisabledDelete(false);
			}
		}, [opdocumentosreferencia, tipodocumentoid]);

		return (
			<Card className={classes.root}>
				<CardHeader
					className={classes.header}
					avatar={
						<img
							src={require("../../../images/docref.png")}
							alt={"logo"}
							className={classes.icon}
						/>
					}
					action={
						<div className={classes.actions}>
							<Tooltip title="Editar">
								<IconButton aria-label="Editar" onClick={() => handleLoad(id)}>
									<CreateIcon className={classes.iconAction} />
								</IconButton>
							</Tooltip>
							<Tooltip title="Eliminar">
								<IconButton
									aria-label="Eliminar"
									onClick={() => handleDelete(id, numero)}
									disabled={disabledDelete} //Contingencia
								>
									<DeleteIcon
										className={classes.iconAction}
										style={{
											opacity: disabledDelete ? "20%" : "initial",
										}}
									/>
								</IconButton>
							</Tooltip>
						</div>
					}
					classes={{
						title: classes.title,
					}}
					title={tipodocumentodescripcion}
				/>
				<CardContent className={classes.content}>
					<Typography component="div" style={{ textTransform: "none" }}>
						<div className={classes.wordBreak}> {numero}</div>
					</Typography>
					<Typography component="div">
						<div className={classes.wordBreak}>
							{" "}
							{format(new Date(fecha), "dd/MM/yyyy")}
						</div>
					</Typography>
					{tipodocumentoid?.toString() === "1" ? (
						<Typography component="div">
							<div className={classes.wordBreak}>
								<FormatDecimal value={valorapagar} prefix={"$"} />
							</div>
						</Typography>
					) : null}
				</CardContent>
			</Card>
		);
	}
);

ReferenceDocumentCard.propTypes = {
	id: PropTypes.number.isRequired,
	tipodocumentodescripcion: PropTypes.string.isRequired,
	numero: PropTypes.string.isRequired,
	tipodocumentoid: PropTypes.string.isRequired,
	fecha: PropTypes.string.isRequired,
	handleLoad: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
};
