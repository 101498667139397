import { types } from "../types/types"

export const setIndexSelectedAction = (index) => dispatch => {
  dispatch({
    type: types.SET_INDEX_SELECTED,
    payload: index
  })

}


/**
 * @method
 * @description Limpia el correo de la empresa DIAN
 */
export const cleanEmailDianAction = () => dispatch => {


  dispatch({
    type: types.GET_COMPANY_EMAIL_DIAN,
    payload: null
  })

}
