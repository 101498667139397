import React from "react";

import { RenderItemSection } from "../itemCartSections";

/**
 * * Comprueba si una sección está visible.
 * @param {string} sectionName - El nombre de la sección a comprobar.
 * @param {string[]} showSections - Un array de nombres de secciones visibles.
 * @returns {boolean} - Devuelve true si la sección está visible, de lo contrario, devuelve false.
 */
const isSectionVisible = (sectionName, showSections) =>
  showSections.includes(sectionName);

/**
 * * Renderiza las secciones del documento.
 * @param {Object} sectionsObj - Objeto que contiene las secciones del documento.
 * @param {Array} showSections - Array que indica qué secciones deben mostrarse.
 * @returns {Array} - Array de elementos de sección renderizados.
 */
export const renderSections = (sectionsObj, showSections) =>
  Object.entries(sectionsObj).map(([sectionName, section]) =>
    isSectionVisible(sectionName, showSections) ? (
      <RenderItemSection key={sectionName} {...section} />
    ) : null
  );
