import { types } from "../types/types"

const initialState = {
    editContactStatus: false
}


export default function (state = initialState, action) {

    switch (action.type) {
        case types.SAVE_CONTACT:
            return {
                ...state,
                saveContactResponse: action.payload
            }
        case types.GET_CONTACTSLIST:
            return {
                ...state,
                getContactResponse: action.payload
            }
        case types.DELETE_CONTACT:
            return {
                ...state,
                deleteContactResponse: action.payload
            }
        case types.LOAD_CONTACT:
            return {
                ...state,
                loadContact: action.payload,
                editContactStatus: !!action.payload ? true : false
            }
        case types.UPDATE_CONTACT:
            return {
                ...state,
                updateContactResponse: action.payload,
            }
        default:
            return state
    }
} 