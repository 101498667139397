

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.thirdBackgroundColor,
        borderRadius: '5px',
        color: theme.palette.primaryColor
    },
    title: {
        display: "inline-flex",
        verticalAlign: "top",
        fontSize: 16,
        fontfamily: 'Muli',
        fontWeight: 'bold'
    },
    titleOpen: {
        fontSize: 16,
        fontfamily: 'Muli',
        fontWeight: 'bold'
    },
    body: {
        width: '100%'
    },
    margin: {
        borderRadius: 5
    },
    icon: {
        marginLeft: 10
    }
}));


const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },

    },
    expanded: {

    }
})(Accordion);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#2CC63E',
        color: '#000000',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: '#272727'
    },
}))(AccordionDetails);


export const CollapseDataPanel = ({
    component,
    backgroundColor,
    open = true
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(open);

    useEffect(() => {
        setExpanded(open);
    }, [open]);


    const handleExpand = () => {
        setExpanded(prev => !prev)
    }


    return (
        <ExpansionPanel className={classes.root} expanded={expanded} >
            <ExpansionPanelSummary
                aria-controls="paneld-content"
                id="paneld-header"
                className={classes.margin}
                onClick={handleExpand}
                expandIcon={<ExpandMoreIcon />}>
                <Typography
                    disabletypography="true"
                    component={'div'}
                    className={classes.title}>
                    {'Información Adicional'}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ backgroundColor: !!backgroundColor ? backgroundColor : "#272727" }}>
                <Typography component={'div'} className={classes.body}>
                    {component}
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

CollapseDataPanel.propTypes = {
    component: PropTypes.object.isRequired,
};
