import React from "react";

import { ArticleComponent } from "./ArticleComponent";

export const DocumentArticles = ({
  equivalentDocumentItems,
  totalEquivalentDocumentsItems,
}) => {
  return (
    <>
      <div className="greenTitleSupport">
        Artículos ({totalEquivalentDocumentsItems})
      </div>
      <div className="spacingInvoice" />

      {equivalentDocumentItems.map((item) => (
        <ArticleComponent key={item.id} {...item} />
      ))}
    </>
  );
};
