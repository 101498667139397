import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ClearIcon from "@material-ui/icons/Clear";
import Link from "@material-ui/core/Link";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";

import InputIcon from "../../../../components/input/inputIcon.component";
import CustomProgress from "../../../../components/Progress/progress.component";
import DATA_NOT_FOUND_IMAGE from "../../../../images/NotificacionesColfactura.png";
import InvoiceCard from "../components/InvoiceCard.component.js";
import StyledCheckbox from "../../../../components/checkForm/styledCheckbox.component";
import PopoverModal from "../../../../components/modal/popoverModal.component";
import ButtonPrimary from "../../../../components/button/buttonPrimary.component";
import filterImage from "../../../../../src/images/filter.png";
import ResponseModal from "../../../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../../../components/modal/responseRedirectModal.component";

import {
  MESSAGE_NEXT_CONFIRM,
  MESSAGE_DELETE_CONFIRM,
  CLAIM_DOCUMENTS,
} from "../../../../config/config";

import {
  getUploadedInvoiceAction,
  updateStateAction,
  changeStepAction,
  cleanStatusUploadAction,
  cleanStateGetBulkLoadAction,
  UpdateDataBulkAction,
  updateOpenModalItem,
  cleanLicenseBaseAction,
} from "../../../../actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  paddingIcons: {
    paddingTop: "5px",
  },
  letter: {
    color: "#FFFFFF",
  },

  letterInformation: {
    color: "#FFFFFF",
    fontFamily: theme.palette.fontFamily,
    fontWeight: 400,
    fontSize: 18,
    textAlign: "justify",
  },

  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: "center",
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
  dialogConfirm: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    "& .MuiPaper-root": {
      backgroundColor: "#393939",
      color: theme.palette.primaryColor,
    },
  },
  btnBlue: {
    width: "25%",
    float: "right",
  },
  divRegister: {
    background: theme.palette.seventhColor /* Can be in percentage also. */,
    height: "auto",
    margin: "0 auto",
    padding: "10px",
    position: "relative",
    textAlign: "center",
    color: "#272727",
    fontFamily: theme.palette.fontFamily,
    fontWeight: "bold",
    borderRadius: "5px",
  },
  styleText: {
    fontFamily: theme.palette.fontFamily,
    color: "#FFFFFF",
    fontSize: 14,
  },
  styleIcon: {
    color: "#FFFFFF",
    cursor: "pointer",
  },
  datanotfound: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    textAlign: "center",
  },
  dialog: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    "& .MuiPaper-root": {
      backgroundColor: "#272727",
      color: theme.palette.primaryColor,
      height: 480,
      width: 1000,
      borderRadius: "5px",
    },
  },
  titleInformation: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

const UploadedRecords = (props) => {
  const classes = useStyles();

  const {
    handleCreate,
    resultUpdate,
    confirmAlert,
    setActiveStep,
    informationBulk,
    activeStep,
    modal,
    licenseBase,
  } = props;

  const [sinErroresCount, setSinErroresCount] = useState(0);
  const [conErroresCount, setConErroresCount] = useState(0);
  const [itemDelete, setItemDelete] = useState(0);
  const [sinErrores, setSinErrores] = useState(false);
  const [conErrores, setConErrores] = useState(false);
  const [deleteRegister, setDelete] = useState(false);
  const [flagDelete, setFlagDelete] = useState(false);
  const [confirmSend, sendConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [changeimage, setchangeimage] = useState(false);
  const [loadingListData, setLoadingListData] = useState(true);
  const [openModalFinishProcess, setOpenModalFinishProcess] = useState(false);
  const [uploadedRecords, setUploadedRecords] = useState([]);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [uploadedRecordsFilter, setUploadedRecordsFilter] = useState([]);
  const [txtFilter, setTxtFilter] = useState("");
  const [cargueId, setCargueId] = useState(null);
  const [loadigbutton, setloadingbutton] = useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = useState(
    initialModal
  );
  const [modalResponse, setModalResponse] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  /**
   * UseEffect que se encarga de enviar la infromacion al componente para mostrar los registros de facturas que se enviaron.
   */
  useEffect(() => {
    if (!!informationBulk) {
      resultDataInvoice(informationBulk);
      window.scroll(0, 0);
    }
  }, [informationBulk]);

  /**
   * Realizar consulta de registros cuando Ingresa a este paso por perimera vez.
   */
  useEffect(
    () => {
      if (!!resultUpdate) {
        if (
          resultUpdate.statusCode === "200" &&
          !!resultUpdate.result &&
          !!deleteRegister
        ) {
          setSinErroresCount(0);
          setConErroresCount(0);
          props.cleanStateGetBulkLoadAction();
          props.cleanStatusUploadAction();
          deleteItem(uploadedRecordsFilter, itemDelete);
          setDelete(false);

          return;
        }
        if (
          resultUpdate.statusCode === "200" &&
          !resultUpdate.result &&
          resultUpdate.responseCode === "R12"
        ) {
          showValidationErrors(resultUpdate.statusMessage);
          setOpenModal(false);
          setloadingbutton(false);

          return;
        }

        if (
          resultUpdate.statusCode === "200" &&
          !!resultUpdate.result &&
          !!loadigbutton
        ) {
          props.changeStepAction(2);
          setOpenModal(false);
          setloadingbutton(false);

          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultUpdate]
  );

  useEffect(
    () => {
      if (!!confirmSend) {
        const msg = "¿Está seguro que desea enviar las facturas? ";
        const send = async (confirmed) => {
          if (confirmed) {
            sendConfirm(false);
            setOpenModal(true);
          }
        };
        confirmAlert(msg, send, MESSAGE_NEXT_CONFIRM);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmSend]
  );

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
  }, [licenseBase]);

  /**
   * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    props.cleanLicenseBaseAction();
  };

  /**
   * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    props.updateOpenModalItem(false);
  };

  /**
   * Obtener la información de consulta de registro de facturas a emitir,
   * el objeto factura llega en string y se debe convertir a Json para poder enviar la información a cada una de las cards de visualización
   */
  const resultDataInvoice = (uploadedRecordsTmp) => {
    if (uploadedRecordsTmp && uploadedRecordsTmp.factura) {
      const json = uploadedRecordsTmp.factura.map((item) => ({
        id: item.id,
        registro: JSON.parse(item.objeto),
      }));

      setCargueId(uploadedRecordsTmp.id);
      setUploadedRecords(json);
      setUploadedRecordsFilter(json);

      if (json && json.length > 0) {
        json.forEach((item) => {
          if (item.registro.datosCargueMasivo.erroresFactura.length === 0) {
            setSinErroresCount((item) => item + 1);
          } else {
            setConErroresCount((item) => item + 1);
          }
        });
      }
      setLoadingListData(false);
    }
  };

  /**
   * Función  de confirmacion de eliminación de registro seleccionado.
   * @param {id de registro de factura a cambiar de estado} item
   */
  const onDeleteData = (item) => {
    const msg = "¿Está seguro que desea eliminar este registro";
    const send = async (confirmed) => {
      if (confirmed) {
        props.updateStateAction(5, cargueId, item.id);
        setItemDelete(item);
        setDelete(true);
        setFlagDelete(true);
      }
    };

    confirmAlert(msg, send, MESSAGE_DELETE_CONFIRM);
  };

  /**
   * Consulta de facturas que se generaron con errores
   * @returns
   */
  const hasErrorsInvoices = () => {
    var result = uploadedRecords.find((item) => {
      return item.registro.datosCargueMasivo.erroresFactura.length > 0;
    });

    let hasErrors = !!result ? true : false;
    return hasErrors;
  };

  const sendInvoices = () => {
    const msg = hasErrorsInvoices()
      ? "¿Estás seguro que deseas enviar las facturas? Recuerda que solo se emitirán " +
        'las que hayan sido cargadas correctamente, las demás quedarán en la sección "Registros cargados" para que puedas corregirlos y subirlas en un nuevo proceso'
      : "¿Estás seguro que deseas enviar las facturas?";

    const send = async (confirmed) => {
      if (confirmed) {
        setOpenModal(true);
      }
    };
    confirmAlert(msg, send, MESSAGE_NEXT_CONFIRM);
  };

  /**
   * Funcion de filtro de busqueda de infromacion de cada una de las facturas que se cargaron para el paso de registros cargados, se realiza el filtro por cada campo del Json
   * @param {*} event
   */
  const onChangeFilter = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setTxtFilter(value);
    setUploadedRecordsFilter(
      uploadedRecords.filter((item) => {
        const factura = item.registro.datosCargueMasivo;
        return (
          factura.estado.toLowerCase().includes(value.toLowerCase()) ||
          factura.cliente.razonSocial
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          factura.cliente.tipo.toLowerCase().includes(value.toLowerCase()) ||
          factura.cliente.numero.toLowerCase().includes(value.toLowerCase()) ||
          factura.cliente.digito.includes(value.toLowerCase()) ||
          factura.medioPago.toLowerCase().includes(value.toLowerCase()) ||
          factura.formaPago.toLowerCase().includes(value.toLowerCase()) ||
          factura.estado.toLowerCase().includes(value.toLowerCase()) ||
          factura.valorTotal.toString().includes(value) ||
          factura.cantidadArticulos.toString().includes(value)
        );
      })
    );
  };

  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  const cleanFilter = () => {
    setConErrores(false);
    setSinErrores(false);
    setUploadedRecordsFilter(uploadedRecords);
  };

  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  const cancelOption = () => {
    const cancel = (confirmed) => {
      if (confirmed) {
        props.cleanStateGetBulkLoadAction();
        handleCreate();
      }
    };
    confirmAlert(
      "¿Estás seguro que deseas cancelar? Si lo haces se perderá la información cargada",
      cancel,
      MESSAGE_NEXT_CONFIRM
    );
  };

  /**
   * Consulta de facturas de acyerdo a la opcion seleccionada , se puede realizar filtro por facturas con errores o facturas sin errores.
   * @param {*} e
   */
  const consultaFactura = (e) => {
    var array = [];
    if (e.target.name === "chkSinErrores") {
      setSinErrores(e.target.checked);
      if (e.target.checked === true) {
        array = uploadedRecordsFilter.filter(
          (registro) =>
            registro.registro.datosCargueMasivo.erroresFactura.length === 0
        );

        setUploadedRecordsFilter(array);
      } else {
        setUploadedRecordsFilter(uploadedRecords);
      }
    }
    if (e.target.name === "chkConErrores") {
      setConErrores(e.target.checked);
      if (e.target.checked === true) {
        array = uploadedRecordsFilter.filter(
          (registro) =>
            registro.registro.datosCargueMasivo.erroresFactura.length >= 1
        );
        setUploadedRecordsFilter(array);
      } else {
        setUploadedRecordsFilter(uploadedRecords);
      }
    }

    if (
      (sinErrores === true && conErrores === true) ||
      (sinErrores === true && conErrores === false) ||
      (sinErrores === false && conErrores === true)
    ) {
      setUploadedRecordsFilter(uploadedRecords);
    }
  };

  /**
   * Funcióon que filtra el arreglo de las facturas cargadas y , se va afiltrar ara mostrar las facturas que no se eliminan.
   * @param {*} data
   * @param {*} item
   */
  const deleteItem = (data, item) => {
    var array = data.filter((invoice) => invoice.id !== item.id);

    setUploadedRecordsFilter(array);
    var newObject = informationBulk.factura.filter(
      (itemF) => itemF.id !== item.id
    );

    var objeto = {
      id: informationBulk.id,
      cantidad: newObject.filter((invoice) => invoice.valido === true).length,
      procesada: 0,
      factura: newObject,
      estadoCargue: 1,
    };
    if (newObject.length === 0) {
      setchangeimage(true);
    }
    props.UpdateDataBulkAction(objeto);
    setFlagDelete(false);
  };

  const backStep = () => {
    props.cleanStateGetBulkLoadAction();
    props.changeStepAction(0);
    //handleBulkUpload()
  };
  /**
   * Función para cambiar el estado del paso, esta opcion cambia al ultimo paso de la seccion de cargue masivo de  facturas y
   * cambia el estado del modal que se encuentra en el paso inicial.
   */
  const nextStep = () => {
    props.updateStateAction(
      1,
      cargueId,
      null,
      null,
      setloadingbutton,
      setOpenModal
    );
    setloadingbutton(true);
  };

  /**
   * Modal de error
   * @param {Mensaje a visualizr} body
   */
  const showValidationErrors = (body) => {
    setModalResponse({
      type: "warning",
      title: "Upss...!!!",
      subtitle: "Ocurrió algo inesperado",
      body: <div>{body}</div>,
      modalImage: "warning",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 1,
    });
  };
  /**
   * Cerrar modal de  errores
   */
  const handleResponseModal = () => {
    setModalResponse({
      open: false,
    });
  };

  /**
   * Función donde se realiza el componente visual de las opciones de checks.
   * @returns
   */
  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={sinErrores}
              onClick={(e) => consultaFactura(e)}
              name="chkSinErrores"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cargadas Correctamente
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={conErrores}
              onClick={(e) => consultaFactura(e)}
              name="chkConErrores"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cargadas con errores
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilter}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };
  /**
   * Función donde se realiza el componente visual del ultimo modal que se muestra al usuario.
   * @returns
   */
  const dialogInformationBulk = () => {
    return (
      <Dialog
        open={openModal}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">
          <center>
            <span className={classes.titleInformation}>
              Envío de facturas en proceso.
            </span>
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <br />
            <br />
            <br />
            <br />
            <div
              className={classes.letterInformation}
              style={{ height: "100%", width: "100%" }}
            >
              ¡¡Perfecto!!, empezaremos a emitir estas facturas, este proceso se
              ejecutará en segundo plano, puedes seguir realizando tus
              actividades y cuando finalicemos te lo haremos saber.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item lg={4} xs={4} md={4} xl={4} />
            <Grid item lg={4} xs={4} md={4} xl={4}>
              <div
                className={classes.titleInformation}
                style={{ paddingTop: 50, marginTop: 50, textAlign: "center" }}
              >
                <ButtonPrimary
                  loading={loadigbutton}
                  text={"Entendido"}
                  onClick={() => nextStep()}
                />
              </div>
            </Grid>
            <Grid item lg={4} xs={4} md={4} xl={4} />
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Fragment>
      <div
        className={classes.divRegister}
      >{`Se cargaron ${sinErroresCount} registro(s) exitosamente y ${conErroresCount} errado(s)`}</div>
      <br />
      {flagDelete ? (
        <Grid container>
          <Grid item lg={4} xs={4}></Grid>
          <Grid item lg={4} xs={4}>
            <CustomProgress />
          </Grid>
          <Grid item lg={4} xs={4}></Grid>
        </Grid>
      ) : (
        <div>
          <Grid container>
            <Grid container spacing={1}>
              <Grid
                item
                lg={11}
                xs={11}
                md={11}
                xl={11}
                className={classes.txtFilter}
              >
                <InputIcon
                  id={"txtFilter"}
                  label={""}
                  value={txtFilter}
                  onChange={(e) => {
                    onChangeFilter(e);
                  }}
                />
                <br />
              </Grid>

              <div
                className="buttonInvoiceBehavior"
                style={{ paddingTop: 15 }}
                onClick={handleOpenModalChecks}
              >
                <img alt={"Filtrar"} src={filterImage}></img>
              </div>

              {loadingListData === true ? (
                <Grid container>
                  <Grid item lg={4} xs={4}></Grid>
                  <Grid item lg={4} xs={4}>
                    <CustomProgress />
                  </Grid>
                  <Grid item lg={4} xs={4}></Grid>
                </Grid>
              ) : !!uploadedRecordsFilter &&
                uploadedRecordsFilter.length > 0 &&
                !flagDelete ? (
                uploadedRecordsFilter.map((item, index) => (
                  <Grid item lg={12} xs={12} md={12} xl={12}>
                    <InvoiceCard
                      key={index}
                      dataObject={item.registro}
                      onDelete={(e) => {
                        onDeleteData(item);
                      }}
                      step={activeStep}
                      setActiveStep={setActiveStep}
                    ></InvoiceCard>
                  </Grid>
                ))
              ) : changeimage ? (
                <Grid container className={classes.datanotfound}>
                  <Grid item lg={12} xs={12}>
                    <img
                      src={DATA_NOT_FOUND_IMAGE}
                      alt="Datos no encontrados"
                    />
                    <div className={classes.styleText}>
                      No tiene facturas para emitir
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container className={classes.datanotfound}>
                  <div className={classes.styleText}>
                    No se han encontrado resultados...
                  </div>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item lg={8} md={8}></Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <ButtonPrimary
                    text={"Enviar facturas cargadas"}
                    onClick={sendInvoices}
                    disabled={
                      uploadedRecordsFilter.length === 0 ||
                      conErroresCount === uploadedRecordsFilter.length
                        ? true
                        : false
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="bottomButtonsInvoiceRevision">
            <Grid container>
              <Grid item lg={1} sm={1}>
                <FormControlLabel
                  control={
                    <IconButton onClick={() => backStep()}>
                      <ArrowBackIosIcon
                        className={classes.styleIcon}
                        style={{ paddingRight: -40 }}
                      />
                    </IconButton>
                  }
                  label={<div className={classes.styleText}>Volver</div>}
                />
              </Grid>
              <Grid item lg={10} sm={10}>
                <ClearIcon
                  className={classes.styleIcon}
                  onClick={() => cancelOption()}
                ></ClearIcon>
                <br></br>
                <div className={classes.styleText}>Cancelar</div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={265}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
      <Dialog
        open={openModalFinishProcess}
        onClose={() => setOpenModalFinishProcess(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <Box p={3}>
          <DialogTitle id="form-dialog-title">
            <center>
              <span className={classes.title}>
                Envío de facturas en proceso
              </span>
            </center>
          </DialogTitle>
          <DialogContent className={classes.backgroundContent}>
            <DialogContentText>
              <span className={classes.subTitle}>
                Perfecto!!, empezaremos a emitir estas facturas, este proceso se
                ejecutará en segundo plano, puedes seguir realizando tus
                actividades y cuando finalicemos te lo haremos saber.
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="backgroundContent">
            <Grid container>
              <Grid item lg={12} xs={12}>
                <button
                  className="buttonInvoiceConfirm"
                  onClick={() => setOpenModalFinishProcess(false)}
                >
                  Entendido
                </button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>

      {/**{openModal && dialogInformationBulk()} */}

      {dialogInformationBulk()}

      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleResponseModal}
      ></ResponseModal>
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={
          "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
        }
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  informationBulk: state.invoiceReducer.informationBulkLoad,
  resultUpdate: state.invoiceReducer.resultUpdate,
  modal: state.invoiceReducer.openModalItem,
  licenseBase: state.invoiceReducer.licenseBase,
});

const mapDispatchToProps = {
  getUploadedInvoiceAction,
  updateStateAction,
  changeStepAction,
  cleanStatusUploadAction,
  cleanStateGetBulkLoadAction,
  UpdateDataBulkAction,
  cleanLicenseBaseAction,
  updateOpenModalItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadedRecords);
