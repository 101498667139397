import { combineReducers } from "redux";

import generalReducer from "./generalReducer";
import userReducer from "./userReducer";
import officeReducer from "./officeReducer";
import branchOfficeReducer from "./branchOfficeReducer";
import numerationReducer from "./numerationReducer";
import templateReducer from "./templateReducer";
import basicDataReducer from "./basicDataReducer";
import articleReducer from "./articleReducer";
import categoryReducer from "./categoryReducer";
import configReducer from "./configReducer";
import invoiceReducer from "./invoiceReducer";
import addressReducer from "./addressReducer";
import contactReducer from "./contactReducer";
import clientReducer from "./clientReducer";
import chargeReducer from "./chargeReducer";
import discountReducer from "./discountReducer";
import valuesInvoiceReducer from "./valuesInvoiceReducer";
import dashboardReducer from "./dashboardReducer";
import trainingReducer from "./trainingReducer";
import invoiceReceivedReducer from "./invoiceReceivedReducer";
import menuReducer from "./menuReducer";
import { errorReducer } from "./errorReducer";
import { documentReferenceReducer } from "./documentReferenceReducer";
import { customFieldReducer } from "./customFieldReducer";
import { documentReducer } from "./documentReducer";
import { customerReducer } from "./customerReducer";
import { documentCartReducer } from "./documentCartReducer";
import { newChargeReducer } from "./newChargeReducer";
import { newDiscountReducer } from "./newDiscountReducer";
import { addressCustomerReducer } from "./addressCustomerReducer";
import { contactCustomerReducer } from "./contactCustomerReducer";
import { documentIntegrationReducer } from "./documentIntegrationReducer";
import { patientReducer } from "./patientReducer";
import { documentReceivedReducer } from "./documentReceivedReducer";
import { documentSupportReducer } from "./documentSupportReducer";
import { equivalentDocReducer } from "./equivalentDocReducer";

export default combineReducers({
  generalReducer,
  userReducer,
  branchOfficeReducer,
  numerationReducer,
  templateReducer,
  basicDataReducer,
  officeReducer,
  configReducer,
  articleReducer,
  categoryReducer,
  invoiceReducer,
  addressReducer,
  contactReducer,
  clientReducer,
  chargeReducer,
  discountReducer,
  valuesInvoiceReducer,
  dashboardReducer,
  trainingReducer,
  invoiceReceivedReducer,
  menuReducer,
  errorReducer,
  documentReferenceReducer,
  customFieldReducer,
  documentReducer,
  customerReducer,
  documentCartReducer,
  newChargeReducer,
  newDiscountReducer,
  addressCustomerReducer,
  contactCustomerReducer,
  documentIntegrationReducer,
  patientReducer,
  documentReceivedReducer,
  documentSupportReducer,
  equivalentDocReducer,
});
