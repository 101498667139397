import React from "react";
import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

export const ExcelDocumentSupport = ({ element, filename, data }) => {
  return (
    <>
      <ExcelFile element={element} filename={filename}>
        <ExcelSheet data={data} name="Employees">
          <ExcelColumn label="Tipo Identificación" value="tipoIdentificacion" />
          <ExcelColumn
            label="Nombre tipo documento"
            value="nombreTipoIdentificacion"
          />
          <ExcelColumn label="Documento" value="documento" />
          <ExcelColumn label="Razón Social" value="razonSocial" />
          <ExcelColumn label="Teléfono" value="telefono" />
          <ExcelColumn label="Correo electrónico" value="correoElectronico" />
          <ExcelColumn label="Departamento" value="departamento" />
          <ExcelColumn label="Municipio" value="municipio" />
          <ExcelColumn label="Código postal" value="codigoPostal" />
          <ExcelColumn label="Dirección" value="direccion" />
          <ExcelColumn label="Tipo documento" value="tipoDocumento" />
          <ExcelColumn label="Numero" value="numero" />
          <ExcelColumn label="Fecha Documento" value="fecha" />
          <ExcelColumn label="Forma de pago" value="formaPago" />
          <ExcelColumn label="Fecha Vencimiento" value="fechaVencimiento" />
          <ExcelColumn label="Medio de pago" value="medioPago" />
          <ExcelColumn label="Valor bruto" value="valorBruto" />
          <ExcelColumn label="Valor iva" value="valorIva" />
          <ExcelColumn label="Valor total" value="valorTotal" />
          <ExcelColumn label="Valor ReteIVA" value="valorReteIva" />
          <ExcelColumn label="Valor ReteRenta" value="valorReteRenta" />
          <ExcelColumn label="Valor a pagar" value="valorAPagar" />
          <ExcelColumn label="Cuds" value="cuds" />
          <ExcelColumn label="DatosQR" value="datosQR" />
          <ExcelColumn label="Estado Dian" value="estadoDian" />
          <ExcelColumn label="Fecha acuse Dian" value="fechaAcuseDian" />
          <ExcelColumn
            label="Documento referencia"
            value="documentoReferencia"
          />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
