import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Grid, IconButton, makeStyles } from '@material-ui/core';

import {
  selectEquivalentDocIdAction,
  sendMailReportDocEquivalentAction,
  UpdateEquivalentDocAction,
} from '../../../../actions/equivalentDocAction';

import CustomProgress from '../../../../components/Progress/progress.component';
import { FormatDecimal } from '../../../../components/common/formatDecimal';

import { getStyleStatusItem } from '../../../documentSupport/getStyleStatusItem';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import { formatDate } from '../../../../utils/general';
import { getDescriptionRole } from '../../../../helpers/roleUser';
import { getStatusDIAN } from '../../utils';
import { handleDocumentState } from '../../../../helpers/updateStateDocument';

const useStyles = makeStyles((theme) => ({
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  iconButton: {
    color: 'white',
    padding: 0,
  },
  tooltip: {
    backgroundColor: 'transparent',
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'blue',
    },
  },
  cardDetailSupport: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: '4px',
    cursor: 'pointer',
    height: '130px',
    marginBottom: '12px',
    padding: '10px 9px',
    width: '98%',
    transition: 'box-shadow 0.3s ease',

    '&:hover': {
      borderColor: '#16B1F3',
      borderStyle: 'ridge',
      boxShadow: '0 0 15px 0 rgba(22, 177, 243, 0.7)',
    },

    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  cardDetailSupportSelected: {
    backgroundColor: '#16B1F3',
    borderRadius: '4px',
    boxShadow: '0 0 7px 0 #16B1F3',
    cursor: 'pointer',
    height: '130px',
    marginBottom: '12px',
    padding: '1%',
    width: '98%',
    transition: 'box-shadow 0.3s ease',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    '&:hover': {
      boxShadow: '0 0 15px 0 rgba(22, 177, 243, 0.7)',
    },
  },
  containerTagType: {
    alignItems: 'center',
    border: '1px #9DE1FE solid',
    borderRadius: 8,
    display: 'inline-flex',
    gap: 8,
    height: '100%',
    justifyContent: 'flex-start',
    padding: '2px 8px',
  },
  tagType: {
    color: '#9DE1FE',
    fontSize: 12,
    fontWeight: '200',
    wordWrap: 'break-all',
  },
  marginBottom: {
    marginBottom: 8,
  },
  marginTop: {
    marginTop: 4,
  },
}));

export const EquivalentItemCard = ({
  equivalentDoc,
  idStateLoading,
  loadingReportEquivalentDoc,
  selected,
}) => {
  const {
    anulacion,
    estado,
    favorito,
    fecha,
    id,
    notacredito,
    notadebito,
    numero,
    razonsocial,
    tipoDocumento,
    valorTotal,
  } = equivalentDoc;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { menuInvoice } = useSelector((state) => state.menuReducer);
  const { reasonList } = useSelector((state) => state.configReducer);
  const { dataEmailReport } = useSelector((state) => state.invoiceReducer);

  /**
   * * Maneja la acción de mostrar detalles de un documento equivalente.
   * @param {Event} e - Evento que desencadenó la acción.
   */
  const handleDetailEquivalentDoc = (e) => {
    e.stopPropagation();
    dispatch(selectEquivalentDocIdAction(id));
  };

  /**
   * *Cambia el estado de favorito de un documento equivalente.
   * @param {Event} e - Evento que desencadenó la acción.
   */
  const changeStateFavorite = (e) => {
    e.stopPropagation();
    handleDocumentState({
      actionFunction: UpdateEquivalentDocAction,
      currentState: favorito,
      dispatchFunction: dispatch,
      documentId: id,
      isFavorite: favorito,
      operation: 'FAVORITO',
    });
  };

  /**
   * * Función que maneja el reporte de un problema en un documento equivalente.
   *
   * @param {Event} e - El evento del click que desencadena la función.
   * @param {Object} item - El objeto que representa el documento equivalente.
   */
  const reportProblemComponent = (e, item) => {
    e.preventDefault();

    let data = {
      bodyMail: 'Documento equivalente rechazado ante DIAN',
      documentId: item.id,
      issue: 'Documento equivalente rechazado ante DIAN',
      mailSupport: reasonList?.find((c) => c.codigo?.trim() === '12')?.correo,
      mailUser: dataEmailReport.emailUser,
      nameCompany: dataEmailReport.businessName,
      nitCompany: dataEmailReport.nitCompany,
      numero: item.numero,
      roleUser: getDescriptionRole(dataEmailReport.roleUser),
      userName: dataEmailReport.userName,
    };

    dispatch(sendMailReportDocEquivalentAction(data));
  };

  return (
    <div
      onClick={handleDetailEquivalentDoc}
      onKeyDown={handleDetailEquivalentDoc}
      className={`${
        selected ? classes.cardDetailSupportSelected : classes.cardDetailSupport
      } animate__animated animate__fadeIn animate__faster 
      ${classes.containerEllipsis}`}
      title={razonsocial}
    >
      <Grid container className={classes.marginBottom}>
        <Grid
          item
          xs={9}
          className='nameCostumer'
          style={{
            textOverflow: 'ellipsis',
          }}
        >
          {razonsocial}
        </Grid>
        <Grid item xs={3} className={classes.alignRight}>
          {getStatusDIAN(
            classes,
            equivalentDoc,
            loadingReportEquivalentDoc,
            menuInvoice,
            reportProblemComponent
          )}
          {idStateLoading === id ? (
            <CustomProgress size={20} aling={'end'} />
          ) : (
            <IconButton
              className={classes.iconButton}
              onClick={changeStateFavorite}
            >
              {favorito ? <StarIcon /> : <StarBorderIcon />}
            </IconButton>
          )}
        </Grid>
      </Grid>
      {/* fin primera seccion */}

      <Grid container className={classes.marginBottom}>
        <Grid item xs={6} className='dataSupportGrid'>
          <div className={classes.containerTagType}>
            <div className={classes.tagType}>{tipoDocumento}</div>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          className={getStyleStatusItem(equivalentDoc, selected)}
        >
          <FormatDecimal value={valorTotal} prefix={'$'} size='small' />
        </Grid>
      </Grid>
      {/* fin segunda seccion */}

      <Grid container>
        <Grid item xs={8} className='dataNumberSupport'>
          {fecha ? formatDate(new Date(fecha), 'dd/MM/yyyy hh:mm aaaa') : '-'}
          <Grid
            item
            xs={12}
            className={`dataNumberSupport ${classes.marginBottom} ${classes.marginTop}`}
          >
            {`Nro. Consecutivo: ${numero} ${
              notacredito || notadebito ? '- Editada' : ''
            }`}
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          className={getStyleStatusItem(equivalentDoc, selected)}
        >
          {anulacion ? 'Anulada' : estado}
        </Grid>
      </Grid>
      {/* fin tercera seccion */}
    </div>
  );
};
