import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CustomTabs from "../../components/tabs/tabs.component";
import DocumentSupportGrid from "./DocumentSupportGrid.view";
import CreateDocumentSupportView from "./CreateDocumentSupport/CreateDocumentSupportView";
import UpdateDocSupportView from "./UpdateDocSupport/UpdateDocSupportView";

import "../../styles/documentSupport.css";
import DocumentRevisionDocSupport from "./UpdateDocSupport/DocumentRevisionDocSupport";
import {
  cleanDocumentSupportAction,
  getNoteDetailDSAction,
} from "../../actions/documentSupportAction";

import { cleanDataConfigAction } from "../../actions/configAction";

import { cleanDocumentCreateAction } from "../../actions/documentAction";

const DocumentSupport = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showGrid: true,
    showCreate: false,
    showEdit: false,
    showDetailNote: false,
    dataNote: null,
    docFilterRes: null,
  });

  useEffect(() => {
    return () => {
      dispatch(cleanDocumentSupportAction());
      dispatch(cleanDocumentCreateAction());
      dispatch(cleanDataConfigAction());
    };
  }, [dispatch]);

  const handleCreate = (showGrid, showCreate) => {
    setState({
      ...state,
      showGrid,
      showCreate,
    });
  };
  const handleEdit = (showGrid, showEdit) => {
    setState({
      ...state,
      showGrid,
      showEdit,
    });
  };

  const handleShowDetailNote = (id) => {
    dispatch(getNoteDetailDSAction(id, readResponseGetNote));
  };

  const readResponseGetNote = (data, docFilter, showDetailNote, showGrid) => {
    setState({
      ...state,
      showDetailNote,
      showGrid,
      dataNote: data,
      docFilterRes: docFilter,
    });
  };

  const getTabs = () => {
    return [
      {
        title: "Emitidas",
        component: (
          <DocumentSupportGrid
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleShowDetailNote={handleShowDetailNote}
          />
        ),
      },
      {
        title: "Editadas",
        component: (
          <DocumentSupportGrid
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleShowDetailNote={handleShowDetailNote}
            edit={true}
          />
        ),
      },
    ];
  };
  return (
    <>
      {state.showGrid && (
        <div className="container-form">
          <CustomTabs tabs={getTabs()} />
        </div>
      )}
      {state.showCreate && (
        <CreateDocumentSupportView handleCreate={handleCreate} />
      )}
      {state.showEdit && <UpdateDocSupportView handleEdit={handleEdit} />}
      {state.showDetailNote && (
        <DocumentRevisionDocSupport
          dataNote={state.dataNote}
          docFilterRes={state.docFilterRes}
          readResponseGetNote={readResponseGetNote}
        />
      )}
    </>
  );
};

export default DocumentSupport;
