import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  tooltipModeReading: {
    display: "inline-flex",
    padding: "8px 13px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "2px",
    background: "#2F2E2E",
  },
  tooltipModeReadingText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Muli",
    fontSize: "13px",
    fontWeight: 400,
  },
});

export const TooltipMessageReadingMode = () => {
  const classes = useStyles();
  return (
    <section className={classes.tooltipModeReading}>
      <p className={classes.tooltipModeReadingText}>
        No puedes realizar esta acción, DIAN se encuentra haciendo
        mantenimientos en el sistema de Facturación Electrónica.
      </p>
    </section>
  );
};
