import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import Check from "@material-ui/icons/Check";

import clsx from "clsx";
import PropTypes from "prop-types";
import CustomProgress from "../../../components/Progress/progress.component";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: "white",
  },
}));

export const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

export function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {" "}
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}{" "}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

export function getSteps() {
  return ["Información de Nota", "Artículos", "Revisar Nota"];
}

export const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

export const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

export const initialModalResponse = {
  type: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
  onCloseElement: 0,
  closeFromModalBody: false,
};

export const modalResponseObject = (response, documentoref) => {
  if (response.data.statusCode === "201") {
    return {
      type: "success",
      title: "Buen trabajo!!!",
      subtitle: "Has generado una nueva nota",
      body: (
        <div>
          <div></div>
          <div class="fenomenalWhiteLittle">
            Actualizaste el documento No.{" "}
            <span className="fenomenalBlueLittle">{documentoref}</span> con la
            Nota No.{" "}
            <span className="fenomenalBlueLittle">{response.data.result}</span>{" "}
            <span>de manera exitosa. Sigamos facturando.</span> <br></br>{" "}
            {response.data.statusMessage}
          </div>
        </div>
      ),
      modalImage: "quotation",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 1,
      closeFromModalBody: true,
    };
  } else {
    return {
      type: "warning",
      title: "Upss...!!!",
      subtitle: "Ocurrió algo inesperado",
      body: (
        <div>
          <div></div>
          <div class="fenomenalWhiteLittle">
            Actualizaste el documento No.{" "}
            <span className="fenomenalBlueLittle">{documentoref}</span> con la
            Nota No.{" "}
            <span className="fenomenalBlueLittle">{response.data.result}</span>{" "}
            {response.data.statusMessage}
          </div>
        </div>
      ),
      modalImage: "warning",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 1,
      closeFromModalBody: true,
    };
  }
};

export const modalLoading = {
  type: "success",
  title: "",
  subtitle: "Generando documento, por favor espere...",
  body: (
    <div>
      <div>
        <>
          {" "}
          <CustomProgress />
          {"Procesando"}{" "}
        </>
      </div>
    </div>
  ),
  modalImage: "noimagen",
  open: true,
  closeElement: "",
  onCloseElement: 0,
  closeFromModalBody: false,
};
