import React from "react";

import { makeStyles } from "@material-ui/core";

import { FormatDecimal } from "../../../components/common/formatDecimal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "inline-flex",
    alignItems: "baseline",
  },

  text: {
    color: theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: 500,
  },

  valueStyle: {
    color: theme.palette.secundaryColor,
    fontSize: 15,
    fontWeight: 600,
  },

  inlineBlockPr5: {
    paddingRight: 5,
  },
}));

export const TaxElement = ({
  label,
  value,
  prefix,
  suffix,
  size = "small",
}) => {
  const { container, text, inlineBlockPr5, valueStyle } = useStyles();
  return (
    <div className={container}>
      <span className={`${text} ${inlineBlockPr5}`}>{label}:</span>
      <span className={valueStyle}>
        <FormatDecimal
          prefix={prefix}
          value={value}
          suffix={suffix}
          size={size}
        />
      </span>
      &nbsp;&nbsp;&nbsp;
    </div>
  );
};
