import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import AvatarEditor from "react-avatar-editor";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import IconButton from "@material-ui/core/IconButton";
import StopIcon from "@material-ui/icons/Stop";

import { imageCutterSize } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    borderRadius: "15px !important",
  },
  label: {
    flexDirection: "column",
    fontSize: 10,
    color: theme.palette.primaryColor,
  },
  icon: {
    color: theme.palette.primaryColor,
    fontSize: 35,
  },
  containerIcon: {
    backgroundColor: "rgba(255,255,255,0.11)",
    padding: 2,
    borderRadius: 5,
  },
  containerRectangle: {
    backgroundColor: "rgba(255,255,255,0.11)",
    padding: 10,
    borderRadius: 5,
  },
  aspectTitle: {
    fontSize: 16,
    color: theme.palette.primaryColor,
    fontStyle: "italic",
  },
  rectangle: {
    backgroundColor: "white",
  },
  activeButton: {
    boxShadow: "0px 0px 6px 1px #16b1f38c",
  },
  iconActive: {
    color: theme.palette.thirdColor,
  },
}));

const LogoCropper = (props) => {
  const classes = useStyles();
  const { image, handleClose, onClickSave, setEditorRef } = props;
  const [activeAspect, setActiveAspect] = React.useState("4:3");
  const [pixelSize, setPixelSize] = useState({
    width: 200,
    height: 200,
  });
  const [state, setState] = useState({
    allowZoomOut: true,
    scale: 1,
    width: imageCutterSize.width,
    height: imageCutterSize.height,
  });

  // useEffect(() => {
  //     getSize(image);
  // }, [image]);

  /**
   * Obtine ancho y alto de imagen cargada y calcula el aspecto que ejor se ajusta 4:3 o 16:9
   * @param {*} imageData Imagen en base64
   */
  // const getSize = (imageData) => {
  //     if (imageData !== null && imageData !== undefined) {
  //         var i = new Image();
  //         i.onload = () => {
  //             var aspect = getAspectRatio(i.width, i.height);
  //             setPixelSize({
  //                 width: aspect === '4:3' ? 200 : 250,
  //                 height: aspect === '4:3' ? 200 : 80
  //             })
  //         };
  //         i.src = imageData;
  //     }
  // }

  /**
   * Calcula el aspecto que mejos se ajusta a la imagen cargada
   * @param {*} width Ancho de imagen
   * @param {*} height Alto de imagen
   */
  // const getAspectRatio = (width, height) => {
  //     var ratio = width / height;
  //     return (Math.abs(ratio - 4 / 3) < Math.abs(ratio - 16 / 9)) ? '4:3' : '16:9';
  // }

  const handleScale = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      scale: newValue,
    }));
  };

  const handleAspectRadio = (e, aspect) => {
    setActiveAspect(aspect);
    setPixelSize({
      width: aspect === "4:3" ? 200 : 250,
      height: aspect === "4:3" ? 200 : 80,
    });
  };

  return (
    <Grid container>
      <Grid container spacing={2}>
        <AvatarEditor
          ref={setEditorRef}
          scale={parseFloat(state.scale)}
          image={image}
          style={{
            width: pixelSize.width + 100,
            height: pixelSize.height + 100,
            margin: "auto",
          }}
          color={[0, 0, 0, 0.6]}
          border={30}
          width={pixelSize.width}
          height={pixelSize.height}
        />
        <br />
        <Grid item md={12} xs={12}>
          <div style={{ margin: "auto", width: 300 }}>
            <Typography id="discrete-slider" gutterBottom>
              Zoom:
            </Typography>

            <Slider
              style={{ color: "#16B1F3" }}
              defaultValue={1}
              onChange={handleScale}
              aria-labelledby="discrete-slider"
              step={0.01}
              min={state.allowZoomOut ? 0.1 : 1}
              max={2}
            />
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Grid container alignItems={"center"} justify={"center"}>
            <Grid item>
              <span className={classes.aspectTitle}>
                Elige la forma de tu logo:
              </span>
            </Grid>
            <Grid item>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                onClick={(e) => handleAspectRadio(e, "16:9")}
              >
                <div
                  className={`${classes.containerRectangle} ${
                    activeAspect === "16:9" ? classes.activeButton : ""
                  } `}
                >
                  {activeAspect === "16:9" ? (
                    <img
                      src={require("./../../images/icons/rectangleBlue.png")}
                      alt={"rectangle"}
                    />
                  ) : (
                    <img
                      src={require("./../../images/icons/rectangle.png")}
                      alt={"rectangle"}
                    />
                  )}
                </div>
                <span style={{ marginTop: 5 }}>{"Rectangular"}</span>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                onClick={(e) => handleAspectRadio(e, "4:3")}
              >
                <div
                  className={`${classes.containerIcon} ${
                    activeAspect === "4:3" ? classes.activeButton : ""
                  } `}
                >
                  <StopIcon
                    className={`${classes.icon} ${
                      activeAspect === "4:3" ? classes.iconActive : ""
                    }`}
                  />
                </div>
                <span style={{ marginTop: 5 }}>{"Cuadrado"}</span>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} style={{ paddingRight: 30 }}>
        <Grid item md={6} xs={12}>
          <button
            type="button"
            className="btn-modal-image"
            onClick={handleClose}
          >
            Cerrar
          </button>
        </Grid>
        <Grid item md={6} xs={12}>
          <button
            type="button"
            className="btn-modal-image"
            onClick={onClickSave}
          >
            Terminar recorte
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogoCropper;
