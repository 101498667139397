import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  getBillClient,
  getrakingCollaborators,
  getBilling,
  getIncome,
  getCustomer,
  getrakingBranchOffice,
  getrakingArticle,
  issuedInvoices,
} from "../../actions/dashboardAction";

import {
  getUploadedInvoiceAction,
  showBulkLoadAction,
} from "../../actions/invoiceActions";

import InfoCard from "../../components/card/infoCard.component";
import RakingCollaborators from "../../components/carrousel/basicCarrousel";
import Billing from "../dashboard/Components/billingChart.component";
import IncomeChart from "../dashboard/Components/incomeChart.component";
import CustomerChart from "../dashboard/Components/bestCustomerChart.component";
import RakingItem from "../dashboard/Components/rakingArticle.component";
import ResponseModal from "../../components/modal/responseModal.component";
import { differenceInCalendarDays, format } from "date-fns";
import { es } from "date-fns/locale";

const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
    height: "50px",
    fontFamily: "Muli",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  divDate: {
    color: theme.palette.thirdColor,
    //  marginTop:'10px',
    margin: "5px",
  },
  divText: {
    width: "68%",
    height: "50px",
    // textAlign:'center' ,
    marginTop: "20px",
    margin: "5px",
  },
});

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

const soonExpired = (
  differenceInCalendarDayDoc,
  fechaExpiracionLicenciaDoc
) => {
  let titleModal = "";
  if (differenceInCalendarDayDoc === 0) {
    // a la hora que vence en formato am pm
    titleModal = `Tu plan de Colfactura  se vence  hoy a las ${format(
      fechaExpiracionLicenciaDoc,
      "hh:mm a",
      { locale: es }
    )}`;
  } else {
    titleModal = `Tu plan de Colfactura  se vence en ${differenceInCalendarDayDoc} día(s)`;
  }

  return {
    modalType: "warning",
    title: titleModal,
    body: (
      <div>
        <p
          style={{
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {`El plan que adquiriste  vence el ${format(
            fechaExpiracionLicenciaDoc,
            "dd/MMMM/yyyy",
            { locale: es }
          )}`}
        </p>
        <p
          style={{
            marginTop: 10,
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {
            "Es necesario renovarlo para que puedas seguir emitiendo documentos. Si quieres comprar tu nuevo plan, haz clic en “Comprar”."
          }
        </p>
      </div>
    ),
    modalImage: "warning",
    open: true,
    textButton: "Comprar",
    closeElement: "Skip",
    closeFromModalBody: true,
  };
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getBillClient: [],
      getRakingColaborator: [],
      getBilling: [],
      getIncome: [],
      getCustomer: [],
      getRakingBranch: [],
      getRakingItem: [],
      issuedInvoices: [],
      totalSold: 0,
      statusCodeColaborator: 0,
      statusCodeBilling: 0,
      statusCodeIncome: 0,
      statusCodeCustomer: 0,
      statusCodeBranch: 0,
      statusCodeItem: 0,
      quantityInvoice: 0,
      getInforme: [],
      showBulk: true,
      menuDashboard: {},
      countInvoice: 0,
      codeExecuted: false,
      modalLicenseResponse: {
        modalType: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
      },
    };
  }

  componentDidMount() {
    this.props.getBillClient();
    this.props.getrakingCollaborators();
    this.props.getBilling();
    this.props.getIncome();
    this.props.getCustomer();
    this.props.getrakingBranchOffice();
    this.props.getrakingArticle();
    this.props.issuedInvoices();

    if (this.state.showBulk) {
      this.props.getUploadedInvoiceAction(2);
      this.props.showBulkLoadAction();
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.dashboardReducer.getBillClient &&
      nextProps.dashboardReducer.getBillClient !== state.getBillClient
    )
      update.getBillClient = nextProps.dashboardReducer.getBillClient;

    if (
      !!nextProps.dashboardReducer.getRakingColaborator &&
      nextProps.dashboardReducer.getRakingColaborator !==
        state.getRakingColaborator
    )
      update.getRakingColaborator =
        nextProps.dashboardReducer.getRakingColaborator;
    update.statusCodeColaborator =
      nextProps.dashboardReducer.statusCodeColaborator;

    if (
      !!nextProps.dashboardReducer.getBilling &&
      nextProps.dashboardReducer.getBilling !== state.getBilling
    )
      update.getBilling = nextProps.dashboardReducer.getBilling;
    update.totalSold = nextProps.dashboardReducer.totalSold;
    update.statusCodeBilling = nextProps.dashboardReducer.statusCodeBilling;

    if (
      !!nextProps.dashboardReducer.getIncome &&
      nextProps.dashboardReducer.getIncome !== state.getIncome
    )
      update.getIncome = nextProps.dashboardReducer.getIncome;
    update.statusCodeIncome = nextProps.dashboardReducer.statusCodeIncome;

    if (
      !!nextProps.dashboardReducer.getCustomer &&
      nextProps.dashboardReducer.getCustomer !== state.getCustomer
    )
      update.getCustomer = nextProps.dashboardReducer.getCustomer;
    update.statusCodeCustomer = nextProps.dashboardReducer.statusCodeCustomer;

    if (
      !!nextProps.dashboardReducer.getRakingBranch &&
      nextProps.dashboardReducer.getRakingBranch !== state.getRakingBranch
    )
      update.getRakingBranch = nextProps.dashboardReducer.getRakingBranch;
    update.statusCodeBranch = nextProps.dashboardReducer.statusCodeBranch;

    if (
      !!nextProps.dashboardReducer.getRakingItem &&
      nextProps.dashboardReducer.getRakingItem !== state.getRakingItem
    )
      update.getRakingItem = nextProps.dashboardReducer.getRakingItem;
    update.statusCodeItem = nextProps.dashboardReducer.statusCodeItem;

    if (
      !!nextProps.dashboardReducer.quantityInvoice &&
      nextProps.dashboardReducer.quantityInvoice !== state.quantityInvoice
    )
      update.quantityInvoice = nextProps.dashboardReducer.quantityInvoice;

    if (
      !!nextProps.dashboardReducer.countInvoice &&
      nextProps.dashboardReducer.countInvoice !== state.countInvoice
    ) {
      update.countInvoice = nextProps.dashboardReducer.countInvoice;
    }
    if (
      !!nextProps.dashboardReducer.getInforme &&
      nextProps.dashboardReducer.getInforme !== state.getInforme
    )
      update.getInforme = nextProps.dashboardReducer.getInforme;
    update.statusCodeItem = nextProps.dashboardReducer.statusCodeItem;

    if (!!nextProps.showBulkLoad !== state.showBulk) {
      update.showBulk = nextProps.showBulkLoad;
    }

    if (
      !!nextProps.menuReducer.menuDashboard &&
      nextProps.menuReducer.menuDashboard !== state.menuDashboard
    ) {
      update.menuDashboard = nextProps.menuReducer.menuDashboard;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.codeExecuted &&
      this.props?.getBillClients &&
      this.props.getBillClients[0]?.licenseActiveBase
    ) {
      const expirationDate = new Date(
        this.props.getBillClients[0]?.expirationDate
      );
      const differenceInCalendarDayDoc = differenceInCalendarDays(
        expirationDate,
        new Date()
      );
      if (differenceInCalendarDayDoc <= 3) {
        this.setState({
          ...this.state,
          codeExecuted: true,
          modalLicenseResponse: soonExpired(
            differenceInCalendarDayDoc,
            expirationDate
          ),
        });
      }
    } else if (
      !this.state.codeExecuted &&
      this.props?.getBillClients &&
      !this.props.getBillClients[0]?.licenseActiveBase &&
      !this.state.modalLicenseResponse.open
    ) {
      if (
        prevState.modalLicenseResponse.open ===
        this.state.modalLicenseResponse.open
      ) {
        this.setState({
          ...this.state,
          codeExecuted: true,
          modalLicenseResponse: basePlanExpired,
        });
      }
    }
  }

  getMonths(months) {
    if (months === 1) {
      return "Enero";
    }
    if (months === 2) {
      return "Febrero";
    }
    if (months === 3) {
      return "Marzo";
    }
    if (months === 4) {
      return "Abril";
    }
    if (months === 5) {
      return "Mayo";
    }
    if (months === 6) {
      return "Junio";
    }
    if (months === 7) {
      return "Julio";
    }
    if (months === 8) {
      return "Agosto";
    }
    if (months === 9) {
      return "Septiembre";
    }
    if (months === 10) {
      return "Octubre";
    }
    if (months === 11) {
      return "Noviembre";
    }
    if (months === 12) {
      return "Diciembre";
    }
  }

  handleCloseResponseModal = () => {
    this.setState({
      modalLicenseResponse: {
        ...this.state.modalLicenseResponse,
        open: false,
      },
    });
  };

  render() {
    const classes = this.props.classes;
    const previousDate = new Date();
    const currentDate = new Date();
    previousDate.setDate(previousDate.getDate() - 29);
    return (
      <div className="container-home">
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            <Card className={classes.colorCard}>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <label className={classes.divText}>
                  Esta información corresponde a los últimos 30 días:
                  <label className={classes.divDate}>
                    {`${previousDate.getDate()}` +
                      " " +
                      `${this.getMonths(previousDate.getMonth() + 1)}`}{" "}
                    -{" "}
                    {`${currentDate.getDate()}` +
                      " " +
                      `${this.getMonths(currentDate.getMonth() + 1)}`}
                  </label>
                </label>
              </div>
            </Card>
          </Grid>
          <Grid item lg={5} md={12} xs={12} direction="column">
            <Grid container spacing={1}>
              <Grid item lg={6} xs={6}>
                <InfoCard
                  title={this.state.getBillClient.map((billigs) => {
                    return billigs.billings;
                  })}
                  subtitle={this.state.menuDashboard.labelDocumentos}
                />
              </Grid>
              {/* <Grid item lg={6} xs={6} >
                            <InfoCard title={this.state.quantityInvoice} subtitle={'Facturas'} />
                            </Grid> */}
            </Grid>
            <br />
            <Grid item lg={12} md={12} xs={12} direction="column">
              <Billing
                data={this.state.getBilling}
                totalSold={this.state.totalSold}
                quantityInvoice={this.state.countInvoice}
                statusCode={this.state.statusCodeBilling}
              />
            </Grid>
          </Grid>
          <Grid item lg={7} md={12} xs={12} direction="column">
            <RakingCollaborators
              name={"Ranking Colaboradores"}
              items={this.state.getRakingColaborator}
              statusCode={this.state.statusCodeColaborator}
              type={"Colaborador"}
            />

            <br />
            <RakingCollaborators
              name={"Ranking Sucursales"}
              items={this.state.getRakingBranch}
              statusCode={this.state.statusCodeBranch}
              type={"Sucursal"}
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <IncomeChart
              dataIncome={this.state.getIncome}
              statusCode={this.state.statusCodeIncome}
            />
          </Grid>
          <Grid item lg={5} md={12} xs={12}>
            <CustomerChart
              dataClient={this.state.getCustomer}
              statusCode={this.state.statusCodeCustomer}
            />
          </Grid>
          <Grid item lg={7} md={12} xs={12}>
            <RakingItem
              items={this.state.getRakingItem}
              statusCode={this.state.statusCodeItem}
              name={"Ranking Artículos"}
            />
          </Grid>
        </Grid>
        <ResponseModal
          modalType={this.state.modalLicenseResponse.modalType}
          title={this.state.modalLicenseResponse.title}
          body={this.state.modalLicenseResponse.body}
          modalImage={this.state.modalLicenseResponse.modalImage}
          open={this.state.modalLicenseResponse.open}
          textButton={this.state.modalLicenseResponse?.textButton}
          closeElement={this.state.modalLicenseResponse.closeElement}
          onCloseElement={this.handleCloseResponseModal}
          closeFromModalBody={
            this.state.modalLicenseResponse.closeFromModalBody
          }
        />
      </div>
    );
  }
}

Home.propTypes = {
  prop: PropTypes,
};

const mapStateToProps = (state) => ({
  dashboardReducer: state.dashboardReducer,
  menuReducer: state.menuReducer,
  showBulkLoad: state.invoiceReducer.showBulk,
  getBillClients: state.dashboardReducer.getBillClient,
});

export default connect(mapStateToProps, {
  getBillClient,
  getrakingCollaborators,
  getBilling,
  getIncome,
  getCustomer,
  getrakingBranchOffice,
  getrakingArticle,
  issuedInvoices,
  getUploadedInvoiceAction,
  showBulkLoadAction,
})(withStyles(useStyles)(Home));
