import { types } from "../types/types"

const initialState = {}


export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_OFFICES:
            return {
                ...state,
                getOfficesResponse: action.payload
            }
        default:
            return state
    }
} 