import React from 'react';

import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { URL_SECURITY } from '../config/config'


//import { validateTokenExpirationDate } from '../actions/authActions';

const PrivateRouteWithoutMenu = ({ component: Component, ...rest }) => {
    //validateTokenExpirationDate();
    return (
        <Route {...rest} render={props => (
            <div>
                {
                    localStorage.getItem('isAuthenticated')
                        ? <Component></Component>
                        : window.location.href = URL_SECURITY
                }
            </div>
        )} />
    );
};

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {})(PrivateRouteWithoutMenu);
