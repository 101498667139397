import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    backgroundColor: theme.palette.thirdColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: 45,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  buttonInfo: {
    backgroundColor: theme.palette.eleventhBackgroundColor,
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600,
    padding: 1,
    border: "none",
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    width: 129,
    height: 29,
  },
  actions: {
    textAlign: "center",
    justifyContent: "center",
  },
}));

export default function InfoCard(props) {
  const { title, subtitle } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <span className={classes.title}>{title}</span>
        <Typography variant="body2" className={classes.subTitle} component="p">
          {subtitle}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.actions}>
        <button type="button" className={classes.buttonInfo}>
          Comprar
        </button>
      </CardActions>
    </Card>
  );
}
