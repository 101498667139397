import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import { AddressDocumentCard } from "../customer/addressDocumentCard";
import { ContactDocumentCard } from "../customer/contactDocumentCard";
import { CollapseFormPanel } from "../../../components/ui/collapseFormPanel";
import { ReferenceDocumentForm } from "../reference/refDocumentForm";
import { ReferenceDocumentCard } from "../reference/refDocumentCard";

import {
  loadDocumentReferenceAction,
  deleteDocumentReferenceAction,
  refreshDocumentReferenceAction,
} from "../../../actions/documentReferenceAction";
import {
  loadAddressCustomerAction,
  loadContactCustomerAction,
} from "../../../actions/documentAction";
import { ConfirmAlert } from "../../../helpers/alert.helpers";
import CustomProgress from "../../../components/Progress/progress.component";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    borderRadius: "5px",
    color: theme.palette.primaryColor,
  },
  titleSection: {
    height: 36,
    width: "100%",
    borderRadius: 5,
    backgroundColor: "rgba(18,18,18,0.3)",
    color: "#2CC63E",
    fontSize: "18px",
    fontWeight: "600",
    paddingTop: 1,
    textAlign: "center",
  },
}));

export const AdditionalData = ({
  addresses,
  contacts,
  principalAddress,
  typedocument,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addressList, setAddressList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [expandPanelReference, setExpandPanelReference] = useState(false);

  const { opdocumentosreferencia, documentoreferenciaactivo } = useSelector(
    (s) => s.documentReferenceReducer
  );
  const { direccionclienteid, contactoclienteid, escontingencia } = useSelector(
    (s) => s.documentReducer
  );
  const { loadingDetail: loadingGetCustomer } = useSelector(
    (s) => s.customerReducer
  );

  useEffect(() => {
    if (!!addresses) {
      setAddressList(addresses);
    }

    if (addresses?.length <= 0 && !!principalAddress) {
      dispatch(loadAddressCustomerAction(principalAddress.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  useEffect(() => {
    if (!!contacts) {
      setContactsList(contacts);
    }
  }, [contacts]);

  /**
   * Actualiza listado documentos de referencia
   */
  useEffect(() => {
    const contingency = opdocumentosreferencia.filter(
      (c) => c.tipodocumentoid.toString() === "9"
    );

    if (escontingencia === true) {
      if (contingency.length > 0) {
        setExpandPanelReference(false);
      } else {
        setExpandPanelReference(true);
      }
    } else {
      setExpandPanelReference(false);
    }

    if (escontingencia === false && contingency.length > 0) {
      const notContingency = opdocumentosreferencia.filter(
        (c) => c.tipodocumentoid.toString() !== "9"
      );
      dispatch(refreshDocumentReferenceAction(notContingency));
    }
  }, [dispatch, escontingencia, opdocumentosreferencia]);

  /**
   * Carga identificador de dirección
   * @param {*} id
   */
  const handleSelectionAddress = (id) => {
    dispatch(loadAddressCustomerAction(direccionclienteid === id ? null : id));
  };

  /**
   * Carga identificador de contacto
   * @param {*} id
   */
  const handleSelectionContact = (id) => {
    dispatch(loadContactCustomerAction(contactoclienteid === id ? null : id));
  };

  /**
   * Carga documento de referencia en formulario
   * @param {*} id Identificador
   */
  const handleLoadDocumentReference = (id) => {
    setExpandPanelReference(true);
    dispatch(loadDocumentReferenceAction(id));
  };

  /**
   * Elimina un documento de referencia
   * @param {*} id Identificador
   */
  const handleDeleteDocumentReference = (id, numero) => {
    const confirmAction = (confirmed) => {
      if (confirmed) dispatch(deleteDocumentReferenceAction(id));
    };
    ConfirmAlert(
      `¿Estás seguro que quieres eliminar el documento número ${numero}?`,
      confirmAction,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * Expande contrae formulario documentos de referencia,
   * limpia información de formulario
   */
  const handleExpandPanelDocument = () => {
    if (expandPanelReference && !!documentoreferenciaactivo) {
      dispatch(loadDocumentReferenceAction(null));
    }

    setExpandPanelReference((prev) => !prev);
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} xs={12}>
        <div className={classes.titleSection}>Direcciones adicionales</div>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={2}>
          {loadingGetCustomer ? (
            <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
              <CustomProgress size={20} />
            </Grid>
          ) : (
            <>
              {!!principalAddress?.direccion && (
                <>
                  {!!principalAddress && (
                    <Grid key={principalAddress.id} item lg={6} xs={6}>
                      <AddressDocumentCard
                        selected={direccionclienteid}
                        handleSelection={() =>
                          handleSelectionAddress(principalAddress.id)
                        }
                        {...principalAddress}
                      />
                    </Grid>
                  )}
                </>
              )}

              {addressList.map((address) => (
                <Grid key={address.id} item lg={6} xs={6}>
                  <AddressDocumentCard
                    selected={direccionclienteid}
                    handleSelection={() => handleSelectionAddress(address.id)}
                    {...address}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>

      <Grid item lg={12} xs={12}>
        <div className={classes.titleSection}>Contactos adicionales</div>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={2}>
          {loadingGetCustomer ? (
            <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
              <CustomProgress size={20} />
            </Grid>
          ) : (
            <>
              {contactsList.map((contact) => (
                <Grid key={contact.id} item lg={6} xs={6}>
                  <ContactDocumentCard
                    selected={contactoclienteid}
                    handleSelection={() => handleSelectionContact(contact.id)}
                    {...contact}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>

      {typedocument === "FA" && (
        <>
          <Grid item lg={12} xs={12}>
            <div className={classes.titleSection}>Documentos de referencia</div>
          </Grid>
          <Grid item lg={12} xs={12}>
            <CollapseFormPanel
              component={<ReferenceDocumentForm />}
              title={"Agregar documentos de referencia"}
              titleOpen={
                !!documentoreferenciaactivo
                  ? "Editar documento de referencia"
                  : "Agregar documento de referencia"
              }
              open={expandPanelReference}
              onClick={handleExpandPanelDocument}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Grid container spacing={1}>
              {opdocumentosreferencia.map((document) => (
                <Grid key={document.id} item lg={6} xs={12}>
                  <ReferenceDocumentCard
                    handleLoad={handleLoadDocumentReference}
                    handleDelete={handleDeleteDocumentReference}
                    {...document}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

AdditionalData.propTypes = {
  addresses: PropTypes.array,
  contacts: PropTypes.array,
  principalAddress: PropTypes.object,
};
