import { checkTotalDocument } from './validateDocument';
import { WarningAlert } from './alert.helpers';
import { warningToast } from './toast.helpers';

/**
 * * Valida información de documento antes de guardar
 */

export const validateDocument = (document, cartDocumentSupport = false) => {
  let errorMessages = [];
  if (document.opdetalledocumento.length === 0) {
    if (!cartDocumentSupport) {
      errorMessages.push('Debes seleccionar al menos un producto.');
    } else {
      errorMessages.push('Debes agregar al menos un servicio.');
    }
  } else {
    if (document.valorapagar < 0)
      errorMessages.push(
        'El valor a pagar de la factura no puede ser negativo.'
      );

    //Campos obligatorios por item
    let valid = true;
    document.opdetalledocumento.forEach((item) => {
      if (item.esregalo) {
        if (!item.asumeresponsabilidad || !item.valorreferencia) {
          valid = false;
        }
      } else {
        if (item.valorneto === 0 || !item.valorneto) {
          valid = false;
        }
      }
    });

    if (!valid) {
      errorMessages.push('Tienes campos pendientes por completar.');
    }

    //Valores cargos
    if (document.valorbase < document.valorcargosaplicados) {
      errorMessages.push(
        'El valor de los cargos no puede ser superior a la factura con descuentos'
      );
    }
  }

  if (errorMessages.length > 0) {
    let message = errorMessages.join('  ');
    WarningAlert('Upss...!', message);
    return false;
  }

  return true;
};

export const validateNote = (note) => {
  let errormessage = [];

  if (note.opdetalledocumento.length === 0) {
    WarningAlert('Upsss...!', 'Debes seleccionar al menos un articulo');
    return false;
  }

  const valid = checkTotalDocument(
    note.valorapagar,
    note.opdocumentosreferencia
  );
  if (!valid) {
    errormessage.push(
      'El valor del anticipo es superior al valor de la nota. Por favor verifique la información.'
    );
    warningToast(
      'Valor anticipo no valido.',
      'El valor del anticipo es superior al valor de la nota. Por favor verifique la información.'
    );
  }

  note.opdetalledocumento.forEach((item) => {
    if (item.total < 0) {
      errormessage.push(
        `El artículo "${item.nombrearticulo}" tiene un valor total negativo de $${item.total}.`
      );
    }
  });

  if (!note.motivonota)
    errormessage.push('Debes seleccionar el motivo de la edición.');

  if (note.observacionesnota.trim().length === 0)
    errormessage.push('Debes digitar la observación de la nota.');

  if (errormessage.length > 0) {
    let message = errormessage.join('               ');
    WarningAlert('Upsss...!', message);
    return false;
  }

  return true;
};
