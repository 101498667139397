import { SetEquivalentDocFormErrorsAction } from "../../../actions/equivalentDocAction";

export const checkFormErrors = (dispatch, customer) => {
  const errors = [];
  if (customer === null) {
    errors.push("No se ha seleccionado un cliente");
  }
  dispatch(SetEquivalentDocFormErrorsAction(errors));
  return errors.length > 0;
};
