import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import SelectForm from "../../components/select/select.component";
import InputForm from "../../components/input/input.component";
import ToggleButtons from "../../components/toggle/toggle.component";
import TooltipMessage from "../../components/tooltip/tootltip-message.component";
import CustomAutoComplete from "../../components/autocomplete/autocomplete.component";
import ButtonWizard from "../../components/button/buttonWizard.component";
import CustomProgress from "../../components/Progress/progress.component";
import logo from "../../images/wizard/logo.png";

import {
  getCompanyAction,
  updateInitialData,
} from "../../actions/basicDataActions";
import {
  getStatesAction,
  getAllCitiesAction,
  getRegimeTypesAction,
  getFiscalResponsibilityAction,
  getTaxResponsibilityAction,
} from "../../actions/configAction";

import wizardImage from "../../images/wizard/wizard_5.png";
import imgBtnBack from "../../images/wizard/btnBack.png";

import isEmpty from "../../utils/isEmpty";
import "./step.css";
import { useFormComplete } from "../../hooks/useFormComplete";
import SwitchForm from "../switch/switch.component";
import MultiSelectForm from "../multiselect/multiselect.component";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: "100vh ",
  },
  general: {
    maxHeight: "100vh",
    position: "absolute",
    overflow: "auto",
  },
  leftGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  arrowBackIcon: {
    marginRight: 15,
    color: "#16B1F3",
  },
  centerGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: 7,
    },
  },
  logo: {
    marginTop: 20,
  },
  txtCompany: {
    color: "#7DD1F8",
    fontStyle: "italic",
  },
  grpToggle: {
    alignSelf: "center",
    "& .Mui-selected": {
      backgroundColor: theme.palette.secundaryColor + "!important",
      borderRadius: 5,
      border: "none",
      color: "#FFFFFF",
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.secundaryColor + "!important",
      borderRadius: 5,
      border: "none",
      color: "#FFFFFF",
    },
  },
  btnNext: {
    textTransform: "none",
    backgroundColor: theme.palette.thirdColor,
    color: "#FFFFFF",
    marginBottom: 5,
  },
  imageWrapper: {
    backgroundColor: "#16B1F3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avoidStep: {
    alignSelf: "flex-end",
    marginTop: 30,
    marginRight: 40,
    fontStyle: "italic",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#16B1F3",
    height: "100vh",
  },
  image: {
    width: "100%",
  },
  txtMsg: {
    fontSize: 30,
    color: "#FFFFFF",
    textAlign: "center",
    fontStyle: "italic",
  },
  btnBack: {
    color: "#FFFFFF",
    cursor: "pointer",
    fontSize: "0.9vw",
    width: "7vw",
    textTransform: "none",
    justifyContent: "space-around",
  },
  containerProgresss: {
    marginTop: "15vh",
  },
  containerSeccion: {
    height: "100vh",
    overflow: "auto",
  },
}));

const Step5 = ({ setActiveStep, setActionSetBack }) => {
  const animated = "animate__animated animate__fadeIn animate__faster";
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [company, setCompany] = useState({});
  const [disabledForm, setDisabledForm] = useState(false);
  const [loadingGetData, setLoadingGetData] = useState(false);
  const [typesRegime, setTypesRegime] = useState([]);

  const {
    values,
    handleInputChange,
    handleTooggleChange,
    handleAutocompleteChange,
    handleUpdateForm,
    handleCheckChange,
  } = useFormComplete({
    departamento: null,
    municipio: "",
    tipoPersona: "1",
    regimen: "",
    tiporesponsabilidadtributaria: "",
    fiscalResponsibility: [],
    direccion: "",
    nombreEstablecimiento: "",
    prestaServicioSalud: false,
    codigo: "",
  });

  const {
    departamento,
    municipio,
    tipoPersona,
    regimen,
    direccion,
    nombreEstablecimiento,
    prestaServicioSalud,
    codigo,
    tiporesponsabilidadtributaria,
    fiscalResponsibility,
  } = values;

  //Data Reducers
  const {
    getStatesResponse,
    allCitiesCountry,
    getRegimeTypesResponse,
    getTaxResponsibility: taxResponsibilityList,
    getFiscalResponsibility: fiscalResponsibilityList,
  } = useSelector((s) => s.configReducer);

  const {
    requestCompany,
    loadingSaveBasicData,
    loadingGetBasicData,
  } = useSelector((s) => s.basicDataReducer);

  /**
   * Cargue inicial
   */
  useEffect(() => {
    dispatch(getStatesAction());
    dispatch(getAllCitiesAction());
    dispatch(getRegimeTypesAction());
    dispatch(getCompanyAction());
    dispatch(getTaxResponsibilityAction());
    dispatch(getFiscalResponsibilityAction());
  }, [dispatch]);

  /**
   * Escucha cambios en Reducer,
   * carga información de empresa en formulario
   */
  useEffect(() => {
    if (
      !isEmpty(requestCompany) &&
      !isEmpty(allCitiesCountry) &&
      !isEmpty(getStatesResponse)
    ) {
      if (requestCompany.wizard === 3 || requestCompany.wizard === 2) {
        history.push(`/dashboard`);
      }

      setStatesOptions(getStatesResponse);
      setCompany(requestCompany);
      setAllCities(allCitiesCountry);

      //Filtro Listado ciudades
      let principal = requestCompany.sucursales.find(
        (s) => s.principal === true
      );
      let ciuOpcion = allCitiesCountry.find(
        (c) => c.id === principal?.ciudadid
      );
      let depOpcion = getStatesResponse.find(
        (d) => d.value === ciuOpcion?.departamentoid?.toString()
      );
      let newCities = allCitiesCountry.filter(
        (c) => c.departamentoid === ciuOpcion?.departamentoid
      );

      setCitiesOptions(newCities);

      //Datos de empresa
      handleUpdateForm({
        tipoPersona: `${requestCompany.diantipopersonaid}`,
        regimen: requestCompany.diantiporegimenid,
        direccion: !!principal?.direccion ? principal?.direccion : "",
        nombreEstablecimiento: principal?.nombre ? principal?.nombre : "",
        departamento: !!depOpcion ? depOpcion : null,
        municipio: !!ciuOpcion ? ciuOpcion : null,
        prestaServicioSalud: requestCompany.esprestadorserviciosalud ?? false,
        codigo: requestCompany.codigoprestadorserviciosalud ?? "",
        tiporesponsabilidadtributaria:
          requestCompany.responsabilidadtributariaid ?? "",
        fiscalResponsibility:
          requestCompany.responsabilidadfiscal?.split("").map(Number) ?? [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCompany, allCitiesCountry, getStatesResponse]);

  /**
   * Listado tipos de regimen
   */
  useEffect(() => {
    if (!isEmpty(getRegimeTypesResponse)) {
      let newList = getRegimeTypesResponse.map((item) => ({
        ...item,
        text: `${item.codigo} - ${item.descripcion}`,
      }));

      setTypesRegime(newList);
    }
  }, [getRegimeTypesResponse]);

  /**
   * Deshabilitar componentes formulario
   */
  useEffect(() => {
    setDisabledForm(loadingSaveBasicData ?? false);
  }, [loadingSaveBasicData]);

  /**
   * Loading Get data company
   */
  useEffect(() => {
    setLoadingGetData(loadingGetBasicData ?? false);
  }, [loadingGetBasicData]);

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  const syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];
    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }

    handleUpdateForm({
      [options.name]: value,
    });
  };

  /**
   * Captura y guarda información de empresa
   * @param {*} e Evento
   */
  const onSubmit = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      const data = {
        EmpresaId: parseInt(company.id),
        Diantipopersonaid: parseInt(tipoPersona),
        Diantiporegimenid: regimen,
        ResponsabilidadFiscal: fiscalResponsibility.join(""),
        ResponsabilidadTributariaId: Number(tiporesponsabilidadtributaria),
        Razonsocial: company.razonsocial,
        Direccion: direccion,
        Ciudadid: municipio.value,
        NombreSucursal: nombreEstablecimiento,
        Esprestadorserviciosalud: prestaServicioSalud,
        Codigoprestadorserviciosalud: codigo,
      };

      dispatch(updateInitialData(data, completeSave));
    } else {
      validator.showMessages();
      forceUpdate(1);
    }
  };

  /**
   * Redirige a paso 5 despues de guardado exitoso
   */
  const completeSave = () => {
    setActiveStep(5);
  };

  /**
   * Evento, Escucha cambio en listado de departamentos
   * @param {*} event Evento
   * @param {*} values Nuevo valor
   */
  const changeCompanyState = (e, value, name) => {
    let newCities = [];
    if (!!value)
      newCities = allCities.filter(
        (c) => c.departamentoid === parseInt(value.value)
      );

    handleUpdateForm({
      municipio: null,
      departamento: value,
    });

    setCitiesOptions(newCities);
  };

  /**
   * Evento, boton volver
   */
  const handleBack = () => {
    setActionSetBack(3);
  };

  let fiscalResponsibilityOption = [];
  if (!!fiscalResponsibilityList) {
    fiscalResponsibilityOption = fiscalResponsibilityList
      .filter((item) => item.codigo !== "O-48" && item.codigo !== "O-49")
      .map((item) => {
        return {
          ...item,
          value: item.id,
          text: `${item.codigo} ${item.descripcion}`,
        };
      });
  }

  return (
    <>
      <Grid container className={classes.general}>
        <Grid item lg={7} xs={12} className={classes.containerSeccion}>
          {loadingGetData ? (
            <Grid container justify={"center"}>
              <CustomProgress />
            </Grid>
          ) : (
            <div className={animated}>
              <Grid container justify={"center"}>
                <Grid item lg={6} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                      <img src={logo} alt="logo" className={classes.logo} />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography>
                        Verifica la información de tu cuenta
                      </Typography>
                      <Typography className={classes.txtCompany}>
                        {company.razonsocial}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <ToggleButtons
                        name={"tipoPersona"}
                        label={"Tipo de persona"}
                        value={tipoPersona}
                        onChange={handleTooggleChange}
                        titleOne={"Jurídica"}
                        titleTwo={"Natural"}
                        disabled={disabledForm}
                        txtTooltip={
                          <TooltipMessage
                            title={"¿Cómo sé que tipo de persona soy?"}
                            message={
                              "Podrás encontrar tu tipo de persona en la casilla 24 de tu RUT"
                            }
                          />
                        }
                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <SelectForm
                        label={"Regimen"}
                        name="regimen"
                        value={regimen}
                        options={typesRegime}
                        validator={validator}
                        validateOptions={"required"}
                        disabled={disabledForm}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <SelectForm
                        label={"Responsabilidad Tributaria *"}
                        name="tiporesponsabilidadtributaria"
                        value={tiporesponsabilidadtributaria}
                        options={taxResponsibilityList}
                        // disabled={loadingActive}
                        validator={validator}
                        validateOptions={"required"}
                        nameValidator={"tiporesponsabilidadtributaria"}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <MultiSelectForm
                        label={"Responsabilidad Fiscal *"}
                        name="fiscalResponsibility"
                        value={fiscalResponsibility}
                        options={fiscalResponsibilityOption}
                        onChange={syncMultipleChange}
                        validator={validator}
                        validateOptions={"required"}
                        nameValidator={"fiscalResponsibility"}
                        renderValue={(selected) =>
                          fiscalResponsibility
                            .map((value) => {
                              let name = fiscalResponsibilityOption.filter(
                                (fiscal) => fiscal.id === value
                              );
                              return name[0]?.descripcion;
                            })
                            .join(",")
                        }
                        placeholder={"Seleccione..."}
                      />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <SwitchForm
                        name={"prestaServicioSalud"}
                        value={prestaServicioSalud}
                        checked={prestaServicioSalud}
                        titleOn={
                          "Soy una entidad prestadora de servicios de salud"
                        }
                        onChange={handleCheckChange}
                      />
                    </Grid>

                    {prestaServicioSalud && (
                      <Grid item lg={12} xs={12} className={animated}>
                        <InputForm
                          name={"codigo"}
                          label={"Código prestador de servicio de salud"}
                          maxLength={50}
                          onChange={(e) => handleInputChange(e, "alphanumeric")}
                          value={codigo}
                          disabled={disabledForm}
                        />
                      </Grid>
                    )}

                    <Grid item lg={12} xs={12}>
                      <Divider style={{ backgroundColor: "grey" }} />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography>Sucursal principal</Typography>
                      <Typography className={classes.txtCompany}>
                        Estos son los datos de tu sucursal principal los cuales
                        te servirán para facturar.
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputForm
                        name={"direccion"}
                        label={"Dirección"}
                        maxLength={200}
                        onChange={handleInputChange}
                        value={direccion}
                        disabled={disabledForm}
                        validator={validator}
                        validateOptions={"required|min:3|max:200"}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <CustomAutoComplete
                        name="departamento"
                        label="Departamento"
                        value={departamento}
                        options={statesOptions}
                        textNoOption="No se encontraron resultados."
                        disabled={disabledForm}
                        onChange={changeCompanyState}
                        nameValidator={"departamento"}
                        validator={validator}
                        validateOptions={"required"}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <CustomAutoComplete
                        name="municipio"
                        label="Municipio"
                        value={municipio}
                        options={citiesOptions}
                        textNoOption="No se encontraron resultados."
                        disabled={disabledForm}
                        onChange={handleAutocompleteChange}
                        nameValidator={"municipio"}
                        validator={validator}
                        validateOptions={"required"}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputForm
                        name={"nombreEstablecimiento"}
                        label={"Nombre del establecimiento"}
                        maxLength={200}
                        onChange={handleInputChange}
                        value={nombreEstablecimiento}
                        disabled={disabledForm}
                        validator={validator}
                        validateOptions={"required|min:3|max:200"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={3} xs={12}>
                  <Button className={classes.btnBack} onClick={handleBack}>
                    <img alt="" src={imgBtnBack}></img>
                    Atras
                  </Button>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ButtonWizard
                    text={"Siguiente"}
                    type={"button"}
                    onClick={onSubmit}
                    loading={loadingSaveBasicData === true ? true : false}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>

        <Grid item lg={5} xs={12}>
          <Box className={classes.wrapper}>
            <div className={classes.image}>
              <img src={wizardImage} alt="wizardImage" />
              <Typography className={classes.txtMsg}>
                Hazlo fácil!!!
                <br></br>
                Verifica tus datos básicos para poder facturar
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Step5;
