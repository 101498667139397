import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import InputSearch from '../../../components/input/inputSearch.component';
import CustomProgress from '../../../components/Progress/progress.component';


import { getRoleOfLoggedUser } from '../../../actions/authActions';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 200,
        backgroundColor: theme.palette.primaryBackgroundColor,
        color: theme.palette.primaryColor,
        border: 'none',
        minHeight: 130,
        overflowX: 'hidden'
    },
    listStyle: {
        backgroundColor: theme.palette.primaryBackgroundColor,
        color: theme.palette.primaryColor,
    },
    item: {
        fontSize: 12,
        marginBottom: 3,
        "&.MuiListItem-root:hover": {
            backgroundColor: 'rgba(255,255,255,0.16)'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,0.16)'
        },
        '& .MuiTypography-displayBlock': {
            fontSize: 14
        }
    },
    itemButton: {
        fontSize: 12,
        marginBottom: 3,
        '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,0.16)'
        },
        '& .MuiTypography-displayBlock': {
            fontSize: 14
        }
    },
    icon: {
        color: 'gray'
    },
    noResult: {
        marginBottom: 5,
        padding: 5,
        paddingLeft: 15
    },
    containerInput: {
        padding: '10px 15px'
    },
    padding: {
        padding: 10
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export const ChargesList = React.memo(({
    handleCreate,
    handleSelection,
    charges,
    loadingGetCharges
}) => {
    const classes = useStyles();
    const [filter, setFilter] = useState("");
    const [chargesList, setChargesList] = useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState('');

 
    /**
     * Actualiza listado a mostrar en pantalla
     */
    useEffect(() => {
        setChargesList(charges);

    }, [charges])

    /**
     * Sincroniza nuevo valor input con state
     * @param {*} param0 
     */
    const handleChangeFilter = ({ target }) => {
        setFilter(target.value);
        let newList = filterList(target.value);
        setChargesList(newList);
    }

    /**
     * Marca como seleccionado item de lista
     * @param {*} index 
     */
    const handleItemClick = (item) => {
        setSelectedIndex(item.id);
        handleSelection(item);
    }

    /**
     * Filtra listado por nombre
     * @param {*} value 
     * @returns 
     */
    const filterList = (value) => {
        if (value !== '') {
            let newList = charges.filter(c => c.descripcion.toLowerCase().match(new RegExp(value.toLowerCase() + ".*")));
            return newList;
        }
        return charges;
    }


    return (
        <>
            <div className={classes.root}>
                <div className={classes.containerInput}>
                    <InputSearch
                        label={"Buscar cargo"}
                        maxLength={15}
                        value={filter}
                        onChange={handleChangeFilter} />
                </div>
                {loadingGetCharges === true ?
                    <CustomProgress /> :
                    <List className={classes.listStyle} component="nav">
                        {
                            getRoleOfLoggedUser()
                                ? <ListItem
                                    className={classes.itemButton}
                                    button
                                    onClick={() => { }}>
                                    <LightTooltip
                                        title="No tiene permisos sobre esta acción"
                                        placement="bottom">
                                        <span className={classes.padding}>Crear nuevo cargo</span>
                                    </LightTooltip>
                                    <ListItemIcon style={{ minWidth: 0 }}>
                                        <AddCircleIcon className={classes.icon} />
                                    </ListItemIcon>
                                </ListItem>
                                : <ListItem
                                    className={classes.itemButton}
                                    button
                                    onClick={handleCreate}>
                                    <ListItemText primary={"Crear nuevo cargo"} />
                                    <ListItemIcon style={{ minWidth: 0 }}>
                                        <AddCircleIcon className={classes.icon} />
                                    </ListItemIcon>
                                </ListItem>

                        }

                        {chargesList.length
                            ? <>
                                {chargesList.map((item) => {
                                    return (
                                        <ListItem
                                            key={item.id}
                                            className={classes.item}
                                            button
                                            selected={selectedIndex === item.id}
                                            onClick={() => handleItemClick(item)}
                                        >
                                            <ListItemText primary={item.descripcion} />
                                        </ListItem>
                                    )
                                })}
                            </>
                            : <div className={classes.noResult}>No se han encontrado resultados...</div>}
                    </List>
                }
            </div>

        </>


    )
})