import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import CustomTooltip from "../tooltip/tooltip.component";
import { FormHelperText } from "@material-ui/core";
import CustomProgress from "../Progress/progress.component";
import "./switch.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {},
  title: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 17,
    textAlign: "start",
    marginRight: 10,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50.4,
    height: 23,
    padding: 0,
    display: "flex",
    borderRadius: 15,
    overflow: "hidden",
    border: "1.4px solid #C9C9C9",
  },
  switchBase: {
    padding: 2,
    color: "grey",
    "&$checked": {
      transform: "translateX(22.4px)",
      color: theme.palette.secundaryColor,
      "& + $track": {
        opacity: 1,
        backgroundColor: "transparent", //gris
      },
    },
  },
  thumb: {
    width: 17,
    height: 17,
    boxShadow: "none",
  },
  track: {
    border: `1.4px solid ${theme.palette.fifthBackgroundColor}`,
    borderRadius: 17,
    opacity: 1,
    backgroundColor: "transparent", // Gris Claro
    display: "none",
  },
  checked: {},
}))(Switch);

export default function SwitchForm({
  titleOff,
  titleOn,
  checked: externalChecked,
  onChange,
  name,
  txtTooltip,
  text,
  value,
  validator,
  validateOptions,
  withoutMargin,
  disabled,
  loading,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(
    externalChecked === true ? true : false
  );

  useEffect(() => {
    setChecked(externalChecked);
  }, [externalChecked]);

  let errorValidator = validator
    ? validator.message(titleOn, value, validateOptions)
    : "";

  return (
    <FormGroup className={classes.root}>
      <div className={withoutMargin ? "" : classes.margin}>
        <div className={classes.container}>
          <div className={classes.title}>
            {!!titleOn ? titleOn : ""}{" "}
            {text !== undefined ? `${text}${"%"}` : ""}
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            {!!txtTooltip && (
              <div style={{ marginLeft: 5 }}>
                <CustomTooltip message={txtTooltip} />
              </div>
            )}
            <div style={{ marginLeft: 5 }}>
              {loading === true ? (
                <CustomProgress size={15} />
              ) : (
                <AntSwitch
                  error={!!errorValidator ? "error" : ""}
                  name={name}
                  checked={checked}
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
          {!!titleOff && <div className={classes.title}>{titleOff}</div>}
          {!!errorValidator && (
            <FormHelperText>{errorValidator}</FormHelperText>
          )}
        </div>
      </div>
    </FormGroup>
  );
}
