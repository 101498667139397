import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-contained.Mui-disabled': {
      color: theme.palette.primaryColor,
      backgroundColor: '#b3f9bb',
    },
    fontSize: '1.2vh',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    margin: '15px 15px'
  },
  wrapperLessMargin: {
        position: 'relative',
        width: '100%',	
        margin: '5px 5px'	
      },
  buttonSuccess: {
    backgroundColor: theme.palette.secundaryColor,
    color: theme.palette.primaryColor,
    '&:hover': {
      backgroundColor: green[800],
    },
    width: '100%',
    height: 50,
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize:18
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ButtonSecundary(props) {
  const classes = useStyles();
  const { onClick, loading, text, type, disabled } = props;


  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          className={classes.buttonSuccess}
          disabled={loading === true || disabled === true ? true : false}
          onClick={onClick}
          type={type!==undefined?type:'button'}>
          {text}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}
