/**
 * Maneja el cambio de estado de un documento.
 * @param {Object} options - Opciones para el manejo del estado del documento.
 * @param {string} options.documentId - ID del documento.
 * @param {boolean} options.currentState - Estado actual del documento.
 * @param {Function} options.dispatchFunction - Función de despacho de Redux para actualizar el estado.
 * @param {Function} options.actionFunction - Acción de Redux para actualizar el documento con el nuevo estado.
 * @param {boolean} options.isFavorite - Indica si el documento es favorito o no.
 * @param {string} options.operation - Operación a realizar ("FAVORITO" o "PAGADO").
 */
export const handleDocumentState = ({
  actionFunction,
  currentState,
  dispatchFunction,
  documentId,
  isFavorite,
  operation,
}) => {
  const documentData = {
    Id: documentId,
    Operacion: operation,
    Favorito: operation === "FAVORITO" ? !currentState : isFavorite,
    Pagado: operation === "PAGADO" ? !currentState : undefined,
  };

  dispatchFunction(actionFunction({ dataDoc: documentData }));
};
