import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ResponseModal from "../../../components/modal/responseModal.component";
import ArchiveModal from "../../../components/modal/archiveModal.component";
import { getDocumentDocSupportNoteAction } from "../../../actions/documentSupportAction";

const useStyles = makeStyles((theme) => ({
  containerActions: {
    backgroundColor: "#393939",
    padding: 0,
  },
  button: {
    borderRadius: "15px !important",
  },
  labelButton: {
    flexDirection: "column",
    fontSize: 12,
    color: theme.palette.primaryColor,
  },
  iconAction: {
    color: "white",
    fontSize: "1.3rem",
  },
}));

/**
 * Evento click, controla visualizar o esconder modal ver PDF
 */

const FooterNoteRevision = ({ handleBackRevision, dataNote, docFilterRes }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loadingGetFile, setLoadingGetFile] = useState(false);
  const [base64PDF, setBase64PDF] = useState(null);
  const [base64XML, setBase64XML] = useState(null);
  const [modalData, setModalData] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  /**
   * Cierra Modal, Notificación usuario
   */
  const handleCloseResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  /**
   * Cierra Modal, Previsusalizacion PDF
   */
  const handleCloseFileModal = () => {
    setOpenModal(false);
    setBase64PDF(null);
    setBase64XML(null);
  };

  /**
   * Lee respuesta consulta archvivos documento electrónico
   * @param {*} data Response
   */
  const readResponseGetFiles = (response) => {
    setLoadingGetFile(false);
    if (response !== null) {
      setBase64PDF(
        response.find((c) => c?.tipoContenido === "application/pdf")
          ?.archivoBase64 ?? null
      );
      setBase64XML(
        response.find((c) => c?.tipoContenido === "application/xml")
          ?.archivoBase64 ?? null
      );
    } else {
      setOpenModal(false);
    }
  };
  const handleOpenModal = () => {
    if (
      docFilterRes.tipoestadodocumentoid === 3 ||
      docFilterRes.tipoestadodocumentoid === 2
    ) {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Archivo no disponible",
        body: (
          <div>
            {
              "Documento soporte  en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá visualizarlo."
            }
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
      return;
    } else {
      setLoadingGetFile(true);
      setOpenModal(true);
      dispatch(
        getDocumentDocSupportNoteAction(dataNote.id, readResponseGetFiles)
      );
    }
  };

  return (
    <Grid container className={classes.containerActions}>
      <Grid item lg={4} xs={12}>
        <IconButton
          classes={{ root: classes.button, label: classes.labelHorizontal }}
          variant="raised"
          disableRipple={true}
          onClick={() => handleBackRevision(null, null, false, true)}
          style={{ marginTop: 10 }}
        >
          <div className={classes.containerIcon}>
            <ArrowBackIosIcon className={classes.iconAction} />
          </div>
          <span style={{ marginLeft: 5, fontSize: "0.9rem", color: "white" }}>
            {"Volver"}
          </span>
        </IconButton>
      </Grid>
      <Grid item lg={4} xs={12} style={{ textAlign: "center" }}>
        <IconButton
          classes={{ root: classes.button, label: classes.labelButton }}
          variant="raised"
          disableRipple={true}
          onClick={handleOpenModal}
        >
          <div className={classes.containerIcon}>
            <PictureAsPdfIcon className={classes.iconAction} />
          </div>
          <span style={{ marginTop: 5 }}>{"Generar PDF"}</span>
        </IconButton>
      </Grid>
      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleCloseResponseModal}
      />
      {/* Modal previsualizar PDF */}
      <ArchiveModal
        loading={loadingGetFile}
        open={openModal}
        pdfBase64={base64PDF}
        xmlBase64={base64XML}
        handleClose={handleCloseFileModal}
      />
    </Grid>
  );
};

export default FooterNoteRevision;
