import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        padding: 5,
        textAlign: 'center',
        borderRadius: 5,
        backgroundColor: '#3B3B3B',
        width: '100%',
        justifyContent: 'space-between',
        marginLeft:1
    },
    text: {
        fontStyle: 'italic',
        width: '95%'
    }



}));

export const EmptyCustomer = () => {
    const classes = useStyles();

    return (
        <Grid container className={`animate__animated animate__fadeIn animate__faster ${classes.root}`} spacing={2}>
            <ArrowBackIcon />
            <span className={classes.text}>
                Selecciona un cliente de la lista de la izquierda
            </span>
        </Grid>

    )
}
