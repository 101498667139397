import {
  defaultHeaders,
  API_ENDPOINT_ENTERPRISE,
  TIME_CREATE_REGISTER,
} from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";
import { types } from "../types/types";
import { homologateDescripcionIdentification } from "../helpers/catalogDian";
import { SuccessAlert } from "../helpers/alert.helpers";
import {
  emailUser,
  enterpriseDocumentNumber,
  enterpriseDocumentType,
} from "./authActions";

const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/Empresa`;

/**
 * Consulta el estado actual del certificado de la empresa
 * @returns
 */
export const getCertificateAction = (handleOpenResponseModal) => async (
  dispatch,
  getState
) => {
  try {
    const { certificate } = getState().generalReducer;

    if (!certificate) {
      let config = defaultHeaders();
      Object.assign(config.headers, {
        pTipoDocumento: homologateDescripcionIdentification(
          enterpriseDocumentType()
        ),
        pNumeroDocumento: enterpriseDocumentNumber(),
      });

      const resp = await axiosApiInstance.get(
        `${urlEnterprise}/GetCertificateVP`,
        config
      );

      dispatch({
        type: types.CERTIFICATE_CONSULTED,
        payload: true,
      });

      if (
        resp?.data?.httpCode === 200 &&
        !!resp.data.result?.documentoEmpresa
      ) {
        handleOpenResponseModal(resp.data.result);
      }
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error consultando el certificado de la empresa.",
        error: err,
      },
    });
  }
};

/**
 * Solicita un nuevo certificado digital para la empresa
 * @returns
 */
export const requestCertificateAction = (
  data,
  personType,
  entepriseName,
  setLoadingRequestCertificate,
  handleCloseRequestCertificate
) => async (dispatch) => {
  try {
    setLoadingRequestCertificate(true);

    let body = {
      TipoDocumentoEmpresa: homologateDescripcionIdentification(
        enterpriseDocumentType()
      ),
      DocumentoEmpresa: enterpriseDocumentNumber(),
      RazonSocial: entepriseName,
      TipoPersona: personType,
      TipoDocumentoRepresentante: data.documentType,
      DocumentoRepresentante: data.document,
      Nombre: data.name.trim().replace(/\s+/g, " "),
      Apellido: data.lastname.trim().replace(/\s+/g, " "),
      CorreoElectronico: data.email,
      Telefono: data.phoneNumber,
      CorreoUsuario: emailUser()
    };

    const resp = await axiosApiInstance.post(
      `${urlEnterprise}/RequestNewCertificate`,
      body,
      defaultHeaders()
    );

    if (resp.data.httpCode === 200) {
      SuccessAlert(
        "Buen trabajo!!!",
        "La solicitud de renovación de tu certificado digital se ha realizado exitosamente, recuerda que el representante legal debe ingresar a la plataforma y actualizar la información.",
        TIME_CREATE_REGISTER
      );

      handleCloseRequestCertificate();
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error en la solicitud del certificado de la empresa.",
        error: err,
      },
    });
  } finally {
    setLoadingRequestCertificate(false);
  }
};
