import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

import CategoryCard from "./CategoryComponent";
import CollapsePanel from "../../../components/collapse/collapse.component";
import CreateCategory from "../category/newCategory.viwe";
import InputIcon from "../../../components/input/inputIcon.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import CustomProgress from "../../../components/Progress/progress.component";

import {
  deleteCategoryAction,
  loadCategoryAction,
  getCategoryPagination,
  refreshAfterDeleteAction,
} from "../../../actions/categoryActions";
import { getRoleOfLoggedUser } from "../../../actions/authActions";

import { SuccessAlert, ConfirmAlert } from "../../../helpers/alert.helpers";
//import imageNotification from "../../../../src/images/NotificacionesColfactura.png";
import isEmpty from "../../../utils/isEmpty";
import {
  MESSAGE_DELETE_CONFIRM,
  TIME_UPDATE_REGISTER,
} from "../../../config/config";
import "../article/article.css";

const useStyles = (theme) => ({
  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
  modalConfirm: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paperConfirm: {
    width: 410,
    height: 150,
    backgroundColor: "#252625",
    padding: theme.spacing(2, 4, 3),
  },
  colorIcon: {
    color: "white",
  },
  button: {
    color: "#81F7D8",
  },
  gridList: {
    width: "100%",
    height: "10%",
  },
  TypographyNotification: {
    fontSize: 28,
    fontFamily: "Muli Medium",
    textAling: "center",
  },
  centerImage: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export class CategoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txCategoryName: "",
        txCategoryColor: "",
        txCategoryImage: null,
      },

      categoryListResponse: [],
      deleteCategoryResponse: {},

      editCategoryStatus: false,
      expandPanelCategory: "",

      txtFilter: "",
      txtFilterConfirmed: "",

      skip: 0,
      countCategories: 0,
      loadingGetCategories: false,
    };
  }

  componentDidMount() {
    let data = {
      skip: this.state.skip,
      filter: "",
    };

    this.props.getCategoryPagination(data, true);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.categoryReducer.categoryListResponse &&
      nextProps.categoryReducer.categoryListResponse !==
        state.categoryListResponse
    )
      update.categoryListResponse =
        nextProps.categoryReducer.categoryListResponse;

    if (
      !isEmpty(nextProps.categoryReducer.editCategoryStatus) &&
      nextProps.categoryReducer.editCategoryStatus !== state.editCategoryStatus
    )
      update.editCategoryStatus = nextProps.categoryReducer.editCategoryStatus;

    if (
      !!nextProps.categoryReducer.deleteCategoryResponse &&
      nextProps.categoryReducer.deleteCategoryResponse !==
        state.deleteCategoryResponse
    )
      update.deleteCategoryResponse =
        nextProps.categoryReducer.deleteCategoryResponse;

    if (
      !!nextProps.categoryReducer.saveCategoryResponse &&
      nextProps.categoryReducer.saveCategoryResponse !==
        state.saveCategoryResponse
    )
      update.saveCategoryResponse =
        nextProps.categoryReducer.saveCategoryResponse;

    if (
      !!nextProps.categoryReducer.updateCategoryResponse &&
      nextProps.categoryReducer.updateCategoryResponse !==
        state.updateCategoryResponse
    )
      update.updateCategoryResponse =
        nextProps.categoryReducer.updateCategoryResponse;

    if (
      !isEmpty(nextProps.categoryReducer.loadingGetCategories) &&
      nextProps.categoryReducer.loadingGetCategories !==
        state.loadingGetCategories
    )
      update.loadingGetCategories =
        nextProps.categoryReducer.loadingGetCategories;

    if (
      !!nextProps.categoryReducer.countCategories &&
      nextProps.categoryReducer.countCategories !== state.countCategories
    )
      update.countCategories = nextProps.categoryReducer.countCategories;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.deleteCategoryResponse !== this.state.deleteCategoryResponse
    ) {
      if (this.state.deleteCategoryResponse.statusCode === "200") {
        SuccessAlert(
          null,
          "Se ha eliminado la categoría de manera exitosa, sigamos Facturando",
          TIME_UPDATE_REGISTER
        );
        this.props.refreshAfterDeleteAction(
          this.state.deleteCategoryResponse.id
        );
      }
    }

    if (prevState.saveCategoryResponse !== this.state.saveCategoryResponse) {
      if (this.state.saveCategoryResponse.statusCode === "200") {
        this.setState({
          expandPanelCategory: "",
        });
        this.refreshList();
      }
    }

    if (
      prevState.updateCategoryResponse !== this.state.updateCategoryResponse
    ) {
      if (this.state.updateCategoryResponse.statusCode === "200") {
        this.setState({
          expandPanelCategory: "",
        });
        this.refreshList();
      }
    }
  }

  /**
   * Abre modal para confirmar eliminar
   * categoria, Elimina categoria
   * @param {*} item
   */
  delete(e, item) {
    e.preventDefault();

    const deleteData = (confirmed) => {
      if (confirmed) {
        this.props.deleteCategoryAction(item.id);
      }
    };
    ConfirmAlert(
      `¿Estás seguro que desea eliminar la categoría ${item.nombre}?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  }

  /**
   * Evento, Escucha cambios en filtro para categorias,
   * @param {*} e Evento
   */
  syncChangesFilter(e) {
    e.preventDefault();
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState({
      [id]: value,
    });

    if (value.length === 0) {
      this.refreshList();
    }
  }

  /**
   * Evento, consulta listado de acuerdo a parmetros de busqueda
   */
  searchCategories() {
    let data = {
      filter: this.state.txtFilter,
      skip: 0,
    };
    this.setState({
      txtFilterConfirmed: this.state.txtFilter,
    });
    this.props.getCategoryPagination(data, true);
  }

  /**
   * Carga  información de categoria para edición
   * @param {*} e Evento
   * @param {*} id  Identificador categoria
   */
  onLoadCategory = (e, item) => {
    e.preventDefault();
    this.setState({
      expandPanelCategory: "panel",
    });

    this.props.loadCategoryAction(item);
  };

  /**
   * Evento, carga nuevo lote de categorias
   * en pantalla
   */
  loadMore() {
    let skip = this.state.categoryListResponse.length;
    let data = {
      skip: skip,
      filter: this.state.txtFilterConfirmed,
    };

    //Actualiza filtro confirmado
    this.setState({
      txtFilter: this.state.txtFilterConfirmed,
    });

    this.props.getCategoryPagination(data);
  }

  /**
   * Escucha evento click, collapse formulario gestión categoria
   */
  onClickCollapse = () => {
    this.setState({
      expandPanelCategory:
        this.state.expandPanelCategory === "panel" ? "" : "panel",
    });
  };

  /**
   * Consulta listado de categorias,
   * limpiando filtros y paginado
   */
  refreshList = () => {
    let data = {
      filter: "",
      skip: 0,
    };
    this.setState({
      txtFilter: "",
      txtFilterConfirmed: "",
    });
    this.props.getCategoryPagination(data, true);
  };

  render() {
    return (
      <Fragment>
        <Grid container>
          <Grid item lg={12} xs={12}>
            {getRoleOfLoggedUser() ? (
              <CollapsePanel
                component={<CreateCategory></CreateCategory>}
                title={
                  <LightTooltip
                    title="No tiene permisos sobre esta acción"
                    placement="bottom"
                  >
                    <span>Crear Nueva Categoría</span>
                  </LightTooltip>
                }
                titleOpen={"Crear Nueva Categoría"}
                titleEdit={"Crear Nueva Categoría"}
                open={""}
              />
            ) : (
              <CollapsePanel
                component={<CreateCategory></CreateCategory>}
                title={
                  this.state.editCategoryStatus === false
                    ? "Crear Nueva Categoría"
                    : "Editar Categoría"
                }
                titleOpen={
                  this.state.editCategoryStatus === false
                    ? "Crear Nueva Categoría"
                    : "Editar Categoría"
                }
                open={this.state.expandPanelCategory}
                onClick={this.onClickCollapse}
              />
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={12} xs={12}>
            <InputIcon
              name={"txtFilter"}
              label={"Buscar categoría..."}
              value={this.state.txtFilter}
              onChange={(e) => {
                this.syncChangesFilter(e);
              }}
              onSearch={() => this.searchCategories()}
            />
          </Grid>
        </Grid>

        <Grid container>
          {this.state.loadingGetCategories === true &&
          this.state.categoryListResponse.length === 0 ? (
            <Grid item lg={12} xs={12}>
              <Grid container alignItems="center" justify="center">
                <CustomProgress />
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              {this.state.categoryListResponse.length > 0 ? (
                <Fragment>
                  {this.state.categoryListResponse.map((item, index) => (
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      key={index}
                      style={{ marginTop: 10 }}
                    >
                      <CategoryCard
                        data={item}
                        onLoad={(e) => {
                          this.onLoadCategory(e, item);
                        }}
                        onClick={(e) => this.delete(e, item)}
                      />
                    </Grid>
                  ))}
                </Fragment>
              ) : (
                "No se han encontrado resultados..."
              )}
            </Fragment>
          )}
        </Grid>

        {this.state.categoryListResponse.length > 0 ? (
          <Grid container alignItems="center" justify="center">
            <Grid item lg={4} xs={12}>
              <ButtonPrimary
                text={"Mostrar más..."}
                onClick={() => this.loadMore()}
                loading={
                  this.state.loadingGetCategories === true ? true : false
                }
                disabled={
                  this.state.categoryListResponse.length ===
                  this.state.countCategories
                    ? true
                    : false
                }
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

CategoryView.propTypes = {};

const mapStateToProps = (state) => ({
  categoryReducer: state.categoryReducer,
  articleReducer: state.articleReducer,
});

export default connect(mapStateToProps, {
  deleteCategoryAction,
  loadCategoryAction,
  getCategoryPagination,
  refreshAfterDeleteAction,
})(withStyles(useStyles)(CategoryView));
