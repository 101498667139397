import React, { } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { Grid } from "@material-ui/core";

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import logo from "../../images/wizard/logo.png";
import Step1 from '../../components/wizard/step1'
import Step2 from '../../components/wizard/step2'
import Step3 from '../../components/wizard/step3'
import Step4 from '../../components/wizard/step4'
import Step5 from '../../components/wizard/step5'
import Step6 from '../../components/wizard/step6'
import '../../components/wizard/step.css';
import Typography from "@material-ui/core/Typography";
import imgBtnBack from '../../images/wizard/btnBack.png'
import ButtonWizard from '../../components/button/buttonWizard.component'



const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    width: '100%',
    position: 'fixed',
    marginBottom: 5,
    backgroundColor: 'transparent !important',
    '& .MuiMobileStepper-dots': {

      '& .MuiMobileStepper-dot': {
        backgroundColor: theme.palette.primaryColor,
        marginRight: '1.5vh',
        marginLeft: '1.5vh',
        height: 10,
        width: 10,
      },
      '& .MuiMobileStepper-dotActive': {
        backgroundColor: theme.palette.thirdColor,
        border: '1px solid white'
      }
    }
  },
  btnBack: {
    cursor: "pointer",
    color: theme.palette.primaryColor,
    fontSize: 16,
    letterSpace: 0,
    width: "7vw",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 10,
    textTransform: 'none'

  },
  arrowBackIcon: {
    marginRight: 15,
    color: "#16B1F3",
  },

  body: {
    textAlign: 'center'
  },
  footer: {
    textAlign: 'center',
  },
  logo: {
    marginTop: '2vh',
    marginBottom: '1vh',
    marginLeft: '20% !important'
  },



}));



const Steps = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);



  const handleNext = () => {
    setActiveStep(prev => prev + 1);
  }

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  }


  const tutorialSteps = [
    <Step1/>,
    <Step2 />,
    <Step3 />,
    <Step4 />,
    <Step5
      setActiveStep={handleNext}
      setActionSetBack={handleBack}
    />,
    <Step6
      setActionSetBack={handleBack} />
  ];


  const maxSteps = tutorialSteps.length;


  const btnOmitir = () => {
    setActiveStep(prevActiveStep => 3);
  }

  return (
    <div className="conntentStep" >

      <Grid container>
        <Grid item lg={12} xs={12}>
          <Grid container>
            <Grid item lg={10} xs={10} style={{ textAlign: 'center' }}>
              {activeStep !== 4 && activeStep !== 5 ?
                <img alt='' src={logo} className={classes.logo}></img> :
                ''}
            </Grid>
            <Grid item lg={2} xs={2}>
              {
                (activeStep !== 3 && activeStep !== 4 && activeStep !== 5) &&
                <Typography className="omitirStep" onClick={btnOmitir}>
                  Omitir este paso
                </Typography>
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} xs={12} className={classes.body}>
          {tutorialSteps[activeStep]}
        </Grid>

        <Grid item lg={12} xs={12} className={classes.footer}>
          <Grid container >
            <Grid item lg={5} xs={3} style={{ textAlign: 'initial' }}>
              {
                (activeStep !== 0 && activeStep !== 4 && activeStep !== 5) &&
                  <Button className={classes.btnBack} onClick={handleBack} disabled={activeStep === 0}><img alt='' src={imgBtnBack}></img>Atras</Button> 
              }
            </Grid>
            <Grid item lg={2} xs={6}>
              {activeStep !== 4 && activeStep !== 5 ?
                <ButtonWizard
                  className={classes.nextButton}
                  text={'Siguiente'}
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                /> :
                ''
              }
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          </Button>
        }
      />
    </div>

  );
}



const mapStateToProps = (state) => ({
  generalReducer: state.generalReducer,

});

export default connect(mapStateToProps, {})(Steps);



