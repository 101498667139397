import React, { memo } from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { CartList } from "./cartList";
import { CartDocEmpty } from "./CartDocEmpty";
import { SectionCart } from "./documentCartSections";

const useStyles = makeStyles((theme) => ({
  containerSection: {
    overflow: "auto",
    height: "65.2vh",
    width: "100%",
    overflowX: "hidden",
  },
  containerList: {
    backgroundColor: "#4A4A4A",
    borderRadius: 5,
  },
  containerData: {
    padding: "0px 15px 0px 0px",
  },
}));

export const DocumentCartComponent = memo(
  ({ opdetalledocumento, sections, showSections, typedocument }) => {
    const classes = useStyles();

    return (
      <>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xl={7} lg={8} xs={8}>
            <div
              className={`${classes.containerList} ${classes.containerSection}`}
            >
              {opdetalledocumento.length === 0 ? (
                <CartDocEmpty typedocument={typedocument} />
              ) : (
                <CartList typedocument={typedocument} />
              )}
            </div>
          </Grid>
          <Grid item xl={4} lg={4} xs={4}>
            <div
              className={`${classes.containerData} ${classes.containerSection}`}
            >
              <Grid container spacing={1}>
                {showSections.map((sectionId) => (
                  <SectionCart
                    key={sectionId}
                    sectionId={sectionId}
                    sections={sections}
                  />
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
);
