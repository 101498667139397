import React from "react";

import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import { ValueInfoCard } from "./";
import { FormatDecimal } from "../../../../../components/common/formatDecimal";

export const BoxAdditionalInformation = () => {
  const { boxLocation, boxPlate, boxType, cashier, saleCode, subtotal } = useSelector(
    (state) => state.equivalentDocReducer.additionalinfoBoxEquivalent,
    (prev, next) => prev === next
  );

  /**
   * * Datos adicionales para mostrar en una caja de información adicional.
   * @property {string} title - El título del dato.
   * @property {string|JSX.Element} value - El valor del dato.
   * @property {number} [md] - El tamaño del grid en dispositivos de tamaño medio (md) (opcional).
   * @property {number} [lg] - El tamaño del grid en dispositivos de tamaño grande (lg) (opcional).
   */
  const additionalinfoBoxData = [
    {
      title: "Placa caja",
      value: boxPlate,
    },
    {
      title: "Ubicación caja",
      value: boxLocation,
      md: 4,
      lg: 4,
    },
    {
      title: "Cajero",
      value: cashier,
      md: 4,
      lg: 4,
    },
    {
      title: "Tipo caja",
      value: boxType,
      md: 4,
      lg: 4,
    },
    {
      title: "Código venta",
      value: saleCode,
      md: 6,
      lg: 6,
    },
    {
      title: "Subtotal",
      value: (
        <div style={{ color: " #2CC63E", fontWeight: 600 }}>
          <FormatDecimal value={subtotal} prefix={"$"} />
        </div>
      ),
      md: 6,
      lg: 6,
    },
  ];
  return (
    <div>
      <Grid
        container
        style={{
          background: "#5E5E5E",
          padding: "1.5%",
          borderRadius: "5px",
        }}
      >
        {additionalinfoBoxData.map((infoData, index) => infoData.show !== false && <ValueInfoCard key={infoData.title} {...infoData} />)}
      </Grid>
    </div>
  );
};
