import React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import { SelectRetentionSection } from "./";
import { ReteFuenteCard } from "../../withholdings/reteFuenteCard";

const useStyles = makeStyles((theme) => ({
  commonMargin: {
    marginTop: 15,
  },
}));

export const ItemRetentionComponent = ({
  handleEditRteFuente,
  handleOpenModalRteFuente,
  isGift,
  itemDoc,
  retfte,
  typedocument,
}) => {
  const classes = useStyles();

  return (
    !isGift && (
      <Grid container className={classes.commonMargin}>
        {retfte === null ? (
          <SelectRetentionSection
            handleOpenModalRteFuente={handleOpenModalRteFuente}
            typedocument={typedocument}
          />
        ) : (
          <ReteFuenteCard
            data={itemDoc}
            onEdit={() => handleEditRteFuente(itemDoc)}
            typedocument={typedocument}
          />
        )}
      </Grid>
    )
  );
};
