import React, { Fragment, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import SimpleReactValidator from "simple-react-validator";

import { Card } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import ButtonPrimary from "../../components/button/buttonPrimary.component";
import ButtonSecundary from "../../components/button/buttonSecundary.component";
import DatePickerForm from "../../components/datepicker/datepicker.component";

import { useFormComplete } from "../../hooks/useFormComplete";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    backgroundColor: "rgba(255,255,255,0.11)",
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  header: {
    alignItems: "normal",
    paddingBottom: 0,
  },
  headerContent: {
    textOverflow: "ellipsis",
    minHeight: 130,
  },
  question: {
    height: "52px",
    width: "418px",
    color: "#16B1F3",
    fontFamily: "Muli",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "26px",
  },
  answer: {
    height: "63px",
    width: "418px",
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "14px",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "20px",
  },
  icon: {
    color: "#16B1F3",
    fontFamily: "Muli",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "18px",
  },
  actions: {
    justifyContent: "flex-end",
    padding: "unset",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: 33,
    },
  },
  dialog: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    "& .MuiPaper-root": {
      backgroundColor: "#393939",
      color: theme.palette.primaryColor,
    },
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 17,
  },
  title: {
    color: theme.palette.thirdColor,
  },
  containerActions: {
    justifyContent: "end",
    alignItems: "center",
  },
}));

/**
 * Fecha inicial por defecto
 * @returns
 */
const getStartDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
};

export const CountReport = ({
  title,
  subtitle,
  reportTitle,
  action,
  ExcelComponent}) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [, forceUpdate] = useState();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
    
  const { values, handlePickerChange, reset } = useFormComplete({
    dateFrom: getStartDate(),
    dateTo: new Date(),
  });

  const { dateFrom, dateTo } = values;

  const excelRef = useRef(null);

  /**
   * Consulta información para generación de reporte
   */
  const getDataReport = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
    } else {
      dispatch(
        action(
          format(dateFrom, "yyyy/MM/dd"),
          format(dateTo, "yyyy/MM/dd"),
          completeDonwload,
          changeLoadingStatus
        )
      );
    }
  };

  /**
   * Actualiza estado loading
   * @param {*} status nueva estado loading
   */
  const changeLoadingStatus = (status) => {
    setLoading(status);
  };

  /**
   * Dispara click, para descarga de excel
   * despues de consultar información de reporte
   * @param {Array<object>} data Informe 
   */
  const completeDonwload = (data) => {
    setData(data);
    excelRef.current.click();
  };

  /**
   * Cierra modal para descarga informe
   */
  const handleClose = () => {
    if (!loading) {
      setOpenModal(false);
      reset();
    }
  };

  /**
   * Abre modal para descarga informe
   */
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const getMinDate = () => {
    const date = new Date(dateFrom);
    return date.setDate(date.getDate() + 1);
  };

  const getMaxDate = () => {
    const date = new Date(dateFrom);
    return date.setDate(date.getDate() + 30);
  };

  return (
    <Fragment>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          classes={{
            title: classes.question,
            content: classes.headerContent,
          }}
          title={
            <Fragment>
              <Typography variant="body1">
                <Fragment>
                  <span className={classes.question}>
                    {title}
                    <br />
                    <br />
                  </span>
                </Fragment>
                <Fragment>
                  <span className={classes.answer}>
                    {subtitle}
                    <br />
                  </span>
                </Fragment>
              </Typography>
            </Fragment>
          }
        />
        <CardActions className={classes.actions}>
          <IconButton className={classes.expandIcon} onClick={handleOpenModal}>
            <Typography variant="caption" className={classes.icon}>
              Descargar
            </Typography>
            <ExpandMore className={clsx(classes.expand, {}, classes.icon)} />
          </IconButton>
        </CardActions>

        <div>
          <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">
              <span className={classes.title}>{title}</span>
            </DialogTitle>
            <DialogContent className={classes.backgroundContent}>
              <DialogContentText>
                <span className={classes.subTitle}>{subtitle}</span>
              </DialogContentText>
              {/* Container que captura una fecha inicio y una fecha fin
               para generar reportes mas completos */}
              <Grid container alignItems="center">
                <Grid item lg={12} xs={12}>
                  <Grid container alignItems="start">
                    <Grid item lg={6} xs={6}>
                      <DatePickerForm
                        name={"dateFrom"}
                        label={"Desde *"}
                        value={dateFrom}
                        format={"dd MMM yyyy"}
                        onChange={handlePickerChange}
                        openTo={"date"}
                        validator={validator}
                        validateOptions={"required"}
                        views={["year", "month", "date"]}
                      />
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <DatePickerForm
                        name={"dateTo"}
                        label={"Hasta *"}
                        value={dateTo}
                        format={"dd MMM yyyy"}
                        onChange={handlePickerChange}
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        validator={validator}
                        validateOptions={[
                          "required",
                          { dateNotLessThan: dateFrom },
                          { dateNotMoreThan: [30, dateFrom] },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="backgroundContent">
              <Grid container className={classes.containerActions}>
                <Grid item xs={4} lg={4}>
                  <ButtonSecundary text={"Cancelar"} onClick={handleClose} />
                </Grid>
                <Grid item lg={4} xs={4}>
                  <ButtonPrimary
                    text={"Descargar"}
                    loading={loading}
                    type={"button"}
                    onClick={getDataReport}
                  />
                </Grid>
              </Grid>

              <ExcelComponent
                element={<button ref={excelRef} style={{ display: "none" }} />}
                filename={`${reportTitle}${!!dateFrom ? format(dateFrom, "dd/MM/yyyy") : ""
                  }-${!!dateTo ? format(dateTo, "dd/MM/yyyy") : ""}`}
                data={data}
              />
            </DialogActions>
          </Dialog>
        </div>
      </Card>
    </Fragment>
  );
};