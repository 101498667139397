import React from 'react';
import ReactExport from "react-data-export";

import { Box, Grid, makeStyles } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        backgroundcolor: "red",
    },
    paper: {
        maxHeight: 500,
        backgroundColor: "#393939",
        border: "none",
        borderRadius: "5px",
        overflowY: "auto",
        overflowX: "hidden",
        color: "rgba(216,216,216,0.5)",
        outline: 0,
    },
    content: {
        paddingTop: 0,
        paddingLeft: 58,
    },
    title: {
        color: theme.palette.thirdColor,
        fontSize: 25,
        fontWeight: "bold",
        textAlign: "center",
    },
    blueColor: {
        color: theme.palette.thirdColor,
        fontSize: 16,
    },
    yellowColor: {
        color: "#f5d13d",
        fontSize: 16,
    },
    contentText: {
        color: "white",
        fontsize: "5",
        textAlign: "left",
        padding: "10px 80px",
    },
    contentTextCarga: {
        color: "white",
        fontsize: "5",
        textAlign: "center",
    },
    buttonSuccess: {
        height: "54px",
        width: "100%",
        borderRadius: "4px",
        backgroundColor: "#16B1F3",
        border: "#16B1F3",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "0",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: blue[800],
        },
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.thirdColor,
    },
    userDevices: {
        textAlign: "left",
        color: "#FFFFFF",
        padding: "0px",
    },
    containerErrors: {
        fontSize: "10px",
        backgroundColor: "#3e3e3e",
        textAlign: "center",
        borderRadius: '10px',
        padding: "12px",
        height: '150px',
        overflow: 'auto',
        width: '90%'
    },
    table: {
        width: "100%"
    },
    titleTable: {
        color: "#f5d13d",
    },
    growth: {
        overflowY: 'scroll'
    },

}));


const DialogTitle = ({ children, onClose }) => {
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography>
            <Typography variant="h6">{children}</Typography>
            {
                onClose
                    ? (
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                    : null
            }
        </MuiDialogTitle>
    );
};

const ErrorTable = ({
    errors
}) => {
    const classes = useStyles();
    return (
        <div className={classes.containerErrors} id="divToPrint">
            <table className={classes.table}>
                <thead>
                    <tr className={(classes.userDevices, classes.titleTable)}>
                        <th style={{ width: '10%' }}><Box pr={12}>Fila</Box></th>
                        <th style={{ width: '30%', textAlign: 'justify' }}><Box pr={12}>Columna</Box></th>
                        <th style={{ width: '60%', textAlign: 'justify' }}><Box>Corrección</Box></th>
                    </tr>
                </thead>
                <tbody>
                    {errors ? (
                        errors.map((error, index) => (
                            <tr key={index} className={classes.userDevices}>
                                <td>{error.line}</td>
                                <td>{error.key}</td>
                                <td>{error.message}</td>
                            </tr>
                        ))
                    ) : (
                        <tr className={classes.userDevices}>
                            <td colSpan={3}>No se encontraron errores</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}



export const ResultLoadModal = ({
    handleClose,
    open,
    errorList = [],
    recordsError = 0,
    recordsOk = 0,
    multiDataSet = [],
    quantityCustomer = 0,

}) => {
    const classes = useStyles();
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            className={`${classes.root} noOverFlowY`}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <center>
                <div className={classes.paper}>
                    <DialogTitle id="form-dialog-title" onClose={handleClose}>
                        <span className={classes.title}>Resultado del Cargue</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div className={classes.contentTextCarga}>
                                {
                                    recordsError === 0
                                    && <p>
                                        Se cargaron <span className={classes.blueColor}>{quantityCustomer}</span> registro(s) exitosamente.
                                       </p>
                                }

                                {
                                    recordsError > 0 &&
                                    <div className={classes.contentTextCarga}>
                                        <p>Se cargaron <span className={classes.blueColor}>{recordsOk}</span> registro(s) exitosamente.</p>
                                        <p>
                                            No fue posible cargar <span className={classes.yellowColor}>{recordsError} </span>registro(s), por favor <br></br>{" "}
                                            corrigelos y subelos nuevamente.
                                        </p>
                                        <ErrorTable
                                            errors={errorList}
                                        />
                                        <ExcelFile element={
                                            <Link href="#" onc underline="always"  >
                                                Descargar informe de registros erroneos
                                            </Link>}>
                                            <ExcelSheet dataSet={multiDataSet} name="Organization" />
                                        </ExcelFile>
                                    </div>
                                }
                            </div>
                        </DialogContentText>
                        <center>
                            <div style={{ color: "#FFFFFF" }}>
                                <Grid container>
                                    <Grid item lg={4} md={6} sm={12} xs={12}></Grid>
                                    <Grid item lg={4} md={6} sm={12} xs={12}>
                                        <div>
                                            <button
                                                className={classes.buttonSuccess}
                                                type="submit"
                                                onClick={handleClose}>
                                                Finalizar
                                            </button>
                                        </div>
                                    </Grid>
                                    <Grid item lg={4} md={6} sm={12} xs={12}></Grid>
                                </Grid>
                                <br></br>
                            </div>
                        </center>
                    </DialogContent>
                </div>
            </center>
        </Dialog>
    )
}
