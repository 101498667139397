import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SimpleModal from "../../../components/modal/simpleModal.component";
import { formatDate } from "../../../utils/general";
import CustomCardAditionalData from "../../invoice/aditional-data-card.component";
import ModalFileForm from "../../../views/invoice/modal-file-form.component";

const useStyles = makeStyles({
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  letter: {
    color: "#FFFFFF",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  widthMax: {
    width: "100%",
  },
  paddingIcons: {
    paddingTop: "20%",
  },
  width99: {
    width: "99%",
  },
  width98: {
    width: "98%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  width97: {
    width: "97%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  width90: {
    width: "90%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  letterTaxes: {
    color: "#2CC63E",
  },
  totalInvoiceDetail: {
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "18px",
    fontWeight: "800",
    letterSpacing: "0",
    textAlign: "left",
  },
  colorIcon: {
    color: "#2CC63E",
  },
  h1Card: {
    justifyContent: "center",
    textAlign: "center",
    color: "white",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  containerLoading: {
    paddingTop: 200,
  },
});

const DetailGetInfoAditional = (handleShowDetailNote, setmodalError) => {
  const classes = useStyles();
  const [document, setDocument] = useState({});
  const [email, setEmail] = useState("");
  const [mailData, setMailData] = useState({});
  const [openModalMail, setOpenModalMail] = useState(false);

  const {
    detailDocumentSupport,
    loadingGetNoteIdDs,
    documentSupportFilter,
  } = useSelector((state) => state.documentSupportReducer);

  const documentsupport = documentSupportFilter.find(
    (item) => item.id === detailDocumentSupport.id
  );

  useEffect(() => {
    if (detailDocumentSupport) {
      setDocument(detailDocumentSupport);
    }
  }, [detailDocumentSupport]);

  /**
   *  * Cierra modal reenvio documento
   */
  const handleCloseModalMail = () => {
    setOpenModalMail(false);
  };

  let notas = document.opdocumentonotas;

  /**
   * Abre notificción reenvio de documento
   * @param {*} email
   * @param {*} isInvoiceMail
   * @param {*} tipodocumentoentidad
   * @param {*} documentompresa
   * @param {*} codigounico
   * @param {*} numero
   * @param {*} razonsocialempresa
   * @param {*} razonsocialcliente
   */
  const OpenMailModal = (
    email,
    isInvoiceMail,
    tipodocumentoentidad,
    documentompresa,
    codigounico,
    numero,
    razonsocialempresa,
    razonsocialcliente,
    tipoModalidad,
    documentoid,
    data
  ) => {
    if (tipoModalidad === 3 || tipoModalidad === 2) {
      setmodalError(1);
    } else {
      setEmail(data.correoelectronico);
      setMailData({
        TipoDocumento: "31",
        Documento: documentompresa,
        CodigoUnico: null,
        NumeroDocumento: numero,
        RazonSocialEmpresa: razonsocialempresa,
        RazonSocialCliente: data.razonsocial,
        DocumentoId: data.id,
      });
      setOpenModalMail(true);
    }
  };

  /**
   * Modal filtro reenvio documento
   */
  const modalMails = () => {
    return (
      <ModalFileForm
        email={email}
        isInvoiceMail={false}
        isQuotationMail={false}
        isDocSupportMail={true}
        data={mailData}
        type={"DOC"}
      />
    );
  };

  return (
    <div>
      <div className="cardDetailInfoAditional">
        <Grid container>
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Fecha Documento{" "}
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Forma de Pago
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document?.fecha !== null && document?.fecha !== undefined
              ? formatDate(new Date(document.fecha), "dd/MM/yyyy")
              : "-"}
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document?.dianFormaPagoId === 1 ? "Contado" : "Crédito"}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>
        <Grid container className="fieldsReadContainer">
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Medio Pago
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Fecha de Pago
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document.descMedioPago === null
              ? "Sin Datos"
              : document.descMedioPago}
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document?.fechaPago !== null && document?.fechaPago !== undefined
              ? formatDate(new Date(document.fechaPago), "dd/MM/yyyy")
              : "-"}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>

        {/* Nuevos datos
      <Grid container className="fieldsReadContainer">
        <Grid item lg={4} sm={12} className="fieldsReadMini">
          Aceptada/Rechazada
        </Grid>
        <Grid item lg={4} sm={12} className="fieldsReadMini">
          Fecha Aceptación/Rechazo
        </Grid>
        <Grid item lg={4} sm={12} className="fieldsReadMini">
          Fecha validación DIAN
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} sm={12} className="fieldsReadBig">
          {document?.descripcionestado}
        </Grid>
        <Grid item lg={4} sm={12} className="fieldsReadBig">
          {document?.fechaacusecliente !== null &&
          document?.fechaacusecliente !== undefined
            ? formatDate(
                new Date(document.fechaacusecliente),
                "dd/MM/yyyy hh:mm aaaa"
              )
            : "-"}
        </Grid>
        <Grid item lg={4} sm={12} className="fieldsReadBig">
          {document?.fechaacusedian !== null &&
          document?.fechaacusedian !== undefined
            ? formatDate(
                new Date(document.fechaacusedian),
                "dd/MM/yyyy hh:mm aaaa"
              )
            : "-"}
        </Grid>
      </Grid> */}
        {/* <Grid container>
        <Grid item lg={4} sm={12}>
          <div className={classes.width97}></div>
        </Grid>
        <Grid item lg={4} sm={12}>
          <div className={classes.width97}></div>
        </Grid>
        <Grid item lg={4} sm={12}>
          <div className={classes.width97}></div>
        </Grid>
      </Grid> */}
        {/* <Grid container className="fieldsReadContainer">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          className="fieldsReadMini"
          style={{ width: "96%" }}
        >
          Concepto rechazo cliente
        </Grid>
      </Grid> */}

        {/* <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          className="fieldsReadBig"
          style={{ wordBreak: "break-word" }}
        >
          {"-"}{" "}
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>
      </Grid> */}

        <Grid container className="fieldsReadContainer">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="fieldsReadMini"
            style={{ width: "96%" }}
          >
            Observaciones
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="fieldsReadBig"
            style={{ wordBreak: "break-word" }}
          >
            {document.observacion || "-"}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12}></Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>
      </div>
      <div className="spacingInvoice"></div>
      {/* <div className="titleAdicitional">Cargos</div>
    <div className="spacingInvoice"></div>
    <Grid container spacing={1}>
      {cargos !== undefined
        ? cargos !== null
          ? cargos.map((data, index) => (
              <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                <CustomCardAditionalData
                  data={data}
                  type="charges"
                  onClick=""
                />
              </Grid>
            ))
          : ""
        : ""}
    </Grid>
    <div className="spacingInvoice"></div> */}
      {/* <div className="titleAdicitional">Descuentos sobre el total</div>
    <div className="spacingInvoice"></div>
    <Grid container spacing={1}>
      {descuentos !== undefined
        ? descuentos !== null
          ? descuentos.map((data, index) => (
              <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                <CustomCardAditionalData
                  data={data}
                  type="discounts"
                  tooltipMessage={
                    data.modelodescuento === 1 ? (
                      <TooltipMessage
                        title={getComponentName(
                          menuComponent,
                          93,
                          157,
                          "¿Cómo afecta la factura este descuento?"
                        )}
                        message={getComponentName(
                          menuComponent,
                          93,
                          158,
                          "Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura."
                        )}
                        botton={getComponentName(
                          menuComponent,
                          93,
                          159,
                          "Más información"
                        )}
                        href={getComponentUrl(menuComponent, 93, 150, null)}
                      />
                    ) : (
                      <TooltipMessage
                        title={getComponentName(
                          menuComponent,
                          94,
                          160,
                          "¿Cómo afecta la factura este descuento?"
                        )}
                        message={getComponentName(
                          menuComponent,
                          94,
                          161,
                          "Es un descuento que afecta el valor total a pagar pero no el valor total de la factura."
                        )}
                        botton={getComponentName(
                          menuComponent,
                          94,
                          162,
                          "Más información"
                        )}
                        href={getComponentUrl(menuComponent, 94, 162, null)}
                      />
                    )
                  }
                  onClick=""
                />
              </Grid>
            ))
          : ""
        : ""}
    </Grid>*/}

      <div className="spacingInvoice"></div>
      <div className="titleAdicitional">Notas</div>
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        {notas !== undefined
          ? notas !== null
            ? notas.map((data, index) => (
                <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                  <CustomCardAditionalData
                    data={data}
                    type="notes"
                    diantipomodalidadid={documentsupport?.tipoestadodocumentoid}
                    tipodocumentoentidad={"31"}
                    documentompresa={detailDocumentSupport.documentompresa}
                    razonsocialempresa={
                      detailDocumentSupport.razonSocialEmpresa
                    }
                    // openFileModal={OpenFileModal}
                    openMailModal={OpenMailModal}
                    // handleOpenModalWarning={handleOpenModalWarning}
                    handleShowDetailNote={handleShowDetailNote}
                    // onClick={notificationNote}
                    loadingGetNoteId={loadingGetNoteIdDs}
                  />
                </Grid>
              ))
            : ""
          : ""}
      </Grid>

      <div className="spacingInvoice"></div>
      {/* <div className="titleAdicitional">Documentos de referencia</div>
    <div className="spacingInvoice"></div>
    <Grid container spacing={1}>
      {documentosreferencia.map((data, index) => (
        <Grid item key={index} lg={12} xs={12}>
          <CustomCardAditionalData data={data} type="docref" />
        </Grid>
      ))}
    </Grid> */}
      <SimpleModal
        onClose={handleCloseModalMail}
        open={openModalMail}
        component={modalMails}
        title={"Envio de Documento Soporte"}
        width={365}
        showCloseButton={false}
      />
    </div>
  );
};

export default DetailGetInfoAditional;
