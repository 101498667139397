import React from "react";

export const SectionCart = ({ sectionId, sections }) => {
  const section = sections[sectionId];

  if (!section || !section.component) {
    return null;
  }

  const Component = section.component;

  return <Component key={sectionId} {...section.props} />;
};
