import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid, IconButton } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import FormControl from "@material-ui/core/FormControl";
import { Tooltip } from "@material-ui/core";

import InputIcon from "../../../components/input/inputIcon.component";
import GrandList from "../component/GrandList/GrandList.component";
import CheckboxList from "../../../components/list/checkBoxList.component";
import CardList from "./cardArticle.component";
import CollapsePrimary from "../../../components/collapse/collapsePrimary.component";
import CreateArticle from "./createArticle.component";
import SelectFilter from "../component/selectFilter.component";
import BottonThird from "../../../components/button/buttonThird.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import CustomProgress from "../../../components/Progress/progress.component";
import SimpleModal from "../../../components/modal/simpleModal.component";
import DetailArticle from "../article/detailArticle.component";
import ResponseModal from "../../../components/modal/responseModal.component";

import {
  deleteArticleAction,
  loadArticleAction,
  getArticleListAction,
  cleanArticleListAction,
  clearArticleListAction,
  filterArticleListAction,
  orderArticleListAction,
  setArticleCheckedAction,
  getArticleCheckedAction,
  cleanArticleCheckedAction,
} from "../../../actions/articleActions";
import {
  setStatusShowCartAction,
  addArticlesToCartAction,
} from "../../../actions/valuesInvoiceAction";
import { checkCertificateCompanyAction } from "../../../actions/basicDataActions";
import { changeShowInvoiceCreationAction } from "../../../actions/invoiceActions";
import {
  changeShowCartAction,
  loadItemsCheckedInCartAction,
} from "../../../actions/documentCartAction";

import { WarningAlert, ConfirmAlert } from "../../../helpers/alert.helpers";
import isEmpty from "../../../utils/isEmpty";
import { getRoleOfLoggedUser } from "../../../actions/authActions";
import { MESSAGE_DELETE_CONFIRM, READING_MODE } from "../../../config/config";
import { TooltipMessageReadingMode } from "../../../components/tooltip/TooltipMessageReadingMode";

const optionsFilter = [
  { value: 1, text: "Menor precio" },
  { value: 2, text: "Mayor precio" },
  { value: 3, text: "A-Z" },
  { value: 4, text: "Z-A" },
  { value: 5, text: "Lo más reciente" },
];

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    minWidth: 800,
  },
  paper: {
    width: 600,
    height: 700,
    backgroundColor: "#252625",
    padding: theme.spacing(2, 4, 3),
  },
  buttonIcon: {
    color: "white",
    fontSize: 30,
  },
  buttonIconSearch: {
    color: "#D8D8D8",
  },

  modalConfirm: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },

  paperConfirm: {
    width: 410,
    height: 150,
    backgroundColor: "#252625",
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    color: "#81F7D8",
  },
  filter: {
    height: 30,
    color: "white",
  },
  closeModal: {
    left: 510,
    color: "#00BFFF",
  },
  divImage: {
    width: 200,
    height: 200,
  },

  colorIcon: {
    color: "white",
  },

  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
});
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2F2E2E",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 280,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

class ArticleView extends Component {
  // sin export
  constructor(props) {
    super(props);
    this.state = {
      txtFilter: "",
      expandPanelArticle: "",
      modalDeleteArticle: false,
      editArticleStatus: false,
      updateFavoriteTransaction: null,
      categories: [],
      checkedCategories: [],
      articleDetailObject: {},
      skip: 0,
      typeView: "",
      getArticlesResponse: [],
      loadingGetArticles: false,
      getArticleListResponse: [],
      totalArticles: 0,
      filterTextConfirmed: "",
      ddlOrderBy: "5",
      presentationType: "List",
      openModalItem: false,
      checkedArticles: [],
      loadingUpdateArticle: false,
      loadingGoInvoice: false,
      modalData: {
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
      },
    };
  }

  componentDidMount() {
    let filterData = {
      skip: this.state.skip,
      filter: "",
      type: this.props.type,
      categories: [],
      typeOrder: 5,
    };

    let initialRequest = this.state.checkedArticles.length === 0;

    this.props.cleanArticleListAction();

    this.props.getArticleListAction(filterData, true, initialRequest);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.articleReducer.getArticleListResponse &&
      nextProps.articleReducer.getArticleListResponse !==
        state.getArticleListResponse
    )
      update.getArticleListResponse =
        nextProps.articleReducer.getArticleListResponse;

    if (nextProps?.articleReducer.editArticleStatus !== state.editArticleStatus)
      update.editArticleStatus = nextProps.articleReducer.editArticleStatus;

    if (
      !!nextProps.articleReducer.updateFavoriteTransaction &&
      nextProps.articleReducer.updateFavoriteTransaction !==
        state.updateFavoriteTransaction
    ) {
      update.updateFavoriteTransaction =
        nextProps.articleReducer.updateFavoriteTransaction;
      update.articleDetailObject = {
        ...state.articleDetailObject,
        favorito: !state.articleDetailObject.favorito,
      };
    }

    if (!!nextProps.typeView && nextProps.typeView !== state.typeView)
      update.typeView = nextProps.typeView;

    if (
      !isEmpty(nextProps.articleReducer.loadingGetArticles) &&
      nextProps.articleReducer.loadingGetArticles !== state.loadingGetArticles
    )
      update.loadingGetArticles = nextProps.articleReducer.loadingGetArticles;

    if (
      !isEmpty(nextProps.articleReducer.totalArticles) &&
      nextProps.articleReducer.totalArticles !== state.totalArticles
    )
      update.totalArticles = nextProps.articleReducer.totalArticles;

    if (
      !!nextProps.articleReducer.checkedArticles &&
      nextProps.articleReducer.checkedArticles !== state.checkedArticles
    )
      update.checkedArticles = nextProps.articleReducer.checkedArticles;

    if (
      !isEmpty(nextProps.articleReducer.loadingUpdateArticle) &&
      nextProps.articleReducer.loadingUpdateArticle !==
        state.loadingUpdateArticle
    )
      update.loadingUpdateArticle =
        nextProps.articleReducer.loadingUpdateArticle;

    if (
      !!nextProps.categoryReducer.getCategories &&
      nextProps.categoryReducer.getCategories !== state.categories
    ) {
      let newArray = nextProps.categoryReducer.getCategories;
      if (newArray.find((c) => c.id === 0) === undefined) {
        newArray.push({
          id: 0,
          color: "gray",
          nombre: "Sin categoría",
        });
      }

      update.categories = newArray;
    }

    return Object.keys(update).length ? update : null;
  }

  /**
   * Sincroniza inputs con state de componente
   * @param {object} e
   */
  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState({
      [id]: value,
    });
  }

  /**
   * Sincroniza información de CheckBox Articulo,
   * agrega objecto articulo a listado (getArticleCheckedAction) de articulos seleccionados
   * @param {*} event
   * @param {*} item
   */
  syncCheckChange(event, item) {
    let checked = event.target.checked;
    item = {
      ...item,
      dateAdded: Date.now(),
    };
    this.props.setArticleCheckedAction(item, checked);

    let filterData = {
      skip: this.state.skip,
      filter: this.state.filterTextConfirmed,
      type: this.props.type,
      categories: this.state.checkedCategories,
      typeOrder: parseInt(this.state.ddlOrderBy),
    };

    this.props.getArticleListAction(filterData, false, false);
  }

  /**
   * Evento click, Carga articulos seleccionados en Carrito
   * @param {*} item
   */
  addItemsToCart() {
    if (this.state.checkedArticles.length === 0) {
      WarningAlert("Upss...!!!", "Aun no has seleccionado articulos.");
      return;
    }

    if (this.state.typeView !== "invoice") {
      this.props.checkCertificateCompanyAction(
        this.checkCertificateToCreateInvoice,
        this.loadingRedirectInvoice
      );
    } else {
      this.loadItemsAndGoCart();
    }
  }

  /**
   * Carga items seleccionados en memoria y muestra
   * carrito de compras
   */
  loadItemsAndGoCart = () => {
    //Limpia lista de articulos seleccionados en Redux
    this.props.cleanArticleCheckedAction();
    //Carga items en Redux
    this.props.loadItemsCheckedInCartAction(this.state.checkedArticles);
    //Ocultar catalogo
    this.props.changeShowCartAction(false);
  };

  /**
   * Valida si la empresa tiene un certificado valido para facturar antes de crear una factura
   * @param {object} data Información del certificado
   */
  checkCertificateToCreateInvoice = (data) => {
    if (data.requiere === true && data.activo === false) {
      this.setState({
        modalData: {
          type: "warning",
          title: "Upss...!!!",
          subtitle: `Esta opción aún no está disponible.`,
          body: (
            <div>
              {
                "Todavía no cuentas con un certificado de firma digital vigente."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        },
      });
      //Limpia lista de articulos seleccionados en Redux
      this.props.cleanArticleCheckedAction();

      //Consultar listado inicial Articulos
      let filterData = {
        skip: this.state.skip,
        filter: "",
        type: this.props.type,
        categories: [],
        typeOrder: 5,
      };

      this.props.getArticleListAction(filterData, true, true);
    } else {
      //Carga items en Redux
      this.props.loadItemsCheckedInCartAction(this.state.checkedArticles);

      //Ocultar catalogo
      this.props.changeShowCartAction(false);

      //Carga estado en redux, visualizacion modulo nueva factura
      this.props.changeShowInvoiceCreationAction(true);

      //Limpia lista de articulos seleccionados en Redux
      this.props.cleanArticleCheckedAction();

      //Redireccionar, Crear nueva factura
      this.props.history.push("/invoice");
    }
  };

  /**
   * Actualiza estado loading crear nueva factura.
   * @param {boolean} status Nuevo estado
   */
  loadingRedirectInvoice = (status) => {
    this.setState({
      loadingGoInvoice: status,
    });
  };

  /**
   * Consulta listado de articulos por parametros de busqueda
   * @event
   */
  onSearch = () => {
    //Limpia lista actual
    this.props.clearArticleListAction();

    let filterData = {
      skip: 0,
      filter: this.state.txtFilter,
      type: this.props.type,
      categories: this.state.checkedCategories,
      typeOrder: parseInt(this.state.ddlOrderBy),
    };

    //Actualiza filtro confirmado
    this.setState({
      filterTextConfirmed: this.state.txtFilter,
    });

    this.props.filterArticleListAction(filterData);
  };

  /**
   * Evento click, Invoca action para renderixado de carrito de compras.
   * Setea estado para ver panel detalle factura, carrito de compras
   * @param {object} e
   */
  goToCart = (e) => {
    e.preventDefault();
    this.props.changeShowCartAction(false);
  };

  /**
   * Consulta siguiente pagina de articulos en la BD
   */
  loadMoreArticles = (e) => {
    e.preventDefault();

    let skip = this.state.getArticleListResponse.length;
    let filterData = {
      skip: skip,
      filter: this.state.filterTextConfirmed,
      type: this.props.type,
      categories: this.state.checkedCategories,
      typeOrder: parseInt(this.state.ddlOrderBy),
    };

    //Actualiza filtro confirmado
    this.setState({
      txtFilter: this.state.filterTextConfirmed,
    });

    //Consulta nuevo lote de articulos
    this.props.getArticleListAction(filterData, true, false);
  };

  /**
   * Evento, Ordena lista cargada de acuerdo a la selección del usuario
   * @param {object} e
   */
  onChangeOrder(e) {
    e.preventDefault();
    const value = e.target.value;

    //Limpia lista actual
    this.props.clearArticleListAction();

    let filterData = {
      skip: 0,
      filter: this.state.filterTextConfirmed,
      type: this.props.type,
      categories: this.state.checkedCategories,
      typeOrder: parseInt(value),
    };

    //Actualiza filtro confirmado
    this.setState({
      txtFilter: this.state.filterTextConfirmed,
    });

    this.props.filterArticleListAction(filterData);
  }

  /**
   * Escucha cambios en tipo presentación
   * catalogo articulos (Presentación mosaico o listado tarjetas)
   */
  syncChangePresentationType(type) {
    if (type !== this.state.presentationType) {
      this.setState({
        presentationType: type,
      });

      let skip = this.state.getArticleListResponse.length;
      let filterData = {
        skip: skip,
        filter: "",
        type: this.props.type,
        categories: this.state.checkedCategories,
        typeOrder: parseInt(this.state.ddlOrderBy),
      };

      this.props.getArticleListAction(filterData, false, false);
    }
  }

  /**
   * Actualiza estado, cierra modal detalle articulo
   * al dar click en boton cerrar
   * @param {object} e
   */
  onCloseModalItem = (e) => {
    this.setState({
      openModalItem: false,
    });
  };

  /**
   * Actualiza estado, Muestra modal detalle articulo
   */
  handleOpenModal(e, item) {
    this.setState({
      openModalItem: true,
      articleDetailObject: item, //Carga articulo seleccionado en estado
    });
  }

  /**
   * Evento, Escucha seleccion de articulo desde modal de detalle
   * @param {object} e Evento
   * @param {object} data Información Item
   */
  onSelectItem = (e, data) => {
    e.preventDefault();

    this.props.setArticleCheckedAction(data, true);

    this.setState({
      openModalItem: false,
    });

    this.props.getArticleListAction(null, false, false);
  };

  /**
   * Carga información del articulo en Redux
   * para edición
   * @param {*} e
   */
  onLoadItem = (e, item) => {
    e.preventDefault();

    //Carga item en Redux
    this.props.loadArticleAction(item);

    //Expande collapse articulos,
    //cierra modal detalle articulos
    this.setState({
      expandPanelArticle: "panel",
      openModalItem: false,
    });
    window.scrollTo(0, 0);
  };

  /**
   * Evento, Escucha evento para eliminar articulo desde el modal de detalle
   * @param {object} e Evento
   * @param {object} item Información articulo
   */
  onDeleteItem = (e, item) => {
    e.preventDefault();

    const deleteData = (confirmed) => {
      if (confirmed) {
        this.props.deleteArticleAction(item.id);

        this.props.setArticleCheckedAction(item, false);

        //Cierra modal detalle articulos
        this.setState({
          openModalItem: false,
        });
      }
    };
    ConfirmAlert(
      `¿Estás seguro que desea eliminar el articulo ${item.nombrearticulo}?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * Evento click, Escucaha evento click sobre collapse
   * del formulario articulos, expande o contrae collapse
   */
  onClickCollapse = () => {
    const current = this.state.expandPanelArticle;
    this.setState({
      expandPanelArticle: current === "panel" ? "" : "panel",
    });

    if (current === "panel" && this.state.editArticleStatus)
      this.props.loadArticleAction({});
  };

  /**
   * Actualiza objeto cargado en Modal de detalle articulo,
   * y actualiza listado de articulos en memoria
   */
  refreshAfterClickFavorite(id) {
    //actualiza objecto cargdo en modal
    let newData = {
      ...this.state.articleDetailObject,
      favorito: this.state.articleDetailObject.favorito === true ? false : true,
    };

    this.setState({
      articleDetailObject: newData,
    });

    //Actualiza Listado en memoria
    this.props.refreshAfterUpdatingAction(id, this.props.type);
  }

  /**
   * Evento, escucha evento de cerrado de lista de categorias
   * realiza filtro
   * @param {*} e
   * @param {*} categories
   */
  onCloseListCategory(e, categories) {
    e.preventDefault();

    const list = this.state.checkedCategories;
    const newList = categories.map((data) => {
      return data.id;
    });

    list.sort();
    newList.sort();

    let theyAreEqual =
      list.length === newList.length &&
      list.every((v, i) => {
        return v === newList[i];
      });

    if (theyAreEqual !== true) {
      //Actualiza filtro confirmado.
      //actualiza listado de categorias seleccionadas
      this.setState({
        txtFilter: this.state.filterTextConfirmed,
        checkedCategories: newList,
      });

      let filterData = {
        skip: 0,
        filter: this.state.txtFilter,
        type: this.props.type,
        categories: newList,
        typeOrder: parseInt(this.state.ddlOrderBy),
      };

      //Limpia lista actual
      this.props.clearArticleListAction();
      this.props.filterArticleListAction(filterData);
    }
  }

  /**
   * Cierra Modal, Notificación certificado no valido
   */
  handleResponseModal = () => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        open: false,
      },
    });
  };

  /**
   * Evento, escucha evento de nuev iten marcado en filtro de categorias,
   * realiza nueva consulta
   * @param {*} checkedList Listado categorias marcadas
   */
  getCheckboxList = (checkedList) => {
    const list = this.state.checkedCategories;
    const newList = checkedList.map((data) => {
      return data.id;
    });

    list.sort();
    newList.sort();

    let theyAreEqual =
      list.length === newList.length &&
      list.every((v, i) => {
        return v === newList[i];
      });

    if (theyAreEqual !== true) {
      //Actualiza filtro confirmado.
      //actualiza listado de categorias seleccionadas
      this.setState({
        txtFilter: this.state.filterTextConfirmed,
        checkedCategories: newList,
      });

      let filterData = {
        skip: 0,
        filter: this.state.txtFilter,
        type: this.props.type,
        categories: newList,
        typeOrder: parseInt(this.state.ddlOrderBy),
      };

      //Limpia lista actual
      this.props.clearArticleListAction();
      this.props.filterArticleListAction(filterData);
    }
  };

  /**
   * Contrae collapse articulo.
   */
  handleCloseCollapse = () => {
    this.setState({
      expandPanelArticle: "",
    });
  };

  /**
   * * Obtiene el texto del botón según el tipo de documento.
   *
   * @param {string} typedocument - El tipo de documento.
   * @returns {string} - El texto del botón correspondiente al tipo de documento.
   */
  getButtonText = (typedocument = "FA") => {
    const document = {
      CO: "Añadir a cotización",
      FA: "Añadir a factura",
      DE: "Añadir a documento",
    };

    const documentType = typedocument.replace(/_NOTE$/, "");

    return document[documentType] || "Añadir";
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="comunContainer">
          {getRoleOfLoggedUser() ? (
            <CollapsePrimary
              component={
                <CreateArticle
                  type={this.props.type}
                  handleCloseCollapse={this.handleCloseCollapse}
                />
              }
              title={
                <LightTooltip
                  title="No tiene permisos sobre esta acción"
                  placement="bottom"
                >
                  <span>Crear nuevo artículo</span>
                </LightTooltip>
              }
              titleOpen={"Crear nuevo artículo test"}
              titleEdit={"Crear nuevo artículo test"}
              open={""}
            />
          ) : (
            <CollapsePrimary
              component={
                <CreateArticle
                  type={this.props.type}
                  handleCloseCollapse={this.handleCloseCollapse}
                />
              }
              title={
                !this.state.editArticleStatus
                  ? "Crear nuevo artículo"
                  : "Editar artículo"
              }
              titleOpen={
                !this.state.editArticleStatus
                  ? "Nuevo Artículo"
                  : "Editar artículo"
              }
              open={this.state.expandPanelArticle}
              onClick={this.onClickCollapse}
            />
          )}
        </div>

        <Grid container>
          <Grid item lg={4} xs={12}>
            <InputIcon
              id={"txtFilter"}
              label={"Buscar por artículo o referencia"}
              value={this.state.txtFilter}
              onChange={(e) => this.syncChanges(e)}
              onSearch={this.onSearch}
              maxLength={100}
              disabled={this.state.loadingGetArticles}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <div style={{ display: "flex" }}>
              <CheckboxList
                options={this.state.categories}
                getCheckboxList={this.getCheckboxList}
                disabled={this.state.loadingGetArticles}
              />
              <Tooltip title="Lista">
                <IconButton
                  onClick={() => this.syncChangePresentationType("Grid")}
                  disabled={this.state.loadingGetArticles}
                >
                  <ViewListIcon className={classes.buttonIcon} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Mosaico">
                <IconButton
                  onClick={() => this.syncChangePresentationType("List")}
                  disabled={this.state.loadingGetArticles}
                >
                  <ViewModuleIcon className={classes.buttonIcon} />
                </IconButton>
              </Tooltip>

              <FormControl style={{ paddingTop: 20, width: "100%" }}>
                <SelectFilter
                  name={"ddlOrderBy"}
                  value={this.state.ddlOrderBy}
                  options={optionsFilter}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.onChangeOrder(e);
                  }}
                  label={""}
                  disabled={this.state.loadingGetArticles}
                />
              </FormControl>
            </div>
          </Grid>

          {this.state.typeView === "invoice" ? (
            <Fragment>
              <Grid item lg={2} md={4} sm={6} xs={6}>
                <ButtonPrimary
                  onClick={(e) => this.addItemsToCart(e)}
                  loading={false}
                  text={this.getButtonText(this.props.typedocument)}
                  disabled={
                    this.state.checkedArticles.length > 0 ? false : true
                  }
                  lessMargin={true}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={6}>
                <BottonThird
                  text={"Volver al carrito"}
                  loading={false}
                  onClick={this.goToCart}
                  disabled={
                    this.props.opdetalledocumento.length > 0 ? false : true
                  }
                  lessMargin={true}
                />
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <HtmlTooltip
                  title={READING_MODE ? <TooltipMessageReadingMode /> : ""}
                >
                  <span>
                    <ButtonPrimary
                      onClick={(e) => this.addItemsToCart(e)}
                      loading={this.state.loadingGoInvoice}
                      text={"Añadir a factura "}
                      disabled={
                        READING_MODE ||
                        (this.state.checkedArticles.length > 0 ? false : true)
                      }
                    />
                  </span>
                </HtmlTooltip>
              </Grid>
            </Fragment>
          )}
        </Grid>

        {this.state.presentationType === "List" ? (
          <GrandList
            options={this.state.getArticleListResponse}
            onSelectArticle={(e, item) => {
              this.handleOpenModal(e, item);
            }}
            onChange={(e, item) => {
              this.syncCheckChange(e, item);
            }}
          />
        ) : (
          <Grid container spacing={2} className={classes.container}>
            {this.state.getArticleListResponse.map((item, index) => (
              <Grid key={index} item lg={4} xs={6}>
                <CardList
                  data={item}
                  onSelectArticle={(e, item) => {
                    this.handleOpenModal(e, item);
                  }}
                  onChange={(e, item) => {
                    this.syncCheckChange(e, item);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {this.state.getArticleListResponse.length === 0 &&
        this.state.loadingGetArticles === false ? (
          <div style={{ height: "55vh", color: "white" }}>
            No se han encontrado resultados...
          </div>
        ) : (
          ""
        )}

        {this.state.getArticleListResponse.length === 0 &&
        this.state.loadingGetArticles === true ? (
          <div style={{ height: "55vh" }}>
            <CustomProgress />
          </div>
        ) : (
          ""
        )}

        {/* Modal detalle articulo */}
        <SimpleModal
          onClose={(e) => this.onCloseModalItem(e)}
          open={this.state.openModalItem}
          title={""}
          disabled={this.state.loadingUpdateArticle}
          noPadding={true}
          component={() => (
            <DetailArticle
              data={this.state.articleDetailObject}
              onSelectItem={this.onSelectItem}
              onLoadItem={this.onLoadItem}
              onDeleteItem={this.onDeleteItem}
              loadingFavorite={this.state.loadingUpdateArticle}
              typedocument={this.props.typedocument}
            />
          )}
        />
        <Grid container style={{ marginTop: 30 }}>
          <Grid item lg={4} xs={4} />
          <Grid item lg={4} md={6} sm={12} xs={12}>
            {this.state.getArticleListResponse.length > 0 ? (
              <ButtonPrimary
                onClick={this.loadMoreArticles}
                loading={this.state.loadingGetArticles}
                text={"Mostrar más..."}
                disabled={
                  this.state.totalArticles ===
                  this.state.getArticleListResponse.length
                    ? true
                    : false
                }
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={4} xs={4} />
        </Grid>

        {/* Modal Notificaciones */}
        <ResponseModal
          modalType={this.state.modalData.modalType}
          title={this.state.modalData.title}
          subtitle={this.state.modalData.subtitle}
          body={this.state.modalData.body}
          modalImage={this.state.modalData.modalImage}
          open={this.state.modalData.open}
          closeElement={this.state.modalData.closeElement}
          onCloseElement={this.handleResponseModal}
        />
      </>
    );
  }
}

ArticleView.propTypes = {
  setStatusShowCartAction: PropTypes.func.isRequired,
  addArticlesToCartAction: PropTypes.func.isRequired,
  getArticleListAction: PropTypes.func.isRequired,
  cleanArticleListAction: PropTypes.func.isRequired,
  clearArticleListAction: PropTypes.func.isRequired,
  filterArticleListAction: PropTypes.func.isRequired,
  orderArticleListAction: PropTypes.func.isRequired,
  setArticleCheckedAction: PropTypes.func.isRequired,
  getArticleCheckedAction: PropTypes.func.isRequired,
  cleanArticleCheckedAction: PropTypes.func.isRequired,
  deleteArticleAction: PropTypes.func.isRequired,
  checkCertificateCompanyAction: PropTypes.func.isRequired,
  changeShowInvoiceCreationAction: PropTypes.func.isRequired,
  articleReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  articleReducer: state.articleReducer,
  authReducer: state.authReducer,
  categoryReducer: state.categoryReducer,
  opdetalledocumento: state.documentCartReducer.opdetalledocumento,
});

export default connect(mapStateToProps, {
  deleteArticleAction,
  loadArticleAction,
  setStatusShowCartAction,
  addArticlesToCartAction,
  getArticleListAction,
  cleanArticleListAction,
  clearArticleListAction,
  filterArticleListAction,
  orderArticleListAction,
  setArticleCheckedAction,
  getArticleCheckedAction,
  cleanArticleCheckedAction,
  checkCertificateCompanyAction,
  changeShowInvoiceCreationAction,
  changeShowCartAction,
  loadItemsCheckedInCartAction,
})(withStyles(useStyles)(ArticleView));
