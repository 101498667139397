import React from "react";

/**
 * * Datos iniciales para un modal.
 * @typedef {object} InitialModalData
 * @property {string} type - El tipo de modal.
 * @property {string} title - El título del modal.
 * @property {string} subtitle - El subtítulo del modal.
 * @property {string|JSX.Element} body - El contenido del cuerpo del modal.
 * @property {string} modalImage - La imagen asociada al modal.
 * @property {boolean} open - Indica si el modal está abierto o cerrado.
 * @property {string} closeElement - El texto del elemento de cierre del modal.
 * @property {number} onCloseElement - La acción a realizar al cerrar el modal.
 */

/**
 * * Datos del modal de error estándar.
 * @type {InitialModalData}
 */
const initialModalData = {
  type: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "warning",
  open: false,
  closeElement: "",
  onCloseElement: 0,
};

/**
 * * Datos del modal de error para edición.
 * @type {InitialModalData}
 */
const errorDataModal = {
  type: "warning",
  title: "Upss...!!!",
  subtitle: "Archivo no disponible",
  body: (
    <div>
      Documento en proceso de envío a DIAN. Mientras, el documento no se
      encuentre aprobado por DIAN, Colfactura no te permitirá visualizarlo.
    </div>
  ),
  modalImage: "warning",
  open: true,
  closeElement: "Cerrar",
  onCloseElement: 0,
};

/**
 * * Datos del modal de error para el envío por correo.
 * @type {InitialModalData}
 */
const editErrorDataModal = {
  type: "warning",
  title: "Upss...!!!",
  subtitle: `No puedes editar este documento.`,
  body: (
    <div>
      No puedes editar este documento por que aun no se ha sincronizado con la
      DIAN.
    </div>
  ),
  modalImage: "warning",
  open: true,
  closeElement: "Cerrar",
  onCloseElement: 0,
};

/**
 * * Datos iniciales para un modal.
 * @type {InitialModalData}
 */
const mailErrorDataModal = {
  type: "warning",
  title: "Oops!!!",
  subtitle: "Documento no disponible",
  body: (
    <div>
      Documento en proceso de envío a DIAN. Mientras, el documento no se
      encuentre aprobado por DIAN, Colfactura no te permitirá enviarlo.
    </div>
  ),
  modalImage: "warning",
  open: true,
  closeElement: "Cerrar",
  onCloseElement: 0,
};

export {
  initialModalData,
  errorDataModal,
  editErrorDataModal,
  mailErrorDataModal,
};
