import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import { FormatDecimal } from "../../components/common/formatDecimal";

const animate = " animate__animated animate__fadeIn animate__faster";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: "#5E5E5E",
		color: theme.palette.primaryColor,
		"& .MuiCardContent-root": {
			paddingTop: 0,
			paddingBottom: 5,
			marginLeft: 40,
		},
		"& .MuiCardHeader-root": {
			paddingTop: 5,
			paddingBottom: 0,
		},
		"& .MuiCardHeader-avatar": {
			marginRight: 5,
		},
	},
	content: {
		fontSize: 14,
		fontWeight: "normal",
		color: "#F5D13D",
	},
	title: {
		color: theme.palette.primaryColor,
		fontSize: 15,
		fontWeight: "bold",
	},
	avatar: {
		color: "#2CC63E",
	},
}));

export const IntegrationDiscountCard = React.memo(
	({ Codigo, Descripcion, Valor }) => {
		const classes = useStyles();

		return (
			<Card className={`${classes.root} ${animate}`}>
				<CardHeader
					avatar={<AccountBalanceIcon className={classes.avatar} />}
					classes={{
						title: classes.title,
					}}
					title={Descripcion}
				/>
				<CardContent className={classes.content}>
					<FormatDecimal prefix={"$"} value={Valor} />
				</CardContent>
			</Card>
		);
	}
);
