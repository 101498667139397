import { types } from "../types/types";

const initialState = {
    error: null,
    message: '',
    show: true
}

export const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.showError:
            return {
                ...state,
                ...action.payload
            }
        case types.hideErrors:
            return{
                ...state,
                ...action.payload,
                show: false
            }
        default:
            return state;
    }
}