import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { TaxElement } from "../TaxElement";

import { getTaxItems } from "../../../../utils/getTaxItems";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRight: "1px solid gray",
  },
  commonMargin: {
    marginTop: 15,
  },
}));

export const TaxItemComponent = ({
  cantidad,
  cantidadmililitros,
  gradosalcohol,
  pesogramos,
  porcentajeadv,
  porcentajeicui,
  porcentajeinc,
  porcentajeiva,
  tasauvtimp,
  tipobebida,
  valoribua,
}) => {
  const classes = useStyles();

  const taxItems = getTaxItems(
    porcentajeiva,
    porcentajeinc,
    tipobebida,
    gradosalcohol,
    cantidad,
    pesogramos,
    tasauvtimp,
    cantidadmililitros,
    valoribua,
    porcentajeicui,
    porcentajeadv
  );

  return (
    <Grid container className={classes.commonMargin}>
      <Grid item lg={12} xs={12}>
        {taxItems.map((taxItem, index) =>
          taxItem.aplica ? <TaxElement key={index} {...taxItem} /> : null
        )}
      </Grid>
    </Grid>
  );
};
