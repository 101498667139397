import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { createEquivalentDocAction } from "../../../../actions/equivalentDocAction";

import ButtonPrimary from "../../../../components/button/buttonPrimary.component";

import { MESSAGE_NEXT_CONFIRM } from "../../../../config/config";

import { ConfirmAlert } from "../../../../helpers/alert.helpers";
import { validateDocument, validateNote } from "../../../../helpers/documentValidate";

import { generateRequestEquivalentDoc } from "../../utils";
import { generateRequestEquivalentDocNote } from "../../utils/generateRequestEquivalentDoc";

export const DocumentCartButtons = ({ typedocument }) => {
  const dispatch = useDispatch();

  const isNote = typedocument === "DE_NOTE";

  const totals = useSelector((state) => state.documentCartReducer);

  const { equivalentDocumentForm, customerEquivalentDoc, equivalentDocDetail } = useSelector((state) => state.equivalentDocReducer);

  const textModal = isNote
    ? "Al editar el documento equivalente se generara una nota de ajuste ¿Esta seguro que desea hacerlo?"
    : "¿Estás seguro de guardar y enviar el documento?";

  const handleSaveDocument = (e) => {
    e.preventDefault();

    const handleDocumentCreation = (isNote, totals, equivalentDocDetail, equivalentDocumentForm, customerEquivalentDoc) => {
      if (isNote) {
        return generateRequestEquivalentDocNote({
          equivalentDocNote: equivalentDocDetail,
          totalsCart: totals,
        });
      } else {
        return generateRequestEquivalentDoc({
          equivalentDoc: equivalentDocumentForm,
          totalsCart: totals,
          customerDoc: customerEquivalentDoc,
        });
      }
    };

    const validateAndCreateDocument = (isNote, totals) => {
      if (isNote ? validateNote(totals) : validateDocument(totals)) {
        const deleteData = (confirmed) => {
          if (confirmed) {
            const document = handleDocumentCreation(isNote, totals, equivalentDocDetail, equivalentDocumentForm, customerEquivalentDoc);
            dispatch(createEquivalentDocAction(document, isNote));
          }
        };
        ConfirmAlert(textModal, deleteData, MESSAGE_NEXT_CONFIRM);
      }
    };

    const isNote = typedocument === "DE_NOTE";
    validateAndCreateDocument(isNote, totals);
  };

  return (
    <Grid container style={{ marginTop: 11 }}>
      <Grid item xl={11} lg={12} sm={12} xs={12}>
        <Grid container spacing={1} justify="flex-end">
          <Grid item lg={6} xs={12}>
            {/* <ButtonPrimary
              type="button"
              onClick={() => handleChangeStep(2)}
              text={"Revisar Documento"} //! Pendiente de implementar
            /> */}
          </Grid>
          <Grid item lg={6} xs={12}>
            <ButtonPrimary type="button" onClick={handleSaveDocument} text={!isNote ? "Enviar Documento" : "Enviar Nota"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
