
import React,{ useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";


import AboutComponent from '../helps/about.component'
import ContactComponent from '../helps/contact.component'
import FrequentQuestionsComponent from '../helps/frequentQuestions.component'
import PrivacyPolicyComponent from '../helps/privacyPolicy.component'
import ReportProblemComponent from '../helps/reportProblem.component'
import CustomTabs from "../../components/tabs/tabs.component";

import { getComponentsAction, ClearGetComponentsAction,getReportProblem,getreason} from '../../actions/configAction'


const tabs = [
  {
    title: 'Preguntas frecuentes ',
    component: <FrequentQuestionsComponent type={0} /> //TPreguntas frecuentes
  },
  {
    title: 'Reportar un problema',
    component: <ReportProblemComponent type={1} />  //Reportar un problema
  },
  {
    title: 'Contáctanos',
    component: <ContactComponent/> // Contactenos
  },
  {
    title: 'Política de tratamiento de datos',
    component: <PrivacyPolicyComponent/> // Politica y privacidad
  },
  {
    title: 'Acerca de',
    component: <AboutComponent/> // Reportar un problema
  }

];


const Help = (props) => {

  const { getComponentsAction , ClearGetComponentsAction, getReportProblem, getreason} = props;

  useEffect(() => {
    getComponentsAction(9);
    getReportProblem()
    getreason()
    // returned function will be called on component unmount 
    return () => {
      ClearGetComponentsAction()
    }
  },[getComponentsAction,ClearGetComponentsAction,getReportProblem,getreason])




  return (
    <div className="container-form">
      <CustomTabs tabs={tabs} using={true} />
    </div>
  );
};


Help.propTypes = {
  getComponentsAction: PropTypes.func.isRequired,
  ClearGetComponentsAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  
});

export default connect(mapStateToProps, {
  getComponentsAction,
  ClearGetComponentsAction,
  getReportProblem,
  getreason
})(Help);
