import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';


import { format } from "date-fns";
import { FormatDecimal } from '../../../components/common/formatDecimal';

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.11)',
        color: theme.palette.primaryColor,
        fontSize: 16,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: -5,
            paddingLeft: 55
        }
    },
    header: {
        paddingBottom: 0,
        paddingTop: 5,
        marginLeft: -5,
        '& .MuiCardHeader-action': {
            display: 'flex'
        },
        '& .MuiCardHeader-root': {
            padding: 10
        }
    },
    content: {
        fontSize: '0.9rem'
    },
    title: {
        color: theme.palette.primaryColor,
        fontSize: '0.9rem'
    },
    icon: {
        color: theme.palette.secundaryColor
    }
}));

export const RefDocumentReviewCard = React.memo(({
    tipodocumentodescripcion,
    numero,
    tipodocumentoid,
    valorapagar,
    fecha
}) => {
    const classes = styles();
    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={<img src={require('../../../images/docref.png')} alt={"logo"} className={classes.icon} />}
                classes={{
                    title: classes.title,
                }}
                title={tipodocumentodescripcion}
            />
            <CardContent className={classes.content}>
                <div className={classes.wordBreak} style={{ textTransform: 'none' }}> {numero}</div>
                <div className={classes.wordBreak}> {format(new Date(fecha), 'dd/MM/yyyy')}</div>

                {
                    tipodocumentoid?.toString() === "1"
                    &&
                    <div className={classes.wordBreak}>
                        <FormatDecimal
                            value={valorapagar}
                            prefix={"$"}
                        />
                    </div>

                }
            </CardContent>
        </Card>
    )
})


RefDocumentReviewCard.propTypes = {
    id: PropTypes.number.isRequired,
    tipodocumentodescripcion: PropTypes.string.isRequired,
    numero: PropTypes.string.isRequired,
    tipodocumentoid: PropTypes.string.isRequired,
    fecha: PropTypes.string.isRequired
}