import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';



const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        '& .MuiTypography-body1': {
            fontSize: 10
        },
        '& .MuiSvgIcon-root':{
            fontSize:20
        },
        '& .MuiFormGroup-root':{
            //borderBottom: '1px solid gray'
        },
        '& .MuiFormControlLabel-labelPlacementStart': {
            marginLeft: 0,
            marginRight: 5
        },
    },
    label: {
        color: theme.palette.primaryColor,
        fontSize: 10,
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.primaryColor
        }
    },
    '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
        marginRight: 5
    },

}));

const CustomRadio = withStyles((theme) => ({
    root: {
        color: 'gray',
        '&$checked': {
            color: theme.palette.secundaryColor
        },
        padding: 0

    },
    '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
        marginRight: 5
    },
    '& .MuiFormHelperText-root.Mui-error':{
        fontSize: 10
    },
    checked: {},
}))((props) => <Radio  {...props} />);



function RadioButtonGroupSmall(props) {
    const classes = useStyles();
    const { 
        label, 
        value = 1, //Valor 
        onChange, 
        options, 
        validator, 
        validateOptions, 
        nameValidator, 
        name, 
        disabled } = props;

    let errorValidator = validator
        ? validator.message(
            !!nameValidator ? nameValidator : label,
            value,
            validateOptions
        )
        : "";

    return (
        <FormControl
            name={name}
            error={!!errorValidator ? true : false}
            className={classes.formControl}
            disabled={disabled === true ? true : false} >
            <FormLabel className={classes.label} >{label}</FormLabel>
            <RadioGroup row aria-label="position" name={name} value={value} onChange={onChange}>
                {options.map((item, index) =>
                    <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<CustomRadio />}
                        label={item.text}
                        labelPlacement="start" />
                )}
            </RadioGroup>
            <FormHelperText>
                <span style={{ fontSize: 10 }}> {errorValidator}</span>
            </FormHelperText>
        </FormControl>
    );
}

RadioButtonGroupSmall.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired
}


export default RadioButtonGroupSmall;
