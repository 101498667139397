import { types } from "../types/types";

const initialState = {
  menuMyAccount: {
    titleHealthCode: "",
    messageHealthCode: "",
    bottonHealthCode: "",
    urlHealthCode: "",
  },
  menuInvoice: {
    titleEsRegalo: "",
    messageEsRegalo: "",
    bottonEsRegalo: "",
    hrefEsRegalo: "",
    tooltipMotiveNoteHeader: "",
    tooltipMotiveNoteBody: "",
    tooltipMotiveNoteBotton: "",
    tooltipMotiveNoteUrl: "",
  },
  menuDocSupport: {
    tooltipMotiveNoteHeader: "",
    tooltipMotiveNoteBody: "",
  },
  menuDiscount: {
    btnSaveDiscount: "",
    txtModelDiscount: "",
    txtTypeDiscount: "",
    txtReasonDiscount: "",
    txtNameDiscount: "",
    txtValueDiscount: "",
    recordSuccessHeader: "",
    recordSuccessBody: "",
    updateSuccessHeader: "",
    updateSuccessBody: "",
    tooltipModelDiscountHeader: "",
    tooltipModelDiscountBody: "",
    tooltipModelDiscountBotton: "",
    tooltipModelDiscountUrl: "",
    tooltipTypeDiscountHeader: "",
    tooltipTypeDiscountBody: "",
    tooltipTypeDiscountBotton: "",
    tooltipTypeDiscountUrl: "",

    tooltipComercialDiscountHeader: "",
    tooltipComercialDiscountBody: "",
    tooltipComercialDiscountBotton: "",
    tooltipComercialDiscountUrl: "",

    tooltipFinancialDiscountHeader: "",
    tooltipFinancialDiscountBody: "",
    tooltipFinancialDiscountBotton: "",
    tooltipFinancialDiscountUrl: "",
  },
  menuCustomer: {
    personTypeTitle: "",
    personTypeMessage: "",
    personTypeButton: "",
    personTypeHref: "",
    titleBulkLoad: "",
    contentBulkLoadOne: "",
    contentBulkLoadTwo: "",
    contentBulkLoadThree: "",
    contentBulkLoadFour: "",
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_MENU_MYACCOUNT:
      return {
        ...state,
        menuMyAccount: {
          ...state.menuMyAccount,
          ...action.payload,
        },
      };
    case types.GET_MENU_ARTICLE:
      return {
        ...state,
        menuArticle: {
          ...state.menuArticle,
          ...action.payload,
        },
      };
    case types.GET_MENU_INVOICE:
      return {
        ...state,
        menuInvoice: {
          ...state.menuInvoice,
          ...action.payload,
        },
      };
    case types.GET_MENU_DOC_SUPPORT:
      return {
        ...state,
        menuDocSupport: {
          ...state.menuDocSupport,
          ...action.payload,
        },
      };
    case types.GET_MENU_DASHBOARD:
      return {
        ...state,
        menuDashboard: {
          ...state.menuDashboard,
          ...action.payload,
        },
      };
    case types.GET_MENU_HELP:
      return {
        ...state,
        menuHelp: {
          ...state.menuHelp,
          ...action.payload,
        },
      };
    case types.GET_MENU_CHARGE:
      return {
        ...state,
        menuCharge: {
          ...state.menuCharge,
          ...action.payload,
        },
      };
    case types.GET_MENU_DISCOUNT:
      return {
        ...state,
        menuDiscount: {
          ...state.menuDiscount,
          ...action.payload,
        },
      };
    case types.GET_MENU_CUSTOMER:
      return {
        ...state,
        menuCustomer: {
          ...state.menuCustomer,
          ...action.payload,
        },
      };
    case types.GET_MENU_INVOICE_RECEIVED:
      return {
        ...state,
        menuInvoiceReceived: action.payload,
      };
    default:
      return state;
  }
}
