import React, {  } from 'react';
import { makeStyles } from '@material-ui/styles';
import './step.css';
import imgstep1 from '../../images/wizard/wizard_1.png'
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: 'auto'
  },
  message: {
    width: '27vw',
    textAlign: 'center',
    position: 'absolute',
    bottom: '5%',
    right: '-25%',
    backgroundColor: 'rgba(65, 65, 65, 0.95)',
    color: theme.palette.primarycolor,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '2vw',
    fontSize: '1.1vw',
    padding: '1vw',
    fontStyle: 'italic',
  },
  containerImage:{
    width: '45%',
    margin: '0 auto',
    position: 'relative'
  }

}));


const Step1 = () => {
  const classes = useStyles();

  const openColfactura = () => {
    window.open("https://colfactura.com/colfactura/", "_blank")
  }

  return (
    <div className={classes.containerImage}>
      <img alt='' src={imgstep1} className={classes.image}></img>
      <Typography className={classes.message}>
        Bienvenido a <span className="linkColfactura" onClick={openColfactura}>Colfactura</span>, aquí podrás tener un DashBoard con toda la
            información y estadística más importante de tu empresa o negocio,
            podrás ver tus productos más vendidos, tus mejores clientes,
            facturación y muchas cosas más.
          </Typography>
    </div>
  );
}

export default Step1;