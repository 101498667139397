import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Style from "@material-ui/icons/Style";

import CreateArticle from "../../catalogCategory/article/createArticle.component";
import CreateArticleDocSup from "../../catalogCategory/article/createArticleDocSup.component ";
import SwitchForm from ".././../../components/switch/switch.component";

import { getRoleOfLoggedUser } from "../../../actions/authActions";
import {
  changeShowCartAction,
  changeRoundStatusAction,
} from "../../../actions/documentCartAction";

import { FormatDecimal } from "../../../components/common/formatDecimal";
import { CustomModal } from "../../../components/modal/CustomModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.eighthBackgroundColor,
  },
  content: {
    textAlign: "center",
  },
  totalText: {
    fontSize: 26,
    color: theme.palette.secundaryColor,
    fontWeight: 600,
  },
  comunText: {
    color: theme.palette.primaryColor,
  },
  actions: {
    textAlign: "center",
  },
  button: {
    borderRadius: "15px !important",
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: theme.palette.primaryColor,
  },
  icon: {
    color: theme.palette.secundaryColor,
    fontSize: "5vh !important",
  },
  containerIcon: {
    backgroundColor: "rgba(255,255,255,0.11)",
    padding: 5,
    borderRadius: 10,
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid gray",
    display: "block",
  },
  totalInvalid: {
    color: "red",
    fontSize: 26,
    fontWeight: 600,
  },
  containerSwitch: {
    display: "inline-block",
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const DocumentTotal = React.memo(
  ({ valorapagar, totalcantidades, redondeo, typedocument = "" }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openModalProduct, setOpenModalProduct] = useState(false);
    const { cartDocumentSupport } = useSelector(
      (state) => state.documentCartReducer
    );

    const handleChangeShowCatalog = () => {
      dispatch(changeShowCartAction(true));
    };

    /**
     * Actualiza estado redondeo en reducer
     */
    const handleChangeRound = () => {
      dispatch(changeRoundStatusAction(!redondeo));
    };

    /**
     * Cierra modal nuevo producto
     */
    const handleCloseModalProduct = () => {
      setOpenModalProduct(false);
    };

    /**
     * Abre modal nuevo producto
     */
    const handleOpenModalProduct = () => {
      setOpenModalProduct(true);
    };
    return (
      <>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <span className={classes.comunText} style={{ fontSize: 14 }}>
              <FormatDecimal value={totalcantidades} suffix={" Productos"} />
            </span>
            <br />
            {valorapagar < 0 ? (
              <span className={classes.totalInvalid}>
                <FormatDecimal
                  value={valorapagar}
                  prefix={"$"}
                  size={"small"}
                />
              </span>
            ) : (
              <span className={classes.totalText}>
                <FormatDecimal
                  value={valorapagar}
                  prefix={"$"}
                  size={"small"}
                />
              </span>
            )}

            <br />
            <span
              className={classes.comunText}
              style={{ fontSize: 12, fontStyle: "italic" }}
            >
              Valor total impuestos incluidos
            </span>
            <br />
            <div className={classes.containerSwitch}>
              <SwitchForm
                name={"roundValuesStatus"}
                titleOn={"Redondear decimales"}
                checked={redondeo}
                onChange={handleChangeRound}
                disabled={typedocument.includes("_NOTE")}
              />
            </div>
          </CardContent>
          <span className={classes.divider}></span>
          <CardActions className={classes.actions}>
            <Grid container spacing={3}>
              {cartDocumentSupport ? (
                <Grid item xs={12}>
                  {getRoleOfLoggedUser() ? (
                    <LightTooltip
                      title="No tiene permisos sobre esta acción"
                      placement="bottom"
                    >
                      <IconButton
                        classes={{
                          root: classes.button,
                          label: classes.label,
                        }}
                        variant="raised"
                        disableRipple={true}
                      >
                        <div className={classes.containerIcon}>
                          <AddCircleIcon className={classes.icon} />
                        </div>
                        <span style={{ marginTop: 5 }}>{"Crear producto"}</span>
                      </IconButton>
                    </LightTooltip>
                  ) : (
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleOpenModalProduct}
                    >
                      <div className={classes.containerIcon}>
                        <AddCircleIcon className={classes.icon} />
                      </div>
                      <span style={{ marginTop: 5 }}>{"Agregar servicio"}</span>
                    </IconButton>
                  )}
                </Grid>
              ) : (
                <>
                  <Grid item lg={6} xs={6}>
                    {getRoleOfLoggedUser() ? (
                      <LightTooltip
                        title="No tiene permisos sobre esta acción"
                        placement="bottom"
                      >
                        <IconButton
                          classes={{
                            root: classes.button,
                            label: classes.label,
                          }}
                          variant="raised"
                          disableRipple={true}
                        >
                          <div className={classes.containerIcon}>
                            <AddCircleIcon className={classes.icon} />
                          </div>
                          <span style={{ marginTop: 5 }}>
                            {"Crear producto"}
                          </span>
                        </IconButton>
                      </LightTooltip>
                    ) : (
                      <IconButton
                        classes={{ root: classes.button, label: classes.label }}
                        variant="raised"
                        disableRipple={true}
                        onClick={handleOpenModalProduct}
                      >
                        <div className={classes.containerIcon}>
                          <AddCircleIcon className={classes.icon} />
                        </div>
                        <span style={{ marginTop: 5 }}>{"Crear producto"}</span>
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleChangeShowCatalog}
                    >
                      <div className={classes.containerIcon}>
                        <Style className={classes.icon} />
                      </div>
                      <span style={{ marginTop: 5 }}>
                        {" "}
                        {"Agregar desde catalogo"}
                      </span>
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </CardActions>
        </Card>

        {/* Modal nuevo articulo */}
        {!cartDocumentSupport ? (
          <CustomModal
            onClose={handleCloseModalProduct}
            open={openModalProduct}
            component={<CreateArticle origin={"cart-hooks"} />}
            title={"Nuevo articulo"}
            width={1000}
            disableBackdropClick={true}
          />
        ) : (
          <CustomModal
            onClose={handleCloseModalProduct}
            open={openModalProduct}
            component={
              <CreateArticleDocSup
                setOpenModalProduct={setOpenModalProduct}
                origin={"cart-hooks"}
              />
            }
            title={"Nuevo Servicio"}
            width={1000}
            disableBackdropClick={true}
          />
        )}
      </>
    );
  }
);
