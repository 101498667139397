import React from "react";
import FlareComponent from "flare-react";

import { Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import LoadingAnimation from "../../images/animations/cargando.flr";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    padding: 50,
    textAlign: "center",
    width: "100%",
  },
  text: {
    textAlign: "center",
    padding: 10,
    fontSize: 17,
    fontWeight: 500,
  },
}));

export const UploadProgress = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={12} xs={12}>
          <FlareComponent
            width={104}
            height={104}
            animationName="music_walk"
            file={LoadingAnimation}
          />
        </Grid>
        <Grid item lg={12} xs={12} className={classes.text}>
          {text}
        </Grid>
      </Grid>
    </div>
  );
};
