import React from "react";
import { DIGITS_AFTER_DECIMAL } from "../config/config";

export const reactValidatorOptions = {
  element: (message) => (
    <div className="font-validator-red float-left">{message}</div>
  ),
  validators: {
    password: {
      message:
        ":attribute debe tener al menos 1 mayúscula, minúscula, número, y caracter, no puede contener espacios.",
      rule: (val, params, validator) => {
        //regex params (1 number)(1 a-z)(1 A-Z)(1 special char)(not contain special chars(space))
        return validator.helpers.testRegex(
          val,
          /^((?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[-_@.,;+{}()/*&%$#!¡¿?'|áéíóúÁÉÍÓÚ])(?!.*?[ ])).+$/i
        );
      },
      required: true, // optional
    },
    documento: {
      message: "Número de documento no válido",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /(^[0-9]{3,12}$)/);
      },
    },
    correo: {
      message: "El formato del correo es incorrecto",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/
        );
      },
    },
    isNumber: {
      message: "Este campo solo admite valores numericos",
      rule: (val) => {
        return parseInt(val).toString() !== "NaN";
      },
    },
    isAlphanumeric: {
      message: "Este campo solo admite valores alfanuméricos",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z0-9]+$/);
      },
    },
    address: {
      message: "Este campo solo admite valores alfabéticos",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z0-9-#.\s]+$/);
      },
    },
    noNegative: {
      message: "Este campo no admite valores negativos",
      rule: (val) => {
        return parseInt(val) >= -1;
      },
    },
    noDecimal: {
      message: "Este campo solo admite valores enteros",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^\d+$/);
      },
    },
    noZero: {
      message: "Cantidad superior a 1",
      rule: (val) => {
        return val > 0;
      },
    },
    passport: {
      message: "El formato del pasaporte es incorrecto",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z][a-zA-Z0-9]*$/);
      },
    },
    phone: {
      message: "El número de teléfono no posee un formato válido",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^(\(\+?\d{1,3}\)[\s|-]?(([\d][\s|-]?){6,7})(([\d][\s|-]?){2})?|(\+?[\d][\s|-]?){6,7}(([\d][\s|\\-]?){2}(([\d][\s|-]?){2})?)?)$/
        );
      },
    },
    uuid: {
      message: "Campo no válido",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/
        );
      },
    },
    percentageValue: {
      message: "El porcentaje no puede ser mayor a 100%.",
      rule: (val) => {
        return parseFloat(val) > -1 && parseFloat(val) <= 100;
      },
    },
    porcentage: {
      message: "El porcentaje no puede ser mayor a 100%.",
      rule: (val, validator) => {
        return parseFloat(val) <= 100 ? true : false;
      },
    },
    valueTax: {
      message: "Máximo 12 enteros y 4 decimales",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^([0-9]{1,12}([.,][0-9]{1,4})?)$/
        );
      },
    },
    discountvalue: {
      message:
        "El valor del descuento no debe superar valor unitario * cantidad",
      rule: (val, params, validator) => {
        return parseFloat(val) > parseFloat(params[0]) ? false : true;
      },
      messageReplace: (message, params) =>
        message.replace(
          ":values",
          params[0].toLocaleString("es", {
            maximumFractionDigits: DIGITS_AFTER_DECIMAL,
          })
        ),
    },
    dateNotLessThanOrEqual: {
      message: "La fecha de pago debe ser mayor a la fecha de la factura",
      rule: (val, params, validator) => {
        var dateOne = new Date(val).getTime();
        var dateTwo = new Date(params[0]).getTime();
        return dateOne <= dateTwo ? false : true;
      },
    },
    percentageTwoDecimal: {
      message: "Campo no válido, solo se aceptan dos decimales",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^\d+\.?\d{0,2}$/);
      },
    },
    blankSpace: {
      message: "Campo no válido no puede contener espacios",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z\-0-9]+$/);
      },
    },
    consecutiveBetween: {
      message: "Campo no válido",
      rule: (val, params, validator) => {
        return parseInt(val) >= params[0] && parseInt(val) <= params[1];
      },
    },

    initialConsecutive: {
      message: "Campo no válido",
      rule: (val, params, validator) => {
        return parseInt(val) < params[0];
      },
    },
    void: {
      message: "",
      rule: (val, params, validator) => {
        return true;
      },
    },
    notNone: {
      message: "Campo obligatorio",
      rule: (val, params, validator) => {
        return val === "none" ? false : true;
      },
    },
    leastOneNumber: {
      message: "Debe tener al menos un número",
      rule: (val, params, validator) => {
        return /\d/.test(val);
      },
    },
    dateNotLessThan: {
      message: "La fecha hasta debe ser mayor a la fecha desde.",
      rule: (val, params, validator) => {
        var dateOne = val.toISOString().substring(0, 10);
        var dateTwo = params[0].toISOString().substring(0, 10);
        return dateOne <= dateTwo ? false : true;
      },
    },
    dateNotMoreThan: {
      message:
        "Las fechas no pueden tener una diferencia mayor a :values días.",
      rule: (val, params, validator) => {
        return Math.round((val - params[1]) / (1000 * 60 * 60 * 24)) > params[0]
          ? false
          : true;
      },
      messageReplace: (message, params) =>
        message.replace(":values", params[0]),
    },
    noZeroTax: {
      message: "El valor debe tener máximo 12 enteros y 4 decimales, y ser superior a 0.",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^([0-9]{1,12}([.,][0-9]{1,4})?)$/
        ) && val > 0;
      },
    },
    noZeroPercentage: {
      message: "El porcentaje debe ser superior a 0% y no puede ser mayor a 100%.",
      rule: (val, validator) => {
        return parseFloat(val) > 0 && parseFloat(val) <= 100;
      },
    },
    alphanumericAndSymbols: {
      message: "Este campo solo admite valores alfanuméricos, _ y -.",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[-_a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]+$/);
      },
    },
  },
  messages: {
    required: "Campo obligatorio.",
    email: "Formato incorrecto.",
    min: ":attribute debe tener al menos :min caracteres.",
    max: ":attribute debe tener :max caracteres como máximo.",
    numeric: ":attribute debe ser un número.",
    integer: "Debe ser un número",
    phone: "Formato no válido",
    percentageValue: "El porcentaje no puede ser mayor a 100%.",
    noNegative: "Este campo no admite valores negativos",
    porcentage: "El porcentaje no puede ser mayor a 100%.",
    valueTax: "Máximo 12 enteros y 4 decimales",
    discountvalue: "El valor del descuento no debe superar  $ :values",
    default: "Campo no válido!",
    percentageTwoDecimal: "Campo no válido, solo se aceptan dos decimales",
    blankSpace: "Campo no válido, no puede contener espacios",
    consecutiveBetween:
      "El campo consecutivo actual debe estar en el intervalo del consecutivo inicial y el consecutivo final",
    initialConsecutive:
      "El campo consecutivo inicial debe ser menor que el consecutivo final",
    dateNotLessThanOrEqual:
      "La fecha de pago debe ser mayor a la fecha de la factura",
    notNone: "Campo obligatorio.",
    leastOneNumber: "Debe tener al menos un número",
    dateNotLessThan: "La fecha hasta debe ser mayor a la fecha desde.",
    dateNotMoreThan:
      "Las fechas no pueden tener una diferencia mayor a :values días.",
    noZeroTax: "El valor debe tener máximo 12 enteros y 4 decimales, y ser superior a 0.",
    noZeroPercentage: "El porcentaje debe ser superior a 0% y no puede ser mayor a 100%.",
    isAlphanumeric: "Este campo solo admite valores alfanuméricos",
    alphanumericAndSymbols: "Este campo solo admite valores alfanuméricos, _ y -.",
  },
};
