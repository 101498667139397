import { types } from "../types/types";

const initialState = {
	patientList: [],
	active: null,
	loading: false,
	existing: null,
};

export const patientReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.ADD_PATIENT: {
			return {
				...state,
				patientList: [...state.patientList, action.payload],
			};
		}
		case types.UPDATED_PATIENT:
			return {
				...state,
				patientList: state.patientList.map((p) =>
					p.id === action.payload.id ? action.payload : p
				),
				active: null,
			};
		case types.DELETE_PATIENT:
			return {
				...state,
				patientList: state.patientList.filter((p) => p.id !== action.payload),
			};
		case types.LOAD_PATIENT:
			return {
				...state,
				active: action.payload,
			};
		case types.REFRESH_PATIENT_LIST:
			return {
				...state,
				patientList: action.payload,
			};
		case types.LOADING_GET_PATIENT:
			return {
				...state,
				loading: action.payload,
			};
		case types.LOAD_EXISTING_PATIENT:
			return {
				...state,
				existing: action.payload,
			};
		case types.CLEAN_DATA_PATIENT:
			return initialState;
		default:
			return state;
	}
};
