import { types } from "../types/types";
import isEmpty from "../utils/isEmpty";

const initialState = {
  requestUnitMeasure: "",
  requestCategory: "",
  requestGetArticle: {},
  requestDeleteArticle: {},
  closeCollapseArticle: "",
  loadingGetArticles: false,
  loadingUpdateArticle: false,
  loadingSaveArticle: false,
  editArticleStatus: false,
  updateFavoriteTransaction: null,
  getArticleListResponse: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.getUnitMeasure:
      return {
        ...state,
        requestUnitMeasure: action.payload,
      };

    case types.getCategory:
      return {
        ...state,
        requestCategory: action.payload,
      };

    case types.getArticle:
      return {
        ...state,
        getArticlesResponse: action.payload,
      };

    case types.loadArticle:
      return {
        ...state,
        loadArticleObject: action.payload,
        editArticleStatus: !isEmpty(action.payload),
      };
    case types.sendIndexTab:
      return {
        ...state,
        requestTabIndex: action.payload,
      };

    case types.updateFavoriteArticle:
      return {
        ...state,
        updateFavoriteTransaction: action.payload,
      };
    case types.getFilterArticle:
      return {
        ...state,
        getfilterArticle: action.payload,
        resultArticle: true,
      };
    case types.cleanRequest:
      return {
        ...state,
        cleanRequest: true,
      };

    case types.loadingGetArticles:
      return {
        ...state,
        loadingGetArticles: action.payload,
      };

    case types.getArticleList:
      return {
        ...state,
        getArticleListResponse: action.payload,
        totalArticles: action.payload1,
      };

    case types.getArticleChecked:
      return {
        ...state,
        checkedArticles: action.payload,
      };

    case types.loadingUpdateArticle:
      return {
        ...state,
        loadingUpdateArticle: action.payload,
      };

    case types.loadingSaveArticle:
      return {
        ...state,
        loadingSaveArticle: action.payload,
      };

    default:
      return state;
  }
}
