/**
 * Función para generar objeto de articuloas para agregar al arreglo de articulos.
 * @param {Lista de los articulos que se vana a gregar a la factura} listproducts 
 */
export function buildrequetitems(listproducts) {

    let products = []

    listproducts.forEach(item => {
        products.push({
            Codigo: item.codigo,
            Descripcion: item.descripcion,
            Nombrearticulo :item.descripcion,
            Cantidad: item.cantidad,
            Valorunitario: item.valorNeto,
            Porcentajedescuento: item.porcentajedescuento,
            Valordescuento: item.valordescuento,
            Id: 0,
            Dianunidadmedidaid: item.dianunidadmedidaid,
            Articuloid: item.articuloid,
            Totaliva: item.totaliva,
            Totalinc: item.totalinc,
            Idstate: 0,
            Createdby: 1,
            Createdon: '1900-01-01',
            Modifiedby: 1,
            Modifiedon: '1900-01-01',
            Idbusinessunit: 1,
            Idowner: 1,
            Subtotal: item.subtotal,
            Tipodescuento: item.tipodescuento,
            Total: item.total,
            Totaldescuento: item.totaldescuento,
            Valordescuentocomercial: item.valordescuentocomercial,
            Prorrateo: item.prorrateo,
            Totaldescuentoreal: item.totaldescuentoreal,
            Subtotalreal: item.subtotalreal,
            Totalivareal: item.totalivareal,
            Totalincreal: item.totalincreal,
            Totalreal: item.totalreal,
            Costo: item.costo ?? 0,
            Porcentajeiva: item.porcentajeiva,
            Porcentajeinc: item.porcentajeinc,


            //Retenciones
            idretfte: item.idretfte,
            baseretfte: item.baseretfte,
            tarifaretfte: item.tarifaretfte,
            valorretfte: item.valorretfte,

            idretica: item.idretica,
            baseretica: item.baseretica,
            tarifaretica: item.tarifaretica,
            valorretica: item.valorretica,

            idretiva: item.idretiva,
            baseretiva: item.baseretiva,
            tarifaretiva: item.tarifaretiva,
            valorretiva: item.valorretiva,
        })
    });

    return products;

}





   

   
