import React from "react";

import { useSelector } from "react-redux";

import { FormatDecimal } from "../../../components/common/formatDecimal";
import { calculateTaxes } from "../../../utils/calculateTaxes";

export const TaxDetailArticle = ({ tax, classes }) => {
  const { impuestoid, otrovalor, cantidad, cantidadcc } = tax;

  const { taxList, allUvt } = useSelector((s) => s.configReducer);

  const taxName = taxList.find((t) => t.id === impuestoid);

  const { tipo, value } = taxName || {};

  return (
    <div className={classes.container}>
      <span className={classes.comunStyle}>
        {`${tipo}: `}
        <span className={classes.greenStyle}>
          {["IVA", "INC", "ICUI", "ADV"].includes(tipo) ? (
            <FormatDecimal
              value={value === 999 ? otrovalor : value}
              suffix={"%"}
              size={"10"}
            />
          ) : (
            <FormatDecimal
              value={calculateTaxes(value, tipo, cantidad, cantidadcc, allUvt)}
              prefix={"$"}
              suffix={null}
              size={"10"}
            />
          )}
        </span>
      </span>
      &nbsp;&nbsp;
    </div>
  );
};
