import React from "react";
import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

export const ExcelInvoice = ({ element, filename, data }) => {
  return (
    <>
      <ExcelFile element={element} filename={filename}>
        <ExcelSheet data={data} name="Informe">
          <ExcelColumn label="Numero de factura" value="numero" />
          <ExcelColumn label="Identificación tercero" value="documento" />
          <ExcelColumn label="Nombre tercero" value="razonSocial" />
          <ExcelColumn label="Sucursal" value="" />
          <ExcelColumn
            label="Código centro/subcentro de costos"
            value="codigoCentro"
          />
          <ExcelColumn label="Fecha de elaboración" value="fecha" />
          <ExcelColumn label="Nombre contacto" value="nombreContacto" />
          <ExcelColumn label="Email Contacto" value="correoElectronico" />
          <ExcelColumn label="Telefono de contacto" value="telefono" />
          <ExcelColumn label="Ciudad de contacto" value="ciudad" />
          <ExcelColumn label="Código producto" value="codigoProducto" />
          <ExcelColumn
            label="Descripción producto"
            value="descripcionProducto"
          />
          <ExcelColumn
            label="Identificación vendedor"
            value="identificacionVendedor"
          />
          <ExcelColumn label="Cantidad producto" value="cantidadProducto" />
          <ExcelColumn label="Valor unitario" value="valorUnitario" />
          <ExcelColumn label="Valor Descuento" value="valorDescuento" />
          <ExcelColumn label="Código impuesto cargo" value="valorCargos" />
          <ExcelColumn label="Código impuesto iva" value="valorIva" />
          <ExcelColumn label="%" value="porcentajeIva" />
          <ExcelColumn
            label="Código impuesto retención"
            value="valorRetencion"
          />
          <ExcelColumn label="%" value="porcentajeRetencion" />
          <ExcelColumn label="Código ReteICA" value="valorReteIca" />
          <ExcelColumn label="%" value="porcentajeReteIca" />
          <ExcelColumn label="Código ReteIVA" value="valorReteIva" />
          <ExcelColumn label="%" value="porcentajeReteIva" />
          <ExcelColumn label="Código forma de pago" value="formaPago" />
          <ExcelColumn label="Valor total de factura" value="valorTotal" />
          <ExcelColumn label="Fecha Vencimiento" value="fechaVencimiento" />
          <ExcelColumn label="Observaciones" value="observacion" />
          <ExcelColumn label="Documento de Referencia" value="documentoReferencia" />
          <ExcelColumn label="Tipo Documento" value="tipoDocumento" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
