/**
 * * Un objeto que mapea los tipos de documentos con las secciones que se deben mostrar del Carrito de Documentos
 * * Cada clave del objeto representa un tipo de documento y su valor es un array de nombres de secciones a renderizar em el carrito de documentos.
 *
 * @property {string[]} FA - Secciones a mostrar para el tipo de documento "Factura".
 * @property {string[]} CO - Secciones a mostrar para el tipo de documento "Cotización".
 * @property {string[]} DS - Secciones a mostrar para el tipo de documento "Documento Soporte".
 * @property {string[]} DE - Secciones a mostrar para el tipo de documento "Documento Equivalente".
 */
const documentSectionsMapping = {
  FA: [
    "sectionDocumentTotal",
    "sectionReteIva",
    "sectionRetIca",
    "sectionCharges",
    "sectionDiscounts",
  ],
  CO: [
    "sectionDocumentTotal",
    "sectionReteIva",
    "sectionRetIca",
    "sectionCharges",
    "sectionDiscounts",
  ],
  DS: ["sectionDocumentTotal", "sectionReteIva"],
  DE: ["sectionDocumentTotal"],
};

/**
 *  * Agrega la sección de notas si es necesario.
 * @param {Array} sections - Las secciones existentes.
 * @param {string} typeDocument - El tipo de documento.
 * @returns {Array} - Las secciones actualizadas.
 */
const addNotesSectionIfNeeded = (sections, typeDocument) => {
  const sectionsSet = new Set(sections);
  if (typeDocument.endsWith("_NOTE") && !sectionsSet.has("sectionNotes")) {
    sectionsSet.add("sectionNotes");
  }
  return [...sectionsSet];
};

/**
 * * Obtiene las secciones a mostrar según el tipo de documento.
 * @param {string} typeDocument - El tipo de documento.
 * @returns {Array} - Las secciones a mostrar.
 */
export const getSectionsToShow = (typeDocument) => {
  const documentType = typeDocument.replace(/_NOTE$/, "");
  const sections = documentSectionsMapping[documentType] || [];
  return addNotesSectionIfNeeded([...sections], typeDocument);
};
