import { useState } from "react";
import {
  regexAlphaNumeric,
  regexOnlyNumbers,
  regexEmail,
  regexPhone,
  regexNumericSpace,
  regexAlphaNumeric2,
  regexAlphaNumericSpace,
  regexLettersSpace,
} from "../helpers/customRegex.hepers";

export const useFormComplete = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = (e, rule) => {
    let value = !!e.target.value ? e.target.value : "";

    if (!!rule) {
      value = validateInput(value, rule);
    }

    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleCheckChange = ({ target }) => {
    const value = !!target.checked ? target.checked : false;
    setValues({
      ...values,
      [target.name]: value,
    });
  };

  const handlePickerChange = (id, date, name) => {
    setValues({
      ...values,
      [name]: date,
    });
  };

  const handleTooggleChange = (e, value, name) => {
    if (!!value) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleAutocompleteChange = (e, value, name) => {
    setValues({
      ...values,
      [name]: value ?? null,
    });
  };

  const handleUpdateForm = (newData) => {
    setValues({
      ...values,
      ...newData,
    });
  };

  const setValue = (value, name) => {
    setValues({
      ...values,
      [name]: value ?? null,
    });
  };

  return {
    values,
    handleInputChange,
    reset,
    handleTooggleChange,
    handleUpdateForm,
    handleCheckChange,
    handleAutocompleteChange,
    handlePickerChange,
    setValue,
    initialState,
  };
};

const validateInput = (value, rule) => {
  switch (rule) {
    case "number":
      return value.replace(regexOnlyNumbers, "");
    case "alphanumeric":
      return value.replace(regexAlphaNumeric, "");
    case "alphanumeric2":
      return value.replace(regexAlphaNumeric2, "");
    case "alphanumericspace":
      return value.replace(regexAlphaNumericSpace, "");
    case "lettersSpace":
      return value.replace(regexLettersSpace, "");
    case "phone":
      return value.replace(regexPhone, "");
    case "email":
      return value.replace(regexEmail, "");
    case "numericSpace":
      return value.replace(regexNumericSpace, "");
    default:
      return value;
  }
};
