import React, { useCallback, useEffect } from "react";

import { useSelector, shallowEqual } from "react-redux";

import { Grid } from "@material-ui/core";

import { format } from "date-fns";

import { GroupRangeDate } from "../../../document/ui/groupRangeDate.component";
import DateForm from "../../../../components/datepicker/datepicker.component";
import ExpandableSelect from "../../../../components/select/expandableSelect.component";
import InputForm from "../../../../components/input/input.component";
import SelectForm from "../../../../components/select/select.component";
import ToggleButtons from "../../../../components/toggle/toggle.component";

import {
  getDateMinPay,
  getDateNotAfter,
  getDateNotBefore,
} from "../../../../utils/rangeDates";

export const DataEquivalentDoc = ({
  branchOfficeID,
  dateRange,
  dianPaymentMethodID,
  documentDate,
  documentType,
  handleInputChange,
  handleUpdateForm,
  numericalID,
  observations,
  paymentDate,
  paymentMethod,
  validator,
}) => {
  const {
    activeBranches,
    activeEquivalentDocTypes,
    activeResolutionsDoc,
  } = useSelector(
    (state) => state.equivalentDocReducer.initialLoadCreateEquivalentDoc,
    shallowEqual
  );

  const { defaultPaymentMethods, othersPaymentMethods } = useSelector(
    (s) => s.configReducer,
    shallowEqual
  );

  useEffect(() => {
    handleUpdateForm({
      numericalID:
        activeResolutionsDoc.length > 0 ? activeResolutionsDoc[0].id : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeResolutionsDoc]);

  useEffect(() => {
    if (documentType === "" && activeEquivalentDocTypes.length > 0) {
      handleUpdateForm({
        documentType: activeEquivalentDocTypes[0].value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType, activeEquivalentDocTypes]);

  /**
   * * Maneja el evento de cambio de fecha.
   * @param {Event} _ - El evento de cambio de fecha.
   * @param {Date} date - La fecha seleccionada.
   */
  const handleDate = (_, date) => {
    let range = dateRange > 0 ? dateRange : 1;
    let datepay = new Date(date);
    datepay.setDate(datepay.getDate() + range);
    handleUpdateForm({
      documentDate: new Date(date).toISOString(),
      paymentDate: paymentMethod === "1" ? datepay.toISOString() : "",
    });
  };

  /**
   * * Maneja el cambio del método de pago.
   * @param {Event} e - El evento del cambio.
   * @param {string} value - El valor seleccionado del método de pago.
   * @returns {void}
   */
  const handleChangePaymentMethod = (e, value) => {
    const defaultPay = defaultPaymentMethods.find((p) => p.codigo === "10");
    handleUpdateForm({
      dianPaymentMethodID: value === "1" ? "" : defaultPay?.id ?? "",
      paymentMethod: value ?? paymentMethod,
    });
    validator.purgeFields();
  };

  /**
   * * Actualiza el formulario con la fecha de pago y el rango de fechas.
   * @param {string} id - El ID del documento equivalente.
   * @param {string} date - La fecha de pago en formato de cadena.
   * @param {string} name - El nombre del documento equivalente.
   * @returns {void}
   */
  const handleDatePay = (id, date, name) => {
    handleUpdateForm({
      dateRange: 0,
      paymentDate: new Date(date).toISOString(),
    });
  };

  /**
   * * Cambia el rango de fechas y actualiza el formulario.
   * @param {number} value - El nuevo valor del rango de fechas.
   */
  const changeRange = useCallback(
    (value) => {
      let date = documentDate || new Date();
      let datePay = new Date(date);
      let days = parseInt(value);
      datePay.setDate(datePay.getDate() + days - 1); //Cuenta dia actual
      handleUpdateForm({
        dateRange: value,
        paymentDate: format(datePay, "yyyy/MM/dd"),
      });
    },
    [documentDate, handleUpdateForm]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <SelectForm
          label={"Tipo de documento"}
          name="documentType"
          onChange={handleInputChange}
          options={activeEquivalentDocTypes}
          validateOptions={"required"}
          validator={validator}
          value={documentType}
          disabled={activeEquivalentDocTypes.length === 0}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <DateForm
          label="Fecha de documento"
          maxDate={getDateNotAfter()}
          minDate={getDateNotBefore()}
          name={"documentDate"}
          onChange={handleDate}
          validateOptions={"required"}
          validator={validator}
          value={documentDate}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <ToggleButtons
          label={"Forma de pago"}
          name={"paymentMethod"}
          onChange={handleChangePaymentMethod}
          titleOne={"Crédito"}
          titleTwo={"Contado"}
          value={paymentMethod}
        />
      </Grid>
      {paymentMethod === "1" ? (
        <>
          <Grid item lg={3} xs={12}>
            <DateForm
              label="Fecha de Pago"
              minDate={getDateMinPay(documentDate)}
              name={"paymentDate"}
              onChange={handleDatePay}
              validateOptions={
                paymentMethod === "1"
                  ? ["required", { dateNotLessThanOrEqual: documentDate }]
                  : "void"
              }
              validator={validator}
              value={paymentDate}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <GroupRangeDate value={dateRange} onChange={changeRange} />
          </Grid>
        </>
      ) : (
        <Grid item lg={6} xs={12}>
          <ExpandableSelect
            label={"Medio Pago"}
            name={"dianPaymentMethodID"}
            onChange={handleInputChange}
            options={defaultPaymentMethods}
            optionsSeeMore={othersPaymentMethods}
            validateOptions={paymentMethod === "1" ? "void" : "required"}
            validator={validator}
            value={dianPaymentMethodID}
          />
        </Grid>
      )}
      <Grid item lg={6} xs={12}>
        <SelectForm
          label={"Sucursal"}
          name="branchOfficeID"
          onChange={handleInputChange}
          options={activeBranches}
          validateOptions={"required"}
          validator={validator}
          value={branchOfficeID}
          disabled={activeBranches.length === 0}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <SelectForm
          label={"Resolución"}
          name="numericalID"
          onChange={handleInputChange}
          options={activeResolutionsDoc}
          validateOptions={"required"}
          validator={validator}
          value={numericalID}
          disabled={activeResolutionsDoc.length === 0}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <InputForm
          label={"Observaciones"}
          maxLength={1000}
          multiline={true}
          name="observations"
          onChange={handleInputChange}
          value={observations}
        />
      </Grid>
    </Grid>
  );
};
