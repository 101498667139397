import React, { Fragment } from "react";

import { Grid, makeStyles, Link } from "@material-ui/core";

import StyledCheckbox from "../checkForm/styledCheckbox.component";

const filters = [
  { name: "Pagadas", label: "Pagadas" },
  { name: "Pendientes", label: "Por pagar" },
  { name: "Vencidas", label: "En mora" },
  { name: "Destacadas", label: "Destacadas" },
  { name: "Anulacion", label: "Anuladas" },
  // { name: "Notadebito", label: "Notas débito" },
  // { name: "Notacredito", label: "Notas crédito" },
];
const useStyles = makeStyles({
  letter: {
    color: "#FFFFFF",
  },
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
});

export const ModalFilters = (handlecheckFilters, filterData, cleanFilter) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container justify="flex-start" alignItems="center">
        {filters.map(({ name, label }) => (
          <Fragment key={name}>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                checked={filterData[name]}
                onChange={handlecheckFilters}
                name={name}
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              {label}
            </Grid>
          </Fragment>
        ))}
        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <Link
            href="#"
            onClick={cleanFilter}
            variant="inherit"
            underline="none"
            className={classes.link}
          >
            Limpiar Filtro
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
