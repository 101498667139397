import React, { useEffect, useState } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';

import {
  addObservationsNote,
  addReasonNoteAction,
} from '../../../../actions/documentCartAction';
import { getReasonNotes } from '../../../../actions/invoiceActions';

import { CollapseList } from '../../ui/collapseList';
import { ReasonCard } from '../../reasonNote/reasonCard';
import { ReasonsList } from '../../reasonNote/reasonsList';
import InputForm from '../../../../components/input/input.component';

import { getButtonTextSelect } from '../utils';

export const NotesSection = ({ typedocument }) => {
  const [expandMotives, setExpandMotives] = useState(false);
  const [observations, setObservations] = useState('');

  const dispatch = useDispatch();

  const { motivonota, observacionesnota } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getReasonNotes());
  }, [dispatch]);

  /**
   * * Carga las observaciones en el estado local.
   * @returns {void}
   */
  useEffect(() => {
    setObservations(observacionesnota ?? '');
  }, [observacionesnota]);

  /**
   * * Actualiza el estado local con el nuevo valor de las observaciones.
   * @param {*} event - El evento que desencadenó el cambio.
   * @returns {void}
   */
  const handleChangeObservations = ({ target }) => {
    setObservations(target.value);
  };

  /**
   * * Alterna la expansión o contracción del listado de motivos.
   * @returns {void}
   */
  const handleExpandMotives = () => {
    setExpandMotives((prev) => !prev);
  };

  /**
   * * Carga la selección de un motivo para la edición de la nota.
   * @param {*} reason - El motivo seleccionado para la edición de la nota.
   * @returns {void}
   */
  const handleSelectionReason = (reason) => {
    dispatch(addReasonNoteAction(reason));
    setExpandMotives(false);
  };

  /**
   * * Actualiza las observaciones en el estado Redux al perder el foco del campo de observaciones.
   * @param {*} event - El evento blur que desencadenó la actualización de las observaciones.
   * @returns {void}
   */
  const handelBlurObservation = ({ target }) => {
    dispatch(addObservationsNote(target.value));
  };

  return (
    <>
      <Grid item lg={12} xs={12}>
        <CollapseList
          component={<ReasonsList handleSelection={handleSelectionReason} />}
          onClick={handleExpandMotives}
          open={expandMotives}
          title={getButtonTextSelect(typedocument, 'NOTE')}
        />
      </Grid>
      {!!motivonota && (
        <Grid item lg={12} xs={12}>
          <ReasonCard {...motivonota} />
        </Grid>
      )}
      <Grid item lg={12} xs={12}>
        <InputForm
          label={'Observaciones *'}
          maxLength={500}
          multiline={true}
          name='observaciones'
          noMargin={true}
          onBlur={handelBlurObservation}
          onChange={handleChangeObservations}
          value={observations}
        />
      </Grid>
    </>
  );
};
