import { types } from "../types/types"


const initialState = {
    contacts: [],
    active: null,
    loading: false

}

export const contactCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONTACT_LIST:
            return {
                ...state,
                contacts: action.payload
            }
        case types.ADD_CUSTOMER_CONTACT:
            return {
                ...state,
                contacts: [...state.contacts, action.payload]
            }
        case types.UPDATE_CUSTOMER_CONTACT:
            return {
                ...state,
                contacts: state.contacts.map(item =>
                    item.id === action.payload.id
                        ? action.payload
                        : item)
            }
        case types.DELETE_CUSTOMER_CONTACT:
            return {
                ...state,
                contacts: state.contacts.filter(c => c.id !== action.payload)
            }
        case types.SET_ACTIVE_CUSTOMER_CONTACT:
            return {
                ...state,
                active: action.payload
            }
        case types.CLEAN_CUSTOMER_CONTACT:
            return initialState;

        default:
            return state
    }
}