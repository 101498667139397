/**
 * * Evita que se ingrese un espacio en blanco si el input está vacío.
 * @param {Event} event - El evento del teclado.
 */
export const preventSpaceIfEmpty = (event) => {
  const inputValue = event.target.value;
  if (event.key === " " && inputValue.trim() === "") {
    event.preventDefault();
  }
};
