import React from 'react';
import { makeStyles } from '@material-ui/styles';

import imgstep4 from '../../images/wizard/wizard_4.png'
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    image: {
        width: '70%',
        height: 'auto',
        position: 'absolute',
        right: '20%',
        zIndex: 40
    },
    message: {
        right: '4%',
        height: 'auto',
        zIndex: 0,
        position: 'absolute',
        width: '24vw',
        padding: '4vh',
        textAlign: 'center',
        top:'30%',
        color: theme.palette.primarycolor,
        backgroundColor: '#16b1f3',
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '2vw',
        fontStyle: 'italic',
        fontSize: '1.4vw !important',
        justifyContent: 'center',

    },
    containerImage: {
        width: '45%',
        margin: '0 auto',
        position: 'relative',
        height: '65vh'
    }

}));

const Step4 = () => {
    const classes = useStyles();
  

    return (
        <div className={classes.containerImage}>
            <img  alt='' src={imgstep4} className={classes.image} ></img>
            <Typography className={classes.message}>
                Perfecto!!! Ahora vamos a realizar la configuración básica de tu
                empresa para que puedas empezar a facturar lo antes posible.
                </Typography>
        </div>

    );
}

export default Step4;