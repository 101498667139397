import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { FormatDecimal } from "../../../components/common/formatDecimal";
import { formatDate } from "../../../utils/general";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import { getStyleStatusItem } from "../getStyleStatusItem";

const useStyles = makeStyles({
  containerLoading: {
    paddingTop: 200,
  },
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  colorIcon: {
    color: "#2CC63E",
  },
  iconsDetail: {
    display: "flex",
    alignItems: "center",
  },
});

const DetailSupportInfo = ({
  detailDocumentSupport,
  documentSupportFilter,
}) => {
  const classes = useStyles();
  const dat = documentSupportFilter?.find(
    (item) => item.id === detailDocumentSupport?.id
  );

  return (
    <>
      <Grid container>
        <Grid item xs={6} className="detailSupportNumber">
          No. {detailDocumentSupport.prefijo} -
          {detailDocumentSupport.consecutivo}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <div
            className={
              dat?.favorito === true ? classes.showLayer : classes.hideLayer
            }
          >
            <StarIcon />
          </div>
          <div
            className={
              dat?.favorito === false ? classes.showLayer : classes.hideLayer
            }
          >
            <StarBorderIcon />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          lg={6}
          sm={12}
          style={{ textAlign: "left" }}
          className={getStyleStatusItem(dat)}
        >
          <FormatDecimal
            value={detailDocumentSupport?.valorAPagar}
            prefix={"$"}
            size="small"
          />
        </Grid>
        <Grid item lg={6} sm={12} className={getStyleStatusItem(dat)}>
          {dat?.anulacion ? "Anulada" : dat?.estado}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={6} sm={12} className="dataInvoiceGrid">
          {detailDocumentSupport?.fecha !== null &&
          detailDocumentSupport?.fecha !== undefined
            ? formatDate(new Date(detailDocumentSupport.fecha), "dd/MM/yyyy")
            : "-"}
        </Grid>
        <Grid item lg={6} sm={12}></Grid>
      </Grid>
      <div className="lineDivisionSupport" />
      <>
        <div className="constumerNameDetailSupport">
          {detailDocumentSupport.razonSocial}
        </div>
        <div className="detailSupportInfo">
          {detailDocumentSupport.prefijoDoc}{" "}
          {detailDocumentSupport.numeroDocumentoProveedor}
        </div>
        <div className="detailInvoiceInfo">
          {detailDocumentSupport.descTipoRegimen}
        </div>
        <div className="spacingInvoice"></div>
        <Grid
          container
          style={{
            marginTop: "12px",
          }}
        >
          <Grid item xs={12} className={classes.iconsDetail}>
            <EmailIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={classes.wordBreak}>
              {detailDocumentSupport.correoElectronico}
            </div>
          </Grid>

          <Grid item xs={12} className={classes.iconsDetail}>
            <PhoneIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={classes.wordBreak}>
              {detailDocumentSupport.telefono}
            </div>
          </Grid>

          <Grid item xs={12} className={classes.iconsDetail}>
            <RoomIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={classes.wordBreak}>
              {detailDocumentSupport.direccion}
            </div>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default DetailSupportInfo;
