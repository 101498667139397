export const encryptionKey = [
  227,
  144,
  75,
  59,
  108,
  168,
  38,
  75,
  193,
  195,
  63,
  125,
  144,
  17,
  12,
  148,
  217,
  49,
  188,
  190,
  52,
  189,
  68,
  134,
  106,
  170,
  255,
  88,
  27,
  183,
  249,
  88
];

export const encryptionIv = [
  58,
  237,
  223,
  159,
  226,
  61,
  73,
  88,
  156,
  25,
  99,
  11,
  144,
  81,
  32,
  101
];