import React from "react";

import { Tooltip, makeStyles, withStyles } from "@material-ui/core";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import { TooltipMessageReadingMode } from "../tooltip/TooltipMessageReadingMode";

const useStyles = makeStyles({
  container: {
    color: "#fff",
    display: "grid",
    gap: "2%",
    gridTemplateColumns: "repeat(1,1fr)",
  },
  contReadingMode: {
    backgroundColor: "#373737",
    color: "#8B8B8B",
    cursor: "not-allowed",
  },
  item: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.18)",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    gridAutoColumns: "1fr",
    justifyContent: "center",
    padding: "1%",
  },
});

export const ButtonCreateDoc = ({ isReadingMode, onClick, title }) => {
  const classes = useStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2F2E2E",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      maxWidth: 280,
    },
  }))(Tooltip);

  return (
    <HtmlTooltip title={isReadingMode ? <TooltipMessageReadingMode /> : ""}>
      <div className={classes.container} onClick={onClick}>
        <div
          className={`${classes.item} ${
            isReadingMode ? classes.contReadingMode : ""
          }`}
        >
          {title} &nbsp;&nbsp;&nbsp;
          <AddCircleRoundedIcon />
        </div>
      </div>
    </HtmlTooltip>
  );
};
