import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";
import { CollapseFormPanel } from "../../components/ui/collapseFormPanel";
import { AddressForm } from "./addressesCustomer/addressForm";
import { ContactForm } from "./contactsCustomer/contactForm";
import { AddressFormCard } from "./addressesCustomer/addressFormCard";

import {
  setActiveAddress,
  deleteAddressAction,
} from "../../actions/addressCustomerAction";
import {
  setActiveContactAction,
  deleteContactAction,
} from "../../actions/contactCustomerAction";
import { ConfirmAlert } from "../../helpers/alert.helpers";
import { ContactFormCard } from "./contactsCustomer/contactFormCard";

import { MESSAGE_DELETE_CONFIRM } from "../../config/config";

export const AdditionalDataCustomer = () => {
  const dispatch = useDispatch();
  const [expandAddressPanel, setExpandAddressPanel] = useState(false);
  const [expandContactPanel, setExpandContactPanel] = useState(false);

  const { addresses, active: activeAddress } = useSelector(
    (state) => state.addressCustomerReducer
  );
  const { contacts, active: activeContact } = useSelector(
    (state) => state.contactCustomerReducer
  );

  /**
   * Expande o contrae formulario direccion adicional
   */
  const handleExpandAddressPanel = () => {
    setExpandAddressPanel((prev) => !prev);
  };

  /**
   * Expande o contrae formulario contacto adicional
   */
  const handleExpandContactPanel = () => {
    setExpandContactPanel((prev) => !prev);
  };

  /**
   * Carga información de direccion en redux
   * @param {*} address
   */
  const handleEditAddress = useCallback(
    (address) => {
      dispatch(setActiveAddress(address));
      setExpandAddressPanel(true);
    },
    [dispatch]
  );

  /**
   * Borra dirección adicional
   * @param {*} address
   */
  const handleDeleteAddress = useCallback(
    (address) => {
      const deleteData = (confirmed) => {
        if (confirmed) {
          dispatch(deleteAddressAction(address.id));
          dispatch(setActiveAddress(null));
          setExpandAddressPanel(false);
        }
      };
      ConfirmAlert(
        `¿Estás seguro de eliminar esta dirección?`,
        deleteData,
        MESSAGE_DELETE_CONFIRM
      );
    },
    [dispatch]
  );

  /**
   * Carga información de direccion en redux
   * @param {*} address
   */
  const handleEditContact = useCallback(
    (contact) => {
      dispatch(setActiveContactAction(contact));
      setExpandContactPanel(true);
    },
    [dispatch]
  );

  /**
   * Borra dirección adicional
   * @param {*} address
   */
  const handleDeleteContact = useCallback(
    (contact) => {
      const deleteData = (confirmed) => {
        if (confirmed) {
          dispatch(deleteContactAction(contact.id));
          dispatch(setActiveContactAction(null));
          setExpandContactPanel(false);
        }
      };
      ConfirmAlert(
        `¿Estás seguro de eliminar este contacto?`,
        deleteData,
        MESSAGE_DELETE_CONFIRM
      );
    },
    [dispatch]
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} xs={12}>
        <CollapseFormPanel
          component={<AddressForm />}
          title={
            !activeAddress
              ? "Agregar dirección adicional"
              : "Editar dirección adicional"
          }
          titleOpen={
            !activeAddress
              ? "Agregar dirección adicional"
              : "Editar dirección adicional"
          }
          open={expandAddressPanel}
          onClick={handleExpandAddressPanel}
          backgroundColor={"#5A5A5A"}
        />
      </Grid>
      {addresses.map((item) => (
        <Grid item key={item.id} lg={4} xs={12}>
          <AddressFormCard
            handleEdit={() => handleEditAddress(item)}
            handleDelete={() => handleDeleteAddress(item)}
            {...item}
          />
        </Grid>
      ))}

      <Grid item lg={12} xs={12}>
        <CollapseFormPanel
          component={<ContactForm />}
          title={
            !activeContact
              ? "Agregar contacto adicional"
              : "Editar contacto adicional"
          }
          titleOpen={
            !activeContact
              ? "Agregar contacto adicional"
              : "Editar contacto adicional"
          }
          onClick={handleExpandContactPanel}
          open={expandContactPanel}
          backgroundColor={"#5A5A5A"}
        />
      </Grid>
      {contacts.map((item) => (
        <Grid item key={item.id} lg={4} xs={12}>
          <ContactFormCard
            handleEdit={() => handleEditContact(item)}
            handleDelete={() => handleDeleteContact(item)}
            {...item}
          />
        </Grid>
      ))}
    </Grid>
  );
};
