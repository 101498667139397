import React, { useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import SimpleReactValidator from "simple-react-validator";

import ButtonPrimary from "../../../../components/button/buttonPrimary.component";
import InputForm from "../../../../components/input/input.component";

import { createEquivalentDocAction } from "../../../../actions/equivalentDocAction";
import { generateRequestEquivalentDocNote } from "../../utils/generateRequestEquivalentDoc";

import { reactValidatorOptions } from "../../../../helpers/simpleReactValidator";
import { errorToast, successToast, warningToast } from "../../../../helpers/toast.helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    fontSize: 15,
    padding: "0px 30px 25px 30px !important",
    textAlign: "justify",
  },
  title: {
    color: "#16B1F3",
    fontSize: 25,
    fontWeight: 600,
  },
  subtitle: {
    color: "#16B1F3",
    fontSize: 18,
  },
  containerTitle: {
    paddingBottom: "20px !important",
    paddingTop: "20px !important",
    textAlign: "center",
  },
}));
export const AnnulmentNoteModal = ({ handleCloseModal }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [observations, setObservations] = useState("");
  const [, forceUpdate] = useState();

  const [isLoadingAnnulment, setIsLoadingAnnulment] = useState(false);

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions));

  const { messageModalAnnulmentEquivalentDoc } = useSelector(
    (state) => state.menuReducer.menuInvoice,
    (prev, next) => prev.menuInvoice === next.menuInvoice
  );

  const { equivalentDocDetail } = useSelector(
    (state) => state.equivalentDocReducer,
    (prev, next) => prev.equivalentDocDetail === next.equivalentDocDetail
  );

  const totals = useSelector((state) => state.documentCartReducer);

  /**
   * Valida formulario, continua proceso edición
   */
  const handleClickContinue = () => {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    } else {
      const note = generateRequestEquivalentDocNote({
        equivalentDocNote: equivalentDocDetail,
        totalsCart: totals,
        isAnnulment: true,
        observationAnnulment: observations,
      });

      setIsLoadingAnnulment(true);
      dispatch(createEquivalentDocAction(note, true, actionResponse, true));
    }
  };

  const actionResponse = (response, error = false) => {
    handleCloseModal();
    if (error && response?.data?.statusMessage !== "Unauthorized, no quota") {
      errorToast("Ocurrió algo inesperado", `${JSON.stringify(response?.data?.statusMessage ?? "Error inesperado, no se ha podido anular factura")}`);
    }
    if (response?.data?.statusCode === "201") {
      successToast("", `El documento ${equivalentDocDetail.numero} fue anulado de manera exitosa, sigamos facturando`, "top-center");
    } else {
      warningToast(
        "Ocurrió algo inesperado",
        `La factura ${equivalentDocDetail.numero} fue anulada de manera exitosa, ${response?.data?.result}. ${response?.data?.statusMessage}`
      );
    }
  };

  return (
    <Grid container spacing={1} className={`${classes.root} animate__animated animate__fadeIn animate__faster`}>
      <Grid item lg={12} xs={12} className={classes.containerTitle}>
        <span className={classes.title}>Anular Documento equivalente</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div
          dangerouslySetInnerHTML={{
            __html: messageModalAnnulmentEquivalentDoc?.replace("NUMERO", equivalentDocDetail.numero) ?? "",
          }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <InputForm
          // disabled={loading}
          label={"Observaciones *"}
          maxLength={500}
          multiline={true}
          name="observaciones"
          noMargin={true}
          onChange={(e) => setObservations(e.target.value)}
          validateOptions={"required"}
          validator={validator.current}
          value={observations}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems="center" justify="center">
          <Grid item lg={6} xs={6}>
            <ButtonPrimary loading={isLoadingAnnulment} onClick={handleClickContinue} text="Continuar" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
