import { axiosApiInstance } from '../config/axios-instance'

import { API_ENDPOINT_ARTICLE, defaultHeaders } from "../config/config";
import { enterpriseIdHeader } from './authActions';
import { types } from '../types/types';


const urlEndPoint = `${API_ENDPOINT_ARTICLE}/item/api/cargo`;
let allCharges = [];


export const getCharges = (filter = '', refresh = true) => async (dispatch) => {

  try {
    changeGetListLoading(dispatch, true);

    if (allCharges.length > 0 && refresh === false) {
      dispatch({
        type: types.GET_CHARGELIST,
        payload: filerList(allCharges, filter),
      });

    } else {

      let config = defaultHeaders();
      Object.assign(config.headers, {
        'enterpriseId': `${enterpriseIdHeader()}`,
      });

      const url = `${urlEndPoint}/getallbyenterpriseid`;
      var response = await axiosApiInstance.get(url, config);

      allCharges = response.data.result.map(item => ({
        ...item,
        text: item.descripcion
      }));

      dispatch({
        type: types.GET_CHARGELIST,
        payload: (filter !== '') ? filerList(allCharges, filter) : allCharges
      });

    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar listado cargos.',
        error: err
      }
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }
};

//
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_CHARGES,
    payload: status
  })
}


export const getCharge = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/getdatabyid`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });

    var response = await axiosApiInstance.get(url, config);
    dispatch({
      type: types.GET_CHARGE,
      payload: response.data,
    });

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar información de cargo.',
        error: err
      }
    });
  }
};

export const getFilterCharge = (data) => async (dispatch) => {
  dispatch({
    type: types.GET_FILTER_CHARGE,
    payload: data,
  });
};

export const changeEditingChargeStatus = (data) => async (dispatch) => {
  dispatch({
    type: types.CHANGE_EDITING_CHARGE_STATUS,
    payload: data,
  });
};

/**
 * @action
 * @description Guarda cargo nuevo
 * @param {object} data Información cargp
 */
export const postCharge = (data) => async (dispatch) => {

  try {

    changeSaveLoading(dispatch, true);

    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    }

    const url = `${urlEndPoint}/createcharge`;

    var response = await axiosApiInstance.post(url, data, defaultHeaders());

    dispatch({
      type: types.SAVE_CHARGE,
      payload: response.data,
    });

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido registrando información de cargo.',
        error: err
      }
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }
}


export const putCharge = (data) => async (dispatch) => {

  try {

    changeSaveLoading(dispatch, true);
    const url = `${urlEndPoint}/updatecharge`;

    var response = await axiosApiInstance.put(url, data, defaultHeaders());

    dispatch({
      type: types.UPDATE_CHARGE,
      payload: response.data,
    });

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido actualizando información de cargo.',
        error: err
      }
    });

  } finally {
    changeSaveLoading(dispatch, false);
  }
};


function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_CHARGE,
    payload: status
  })
}


export const deleteCharge = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/deletecharge`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });


    var response = await axiosApiInstance.delete(url, config);
    dispatch({
      type: types.DELETE_CHARGE,
      payload: response.data,
    });

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido eliminando información de cargo.',
        error: err
      }
    });

  }
};

export const clearCurrentCharge = () => async dispatch => {
  dispatch({
    type: types.GET_FILTER_CHARGE,
    payload: null
  })
}

export const clearUpdateCharge = () => async dispatch => {
  dispatch({
    type: types.UPDATE_CHARGE,
    payload: null
  })
}

export const clearCreateCharge = () => async dispatch => {
  dispatch({
    type: types.SAVE_CHARGE,
    payload: null
  })
}

export const clearDeleteCharge = () => async dispatch => {
  dispatch({
    type: types.DELETE_CHARGE,
    payload: null
  })
}

//Filtra listado cargos
function filerList(list, filter) {
  try {

    if (!list) return list;

    const data = list.filter(x => {
      const descripcion = x.descripcion.toLowerCase();
      const valor = x.valor;
      const query = `${descripcion} ${valor}`;
      const modValue = filter.toLowerCase();
      return query.indexOf(modValue) > -1;
    });

    return data;
  } catch (err) {
    console.log("Error Filtrando listado cargos:", err);
  }
}
