import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Grid, IconButton } from '@material-ui/core/';
import { getFilePreview } from '../../actions/invoiceActions';
import CustomProgress from '../../components/Progress/progress.component';

import './modal.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.modalBackgroundColor,
  },
  paper: {
    height: '100%',
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
    color: theme.palette.primaryColor,
  },
  iconClose: {
    color: theme.palette.thirdColor,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'right',
    textAlign: 'right',
  },
  logoColfactura: {
    height: '51px',
    width: '162.25px',
  },
  divContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'left',
    textAlign: 'center',
  },
  titleModal: {
    fontSize: '30px',
    fontWeight: '800',
    fontFamily: 'Muli',
  },
  subTitleModal: {
    color: theme.palette.primaryColor,
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Muli',
  },
  bodyModal: {
    color: theme.palette.primaryColor,
    fontWeight: '800',
    fontFamily: 'Muli',
    marginBottom: '2rem',
  },
  successTitle: {
    color: theme.palette.thirdColor,
  },
  warningTitle: {
    color: theme.palette.fourthColor,
  },
  dangerTitle: {
    color: theme.palette.fifthColor,
  },
  showObject: {
    display: 'block'
  },
  hideObject: {
    display: 'none'
  }
}));

function FileModalData({ paramFile, closeElement, onCloseElement, open, getFilePreview }) {
  const classes = useStyles();
  const [fileobj, setFileobj] = React.useState({
    archivoBase64: null
  });
  const windowsHeight = parseInt((window.innerHeight) * 0.78, 10);
  const heightDiv = windowsHeight + 'px';

  const [loading, setLoading] = React.useState(true);

  const functionResp = (response) => {

    setFileobj({
      archivoBase64: response
    });
    setLoading(false);
  }


  useEffect(() => {

    if (paramFile !== null) {
      setLoading(true);
      getFilePreview(paramFile, functionResp, onCloseElement);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramFile]);


  const contentModal = (
    <div className={classes.paper}>
      <Grid container>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}></Grid>
        <Grid item lg={2} md={2} sm={12}>
          <IconButton className={classes.iconClose} onClick={onCloseElement}  >
            {closeElement}
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={1} md={1} sm={12}></Grid>
        <Grid item lg={10} md={10} sm={12}>
          <div className={classes.divContainer} >
            <div className={classes.bodyModal}>
              {loading ? <Fragment>  Cargando archivo<CustomProgress /></Fragment> : ''}
              <div className={fileobj === null ? classes.showObject : fileobj.archivoBase64 == null ? classes.showObject : classes.hideObject}>
                {loading ? '' : 'No se encontró el archivo '}
              </div>
              <div className={fileobj === null || loading ? classes.hideObject : fileobj.archivoBase64 == null ? classes.hideObject : classes.showObject}>
                <iframe src={'data:application/pdf;base64,' + fileobj.archivoBase64} title="File" height={heightDiv} width={'100%'}>
                </iframe>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={2} md={2} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={4} md={4} sm={12}>
        </Grid>
        <Grid item lg={4} md={4} sm={12}></Grid>
      </Grid>
    </div >
  );

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="response-modal-title"
        aria-describedby="response-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {contentModal}
        </Fade>
      </Modal>
    </div>
  );
}

FileModalData.propTypes = {
  closeElement: PropTypes.string.isRequired,
  onCloseElement: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  file: state.invoiceReducer.file
});

const mapDispatchToProps = {
  getFilePreview
};

export default connect(mapStateToProps, mapDispatchToProps)(FileModalData);

