import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import QuotationGrid from "../quotation/quotationGrid.view";
import CustomTabs from "../../../components/tabs/tabs.component";
import { CreateQuotationView } from "../../document/createQuotationView";
import { InvoiceQuoteView } from "../../document/invoiceQuoteView";

import {
  cleanDocumentCreateAction,
  loadQuotationDataAction,
} from "../../../actions/documentAction";
import { getAditionalData } from "../../../actions/invoiceActions";

import { getDataEditDocument } from "../../../helpers/dataDocumentSaved";
import { getEnterpriseId } from "../../../utils/dataUserLogin";
import "../../../App.css";
import "../../../styles/invoice.css";

const Quotation = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showCreate: false,
    showGrid: true,
    showEdit: false,
    showBill: false,
    objHead: null,
  });

  const empresaId = () => {
    return JSON.parse(localStorage.getItem("dataCompany")).companyId;
  };

  const userId = () => {
    return "";
  };

  useEffect(() => {
    dispatch(cleanDocumentCreateAction());
  }, [dispatch]);

  /**
   * Carge inicial
   */
  useEffect(() => {
    dispatch(getAditionalData(getEnterpriseId(), 0));
  }, [dispatch]);

  const handleBack = () => {
    setState({
      ...state,
      showGrid: true,
      showCreate: false,
      showEdit: false,
      showBill: false,
    });
  };

  const handleCreate = () => {
    setState({
      ...state,
      showGrid: false,
      showCreate: true,
      showEdit: false,
      showBill: false,
    });
  };

  const handleEdit = (document) => {
    const quotation = getDataEditDocument(document);
    dispatch(loadQuotationDataAction(quotation));

    setState({
      ...state,
      showGrid: false,
      showCreate: false,
      showEdit: true,
      showBill: false,
    });
  };

  const handleBill = (document) => {
    const datadocument = getDataEditDocument(document);
    dispatch(loadQuotationDataAction(datadocument));
    setState({
      ...state,
      showGrid: false,
      showCreate: false,
      showEdit: false,
      showBill: true,
    });
  };

  const getTabs = () => {
    return [
      {
        title: "Emitidas",
        component: (
          <QuotationGrid
            HandleCreate={handleCreate}
            HandleEdit={handleEdit}
            HandleBill={handleBill}
            Change={Math.random()}
            Empresaid={empresaId()}
            Userid={userId()}
            Type={0}
          />
        ),
      },
      {
        title: "Aprobadas",
        component: (
          <QuotationGrid
            HandleCreate={handleCreate}
            HandleEdit={handleEdit}
            HandleBill={handleBill}
            Change={Math.random()}
            Empresaid={empresaId()}
            Userid={userId()}
            Type={2}
          />
        ),
      },
      {
        title: "Rechazadas",
        component: (
          <QuotationGrid
            HandleCreate={handleCreate}
            HandleEdit={handleEdit}
            HandleBill={handleBill}
            Change={Math.random()}
            Empresaid={empresaId()}
            Userid={userId()}
            Type={4}
          />
        ),
      },
    ];
  };

  return (
    <>
      {state.showGrid && (
        <div className="container-form">
          <CustomTabs tabs={getTabs()} />
        </div>
      )}

      {state.showCreate && <CreateQuotationView HandleBack={handleBack} />}

      {state.showEdit === true && (
        <CreateQuotationView HandleBack={handleBack} editQuotation={true} />
      )}

      {state.showBill && (
        <InvoiceQuoteView HandleBack={handleBack} typedocument={"FA"} />
      )}
    </>
  );
};

export default Quotation;
