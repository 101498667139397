import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { READING_MODE, URL_IMAGES } from '../../config/config.js';

import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import CardMedia from '@material-ui/core/CardMedia';
import { Card } from '@material-ui/core';

import CustomCardAditionalData from '../invoice/aditional-data-card.component';
import {
  ClearGetComponentsAction,
  getMenuComponentAction,
} from '../../actions/configAction';
import CollapsePanel from '../../components/collapse/collapsestyles.component';
import { makeStyles } from '@material-ui/core/styles';
import CustomProgress from '../../components/Progress/progress.component';
import TooltipMessage from '../../components/tooltip/tootltip-message.component';
import VisibilityIcon from '@material-ui/icons/Visibility';
import imageMoney from '../../../src/images/Icon-Shape.png';

import { ConfirmAlert } from '../../helpers/alert.helpers';
import { getComponentName, getComponentUrl } from '../../utils/general.js';

import PopoverModal from '../../components/modal/popoverModal.component';
import { getRoleOfLoggedUser } from '../../actions/authActions';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import imageMoneyShadow from '../../../src/images/Icon-Shape-Shadow.png';

import { formatDate } from '../../utils/general.js';
import { FormatDecimal } from '../../components/common/formatDecimal.js';
import { MESSAGE_NEXT_CONFIRM } from '../../config/config';
import { TooltipMessageReadingMode } from '../../components/tooltip/TooltipMessageReadingMode.js';
import { getTaxItems } from '../../utils/getTaxItems.js';
import { TaxElement } from '../document/cart/TaxElement.js';

const useStyles = makeStyles({
  showLayer: {
    display: 'block',
  },
  hideLayer: {
    display: 'none',
  },
  letter: {
    color: '#FFFFFF',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  widthMax: {
    width: '100%',
  },
  paddingIcons: {
    paddingTop: '20%',
  },
  width99: {
    width: '99%',
  },
  width98: {
    width: '98%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  width97: {
    width: '97%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  width90: {
    width: '90%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  letterTaxes: {
    color: '#2CC63E',
  },
  totalInvoiceDetail: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: '800',
    letterSpacing: '0',
    textAlign: 'left',
  },
  colorIcon: {
    color: '#2CC63E',
  },
  h1Card: {
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
  },
  cover: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerLoading: {
    paddingTop: 200,
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const InvoiceDetails = (props) => {
  const classes = useStyles();
  const {
    document,
    OpenMailModal,
    OpenFileModal,
    OpenEdit,
    getMenuComponentAction,
    ClearGetComponentsAction,
    loadingEdit,
    updateDocumentStatus,
    handleShowDetailNote,
  } = props;

  const {
    loadingGetDetail,
    loadingUpdateStatus,
    loadingUpdateFavourite,
  } = props; //Reducer
  const { configReducer, invoiceReducer } = props;
  const [modalWarningAnchorEl, setModalWarningAnchorEl] = React.useState(null);
  const [anchorPosition, setAnchorPosition] = React.useState(null);
  const [loadingGetNoteId, setLoadingGetNoteId] = React.useState(0);
  const components = configReducer.menuComponent;

  useEffect(() => {
    getMenuComponentAction(3);

    // returned function will be called on component unmount
    return () => {
      ClearGetComponentsAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Carga identificador documento loading ver detalle
   */
  useEffect(() => {
    setLoadingGetNoteId(invoiceReducer.loadingGetNoteId);
  }, [invoiceReducer.loadingGetNoteId]);

  /**
   * Evento, controla cambio estado destacado de un documento
   */
  const handleFavourite = () => {
    let data = {
      Id: document.id,
      Pagado: document.estado === 'Pagada' ? false : true,
      Operacion: 'FAVORITO',
      Favorito: !document.favorito,
    };

    updateDocumentStatus(data);
  };

  /**
   * Evento para Notificar que se va a realizar envio de una nota
   */
  const notificationNote = (notes) => {
    OpenMailModal(
      document.correoelectronicocliente,
      true,
      document.tipodocumentoentidad,
      document.documentompresa,
      document.codigounico,
      document.numero,
      document.razonsocialempresa,
      document.razonsocialcliente,
      document.diantipomodalidadid,
      document.id,
      'FA'
    );
  };

  /**
   * Evento, controla cambio de estado en documentos
   */
  const handlePayDocument = () => {
    const changeStatus = (confirmed) => {
      if (confirmed) {
        let data = {
          Id: document.id,
          Pagado: document.estadoid === '01' ? false : true,
          Operacion: 'PAGADO',
          Favorito: document.favorito,
        };

        updateDocumentStatus(data);
      }
    };

    stateInvoice(document);

    const messageText =
      'Actualmente la factura se encuentra en estado "' +
      getNameChangeState(document) +
      '" ¿Desea cambiar el estado de la factura a "' +
      stateInvoice(document) +
      '"?';

    ConfirmAlert(messageText, changeStatus, MESSAGE_NEXT_CONFIRM);
  };

  const [expandPanel] = useState({
    expandPanelInfo: '',
    cleanData: false,
    edit: false,
  });

  /**
   * Determinar el estado al cual pasa la factura, cuando se le va a cambiar un estado.
   */
  const stateInvoice = (state) => {
    if (state.anulacion) {
      return 'Anulada';
    }

    if (!!state.fechapago) {
      var payDate = new Date(
        formatDate(new Date(state.fechapago), 'yyyy/MM/dd')
      );
      var currentDate = new Date(formatDate(new Date(), 'yyyy/MM/dd'));

      if (state.estadoid === '02' || state.estadoid === '03') {
        return 'Pagada';
      }

      if (payDate > currentDate) {
        return 'Por pagar';
      } else {
        return 'En mora';
      }
    }

    if (state.estadoid === '01') {
      return 'Por pagar';
    }
    if (state.estadoid === '02') {
      return 'Pagada';
    }
  };

  /**
   * Cierra modal warning
   */
  const handleCloseModalWarning = () => {
    setModalWarningAnchorEl(null);
  };

  /**
   * Abre modal warning
   */
  const handleOpenModalWarning = (event) => {
    setAnchorPosition({
      left: event.screenX,
      top: event.screenY - 20,
    });
    setModalWarningAnchorEl(event.currentTarget);
  };

  /**
   *  Obtiene estilos para label valor documento
   * @param {*} item
   * @returns
   */
  const getStyleTotalItem = (item) => {
    if (item.anulacion === true) return 'totalInvoiceGridAnnulment';

    if (item.estadoid === '03') return 'totalInvoiceGridExpired';

    if (item.estadoid === '02') return 'totalInvoiceGridPending';

    return 'totalInvoiceGridPaidout';
  };

  /**
   *  Obtiene nombre del boton del cambio de estado
   * @param {*} item
   * @returns
   */
  const getNameChangeState = (item) => {
    if (item.anulacion === true) return 'Anulada';

    if (item.estadoid === '03') return 'En mora';

    if (item.estadoid === '02') return 'Por Pagar';

    return 'Pagada';
  };

  /**
   * Obtiene estilos para label estado documento
   * @param {*} item
   * @returns
   */
  const getStyleStatusItem = (item) => {
    if (item.anulacion === true) return 'stateInvoiceAnnulment';

    if (item.estadoid === '03') return 'stateInvoiceExpired';

    if (item.estadoid === '02') return 'stateInvoicePending';

    return 'stateInvoicePaidout';
  };

  /**
   * Genera listado de categorias por articulo
   * @param {*} cats   Información categorias
   */
  const getCategories = (cats) => {
    if (cats !== null) {
      const categories = cats.map((cat) => (
        <Grid item key={cat.id} lg={2} md={2} sm={12} xs={12}>
          <div className='spacingInvoiceMini'></div>
          <div
            className='circlesCategories'
            style={{ backgroundColor: cat.color }}
            title={cat.nombre}
          ></div>
        </Grid>
      ));
      return categories;
    }
  };

  /**
   * Modal Warning
   */
  const modalWarning = () => {
    return (
      <div>
        <div className='titleWarning'>
          <b>Pendiente de Sincronización con DIAN</b>
        </div>
        <div className='detailWarning'>
          Este ítem esta pendiente de sincronización con DIAN, el proceso se
          hará automaticamente
        </div>
        <div style={{ textAlign: 'center' }}>
          <button className='bottonWarning' onClick=''>
            Más información
          </button>
        </div>
      </div>
    );
  };

  /**
   * Genera Listado articulos
   */
  const getArticles = () => {
    if (
      document.opdetalledocumento !== undefined &&
      document.opdetalledocumento !== null
    ) {
      const articles = document.opdetalledocumento.map((items, index) => {
        const taxItems = getTaxItems(
          items.porcentajeiva,
          items.porcentajeinc,
          items.tarifaIcl / items.cantidad,
          items.cantidadGrados,
          items.cantidad,
          items.cantidadGramos / items.cantidad,
          items.tarifaInpp,
          items.cantidadMililitros / items.cantidad,
          items.tarifaIbua,
          items.porcentajeIcui,
          items.porcentajeAdv
        );

        return (
          <div key={index}>
            <div className='cardItemInvoice'>
              <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={12}>
                  <Card
                    key={items.id}
                    style={{
                      height: '97%',
                      width: '97%',
                      backgroundColor:
                        items.colorimagen === null ||
                        items.colorimagen === undefined ||
                        items.colorimagen.length === 0
                          ? '#33BBFF'
                          : items.colorimagen,
                    }}
                  >
                    <CardMedia
                      className={classes.cover}
                      image={items && URL_IMAGES + items.imagen}
                      style={{
                        background: `${
                          items.imagen === null ||
                          items.imagen === undefined ||
                          items.imagen.length === 0
                            ? items.colorimagen === null ||
                              items.colorimagen === undefined ||
                              items.colorimagen.length === 0
                              ? '#33BBFF'
                              : items.colorimagen
                            : null
                        }`,
                      }}
                    >
                      {items.imagen === null ||
                      items.imagen === undefined ||
                      items.imagen.length === 0 ? (
                        <h1 className={classes.h1Card}>
                          {items.descripcion.substring(0, 2).toUpperCase()}
                        </h1>
                      ) : null}
                    </CardMedia>
                  </Card>
                </Grid>
                <Grid item lg={9} md={9} sm={12}>
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      className='titleArticleInvoice'
                    >
                      {items.descripcion.length > 60
                        ? items.descripcion.substring(0, 60) + '...'
                        : items.descripcion}{' '}
                      <div className='spacingInvoice'></div>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12}>
                        <div className={classes.width98}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className='fieldsReadContainer'>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={12}
                      className='fieldsReadMini'
                    >
                      Valor C/U:
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} className='fieldsReadMini'>
                      Cantidad:
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={12}
                      className='totalInvoiceDetail'
                    >
                      <FormatDecimal value={items.valorunitario} prefix={'$'} />
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} className='fieldsReadBig'>
                      <FormatDecimal value={items.cantidad} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={10} md={10} sm={12}>
                      <div className={classes.width98}></div>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12}>
                      <div className={classes.width98}></div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} md={6} sm={12} className='fieldsReadBig'>
                      {taxItems.map(
                        ({ aplica, label, value, prefix, suffix }, index) =>
                          aplica ? (
                            <TaxElement
                              key={index}
                              label={label}
                              value={value}
                              prefix={prefix}
                              suffix={suffix}
                            />
                          ) : null
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} className='fieldsReadBig'>
                      <Grid container justify='flex-end'>
                        {getCategories(items.adcategoriaarticulo)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className='spacingInvoiceMini'></div>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12}>
                      <div className={classes.width98}></div>
                    </Grid>
                  </Grid>
                  <Grid container className='fieldsReadContainer'>
                    <Grid item lg={8} md={8} sm={12} className='fieldsReadMini'>
                      Descuento:
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} className='fieldsReadMini'>
                      Total:
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      lg={8}
                      md={8}
                      sm={12}
                      className={classes.totalInvoiceDetail}
                    >
                      {items.valordescuento > 0 ? (
                        <FormatDecimal
                          value={items.valordescuento}
                          prefix={'$'}
                        />
                      ) : (
                        <FormatDecimal
                          value={items.porcentajedescuento}
                          suffix={'%'}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      className='totalInvoiceDetail'
                    >
                      <FormatDecimal value={items.totalreal} prefix={'$'} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className='spacingInvoice'></div>
          </div>
        );
      });
      return articles;
    }
  };

  /**
   * Genera información adicional
   */
  const getInfoAditional = () => {
    let cargos = document.opdocumentocargos;
    let descuentos = document.opdocumentodescuentos;
    let notas = document.opdocumentonotas;
    let documentosreferencia = document?.documentosreferencia ?? [];
    return (
      <div>
        <div className='cardDetailInfoAditional'>
          <Grid container>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Fecha Factura{' '}
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Forma de Pago
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Fecha de Pago
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document?.fecha !== null && document?.fecha !== undefined
                ? formatDate(new Date(document.fecha), 'dd/MM/yyyy hh:mm aaaa')
                : '-'}
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document.descripcionforma}
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document?.fechapago !== null && document?.fechapago !== undefined
                ? formatDate(
                    new Date(document.fechapago),
                    'dd/MM/yyyy hh:mm aaaa'
                  )
                : '-'}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
            <Grid item lg={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
            <Grid item lg={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
          </Grid>
          <Grid container className='fieldsReadContainer'>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Medio Pago
            </Grid>
            <Grid item lg={8} md={8} sm={12} className='fieldsReadMini'>
              Vendedor
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document.descripcionmedio === null
                ? 'Sin datos'
                : document.descripcionmedio}
            </Grid>
            <Grid item lg={8} md={8} sm={12} className='fieldsReadBig'>
              {document.nombresvendedor === null
                ? 'Sin datos'
                : document.nombresvendedor}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} sm={12}>
              <div className={classes.width98}></div>
            </Grid>
            <Grid item lg={8} md={8} sm={12}>
              <div className={classes.width98}></div>
            </Grid>
          </Grid>

          {/* Nuevos datos */}
          <Grid container className='fieldsReadContainer'>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Aceptada/Rechazada
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Fecha Aceptación/Rechazo
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadMini'>
              Fecha validación DIAN
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document.descripcionestado}
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document?.fechaacusecliente !== null &&
              document?.fechaacusecliente !== undefined
                ? formatDate(
                    new Date(document.fechaacusecliente),
                    'dd/MM/yyyy hh:mm aaaa'
                  )
                : '-'}
            </Grid>
            <Grid item lg={4} sm={12} className='fieldsReadBig'>
              {document?.fechaacusedian !== null &&
              document?.fechaacusedian !== undefined
                ? formatDate(
                    new Date(document.fechaacusedian),
                    'dd/MM/yyyy hh:mm aaaa'
                  )
                : '-'}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
            <Grid item lg={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
            <Grid item lg={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
          </Grid>
          <Grid container className='fieldsReadContainer'>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className='fieldsReadMini'
              style={{ width: '96%' }}
            >
              Concepto rechazo cliente
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className='fieldsReadBig'
              style={{ wordBreak: 'break-word' }}
            >
              {'-'}{' '}
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}>
                <div className={classes.width97}></div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className='fieldsReadContainer'>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className='fieldsReadMini'
              style={{ width: '96%' }}
            >
              Observaciones
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className='fieldsReadBig'
              style={{ wordBreak: 'break-word' }}
            >
              {document.textoobservacion}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12}></Grid>
          </Grid>
        </div>
        <div className='spacingInvoice'></div>
        <div className='titleAdicitional'>Cargos</div>
        <div className='spacingInvoice'></div>
        <Grid container spacing={1}>
          {cargos !== undefined
            ? cargos !== null
              ? cargos.map((data, index) => (
                  <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                    <CustomCardAditionalData
                      data={data}
                      type='charges'
                      onClick=''
                    />
                  </Grid>
                ))
              : ''
            : ''}
        </Grid>
        <div className='spacingInvoice'></div>
        <div className='titleAdicitional'>Descuentos sobre el total</div>
        <div className='spacingInvoice'></div>
        <Grid container spacing={1}>
          {descuentos !== undefined
            ? descuentos !== null
              ? descuentos.map((data, index) => (
                  <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                    <CustomCardAditionalData
                      data={data}
                      type='discounts'
                      tooltipMessage={
                        data.modelodescuento === 1 ? (
                          <TooltipMessage
                            title={getComponentName(
                              components,
                              93,
                              157,
                              '¿Cómo afecta la factura este descuento?'
                            )}
                            message={getComponentName(
                              components,
                              93,
                              158,
                              'Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura.'
                            )}
                            botton={getComponentName(
                              components,
                              93,
                              159,
                              'Más información'
                            )}
                            href={getComponentUrl(components, 93, 150, null)}
                          />
                        ) : (
                          <TooltipMessage
                            title={getComponentName(
                              components,
                              94,
                              160,
                              '¿Cómo afecta la factura este descuento?'
                            )}
                            message={getComponentName(
                              components,
                              94,
                              161,
                              'Es un descuento que afecta el valor total a pagar pero no el valor total de la factura.'
                            )}
                            botton={getComponentName(
                              components,
                              94,
                              162,
                              'Más información'
                            )}
                            href={getComponentUrl(components, 94, 162, null)}
                          />
                        )
                      }
                      onClick=''
                    />
                  </Grid>
                ))
              : ''
            : ''}
        </Grid>

        <div className='spacingInvoice'></div>
        <div className='titleAdicitional'>Notas</div>
        <div className='spacingInvoice'></div>
        <Grid container spacing={1}>
          {notas !== undefined
            ? notas !== null
              ? notas.map((data, index) => (
                  <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                    <CustomCardAditionalData
                      data={data}
                      type='notes'
                      diantipomodalidadid={document.diantipomodalidadid}
                      tipodocumentoentidad={document.tipodocumentoentidad}
                      documentompresa={document.documentompresa}
                      razonsocialempresa={document.razonsocialempresa}
                      openFileModal={OpenFileModal}
                      openMailModal={OpenMailModal}
                      handleOpenModalWarning={handleOpenModalWarning}
                      handleShowDetailNote={handleShowDetailNote}
                      onClick={notificationNote}
                      loadingGetNoteId={loadingGetNoteId}
                    />
                  </Grid>
                ))
              : ''
            : ''}
        </Grid>

        <div className='spacingInvoice'></div>
        <div className='titleAdicitional'>Documentos de referencia</div>
        <div className='spacingInvoice'></div>
        <Grid container spacing={1}>
          {documentosreferencia.map((data, index) => (
            <Grid item key={index} lg={12} xs={12}>
              <CustomCardAditionalData data={data} type='docref' />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#2F2E2E',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),
    },
  }))(Tooltip);

  return (
    <div
      className={
        document.numero === undefined ? classes.hideLayer : classes.showLayer
      }
    >
      {document.diantipodocumentoelectronicoid !== 4 ? (
        loadingGetDetail !== true ? (
          <Fragment>
            <div
              className='cardDetailInvoiceFullData'
              style={{ overflow: 'auto', height: '69vh' }}
            >
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  className='detailInvoiceNumber'
                >
                  No. {document.numero}
                </Grid>
                <Grid item lg={6} md={6} sm={12} style={{ textAlign: 'right' }}>
                  <div
                    className={
                      document.favorito === true
                        ? classes.showLayer
                        : classes.hideLayer
                    }
                  >
                    <StarIcon></StarIcon>
                  </div>
                  <div
                    className={
                      document.favorito === false
                        ? classes.showLayer
                        : classes.hideLayer
                    }
                  >
                    <StarBorderIcon></StarBorderIcon>
                  </div>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    style={{ textAlign: 'left' }}
                    className={getStyleTotalItem(document)}
                  >
                    <FormatDecimal
                      value={document.valorapagar}
                      prefix={'$'}
                      size={'small'}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    className={getStyleStatusItem(document)}
                  >
                    {document.anulacion === true ? 'Anulada' : document.estado}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} className='dataInvoiceGrid'>
                    {document?.fecha !== null && document?.fecha !== undefined
                      ? formatDate(
                          new Date(document.fecha),
                          'dd/MM/yyyy hh:mm aaaa'
                        )
                      : '-'}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}></Grid>
                </Grid>
              </Grid>

              <div className='lineDivision'></div>
              <div className='paddingLeft'>
                <div className='constumerNameDetail'>
                  {document.razonsocialcliente}
                </div>
                <div className='detailInvoiceInfo'>
                  {document.desdoccli} {document.clientedocumento}
                </div>
                <div className='detailInvoiceInfo'>{document.desctipe}</div>
                <div className='detailInvoiceInfo'>{document.desctirg}</div>
                <div className='spacingInvoice'></div>
              </div>
              <div className='gridNoSpace'>
                <EmailIcon className={classes.colorIcon}></EmailIcon>
                &nbsp;&nbsp;
                <div
                  className='detailInvoiceInfo'
                  style={{
                    wordBreak: 'break-all',
                  }}
                >
                  {document.correoelectronicocliente}
                </div>
              </div>
              <div className='gridNoSpace'>
                <PhoneIcon className={classes.colorIcon}></PhoneIcon>
                &nbsp;&nbsp;
                <div className='detailInvoiceInfo'>
                  {document.telefonocliente}
                </div>
              </div>
              <div className='gridNoSpace'>
                <RoomIcon className={classes.colorIcon}></RoomIcon>&nbsp;&nbsp;
                <div className='detailInvoiceInfo'>
                  {`${document?.clidirdes || ''} ${document?.cliciudes ||
                    ''}  ${document?.clidesdep || ''}`}
                </div>
              </div>
              <div className='spacingInvoice'></div>
              <div className='greenTitleInvoice'>
                Artículos (
                {document.opdetalledocumento === undefined
                  ? 0
                  : document.opdetalledocumento === null
                  ? 0
                  : document.opdetalledocumento.length}
                )
              </div>
              <div className='spacingInvoice'></div>
              {getArticles()}
              <CollapsePanel
                component={getInfoAditional}
                title={'Información Adicional'}
                titleOpen={'Información Adicional'}
                titleEdit={'Información Adicional'}
                edit={expandPanel.edit}
                open={expandPanel.expandPanelInfo}
                cleanData={expandPanel.cleanData}
                backgroundColorSummary={{
                  backgroundColor: '#2CC63E',
                  color: '#000000',
                }}
                backgroundColorDetail={{}}
                type='showinfo'
              />
            </div>

            <div className='bottomButtonsInvoice'>
              <Grid container>
                <Grid item lg={1} sm={1}></Grid>
                <Grid item lg={2} sm={2}>
                  {loadingUpdateFavourite === true ? (
                    <CustomProgress />
                  ) : (
                    <Fragment>
                      {document.favorito ? (
                        <StarIcon
                          className='buttonInvoiceBehavior'
                          onClick={handleFavourite}
                        ></StarIcon>
                      ) : (
                        <StarBorderIcon
                          className='buttonInvoiceBehavior'
                          onClick={handleFavourite}
                        ></StarBorderIcon>
                      )}
                      <div className='textButtonsInvoice'>Favorito</div>
                    </Fragment>
                  )}
                </Grid>
                <Grid item lg={2} sm={2}>
                  {getRoleOfLoggedUser() ? (
                    <LightTooltip
                      title='No tiene permisos sobre esta acción'
                      placement='bottom'
                    >
                      <span>
                        <div>
                          <div>
                            <img
                              alt='Pagada'
                              src={imageMoneyShadow}
                              className='buttonInvoiceBehavior'
                            ></img>
                          </div>
                          <div className='textButtonsInvoice disabledByPay'></div>
                        </div>
                      </span>
                    </LightTooltip>
                  ) : (
                    <Fragment>
                      {loadingUpdateStatus === true ? (
                        <CustomProgress />
                      ) : (
                        <div title='Cambiar estado factura'>
                          <div
                            className={
                              document.estadoid === '01'
                                ? ''
                                : 'panelInvisibleInvoice'
                            }
                          >
                            <img
                              alt='Pagada'
                              src={imageMoney}
                              onClick={handlePayDocument}
                              className='buttonInvoiceBehavior'
                            ></img>
                          </div>
                          <div
                            className={
                              document.estadoid !== '01'
                                ? ''
                                : 'panelInvisibleInvoice'
                            }
                          >
                            <img
                              alt='Pagada'
                              src={imageMoney}
                              onClick={handlePayDocument}
                              className='buttonInvoiceBehavior'
                            ></img>
                          </div>
                          <div className='textButtonsInvoice'>
                            {stateInvoice(document)}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <div>
                    <VisibilityIcon
                      className='buttonInvoiceBehavior'
                      onClick={() =>
                        OpenFileModal(
                          document.tipodocumentoentidad,
                          document.documentompresa,
                          document.codigounico,
                          document.diantipomodalidadid,
                          document.id
                        )
                      }
                    ></VisibilityIcon>
                    <br />
                    <div className='textButtonsInvoice'>Previsualizar</div>
                  </div>
                </Grid>

                <Grid item lg={2} md={2} sm={2}>
                  {loadingEdit ? (
                    <CustomProgress size={20} />
                  ) : (
                    <Fragment>
                      <HtmlTooltip
                        title={
                          READING_MODE ? <TooltipMessageReadingMode /> : ''
                        }
                      >
                        <span>
                          <EditIcon
                            className='buttonInvoiceBehavior'
                            style={READING_MODE ? { color: '#919191' } : {}}
                            onClick={
                              !READING_MODE ? () => OpenEdit(document) : null
                            }
                          ></EditIcon>
                          <br></br>
                          <div
                            className='textButtonsInvoice'
                            style={READING_MODE ? { color: '#919191' } : {}}
                          >
                            Editar
                          </div>
                        </span>
                      </HtmlTooltip>
                    </Fragment>
                  )}
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <Fragment>
                    <EmailIcon
                      className='buttonInvoiceBehavior'
                      onClick={() =>
                        OpenMailModal(
                          document.correoelectronicocliente,
                          true,
                          document.tipodocumentoentidad,
                          document.documentompresa,
                          document.codigounico,
                          document.numero,
                          document.razonsocialempresa,
                          document.razonsocialcliente,
                          document.diantipomodalidadid,
                          document.id,
                          'FA'
                        )
                      }
                    ></EmailIcon>
                    <br></br>
                    <div className='textButtonsInvoice'>Enviar</div>
                  </Fragment>
                </Grid>
                <Grid item lg={1} md={1} sm={1}></Grid>
              </Grid>
            </div>
          </Fragment>
        ) : (
          <div className={classes.containerLoading}>
            <CustomProgress />
          </div>
        )
      ) : (
        ''
      )}

      <PopoverModal
        handleClose={handleCloseModalWarning}
        open={Boolean(modalWarningAnchorEl)}
        component={modalWarning}
        width={252}
        popoverAnchorEl={modalWarningAnchorEl}
        anchorPosition={anchorPosition}
        showCloseButton={false}
        modalWarning={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  sucessupdate: state.invoiceReducer.sucess,
  document: state.invoiceReducer.document,
  loadingGetDetail: state.invoiceReducer.loadingGetDetail,
  loadingUpdateStatus: state.invoiceReducer.loadingUpdateStatus,
  loadingUpdateFavourite: state.invoiceReducer.loadingUpdateFavourite,
  configReducer: state.configReducer,
  invoiceReducer: state.invoiceReducer,
});

const mapDispatchToProps = {
  getMenuComponentAction,
  ClearGetComponentsAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
