import React,{Component} from "react";

import { withStyles } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";

import {Card,CardContent,Grid,Tooltip} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";

import withWidth from "@material-ui/core/withWidth";


import CustomProgress from '../../../components/Progress/progress.component';

import { URL_IMAGES } from '../../../config/config.js';


const useStyles = (theme) => ({
 
  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
  titleCard: {
    color: theme.palette.primaryColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    fontWeight:'bold',
    textAlign: "center",
  },
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  colorDescription: {
    background: "#272727",
    color: theme.palette.primaryColor,
    fontSize: 12.32,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow:'hidden'
  },
  nameArticle: {
    padding:'0 5%',
    textOverflow: 'ellipsis',
    overflow:'hidden'
  },
  priceArticle: {
    fontSize: 10.68,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    marginLeft: 10,
    fontWeight:200
  },
  cardOpacity:{
    opacity:0.3
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  },
  dinamicText:{
    
  }
});

class RakingArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false,
      timer: 500,
      animation: "slide",
      indicators: false,
      timeout: 200,
      numItemPerPage: 6,
      pages: 0,
      navButtonsAlwaysVisible: true,
    };
  }
/**
 * Metodo para obtener las dos primeras letras del nombre del articulo
 * @param {Parametro del nombre del articulo que llega de la respuesta} name 
 */
  subNameArticle = (name) => {
    return name ? name.substring(0, 2).toUpperCase() : "";
  };
/**
 * Metodo para obtener el color de la categoria que llega en la data
 * @param {Arreglo que se agrega de acuerdo a la dat que llega} color 
 */
  colorCategory = (color) =>{
    return color.find(c => c.color ).color
  }

  /**
   * Metodo para darle formato al valor del artículo
   * @param {*} value 
   */
  formatter = (value) => {
    const options = { style: 'currency', currency: 'USD', maximumSignificantDigits:10};
    const numberFormat = new Intl.NumberFormat('en-US', options);
    return numberFormat.format(value)
  } 

  truncNameArticle = (name) =>{

    var nameArticleTrunc ;
    if(this.props.width === 'lg' ){
      if(name.length >= 14 ){
       nameArticleTrunc = name.substring(0,11);
      return nameArticleTrunc + '...'
      }
    }

    if(this.props.width === 'xl' ||  this.props.width === 'md' || this.props.width === 'xs'){
      if(name.length >= 14 ){
       nameArticleTrunc = name.substring(0,21);
      return nameArticleTrunc + '...'
      }
    }
    return name;
  }

  /**
   *
   * @param {*} classes
   * @param {*} data
   */
  CardItem(classes, data, index) {
    return (
       
        <Grid item lg={2} xs={2}>
         <Card className={index===5 ? classes.cardOpacity:''}>
          <CardActionArea >
           <div className="containerImg" style={{background:data.colors.length === 0 && (data.image === null || data.image === undefined || data.image.length === 0) ? '#D8D8D8' : data.colors.length !== 0 && (data.image === null || data.image === undefined || data.image.length === 0) ? '' : ''}}>
           { data.image === null || data.image === undefined || data.image.length === 0 ? <img alt='' className="cover" width="230" height="230" style={{background:data.image === null || data.image === undefined || data.image.length === 0  ? data.colors:'' }}/> : <img alt=''className="cover" width="230" height="230" src={URL_IMAGES+data.image}/>}
           
            </div>
            
            
         <Tooltip title={data.nombrearticulo}>
            <div className={classes.colorDescription}>
            <hr color={data.colors.length === 0 ? '#D8D8D8' : data.colors} size={5} />
              <span className={classes.nameArticle} style ={{top: '80%',
    left:'30%'}}>{this.truncNameArticle(data.nombrearticulo)}</span>  
              <br />
              <span className={classes.priceArticle}> {this.formatter(data.valortotal)}</span>
            </div>
            </Tooltip>   
          </CardActionArea>
        </Card> 
      </Grid> 
    );
  }

  render() {
    const { name, classes, items , statusCode } = this.props;

    let list = [];
    let listCards = [];
    for (let index = 0; index < items.length; index++) {
      if (
        (index + 1) % this.state.numItemPerPage !== 0 &&
        index + 1 !== items.length
      ) {
        list.push(items[index]);
      } else {
        list.push(items[index]);
        listCards.push(list);
        list = [];
      }
    }
    return (
      <Card className={classes.colorCard}>
      <CardHeader title={name} className={"title"} />
        {items.length === 0 && statusCode === undefined ?  <CustomProgress/>  : 
            (items.length === 0 && statusCode === 0)  || (items.length === 0 && statusCode === 200)? 
            <div className={classes.divMessage}>No tiene facturas registradas</div>: ''}
        <CardContent>
       {items.length === 0 && statusCode === undefined ? '' : 
            (items.length === 0 && statusCode === 0)  || (items.length === 0 && statusCode === 200)? 
           '':
          !!listCards && listCards.length && (
            <Carousel
              autoPlay={this.state.autoPlay}
              timer={this.state.timer}
              animation={this.state.animation}
              indicators={this.state.indicators}
              timeout={this.state.timeout}
              navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}>
              {listCards.map((cards, index) => {
                return (
                  <Grid container spacing={1} key={index} vstyle={{marginTop:100}}>
                    {cards.map((data, indexCard) =>
                      this.CardItem(classes, data, indexCard)
                    )}
                  </Grid>)
              })}
            </Carousel>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withWidth() (withStyles(useStyles)(RakingArticle));
