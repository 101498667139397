import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FFFFF",
		color: "black",
		textAlign: "center",
	},
	action: {
		flexDirection: "column",
		justifyContent: "center",
	},
	title: {
		fontWeight: 800,
		fontSize: 14,
	},
	message: {
		fontSize: 12,
		whiteSpace: "pre-wrap",
		textAlign: "justify",
	},
}));

const CustomContent = withStyles(() => ({
	root: {
		padding: "10px 16px",
	},
}))(CardContent);

const CustomTypography = withStyles(() => ({
	root: {
		paddingBottom: "10px",
	},
}))(Typography);

const MessageSecundary = ({ ...props }) => {
	const { message, title } = props;
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CustomContent>
				<CustomTypography variant="body1">
					<span className={classes.title}>{title}</span>
				</CustomTypography>
				<CustomTypography variant="body2" inline="true">
					<span className={classes.message}>
						<div dangerouslySetInnerHTML={{ __html: message }} />
					</span>
				</CustomTypography>
			</CustomContent>
		</Card>
	);
};

export default MessageSecundary;
