import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { FormatDecimal } from "../../../components/common/formatDecimal";

import { getRoleOfLoggedUser } from "../../../actions/authActions";
import { deleteChargeDocumentAction } from "../../../actions/documentCartAction";
import { ConfirmAlert } from "../../../helpers/alert.helpers";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.thirdBackgroundColor,
    color: theme.palette.primaryColor,
    "& .MuiCardContent-root": {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
  },
  header: {
    color: theme.palette.primaryColor,
    paddingBottom: 0,
    paddingTop: 0,
  },
  title: {
    color: theme.palette.primaryColor,
    wordBreak: "break-all",
  },
  icon: {
    color: theme.palette.secundaryColor,
  },
  iconAction: {
    color: theme.palette.primaryColor,
  },
  content: {
    fontSize: 14,
    color: theme.palette.primaryColor,
  },
  text: {
    color: theme.palette.primaryColor,
  },
  valueText: {
    color: "#F5D13D",
    fontWeight: 500,
    wordBreak: "break-all",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

export const ChargeCard = React.memo(
  ({ id, descripcion, tipocargo, valor, valorasumar, handleLoad }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    /**
     * Elimina cargo del documento
     * @param {*} id
     */
    const handleDelete = (id) => {
      const deleteData = (confirmed) => {
        if (confirmed) {
          dispatch(deleteChargeDocumentAction(id));
        }
      };
      ConfirmAlert(
        `¿Estás seguro de eliminar ${descripcion}?`,
        deleteData,
        MESSAGE_DELETE_CONFIRM
      );
    };

    return (
      <Card
        className={`${classes.root} animate__animated animate__fadeIn animate__faster`}
      >
        <CardHeader
          className={classes.header}
          avatar={<AddToPhotosIcon fontSize="small" className={classes.icon} />}
          action={
            <Fragment>
              {getRoleOfLoggedUser() ? (
                <Tooltip title="No tiene permisos sobre esta acción">
                  <IconButton aria-label="Editar">
                    <CreateIcon className={classes.iconAction} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Editar">
                  <IconButton onClick={handleLoad}>
                    <CreateIcon className={classes.iconAction} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Eliminar">
                <IconButton onClick={() => handleDelete(id)}>
                  <DeleteIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>
            </Fragment>
          }
          classes={{
            title: classes.title,
          }}
          title={descripcion}
        />
        <CardContent className={classes.content}>
          {tipocargo === 1 ? (
            <span className={classes.valueText}>
              <FormatDecimal value={valorasumar} prefix={"$"} />
            </span>
          ) : (
            <>
              <span>
                <FormatDecimal value={valor} suffix={"%"} size={"inherit"} />
              </span>
              &nbsp;
              <span className={classes.valueText}>
                <FormatDecimal value={valorasumar} prefix={"($"} suffix={")"} />
              </span>
            </>
          )}
        </CardContent>
      </Card>
    );
  }
);
