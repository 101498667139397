import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import DeleteIcon from "@material-ui/icons/Delete";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import EditIcon from "@material-ui/icons/Edit";
import StarIcon from "@material-ui/icons/Star";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { AddressCard } from "./addressesCustomer/addressCard";
import { ContactCard } from "./contactsCustomer/contactCard";
import CustomProgress from "../../components/Progress/progress.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";

import {
  standOutCustomerAction,
  loadCustomerForDocumentAction,
} from "../../actions/customerAction";
import {
  changeShowInvoiceCreationAction,
  setDefaultFilterAction,
} from "../../actions/invoiceActions";

import documentDescription from "../../utils/typeDocument";
import { CustomerDocumentCard } from "./customerDocumentCard";
import { READING_MODE } from "../../config/config";
import { TooltipMessageReadingMode } from "../../components/tooltip/TooltipMessageReadingMode";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.fourthBackgroundColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
    marginTop: 15,
  },
  body: {
    overflow: "auto",
    maxHeight: "66vh",
  },
  header: {
    color: theme.palette.fourthColor,
    fontWeight: "bold",
    padding: "15px 20px 5px 20px",
    wordBreak: "break-word",
  },
  content: {
    fontSize: 14,
    fontWeight: 300,
    display: "flex",
  },
  icon: {
    color: "#2CC63E",
    marginLeft: 0,
    marginRight: 10,
    fontSize: 19,
  },

  title: {
    fontWeight: 600,
    fontSize: 24,
  },
  label: {
    flexDirection: "column",
    fontSize: 10,
    color: theme.palette.primaryColor,
  },
  formCheck: {
    margin: 0,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  styleActions: {
    backgroundColor: "#393939",
  },
  cardContent: {
    paddingTop: 5,
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid gray",
    display: "inline-grid",
  },
  iconComun: {
    color: theme.palette.primaryColor,
  },
  containerProgress: {
    textAlign: "center",
    width: "100%",
  },
  titleInfo: {
    color: theme.palette.secundaryColor,
    fontSize: 14,
    fontWeight: 600,
  },
  buttonGreen: {
    width: "100%",
    backgroundColor: theme.palette.secundaryColor,
    color: theme.palette.primaryBackgroundColor,
    outline: "none",
    borderRadius: 5,
    boxShadow: "none",
    border: "none",
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Muli",
    display: "inline-flex",
    cursor: "pointer",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

export const CustomerDetail = React.memo(
  ({ data, disabled, handleEditCustomer, handleDeleteCustomer }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const [regime, setRegime] = useState("");
    const { getRegimeTypesResponse } = useSelector(
      (state) => state.configReducer
    );
    const { loadingStandOut, loadingDelete } = useSelector(
      (state) => state.customerReducer
    );

    const {
      id,
      razonsocial,
      diantipodocumentoidentidadid,
      documento,
      correoelectronico,
      telefono,
      ciudaddescripcion,
      departamentodescripcion,
      adclientedireccion,
      adcontactos,
      diantiporegimenid,
      favorito,
      documentosRecientes,
      direccion,
    } = data;

    /**
     * Carga descripción regimen
     */
    useEffect(() => {
      const regimeName = getRegimeTypesResponse.find(
        (x) => x.id === diantiporegimenid
      )?.descripcion;
      setRegime(regimeName ?? "");
    }, [diantiporegimenid, getRegimeTypesResponse]);

    /**
     * Actualiza estado de cliente
     */
    const handleStandOut = () => {
      dispatch(standOutCustomerAction(data.id));
    };

    /**
     * Redirige a modulo facturación
     * @param {*} e Evento
     * @param {*} data Informacion cliente
     */
    const handleRedirectInvoice = () => {
      //Carga informacion de cliente en modulo facturación
      dispatch(loadCustomerForDocumentAction(data));

      //Carga estado en redux, visualizacion modulo nueva factura
      dispatch(changeShowInvoiceCreationAction(true));

      //Redireccionar, Crear nueva factura
      history.push("/invoice");
    };

    /**
     * Redirige a listado documentos
     */
    const handleRedirectListInvoice = () => {
      dispatch(setDefaultFilterAction(documento ?? ""));

      //Redireccionar, Listado Facturas
      history.push("/invoice");
    };

    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#2F2E2E",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 280,
        fontSize: theme.typography.pxToRem(12),
      },
    }))(Tooltip);

    return (
      <Card
        className={`${classes.root} animate__animated animate__fadeIn animate__faster`}
      >
        <div className={classes.body}>
          <CardHeader
            className={classes.header}
            classes={{
              title: classes.title,
            }}
            title={razonsocial}
            subheader=""
          />

          <CardContent className={classes.cardContent}>
            <Typography className={classes.content} component="div">
              {documentDescription(diantipodocumentoidentidadid)} {documento}{" "}
              <br />
              {regime} <br />
            </Typography>
            <Typography
              component="div"
              className={classes.content}
              style={{ textTransform: "none" }}
            >
              <EmailIcon className={classes.icon} />
              <div className={classes.wordBreak}> {correoelectronico}</div>
            </Typography>
            <Typography className={classes.content} component="div">
              <PhoneIcon className={classes.icon} />
              <div className={classes.wordBreak}> {telefono}</div>
            </Typography>
            <Typography className={classes.content} component="div">
              <RoomIcon className={classes.icon} />
              <div className={classes.wordBreak}>
                {`${direccion ?? ""} ${ciudaddescripcion ??
                  ""} - ${departamentodescripcion ?? ""}`}
              </div>
            </Typography>
            <div>
              {adclientedireccion?.length > 0 || adcontactos?.length > 0 ? (
                <div>
                  <span className={classes.divider}></span>
                  <div className={classes.titleInfo}>
                    {" "}
                    Información adicional
                  </div>
                </div>
              ) : null}
            </div>
            {adclientedireccion?.map((item) => (
              <Grid item lg={12} xs={12} key={item.id}>
                <AddressCard {...item} />
              </Grid>
            ))}
            {adcontactos?.map((item) => (
              <Grid item lg={12} xs={12} key={item.id}>
                <ContactCard {...item} />
              </Grid>
            ))}

            <div className="container-title">
              {" "}
              Últimas 3 facturas de este cliente
            </div>
            {documentosRecientes?.map((document) => (
              <Grid item lg={12} xs={12} key={document.id}>
                <CustomerDocumentCard razonsocial={razonsocial} {...document} />
              </Grid>
            ))}

            <div
              className={classes.buttonGreen}
              onClick={handleRedirectListInvoice}
            >
              <div
                style={{
                  width: "50%",
                  padding: "10px 20px",
                }}
              >
                Ver todas las facturas de este cliente
              </div>
              <div
                style={{
                  width: "50%",
                  padding: "10px 20px",
                  textAlign: "end",
                }}
              >
                <ArrowForwardIosIcon />
              </div>
            </div>
          </CardContent>
        </div>

        <CardActions className={classes.styleActions}>
          <Grid container>
            <Grid item lg={6} xs={6} style={{ textAlign: "center" }}>
              <HtmlTooltip
                title={READING_MODE ? <TooltipMessageReadingMode /> : ""}
              >
                <span>
                  <ButtonPrimary
                    text={"Nueva Factura"}
                    type="button"
                    disabled={disabled || READING_MODE}
                    onClick={handleRedirectInvoice}
                  />
                </span>
              </HtmlTooltip>
            </Grid>
            <Grid item lg={2} xs={2} style={{ textAlign: "center" }}>
              {loadingDelete ? (
                <div style={{ paddingTop: 20 }}>
                  <CustomProgress size={20} />
                </div>
              ) : (
                <IconButton
                  classes={{ root: classes.button, label: classes.label }}
                  variant="raised"
                  disableRipple={true}
                  onClick={() => handleDeleteCustomer(id)}
                  disabled={disabled === true ? true : false}
                >
                  <DeleteIcon className={classes.iconAction} />
                  {"Eliminar"}
                </IconButton>
              )}
            </Grid>

            <Grid item lg={2} xs={2} style={{ textAlign: "center" }}>
              {loadingStandOut ? (
                <div style={{ paddingTop: 20 }}>
                  <CustomProgress size={20} />
                </div>
              ) : (
                <FormControlLabel
                  style={{ margin: 0, height: "75%" }}
                  control={
                    <Checkbox
                      name="checkedF"
                      checked={favorito}
                      onChange={handleStandOut}
                      className={classes.formCheck}
                      disabled={false}
                      icon={
                        <IconButton
                          classes={{
                            root: classes.button,
                            label: classes.label,
                          }}
                          variant="raised"
                          disableRipple={true}
                        >
                          <StarBorderIcon className={classes.iconAction} />
                          {"Favorito"}
                        </IconButton>
                      }
                      checkedIcon={
                        <IconButton
                          classes={{
                            root: classes.button,
                            label: classes.label,
                          }}
                          variant="raised"
                          disableRipple={true}
                        >
                          <StarIcon className={classes.iconAction} />
                          {"Favorito"}
                        </IconButton>
                      }
                    />
                  }
                />
              )}
            </Grid>
            <Grid item lg={2} xs={2} style={{ textAlign: "center" }}>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                onClick={handleEditCustomer}
                disabled={disabled === true ? true : false}
              >
                <EditIcon className={classes.iconAction} />
                {"Editar"}
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  }
);
