import React from "react";

import { BoxInformationForm } from "../components/createEquivalentDocument";

export const FormByEquivalentDocType = ({
  documentType,
  handleInputChange,
  validator,
  values,
}) => {
  const documentComponents = {
    "1": (
      <BoxInformationForm
        {...values}
        handleInputChange={handleInputChange}
        validator={validator}
      />
    ),
    // ! Agregar más casos aquí si es  para otros tipos de documento equivalentes
  };
  return documentComponents[documentType] || null;
};
