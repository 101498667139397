import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";

import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import InputComponent from "../../components/input/input.component";
import SelectComponent from "../../components/select/select.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import CardContact from "../../components/card/infoCardContact.component";
import {
  getMenuComponentAction,
  cleanMenuComponentAction,
  getreason,
  sendMailContact,
} from "../../actions/configAction";
import { SuccessAlert } from "../../helpers/alert.helpers";
import { TIME_UPDATE_REGISTER } from "../../config/config";

import Phone from "../../../src/images/whatsapp.png";
//import Calendar from "../../../src/images/calendar.png";
import Email from "../../../src/images/Mail.png";

const styles = () => ({
  descriptionSpan: {
    height: "81px",
    width: "480px",
    color: "#7DD1F8",
    fontFamily: "Muli",
    fontSize: "18px",
    fontStyle: "italic",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "23px",
    textAlign: "center",
    margin: "auto",
  },
  descriptionName: {
    height: "26px",
    width: "372px",
    color: "#848181",
    fontFamily: "Muli",
    fontSize: "18px",
    letterSpacing: 0,
    lineHeight: "26px",
  },
  mainGrid: {
    margin: "auto",
  },
});

export class ReportProblem extends Component {
  constructor() {
    super();

    this.state = {
      form: {
        ddlReason: "",
        txtDescription: "",
        mailSupport: "",
        issue: "",
      },
      issue: [],
      data: [],
      fullName: "",
      emailUser: "",
      idUser: "",
      nameCompany: "",
      nitcompany: "",
      loadingSend: false,
      responseDataMailContact: {},
      menuHelp: {},
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (
      !!nextProps.configReducer.reasonData &&
      nextProps.configReducer.reasonData !== state.reasonData
    ) {
      update.reasonData = nextProps.configReducer.reasonData;
      update.data = nextProps.configReducer.data;
      update.emailUser = nextProps.configReducer.emailUser;
      update.fullName = nextProps.configReducer.fullName;
      update.idUser = nextProps.configReducer.idUser;
      update.nameCompany = nextProps.configReducer.businessName;
      update.nitcompany = nextProps.configReducer.nitCompany;
    }

    if (
      !!nextProps.configReducer.responseDataMailContact &&
      nextProps.configReducer.responseDataMailContact !==
        state.responseDataMailContact
    ) {
      update.responseDataMailContact =
        nextProps.configReducer.responseDataMailContact;
    }

    if (
      !!nextProps.menuReducer.menuHelp &&
      nextProps.menuReducer.menuHelp !== state.menuHelp
    ) {
      update.menuHelp = nextProps.menuReducer.menuHelp;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.responseDataMailContact !== this.state.responseDataMailContact
    ) {
      if (this.state.responseDataMailContact.statusCode === "200") {
        SuccessAlert(
          null,
          this.state.menuHelp.messageContact,
          TIME_UPDATE_REGISTER
        );
        this.cleanFormProblem();
        this.setState({
          loadingSend: false,
        });
      }
    }
  }
  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;

    const value = e.target.value;
    let emailSelect = "";
    let issue = "";

    this.state.data.forEach((item) => {
      if (item.id === value) {
        emailSelect = item.correo;
        issue = item.descripcion;
      }
    });

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
        mailSupport: emailSelect,
        issue: issue,
      },
    }));
  }

  syncChange(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  onSendReport = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let mailStructure = {};
      var textHtml = this.state.form.txtDescription.replace(
        /\n/g,
        " ",
        /"/g,
        '"'
      );
      mailStructure.mailSupport = this.state.form.mailSupport;
      mailStructure.mailUser = this.state.emailUser;
      mailStructure.issue = this.state.form.issue;
      mailStructure.bodyMail = textHtml;
      mailStructure.idUser =
        this.state.idUser === "CFREPLEG"
          ? "Representante Legal"
          : this.state.idUser === "ROL_CFCOLAB"
          ? "Colaborador"
          : "Administrador";
      mailStructure.nameCompany = this.state.nameCompany;
      mailStructure.nitCompany = this.state.nitcompany;
      mailStructure.userEmail = this.state.emailUser;
      mailStructure.userName = this.state.fullName;

      this.props.sendMailContact(mailStructure, true);
      this.setState({
        loadingSend: true,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  cleanFormProblem = () => {
    this.setState({
      form: {
        ddlReason: "",
        txtDescription: "",
        mailSupport: "",
        issue: "",
      },
    });
    this.validator.hideMessages();
  };

  render() {
    const classes = this.props.classes;
    return (
      <form
        id="reportProblem"
        onSubmit={(e) => this.onSendReport(e)}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <Grid item md={8} className={classes.mainGrid}>
            <Grid item lg={12} md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}>
                  <CardContact
                    imageCard={Email}
                    subtitle={this.state.menuHelp.emailContact}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <CardContact
                    imageCard={Phone}
                    subtitle={this.state.menuHelp.phoneContact}
                    url={this.state.menuHelp.urlPhoneContact}
                    link={true}
                  />
                </Grid>
                {/**
            * <Grid item lg={4} md={4} xs={4}>
                  <CardContact
                    imageCard={Calendar}
                    subtitle={getComponentName(this.state.components, 83, 136, 'Lunes-Viernes 7 am 6pm Sabados 8 am - 2 pm')}
                    margin={true}
                  />
                </Grid>
            */}
              </Grid>
            </Grid>
            <Grid />
            <br />
            <hr color={"#979797"} size={1} />
            <br />
            <div className={classes.descriptionSpan}>
              {this.state.menuHelp.contactLabel}
            </div>
            <br />
            <SelectComponent
              id="ddlReason"
              name="ddlReason"
              label={"Motivo *"}
              options={this.state.reasonData}
              //options={dataEjemplo}
              value={this.state.form.ddlReason}
              onChange={(e) => {
                this.syncChanges(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
            <InputComponent
              id={"txtDescription"}
              rowsline={true}
              label="Observaciones *"
              maxLength={5000}
              multiline={true}
              value={this.state.form.txtDescription}
              onChange={(e) => {
                this.syncChange(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
            <Grid container spacing={5} justify="center" alignItems="center">
              <Grid item lg={6} md={6} sm={12} xs={12} />
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ButtonPrimary
                  loading={this.state.loadingSentrue}
                  text={this.state.menuHelp.buttonContact}
                  //disabled={this.state.checkedArticles.length > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

ReportProblem.propTypes = {};

const mapStateToProps = (state) => ({
  configReducer: state.configReducer,
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {
  getMenuComponentAction,
  cleanMenuComponentAction,
  getreason,
  sendMailContact,
})(withStyles(styles)(ReportProblem));
