import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ReceiptIcon from "@material-ui/icons/Receipt";

import CustomTooltip from "../../../components/tooltip/tooltip.component";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.thirdBackgroundColor,
    color: theme.palette.primaryColor,
  },
  avatar: {},
  header: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    marginTop: 0,
    fontSize: 14,
  },
  title: {
    color: theme.palette.primaryColor,
  },

  icon: {
    color: theme.palette.secundaryColor,
  },
  iconAction: {
    color: theme.palette.primaryColor,
  },
  valueText: {
    color: "#EC005F",
    fontWeight: 500,
  },
  tooltip: {
    marginTop: 15,
  },
}));

export const ReasonCard = React.memo(({ tipo, descripcion }) => {
  const classes = useStyles();

  const {
    tooltipMotiveNoteHeader: tooltipHeaderds,
    tooltipMotiveNoteBody: tooltipBodyds,
  } = useSelector((state) => state.menuReducer.menuDocSupport);

  const {
    tooltipMotiveNoteHeader,
    tooltipMotiveNoteBody,
    tooltipMotiveNoteBotton,
    tooltipMotiveNoteUrl,
  } = useSelector((state) => state.menuReducer.menuInvoice);

  const { cartDocumentSupport } = useSelector(
    (state) => state.documentCartReducer
  );

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        avatar={<ReceiptIcon fontSize="small" className={classes.icon} />}
        action={
          <div className={classes.tooltip}>
            {cartDocumentSupport ? (
              <CustomTooltip
                message={
                  <TooltipMessage
                    title={tooltipHeaderds}
                    message={tooltipBodyds}
                    botton={""}
                    href={null}
                    noButton={true}
                  />
                }
              />
            ) : (
              <CustomTooltip
                message={
                  <TooltipMessage
                    title={tooltipMotiveNoteHeader}
                    message={tooltipMotiveNoteBody}
                    botton={tooltipMotiveNoteBotton}
                    href={tooltipMotiveNoteUrl}
                  />
                }
              />
            )}
          </div>
        }
        classes={{
          title: classes.title,
        }}
        title={
          tipo === "NC"
            ? "Nota Crédito"
            : cartDocumentSupport
            ? "Nota Ajuste"
            : "Nota Débito"
        }
      />
      <CardContent className={classes.content}>
        {!cartDocumentSupport ? (
          <span>{descripcion.substring(0, descripcion.indexOf("("))}</span>
        ) : (
          <span>{descripcion}</span>
        )}
      </CardContent>
    </Card>
  );
});
