import React from "react";

const EquivalentDocIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <g clipPath="url(#clip0_4704_35633)">
        <path
          d="M21.2789 7.11222L15.3756 1.20889C14.9233 0.756667 14.3 0.5 13.6522 0.5H2.44444C1.1 0.5 0 1.6 0 2.94444V20.0556C0 21.4 1.1 22.5 2.44444 22.5H19.5556C20.9 22.5 22 21.4 22 20.0556V8.84778C22 8.2 21.7433 7.57667 21.2789 7.11222ZM4.88889 5.38889H13.4444V7.83333H4.88889V5.38889ZM17.1111 17.6111H4.88889V15.1667H17.1111V17.6111ZM17.1111 12.7222H4.88889V10.2778H17.1111V12.7222Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4704_35633">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EquivalentDocIcon;
