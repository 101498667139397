import { axiosApiInstance } from "../config/axios-instance";
import {
  defaultHeaders,
  API_ENDPOINT_CONSULT_DOCUMENT,
  TIME_UPDATE_REGISTER,
  API_ENDPOINT_DOCUMENT,
} from "../config/config.js";
import {
  enterpriseDocumentType,
  enterpriseDocumentNumber,
} from "./authActions";
import { types } from "../types/types";
import { readDetailDocument, readDetailIntegrationReport } from "../helpers/readDataDian";
import { SuccessAlert } from "../helpers/alert.helpers";
import { homologateDescripcionIdentification } from "../helpers/catalogDian";

const baseUrl = `${API_ENDPOINT_CONSULT_DOCUMENT}/consultdocument/api/DocumentoIntegracion`;
const urlInvoice = `${API_ENDPOINT_DOCUMENT}/document/api/Documento/`;

/**
 * Consulta listado documentos de integración
 * @param {*} pFilterData Información filtro
 * @returns
 */
export const getDocumentsIntegrationAction = (pFilterData) => async (
  dispatch,
  getState
) => {
  try {
    if (!pFilterData.Loadmore) {
      dispatch({
        type: types.FILL_INTEGRATION_DOCUMENT,
        payload: [],
      });
    }

    const { documentList } = getState().documentIntegrationReducer;

    changeLoadingListStatus(dispatch, true);
    const type = homologateDescripcionIdentification(enterpriseDocumentType());
    const body = {
      ...pFilterData,
      Tipoidentificacionempresa: type,
      Numeroidentificacionempresa: enterpriseDocumentNumber(), //"900943314",
    };

    const url = `${baseUrl}/GetResumenDocumentos`;
    const resp = await axiosApiInstance.post(url, body, defaultHeaders());

    const newList = pFilterData.Loadmore
      ? [...documentList, ...(resp?.data?.result ?? [])]
      : resp?.data?.result ?? [];

    dispatch({
      type: types.FILL_INTEGRATION_DOCUMENT,
      payload: newList,
    });

    if (!pFilterData.Loadmore) {
      const id = newList.length > 0 ? newList[0].id : 0;
      await getDetailIntegrationDocument(dispatch, id);
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado documentos de integración",
        error: err,
      },
    });
  } finally {
    changeLoadingListStatus(dispatch, false);
  }
};

/**
 * Asigna estado de transacción. consulta listado documentos integración
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingListStatus = (dispatch, status) => {
  dispatch({
    type: types.LOADING_INTEGRATION_DOCUMENTS,
    payload: status,
  });
};

/**
 * Consulta detalle de documento
 * @param {*} id
 * @returns
 */

export const getDetailDocumentAction = (id) => async (dispatch) => {
  await getDetailIntegrationDocument(dispatch, id);
};

/**
 * Consulta detalle  documento por identificador
 * @param {*} dispatch
 * @param {*} id
 */
const getDetailIntegrationDocument = async (dispatch, id) => {
  try {
    changeLoadingDetail(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: id,
    });
    const url = `${baseUrl}/GetDocumento`;
    const resp = await axiosApiInstance.get(url, config);

    if (!!resp?.data?.result?.objeto) {
      const detail = readDetailDocument(resp?.data?.result);
      dispatch({
        type: types.FILL_INTEGRATION_DETAIL,
        payload: detail,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener detalle documento de integración",
        error: err,
      },
    });
  } finally {
    changeLoadingDetail(dispatch, false);
  }
};

/**
 * Actualiza estado loading consultar detalle documento
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingDetail = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_INTEGRATION_DETAIL,
    payload: status,
  });
};

/**
 * Marca o desmarca documento con favorito
 */
export const standOutDocumentAction = (id, status) => async (dispatch) => {
  try {
    changeLoadingStandOut(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: id,
      pDestacado: status,
    });
    const url = `${baseUrl}/DestacarDocumento`;
    const resp = await axiosApiInstance.put(url, null, config);
    if (resp.data.statusCode === "A11") {
      dispatch({
        type: types.UPDATE_STAND_OUT_INTEGRATION,
        payload: resp.data.result ?? 0,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error actualizando estado favorito de documento",
        error: err,
      },
    });
  } finally {
    changeLoadingStandOut(dispatch, false);
  }
};

const changeLoadingStandOut = (dispatch, status) => {
  dispatch({
    type: types.LOADING_STAND_OUT_INTEGRATION,
    payload: status,
  });
};

/**
 * Destacar documento desde listado
 * @param {*} id Identificador documento
 * @param {*} status Nuevo estado
 * @returns
 */
export const standOutDocumentFormListAction = (id, status) => async (
  dispatch
) => {
  try {
    changeLoadingStandOutList(dispatch, id, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: id,
      pDestacado: status,
    });
    const url = `${baseUrl}/DestacarDocumento`;
    const resp = await axiosApiInstance.put(url, null, config);
    if (resp.data.statusCode === "A11") {
      dispatch({
        type: types.UPDATE_STAND_OUT_INTEGRATION,
        payload: resp.data.result ?? 0,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error actualizando estado favorito de documento desde listado",
        error: err,
      },
    });
  } finally {
    changeLoadingStandOutList(dispatch, id, false);
  }
};

const changeLoadingStandOutList = (dispatch, id, status) => {
  dispatch({
    type: types.LOADING_INTEGRATION_DOCUMENT_LIST,
    payload: {
      id: id,
      status: status,
    },
  });
};

/**
 * Envia la factura o nota a un correo
 * @param {*} body Informacion de la factura a enviar
 */
export const sendEmailWithDocumentAction = (
  data,
  readResponseEmail,
  type
) => async (dispatch) => {
  try {
    changeLoadingSendEmail(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pUrl: "",
    });
    const resp = await axiosApiInstance.post(
      `${urlInvoice}SendDocument`,
      data,
      config
    );
    const typedoc = type === "91" || type === "92" ? "Nota" : "Factura";
    SuccessAlert(
      null,
      ` Se realizó el reenvío de la ${typedoc} exitosamente`,
      TIME_UPDATE_REGISTER
    );

    //Leer respuesta
    readResponseEmail(resp);
  } catch (err) {
    let message = err?.response?.data?.statusMessage ?? "";
    dispatch({
      type: types.showError,
      payload: {
        message: `No hemos podido enviar correo electrónico, ${message}`,
        error: err,
      },
    });
    readResponseEmail(null);
  } finally {
    changeLoadingSendEmail(dispatch, false);
  }
};

const changeLoadingSendEmail = (dispatch, status) => {
  dispatch({
    type: types.LOADING_SEND_MAIL_INTEGRATION,
    payload: status,
  });
};

/**
 * Limpia reduuer
 */
export const cleanReduxIntegrationAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_REDUX_INTEGRATION,
  });
};

/**
 * Consulta información  para generar reporte de integracion con rango de fechas
 * @param {string} pFechaDesde Fecha de inicio
 * @param {string} pFechaHasta Fecha de fin
 * @param {function} completar Funcion, completa descarga despues de obtener información de api
 */
export const getItegracionReportAction = (
  pFechaDesde,
  pFechaHasta,
  complete,
  changeLoadingStatus
) => async (dispatch) => {
  try {
    changeLoadingStatus(true);

    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoIdentificacionEmpresa: homologateDescripcionIdentification(
        enterpriseDocumentType()
      ),
      pNumeroIdentificacionEmpresa: enterpriseDocumentNumber(),
      pFechaDesde: pFechaDesde,
      pFechaHasta: pFechaHasta,
    });

    const response = await axiosApiInstance.get(
      `${baseUrl}/ConsultarInforme`,
      config
    );
      
    var documents = response.data.result.map((document) => {
      return readDetailIntegrationReport(document);
    });

    complete(documents);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar información de reporte.",
        error: err,
      },
    });
  } finally {
    changeLoadingStatus(false);
  }
};
