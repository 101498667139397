import { Typography, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";

const useStyles = makeStyles(() => ({
  cardStatus: {
    fontFamily: "Muli",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "20.08px",
    textAlign: "left",
    paddingTop: "3px",
  },
}));

const statusColors = {
  true: "#2CC63E",
  false: "#FFA0A5",
};

/**
 * El componente Typography renderiza el texto proporcionado como su contenido.
 * El color del componente Typography se determina por el valor de la palabra en el objeto statusColors.
 * Si la palabra no se encuentra en el objeto, el color predeterminado es blanco ('#FFFFFF').
 * @param {string} word - La palabra que se debe mostrar en el componente Typography
 * @return {JSX.Element} El componente Typography
 */
const StatusDocument = ({ word }) => {
  const classes = useStyles();
  const color = useMemo(() => statusColors[word] || "#FFFFFF", [word]);

  return (
    <Typography className={classes.cardStatus} style={{ color }}>
      {word ? "Activo" : "Inactivo"}
    </Typography>
  );
};

export default StatusDocument;
