import {
  defaultHeaders,
  API_ENDPOINT_DOCUMENT,
  API_ENDPOINT_DOCUMENT_RECEPTION,
  TIME_UPDATE_REGISTER,
  TIME_CREATE_REGISTER,
} from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";
import {
  enterpriseDocumentType,
  enterpriseDocumentNumber,
} from "./authActions";
import { types } from "../types/types";
import { readDetailReceivedDocument } from "../helpers/readDataDian";
import { ConfirmAlert, SuccessAlert } from "../helpers/alert.helpers";
import { homologateDescripcionIdentification } from "../helpers/catalogDian";

const urlInvoice = `${API_ENDPOINT_DOCUMENT}/document/api/RecepcionDocumentos`;
const baseUrl = `${API_ENDPOINT_DOCUMENT_RECEPTION}/recepcion/api/Recepcion`;

/**
 * Consulta los documentos recibidos
 * @param {object} filterData Datos filtro
 */

export const getDocumentsReceivedAction = (filterData) => async (
  dispatch,
  getState
) => {
  try {
    if (!filterData.Loadmore) {
      dispatch({
        type: types.FILL_RECEIVED_DOCUMENT,
        payload: [],
      });
    }

    const { documentList } = getState().documentReceivedReducer;
    changeLoadingListStatus(dispatch, true);
    const body = {
      ...filterData,
      TipoDocumento: homologateDescripcionIdentification(
        enterpriseDocumentType()
      ),
      Documento: enterpriseDocumentNumber(), //"7719690",
    };

    const resp = await axiosApiInstance.post(
      `${baseUrl}/ObtenerDocumentosRecepcionados`,
      body,
      defaultHeaders()
    );

    const newList = filterData.Loadmore
      ? [...documentList, ...(resp?.data?.result ?? [])]
      : resp?.data?.result ?? [];

    dispatch({
      type: types.FILL_RECEIVED_DOCUMENT,
      payload: newList,
    });

    if (!filterData.Loadmore) {
      const id = newList.length > 0 ? newList[0].id : 0;
      await getDetailReceivedDocument(dispatch, id);
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener el listado de documentos recibidos.",
        error: err,
      },
    });
  } finally {
    changeLoadingListStatus(dispatch, false);
  }
};

/**
 * Asigna estado de transacción. consulta listado documentos recibidos
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingListStatus = (dispatch, status) => {
  dispatch({
    type: types.LOADING_RECEIVED_DOCUMENTS,
    payload: status,
  });
};

/**
 * Consulta detalle de documento
 * @param {*} id
 * @returns
 */
export const getDetailDocumentAction = (id) => async (dispatch) => {
  await getDetailReceivedDocument(dispatch, id);
};

/**
 * Consulta detalle  documento por identificador
 * @param {*} dispatch
 * @param {*} id
 */
const getDetailReceivedDocument = async (dispatch, id) => {
  try {
    changeLoadingDetail(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoid: id,
    });

    const resp = await axiosApiInstance.get(
      `${baseUrl}/ConsultarDocumento`,
      config
    );

    if (!!resp?.data?.result?.documento) {
      const detail = readDetailReceivedDocument(resp?.data?.result);
      dispatch({
        type: types.FILL_RECEIVED_DETAIL,
        payload: detail,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "No se ha podido obtener el detalle del documento de recepción",
        error: err,
      },
    });
  } finally {
    changeLoadingDetail(dispatch, false);
  }
};

/**
 * Actualiza estado loading consultar detalle documento
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingDetail = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_RECEIVED_DETAIL,
    payload: status,
  });
};

/**
 * Marca o desmarca documento con favorito
 * @param {*} id
 * @param {*} status
 * @returns
 */
export const standOutDocumentAction = (id, status) => async (dispatch) => {
  try {
    changeLoadingStandOut(dispatch, true);

    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: id,
      pDestacado: status,
    });

    const resp = await axiosApiInstance.put(
      `${baseUrl}/DestacarDocumento`,
      null,
      config
    );

    if (resp.data.httpCode === 200) {
      dispatch({
        type: types.UPDATE_STAND_OUT_RECEIVED,
        payload: resp.data.result ?? 0,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error actualizando el estado favorito del documento",
        error: err,
      },
    });
  } finally {
    changeLoadingStandOut(dispatch, false);
  }
};

/**
 * Actualiza estado loading destacar documento
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingStandOut = (dispatch, status) => {
  dispatch({
    type: types.LOADING_STAND_OUT_RECEIVED,
    payload: status,
  });
};

/**
 * Destacar documento desde listado
 * @param {*} id Identificador documento
 * @param {*} status Nuevo estado
 * @returns
 */
export const standOutDocumentFormListAction = (id, status) => async (
  dispatch
) => {
  try {
    changeLoadingStandOutList(dispatch, id, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoid: id,
      pDestacado: status,
    });

    const resp = await axiosApiInstance.put(
      `${baseUrl}/DestacarDocumento`,
      null,
      config
    );

    if (resp.data.httpCode === 200) {
      dispatch({
        type: types.UPDATE_STAND_OUT_RECEIVED,
        payload: resp.data.result ?? 0,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error actualizando el estado favorito del documento desde listado",
        error: err,
      },
    });
  } finally {
    changeLoadingStandOutList(dispatch, id, false);
  }
};

/**
 * Actualiza estado loading destacar documento desde listado
 * @param {*} dispatch
 * @param {*} id
 * @param {*} status
 */
const changeLoadingStandOutList = (dispatch, id, status) => {
  dispatch({
    type: types.LOADING_RECEIVED_DOCUMENT_LIST,
    payload: {
      id: id,
      status: status,
    },
  });
};

/**
 * Registra los documentos recibidos
 * @param {*} file Datos filtro
 * @param {*} cleanFilter
 * @returns
 */
export const uploadDocumentReceivedAction = (file, cleanFilter) => async (
  dispatch
) => {
  try {
    changeLoadingUpload(dispatch, true);

    const formData = new FormData();
    formData.append("Archivo", file);
    formData.append(
      "TipoDocumento",
      homologateDescripcionIdentification(enterpriseDocumentType())
    );
    formData.append("Documento", enterpriseDocumentNumber()); //"7719690"

    let config = defaultHeaders();
    Object.assign(config.headers, {
      "Content-Type": "multipart/form-data",
    });

    const response = await axiosApiInstance.post(
      `${baseUrl}/Registrar`,
      formData,
      config
    );

    changeLoadingUpload(dispatch, false);
    if (response.data.httpCode === 201) {
      SuccessAlert(
        "Buen trabajo!!!",
        "Has cargado exitosamente la factura No. " + response.data.result,
        TIME_CREATE_REGISTER
      );
      cleanFilter();
    }
  } catch (error) {
    changeLoadingUpload(dispatch, false);
    dispatch({
      type: types.showError,
      payload: {
        message: !!error?.response?.data
          ? error.response.data.statusMessage
          : "Error cargando la factura.",
        error: error,
      },
    });
  }
};

/**
 * Actualiza estado loading cargando un documento
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingUpload = (dispatch, status) => {
  dispatch({
    type: types.LOADING_UPLOAD_RECEIVED,
    payload: status,
  });
};

/**
 * Consulta listado de motivos de rechazo
 */
export const getRejectionReasonsAction = () => async (dispatch, getState) => {
  try {
    const { rejectionReasons } = getState().documentReceivedReducer;
    if (rejectionReasons.length === 0) {
      const response = await axiosApiInstance.get(
        `${urlInvoice}/GetMotivosRechazoDocumento`,
        defaultHeaders()
      );

      var data =
        response?.data?.result?.length > 0
          ? response.data.result.map((item) => ({
              value: item.codigo,
              text: item.text,
            }))
          : [];

      dispatch({
        type: types.GET_REJECTION_REASONS,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando el listado de motivos de rechazo.",
        error: err,
      },
    });
  }
};

/**
 * Realiza el proceso de acuse y recibo de bien de un documento
 * @param {*} data
 * @param {*} functionLoad
 */
export const receiptDocumentAction = (
  receiver,
  uniqueCode,
  acknowledgement,
  numberDocument,
  reject,
  rejectAction
) => async (dispatch) => {
  try {
    changeLoadingReceiptDocument(dispatch, true);
    let receipt = false;
    let body = {
      TipoDocumento: homologateDescripcionIdentification(
        enterpriseDocumentType()
      ),
      Documento: enterpriseDocumentNumber(), //"7719690",
      CodigoUnico: uniqueCode,
      Receptor: {
        TipoDocumento: receiver.documentType,
        Documento: receiver.document,
        Nombre: receiver.name,
        Apellido: receiver.lastname,
        Cargo: receiver.occupation,
        Area: receiver.area,
      },
    };

    let response = {};

    if (!acknowledgement) {
      Object.assign(body, {
        TipoAcuse: 2,
      });

      response = await axiosApiInstance.post(
        `${baseUrl}/AcuseReciboDocumento`,
        body,
        defaultHeaders()
      );

      if (response.data.httpCode === 201) {
        receipt = true;

        dispatch({
          type: types.SUCESS_ACKNOWLEDGEMENT_DOCUMENT_RECEIVED,
          payload: response.data.result,
        });
      } else {
        changeLoadingReceiptDocument(dispatch, false);
      }
    } else {
      receipt = true;
    }

    if (receipt) {
      response = await axiosApiInstance.post(
        `${baseUrl}/ReciboBienDocumento`,
        body,
        defaultHeaders()
      );

      changeLoadingReceiptDocument(dispatch, false);
      if (response.data.httpCode === 201) {
        dispatch({
          type: types.SUCESS_RECEIPT_DOCUMENT_RECEIVED,
          payload: response.data.result,
        });

        if (reject) {
          rejectAction();
        } else {
          const confirmDocumentAcceptance = async (confirmed) => {
            if (confirmed) {
              await acceptDocument(dispatch, uniqueCode, numberDocument);
            }
          };

          ConfirmAlert(
            "¿Estás seguro que deseas aceptar la factura?",
            confirmDocumentAcceptance,
            "Si",
            "No"
          );
        }
      }
    }
  } catch (err) {
    changeLoadingReceiptDocument(dispatch, false);
    dispatch({
      type: types.showError,
      payload: {
        message:
          "No se pudo realizar el recibo de bien o servicio de la factura.",
        error: err,
      },
    });
  }
};

const changeLoadingReceiptDocument = (dispatch, status) => {
  dispatch({
    type: types.LOADING_RECEIPT_DOCUMENT_RECEIVED,
    payload: status,
  });
};

/**
 * Aceptacion expresa de un documento
 * @param {*} uniqueCode
 * @param {*} numberDocument
 * @returns
 */
export const acceptDocumentAction = (uniqueCode, numberDocument) => async (
  dispatch
) => {
  await acceptDocument(dispatch, uniqueCode, numberDocument);
};

/**
 * Realiza el proceso de aceptacion expresa de un documento
 * @param {*} dispatch
 * @param {*} uniqueCode
 * @param {*} numberDocument
 */
const acceptDocument = async (dispatch, uniqueCode, numberDocument) => {
  try {
    changeLoadingAcceptanceDocument(dispatch, true);

    const type = homologateDescripcionIdentification(enterpriseDocumentType());
    const documentNumber = enterpriseDocumentNumber();
    const body = {
      Receptor: {
        TipoDocumento: type,
        Documento: documentNumber,
      },
      TipoDocumento: type,
      Documento: documentNumber, //"7719690",
      CodigoUnico: uniqueCode,
    };

    const response = await axiosApiInstance.post(
      `${baseUrl}/AceptacionExpresa`,
      body,
      defaultHeaders()
    );

    changeLoadingAcceptanceDocument(dispatch, false);

    if (response.data.httpCode === 201) {
      SuccessAlert(
        "Buen trabajo!!!",
        "Aceptaste exitosamente la factura No. " + numberDocument,
        TIME_UPDATE_REGISTER
      );

      dispatch({
        type: types.SUCESS_ACCEPT_DOCUMENT_RECEIVED,
        payload: response.data.result,
      });
    }
  } catch (error) {
    changeLoadingAcceptanceDocument(dispatch, false);
    if (
      error?.response?.data?.statusCode === "R26" &&
      error?.response?.data?.statusMessage === "Unauthorized, no quota." &&
      error?.response?.data?.httpCode === 404
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: error.response.data,
      });
      if (
        error.response.data.result.licenseActiveBase &&
        !error.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
    }
    if (error.response.data.httpCode === 404) {
      dispatch({
        type: types.DOCUMENT_AVAILABLE_ZERO,
        payload: true,
      });
    } else {
      let statusCode = error?.response?.data?.statusCode;
      dispatch({
        type: types.showError,
        payload: {
          message:
            statusCode === "R24"
              ? error.response.data.statusMessage
              : "No se pudo aceptar la factura.",
          error: error,
        },
      });
    }
  }
};

export const changeDocumetAvailableZeroAction = () => {
  return {
    type: types.DOCUMENT_AVAILABLE_ZERO,
    payload: false,
  };
};

const changeLoadingAcceptanceDocument = (dispatch, status) => {
  dispatch({
    type: types.LOADING_ACCEPT_DOCUMENT_RECEIVED,
    payload: status,
  });
};

/**
 * Realiza el proceso de reclamo de un documento
 * @param {*} rejectReason
 * @param {*} uniqueCode
 * @param {*} numberDocument
 * @returns
 */
export const rejectDocumentAction = (
  rejectReason,
  uniqueCode,
  numberDocument
) => async (dispatch) => {
  try {
    changeLoadingRejectDocument(dispatch, true);

    const type = homologateDescripcionIdentification(enterpriseDocumentType());
    const documentNumber = enterpriseDocumentNumber();
    const body = {
      Receptor: {
        TipoDocumento: type,
        Documento: documentNumber,
      },
      TipoDocumento: type,
      Documento: documentNumber, //"7719690",
      CodigoUnico: uniqueCode,
      CodigoReclamo: rejectReason,
    };

    const response = await axiosApiInstance.post(
      `${baseUrl}/ReclamoDocumento`,
      body,
      defaultHeaders()
    );

    changeLoadingRejectDocument(dispatch, false);
    if (response.data.httpCode === 201) {
      SuccessAlert(
        "Buen trabajo!!!",
        "Has rechazado exitosamente la factura No. " + numberDocument,
        TIME_UPDATE_REGISTER
      );

      dispatch({
        type: types.SUCESS_REJECT_DOCUMENT_RECEIVED,
        payload: response.data.result,
      });
    }
  } catch (error) {
    changeLoadingRejectDocument(dispatch, false);
    if (
      error?.response?.data?.statusCode === "R26" &&
      error?.response?.data?.statusMessage === "Unauthorized, no quota." &&
      error?.response?.data?.httpCode === 404
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: error.response.data,
      });
      if (
        error.response.data.result.licenseActiveBase &&
        !error.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
    }
    if (error.response.data.httpCode === 404) {
      dispatch({
        type: types.DOCUMENT_AVAILABLE_ZERO,
        payload: true,
      });
    } else {
      let statusCode = error?.response?.data?.statusCode;
      dispatch({
        type: types.showError,
        payload: {
          message:
            statusCode === "R24"
              ? error.response.data.statusMessage
              : "No se pudo rechazar la factura.",
          error: error,
        },
      });
    }
  }
};

const changeLoadingRejectDocument = (dispatch, status) => {
  dispatch({
    type: types.LOADING_REJECT_DOCUMENT_RECEIVED,
    payload: status,
  });
};

/**
 * Limpia reducer
 */
export const cleanReduxReceivedAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_REDUX_RECEIVED,
  });
};
