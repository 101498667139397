import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { DianDataCard } from "./DianDataCard";
import { ReceivedChargeCard } from "./ReceivedChargeCard";
import { ReceivedDiscountCard } from "./ReceivedDiscountCard";

const useStyles = makeStyles({
  titleItems: {
    width: "100%",
    backgroundColor: "rgba(18, 18, 18, 0.3)",
    borderRadius: 5,
    color: "#2CC63E",
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px auto",
    padding: 5,
  },
});

export const AdditionalDataPanel = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={12} xs={12}>
        <DianDataCard {...data} />
      </Grid>
      <Grid item lg={12} xs={12}>
        <div className={classes.titleItems}>{`Cargos`}</div>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={1}>
          {data.Cargos.map((charge, index) => (
            <Grid key={index} item lg={6} xs={12}>
              <ReceivedChargeCard {...charge} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div className={classes.titleItems}>{`Descuentos`}</div>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={1}>
          {data.Descuentos.map((discount, index) => (
            <Grid key={index} item lg={12} xs={12}>
              <ReceivedDiscountCard {...discount} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
