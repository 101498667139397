import React from "react";

import { useDispatch } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import RadioButtonGroupSmall from "../../../../components/radioButton/radioButtonSmall.component";
import InputSmall from "../../../../components/input/inputSmall.component";

import { updateItemAction } from "../../../../actions/documentCartAction";

import { convertStringToDecimal } from "../../../../utils/convertStringToDecimal";

const useStyles = makeStyles((theme) => ({
  commonMargin: {
    marginTop: 15,
  },
}));

/**
 * * Listado de responsables a asumir impuesto de un articulo
 */
const responsibilityList = [
  {
    value: "1",
    text: "Cliente",
  },
  {
    value: "2",
    text: "Empresa",
  },
];

export const ItemGiftComponent = ({
  handleInputChange,
  isGift,
  itemDoc,
  referencePrice,
  responsibility,
  validator,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  /**
   * * Maneja el cambio en la responsabilidad del artículo.
   * @param {Event} e - El evento del cambio.
   * @returns {void}
   */
  const handleChangeResponsibility = (e) => {
    handleInputChange(e);
    let newdata = {
      ...itemDoc,
      asumeresponsabilidad: e.target.value,
    };
    dispatch(updateItemAction(newdata));
  };

  /**
   * * Maneja el evento de desenfoque (blur) del campo de precio de referencia.
   * @param {Event} event - El evento de desenfoque.
   */
  const handleBlurReferencePrice = ({ target }) => {
    let newdata = {
      ...itemDoc,
      valorreferencia: convertStringToDecimal(target.value),
    };
    dispatch(updateItemAction(newdata));
  };

  return (
    isGift && (
      <Grid container className={classes.commonMargin}>
        <Grid item lg={7} xs={12}>
          <RadioButtonGroupSmall
            label={"Asume impuestos"}
            name={"responsibility"}
            nameValidator={"responsibility"}
            onChange={handleChangeResponsibility}
            options={responsibilityList}
            validateOptions={"required"}
            validator={validator}
            value={responsibility}
          />
        </Grid>
        <Grid item lg={5} xs={12}>
          <InputSmall
            format={"money"}
            label={"Precio referencia"}
            maxLength={21}
            name={"referencePrice"}
            nameValidator={"referencePrice"}
            onBlur={handleBlurReferencePrice}
            onChange={handleInputChange}
            validateOptions={"required|noZero"}
            validator={validator}
            value={referencePrice}
            variant={"standard"}
          />
        </Grid>
      </Grid>
    )
  );
};
