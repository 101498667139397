import { WarningAlert } from "../../../helpers/alert.helpers";
// import { warningToast } from "../../../helpers/toast.helpers";
// import { checkTotalDocument } from "../../../helpers/validateDocument";

export const validateStepTwo = (
  opdetalledocumento,
  motivonota,
  observacionesnota,
  valorapagar,
  detailDocumentSupport
) => {
  let errormessage = [];

  if (opdetalledocumento.length === 0) {
    WarningAlert("Upsss...!", "Debes crear al menos un Servicio.");
    return false;
  }

  // const valid = checkTotalDocument(valorapagar);
  // if (!valid) {
  //   errormessage.push(
  //     "El valor del anticipo es superior al valor de la nota. Por favor verifique la información."
  //   );
  //   warningToast(
  //     "Valor anticipo no valido.",
  //     "El valor del anticipo es superior al valor de la nota. Por favor verifique la información."
  //   );
  // }

  if (valorapagar > detailDocumentSupport.valorAPagar) {
    errormessage.push(
      ` El valor de la nota de ajuste ($${valorapagar} ) es superior al valor del documento referenciado ($${detailDocumentSupport.valorAPagar}).  <br/>`
    );
  }

  if (!motivonota)
    errormessage.push("Debes seleccionar el Concepto de Corrección.");

  if (observacionesnota.trim().length === 0)
    errormessage.push("Debes digitar la observación de la nota.");

  if (errormessage.length > 0) {
    let message = errormessage.join("               ");
    WarningAlert("Upsss...!", message);
    return false;
  }

  return true;
};
