import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, IconButton, makeStyles, Tooltip, Link } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import InputIcon from "../../components/input/inputIcon.component";
import { ReceivedCard } from "./receivedCard";
import ReceivedDetail from "./receivedDetail";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import CustomProgress from "../../components/Progress/progress.component";
import PopoverModal from "../../components/modal/popoverModal.component";
import StyledCheckbox from "../../components/checkForm/styledCheckbox.component";
import ResponseModal from "../../components/modal/responseModal.component";
import NotificationNotCreated from "../../components/notification/notificationNotCreated.component";
import ButtonUploadInvoice from "../../components/button/buttonUploadInvoice.component";
import { SecondCustomModal } from "../../components/modal/SecondCustomModal";
import { UploadProgress } from "./UploadProcess";

import {
  getDocumentsReceivedAction,
  getDetailDocumentAction,
  cleanReduxReceivedAction,
  getRejectionReasonsAction,
  uploadDocumentReceivedAction,
} from "../../actions/documentReceivedActions";
import { FilterDate } from "../documentintegration/filterDate";

const filterImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonIcon: {
    color: "white",
  },
  containerList: {
    height: "80.8vh",
    marginTop: 5,
    overflowY: "auto",
    overflowX: "hidden",
  },
  alignCenter: {
    textAlign: "center",
  },
  letter: {
    color: theme.palette.primaryColor,
  },
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
}));

const initialFilter = {
  Skip: 0,
  Limit: 30,
  Fechadesde: null,
  Fechahasta: null,
  Filtro: null,
  Destacada: null,
  Aceptada: null,
  Rechazada: null,
  Loadmore: false,
};

export const ReceivedManage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initialFilter);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalFilterDocuments, setTotalFilterDocuments] = useState(0);
  const [txtfilter, setTxtFilter] = useState("");
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = React.useState(null);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
  const [filterStatus, setFilterStatus] = useState({
    standOut: false,
    accepted: false,
    rejected: false,
  });
  const [selectedId, setselectedId] = useState(0);

  const {
    documentList,
    loadingList,
    documentDetail,
    loadingDetail,
    loadingUploadDocument,
  } = useSelector((s) => s.documentReceivedReducer);

  /**
   * Desmontar componente
   */
  useEffect(() => {
    return () => {
      dispatch(cleanReduxReceivedAction());
    };
  }, [dispatch]);

  /**
   *  Llama al servicio para obtener los motivos de rechazo
   */
  useEffect(() => {
    dispatch(getRejectionReasonsAction());
  }, [dispatch]);

  /**
   * Consulta listado cuando cambia información de filtro
   */
  useEffect(() => {
    dispatch(getDocumentsReceivedAction(filter));
  }, [dispatch, filter]);

  /**
   * Lee total documentos registrados en BD
   */
  useEffect(() => {
    setTotalFilterDocuments(
      documentList.length > 0 ? documentList[0].totalregistros : 0
    );

    if (totalDocuments === 0 && documentList.length > 0) {
      setTotalDocuments(documentList[0].totalregistros);
    }
  }, [documentList, totalDocuments]);

  useEffect(() => {
    if (!!documentDetail) setselectedId(documentDetail.Id);
  }, [documentDetail]);

  const cleanFilter = () => {
    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Filtro: null,
      Fechadesde: null,
      Fechahasta: null,
      Destacada: null,
      Aceptada: null,
      Rechazada: null,
      Loadmore: false,
    }));
    setModalChecksAnchorEl(null);
    setModalDatesAnchorEl(null);

    setFilterStatus({
      standOut: false,
      accepted: false,
      rejected: false,
    });
  };

  /**
   * Carga siguiente pagina listado documentos
   */
  const handleLoadMore = () => {
    setFilter((prev) => ({
      ...prev,
      Skip: documentList.length,
      Loadmore: true,
    }));
  };

  /**
   * Sincroniza nuevo valor de input con state,
   * filtra listado si el campo es vacio
   * @param {*} param0
   */
  const handleChageTextFilter = ({ target }) => {
    setTxtFilter(target.value);

    if (target.value.length === 0) {
      setFilter((prev) => ({
        ...prev,
        Skip: 0,
        Filtro: "",
        Loadmore: false,
      }));
    }
  };

  /**
   * Filtra por cadena de cararcteres
   */
  const handleFilterByText = () => {
    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Filtro: txtfilter,
      Loadmore: false,
    }));
  };

  /**
   * Abre modal para filtro por fechas
   * @param {*} event
   */
  const handleOpenFilterDate = (event) => {
    if (loadingList !== true) setModalDatesAnchorEl(event.currentTarget);
  };

  /**
   * Filtra listado documentos por rango fechas
   * @param {} dateTo
   * @param {*} dateFrom
   */
  const handleConfirmDate = (dateTo, dateFrom) => {
    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: dateTo,
      Fechahasta: dateFrom,
      Loadmore: false,
    }));
    setModalDatesAnchorEl(null);
  };

  /**
   * Limpia filtro por fechas
   */
  const handelCleanFilterDate = () => {
    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: null,
      Fechahasta: null,
      Loadmore: false,
    }));
    setModalDatesAnchorEl(null);
  };

  /**
   * Abre modal para filtro por estado
   * @param {*} event
   */
  const handleOpenFilterStatus = (event) => {
    if (loadingList !== true) setModalChecksAnchorEl(event.currentTarget);
  };

  /**
   *  Filtra listado por estado
   * @param {*} param0
   */
  const handleChangeFilterStatus = ({ target }) => {
    const { name, checked } = target;
    setFilterStatus((prev) => ({
      ...prev,
      [name]: checked,
    }));

    let standOut = filter.Destacada;
    let accepted = filter.Aceptada;
    let rejected = filter.Rechazada;

    if (name === "accepted") {
      accepted = checked;
    }

    if (name === "rejected") {
      rejected = checked;
    }

    if (name === "standOut") {
      standOut = checked;
    }

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Destacada: standOut,
      Aceptada: accepted,
      Rechazada: rejected,
      Loadmore: false,
    }));
  };

  /**
   * Limpia filtro por estado
   */
  const handleCleanFilterStatus = () => {
    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Destacada: null,
      Aceptada: null,
      Rechazada: null,
      Loadmore: false,
    }));
    setModalChecksAnchorEl(null);

    setFilterStatus({
      standOut: false,
      accepted: false,
      rejected: false,
    });
  };

  /**
   * Consulta detalle de documento
   * @param {*} id Identificador documento
   */
  const handleSelectDocument = useCallback(
    (id) => {
      dispatch(getDetailDocumentAction(id));
      setselectedId(id);
    },
    [dispatch]
  );

  const modalStatusFilter = () => {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={filterStatus.standOut}
            onChange={handleChangeFilterStatus}
            name="standOut"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Destacadas
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={filterStatus.accepted}
            onChange={handleChangeFilterStatus}
            name="accepted"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Aceptadas
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={filterStatus.rejected}
            onChange={handleChangeFilterStatus}
            name="rejected"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Rechazadas
        </Grid>
        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <Link
            href="#"
            onClick={handleCleanFilterStatus}
            variant="inherit"
            underline="none"
            className={classes.link}
          >
            Limpiar Filtro
          </Link>
        </Grid>
      </Grid>
    );
  };

  const [modalResponse, setModalResponse] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  const showValidationErrors = (body) => {
    setModalResponse({
      type: "warning",
      title: "Upss...!!!",
      subtitle: "Ocurrió algo inesperado",
      body: <div>{body}</div>,
      modalImage: "warning",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 1,
    });
  };

  const handleResponseModal = () => {
    setModalResponse({
      open: false,
    });
  };

  /**
   * Validaciones pre-cargue y cargue de columnas y validaciones
   * de tipo de datos del excel
   * @param {*} e
   */
  const initialValidations = (e) => {
    e.preventDefault();
    if (!!e.target) {
      const file = e.target.files[0];
      //Extensiones admitidas
      const admitedExtensions = ["xml", "zip"];
      //Tamaño Màximo permitido del archivo
      const sizeMax = 500;
      //Validacion de formatos
      if (!validateExtension(file, admitedExtensions)) {
        showValidationErrors(
          "El archivo que estas cargando no corresponde a un XML o un ZIP, Colfactura no puede interpretarlo."
        );
        e.target.value = "";
        return;
      } else if (!validateSize(file, sizeMax)) {
        //validacion de Peso Màximo
        showValidationErrors(
          "Recuerda que el tamaño del archivo no puede superar los " +
            sizeMax +
            " Megabytes"
        );
        e.target.value = "";
        return;
      }

      dispatch(uploadDocumentReceivedAction(file, cleanFilter));
      e.target.value = "";
    }
  };

  /**
   * Validación de la extensión
   *
   * @param {*} file
   * @param {*} admitedExtensions
   */
  function validateExtension(file, admitedExtensions) {
    var ext = file.name.split(".");
    var extension = ext[ext.length - 1];
    return admitedExtensions.includes(extension);
  }

  /**
   * Validación del tamaño del archivo
   *
   * @param {*} file
   * @param {*} sizeMax
   */
  function validateSize(file, sizeMax) {
    //var fileSize = file.size / 1024 / 1024; // in MB
    var fileSize = file.size / 1024; // in KB
    return fileSize <= sizeMax;
  }

  return (
    <>
      <br />
      <Grid container spacing={1}>
        {documentList.length === 0 && !loadingList && totalDocuments === 0 ? (
          <Grid item xs={12}>
            <NotificationNotCreated
              text={
                <>
                  Por el momento no has cargado ninguna factura.
                  <br />
                  <ButtonUploadInvoice
                    text="Cargar una factura"
                    principal={false}
                    onChange={initialValidations}
                  />
                </>
              }
            />
          </Grid>
        ) : (
          <>
            <Grid item lg={5} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} xs={12} style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <InputIcon
                      name={"txtFilter"}
                      label={"Buscar por emisor"}
                      value={txtfilter}
                      onChange={handleChageTextFilter}
                      onSearch={handleFilterByText}
                      maxLength={100}
                      disabled={loadingList}
                    />
                  </div>
                  <Tooltip title="Filtrar por fechas">
                    <IconButton onClick={handleOpenFilterDate} disabled={false}>
                      <EventIcon className={classes.buttonIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Filtrar por estado">
                    <IconButton
                      className={classes.buttonFilterStatus}
                      onClick={handleOpenFilterStatus}
                      disabled={loadingList}
                    >
                      <img alt={"Filtrar"} src={filterImage}></img>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className={classes.containerList}>
                    <Grid container spacing={1}>
                      {documentList.length === 0 && loadingList && (
                        <Grid
                          item
                          lg={12}
                          xs={12}
                          style={{ textAling: "center" }}
                        >
                          <CustomProgress />
                        </Grid>
                      )}

                      {documentList.map((item) => (
                        <Grid key={item.id} item lg={12} xs={12}>
                          <ReceivedCard
                            selectedId={selectedId}
                            onClick={handleSelectDocument}
                            {...item}
                          />
                        </Grid>
                      ))}

                      {documentList.length === 0 && !loadingList && (
                        <Grid
                          item
                          lg={12}
                          xs={12}
                          style={{ textAling: "center" }}
                        >
                          <span>No se han encontado resultados...</span>
                        </Grid>
                      )}
                    </Grid>
                    {documentList.length > 0 &&
                      totalFilterDocuments !== documentList.length && (
                        <Grid container justify={"center"}>
                          <Grid item lg={6} xs={12}>
                            <ButtonPrimary
                              onClick={handleLoadMore}
                              text={"Mostrar más..."}
                              loading={loadingList}
                              disabled={totalDocuments === documentList.length}
                            />
                          </Grid>
                        </Grid>
                      )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={7} xs={12}>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <ButtonUploadInvoice
                    text={
                      <>
                        Cargar una factura &nbsp; <AddCircleRoundedIcon />
                      </>
                    }
                    principal={true}
                    onChange={initialValidations}
                  />
                </Grid>
                {loadingDetail ? (
                  <Grid item lg={12} xs={12}>
                    <CustomProgress />
                  </Grid>
                ) : (
                  <Grid item lg={12} xs={12}>
                    {!!documentDetail && documentList.length > 0 && (
                      <ReceivedDetail data={documentDetail} />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <PopoverModal
        handleClose={() => setModalDatesAnchorEl(null)}
        open={Boolean(modalDatesAnchorEl)}
        component={() => (
          <FilterDate
            handleConfirmDate={handleConfirmDate}
            handleCleanFilter={handelCleanFilterDate}
            dateFrom={filter.Fechadesde}
            dateTo={filter.Fechahasta}
          />
        )}
        title={""}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />
      <PopoverModal
        handleClose={() => setModalChecksAnchorEl(null)}
        open={Boolean(modalChecksAnchorEl)}
        component={modalStatusFilter}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleResponseModal}
      />

      {/* Modal carga de factura */}
      <SecondCustomModal
        open={loadingUploadDocument}
        component={
          <UploadProgress text="Cargando factura, espera un momento por favor." />
        }
        title={"Carga de factura"}
        showCloseButton={false}
        disableBackdropClick={true}
      />
    </>
  );
};
