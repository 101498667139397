import React from "react";

import { Grid } from "@material-ui/core";

import { RenderItemSection } from "./RenderItemSection";

import { renderSections } from "../utils";

export const CartItemContainer = ({
  sectionApplyGift,
  sectionItemDiscounts,
  sectionItemGift,
  sectionItemImage,
  sectionItemQuantity,
  sectionItemRetention,
  sectionTaxItem,
  sectionTotalItem,
  sectionUnitValue,
  showSections,
}) => {
  /**
   * * Contenedor de secciones del carrito de items.
   * @property {Object} leftSections - Secciones izquierdas.
   * @property {Object} rightSections - Secciones derechas.
   */
  const sectionsContainer = {
    leftSections: {
      sectionApplyGift,
      sectionItemDiscounts,
      sectionItemRetention,
      sectionItemGift,
    },
    rightSections: {
      sectionUnitValue,
      sectionItemQuantity,
      sectionTaxItem,
      sectionTotalItem,
    },
  };

  return (
    <Grid container spacing={1}>
      <RenderItemSection {...sectionItemImage} />
      <Grid item xl={10} lg={9} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12} style={{ borderRight: "1px solid gray" }}>
            {renderSections(sectionsContainer.leftSections, showSections)}
          </Grid>
          <Grid item lg={6} xs={12} container spacing={1}>
            {renderSections(sectionsContainer.rightSections, showSections)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
