import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import DocumentItemCard from "./document-item-card.component";
import CollapsePanel from "../../../components/collapse/collapsestyles.component";
import { DocumentChargeCard } from "../../document/charges/documentChargeCard";
import { DocumentDiscountCard } from "../../document/discounts/documentDiscountCard";
import DocumentReference from "./documentReference.component";
import ArchiveModal from "../../../components/modal/archiveModal.component";
import ResponseModal from "../../../components/modal/responseModal.component";

import {
  getDocumentFilesAction,
  getNonElectronicFilesAction,
} from "../../../actions/invoiceActions";
import { format } from "date-fns";
import { FormatDecimal } from "../../../components/common/formatDecimal";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primaryColor,
    backgroundColor: "rgba(216,216,216,0.09)",
    overflow: "auto",
    width: "100%",
  },
  title: {
    fontsize: 16,
  },
  containerTitle: {
    textAlign: "center",
    padding: "20px 10px",
    fontWeight: 600,
    borderBottom: "0.1px solid #16B1F3",
    marginBottom: 20,
  },
  containerData: {
    backgroundColor: "#4B4B4B",
    borderRadius: 5,
    padding: 25,
    height: "78vh",
    overflow: "auto",
  },
  containerHeader: {
    backgroundColor: "#5E5E5E",
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  numberContainer: {
    margin: "10px auto",
  },
  seccionTitle: {
    height: 36,
    width: "100%",
    borderRadius: 5,
    backgroundColor: "rgba(18,18,18,0.3)",
    color: "#2CC63E",
    fontFamily: "Muli",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    paddingTop: "0.8%",
    margin: "20px auto",
  },
  gridData: {
    display: "grid",
    width: "100%",
  },
  grayline: {
    height: 1,
    width: "100%",
    borderBottom: "1px solid gray",
    marginTop: 5,
  },
  label: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 12,
  },
  valueTotal: {
    color: "#2CC63E",
    fontSize: 20,
    fontWeight: 600,
  },
  legend: {
    fontSize: "0.9rem",
    fontStyle: "italic",
    color: "rgba(255,255,255,0.5)",
  },
  containerValue: {
    textAlign: "center",
    margin: "10px auto",
  },
  name: {
    fontSize: "1.2rem",
    color: "#F6D746",
    fontWeight: 800,
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  flexContent: {
    display: "flex",
  },
  icon: {
    color: "#2CC63E",
    marginLeft: 0,
    marginRight: 10,
    fontSize: "1.3rem",
  },
  containerActions: {
    backgroundColor: "#393939",
    padding: 0,
  },
  button: {
    borderRadius: "15px !important",
  },
  labelButton: {
    flexDirection: "column",
    fontSize: 12,
    color: theme.palette.primaryColor,
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: theme.palette.primaryColor,
  },
  iconAction: {
    color: "white",
    fontSize: "1.3rem",
  },
}));

const DocumentRevision = ({ data, handleBackRevision, ...props }) => {
  const classes = useStyles();
  const [expand] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [base64PDF, setBase64PDF] = useState(null);
  const [base64XML, setBase64XML] = useState(null);

  const [loadingGetFile, setLoadingGetFile] = useState(false);
  const [modalData, setModalData] = React.useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  /**
   * Evento click, controla visualizar o esconder modal ver PDF
   */
  const handleOpenModal = () => {
    //Documetos no electronicos
    if (data.diantipomodalidadid !== 3) {
      setLoadingGetFile(true);
      setOpenModal(true);
      props.getNonElectronicFilesAction(data.id, readResponseGetFiles);
      return;
    }

    if (data.codigounico === null && data.diantipomodalidadid === 3) {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Archivo no disponible",
        body: (
          <div>
            {
              "Factura en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá visualizarlo."
            }
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
      return;
    } else {
      let request = {
        tipodocumento: data.tipodocumentoentidad,
        documento: data.documentompresa,
        codigounico: data.codigounico,
        tipomodalidad: data.diantipomodalidadid,
        id: data.id,
      };
      setLoadingGetFile(true);
      setOpenModal(true);
      props.getDocumentFilesAction(request, readResponseGetFiles);
    }
  };

  /**
   * Lee respuesta consulta archvivos documento electrónico
   * @param {*} data Response
   */
  const readResponseGetFiles = (response) => {
    setLoadingGetFile(false);
    if (response !== null) {
      setBase64PDF(
        response.find((c) => c?.tipoContenido === "application/pdf")
          ?.archivoBase64 ?? null
      );
      setBase64XML(
        response.find((c) => c?.tipoContenido === "application/xml")
          ?.archivoBase64 ?? null
      );
    } else {
      setOpenModal(false);
    }
  };

  /**
   * Cierra Modal, Notificación usuario
   */
  const handleCloseResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  /**
   * Cierra Modal, Previsusalizacion PDF
   */
  const handleCloseFileModal = () => {
    setOpenModal(false);
    setBase64PDF(null);
    setBase64XML(null);
  };

  /**
   * Panel información adicional
   * @returns
   */
  const additionalInformationPanel = () => {
    return (
      <Grid container>
        <Grid item lg={12} xs={12} className={classes.seccionTitle}>
          <span>Cargos</span>
        </Grid>
        <Grid container spacing={1}>
          {data?.opdocumentocargos?.map((item, index) => {
            return (
              <Grid item key={index} lg={6} xs={6}>
                <DocumentChargeCard data={item} />
              </Grid>
            );
          })}
        </Grid>

        <Grid item lg={12} xs={12} className={classes.seccionTitle}>
          <span>Descuentos</span>
        </Grid>
        <Grid container spacing={1}>
          {data?.opdocumentodescuentos?.map((item, index) => {
            return (
              <Grid item key={index} lg={6} xs={6}>
                <DocumentDiscountCard data={item} />
              </Grid>
            );
          })}
        </Grid>

        <Grid item lg={12} xs={12} className={classes.seccionTitle}>
          <span>Documentos de referencia</span>
        </Grid>
        <Grid container spacing={1}>
          {data?.documentosreferencia?.map((item, index) => {
            return (
              <Grid item key={index} lg={6} xs={6}>
                <DocumentReference data={item} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="row" alignItems="stretch">
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item lg={4} xs={4} className={classes.containerTitle}>
            <span>Previsualizar Nota</span>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item lg={6} xs={6}>
            <div className={classes.containerData}>
              <Grid container>
                <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                  <span>Información de la nota</span>
                </Grid>
              </Grid>

              <Grid container className={classes.containerHeader} spacing={1}>
                <Grid item lg={6} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>Factura Asociada</label>
                    <span>{data?.numeroreferencia}</span>
                    <div className={classes.grayline} />
                  </div>
                </Grid>
                <Grid item lg={6} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>Tipo Nota</label>
                    <span>
                      {!!data?.dianconceptonotacreditoid ? "Crédito" : "Debito"}
                    </span>
                    <div className={classes.grayline} />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>
                      Motivo de la edición
                    </label>
                    <span>{data?.descripcionnc ?? data?.descripcionnd}</span>
                    <div className={classes.grayline} />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>Observaciones</label>
                    <span>{data?.textoobservacion}</span>
                  </div>
                </Grid>
              </Grid>

              <div className={classes.grayline} />
              <Grid container className={classes.numberContainer}>
                <Grid item lg={6} xs={12}>
                  <span>No. {data?.numero}</span>
                </Grid>
                <Grid item lg={6} xs={12} style={{ textAlign: "end" }}>
                  <span>{format(new Date(data?.fecha), "dd/MM/yyyy")}</span>
                </Grid>
              </Grid>

              <div className={classes.grayline} />

              <Grid container className={classes.containerValue}>
                <Grid item lg={12} xs={12} className={classes.gridData}>
                  <span className={classes.valueTotal}>
                    <FormatDecimal
                      value={data?.valorapagar}
                      prefix={"$"}
                      size={"small"}
                    />
                  </span>
                  <span className={classes.legend}>
                    Valor total impuestos incluidos
                  </span>
                </Grid>
              </Grid>

              <div className={classes.grayline} />

              <Grid
                container
                justify="flex-start"
                style={{ margin: "10px auto" }}
              >
                <Grid item lg={12} xs={12}>
                  <div className={classes.name}>{data?.razonsocialcliente}</div>
                  <div className={classes.flexContent}>
                    <span>{`${data.desdoccli}: ${data?.clientedocumento}`}</span>
                  </div>
                  <div className={classes.flexContent}>
                    <span>{data.desctirg}</span>
                  </div>
                  <div className={classes.flexContent}>
                    <EmailIcon className={classes.icon} />
                    <div className={classes.wordBreak}>
                      {" "}
                      {data?.correoelectronicocliente}
                    </div>
                  </div>
                  <div className={classes.flexContent}>
                    <PhoneIcon className={classes.icon} />
                    <div className={classes.wordBreak}>
                      {data?.telefonocliente}
                    </div>
                  </div>
                  <div className={classes.flexContent}>
                    <RoomIcon className={classes.icon} />
                    <div className={classes.wordBreak}>{`${data?.clidirdes ??
                      ""} ${data?.cliciudes ?? ""}-${data.clidesdep ??
                      ""}`}</div>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                  <span>
                    {`Articulos (${
                      !!data?.opdetalledocumento
                        ? data?.opdetalledocumento?.length
                        : "0"
                    })`}{" "}
                  </span>
                </Grid>
              </Grid>

              <Grid container>
                {data?.opdetalledocumento?.map((item, key) => {
                  return (
                    <Grid item key={key} lg={12} xs={12}>
                      <DocumentItemCard data={item} />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container>
                <Grid item lg={12} xs={12}>
                  <CollapsePanel
                    component={additionalInformationPanel}
                    title={"Información Adicional"}
                    titleOpen={"Información Adicional"}
                    titleEdit={"Información Adicional"}
                    open={expand}
                    backgroundColorSummary={{
                      backgroundColor: "#2CC63E",
                      color: "#000000",
                    }}
                    backgroundColorDetail={{ backgroundColor: "#5E5E5E" }}
                  ></CollapsePanel>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container className={classes.containerActions}>
          <Grid item lg={4} xs={12}>
            <IconButton
              classes={{ root: classes.button, label: classes.labelHorizontal }}
              variant="raised"
              disableRipple={true}
              onClick={() => handleBackRevision()}
              style={{ marginTop: 10 }}
            >
              <div className={classes.containerIcon}>
                <ArrowBackIosIcon className={classes.iconAction} />
              </div>
              <span style={{ marginLeft: 5, fontSize: "0.9rem" }}>
                {"Volver"}
              </span>
            </IconButton>
          </Grid>
          <Grid item lg={4} xs={12} style={{ textAlign: "center" }}>
            <IconButton
              classes={{ root: classes.button, label: classes.labelButton }}
              variant="raised"
              disableRipple={true}
              onClick={handleOpenModal}
            >
              <div className={classes.containerIcon}>
                <PictureAsPdfIcon className={classes.iconAction} />
              </div>
              <span style={{ marginTop: 5 }}>{"Generar PDF"}</span>
            </IconButton>
          </Grid>
        </Grid>

        {/* Modal previsualizar PDF */}
        <ArchiveModal
          loading={loadingGetFile}
          open={openModal}
          pdfBase64={base64PDF}
          xmlBase64={base64XML}
          handleClose={handleCloseFileModal}
        />

        <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleCloseResponseModal}
        />
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getDocumentFilesAction,
  getNonElectronicFilesAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentRevision);
