

import { types } from '../types/types'
import isEmpty from '../utils/isEmpty'

const initialState = {
    loadingSyncNumerations: false,
    loadingUpdateState: false,
    loadingGetNumerations: false,
    devices: [],
    modalities:[]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_NUMERATION:
            return {
                ...state,
                saveNumerationResponse: action.payload,
            }
        case types.GET_NUMERATIONS:
            return {
                ...state,
                numerations: action.payload,
                filteredNumerations: action.payload1,
            }
        case types.GET_NUMERATION:
            return {
                ...state,
                numeration: action.payload
            }
        case types.UPDATE_NUMERATION:
            return {
                ...state,
                updateNumerationResponse: action.payload,
            }
        case types.UPDATE_NUMERATION_STATE:
            return {
                ...state,
                updateNumerationStateResponse: action.payload,
            }
        case types.GET_MODALITY_TYPES:
            return {
                ...state,
                modalities: action.payload,
            }
        case types.LOAD_NUMERATION:
            return {
                ...state,
                loadedNumeration: action.payload,
                editNumerationStatus: !isEmpty(action.payload),
            }
        case types.GET_FILTER_NUMERATIONS:
            return {
                ...state,
                getFilterNumerations: action.payload,
            }
        case types.SYNC_GET_NUMERATION:
            return {
                ...state,
                syncNumerationResponse: action.payload,
            }
        case types.STATUS_SYNC_NUMERATION:
            return {
                ...state,
                loadingSyncNumerations: action.payload
            }
        case types.LOADING_UPDATE_NUMERATION:
            return {
                ...state,
                loadingUpdateState: action.payload
            }
        case types.LOADING_SAVE_NUMERATION:
            return {
                ...state,
                loadingSaveNumeration: action.payload
            }
        case types.LOADING_GET_NUMERATIONS:
            return {
                ...state,
                loadingGetNumerations: action.payload
            }
        case types.GET_DEVICES:
            return {
                ...state,
                devices: action.payload,
            }
        default:
            return state
    }
}