import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import Grid from '@material-ui/core/Grid';
import InputForm from "../../../components/input/input.component";
import RadioButtonGroup from '../../../components/radioButton/radioButton.component'
import ButtonSecundary from '../../../components/button/buttonSecundary.component'
import getTypeValue from "../../../utils/typeValue";

import { saveChargeAction } from "../../../actions/newChargeAction";
import { updateChargeDocumentAction } from "../../../actions/documentCartAction";

import { useFormComplete } from '../../../hooks/useFormComplete';
import { regexDecimal, regexNames } from '../../../helpers/customRegex.hepers'

export const ChargeForm = ({
    handleCloseModal
}) => {
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
    const [, forceUpdate] = useState();
    const dispatch = useDispatch();
    const { values, handleInputChange, handleUpdateForm, reset } = useFormComplete({
        type: "1",
        value: 0,
        name: ""

    });
    const { type, value, name } = values;
    const { loadingSaveCharge, activeDocumentCharge } = useSelector(state => state.newChargeReducer);


    useEffect(() => {
        if (!!activeDocumentCharge) {
            const { tipocargo, valor, descripcion } = activeDocumentCharge;
            handleUpdateForm({
                type: tipocargo.toString(),
                value: valor,
                name: descripcion
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocumentCharge])

    /**
     * Sincroniza tipo cargo con state
     * @param {*} param0 
     */
    const handleChangeTypeCharge = ({ target }) => {
        handleUpdateForm({
            type: target.value,
            value: 0

        })
    }

    /**
     * Asigna valor por defecto si no se digita valor
     * @param {*} param0 
     */
    const handleBlurValue = ({ target }) => {
        if (target.value.trim().length === 0)
            handleUpdateForm({ value: 0 });
    }

    /**
     * Sincroniza descripción con state
     * @param {*} param0 
     */
    const handleChangeName = ({ target }) => {
        handleUpdateForm({
            name: target.value.replace(regexNames, '')
        });
    }

    /**
   * Limpia cero de input
   * @param {*} e 
   * @param {*} name 
   */
    const onClickNotZero = (e, name) => {
        let value = e.target.value;
        if (!!value) {
            value = value.replace(regexDecimal, '');
            if (value === 0 || value === '0') {
                handleUpdateForm({
                    [name]: ""
                })
            }
        }
    }

    /**
     * Guarda información nuevo cargo
     * @param {*} e 
     */
    const handleSave = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {

            if (!!activeDocumentCharge) {
                let charge = {
                    ...activeDocumentCharge,
                    tipocargo: parseInt(type),
                    valor: parseFloat(value),
                    esglobal: false,
                }
                dispatch(updateChargeDocumentAction(charge, reset));

            } else {
                let charge = {
                    id: 0,
                    descripcion: name,
                    tipocargo: parseInt(type),
                    valor: parseFloat(value),
                    esglobal: false,
                }

                dispatch(saveChargeAction(charge, reset));
            }
            handleCloseModal();
            validator.current.hideMessages();
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
                <RadioButtonGroup
                    name={'type'}
                    value={type}
                    onChange={(e) => handleChangeTypeCharge(e)}
                    options={getTypeValue()}
                    label={"Tipo Cargo:"}
                    validator={validator.current}
                    validateOptions={"required"}
                />
            </Grid>
            <Grid item lg={12} xs={12}>
                <InputForm
                    name={'value'}
                    label={type === '2' ? "% Cargo *" : "Valor Cargo *"}
                    maxLength={type === '2' ? 9 : 21}
                    value={value}
                    onChange={handleInputChange}
                    onBlur={handleBlurValue}
                    onClick={onClickNotZero}
                    nameValidator={'Valor Cargo'}
                    validator={validator.current}
                    validateOptions={type === '1' ? "required|noZeroTax" : "required|noZeroPercentage"}
                    format={type === '1' ? 'money' : 'porcentage'}
                />
            </Grid>
            <Grid item lg={12} xs={12}>
                <InputForm
                    name={'name'}
                    label={"Nombre Cargo *"}
                    maxLength={100}
                    value={name}
                    onChange={handleChangeName}
                    validator={validator.current}
                    validateOptions={"required"}
                    disabled={!!activeDocumentCharge ? true : false}
                />
            </Grid>

            <Grid item lg={12} xs={12}>
                <ButtonSecundary
                    text={'Confirmar'}
                    loading={loadingSaveCharge}
                    onClick={handleSave} />
            </Grid>
        </Grid>

    )
}
