import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import { checkTokenValidAction } from "./../../actions/authActions";
import { getCompanyAction } from "./../../actions/basicDataActions";

import { URL_SECURITY } from "../../config/config";
import isEmpty from "../../utils/isEmpty";
import ButtonPrimary from "../../components/button/buttonPrimary.component";

const useStyles = (theme) => ({
  divContainer: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    textAlign: "center",
  },
  gridAling: {
    margin: "20px",
    padding: "20px",
  },
  gridMargin: {
    marginTop: 20,
  },
  titleModal: {
    fontSize: 30,
    fontWeight: 800,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.fourthColor,
  },
  subTitleMessage: {
    fontSize: 30,
    fontFamily: theme.typography.fontFamily,
  },
  divImage: {
    width: "85%",
    height: "100%",
    display: "inline-grid",
    justifyItems: "center",
  },
  warningImage: {
    width: "inherit",
    height: "inherit",
  },
  bodyMessage: {
    fontWeight: "800",
    fontFamily: theme.typography.fontFamily,
    marginBottom: 2,
  },
  logoColfactura: {
    height: 51,
    width: 162.25,
  },
});

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validEnterprise: true,
      wizard: null,
      errorMessage: "",
    };
  }

  componentDidMount() {
    const queryString = this.props.match.params.queryString;
    this.props.checkTokenValidAction(queryString, this.completeValid);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    return Object.keys(update).length ? update : null;
  }

  /**
   * Completa autenticación usuario
   * @param {*} data Información empresa
   */
  completeValid = (data) => {
    if (isEmpty(data?.result?.enterprise)) {
      this.setState({
        validEnterprise: false,
        errorMessage:
          data?.statusCode === "404"
            ? "La empresa no existe en Colfactura"
            : "Lo sentimos, ha ocurrido un error interno y no pudimos completar tu solicitud, si el error persiste comuníquese con el Administrador.",
      });
    } else {
      this.setState({
        wizard: data.result.enterprise.wizard,
      });
    }
  };

  redirectToSecurity = () => {
    window.location.href = URL_SECURITY;
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <Grid item lg={4} md={4} sm={12}>
            <div className={classes.divContainer}>
              {this.state.validEnterprise ? (
                <CircularProgress
                  className={classes.gridAling}
                  size={60}
                  thickness={7}
                />
              ) : (
                <Grid container className={classes.gridMargin}>
                  <Grid item xs={12}>
                    <Grid container justify="center">
                      <Grid item lg={6} md={6} sm={12}>
                        <div className={classes.divContainer}>
                          <div
                            id="response-modal-title"
                            className={classes.titleModal}
                          >
                            Oops!!!
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Grid item lg={12} md={12} sm={12}>
                        <div
                          id="response-modal-description"
                          className={classes.subTitleMessage}
                        >
                          Ocurrió algo inesperado
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Grid item lg={12} md={12} sm={12}>
                        <div className={classes.divImage}>
                          <img
                            src={require("./../../images/modal/14Ocurriounerror-14.png")}
                            alt="messageImage"
                            className={classes.warningImage}
                          ></img>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Grid item lg={8} md={8} sm={12}>
                        <div className={classes.bodyMessage}>
                          {this.state.errorMessage}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Grid item lg={4} md={4} sm={6} xs={8}>
                        <ButtonPrimary
                          text={"Aceptar"}
                          type={"button"}
                          onClick={() => this.redirectToSecurity()}
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Grid item lg={4} md={4} sm={12}>
                        <img
                          src={require("./../../images/logo-small.png")}
                          className={classes.logoColfactura}
                          alt={"Logo"}
                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        {this.state.wizard === 2 || this.state.wizard === 3 ? (
          <Redirect to="/dashboard"></Redirect>
        ) : this.state.wizard === 1 ? (
          <Redirect to="/steps"></Redirect>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Index.propTypes = {
  prop: PropTypes,
  getCompanyAction: PropTypes.func.isRequired,
  checkTokenValidAction: PropTypes.func.isRequired,
  basicDataReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  basicDataReducer: state.basicDataReducer,
});

export default connect(mapStateToProps, {
  getCompanyAction,
  checkTokenValidAction,
})(withStyles(useStyles)(Index));
