import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  select: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    "& fieldset": {
      border: "2px solid #757575",
    },
    "&:hover fieldset": {
      borderColor: "gray",
    },
    "&.MuiOutlinedInput-notchedOutline fieldset ": {
      borderColor: "green !important", //azul
    },
  },
  icon: {
    color: "#FFFF",
    marginRight: 10,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiOutlinedInput-input": {
      color: "#FFFF",
    },
    "& .MuiInputLabel-root": {
      color: "#FFFF",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#FFFF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#FFFF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#16B1F3",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      backgroundColor: "#232323",
      color: "gray",
      width: "20%",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336",
    },
  },
  buttonClass: {
    border: "none",
    background: "transparent",
    color: "white",
    fontSize: 16,
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    height: 35,
    outline: 0,
  },
  error: {
    color: "#f44336",
  },
}));

const ExpandableSelect = ({
  onChange,
  options,
  optionsSeeMore,
  label,
  value,
  name,
  validator,
  validateOptions,
  ...props
}) => {
  const classes = useStyles();
  const [seeMore, setSeeMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  let errorValidator = validator
    ? validator.message(label, value, validateOptions)
    : "";

  const handleSeeMore = () => {
    setSeeMore(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onBlur = () => {
    setSeeMore(false);
  };

  return (
    <div className={classes.root}>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={!!errorValidator ? true : false}
      >
        <InputLabel id={`demo-simple-select-label ${name}`}>
          {" "}
          {label}
        </InputLabel>
        <Select
          name={name}
          className={classes.fieldset}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          autoWidth
          onBlur={onBlur}
          onChange={onChange}
          IconComponent={() => <ExpandMoreIcon className={classes.icon} />}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}

          {optionsSeeMore.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ display: seeMore ? "block" : "none" }}
            >
              {option.text}
            </MenuItem>
          ))}

          <MenuItem
            value=""
            onClick={handleOpen}
            style={{ display: seeMore ? "none" : "block" }}
          >
            <button className={classes.buttonClass} onClick={handleSeeMore}>
              Ver más...
            </button>
          </MenuItem>
        </Select>
        {!!errorValidator && (
          <div className={classes.error}>{errorValidator}</div>
        )}
      </FormControl>
    </div>
  );
};

export default ExpandableSelect;
