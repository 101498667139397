import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import PersonIcon from '@material-ui/icons/Person';

const styles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#3B3B3B',
        color: '#FFFFFF',
        fontWeight: 300,
        fontFamily: 'Muli',
        cursor: 'pointer',
        fontSize: '16px',
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 55
        },
        '& .MuiCardHeader-root': {
            paddingBottom: 0,
            paddingTop: 5
        }

    },
    rootchoose: {
        backgroundColor: '#16B1F3',
        color: '#FFFFFF',
        fontWeight: 300,
        fontFamily: 'Muli',
        cursor: 'pointer',
        fontSize: '16px',
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 55
        },
        '& .MuiCardHeader-root': {
            paddingBottom: 0,
            paddingTop: 5
        }
    },
    title: {
        color: '#FFFFFF',
        fontWeight: 500,
        fontSize: 16,
        fontFamily: 'Muli'
    },
    icon: {
        color: '#2CC63E'
    },
    wordBreak: {
        wordBreak: 'break-all'
    }

}));

export const ContactDocumentCard = React.memo(
    ({
        id,
        nombre,
        telefono,
        email,
        selected,
        handleSelection
    }) => {
        const classes = styles();

        return (
            <Card
                className={selected === id ? `${classes.rootchoose} animate__animated animate__fadeIn animate__faster` : `${classes.root} animate__animated animate__fadeIn animate__faster` }
                onClick={handleSelection}>
                <CardHeader
                    avatar={
                        <PersonIcon
                            style={{
                                color: selected === id
                                    ? "#FFFFFF"
                                    : "#2CC63E"
                            }} />
                    }
                    classes={{
                        title: classes.title,
                    }}
                    title={nombre}

                />
                <CardContent>
                    <span className={classes.wordBreak}>
                        {`${telefono} / ${email}`}
                    </span>
                </CardContent>
            </Card>
        )
    }

)
ContactDocumentCard.propTypes = {
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    telefono: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
}

