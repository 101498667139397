import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { updateItemTotalAction } from "../../../../actions/documentCartAction";

import SwitchSmallForm from "../../../../components/switch/switchSmall.component";
import TooltipMessage from "../../../../components/tooltip/tootltip-message.component";

export const ApplyGiftSection = ({ itemDoc, handleUpdateForm, isGift }) => {
  const dispatch = useDispatch();

  const { valornetoarticulo, aplicaregalo } = itemDoc;

  const {
    bottonEsRegalo,
    hrefEsRegalo,
    messageEsRegalo,
    titleEsRegalo,
  } = useSelector((state) => state.menuReducer.menuInvoice);

  /**
   * * Maneja el cambio de aplicar regalo.
   *
   * @param {Object} event - El evento del cambio.
   */
  const handleChangeApplyGift = ({ target }) => {
    const apply = target.checked;

    handleUpdateForm({
      isGift: apply,
      unitValue: apply ? 0 : valornetoarticulo,
    });

    let newdata = {
      ...itemDoc,
      esregalo: apply,
      valorneto: apply ? 0 : valornetoarticulo,
    };
    dispatch(updateItemTotalAction(newdata));
  };

  return (
    aplicaregalo && (
      <Grid container>
        <Grid item lg={12} xs={12}>
          <SwitchSmallForm
            checked={isGift}
            name={"isGift"}
            onChange={handleChangeApplyGift}
            titleOn="¿Este artículo es un regalo?"
            txtTooltip={
              <TooltipMessage
                botton={bottonEsRegalo}
                href={hrefEsRegalo}
                message={messageEsRegalo}
                title={titleEsRegalo}
              />
            }
          />
        </Grid>
      </Grid>
    )
  );
};
