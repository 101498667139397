import { ColumnsFile } from "../../../customers/bulkLoad/validations/Rules";
import calculateDV from '../../../../utils/calculateDV';
import { enterpriseIdHeader } from '../../../../actions/authActions';

/*
METODOS INTERNOS
*/
export const buildJsonToSave = (item) => {

    var addressList = getAddressList(item);
    var contactList = getContactList(item);

    const clienteTmp = item.cliente;
    const edit = clienteTmp?.id ? true : false;    
    const documento = item[ColumnsFile.numeroDocumentoColumn.name];
    
    let client = {
        edit: edit,
        Id: edit ? clienteTmp.id : 0,
        Documento: documento,
        Documentodv: edit ? clienteTmp.digito : calculateDV(documento),//obtener numero cuando es edit
        RazonSocial: item[ColumnsFile.nombreClienteColumn.name],
        Telefono: item[ColumnsFile.telefonoColumn.name],
        CorreoElectronico: item[ColumnsFile.correoElectronicoColumn.name],
        DianTipoDocumentoIdentidadId: parseInt(item[ColumnsFile.tipoDocumentoColumn.name].id),
        DianTipoPersonaId: item[ColumnsFile.tipoPersonaColumn.name].id,
        Diantiporegimenid: item[ColumnsFile.regimenColumn.name].id,
        Favorito: false,
        Direccionid: edit === true ? clienteTmp.clienteDireccion.id : 0,
        Direccion: item[ColumnsFile.direccionColumn.name],
        CiudadId: parseInt(item[ColumnsFile.municipioColumn.name].id),
        Adclientedireccion: addressList,
        Adcontactos: contactList,
        Empresaid: enterpriseIdHeader()
    }
    return client;
}

const getAddressList = (item) => {
    var addressList = [];
    if (item[ColumnsFile.iANombreDireccion1Column.name]) {
        addressList.push(getAditionalInfo(item[ColumnsFile.iANombreDireccion1Column.name], item[ColumnsFile.iADireccion1Column.name], item[ColumnsFile.iAMunicipio1Column.name]))
    }
    if (item[ColumnsFile.iANombreDireccion2Column.name]) {
        addressList.push(getAditionalInfo(item[ColumnsFile.iANombreDireccion2Column.name], item[ColumnsFile.iADireccion2Column.name], item[ColumnsFile.iAMunicipio2Column.name]))
    }
    return addressList
}

const getContactList = (item) => {
    var contactList = [];
    if (item[ColumnsFile.cANombreContacto1Column.name]) {
        contactList.push(getAditionalContact(item[ColumnsFile.cANombreContacto1Column.name], item[ColumnsFile.cACelularContacto1Column.name], item[ColumnsFile.cAEmailContacto1Column.name]))
    }
    if (item[ColumnsFile.cANombreContacto2Column.name]) {
        contactList.push(getAditionalContact(item[ColumnsFile.cANombreContacto2Column.name], item[ColumnsFile.cACelularContacto2Column.name], item[ColumnsFile.cAEmailContacto2Column.name]))
    }
    return contactList
}

const getAditionalInfo = (nombre, direccion, ciudadId) => {
    const exist = nombre.id && nombre.id !== 0;
    return {
        Id: exist ? nombre.id : 0,
        Descripcion: exist ? nombre.valor : nombre,
        Direccion: direccion,
        CiudadId: ciudadId.id,
    }
}

const getAditionalContact = (nombre, telefono, email) => {
    const exist = nombre.id && nombre.id !== 0;
    return {
        Id: exist ? nombre.id : 0,
        Nombre: exist ? nombre.valor : nombre,
        Telefono: telefono,
        Celular: telefono,
        Email: email,
        Activo: true
    }
}
