import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import StarIcon from "@material-ui/icons/Star";
// import StarBorderIcon from "@material-ui/icons/StarBorder";
import { formatDate } from "../../utils/general";
import { FormatDecimal } from "../../components/common/formatDecimal";

import MessageSecundary from "../../components/tooltip/messageSecundary.component";
import cloudIcon from "./../../images/icons/cloud.png";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { getDataEmailReportAction } from "../../actions/invoiceActions";
import { getDescriptionRole } from "../../helpers/roleUser";
import TooltipMessageSecundary from "../../components/tooltip/tooltipSecundary.component";
import CustomProgress from "../../components/Progress/progress.component";
import ReportIcon from "@material-ui/icons/Report";
import {
  sendMailReportDocSupportAction,
  updateDocumentSupportAction,
} from "../../actions/documentSupportAction";
import { getStyleStatusItem } from "./getStyleStatusItem";

const useStyles = makeStyles({
  alignRight: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  iconButton: {
    padding: 0,
    color: "white",
  },
  selectedStyle: {
    backgroundColor: "#16b1f3",
    color: "#FFF",
  },
  tooltip: {
    backgroundColor: "transparent",
    "& .MuiTooltip-tooltip": {
      backgroundColor: "blue",
    },
  },
  containerEllipsis: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const SupportItemCard = ({ dat, selected, onClick }) => {
  const [reasonEmailList, setReasonList] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDetailDocumentSupport = () => {
    onClick();
  };

  /**
   * Inicia componente
   */
  useEffect(() => {
    dispatch(getDataEmailReportAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { dataEmailReport } = useSelector((state) => state.invoiceReducer);
  const { reasonList } = useSelector((state) => state.configReducer);
  const { menuInvoice } = useSelector((state) => state.menuReducer);
  const {
    loadingReportDocument,
    documentSupportFilter,
    idFavorite,
  } = useSelector((state) => state.documentSupportReducer);
  const document = documentSupportFilter.find((item) => item.id === dat.id);

  /**
   * 
   * @param {
   } e  event
   * @param {*} item documento soporte seleccionado
   */
  const reportProblemComponent = (e, item) => {
    e.preventDefault();

    let data = {
      mailSupport: reasonEmailList?.find((c) => c.codigo?.trim() === "12")
        ?.correo,
      mailUser: dataEmailReport.emailUser,
      issue: "Documento soporte rechazado ante DIAN",
      roleUser: getDescriptionRole(dataEmailReport.roleUser),
      nameCompany: dataEmailReport.businessName,
      nitCompany: dataEmailReport.nitCompany,
      userName: dataEmailReport.userName,
      bodyMail: "Documento soporte rechazado ante DIAN",
      documentId: item.id,
      consecutivo: item.consecutivo,
      prefijo: item.prefijo,
    };

    dispatch(sendMailReportDocSupportAction(data));
  };

  /**
   *
   * @param {*} item
   * @returns
   */
  const getStatusDIAN = (item) => {
    if (item === null) return "";

    switch (item.tipoestadodocumentoid) {
      case 2:
        return messageStatus(
          "Pendiente de sincronización con DIAN",
          "Este Ítem esta pendiente de sincronización con DIAN.",
          cloudIcon,
          "cloud"
        );
      case 3:
        return (
          <Tooltip
            title={
              <TooltipMessageSecundary
                title={`Error DIAN - ${item.descripcion}`}
                message={
                  !item.reporterechazo
                    ? menuInvoice?.messageDocumentNotReported ?? ""
                    : menuInvoice?.messageReportedDocument ?? ""
                }
                buttonText={"Reportar a soporte"}
                onClick={(e) => reportProblemComponent(e, item)}
                disabledButton={item.reporterechazo ? true : false}
              />
            }
            placement="bottom-end"
            interactive
            enterTouchDelay={1000}
            className={classes.tooltip}
            disableTouchListener={
              loadingReportDocument.find((c) => c === item.id) !== undefined
                ? true
                : false
            }
            disableHoverListener={
              loadingReportDocument.find((c) => c === item.id) !== undefined
                ? true
                : false
            }
          >
            {loadingReportDocument.find((c) => c === item.id) !== undefined ? (
              <CustomProgress size={20} aling={"end"} />
            ) : (
              <IconButton className={classes.iconButton}>
                <ReportIcon />
              </IconButton>
            )}
          </Tooltip>
        );
      default:
        return null;
    }
  };

  /**
   * Función que genera el mensaje de estado de sincronización con la DIAN y el icono correspondiente
   * @param {*} tittleText Titulo del mensaje
   * @param {*} msgText  Cuerpo del mensaje
   * @param {*} iconImg  Icono del estatus de transmisión  del documento soporte
   * @param {*} altImage Nombre del icono
   * @returns
   */
  const messageStatus = (tittleText, msgText, iconImg, altImage) => {
    return (
      <Tooltip
        title={<MessageSecundary title={tittleText} message={msgText} />}
        placement="bottom-end"
        interactive
        enterTouchDelay={1000}
      >
        <IconButton className={classes.iconButton}>
          <img src={iconImg} alt={altImage} />
        </IconButton>
      </Tooltip>
    );
  };

  const handleFavourite = () => {
    let data = {
      Id: document.id,
      Operacion: "FAVORITO",
      Favorito: !document.favorito,
    };

    dispatch(updateDocumentSupportAction(data, onClick));
  };

  /**
   * Carga listado asuntos reporte email
   */
  useEffect(() => {
    if (reasonList?.length > 0) setReasonList(reasonList);
  }, [reasonList]);

  return (
    <div
      onClick={handleDetailDocumentSupport}
      className={`${
        selected ? "cardDetailSupportSelected" : "cardDetailSupport"
      } ${classes.containerEllipsis}`}
      title={dat.razonsocial}
    >
      <Grid container>
        <Grid
          item
          xs={9}
          className="nameCostumer"
          style={{
            textOverflow: "ellipsis",
          }}
        >
          {dat.razonsocial}
        </Grid>
        <Grid item xs={3} className={classes.alignRight}>
          {getStatusDIAN(dat)}
          {idFavorite === dat.id ? (
            <CustomProgress size={20} aling={"end"} />
          ) : (
            <IconButton
              className={classes.iconButton}
              onClick={() => handleFavourite()}
            >
              {dat.favorito ? <StarIcon /> : <StarBorderIcon />}
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className="dataSupportGrid">
          {dat?.fecha !== null && dat?.fecha !== undefined
            ? formatDate(new Date(dat.fecha), "dd/MM/yyyy hh:mm aaaa")
            : "-"}
        </Grid>
        <Grid item xs={6} className={getStyleStatusItem(dat, selected)}>
          <FormatDecimal value={dat.valortotal} prefix={"$"} size="small" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className="dataNumberSupport">
          {`Factura: ${dat.prefijo} - ${dat.consecutivo}`}
        </Grid>
        <Grid item xs={6} className={getStyleStatusItem(dat, selected)}>
          {dat.anulacion === true ? "Anulada" : dat.estado}
        </Grid>
      </Grid>
    </div>
  );
};

export default SupportItemCard;
