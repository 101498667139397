import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { getEmptyCartMessage } from "./utils";

import addDocService from "../../../images/addDocService.png";

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: "50px 0",
    width: "100%",
  },
  text: {
    color: "#7DD1F8",
    fontfamily: "Muli",
    fontSize: "18px",
    fontStyle: "italic",
    fontWeight: "500",
    lineHeight: "23px",
    textAlign: "center",
    width: "70%",
  },

  addImage: {
    aspectRatio: "1/1",
    marginTop: "20px",
    width: "30%",
  },
});

export const CartDocEmpty = ({ typedocument }) => {
  const classes = useStyles();

  const { description } = getEmptyCartMessage(typedocument);

  return (
    <div className={classes.root}>
      <p className={classes.text}>{description}</p>
      <img
        className={classes.addImage}
        src={addDocService}
        alt="addDocService"
      />
    </div>
  );
};
