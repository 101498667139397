import { axiosApiInstance } from '../config/axios-instance'
import { API_ENDPOINT_ARTICLE, defaultHeaders } from "../config/config";
import { enterpriseIdHeader } from './authActions';
import { types } from '../types/types';


const urlEndPoint = `${API_ENDPOINT_ARTICLE}/item/api/descuento`;
const urlEndPointReason = `${API_ENDPOINT_ARTICLE}/item/api/motivodescuento`;
let allDiscounts = [];


/**
 * @action
 * @description Consulta listado descuentos por empresa
 * @param {*} data 
 * @param {*} filter 
 * @param {*} refresh 
 */
export const getDiscounts = (filter = '', refresh = true) => async (dispatch) => {

  try {
    changeGetListLoading(dispatch, true);

    if (allDiscounts.length > 0 && !refresh) {
      dispatch({
        type: types.GET_DISCOUNTLIST,
        payload: filerList(allDiscounts, filter),
      });

    } else {

      const url = `${urlEndPoint}/getallbyenterpriseid`;
      let config = defaultHeaders();
      Object.assign(config.headers, {
        'enterpriseId': `${enterpriseIdHeader()}`,
      });


      var response = await axiosApiInstance.get(url, config);
      allDiscounts = response.data.result.map(item => ({
        ...item,
        text: item.descripcion
      }));

      dispatch({
        type: types.GET_DISCOUNTLIST,
        payload: (filter !== '') ? filerList(allDiscounts, filter) : allDiscounts
      });
    }

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar listado de descuentos.',
        error: err
      }
    })

  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * @function
 * @description Actualiza estado de loading gSAVE
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_DISCOUNTS,
    payload: status
  })
}


export const getDiscount = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/getdatabyid`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });

    var response = await axiosApiInstance.get(url, config);

    dispatch({
      type: types.GET_DISCOUNT,
      payload: response.data,
    });

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar información descuento.',
        error: err
      }
    })
  }
};

export const getFilterDiscount = (data) => async (dispatch) => {
  dispatch({
    type: types.GET_FILTER_DISCOUNT,
    payload: data,
  });
};


export const changeEditingDiscountStatus = (data) => async (dispatch) => {
  dispatch({
    type: types.CHANGE_EDITING_DISCOUNT_STATUS,
    payload: data,
  });
};

/**
 * @action
 * @description Registra descuento
 * @param {*} data 
 */
export const postDiscount = (data) => async (dispatch) => {

  try {
    changeSaveLoading(dispatch, true);

    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    }

    const url = `${urlEndPoint}/creatediscount`;


    const response = await axiosApiInstance.post(url, data, defaultHeaders());

    dispatch({
      type: types.SAVE_DISCOUNT,
      payload: response.data,
    });


  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido registrar descuento.',
        error: err
      }
    })

  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * @action
 * @description Actualiza descuento
 * @param {*} data 
 */
export const putDiscount = (data) => async (dispatch) => {


  try {

    changeSaveLoading(dispatch, true);

    const url = `${urlEndPoint}/updatediscount`;

    const response = await axiosApiInstance.put(url, data, defaultHeaders());

    dispatch({
      type: types.UPDATE_DISCOUNT,
      payload: response.data,
    });


  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error actualizando descuento.',
        error: err
      }
    })

  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * @function
 * @description Actualiza estado proceso guardado
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_DISCOUNT,
    payload: status
  })
}


export const deleteDiscount = (data) => async (dispatch) => {

  try {

    const url = `${urlEndPoint}/deletediscount`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      'id': data
    });

    var response = await axiosApiInstance.delete(url, config);
    dispatch({
      type: types.DELETE_DISCOUNT,
      payload: response.data,
    });

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido eliminando información descuento.',
        error: err
      }
    })

  }
};

export const getReasonDiscount = () => async (dispatch) => {

  try {

    const url = `${urlEndPointReason}/getallreason`;

    var response = await axiosApiInstance.get(url, defaultHeaders());

    dispatch({
      type: types.GET_REASON_DISCOUNT,
      payload: response.data
    });


  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar listado razones de descuento.',
        error: err
      }
    })

  }
};

export const clearCurrentDiscount = () => async dispatch => {
  dispatch({
    type: types.GET_FILTER_DISCOUNT,
    payload: null
  })
}

export const clearUpdateDiscount = () => async dispatch => {
  dispatch({
    type: types.UPDATE_DISCOUNT,
    payload: null
  })
}

export const clearCreateDiscount = () => async dispatch => {
  dispatch({
    type: types.SAVE_DISCOUNT,
    payload: null
  })
}

export const clearDeleteDiscount = () => async dispatch => {
  dispatch({
    type: types.DELETE_DISCOUNT,
    payload: null
  })
}

//Filtra listado descuentos
function filerList(list, filter) {
  try {
    if (!list) return list;

    const data = list.filter(x => {
      const modelo = (x.modelodescuento === 1 ? 'descuento comercial' : 'descuento financiero');
      const valor = x.valor;
      const motivo = x.motivo.descripcion.toLowerCase();
      const descripcion = x.descripcion.toLowerCase();
      const query = `${modelo} ${valor} ${motivo} ${descripcion}`;
      const modValue = filter.toLowerCase();
      return query.indexOf(modValue) > -1;
    });

    return data;
  } catch (err) {
    console.log("Error Filtrando listado cargos:", err);
  }
}
