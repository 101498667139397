import React from "react";
import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

export const ExcelClient = ({ element, filename, data }) => {
  return (
    <>
      <ExcelFile element={element} filename={filename}>
        <ExcelSheet data={data} name="Informe">
          <ExcelColumn label="Código Tipo Persona" value="tipopersona" />
          <ExcelColumn label="Tipo Persona" value="nombretipopersona" />
          <ExcelColumn
            label="Código Tipo Identificación"
            value="tipoidentificacion"
          />
          <ExcelColumn
            label="Tipo Identificación"
            value="nombretipoidentificacion"
          />
          <ExcelColumn
            label="Numero Identificación"
            value="numeroidentificacion"
          />
          <ExcelColumn label="DV" value="digitoverificacion" />
          <ExcelColumn label="Código de la sucursal" value="codigosucursal" />
          <ExcelColumn label="Nombres" value="nombre" />
          <ExcelColumn label="Apellidos" value="apellido" />
          <ExcelColumn label="Nombre Comercial" value="razonsocial" />
          <ExcelColumn label="Correo electrónico" value="correoelectronico" />
          <ExcelColumn label="Código Departamento" value="departamento" />
          <ExcelColumn label="Código Ciudad" value="ciudad" />
          <ExcelColumn label="Ciudad" value="nombreciudad" />
          <ExcelColumn label="Dirección" value="direccion" />
          <ExcelColumn label="Indicativo" value="" />
          <ExcelColumn label="Numero de télefono" value="telefono" />
          <ExcelColumn label="Extensión" value="" />
          <ExcelColumn label="Nombre de contacto" value="nombrecontacto" />
          <ExcelColumn label="Apellido de contacto" value="apellidocontacto" />
          <ExcelColumn
            label="Correo electrónico de contacto"
            value="correoelectronicocontacto"
          />
          <ExcelColumn label="Código Tipo de Régimen IVA" value="tiporegimen" />
          <ExcelColumn label="Tipo de Régimen IVA" value="nombretiporegimen" />
          <ExcelColumn label="Indicativo de contacto" value="" />
          <ExcelColumn
            label="Numero de teléfono de contacto"
            value="telefonocontacto"
          />
          <ExcelColumn label="Código Postal" value="" />
          <ExcelColumn
            label="Código Responsabilidad Fiscal"
            value="responsabilidadfiscal"
          />
          <ExcelColumn
            label="Responsabilidad Fiscal"
            value="nombreresponsabilidadfiscal"
          />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
