import { regexDecimal } from "../helpers/customRegex.hepers";

export const convertStringToDecimal = (value) => {

    if (value === undefined || value === null)
        return 0;

    if (typeof value === "number")
        return value;

    if (value.trim().length === 0)
        return 0;

    value = value.replace(regexDecimal, '');
    let newValue = parseFloat(value);

    return isNaN(newValue) ? 0 : newValue;
}