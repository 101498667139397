import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import CustomTooltip from '../../../components/tooltip/tooltip.component'
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import { FormatDecimal } from '../../../components/common/formatDecimal';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.thirdBackgroundColor,
        color: theme.palette.primaryColor,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 55
        }
    },
    header: {
        paddingBottom: 0,
        paddingTop: 0
    },
    content: {
        fontSize: 14
    },
    title: {
        color: theme.palette.primaryColor,
    },
    icon: {
        color: theme.palette.secundaryColor
    },
    iconAction: {
        color: theme.palette.primaryColor
    },
    valueText: {
        color: '#F5D13D',
        fontWeight: 500
    },
    help: {
        marginTop: 10
    },
    actions: {
        display: 'flex'
    },
    containerDesRteIca: {
        whiteSpace: 'nowrap',
        width: '90%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export const ReteIcaCard = React.memo(({
    retica,
    valorretica,
    onEdit,
    onDelete
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={
                    <MonetizationOnIcon
                        fontSize="small"
                        className={classes.icon}
                    />
                }
                action={
                    <div className={classes.actions}>
                        <div className={classes.help} >
                            <CustomTooltip
                                message={
                                    <TooltipMessage
                                        title={'Rete-ICA'}
                                        message={'Rete-ICA'}
                                        botton={'Más información'}
                                        href={null}
                                    />
                                }
                            />
                        </div>
                        <Tooltip title="Editar">
                            <IconButton aria-label="Editar" onClick={onEdit}>
                                <CreateIcon className={classes.iconAction} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Eliminar">
                            <IconButton aria-label="Eliminar" onClick={onDelete} >
                                <DeleteIcon className={classes.iconAction} />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
                classes={{
                    title: classes.title,
                }}
                title={'Rete - ICA'}
            />
            <CardContent className={classes.content}>
                <span>
                    <span>
                        <FormatDecimal
                            value={retica?.tarifa ?? 0}
                            suffix={" (X1000)"}
                            size={"inherit"}
                        />
                    </span>
                    &nbsp;
                    <span className={classes.valueText}>
                        <FormatDecimal
                            value={valorretica ?? 0}
                            prefix={"($"}
                            suffix={")"}
                        />

                    </span>
                </span>
                <br />
                <div className={classes.containerDesRteIca}>{retica?.descripcion ?? ''}</div>
            </CardContent>
        </Card>
    );
})


