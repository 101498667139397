import { types } from "../types/types";

const initialState = {
    customFieldsList: [],
    loadingSaveField: false,
    loadingGetFields: false,
    loadingDeleteField: 0

}


export const customFieldReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_CUSTOM_FIELD:
            return {
                ...state,
                customFieldsList: [...state.customFieldsList, action.payload]
            }
        case types.DELETE_CUSTOM_FIELD:
            return {
                ...state,
                customFieldsList: state.customFieldsList.filter(c => c.id !== action.payload)
            }
        case types.LOADING_SAVE_FIELD:
            return {
                ...state,
                loadingSaveField: action.payload
            }
        case types.LOADING_GET_FIELDS:
            return {
                ...state,
                loadingGetFields: action.payload
            }
        case types.REFRESH_FIELD_LIST:
            return {
                ...state,
                customFieldsList: action.payload
            }

        case types.LOADING_DELETE_FIELDS:
            return {
                ...state,
                loadingDeleteField: action.payload
            }

        default:
            return state;
    }

}