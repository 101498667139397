export const removeNullValuesFromList = (list) => {
    return list.filter(item => item.valor != null && item.valor !== '');
}

export const getFirstPositionSplit = (value, split) => {
    if (value != null && value !== '') {
        return value.trim().split(split)[0].trim();
    }
    return null;
}

/**
 * Agrupa errores por factura
 * 
 * @param {*} value 
 */
export const removeSpaces = (value) => {
    if (value != null && value !== '') {
        return value.replace(' ', '');
    }
    return null;
}

export const getErrorsbyInvoice = (items) => {
    let errorsByInvoice = []
    items.forEach(item => {
        if (item.errors) {
            item.errors.forEach(errores => errorsByInvoice.push(errores))
        }
    });
    return errorsByInvoice;
}


/**
 * Agrupa errores por numero de registro
 * @param {lista de errores} results 
 */
export const groupByLine = (results) => {
    return results.reduce((r, a) => {
        r[a.line] = [...r[a.line] || [], a];
        return r;
    }, {});
}

/**
 * Agrupa errores por numero de registro
 * @param {lista de errores} results
 */
/*
export const groupByInvoice = (results) => {
    return results.reduce((r, a) => {
        r[a[ColumnsFile.agrupacionColumn.name]] = [...r[a[ColumnsFile.agrupacionColumn.name]] || [], a];
        return r;
    }, {});
}*/