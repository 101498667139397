import React from "react"

import { Button, Card, CardContent, CardActions, CardHeader} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFF',
        color: 'black',
        textAlign: 'center',
    },
    action: {
        flexDirection: "column",
        justifyContent: "center"
    },
    title: {
        fontWeight: 800,
        fontSize: 14
    },
    message: {
        fontSize: 12,
        whiteSpace: 'pre-wrap'
    },
    content:{
        padding: '5px 16px'
    },
    header:{
        padding: '3px 16px 0px 16px'
    }
}));

const CustomButton = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 600,
        padding: '2px 20px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#C7C7C7',
        borderColor: '#C7C7C7',
        borderRadius: '35px',
        fontSize: 12,
        color: 'black',
        cursor: 'point',
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#C7C7C7',
        },
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#C7C7C7',

        },
        '&:focus': {
            boxShadow: 'none',
            backgroundColor: '#C7C7C7',

        },
    },
}))(Button);


const TooltipMessageSecundary = ({ ...props }) => {
    const { message, title, buttonText, onClick, disabledButton } = props;
    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.header}
                title={title}
                classes={{
                    title: classes.title,
                }} />
            <CardContent className={classes.content}>
                <div className={classes.message}>
                    {message}
                </div>
            </CardContent>
            {disabledButton !== true ?
                <CardActions className={classes.action}>
                    <CustomButton
                        onClick={onClick}
                        disabled={disabledButton}>
                        {buttonText}
                    </CustomButton>
                </CardActions> : null
            }
  
        </Card>
    );
}

export default TooltipMessageSecundary
