import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";

import { withStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import SwitchForm from "../../components/switch/switch.component.js";
import SelectForm from "../../components/select/select.component";
import InputForm from "../../components/input/input.component";
import TooltipMessage from "../../components/tooltip/tootltip-message.component";
import ResponseModal from "../../components/modal/responseModal.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import ToggleButtons from "../../components/toggle/toggle.component";
import CustomProgress from "../../components/Progress/progress.component";
import { MultipleSelect } from "../../components/multiselect/multipleSelect.js";

import {
  updateBasicDataAction,
  getCompanyAction,
} from "../../actions/basicDataActions";

import {
  getEquivalentDocumentTypeAction,
  getTypesHealthServiceAction,
} from "../../actions/configAction";

import {
  regexEmail,
  regexText,
  regexPhone,
  regexOnlyNumbers,
  regexLettersAndNumbers,
} from "../../helpers/customRegex.hepers";
import { SuccessAlert } from "../../helpers/alert.helpers";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { getComponentName, getComponentUrl } from "../../utils/general.js";
import isEmpty from "../../utils/isEmpty";
import { TIME_CREATE_REGISTER } from "../../config/config";
import { enterpriseIdHeader } from "../../actions/authActions.js";
import MultiSelectForm from "../../components/multiselect/multiselect.component.js";
import EquivalentDocumentView from "./equivalentDocComponent/EquivalentDocumentView.jsx";

const styles = (theme) => ({
  buttonTestId: {
    background: "#7DD1F8",
    color: "#FFFF",
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
    width: "100%",
    marginTop: 15,
  },
  italicTxt: {
    marginTop: 15,
    marginBottom: 15,
    textAling: "center",
  },
});

const animated = "animate__animated animate__fadeIn animate__faster";
export class BasicData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      form: {
        diantipodocumentoidentidadid: "",
        documento: "",
        documentodv: "",
        diantipopersonaid: "1",
        diantiporegimenid: "",
        responTributariaId: "",
        fiscalResponsibility: [],
        razonsocial: "",
        correoelectronico: "",
        telefono: "",
        observacion: "",
        aplicafe: false,
        testid: "",
        correoelectronicofacturas: "",
        prestaserviciosalud: false,
        codigo: "",
        tipoprestadorsalud: [],
        aplicade: false,
        tipoDocumentoEquivalente: "",
        tiposDocumentoEquivalente: [],
        identificadorSoftwareDE: "",
        pinSoftwareDE: "",
        testIdDE: "",
      },
      responseModal: {
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
      },
      saveBasicDataResponse: {},
      requestCompany: {},
      options: [],
      optionsRegimen: [],
      optionsResTributaria: [],
      optionsFiscalResponsibility: [],
      loadingSaveBasicData: false,
      loadingGetBasicData: false,
      testidexitoso: false,
      menuMyAccount: {},
      typesHealthServices: [],
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getTypesHealthServiceAction();
    this.props.getEquivalentDocumentTypeAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.configReducer.components &&
      nextProps.configReducer.components !== state.components
    )
      update.components = nextProps.configReducer.components;

    if (
      !!nextProps.configReducer.getDocumentTypesResponse &&
      nextProps.configReducer.getDocumentTypesResponse !== state.options
    )
      update.options = nextProps.configReducer.getDocumentTypesResponse;

    if (
      !!nextProps.configReducer.getRegimeTypesResponse &&
      nextProps.configReducer.getRegimeTypesResponse !== state.optionsRegimen
    )
      update.optionsRegimen = nextProps.configReducer.getRegimeTypesResponse;

    if (
      !!nextProps.configReducer.getTaxResponsibility &&
      nextProps.configReducer.getTaxResponsibility !==
        state.optionsResTributaria
    )
      update.optionsResTributaria =
        nextProps.configReducer.getTaxResponsibility;

    if (
      !!nextProps.configReducer.getFiscalResponsibility &&
      nextProps.configReducer.getFiscalResponsibility !==
        state.optionsFiscalResponsibility
    )
      update.optionsFiscalResponsibility =
        nextProps.configReducer.getFiscalResponsibility;

    if (
      !!nextProps.basicDataReducer.requestSaveBasicData &&
      nextProps.basicDataReducer.requestSaveBasicData !==
        state.saveBasicDataResponse
    )
      update.saveBasicDataResponse =
        nextProps.basicDataReducer.requestSaveBasicData;

    if (
      !!nextProps.basicDataReducer.requestCompany &&
      nextProps.basicDataReducer.requestCompany !== state.requestCompany
    ) {
      if (Object.keys(nextProps.basicDataReducer.requestCompany).length) {
        let data = nextProps.basicDataReducer.requestCompany;

        update.requestCompany = data;

        update.form = {
          diantipodocumentoidentidadid: data.diantipodocumentoidentidadid.toString(),
          documento: data.documento,
          documentodv: data.documentodv,
          correoelectronico: data.correoelectronico,
          telefono: data.telefono,
          diantiporegimenid: data.diantiporegimenid.toString(),
          diantipopersonaid: data.diantipopersonaid === 1 ? "1" : "2",
          aplicafe: data.aplicafe,
          observacion: data.observacion,
          testid: data.testid,
          razonsocial: data.razonsocial,
          prestaserviciosalud: data.esprestadorserviciosalud ?? false,
          codigo: data.codigoprestadorserviciosalud ?? "",
          tipoprestadorsalud:
            data?.adempresatiposervicosalud?.map(
              (item) => item.tiposervicioid
            ) ?? [],
          responTributariaId: data.responsabilidadtributariaid ?? "",
          fiscalResponsibility:
            data.responsabilidadfiscal?.split("").map(Number) ?? [],
        };
      }
    }

    if (
      nextProps.basicDataReducer.requestCompany?.testidexitoso !==
      state.testidexitoso
    ) {
      update.testidexitoso =
        nextProps.basicDataReducer.requestCompany?.testidexitoso;
    }

    if (
      !isEmpty(nextProps.basicDataReducer.loadingSaveBasicData) &&
      nextProps.basicDataReducer.loadingSaveBasicData !==
        state.loadingSaveBasicData
    )
      update.loadingSaveBasicData =
        nextProps.basicDataReducer.loadingSaveBasicData;

    if (
      !isEmpty(nextProps.basicDataReducer.loadingGetBasicData) &&
      nextProps.basicDataReducer.loadingGetBasicData !==
        state.loadingGetBasicData
    )
      update.loadingGetBasicData =
        nextProps.basicDataReducer.loadingGetBasicData;

    if (
      nextProps?.basicDataReducer?.requestCompany?.testidexitoso !==
      state.requestCompany.testidexitoso
    ) {
      update.testidexitoso =
        nextProps?.basicDataReducer?.requestCompany?.testidexitoso;
    }

    if (
      !!nextProps.menuReducer.menuMyAccount &&
      nextProps.menuReducer.menuMyAccount !== state.menuMyAccount
    ) {
      update.menuMyAccount = nextProps.menuReducer.menuMyAccount;
    }

    if (
      nextProps.configReducer.typesHealthServices !== state.typesHealthServices
    )
      update.typesHealthServices = nextProps.configReducer.typesHealthServices;

    if (
      nextProps.configReducer.typesEquivalentDocument !==
      state.tiposDocumentoEquivalente
    )
      update.tiposDocumentoEquivalente =
        nextProps.configReducer.typesEquivalentDocument;
    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.saveBasicDataResponse !== this.state.saveBasicDataResponse) {
      if (this.state.saveBasicDataResponse.statusCode === "201") {
        SuccessAlert(
          null,
          getComponentName(
            this.state.components,
            14,
            21,
            "Actualizaste tus datos básicos de manera exitosa, sigamos facturando."
          ),
          TIME_CREATE_REGISTER
        );
        this.props.getCompanyAction();
      } else if (this.state.saveBasicDataResponse.statusCode === "252") {
        this.setState({
          responseModal: {
            type: "warning",
            title: getComponentName(
              this.state.components,
              88,
              141,
              "Upss...!!!"
            ),
            subtitle: getComponentName(
              this.state.components,
              88,
              142,
              "Test set id incorrecto"
            ),
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: getComponentName(
                    this.state.components,
                    88,
                    143,
                    'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y rectifica el identificador del set de pruebas.'
                  ),
                }}
              ></div>
            ),
            modalImage: "warning",
            open: true,
            closeElement: getComponentName(
              this.state.components,
              88,
              145,
              "Skip"
            ),
            onCloseElement: 0,
          },
        });
      } else if (this.state.saveBasicDataResponse.statusCode === "253") {
        this.setState({
          responseModal: {
            type: "warning",
            title: getComponentName(
              this.state.components,
              89,
              146,
              "Upss...!!!"
            ),
            subtitle: getComponentName(
              this.state.components,
              89,
              147,
              "Aun no tienes registrado a Colfactura como tu software de facturación electrónica en Dian"
            ),
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: getComponentName(
                    this.state.components,
                    89,
                    148,
                    'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y realiza el proceso de registro como facturador electrónico seleccionando a Gestión de Seguridad Electrónica S.A (Colfactura) como tu proveedor tecnológico. ¿Tienes dudas de cómo realizar el proceso? <a style="color:#16B1F3;" href="https://www.youtube.com/watch?v=Om59dTlnD6c" target="_blank">Apréndelo aquí</a>.'
                  ),
                }}
              ></div>
            ),
            modalImage: "warning",
            open: true,
            closeElement: getComponentName(
              this.state.components,
              89,
              149,
              "Skip"
            ),
            onCloseElement: 0,
          },
        });
      } else if (this.state.saveBasicDataResponse.statusCode === "250") {
        this.setState({
          responseModal: {
            type: "success",
            title: getComponentName(this.state.components, 14, 20, "Genial!!!"),
            subtitle: getComponentName(
              this.state.components,
              14,
              21,
              "Configuraste tus datos básicos correctamente."
            ),
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: getComponentName(
                    this.state.components,
                    14,
                    22,
                    'Solo te faltan 4 items para terminar de configurar "MI CUENTA"'
                  ),
                }}
              ></div>
            ),
            modalImage: "successBasicData",
            open: true,
            closeElement: getComponentName(
              this.state.components,
              14,
              23,
              "Skip"
            ),
            onCloseElement: 0,
          },
        });
      }
    }

    if (
      prevState.form.diantipopersonaid === "1" &&
      this.state.form.diantipopersonaid === "2"
    ) {
      this.setState({
        form: {
          ...this.state.form,
          razonsocial: this.state.form.razonsocial.replace(regexText, ""),
        },
      });
    }
  }

  /**
   * Sincroniza nuevo valor de input con state
   * @param {*} e Evento
   */
  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  };

  /**
   * Sincroniza nuevo valor de checkbox con state
   * @param {*} e
   */
  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked,
      },
    }));
  };

  /**
   * Verifica si caracteres invalidos y da formato al campo TestID
   * @param {object} e Evento
   */
  syncChangesTestId(e) {
    e.preventDefault();
    const name = e.target.name;
    let value = e.target.value;

    let guid = "";
    let str = value;
    const regex = /[^a-f0-9\s]+/g;
    str = str.replace(regex, "");
    str = str.trim();

    let one = str.slice(0, 8);
    let two = str.slice(8, 12);
    let three = str.slice(12, 16);
    let four = str.slice(16, 20);
    let five = str.slice(20, 32);

    two = two.length > 0 ? "-" + two : "";
    three = three.length > 0 ? "-" + three : "";
    four = four.length > 0 ? "-" + four : "";
    five = five.length > 0 ? "-" + five : "";
    guid = one + two + three + four + five;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: guid,
      },
    }));
  }

  /**
   * Sincroniza nuevo valor toogle con state
   * @param {*} event Evento
   * @param {*} newAlignment Nuevo valor
   */
  onChangeTooggle = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        diantipopersonaid: !!newAlignment ? newAlignment : "1",
      },
    }));
  };

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  handleChangeTypeHealthService = (e) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        tipoprestadorsalud: e.target.value,
      },
    }));
  };

  /**
   * Limpia caracteres invalidos para correo electronico
   * @param {*} e Evento
   */
  validateEmail(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexEmail, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para telefono
   * @param {*} e Evento
   */
  validatePhone(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexPhone, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para valores solo texto
   * @param {*} e Evento
   */
  validateText(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexText, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para valores numericos
   * @param {*} e Evento
   */
  validateOnlyNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexOnlyNumbers, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos, solo letras y numeros
   * @param {*} e Evento
   */
  validateLettersAndNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexLettersAndNumbers, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];
    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        fiscalResponsibility: value,
      },
    }));
  };

  handleResponseModal = () => {
    this.setState({
      responseModal: {
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: "",
      },
    });
  };

  /**
   * Captura y actualiza información de empresa.
   */
  saveBasicData = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      let data = {
        ...this.state.requestCompany,
        diantipodocumentoidentidadid: this.state.form
          .diantipodocumentoidentidadid,
        documento: this.state.form.documento,
        documentodv: this.state.form.documentodv,
        diantipopersonaid: this.state.form.diantipopersonaid === "1" ? 1 : 2,
        diantiporegimenid: this.state.form.diantiporegimenid,
        responsabilidadFiscal: this.state.form.fiscalResponsibility.join(""),
        responsabilidadTributariaId: Number(this.state.form.responTributariaId),
        razonsocial: this.state.form.razonsocial,
        correoelectronico: this.state.form.correoelectronico,
        telefono: this.state.form.telefono,
        observacion: this.state.form.observacion,
        aplicafe: this.state.form.aplicafe,
        testid: this.state.form.testid,
        numeraciones: [],
        sucursales: [],
        Esprestadorserviciosalud: this.state.form.prestaserviciosalud,
        Codigoprestadorserviciosalud: this.state.form.codigo,
        Adempresatiposervicosalud: this.state.form.tipoprestadorsalud.map(
          (item) => ({
            Empresaid: enterpriseIdHeader(),
            Tiposervicioid: item,
          })
        ),
      };

      this.props.updateBasicDataAction(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const classes = this.props.classes;
    let fiscalResponsibilityOption = [];
    if (!!this.state.optionsFiscalResponsibility) {
      fiscalResponsibilityOption = this.state.optionsFiscalResponsibility
        .filter((item) => item.codigo !== "O-48" && item.codigo !== "O-49")
        .map((item) => {
          return {
            ...item,
            value: item.id,
            text: `${item.codigo} ${item.descripcion}`,
          };
        });
    }

    return (
      <Fragment>
        <br></br>

        {this.state.loadingGetBasicData === true ? (
          <Grid container alignItems="center" justify="center">
            <CustomProgress />
          </Grid>
        ) : (
          <form
            id="basicDataForm"
            onSubmit={(e) => this.saveBasicData(e)}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <SelectForm
                  label={"Tipo documento *"}
                  name="diantipodocumentoidentidadid"
                  value={this.state.form.diantipodocumentoidentidadid}
                  options={this.state.options}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) => this.syncChanges(e)}
                  disabled={true}
                />
              </Grid>

              {/* Numero de documento */}
              <Grid item xl={2} lg={3} xs={12}>
                <InputForm
                  name="documento"
                  label={"Número de documento *"}
                  maxLength={20}
                  value={this.state.form.documento}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.state.form.diantipodocumentoidentidadid === "7"
                      ? this.validateLettersAndNumbers(e)
                      : this.validateOnlyNumbers(e);
                  }}
                  validator={this.validator}
                  validateOptions={"required|documento"}
                  disabled={true}
                />
              </Grid>

              {/* Numero de verificacion */}
              {this.state.form.diantipodocumentoidentidadid === "6" ? (
                <Grid item xl={2} lg={2} sm={12} xs={12}>
                  <InputForm
                    name="documentodv"
                    label={"Número de verificación"}
                    maxLength={20}
                    value={this.state.form.documentodv}
                    disabled
                    onChange={(e) => {
                      this.syncChanges(e);
                      this.validateOnlyNumbers(e);
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Grid container spacing={2}>
              {this.state.form.diantipodocumentoidentidadid === "6" ? (
                <Grid item xl={4} lg={5} xs={12}>
                  <ToggleButtons
                    label={"Tipo de persona"}
                    value={this.state.form.diantipopersonaid}
                    onChange={this.onChangeTooggle}
                    titleOne={"Jurídica "}
                    titleTwo={"Natural"}
                    txtTooltip={
                      <TooltipMessage
                        title={this.state.menuMyAccount.titleTypePerson}
                        message={this.state.menuMyAccount.messageTypePerson}
                        botton={this.state.menuMyAccount.bottonTypePerson}
                        href={this.state.menuMyAccount.urlTypePerson}
                      />
                    }
                  />
                </Grid>
              ) : (
                ""
              )}

              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <InputForm
                  name={"razonsocial"}
                  label={
                    this.state.form.diantipopersonaid === "1"
                      ? "Razón Social *"
                      : "Nombre(s) y Apellido(s) *"
                  }
                  maxLength={400}
                  value={this.state.form.razonsocial}
                  onChange={
                    this.state.form.diantipopersonaid === "1"
                      ? (e) => {
                          this.syncChanges(e);
                        }
                      : (e) => {
                          this.syncChanges(e);
                          this.validateText(e);
                        }
                  }
                  nameValidator={"nombreEmpresa"}
                  validator={this.validator}
                  validateOptions={"required|min:3|max:400"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <SelectForm
                  label={"Régimen"}
                  name="diantiporegimenid"
                  value={this.state.form.diantiporegimenid}
                  options={this.state.optionsRegimen}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) => this.syncChanges(e)}
                />
              </Grid>

              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <SelectForm
                  label={"Responsabilidad Tributaria *"}
                  name="responTributariaId"
                  value={this.state.form.responTributariaId}
                  options={this.state.optionsResTributaria}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={(e) => this.syncChanges(e)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <MultiSelectForm
                  label={"Responsabilidad Fiscal *"}
                  name="responsabilidadFiscalId"
                  value={this.state.form.fiscalResponsibility}
                  options={fiscalResponsibilityOption}
                  onChange={this.syncMultipleChange}
                  renderValue={(selected) =>
                    this.state.form.fiscalResponsibility
                      .map((value) => {
                        let name = fiscalResponsibilityOption.filter(
                          (category) => category.id === value
                        );
                        return name[0]?.descripcion;
                      })
                      .join(",")
                  }
                  placeholder={"Seleccione..."}
                />
              </Grid>

              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <InputForm
                  name={"telefono"}
                  label={"Teléfono *"}
                  maxLength={15}
                  value={this.state.form.telefono}
                  validator={this.validator}
                  validateOptions={"required|min:7|max:15"}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validatePhone(e);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xl={8} lg={10} sm={12} xs={12}>
                <InputForm
                  name="correoelectronico"
                  maxLength={200}
                  label={"Correo electrónico"}
                  value={this.state.form.correoelectronico}
                  validator={this.validator}
                  validateOptions={"required|email|min:10|max:200"}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validateEmail(e);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xl={4} lg={5} sm={12} xs={12}>
                <SwitchForm
                  name={"prestaserviciosalud"}
                  value={this.state.form.prestaserviciosalud}
                  checked={this.state.form.prestaserviciosalud}
                  titleOn={"Soy una entidad prestadora de servicios de salud"}
                  onChange={this.syncCheckChange}
                />
              </Grid>

              {this.state.form.prestaserviciosalud && (
                <Grid
                  item
                  xl={4}
                  lg={5}
                  md={4}
                  sm={12}
                  xs={12}
                  className={animated}
                >
                  <InputForm
                    name={"codigo"}
                    label={"Código prestador de servicio de salud"}
                    maxLength={50}
                    onChange={(e) => {
                      this.syncChanges(e);
                      this.validateLettersAndNumbers(e);
                    }}
                    value={this.state.form.codigo}
                    tooltip={
                      <TooltipMessage
                        title={this.props.menuMyAccount.titleHealthCode}
                        message={this.props.menuMyAccount.messageHealthCode}
                        botton={this.props.menuMyAccount.bottonHealthCode}
                        href={this.props.menuMyAccount.urlHealthCode}
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>

            {this.state.form.prestaserviciosalud && (
              <Grid container className={animated}>
                <Grid item xl={4} lg={5} md={4} sm={12} xs={12}>
                  <MultipleSelect
                    name={"tipoprestador"}
                    label={"Tipo prestador servicios de salud"}
                    value={this.state.form.tipoprestadorsalud}
                    options={this.state.typesHealthServices}
                    onChange={this.handleChangeTypeHealthService}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid item xl={8} lg={10} md={8} sm={12} xs={12}>
                <InputForm
                  name="observacion"
                  label="Observaciones"
                  maxLength={1000}
                  value={this.state.form.observacion}
                  multiline={true}
                  onChange={(e) => this.syncChanges(e)}
                  tooltip={
                    <TooltipMessage
                      title={getComponentName(
                        this.state.components,
                        11,
                        13,
                        "¿Qué es el Test set Id?"
                      )}
                      message={getComponentName(
                        this.state.components,
                        11,
                        14,
                        "¿Qué es el Test set Id?"
                      )}
                      botton={getComponentName(
                        this.state.components,
                        11,
                        15,
                        "¿Qué es el Test set Id?"
                      )}
                      href={getComponentUrl(
                        this.state.components,
                        11,
                        15,
                        null
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xl={4} lg={5} xs={12}>
                <SwitchForm
                  name="aplicafe"
                  titleOn="Voy a facturar electrónicamente"
                  checked={this.state.form.aplicafe}
                  disabled={this.state.testidexitoso}
                  onChange={this.syncCheckChange}
                />
              </Grid>
            </Grid>

            <div
              style={{ display: this.state.form.aplicafe ? "block" : "none" }}
            >
              <Grid container spacing={2}>
                <Grid item xl={4} lg={5} xs={12}>
                  <InputForm
                    name="testid"
                    label={"Test set id"}
                    maxLength={36}
                    value={this.state.form.testid}
                    validator={this.validator}
                    validateOptions={
                      this.state.form.aplicafe ? "required|uuid" : "void"
                    }
                    onChange={(e) => this.syncChangesTestId(e)}
                    placeholder={"00000000-0000-0000-0000-000000000000"}
                    tooltip={
                      <TooltipMessage
                        title={getComponentName(
                          this.state.components,
                          10,
                          10,
                          "¿Qué es el Test set Id?"
                        )}
                        message={getComponentName(
                          this.state.components,
                          10,
                          11,
                          "¿Qué es el Test set Id?"
                        )}
                        botton={getComponentName(
                          this.state.components,
                          10,
                          12,
                          "¿Qué es el Test set Id?"
                        )}
                        href={getComponentUrl(
                          this.state.components,
                          10,
                          12,
                          null
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xl={4} lg={5} md={4} sm={12} xs={12}>
                  <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <Button
                      className={classes.buttonTestId}
                      variant="contained"
                      href="https://catalogo-vpfe-hab.dian.gov.co/User/Login"
                      target="_blank"
                    >
                      {"Obtener mi Test Set ID"}
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xl={4} lg={5} xs={12}>
                  <Typography
                    className={classes.italicTxt}
                    style={{ color: "#FFFFFF", textAlign: "center" }}
                  >
                    ¿Tienes dudas de cómo realizar el proceso?
                    <br></br>
                    Apréndelo en el siguiente video
                  </Typography>

                  <div className={classes.containerVideo}>
                    <iframe
                      src="https://www.youtube.com/embed/Om59dTlnD6c"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                      style={{
                        width: "100%",
                        height: 200,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <br />
                <Grid item xl={8} lg={10} xs={8}>
                  <Divider light />
                  <hr></hr>
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={2}>
              <Grid item xl={4} lg={5} md={4} sm={12} xs={12}>
                <SwitchForm
                  name={"aplicade"}
                  value={this.state.form.aplicade}
                  checked={
                    this.state.form.aplicade ||
                    this.state.requestCompany?.adsoftwarepropiodocelectronico
                      ?.length > 0
                  }
                  titleOn={"Voy a transmitir Documentos Equivalentes"}
                  onChange={this.syncCheckChange}
                />
              </Grid>
              {this.state.form.aplicade ||
              this.state.requestCompany?.adsoftwarepropiodocelectronico
                ?.length > 0 ? (
                <EquivalentDocumentView
                  docEquivalent={
                    this.state.requestCompany.adsoftwarepropiodocelectronico
                  }
                />
              ) : null}
            </Grid>

            <Grid container spacing={5} justify="center" alignItems="center">
              <Grid item xl={4} lg={5} md={4} sm={12} xs={12}>
                <ButtonPrimary
                  text={getComponentName(
                    this.state.components,
                    13,
                    19,
                    "Listo"
                  )}
                  loading={this.state.loadingSaveBasicData}
                  type={"submit"}
                />
              </Grid>
            </Grid>
          </form>
        )}

        <ResponseModal
          modalType={this.state.responseModal.modalType}
          title={this.state.responseModal.title}
          subtitle={this.state.responseModal.subtitle}
          body={this.state.responseModal.body}
          modalImage={this.state.responseModal.modalImage}
          open={this.state.responseModal.open}
          closeElement={this.state.responseModal.closeElement}
          onCloseElement={() => this.handleResponseModal()}
        />
      </Fragment>
    );
  }
}

BasicData.propTypes = {
  getCompanyAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  basicDataReducer: state.basicDataReducer,
  generalReducer: state.generalReducer,
  configReducer: state.configReducer,
  menuReducer: state.menuReducer,
  menuMyAccount: state.menuReducer.menuMyAccount,
});

export default connect(mapStateToProps, {
  updateBasicDataAction,
  getCompanyAction,
  getTypesHealthServiceAction,
  getEquivalentDocumentTypeAction,
})(withStyles(styles)(BasicData));
