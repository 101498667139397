import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';


import ImageCheck from "../../../../images/icons/Check.png";
import ImageExagono from "../../../../images/icons/Exagono.png";
import CloudImage from "../../../../images/icons/cloud.png"

import PopoverModal from '../../../../components/modal/popoverModal.component';
import SimpleModal from "../../../../components/modal/simpleModal.component";
import FileModalData from '../../../../components/modal/fileModalData.component';
import { FormatDecimal } from '../../../../components/common/formatDecimal';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.sixthBackgroundColor,
    color: theme.palette.primaryColor,
  },

  link: {
    color: "Black",
    fontSize: "12px",
    padding: "4px",
    textAlign: "center",
    borderRadius: '15px',
    backgroundColor: '#DCDCDC',
    fontWeight: 'bold'
  },
  showErrors: {
    cursor: 'pointer'
  },
  titleError: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0px'
  },
  errorContent: {
    fontSize: "12px",
    fontFamily: '.SF NS Display',
    textAlign: 'center',
    margin: '0px'
  },
  containerErrors: {
    color: "FFFFFF",
    fontSize: "16px",
    padding: "revert",
    backgroundColor: "#3e3e3e",
    textAlign: "center",
    borderRadius: 8,
    //padding: "15px",
    marginLeft: 50

  },
  divider: {
    width: "100%",
    borderBottom: "1px solid gray",
    display: "inline-grid",
  },
  titleTable: {
    color: "#f5d13d",
    padding: "0px",
  },
  userDevices: {
    textAlign: "left",
    color: "#FFFFFF",
    padding: "0px",
  },
  table: {
    width: "100%"
  },
  cardBukloadName: {
    color: '#FFFFFF',
    fontFamily: theme.typography.fontFamily,
    fontSize: '20px',


  },
  spanNameClient: {
    fontWeight: 600
  },
  countArticle: {
    color: theme.palette.secundaryColor,
    fontFamily: theme.palette.fontFamily
  },
  titleType: {
    fontFamily: theme.palette.fontFamily,
    fontWeight: 600
  }
}));



const InvoiceCard = (props) => {
  const { dataObject: facturas, onDelete, numero, fechaAcuseDian, stepChange, estado } = props;

  const classes = useStyles();

  const loading = {
    Loading: false,
    SelectId: 0
  };

  const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
  const [openModalErrors, setOpenModalErrors] = useState(false)
  const [isStep2, setIsStep2] = useState(false)
  const [openModalFile, setOpenModalFile] = React.useState(false);
  const [formPreview, setFormPreview] = React.useState(null);


  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);

  };

  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  useEffect(() => {
    setIsStep2(onDelete ? true : false)
  }, [onDelete])

  /**
   * obtener icono (avatar) de la card
   */
  const getIcon = () => {
    return stepChange === 1 ? getIconStep2() : stepChange === 2 ? getIconStep3(estado) : '';
  }

  /**
   * obtiene los iconos para el paso 2
   */
  const getIconStep2 = () => {
    // if(facturas.datosCargueMasivo.erroresFactura.length > 0){
    // return !facturas.datosCargueMasivo.erroresFactura  ?
    return facturas.datosCargueMasivo.erroresFactura.length > 0 ?
      <img onClick={handleOpenModalChecks} className={classes.showErrors} src={ImageExagono} alt={"Exagono"} /> :
      facturas.datosCargueMasivo.erroresFactura.length === 0 ? <img src={ImageCheck} alt={"check"} /> : ''
    //  }


  }

  /**
   * obtiene los iconos para el paso 3
   */
  const getIconStep3 = (estadoValidacion) => {

    if (estadoValidacion === 1 || estadoValidacion === 3 || estadoValidacion === 4) {
      return (
        <img className={classes.showErrors} src={ImageExagono} alt={"Exagono"} />)
    }

    if (estadoValidacion === 2) {
      return (<img src={ImageCheck} alt={"check"} />)
    }

    if (estadoValidacion === 0) {

      return (<img src={CloudImage} alt={"cloud"} />)


    }

    return (
      <HourglassEmptyIcon />)

  }





  /**
   * cierra modal de errores
   * al dar click en boton cerrar
   * @param {object} e
   */
  const onCloseModalItem = (e) => {
    setOpenModalErrors(false)
  };

  /**
   * Muestra el modal de asignacion de contingencia
   */
  const handleOpenModal = () => {
    setOpenModalErrors(true)
  };

  function ChangeFormateDate(date) {
    if (date) {
      return date.toString().split("-").reverse().join("/")
    }
  }

  const pad = (input, length, padding) => {
    var str = input + "";
    return (length <= str.length) ? str : pad(padding + str, length, padding);
  }

  const sendPreview = () => {

    const invoice = {
      Opdocumento: {
        fecha: facturas.opdocumento.fecha,
        numeracionid: facturas.opdocumento.numeracionid,
        estadodocumentoid: facturas.opdocumento.estadodocumentoid,
        tipoaceptaciondocumentoid: facturas.opdocumento.tipoaceptaciondocumentoid,
        tipoacusedocumentoid: facturas.opdocumento.tipoacusedocumentoid,
        valorapagar: facturas.opdocumento.valorapagar,
        dianformapagoid: facturas.opdocumento.dianformapagoid,
        valordescuento: facturas.opdocumento.valordescuento,
        valorcargos: facturas.opdocumento.valorcargos,
        subtotal: facturas.opdocumento.subtotal,
        valortotal: facturas.opdocumento.valortotal,
        valordescuentodocumento: facturas.opdocumento.valordescuentodocumento,
        valorcargodocumento: facturas.opdocumento.valorcargodocumento,
        valoranticipo: facturas.opdocumento.valoranticipo,
        valorinc: facturas.opdocumento.valorinc,
        valoriva: facturas.opdocumento.valoriva,
        textoobservacion: facturas.opdocumento.textoobservacion,
        diantipodocumentoelectronicoid: facturas.opdocumento.diantipodocumentoelectronicoid,
        clienteid: facturas.opdocumento.clienteid,
        dianmediopagoid: facturas.opdocumento.dianmediopagoid,
        origendocumentoid: facturas.opdocumento.origendocumentoid,
        Dianconceptonotadebitoid: facturas.opdocumento.dianconceptonotadebitoid,
        Dianconceptonotacreditoid: facturas.opdocumento.dianconceptonotacreditoid,
        fechapago: facturas.opdocumento.fechapago,
        favorito: facturas.opdocumento.favorito,
        empresaid: facturas.opdocumento.empresaid,
        Incluirvendedor: facturas.opdocumento.incluirvendedor,
        Valordescuentoreal: facturas.opdocumento.valordescuentoreal,
        Valorivareal: facturas.opdocumento.valorivareal,
        Valorincreal: facturas.opdocumento.valorincreal,
        TotalfacturaReal: facturas.opdocumento.totalfacturaReal,
        Redondeo: facturas.opdocumento.redondeo,
        Decimales: facturas.opdocumento.decimales,
        Valorretfte: facturas.opdocumento.valorretfte,
        Valorretica: facturas.opdocumento.valorretica,
        Valorretiva: facturas.opdocumento.valorretiva,
        Valorapagardian: facturas.opdocumento.valorapagardian,
        Archivovisualizar: "INVOICE",
        Vendedor: facturas.opdocumento.vendedor,
        Cliente: {
          Id: facturas.opdocumento.cliente.id,
          Documento: facturas.opdocumento.cliente.documento,
          Documentodv: facturas.opdocumento.cliente.documentodv,
          Razonsocial: facturas.opdocumento.cliente.razonsocial,
          Diantipodocumentoidentidadid: facturas.opdocumento.cliente.diantipodocumentoidentidadid,
          Telefono: facturas.opdocumento.cliente.telefono,
          Correoelectronico: facturas.opdocumento.cliente.correoelectronico,
          Clientedireccion: facturas.opdocumento.cliente.clientedireccion,
          Contactos: {}
        },
        Numeracion: facturas.opdocumento.numeracion,
      },
      LstOpdetalledocumento: facturas.LstOpdetalledocumento,
      LstCargos: facturas.lstCargos,
      LstDescuentos: facturas.lstDescuentos
    }

    setFormPreview(invoice)

    setOpenModalFile(true);
  }

  const handleCloseModalFile = () => {
    setOpenModalFile(false);
  };


  /**
   * ventana de filtros
   */
  const modalFilters = () => {
    const errors = facturas.datosCargueMasivo.erroresFactura;

    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={12} md={12} sm={12} >
            <p className={classes.titleError}>Error de información</p>
          </Grid>
          <Grid item lg={12} md={12} sm={12} >
            <p className={classes.errorContent}>Se presenta un error en la fila {errors && errors[0].line}, Columna {errors[0].key}: {errors[0].message}</p>
            <br />
          </Grid>
          {errors.length > 1 && <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link href="#" onClick={handleOpenModal} variant="inherit" underline="none" className={classes.link}>
              Ver más
            </Link>
          </Grid>}
        </Grid>
      </div>
    );
  }

  /**
   * Tabla donde se muestran los errores
   *  */
  const errorsTable = () => {
    const errors = facturas.datosCargueMasivo.erroresFactura;
    return (
      <Grid container >

        <div className={classes.containerErrors}>
          <table className={classes.table}>
            <thead>
              <tr className={(classes.userDevices, classes.titleTable)}>
                <th style={{ width: '20%' }}><Box pr={12}>Fila</Box></th>
                <th style={{ width: '20%' }}><Box pr={12}>Columna</Box></th>
                <th style={{ width: '60%' }}><Box>Corrección</Box></th>
              </tr>
            </thead>
            <tbody>
              {errors ? (
                errors.map((error, index) => (
                  <tr key={index} className={classes.userDevices}>
                    <td>{error.line}</td>
                    <td>{error.key}</td>
                    <td>{error.message}</td>
                  </tr>
                ))
              ) : (
                <tr className={classes.userDevices}>
                  <td colSpan={3}>No se encontraron errores</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </Grid>

    );
  }

  /**
   * informacion adicional, solo para el step 3
   */
  const additionalData = () => {

    return (

      <Grid container>
        <Grid item lg={12} md={12} sm={12} className="dataNumberInvoice">
          {/**
            * {loading.Loading && loading.SelectId === facturas.id ? 
            '' 
            : 'Factura: ' + numero} {' - Fecha validación DIAN : ' + fechaAcuseDian}
            */}
          {numero !== undefined && numero !== null ? <div><span>Factura: {numero}</span> {fechaAcuseDian !== null ? <span> - Fecha validación DIAN: {pad(new Date(fechaAcuseDian).getDate(), 2, "0")}/{pad((new Date(fechaAcuseDian).getMonth() + 1), 2, "0")}/{new Date(fechaAcuseDian).getFullYear()}</span> : ''}</div> : ''}
        </Grid>
      </Grid>

    );
  }

  return (

    <div>

      <Card className={classes.root}>
        <CardHeader
          avatar={<div>
            {getIcon()}
            <PopoverModal
              handleClose={handleCloseModalChecks}
              open={Boolean(modalChecksAnchorEl)}
              component={modalFilters}
              width={220}
              modalWarning={true}
              popoverAnchorEl={modalChecksAnchorEl}
              showCloseButton={false}
            />
            <SimpleModal
              onClose={onCloseModalItem}
              open={openModalErrors}
              title={""}
              noPadding={true}
              width={650}
              height={200}
              //otherClass={classes.modalAssign}
              component={errorsTable} />
          </div>}

          title={<div className={classes.cardBukloadName}><span className={classes.spanNameClient}>{facturas.datosCargueMasivo.cliente.razonSocial}</span> - {facturas.datosCargueMasivo.cliente.tipo === 'null' ? '' : facturas.datosCargueMasivo.cliente.tipo}  <span>{facturas.datosCargueMasivo.cliente.numero} {facturas.datosCargueMasivo.cliente.tipoDocumento === 6 ? - facturas.datosCargueMasivo.cliente.digito : ''}</span> </div>}
          subheader={
            <div>
              <Fragment>
                <Grid container>
                  <Grid item lg={12} xs={12}>
                    <Grid item lg={10} md={10} sm={12} className="dataInvoiceGrid">
                      {loading.Loading && loading.SelectId === facturas.id ? '' :
                        <Fragment >
                          {facturas.datosCargueMasivo.fechaFactura === null || facturas.datosCargueMasivo.fechaFactura === undefined || facturas.datosCargueMasivo.fechaFactura.length === 0 ? '' : <Fragment><span>Fecha Factura :</span> <span className={classes.titleType}>{facturas.datosCargueMasivo.fechaFactura}</span><span> /</span></Fragment>}
                          {facturas.datosCargueMasivo.formaPago !== 'Contado' && facturas.datosCargueMasivo.fechaPago !== null ? <Fragment><span> Fecha de  pago : </span><span className={classes.titleType}>{ChangeFormateDate(facturas.datosCargueMasivo.fechaPago)}</span> <span>/</span></Fragment> : ''}
                          {facturas.datosCargueMasivo.formaPago === null || facturas.datosCargueMasivo.formaPago === undefined ? '' : <Fragment><span> Forma de pago : </span><span className={classes.titleType}> {facturas.datosCargueMasivo.formaPago}</span> {facturas.datosCargueMasivo.formaPago === 'Contado' ? <span>/</span> : ''}</Fragment>}
                          {facturas.datosCargueMasivo.medioPago === null || facturas.datosCargueMasivo.medioPago === undefined ? '' : facturas.datosCargueMasivo.formaPago === 'Contado' ? <Fragment><span> Medio de pago : </span><span className={classes.titleType}>{facturas.datosCargueMasivo.medioPago} </span></Fragment> : ''}

                        </Fragment>}
                    </Grid>
                  </Grid>
                  <Grid item lg={10} md={10} sm={12} className="dataNumberInvoice">
                    {loading.Loading && loading.SelectId === facturas.id ? '' : <div>Cantidad artículos: <span className={classes.countArticle} >{facturas.datosCargueMasivo.cantidadArticulos}</span></div>}

                  </Grid>
                </Grid>
              </Fragment>
              {!isStep2 && additionalData()}
            </div>
          }
          action={
            <Fragment>
              <div>
                {isStep2 && <Tooltip title="Eliminar">
                  <DeleteIcon className="buttonInvoiceBehavior" onClick={onDelete} />
                </Tooltip>}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {facturas.datosCargueMasivo.erroresFactura.length === 0 ?
                  <Tooltip title="Visualizar">

                    <VisibilityIcon
                      className="buttonInvoiceBehavior"
                      onClick={
                        () => sendPreview()
                      }>
                    </VisibilityIcon>

                  </Tooltip> : ''
                }

                <FileModalData
                  open={openModalFile}
                  onCloseElement={handleCloseModalFile}
                  closeElement={"Cerrar"}
                  paramFile={formPreview}
                />
              </div>

              <div className={facturas.datosCargueMasivo.colorValorTotal}>
                {
                  (loading.Loading && loading.SelectId === facturas.id)
                    ? ''
                    : <FormatDecimal
                      value={facturas.datosCargueMasivo.valorTotal}
                      prefix={"$"}
                    />
                }
                <div>
                  {loading.Loading && loading.SelectId === facturas.id ? '' : <div>{facturas.datosCargueMasivo.estado.length < 7 ? <div>&nbsp;&nbsp; {facturas.datosCargueMasivo.estado} </div> : facturas.datosCargueMasivo.estado}</div>}
                </div>
              </div>

            </Fragment>

          }
        />
      </Card>

    </div>

  );
}


const mapStateToProps = (state) => ({
  stepChange: state.invoiceReducer.stepChange
});
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCard);
