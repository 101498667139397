import { types } from "../types/types";

/**
 * Agrega nuevo documento de referencia
 * @param {*} document
 * @returns
 */
export const addDocumentReferenceAction = (document) => (dispatch) => {
	dispatch({
		type: types.ADD_DOCUMENT_REFERENCE,
		payload: document,
	});
};

/**
 * Carga documento de referencia en formulario
 * @param {*} id
 * @returns
 */
export const loadDocumentReferenceAction = (id) => (dispatch) => {
	dispatch({
		type: types.LOAD_DOCUMENT_REFERENCE,
		payload: id,
	});
};
/**
 * Actualiza documento de referencia
 * @param {*} document
 * @returns
 */
export const updatedDocumentReferenceAction = (document) => (dispatch) => {
	dispatch({
		type: types.UPDATED_DOCUMENT_REFERENCE,
		payload: document,
	});
};

/**
 * Borra documento de referencia
 * @param {*} id
 * @returns
 */
export const deleteDocumentReferenceAction = (id) => (dispatch) => {
	dispatch({
		type: types.DELETE_DOCUMENT_REFERENCE,
		payload: id,
	});
};

/**
 * Actualiza listado documentos de referencia
 * @param {*} list
 * @returns
 */
export const refreshDocumentReferenceAction = (list) => (dispatch) => {
	dispatch({
		type: types.REFRESH_DOCUMENTS_REFERENCE,
		payload: list,
	});
};
