import React from 'react'
import { makeStyles } from '@material-ui/core/styles';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { format } from "date-fns";
import { FormatDecimal } from '../../../components/common/formatDecimal';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.11)',
        color: theme.palette.primaryColor,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: -5,
            paddingLeft: 55
        }
    },
    header: {
        paddingBottom: 0,
        paddingTop: 5,
        marginLeft: -5,
        '& .MuiCardHeader-action': {
            display: 'flex'
        },
        '& .MuiCardHeader-root': {
            padding: 10
        }
    },
    content: {
        fontSize: '0.9rem',
    },
    title: {
        color: theme.palette.primaryColor,
        fontSize: '0.9rem',
        fontWeight: 600
    },
    icon: {
        color: theme.palette.secundaryColor
    },
    wordBreak: {
        wordBreak: 'break-all'
    }
}));

const DocumentReference = ({ data }) => {
    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={<img src={require('../../../images/docref.png')} alt={"logo"} className={classes.icon} />}
                classes={{
                    title: classes.title,
                }}
                title={data.tipodocumentodescripcion}
            />
            <CardContent className={classes.content}>
                <div className={classes.wordBreak}>
                    <span>{data.numero}</span>
                </div>

                <div className={classes.wordBreak}>
                    <span>{format(new Date(data.fecha), 'dd/MM/yyyy')}</span>
                </div>

                {
                    data.tipodocumentoid?.toString() === '1'
                    && <div className={classes.wordBreak}>
                        <span>
                            <FormatDecimal
                                value={data?.valorapagar}
                                prefix={"$"}
                            />
                        </span>
                    </div>
                }
            </CardContent>
        </Card>
    )
}



export default DocumentReference;
