import React, { useEffect, useRef } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import {
  makeStyles,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from '@material-ui/core';

import {
  clearEquivalentDocumentReducerAction,
  loadNoteEquivalentDocDataAction,
  SetInforForCreatingModalDataAction,
} from '../../../../actions/equivalentDocAction';

import ResponseModal from '../../../../components/modal/responseModal.component';

import { QontoStepIcon } from '../../../documentSupport/UpdateDocSupport/StylesFunctionUpdate';

import { initialModalDataCreate } from '../../utils/createTypeModalData';
import { getStepContent } from '../../utils';

const useStyles = makeStyles((theme) => ({
  containerForm: {
    backgroundColor: '#232323',
    borderRadius: '8px',
    minHeight: '600px',
    padding: '15px 15px 5px 15px',
  },
  root: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
    display: 'flex',
    paddingBottom: '18px',
  },
  activeButton: {
    borderBottom: '1px solid #50C1EE',
    paddingBottom: '10px',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    top: 10,
  },
  active: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  line: {
    borderColor: '#4F4F4F',
    borderRadius: 1,
    borderTopWidth: 3,
  },
})(StepConnector);

const getProcessSteps = () => [
  'Información de documento',
  'Artículos',
  // "Revisar documento", //! Pendiente de implementar
];

export const CreateEquivalentDocumentView = ({ edit = false }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (edit) {
      dispatch(loadNoteEquivalentDocDataAction());
    }
  }, [dispatch, edit]);

  const { completed, activeStep } = useSelector(
    (state) => state.equivalentDocReducer.createEquivalentDocumentData
  );

  const { modalData } = useSelector(
    (state) => state.equivalentDocReducer,
    shallowEqual
  );

  const { showCatalog } = useSelector((state) => state.documentCartReducer);

  const { current: steps } = useRef(getProcessSteps());

  /**
   * * Verifica si un paso está completo.
   * @param {string} step - El paso a verificar.
   * @returns {boolean} - `true` si el paso está completo, de lo contrario `false`.
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  /**
   * * Obtiene el contenido de un paso específico y muestra el catálogo si es necesario en la sección de artículos.
   *
   * @param {number} activeStep - El paso activo.
   * @param {boolean} showCatalog - Indica si se debe mostrar el catálogo.
   * @returns {Component} - El contenido del paso.
   */
  const { content } = getStepContent(
    activeStep,
    showCatalog,
    edit ? 'DE_NOTE' : 'DE'
  );

  const handleResponseModal = () => {
    dispatch(clearEquivalentDocumentReducerAction());
    dispatch(SetInforForCreatingModalDataAction(initialModalDataCreate));
  };

  return (
    <div className={classes.containerForm}>
      <Stepper
        activeStep={0}
        alternativeLabel
        className={classes.root}
        connector={<QontoConnector />}
        nonLinear
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              className={activeStep === index ? classes.activeButton : ''}
            >
              <StepButton completed={isStepComplete(index)} {...buttonProps}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className='stepper-title'>{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {content}
      <ResponseModal {...modalData} onCloseElement={handleResponseModal} />
    </div>
  );
};
