import { errorToast, warningToast } from "./toast.helpers";

const noResponseFromApi = {
	status: 501,
};

export const readError = (data) => {
	if (!data.error)
		//No hay error
		return;

	const { error, message, show } = data;
	//Imprimir log
	console.log(message, !!error.response ? error.response : error);

	let hideAlert = localStorage.getItem("hideAlert") === "true";
	if (hideAlert === true) {
		return;
	}

	if (!show)
		// No mostar notificaciones
		return;

	const response = !!error.response ? error.response : noResponseFromApi;
	const { status } = response;

	switch (status) {
		case 500:
			errorToast("Error 500", message);
			break;
		case 400:
			warningToast("Error 400, petición invalida", message);
			break;
		case 404:
			errorToast("Error 404,  Petición rechazada", message);
			break;
		case 401:
			errorToast("Error 401, Token invalido", message);
			break;
		case 403:
			errorToast("Error 403, Token invalido", message);
			break;
		case 501:
			errorToast(
				"Upss!!",
				`Se ha generado una exepción no controlada, Por favor contacte al administrador ${message}`
			);
			break;
		default:
			errorToast(
				'Upss!!"',
				`Ha ocurrido un error no identificado, Por favor contacte al administrador ${message}`
			);
			break;
	}
};
