/* eslint-disable import/prefer-default-export */
export const Rules = {
  itemColumn: {
    pattern: {
      value: /^[0-9]*$/,
      message: "Solo se permite números",
    },
  },
  tipoDocumentoColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Opciones Validas [CC|NIT|PA]",
    },
  },
  numeroDocumentoColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[a-zA-Z0-9]{1,15}$/,
      message: "Solo se permite caracteres alfanuméricos, longitud máxima de 15"
    },
  },
  numeroDocumentoNumberColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[0-9]{1,15}$/,
      message: "No puede tener letras ya que el tipo de documento no es de tipo pasaporte",
    },
  },

  nombreClienteColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){3,200}$/,
      message: "Mínimo 3 caracteres y máximo 200",
    },
  },
  tipoPersonaColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Opciones válidas [1|2]",
    },
  },
  regimenColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Opciones válidas [04|49]",
    },
  },
  correoElectronicoColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+]{3,})+@([A-Za-z0-9_\-.]{3,})+\.([A-Za-z]{2,})$/,
      message: "Debe ingresar una dirección de correo válida, mínimo 10 caracteres",
    },
  },
  telefonoColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[0-9-+() ]{7,15}$/,
      message: "Solo se permite números y -+(), mínimo 7 y máximo 15 caracteres",
    },
  },
  direccionColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){1,200}$/,
      message: "Mínimo 1 y máximo 200 caracteres.",
    },
  },
  municipioColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Opciones válidas [11001|05001]",
    },
  },
  iANombreDireccionColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){1,300}$/,
      message: "Mínimo 1 y máximo 300 caracteres.",
    },
  },
  iADireccionColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){1,100}$/,
      message: "Mínimo 1 y máximo 100 caracteres.",
    },
  },
  iAMunicipioColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Opciones válidas [11001|05001]",
    },
  },
  cANombreContactoColumn: {
    pattern: {
      value: /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]{3,100}$/,
      message: "Solo se permite letras, tildes y números, mínimo 3 y máximo 100 caracteres",
    },
  },
  cACelularContactoColumn: {
    pattern: {
      value: /^[0-9-+() ]{7,15}$/,
      message: "Solo se permite números y -+(), mínimo 7 y máximo 15 caracteres",
    },
  },
  cAEmailContactoColumn: {
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+]{3,})+@([A-Za-z0-9_\-.]{3,})+\.([A-Za-z]{2,})$/,
      message: "Debe ingresar una dirección de correo válida, mínimo 10 caracteres",
    },
  },
};

export const ColumnsFile = {
  itemColumn: {
    name: '1. No.',
    position: 0,
    //msgBackValidations: 'No existe esta forma de pago'
  },
  tipoDocumentoColumn: {
    name: '2. Tipo Documento',
    position: 1
  },
  numeroDocumentoColumn: {
    name: '2A. Número de Documento',
    position: 2
  },
  nombreClienteColumn: {
    name: '2B. Razón Social o Nombres y apellidos del cliente',
    position: 3
  },
  tipoPersonaColumn: {
    name: '2C. Tipo de persona',
    position: 4
  },
  regimenColumn: {
    name: '2D. Régimen',
    position: 5
  },
  correoElectronicoColumn: {
    name: '2E. Correo Electrónico',
    position: 6
  },
  telefonoColumn: {
    name: '2F. Teléfono',
    position: 7
  },
  direccionColumn: {
    name: '2G. Dirección',
    position: 8
  },
  municipioColumn: {
    name: '2I. Municipio',
    position: 9,
  },
  iANombreDireccion1Column: {
    name: '3. Información adicional – Nombre Dirección 1',
    position: 10
  },
  iADireccion1Column: {
    name: '3A. Información adicional – Dirección 1',
    position: 11
  },
  iAMunicipio1Column: {
    name: '3B. Información adicional – Municipio 1',
    position: 12
  },
  iANombreDireccion2Column: {
    name: '4. Información adicional – Nombre Dirección 2',
    position: 13
  },
  iADireccion2Column: {
    name: '4A. Información adicional – Dirección 2',
    position: 14
  },
  iAMunicipio2Column: {
    name: '4B. Información adicional – Municipio 2',
    position: 15
  },
  cANombreContacto1Column: {
    name: '5. Contacto adicional – Nombre del contacto 1',
    position: 16
  },
  cACelularContacto1Column: {
    name: '5A. Contacto adicional – Celular del contacto 1',
    position: 17
  },
  cAEmailContacto1Column: {
    name: '5B. Contacto adicional – Email del contacto 1',
    position: 18
  },
  cANombreContacto2Column: {
    name: '6. Contacto adicional – Nombre del contacto 2',
    position: 19
  },
  cACelularContacto2Column: {
    name: '6A. Contacto adicional – Celular del contacto 2',
    position: 20
  },
  cAEmailContacto2Column: {
    name: '6B. Contacto adicional – Email del contacto 2',
    position: 21
  }
}