import React from "react";

import { useSelector, shallowEqual } from "react-redux";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import { EmptyCustomer } from "../../../document/customer/emptyCustomer";
import { FullCustomer } from "./FullCustomer";
import CustomProgress from "../../../../components/Progress/progress.component";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#3B3B3B",
    borderRadius: "4px",
    padding: "10px 15px",
  },
  wordBreak: {
    color: "#FFF",
    wordBreak: "break-word",
  },
  legalName: {
    color: "#2CC63E",
    fontFamily: "Muli",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  textLeft: {
    color: "#FFF",
    fontFamily: "Muli",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  textRight: {
    color: "#FFF",
    fontFamily: "Muli",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  iconsDetail: {
    display: "flex",
  },
  colorIcon: {
    color: "#2CC63E",
  },
}));

export const SelectedCustomerSection = () => {
  const classes = useStyles();

  const { customerEquivalentDoc } = useSelector(
    (state) => state.equivalentDocReducer
  );
  const { loadingDetail: loadingGetCustomer } = useSelector(
    (state) => state.customerReducer,
    shallowEqual
  );

  return customerEquivalentDoc ? (
    <Grid
      container
      className={`${classes.root} animate__animated animate__fadeIn animate__faster`}
    >
      {loadingGetCustomer ? (
        <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
          <CustomProgress size={60} />
        </Grid>
      ) : (
        <FullCustomer {...customerEquivalentDoc} />
      )}
    </Grid>
  ) : (
    <EmptyCustomer />
  );
};
