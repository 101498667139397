/* eslint-disable no-control-regex */
import axios from "axios";
import jwt_decode from "jwt-decode";
import aesjs from "aes-js";
import moment from "moment";
import "moment/locale/es";
import {
  URL_SECURITY,
  API_ENDPOINT_ENTERPRISE,
  ROL_CFCOLAB,
  API_ENDPOINT_IBUHOO,
  MESSAGE_NEXT_CONFIRM,
} from "../config/config";
import { setAuthTokenAxios } from "../config/axios-instance";
import { encryptionKey, encryptionIv } from "./../config/configKeys";
import { ConfirmAlert } from "../helpers/alert.helpers";
import { fillListOffices } from "./branchOfficeActions";
import { fillListSellers, fillListBillers } from "./userAction";
import { fillListNumerations } from "./numerationActions";
import { TIME_REFRESH_TOKEN } from "./../config/config";
import { types } from "../types/types";

const urlEnterpriseEndPoint = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;
const urlAuthentication = `${API_ENDPOINT_IBUHOO}/authentication`;

/**
 * AuthAction loginUser (set user credentials to server for login attempt )
 * @param {string} token
 */
const loginUser = async (token) => {
  const url = `${urlEnterpriseEndPoint}/TokenValidate`;
  const config = {
    headers: {
      Authorization: `${authHeader(token)}`,
    },
  };

  const response = await axios.get(url, config);
  return response.data;
};

export const setCurrentUser = (decoded) => {
  return {
    type: types.SET_CURRENT_USER,
    payload: decoded,
  };
};

/**
 * eliminar usuario de grupo de signalR
 */
/*function leaveGroupSignalAction  (empresaId, usuarioId, connectionId)  {

axiosApiInstance.delete(`${urlSignalR}leaveGroup/${empresaId}/${usuarioId}/${connectionId}`, defaultHeaders());
 
}*/

/**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
export const logoutUserAction = (conection) => (async) => {
  const logoutData = (confirmed) => {
    if (confirmed) {
      // leaveGroupSignalAction(enterpriseIdHeader(),idUser(),conection)
      conection
        .invoke(
          "LeaveGroup",
          enterpriseIdHeader(),
          idUser(),
          conection.connectionId
        )
        .catch((error) => {
          console.log("Error eliminando conexión de grupo", error);
        });
      redirectToLogin();
    }
  };
  ConfirmAlert(
    "¿Está seguro de cerrar sesión?",
    logoutData,
    MESSAGE_NEXT_CONFIRM
  );
};

/**
 * Lee token y lo valida
 * @param {*} queryString Información token
 */
export const checkTokenValidAction = (queryString, completeValid) => async (
  dispatch
) => {
  try {
    const dataToken = await decodeTokenData(queryString);
    const data = await loginUser(dataToken.token);

    dispatchData(data, dispatch);
    setAuthTokenAxios(dataToken);

    //Devuelve información de empresa
    completeValid(data);
  } catch (err) {
    console.log("Error validando token, ", !!err.response ? err.response : err);
    completeValid(!!err.response ? err.response.data : err);
  }
};

/**
 * Carga datos de autenticacion en redux
 * @param {data} data
 */
const dispatchData = async (data, dispatch) => {
  const response = {
    companyId: data.result.enterprise.id,
    companyName: data.result.enterprise.razonsocial,
    userName: data.result.userLogged.nombres,
    userId: data.result.userLogged.iduser,
    userEmail: data.result.userLogged.email,
  };

  localStorage.setItem("dataCompany", JSON.stringify(response));

  dispatch({
    type: types.GET_COMPANY,
    payload: data.result.enterprise,
  });

  dispatch({
    type: types.GET_BRANCH_OFFICES,
    payload: data.result.enterprise.sucursales,
  });

  fillListOffices(data.result.enterprise.sucursales);

  dispatch({
    type: types.GET_SELLERS,
    payload: data.result.enterprise.advendedor,
  });

  fillListSellers(data.result.enterprise.advendedor);

  dispatch({
    type: types.GET_BILLERS,
    payload: data.result.enterprise.adusuarios,
  });

  fillListBillers(data.result.enterprise.adusuarios);

  dispatch({
    type: types.GET_NUMERATIONS,
    payload: data.result.enterprise.numeraciones,
  });

  fillListNumerations(data.result.enterprise.numeraciones);

  return true;
};

/**
 * Decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
async function decodeTokenData(queryString) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || "", "base64").toString("hex")
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = JSON.parse(cleanParamsObj);
    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      return paramsObj;
    } else {
      redirectToLogin();
    }
  } catch (err) {
    redirectToLogin();
  }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\u0000-\u0019]+/g, "");
    return paramsChar;
  } catch (err) {
    console.log("Error limpiando parametros:", err);
  }
}

/**
 * validar vigencia del token
 *
 * @method
 * @param {querytokenString} token
 */
export const validateTokenExpirationDate = async () => {
  try {
    const jwtToken = localStorage.getItem("jwtToken");
    const decodedToken = jwt_decode(jwtToken);
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedToken.exp).utc();

    if (moment(dateExpiresToken).isAfter(currentDate) === false) {
      redirectToLogin();
    } else if (
      moment(dateExpiresToken).diff(currentDate, "seconds") < TIME_REFRESH_TOKEN
    ) {
      //Refresh Token
      let userName = decodedToken.unique_name;
      let refreshData = {
        Token: jwtToken,
        UserName: userName,
        Appname: "colnomina",
      };

      try {
        await axios
          .post(urlAuthentication + "/api/Login/RefreshToken", refreshData)
          .then((response) => {
            setAuthTokenAxios(response.data.result.token);
          });
      } catch (err) {
        redirectToLogin();
        console.log("Error refrescando Token:", err);
      }
    }
  } catch (err) {
    redirectToLogin();
    console.log("Error al validar la fecha de vencimiento del token:", err);
  }
};

/**
 * Encriptar mensaje
 *
 * @method
 * @param {requestParameters} requestParameters
 */
export function encryptParameters(requestParameters) {
  try {
    const requestParametersJson = JSON.stringify(requestParameters);
    const requestTextBytesUtf8 = aesjs.utils.utf8.toBytes(
      requestParametersJson
    );
    const requestPaddedData = aesjs.padding.pkcs7.pad(requestTextBytesUtf8);
    const aesCtr = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedBytes = aesCtr.encrypt(requestPaddedData);
    const encryptedTextBase64 = Buffer.from(encryptedBytes).toString("base64");
    return encodeURIComponent(encryptedTextBase64);
  } catch (err) {
    console.log("Error encriptando parametros:", err);
  }
}

/**
 * Obtener el userName a partir del token
 * @param {token} token
 */
export function userName(token) {
  const decodedToken = jwt_decode(token);
  if (decodedToken) {
    return `${decodedToken.unique_name}`;
  } else {
    return null;
  }
}

/**
 * Redireccionar a login
 *
 * @method
 * @param
 */
function redirectToLogin() {
  setAuthTokenAxios();
  window.location.href = URL_SECURITY;
}

/**
 * Procesar respuesta 401 o 403
 * Se debe quitar
 * @method
 * @param {response} response
 */
export function handleResponse(response) {
  if (!!response) {
    if (response.status !== null && response.status !== undefined) {
      if ([401, 403].indexOf(response.status) !== -1) {
        redirectToLogin();
      }
    }
  }
}

/**
 * Obtener token en el header
 *
 * @method
 * @param {token} token
 */
export function authHeader(token) {
  const currentToken =
    token === null || token === undefined
      ? localStorage.getItem("jwtToken")
      : token;
  if (currentToken !== null || currentToken !== undefined) {
    return `Bearer ${currentToken}`;
  } else {
    return;
  }
}

/**
 * Obtener id empresa en el header
 *
 * @method
 * @param {}
 */
export function enterpriseIdHeader() {
  const company = JSON.parse(localStorage.getItem("dataCompany"));
  const enterpriseId =
    company !== null || company !== undefined ? company.companyId : null;
  if (enterpriseId !== null || enterpriseId !== undefined) {
    return `${enterpriseId}`;
  } else {
    return {};
  }
}

/**
 * Obtener tipo de documento de la empresa.
 *
 * @method
 * @param {}
 */
export function enterpriseDocumentType() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));

  if (decodedToken) {
    return `${decodedToken.customer_type}`;
  } else {
    return {};
  }
}

export function isIntegrationEnterprise() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (!!decodedToken) {
    const privileges = decodedToken?.COLFACTURA_PRIVILEGE ?? [];

    const isIntegration = privileges.find(
      (c) => c === "COLFACTURA_INTEGRATION_VIEW"
    );
    if (!!isIntegration) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * Obtener numero de documento de la empresa
 *
 * @method
 * @param {}
 */
export function enterpriseDocumentNumber() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.customer_value}`;
  } else {
    return {};
  }
}

/**
 * Obtener rol del usuario logueado
 *
 * @method
 * @param {}
 */
export function getRoleOfLoggedUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (!!decodedToken.COLFACTURA_ROLE && decodedToken.COLFACTURA_ROLE !== "") {
    return decodedToken.COLFACTURA_ROLE === ROL_CFCOLAB;
  } else {
    return true;
  }
}

/**
 * Obtener nombre de usuario que logueado
 *
 * @method
 * @param {}
 */
export function fullNameUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    let fullName = decodedToken.given_name.concat(
      " " + decodedToken.family_name
    );
    return `${fullName}`;
  } else {
    return {};
  }
}

/**
 * Obtener el correo electronico de usuario logueado
 *
 * @method
 * @param {}
 */
export function emailUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.email}`;
  } else {
    return {};
  }
}

/**
 * Obtener el el iduser del usuario logueado, para poder obtener el rol del usuario.
 *
 * @method
 * @param {}
 */
export function roleUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));

  /**
   * Valida los roles asignados al usuario, y si tiene mas de un rol toma el primero.
   */
  if (typeof decodedToken.COLFACTURA_ROLE === "string") {
    return `${decodedToken.COLFACTURA_ROLE}`;
  } else if (typeof decodedToken.COLFACTURA_ROLE === "object") {
    return `${decodedToken.COLFACTURA_ROLE[0]}`;
  }
}

/**
 * Obtener el el iduser del usuario logueado, para poder obtener el rol del usuario.
 *
 * @method
 * @param {}
 */
export function idUser() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.nameid}`;
  } else {
    return {};
  }
}

/**
 * Obtener la razonsocial de la empresa , seleccionada.
 *
 * @method
 * @param {}
 */
export function businessName() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.customer_name}`;
  } else {
    return {};
  }
}

/**
 * Obtener el nit d ela empresa seleccionada.
 *
 * @method
 * @param {}
 */
export function nitCompany() {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (decodedToken) {
    return `${decodedToken.customer_value}`;
  } else {
    return {};
  }
}

/**
 * Obtener si el usuario logueado puede ver los reportes de Siigo.
 * @param {}
 * @returns
 */
export function isSiigoEnterprise() {
  return hasPrivilegeEnterprise("COLFACTURA_CONTABILIDAD_SIIGO");
}

/**
 * Obtener si el usuario logueado tiene un privilegio especifico.
 * @param {string} privilege Nombre del privilegio
 * @returns boolean
 */
function hasPrivilegeEnterprise(privilege) {
  const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
  if (!!decodedToken) {
    const privileges = decodedToken?.COLFACTURA_PRIVILEGE ?? [];

    const hasPrivilege = privileges.find((c) => c === privilege);

    if (!!hasPrivilege) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
