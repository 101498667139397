import React, { Component } from "react";
import { connect } from "react-redux";

import InvoiceGrid from "../invoice/invoice-grid.view";

//import InvoiceReceived from "../invoice/invoice-received.view";
import { CreateDocumentView } from "../document/createDocumenView";
import { UpdateDocumentView } from "../document/updateDocumentView";

import BulkUpload from "./bulkUpload/view/bulk-upload.view";
import DocumentRevision from "./documentRevision/document-revision.view";
import CustomTabs from "../../components/tabs/tabs.component";

import { cleanEmailDianAction } from "../../actions/generalAction";

import { getNoteDetailAction } from "../../actions/invoiceActions";

import {
  clearAuthenticationcodeAction,
  setDataAutenticacionOauthAction,
  setMostrarRegistroAutenticacionOautheMailDiferenteAction,
  changeStateUrlAuthentication,
} from "../../actions/invoiceReceivedActions";

import { getCompanyAction } from "../../actions/basicDataActions";
import { getDocumentTypeEntepriseByIdAction } from "../../actions/configAction";
import { loadNoteDocumentDataAction } from "../../actions/documentAction";

import {
  changeShowInvoiceCreationAction,
  changeStepAction,
} from "../../actions/invoiceActions";

import isEmpty from "../../utils/isEmpty";
import "../../App.css";
import "../../styles/invoice.css";

import { getDataNote } from "../../helpers/dataDocumentSaved";
import { IntegrationManage } from "../documentintegration/integrationManage";
import { isIntegrationEnterprise } from "../../actions/authActions";
import { ReceivedManage } from "../documentreceived/receivedManage";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      showCreate: false,
      showGrid: true, //Cambiar a true
      showEdit: false,
      showBulkUpload: false,
      objHead: null,
      editInvoiceStatus: false,
      showInvoiceCreationStatus: false,
      urlParams: props.history.location,
      tabIndex: 0,
      showDetailNote: false,
      dataNote: null,
      invoices: [],
      invoice: null,
      email: null,
      codigoAutenticacion: null,
      isAuthenticated: false,
      limpiarcodigoautenticacion: false,
      typeNote: "",
    };
  }

  componentDidMount() {
    this.autenticacionOauth();
    // this.props.cleanErrorAction();
    this.props.cleanEmailDianAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.generalReducer.error &&
      nextProps.generalReducer.error !== state.error
    )
      update.error = nextProps.generalReducer.error;

    if (
      !isEmpty(nextProps.invoiceReducer.showInvoiceCreationStatus) &&
      nextProps.invoiceReducer.showInvoiceCreationStatus !==
        state.showInvoiceCreationStatus
    ) {
      if (nextProps.invoiceReducer.showInvoiceCreationStatus === true) {
        update.showGrid = false;
        update.showCreate = true;
        update.showEdit = false;
        update.editInvoiceStatus = false;
      }
      update.showInvoiceCreationStatus =
        nextProps.invoiceReducer.showInvoiceCreationStatus;
    }

    if (
      !!nextProps.invoiceReducer.invoices &&
      nextProps.invoiceReducer.invoices !== state.invoices
    ) {
      update.invoices = nextProps.invoiceReducer.invoices;
    }

    if (
      !!nextProps.invoiceReceivedReducer.limpiarcodigoautenticacion !==
      state.limpiarcodigoautenticacion
    ) {
      update.limpiarcodigoautenticacion =
        nextProps.invoiceReceivedReducer.limpiarcodigoautenticacion;
    }

    return Object.keys(update).length ? update : null;
  }

  componentWillUnmount() {
    // this.props.cleanErrorAction();
    this.props.changeShowInvoiceCreationAction(false);
    this.props.cleanEmailDianAction();
  }

  setTabIndex(value) {
    this.setState({
      ...this.state,
      tabIndex: value,
    });
  }

  setDocumentTypeId(value) {
    this.setState({
      ...this.state,
      documentTypeId: value,
    });
  }

  empresaId() {
    return JSON.parse(localStorage.getItem("dataCompany")).companyId;
  }

  userId() {
    return "";
  }

  autenticacionOauth() {
    this.props.getCompanyAction();
    let params = this.getParams(this.state.urlParams);
    let codigoAutorizacionOauth = params.get("code" || "");
    let email = params.get("email" || "");

    if (codigoAutorizacionOauth) {
      if (codigoAutorizacionOauth === "NoAthorize") {
        // el Emial registrado no coincide con el de la DIAN
        this.props.setMostrarRegistroAutenticacionOautheMailDiferenteAction(
          true
        );
        this.setTabIndex(2);
        this.props.history.push("/invoice");
        this.setState({
          ...this.state,
          codigoAutenticacion: codigoAutorizacionOauth,
          isAuthenticated: true,
        });
      } else {
        // Guardar en la base de datos
        // this.setTabIndex(1);
        this.props.setDataAutenticacionOauthAction({
          email: email,
          codigoAutenticacion: codigoAutorizacionOauth,
        });
        this.props.changeStateUrlAuthentication(codigoAutorizacionOauth);

        this.setState({
          ...this.state,
          email: email,
          codigoAutenticacion: codigoAutorizacionOauth,
          isAuthenticated: true,
        });

        this.props.history.push("/invoice");
      }
    }
  }

  getParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.limpiarcodigoautenticacion === true) {
      this.setState({
        ...this.state,
        codigoAutenticacion: null,
      });

      this.props.clearAuthenticationcodeAction(false);
    }
  }

  handleBack = () => {
    this.setState({
      ...this.state,
      showGrid: true,
      showCreate: false,
      showEdit: false,
      showBulkUpload: false,
      editInvoiceStatus: false,
    });
    this.props.changeStepAction(0);
  };

  handleCreate = () => {
    this.setState({
      ...this.state,
      showGrid: false,
      showCreate: true,
      showEdit: false,
      showBulkUpload: false,
      editInvoiceStatus: false,
    });
  };

  /**
   * Muestra vista para edición de factura,
   * carga información de factura en redux
   * @param {*} invoice
   */
  handleEdit = (invoice, typeNote) => {
    const document = getDataNote(invoice);
    this.props.loadNoteDocumentDataAction(document);

    this.setState({
      ...this.state,
      showGrid: false,
      showCreate: false,
      showEdit: true,
      showBulkUpload: false,
      invoice: invoice,
      typeNote: typeNote,
    });
  };

  handleBulkUpload = () => {
    this.setState({
      ...this.state,
      showGrid: false,
      showCreate: false,
      showEdit: false,
      showBulkUpload: true,
      editInvoiceStatus: false,
    });
  };

  /**
   * Consulta detalle Nota (credito, debito)
   * @param {*} id Identificador documento
   */
  handleShowDetailNote = (id) => {
    this.props.getNoteDetailAction(id, this.readResponseGetNote);
  };

  /**
   * Lee respuesta consulta Nota, carga información en state
   * @param {*} data Información Nota
   */
  readResponseGetNote = (data) => {
    this.setState({
      showDetailNote: true,
      dataNote: data,
    });
  };

  /**
   * Cierra panel revicion nota
   */
  handleBackRevision = () => {
    this.setState({
      showDetailNote: false,
      dataNote: null,
    });
  };

  getTabs = () => {
    if (isIntegrationEnterprise()) {
      return [
        {
          title: "Emitidas",
          component: (
            <InvoiceGrid
              HandleCreate={this.handleCreate}
              HandleBulkUpload={this.handleBulkUpload}
              HandleEdit={this.handleEdit}
              Change={Math.random()}
              Empresaid={this.empresaId()}
              Userid={this.userId()}
              Editada={false}
              handleShowDetailNote={this.handleShowDetailNote}
            />
          ),
        },
        // {
        // 	title: "Recibidas",
        // 	component: (
        // 		<InvoiceReceived
        // 			Empresaid={this.empresaId()}
        // 			Userid={this.userId()}
        // 			Email={this.state.email}
        // 			CodigoAutenticacion={this.state.codigoAutenticacion}
        // 		/>
        // 	),
        // },
        {
          title: "Recibidas",
          component: <ReceivedManage />,
        },
        {
          title: "Editadas",
          component: (
            <InvoiceGrid
              HandleCreate={this.handleCreate}
              HandleEdit={this.handleEdit}
              Change={Math.random()}
              Empresaid={this.empresaId()}
              Userid={this.userId()}
              Editada={true}
              HandleBulkUpload={this.handleBulkUpload}
              handleShowDetailNote={this.handleShowDetailNote}
            />
          ),
        },
        {
          title: "Integraciones",
          component: <IntegrationManage />,
        },
      ];
    } else {
      return [
        {
          title: "Emitidas",
          component: (
            <InvoiceGrid
              HandleCreate={this.handleCreate}
              HandleBulkUpload={this.handleBulkUpload}
              HandleEdit={this.handleEdit}
              Change={Math.random()}
              Empresaid={this.empresaId()}
              Userid={this.userId()}
              Editada={false}
              handleShowDetailNote={this.handleShowDetailNote}
            />
          ),
        },
        // {
        // 	title: "Recibidas",
        // 	component: (
        // 		<InvoiceReceived
        // 			Empresaid={this.empresaId()}
        // 			Userid={this.userId()}
        // 			Email={this.state.email}
        // 			CodigoAutenticacion={this.state.codigoAutenticacion}
        // 		/>
        // 	),
        // },
        {
          title: "Recibidas",
          component: <ReceivedManage />,
        },
        {
          title: "Editadas",
          component: (
            <InvoiceGrid
              HandleCreate={this.handleCreate}
              HandleEdit={this.handleEdit}
              Change={Math.random()}
              Empresaid={this.empresaId()}
              Userid={this.userId()}
              Editada={true}
              HandleBulkUpload={this.handleBulkUpload}
              handleShowDetailNote={this.handleShowDetailNote}
            />
          ),
        },
      ];
    }
  };

  render() {
    return (
      <>
        {this.state.showDetailNote ? (
          <DocumentRevision
            data={this.state.dataNote}
            handleBackRevision={this.handleBackRevision}
          />
        ) : (
          <>
            {this.state.showGrid && (
              <CustomTabs
                tabs={this.getTabs()}
                tabIndex={
                  (this.state.email !== null && 1) ||
                  (!!this.state.isAuthenticated && 1)
                }
              />
            )}

            {this.state.showCreate && (
              <CreateDocumentView
                HandleBack={this.handleBack}
                Empresaid={this.empresaId()}
                Userid={this.userId()}
                HandleBulkUpload={this.handleBulkUpload}
              />
            )}

            {this.state.showEdit && (
              <UpdateDocumentView
                HandleBack={this.handleBack}
                Empresaid={this.empresaId()}
                objInvoice={this.state.objHead}
                invoiceBD={this.state.invoice}
                typenote={this.state.typeNote}
              />
            )}

            {this.state.showBulkUpload && (
              <BulkUpload
                cancelOption={this.handleBack}
                Empresaid={this.empresaId()}
                handleCreate={this.handleBack}
                HandleBulkUpload={this.handleBulkUpload}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  generalReducer: state.generalReducer,
  invoiceReducer: state.invoiceReducer,
  basicDataReducer: state.basicDataReducer,
  invoiceReceivedReducer: state.invoiceReceivedReducer,
});

export default connect(mapStateToProps, {
  cleanEmailDianAction,
  setDataAutenticacionOauthAction,
  setMostrarRegistroAutenticacionOautheMailDiferenteAction,
  getCompanyAction,
  getDocumentTypeEntepriseByIdAction,
  getNoteDetailAction,
  changeStateUrlAuthentication,
  clearAuthenticationcodeAction,
  loadNoteDocumentDataAction,
  changeShowInvoiceCreationAction,
  changeStepAction,
})(Invoice);
