import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import RcCalendar from "../datepicker/rcdatepicker.component";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
});

export const ModalDates = (
  cleanFilterDate,
  dates,
  filterDate,
  handleDateChange
) => {
  const classes = useStyles();

  const { dateEnd, dateStart } = dates;

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={5} sm={5} className={classes.alignCenter}>
          Desde
        </Grid>
        <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
        <Grid item lg={6} sm={6} className={classes.alignCenter}>
          Hasta
        </Grid>
      </Grid>

      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} sm={6} className={classes.alignCenter}>
          <div className="lineDivision"></div>
          <div className="spacingInvoiceMini"></div>
        </Grid>
        <Grid item lg={6} sm={6} className={classes.alignCenter}>
          <div className="lineDivision"></div>
          <div className="spacingInvoiceMini"></div>
        </Grid>
      </Grid>

      <Grid container justify="center" alignItems="center">
        <Grid item lg={5} sm={5}>
          <RcCalendar
            disablePast={false}
            disableFuture={true}
            value={dateStart}
            className={"colorCalendarRC"}
            id={"txtdateStart"}
            onChange={(date) => handleDateChange("dateStart", date)}
          />
        </Grid>
        <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
        <Grid item lg={6} sm={6}>
          <RcCalendar
            disablePast={false}
            disableFuture={true}
            value={dateEnd}
            className={"colorCalendarRC"}
            id={"txtdateEnd"}
            onChange={(date) => handleDateChange("dateEnd", date)}
          />
        </Grid>
      </Grid>

      <div className="spacingInvoice"></div>
      <div className="lineDivision"></div>
      <div className="spacingInvoice"></div>
      <Grid container>
        <Grid item lg={6} sm={6} className={classes.alignCenter}>
          <button className="btnFilterGray" onClick={cleanFilterDate}>
            Limpiar Filtro
          </button>
        </Grid>
        <Grid item lg={6} sm={6} className={classes.alignCenter}>
          <button className="btnFilterBlue" onClick={filterDate}>
            Filtrar
          </button>
        </Grid>
      </Grid>
    </>
  );
};
