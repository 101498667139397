import { DEFAULTRESPONSE } from "../config/config";
import { ErrorAlert } from "../helpers/alert.helpers";
import generateId from "../utils/generateId";

import {
  calculateValuesItem,
  calculateTotal,
} from "../utils/calculateInvoiceValues";
import { types } from "../types/types";

var chargesDocument = [];
var discountsDocument = [];
var roundValuesStatus = true;
var totalData = {};

/**
 * Escucha cambios en  valores de cada item cargado en carro de compras,
 * actualiza listado detalle documento y recalcula totales de factura
 * @param {object} item
 */
export const updateCartAction = (item) => async (dispatch) => {
  try {
    let newdetail = getCartToLocalStorage();
    newdetail = newdetail.filter((d) => d.identificador !== item.identificador);
    newdetail.push(item);
    let totals = calculateTotal(
      newdetail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error actualizando carrito,", err);
    ErrorAlert("Upss!!", "No se ha podido actualizar carrito");
  }
};

/**
 * Actualiza cambios en la descripción de un articulo
 * y actualiza listado en memoria
 * @param {object} item //Información item
 */
export const updateDescriptionItemCartAction = (item) => async (dispatch) => {
  try {
    let newdetail = getCartToLocalStorage();
    newdetail = newdetail.filter((d) => d.identificador !== item.identificador);
    newdetail.push(item);
    refreshLocalStorage(newdetail);

    totalData = {
      ...totalData,
      detalleDocumento: newdetail,
    };

    //Carga en redux información factura,
    // con descripcion actualizada
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totalData,
    });
  } catch (err) {
    console.error("Error actualizando descripción de item carrito,", err);
    ErrorAlert("Upss!!", "No se ha podido actualizar descripción de item");
  }
};

/**
 * @action
 * @description Obtiene objecto con detalle del total de a factura
 */
export const getTotalAction = () => async (dispatch) => {
  try {
    const myDetail = getCartToLocalStorage();
    let totals = calculateTotal(
      myDetail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error calculando totales de factura,", err);
    ErrorAlert("Upss!!", "No se ha podido calcular el total de la factura");
  }
};

/**
 * @action
 * @description Actualiza estado para mostrar o ocultar catalogo
 * @param {boolean} status
 */
export const setStatusShowCartAction = (status) => (dispatch) => {
  dispatch({
    type: types.SHOW_CART_INVOICE,
    payload: status,
  });
};

/**
 * @event
 * @description Calcula valores iniciales, agrega listado de item seleccionados
 * a local storage, los carga en Redux
 * @param {Array} listItems
 */
export const addArticlesToCartAction = (listItems) => async (dispatch) => {
  try {
    let totals = addArticlesToCart(listItems);
    totalData = totals;

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Errro agregando articulos a factura", err);
    localStorage.clear("itemsInCart");
  }
};

/**
 * Agrega listado de articulos a localstorage
 * @param {Array<object>} listItems Listado articulos
 */
export const addArticlesToCart = (listItems) => {
  let newDetailDocument = [];
  if (!!listItems && listItems.length > 0) {
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      let detailItem = calculeInitialValuesArticle(item);
      newDetailDocument.push(detailItem);
    }
  }

  //Agrega Listado a varible en memoria
  let myDetailDocument = getCartToLocalStorage();
  myDetailDocument = myDetailDocument.concat(newDetailDocument);

  let totals = calculateTotal(
    myDetailDocument,
    chargesDocument,
    discountsDocument,
    roundValuesStatus
  );

  //Actualiza lista descuentos y cargos en memoria
  discountsDocument = totals.otrosdescuentos;
  chargesDocument = totals.cargos;
  //Actualiza detalle
  refreshLocalStorage(totals.detalleDocumento);

  return totals;
};

/**
 * @function
 * @description Genera objeto y calcula valores inciales para articulo cargado en carrito
 * @param {object} item
 */
export function calculeInitialValuesArticle(item) {
  try {
    let itemDetail = {
      ...item,
      identificador: generateId(),
      codigo: item.codigo,
      descripcion: item.nombrearticulo,
      nombrearticulo: item.nombrearticulo,
      imagen: item.imagen,
      color: item.color,
      cantidad: item.cantidad ?? 1,
      valorunitario: item.valor,
      valorNeto: item.neto,
      valorNetoConfirmado: item.neto,
      valorNetoCatalogo: item.neto,
      costo: item.costo ?? 0,
      valoreditable: item.precioeditable ?? false,
      porcentajedescuento: item.porcentajedescuento ?? 0,
      valordescuento: item.valordescuento ?? 0,
      tipodescuento: item.tipodescuento ?? 2,
      dianunidadmedidaid: item.dianunidadmedidaid,
      articuloid: item.id,
      subtotal: 0,
      totaldescuento: 0,
      totaliva: 0,
      totalinc: 0,
      total: 0,
      documentoid: 0,
      categorias:
        item?.adcategoriaarticulo === null ||
        item?.adcategoriaarticulo === undefined
          ? []
          : item?.adcategoriaarticulo,
      porcentajeiva: item?.valoriva,
      porcentajeinc: item?.valorinc,

      idretfte: item.idretfte ?? 0,
      retfte: item?.retfte === null ? null : item?.retfte,
      baseretfte: 0,
      tarifaretfte: 0,
      valorretfte: 0,

      idretica: item.idretica ?? 0,
      retica: item?.idretica === null ? null : item?.retica,
      baseretica: 0,
      tarifaretica: 0,
      valorretica: 0,

      idretiva: item.idretiva ?? 0,
      retiva: item?.idretiva === null ? null : item?.retiva,
      baseretiva: 0,
      tarifaretiva: 0,
      valorretiva: 0,

      valordescuentocomercial: 0,
      prorrateo: 0,
      totaldescuentoreal: 0,
      subtotalreal: 0,
      totalivareal: 0,
      totalincreal: 0,
      totalreal: 0,
      dateAdded: item.dateAdded ?? new Date(),
      aplicaregalo: item.aplicaregalo ?? false,
      esregalo: item.esregalo ?? false,
      asumeresponsabilidad: item.asumeresponsabilidad ?? "",
      valorreferencia: item.valorreferencia ?? null,
    };

    //Redondeo por defecto true
    calculateValuesItem(itemDetail, true);
    return itemDetail;
  } catch (err) {
    console.error("Error calculando valores de articulo", err);
    throw err;
  }
}

/**
 * @function
 * @description Actualiza carrito en local storage
 * @param {Array<object>} listItems
 */
function refreshLocalStorage(listItems) {
  //Actualiza local stotage
  localStorage.removeItem("itemsInCart");
  localStorage.setItem("itemsInCart", JSON.stringify(listItems));
}

/**
 * @function
 * @description Obtiene listado cargado en local storage
 */
function getCartToLocalStorage() {
  const temp = localStorage.getItem("itemsInCart");
  const list =
    temp === null ? [] : JSON.parse(localStorage.getItem("itemsInCart"));
  return list;
}

/**
 * @action
 * @description Elimina item de carro
 * @param {object} item
 */
export const deleteItemToCartAction = (item) => async (dispatch) => {
  try {
    //Elimina de detalle documento
    let detalleTemp = getCartToLocalStorage();
    detalleTemp = detalleTemp.filter(
      (d) => d.identificador !== item.identificador
    );

    //Actualiza totales de factura
    let totals = calculateTotal(
      detalleTemp,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error eliminando articulo de factura,", err);
    ErrorAlert("Upss!!", "No se ha podido eliminar articulo de  factura");
  }
};

//BEGIN  Seccion Cargos

/**
 * @action
 * @description Agrega cargo a lista en memoria
 * @param {object} charge
 */
export const addChargeToInvoiceAction = (charge) => async (dispatch) => {
  try {
    chargesDocument.push(charge);

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();
    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error agregando cargo a documento,", err);
    ErrorAlert("Upss!!", "Ha ocurrido un error agregando cargo a documento");
  }
};

/**
 * @action
 * @description Actualiza cargo de documento (Factura, Nota)
 * @param {object} charge
 */
export const updateChargeToInvoiceAction = (charge) => async (dispatch) => {
  try {
    let chargesTemp = chargesDocument.filter(
      (c) => c.identificador !== charge.identificador
    );
    chargesTemp.push(charge);
    chargesDocument = chargesTemp;

    dispatch({
      type: types.UPDATE_CHARGE_DOCUMENT,
      payload: DEFAULTRESPONSE.SuccessResponse,
    });

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();
    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error actualizando cargo de documento,", err);
    ErrorAlert("Upss!!", "Ha ocurrido un error actualizando cargo a documento");
  }
};

/**
 * @action
 * @description Actualiza cargo global  de documento (Factura, Nota)
 * @param {object} charge
 */
export const updateGlobalChargeToInvoiceAction = (charge) => async (
  dispatch
) => {
  try {
    let chargesTemp = chargesDocument.filter(
      (c) => c.identificador !== charge.identificador
    );
    chargesTemp.push(charge);
    chargesDocument = chargesTemp;

    dispatch({
      type: types.UPDATE_CHARGE_DOCUMENT,
      payload: DEFAULTRESPONSE.SuccessResponse,
    });

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();
    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error actualizando cargo global de documento,", err);
    ErrorAlert(
      "Upss!!",
      "Ha ocurrido un error actualizando cargo global a documento"
    );
  }
};

/**
 * @action
 * @description Elimina cargo de documento
 * @param {object} charge
 */
export const deleteChargeToInvoiceAction = (charge) => async (dispatch) => {
  try {
    let chargesTemp = chargesDocument.filter(
      (c) => c.identificador !== charge.identificador
    );
    chargesDocument = chargesTemp;

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();
    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error eliminando cargo de documento,", err);
    ErrorAlert("Upss!!", "Ha ocurrido un error eliminando cargo de documento");
  }
};

/**
 * @action
 * @description Obtiene listado de cargos agregados a  un documento
 */
export const getChargesInvoiceAction = () => async (dispatch) => {
  dispatch({
    type: types.GET_CHARGES_DOCUMENT,
    payload: chargesDocument,
  });
};

/**
 * @action
 * @description Carga Objeto cargo en redux para edición
 * @param {object} charge
 */
export const loadChargeInvoiceAction = (charge) => async (dispatch) => {
  dispatch({
    type: types.LOAD_CHARGE_DOCUMENT,
    payload: charge,
  });
};

/**
 * @action
 * @description Limpia objeto cargo de redux
 */
export const clearLoadedChargeInvoiceAction = () => async (dispatch) => {
  dispatch({
    type: types.UPDATE_CHARGE_DOCUMENT,
    payload: null,
  });

  dispatch({
    type: types.LOAD_CHARGE_DOCUMENT,
    payload: null,
  });
};

/**
 * @action
 * @description Limpia objeto cargo de redux, se inicializa
 */
export const clearLoadedChargeInvoiceActionAll = () => async (dispatch) => {
  dispatch({
    type: types.UPDATE_CHARGE_DOCUMENT,
    payload: null,
  });

  dispatch({
    type: types.LOAD_CHARGE_DOCUMENT,
    payload: null,
  });

  dispatch({
    type: types.GET_CHARGES_DOCUMENT,
    payload: null,
  });

  chargesDocument = [];
};

/**
 * @action
 * @description Aigna estado de modal para gestión cargo (abrir, cerrar)
 */
export const openModalChargeAction = (status) => async (dispatch) => {
  dispatch({
    type: types.OPEN_MODAL_CHARGE,
    payload: status,
  });
};

//END Seccion Cargos

//BEGIN Seccion Descuentos

/**
 * @action
 * @description Agrega descuento a lista en memoria,
 * actualiza listado en Redux
 * @param {object} discount
 */
export const addDiscountToInvoiceAction = (discount) => async (dispatch) => {
  try {
    discountsDocument.push(discount);

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();

    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error agregando descuento a documento,", err);
    ErrorAlert(
      "Upss!!",
      "Ha ocurrido un error agregando descuento a documento"
    );
  }
};

/**
 * @action
 * @description Actualiza descuento de documento (Factura, Nota)
 * @param {object} discount
 */
export const updateDiscountToInvoiceAction = (discount) => async (dispatch) => {
  try {
    let discountsTemp = discountsDocument.filter(
      (c) => c.identificador !== discount.identificador
    );
    discountsTemp.push(discount);
    discountsDocument = discountsTemp;

    dispatch({
      type: types.UPDATE_DISCOUNT_DOCUMENT,
      payload: DEFAULTRESPONSE.SuccessResponse,
    });

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();
    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error actualizando descuento de documento,", err);
    ErrorAlert("Upss!!", "Ha ocurrido un error descuento cargo a documento");
  }
};

/**
 * @action
 * @description Elimina descuento de documento
 * @param {object} discount
 */
export const deleteDiscountToInvoiceAction = (discount) => async (dispatch) => {
  try {
    let discuntsTemp = discountsDocument.filter(
      (c) => c.identificador !== discount.identificador
    );
    discountsDocument = discuntsTemp;

    //Actualiza totales de factura
    let detalleTem = getCartToLocalStorage();
    let totals = calculateTotal(
      detalleTem,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error eliminando descuento de documento,", err);
    ErrorAlert(
      "Upss!!",
      "Ha ocurrido un error eliminando descuento de documento"
    );
  }
};

/**
 * @action
 * @description Obtiene listado de cargos agregados a  un documento
 */
export const getDiscountsInvoiceAction = () => async (dispatch) => {
  dispatch({
    type: types.GET_DISCOUNTS_DOCUMENT,
    payload: discountsDocument,
  });
};

/**
 * @action
 * @description Carga objeto descuento en redux para edición
 * @param {object} discount
 */
export const loadDiscountInvoiceAction = (discount) => async (dispatch) => {
  dispatch({
    type: types.LOAD_DISCOUNT_DOCUMENT,
    payload: discount,
  });
};

/**
 * @action
 * @description Limpia objeto cargo de redux
 */
export const clearLoadedDiscountInvoiceAction = () => async (dispatch) => {
  dispatch({
    type: types.UPDATE_DISCOUNT_DOCUMENT,
    payload: null,
  });

  dispatch({
    type: types.LOAD_DISCOUNT_DOCUMENT,
    payload: null,
  });
};

/**
 * @action
 * @description Limpia objeto cargo de redux, iniciliazar
 */
export const clearLoadedDiscountInvoiceActionAll = () => async (dispatch) => {
  dispatch({
    type: types.UPDATE_DISCOUNT_DOCUMENT,
    payload: null,
  });

  dispatch({
    type: types.LOAD_DISCOUNT_DOCUMENT,
    payload: null,
  });

  dispatch({
    type: types.GET_DISCOUNTS_DOCUMENT,
    payload: null,
  });

  discountsDocument = [];
};

/**
 * @action
 * @description Asigna estado de modal para gestión cargo (abrir, cerrar)
 */
export const openModalDiscountAction = (status) => async (dispatch) => {
  dispatch({
    type: types.OPEN_MODAL_DISCOUNT,
    payload: status,
  });
};

//End Seccion Descuentos

/**
 * Carga estado  de carrito en redux (valido invalido) dependiedo de si existen o no
 * valores negativos en totales
 * @param {boolean} status Estado de carrto
 */
export const validateTotalDocumentAction = (status) => async (dispatch) => {
  dispatch({
    type: types.DOCUMENT_VALID,
    payload: status,
  });
};

/**
 * Actualiza estado, redondeo de  valores decimales, recalcula valores de factura
 * @param {*} status
 */
export const changeStatusRoundValuesAction = (status) => async (dispatch) => {
  roundValuesStatus = status;
  dispatch({
    type: types.ROUND_VALUES_STATUS,
    payload: roundValuesStatus,
  });

  //Actualiza totales de factura
  let detailTemp = getCartToLocalStorage();

  //Recalcula valores cuando cambia estado de redondeo
  let newItemsAfterRound = [];
  detailTemp.forEach((item) => {
    let newData = item;
    calculateValuesItem(newData, status);

    newItemsAfterRound.push(newData);
  });

  let totals = calculateTotal(
    newItemsAfterRound,
    chargesDocument,
    discountsDocument,
    roundValuesStatus
  );
  totalData = totals;

  //Actualiza lista descuentos y cargos en memoria
  discountsDocument = totals.otrosdescuentos;
  chargesDocument = totals.cargos;

  //Actualiza detalle
  refreshLocalStorage(totals.detalleDocumento);

  //Actualiza items en Redux
  dispatch({
    type: types.GET_DOCUMENT_DETAIL,
    payload: totals.detalleDocumento,
  });

  //Actualiza totales en Redux
  dispatch({
    type: types.GET_TOTAL_DETAIL,
    payload: totals,
  });
};

/**
 * Calcula valores totales para la edición de una factura
 */
export const calculeTotalNoteAction = (
  detailDocument,
  charges,
  discounts,
  round
) => async (dispatch) => {
  try {
    //Carga estado de redondeo en Redux
    roundValuesStatus = round;
    dispatch({
      type: types.ROUND_VALUES_STATUS,
      payload: round,
    });

    discountsDocument = [];
    chargesDocument = [];

    let totals = calculateTotal(detailDocument, charges, discounts, round);
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error calculando totales al editar la factura,", err);
    ErrorAlert("Upss!!", "No se ha podido calcular el total de la factura");
  }
};

/**BEGIN REGION RETENCIONES */

/**
 * Escucha cambios en  reteFuente  de un articulo y recalcula
 * valores para articulo
 * @param {object} item Información articulo
 * @param {object} retfte Información reteFuente
 */
export const updateItemReteFteAction = (item, retfte) => async (dispatch) => {
  try {
    //Aplica Rete- Fuente a item
    let newItem = {
      ...item,
      idretfte: retfte.id,
      retfte: retfte,
    };

    //Actualiza storage
    let newdetail = getCartToLocalStorage();
    newdetail = newdetail.filter((d) => d.identificador !== item.identificador);
    newdetail.push(newItem);

    //Recalcula Totales
    let totals = calculateTotal(
      newdetail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error en calculo de ret-fuente,", err);
    ErrorAlert("Upss!!", "No se ha podido calcular Rete-Fuente");
  }
};

/**
 * Escucha cambios en  reteFuente  y calcula valores para todos los articulos cargados en carrito
 * @param {object} retfte Información tarifa retefuente
 */
export const updateAllItemsReteFteAction = (retfte) => async (dispatch) => {
  try {
    //Lee articulos en storage
    let detail = getCartToLocalStorage();

    //Aplica rete-Fuente a listado de items
    detail = detail.map((item) => {
      return {
        ...item,
        idretfte: retfte.id,
        retfte: retfte,
      };
    });

    //Recalcula Totales
    let totals = calculateTotal(
      detail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error(
      "Error en calculo de ret-fuente para listado articulos,",
      err
    );
    ErrorAlert(
      "Upss!!",
      "No se ha podido calcular Rete-Fuente para listado de articulos"
    );
  }
};

/**
 * Elimina rete fuente aplicada a un articulo
 * @param {object} retfte Información tarifa retefuente
 */
export const deleteReteFteItemAction = (item) => async (dispatch) => {
  try {
    //Actualiza estado reteFuente
    let newItem = {
      ...item,
      idretfte: 0,
    };

    //Actualiza storage
    let newdetail = getCartToLocalStorage();
    newdetail = newdetail.filter((d) => d.identificador !== item.identificador);
    newdetail.push(newItem);

    //Recalcula Totales
    let totals = calculateTotal(
      newdetail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error eliminando retefuente de articulo, ", err);
    ErrorAlert("Upss!!", "No se ha podido eliminar rete-Fuente de articulo");
  }
};

/**
 * Escucha cambios en  reteICA  y calcula valores para todos los articulos cargados en carrito
 * @param {object} retfte Información tarifa reteICA
 */
export const updateAllItemsReteIcaAction = (reteIca) => async (dispatch) => {
  try {
    //Lee articulos en storage
    let detail = getCartToLocalStorage();

    //Aplica rete-Fuente a listado de items
    detail = detail.map((item) => {
      return {
        ...item,
        idretica: reteIca.id,
        retica: reteIca,
      };
    });

    //Recalcula Totales
    let totals = calculateTotal(
      detail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error en calculo de ret-ica para listado articulos,", err);
    ErrorAlert(
      "Upss!!",
      "No se ha podido calcular Rete-ICA para listado de articulos"
    );
  }
};

/**
 * Elimina Rete-ICA para listado de articulos en carrito
 */
export const deleteAllItemsReteIcaAction = () => async (dispatch) => {
  try {
    //Lee articulos en storage
    let detail = getCartToLocalStorage();

    //Aplica rete-Fuente a listado de items
    detail = detail.map((item) => {
      return {
        ...item,
        idretica: 0,
        retica: null,
      };
    });

    //Recalcula Totales
    let totals = calculateTotal(
      detail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error eliminando rete-ica a listado articulos,", err);
    ErrorAlert("Upss!!", "Ha ocurrido un erro eliminado Rete-ICA");
  }
};

/**
 * Escucha cambios en  reteIVA  y calcula valores para todos los articulos cargados en carrito
 * @param {object} retfte Información tarifa reteIVA
 */
export const updateAllItemsReteIvaAction = (reteIva) => async (dispatch) => {
  try {
    //Lee articulos en storage
    let detail = getCartToLocalStorage();

    detail = detail.map((item) => {
      return {
        ...item,
        idretiva: !!reteIva?.id ? reteIva?.id : 0,
        retiva: reteIva,
      };
    });

    //Recalcula Totales
    let totals = calculateTotal(
      detail,
      chargesDocument,
      discountsDocument,
      roundValuesStatus
    );
    totalData = totals;

    //Actualiza lista descuentos y cargos en memoria
    discountsDocument = totals.otrosdescuentos;
    chargesDocument = totals.cargos;
    //Actualiza detalle
    refreshLocalStorage(totals.detalleDocumento);

    //Actualiza items en Redux
    dispatch({
      type: types.GET_DOCUMENT_DETAIL,
      payload: totals.detalleDocumento,
    });

    //Actualiza totales en Redux
    dispatch({
      type: types.GET_TOTAL_DETAIL,
      payload: totals,
    });
  } catch (err) {
    console.error("Error en calculo de ret-iva para listado articulos,", err);
    ErrorAlert(
      "Upss!!",
      "No se ha podido calcular Rete-IVA para listado de articulos"
    );
  }
};

/**END REGION RETENCIONES */
