import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";


import { Grid, makeStyles } from '@material-ui/core';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { blue } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";

import { getTemplateBulkUploadClientAction } from '../../../actions/configAction';
import { convertBase64ToExcel } from '../../invoice/bulkUpload/helpers/excel.helpers';
import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import { SIZE_MAX_BULKLOAD_CLIENT } from '../../../config/config';



const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        backgroundcolor: "red",
    },
    paper: {
        maxHeight: 500,
        backgroundColor: "#393939",
        border: "none",
        borderRadius: "5px",
        overflowY: "auto",
        overflowX: "hidden",
        color: "rgba(216,216,216,0.5)",
        outline: 0,
    },
    input: {
        display: "none",
    },
    title: {
        color: theme.palette.thirdColor,
        fontSize: 25,
        fontWeight: "bold",
        textAlign: "center",
    },
    contentText: {
        color: "white",
        fontsize: "5",
        textAlign: "left",
        padding: "10px 80px",
    },
    contentTextCarga: {
        color: "white",
        fontsize: "5",
        textAlign: "center",
    },
    btnEnviar: {
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        width: "100%",
        height: "100%",
        cursor: 'pointer',
        opacity: 0,
    },
    divFile: {
        position: "relative",
        width: "100%",
        height: "50px",
        borderRadius: "4px",
        backgroundColor: "#16B1F3",
        border: "#16B1F3",
        color: "#FFFFFF",
        fontSize: "17px",
        fontWeight: "600",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: blue[800],
        },
        padding: "1px",
        marginTop: "10px !important",
    },
    listStyle: {
        float: "left",
        color: theme.palette.thirdColor, //azul
        paddingRight: "6px",
    },
    dialog: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        "& .MuiPaper-root": {
            backgroundColor: "#393939",
            color: theme.palette.primaryColor,
            height: '464px',
            width: '1000px',
            borderRadius: '5px'
        },
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.thirdColor,
    },

}));

const DialogTitle = ({ children, onClose }) => {
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography>
            <Typography variant="h6">{children}</Typography>
            {
                onClose
                    ? (
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                    : null
            }
        </MuiDialogTitle>
    );
};

export const StartLoadModal = ({
    open,
    handleClose,
    handleShowErrors,
    handleProcessData
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        titleBulkLoad,
        contentBulkLoadOne,
        contentBulkLoadTwo,
        contentBulkLoadThree,
        contentBulkLoadFour
    } = useSelector(state => state.menuReducer.menuCustomer);
    const { getTemplate: templateLoadCustomer, loadingTemplateCustomer } = useSelector(state => state.configReducer);


    /**
     * Descarga plantilla
     */
    const handleDonwloadTemplate = () => {
        if (templateLoadCustomer === null) {
            dispatch(getTemplateBulkUploadClientAction(completeDonwload));
        } else {
            completeDonwload(templateLoadCustomer);
        }
    };

    const completeDonwload = (template) => {
        convertBase64ToExcel(template);
    }


    /**
    * Validaciones pre-cargue y cargue de columnas y validaciones de tipo de datos del excel
    * @param {*} e 
    */
    const handleValidateTemplate = (e) => {

        const file = e.target.files[0];
        //Extensiones admitidas
        const admitedExtentions = ['xls', 'xlsx', 'xlsm', 'csv'];
        //Tamaño Màximo permitido del archivo
        const sizeMax = SIZE_MAX_BULKLOAD_CLIENT;

        //Validacion de formatos
        if (!validateExtention(file, admitedExtentions)) {
            handleShowErrors('Solo se aceptan los siguientes formatos ' + admitedExtentions)
            return;
        } else if (!validateSize(file, sizeMax)) {
            //validacion de Peso Màximo
            handleShowErrors('Peso Máximo ' + sizeMax + 'Kb')
            return;
        }

        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

            //Procesar contenido del archivo Excel
            handleProcessData(data);
        };
        reader.readAsBinaryString(file);
        handleClose();
    }

    /**
     * Validación de la extensión
     * @param {*} file 
     * @param {*} admitedExtentions 
     */
    const validateExtention = (file, admitedExtentions) => {
        let ext = file.name.split(".");
        let extention = ext[ext.length - 1];
        return admitedExtentions.includes(extention);
    }

    /**
    * Validación del tamaño del archivo
    * 
    * @param {*} file 
    * @param {*} sizeMax 
    */
    const validateSize = (file, sizeMax) => {
        let fileSize = file.size / 1024; // in KB
        return fileSize <= sizeMax;
    }



    return (

        <Dialog
            fullWidth={true}
            maxWidth="md"
            className="classes.root"
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <center>
                <div className={classes.paper}>
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}>
                        <span className={classes.title}>
                            {titleBulkLoad}
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            <div className={classes.contentText}>
                                {contentBulkLoadOne}
                                <br></br>
                                <br></br>
                                <div>
                                    <Typography className={classes.listStyle} display="inline">
                                        {"1. "}
                                    </Typography>
                                    <Typography display="inline" >
                                        {contentBulkLoadTwo}
                                    </Typography>
                                </div>
                                <br></br>
                                <div>
                                    <Typography className={classes.listStyle} display="inline">
                                        {"2. "}
                                    </Typography>
                                    <Typography display="inline" >
                                        {contentBulkLoadThree}
                                    </Typography>
                                </div>
                                <br></br>
                                <div>
                                    <Typography className={classes.listStyle} display="inline">
                                        {"3. "}
                                    </Typography>
                                    <Typography display="inline" >
                                        {contentBulkLoadFour}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContentText>
                        <Box>
                            <div style={{ color: "#FFFFFF" }}>
                                <div className="spacingInvoice"></div>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>

                                        <ButtonPrimary
                                            text="Descargar Plantilla"
                                            type="submit"
                                            onClick={handleDonwloadTemplate}
                                            loading={loadingTemplateCustomer}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <center>
                                            <div className={classes.divFile}>
                                                <p id="texto">Cargar Plantilla Masiva</p>
                                                <input
                                                    type="file"
                                                    className={classes.btnEnviar}
                                                    accept=".csv,.xlsx,.xls,.xlsm"
                                                    onChange={handleValidateTemplate}
                                                />
                                            </div>
                                        </center>
                                    </Grid>
                                </Grid>
                                <br></br>
                            </div>
                        </Box>
                    </DialogContent>
                </div>
            </center>
        </Dialog>
    )
}

