/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { WarningAlert } from "../../../helpers/alert.helpers";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import InputForm from "../../../components/input/input.component";
import SwitchForm from "../../../components/switch/switch.component.js";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import CustomAutoComplete from "../../../components/autocomplete/autocomplete.component";

import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import ModalTax from "./modalTax.component";

import {
  regexOnlyNumbers,
  regexSpace,
  regexLettersAndNumbers,
} from "../../../helpers/customRegex.hepers";

import {
  clearLoadArticleAction,
  createAndAddArticleAction,
  createArticleAction,
  getUnitMeasureAction,
  updateArticleAction,
  createAndAddProductAction,
} from "../../../actions/articleActions";

import { getCategoryAction } from "../../../actions/categoryActions";
import {
  cleanMenuComponentAction,
  getTaxDianAction,
} from "../../../actions/configAction";

import { bytesToSize } from "../../../utils/general";
import isEmpty from "../../../utils/isEmpty";
import { regexDecimal } from "../../../helpers/customRegex.hepers";

import {
  DIGITS_AFTER_DECIMAL_DOC,
  imageSize,
  URL_IMAGES,
} from "../../../config/config";

import "../article/article.css";
import { roundDecimalValue } from "../../../utils/managementNumber";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    outline: 0,
  },
  paperModalImage: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    width: 400,

    outline: 0,
  },
  Iconclose: {
    color: theme.palette.thirdColor,
    fontSize: 15,
    padding: 0,
  },
  buttonClose: {
    textAlign: "end",
  },
});

class CreateArticleDocSup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txtArticleName: "",
        txtArticleDescription: "",
        txtArticlePrice: "0",
        chkArticlePrice: false,
        txtArticleIva: "0",
        chkArticleIva: false,
        txtArticleInc: "0",
        chkArticleInc: false,
        chkArticleTax: false,
        txtArticleNetWorth: "0",
        txtArticleTotal: "0",
        txtArticleCost: "",
        ddlArticleUnitMeasure: null,
        ddlArticleCategory: [],
        txtArticleReference: "",
        chkArticleFavorite: false,
        txtArticleBarcode: "",
        imgArticleSrc: "",
        categories: [],
        chkAplicaRegalo: false,
      },
      getCategories: [],
      valueCategory: [],
      unitMeasure: [],
      loadingSaveArticle: false,
      loadObjectArticle: {},
      editArticleStatus: false,
      stateModal: false,
      openModalIVA: false,
      openModalINC: false,
      image: null,
      menuArticle: {},
      imgArticleSrcOld: "",
      taxIvaList: [],
      taxIncList: [],
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
    this.cleanForm = this.cleanForm.bind(this);
  }

  componentDidMount() {
    this.props.getUnitMeasureAction();
    this.props.getCategoryAction();
    this.props.getTaxDianAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.articleReducer.requestUnitMeasure &&
      nextProps.articleReducer.requestUnitMeasure.responseCode !==
        state.unitMeasure
    )
      update.unitMeasure = nextProps.articleReducer.requestUnitMeasure.result;

    if (
      !!nextProps.categoryReducer.getCategories &&
      nextProps.categoryReducer.getCategories !== state.getCategories
    )
      update.getCategories = nextProps.categoryReducer.getCategories.filter(
        (c) => c.id !== 0
      );

    if (
      !!nextProps.menuReducer.menuArticle &&
      nextProps.menuReducer.menuArticle !== state.menuArticle
    )
      update.menuArticle = nextProps.menuReducer.menuArticle;

    if (
      !!nextProps.articleReducer.loadArticleObject &&
      nextProps.articleReducer.loadArticleObject !== state.loadArticleObject
    )
      update.loadArticleObject = nextProps.articleReducer.loadArticleObject;

    if (
      !isEmpty(nextProps.articleReducer.editArticleStatus) &&
      nextProps.articleReducer.editArticleStatus !== state.editArticleStatus
    )
      update.editArticleStatus = nextProps.articleReducer.editArticleStatus;

    if (
      !isEmpty(nextProps.articleReducer.loadingSaveArticle) &&
      nextProps.articleReducer.loadingSaveArticle !== state.loadingSaveArticle
    )
      update.loadingSaveArticle = nextProps.articleReducer.loadingSaveArticle;

    if (nextProps.configReducer.taxIvaList !== state.taxIvaList) {
      update.taxIvaList = nextProps.configReducer.taxIvaList.filter(
        (tax) => tax.id !== 3 && tax.id !== 5
      );
    }
    if (nextProps.configReducer.taxIncList !== state.taxIncList)
      update.taxIncList = nextProps.configReducer.taxIncList;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.unitMeasure.length > 0 &&
      this.state.form.ddlArticleUnitMeasure === null
    ) {
      this.setState({
        form: {
          ...this.state.form,
          ddlArticleUnitMeasure: this.state.unitMeasure.find((u) => u.id === 4),
        },
      });
    }
    if (prevState.loadArticleObject !== this.state.loadArticleObject) {
      if (this.state.editArticleStatus === true)
        this.loadInfoForm(this.state.loadArticleObject);
      else this.cleanForm();
    }
  }

  componentWillUnmount() {
    this.props.clearLoadArticleAction();
  }

  /**
   * Sincroniza nuevo valor de  input con state
   * @param {object} e Evento
   */
  syncChanges = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  //Sincroniza valores decimales
  syncChangeDecimalValue = ({ target }) => {
    let { value } = { ...target };
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [target.name]: value,
      },
    }));
  };

  /**
   * Sincroniza Autocomplete con state
   * @param {object} event evento
   * @param {object} values nevo valor state
   * @param {string} id Identificador autocomplete
   */
  syncAutoCompleteChanges(event, values, id) {
    event.preventDefault();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: values,
      },
    }));
  }

  /**
   * Sincroniza checkBox con state
   * @param {object} e evento
   */
  syncCheckChange(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const checked = e.target.checked;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: checked,
      },
    }));
  }

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];
    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        ddlArticleCategory: value,
      },
    }));
  };

  /**
   * Regex solo numeros
   * @param {*} e
   */
  validateOnlyNumber(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexOnlyNumbers, "");

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Regex Alfanumerico con espacios
   * @param {*} e
   */
  validateSpace(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexSpace, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos, solo letras y numeros
   * @param {*} e Evento
   */
  validateLettersAndNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexLettersAndNumbers, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Recalcula valor articulo con nuevo valor IVA
   * @param {*} value Valor IVA
   */
  confirmIVA = (value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        txtArticleIva: value,
      },
      openModalIVA: false,
    }));

    const iva = value;
    const inc = this.state.form.txtArticleInc;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(iva, inc, price, this.state.form.chkArticleTax);
  };

  /**
   * Recalcula valor articulo con nuevo valor INC
   * @param {*} value Valor INC
   */
  confirmINC = (value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        txtArticleInc: value,
      },
      openModalINC: false,
    }));

    const iva = this.state.form.txtArticleIva;
    const inc = value;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(iva, inc, price, this.state.form.chkArticleTax);
  };

  /**
   * Calcula valores de articulo dependendo de la configuración de usuario,
   * aplica o no impuesto
   */
  calculateValues(pIVA, pINC, pPrice, pIncludesTax) {
    const iva = isEmpty(pIVA) ? 0 : parseFloat(pIVA);
    const inc = isEmpty(pINC) ? 0 : parseFloat(pINC);
    const price = isEmpty(pPrice) ? 0 : parseFloat(pPrice);
    let ivavalue = price * (iva / 100);
    let incvalue = price * (inc / 100);
    let digits = DIGITS_AFTER_DECIMAL_DOC;

    if (pIncludesTax === true) {
      let taxTotal = iva + inc;
      let totalWithoutTax = price / (1 + taxTotal / 100);

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleNetWorth: roundDecimalValue(totalWithoutTax, digits),
          txtArticleTotal: roundDecimalValue(pPrice, digits),
        },
      }));
    }

    if (pIncludesTax === false) {
      let total = price + ivavalue + incvalue;

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleNetWorth: roundDecimalValue(price, digits),
          txtArticleTotal: roundDecimalValue(total, digits),
        },
      }));
    }
  }

  /**
   * Abre modal para registro de IVA,
   * cuando aplica (checkeo apiaca IVA)
   * @param {object} e
   */
  handleOpenModalIVA(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalIVA: true,
      });
    }
  }

  /**
   * Evento check, cambio aplica iva
   * @param {*} e
   */
  changeCheckIVA = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalIVA: true,
        form: {
          ...prevState.form,
          chkArticleIva: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleIva: 0,
          chkArticleIva: checked,
        },
      }));

      let iva = 0;
      let inc = this.state.form.txtArticleInc;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(iva, inc, price, this.state.form.chkArticleTax);
    }
  };

  /**
   * Evento check, cambio aplica iva
   * @param {*} e
   */
  changeCheckINC = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalINC: true,
        form: {
          ...prevState.form,
          chkArticleInc: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleInc: 0,
          chkArticleInc: checked,
        },
      }));

      let iva = this.state.form.txtArticleIva;
      let inc = 0;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(iva, inc, price, this.state.form.chkArticleTax);
    }
  };

  /**
   * Cierra modal para registro de IVA,
   * actualiza estado para visualizacion
   */
  closeModalIVA() {
    this.setState({
      openModalIVA: false,
    });
  }

  /**
   * Abre modal para registro de IVA,
   * cuando aplica (checkeo apiaca IVA)
   * @param {object} e
   */
  handleOpenModalINC(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalINC: true,
      });
    }
  }

  /**
   * Cierra modal para registro de IVA,
   * actualiza estado para visualizacion
   */
  closeModalINC() {
    this.setState({
      openModalINC: false,
    });
  }

  /**
   * Abre modal Imagen
   */
  handleOpen = () => {
    this.setState({
      stateModal: true,
    });
  };

  /**
   * Cierra modal Imagen
   */
  handleClose = () => {
    this.setState({
      stateModal: false,
    });
  };

  /**
   * Guarda o actualiza información de un articulo
   * @param {Object} e Evento
   */
  saveArticle = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      let data = {};
      let categories = this.state.form.ddlArticleCategory.map((index, unit) => {
        return { categoriaid: index };
      });

      data = {
        id:
          this.state.editArticleStatus === true
            ? this.state.loadArticleObject.id
            : 0,
        imagen: this.state.form.imgArticleSrc,
        nombrearticulo: this.state.form.txtArticleName,
        valor:
          this.state.form.txtArticlePrice !== ""
            ? parseFloat(this.state.form.txtArticlePrice)
            : 0,
        precioeditable: this.state.form.chkArticlePrice,
        aplicaiva: this.state.form.chkArticleIva,
        valoriva:
          this.state.form.txtArticleIva !== ""
            ? parseFloat(this.state.form.txtArticleIva)
            : 0, //Valor default
        aplicainc: this.state.form.chkArticleInc,
        valorinc:
          this.state.form.txtArticleInc !== ""
            ? parseFloat(this.state.form.txtArticleInc)
            : 0, //Valor default
        incluyeimpuesto: this.state.form.chkArticleTax,
        neto:
          this.state.form.txtArticleNetWorth !== ""
            ? parseFloat(this.state.form.txtArticleNetWorth)
            : 0,
        total:
          this.state.form.txtArticleTotal !== ""
            ? parseFloat(this.state.form.txtArticleTotal)
            : 0,
        costo:
          this.state.form.txtArticleCost !== ""
            ? parseFloat(this.state.form.txtArticleCost)
            : null,
        dianunidadmedidaid: parseInt(
          this.state.form.ddlArticleUnitMeasure.value
        ),
        codigo:
          this.state.editArticleStatus === true
            ? this.state.loadArticleObject.codigo
            : this.state.form.txtArticleReference,
        descripcion: this.state.form.txtArticleDescription,
        favorito: this.state.form.chkArticleFavorite,
        codigobarras: this.state.form.txtArticleBarcode,
        adcategoriaarticulo: categories,
        color:
          this.state.editArticleStatus === true
            ? this.state.loadArticleObject.color
            : "",
        tipodescuento: null,
        descuento: 0,
        activo: true,
        imgArticleSrcOld: this.state.imgArticleSrcOld,
        aplicaregalo: this.state.form.chkAplicaRegalo,
      };

      if (this.state.editArticleStatus === true) {
        this.props.updateArticleAction(
          data,
          this.props.type ?? 0,
          this.completeSaveUpdate
        );
      } else {
        if (this.props.origin === "cart-hooks") {
          this.props.createAndAddProductAction(
            data,
            this.cleanForm,
            this.props.setOpenModalProduct
          );
          //this.props.setOpenModalProduct(false);
        } else {
          this.props.createArticleAction(
            data,
            this.props.type ?? 0,
            this.completeSaveUpdate
          );
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * Limpia y cierra formulario despues de guardar o actualizar
   */
  completeSaveUpdate = () => {
    this.cleanForm();
    if (!!this.props.handleCloseCollapse) {
      this.props.handleCloseCollapse();
    }
  };

  /**
   * Limpia formulario, despues de guardado o actualización
   */
  cleanForm() {
    this.setState({
      form: {
        txtArticleName: "",
        txtArticleDescription: "",
        txtArticlePrice: 0,
        chkArticlePrice: false,
        txtArticleIva: 0,
        chkArticleIva: false,
        txtArticleInc: 0,
        chkArticleInc: false,
        chkArticleTax: false,
        txtArticleNetWorth: 0,
        txtArticleTotal: 0,
        txtArticleCost: "",
        ddlArticleUnitMeasure: null,
        ddlArticleCategory: [],
        txtArticleReference: "",
        chkArticleFavorite: false,
        txtArticleBarcode: "",
        imgArticleSrc: "",
        categories: [],
      },
      openModalIVA: false,
      openModalINC: false,
      imgArticleSrcOld: "", //Limpiar valor
    });
    this.validator.hideMessages();
    this.props.clearLoadArticleAction();
  }

  /**
   * Carga información de articulo en formulario para edición
   * @param {object} object Información articulo para edición
   */
  loadInfoForm(item) {
    let categories = [];
    item.adcategoriaarticulo.map((index, item) => {
      return categories.push(index.categoriaid);
    });

    const unitMeasure = this.state.unitMeasure.find(
      (c) => c.id === item.dianunidadmedidaid
    );

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        imgArticleSrc: item.imagen && URL_IMAGES + item.imagen,
        txtArticleName: item.nombrearticulo,
        txtArticleDescription: item.descripcion,
        txtArticlePrice: item.valor,
        chkArticlePrice: item.precioeditable,
        txtArticleIva: item.valoriva,
        chkArticleIva: item.aplicaiva,
        txtArticleInc: item.valorinc,
        chkArticleInc: item.aplicainc,
        chkArticleTax: item.incluyeimpuesto,
        txtArticleNetWorth: item.neto,
        txtArticleTotal: item.total,
        txtArticleCost: item.costo ?? "",
        ddlArticleUnitMeasure: !!unitMeasure ? unitMeasure : null,
        ddlArticleCategory: categories,
        txtArticleReference: item.codigo,
        chkArticleFavorite: item.favorito,
        txtArticleBarcode: item.codigobarras,
        chkAplicaRegalo: item.aplicaregalo,
      },
      imgArticleSrcOld: item.imagen,
    }));
  }

  onChange(e) {
    e.preventDefault();
    try {
      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
        var objetFile = files[0];
        if (!/\.(jpg|png)$/i.test(objetFile.name)) {
          WarningAlert("Formato no permitido. Formatos permitidos .jpg o .png");
          this.handleClose();
        } else if (objetFile.size >= imageSize) {
          WarningAlert(
            `El tamaño de la imagen no puede se superior a ${bytesToSize(
              imageSize
            )}`
          );
          this.handleClose();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({ image: reader.result });
          };
          reader.readAsDataURL(files[0]);
          this.handleOpen();
        }
      }
      e.target.value = null;
    } catch (error) {
      console.log("Error cargando imagen", error);
    }
  }

  onClickSave = async () => {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          imgArticleSrc: canvas,
        },
      }));
    }
    this.handleClose();
  };

  setEditorRef = (editor) => (this.editor = editor);

  validateFieldsOnBlur(e, name) {
    e.preventDefault();
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name);
    }
  }

  /**
   * Limpia valor cero por defecto de caja de texto
   * @param {} e Evento
   */
  onClickNotZero = (e, name) => {
    let value = e.target.value;
    if (value !== null && value !== undefined) {
      value = value?.replace(regexDecimal, "");
      if (value === 0 || value === "0") {
        this.setState((prev) => ({
          form: {
            ...prev.form,
            [name]: "",
          },
        }));
      }
    }
  };

  /**
   * Cierra modal IVA, Recalcula con valor cero
   */
  handleCloseModalIVA = () => {
    this.setState((prev) => ({
      openModalIVA: false,
    }));

    this.confirmIVA(0);
  };

  /**
   * Cierra modal INC, Recalcula con valor cero
   */
  handleCloseModalINC = () => {
    this.setState((prev) => ({
      openModalINC: false,
    }));

    this.confirmINC(0);
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    let categoryOption = [];
    if (!!this.state.getCategories) {
      categoryOption = this.state.getCategories.map((item) => {
        return {
          ...item,
          value: item.id,
          text: item.nombre,
          color: item.color,
        };
      });
    }

    return (
      <>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <InputForm
              name="txtArticleReference"
              value={this.state.form.txtArticleReference}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateLettersAndNumbers(e);
              }}
              maxLength={50}
              label={"Referencia *"}
              nameValidator={"Referencia"}
              onBlur={(e) => this.validateFieldsOnBlur(e, "Referencia")}
              disabled={this.state.editArticleStatus}
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputForm
              name="txtArticleName"
              maxLength={250}
              value={this.state.form.txtArticleName}
              label={"Descripción *"}
              validator={this.validator}
              validateOptions={"required|min:3"}
              onChange={this.syncChanges}
            />
          </Grid>

          {/* <Grid item lg={12} xs={12}>
            <InputForm
              name="txtArticleDescription"
              maxLength={2000}
              value={this.state.form.txtArticleDescription}
              multiline={true}
              rowsMax={2}
              label={"Descripción *"}
              validator={this.validator}
              validateOptions={"required"}
              onChange={this.syncChanges}
            />
          </Grid> */}

          <Grid item lg={4} xs={12}>
            <InputForm
              name="txtArticlePrice"
              value={this.state.form.txtArticlePrice}
              label={"Precio"}
              validator={this.validator}
              validateOptions={"required"}
              maxLength={21}
              format={true}
              onClick={this.onClickNotZero}
              onChange={this.syncChangeDecimalValue}
              onBlur={(e) => {
                this.calculateValues(
                  this.state.form.txtArticleIva,
                  this.state.form.txtArticleInc,
                  this.state.form.txtArticlePrice,
                  this.state.form.chkArticleTax
                );
              }}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <SwitchForm
              name={"chkArticleTax"}
              value={this.state.form.chkArticleTax}
              checked={this.state.form.chkArticleTax}
              titleOn="¿El precio incluye impuesto?"
              onChange={(e) => {
                this.syncCheckChange(e);
                this.calculateValues(
                  this.state.form.txtArticleIva,
                  this.state.form.txtArticleInc,
                  this.state.form.txtArticlePrice,
                  e.target.checked
                );
              }}
              txtTooltip={
                <TooltipMessage
                  title={this.state.menuArticle.titleTax}
                  message={this.state.menuArticle.messageTax}
                  botton={this.state.menuArticle.bottonTax}
                  href={this.state.menuArticle.hrefTax}
                />
              }
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SwitchForm
              name={"chkArticleIva"}
              value={this.state.form.chkArticleIva}
              checked={this.state.form.chkArticleIva}
              titleOn="¿Este producto aplica IVA? "
              text={
                isEmpty(this.state.form.txtArticleIva)
                  ? 0
                  : this.state.form.txtArticleIva
              }
              maxLength={5}
              onChange={this.changeCheckIVA}
              txtTooltip={
                <TooltipMessage
                  title={this.state.menuArticle.titleIVA}
                  message={this.state.menuArticle.messageIVA}
                  botton={this.state.menuArticle.bottonIVA}
                  href={this.state.menuArticle.hrefIVA}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <InputForm
              name="txtArticleNetWorth"
              label={"Valor sin impuesto"}
              value={this.state.form.txtArticleNetWorth}
              disabled={true}
              onChange={() => {}}
              format={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputForm
              name="txtArticleTotal"
              label={"Valor total con impuestos"}
              value={this.state.form.txtArticleTotal}
              onChange={() => {}}
              format={true}
              disabled
            />
          </Grid>

          {/* <Grid item lg={6} xs={12}>
            <InputForm
              name="txtArticleCost"
              label={"Costo"}
              value={this.state.form.txtArticleCost}
              validator={this.validator}
              maxLength={21}
              validateOptions={"isNumber"}
              onChange={this.syncChangeDecimalValue}
              format={true}
            />
          </Grid> */}
          <Grid item lg={6} xs={12}>
            <CustomAutoComplete
              name="ddlArticleUnitMeasure"
              label={"Unidad de medida * "}
              value={this.state.form.ddlArticleUnitMeasure}
              options={this.state.unitMeasure}
              textNoOption="No se encontraron resultados."
              onChange={(event, value) =>
                this.syncAutoCompleteChanges(
                  event,
                  value,
                  "ddlArticleUnitMeasure"
                )
              }
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>
        </Grid>

        <Grid container justify="flex-end">
          <Grid item xs={12} md={4}>
            <ButtonPrimary
              text={this.state.menuArticle.saveButton}
              loading={this.state.loadingSaveArticle}
              type={"button"}
              onClick={(e) => this.saveArticle(e)}
            />
          </Grid>
        </Grid>

        {/* Nuevo modal para IVA */}
        <ModalTax
          open={this.state.openModalIVA}
          label={"Seleccione el valor del IVA"}
          options={this.state.taxIvaList}
          confirmTax={this.confirmIVA}
          title={"Valor % Impuesto IVA"}
          onClose={this.handleCloseModalIVA}
        />

        {/* Nuevo modal para INC */}
        <ModalTax
          open={this.state.openModalINC}
          label={"Seleccione el valor del INC"}
          options={this.state.taxIncList}
          confirmTax={this.confirmINC}
          title={"Valor % Impuesto INC"}
          onClose={this.handleCloseModalINC}
        />
      </>
    );
  }
}

CreateArticleDocSup.propTypes = {
  getUnitMeasureAction: PropTypes.func.isRequired,
  createArticleAction: PropTypes.func.isRequired,
  updateArticleAction: PropTypes.func.isRequired,
  clearLoadArticleAction: PropTypes.func.isRequired,
  cleanMenuComponentAction: PropTypes.func.isRequired,
  createAndAddArticleAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  articleReducer: state.articleReducer,
  categoryReducer: state.categoryReducer,
  menuReducer: state.menuReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  getUnitMeasureAction,
  clearLoadArticleAction,
  createArticleAction,
  updateArticleAction,
  cleanMenuComponentAction,
  createAndAddArticleAction,
  getCategoryAction,
  createAndAddProductAction,
  getTaxDianAction,
})(withStyles(useStyles)(CreateArticleDocSup));
