import { DIGITS_AFTER_DECIMAL } from "../config/config";

import { GetUVTYear } from "./getUvtYear";

/**
 * * Calcula impuestos según el tipo especificado.
 *
 * @param {number} value - El valor base para el cálculo de impuestos.
 * @param {string} tipo - El tipo de impuesto a calcular (p. ej., 'IBUA', 'ICL', 'INPP').
 * @param {number} cantidad - La cantidad utilizada en el cálculo, dependiendo del tipo de impuesto.
 * @param {number} cantidadcc - La cantidad por centímetro cúbico, utilizado en el cálculo específico para 'ICL'.
 * @param {Array<number>} uvtList - Lista de valores de UVT (Unidad de Valor Tributario) para el cálculo específico de 'INPP'.
 *
 * @returns {string|number} - El monto calculado de impuestos o 0 si el tipo de impuesto no está soportado.
 *
 */
export const calculateTaxes = (value, tipo, cantidad, cantidadcc, uvtList) => {
  const taxCalculators = {
    IBUA: () => ((cantidad * value) / 100).toFixed(2),
    ICL: () => {
      const priceDrinkType = (cantidadcc * value) / 750;
      return (priceDrinkType * cantidad).toFixed(2);
    },
    INPP: () => {
      const uvtx5 = GetUVTYear(new Date().getFullYear(), uvtList) * 0.00005;
      const uvt = uvtx5.toLocaleString("en", {
        maximumFractionDigits: DIGITS_AFTER_DECIMAL,
      });
      return (cantidad * uvt).toFixed(2);
    },
  };

  const calculateFunction = taxCalculators[tipo];

  return calculateFunction ? calculateFunction() : 0;
};
