import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import { formatDate } from "../../utils/general";
import { FormatDecimal } from "../../components/common/formatDecimal";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: "rgba(255,255,255,0.11)",
		color: theme.palette.primaryColor,
		"& .MuiCardContent-root": {
			paddingBottom: 5,
			paddingTop: 0,
		},
		marginBottom: 12,
		fontSize: 14,
		borderRadius: 5,
	},
	header: {
		paddingBottom: 0,
		paddingTop: 10,
		"& .MuiCardHeader-action": {
			display: "flex",
		},
		"& .MuiCardHeader-root": {
			padding: 10,
		},
	},
	content: {
		fontSize: 12,
	},
	title: {
		color: theme.palette.primaryColor,
		fontSize: 16,
		fontWeight: 600,
	},
	icon: {
		color: theme.palette.primaryColor,
		fontSize: 25,
	},
	wordBreak: {
		wordBreak: "break-all",
	},
	payStatus: {
		color: "#2CC63E",
		fontWeight: 600,
		fontSize: 16,
		textAlign: "end",
		wordBreak: "break-all",
	},
	overdueStatus: {
		color: "#EC005F",
		fontWeight: 600,
		fontSize: 16,
		textAlign: "end",
		wordBreak: "break-all",
	},
	notPayStatus: {
		color: "#F5D13D",
		fontWeight: 600,
		fontSize: 16,
		textAlign: "end",
		wordBreak: "break-all",
	},
	annulmentStatus: {
		color: "#E292FF",
		fontWeight: 600,
		fontSize: 16,
		textAlign: "end",
		wordBreak: "break-all",
	},
}));

export const CustomerDocumentCard = ({
	numero,
	fecha,
	marcapagado,
	fechapago,
	favorito,
	razonsocial,
	anulacion,
	valorapagar,
}) => {
	const classes = useStyles();
	const [status, setStatus] = useState({
		status: "",
		class: "",
	});

	useEffect(() => {
		const statusData = getStatus(marcapagado, anulacion, fechapago);
		setStatus(statusData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marcapagado, anulacion, fechapago]);

	/**
	 * Obtiene estado de factura
	 * @param {*} marcapagado
	 * @param {*} anulacion
	 * @returns
	 */
	const getStatus = (marcapagado, anulacion, fechaParaPago) => {

		if (anulacion === true) {
			return {
				status: "Anulada",
				class: "annulmentStatus",
			};
		} else {
			if (marcapagado === true) {
				return {
					status: "Pagada",
					class: "payStatus",
				};
			} else if (!fechaParaPago){
				return {
					status: "Por Pagar",
					class: "notPayStatus",
				};
			}
			
			else if (
				new Date(fechaParaPago) >= new Date()
			) {
				return {
					status: "Por Pagar",
					class: "notPayStatus",
				};
			} else {
				return {
					status: "En Mora",
					class: "overdueStatus",
				};
			}
		}
	};

	return (
		<Card className={`${classes.root} animate__animated animate__fadeIn`}>
			<CardHeader
				className={classes.header}
				classes={{
					title: classes.title,
				}}
				action={
					favorito ? (
						<StarIcon className={classes.icon} />
					) : (
						<StarBorderIcon className={classes.icon} />
					)
				}
				title={razonsocial}
			/>
			<CardContent>
				<Grid container>
					<Grid item lg={8} xs={12}>
						{formatDate(fecha, "dd-MM-yyyy")}
					</Grid>
					<Grid item lg={4} xs={12} style={{ textAlign: "end" }}>
						<span className={classes[status.class]}>
							<FormatDecimal prefix={"$"} value={valorapagar} />
						</span>
					</Grid>
					<Grid item lg={8} xs={12}>
						{`Factura: ${numero}`}
					</Grid>
					<Grid item lg={4} xs={12} style={{ textAlign: "end" }}>
						<span className={classes[status.class]}>{status.status}</span>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
