import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { Grid, IconButton } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InputForm from "../../../components/input/input.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";

import { addNewCustomFieldAction } from "../../../actions/customFiledAction";

import { WarningAlert } from "../../../helpers/alert.helpers";
import generateId from "../../../utils/generateId";
import { useFormComplete } from "../../../hooks/useFormComplete";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiIconButton-colorSecondary": {
			color: theme.palette.primaryColor,
		},
		width: "100%",
		overflowY: "auto",
	},
	icon: {
		color: theme.palette.primaryColor,
	},
	iconChecked: {
		color: theme.palette.primaryColor,
	},
	progress: {
		textAlign: "center",
		width: "100%",
	},
	button: {},
	label: {
		flexDirection: "column",
		fontSize: 10,
		color: theme.palette.primaryColor,
	},
	formCheck: {
		margin: 0,
		"& .MuiIconButton-root": {
			padding: 0,
		},
		marginTop: -8,
	},
	"& .MuiFormControlLabel-root": {
		margin: 0,
	},
}));

export const CustomFielForm = () => {
	const classes = useStyles();
	const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
	const [, forceUpdate] = useState();
	const dispatch = useDispatch();
	const {
		values,
		handleInputChange,
		handleCheckChange,
		reset,
	} = useFormComplete({
		id: "",
		name: "",
		mustSave: false,
	});
	const { name, mustSave } = values;
	const { loadingSaveField, customFieldsList } = useSelector(
		(state) => state.customFieldReducer
	);

	const onSaveData = (e) => {
		e.preventDefault();
		const formValid = validator.current.allValid();
		if (!formValid) {
			validator.current.showMessages();
			forceUpdate(1);
		} else {
			const fieldExist = customFieldsList.find(
				(c) =>
					c.codigo.toLowerCase().replaceAll(" ", "") ===
					name.toLowerCase().replaceAll(" ", "")
			);
			if (!!fieldExist) {
				WarningAlert(
					"Campo existente",
					`Ya agregaste un campo con el nombre ${name}`
				);
				return;
			}

			let data = {
				id: generateId(),
				codigo: name,
				guardar: mustSave,
				valor: "",
			};
			dispatch(addNewCustomFieldAction(data, reset));
		}
	};

	return (
		<>
			<Grid container>
				<Grid item lg={6} xs={12}>
					<InputForm
						name={"name"}
						label={"Nombre nuevo dato"}
						maxLength={50}
						value={name}
						validator={validator.current}
						validateOptions={"required"}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item lg={6} xs={12}>
					<FormControlLabel
						style={{ marginTop: 15 }}
						control={
							<Checkbox
								name="mustSave"
								checked={mustSave}
								className={classes.formCheck}
								onChange={handleCheckChange}
								icon={
									<IconButton
										classes={{ root: classes.button, label: classes.label }}
										variant="raised"
										disableRipple={true}
									>
										<LockOpenIcon className={classes.iconChecked} />
									</IconButton>
								}
								checkedIcon={
									<IconButton
										classes={{ root: classes.button, label: classes.label }}
										variant="raised"
										disableRipple={true}
									>
										<LockIcon className={classes.icon} />
									</IconButton>
								}
							/>
						}
					/>
				</Grid>
			</Grid>
			<Grid container style={{ justifyContent: "flex-end" }}>
				<Grid item lg={6} xs={6}>
					<ButtonPrimary
						text={"Listo!"}
						type={"button"}
						onClick={onSaveData}
						loading={loadingSaveField}
					/>
				</Grid>
			</Grid>
		</>
	);
};
