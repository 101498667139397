import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

import EquivalentIcon from "../../../images/icons/equivalentDocument.svg";
import StatusDocument from "./util/StatusDocument";

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: "normal",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyItems: "center",
    maxWidth: "1027px",
    margin: "2rem 0 0 0.5rem",
    padding: "0 2rem",
    width: "100%",
    [theme.breakpoints.down(1051)]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down(800)]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      padding: "0",
      marginLeft: "0",
    },
  },
  cardContainer: {
    margin: "0.7rem",
    maxWidth: "100%",
    width: "335px",
  },
  card: {
    display: "flex",
    backgroundColor: "#FFFFFF2E",
    height: "162px",
  },
  cardContent: {
    padding: "8px 8px 13px 16px",
  },
  cardIcon: {
    objectFit: "initial",
    height: 32,
    margin: "16px 8px 0 16px",
    width: 32,
  },
  textId: {
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20.08px",
    margin: "2px 0 7px 0px",
    textAlign: "left",
  },
  textName: {
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22.59px",
    letterSpacing: "0.32px",
    minHeight: "61.1px",
    padding: "0.5rem 0px",
  },
}));

const RenderTypesEquivalentDoc = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {data &&
        data.length > 0 &&
        data.map((eq, index) => (
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className={classes.cardContainer}
            key={index}
          >
            <Card className={classes.card}>
              <div>
                <CardMedia
                  className={classes.cardIcon}
                  component="img"
                  image={EquivalentIcon}
                  alt={"EquivalentIcon"}
                  height={32}
                />
              </div>
              <div>
                <CardContent className={classes.cardContent}>
                  <Typography
                    className={classes.textName}
                    variant="h6"
                    component="h2"
                  >
                    {"POS ELECTRÓNICO"}
                  </Typography>
                  <Typography
                    className={classes.textId}
                    variant="body2"
                    component="p"
                  >
                    {`ID: ${eq.testidposelectronico}`}
                  </Typography>
                  <StatusDocument word={eq.activo} key={index} />
                </CardContent>
              </div>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default RenderTypesEquivalentDoc;
