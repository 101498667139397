import React from "react";

import { useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { updateItemTotalAction } from "../../../../actions/documentCartAction";

import AutoIncrementSmall from "../../../../components/autoIncrement/autoIncrementSmall.component";

import {
  decrementQuantityItem,
  incrementQuantityItem,
  validateQuantityItem,
} from "../../../../helpers/quantityUnitValue";

import { convertStringToDecimal } from "../../../../utils/convertStringToDecimal";

export const ItemQuantitySection = ({
  handleUpdateForm,
  itemDoc,
  quantity,
  unitValue,
}) => {
  const dispatch = useDispatch();

  /**
   * * Maneja el cambio de cantidad de un artículo.
   * @param {Object} event - El evento de cambio.
   */
  const handleChangeQuatity = ({ target }) => {
    const value = validateQuantityItem(unitValue, target.value);
    handleUpdateForm({
      quantity: value,
    });
  };

  /**
   * * Maneja el evento onBlur del campo de cantidad.
   * * Actualiza la cantidad del artículo y el total del artículo.
   * * Si la nueva cantidad es menor o igual a cero, se establece la cantidad en 1.
   * @param {Event} event - El evento onBlur.
   */
  const handleOnBlurQuantity = ({ target }) => {
    let newQuantity = convertStringToDecimal(target.value);
    if (newQuantity <= 0) {
      newQuantity = 1;
      handleUpdateForm({
        quantity: newQuantity,
      });
    }

    let newdata = {
      ...itemDoc,
      cantidad: newQuantity,
    };
    dispatch(updateItemTotalAction(newdata));
  };

  /**
   * * Incrementa la cantidad del artículo y actualiza el formulario y el total del artículo.
   */
  const handleIncrementQuantity = () => {
    let newQuantity = incrementQuantityItem(unitValue, quantity);
    handleUpdateForm({
      quantity: newQuantity,
    });

    let newdata = {
      ...itemDoc,
      cantidad: newQuantity,
    };
    dispatch(updateItemTotalAction(newdata));
  };

  /**
   * * Decrementa la cantidad de un ítem y actualiza el formulario y el total del ítem.
   */
  const handleDecrementQuantity = () => {
    let newQuantity = decrementQuantityItem(quantity);
    handleUpdateForm({
      quantity: newQuantity,
    });

    let newdata = {
      ...itemDoc,
      cantidad: newQuantity,
    };
    dispatch(updateItemTotalAction(newdata));
  };

  return (
    <Grid item lg={6} xs={12}>
      <AutoIncrementSmall
        handleDecrement={handleDecrementQuantity}
        handleIncrement={handleIncrementQuantity}
        maxLength={21}
        name={"quantity"}
        onBlur={handleOnBlurQuantity}
        onChange={handleChangeQuatity}
        value={quantity}
      />
    </Grid>
  );
};
