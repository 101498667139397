import React from "react";
import PropTypes from "prop-types";
import { InputAdornment } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import { useSelector } from "react-redux";

import CustomTooltip from "../tooltip/tooltip.component";

import {
  validateNumberFormat,
  validateNumberPorcentaje,
} from "../../utils/managementNumber";
import {
  DIGITS_AFTER_DECIMAL,
  DIGITS_AFTER_DECIMAL_DOC,
  DIGITS_BEFORE_DECIMAL,
} from "../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  margin: {
    margin: theme.spacing(1),
  },
  endAdornment: {
    color: theme.palette.primaryColor,
  },
  noMargin: {
    margin: 0,
  },
  noMarginLeftRight: {
    margin: 0,
    marginBottom: 10,
    marginTop: 10,
  },
  error: {
    color: "#f44336",
    fontSize: "0.8035714285714286rem",
    marginTop: -5,
    marginLeft: 10,
  },
}));

function NumberFormatCustom({ inputRef, onChange, name, ...other }) {
  const { cartDocumentSupport } = useSelector(
    (state) => state.documentCartReducer
  );

  const handleChange = (values) => {
    const validNumber = validateNumberFormat(
      values.value ?? "",
      DIGITS_BEFORE_DECIMAL,
      cartDocumentSupport ? DIGITS_AFTER_DECIMAL_DOC : DIGITS_AFTER_DECIMAL
    );
    onChange({
      target: {
        name: name,
        value: validNumber,
      },
    });
  };

  const excludeFieldsNegative = ["txtArticleTotal", "txtArticleNetWorth"];

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      isNumericString={false}
      thousandSeparator=","
      decimalSeparator="."
      prefix="$"
      allowNegative={excludeFieldsNegative.includes(name)}
      allowedDecimalSeparators={["."]}
    />
  );
}

const NumberFormatPorcentaje = ({ inputRef, onChange, name, ...other }) => {
  const handleChange = (values) => {
    const validNumber = validateNumberPorcentaje(values.value ?? "");

    onChange({
      target: {
        name: name,
        value: validNumber,
      },
    });
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      isNumericString={false}
      thousandSeparator=","
      decimalSeparator="."
      suffix="%"
      allowNegative={false}
      allowedDecimalSeparators={["."]}
    />
  );
};

function AceptOnlyNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      false
      isNumericString
    />
  );
}

function TextUUIDMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        "-",
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        "-",
        /[1-5]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        "-",
        /[89ab]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        "-",
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
        /[a-f0-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const CustomInput = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.thirdColor, //azul
    },
    "& label.MuiFormLabel-filled:hover": {
      color: theme.palette.thirdColor, //azul
    },
    "& label.Mui-disabled": {
      color: "gray", //gris
    },
    "& input.Mui-disabled": {
      color: "rgba(255,255,255,0.5);",
    },
    "& label.MuiFormLabel-filled:not(.Mui-focused):not(.Mui-error)": {
      color: "gray", //gris
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#16B1F3", //verde
    },
    "& .MuiInputBase-input": {
      color: "#FFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "gray", //gris
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "gray", //gris
    },
    "& .MuiInput-underline:focus": {
      borderBottomColor: "gray", //gris
    },
    "& .MuiOutlinedInput-root": {
      "&.MuiInputLabel-outlined": {
        color: "white",
      },
      "& fieldset": {
        border: "2px solid",
        borderColor: " rgba(255,255,255,0.3)", //borde de la caja
      },
      "&:hover fieldset": {
        borderColor: "gray",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.thirdColor, //azul
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "gray", //gris
      },
    },
  },
}))(TextField);

const Input = ({
  tooltip,
  id,
  onChange,
  value,
  label,
  disabled,
  validator,
  validateOptions,
  hidden,
  onKeyPress,
  onBlur,
  maxLength,
  nameValidator,
  variant,
  multiline,
  format,
  name,
  size,
  max,
  porcentage,
  rowsline,
  placeholder,
  noMargin,
  noMarginLR,
  type,
  onClick,
  rowsMax,
  endAdornment = "",
  rows,
}) => {
  const classes = useStyles();
  let errorValidator = validator
    ? validator.message(
        !!nameValidator ? nameValidator : label,
        value,
        validateOptions
      )
    : "";

  if (!!hidden) return null;

  let myFormat = "input";
  switch (format) {
    case true:
      myFormat = NumberFormatCustom;
      break;
    case "money":
      myFormat = NumberFormatCustom;
      break;
    case "UUID":
      myFormat = TextUUIDMaskCustom;
      break;
    case "onlynNumber":
      myFormat = AceptOnlyNumber;
      break;
    case "porcentage":
      myFormat = NumberFormatPorcentaje;
      break;
    default:
      break;
  }

  let endAdornmentInput = !!tooltip ? (
    <InputAdornment position="end">
      {porcentage === true ? (
        <span className={classes.endAdornment}>%</span>
      ) : (
        <CustomTooltip message={tooltip} />
      )}
    </InputAdornment>
  ) : (
    <InputAdornment position="end">{endAdornment}</InputAdornment>
  );

  return (
    <div className={classes.root}>
      <CustomInput
        fullWidth
        className={
          noMargin
            ? classes.noMargin
            : noMarginLR
            ? classes.noMarginLeftRight
            : classes.margin
        }
        rowsMax={rowsMax ?? ""}
        rowsline={rowsline ?? ""}
        rows={rows ?? ""}
        disabled={disabled === true ? true : false}
        multiline={multiline ? true : false}
        label={label}
        variant={!!variant ? variant : "outlined"}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        error={!!errorValidator ? true : false}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        size={size}
        inputProps={{ maxLength: maxLength, max: max }}
        autoComplete="off"
        type={!!type ? type : "text"}
        placeholder={placeholder}
        onClick={!!onClick ? (e) => onClick(e, name) : null}
        InputProps={{
          inputComponent: myFormat,
          endAdornment: endAdornmentInput,
        }}
      />
      {!!errorValidator && (
        <div className={classes.error}>{errorValidator}</div>
      )}
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
};

export default Input;
