import React from "react";

import { Button, Grid, makeStyles } from "@material-ui/core";

import CustomTooltip from "../../../../components/tooltip/tooltip.component";
import TooltipMessage from "../../../../components/tooltip/tootltip-message.component";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { getButtonTextSelect } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  buttonRetefuente: {
    backgroundColor: "transparent",
    border: "2px solid rgba(255,255,255,0.3)",
    borderRadius: 5,
    color: theme.palette.primaryColor,
    fontSize: 12,
    padding: "0px 5px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    width: "100%",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  iconReteFuente: {
    color: theme.palette.primaryColor,
  },
  tooltip: {
    padding: 5,
  },
}));

export const SelectRetentionSection = ({
  handleOpenModalRteFuente,
  typedocument,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} lg={12} className={classes.root}>
      <Button
        className={classes.buttonRetefuente}
        component="div"
        onClick={handleOpenModalRteFuente}
        variant="contained"
      >
        {getButtonTextSelect(typedocument, "RETENTION")}
        <KeyboardArrowDownIcon className={classes.iconReteFuente} />
      </Button>
      <div className={classes.tooltip}>
        <CustomTooltip
          message={
            <TooltipMessage
              message={getButtonTextSelect(typedocument, "TOOLTIPMESSAGE")}
              noButton
              title={getButtonTextSelect(typedocument, "TOOLTIPTITLE")}
            />
          }
          size={22}
        />
      </div>
    </Grid>
  );
};
