import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  URL_IMAGES,
  MESSAGE_DELETE_CONFIRM,
  READING_MODE,
} from "../../../config/config.js";

import CustomCardAditionalData from "../../invoice/aditional-data-card.component";
import { updateDocument } from "../../../actions/invoiceActions";
import {
  ClearGetComponentsAction,
  getMenuComponentAction,
} from "../../../actions/configAction";
import CollapsePanel from "../../../components/collapse/collapsestyles.component";
import CustomProgress from "../../../components/Progress/progress.component";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import { ConfirmAlert } from "../../../helpers/alert.helpers";
import { getComponentName, getComponentUrl } from "../../../utils/general.js";

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { Card, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";

import { formatDate } from "../../../utils/general.js";
import { FormatDecimal } from "../../../components/common/formatDecimal.js";
import { TooltipMessageReadingMode } from "../../../components/tooltip/TooltipMessageReadingMode.js";

import { withStyles } from "@material-ui/core/styles";
import { getTaxItems } from "../../../utils/getTaxItems.js";
import { TaxElement } from "../../document/cart/TaxElement.js";

const useStyles = makeStyles({
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  letter: {
    color: "#FFFFFF",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  widthMax: {
    width: "100%",
  },
  paddingIcons: {
    paddingTop: "20%",
  },
  width99: {
    width: "99%",
  },
  width98: {
    width: "98%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  width97: {
    width: "97%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  width90: {
    width: "90%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  letterTaxes: {
    color: "#2CC63E",
  },
  totalInvoiceDetail: {
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "20px",
    fontWeight: "800",
    letterSpacing: "0",
    textAlign: "left",
  },
  colorIcon: {
    color: "#2CC63E",
  },
  h1Card: {
    justifyContent: "center",
    textAlign: "center",
    color: "white",
  },
  cover: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerLoading: {
    paddingTop: 200,
  },
});

const QuotationDetails = (props) => {
  const classes = useStyles();
  const {
    document,
    // heightDiv,
    OpenMailModal,
    OpenEdit,
    getMenuComponentAction,
    ClearGetComponentsAction,
    updateDocumentStatus,
  } = props;

  const {
    loadingGetDetail,
    loadingUpdateFavourite,
    loadingDeleteDocument,
  } = props; //Reducer
  let { configReducer } = props;
  let components = configReducer.menuComponent;

  useEffect(() => {
    getMenuComponentAction(3);
    return () => {
      ClearGetComponentsAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Evento, controla cambio estado destacado de un documento
   */
  const handleFavourite = () => {
    let data = {
      Id: document.id,
      Pagado: true,
      Operacion: "FAVORITO",
      Favorito: !document.favorito,
    };

    updateDocumentStatus(data);
  };

  /**
   * Evento, controla opcion eliminar documento
   */
  const handleDeleteDocument = () => {
    const setPrincipal = (confirmed) => {
      if (confirmed) {
        localStorage.removeItem("itemsInCart");
        let data = {
          Id: document.id,
          Pagado: false,
          Operacion: "BORRADO",
          Favorito: document.favorito,
        };

        updateDocumentStatus(data);
      }
    };
    ConfirmAlert(
      "¿Desea eliminar la cotización?",
      setPrincipal,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * Expander panel informacion adicional
   */
  const [expandPanel] = useState({
    expandPanelInfo: "",
    cleanData: false,
    edit: false,
  });

  /**
   * Genera listado categorias
   * @param {*} cats Listado categorias
   */

  const getCategories = (cats) => {
    if (cats !== null) {
      const categories = cats.map((cat) => (
        <Grid item key={cat.id} lg={2} md={2} sm={12} xs={12}>
          <div className="spacingInvoiceMini"></div>
          <div
            className="circlesCategories"
            style={{ backgroundColor: cat.color }}
            title={cat.nombre}
          ></div>
        </Grid>
      ));
      return categories;
    }
  };

  /**
   * Panel listado articulos
   */
  const getArticles = () => {
    if (
      document.opdetalledocumento !== undefined &&
      document.opdetalledocumento !== null
    ) {
      const articles = document.opdetalledocumento.map((items) => {
        const taxItems = getTaxItems(
          items.porcentajeiva,
          items.porcentajeinc,
          items.tarifaIcl / items.cantidad,
          items.cantidadGrados,
          items.cantidad,
          items.cantidadGramos / items.cantidad,
          items.tarifaInpp,
          items.cantidadMililitros / items.cantidad,
          items.tarifaIbua,
          items.porcentajeIcui,
          items.porcentajeAdv
        );

        return (
          <div key={items.id}>
            <div className="cardItemInvoice">
              <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={12}>
                  <Card
                    key={items.id}
                    style={{
                      height: "97%",
                      width: "97%",
                      backgroundColor:
                        items.colorimagen === null ||
                        items.colorimagen === undefined ||
                        items.colorimagen.length === 0
                          ? "#33BBFF"
                          : items.colorimagen,
                    }}
                  >
                    <CardMedia
                      className={classes.cover}
                      image={items && URL_IMAGES + items.imagen}
                      style={{
                        background: `${
                          items.imagen === null ||
                          items.imagen === undefined ||
                          items.imagen.length === 0
                            ? items.colorimagen === null ||
                              items.colorimagen === undefined ||
                              items.colorimagen.length === 0
                              ? "#33BBFF"
                              : items.colorimagen
                            : null
                        }`,
                      }}
                    >
                      {items.imagen === null ||
                      items.imagen === undefined ||
                      items.imagen.length === 0 ? (
                        <h1 className={classes.h1Card}>
                          {items.descripcion.substring(0, 2).toUpperCase()}
                        </h1>
                      ) : null}
                    </CardMedia>
                  </Card>
                </Grid>
                <Grid item lg={9} md={9} sm={12}>
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      className="titleArticleInvoice"
                    >
                      {items.descripcion.length > 60
                        ? items.descripcion.substring(0, 60) + "..."
                        : items.descripcion}{" "}
                      <div className="spacingInvoice"></div>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12}>
                        <div className={classes.width98}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className="fieldsReadContainer">
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={12}
                      className="fieldsReadMini"
                    >
                      Valor C/U:
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} className="fieldsReadMini">
                      Cantidad:
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={12}
                      className="totalInvoiceDetail"
                    >
                      <FormatDecimal value={items.valorunitario} prefix={"$"} />
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} className="fieldsReadBig">
                      <FormatDecimal value={items.cantidad} />
                    </Grid>
                  </Grid>
                  <div className="spacingInvoiceMini"></div>
                  <Grid container>
                    <Grid item lg={10} md={10} sm={12}>
                      <div className={classes.width98}></div>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12}>
                      <div className={classes.width98}></div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">
                      {taxItems.map(
                        ({ aplica, label, value, prefix, suffix }, index) =>
                          aplica ? (
                            <TaxElement
                              key={index}
                              label={label}
                              value={value}
                              prefix={prefix}
                              suffix={suffix}
                            />
                          ) : null
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} className="fieldsReadBig">
                      <Grid container justify="flex-end">
                        {getCategories(items.adcategoriaarticulo)}
                      </Grid>
                    </Grid>
                  </Grid>

                  <div className="spacingInvoiceMini"></div>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12}>
                      <div className={classes.width98}></div>
                    </Grid>
                  </Grid>
                  <Grid container className="fieldsReadContainer">
                    <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">
                      Descuento:
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">
                      Total:
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      lg={8}
                      md={8}
                      sm={12}
                      className={classes.totalInvoiceDetail}
                    >
                      {items.valordescuento > 0 ? (
                        <FormatDecimal
                          value={items.valordescuento}
                          prefix={"$"}
                        />
                      ) : (
                        <FormatDecimal
                          value={items.porcentajedescuento}
                          suffix={"%"}
                        />
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      className="totalInvoiceDetail"
                    >
                      <FormatDecimal value={items.totalreal} prefix={"$"} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className="spacingInvoice"></div>
          </div>
        );
      });
      return articles;
    }
  };

  /**
   * Panel informacion adicional
   */
  const getInfoAditional = () => {
    let cargos = document.opdocumentocargos;
    let descuentos = document.opdocumentodescuentos;

    return (
      <div>
        <div className="cardDetailInfoAditional">
          <Grid container>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">
              Fecha Cotización{" "}
            </Grid>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">
              Forma de Pago
            </Grid>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">
              Fecha de Vigencia
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">
              {document?.fecha !== null && document?.fecha !== undefined
                ? formatDate(new Date(document.fecha), "dd/MM/yyyy hh:mm aaaa")
                : "-"}
            </Grid>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">
              {document.descripcionforma}
            </Grid>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">
              {document?.fechavigencia !== null &&
              document?.fechavigencia !== undefined
                ? formatDate(
                    new Date(document.fechavigencia),
                    "dd/MM/yyyy hh:mm aaaa"
                  )
                : "-"}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} md={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
          </Grid>
          <Grid container className="fieldsReadContainer">
            <Grid item lg={4} sm={12} className="fieldsReadMini">
              Medio Pago
            </Grid>
            <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">
              Vendedor
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">
              {document.descripcionmedio === null
                ? "Sin datos"
                : document.descripcionmedio}
            </Grid>
            <Grid item lg={8} md={8} sm={12} className="fieldsReadBig">
              {document.nombresvendedor === null
                ? "Sin datos"
                : document.nombresvendedor}
            </Grid>
          </Grid>
          <Grid container className="fieldsReadContainer">
            <Grid item lg={4} sm={12} className="fieldsReadMini">
              Aceptada/Rechazada
            </Grid>
            <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">
              Fecha Aceptación/Rechazo
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">
              {document.descripcionaceptacion === null
                ? "Sin datos"
                : document.descripcionaceptacion}
            </Grid>
            <Grid item lg={8} md={8} sm={12} className="fieldsReadBig">
              {document?.fechaaceptacliente !== null &&
              document?.fechaaceptacliente !== undefined
                ? formatDate(
                    new Date(document.fechaaceptacliente),
                    "dd/MM/yyyy hh:mm aaaa"
                  )
                : "-"}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} md={4} sm={12}>
              <div className={classes.width98}></div>
            </Grid>
            <Grid item lg={8} md={8} sm={12}>
              <div className={classes.width98}></div>
            </Grid>
          </Grid>
          <Grid container className="fieldsReadContainer">
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className="fieldsReadMini"
              style={{ width: "96%" }}
            >
              Observaciones
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className="fieldsReadBig"
              style={{ wordBreak: "break-word" }}
            >
              {document.textoobservacion}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12}></Grid>
          </Grid>
        </div>
        <div className="spacingInvoice"></div>
        <div className="titleAdicitional">Cargos</div>
        <div className="spacingInvoice"></div>
        <Grid container spacing={1}>
          {cargos !== undefined
            ? cargos !== null
              ? cargos.map((data, index) => (
                  <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                    <CustomCardAditionalData data={data} type="charges" />
                  </Grid>
                ))
              : ""
            : ""}
        </Grid>
        <div className="spacingInvoice"></div>
        <div className="titleAdicitional">Descuentos sobre el total</div>
        <div className="spacingInvoice"></div>
        <Grid container spacing={1}>
          {descuentos !== undefined
            ? descuentos !== null
              ? descuentos.map((data, index) => (
                  <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                    <CustomCardAditionalData
                      data={data}
                      type="discounts"
                      tooltipMessage={
                        data.modelodescuento === 1 ? (
                          <TooltipMessage
                            title={getComponentName(
                              components,
                              93,
                              157,
                              "¿Cómo afecta la factura este descuento?"
                            )}
                            message={getComponentName(
                              components,
                              93,
                              158,
                              "Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura."
                            )}
                            botton={getComponentName(
                              components,
                              93,
                              159,
                              "Más información"
                            )}
                            href={getComponentUrl(components, 93, 150, null)}
                          />
                        ) : (
                          <TooltipMessage
                            title={getComponentName(
                              components,
                              94,
                              160,
                              "¿Cómo afecta la factura este descuento?"
                            )}
                            message={getComponentName(
                              components,
                              94,
                              161,
                              "Es un descuento que afecta el valor total a pagar pero no el valor total de la factura."
                            )}
                            botton={getComponentName(
                              components,
                              94,
                              162,
                              "Más información"
                            )}
                            href={getComponentUrl(components, 94, 162, null)}
                          />
                        )
                      }
                      onClick=""
                    />
                  </Grid>
                ))
              : ""
            : ""}
        </Grid>
      </div>
    );
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2F2E2E",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),
    },
  }))(Tooltip);

  return (
    <div
      className={
        document.numero === undefined ? classes.hideLayer : classes.showLayer
      }
    >
      {document.diantipodocumentoelectronicoid === 4 ? (
        loadingGetDetail !== true ? (
          <Fragment>
            <div
              className="cardDetailInvoiceFullData"
              style={{ overflow: "auto", height: "72.0vh" }}
            >
              <Grid container>
                <Grid item lg={6} sm={12} className="detailInvoiceNumber">
                  No. {document.numero}
                </Grid>
                <Grid item lg={6} sm={12} style={{ textAlign: "right" }}>
                  <div
                    className={
                      document.favorito === true
                        ? classes.showLayer
                        : classes.hideLayer
                    }
                  >
                    <StarIcon></StarIcon>
                  </div>
                  <div
                    className={
                      document.favorito === false
                        ? classes.showLayer
                        : classes.hideLayer
                    }
                  >
                    <StarBorderIcon></StarBorderIcon>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{ textAlign: "left" }}
                  className={
                    document.estadocotizacionid === "05"
                      ? "totalInvoiceGridExpired"
                      : "totalInvoiceGridPaidout"
                  }
                >
                  <FormatDecimal
                    value={document.valorapagar}
                    prefix={"$"}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  className={
                    document.estadocotizacionid === "05"
                      ? "stateInvoiceExpired"
                      : "stateInvoicePaidout"
                  }
                >
                  {document.estadocot}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} sm={12} className="dataInvoiceGrid">
                  {document?.fecha !== null && document?.fecha !== undefined
                    ? formatDate(
                        new Date(document.fecha),
                        "dd/MM/yyyy hh:mm aaaa"
                      )
                    : "-"}
                </Grid>
                <Grid item lg={6} sm={12}></Grid>
              </Grid>

              <div className="lineDivision" />

              <div className="paddingLeft">
                <div className="constumerNameDetail">
                  {document.razonsocialcliente}
                </div>
                <div className="detailInvoiceInfo">
                  {document.desdoccli} {document.clientedocumento}
                </div>
                <div className="detailInvoiceInfo">{document.desctipe}</div>
                <div className="detailInvoiceInfo">{document.desctirg}</div>
                <div className="spacingInvoice"></div>
              </div>
              <div className="gridNoSpace">
                <EmailIcon className={classes.colorIcon}></EmailIcon>
                &nbsp;&nbsp;
                <div className="detailInvoiceInfo">
                  {document.correoelectronicocliente}
                </div>
              </div>
              <div className="gridNoSpace">
                <PhoneIcon className={classes.colorIcon}></PhoneIcon>
                &nbsp;&nbsp;
                <div className="detailInvoiceInfo">
                  {document.telefonocliente}
                </div>
              </div>
              <div className="gridNoSpace">
                <RoomIcon className={classes.colorIcon}></RoomIcon>&nbsp;&nbsp;
                <div className="detailInvoiceInfo">
                  {document.adclientedirecciones === undefined ||
                  document.adclientedirecciones === null
                    ? ""
                    : document.adclientedirecciones[0].direccion +
                      " " +
                      document.adclientedirecciones[0].nombmun +
                      " " +
                      document.adclientedirecciones[0].nombdep}
                </div>
              </div>
              <div className="spacingInvoice"></div>
              <div className="greenTitleInvoice">
                Artículos (
                {document.opdetalledocumento === undefined
                  ? 0
                  : document.opdetalledocumento === null
                  ? 0
                  : document.opdetalledocumento.length}
                )
              </div>
              <div className="spacingInvoice"></div>
              {getArticles()}
              <CollapsePanel
                component={getInfoAditional}
                title={"Información Adicional"}
                titleOpen={"Información Adicional"}
                titleEdit={"Información Adicional"}
                edit={expandPanel.edit}
                open={expandPanel.expandPanelInfo}
                cleanData={expandPanel.cleanData}
                backgroundColorSummary={{
                  backgroundColor: "#2CC63E",
                  color: "#000000",
                }}
                backgroundColorDetail={{}}
                type="showinfo"
              />
            </div>
            <div className="bottomButtonsInvoice">
              <Grid container>
                <Grid item lg={1} sm={1} />

                <Grid item lg={2} sm={2}>
                  {loadingUpdateFavourite === true ? (
                    <CustomProgress />
                  ) : (
                    <Fragment>
                      {document.favorito ? (
                        <StarIcon
                          className="buttonInvoiceBehavior"
                          onClick={() => handleFavourite(false)}
                        ></StarIcon>
                      ) : (
                        <StarBorderIcon
                          className="buttonInvoiceBehavior"
                          onClick={() => handleFavourite(true)}
                        ></StarBorderIcon>
                      )}
                      <div className="textButtonsInvoice">Favorito</div>
                    </Fragment>
                  )}
                </Grid>

                <Grid item lg={2} sm={2}>
                  {loadingDeleteDocument === true ? (
                    <CustomProgress />
                  ) : (
                    <div title="Eliminar Cotización">
                      <DeleteIcon
                        className="buttonInvoiceBehavior"
                        onClick={() => handleDeleteDocument()}
                      ></DeleteIcon>
                      <br></br>
                      <div className="textButtonsInvoice">Eliminar</div>
                    </div>
                  )}
                </Grid>
                <Grid item lg={2} sm={2}>
                  <HtmlTooltip
                    title={READING_MODE ? <TooltipMessageReadingMode /> : ""}
                  >
                    <span>
                      <ReceiptIcon
                        className="buttonInvoiceBehavior"
                        style={
                          READING_MODE
                            ? { color: "#919191", cursor: "not-allowed" }
                            : {}
                        }
                        onClick={
                          !READING_MODE ? () => OpenEdit(document, 2) : null
                        }
                      ></ReceiptIcon>
                      <br></br>

                      <div
                        className="textButtonsInvoice"
                        style={
                          READING_MODE
                            ? { color: "#919191", cursor: "not-allowed" }
                            : {}
                        }
                      >
                        Facturar
                      </div>
                    </span>
                  </HtmlTooltip>
                </Grid>
                <Grid item lg={2} sm={2}>
                  <EditIcon
                    className="buttonInvoiceBehavior"
                    onClick={() => OpenEdit(document, 1)}
                  ></EditIcon>
                  <br></br>
                  <div className="textButtonsInvoice">Editar</div>
                </Grid>
                <Grid item lg={2} sm={2}>
                  <EmailIcon
                    className="buttonInvoiceBehavior"
                    onClick={() =>
                      OpenMailModal(
                        document.clientecorreo,
                        document.tipodocumentoentidad,
                        document.documentompresa,
                        document.numero,
                        document.razonsocialempresa,
                        document.razonsocialcliente,
                        document.id
                      )
                    }
                  ></EmailIcon>
                  <br></br>
                  <div className="textButtonsInvoice">Enviar</div>
                </Grid>
                <Grid item lg={1} sm={1}></Grid>
              </Grid>
            </div>
          </Fragment>
        ) : (
          <div className={classes.containerLoading}>
            <CustomProgress />
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sucessupdate: state.invoiceReducer.sucess,
  document: state.invoiceReducer.document,
  loadingGetDetail: state.invoiceReducer.loadingGetDetail,
  loadingUpdateFavourite: state.invoiceReducer.loadingUpdateFavourite,
  loadingDeleteDocument: state.invoiceReducer.loadingDeleteDocument,
  configReducer: state.configReducer,
});

const mapDispatchToProps = {
  updateDocument,
  getMenuComponentAction,
  ClearGetComponentsAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);
