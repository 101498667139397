import React, { useEffect, useRef, useState } from "react";

import { Grid } from "@material-ui/core";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import SimpleReactValidator from "simple-react-validator";

import { reactValidatorOptions } from "../../../../helpers/simpleReactValidator";

import { format } from "date-fns";

import { useFormComplete } from "../../../../hooks/useFormComplete";

import {
  initialLoadCreateEquivalentDoc,
  nextStepCreateDocumentAction,
  setEquivalentDocDataAction,
} from "../../../../actions/equivalentDocAction";

import { getAditionalData } from "../../../../actions/invoiceActions";
import {
  getEquivalentDocumentTypeAction,
  getPaymentMethodsAction,
} from "../../../../actions/configAction";

import { FormByEquivalentDocType, checkFormErrors } from "../../utils";
import { getDatepay } from "../../../../utils/rangeDates";
import {
  getEnterpriseId,
  getUserIdSecurity,
} from "../../../../utils/dataUserLogin";

import { DataEquivalentDoc } from "./DataEquivalentDoc";

/**
 * * Objeto que representa los campos iniciales del formulario de documento equivalente.
 * @typedef {Object} InitialForm
 * @property {string} boxLocation - Ubicación de la caja.
 * @property {string} boxPlate - Placa de la caja.
 * @property {string} boxType - Tipo de caja.
 * @property {string} branchOfficeID - ID de la sucursal.
 * @property {string} cashier - Cajero.
 * @property {number} dateRange - Rango de fechas.
 * @property {string} dianPaymentMethodID - ID del método de pago Dian.
 * @property {string} documentDate - Fecha del documento en formato "yyyy/MM/dd".
 * @property {string} documentType - Tipo de documento.
 * @property {string} numericalID - ID numeración.
 * @property {string} observations - Observaciones.
 * @property {string} paymentDate - Fecha de pago en formato "yyyy/MM/dd".
 * @property {string} paymentMethod - Método de pago.
 * @property {string} saleCode - Código de venta.
 * @property {string} subtotal - Subtotal.
 */
const initialForm = {
  boxLocation: "",
  boxPlate: "",
  boxType: "",
  branchOfficeID: "",
  cashier: "",
  dateRange: 0,
  dianPaymentMethodID: "",
  documentDate: format(new Date(), "yyyy/MM/dd"),
  documentType: "",
  numericalID: "",
  observations: "",
  paymentDate: format(getDatepay(), "yyyy/MM/dd"),
  paymentMethod: "2",
  saleCode: "",
  subtotal: "",
};

export const EquivalentDocFormFields = ({ handleScrollTop }) => {
  const dispatch = useDispatch();

  const [, forceUpdate] = useState();

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;

  const { customerEquivalentDoc, equivalentDocumentForm } = useSelector(
    (state) => state.equivalentDocReducer
  );

  const { values, handleInputChange, handleUpdateForm } = useFormComplete(
    equivalentDocumentForm?.documentType ? equivalentDocumentForm : initialForm
  );

  const { branchOfficeID, documentType, observations } = values;

  const { adsucursalesempresa, adnumeracion, adobservaciones } = useSelector(
    (s) => s.invoiceReducer.aditionaldataenterprise,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getPaymentMethodsAction());
    dispatch(getAditionalData(getEnterpriseId(), getUserIdSecurity()));
    dispatch(getEquivalentDocumentTypeAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(initialLoadCreateEquivalentDoc(branchOfficeID));
  }, [dispatch, branchOfficeID, adsucursalesempresa, adnumeracion]);

  useEffect(() => {
    if (adobservaciones.length > 0 && observations.length === 0) {
      handleUpdateForm({
        observations: adobservaciones[0].texto,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adobservaciones]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const containsFormErrors = checkFormErrors(dispatch, customerEquivalentDoc);

    const formValid = validator.allValid();

    if (!formValid) {
      validator.showMessages();
      forceUpdate(1);
      handleScrollTop();
      return null;
    }

    if (!containsFormErrors) {
      dispatch(nextStepCreateDocumentAction(1));
      dispatch(setEquivalentDocDataAction(values));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <form id={"head-form"} onSubmit={handleSubmit}>
          <DataEquivalentDoc
            {...values}
            handleInputChange={handleInputChange}
            handleUpdateForm={handleUpdateForm}
            validator={validator}
          />
          <div className="spacingInvoice" />
          <FormByEquivalentDocType
            documentType={documentType}
            handleInputChange={handleInputChange}
            validator={validator}
            values={values}
          />
        </form>
      </Grid>
    </Grid>
  );
};
