import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    display: "none",
  },
  buttonSuccess: {
    backgroundColor: theme.palette.thirdColor,
    color: theme.palette.primaryColor,
    "&:hover": {
      backgroundColor: blue[800],
    },
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
    minWidth: 130,
    marginTop: 10,
  },
  buttonPrincipal: {
    backgroundColor: "rgba(255,255,255,0.18)",
    color: theme.palette.primaryColor,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.18)",
    },
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
  },
}));

export default function ButtonUploadInvoice(props) {
  const classes = useStyles();
  const { text, principal, onChange } = props;

  return (
    <div className={classes.root}>
      <input
        accept=".xml, .zip"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={onChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          component="span"
          className={
            principal ? classes.buttonPrincipal : classes.buttonSuccess
          }
        >
          {text}
        </Button>
      </label>
    </div>
  );
}
