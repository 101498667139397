import React, { useCallback, useEffect, useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Grid } from "@material-ui/core";

import {
  addDiscountDocumentAction,
  deleteDiscountDocumentAction,
} from "../../../../actions/documentCartAction";

import {
  getDiscountsAction,
  loadDiscountInFormAction,
} from "../../../../actions/newDiscountAction";

import { CollapseList } from "../../ui/collapseList";
import { CustomModal } from "../../../../components/modal/CustomModal";
import { DiscountCard } from "../../discounts/discountCard";
import { DiscountForm } from "../../discounts/discountForm";
import { DiscountsList } from "../../discounts/discountsList";

import generateId from "../../../../utils/generateId";

import { ConfirmAlert } from "../../../../helpers/alert.helpers";

import { MESSAGE_DELETE_CONFIRM } from "../../../../config/config";

export const DiscountsSection = () => {
  const [expandDiscounts, setExpandDiscounts] = useState(false);
  const [openModalDiscounts, setOpenModalDiscounts] = useState(false);

  const dispatch = useDispatch();

  const { opdocumentodescuentos } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  const {
    activeDocumentDiscount,
    discounts,
    loadingGetDiscounts,
  } = useSelector((state) => state.newDiscountReducer);

  useEffect(() => {
    dispatch(getDiscountsAction());
  }, [dispatch]);

  /**
   * * Alterna la expansión o contracción del panel de listado de descuentos.
   * @returns {void}
   */
  const handleExpandDiscounts = () => {
    setExpandDiscounts(!expandDiscounts);
  };

  /**
   * * Agrega un nuevo descuento al documento seleccionado.
   * @param {*} discount - El descuento que se va a agregar al documento.
   * @returns {void}
   */
  const handleSelectionDiscount = useCallback(
    (discount) => {
      setExpandDiscounts(false);
      let newDiscount = {
        ...discount,
        id: generateId(),
        descuentoid: discount.id,
      };
      dispatch(addDiscountDocumentAction(newDiscount));
    },
    [dispatch]
  );

  /**
   * * Elimina un descuento del documento.
   * @param {*} param0.id - ID del descuento a eliminar.
   * @param {*} param0.descripcion - Descripción del descuento a eliminar.
   * @returns {void}
   */
  const handleDeleteDiscount = ({ id, descripcion }) => {
    const deleteData = (confirmed) => {
      if (confirmed) {
        dispatch(deleteDiscountDocumentAction(id));
      }
    };
    ConfirmAlert(
      `¿Estás seguro de eliminar ${descripcion}?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * * Cierra el modal del formulario de descuentos.
   * @returns {void}
   */
  const handleCloseModalDiscount = () => {
    setOpenModalDiscounts(false);
    dispatch(loadDiscountInFormAction(null));
  };

  /**
   * * Abre un modal con el formulario para la creación de un nuevo descuento.
   * @returns {void}
   */
  const handleCreateDiscount = useCallback(() => {
    setOpenModalDiscounts(true);
  }, []);

  /**
   * * Carga la información de un descuento en el formulario para su edición.
   * @param {*} discount - La información del descuento que se cargará en el formulario.
   * @returns {void}
   */
  const handleLoadDiscountInForm = (discount) => {
    dispatch(loadDiscountInFormAction(discount));
    setOpenModalDiscounts(true);
  };

  return (
    <>
      <Grid item lg={12} xs={12}>
        <CollapseList
          title={"Agregar descuento"}
          component={
            <DiscountsList
              discounts={discounts}
              handleCreate={handleCreateDiscount}
              handleSelection={handleSelectionDiscount}
              loadingGetDiscounts={loadingGetDiscounts}
            />
          }
          onClick={handleExpandDiscounts}
          open={expandDiscounts}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={1}>
          {opdocumentodescuentos.map((discount) => (
            <Grid key={discount.id} item lg={12} xs={12}>
              <DiscountCard
                data={discount}
                handleLoad={() => handleLoadDiscountInForm(discount)}
                handleDelete={() => handleDeleteDiscount(discount)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CustomModal
        onClose={handleCloseModalDiscount}
        open={openModalDiscounts}
        component={
          <DiscountForm handleCloseModal={() => setOpenModalDiscounts(false)} />
        }
        title={
          !!activeDocumentDiscount
            ? "Editar descuento documento"
            : "Crear descuento"
        }
        width={600}
      />
    </>
  );
};
