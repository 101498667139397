import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, IconButton } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";

import { ItemIntegrationCard } from "./itemIntegrationCard";
import { FormatDecimal } from "../../components/common/formatDecimal";
import { CollapseDataPanel } from "../../components/ui/collapseDataPanel";
import CustomProgress from "../../components/Progress/progress.component";
import { AdditionalDataPanel } from "./AdditionalDataPanel";
import ArchiveModal from "../../components/modal/archiveModal.component";
import { CustomModal } from "../../components/modal/CustomModal.js";
import { EmailConfirmForm } from "./EmailConfirmForm";
import ResponseModal from "../../components/modal/responseModal.component";

import {
  sendEmailWithDocumentAction,
  standOutDocumentAction,
} from "../../actions/documentIntegrationAction";
import { getDocumentFilesAction } from "../../actions/invoiceActions";

import { format } from "date-fns";
import isEmpty from "../../utils/isEmpty";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#4B4B4B",
    borderRadius: 5,
    height: "80.88vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "justify",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
    cursor: "pointer",
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  content: {
    width: "100%",
    padding: 15,
    fontSize: 16,
    fontWeight: 300,
    overflow: "auto",
  },
  actions: {
    backgroundColor: "#393939",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    textAlign: "center",
    alignSelf: "flex-end",
    width: "100%",
    height: 80,
    padding: 15,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  value: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#16B1F3",
  },
  line: {
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    height: 0.5,
    margin: "10px 5px",
  },
  name: {
    fontSize: 20,
    color: "#F6D746",
    fontWeight: "bold",
  },
  icondata: {
    color: "#2CC63E",
    marginRight: 5,
    fontSize: 22,
  },
  flexdisplay: {
    display: "flex",
  },
  titleItems: {
    width: "100%",
    backgroundColor: "rgba(18, 18, 18, 0.3)",
    borderRadius: 5,
    color: "#2CC63E",
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px auto",
    padding: 5,
  },
  status: {
    fontSize: 20,
    color: "#16B1F3",
  },
  containerFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default function IntegrationDetail({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadingGetFile, setLoadingGetFile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [base64PDF, setBase64PDF] = useState(null);
  const [base64XML, setBase64XML] = useState(null);
  const [openMailModal, setOpenMailModal] = useState(false);
  const [modalData, setModalData] = React.useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  const {
    Id,
    Numero,
    Fecha,
    Codigounico,
    TipoDocumentoEmisor,
    DocumentoEmisor,
    NombreEmisor,
    NombreAdquiriente,
    TipoDocumentoAdquiriente,
    DocumentoAdquiriente,
    CorreoElectronicoAdquiriente,
    NumeroTelefonicoAdquiriente,
    DireccionAquiriente,
    DepartamentoAquiriente,
    MunicipioAquiriente,
    TipoRegimenAdquiriente,
    Favorito,
    Valorapagar,
    LineaDetalle,
    TipoDocumentoElectronicocodigo,
    CodigoEstadoDian,
    CodigoDocumentoAdquiriente,
  } = data;

  const { loadingStandOut } = useSelector((s) => s.documentIntegrationReducer);
  const { loadingSendEmail } = useSelector((s) => s.documentIntegrationReducer);

  /**
   * Marca documento como destacado
   */
  const handleStandOut = () => {
    dispatch(standOutDocumentAction(Id, !Favorito));
  };

  /**
   * Abre modal y consulta PDF para previsualización
   */
  const handleGetFile = () => {
    if (isEmpty(Codigounico) || CodigoEstadoDian !== "00") {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Archivo no disponible",
        body: (
          <div>
            {
              "Documento en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá visualizarlo."
            }
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
    } else {
      const data = {
        tipodocumento:
          TipoDocumentoElectronicocodigo !== "05"
            ? TipoDocumentoEmisor
            : CodigoDocumentoAdquiriente,
        documento:
          TipoDocumentoElectronicocodigo !== "05"
            ? DocumentoEmisor
            : DocumentoAdquiriente,
        codigounico: Codigounico,
      };
      setLoadingGetFile(true);
      setOpenModal(true);
      dispatch(getDocumentFilesAction(data, readDataFile));
    }
  };

  /**
   * Lee respoknse consulta PDF
   * @param {*} response
   */
  const readDataFile = (response) => {
    setLoadingGetFile(false);
    if (response !== null) {
      setBase64PDF(
        response.find((c) => c?.tipoContenido === "application/pdf")
          ?.archivoBase64 ?? null
      );
      setBase64XML(
        response.find((c) => c?.tipoContenido === "application/xml")
          ?.archivoBase64 ?? null
      );
    } else {
      setOpenModal(false);
    }
  };

  /**
   * Cierra Modal, Previsusalizacion PDF
   */
  const handleCloseFileModal = () => {
    setOpenModal(false);
    setBase64PDF(null);
    setBase64XML(null);
  };

  /**
   * Abre modal confirmación email
   */
  const handleOpenEmailModal = () => {
    if (isEmpty(Codigounico) || CodigoEstadoDian !== "00") {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Documento no disponible",
        body: (
          <div>
            {
              "Documento en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá enviarlo."
            }
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
    } else {
      setOpenMailModal(true);
    }
  };

  /**
   * Cierra Modal, Notificación certificado no valido
   */
  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  /**
   * Reenvio de documento
   */
  const handleConfirmEmail = (emails) => {
    const data = {
      Correos: emails,
      RazonSocialEmpresa: NombreEmisor,
      Documento:
        TipoDocumentoElectronicocodigo !== "05"
          ? DocumentoEmisor
          : DocumentoAdquiriente,
      TipoDocumento:
        TipoDocumentoElectronicocodigo !== "05"
          ? TipoDocumentoEmisor
          : CodigoDocumentoAdquiriente,
      CodigoUnico: Codigounico,
      NumeroDocumento: Numero, //Numero factura
      RazonSocialCliente: NombreAdquiriente,
      TipoModalidad: TipoDocumentoElectronicocodigo !== "05" ? 3 : 6, //Electronica
      DocumentoId: 0, //No Aplica
    };

    dispatch(
      sendEmailWithDocumentAction(
        data,
        readResponseEmail,
        TipoDocumentoElectronicocodigo
      )
    );
  };

  /**
   * Lee respuesta envio email con PDF
   * @param {*} response
   */
  const readResponseEmail = (response) => {
    setOpenMailModal(false);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container>
            <Grid item lg={12} xs={12} className={classes.containerFlex}>
              <div className={classes.title}>No. {Numero}</div>
              {Favorito ? <StarIcon /> : <StarBorderIcon />}
            </Grid>
            <Grid item lg={12} xs={12} className={classes.containerFlex}>
              <div className={classes.value}>
                <FormatDecimal value={Valorapagar} prefix={"$"} />
              </div>
              <div className={classes.status}>{"Integración"}</div>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{!!Fecha ? format(Fecha, "dd/MM/yyyy") : ""}</span>
            </Grid>
            <div className={classes.line} />
            <Grid item lg={12} xs={12}>
              <span className={classes.name}>{NombreAdquiriente}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{`${TipoDocumentoAdquiriente} ${DocumentoAdquiriente}`}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{TipoRegimenAdquiriente}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <EmailIcon className={classes.icondata}></EmailIcon>
              <span>{CorreoElectronicoAdquiriente}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <PhoneIcon className={classes.icondata}></PhoneIcon>
              <span>{NumeroTelefonicoAdquiriente}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <RoomIcon className={classes.icondata}></RoomIcon>
              <span>
                {`${DireccionAquiriente} ${MunicipioAquiriente} - ${DepartamentoAquiriente.toUpperCase()}`}
              </span>
            </Grid>
          </Grid>
          <div className={classes.titleItems}>
            {`${
              TipoDocumentoElectronicocodigo === "05"
                ? "Servicios"
                : "Articulos"
            } (${LineaDetalle.length})`}
          </div>

          <Grid container>
            {LineaDetalle.map((item, index) => (
              <Grid item lg={12} xs={12} key={index}>
                <ItemIntegrationCard {...item} />
              </Grid>
            ))}
          </Grid>

          <Grid container>
            <Grid item lg={12} xs={12}>
              <CollapseDataPanel
                backgroundColor={"#4B4B4B"}
                component={<AdditionalDataPanel data={data} />}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actions}>
          <Grid container>
            <Grid item lg={4} xs={4}>
              {loadingStandOut ? (
                <CustomProgress size={20} />
              ) : (
                <IconButton
                  classes={{ root: classes.button, label: classes.label }}
                  variant="raised"
                  disableRipple={true}
                  onClick={handleStandOut}
                >
                  {Favorito ? <StarIcon /> : <StarBorderIcon />}
                  <span style={{ marginTop: 5 }}>Favorito</span>
                </IconButton>
              )}
            </Grid>
            <Grid item lg={4} xs={4}>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                onClick={handleGetFile}
              >
                <VisibilityIcon />
                <span style={{ marginTop: 5 }}>Ver</span>
              </IconButton>
            </Grid>
            <Grid item lg={4} xs={4}>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                onClick={handleOpenEmailModal}
              >
                <EmailIcon />
                <span style={{ marginTop: 5 }}>Enviar</span>
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Modal previsualizar PDF */}
      <ArchiveModal
        loading={loadingGetFile}
        open={openModal}
        pdfBase64={base64PDF}
        xmlBase64={base64XML}
        handleClose={handleCloseFileModal}
      />
      {/* Modal confirmación email */}
      <CustomModal
        onClose={() => setOpenMailModal(false)}
        open={openMailModal}
        component={
          <EmailConfirmForm
            emailUser={CorreoElectronicoAdquiriente}
            handleConfirmEmail={handleConfirmEmail}
            loading={loadingSendEmail}
          />
        }
        title={"Envio de Factura"}
        width={365}
        showCloseButton={false}
        disableBackdropClick={loadingSendEmail}
      />

      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleResponseModal}
      />
    </>
  );
}
