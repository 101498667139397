import React, { useEffect, useState, useRef, Fragment } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import SwitchForm from "../../components/switch/switch.component.js";
import InputForm from "../../components/input/input.component";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import imgBtnBack from "../../images/wizard/btnBack.png";
import CustomProgress from "../../components/Progress/progress.component";
import ButtonWizard from "../../components/button/buttonWizard.component";
import ResponseModal from "../../components/modal/responseModal.component";

import {
	getCompanyAction,
	updateDianData,
} from "../../actions/basicDataActions";

import logo from "../../images/wizard/logo.png";
import wizardImage from "../../images/wizard/wizard_6.png";

import { SuccessAlert } from "../../helpers/alert.helpers";
import isEmpty from "../../utils/isEmpty";
import "./step.css";
import { TIME_UPDATE_REGISTER } from "../../config/config";

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		minHeight: "100vh ",
	},
	generalContainer: {
		maxHeight: "100vh",
		position: "absolute",
		overflow: "auto",
	},
	leftGrid: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-end",
	},
	arrowBackIcon: {
		marginRight: 15,
		color: "#16B1F3",
	},
	centerGrid: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
	},
	formWrapper: {
		display: "flex",
		flexDirection: "column",
		"& > *": {
			margin: 7,
		},
	},
	logo: {
		marginTop: 20,
	},
	txtCompany: {
		color: "#7DD1F8",
		fontStyle: "italic",
	},
	grpToggle: {
		alignSelf: "center",
		"& .Mui-selected": {
			backgroundColor: theme.palette.secundaryColor + "!important",
			borderRadius: 5,
			border: "none",
			color: "#FFFFFF",
		},
		"& .Mui-selected:hover": {
			backgroundColor: theme.palette.secundaryColor + "!important",
			borderRadius: 5,
			border: "none",
			color: "#FFFFFF",
		},
	},
	btnNext: {
		textTransform: "none",
		backgroundColor: theme.palette.thirdColor,
		color: "#FFFFFF",
		marginBottom: 5,
		"&:hover": {
			backgroundColor: theme.palette.thirdColor,
		},
	},
	imageWrapper: {
		backgroundColor: "#16B1F3",
	},
	avoidStep: {
		textAlign: "end",
		marginTop: 30,
		marginRight: 40,
		fontStyle: "italic",
		cursor: "pointer",
	},
	wizardImgWrapper: {
		padding: 40,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginTop: "25vh",
	},
	txtMsg: {
		fontSize: 30,
		color: "#FFFFFF",
		textAlign: "center",
		fontStyle: "italic",
	},
	btnBack: {
		color: "#FFFFFF",
		left: "1vw !important",
		cursor: "pointer",
		display: "flex",
		position: "absolute",
		fontSize: "0.9vw",
		alignItems: "center",
		letterSpace: 0,
		justifyContent: "space-between",
		width: "7vw",
		textTransform: "none",
		paddingBottom: "5vh",
	},
	containerProgresss: {
		marginTop: "15vh",
	},
	italicTxt: {
		fontSize: 14,
	},
	errorText: {
		fontSize: 12,
		color: "rgb(244, 67, 54);",
	},
	containerVideo: {
		width: "100%",
		position: "relative",
	},
	disabledVideo: {
		position: "absolute",
		backgroundColor: "rgba(0,0,0,0.6)",
		width: "100%",
		height: "200px",
	},
	containerProgress: {
		marginTop: 30,
		marginRight: 40,
		marginLeft: "90%",
		borderRadius: 50,
		backgroundColor: "rgba(0,0,0,0.1)",
		padding: 5,
	},
}));

const Step6 = (props) => {
	const classes = useStyles();

	const history = useHistory();

	const simpleValidator = useRef(
		new SimpleReactValidator(reactValidatorOptions)
	);
	const [, forceUpdate] = useState();

	const [dataForm, setDataForm] = useState({
		eFacturar: true,
		setId: "",
	});
	const [company, setCompany] = useState({});
	const [disableForm, setDisableForm] = useState(false);
	const [countItem, setCountItem] = useState("");
	const [messagelength, setMessagelength] = useState("");
	const [modalData, setModalData] = useState({
		type: "",
		title: "",
		subtitle: "",
		body: "",
		modalImage: "",
		open: false,
		closeElement: "",
		onCloseElement: 0,
	});
	const [redirect, setRedirect] = useState(false);
	const [typeLoading, setTypeLoading] = useState(0);

	//Reducer
	const { requestCompany, loadingSaveDianData } = props.basicDataReducer;

	/**
	 * Cargue inicial
	 */
	useEffect(() => {
		props.getCompanyAction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Escucha cambios en Reducer, información empresa
	 */
	useEffect(() => {
		if (!isEmpty(requestCompany)) setCompany(requestCompany);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestCompany]);

	/**
	 * Sincroniza y agrega a mascara a nuevo valor Test ID
	 * @param {*} e Evento
	 */
	const syncChangesTestId = (e) => {
		e.preventDefault();
		let value = e.target.value;

		setCountItem(value);
		let guid = "";
		let str = value;
		const regex = /[^a-f0-9\s]+/g;
		str = str.replace(regex, "");
		str = str.trim();

		let one = str.slice(0, 8);
		let two = str.slice(8, 12);
		let three = str.slice(12, 16);
		let four = str.slice(16, 20);
		let five = str.slice(20, 32);

		two = two.length > 0 ? "-" + two : "";
		three = three.length > 0 ? "-" + three : "";
		four = four.length > 0 ? "-" + four : "";
		five = five.length > 0 ? "-" + five : "";
		guid = one + two + three + four + five;

		setDataForm({
			...dataForm,
			setId: guid,
		});
	};

	/**
	 * Valida longitud de TestId
	 * @param {*} e
	 */
	const countItems = (e) => {
		const numCar = countItem.length;
		if (disableForm === false) {
			if (numCar < 36) {
				const msg = `Faltán ${36 - numCar} letra(s)`;
				setMessagelength(msg);
			} else {
				setMessagelength();
			}
		}
	};

	/**
	 * Sincroniza nuevo valor checkBox Aplica facturación electronica
	 * @param {*} e Evento
	 */
	const syncCheckChanges = (e) => {
		const name = e.target.name;
		const checked = e.target.checked;

		let temp;

		if (checked === false) {
			temp = true;
			setMessagelength("");
		} else {
			temp = false;
		}

		setDisableForm(temp);
		setDataForm({
			...dataForm,
			[name]: checked,
		});
	};

	/**
	 * Redirige pagina DIAN
	 */
	const openDian = () => {
		window.open("https://catalogo-vpfe-hab.dian.gov.co/User/Login", "_blank");
	};

	/**
	 * Evento. boton volver
	 */
	const handleBack = () => {
		props.setActionSetBack(4);
	};

	/**
	 * Valida y guarda Información DIAN
	 * @param {*} e
	 */
	const onSubmit = (e) => {
		e.preventDefault();
		if (simpleValidator.current.allValid()) {
			setTypeLoading(1);
			const data = {
				Aplicafe: dataForm.eFacturar,
				TestId:
					dataForm.eFacturar === true ? dataForm.setId : requestCompany.testid,
				Wizard: "2",
			};

			props.updateDianData(data, completeSave);
		} else {
			simpleValidator.current.showMessages();
			forceUpdate(1);
		}
	};

	/**
	 * Guarda estado omitido wizard
	 */
	const omitir = () => {
		if (loadingSaveDianData !== true) {
			setTypeLoading(2);
			const body = {
				Aplicafe: false,
				TestId: requestCompany.testid,
				Wizard: "3", //Omitir
			};

			props.updateDianData(body, completeSave);
		}
	};

	/**
	 * Lee repuesta  despues de guadado de información DIAN
	 */
	const completeSave = (response) => {
		setTypeLoading(0);
		var data = {
			type: "warning",
			title: "Upss...!!!",
			subtitle: "Error no controlado",
			body: (
				<div
					dangerouslySetInnerHTML={{
						__html:
							"Ha ocurrido un error, por favor comuníquese con el administrador",
					}}
				></div>
			),
			modalImage: "warning",
			open: true,
			closeElement: "Cerrar",
			onCloseElement: 0,
		};

		if (response.statusCode === "252") {
			setModalData({
				...data,
				subtitle: "Test set id incorrecto",
				body: (
					<div
						dangerouslySetInnerHTML={{
							__html:
								'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y rectifica el identificador del set de pruebas.',
						}}
					></div>
				),
			});
		} else if (response.statusCode === "253") {
			setModalData({
				...data,
				subtitle:
					"Aun no tienes registrado a Colfactura como tu software de facturación electrónica en Dian",
				body: (
					<div
						dangerouslySetInnerHTML={{
							__html:
								'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y realiza el proceso de registro como facturador electrónico seleccionando a Gestión de Seguridad Electrónica S.A (Colfactura) como tu proveedor tecnológico. ¿Tienes dudas de cómo realizar el proceso? <a style="color:#16B1F3;" href="https://www.youtube.com/watch?v=Om59dTlnD6c" target="_blank">Apréndelo aquí</a>.',
						}}
					></div>
				),
			});
		} else if (response.statusCode === "250") {
			setModalData({
				type: "success",
				title: "Genial!!!",
				subtitle:
					"Actualizaste tus datos básicos de manera exitosa, sigamos facturando",
				body: <div dangerouslySetInnerHTML={{ __html: "" }}></div>,
				modalImage: "successBasicData",
				open: true,
				closeElement: "Continuar",
				onCloseElement: 0,
			});
			setRedirect(true);
		} else if (response.statusCode === "200" || response.statusCode === "201") {
			SuccessAlert(
				null,
				"Actualizaste tus datos básicos de manera exitosa, sigamos facturando",
				TIME_UPDATE_REGISTER
			);
			history.push(`/dashboard`);
		} else {
			setModalData({
				...data,
				subtitle: "Error no controlado",
				body: (
					<div
						dangerouslySetInnerHTML={{
							__html:
								"Ha ocurrido un error, por favor comuníquese con el administrador",
						}}
					></div>
				),
			});
		}
	};

	/**
	 * Cierra modal notificación
	 */
	const handleResponseModal = () => {
		setModalData({
			...modalData,
			type: "",
			title: "",
			subtitle: "",
			body: "",
			modalImage: "",
			open: false,
			closeElement: "",
			onCloseElement: "",
		});

		if (redirect === true) {
			history.push(`/dashboard`);
		}
	};

	return (
		<Fragment>
			<Grid container className={classes.generalContainer}>
				<Grid item xs={12} sm={7}>
					<Grid container className={classes.gridContainer}>
						<Grid item xs={false} sm={4} className={classes.leftGrid}>
							<Hidden smDown>
								<Button className={classes.btnBack} onClick={handleBack}>
									<img alt="" src={imgBtnBack}></img>Atras
								</Button>
							</Hidden>
						</Grid>

						<Grid item xs={12} sm={6} className={classes.centerGrid}>
							<img src={logo} alt="logo" className={classes.logo} />
							<form
								noValidate
								autoComplete="off"
								className={classes.formWrapper}
							>
								<Box>
									<Typography>Completa la información de tu cuenta</Typography>
									<Typography className={classes.txtCompany}>
										{company.razonsocial}
									</Typography>
								</Box>

								<SwitchForm
									name="eFacturar"
									titleOn="Voy a facturar electrónicamente"
									checked={dataForm.eFacturar}
									onChange={(e) => syncCheckChanges(e)}
									disabled={loadingSaveDianData === true ? true : false}
								/>

								<InputForm
									name="testid"
									label={disableForm === true ? "Test set id" : "Test set id *"}
									disabled={
										disableForm || loadingSaveDianData === true ? true : false
									}
									maxLength={36}
									value={dataForm.setId}
									nameValidator={"testSetId"}
									validator={simpleValidator.current}
									validateOptions={
										disableForm === true ? "void" : "required|uuid"
									}
									onChange={(e) => syncChangesTestId(e)}
									placeholder={"00000000-0000-0000-0000-000000000000"}
									onBlur={(e) => countItems(e)}
								/>
								<Typography className={classes.errorText}>
									{messagelength}
								</Typography>

								<Typography
									disabled={disableForm}
									className={classes.italicTxt}
									style={{ color: "#7DD1F8", opacity: disableForm ? 0.4 : 2 }}
								>
									Este es el identificador del set de pruebas que te entrega la
									DIAN cuando registras a Gestión de Seguridad Electrónica como
									tu proveedor tecnológico
								</Typography>

								<Button
									variant="contained"
									className={classes.btnNext}
									disabled={
										disableForm || loadingSaveDianData === true ? true : false
									}
									onClick={openDian}
								>
									Obtener mi Test Set ID
								</Button>

								<Divider light />
								<hr></hr>

								<Typography
									className={classes.italicTxt}
									style={{ color: "#FFFFFF", opacity: disableForm ? 0.4 : 2 }}
								>
									¿Tienes dudas de cómo realizar el proceso?
									<br></br>
									Apréndelo en el siguiente video
								</Typography>

								<div className={classes.containerVideo}>
									{disableForm === true ? (
										<div className={classes.disabledVideo}></div>
									) : (
										""
									)}
									<div className={classes.containerVideo}>
										<iframe
											src="https://www.youtube.com/embed/Om59dTlnD6c"
											frameborder="0"
											allow="autoplay; encrypted-media"
											allowfullscreen
											title="video"
											style={{
												width: "100%",
												height: 200,
											}}
										/>
									</div>
								</div>
							</form>
							<div style={{ paddingBottom: "5vh", width: "100%" }}>
								<ButtonWizard
									text={"Siguiente"}
									type={"button"}
									onClick={(e) => onSubmit(e)}
									loading={
										loadingSaveDianData === true && typeLoading === 1
											? true
											: false
									}
								/>
							</div>
						</Grid>

						<Grid item xs={false} sm={2} />
					</Grid>
				</Grid>
				<Grid item xs={12} sm={5} className={classes.imageWrapper}>
					{loadingSaveDianData === true && typeLoading === 2 ? (
						<div className={classes.containerProgress}>
							<CustomProgress color="#3f51b" />
						</div>
					) : (
						<Typography className={classes.avoidStep} onClick={omitir}>
							Omitir este paso
						</Typography>
					)}

					<Box className={classes.wizardImgWrapper}>
						<img src={wizardImage} alt="wizardImage" />
						<Typography className={classes.txtMsg}>
							Si vas a facturar electrónicamente, debes ingresar tu Test Set ID
							en cual vas a poder encontrar en la plataforma de la DIAN
						</Typography>
					</Box>
				</Grid>
			</Grid>

			{/* Modal Notificaciones */}
			<ResponseModal
				modalType={modalData.modalType}
				title={modalData.title}
				subtitle={modalData.subtitle}
				body={modalData.body}
				modalImage={modalData.modalImage}
				open={modalData.open}
				closeElement={modalData.closeElement}
				onCloseElement={handleResponseModal}
			/>
		</Fragment>
	);
};

Step6.propTypes = {
	getCompanyAction: PropTypes.func.isRequired,
	basicDataReducer: PropTypes.object.isRequired,
	getStatesAction: PropTypes.func.isRequired,
	configReducer: PropTypes.object.isRequired,
	updateDianData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	basicDataReducer: state.basicDataReducer,
	configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
	getCompanyAction,
	updateDianData,
})(Step6);
