import React, { useCallback, useEffect, useState } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { addChargeDocumentAction } from "../../../../actions/documentCartAction";

import {
  getChargesAction,
  loadChargeInFormAction,
} from "../../../../actions/newChargeAction";

import { ChargeCard } from "../../charges/chargeCard";
import { ChargesList } from "../../charges/chargesList";
import { CollapseList } from "../../ui/collapseList";
import { GlobalChargeCard } from "../../charges/globalChargeCard";

import generateId from "../../../../utils/generateId";

import { CustomModal } from "../../../../components/modal/CustomModal";
import { ChargeForm } from "../../charges/chargeForm";

export const ChargesSection = () => {
  const dispatch = useDispatch();

  const [expandCharges, setExpandCharges] = useState(false);
  const [openModalCharge, setOpenModalCharge] = useState(false);

  const { opdocumentocargos } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  const { charges, loadingGetCharges, activeDocumentCharge } = useSelector(
    (state) => state.newChargeReducer
  );

  useEffect(() => {
    dispatch(getChargesAction());
  }, [dispatch]);

  /**
   * * Alterna la expansión o contracción del panel de listado de cargos.
   * @returns {void}
   */
  const handleExpandCharges = () => {
    setExpandCharges(!expandCharges);
  };

  /**
   * * Agrega un nuevo cargo al documento seleccionado.
   * @param {*} charge - El cargo que se va a agregar al documento.
   * @returns {void}
   */
  const handleSelectionCharge = useCallback(
    (charge) => {
      setExpandCharges(false);
      let newCharge = {
        ...charge,
        id: generateId(),
        cantidad: 1,
        cargoid: charge.id,
      };
      dispatch(addChargeDocumentAction(newCharge));
    },
    [dispatch]
  );

  /**
   * * Cierra el modal del formulario de carga de nuevo cargo.
   * @returns {void}
   */
  const handleCloseModalCharge = () => {
    setOpenModalCharge(false);
    dispatch(loadChargeInFormAction(null));
  };

  /**
   * * Abre un modal con el formulario para la creación de un nuevo cargo.
   * @name handleCreateCharge
   * @returns {void}
   */
  const handleCreateCharge = useCallback(
    () => {
      setOpenModalCharge(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDocumentCharge]
  );

  /**
   * * Carga la información de un cargo en el formulario para su edición.
   * @param {*} charge - La información del cargo que se cargará en el formulario.
   * @returns {void}
   */
  const handleLoadChargeInForm = (charge) => {
    dispatch(loadChargeInFormAction(charge));
    setOpenModalCharge(true);
  };

  return (
    <>
      <Grid item lg={12} xs={12}>
        <CollapseList
          title={"Agregar cargo"}
          component={
            <ChargesList
              charges={charges}
              handleCreate={handleCreateCharge}
              handleSelection={handleSelectionCharge}
              loadingGetCharges={loadingGetCharges}
            />
          }
          onClick={handleExpandCharges}
          open={expandCharges}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={1}>
          {opdocumentocargos.map((charge) => (
            <Grid key={charge.id} item lg={12} xs={12}>
              {charge.esglobal ? (
                <GlobalChargeCard data={charge} />
              ) : (
                <ChargeCard
                  {...charge}
                  handleLoad={() => handleLoadChargeInForm(charge)}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CustomModal
        component={
          <ChargeForm handleCloseModal={() => setOpenModalCharge(false)} />
        }
        title={
          !!activeDocumentCharge ? "Editar cargo documento" : "Crear cargo"
        }
        onClose={handleCloseModalCharge}
        open={openModalCharge}
        width={400}
      />
    </>
  );
};
