import React from "react";
import {} from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import { FormatDecimal } from "../../components/common/formatDecimal";

const useStyles = makeStyles((theme) => ({
	styleCard: {
		backgroundColor: theme.palette.ninethBackgroundColor,
		borderRadius: "5px",
		color: theme.palette.primaryColor,
		border: "none",
		margin: 10,
		"& .MuiCardContent-root": {
			paddingBottom: 10,
			paddingLeft: 15,
			paddingRight: 15,
			paddingTop: 10,
		},
	},
	title: {
		color: theme.palette.primaryColor,
		fontSize: 18,
		borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
		fontWeight: 500,
	},
	content: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	icon: {
		color: theme.palette.primaryColor,
	},
	headerCard: {
		paddingTop: 5,
		paddingBottom: 0,
	},
	titleImage: {
		color: theme.palette.primaryColor,
		fontSize: "3.1vh",
		marginTop: "-20%",
	},
	containerImage: {
		width: "100%",
		backgroundRepeat: "no-repeat !important",
		mozBackgroundSize: "cover !important",
		webkitBackgroundSize: "cover !important",
		oBackgroundSize: "cover !important",
		paddingTop: "55% !important",
		backgroundSize: "cover !important",
		backgroundPosition: "center center",
		justifyContent: "center",
		display: "flex",
		borderRadius: 5,
		height: 120,
		maxWidth: 320,
	},
	containerValue: {
		display: "grid",
		borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
	},
	containerDiscount: {
		display: "grid",
		textAlign: "initial",
	},
	label: {
		fontSize: 10,
		color: "rgba(255, 255, 255, 0.5)",
	},
	value: {
		color: "#2CC63E",
		fontWeight: 600,
		fontSize: 15,
	},
	containerTax: {
		display: "contents",
	},
	labelTax: {
		color: "rgba(255, 255, 255, 0.5)",
		fontSize: 15,
		fontWeight: 300,
	},
	labelTotal: {
		color: "#FFFFFF",
		fontSize: 18,
		fontWeight: 600,
	},
	valueTotal: {
		color: "#2CC63E",
		fontSize: 21,
		fontWeight: 600,
	},
	valueDicount: {
		color: "#FFFFFF",
		fontWeight: 600,
		fontSize: 15,
	},
	line: {
		width: "100%",
		border: "1px solid rgba(255, 255, 255, 0.2)",
		height: 0.1,
		margin: "1px 0px",
	},
}));

export const ItemIntegrationCard = React.memo(
	({
		Cantidad,
		Descripcion,
		ValorTotal,
		ValorUnitario,
		PorcetanjeIva,
		PorcetanjeInc,
		Prefijo,
		Color,
		ValorDescuentos,
	}) => {
		const classes = useStyles();

		return (
			<Card
				className={`${classes.styleCard} animate__animated animate__fadeIn animate__faster`}
			>
				<CardHeader
					className={classes.headerCard}
					classes={{
						title: classes.title,
					}}
					title={Descripcion}
				/>

				<CardContent className={classes.content}>
					<Grid container spacing={1}>
						<Grid item xl={2} lg={3} xs={12}>
							<div
								className={classes.containerImage}
								style={{
									backgroundColor: !!Color ? Color : "#EC005F",
								}}
							>
								<span className={classes.titleImage}>{Prefijo}</span>
							</div>
						</Grid>

						<Grid item xl={10} lg={9} xs={12}>
							<Grid container spacing={1}>
								<Grid item lg={6} xs={12}>
									<div className={classes.containerValue}>
										<span className={classes.label}>Valor Unitario</span>
										<span className={classes.value}>
											<FormatDecimal value={ValorUnitario} prefix={"$"} />
										</span>
									</div>
								</Grid>
								<Grid item lg={6} xs={12}>
									<div className={classes.containerValue}>
										<span className={classes.label} style={{ color: "#FFFFF" }}>
											Cantidad
										</span>
										<span className={classes.value}>{Cantidad}</span>
									</div>
								</Grid>
								<Grid item lg={12} xs={12}>
									<div className={classes.containerTax}>
										<span className={classes.labelTax}>IVA: </span>
										<span className={classes.value}>
											<FormatDecimal value={PorcetanjeIva} suffix={"%"} />
										</span>
										&nbsp;&nbsp;&nbsp;
										<span className={classes.labelTax}>INC: </span>
										<span className={classes.value}>
											<FormatDecimal value={PorcetanjeInc} suffix={"%"} />
										</span>
									</div>
								</Grid>
							</Grid>
						</Grid>
						<div className={classes.line} />
						<Grid item lg={12} xs={12} style={{ textAlign: "end" }}>
							<Grid container>
								<Grid item lg={6} xs={12}>
									<div className={classes.containerDiscount}>
										<span className={classes.label} style={{ color: "#FFFFF" }}>
											Descuento:
										</span>
										<span className={classes.valueDicount}>
											<FormatDecimal value={ValorDescuentos} prefix={"$"} />
										</span>
									</div>
								</Grid>
								<Grid item lg={6} xs={12}>
									<div className={classes.containerTax}>
										<span className={classes.labelTotal}>Total: </span>
										<span className={classes.valueTotal}>
											<FormatDecimal value={ValorTotal} prefix={"$"} />
										</span>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
);
