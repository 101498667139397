import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Article from "../catalogCategory/article/article.view";
import Category from "../catalogCategory/category/category.view";
import CustomTabs from "../../components/tabs/tabs.component";

import { sendTabArticle } from "../../actions/articleActions";
import {
  getComponentsAction,
  ClearGetComponentsAction,
} from "../../actions/configAction";
import { cleanArticleCheckedAction } from "../../actions/articleActions";

const CatalogCategory = (props) => {
  const {
    getComponentsAction,
    ClearGetComponentsAction,
    cleanArticleCheckedAction,
  } = props;

  useEffect(() => {
    getComponentsAction(5);
    cleanArticleCheckedAction();
    // returned function will be called on component unmount
    return () => {
      ClearGetComponentsAction();
      cleanArticleCheckedAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = () => {
    return [
      {
        title: "Artículos",
        component: <Article type={0} history={props.history} />, //Todos
      },
      {
        title: "Favoritos",
        component: <Article type={1} history={props.history} />, //Favoritos
      },
      {
        title: "Categorías",
        component: <Category />,
      },
    ];
  };

  return (
    <div className="container-form">
      <CustomTabs tabs={tabs()} using={true} />
    </div>
  );
};

CatalogCategory.propTypes = {
  sendTabArticle: PropTypes.func.isRequired,
  getComponentsAction: PropTypes.func.isRequired,
  ClearGetComponentsAction: PropTypes.func.isRequired,
  cleanArticleCheckedAction: PropTypes.func.isRequired,
  generalReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  generalReducer: state.generalReducer,
});

export default connect(mapStateToProps, {
  sendTabArticle,
  getComponentsAction,
  ClearGetComponentsAction,
  cleanArticleCheckedAction,
})(CatalogCategory);
