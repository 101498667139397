import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Grid } from "@material-ui/core";

import BranchOfficeCard from "../../../components/card/branchOfficeCard.component";
import CollapsePanel from "../../../components/collapse/collapse.component";
import FormBranchofficeView from "./formBranchoffice.view";
import CustomProgress from "../../../components/Progress/progress.component";
import InputIcon from "../../../components/input/inputIcon.component";

import {
  loadBranchOfficeAction,
  getBranchOfficeAction,
  changeStepStatusAction,
  changeEditStatusAction,
  updateStateBranchOfficeAction,
  updatePrincipalBranchOfficeAction,
  refreshAfterChangeStatusAction,
  refreshAfterChangePrincipalAction,
} from "../../../actions/branchOfficeActions";

import {
  WarningAlert,
  ConfirmAlert,
  SuccessAlert,
} from "../../../helpers/alert.helpers";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import NotificationNotCreated from "../../../components/notification/notificationNotCreated.component";
import { getComponentName } from "../../../utils/general";
import isEmpty from "../../../utils/isEmpty";
import {
  MESSAGE_NEXT_CONFIRM,
  TIME_UPDATE_REGISTER,
} from "../../../config/config";

class Branch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchOfficesList: [],
      filteredBranchOfficesList: [],
      responseUpdateStateBranchOffice: {},
      editBranchStatus: false,
      txtFilter: "",
      expandPanelBranch: "",
      responseUpdateBranchOffice: {},
      responseSaveBranchOffice: {},
      updatePrincipalBranchResponse: {},
      loadingGetBranchOffices: false,
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getBranchOfficeAction("", false);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.branchOfficeReducer.branchOfficesList &&
      nextProps.branchOfficeReducer.branchOfficesList !==
        state.branchOfficesList
    )
      update.branchOfficesList =
        nextProps.branchOfficeReducer.branchOfficesList;

    if (
      !!nextProps.branchOfficeReducer.filteredBranchOfficesList &&
      nextProps.branchOfficeReducer.filteredBranchOfficesList !==
        state.filteredBranchOfficesList
    )
      update.filteredBranchOfficesList =
        nextProps.branchOfficeReducer.filteredBranchOfficesList;

    if (
      !isEmpty(nextProps.branchOfficeReducer.loadingGetBranchOffices) &&
      nextProps.branchOfficeReducer.loadingGetBranchOffices !==
        state.loadingGetBranchOffices
    )
      update.loadingGetBranchOffices =
        nextProps.branchOfficeReducer.loadingGetBranchOffices;

    if (
      !isEmpty(nextProps.branchOfficeReducer.editBranchStatus) &&
      nextProps.branchOfficeReducer.editBranchStatus !== state.editBranchStatus
    ) {
      update.editBranchStatus = nextProps.branchOfficeReducer.editBranchStatus;
    }
    if (
      !!nextProps.branchOfficeReducer.responseSaveBranchOffice &&
      nextProps.branchOfficeReducer.responseSaveBranchOffice !==
        state.responseSaveBranchOffice
    )
      update.responseSaveBranchOffice =
        nextProps.branchOfficeReducer.responseSaveBranchOffice;

    if (
      !!nextProps.branchOfficeReducer.responseUpdateBranchOffice &&
      nextProps.branchOfficeReducer.responseUpdateBranchOffice !==
        state.responseUpdateBranchOffice
    )
      update.responseUpdateBranchOffice =
        nextProps.branchOfficeReducer.responseUpdateBranchOffice;

    if (
      !!nextProps.branchOfficeReducer.responseUpdateStateBranchOffice &&
      nextProps.branchOfficeReducer.responseUpdateStateBranchOffice !==
        state.responseUpdateStateBranchOffice
    )
      update.responseUpdateStateBranchOffice =
        nextProps.branchOfficeReducer.responseUpdateStateBranchOffice;

    if (
      !!nextProps.branchOfficeReducer.updatePrincipalBranchResponse &&
      nextProps.branchOfficeReducer.updatePrincipalBranchResponse !==
        state.updatePrincipalBranchResponse
    )
      update.updatePrincipalBranchResponse =
        nextProps.branchOfficeReducer.updatePrincipalBranchResponse;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.responseSaveBranchOffice !== this.state.responseSaveBranchOffice
    ) {
      if (
        this.state.responseSaveBranchOffice.statusCode === "200" ||
        this.state.responseSaveBranchOffice.statusCode === "201"
      ) {
        this.setState({
          expandPanelBranch: "",
        });
      }
    }

    if (
      prevState.responseUpdateBranchOffice !==
      this.state.responseUpdateBranchOffice
    ) {
      if (
        this.state.responseUpdateBranchOffice.statusCode === "200" ||
        this.state.responseUpdateBranchOffice.statusCode === "201"
      ) {
        this.setState({
          expandPanelBranch: "",
        });
      }
    }

    if (
      prevState.responseUpdateStateBranchOffice !==
      this.state.responseUpdateStateBranchOffice
    ) {
      if (
        this.state.responseUpdateStateBranchOffice.statusCode === "200" ||
        this.state.responseUpdateStateBranchOffice.statusCode === "201"
      ) {
        SuccessAlert(
          null,
          getComponentName(
            this.state.components,
            25,
            94,
            "Actualizaste la Sucursal  de manera exitosa, sigamos facturando."
          ),
          TIME_UPDATE_REGISTER
        );

        let id = this.state.responseUpdateStateBranchOffice.id;
        let idstate = this.state.responseUpdateStateBranchOffice.idstate;
        this.props.refreshAfterChangeStatusAction(
          id,
          idstate,
          this.state.txtFilter
        );
      }
    }

    if (
      prevState.updatePrincipalBranchResponse !==
      this.state.updatePrincipalBranchResponse
    ) {
      if (
        this.state.updatePrincipalBranchResponse.statusCode === "200" ||
        this.state.updatePrincipalBranchResponse.statusCode === "201"
      ) {
        SuccessAlert(
          null,
          getComponentName(
            this.state.components,
            25,
            94,
            "Actualizaste la Sucursal de manera exitosa, sigamos facturando."
          ),
          TIME_UPDATE_REGISTER
        );

        let idOldPrincipal = this.state.updatePrincipalBranchResponse
          .idOldPrincipal;
        let idNewPrincipal = this.state.updatePrincipalBranchResponse
          .idNewPrincipal;
        this.props.refreshAfterChangePrincipalAction(
          idOldPrincipal,
          idNewPrincipal,
          this.state.txtFilter
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.loadBranchOfficeAction(null);
  }

  /**
   * Escucha cambios en switch de estado de susucrsal y
   * sucursal principal
   * @param {*} e Evento
   * @param {*} typeChange Tipo de cambio (estado, principal)
   * @param {*} data Iinformación sucursal
   */
  onChange = (e, typeChange, data) => {
    const principal = this.hasPrincipal();
    if (typeChange === 1) {
      if (data.idstate === 0) {
        if (!!principal) {
          const setPrincipal = (confirmed) => {
            if (confirmed) {
              this.props.updatePrincipalBranchOfficeAction(
                principal.id,
                data.id
              );
            }
          };
          if (data.id !== principal.id) {
            ConfirmAlert(
              "¿Desea cambiar la sucursal principal?",
              setPrincipal,
              MESSAGE_NEXT_CONFIRM
            );
          } else {
            WarningAlert("Upss...!!!", "Debe existir una sucursal principal.");
          }
        }
      } else {
        WarningAlert(
          "Upss...!!!",
          "La sucursal debe estar activa para ser una sucursal principal."
        );
      }
    } else if (typeChange === 2) {
      if (!!principal) {
        if (principal.id !== data.id) {
          this.props.updateStateBranchOfficeAction(
            data.id,
            data.idstate === 0 ? 1 : 0
          );
        } else {
          WarningAlert(
            "Upss...!!!",
            "La sucursal principal no puede estar inactiva."
          );
        }
      }
    }
  };

  /**
   * Consulta sucursal principal
   */
  hasPrincipal() {
    const principal = this.state.branchOfficesList.find(
      (b) => b.principal === true
    );
    return principal;
  }

  /**
   * Evento, escucha evento para edición de sucursal,
   * consume función para cargar información en Redux
   * @param {*} e Evento
   * @param {*} data Infromación sucursal
   */
  editBranchOffice = (e, data) => {
    e.preventDefault();
    this.props.loadBranchOfficeAction(data);
    this.setState({
      expandPanelBranch: "panel",
    });
    window.scrollTo(0, 0);
  };

  /**
   * Evento, Cambia estado de collapse expande o contrae
   */
  onClickCollapseChange = () => {
    this.setState({
      expandPanelBranch:
        this.state.expandPanelBranch === "panel" ? "" : "panel",
    });

    if (
      this.state.expandPanelBranch === "panel" &&
      this.state.editBranchStatus === true
    ) {
      this.props.loadBranchOfficeAction({});
    }
  };

  /**
   * Evento, filtra listado se sucursales cuado detecta cambio
   * en filtro.
   * @param {*} e Evento
   */
  onChangeFilter(e) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      txtFilter: value,
    });
    this.props.getBranchOfficeAction(value, false);
  }

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid item xs={12}>
            <CollapsePanel
              component={<FormBranchofficeView />}
              title={
                this.state.editBranchStatus
                  ? "Editar sucursal"
                  : "Crear nueva sucursal"
              }
              titleOpen={
                this.state.editBranchStatus
                  ? "Editar sucursal"
                  : "Nueva sucursal"
              }
              open={this.state.expandPanelBranch}
              onClick={() => this.onClickCollapseChange()}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <InputIcon
              name={"txtFilter"}
              label={"Buscar sucursal"}
              value={this.state.txtFilter}
              onChange={(e) => {
                this.onChangeFilter(e);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {this.state.loadingGetBranchOffices === true ? (
            <Grid item lg={12} xs={12}>
              <Grid container alignItems="center" justify="center">
                <CustomProgress />
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              {this.state.filteredBranchOfficesList.length > 0 ? (
                <Fragment>
                  {this.state.filteredBranchOfficesList.map((data, index) => (
                    <Grid item key={index} lg={4} xs={12}>
                      <BranchOfficeCard
                        data={data}
                        onClick={this.editBranchOffice}
                        onChange={this.onChange}
                      />
                    </Grid>
                  ))}
                </Fragment>
              ) : (
                <Grid item lg={12} xs={12}>
                  <NotificationNotCreated
                    text={"No se han encontrado resultados..."}
                  />
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      </Fragment>
    );
  }
}

Branch.propTypes = {
  refreshAfterChangeStatusAction: PropTypes.func.isRequired,
  refreshAfterChangePrincipalAction: PropTypes.func.isRequired,
  getBranchOfficeAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  branchOfficeReducer: state.branchOfficeReducer,
  basicDataReducer: state.basicDataReducer,
});

export default connect(mapStateToProps, {
  loadBranchOfficeAction,
  getBranchOfficeAction,
  changeStepStatusAction,
  changeEditStatusAction,
  updateStateBranchOfficeAction,
  updatePrincipalBranchOfficeAction,
  refreshAfterChangeStatusAction,
  refreshAfterChangePrincipalAction,
})(Branch);
