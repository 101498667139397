import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";

import CustomProgress from "../../components/Progress/progress.component";
import ResponseModal from "../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../components/modal/responseRedirectModal.component";
import { DocumentManage } from "./head/documentManage.view";
import { DocumentCartManage } from "./cart/documentCartManage.view";
import { DocumentReview } from "./review/documentReview.view";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import ArchiveModal from "../../components/modal/archiveModal.component";

import {
  createElectronicInvoice,
  updateOpenModalItem,
  getDocumentPreviewAction,
  cleanLicenseBaseAction,
} from "../../actions/invoiceActions";
import {
  standOutDocumentAction,
  cleanDocumentCreateAction,
} from "../../actions/documentAction";

import { CLAIM_DOCUMENTS } from "../../config/config";
import { ConfirmAlert, WarningAlert } from "../../helpers/alert.helpers";
import { warningToast } from "../../helpers/toast.helpers";
import { checkTotalDocument } from "../../helpers/validateDocument";

import { generateRequestDocument } from "../../helpers/generateRequestDocument";

import { MESSAGE_NEXT_CONFIRM } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: "white",
  },
  container: {
    backgroundColor: "#232323",
    padding: 15,
    borderRadius: 5,
    overflow: "hidden",
    height: "auto",
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {" "}
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}{" "}
    </div>
  );
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function getSteps() {
  return ["Información de Factura", "Artículos", "Revisar Factura"];
}

const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

export const InvoiceQuoteView = ({ HandleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = useState(
    initialModal
  );
  const [modalResponse, setModalResponse] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
    closeFromModalBody: false,
  });

  const { openModalItem: modal, licenseBase } = useSelector(
    (state) => state.invoiceReducer
  );
  const documentdata = useSelector((state) => state.documentReducer);
  const { favorito, opdocumentosreferencia } = documentdata;
  const totals = useSelector((state) => state.documentCartReducer);
  const {
    showCatalog,
    valorapagar,
    opdetalledocumento,
    valorcargosaplicados,
    valorbase,
  } = totals;

  /**
   * Desmontado componente
   */
  useEffect(() => {
    return () => {
      dispatch(cleanDocumentCreateAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (loading) {
      setModalResponse({
        type: "success",
        title: "",
        subtitle: "Generando documento, por favor espere...",
        body: (
          <>
            {" "}
            <CustomProgress />
            {"Procesando"}{" "}
          </>
        ),
        modalImage: "noimagen",
        open: true,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
  }, [licenseBase]);

  /**
   * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    dispatch(cleanLicenseBaseAction());
  };

  /**
   * Avanza paso siguiente
   */
  const handleNextStep = (step) => {
    if (step === 2) {
      const valid = checkTotalDocument(valorapagar, opdocumentosreferencia);
      if (!valid) {
        warningToast(
          "Valor anticipo no valido.",
          "El valor del anticipo es superior al valor de la factura. Por favor verifique la información."
        );
        return;
      }
    }

    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < step; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
    setActiveStep(step);
  };

  /**
   * Regresa a paso anterior
   * @param {*} step
   */
  const handleBackStep = (step) => {
    setActiveStep(step - 1);
  };

  /**
   * Desctacar documento
   */
  const handleStandOut = () => {
    dispatch(standOutDocumentAction());
  };

  /**
   * Marca paso a completo
   * @param {*} step
   * @returns
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  /**
   * Valida información de documento antes de guardar
   */
  const validateDocument = () => {
    let errorMessages = [];
    if (opdetalledocumento.length === 0) {
      errorMessages.push("Debes seleccionar al menos un producto.");
    } else {
      if (valorapagar < 0)
        errorMessages.push(
          "El valor a pagar de la factura no puede ser negativo."
        );

      //Campos obligatorios por item
      let valid = true;
      opdetalledocumento.forEach((item) => {
        if (item.esregalo) {
          if (!item.asumeresponsabilidad || !item.valorreferencia) {
            valid = false;
          }
        } else {
          if (item.valorneto === 0 || !item.valorneto) {
            valid = false;
          }
        }
      });

      if (!valid) errorMessages.push("Tienes campos pendientes por completar.");

      //Valores anticipo
      let anticipovalido = checkTotalDocument(
        valorapagar,
        opdocumentosreferencia
      );
      if (!anticipovalido)
        errorMessages.push(
          "El valor del anticipo es superior al valor de la factura. Por favor verifique la información."
        );

      //Valores cargos
      if (valorbase < valorcargosaplicados) {
        errorMessages.push(
          "El valor de los cargos no puede ser superior a la factura con descuentos"
        );
      }
    }

    if (errorMessages.length > 0) {
      let message = errorMessages.join("       ");
      WarningAlert("Upss...!", message);
      return false;
    }

    return true;
  };

  /**
   * Envia documento para registro
   */
  const handleSaveDocument = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      const deleteData = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          let document = generateRequestDocument(documentdata, totals);
          dispatch(
            createElectronicInvoice(
              document,
              readResponseSendInvoice,
              readErrorSendInvoice
            )
          );
        }
      };
      ConfirmAlert(
        `¿Estás seguro de guardar y enviar la factura?`,
        deleteData,
        MESSAGE_NEXT_CONFIRM
      );
    }
  };

  /**
   * Genera previsualización de documento
   * @param {*} e
   */
  const handlePreviewDocument = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      setOpenModalFile(true);
      setLoadingFile(true);
      let document = generateRequestDocument(documentdata, totals);
      dispatch(getDocumentPreviewAction(document, readResponseGetFile));
    }
  };

  /**
   * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response);
    setLoadingFile(false);
  };

  /**
   * Cierra modal previsualizació documento
   */
  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
  };

  /**
   * Cancelar creación documento
   */
  const handleCancelDocument = () => {
    HandleBack();
  };

  /**
   * Lee respuesta de envio documento
   * @param {*} response
   */
  const readResponseSendInvoice = (response) => {
    if (response.data.statusCode === "201") {
      setLoading(false);
      setModalResponse({
        type: "success",
        title: "Fenomenal!!!",
        subtitle: "Has generado una nueva factura",
        body: (
          <div>
            <div>
              {response.data.statusMessage.indexOf("Rechazo") !== -1
                ? "Factura generada con error al enviar a Dian"
                : "Proceso Exitoso"}
            </div>
            <div className="fenomenalWhiteLittle">
              {response.data.statusMessage}{" "}
              <span className="fenomenalBlueLittle">
                No. {response.data.result}
              </span>
            </div>
          </div>
        ),
        modalImage: "success",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    } else {
      setLoading(false);
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>{"Factura generada con error al enviar a Dian"}</div>
            <div className="fenomenalWhiteLittle">
              {response.data.statusMessage} Factura Generada:{" "}
              <span className="fenomenalBlueLittle">
                No. {response.data.result}
              </span>
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: false,
      });
    }
  };

  /**
   * Lee error envio documento
   * @param {*} response
   */
  const readErrorSendInvoice = (response) => {
    setLoading(false);
    if (
      response.data.statusCode === "404" &&
      response.data.statusMessage === "Unauthorized, no quota"
    ) {
      setModalResponse({
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    } else {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>
              {JSON.stringify(
                response === undefined
                  ? "Error inesperado"
                  : response.data.statusMessage
              )}
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    }
  };

  /**
   * Cierra modal mensaje envio documento
   * @param {*} rta
   */
  const handleResponseModal = (rta) => {
    if (rta === 1) {
      HandleBack();
    }

    setModalResponse({
      type: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: 0,
      closeFromModalBody: false,
    });
  };

  /**
   * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    dispatch(updateOpenModalItem(false));
  };

  const footPage = (step) => {
    return (
      <>
        {step === 0 ? (
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <div className="bottomButtonsInvoiceRevision">
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleStandOut}
                    >
                      {favorito ? <StarIcon /> : <StarBorderIcon />}
                      <span style={{ marginTop: 5 }}>Favorito</span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleCancelDocument}
                    >
                      <ClearIcon />
                      <span style={{ marginTop: 5 }}>Cancelar</span>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <div className="bottomButtonsInvoiceRevision">
                <Grid container spacing={1}>
                  <Grid item lg={3} xs={3} style={{ textAlign: "left" }}>
                    <IconButton
                      classes={{
                        root: classes.button,
                        label: classes.labelHorizontal,
                      }}
                      variant="raised"
                      disableRipple={true}
                      onClick={() => handleBackStep(step)}
                      style={{ marginLeft: 10, marginTop: 10 }}
                    >
                      <ArrowBackIosIcon />
                      <span style={{ marginLeft: 5, fontSize: 14 }}>
                        Volver
                      </span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleStandOut}
                    >
                      {favorito ? <StarIcon /> : <StarBorderIcon />}
                      <span style={{ marginTop: 5 }}>Favorito</span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handlePreviewDocument}
                    >
                      <VisibilityIcon />
                      <span style={{ marginTop: 5 }}>Previsualizar</span>
                    </IconButton>
                  </Grid>

                  <Grid item lg={3} xs={3}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleCancelDocument}
                      style={{ marginRight: 10 }}
                    >
                      <ClearIcon />
                      <span style={{ marginTop: 5 }}>Cancelar</span>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  /**
   * Componente por paso
   * @param {*} step
   * @returns
   */
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <DocumentManage
              handleChangeStep={handleNextStep}
              typedocument={"FA"}
              origin={"CO"}
            />
            {footPage(0)}
          </>
        );
      case 1:
        return (
          <>
            <DocumentCartManage typedocument={"FA"} />
            {!showCatalog && (
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xl={11} lg={12} sm={12} xs={12}>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={() => handleNextStep(2)}
                        text={"Revisar factura"}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={handleSaveDocument}
                        text={"Enviar factura"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {footPage(1)}
          </>
        );
      case 2:
        return (
          <>
            <DocumentReview typedocument={"CO"} />
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xl={11} lg={12} sm={12}>
                <Grid container spacing={1} justify="center">
                  <Grid item lg={4} xs={12}>
                    <ButtonPrimary
                      type="button"
                      onClick={handleSaveDocument}
                      text={"Enviar factura"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {footPage(2)}
          </>
        );
      default:
        return <span>{"Unknown step"}</span>;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <Stepper
          alternativeLabel
          nonLinear
          activeStep={activeStep}
          connector={<QontoConnector />}
          className={classes.root}
        >
          {getSteps().map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            return (
              <Step
                key={label}
                {...stepProps}
                className={activeStep === index ? classes.activeButton : ""}
              >
                <StepButton completed={isStepComplete(index)} {...buttonProps}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    <label className="stepper-title">{label}</label>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>{getStepContent(activeStep)}</div>
      </div>
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={
          "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
        }
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />
      <ArchiveModal
        loading={loadingFile}
        open={openModalFile}
        pdfBase64={base64File}
        handleClose={handleCloseFileModal}
      />
    </>
  );
};
