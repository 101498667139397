import React from "react";

import { useDispatch } from "react-redux";

import { Grid, IconButton, makeStyles } from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import { Delete } from "@material-ui/icons";

import { deleteCustomerEquivalentDocAction } from "../../../../actions/equivalentDocAction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#3B3B3B",
    borderRadius: "4px",
    padding: "10px 15px",
  },
  wordBreak: {
    color: "#FFF",
    wordBreak: "break-word",
  },
  legalName: {
    color: "#2CC63E",
    fontFamily: "Muli",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  textLeft: {
    color: "#FFF",
    fontFamily: "Muli",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  textRight: {
    color: "#FFF",
    fontFamily: "Muli",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  iconsDetail: {
    display: "flex",
  },
  colorIcon: {
    color: "#2CC63E",
  },
  iconButton: {
    padding: "0px 10px",
    cursor: "pointer",
  },
  iconAction: {
    color: "white",
    "&:hover": {
      color: "#2CC63E",
    },
  },
  iconContainer: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const FullCustomer = ({
  address,
  dianTypePerson,
  document,
  email,
  fullAddress,
  legalName,
  phone,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  /**
   * * Elimina el cliente del documento equivalente seleccionado que se muestra en el componente.
   */
  const handleDelete = () => {
    dispatch(deleteCustomerEquivalentDocAction());
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Grid item xs={12} className={classes.iconsDetail}>
          <div className={`${classes.wordBreak} ${classes.legalName}`}>
            {legalName}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.iconsDetail}>
          <div className={`${classes.wordBreak} ${classes.textLeft}`}>
            {document}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.iconsDetail}>
          <div className={`${classes.wordBreak} ${classes.textLeft}`}>
            {dianTypePerson}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={5} container>
        <Grid item xs={12} className={classes.iconsDetail}>
          <EmailIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={`${classes.wordBreak} ${classes.textRight}`}>
            {email}
          </div>
        </Grid>
        {!!phone && (
          <Grid item xs={12} className={classes.iconsDetail}>
            <PhoneIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={`${classes.wordBreak} ${classes.textRight}`}>
              {phone}
            </div>
          </Grid>
        )}

        {!!address && (
          <Grid item xs={12} className={classes.iconsDetail}>
            <RoomIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={`${classes.wordBreak} ${classes.textRight}`}>
              {fullAddress}
            </div>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={1} md={1} lg={1} className={classes.iconContainer}>
        <IconButton className={classes.iconButton} onClick={handleDelete}>
          <Delete className={classes.iconAction} />
        </IconButton>
      </Grid>
    </>
  );
};
