import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ClearIcon from "@material-ui/icons/Clear";
import Link from "@material-ui/core/Link";

import StyledCheckbox from "../../../../components/checkForm/styledCheckbox.component";
import InputIcon from "../../../../components/input/inputIcon.component";
import CustomProgress from "../../../../components/Progress/progress.component";
import InvoiceCard from "../components/InvoiceCard.component.js";
import PopoverModal from "../../../../components/modal/popoverModal.component";
import BulkLoadStatus from "../../../../components/bulkLoadStatus.component";
import ButtonPrimary from "../../../../components/button/buttonPrimary.component";
import filterImage from "../../../../../src/images/filter.png";
import { SuccessAlertConfirm } from "../../../../helpers/alert.helpers";

import {
  updateStateAction,
  getUploadedInvoiceAction,
  cleanStateGetBulkLoadAction,
  changeStepAction,
  cleanStatusAction,
  getDocumentsAction,
  clearResultSignalAction,
  cleanStatusUploadAction,
} from "../../../../actions/invoiceActions";

import {
  QUANTITY_DATA_DOCUMENT,
  TIME_CREATE_REGISTER,
} from "../../../../config/config";
import { enterpriseIdHeader } from "../../../../actions/authActions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 5,
    height: 90,
  },
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
  inputFile: {
    display: "none",
  },
  btnBlue: {
    width: "20%",
    float: "right",
  },
  styleIcon: {
    color: "#FFFFFF",
    cursor: "pointer",
  },
  styleText: {
    fontFamily: theme.palette.fontFamily,
    color: "#FFFFFF",
    fontSize: 14,
  },
  datanotfound: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    textAlign: "center",
  },
}));

const IssuedInvoices = (props) => {
  const classes = useStyles();
  const {
    informationBulk,
    setActiveStep,
    activeStep,
    handleCreate,
    stateBulk,
    stepChange,
    HandleBulkUpload,
    invoices,
  } = props;

  const [uploadedRecords, setUploadedRecords] = useState([]);
  const [uploadedRecordsFilter, setUploadedRecordsFilter] = useState([]);
  const [txtFilter, setTxtFilter] = useState("");
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
  const [sinErrores, setSinErrores] = useState(false);
  const [conErrores, setConErrores] = useState(false);
  const [cargueId, setCargueId] = useState(null);
  const [loadingListData, setLoadingListData] = useState(true);

  useEffect(() => {
    getRecordsDB(informationBulk);
  }, [informationBulk]);

  useEffect(() => {
    if (!informationBulk && stepChange === 2) handleCreate();
  }, [informationBulk, handleCreate, stepChange]);

  const getRecordsDB = (uploadedRecordsTmp) => {
    if (uploadedRecordsTmp && uploadedRecordsTmp.factura) {
      const json = uploadedRecordsTmp.factura.map((item) => ({
        id: item.id,
        registro: JSON.parse(item.objeto),
        numero: item.numero,
        fechaAcuseDian: item.fechaacuseDian,
        idFactura: item.idfactura,
        estadoDocumento: item.estadoDocumentoId,
      }));

      var resultJson = json.filter(
        (item) => item.registro.datosCargueMasivo.erroresFactura.length === 0
      );

      setCargueId(uploadedRecordsTmp.id);
      setUploadedRecords(resultJson);
      setUploadedRecordsFilter(resultJson);
      setLoadingListData(false);
    }
  };

  /**
   * Función donde se obtiene dia y mes de la fecha de Acuse Dian
   * @param {*} input
   * @param {*} length
   * @param {*} padding
   * @returns
   */
  const pad = (input, length, padding) => {
    var str = input + "";
    return length <= str.length ? str : pad(padding + str, length, padding);
  };

  /**
   * Función para realizar búsqueda en el paso 3 de cargue masivo.
   * @param {} event
   */

  const onChangeFilter = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setTxtFilter(value);

    setUploadedRecordsFilter(
      uploadedRecords.filter((item) => {
        var dateDian = `${pad(
          new Date(item.fechaAcuseDian).getDate(),
          2,
          "0"
        )}/${pad(
          new Date(item.fechaAcuseDian).getMonth() + 1,
          2,
          "0"
        )}/${new Date(item.fechaAcuseDian).getFullYear()}`;

        const factura = item.registro.datosCargueMasivo;
        if (item.numero !== null) {
          return (
            factura.estado.toLowerCase().includes(value.toLowerCase()) ||
            factura.cliente.razonSocial
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            factura.cliente.tipo.toLowerCase().includes(value.toLowerCase()) ||
            factura.cliente.numero
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            factura.cliente.digito.includes(value.toLowerCase()) ||
            factura.medioPago.toLowerCase().includes(value.toLowerCase()) ||
            factura.formaPago.toLowerCase().includes(value.toLowerCase()) ||
            factura.estado.toLowerCase().includes(value.toLowerCase()) ||
            factura.valorTotal.toString().includes(value) ||
            factura.cantidadArticulos.toString().includes(value) ||
            item.numero.toLowerCase().includes(value.toLowerCase()) ||
            factura.fechaFactura.toLowerCase().includes(value.toLowerCase()) ||
            dateDian.toLowerCase().includes(value.toLowerCase())
          );
        }
        return (
          factura.estado.toLowerCase().includes(value.toLowerCase()) ||
          factura.cliente.razonSocial
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          factura.cliente.tipo.toLowerCase().includes(value.toLowerCase()) ||
          factura.cliente.numero.toLowerCase().includes(value.toLowerCase()) ||
          factura.cliente.digito.includes(value.toLowerCase()) ||
          factura.medioPago.toLowerCase().includes(value.toLowerCase()) ||
          factura.formaPago.toLowerCase().includes(value.toLowerCase()) ||
          factura.estado.toLowerCase().includes(value.toLowerCase()) ||
          factura.valorTotal.toString().includes(value) ||
          factura.cantidadArticulos.toString().includes(value) ||
          factura.fechaFactura.toLowerCase().includes(value.toLowerCase())
        );
      })
    );
  };

  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  const cleanFilter = () => {
    setConErrores(false);
    setSinErrores(false);
    setUploadedRecordsFilter(uploadedRecords);
  };
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Carga identificador  de documento por defecto, para realizar consulta
   * @param {*} id Identificador documento
   */
  const selectDefaultItem = (id) => {
    // setDocumentSelected(id);
  };

  const finishProcess = () => {
    var result = informationBulk.factura.filter((e) => {
      return e.estadoDocumentoId === 4;
    });
    result = result.length;
    let messageError =
      "Envío de facturas finalizado, podrás verlas en el listado de facturas. " +
      result +
      " registro subidos con errores se borrarán, no olvides corregirlos y cargarlos en un nuevo proceso.";
    let message =
      "Envío de facturas finalizado, podrás verlas en el listado de facturas. Al aceptar, el sistema retorna al listado de facturas de la pantalla principal con la información actualizada.";

    const send = async (confirmed) => {
      if (confirmed) {
        let filterData = {
          Fechadesde: null,
          Fechahasta: null,
          Empresaid: enterpriseIdHeader(),
          Filtro: "",
          Skip: 0,
          Limit: QUANTITY_DATA_DOCUMENT,
          Tipodocumento: 1, //Facturas
          Editada: false,
          Destacadas: false,
          Pagadas: false,
          Pendientes: false,
          Vencidas: false,
          Masivo: false,
        };

        props.updateStateAction(3, cargueId, 0);
        props.changeStepAction(0);
        props.cleanStateGetBulkLoadAction();
        props.cleanStatusAction();
        //props.updateStoreAction()
        props.cleanStatusUploadAction(true);
        props.getDocumentsAction(filterData, invoices, selectDefaultItem);
        props.clearResultSignalAction();

        closeStep();
      } else {
        console.log("");
      }
    };

    SuccessAlertConfirm(
      result > 0 ? messageError : message,
      send,
      TIME_CREATE_REGISTER
    );
  };

  const closeStep = () => {
    if (!!handleCreate) {
      handleCreate();
    }
  };

  const getDisabled = () => {
    if (informationBulk.cantidad === informationBulk.procesada) {
      return false;
    }
    if (stateBulk === 2 && stepChange === 2) {
      return false;
    }
    return true;
  };

  const consultaFactura = (e) => {
    var array = [];
    if (e.target.name === "chkConErrores") {
      setConErrores(e.target.checked);

      if (e.target.checked === true) {
        array = uploadedRecordsFilter.filter(
          (registro) =>
            registro.estadoDocumento === 1 ||
            registro.estadoDocumento === 3 ||
            registro.estadoDocumento === 4
        );

        setUploadedRecordsFilter(array);
      } else {
        setUploadedRecordsFilter(uploadedRecords);
      }
    }

    if (e.target.name === "chkSinErrores") {
      setSinErrores(e.target.checked);
      if (e.target.checked === true) {
        array = uploadedRecordsFilter.filter(
          (registro) => registro.estadoDocumento === 2
        );
        setUploadedRecordsFilter(array);
      } else {
        setUploadedRecordsFilter(uploadedRecords);
      }
    }
  };

  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={sinErrores}
              onClick={(e) => consultaFactura(e)}
              name="chkSinErrores"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cargadas Correctamente
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={conErrores}
              onClick={(e) => consultaFactura(e)}
              name="chkConErrores"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cargadas con errores
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilter}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={classes.divRegister}>
        {!loadingListData && (
          <BulkLoadStatus
            cargueId={cargueId}
            cantidad={
              informationBulk.cantidad !== undefined
                ? informationBulk.cantidad
                : 0
            }
            procesadas={
              informationBulk.procesada !== undefined
                ? informationBulk.procesada
                : 0
            }
            estadoCargue={
              informationBulk.estado !== undefined ? informationBulk.estado : 1
            }
            global={false}
            step={activeStep}
            setActiveStep={setActiveStep}
            view={2}
            HandleBulkUpload={HandleBulkUpload}
          />
        )}
      </div>
      <br />
      <div>
        <Grid container>
          <Grid container spacing={1}>
            <Grid
              item
              lg={11}
              xs={11}
              md={11}
              xl={11}
              className={classes.txtFilter}
            >
              <InputIcon
                id={"txtFilter"}
                label={""}
                value={txtFilter}
                onChange={(e) => {
                  onChangeFilter(e);
                }}
              />
              <br />
            </Grid>

            <div
              className="buttonInvoiceBehavior"
              style={{ paddingTop: 15 }}
              onClick={handleOpenModalChecks}
            >
              <img alt={"Filtrar"} src={filterImage}></img>
            </div>

            {loadingListData === true ? (
              <Grid container>
                <Grid item lg={4} xs={4}></Grid>
                <Grid item lg={4} xs={4}>
                  <CustomProgress />
                </Grid>
                <Grid item lg={4} xs={4}></Grid>
              </Grid>
            ) : !!uploadedRecordsFilter && uploadedRecordsFilter.length > 0 ? (
              uploadedRecordsFilter.map((item, index) => (
                <Grid item lg={12} xs={12}>
                  <InvoiceCard
                    key={index}
                    dataObject={item.registro}
                    numero={item.numero}
                    fechaAcuseDian={item.fechaAcuseDian}
                    idFactura={item.idFactura}
                    step={activeStep}
                    estado={item.estadoDocumento}
                  ></InvoiceCard>
                </Grid>
              ))
            ) : (
              <Grid container className={classes.datanotfound}>
                <Grid container className={classes.datanotfound}>
                  <div className={classes.styleText}>
                    No se han encontrado resultados...
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3}>
              <Grid item lg={8} md={8}></Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ButtonPrimary
                  text={"Finalizar"}
                  onClick={finishProcess}
                  disabled={getDisabled()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {stateBulk === 2 || stateBulk === 3 ? (
          ""
        ) : (
          <div className="bottomButtonsInvoiceRevision">
            <Grid container>
              <Grid item lg={4} md={4} sm={4} />
              <Grid item lg={4} md={4} sm={4}>
                <ClearIcon
                  className={classes.styleIcon}
                  onClick={() => closeStep()}
                ></ClearIcon>
                <br></br>
                <div className="textButtonsInvoice">Cerrar</div>
              </Grid>
              <Grid item lg={4} md={4} sm={4} />
            </Grid>
          </div>
        )}
      </div>
      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={265}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
    </Fragment>
  );
};

IssuedInvoices.propTypes = {};

const mapStateToProps = (state) => ({
  informationBulk: state.invoiceReducer.informationBulkLoad,
  stateBulk: state.invoiceReducer.stateBulkLoad,
  stepChange: state.invoiceReducer.stepChange,
  invoices: state.invoiceReducer.invoices,
  resultData: state.invoiceReducer.resultData,
});
const mapDispatchToProps = {
  updateStateAction,
  getUploadedInvoiceAction,
  cleanStateGetBulkLoadAction,
  changeStepAction,
  cleanStatusAction,
  getDocumentsAction,
  clearResultSignalAction,
  cleanStatusUploadAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuedInvoices);
