import React from "react";
import Calendar from "rc-calendar";
import "rc-calendar/assets/index.css";
import moment from "moment";
import "moment/locale/es";
import esCO from "rc-calendar/lib/locale/es_ES";

const RcDatePicker = (props) => {
	const { disableFuture, disablePast, className, id, onChange, value } = props;

	const defaultDate = moment();

	const disabledDateLess = (current) => {
		if (!current) {
			return false;
		}

		const date = moment();
		date.hour(0);
		date.minute(0);
		date.second(0);
		return current.valueOf() < date.valueOf();
	};

	const disabledDatePlus = (current) => {
		if (!current) {
			return false;
		}

		const date = moment().add(1, "days");
		date.hour(0);
		date.minute(0);
		date.second(0);
		return current.valueOf() > date.valueOf();
	};

	return (
		<div>
			<Calendar
				locale={esCO}
				disabledDate={
					disablePast
						? disabledDateLess
						: disableFuture
						? disabledDatePlus
						: false
				}
				showToday={true}
				defaultValue={defaultDate}
				className={className}
				id={id}
				onChange={onChange}
				value={value}
				showDateInput={false}
			></Calendar>
		</div>
	);
};

export default RcDatePicker;
