import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { FormatDecimal } from "../../../../components/common/formatDecimal";

const useStyles = makeStyles((theme) => ({
  totalTitle: {
    fontSize: 10,
  },
  totalInvalid: {
    color: "red",
    fontSize: 21,
    fontWeight: 600,
  },
  total: {
    color: theme.palette.secundaryColor,
    fontSize: 18,
    fontWeight: 600,
  },
}));

export const TotalItemComponent = ({ totalreal, total }) => {
  const classes = useStyles();

  const isInvalid = totalreal < 0;

  return (
    <Grid container>
      <Grid item lg={12} xs={12}>
        <span className={classes.totalTitle}>Total </span>
        <br />
        <span className={isInvalid ? classes.totalInvalid : classes.total}>
          <FormatDecimal value={total} prefix={"$"} size={"small"} />
        </span>
      </Grid>
    </Grid>
  );
};
