//import { API_ENDPOINT_SIGNALR} from "../config/config"
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
//import * as SignalR from "@microsoft/signalr";
import FlareComponent from "flare-react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import LoopIcon from "@material-ui/icons/Loop";

import loadInvoice from "../images/animations/buscando.flr";

import { ConfirmAlert } from "../helpers/alert.helpers";
import { MESSAGE_NEXT_CONFIRM } from "../config/config";

import {
  updateStateAction,
  changeStepAction,
  joinGroupSignalAction,
  informationInvoiceAction,
} from "../actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  global: {
    background: theme.palette.seventhColor,
    height: 50,
    margin: "0 auto",
    position: "relative",
    textAlign: "center",
    color: "#272727",
    fontFamily: theme.palette.fontFamily,
    fontWeight: "bold",
    borderRadius: "5px",
  },
  globalComponent: {
    background: "#4A4A4A",
    height: 50,
    margin: "0 auto",
    position: "relative",
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: theme.palette.fontFamily,
    borderRadius: "5px",
    width: "100%",
    marginTop: "-12px",
  },
  styleIcon: {
    color: "#000000",
    fontSize: 18,
  },
  styleText: {
    color: "#272727",
    fontFamily: ".SF NS Display",
    fontSize: 18,
  },

  styleIconComponent: {
    color: "#FFFFFF",
    fontSize: 15,
  },
  styleTextComponent: {
    color: "#FFFFFF",
    fontFamily: ".SF NS Display",
    fontSize: 15,
  },
  divRegister: {
    background: theme.palette.seventhColor,
    height: "auto",
    margin: "0 auto",
    padding: "10px",
    position: "relative",
    textAlign: "center",
    color: "#272727",
    fontFamily: theme.palette.fontFamily,
    fontWeight: "bold",
    borderRadius: "5px",
  },
}));

const BulkLoadStatus = (props) => {
  const classes = useStyles();

  const {
    cantidad,
    procesadas,
    cargueId,
    stateBulk,
    stepChange,
    view,
    informationBulk,
    HandleBulkUpload,
    getChangeState,
    resultData,
  } = props;

  const history = useHistory();

  useEffect(
    () => {
      if (getChangeState.statusCode === "200" && getChangeState.result) {
        props.changeStepAction(2);
        history.push("/invoice");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getChangeState]
  );

  useEffect(() => {
    if (HandleBulkUpload)
      if (stepChange === 2) {
        HandleBulkUpload();
      }
  }, [stepChange, HandleBulkUpload]);

  useEffect(() => {
    if (!!resultData && Object.keys(resultData).length !== 0) {
      var result = {
        cantidad: resultData.total,
        factura: datamodification(
          informationBulk.factura,
          resultData.detailId,
          resultData.stateDocument,
          resultData.numberinvoice,
          resultData.dateAcuse
        ),
        id: informationBulk.id,
        procesada: resultData.processed,
        estado: resultData.stateDocument,
      };
      props.informationInvoiceAction(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData]);

  function datamodification(
    dataResult,
    idCargue,
    estado,
    numeroFactura,
    fechaAcuse
  ) {
    const invoiceUpdated = dataResult.filter(
      (item) => item.id === idCargue && item.numero !== numeroFactura
    )[0];
    if (invoiceUpdated) {
      dataResult = dataResult.filter((item) => item.id !== idCargue);
      invoiceUpdated.estadoDocumentoId = estado;
      invoiceUpdated.numero = numeroFactura;
      invoiceUpdated.fechaacuseDian = fechaAcuse;
      const dataNew = [invoiceUpdated, ...dataResult];
      return dataNew;
    }
    return dataResult.sort();
  }

  /**
   * obtiene el string del estado
   *
   * @param {*} statusId
   */
  const getStatusString = (statusId) => {
    if (statusId === 1) {
      return "en proceso";
    }
    if (statusId === 4) {
      return "pausado";
    }
    if (statusId === 2) {
      return "cancelado";
    }
    if (statusId === 3) {
      return "finalizado";
    }
  };

  const HeaderView = () => {
    const location = useLocation();
    return location.pathname;
  };

  /**
   * Funcion de cambio de estado Pausado
   */
  const onPause = () => {
    props.updateStateAction(4, cargueId, null);
  };

  /**
   * Funcion para renaudar el proceso de cargue masivo
   */

  const onResume = () => {
    props.updateStateAction(1, cargueId, null);
  };

  /**
   * Funcion para cancelar el proceso de acrgue masivo despues de que fueron cargadas las facturas a generar.
   */
  const onCancel = () => {
    const msg =
      "¿Está seguro que desea cancelar la emisión de las facturas? Si lo haces, se perderán los registros que no alcanzaron a ser emitidos.";
    const send = async (confirmed) => {
      if (confirmed) {
        if (view === 2) props.updateStateAction(2, cargueId, 0);
        props.changeStepAction(2);

        if (view === 1) props.updateStateAction(2, cargueId, 0, view);
      }
    };
    ConfirmAlert(msg, send, MESSAGE_NEXT_CONFIRM);
  };

  /**
   * Funcion para redireccionar al paso 2 estando en el modulo de facturas.
   */
  const redirect = () => {
    props.changeStepAction(2);
  };

  /**
   * Funcion para redireccionar al modulo de facturas y al paso de cargue masivo donde se encuentra el listado de las facturas que se estan generando..
   */

  const redirgir = () => {
    props.changeStepAction(2);
    history.push("/invoice");
  };

  /**
   * Funcion para cambiar los iconos de las barras de estado de un cargue masivo.
   * @param {} state
   * @returns
   */
  const stateOption = (state) => {
    if (state === 4 && procesadas !== cantidad) {
      return (
        <FormControlLabel
          control={
            <IconButton onClick={onResume}>
              <PlayCircleFilledWhiteIcon
                className={
                  view === 2 ? classes.styleIcon : classes.styleIconComponent
                }
              />
            </IconButton>
          }
          label={
            <div
              className={
                view === 2 ? classes.styleText : classes.styleTextComponent
              }
            >
              Reanudar &nbsp;&nbsp;
              {stateOptionCancel(state, stepChange)}
            </div>
          }
        />
      );
    }

    if (state === 1 && procesadas !== cantidad) {
      return (
        <FormControlLabel
          control={
            <IconButton onClick={onPause}>
              <PauseCircleFilledIcon
                className={
                  view === 2 ? classes.styleIcon : classes.styleIconComponent
                }
              />
            </IconButton>
          }
          label={
            <div
              className={
                view === 2 ? classes.styleText : classes.styleIconComponent
              }
            >
              Pausar &nbsp;&nbsp;
              {stateOptionCancel(state, stepChange)}
            </div>
          }
        />
      );
    }

    return "";
  };

  const stateOptionCancel = (state) => {
    if (
      (state === 2 && procesadas !== cantidad) ||
      (state === 3 && procesadas !== cantidad)
    ) {
      return "";
    }
    if (
      (state === 4 && procesadas === cantidad) ||
      (state === 1 && procesadas === cantidad)
    ) {
      return "";
    }

    return (
      <FormControlLabel
        control={
          <IconButton onClick={onCancel}>
            <CancelIcon
              className={
                view === 2 ? classes.styleIcon : classes.styleIconComponent
              }
            />
          </IconButton>
        }
        label={
          <div
            className={
              view === 2 ? classes.styleText : classes.styleIconComponent
            }
          >
            Cancelar
          </div>
        }
      />
    );
  };

  const gifSateBulk = (state) => {
    if (state === 2 || state === 3 || state === 4) {
      return "";
    }

    if (
      (state === 4 && procesadas === cantidad) ||
      (state === 1 && procesadas === cantidad)
    ) {
      return "";
    }
    if (view === 1) {
      /** return (
      <FlareComponent
        width={20}
        height={20}
        animationName="music_walk"
        file={loadInvoice1}
      />
    );*/
      return <LoopIcon />;
    }
    if (view === 2)
      return (
        <FlareComponent
          width={20}
          height={20}
          animationName="music_walk"
          file={loadInvoice}
        />
      );
  };

  const menssageBulk = (state, step) => {
    if (
      (state === 1 && step === 1) ||
      step === 0 ||
      (state === 4 && step === 1) ||
      step === 0
    ) {
      return informationBulk.procesada === informationBulk.cantidad ? (
        <div style={{ paddingTop: 10 }}>
          <span>
            &nbsp; Envío masivo de facturas{" "}
            {informationBulk.procesada === informationBulk.cantidad
              ? getStatusString(3)
              : getStatusString(stateBulk)}{" "}
            ... {informationBulk.procesada} de {informationBulk.cantidad}{" "}
            registros &nbsp;&nbsp;{" "}
            <span
              onClick={view === 1 ? () => redirgir() : () => redirect()}
              style={{ cursor: "pointer" }}
            >
              (Clic Aquí para ver)
            </span>
          </span>
        </div>
      ) : (
        <span>
          &nbsp; Envío masivo de facturas
          {informationBulk.procesada === informationBulk.cantidad
            ? getStatusString(3)
            : getStatusString(stateBulk)}{" "}
          ... {informationBulk.procesada} de {informationBulk.cantidad}{" "}
          registros &nbsp;&nbsp;{" "}
          {
            <span
              onClick={view === 1 ? () => redirgir() : () => redirect()}
              style={{ cursor: "pointer" }}
            >
              (Clic Aquí para ver)
            </span>
          }
        </span>
      );
    }

    if ((state === 2 && step === 1) || step === 0) {
      return (
        <div style={{ paddingTop: 10 }}>
          {" "}
          <span>
            &nbsp; Envio masivo de facturas finalizado,
            {
              <span
                onClick={view === 1 ? () => redirgir() : () => redirect()}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Clic aquí
              </span>
            }
            <span> para ver resultado</span>
          </span>
        </div>
      );
    }

    if (state === 2 && step === 2 && HeaderView() === "/invoice") {
      return (
        <div className={classes.divRegister}>
          &nbsp; Envío masivo de facturas{" "}
          {informationBulk.procesada === informationBulk.cantidad
            ? getStatusString(3)
            : getStatusString(stateBulk)}{" "}
          ... {informationBulk.procesada} de {informationBulk.cantidad}{" "}
          registros &nbsp;&nbsp;
        </div>
      );
    }

    if (
      (state === 1 && step === 1) ||
      step === 2 ||
      (state === 4 && step === 1) ||
      step === 2
    ) {
      return informationBulk.procesada === informationBulk.cantidad &&
        HeaderView() === "/invoice" ? (
        <div style={{ paddingTop: 10 }}>
          <span>
            &nbsp; Envío masivo de facturas{" "}
            {informationBulk.procesada === informationBulk.cantidad
              ? getStatusString(3)
              : getStatusString(stateBulk)}{" "}
            ... {informationBulk.procesada} de {informationBulk.cantidad}{" "}
            registros &nbsp;&nbsp;
          </span>
        </div>
      ) : (
        <span>
          &nbsp; Envío masivo de facturas{" "}
          {informationBulk.procesada === informationBulk.cantidad
            ? getStatusString(3)
            : getStatusString(stateBulk)}{" "}
          ... {informationBulk.procesada} de {informationBulk.cantidad}{" "}
          registros &nbsp;&nbsp;
          <span
            onClick={view === 1 ? () => redirgir() : () => redirect()}
            style={{ cursor: "pointer" }}
          >
            (Clic Aquí para ver)
          </span>
        </span>
      );
    }
  };

  const component = (viewComponenet) => {
    if (viewComponenet === 1) {
      return (
        <div className={classes.globalComponent}>
          {gifSateBulk(stateBulk)}
          {menssageBulk(stateBulk, stepChange)}
          &nbsp;&nbsp;
          {stateOption(stateBulk)}
        </div>
      );
    }

    if (viewComponenet === 2)
      return (
        <div className={classes.global}>
          {gifSateBulk(stateBulk)}
          {menssageBulk(stateBulk, stepChange)}
          &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;
          {stateOption(stateBulk)}
        </div>
      );
  };

  return component(view);
};

const mapStateToProps = (state) => ({
  generalReducer: state.generalReducer,
  stateBulk: state.invoiceReducer.stateBulkLoad,
  stepChange: state.invoiceReducer.stepChange,
  informationBulk: state.invoiceReducer.informationBulkLoad,
  getChangeState: state.invoiceReducer.getChangeState,
  resultData: state.invoiceReducer.resultData,
});

const mapDispatchToProps = {
  updateStateAction,
  changeStepAction,
  joinGroupSignalAction,
  informationInvoiceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkLoadStatus);
