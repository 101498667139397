import React from "react";
// import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import { FormatDecimal } from "../../../components/common/formatDecimal";
// import CollapsePanel from "../../../components/collapse/collapsestyles.component";

import ArticlesDocuSupport from "../detailSupportComponet/ArticlesDocuSupport";
// import GetInfoAditional from "../detailSupportComponet/GetInfoAditional";
import DetailRewiewDocument from "./DetailRewiewDocument";

// import { formatDate } from "../../../utils/general";
import FooterNoteRevision from "./FooterNoteRevision";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primaryColor,
    backgroundColor: "rgba(216,216,216,0.09)",
    overflow: "auto",
    width: "100%",
  },
  title: {
    fontsize: 16,
  },
  containerTitle: {
    textAlign: "center",
    padding: "20px 10px",
    fontWeight: 600,
    borderBottom: "0.1px solid #16B1F3",
    marginBottom: 20,
  },
  containerData: {
    backgroundColor: "#4B4B4B",
    borderRadius: 5,
    padding: 25,
    height: "78vh",
    overflow: "auto",
  },
  containerHeader: {
    backgroundColor: "#5E5E5E",
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  numberContainer: {
    margin: "10px auto",
  },
  seccionTitle: {
    height: 36,
    width: "100%",
    borderRadius: 5,
    backgroundColor: "rgba(18,18,18,0.3)",
    color: "#2CC63E",
    fontFamily: "Muli",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    paddingTop: "0.8%",
    margin: "20px auto",
  },
  gridData: {
    display: "grid",
    width: "100%",
  },
  grayline: {
    height: 1,
    width: "100%",
    borderBottom: "1px solid gray",
    marginTop: 5,
  },
  label: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 12,
  },
  valueTotal: {
    color: "#2CC63E",
    fontSize: 20,
    fontWeight: 600,
  },
  legend: {
    fontSize: "0.9rem",
    fontStyle: "italic",
    color: "rgba(255,255,255,0.5)",
  },
  containerValue: {
    textAlign: "center",
    margin: "10px auto",
  },
  name: {
    fontSize: "1.2rem",
    color: "#F6D746",
    fontWeight: 800,
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  flexContent: {
    display: "flex",
  },
  icon: {
    color: "#2CC63E",
    marginLeft: 0,
    marginRight: 10,
    fontSize: "1.3rem",
  },
  containerActions: {
    backgroundColor: "#393939",
    padding: 0,
  },
  button: {
    borderRadius: "15px !important",
  },
  labelButton: {
    flexDirection: "column",
    fontSize: 12,
    color: theme.palette.primaryColor,
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: theme.palette.primaryColor,
  },
  iconAction: {
    color: "white",
    fontSize: "1.3rem",
  },
}));

const DocumentRevisionDocSupport = ({
  dataNote,
  docFilterRes,
  readResponseGetNote,
}) => {
  const classes = useStyles();
  const {
    prefijo,
    consecutivo,
    valorAPagar,
    opdetalledocumento,
    fecha,
  } = dataNote;

  const {
    notaconcepto,
    observacion,
    codconcepto,
    consecutivoDoc,
    prefijoDoc,
  } = docFilterRes;
  return (
    <Grid container direction="row" alignItems="stretch">
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item lg={4} xs={4} className={classes.containerTitle}>
            <span>Previsualizar Nota</span>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item lg={6} xs={6}>
            <div className={classes.containerData}>
              <Grid container>
                <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                  <span>Información de la nota</span>
                </Grid>
              </Grid>

              <Grid container className={classes.containerHeader} spacing={1}>
                <Grid item lg={6} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>
                      Doc. Soporte Asociado
                    </label>
                    <span>
                      {prefijoDoc}-{consecutivoDoc}
                    </span>
                    <div className={classes.grayline} />
                  </div>
                </Grid>
                <Grid item lg={6} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>Tipo Nota</label>
                    <span>{codconcepto === "2" ? "Anulación" : "Ajuste"}</span>
                    <div className={classes.grayline} />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>
                      Motivo de la edición
                    </label>
                    <span>{notaconcepto}</span>
                    <div className={classes.grayline} />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12} style={{ paddingRight: 10 }}>
                  <div className={classes.gridData}>
                    <label className={classes.label}>Observaciones</label>
                    <span>{observacion}</span>
                  </div>
                </Grid>
              </Grid>

              <div className={classes.grayline} />
              <Grid container className={classes.numberContainer}>
                <Grid item lg={6} xs={12}>
                  <span>
                    No. {prefijo}-{consecutivo}
                  </span>
                </Grid>
                <Grid item lg={6} xs={12} style={{ textAlign: "end" }}>
                  <span>{format(new Date(fecha), "dd/MM/yyyy")}</span>
                </Grid>
              </Grid>

              <div className={classes.grayline} />

              <Grid container className={classes.containerValue}>
                <Grid item lg={12} xs={12} className={classes.gridData}>
                  <span className={classes.valueTotal}>
                    <FormatDecimal
                      value={valorAPagar}
                      prefix={"$"}
                      size={"small"}
                    />
                  </span>
                  <span className={classes.legend}>
                    Valor total impuestos incluidos
                  </span>
                </Grid>
              </Grid>

              <div className={classes.grayline} />
              <DetailRewiewDocument />
              <Grid container>
                <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                  <span>Servicios ( {opdetalledocumento.length} )</span>
                </Grid>
              </Grid>
              {opdetalledocumento.map((detail) => (
                <ArticlesDocuSupport key={detail.id} detail={detail} />
              ))}

              {/* <Grid container>
                <Grid item lg={12} xs={12}>
                  <CollapsePanel
                    component={additionalInformationPanel}
                    title={"Información Adicional"}
                    titleOpen={"Información Adicional"}
                    titleEdit={"Información Adicional"}
                    open={expand}
                    backgroundColorSummary={{
                      backgroundColor: "#2CC63E",
                      color: "#000000",
                    }}
                    backgroundColorDetail={{ backgroundColor: "#5E5E5E" }}
                  ></CollapsePanel>
                </Grid>
              </Grid> */}
            </div>
          </Grid>
        </Grid>
        <FooterNoteRevision
          handleBackRevision={readResponseGetNote}
          dataNote={dataNote}
          docFilterRes={docFilterRes}
        />

        {/* Modal previsualizar PDF */}
        {/* <ArchiveModal
          loading={loadingGetFile}
          open={openModal}
          pdfBase64={base64PDF}
          xmlBase64={base64XML}
          handleClose={handleCloseFileModal}
        /> */}

        {/* <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleCloseResponseModal}
        /> */}
      </div>
    </Grid>
  );
};

export default DocumentRevisionDocSupport;
