import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";

import { URL_IMAGES } from "../../../config/config";
import isEmpty from "../../../utils/isEmpty";
import { FormatDecimal } from "../../../components/common/formatDecimal";
import { getTaxItems } from "../../../utils/getTaxItems";
import { TaxElement } from "../../document/cart/TaxElement";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#5E5E5E",
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
    color: "#FFFF",
    fontSize: "0.9rem",
  },
  cover: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    borderRadius: 5,
    marginRight: 10,
    textAlign: "center",
    position: "relative",
  },
  grayline: {
    height: 1,
    width: "100%",
    borderBottom: "1px solid gray",
    marginTop: 5,
  },
  gridData: {
    display: "grid",
    width: "100%",
  },
  label: {
    color: "rgba(255,255,255,0.5)",
    fontSize: "0.8rem",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  totalItem: {
    color: "#2CC63E",
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  valueOne: {
    color: "#2CC63E",
    wordBreak: "break-all",
  },
  greenText: {
    color: "#2CC63E",
  },
  imageText: {
    color: "white",
    fontSize: "2.5vh",
    fontWeight: 600,
    marginTop: "-35%",
    marginLeft: "-5%",
    position: "absolute",
  },
});

const DocumentItemCard = ({ data }) => {
  const classes = useStyles();

  const taxItems = getTaxItems(
    data.porcentajeiva,
    data.porcentajeinc,
    data.tarifaIcl / data.cantidad,
    data.cantidadGrados,
    data.cantidad,
    data.cantidadGramos / data.cantidad,
    data.tarifaInpp,
    data.cantidadMililitros / data.cantidad,
    data.tarifaIbua,
    data.porcentajeIcui,
    data.porcentajeAdv
  );

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item lg={4} xs={4}>
          <CardMedia
            className={classes.cover}
            image={!isEmpty(data?.imagen) ? URL_IMAGES + data.imagen : ""}
            style={{
              backgroundColor: !isEmpty(data.imagen)
                ? ""
                : isEmpty(data?.colorimagen)
                ? "#33BBFF"
                : data?.colorimagen,
            }}
          >
            {!!data?.imagen ? (
              ""
            ) : (
              <span className={classes.imageText}>
                {" "}
                {data?.descripcion.substring(0, 2).toUpperCase()}
              </span>
            )}
          </CardMedia>
        </Grid>

        <Grid item lg={8} xs={8}>
          <Grid container>
            <Grid item lg={12} xs={12} style={{ paddingRight: 10 }}>
              <span>{data.descripcion}</span>
              <div className={classes.grayline} />
            </Grid>
            <Grid item lg={8} xs={8}>
              <div className={classes.gridData} style={{ paddingRight: 10 }}>
                <span className={classes.label}>Valur C/U:</span>
                <span className={classes.valueOne}>
                  <FormatDecimal prefix={"$"} value={data.valorunitario} />
                </span>
                <div className={classes.grayline} />
              </div>
            </Grid>
            <Grid item lg={4} xs={4}>
              <div className={classes.gridData} style={{ paddingRight: 10 }}>
                <span className={classes.label}>Cantidad:</span>
                <span className={classes.wordBreak}>
                  <FormatDecimal value={data.cantidad} />
                </span>
                <div className={classes.grayline} />
              </div>
            </Grid>
            <Grid item lg={12} xs={12} style={{ paddingRight: 10 }}>
              {taxItems.map(({ aplica, label, value, prefix, suffix }, index) =>
                aplica ? (
                  <TaxElement
                    key={index}
                    label={label}
                    value={value}
                    prefix={prefix}
                    suffix={suffix}
                  />
                ) : null
              )}
            </Grid>
            <Grid item lg={6} xs={6} style={{ paddingRight: 10 }}>
              <div className={classes.gridData}>
                <span className={classes.label}>Descuento</span>
                <span className={classes.wordBreak}>
                  <FormatDecimal value={data.valordescuento} prefix={"$"} />
                </span>
                <div className={classes.grayline} />
              </div>
            </Grid>
            <Grid item lg={6} xs={6} style={{ paddingRight: 10 }}>
              <div className={classes.gridData}>
                <span className={classes.label}>Total</span>
                <span className={classes.totalItem}>
                  <FormatDecimal value={data?.totalreal} prefix={"$"} />
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DocumentItemCard;
