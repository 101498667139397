import React from "react";

import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";

import EventIcon from "@material-ui/icons/Event";

import InputIcon from "../../../../../components/input/inputIcon.component";

import PopoverModal from "../../../../../components/modal/popoverModal.component";

import { ModalDates } from "../../../../../components/modal/ModalDates";
import { ModalFilters } from "../../../../../components/modal/ModalFilters";

import filterImage from "../../../../../images/filterImage";

const useStyles = makeStyles({
  buttonIcon: {
    color: "white",
    marginTop: "50%",
  },
});

export const FilterComponent = ({
  cleanFilter,
  cleanFilterDate,
  dates,
  filterData,
  filterDate,
  handlecheckFilters,
  handleCloseModal,
  handleDateChange,
  loading = false,
  modalChecksAnchorEl,
  modalDatesAnchorEl,
  onSearch,
  setModalState,
  syncChangesFilter,
  txtFilter,
}) => {
  const classes = useStyles();

  /**
   * * Configuración de los diferentes tipos de modales utilizados en la interfaz.
   * *Cada objeto en esta matriz define las propiedades de un modal específico.
   * @type {Array<Object>}
   */
  const modalFilters = [
    {
      component: () =>
        ModalFilters(handlecheckFilters, filterData, cleanFilter),
      handleClose: () => handleCloseModal("modalChecksAnchorEl", null),
      open: !!modalChecksAnchorEl,
      popoverAnchorEl: modalChecksAnchorEl,
      showCloseButton: false,
      width: 191,
    },
    {
      component: () =>
        ModalDates(cleanFilterDate, dates, filterDate, handleDateChange),
      handleClose: () => handleCloseModal("modalDatesAnchorEl", null),
      open: !!modalDatesAnchorEl,
      popoverAnchorEl: modalDatesAnchorEl,
      showCloseButton: false,
      title: "",
      width: 530,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <InputIcon
            disabled={loading}
            label={"Buscar"}
            maxLength={100}
            name={"txtFilter"}
            onChange={(e) => syncChangesFilter(e)}
            onSearch={onSearch}
            value={txtFilter}
          />
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Filtrar por fechas">
              <span>
                <IconButton
                  onClick={(e) =>
                    setModalState("modalDatesAnchorEl", e.currentTarget)
                  }
                  disabled={loading}
                >
                  <EventIcon className={classes.buttonIcon} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Filtrar por estado">
              <span>
                <IconButton
                  onClick={(e) =>
                    setModalState("modalChecksAnchorEl", e.currentTarget)
                  }
                  disabled={loading}
                >
                  <img
                    alt={"Filtrar"}
                    src={filterImage}
                    style={{ marginTop: "80%" }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      {modalFilters.map(({ ...rest }, index) => (
        <PopoverModal key={index} {...rest} />
      ))}
    </>
  );
};
