import React, { useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { putStateChangeFavorite } from '../../actions/trainingActions';

import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";
import { Grid, Tooltip } from "@material-ui/core";
import ReactPlayer from "react-player";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";


const useStyles = makeStyles((theme) => ({
  styleCheck: {
    color: theme.palette.primaryColor,
  },
  containerCard: {
    backgroundColor: theme.palette.ninethBackgroundColor,
    borderRadius: "5px",
  },
  descriptionMultimedia: {
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: 14,
    fontWeight: 600,
  },
  linkVideo: {
    color: 'white',
    textDecoration: 'none'
  },
  dinamicText: {
    padding: '0 5%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    marginBlockStart: 'auto',
    marginBlockEnd: '0.5em',
    minHeight: '3rem',
    maxHeight: '3rem',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2'
  }
}));

const CheckboxCustom = withStyles({
  root: {
    "& .MuiCheckbox-colorPrimary": {
      backgroundColor: "green",
      color: "red",
    },
    "& .MuiCheckbox-root": {
      backgroundColor: "green",
      color: "white",
    },
    "& .MuiTab-textColorSecondary": {
      backgroundColor: "green",
      color: "red",
    },
    "&.Mui-checked": {
      color: "white",
    },
  },
})(Checkbox);

const CardMultimedia = ({ ...props }) => {

  const classes = useStyles();
  const { url, name, id, favorito, key } = props;
  const pTagName = useRef();
  //const [boxSize, setBoxSize] = useState();
  const [checked, setChecked] = React.useState(favorito);


  const handleChange = (event, id) => {
    setChecked(
      event.target.checked
    );
    props.putStateChangeFavorite(id, event.target.checked)
  };
  



  return (
    <Grid key={key} item md={4} lg={4} xs={12} xl={3}>
      <div className={classes.containerCard}>
        <ReactPlayer
          url={url}
          width="100%"
          height="80%"
        />
        <Grid container>
          <Grid item md={11} lg={11} xs={11}>
            <Tooltip title={name} >
              <a href={url} target="_blank" rel="noopener noreferrer" className={classes.linkVideo}>
                <p className={classes.dinamicText} ref={pTagName}>{name}</p>
              </a>
            </Tooltip>
          </Grid>
          <Grid item md={1} lg={1} xs={1}>

            <FormControlLabel
              style={{ marginTop: '10px' }}
              control={
                <CheckboxCustom
                  checked={checked}
                  onChange={(e) => handleChange(e, id)}
                  icon={
                    <StarBorderIcon
                      style={{
                        color: "#FFFFFF",
                        fontSize: "22px",
                      }}
                    />
                  }
                  checkedIcon={
                    <StarIcon />
                  }
                  name="checkedH"
                />
              }
            />

          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

CardMultimedia.propTypes = {
  putStateChangeFavorite: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})



export default connect(mapStateToProps, {
  putStateChangeFavorite
})(withWidth()((CardMultimedia)));
