import { types } from "../types/types";

const initialState = {
  stepBasicDataComplete: false,
  loadingSaveBasicData: false,
  loadingGetBasicData: false,
  loadingSaveTemplate: false,
  updateInitial: false,
  updateDian: false,
  loadingSaveDianData: false,
  listCustomFields: [],
  loadingSaveCustomField: false,
  loadingDeleteCustomField: false,
  loadingGetCustomFieldList: false,
  documentTypeInfo: {},
  requestCompany: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_BASIC_DATA:
      return {
        ...state,
        requestSaveBasicData: action.payload,
      };
    case types.GET_TYPE_DOCUMENT:
      return {
        ...state,
        requestTypeDocument: action.payload,
      };
    case types.GET_REGIMEN:
      return {
        ...state,
        requestRegimen: action.payload,
      };
    case types.GET_COMPANY:
      return {
        ...state,
        requestCompany: action.payload,
      };
    case types.STATUS_STEP_BASIC_DATA:
      return {
        ...state,
        stepBasicDataComplete: action.payload,
      };
    case types.LOADING_SAVE_BASIC_DATA:
      return {
        ...state,
        loadingSaveBasicData: action.payload,
      };
    case types.LOADING_GET_BASIC_DATA:
      return {
        ...state,
        loadingGetBasicData: action.payload,
      };
    case types.LOADING_SAVE_TEMPLATE:
      return {
        ...state,
        loadingSaveTemplate: action.payload,
      };
    case types.UPDATE_INITIAL_DATA:
      return {
        ...state,
        updateInitial: action.payload,
      };
    case types.UPDATE_DIAN_DATA:
      return {
        ...state,
        updateDian: action.payload,
      };
    case types.LOADING_SAVE_DIAN_DATA:
      return {
        ...state,
        loadingSaveDianData: action.payload,
      };
    case types.LIST_CUSTOM_FIELDS:
      return {
        ...state,
        listCustomFields: action.payload,
      };
    case types.LOADING_SAVE_CUSTOM_FIELD:
      return {
        ...state,
        loadingSaveCustomField: action.payload,
      };
    case types.SAVE_CUSTOM_FIELD:
      return {
        ...state,
        saveCustomFieldResponse: action.payload,
      };
    case types.LOADING_DELETE_CUSTOM_FIELD:
      return {
        ...state,
        loadingDeleteCustomField: action.payload,
      };
    case types.LOADING_GET_CUSTOM_FIELD:
      return {
        ...state,
        loadingGetCustomFieldList: action.payload,
      };
    case types.GET_DOCUMENT_TYPE_INFO:
      return {
        ...state,
        documentTypeInfo: action.payload,
      };

    default:
      return state;
  }
}
