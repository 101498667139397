import { types } from "../types/types";

const initialState = {
  emailEmpresaDian: null,
  autenticacionEmail: { emailId: null, codigoautentiacionemail: null },
  urlAutenticacionOauth: "",
  registroCodigoAutenticacionError: null,
  registroCodigoAutenticacionExito: null,
  registroCodigoAutenticacionData: {},
  registroCodigoAutenticacionEmailDiferente: false,
  errorResponse: {},
  invoicesReceived: [],
  consultandoDocumentos: null,
  invoiceSelected: {},
  loadingFavouriteReceivedFromList: 0,
  totalDocumentosRecibidos: 0,
  loadingCabioEstadoPago: 0,
  showSuccesChangeStatePay: false,
  tiposMotivoRechazo: [],
  consultandoMotivosRechazo: false,
  file: {},
  consultandoInfoAutenticacion: false,
  filtrandoDocumentos: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPANY_EMAIL_DIAN:
      return {
        ...state,
        emailEmpresaDian: action.payload,
      };

    case types.GET_INFO_AUTHENTICATION_EMAIL:
      return {
        ...state,
        autenticacionEmail: action.payload.result,
      };

    case types.GET_URL_AUTHENTICATION_OAUTH:
      return {
        ...state,
        urlAutenticacionOauth: action.payload,
      };

    case types.SET_AUTHENTICATION_OAUTH_ERROR:
      return {
        ...state,
        registroCodigoAutenticacionError: action.payload,
      };

    case types.SET_AUTHENTICATION_OAUTH_EXITO:
      return {
        ...state,
        registroCodigoAutenticacionExito: action.payload,
      };

    case types.SET_AUTHENTICATION_OAUTH_DATA:
      return {
        ...state,
        registroCodigoAutenticacionData: action.payload,
      };

    case types.SET_AUTHENTICATION_OAUTH_EMAIL_DIFERENTE:
      return {
        ...state,
        registroCodigoAutenticacionEmailDiferente: action.payload,
      };

    case types.GET_ERRORS_INVOICE_RECEIVED:
      return {
        ...state,
        errorResponse: {
          error: action.payload,
          message: action.payload1,
        },
      };

    case types.CLEAR_CODE_AUTHENTICATION:
      return {
        ...state,
        limpiarcodigoautenticacion: action.payload,
      };

    case types.GET_INVOICES_RECEIVED:
      return {
        ...state,
        invoicesReceived: action.payload,
        totalDocumentosRecibidos: action.payload1,
        responseInvoice: action.payload2,
      };

    case types.CHANGE_STATE_INVOICE_RECEIVE:
      return {
        ...state,
        stateUrlAuthentication: action.payload,
      };

    case types.SET_CONSULTANDO_DOCUMENTOS:
      return {
        ...state,
        consultandoDocumentos: action.payload,
      };

    case types.SET_INVOICE_SELECTED:
      return {
        ...state,
        invoiceSelected: action.payload,
      };

    case types.LOADING_UPDATE_FAV_RECEIVED_FROM_LIST:
      return {
        ...state,
        loadingFavouriteReceivedFromList: action.payload,
      };

    case types.LOADING_CAMBIAR_ESTADO_PAGO:
      return {
        ...state,
        loadingCabioEstadoPago: action.payload,
      };

    case types.SHOW_SUCCES_CHANGE_STATE_PAY:
      return {
        ...state,
        showSuccesChangeStatePay: action.payload,
      };

    case types.GET_TIPOS_MOTIVO_RECHAZO:
      return {
        ...state,
        tiposMotivoRechazo: action.payload,
      };

    case types.LOADING_TIPOS_MOTIVO_RECHAZO:
      return {
        ...state,
        consultandoMotivosRechazo: action.payload,
      };

    case types.GET_FILE_INVOICE_RECEIVED:
      return {
        ...state,
        file: action.payload,
      };

    case types.LOADING_INFO_AUTH:
      return {
        ...state,
        consultandoInfoAutenticacion: action.payload,
      };

    case types.FILTRANDO_DOCUMENTOS:
      return {
        ...state,
        filtrandoDocumentos: action.payload,
      };

    case types.CHANGE_VALUE_VAR:
      return {
        ...state,
        changestatus: action.payload,
      };

    default:
      return state;
  }
};
