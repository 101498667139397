/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import { ChromePicker } from "react-color";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import InputForm from "../../../components/input/input.component";

import SimpleReactValidator from "simple-react-validator";

import {
  cleanStatusEdit,
  dataCleanCloseForm,
  getCategoryPagination,
  createCategoryAction,
  updateCategoryAction,
  loadCategoryAction,
} from "../../../actions/categoryActions";
import { getMenuComponentAction } from "../../../actions/configAction";

import { SuccessAlert } from "../../../helpers/alert.helpers";
import { QUANTITY_DATA, TIME_CREATE_REGISTER } from "../../../config/config.js";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import "../article/article.css";
import SimpleModal from "../../../components/modal/simpleModal.component";
import isEmpty from "../../../utils/isEmpty";

const useStyles = (theme) => ({
  cardCreate: {
    background: theme.palette.thirdBackgroundColor,
    with: "100%",
  },

  cardList: {
    background: theme.palette.thirdBackgroundColor,
    with: "100%",
    height: 45,
  },

  typography: {
    color: "#16B1F3",
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    borderRadius: 10,
    width: 405,
    height: 360,
  },
  typographyCategory: {
    color: "white",
    textAlign: "center",
  },
  buttonModal: {
    width: "80%",
    height: "100%",
    background: "#16B1F3",
    color: "white",
    borderRadius: "5px",
    fontFamily: "Muli",
  },
  divButtonModal: {
    textAlign: "center",
  },
  buttonCloseModal: {
    left: "80%",
    color: "#00BFFF",
  },
  img: {
    height: "50px",
    width: "63px",
    borderRadius: "5px",
    border: "none",
  },

  styleGridButton: {
    paddingTop: -50,
  },
  centerImage: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  gridButton: {
    padding: "20px 40px",
  },
  marginButtons: {
    padding: "0 15px",
  },
  btnModalImage: {
    borderRadius: 5,
    backgroundColor: theme.palette.thirdColor,
    width: "100%",
    height: 39,
    border: "none",
    cursor: "pointer",
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 300,
    marginTop: 5,
    marginBottom: 15,
    marginLeft: 15,
  },
  colorContainer: {
    fontSize: "15px",
    alignItems: "center",
    textAlign: "right",
  },
  colorItem: {
    textAlign: "left",
  },
});

const ColorCategoryModal = (props) => {
  const { classes, handleClose, onChange, data } = props;
  return (
    <Grid container>
      <Grid item className={classes.centerImage}>
        <ChromePicker color={data.categoryColor} onChangeComplete={onChange} />
      </Grid>
      <Grid container className={classes.gridButton}>
        <Grid item md={6} xs={12} className={classes.marginButtons}>
          <button
            type="button"
            className={classes.btnModalImage}
            onClick={handleClose}
          >
            Cerrar
          </button>
        </Grid>
        <Grid item md={6} xs={12}>
          <button
            type="button"
            className={classes.btnModalImage}
            onClick={handleClose}
          >
            Terminar
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export class NewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txCategoryName: "",
        categoryColor: this.getRandomColor(),
        categoryImage: null,
      },
      statusModalColor: false,

      saveCategoryResponse: {},
      editCategoryStatus: false,

      page: 0,

      components: [],
      componentRequest: [],
      loadingSaveCategory: false,
      menuComponent: [],
      configurableMenu: {},
      loadedCategory: {},
    };

    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getMenuComponentAction(5);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.categoryReducer.saveCategoryResponse &&
      nextProps.categoryReducer.saveCategoryResponse !==
        state.saveCategoryResponse
    )
      update.saveCategoryResponse =
        nextProps.categoryReducer.saveCategoryResponse;

    if (
      !!nextProps.categoryReducer.loadedCategory &&
      nextProps.categoryReducer.loadedCategory !== state.loadedCategory
    ) {
      update.loadedCategory = nextProps.categoryReducer.loadedCategory;
      update.form = {
        txCategoryName: nextProps.categoryReducer.loadedCategory.nombre,
        categoryColor: nextProps.categoryReducer.loadedCategory.color,
        categoryImage: null,
      };
    }

    if (
      !isEmpty(nextProps.categoryReducer.editCategoryStatus) &&
      nextProps.categoryReducer.editCategoryStatus !== state.editCategoryStatus
    )
      update.editCategoryStatus = nextProps.categoryReducer.editCategoryStatus;

    if (
      !!nextProps.categoryReducer.updateCategoryResponse &&
      nextProps.categoryReducer.updateCategoryResponse !==
        state.updateCategoryResponse
    ) {
      update.updateCategoryResponse =
        nextProps.categoryReducer.updateCategoryResponse;
    }

    if (
      !!nextProps.configReducer.menuComponent &&
      nextProps.configReducer.menuComponent !== state.menuComponent
    ) {
      update.menuComponent = nextProps.configReducer.menuComponent;
      let menu = nextProps.configReducer.menuComponent;
      let componentSaveButton = menu.find((e) => e.id === 45)
        ?.adcomponenteinformacion;
      let componentMessageSave = menu.find((element) => element.id === 72)
        ?.adcomponenteinformacion;
      let componentMessageUpdate = menu.find((element) => element.id === 73)
        ?.adcomponenteinformacion;

      update.configurableMenu = {
        ...state.configurableMenu,
        saveButton: !!componentSaveButton?.find((e) => e.id === 55)?.text
          ? componentSaveButton.find((e) => e.id === 55)?.text
          : "Listo",
        messageSave: !!componentMessageSave?.find((e) => e.id === 62)?.text
          ? componentMessageSave.find((e) => e.id === 62)?.text
          : "Registro exitoso.",
        messageUpdate: !!componentMessageUpdate?.find((e) => e.id === 63)?.text
          ? componentMessageUpdate.find((e) => e.id === 63)?.text
          : "Actualización exitoso.",
      };
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    let data = {
      page: this.state.page,
      quantity: QUANTITY_DATA,
      name: null,
    };

    if (prevState.saveCategoryResponse !== this.state.saveCategoryResponse) {
      if (this.state.saveCategoryResponse.statusCode === "200") {
        SuccessAlert(
          null,
          this.state.configurableMenu.messageSave,
          TIME_CREATE_REGISTER
        );
        this.props.getCategoryPagination(data);
        this.cleanData();
      }
    }

    if (
      prevState.updateCategoryResponse !== this.state.updateCategoryResponse
    ) {
      if (this.state.updateCategoryResponse.statusCode === "200") {
        SuccessAlert(
          null,
          this.state.configurableMenu.messageUpdate,
          TIME_CREATE_REGISTER
        );
        this.props.getCategoryPagination(data);
        this.cleanData();
      }
    }
  }

  componentWillUnmount() {
    this.props.loadCategoryAction(null);
  }

  /**
   * Sincroniza  nuevo valor input con state
   * @param {*} e Evento
   */
  syncChanges(e) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  }

  /**
   * Evento, Escucha cambio en la seleccion de color
   * @param {*} color Información color
   */
  handleChangeComplete = (color) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        categoryColor: color.hex,
      },
    }));
  };

  /**
   * Evento, Captura informacion y guarda oa actualiza
   * información de categoria
   * @param {object} e Evento
   */
  saveCategory = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        ...this.state.loadedCategory,
        id:
          this.state.editCategoryStatus === true
            ? this.state.loadedCategory.id
            : 0,
        nombre: this.state.form.txCategoryName,
        color: this.state.form.categoryColor,
        imagen: null,
        activo: true,
        favorita: false,
        idowner: 0,
        createdby: 1,
        modifiedby: 1,
        idbusinessunit: 0,
      };

      this.state.editCategoryStatus === true
        ? this.props.updateCategoryAction(data, this.changeStatusSaveLoading)
        : this.props.createCategoryAction(data, this.changeStatusSaveLoading);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * Cambia estado loading, guardado o actualización de categoria
   * @param {*} status
   */
  changeStatusSaveLoading = (status) => {
    this.setState({
      loadingSaveCategory: status,
    });
  };

  /**
   * Limpia formulario
   */
  cleanData() {
    this.setState({
      form: {
        txCategoryName: "",
        categoryColor: this.getRandomColor(),
      },
    });
    this.validator.hideMessages();
    this.props.loadCategoryAction(null);
  }

  /**
   * Abre modal selecion color categoria
   */
  handleOpenModalColor = () => {
    this.setState({
      statusModalColor: true,
    });
  };

  /**
   * Cierra modal seleccion color
   */
  handleCloseModalColor = () => {
    this.setState({
      statusModalColor: false,
    });
  };

  /**
   * Valida reglas de input en formaulario,
   * muestra mesanje de error
   * @param {*} e Evento
   * @param {*} name Nombre validador
   */
  validatarTest(e, name) {
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name);
    }
  }

  /**
   * Genera color randon rgb
   *
   * @param {*}
   * @param {*}
   */
  getRandomColor = () => {
    const numero = 255;
    const red = (Math.random() * numero).toFixed(0);
    const green = (Math.random() * numero).toFixed(0);
    const blue = (Math.random() * numero).toFixed(0);
    return `rgb(${red},${green},${blue})`;
  };

  render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={(e) => this.saveCategory(e)}
        noValidate
        autoComplete="off"
      >
        <Grid container>
          <Grid item lg={4} md={4}>
            <InputForm
              name="txCategoryName"
              value={this.state.form.txCategoryName}
              label={"Nombre categoría *"}
              nameValidator={"txCategoryName"}
              onBlur={(e) => this.validatarTest(e, "txCategoryName")}
              onChange={(e) => this.syncChanges(e)}
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>

          <Grid item lg={4} xs={4}>
            <Grid container className={classes.colorContainer}>
              <Grid item xs={8}>
                <span>Asigna un color a la categoría</span>
              </Grid>
              <Grid item xs={4} className={classes.colorItem}>
                <img
                  className={classes.img}
                  onClick={this.handleOpenModalColor}
                  style={{
                    background: this.state.form.categoryColor,
                    cursor: "pointer",
                    margin: "8px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ justifyContent: "flex-end" }}>
          <Grid item lg={4} md={4}>
            <ButtonPrimary
              text={this.state.configurableMenu.saveButton}
              loading={this.state.loadingSaveCategory}
              type={"submit"}
            />
          </Grid>
        </Grid>

        {/* Modal seleccion color */}
        <SimpleModal
          onClose={this.handleCloseModalColor}
          open={this.state.statusModalColor}
          title={""}
          noPadding={true}
          component={() => (
            <ColorCategoryModal
              classes={classes}
              data={this.state.form}
              handleClose={this.handleCloseModalColor}
              onChange={this.handleChangeComplete}
            />
          )}
        />
      </form>
    );
  }
}

NewCategory.propTypes = {};

const mapStateToProps = (state) => ({
  categoryReducer: state.categoryReducer,
  articleReducer: state.articleReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  createCategoryAction,
  updateCategoryAction,
  getCategoryPagination,
  dataCleanCloseForm,
  cleanStatusEdit,
  getMenuComponentAction,
  loadCategoryAction,
})(withStyles(useStyles)(NewCategory));
