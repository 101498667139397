 import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiButton-contained.Mui-disabled': {
      color: theme.palette.primaryColor,
      backgroundColor: '#7DD1F8',
    },
    fontSize: '1.5vh',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: '100%'
  },
  buttonSuccess: {
    backgroundColor: theme.palette.thirdColor,
    color: theme.palette.primaryColor,
    '&:hover': {
      backgroundColor: blue[800],
    },
    width: '100%',
    height: 50,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 18
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ButtonLink(props) {
  const classes = useStyles();
  const { onClick, loading, text, disabled, href } = props;


  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          className={classes.buttonSuccess}
          disabled={loading === true || disabled === true ? true : false}
          onClick={onClick}
          target="_blank"
          href={href}
        >
          {text}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}
