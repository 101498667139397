import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { URL_IMAGES } from "../../../../config/config";

import { getArticleInitials } from "../utils";
import isEmpty from "../../../../utils/isEmpty";

const useStyles = makeStyles((theme) => ({
  category: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    height: 10,
    maxWidth: 320,
    width: "100%",
  },
  containerImage: {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    display: "flex",
    height: 140,
    justifyContent: "center",
    maxWidth: 320,
    mozBackgroundSize: "cover !important",
    oBackgroundSize: "cover !important",
    paddingTop: "55% !important",
    webkitBackgroundSize: "cover !important",
    width: "100%",
  },
  titleImage: {
    color: theme.palette.primaryColor,
    fontSize: "3.1vh",
    marginTop: "-20%",
  },
}));

export const ItemImage = ({ color, descripcion, imagen }) => {
  const classes = useStyles();

  /**
   * * Obtiene el color de fondo para un contenedor según una imagen y un color dado.
   * @param {string} imagen - La URL de la imagen.
   * @param {string} color - El color de fondo.
   * @returns {string} El color de fondo calculado.
   */
  const getBackgroundColor = (imagen, color) => {
    if (isEmpty(imagen)) {
      return isEmpty(color) ? "rgba(0, 0, 0, 0.5)" : color;
    } else {
      return "rgba(0, 0, 0, 0.5)";
    }
  };

  /**
   * * Estilo del contenedor de la imagen del artículo del carrito.
   * @property {string} backgroundImage - La URL de la imagen de fondo del contenedor.
   * @property {string} backgroundColor - El color de fondo del contenedor segun la categoria o por defecto.
   */
  const containerStyle = {
    backgroundImage: `url(${URL_IMAGES + imagen})`,
    backgroundColor: getBackgroundColor(imagen, color),
  };

  return (
    <Grid item xl={2} lg={3} xs={12}>
      <div className={classes.containerImage} style={containerStyle}>
        {isEmpty(imagen) && (
          <span className={classes.titleImage}>
            {getArticleInitials(descripcion)}
          </span>
        )}
      </div>
      <div
        className={classes.category}
        style={{
          backgroundColor: getBackgroundColor(null, color),
        }}
      />
    </Grid>
  );
};
