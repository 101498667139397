import { types } from "../types/types";

const initialState = {
  loadingGetGraphic: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_BILL_CLIENT:
      return {
        ...state,
        getBillClient: action.payload,
      };
    case types.GET_COLLABORATOR_RANKING:
      return {
        ...state,
        getRakingColaborator: action.payload,
        statusCodeColaborator: action.payload1,
      };
    case types.GET_BILLING:
      return {
        ...state,
        getBilling: action.payload,
        totalSold: action.payload1,
        statusCodeBilling: action.payload2,
        countInvoice: action.payload3
      };

    case types.GET_INCOME:
      return {
        ...state,
        getIncome: action.payload,
        statusCodeIncome: action.payload1,
      };
    case types.GET_BEST_CUSTOMER:
      return {
        ...state,
        getCustomer: action.payload,
        statusCodeCustomer: action.payload1,
      };
    case types.GET_BRANCH_RAKING:
      return {
        ...state,
        getRakingBranch: action.payload,
        statusCodeBranch: action.payload1,
      };
    case types.GET_RAKING_ITEMS:
      return {
        ...state,
        getRakingItem: action.payload,
        statusCodeItem: action.payload1,
      };
    case types.GET_ISSUED_INVOICE:
      return {
        ...state,
        quantityInvoice: action.payload,
      };
    default:
      return state;
  }
}
