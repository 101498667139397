import { types } from "../types/types";

const initialState = {
  supplierData: [],
  providerData: [],
  documentData: [],
  detailDocumentSupport: null,
  ProviderDataComplete: [],
  documentSupportData: [],
  documentSupportFilter: [],
  favorite: false,
  loadingGetDocumentSupportData: false,
  loadingGetDocumentSupportDetail: false,
  loadingUpdateStatusDocSupport: false,
  loadingUpdateStatusDocSupportFavorite: false,
  loadingGetNoteIdDs: 0,
  idFavorite: null,
  totaldocuments: 0,
  loadingReportDocument: [],
  conceptsNotesAdjustment: [],
  sendMailDocSupport: {},
};

export const documentSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUPPLIER_DATA:
      return {
        ...state,
        supplierData: action.payload,
      };
    case types.FILL_PROVIDER_DATA:
      return {
        ...state,
        providerData: action.payload,
      };
    case types.FILL_DOCUMENT_DATA:
      return {
        ...state,
        documentData: action.payload,
      };
    case types.CREATE_PROVIDER_DATA:
      return {
        ...state,
        ProviderDataComplete: action.payload,
      };
    case types.CREATE_DOCUMENT_SUPPORT:
      return {
        ...state,
        documentSupportData: action.payload,
      };
    case types.SET_FAVORITE_PROVIDER:
      return {
        ...state,
        favorite: action.payload,
      };
    case types.GET_DOCUMENT_SUPPORT:
      return {
        ...state,
        documentSupportFilter: action.payload,
        totaldocuments: action.payload1,
      };
    case types.GET_DETAIL_DOCUMENT_SUPPORT:
      return {
        ...state,
        detailDocumentSupport: action.payload,
      };
    case types.LOADING_GET_DOCUMENT_SUPPORT_DATA:
      return {
        ...state,
        loadingGetDocumentSupportData: action.payload,
      };
    case types.LOADING_GET_DOCUMENT_SUPPORT_DETAIL:
      return {
        ...state,
        loadingGetDocumentSupportDetail: action.payload,
      };
    case types.CLEAN_DOCUMENT_SUPPORT:
      return {
        ...state,
        supplierData: [],
        providerData: [],
        documentData: [],
        ProviderDataComplete: [],
        documentSupportData: [],
        favorite: false,
      };
    case types.LOADING_REPORT_DOCUMENT_SUPPORT:
      return {
        ...state,
        loadingReportDocument: action.payload,
      };
    case types.LOADING_UPDATE_STATUS_DOCUMENT_SUPPORT:
      return {
        ...state,
        loadingUpdateStatusDocSupport: action.payload,
      };
    case types.LOADING_UPDATE_FAVORITE_DOCUMENT_SUPPORT:
      return {
        ...state,
        loadingUpdateStatusDocSupportFavorite: action.payload,
        idFavorite: action.payload1,
      };
    case types.GET_CONCEPTS_NOTES_ADJUSTMENT:
      return {
        ...state,
        conceptsNotesAdjustment: action.payload,
      };
    case types.LOADING_GET_NOTE_DOCUMENT:
      return {
        ...state,
        loadingGetNoteIdDs: action.payload,
      };
    case types.SUCESS_SEND_DOCSUPPORT:
      return {
        ...state,
        sendMailDocSupport: action.payload,
      };
    default:
      return state;
  }
};
