/**
 * * Un objeto que mapea los tipos de documentos con las secciones que se deben mostrar del Carrito de Documentos
 * * Cada clave del objeto representa un tipo de documento y su valor es un array de nombres de secciones a renderizar em el carrito de documentos.
 *
 * @property {string[]} FA - Secciones a mostrar para el tipo de documento "Factura".
 * @property {string[]} CO - Secciones a mostrar para el tipo de documento "Cotización".
 * @property {string[]} DS - Secciones a mostrar para el tipo de documento "Documento Soporte".
 * @property {string[]} DE - Secciones a mostrar para el tipo de documento "Documento Equivalente".
 */
const documentSectionsMapping = {
  FA: [
    "sectionItemImage",
    "sectionApplyGift",
    "sectionItemDiscounts",
    "sectionItemRetention",
    "sectionItemGift",
    "sectionUnitValue",
    "sectionItemQuantity",
    "sectionTaxItem",
    "sectionTotalItem",
  ],
  CO: [
    "sectionItemImage",
    "sectionApplyGift",
    "sectionItemDiscounts",
    "sectionItemRetention",
    "sectionItemGift",
    "sectionUnitValue",
    "sectionItemQuantity",
    "sectionTaxItem",
    "sectionTotalItem",
  ],
  DS: [
    "sectionItemImage",
    "sectionItemRetention",
    "sectionUnitValue",
    "sectionItemQuantity",
    "sectionTaxItem",
    "sectionTotalItem",
  ],
  DE: [
    "sectionItemImage",
    "sectionApplyGift",
    "sectionItemDiscounts",
    "sectionItemRetention",
    "sectionItemGift",
    "sectionUnitValue",
    "sectionItemQuantity",
    "sectionTaxItem",
    "sectionTotalItem",
  ],
};

/**
 * * Obtiene las secciones a mostrar según el tipo de documento.
 * @param {string} typeDocument - El tipo de documento.
 * @returns {Array} - Las secciones a mostrar.
 */
export const getSectionsItemsToShow = (typeDocument) => {
  const documentType = typeDocument.replace(/_NOTE$/, "");
  const sections = documentSectionsMapping[documentType] || [];
  return sections;
};
