import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";

import { CollapseFormPanel } from "../../components/ui/collapseFormPanel";
import { CustomersList } from "./customersList";
import { CustomerDetail } from "./customerDetail";

import {
  getCustomerDetailAction,
  loadCustomerActive,
  cleanCustomerDataAction,
  deleteCustomerAction,
} from "../../actions/customerAction";
import { cleanDataAddressAction } from "../../actions/addressCustomerAction";
import { cleanDataContactAction } from "../../actions/contactCustomerAction";
import CustomProgress from "../../components/Progress/progress.component";
import { CustomerForm } from "./customerForm";
import { ConfirmAlert } from "../../helpers/alert.helpers";

import { MESSAGE_DELETE_CONFIRM } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    margin: "20% auto",
    textAlign: "center",
  },
}));

export const CustomerManage = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandPanelCustomer, setExpandPanelCustomer] = useState(false);
  const {
    customerDetail,
    loadingDetail,
    customerActive,
    loading: loadingList,
  } = useSelector((state) => state.customerReducer);

  /**
   * Cargue incial
   */
  useEffect(() => {
    return () => {
      dispatch(cleanCustomerDataAction());
    };
  }, [dispatch]);

  /**
   * Carga información cliente en formulario, si esta abierto el formulario
   */
  useEffect(() => {
    if (!!customerDetail && expandPanelCustomer === true) {
      dispatch(loadCustomerActive(customerDetail));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetail, dispatch]);

  /**
   * Limpia formaulario cuando se cierra panel
   */
  useEffect(() => {
    if (!!customerActive && !expandPanelCustomer) {
      dispatch(loadCustomerActive(null));
      dispatch(cleanDataContactAction());
      dispatch(cleanDataAddressAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, expandPanelCustomer]);

  /**
   * Contrae o expande formulario cliente
   */
  const handleExpandCustomer = () => {
    setExpandPanelCustomer((prev) => !prev);
  };

  /**
   * Contrae panel nuevo cliente
   */
  const handleCollapsePanelCustomer = () => {
    setExpandPanelCustomer(false);
  };

  /**
   * Consulta  detlla cliente
   * @param {*} id
   */

  const handleSelectionCustomer = useCallback(
    (id) => {
      dispatch(getCustomerDetailAction(id));
    },
    [dispatch]
  );

  /**
   * Carga información de cliente en formulario para edición
   */
  const handleEditCustomer = useCallback(() => {
    dispatch(loadCustomerActive(customerDetail));
    setExpandPanelCustomer(true);
  }, [customerDetail, dispatch]);

  /**
   * Borra información cliente
   */
  const handleDeleteCustomer = useCallback(
    (id) => {
      const deleteData = (confirmed) => {
        if (confirmed) {
          dispatch(deleteCustomerAction(id, completeDelete));
        }
      };
      ConfirmAlert(
        `¿Estás seguro de eliminar este cliente?`,
        deleteData,
        MESSAGE_DELETE_CONFIRM
      );
    },
    [dispatch]
  );

  /**
   * Cierra panel formulario despues e eliminar
   */
  const completeDelete = () => {
    setExpandPanelCustomer(false);
  };

  return (
    <Grid container>
      <Grid item lg={12} xs={12}>
        <CollapseFormPanel
          component={
            <CustomerForm
              handleCollapsePanel={handleCollapsePanelCustomer}
              type={type}
            />
          }
          title={"Crear nuevo cliente"}
          titleOpen={!!customerActive ? "Editar cliente" : "Nuevo cliente"}
          open={expandPanelCustomer}
          onClick={handleExpandCustomer}
          disabled={loadingDetail || loadingList}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={6}>
            <CustomersList
              handleSelection={handleSelectionCustomer}
              consultFirst={true}
              height={"73vh"}
              type={type}
            />
          </Grid>
          <Grid item lg={6} xs={6}>
            {loadingDetail || loadingList ? (
              <div
                className={`${classes.containerLoading} animate__animated animate__fadeIn`}
              >
                <CustomProgress />
              </div>
            ) : customerDetail === null ? (
              <div className={classes.containerLoading}>
                No se ha encontrado información
              </div>
            ) : (
              <CustomerDetail
                data={customerDetail}
                handleEditCustomer={handleEditCustomer}
                handleDeleteCustomer={handleDeleteCustomer}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
