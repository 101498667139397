import { axiosApiInstance } from "../config/axios-instance";
import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from "../config/config";
import { WarningAlert } from "../helpers/alert.helpers";
import { types } from "../types/types";
import { enterpriseIdHeader } from "./authActions";

const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

/**
 * Agrega nuevo campo personalizado
 * @param {*} data 
 */
export const addNewCustomFieldAction = (data, reset) => async (dispatch) => {

    try {
        changeStatusLoadingSave(dispatch, true);
        if (data.guardar) {

            let body = { ...data, id: 0, empresaId: enterpriseIdHeader() };
            const res = await axiosApiInstance.post(`${urlEnterprise}/SaveAdditionalData`, body, defaultHeaders());

            //Campo existente en Bd
            if (res.data.statusCode === "210") {
                WarningAlert("Campo existente", `Ya agregaste un campo con el nombre ${data.codigo}`);
                return;
            }

            data = { ...data, id: res.data.result }
        }

        dispatch({
            type: types.ADD_CUSTOM_FIELD,
            payload: data
        })

        reset();

    } catch (err) {
        dispatch({
            type: types.showError,
            payload: {
                message: 'No se ha podido agregar nuevo dato adicional.',
                error: err
            }
        });
    } finally {
        changeStatusLoadingSave(dispatch, false);
    }
}


/**
 * Obtiene listado campos personalizables de empresa.
 * @returns 
 */
export const getCustomFieldListAction = (fieldsdocument = []) => async (dispatch, getState) => {

    try {

        changeStatusLoadingGetList(dispatch, true);

        const { customFieldsList } = getState().customFieldReducer;

        if (customFieldsList.length > 0) {
            if (fieldsdocument.length > 0) {
                dispatch(mergeCustomFieldListAction(customFieldsList, fieldsdocument));
            }
            return;
        }


 
        let config = defaultHeaders();
        Object.assign(config.headers, {
            'pEmpresaId': enterpriseIdHeader(),
        });

        var res = await axiosApiInstance.get(`${urlEnterprise}/GetAdditionalData`, config);
        let customFields = res.data.result.map(item => ({
            ...item,
            guardar: true
        }));

        dispatch(mergeCustomFieldListAction(customFields, fieldsdocument));


    } catch (err) {
        dispatch({
            type: types.showError,
            payload: {
                message: 'No se ha podido consultar listado de campos adicionales.',
                error: err
            }
        });

    } finally {
        changeStatusLoadingGetList(dispatch, false);
    }
}

/**
 * Une listado campos registrados por empresa y campos registrados por documento,
 * elimina repetidos
 * @param {*} customFieldsList 
 * @param {*} fieldsdocument 
 * @returns 
 */
const mergeCustomFieldListAction = (customFieldsList, fieldsdocument = []) => {

    let newList = [...fieldsdocument];

    for (let i = 0; i < customFieldsList.length; i++) {
        const item = customFieldsList[i];
        let exist = newList.find(c => c.codigo.trim() === item.codigo);
        if (!exist) {
            newList.push(item);
        } else {
            newList = newList.map(c =>
                c.codigo === item.codigo ?
                    {
                        ...item,
                        valor: c.valor,
                        guardar: true
                    }
                    : c
            )
        }
    }

    return ({
        type: types.REFRESH_FIELD_LIST,
        payload: newList
    })

}

/**
 * Borra campo adicional
 * @returns 
 */
export const deleteCustomFieldAction = (id, deleteBD) => async (dispatch) => {

    try {

        changeStatusLoadingDelete(dispatch, id);
        if (deleteBD) {
            let config = defaultHeaders();
            Object.assign(config.headers, {
                'pId': id
            });
            const res = await axiosApiInstance.post(`${urlEnterprise}/DeleteAdditionalData`, null, config);
            console.log(res);
        }

        dispatch({
            type: types.DELETE_CUSTOM_FIELD,
            payload: id
        })


    } catch (err) {
        dispatch({
            type: types.showError,
            payload: {
                message: 'No se ha podido eliminar  dato adicional.',
                error: err
            }
        });
    } finally {
        changeStatusLoadingDelete(dispatch, 0);
    }
}

const changeStatusLoadingSave = (dispatch, status) => {
    dispatch({
        type: types.LOADING_SAVE_FIELD,
        payload: status
    })
}

const changeStatusLoadingGetList = (dispatch, status) => {
    dispatch({
        type: types.LOADING_GET_FIELDS,
        payload: status
    })
}

const changeStatusLoadingDelete = (dispatch, status) => {
    dispatch({
        type: types.LOADING_DELETE_FIELDS,
        payload: status
    })
}