import { enterpriseIdHeader } from "../actions/authActions";
import { axiosApiInstance } from "../config/axios-instance";
import { API_ENDPOINT_CUSTOMERS, API_ENDPOINT_DOCUMENT, defaultHeaders } from "../config/config";

import {readError} from "../helpers/readError";

const urlBulkLoadClient = `${API_ENDPOINT_DOCUMENT}/document/api/customer/`;
const urlEndPoint = `${API_ENDPOINT_CUSTOMERS}/customer/api/Cliente`;



/**
 * Valida existencia registros en BD
 */
export const checkValidationsClientService = async (inputList) => {
 
    try {

        inputList = {
            ...inputList,
            empresaId: `${enterpriseIdHeader()}`,
        }

        const response = await axiosApiInstance.post(`${urlBulkLoadClient}ValidateInformationClient`, inputList, defaultHeaders());

        if (response.data.result != null) {
            return response.data.result != null ? response.data.result : null;
        }

    } catch (err) {
        readError({
            message: 'No se ha podido validar los datos de cliente ingresados.',
            error: err
        })
        return null;
    }
}

/**
 * validar existencia de registros nformación adicional en BD
 */
export const checkAditionalValidationsClientService = async (inputList) => {

    try {

        inputList = {
            ...inputList,
            empresaId: `${enterpriseIdHeader()}`,
        }

        const response = await axiosApiInstance.post(`${urlBulkLoadClient}ValidateInformationAditionalClient`, inputList, defaultHeaders());
        return response.data.result != null ? response.data.result : null;

    } catch (err) {
        readError({
            message: 'No se ha podido validar los información adicional ingresada.',
            error: err
        })
        return null;
    }
}


export const saveBulkLoadClientService = async (clients) => {

    try {

        const response = await axiosApiInstance.post(`${urlEndPoint}${'/CreateCustomerBulk'}`, clients, defaultHeaders());
        return response.data.result != null ? response : null;

    } catch (err) {
        readError({
            message: 'No se ha podido registrar cliente.',
            error: err
        })
        return null;
    }
}




  