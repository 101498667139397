import React from "react";

import { useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { updateItemTotalAction } from "../../../../actions/documentCartAction";

import RadioButtonGroupSmall from "../../../../components/radioButton/radioButtonSmall.component";

import getTypeValue from "../../../../utils/typeValue";

export const DiscountsItemSection = ({
  discountType,
  handleUpdateForm,
  itemDoc,
}) => {
  const dispatch = useDispatch();

  /**
   * * Maneja el cambio del tipo de descuento.
   * @param {Event} event - El evento del cambio.
   */
  const handleChangeDicountType = ({ target }) => {
    handleUpdateForm({
      discountType: target.value,
      discountValue: 0,
    });

    let newdata = {
      ...itemDoc,
      porcentajedescuento: 0,
      tipodescuento: parseInt(target.value),
      valordescuento: 0,
    };
    dispatch(updateItemTotalAction(newdata));
  };

  return (
    <Grid item lg={6} xs={12}>
      <RadioButtonGroupSmall
        label={"Descuentos"}
        name={"discountType"}
        onChange={handleChangeDicountType}
        options={getTypeValue()}
        value={discountType}
      />
    </Grid>
  );
};
