import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { DiscountsItemSection, DiscountTypeSection } from "./";

const useStyles = makeStyles((theme) => ({
  commonMargin: {
    marginTop: 15,
  },
}));

export const ItemDiscountsComponent = (props) => {
  const classes = useStyles();

  const { isGift } = props;

  return (
    !isGift && (
      <Grid container className={classes.commonMargin}>
        <DiscountsItemSection {...props} />
        <DiscountTypeSection {...props} />
      </Grid>
    )
  );
};
