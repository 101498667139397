import React from "react";
import { Grid, IconButton } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "initial",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  paper: {
    maxHeight: 800,
    backgroundColor: "#272727",
    border: "none",
    borderRadius: 5,
    overflowY: "auto",
    overflowX: "hidden",
    color: "#EEEEEE",
    outline: 0,
  },
  iconClose: {
    color: theme.palette.thirdColor,
    padding: 0,
  },
  buttonClose: {
    textAlign: "end",
    padding: 10,
  },
  title: {
    textAlign: "center",
    color: "#17DAFA",
    padding: 10,
    fontSize: 30,
    fontWeight: "800",
  },
}));

export const SecondCustomModal = ({
  component,
  open,
  onClose,
  title,
  width,
  showCloseButton,
  disabled,
  noPadding,
  height,
  disableBackdropClick,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={`${classes.modal} animate__animated animate__fadeIn animate__faster`}
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick ?? false}
    >
      <div
        className={classes.paper}
        style={{
          width: !!width ? width : 600,
          height: !!height ? height : "",
          padding:
            noPadding === true ? "0px 0px 10px 0px" : "5px 20px 10px 20px",
        }}
      >
        <Grid container>
          {showCloseButton ? (
            <>
              <Grid item lg={10} xs={10} className={classes.title}>
                {title}
              </Grid>
              <Grid item lg={2} xs={2} className={classes.buttonClose}>
                <IconButton
                  className={classes.iconClose}
                  onClick={onClose}
                  disabled={disabled}
                >
                  <CloseIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Grid>
            </>
          ) : (
            <Grid item lg={12} xs={12} className={classes.title}>
              {title}
            </Grid>
          )}
          <Grid item lg={12} xs={12}>
            <Grid container>{component}</Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
