import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import InputForm from "../../components/input/input.component";
import SelectForm from "../../components/select/select.component";
import { SuccessAlert } from "../../helpers/alert.helpers";
import { withStyles } from "@material-ui/core/styles";
import ToggleButtons from "../../components/toggle/toggle.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import {
  postDiscount,
  putDiscount,
  getDiscounts,
  changeEditingDiscountStatus,
  getReasonDiscount,
  clearCurrentDiscount,
  clearUpdateDiscount,
  clearCreateDiscount,
} from "./../../actions/discountAction";
import {
  regexDecimal,
  regexTextSpecial,
} from "../../helpers/customRegex.hepers";
import TooltipMessage from "../../components/tooltip/tootltip-message.component";

import {
  TIME_UPDATE_REGISTER,
  TIME_CREATE_REGISTER,
} from "../../config/config";

const useStyles = (theme) => ({
  paddingTop: {
    paddingTop: 200,
  },
});
class NewDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        toogleDiscountModel: "1",
        toogleDiscountType: "1",
        txtDiscountDescription: "",
        txtDiscountValue: 0,
        ddlDiscountReason: "",
      },
      createDiscount: {},
      updateDiscount: {},
      currentDiscount: {},
      reasonDiscount: [],
      editingDiscountData: false,
      enterpriseId: 6,
      alterForm: {
        createdby: JSON.parse(localStorage.getItem("dataCompany")).userId,
        createdon: new Date(),
        empresaid: JSON.parse(localStorage.getItem("dataCompany")).companyId,
        esglobal: false,
        id: 0,
        idbusinessunit: 0,
        idowner: 0,
        idstate: 0,
        modifiedby: JSON.parse(localStorage.getItem("dataCompany")).userId,
        modifiedon: new Date(),
      },
      loadingSave: false,
      menuDiscount: {},
    };

    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.discountReducer.createDiscount) {
      if (nextProps.discountReducer.createDiscount.statusCode === "201") {
        update.createDiscount = nextProps.discountReducer.createDiscount;
      }
    }

    if (!!nextProps.discountReducer.updateDiscount) {
      if (nextProps.discountReducer.updateDiscount.statusCode === "201") {
        update.updateDiscount = nextProps.discountReducer.updateDiscount;
      }
    }

    if (!!nextProps.discountReducer.reasonDiscount) {
      if (nextProps.discountReducer.reasonDiscount.statusCode === "200") {
        update.reasonDiscount = nextProps.discountReducer.reasonDiscount.result;
      }
    }

    if (nextProps.discountReducer.currentDiscount !== state.currentDiscount) {
      update.currentDiscount = nextProps.discountReducer.currentDiscount;
      update.editingDiscountData =
        nextProps.discountReducer.editingDiscountData;
    }

    if (nextProps.discountReducer.loadingSave !== state.loadingSave) {
      update.loadingSave = nextProps.discountReducer.loadingSave;
    }

    if (
      !!nextProps.menuReducer.menuDiscount &&
      nextProps.menuReducer.menuDiscount !== state.menuDiscount
    ) {
      update.menuDiscount = nextProps.menuReducer.menuDiscount;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getReasonDiscount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.createDiscount !== this.state.createDiscount) {
      if (this.state.createDiscount.statusCode === "201") {
        SuccessAlert(
          null,
          this.state.menuDiscount.recordSuccessBody,
          TIME_CREATE_REGISTER
        );
        this.cleanData();
      }
    }

    if (prevState.currentDiscount !== this.state.currentDiscount) {
      if (!!this.state.currentDiscount)
        this.loadInfo(this.state.currentDiscount);
    }

    if (prevState.updateDiscount !== this.state.updateDiscount) {
      if (this.state.updateDiscount.statusCode === "201") {
        SuccessAlert(
          null,
          this.state.menuDiscount.updateSuccessBody,
          TIME_UPDATE_REGISTER
        );
        this.cleanData();
        this.props.changeEditingDiscountStatus(false);
      }
    }

    if (prevState.components !== this.state.components) {
      if (!!this.state.components && this.state.components.length > 0) {
        this.assignConfigData();
      }
    }

    if (prevState.editingDiscountData !== this.state.editingDiscountData) {
      if (this.state.editingDiscountData === false) {
        this.cleanData();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentDiscount();
    this.props.clearUpdateDiscount();
    this.props.clearCreateDiscount();
  }

  filterConfigInfo(id) {
    const configData = this.state.components;
    const data = configData.find((item) => item.id === id);
    return data;
  }

  filterConfigInfoList(list, id) {
    const data = list.find((item) => item.id === id);
    return data;
  }

  cleanData() {
    this.setState({
      form: {
        toogleDiscountModel: "1",
        toogleDiscountType: "1",
        txtDiscountDescription: "",
        txtDiscountValue: 0,
        ddlDiscountReason: "",
      },
      alterForm: {
        createdby: JSON.parse(localStorage.getItem("dataCompany")).userId,
        createdon: new Date(),
        empresaid: JSON.parse(localStorage.getItem("dataCompany")).companyId,
        esglobal: false,
        id: 0,
        idbusinessunit: 0,
        idowner: 0,
        idstate: 0,
        modifiedby: JSON.parse(localStorage.getItem("dataCompany")).userId,
        modifiedon: new Date(),
      },
    });
    this.validator.hideMessages();
    this.props.clearCurrentDiscount();
    this.props.clearUpdateDiscount();
    this.props.clearCreateDiscount();
  }

  syncChanges(value, property) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [property]: value,
      },
    }));
  }

  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked,
      },
    }));
  };

  syncChangesValue = ({ target }) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  loadInfo(item) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleDiscountModel: item.modelodescuento === 1 ? "1" : "2",
        toogleDiscountType: item.tipodescuento === 1 ? "1" : "2",
        txtDiscountDescription: item.descripcion,
        txtDiscountValue: item.valor,
        ddlDiscountReason: item.motivoid,
      },

      alterForm: {
        ...prevState.alterForm,
        createdby: item.createdby,
        createdon: item.createdon,
        empresaid: item.empresaid,
        esglobal: item.esglobal,
        id: item.id,
        idbusinessunit: item.idbusinessunit,
        idowner: item.idowner,
        idstate: item.idstate,
        modifiedby: JSON.parse(localStorage.getItem("dataCompany")).userId,
        modifiedon: item.modifiedon,
      },
    }));
  }

  onSaveDiscount = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        modelodescuento: this.state.form.toogleDiscountModel === "1" ? 1 : 2,
        tipodescuento: this.state.form.toogleDiscountType === "1" ? 1 : 2,
        descripcion: this.state.form.txtDiscountDescription,
        valor: this.state.form.txtDiscountValue,
        motivoid: this.state.form.ddlDiscountReason,
        createdby: this.state.alterForm.createdby,
        createdon: this.state.alterForm.createdon,
        empresaid: this.state.alterForm.empresaid,
        esglobal: this.state.alterForm.esglobal,
        id: this.state.alterForm.id,
        idbusinessunit: this.state.alterForm.idbusinessunit,
        idowner: this.state.alterForm.idowner,
        idstate: this.state.alterForm.idstate,
        modifiedby: this.state.alterForm.modifiedby,
        modifiedon: this.state.alterForm.modifiedon,
      };

      if (this.state.editingDiscountData) this.props.putDiscount(data);
      else this.props.postDiscount(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleAlignmentDiscountModel = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleDiscountModel: !!newAlignment ? newAlignment : "1",
      },
    }));
  };

  handleAlignmentDiscountType = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        toogleDiscountType: !!newAlignment ? newAlignment : "1",
        txtDiscountValue: "",
      },
    }));
  };

  validateOnlyNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexDecimal;
    value = value.replace(regex, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  //Valida valor deciamal
  validateDecimal(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexDecimal, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  validateInputText(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    let regex = regexTextSpecial;
    value = value.replace(regex, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  validateFieldsOnBlur(e, name) {
    e.preventDefault();
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name);
    }
  }

  /**
   * Limpia valor cero por defecto de caja de texto
   * @param {} e Evento
   */
  onClickNotZero = (e) => {
    let value = e.target.value;

    if (value !== null && value !== undefined) {
      value = value?.replace(regexDecimal, "");
      if (value === 0 || value === "0") {
        value = value?.replace(regexDecimal, "");
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            txtDiscountValue: "",
          },
        }));
      }
    }
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ToggleButtons
            label={this.state.menuDiscount.txtModelDiscount}
            value={this.state.form.toogleDiscountModel}
            onChange={this.handleAlignmentDiscountModel}
            titleOne={"Comercial"}
            titleTwo={"Financiero"}
            txtTooltip={
              <TooltipMessage
                title={
                  !!this.state.menuDiscount.tooltipModelDiscountHeader
                    ? this.state.menuDiscount.tooltipModelDiscountHeader
                    : ""
                }
                message={
                  !!this.state.menuDiscount.tooltipModelDiscountBody
                    ? this.state.menuDiscount.tooltipModelDiscountBody
                    : ""
                }
                botton={
                  !!this.state.menuDiscount.tooltipModelDiscountBotton
                    ? this.state.menuDiscount.tooltipModelDiscountBotton
                    : ""
                }
                href={
                  !!this.state.menuDiscount.tooltipModelDiscountUrl
                    ? this.state.menuDiscount.tooltipModelDiscountUrl
                    : ""
                }
              />
            }
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <SelectForm
            id="ddlDiscountReason"
            label={this.state.menuDiscount.txtReasonDiscount}
            name="ddlDiscountReason"
            validator={this.validator}
            nameValidator={"MotivoDescuento"}
            validateOptions={"required"}
            value={this.state.form.ddlDiscountReason}
            options={this.state.reasonDiscount.map((item) => ({
              value: item.id,
              text: item.descripcion,
            }))}
            onChange={(ev) =>
              this.syncChanges(ev.target.value, "ddlDiscountReason")
            }
            placeholder={"Seleccione..."}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ToggleButtons
            label={this.state.menuDiscount.txtTypeDiscount}
            value={this.state.form.toogleDiscountType}
            onChange={this.handleAlignmentDiscountType}
            titleOne={"$"}
            titleTwo={"%"}
            txtTooltip={
              <TooltipMessage
                title={
                  !!this.state.menuDiscount.tooltipTypeDiscountHeader
                    ? this.state.menuDiscount.tooltipTypeDiscountHeader
                    : ""
                }
                message={
                  !!this.state.menuDiscount.tooltipTypeDiscountBody
                    ? this.state.menuDiscount.tooltipTypeDiscountBody
                    : ""
                }
                botton={
                  !!this.state.menuDiscount.tooltipTypeDiscountBotton
                    ? this.state.menuDiscount.tooltipTypeDiscountBotton
                    : ""
                }
                href={
                  !!this.state.menuDiscount.tooltipTypeDiscountUrl
                    ? this.state.menuDiscount.tooltipTypeDiscountUrl
                    : ""
                }
              />
            }
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <InputForm
            name={"txtDiscountValue"}
            label={this.state.menuDiscount.txtValueDiscount}
            value={this.state.form.txtDiscountValue}
            maxLength={this.state.form.toogleDiscountType === "1" ? 21 : 9}
            format={
              this.state.form.toogleDiscountType === "1"
                ? "money"
                : "porcentage"
            }
            onClick={this.onClickNotZero}
            onChange={(ev) => {
              this.syncChanges(ev.target.value, "txtDiscountValue");
              this.validateDecimal(ev);
            }}
            nameValidator={"Valor"}
            onBlur={(e) => this.validateFieldsOnBlur(e, "Valoruuu")}
            validator={this.validator}
            validateOptions={
              this.state.form.toogleDiscountType === "1"
                ? "required|valueTax"
                : "required|porcentage"
            }
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <InputForm
            id={"txtDiscountDescription"}
            label={this.state.menuDiscount.txtNameDiscount}
            maxLength={100}
            value={this.state.form.txtDiscountDescription}
            onChange={(ev) => {
              this.syncChanges(ev.target.value, "txtDiscountDescription");
              this.validateInputText(ev);
            }}
            nameValidator={"Descripción"}
            onBlur={(e) => this.validateFieldsOnBlur(e, "Descripción")}
            validator={this.validator}
            validateOptions={"required"}
          />
        </Grid>
        <Grid container>
          <Grid item lg={4} md={4}></Grid>
          <Grid item lg={4} md={4}></Grid>
          <Grid item lg={4} md={4} style={{ paddingTop: -50 }}>
            <ButtonPrimary
              text={this.state.menuDiscount.btnSaveDiscount}
              loading={this.state.loadingSave}
              onClick={this.onSaveDiscount}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  discountReducer: state.discountReducer,
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {
  postDiscount,
  putDiscount,
  getDiscounts,
  getReasonDiscount,
  changeEditingDiscountStatus,
  clearCurrentDiscount,
  clearUpdateDiscount,
  clearCreateDiscount,
})(withStyles(useStyles)(NewDiscount));
