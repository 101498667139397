import { types } from "../types/types";

const initialState = {
	opdocumento: {
		id: 0,
		numero: null,
		tipoaceptacionid: 1,
		dianformapagoid: "",
		dianformapagodescripcion: "",
		dianmediopagoid: "",
		dianmediopagodescripcion: "",
		fecha: "",
		fechapago: null,
		formaPago: "",
		incluirVendedor: false,
		numeracionid: "",
		numeracion: null,
		observaciones: "",
		rangofecha: 0,
		sucursalid: "",
		vendedorid: "",
		vendedor: null,
		documentoreferenciaid: null,
		documentoref: "",
	},
	adcliente: null,
	direccionclienteid: null,
	contactoclienteid: null,
	datosadicionales: {},
	opdocumentosreferencia: [],
	addatosadicionales: [],
	opdatosadicionales: [],
	favorito: false,
	tiponotaactiva: "",
	numeraciondisponible: [],
	oppacientes: [],
	generardocumentopaciente: false,
	escontingencia: false,
};

export const documentReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.LOAD_ADDRESSS_CUSTOMER:
			return {
				...state,
				direccionclienteid: action.payload,
			};
		case types.LOAD_CONTACT_CUSTOMER:
			return {
				...state,
				contactoclienteid: action.payload,
			};
		case types.LOAD_CUSTOMER_DOCUMENT:
			return {
				...state,
				adcliente: action.payload,
			};
		case types.LOAD_HEAD_DOCUMENT:
			return {
				...state,
				opdocumento: {
					...state.opdocumento,
					...action.payload,
				},
			};
		case types.LOAD_CUSTOM_FIELDS_DOCUMENT:
			return {
				...state,
				datosadicionales: { ...action.payload },
			};
		case types.LOAD_DOCS_REFERENCE_DOCUMENT:
			return {
				...state,
				opdocumentosreferencia: action.payload,
			};
		case types.LOAD_CUSTOM_FIELDS_COMPANY:
			return {
				...state,
				addatosadicionales: action.payload,
			};
		case types.STAND_OUT_DOCUMENT:
			return {
				...state,
				favorito: !state.favorito,
			};
		case types.SET_CURRENT_TYPE_NOTE:
			return {
				...state,
				tiponotaactiva: action.payload,
			};
		case types.SET_AVAILABLE_NUMERTAION:
			return {
				...state,
				numeraciondisponible: action.payload,
			};
		case types.ADD_NUMERATION_DOCUMENT:
			return {
				...state,
				opdocumento: {
					...state.opdocumento,
					numeracionid: action.payload?.id ?? 0,
					numeracion: action.payload,
				},
			};
		case types.LOAD_FIELDS_DOCUMENT:
			return {
				...state,
				opdatosadicionales: action.payload,
			};
		case types.LOAD_PATIENTS_DOCUMENT:
			return {
				...state,
				oppacientes: action.payload,
			};
		case types.SET_GENERATE_PATIENT_DOCUMENT:
			return {
				...state,
				generardocumentopaciente: action.payload,
			};
		case types.SET_CONTINGENCY_STATUS:
			return {
				...state,
				escontingencia: action.payload,
			};

		case types.CLEAN_DOCUMENT:
			return {
				...initialState,
			};

		default:
			return state;
	}
};
