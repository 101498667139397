import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import RoomIcon from '@material-ui/icons/Room';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';




const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        backgroundColor: theme.palette.fourthBackgroundColor,
        color: theme.palette.primaryColor,
        fontWeight: 500,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 55
        },
        '& .MuiCardHeader-root': {
            paddingBottom: 0,
            paddingTop: 5
        }
    },
    title: {
        color: theme.palette.primaryColor,
        fontWeight: 600,
    },
    content: {
        textTransform: 'capitalize',
        padding: '5px 10px'

    },
    icon: {
        color: '#2CC63E'
    },
    header: {
        padding: '5px 10px 0px 10px'
    },
    cardContent: {
        padding: '5px 10px 0px 42px'
    }
}));


export const AddressFormCard =React.memo(({
    handleEdit,
    handleDelete,
    descripcion,
    direccion,
    departamentodescripcion,
    ciudaddescripcion
}) => {
    const classes = useStyles();

    
    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.header}
                avatar={
                    <RoomIcon className={classes.icon} />
                }
                action={
                    <>
                        <Tooltip title="Editar">
                            <IconButton aria-label="Editar" onClick={handleEdit}>
                                <CreateIcon className={classes.icon}  />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                            <IconButton aria-label="Eliminar"  onClick={handleDelete}>
                                <DeleteIcon className={classes.icon} />
                            </IconButton>
                        </Tooltip>

                    </>
                }
                classes={{
                    title: classes.title,
                }}
                title={descripcion}
                subheader=""
            />
            <CardContent className={classes.cardContent}>
                <Typography  variant="body2" className={classes.content} component="p">
                    {direccion}
                </Typography>
                <Typography  variant="body2" className={classes.content} component="p">
                    {`${departamentodescripcion} - ${ciudaddescripcion}`}<br />
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
            </CardActions>
        </Card>
    )
})
