/**
 * * Obtiene estilos para label estado documento
 * @param {*} item
 * @returns
 */
export const getStyleStatusItem = (item, selected) => {
  if (selected) return "totalSupportGridSelected";

  if (item?.anulacion === true) return "totalSupportGridAnnulment";

  if (item?.estadoId === "01") return "totalSupportGridPaid";

  if (item?.estadoId === "03") return "totalSupportGridInArrears";

  return "totalSupportGridUnpaid";
};
