import { types } from "../types/types";
import { axiosApiInstance } from "../config/axios-instance";
import { API_ENDPOINT_CONFIGURATION, defaultHeaders } from "../config/config";

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/config/api`;

/**
 * Consulta listado menus configurables
 */

export const getConfigurableMenuAction = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}/menu/GetConfigurableMenu`;
    const response = await axiosApiInstance.get(url, defaultHeaders());
    const menu = response?.data?.result;
    getMenuMyAccount(menu, dispatch);
    getMenuArticle(menu, dispatch);
    getMenuInvoice(menu, dispatch);
    getMenuDocSupport(menu, dispatch);
    getMenuDashboard(menu, dispatch);
    getMenuHelp(menu, dispatch);
    getMenuCharge(menu, dispatch);
    getMenuDiscount(menu, dispatch);
    getMenuInvoiceReceived(menu, dispatch);
    getMenuCustomer(menu, dispatch);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando ménu configurable",
        error: err,
      },
    });
  }
};

/**
 * Obtiene informacion menu  seccion mi cuenta
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuMyAccount = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      titleTypePerson: menu?.find((e) => e.id === 16)?.texto ?? "",
      messageTypePerson: menu?.find((e) => e.id === 17)?.texto ?? "",
      bottonTypePerson: menu?.find((e) => e.id === 18)?.texto ?? "",
      urlTypePerson: menu?.find((e) => e.id === 18)?.url ?? "",
      txtMessageConfigurationTemplate:
        menu?.find((e) => e.id === 282)?.texto ?? "",
      txtMessageErrorTemplate: menu?.find((e) => e.id === 283)?.texto ?? "",
      txtSelectTemplate: menu?.find((e) => e.id === 284)?.texto ?? "",
      txtDeleteImage: menu?.find((e) => e.id === 285)?.texto ?? "",

      titleHealthCode: menu?.find((e) => e.id === 362)?.texto ?? "",
      messageHealthCode: menu?.find((e) => e.id === 363)?.texto ?? "",
      bottonHealthCode: menu?.find((e) => e.id === 364)?.texto ?? "",
      urlHealthCode: menu?.find((e) => e.id === 364)?.url ?? "",

      titlePostalCode: menu?.find((e) => e.id === 393)?.texto ?? "",
      messagePostalCode: menu?.find((e) => e.id === 394)?.texto ?? "",
      bottonPostalCode: menu?.find((e) => e.id === 395)?.texto ?? "",
      urlPostalCode: menu?.find((e) => e.id === 395)?.url ?? "",

      titleSellerOrigin: menu?.find((e) => e.id === 396)?.texto ?? "",
      messageSellerOrigin: menu?.find((e) => e.id === 397)?.texto ?? "",
      bottonSellerOrigin: menu?.find((e) => e.id === 398)?.texto ?? "",
      urlSellerOrigin: menu?.find((e) => e.id === 398)?.url ?? "",
    };

    dispatch({
      type: types.GET_MENU_MYACCOUNT,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu  seccion articulos
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuArticle = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      titleIVA: menu.find((e) => e.id === 56)?.texto ?? "Aplica IVA",
      messageIVA: menu.find((e) => e.id === 67)?.texto ?? "",
      bottonIVA: menu.find((e) => e.id === 68)?.texto ?? "",
      hrefIVA: menu.find((e) => e.id === 68)?.url ?? "",

      titleINC: menu.find((e) => e.id === 58)?.texto ?? "Aplica INC",
      messageINC: menu.find((e) => e.id === 69)?.texto ?? "",
      bottonINC: menu.find((e) => e.id === 70)?.texto ?? "",
      hrefINC: menu.find((e) => e.id === 70)?.url ?? "",

      titleICUI: menu.find((e) => e.id === 371)?.texto ?? "Aplica ICUI",
      messageICUI: menu.find((e) => e.id === 372)?.texto ?? "",
      bottonICUI: menu.find((e) => e.id === 373)?.texto ?? "",
      hrefICUI: menu.find((e) => e.id === 373)?.url ?? "",

      titleIBUA: menu.find((e) => e.id === 374)?.texto ?? "Aplica IBUA",
      messageIBUA: menu.find((e) => e.id === 375)?.texto ?? "",
      bottonIBUA: menu.find((e) => e.id === 376)?.texto ?? "",
      hrefIBUA: menu.find((e) => e.id === 376)?.url ?? "",

      titleICL: menu.find((e) => e.id === 377)?.texto ?? "Aplica ICL",
      messageICL: menu.find((e) => e.id === 378)?.texto ?? "",
      bottonICL: menu.find((e) => e.id === 379)?.texto ?? "",
      hrefICL: menu.find((e) => e.id === 379)?.url ?? "",

      titleINPP: menu.find((e) => e.id === 380)?.texto ?? "Aplica INPP",
      messageINPP: menu.find((e) => e.id === 381)?.texto ?? "",
      bottonINPP: menu.find((e) => e.id === 382)?.texto ?? "",
      hrefINPP: menu.find((e) => e.id === 382)?.url ?? "",

      titleADV: menu.find((e) => e.id === 383)?.texto ?? "Aplica ADV",
      messageADV: menu.find((e) => e.id === 384)?.texto ?? "",
      bottonADV: menu.find((e) => e.id === 385)?.texto ?? "",
      hrefADV: menu.find((e) => e.id === 385)?.url ?? "",

      titlePrice: menu.find((e) => e.id === 57)?.texto ?? "Precio editable",
      messagePrice: menu.find((e) => e.id === 64)?.texto ?? "",
      bottonPrice: menu.find((e) => e.id === 65)?.texto ?? "",
      hrefPrice: menu.find((e) => e.id === 65)?.url ?? "",

      titleTax: menu.find((e) => e.id === 59)?.texto ?? "Precio editable",
      messageTax: menu.find((e) => e.id === 71)?.texto ?? "",
      bottonTax: menu.find((e) => e.id === 72)?.texto ?? "",
      hrefTax: menu.find((e) => e.id === 72)?.url ?? "",

      saveButton: menu.find((e) => e.id === 54)?.texto ?? "Listo",
      messageSave: menu.find((e) => e.id === 60)?.texto ?? "Registro exitoso.",
      messageUpdate:
        menu.find((e) => e.id === 61)?.texto ?? "Actualización exitosa.",

      titleGift: menu.find((e) => e.id === 202)?.texto ?? "Aplica IVA",
      messageGift: menu.find((e) => e.id === 203)?.texto ?? "",
      buttonGift: menu.find((e) => e.id === 204)?.texto ?? "",
      hrefGift: menu.find((e) => e.id === 204)?.url ?? "",
    };

    dispatch({
      type: types.GET_MENU_ARTICLE,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu  seccion documentos electronicos
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuInvoice = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      titleEsRegalo: menu.find((e) => e.id === 205)?.texto ?? "",
      messageEsRegalo: menu.find((e) => e.id === 206)?.texto ?? "",
      bottonEsRegalo: menu.find((e) => e.id === 207)?.texto ?? "",
      hrefEsRegalo: menu.find((e) => e.id === 207)?.url ?? "",

      descripcionNotaCredito: menu.find((e) => e.id === 219)?.texto ?? "",
      descripcionNotaDebito: menu.find((e) => e.id === 220)?.texto ?? "",
      descripcionAnulacion: menu.find((e) => e.id === 221)?.texto ?? "",
      descripcionAnulacionPreviamenteAceptada:
        menu.find((e) => e.id === 386)?.texto ?? "",
      messageModalEditInvoice: menu.find((e) => e.id === 222)?.texto ?? "",
      messageModalAnnulmentInvoice: menu.find((e) => e.id === 223)?.texto ?? "",
      messageModalApprovedAnnulmentInvoice:
        menu.find((e) => e.id === 387)?.texto ?? "",

      messageDocumentNotReported: menu.find((e) => e.id === 217)?.texto ?? "",
      messageReportedDocument: menu.find((e) => e.id === 218)?.texto ?? "",

      tooltipMotiveNoteHeader: menu.find((e) => e.id === 166)?.texto ?? "",
      tooltipMotiveNoteBody: menu.find((e) => e.id === 167)?.texto ?? "",
      tooltipMotiveNoteBotton: menu.find((e) => e.id === 168)?.texto ?? "",
      tooltipMotiveNoteUrl: menu.find((e) => e.id === 168)?.url ?? "",

      descripcionNotaAjusteCredito: menu.find((e) => e.id === 388)?.texto ?? "",
      descripcionNotaAjusteDebito: menu.find((e) => e.id === 389)?.texto ?? "",
      descripcionAjusteAnulacion: menu.find((e) => e.id === 390)?.texto ?? "",
      messageModalEditEquivalentDoc:
        menu.find((e) => e.id === 391)?.texto ?? "",
      messageModalAnnulmentEquivalentDoc:
        menu.find((e) => e.id === 392)?.texto ?? "",
    };

    dispatch({
      type: types.GET_MENU_INVOICE,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu  seccion documentos electronicos
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuDocSupport = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      messageModalAnnulmentDocSupport:
        menu.find((e) => e.id === 367)?.texto ?? "",
      messageModalEditDocSupport: menu.find((e) => e.id === 368)?.texto ?? "",
      descripcionAnulacion: menu.find((e) => e.id === 369)?.texto ?? "",
      descripcionCorrecion: menu.find((e) => e.id === 370)?.texto ?? "",

      tooltipMotiveNoteHeader: menu.find((e) => e.id === 365)?.texto ?? "",
      tooltipMotiveNoteBody: menu.find((e) => e.id === 366)?.texto ?? "",
    };

    dispatch({
      type: types.GET_MENU_DOC_SUPPORT,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu  seccion inicio
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuDashboard = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      labelDocumentos: menu.find((e) => e.id === 216)?.texto ?? "",
      labelVersion: menu.find((e) => e.id === 224)?.texto ?? "",
    };

    dispatch({
      type: types.GET_MENU_DASHBOARD,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene la informacion del menu Ayuda
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuHelp = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      urlFrequentQuestions: menu.find((e) => e.id === 197)?.url ?? "",
      buttonFrequentQuestions: menu.find((e) => e.id === 197)?.texto ?? "",

      reportProblem: menu.find((e) => e.id === 132)?.texto ?? "",
      buttonReportProblem: menu.find((e) => e.id === 133)?.texto ?? "",
      messageReportProblem: menu.find((e) => e.id === 139)?.texto ?? "",

      contactLabel: menu.find((e) => e.id === 137)?.texto ?? "",
      buttonContact: menu.find((e) => e.id === 138)?.texto ?? "",
      messageContact: menu.find((e) => e.id === 140)?.texto ?? "",
      emailContact: menu.find((e) => e.id === 134)?.texto ?? "",
      phoneContact: menu.find((e) => e.id === 135)?.texto ?? "",
      urlPhoneContact: menu.find((e) => e.id === 135)?.url ?? "",

      privacyPolicy: menu.find((e) => e.id === 144)?.texto ?? "",
      protectedData: menu.find((e) => e.id === 193)?.texto ?? "",
      buttonProtectedData: menu.find((e) => e.id === 194)?.texto ?? "",
      urlProtectedData: menu.find((e) => e.id === 194)?.url ?? "",
      termData: menu.find((e) => e.id === 195)?.texto ?? "",
      buttonTermData: menu.find((e) => e.id === 196)?.texto ?? "",
      urlTermData: menu.find((e) => e.id === 196)?.url ?? "",

      labelAbout: menu.find((e) => e.id === 129)?.texto ?? "",
      versionAbout: menu.find((e) => e.id === 130)?.texto ?? "",
      byAbout: menu.find((e) => e.id === 131)?.texto ?? "",
    };

    dispatch({
      type: types.GET_MENU_HELP,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu de cargos
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuCharge = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      buttonCharge: menu.find((e) => e.id === 49)?.texto ?? "",
      txtTypeCharge: menu.find((e) => e.id === 50)?.texto ?? "",
      recordSuccessHeader: menu.find((e) => e.id === 53)?.texto ?? "",
      recordSuccessBody: menu.find((e) => e.id === 24)?.texto ?? "",
      txtNameCharge: menu.find((e) => e.id === 51)?.texto ?? "",
      txtValueCharge: menu.find((e) => e.id === 52)?.texto ?? "",
      updateSuccessHeader: menu.find((e) => e.id === 25)?.texto ?? "",
      updateSuccessBody: menu.find((e) => e.id === 26)?.texto ?? "",
    };

    dispatch({
      type: types.GET_MENU_CHARGE,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu de descuentos
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuDiscount = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      btnSaveDiscount: menu.find((e) => e.id === 31)?.texto ?? "",
      txtModelDiscount: menu.find((e) => e.id === 32)?.texto ?? "",
      txtTypeDiscount: menu.find((e) => e.id === 33)?.texto ?? "",
      txtReasonDiscount: menu.find((e) => e.id === 34)?.texto ?? "",
      txtNameDiscount: menu.find((e) => e.id === 35)?.texto ?? "",
      txtValueDiscount: menu.find((e) => e.id === 36)?.texto ?? "",
      recordSuccessHeader: menu.find((e) => e.id === 37)?.texto ?? "",
      recordSuccessBody: menu.find((e) => e.id === 38)?.texto ?? "",
      updateSuccessHeader: menu.find((e) => e.id === 39)?.texto ?? "",
      updateSuccessBody: menu.find((e) => e.id === 40)?.texto ?? "",
      tooltipModelDiscountHeader: menu.find((e) => e.id === 45)?.texto ?? "",
      tooltipModelDiscountBody: menu.find((e) => e.id === 46)?.texto ?? "",
      tooltipModelDiscountBotton: menu.find((e) => e.id === 127)?.texto ?? "",
      tooltipModelDiscountUrl: menu.find((e) => e.id === 127)?.url ?? "",
      tooltipTypeDiscountHeader: menu.find((e) => e.id === 47)?.texto ?? "",
      tooltipTypeDiscountBody: menu.find((e) => e.id === 48)?.texto ?? "",
      tooltipTypeDiscountBotton: menu.find((e) => e.id === 128)?.texto ?? "",
      tooltipTypeDiscountUrl: menu.find((e) => e.id === 128)?.url ?? "",

      tooltipComercialDiscountHeader:
        menu.find((e) => e.id === 157)?.texto ?? "",
      tooltipComercialDiscountBody: menu.find((e) => e.id === 158)?.texto ?? "",
      tooltipComercialDiscountBotton:
        menu.find((e) => e.id === 159)?.texto ?? "",
      tooltipComercialDiscountUrl: menu.find((e) => e.id === 159)?.url ?? "",

      tooltipFinancialDiscountHeader:
        menu.find((e) => e.id === 160)?.texto ?? "",
      tooltipFinancialDiscountBody: menu.find((e) => e.id === 161)?.texto ?? "",
      tooltipFinancialDiscountBotton:
        menu.find((e) => e.id === 162)?.texto ?? "",
      tooltipFinancialDiscountUrl: menu.find((e) => e.id === 162)?.url ?? "",
    };

    dispatch({
      type: types.GET_MENU_DISCOUNT,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu Facturas Pestaña Recibidas
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuInvoiceReceived = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      tooltipTittlePort: menu.find((e) => e.id === 289)?.texto ?? "",
      tooltipBodyPort: menu.find((e) => e.id === 290)?.texto ?? "",
      tooltipButtonPort: menu.find((e) => e.id === 291)?.texto ?? "",
      tooltipUrlPort: menu.find((e) => e.id === 291)?.url ?? "",
      tooltipTittleHost: menu.find((e) => e.id === 292)?.texto ?? "",
      tooltipBodyHost: menu.find((e) => e.id === 293)?.texto ?? "",
      tooltipButtonHost: menu.find((e) => e.id === 294)?.texto ?? "",
      tooltipUrlHost: menu.find((e) => e.id === 294)?.url ?? "",
      txtSuccesHeader: menu.find((e) => e.id === 295)?.texto ?? "",
      txtSuccesBody: menu.find((e) => e.id === 296)?.texto ?? "",
      txtAuthenticationHeader: menu.find((e) => e.id === 297)?.texto ?? "",
      txtAuthenticationBody: menu.find((e) => e.id === 298)?.texto ?? "",
      txtEmailHeader: menu.find((e) => e.id === 299)?.texto ?? "",
      txtEmailBody: menu.find((e) => e.id === 300)?.texto ?? "",
      buttonOther: menu.find((e) => e.id === 301)?.texto ?? "",
      txtErrorAuth: menu.find((e) => e.id === 302)?.texto ?? "",
      txtSearchInvoice: menu.find((e) => e.id === 303)?.texto ?? "",
      txtAccept: menu.find((e) => e.id === 311)?.texto ?? "",
      txtRefuse: menu.find((e) => e.id === 312)?.texto ?? "",
    };
    dispatch({
      type: types.GET_MENU_INVOICE_RECEIVED,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion menu  seccion articulos
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuCustomer = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      personTypeTitle: menu.find((e) => e.id === 16)?.texto ?? "",
      personTypeMessage: menu.find((e) => e.id === 17)?.texto ?? "",
      personTypeButton: menu.find((e) => e.id === 18)?.texto ?? "",
      personTypeHref: menu.find((e) => e.id === 18)?.texto ?? "",
      titleBulkLoad:
        menu.find((e) => e.id === 208)?.texto ?? "Cargue masivo de clientes",
      contentBulkLoadOne:
        menu.find((e) => e.id === 209)?.texto ??
        "Bienvenido al cargue masivo, aquí podrás cargar la información de varios clientes al mismo tiempo, solo debes seguir estas instrucciones:",
      contentBulkLoadTwo:
        menu.find((e) => e.id === 210)?.texto ??
        "Descarga la plantilla de cargue, deberás completarla con la información solicitada de cada cliente que desees subir; por favor, no la modifiques ni cambies el nombre del archivo.",
      contentBulkLoadThree:
        menu.find((e) => e.id === 211)?.texto ??
        'Sube la plantilla con la información diligenciada mediante el boton "Cargar Plantilla Masiva", nosotros haremos el resto... tu, tranquilo.',
      contentBulkLoadFour:
        menu.find((e) => e.id === 212)?.texto ??
        "Listo!!!, La plataforma te mostrará el resultado del cargue, por favor verifica que todo quede en orden.",
    };

    dispatch({
      type: types.GET_MENU_CUSTOMER,
      payload: configurableMenu,
    });
  }
};
