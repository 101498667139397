import { types } from "../types/types"


const initialState = {
    editAddressStatus: false
}


export default function (state = initialState, action) {

    switch (action.type) {
        case types.SAVE_ADDRESS:
            return {
                ...state,
                saveAddressResponse: action.payload
            }
        case types.GET_ADDRESSLIST:
            return {
                ...state,
                getAddressListResponse: action.payload
            }
        case types.DELETE_ADDRESS:
            return {
                ...state,
                deleteAddressResponse: action.payload
            }
        case types.LOAD_ADDRESS:
            return {
                ...state,
                loadAddress: action.payload,
                editAddressStatus: !!action.payload ? true : false
            }
        case types.UPDATE_ADDRESS:
            return {
                ...state,
                updateAddressResponse: action.payload,

            }
        default:
            return state
    }
} 