import {
  API_ENDPOINT_DOCUMENT,
  DEFAULTRESPONSE,
  defaultHeaders,
} from "../config/config";
import axios from "axios";
import { handleResponse } from "./authActions";
import { axiosApiInstance } from "../config/axios-instance";
import { types } from "../types/types";
import { enterpriseDocumentNumber } from "../actions/authActions";

const urlInvoice = `${API_ENDPOINT_DOCUMENT}/document/api/RecepcionDocumentos/`;
const urlDocuments = `${API_ENDPOINT_DOCUMENT}/document/api/Documento/`;

function consultandoDocumentos(dispatch, consultando) {
  dispatch({
    type: types.SET_CONSULTANDO_DOCUMENTOS,
    payload: consultando,
  });
}

/**
 * Consulta listado de documentos por parametros de busqueda
 * @param {object} numeroDocumentoEmpresa Número de documento de la empresa
 */
export const getEMailEmpresaDIANAction = (payload) => (dispatch) => {
  dispatch({
    type: types.GET_COMPANY_EMAIL_DIAN,
    payload: payload,
    payload1: payload.result.emailEmpresaDIAN,
  });
};

export const setErrorMessageAction = (response, message) => (dispatch) => {
  dispatch({
    type: types.GET_ERRORS_INVOICE_RECEIVED,
    payload: response,
    payload1: message,
  });
};

/**
 * Notifica a la vista que se puede limpiar el estado del codigo de autenticacion
 * @param {valor de variable a cambir} value
 * @returns
 */
export const clearAuthenticationcodeAction = (value) => (dispatch) => {
  dispatch({
    type: types.CLEAR_CODE_AUTHENTICATION,
    payload: value,
  });
};

/**
 * Consulta listado de documentos por parametros de busqueda
 * @param {object} numeroDocumentoEmpresa Número de documento de la empresa
 */
export const getAutenticacionEMailAction = (payload) => (dispatch) => {
  dispatch({
    type: types.GET_INFO_AUTHENTICATION_EMAIL,
    payload: payload,
  });
};

export const getUrlAutenticacionOauthAction = (payload) => (dispatch) => {
  dispatch({
    type: types.GET_URL_AUTHENTICATION_OAUTH,
    payload: payload,
  });
};

export const setAutenticacionOauthAction = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_AUTHENTICATION_OAUTH_ERROR,
    payload: payload || null,
  });
};

/**
 * Cambiar el estado de variable para que no se vuelva a mostrar el mensaje de Configuracion correcta de correo
 * @param {*} payload
 * @returns
 */
export const changeStatusAction = (payload) => (dispatch) => {
  dispatch({
    type: types.CHANGE_VALUE_VAR,
    payload: payload,
  });
};

/**
 * Errores  generales de las Apis
 * @param {object} numeroDocumentoEmpresa Número de documento de la empresa
 */
export const errorGeneralReceivedAction = (error, message) => (dispatch) => {
  dispatch({
    type: types.showError,
    payload: {
      message: message,
      error: error,
    },
  });
};

/**
 * Limpiar response getEMailEmpresaDIANAction
 * @param {object} numeroDocumentoEmpresa Número de documento de la empresa
 */
export const cleanStoreCompanyEmailDian = () => (dispatch) => {
  dispatch({
    type: types.GET_COMPANY_EMAIL_DIAN,
    payload: null,
  });
};

export const cleanStoreResponseDocumentAction = () => (dispatch) => {
  dispatch({
    type: types.GET_INVOICES_RECEIVED,
    payload2: null,
  });
};

export const changeStateUrlAuthentication = (state) => (dispatch) => {
  dispatch({
    type: types.CHANGE_STATE_INVOICE_RECEIVE,
    payload: state,
  });
};

/**
 * Limpia la Url de redirección para la autenticación con oaut
 * @param {object} filterData Datos filtro
 */
export const cleanUrlAutenticacionOauthAction = (dispatch) => {
  dispatch({
    type: types.GET_URL_AUTHENTICATION_OAUTH,
    payload: "",
  });
};

/**
 * Consulta los documentos recibidos
 * @param {object} filterData Datos filtro
 */
export const getInvoicesReceivedAction = (
  filterData,
  invoicesReceived,
  selectDefaultItem
) => async (dispatch) => {
  try {
    //Limpia listado en pantlla para nueva consulta
    if (filterData.LoadMore !== true) {
      consultandoDocumentos(dispatch, true);

      dispatch({
        type: types.GET_INVOICES_RECEIVED,
        payload: [],
        payload1: 0,
      });
    }
    filterData.pDocument = enterpriseDocumentNumber();

    const response = await axiosApiInstance.post(
      `${urlInvoice}GetDocumentosRecepcionados`,
      filterData,
      defaultHeaders()
    );

    dispatch({
      type: types.GET_INVOICES_RECEIVED,
      payload2: response,
    });
    var data = response?.data?.result;

    let newInvoices = [];
    let total = 0;

    if (filterData.LoadMore === true) {
      newInvoices = invoicesReceived.concat(data);
      total = response?.data?.result[0]?.totalregistros;
    } else {
      newInvoices = data;
      total = response?.data?.result[0]?.totalregistros;
    }

    dispatch({
      type: types.GET_INVOICES_RECEIVED,
      payload: newInvoices,
      payload1: total,
      payload2: response,
    });

    consultandoDocumentos(dispatch, false);

    if (filterData.LoadMore !== true) {
      selectDefaultItem(response?.data?.result[0].codigounico);
    }
  } catch (err) {
    console.log(err);
    consultandoDocumentos(dispatch, false);
    handleResponse(err.response);
    let response = !!err?.response
      ? err?.response?.status
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: types.GET_ERRORS_INVOICE_RECEIVED,
      payload: response,
      payload1: "No se ha podido obtener la data.",
    });
  }
};

/**
 * Actualiza estado de documento favorito o destacado
 * @param {*} data Informacion documento
 * @param {*} invoices Informacion documento
 */
export const updateDocumentReceivedFromListAction = (data, invoices) => async (
  dispatch
) => {
  try {
    changeUpdateFavoriteFromListLoading(dispatch, data.codigoUnico);
    let bodyFormData = new FormData();
    bodyFormData.append("codigoUnico", data.codigoUnico);
    bodyFormData.append("favorito", data.favorito);
    const response = await axiosApiInstance.post(
      `${urlInvoice}SetFavoritoDocumentoRecepcion`,
      bodyFormData,
      defaultHeaders()
    );

    //Actualiza detalle documento
    if (response.status === 200) {
      //Actualiza listado documentos
      let newinvoices = updateDocumentsAfterUpdate(
        invoices,
        response?.data?.result[0]
      );

      dispatch({
        type: types.GET_INVOICES_RECEIVED,
        payload: newinvoices,
      });

      dispatch({
        type: types.SET_INVOICE_SELECTED,
        payload: response?.data?.result[0],
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error actualizando documento.",
        error: err,
      },
    });
  } finally {
    changeUpdateFavoriteFromListLoading(dispatch, 0);
  }
};

/**
 * Actualiza estado de pago del documento por pagar a pagado y vicerversa
 * @param {*} data Informacion documento
 */
export const setEstadoPagadoAction = (data, invoices) => async (dispatch) => {
  try {
    changeUpdateEstadoPagoLoading(dispatch, data.codigoUnico);
    let bodyFormData = new FormData();
    bodyFormData.append("codigoUnico", data.codigoUnico);
    bodyFormData.append("pagado", data.pagado);
    const response = await axiosApiInstance.post(
      `${urlInvoice}SetEstadoPagoDocumentoRecepcion`,
      bodyFormData,
      defaultHeaders()
    );

    //Actualiza detalle documento
    if (response.status === 200) {
      //Actualiza listado documentos
      let newinvoices = updateDocumentsAfterUpdate(
        invoices,
        response?.data?.result[0]
      );

      dispatch({
        type: types.GET_INVOICES_RECEIVED,
        payload: newinvoices,
      });

      dispatch({
        type: types.SET_INVOICE_SELECTED,
        payload: response?.data?.result[0],
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error actualizando documento.",
        error: err,
      },
    });
  } finally {
    changeUpdateEstadoPagoLoading(dispatch, 0);
  }
};

/**
 * Actualiza estado de aceptacion del documento
 * @param {*} data Informacion documento
 */
export const setEstadoAceptacionAction = (data, invoices) => async (
  dispatch
) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("codigoUnico", data.codigoUnico);
    bodyFormData.append("estado", data.estado);
    if (!!data.motivoRechazo) {
      bodyFormData.append("motivoRechazo", data.motivoRechazo);
    }

    const response = await axiosApiInstance.post(
      `${urlInvoice}SetEstadoAceptacionDocumentoRecepcion`,
      bodyFormData,
      defaultHeaders()
    );

    //Actualiza detalle documento
    if (response.status === 200) {
      //Actualiza listado documentos
      let newinvoices = updateDocumentsAfterUpdate(
        invoices,
        response?.data?.result[0]
      );

      dispatch({
        type: types.GET_INVOICES_RECEIVED,
        payload: newinvoices,
      });

      dispatch({
        type: types.SET_INVOICE_SELECTED,
        payload: response?.data?.result[0],
      });

      dispatch({
        type: types.SHOW_SUCCES_CHANGE_STATE_PAY,
        payload: true,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error actualizando documento,.",
        error: err,
      },
    });
  }
};

/**
 * Consulta listado de motivos de rechazo
 */
export const getMotivosRechazoAction = () => async (dispatch) => {
  try {
    dispatch({
      type: types.LOADING_TIPOS_MOTIVO_RECHAZO,
      payload: true,
    });

    const response = await axios.get(
      `${urlInvoice}GetMotivosRechazoDocumento`,
      defaultHeaders()
    );

    var data = response?.data?.result;

    dispatch({
      type: types.GET_TIPOS_MOTIVO_RECHAZO,
      payload: data === null ? [] : data,
    });
  } catch (err) {
    handleResponse(err.response);
    let response = !!err?.response
      ? err?.response?.status
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: types.GET_ERRORS_INVOICE_RECEIVED,
      payload: response,
      payload1: "No se ha podido obtener la data.",
    });
  } finally {
    dispatch({
      type: types.LOADING_TIPOS_MOTIVO_RECHAZO,
      payload: false,
    });
  }
};

export const setDataAutenticacionOauthAction = (datos) => async (dispatch) => {
  dispatch({
    type: types.SET_AUTHENTICATION_OAUTH_DATA,
    payload: datos,
  });
};

export const setConsultantoInfoAutAction = (datos) => async (dispatch) => {
  dispatch({
    type: types.LOADING_INFO_AUTH,
    payload: datos,
  });
};

export const setMostrarRegistroAutenticacionOauthAction = (datos) => async (
  dispatch
) => {
  dispatch({
    type: types.SET_AUTHENTICATION_OAUTH_EXITO,
    payload: datos,
  });
};

export const setMostrarRegistroAutenticacionOautheMailDiferenteAction = (
  datos
) => async (dispatch) => {
  dispatch({
    type: types.SET_AUTHENTICATION_OAUTH_EMAIL_DIFERENTE,
    payload: datos,
  });
};

export const setMostrarSuccesCambioEstadoAceptacionAction = (datos) => async (
  dispatch
) => {
  dispatch({
    type: types.SHOW_SUCCES_CHANGE_STATE_PAY,
    payload: datos,
  });
};

export const setInvoiceSelectedAction = (datos) => async (dispatch) => {
  dispatch({
    type: types.SET_INVOICE_SELECTED,
    payload: datos,
  });
};

/**
 * Actualiza estado loading actualizacion estado documento desde lista
 * @param {*} dispatch
 * @param {*} codigoUnico
 */
function changeUpdateFavoriteFromListLoading(dispatch, codigoUnico) {
  dispatch({
    type: types.LOADING_UPDATE_FAV_RECEIVED_FROM_LIST,
    payload: codigoUnico,
  });
}

/**
 * Actualiza estado loading actualizacion estado pagado documento desde lista
 * @param {*} dispatch
 * @param {*} codigoUnico
 */
function changeUpdateEstadoPagoLoading(dispatch, codigoUnico) {
  dispatch({
    type: types.LOADING_CAMBIAR_ESTADO_PAGO,
    payload: codigoUnico,
  });
}

/**
 * Actualiza listado de documentos despues  de actualizar edtado
 * @param {*} invoices Listado documentos
 * @param {*} data nueva informacion documento
 */
const updateDocumentsAfterUpdate = (invoices, data) => {
  if (data !== null && data !== undefined) {
    let newData = invoices.find(
      (d) => d.documento.CodigoUnico === data?.documento.CodigoUnico
    );
    newData = {
      ...newData,
      estadoid: data.estadoid,
      estado: data.estado,
      favorito: data.favorito,
    };

    const index = invoices.findIndex(
      (d) => d.documento.CodigoUnico === data?.documento.CodigoUnico
    );
    let newInvoices = [];
    if (index >= 0) {
      newInvoices = [...invoices];
      newInvoices[index] = newData;
    }

    return newInvoices;
  }
};

/**
 * Obtener xml y pdf de un documento recepcionado
 * @param {*} data
 * @param {*} functionLoad
 */
export const getDocumentReceptionFilesAction = (data, functionLoad) => async (
  dispatch
) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoDocumento: data.TipoDocumento,
      pDocumento: data.Documento,
      pCodigoUnico: data.CodigoUnico,
    });

    const response = await axiosApiInstance.get(
      `${urlDocuments}GetReceptionFiles`,
      config
    );
    if (response.data.statusCode === "200") {
      functionLoad(response.data.result);
    }
    dispatch({
      type: types.GET_FILE_INVOICE_RECEIVED,
      payload: response.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error en recepción de archivos.",
        error: err,
      },
    });
  }
};
