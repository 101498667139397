import React, { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ReteFuenteTable } from "../withholdings/reteFuenteTable";
import { WithholdingModal } from "../withholdings/withholdingModal";

import { CartItemCard } from "./cartItemCard";

import {
  addRteFuenteItemAction,
  addRteFuenteAllItemsAction,
} from "../../../actions/documentCartAction";

import { getButtonTextSelect } from "./utils";

export const CartList = ({ typedocument }) => {
  const dispatch = useDispatch();

  const [activeItemRteFuente, setActiveItemRteFuente] = useState(null);
  const [activeRteFuente, setActiveRteFuente] = useState(null);
  const [openModalReteFuente, setOpenModalReteFuente] = useState(false);

  const { opdetalledocumento } = useSelector(
    (state) => state.documentCartReducer
  );

  /**
   * * Abre modal listado rete fuente, carga en memoria item que aplica retencion
   * @param {*} item
   */
  const handleOpenModalReteFuente = useCallback((item) => {
    setOpenModalReteFuente(true);
    setActiveItemRteFuente(item);
  }, []);

  /**
   * * Abre modal listado rete fuente en modo edición
   * @param {*} tax
   */
  const handleEditRteFuente = useCallback((item) => {
    setOpenModalReteFuente(true);
    setActiveItemRteFuente(item);
    setActiveRteFuente(item.retfte);
  }, []);

  /**
   * * Aplica rete fuente a item en memoria
   */
  const handleApplyRteFuenteOne = (tax) => {
    dispatch(addRteFuenteItemAction(activeItemRteFuente.id, tax));
    onCloseModalReteFuente();
  };

  /**
   * * Aplica rete fuente al listado de articulos en carrito
   * @param {*} tax
   */
  const handleAplyRteFuenteAll = (tax) => {
    dispatch(addRteFuenteAllItemsAction(tax));
    onCloseModalReteFuente();
  };

  /**
   * * Cierra modal rete fuente
   */
  const onCloseModalReteFuente = () => {
    setOpenModalReteFuente(false);
    setActiveItemRteFuente(null);
    setActiveRteFuente(null);
  };

  return (
    <>
      {opdetalledocumento.map((item) => (
        <CartItemCard
          data={item}
          handleEditRteFuente={() => handleEditRteFuente(item)}
          handleOpenModalRteFuente={() => handleOpenModalReteFuente(item)}
          key={item.id}
          typedocument={typedocument}
        />
      ))}
      <WithholdingModal
        component={
          <ReteFuenteTable
            onClickApplyToOne={handleApplyRteFuenteOne}
            onClickApplyToAll={handleAplyRteFuenteAll}
            activeRteFuente={activeRteFuente}
          />
        }
        onClose={onCloseModalReteFuente}
        open={openModalReteFuente}
        showCloseButton={true}
        title={getButtonTextSelect(typedocument, "TITLE")}
        width={1000}
      />
    </>
  );
};
