import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { SuccessAlert } from "../../../helpers/alert.helpers";
import { TIME_CREATE_REGISTER } from "../../../config/config";
import moment from "moment";

const styles = (theme) => ({
  titleStyle: {
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "left",
  },
  subTitleStyle: {
    color: "#FFFFFF",
    fontSize: "18px",
    textAlign: "left",
    lineHeight: "20px",
  },
  cardDevice: {
    lineHeight: "1px",
    fontSize: "10px",
    padding: "revert",
    backgroundColor: "#3e3e3e",
    textAlign: "left",
  },
  container: {
    color: "FFFFFF",
    fontSize: "10px",
    backgroundColor: "#3e3e3e",
    textAlign: "left",
    borderRadius: 8,
    padding: "15px",
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid gray",
    display: "inline-grid",
  },
  avatar: {
    color: "#f5d13d",
    padding: "0px",
  },
  titleTable: {
    color: "#f5d13d",
    padding: "0px",
  },
  userDevices: {
    textAlign: "left",
    color: "#FFFFFF",
    padding: "0px",
  },
  buttonLinkDevice: {
    backgroundColor: "#535353",
    color: "#FFFFFF",
    fontSize: "15px",
    borderRadius: "5px",
    margin: "5px",
    textTransform: "initial",
    padding: "5px 18px 5px 17px",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    fontSize: "11px",
  },
});

class CardDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.tableUsers = this.tableUsers.bind(this);
    this.associateDevice = this.associateDevice.bind(this);
  }

  associateDevice = async () => {
    const deviceName = this.props.device.deviceName;
    if (await this.props.associateDevice()) {
      SuccessAlert(
        null,
        "Asignaste la numeración de contingencia No. " +
          this.props.numerationName +
          " al Dispositivo " +
          deviceName,
        TIME_CREATE_REGISTER
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid container>
          <Grid item lg={1} xs={1}>
            <IconButton className={classes.avatar}>
              <PhoneIphoneIcon />
            </IconButton>
          </Grid>
          <Grid item lg={11} xs={11}>
            <Grid item lg={12} xs={12} className={classes.titleStyle}>
              <span>{this.props.device.deviceName}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.subTitleStyle}>
              <span>{"Serial Number:" + this.props.device.deviceMac}</span>
            </Grid>
          </Grid>
        </Grid>
        <span className={classes.divider}></span>
        <br></br>
        <table className={classes.table}>
          <thead>
            <tr className={(classes.userDevices, classes.titleTable)}>
              <th colSpan={2}>Usuarios registrados en este dispositivo</th>
              <th>Último ingreso</th>
            </tr>
          </thead>
          <tbody>
            {this.props.device.users ? (
              this.props.device.users.map((user) => (
                <tr key={user.username} className={classes.userDevices}>
                  <td>{user.userName}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.lastEntry
                      ? moment(user.lastEntry).format("MM/DD/YYYY hh:mm:ss")
                      : ""}
                  </td>
                </tr>
              ))
            ) : (
              <tr className={classes.userDevices}>
                <td colSpan={3}>No se encontraron usuarios</td>
              </tr>
            )}
          </tbody>
        </table>
        <br></br>
        <span className={classes.divider}></span>
        <br></br>
        {/* <Grid container justify="center">
          <Button className={classes.buttonLinkDevice} onClick={() => this.associateDevice()} >
            Asociar dispositivo
          </Button>
        </Grid> */}
      </div>
    );
  }

  tableUsers(users) {
    return (
      <table>
        <thead>
          <tr>
            <th colSpan={2}>Usuarios registrados en este dispositivo</th>
            <th>Último ingreso</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.username}>
                <td>{users.username}</td>
                <td>{users.mail}</td>
                <td>{users.lastEntry}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>No se encontraron usuarios</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default withStyles(styles)(CardDevice);
