import React from "react";

import { useDispatch } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import { deleteRteFuenteItemAction } from "../../../actions/documentCartAction";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import { FormatDecimal } from "../../../components/common/formatDecimal";

import { ConfirmAlert } from "../../../helpers/alert.helpers";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config";

import { getButtonTextSelect } from "../cart/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(255,255,255,0.11)",
    color: theme.palette.primaryColor,
    width: "100%",
    "& .MuiCardContent-root": {
      marginBottom: 0,
      marginTop: -5,
      paddingBottom: 5,
      paddingLeft: 55,
      paddingTop: 0,
    },
  },
  header: {
    marginLeft: -5,
    paddingBottom: 0,
    paddingTop: 0,
    "& .MuiCardHeader-action": {
      display: "flex",
    },
    "& .MuiCardHeader-root": {
      padding: 10,
    },
  },
  content: {
    fontSize: 11,
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: 11,
  },
  iconAction: {
    color: theme.palette.primaryColor,
    fontSize: 20,
  },
  valueText: {
    color: theme.palette.fourthColor,
    fontWeight: 500,
  },
  iconButton: {
    padding: 5,
  },
  actions: {
    display: "flex",
  },
  containerDesRteFte: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "90%",
  },
}));

export const ReteFuenteCard = React.memo(({ data, onEdit, typedocument }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { tarifaretfte, valorretfte, retfte } = data;

  /**
   * * Elimina retención de item
   */
  const handleDeleteRteFuente = () => {
    const deleteData = (confirmed) => {
      if (confirmed) {
        dispatch(deleteRteFuenteItemAction(data.id));
      }
    };
    ConfirmAlert(
      `¿Estás seguro de eliminar la retención?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  };

  return (
    <Grid item xs={12} lg={12}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          action={
            <div className={classes.actions}>
              <Tooltip title="Editar">
                <IconButton className={classes.iconButton} onClick={onEdit}>
                  <CreateIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar">
                <IconButton
                  aria-label="Eliminar"
                  onClick={handleDeleteRteFuente}
                >
                  <DeleteIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>
            </div>
          }
          classes={{
            title: classes.title,
          }}
          title={getButtonTextSelect(typedocument, "TITLECARD")}
        />
        <CardContent className={classes.content}>
          <span>
            <span className={classes.valueText}>
              <FormatDecimal
                size={"inherit"}
                suffix={"%"}
                value={tarifaretfte}
              />
            </span>
            &nbsp;
            <span className={classes.valueText}>
              <FormatDecimal value={valorretfte} prefix={"($"} suffix={")"} />
            </span>
          </span>
          <br />
          <div className={classes.containerDesRteFte}>{retfte.descripcion}</div>
        </CardContent>
      </Card>
    </Grid>
  );
});
