import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";

import InputForm from "../../components/input/input.component";
import SelectForm from "../../components/select/select.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { useFormComplete } from "../../hooks/useFormComplete";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  title: {
    padding: "6px 10px 20px 10px",
  },
}));

export const ReceiptDocumentForm = ({ handleReceiptDocument }) => {
  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const { values, handleInputChange } = useFormComplete({
    documentType: "",
    document: "",
    name: "",
    lastname: "",
    occupation: "",
    area: "",
  });

  const { documentType, document, name, lastname, occupation, area } = values;
  const { getDocumentTypesCodeReceptionResponse } = useSelector((s) => s.configReducer);

  /**
   * VAlida formulario y confirma petición
   * @param {*} e
   * @returns
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
      return;
    }

    handleReceiptDocument(values);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container justify="center">
        <Grid item lg={12} xs={12} className={classes.title}>
          Antes de aceptar o rechazar la factura es necesario declarar el
          recibido del bien o servicio adquirido. Para hacerlo, diligencia la
          información de quien recibe la factura y haz clic en el botón
          "Recibido".
        </Grid>
        <Grid item lg={6} xs={6}>
          <SelectForm
            label={"Tipo de documento"}
            name="documentType"
            onChange={(e) => handleInputChange(e)}
            options={getDocumentTypesCodeReceptionResponse}
            value={documentType}
            validator={validator}
            validateOptions={"required"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Número de documento"}
            maxLength={15}
            name="document"
            onChange={(e) =>
              handleInputChange(
                e,
                documentType === "41" ? "alphanumeric" : "number"
              )
            }
            value={document}
            validator={validator}
            validateOptions={"required|min:1|max:15"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Nombres"}
            maxLength={100}
            name="name"
            onChange={(e) => handleInputChange(e)}
            value={name}
            validator={validator}
            validateOptions={"required|min:1|max:100"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Apellidos"}
            maxLength={100}
            name="lastname"
            onChange={(e) => handleInputChange(e)}
            value={lastname}
            validator={validator}
            validateOptions={"required|min:1|max:100"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Cargo"}
            maxLength={100}
            name="occupation"
            onChange={(e) => handleInputChange(e)}
            value={occupation}
            validator={validator}
            validateOptions={"required|min:1|max:100"}
          />
        </Grid>
        <Grid item lg={6} xs={6}>
          <InputForm
            label={"Área"}
            maxLength={100}
            name="area"
            onChange={(e) => handleInputChange(e)}
            value={area}
            validator={validator}
            validateOptions={"required|min:1|max:100"}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <ButtonPrimary text={"Recibido"} type={"submit"} />
        </Grid>
      </Grid>
    </form>
  );
};
