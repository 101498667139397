import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import CastDetailSupport from "./CastDetailSupport.view";
import CastListSupport from "./CastListSupport.view";

const DocumentSupportGrid = ({
  handleCreate,
  handleEdit,
  handleShowDetailNote,
  edit = false,
}) => {
  const [documentIdSelected, setDocumentSelected] = useState(null);
  return (
    <Grid container>
      <Grid item xs={12} lg={5}>
        <CastListSupport
          documentIdSelected={documentIdSelected}
          setDocumentSelected={setDocumentSelected}
          edit={edit}
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <CastDetailSupport
          handleCreate={handleCreate}
          documentIdSelected={documentIdSelected}
          setDocumentSelected={setDocumentSelected}
          handleEdit={handleEdit}
          handleShowDetailNote={handleShowDetailNote}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentSupportGrid;
