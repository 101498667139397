import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

import AutoIncrementSmall from "../../../components/autoIncrement/autoIncrementSmall.component";

import {
  updateQuantityGlobalChargeAction,
  deleteChargeDocumentAction,
} from "../../../actions/documentCartAction";

import { FormatDecimal } from "../../../components/common/formatDecimal";
import { useFormComplete } from "../../../hooks/useFormComplete";
import { convertStringToDecimal } from "../../../utils/convertStringToDecimal";
import { roundDecimalValue } from "../../../utils/managementNumber";
import { ConfirmAlert } from "../../../helpers/alert.helpers";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.thirdBackgroundColor,
    color: theme.palette.primaryColor,
    "& .MuiCardContent-root": {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
  },
  header: {
    color: theme.palette.primaryColor,
    paddingBottom: 0,
    paddingTop: 0,
  },
  title: {
    color: theme.palette.primaryColor,
  },
  icon: {
    color: theme.palette.secundaryColor,
  },
  iconAction: {
    color: theme.palette.primaryColor,
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    marginTop: 0,
    fontSize: 14,
    color: theme.palette.primaryColor,
  },
  text: {
    color: theme.palette.primaryColor,
  },
  valueText: {
    color: "#EC005F",
    fontWeight: 500,
  },
}));

export const GlobalChargeCard = React.memo(({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { values, handleInputChange, handleUpdateForm } = useFormComplete({
    quantity: 1,
  });
  const { quantity } = values;

  /**
   * Valida valor digitado en cantidad, actualiza cantidad de cargo
   * @param {*} param0
   */
  const onBlurQuantity = ({ target }) => {
    let newQuantity = convertStringToDecimal(target.value);

    if (target.value.trim().length === 0) {
      handleUpdateForm({
        quantity: newQuantity,
      });
    }

    let newCharge = {
      ...data,
      cantidad: newQuantity,
    };

    updateGlobalCharge(newCharge);
  };

  /**
   * Incrementa cantidad, actualiza información cargo
   * @param {*} param0
   */
  const handleIncrement = () => {
    let newQuantity = convertStringToDecimal(quantity);
    newQuantity = roundDecimalValue(newQuantity + 1);
    handleUpdateForm({
      quantity: newQuantity,
    });

    let newCharge = {
      ...data,
      cantidad: newQuantity,
    };
    updateGlobalCharge(newCharge);
  };

  /**
   * Decrementa cantidad, actualiza información cargo
   * @param {*} param0
   */
  const handleDecrement = () => {
    let newQuantity = convertStringToDecimal(quantity);
    newQuantity = roundDecimalValue(newQuantity - 1);
    newQuantity = newQuantity <= 0 ? 1 : newQuantity;
    handleUpdateForm({
      quantity: newQuantity,
    });

    let newCharge = {
      ...data,
      cantidad: newQuantity,
    };
    updateGlobalCharge(newCharge);
  };

  /**
   * Actualiza información de cargo en redux
   * @param {*} charge
   */
  const updateGlobalCharge = (charge) => {
    dispatch(updateQuantityGlobalChargeAction(charge));
  };

  /**
   * Elimina cargo
   */
  const handleDelete = () => {
    const deleteData = (confirmed) => {
      if (confirmed) {
        dispatch(deleteChargeDocumentAction(data.id));
      }
    };
    ConfirmAlert(
      `¿Estás seguro que desea eliminar el articulo ${data.descripcion}?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  };

  return (
    <Card
      className={`${classes.root} animate__animated animate__fadeIn animate__faster`}
    >
      <CardHeader
        className={classes.header}
        avatar={<AddToPhotosIcon fontSize="small" className={classes.icon} />}
        action={
          <Tooltip title="Eliminar">
            <IconButton aria-label="Eliminar" onClick={handleDelete}>
              <DeleteIcon className={classes.iconAction} />
            </IconButton>
          </Tooltip>
        }
        classes={{
          title: classes.title,
        }}
        title={data.descripcion}
      />
      <CardContent className={classes.content}>
        <span style={{ maxWidth: 200 }}>
          <AutoIncrementSmall
            name={"quantity"}
            value={quantity}
            onChange={handleInputChange}
            onBlur={onBlurQuantity}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            maxLength={21}
            fullWidth={false}
          />
        </span>
        <br />
        <span className={classes.total}>
          <FormatDecimal value={data?.valorasumar ?? 0} prefix={"$"} />
        </span>
      </CardContent>
    </Card>
  );
});
