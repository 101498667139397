import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ReportIcon from "@material-ui/icons/Report";

import CustomProgress from "../../components/Progress/progress.component";
import { FormatDecimal } from "../../components/common/formatDecimal";
import MessageSecundary from "../../components/tooltip/messageSecundary.component";

import { format } from "date-fns";

import { standOutDocumentFormListAction } from "../../actions/documentIntegrationAction";

const animate = " animate__animated animate__fadeIn animate__faster";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.11)",
		color: theme.palette.primaryColor,
		"& .MuiCardContent-root": {
			paddingTop: 0,
			paddingBottom: 5,
			marginBottom: 0,
			marginTop: 0,
		},
		cursor: "pointer",
	},
	selected: {
		width: "100%",
		backgroundColor: "#16B1F3",
		color: theme.palette.primaryColor,
		"& .MuiCardContent-root": {
			paddingTop: 0,
			paddingBottom: 5,
			marginBottom: 0,
			marginTop: 0,
		},
		cursor: "pointer",
	},
	header: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	content: {
		fontSize: 14,
	},
	title: {
		color: theme.palette.primaryColor,
		fontSize: 20,
		fontWeight: 800,
		textAlign: "justify",
	},

	icon: {
		color: theme.palette.secundaryColor,
	},
	iconAction: {
		color: theme.palette.primaryColor,
	},
	valueText: {
		color: "#F5D13D",
		fontWeight: 500,
	},
	help: {
		marginTop: 10,
	},
	actions: {
		display: "flex",
	},
	spaceBetween: {
		justifyContent: "space-between",
		display: "flex",
	},
	customColor: {
		color: "#16B1F3",
	},
	styleValue: {
		color: "#16B1F3",
		fontSize: 20,
		fontWeight: 800,
	},
	styleValueSeleced: {
		fontSize: 20,
		fontWeight: 800,
	},
	styleType: {
		color: "#16B1F3",
		fontSize: 18,
		fontWeight: 500,
	},
	styleTypeSelected: {
		fontSize: 18,
		fontWeight: 500,
	},
	iconButton: {
		padding: "12px 3px",
	},
}));

export const IntegrationCard = React.memo(
	({
		id,
		adquirientenombre,
		fecha,
		valorapagar,
		numero,
		favorito,
		onClick,
		selectedId,
		loading = false,
		conceptodian,
		aprobadodian,
		codigoestadodian,
	}) => {
		const classes = useStyles();
		const dispatch = useDispatch();

		/**
		 * Actualiza estado favorito
		 * @param {*} e
		 */
		const handleStandOut = () => {
			dispatch(standOutDocumentFormListAction(id, !favorito));
		};

		/**
		 * Tooltip documentos de integracion
		 * @returns
		 */
		const toolTipIntegration = () => {
			return (
				<Tooltip
					title={
						<MessageSecundary
							message={
								"Esta factura fue creada en un Software diferente y cargada por integración."
							}
						/>
					}
					placement="bottom-end"
					interactive
					enterTouchDelay={1000}
				>
					<IconButton className={classes.iconButton}>
						<InsertDriveFileIcon className={classes.iconAction} />
					</IconButton>
				</Tooltip>
			);
		};

		/**
		 * Tooltip estado DIAN
		 * @returns
		 */
		const getStatusDIAN = () => {
			return (
				<Tooltip
					title={
						<MessageSecundary
							title={`Error DIAN - ${codigoestadodian}`}
							message={conceptodian}
						/>
					}
					placement="bottom-end"
					interactive
					enterTouchDelay={1000}
				>
					<IconButton className={classes.iconButton}>
						<ReportIcon className={classes.iconAction} />
					</IconButton>
				</Tooltip>
			);
		};

		return (
			<Card
				className={`${
					selectedId === id ? classes.selected : classes.root
				} ${animate}`}
			>
				<CardHeader
					className={classes.header}
					action={
						<div className={classes.actions}>
							{!aprobadodian && getStatusDIAN()}
							{toolTipIntegration()}

							<Tooltip title="Destacar">
								{loading === true ? (
									<div style={{ padding: "14px 15px 0px 15px" }}>
										<CustomProgress size={15} />
									</div>
								) : (
									<IconButton
										aria-label="Destacar"
										onClick={handleStandOut}
										className={classes.iconButton}
									>
										{favorito ? (
											<StarIcon className={classes.iconAction} />
										) : (
											<StarBorderIcon className={classes.iconAction} />
										)}
									</IconButton>
								)}
							</Tooltip>
						</div>
					}
					classes={{
						title: classes.title,
					}}
					title={<div onClick={() => onClick(id)}>{adquirientenombre}</div>}
				/>
				<CardContent className={classes.content} onClick={() => onClick(id)}>
					<Grid container>
						<Grid item lg={12} xs={12} className={classes.spaceBetween}>
							<div>
								{!!fecha ? format(new Date(fecha), "dd/MM/yyyy") : "dd/MM/yyyy"}
							</div>
							<div
								className={
									selectedId === id
										? classes.styleValueSeleced
										: classes.styleValue
								}
							>
								<FormatDecimal value={valorapagar} prefix={"$"} />
							</div>
						</Grid>
						<Grid item lg={12} xs={12} className={classes.spaceBetween}>
							<div>Factura: {numero}</div>
							<div
								className={
									selectedId === id
										? classes.styleTypeSelected
										: classes.styleType
								}
							>
								Integración
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
);
