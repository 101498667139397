import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height:'100%',
    '& .MuiButton-contained.Mui-disabled': {
      color: theme.palette.primaryColor,
      backgroundColor: 'rgba(255,255,255,0.18)',
      opacity: 0.5
    },
    fontSize: '1.2vh',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    margin: '10px'
  },
  wrapperLessMargin: {
    position: 'relative',
    width: '100%',
    margin: '5px '
  },
  buttonSuccessSmall: {
    backgroundColor: 'rgba(255,255,255,0.18)',
    color: theme.palette.primaryColor,
    '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.18)',
    },
    '&:disable':{
      color: 'rgba(255,255,255,0.18)'
    },
    width: '100%',
    height:'100%',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    fontFamily:'Muli'
  },
  buttonProgress: {
    color: 'rgba(255,255,255,0.18)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ButtonPrimary(props) {
  const classes = useStyles();
  const { onClick, loading, text, disabled, type, lessMargin, small,icon ,buttonSize , margin} = props;


  return (
    <Fragment>
      {small === true ?
        <div className={classes.root}>
          <div className={lessMargin ? classes.wrapperLessMargin : classes.wrapper}>
            <Button
              component={"button"}
              variant="contained"
              className={classes.buttonSuccessSmall}
              disabled={loading === true || disabled === true ? true : false}
              onClick={onClick}
              type={!!type ? type : 'submit'}
              endIcon={icon}
              >
              {text}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div> : <div className={classes.root}>
          <div className={lessMargin ? classes.wrapperLessMargin : classes.wrapper}>
          <Button
              component={"button"}
              variant="contained"
              className={classes.buttonSuccessSmall}
              disabled={loading === true || disabled === true ? true : false}
              onClick={onClick}
              type={!!type ? type : 'submit'}
              endIcon={icon}
              style={{height:buttonSize, marginLeft:margin}}
              >
              {text}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>

      }
    </Fragment>

  );
}
