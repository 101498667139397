/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { READING_MODE, URL_IMAGES } from "../../../config/config.js";

import Tooltip from "@material-ui/core/Tooltip";
import { Grid, IconButton } from "@material-ui/core/";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import CustomProgress from "../../../components/Progress/progress.component";

import { updateFavoriteStatusAction } from "../../../actions/articleActions";

import isEmpty from "../../../utils/isEmpty";
import { getRoleOfLoggedUser } from "../../../actions/authActions";
import { FormatDecimal } from "../../../components/common/formatDecimal.js";
import { TooltipMessageReadingMode } from "../../../components/tooltip/TooltipMessageReadingMode.js";
import { TaxDetailArticle } from "./taxDetailArticle.component.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiIconButton-colorSecondary": {
      color: theme.palette.primaryColor,
    },
    width: "100%",
    overflowY: "auto",
  },
  nameStyle: {
    color: theme.palette.fourthColor,
    fontSize: "22px",
    fontWeight: "bold",
    textTransform: "capitalize",
    lineHeight: "26px",
  },
  totalStyle: {
    color: theme.palette.secundaryColor,
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "26px",
  },
  comunStyle: {
    color: "#9e9e9e",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
    display: "inline-block",
  },
  whiteStyle: {
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "26px",
  },
  greenStyle: {
    color: theme.palette.secundaryColor,
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
  },
  tag: {
    width: 100,
    borderRadius: 10,
    color: theme.palette.primaryColor,
    fontWeight: 600,
    fontSize: 12,
    marginRight: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 1,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },

  cover: {
    color: theme.palette.primaryColor,
    height: "210px",
    borderRadius: 10,
    width: "95%",
    border: "0.3px solid #ffffff17",
    position: "relative",
  },
  image: {
    height: "210px",
    borderRadius: 10,
    width: "95%",
  },
  titleImage: {
    position: "absolute",
    textAlign: "center",
    color: theme.palette.primaryColor,
    top: "40%",
    left: "40%",
    fontSize: "4vh",
    fontWeight: 600,
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid gray",
    display: "inline-grid",
  },
  styleContainer: {
    backgroundColor: theme.palette.fourthBackgroundColor,
    fontSize: "18px",
    padding: "5px 15px",
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 5,
    color: theme.palette.primaryColor,
  },

  styleActions: {
    backgroundColor: theme.palette.fourthBackgroundColor,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.primaryColor,
  },
  list: {
    listStyleType: "none",
    paddingInlineStart: 0,
  },
  iconChecked: {
    color: theme.palette.primaryColor,
  },
  progress: {
    textAlign: "center",
    width: "100%",
  },
  containerActionsButtons: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {},
  label: {
    flexDirection: "column",
    fontSize: 10,
    color: theme.palette.primaryColor,
  },
  formCheck: {
    margin: 0,
    "& .MuiIconButton-root": {
      padding: 0,
    },
    marginTop: -8,
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
  },
  container: {
    display: "inline-flex",
    alignItems: "baseline",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2F2E2E",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 280,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

const DetailArticle = React.memo(
  ({
    typedocument,
    data,
    onSelectItem,
    onLoadItem,
    onDeleteItem,
    loadingFavorite,
    ...props
  }) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const {
      adcategoriaarticulo,
      adimpuestoarticulo,
      codigo,
      codigobarras,
      color,
      descripcion,
      dianunidadmedida,
      favorito,
      id,
      imagen,
      neto,
      nombrearticulo,
      precioeditable,
      total,
    } = data;
    useEffect(() => {
      setChecked(favorito === true ? true : false);
    }, [favorito]);

    const subNameArticle = (name) => {
      return name ? name.substring(0, 2).toUpperCase() : "";
    };

    const handleChecked = (event) => {
      const value = event.target.checked;
      setChecked(value);
      props.updateFavoriteStatusAction(id, value);
    };

    /**
     * * Obtiene el texto del botón según el tipo de documento.
     *
     * @param {string} typedocument - El tipo de documento.
     * @returns {string} - El texto del botón correspondiente al tipo de documento.
     */
    const getButtonText = (typedocument = "FA") => {
      const document = {
        CO: "Añadir a cotización",
        FA: "Añadir a factura",
        DE: "Añadir a documento",
      };

      const documentType = typedocument.replace(/_NOTE$/, "");

      return document[documentType] || "Añadir";
    };

    return (
      <div className={classes.root}>
        <Grid container style={{ padding: "0px 20px" }}>
          <Grid item lg={5} xs={5}>
            {isEmpty(imagen) ? (
              <div
                style={{
                  background: color,
                }}
                className={classes.cover}
              >
                <span className={classes.titleImage}>
                  {subNameArticle(nombrearticulo)}
                </span>
              </div>
            ) : (
              <img
                height={"100%"}
                width={"100%"}
                className={classes.image}
                src={URL_IMAGES + imagen}
                alt={""}
              />
            )}
          </Grid>
          <Grid item lg={7} xs={7}>
            <span className={classes.nameStyle}>{nombrearticulo}</span>
            <br />
            <br />
            <span className={classes.totalStyle}>
              <FormatDecimal value={total} prefix={"$"} size={"16px"} />
            </span>
            <br />
            <span className={classes.comunStyle}>
              {"Precio editable: "}
              <span className={classes.whiteStyle}>
                {precioeditable === true ? "SI" : "NO"}
              </span>
            </span>{" "}
            <br />
            {adimpuestoarticulo &&
              adimpuestoarticulo
                .toSorted((a, b) => a.id - b.id)
                .map((impuesto, index) => (
                  <TaxDetailArticle
                    key={index}
                    tax={impuesto}
                    classes={classes}
                    index={index}
                  />
                ))}
            <br />
            <span className={classes.divider}></span>
            <br />
            <div style={{ display: "flex" }}>
              {adcategoriaarticulo.map((category, index) => (
                <div
                  key={index}
                  className={classes.tag}
                  style={{ backgroundColor: category?.categoria?.color }}
                >
                  {category?.categoria?.nombre}
                </div>
              ))}
            </div>
          </Grid>

          <Grid item xs={12} lg={12} className={classes.styleContainer}>
            {descripcion}
          </Grid>

          <Grid item xs={12} lg={12} className={classes.styleContainer}>
            <ul className={classes.list}>
              <li>
                <span
                  className={classes.comunStyle}
                  style={{ paddingRight: 7 }}
                >
                  {"- Valor Neto: "}
                </span>
                <span className={classes.greenStyle}>
                  <FormatDecimal value={neto} prefix={"$"} size={"small"} />
                </span>
              </li>
              <li>
                <span
                  className={classes.comunStyle}
                  style={{ paddingRight: 7 }}
                >
                  {"- Costo: "}
                </span>
                <span className={classes.greenStyle}>
                  <FormatDecimal
                    value={data.costo}
                    prefix={"$"}
                    size={"small"}
                  />
                </span>
              </li>
              <li>
                <span
                  className={classes.comunStyle}
                  style={{ paddingRight: 7 }}
                >
                  {"- Unidad de medidad: "}
                </span>
                <span
                  className={classes.whiteStyle}
                  style={{ textTransform: "uppercase" }}
                >
                  {" "}
                  {!!dianunidadmedida?.descripcion
                    ? dianunidadmedida?.descripcion
                    : ""}
                </span>
              </li>
              <li>
                <span
                  className={classes.comunStyle}
                  style={{ paddingRight: 7 }}
                >
                  {"- Referencia: "}
                </span>
                <span className={classes.whiteStyle}> {codigo}</span>
              </li>
              <li>
                <span
                  className={classes.comunStyle}
                  style={{ paddingRight: 7 }}
                >
                  {"- Código de barras: "}
                </span>
                <span className={classes.whiteStyle}> {codigobarras}</span>
              </li>
            </ul>
          </Grid>
        </Grid>
        <br></br>

        <Grid container className={classes.styleActions}>
          <>
            <Grid item lg={6} xs={6} style={{ textAlign: "center" }}>
              <HtmlTooltip
                title={
                  READING_MODE && typedocument !== "CO" ? (
                    <TooltipMessageReadingMode />
                  ) : (
                    ""
                  )
                }
              >
                <span>
                  <ButtonPrimary
                    text={getButtonText(typedocument)}
                    loading={false}
                    type="button"
                    disabled={
                      loadingFavorite || (READING_MODE && typedocument !== "CO")
                    }
                    onClick={(e) => onSelectItem(e, data)}
                  />
                </span>
              </HtmlTooltip>
            </Grid>
            {getRoleOfLoggedUser() ? (
              <>
                <Grid item lg={2} xs={2} style={{ textAlign: "center" }}>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton
                      classes={{
                        root: classes.button,
                        label: classes.label,
                      }}
                      variant="raised"
                      disableRipple={true}
                      disabled={loadingFavorite}
                    >
                      <DeleteIcon className={classes.icon} />
                      {"Eliminar"}
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  lg={2}
                  xs={2}
                  className={classes.containerActionsButtons}
                  style={{ marginTop: "7px" }}
                >
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <FormControlLabel
                      style={{ margin: 0, height: "100%" }}
                      control={
                        <Checkbox
                          name="checkedF"
                          checked={checked}
                          className={classes.formCheck}
                          icon={
                            <IconButton
                              classes={{
                                root: classes.button,
                                label: classes.label,
                              }}
                              variant="raised"
                              disableRipple={true}
                              disabled={loadingFavorite}
                            >
                              <StarBorderIcon className={classes.icon} />
                              {"Favorito"}
                            </IconButton>
                          }
                          checkedIcon={
                            <IconButton
                              classes={{
                                root: classes.button,
                                label: classes.label,
                              }}
                              variant="raised"
                              disableRipple={true}
                              disabled={loadingFavorite}
                            >
                              <StarIcon className={classes.iconChecked} />
                              {"Favorito"}
                            </IconButton>
                          }
                        />
                      }
                    />
                  </Tooltip>
                </Grid>
                <Grid item lg={2} md={2} style={{ textAlign: "center" }}>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton
                      classes={{
                        root: classes.button,
                        label: classes.label,
                      }}
                      variant="raised"
                      disableRipple={true}
                      disabled={loadingFavorite}
                    >
                      <EditIcon className={classes.icon} />
                      {"Editar"}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={2} xs={2} style={{ textAlign: "center" }}>
                  <IconButton
                    classes={{
                      root: classes.button,
                      label: classes.label,
                    }}
                    variant="raised"
                    disableRipple={true}
                    onClick={(e) => onDeleteItem(e, data)}
                  >
                    <DeleteIcon className={classes.icon} />
                    {"Eliminar"}
                  </IconButton>
                </Grid>
                <Grid
                  item
                  lg={2}
                  xs={2}
                  className={classes.containerActionsButtons}
                  style={{ marginTop: "7px" }}
                >
                  {loadingFavorite ? (
                    <div className={classes.progress}>
                      <CustomProgress size={22} />
                    </div>
                  ) : (
                    <FormControlLabel
                      style={{ margin: 0, height: "100%" }}
                      control={
                        <Checkbox
                          name="checkedF"
                          checked={checked}
                          onChange={handleChecked}
                          className={classes.formCheck}
                          icon={
                            <IconButton
                              classes={{
                                root: classes.button,
                                label: classes.label,
                              }}
                              variant="raised"
                              disableRipple={true}
                            >
                              <StarBorderIcon className={classes.icon} />
                              {"Favorito"}
                            </IconButton>
                          }
                          checkedIcon={
                            <IconButton
                              classes={{
                                root: classes.button,
                                label: classes.label,
                              }}
                              variant="raised"
                              disableRipple={true}
                            >
                              <StarIcon className={classes.iconChecked} />
                              {"Favorito"}
                            </IconButton>
                          }
                        />
                      }
                    />
                  )}
                </Grid>
                <Grid item lg={2} md={2} style={{ textAlign: "center" }}>
                  <IconButton
                    classes={{
                      root: classes.button,
                      label: classes.label,
                    }}
                    variant="raised"
                    disableRipple={true}
                    onClick={(e) => onLoadItem(e, data)}
                  >
                    <EditIcon className={classes.icon} />
                    {"Editar"}
                  </IconButton>
                </Grid>
              </>
            )}
          </>
        </Grid>
      </div>
    );
  }
);

DetailArticle.propTypes = {
  updateFavoriteStatusAction: PropTypes.func.isRequired,

  articleReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  articleReducer: state.articleReducer,
});

export default connect(mapStateToProps, {
  updateFavoriteStatusAction,
})(DetailArticle);
