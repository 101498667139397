import { Grid, IconButton, Link, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import InputIcon from "../../../components/input/inputIcon.component";
import EventIcon from "@material-ui/icons/Event";
import PopoverModal from "../../../components/modal/popoverModal.component";
// import StyledCheckbox from "../../../components/checkForm/styledCheckbox.component";
import RcCalendar from "../../../components/datepicker/rcdatepicker.component";
import StyledCheckbox from "../../../components/checkForm/styledCheckbox.component";

const useStyles = makeStyles({
  buttonIcon: {
    color: "white",
    marginTop: "50%",
  },
  letter: {
    color: "#FFFFFF",
  },
  alignCenter: {
    textAlign: "center",
  },
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
  buttonFilterStatus: {},
});

const filterImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC";

const FilterContainerSupport = ({
  onSearch,
  txtFilter,
  syncChangesFilter,
  datesTmp,
  handleDateInitial,
  handleDateFinal,
  cleanFilterDate,
  modalDatesAnchorEl,
  setModalDatesAnchorEl,
  handleCloseModalDates,
  filterDate,
  filterData,
  handlecheckFilters,
  cleanFilter,
  handleOpenModalChecks,
  handleCloseModalChecks,
  modalChecksAnchorEl,
  loadingGetdocuments,
  txtFilterCheck,
}) => {
  const classes = useStyles();

  const { loadingGetDocumentSupportData } = useSelector(
    (state) => state.documentSupportReducer
  );

  //const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);

  /**
   *  *Abre modal para filtro por fechas
   * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    if (loadingGetDocumentSupportData !== true) {
      setModalDatesAnchorEl(event.currentTarget);
    }
  };

  /**
   * *Panel selección filtro rango fechas
   */
  const modalDates = () => {
    return (
      <>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={datesTmp.Fechini}
              className={"colorCalendarRC"}
              id={"txtfechini"}
              onChange={handleDateInitial}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={datesTmp.Fechfin}
              className={"colorCalendarRC"}
              id={"txtfechfin"}
              onChange={handleDateFinal}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={filterDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </>
    );
  };

  // ! Fin Panel selección filtro rango fechas

  // /**
  //  * * Cerrar modal filtro estado documentos
  //  */
  // const handleCloseModalChecks = () => {
  //   setModalChecksAnchorEl(null);
  // };

  /**
   *  *  Abrir modal filtro estado documentos
   * @param {*} event Evento
   */
  // const handleOpenModalChecks = (event) => {
  //   // if (loadingGetdocuments !== true) {
  //   // }
  //   setModalChecksAnchorEl(event.currentTarget);
  // };

  /**
   *  * Modal filtros por estado documentos
   */
  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Pagadas}
              onChange={handlecheckFilters}
              name="chkPagadas"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Pagadas
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Pendientes}
              onChange={handlecheckFilters}
              name="chkPendientes"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Por pagar
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Vencidas}
              onChange={handlecheckFilters}
              name="chkVencidas"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            En mora
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              onClick={() => handlecheckFilters}
              checked={filterData.Destacadas}
              onChange={handlecheckFilters}
              name="chkDestacadas"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Destacadas
          </Grid>

          {
            // ! filtros todavia no usados en doc soporte -no borrar -
            /* <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              // onClick={() => handlecheckFilters}
              // checked={filterData.Masivo}
              // onChange={handlecheckFilters}
              name="chkMasivo"
            />
          </Grid> */
          }
          {/* <Grid item lg={9} md={9} sm={9}>
            Cargue masivo
          </Grid> */}
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              onClick={() => handlecheckFilters}
              checked={filterData.Anulacion}
              onChange={handlecheckFilters}
              name="chkAnulacion"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Anuladas
          </Grid>
          {/* <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              // onClick={() => handlecheckFilters}
              // checked={filterData.Notadebito}
              // onChange={handlecheckFilters}
              name="chkNotaDebito"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Notas débito
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              // onClick={() => handlecheckFilters}
              // checked={filterData.Notacredito}
              // onChange={handlecheckFilters}
              name="chkNotaCredito"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Notas crédito
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilter}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  //  !Fin filtros por estado de documentos

  //*  Contenedor  filtros

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <InputIcon
            name={"txtFilter"}
            label={"Buscar"}
            value={txtFilter}
            onChange={(e) => syncChangesFilter(e)}
            onSearch={onSearch}
            maxLength={100}
            disabled={loadingGetDocumentSupportData === true}
          />
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex" }}>
            <Tooltip title="Filtrar por fechas">
              <IconButton
                onClick={handleOpenModalDates}
                disabled={loadingGetDocumentSupportData === true}
              >
                <EventIcon className={classes.buttonIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filtrar por estado">
              <IconButton
                className={classes.buttonFilterStatus}
                onClick={handleOpenModalChecks}
                disabled={loadingGetdocuments === true}
              >
                <img
                  alt={"Filtrar"}
                  src={filterImage}
                  style={{ marginTop: "60%" }}
                ></img>
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
      <PopoverModal
        handleClose={handleCloseModalDates}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={""}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />
      {/* <SimpleModal
        // onClose={handleCloseModalMail}
        // open={openModalMail}
        // component={modalMails}
        title={"Enviar Cotización"}
        width={365}
        showCloseButton={false}
      /> */}

      {/* <ResponseModal
  // modalType={modalData.modalType}
  // title={modalData.title}
  // subtitle={modalData.subtitle}
  // body={modalData.body}
  // modalImage={modalData.modalImage}
  // open={modalData.open}
  // closeElement={modalData.closeElement}
  // onCloseElement={handleResponseModal}
  /> */}
    </>
  );
};

export default FilterContainerSupport;
