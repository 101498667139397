import React from "react";

import { useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { updateItemTotalAction } from "../../../../actions/documentCartAction";

import InputSmall from "../../../../components/input/inputSmall.component";

import { convertStringToDecimal } from "../../../../utils/convertStringToDecimal";
import { regexDecimal } from "../../../../helpers/customRegex.hepers";

export const DiscountTypeSection = ({
  discountType,
  discountValue,
  handleInputChange,
  handleUpdateForm,
  itemDoc,
  quantity,
  unitValue,
  validator,
}) => {
  const dispatch = useDispatch();

  /**
   * * Maneja el evento de clic cuando el valor no es cero.
   * @param {Event} e - El evento de clic.
   * @param {string} name - El nombre del campo a actualizar.
   */
  const onClickNotZero = (e, name) => {
    let value = e.target.value;
    if (!!value) {
      value = value.replace(regexDecimal, "");
      if (value === 0 || value === "0") {
        handleUpdateForm({
          [name]: "",
        });
      }
    }
  };

  /**
   * * Maneja el evento onBlur del campo de descuento.
   * @param {Object} event - El evento onBlur.
   */
  const handleBlurDiscount = ({ target }) => {
    let isvalid = validator.fieldValid("discountValue");
    let newdiscount = isvalid ? convertStringToDecimal(target.value) : 0;

    let newdata = {
      ...itemDoc,
      porcentajedescuento: discountType === "1" ? 0 : newdiscount,
      valordescuento: discountType === "2" ? 0 : newdiscount,
    };

    if (target.value.trim().length === 0 || !isvalid) handleUpdateForm({ discountValue: 0 });

    dispatch(updateItemTotalAction(newdata));
  };

  return (
    <Grid item lg={6} xs={12}>
      <InputSmall
        format={discountType === "1" ? "money" : "porcentage"}
        label={discountType === "2" ? "Porcentajes" : "Valor Descuento"}
        maxLength={discountType === "2" ? 9 : 21}
        name={"discountValue"}
        nameValidator={"discountValue"}
        onBlur={handleBlurDiscount}
        onChange={handleInputChange}
        onClick={(e) => onClickNotZero(e, "discountValue")}
        validateOptions={
          discountType === "2"
            ? "porcentage"
            : [
                {
                  discountvalue: unitValue * quantity,
                },
              ]
        }
        validator={validator}
        value={discountValue}
        variant={"standard"}
      />
    </Grid>
  );
};
