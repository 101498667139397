import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";

import InputIcon from "../../components/input/inputIcon.component";
import CollapsePanel from "./../../components/collapse/collapse.component";
import { ConfirmAlert, SuccessAlert } from "../../helpers/alert.helpers";
import NewCharge from "./newCharge.component";
import ChargeCard from "./chargeCard.component";
import {
  getFilterCharge,
  getCharges,
  deleteCharge,
  clearDeleteCharge,
} from "./../../actions/chargeAction";
import { getRoleOfLoggedUser } from "../../actions/authActions";
import CustomProgress from "../../components/Progress/progress.component";
import {
  MESSAGE_DELETE_CONFIRM,
  TIME_UPDATE_REGISTER,
} from "../../config/config";

import DATA_NOT_FOUND_IMAGE from "../../images/NotificacionesColfactura.png";

const useStyles = (theme) => ({
  txtFilter: {
    margin: "10px 0px 10px 0px",
    color: theme.palette.primaryColor,
  },
  datanotfound: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    textAlign: "center",
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

class ChargeManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      deleteCharge: {},
      createCharge: {},
      updateCharge: {},
      currentCharge: {},
      txtFilter: "",
      openPanel: "",
      editingChargeData: false,
      components: [],
      wantToDeleteHeader: "",
      wantToDeleteBody: "",
      recordRemovedHeader: "",
      recordRemovedBody: "",
      loadingListData: false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.chargeReducer.getChargesEnterprise !== state.data) {
      update.data = nextProps.chargeReducer.getChargesEnterprise;
    }

    if (!!nextProps.chargeReducer.getFilterCharges) {
      update.data = nextProps.chargeReducer.getFilterCharges;
    }

    if (nextProps.chargeReducer.editingChargeData !== state.editingChargeData) {
      update.editingChargeData = nextProps.chargeReducer.editingChargeData;
    }

    if (!!nextProps.chargeReducer.createCharge) {
      if (nextProps.chargeReducer.createCharge.statusCode === "201") {
        update.createCharge = nextProps.chargeReducer.createCharge;
        update.openPanel = "";
      }
    }

    if (!!nextProps.chargeReducer.updateCharge) {
      if (nextProps.chargeReducer.updateCharge.statusCode === "201") {
        update.updateCharge = nextProps.chargeReducer.updateCharge;
        update.openPanel = "";
      }
    }

    if (!!nextProps.chargeReducer.deleteCharge) {
      if (nextProps.chargeReducer.deleteCharge.statusCode === "201") {
        update.deleteCharge = nextProps.chargeReducer.deleteCharge;
      }
    }

    if (!!nextProps.configReducer.components !== state.components) {
      update.components = nextProps.configReducer.components;
    }

    if (
      !!nextProps.chargeReducer.loadingGetDiscountList !== state.loadingListData
    ) {
      update.loadingListData = nextProps.chargeReducer.loadingGetDiscountList;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCharges();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.createCharge !== this.state.createCharge) {
      if (this.state.createCharge.statusCode === "201") {
        this.state.txtFilter !== ""
          ? this.props.getCharges(this.state.txtFilter)
          : this.props.getCharges();
      }
    }

    if (prevState.updateCharge !== this.state.updateCharge) {
      if (this.state.updateCharge.statusCode === "201") {
        this.state.txtFilter !== ""
          ? this.props.getCharges(this.state.txtFilter)
          : this.props.getCharges();
      }
    }

    if (prevState.deleteCharge !== this.state.deleteCharge) {
      if (this.state.deleteCharge.statusCode === "201") {
        SuccessAlert(null, this.state.recordRemovedBody, TIME_UPDATE_REGISTER);
        this.cleanData();
        this.state.txtFilter !== ""
          ? this.props.getCharges(this.state.txtFilter)
          : this.props.getCharges();
      }
    }

    if (prevState.components !== this.state.components) {
      if (!!this.state.components && this.state.components.length > 0) {
        this.assignConfigData();
      }
    }
  }

  cleanData() {
    this.props.clearDeleteCharge();
  }

  assignConfigData() {
    const popupChargeWantToDelete = this.filterConfigInfo(52);
    const popupChargeRecordRemoved = this.filterConfigInfo(53);
    if (!!popupChargeWantToDelete) {
      this.setState({
        wantToDeleteHeader: this.filterConfigInfoList(
          popupChargeWantToDelete.adcomponenteinformacion,
          27
        ).text,
        wantToDeleteBody: this.filterConfigInfoList(
          popupChargeWantToDelete.adcomponenteinformacion,
          28
        ).text,
      });
    }
    if (!!popupChargeRecordRemoved) {
      this.setState({
        recordRemovedHeader: this.filterConfigInfoList(
          popupChargeRecordRemoved.adcomponenteinformacion,
          29
        ).text,
        recordRemovedBody: this.filterConfigInfoList(
          popupChargeRecordRemoved.adcomponenteinformacion,
          30
        ).text,
      });
    }
  }

  filterConfigInfo(id) {
    const configData = this.state.components;
    const data = configData.find((item) => item.id === id);
    return data;
  }

  filterConfigInfoList(list, id) {
    const data = list.find((item) => item.id === id);
    return data;
  }

  onClickCollapse = () => {
    if (this.state.openPanel === "panel") {
      this.setState({
        openPanel: "",
      });
      this.props.getFilterCharge("");
    } else if (this.state.openPanel === "") {
      this.setState({
        openPanel: "panel",
      });
    }
  };

  onLoad(e, item) {
    e.preventDefault();
    this.props.getFilterCharge(item);

    this.setState({
      openPanel: "panel",
    });
    window.scrollTo(0, 0);
  }

  onDeleteData(e, item) {
    const deleteData = (confirmed) => {
      if (confirmed) {
        this.props.deleteCharge(item.id);
      }
    };
    ConfirmAlert(
      `¿${this.state.wantToDeleteBody} ${item.descripcion}?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  }

  syncChanges(value, property) {
    this.setState((prevState) => ({
      ...prevState.txtFilter,
      [property]: value,
    }));
  }

  onChangeFilter(e) {
    e.preventDefault();
    const value = e.target.value;
    this.props.getCharges(value, false);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              {getRoleOfLoggedUser() ? (
                <CollapsePanel
                  component={<NewCharge></NewCharge>}
                  title={
                    <LightTooltip
                      title="No tiene permisos sobre esta acción"
                      placement="bottom"
                    >
                      <span>Crear nuevo cargo</span>
                    </LightTooltip>
                  }
                  titleOpen={"Crear nuevo cargo"}
                  titleEdit={"Crear nuevo cargo"}
                  open={""}
                />
              ) : (
                <CollapsePanel
                  component={<NewCharge />}
                  title={
                    !this.state.editingChargeData
                      ? "Crear nuevo cargo"
                      : "Editar cargo"
                  }
                  titleOpen={
                    !this.state.editingChargeData
                      ? "Nuevo cargo"
                      : "Editar cargo"
                  }
                  titleEdit={
                    !this.state.editingChargeData
                      ? "Nuevo cargo"
                      : "Editar cargo"
                  }
                  open={this.state.openPanel}
                  onClick={this.onClickCollapse}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className={classes.txtFilter}>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <InputIcon
                id={"txtFilter"}
                label={"Buscar cargo..."}
                maxLength={15}
                value={this.state.txtFilter}
                onChange={(e) => {
                  this.syncChanges(e.target.value, "txtFilter");
                  this.onChangeFilter(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {this.state.loadingListData === true ? (
          <Grid container>
            <Grid item lg={4} xs={4}></Grid>
            <Grid item lg={4} xs={4}>
              <CustomProgress />
            </Grid>
            <Grid item lg={4} xs={4}></Grid>
          </Grid>
        ) : !!this.state.data && this.state.data.length > 0 ? (
          this.state.data.map((item, index) => (
            <Grid key={index} item lg={12} xs={12}>
              <ChargeCard
                key={index}
                data={item}
                onDelete={(e) => {
                  this.onDeleteData(e, item);
                }}
                onLoad={(e) => {
                  this.onLoad(e, item);
                }}
              ></ChargeCard>
            </Grid>
          ))
        ) : (
          <Grid container className={classes.datanotfound}>
            <Grid item lg={12} xs={12}>
              <img src={DATA_NOT_FOUND_IMAGE} alt="Datos no encontrados" />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  chargeReducer: state.chargeReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  getFilterCharge,
  getCharges,
  deleteCharge,
  clearDeleteCharge,
})(withStyles(useStyles)(ChargeManage));
