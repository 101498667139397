import { types } from "../types/types"

/**
 * Carga listado contactos en redux
 * @param {*} contacts 
 * @returns 
 */
 export const loadListContactsAction = (contacts) => dispatch => {

    dispatch({
        type: types.GET_CONTACT_LIST,
        payload: contacts
    })
}


/**
 * Agrega nueva dirección adicional
 * @param {*} contact
 * @returns 
 */
export const addContactAction = (contact) => dispatch => {
    dispatch({
        type: types.ADD_CUSTOMER_CONTACT,
        payload: contact
    })
}

/**
 * Actualiza direccion adicional
 * @param {*} address 
 * @returns 
 */
export const updateContactAction = (contact) => dispatch => {
    dispatch({
        type: types.UPDATE_CUSTOMER_CONTACT,
        payload: contact
    })
}

/**
 * Borra direccion adicional
 * @param {*} address 
 * @returns 
 */
export const deleteContactAction = (id) => dispatch => {
    dispatch({
        type: types.DELETE_CUSTOMER_CONTACT,
        payload: id
    })
}

/**
 * Carga información de dirección activa en redux
 * @param {*} address 
 * @returns 
 */
export const setActiveContactAction = (contact) => dispatch => {
    dispatch({
        type: types.SET_ACTIVE_CUSTOMER_CONTACT,
        payload: contact
    })

}

export const cleanDataContactAction = ()=>dispatch=>{
    dispatch({
        type: types.CLEAN_CUSTOMER_CONTACT
    })
}


