import { generateDocumentItem } from "../helpers/calculateDocumentValues";
import { types } from "../types/types";
import { cleanArticleCheckedAction } from "./articleActions";
import { updateDocumentTotals } from "./documentCartAction";

/**
 * Carga direccion de cliente seleccionada para documento
 * @param {*} id Identificador direccion
 * @returns
 */
export const loadAddressCustomerAction = (id) => (dispatch) => {
  dispatch({
    type: types.LOAD_ADDRESSS_CUSTOMER,
    payload: id,
  });
};

/**
 * Carga contacto de cliente seleccionado para documento
 * @param {*} id Identificador contacto
 * @returns
 */
export const loadContactCustomerAction = (id) => (dispatch) => {
  dispatch({
    type: types.LOAD_CONTACT_CUSTOMER,
    payload: id,
  });
};

/**
 * Cargar cliente a documento
 * @param {*} customer
 * @returns
 */
export const loadCustomerDocument = (customer) => {
  return {
    type: types.LOAD_CUSTOMER_DOCUMENT,
    payload: customer,
  };
};

/**
 * Borra cliente seleccionado para documento
 * @returns
 */
export const deleteCustomerDocumentAction = () => (dispatch) => {
  dispatch(loadCustomerDocument(null));
};

/**
 * Carga listado numeración disponible, en redux
 * @param {*} numerations
 * @returns
 */
export const setAvailableNumerationAction = (numerations) => (dispatch) => {
  const notContingency = numerations.filter(
    (c) => c.diantipomodalidadid.toString() !== "5"
  );
  dispatch({
    type: types.SET_AVAILABLE_NUMERTAION,
    payload: notContingency,
  });
};

export const AddNumerationDocumentAction = (numeration) => (dispatch) => {
  dispatch({
    type: types.ADD_NUMERATION_DOCUMENT,
    payload: numeration,
  });
};

/**
 * Carga información cabecera formulario
 * @param {*} data
 * @returns
 */
export const loadHeadDocumentAction = (
  data,
  customfields,
  customFieldsList,
  documentsReference
) => (dispatch) => {
  dispatch({
    type: types.LOAD_HEAD_DOCUMENT,
    payload: data,
  });

  dispatch({
    type: types.LOAD_CUSTOM_FIELDS_DOCUMENT,
    payload: customfields,
  });

  dispatch({
    type: types.LOAD_CUSTOM_FIELDS_COMPANY,
    payload: customFieldsList.filter((c) => c.guardar !== true),
  });

  dispatch({
    type: types.LOAD_DOCS_REFERENCE_DOCUMENT,
    payload: documentsReference,
  });
};

/**
 * Carga información de factura para edición
 * @param {*} documento Información factura guardada
 * @returns
 */
export const loadNoteDocumentDataAction = (documento) => (
  dispatch,
  getState
) => {
  const {
    opdocumento,
    adarticulos,
    cliente,
    opdocumentodescuentos,
    opdocumentocargos,
    retiva,
    retica,
    redondeo,
    opdatosadicionales,
    opdocumentosreferencia,
  } = documento;
  const {
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  //Mostrar Carrito
  dispatch({
    type: types.SHOW_CATALOG,
    payload: false,
  });

  //Cabecera
  dispatch({
    type: types.LOAD_HEAD_DOCUMENT,
    payload: opdocumento,
  });

  //Cliente
  dispatch(loadCustomerDocument({ ...cliente, inactiveEdit: false }));

  //Datos adicionales
  dispatch({
    type: types.LOAD_CUSTOM_FIELDS_COMPANY,
    payload: opdatosadicionales,
  });

  //Documentos de referencia
  dispatch({
    type: types.LOAD_DOCS_REFERENCE_DOCUMENT,
    payload: opdocumentosreferencia,
  });

  //Detalle documento
  let detaildocument = [];
  adarticulos.forEach((item) => {
    let detail = generateDocumentItem(item);
    detaildocument.push(detail);
  });

  dispatch(
    updateDocumentTotals(
      detaildocument,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Carga información de factura para edición
 * @param {*} documento Información factura guardada
 * @returns
 */
export const loadQuotationDataAction = (documento) => (dispatch, getState) => {
  const {
    opdocumento,
    adarticulos,
    cliente,
    opdocumentodescuentos,
    opdocumentocargos,
    retiva,
    retica,
    redondeo,
    opdatosadicionales,
    datosadicionales,
    opdocumentosreferencia,
    direccionclienteid,
    contactoclienteid,
  } = documento;

  const {
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  //Mostrar Carrito
  dispatch({
    type: types.SHOW_CATALOG,
    payload: false,
  });

  //Cabecera
  dispatch({
    type: types.LOAD_HEAD_DOCUMENT,
    payload: opdocumento,
  });

  //Cliente
  dispatch(loadCustomerDocument({ ...cliente, inactiveEdit: false }));

  //Direccion
  dispatch({
    type: types.LOAD_ADDRESSS_CUSTOMER,
    payload: direccionclienteid,
  });

  //Contacto
  dispatch({
    type: types.LOAD_CONTACT_CUSTOMER,
    payload: contactoclienteid,
  });

  //Datos dilenciados
  dispatch({
    type: types.LOAD_CUSTOM_FIELDS_DOCUMENT,
    payload: datosadicionales,
  });

  //Carga campos guardados con documento
  dispatch({
    type: types.LOAD_FIELDS_DOCUMENT,
    payload: opdatosadicionales,
  });

  //Documentos de referencia
  dispatch({
    type: types.LOAD_DOCS_REFERENCE_DOCUMENT,
    payload: opdocumentosreferencia,
  });

  //Detalle documento
  let detaildocument = [];
  adarticulos.forEach((item) => {
    let detail = generateDocumentItem(item);
    detaildocument.push(detail);
  });

  dispatch(
    updateDocumentTotals(
      detaildocument,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Destacar documento
 * @returns
 */
export const standOutDocumentAction = () => (dispatch) => {
  dispatch({
    type: types.STAND_OUT_DOCUMENT,
  });
};

/**
 * Carga tipo nota a generar
 * @param {*} type
 * @returns
 */
export const setTypeNoteAction = (type) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_TYPE_NOTE,
    payload: type,
  });
};

/**
 * Carga información paciente
 * @returns
 */
export const addDataPatientAction = (patientList, generateDocument) => (
  dispatch
) => {
  dispatch({
    type: types.LOAD_PATIENTS_DOCUMENT,
    payload: patientList,
  });

  dispatch({
    type: types.SET_GENERATE_PATIENT_DOCUMENT,
    payload: generateDocument,
  });
};

/**
 * Limpia storage de Redux al desmontar componente
 * @returns
 */
export const cleanDocumentCreateAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_CUSTOMERS_DATA,
  });

  dispatch({
    type: types.CLEAN_DOCUMENT,
  });

  dispatch({
    type: types.CLEAN_DOCUMENT_CART,
  });

  dispatch({
    type: types.REFRESH_FIELD_LIST,
    payload: [],
  });

  dispatch({
    type: types.CLEAN_ADITIONAL_DATA_INVOICE,
  });
  dispatch({
    type: types.CLEAN_DOCUMENTS_REFERENCE,
  });

  cleanArticleCheckedAction()(dispatch);
};

/**
 * Carga estado, Generación de documento por contingencia
 * @param {*} status
 * @returns
 */
export const setStatusContingencyAction = (status) => (dispatch) => {
  dispatch({
    type: types.SET_CONTINGENCY_STATUS,
    payload: status,
  });
};

export const cleanCartSupportAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_DOCUMENT_CART,
  });
};
