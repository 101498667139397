import { types } from "../types/types";

  

const initialState = {};
  
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case types.GET_VIDEO_MULTIMEDIA:
        return {
          ...state,
          getVideoMultimedia: action.payload,
          countVideo: action.payload1
        }
        case types.LOADING_GET_VIDEO:
            return {
                ...state,
                loadingGetVideo: action.payload

            }
      default:
        return state;
    }
  }
  