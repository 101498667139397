import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";
import SmallYellowTemplate from "../images/yellow-template-small.png";
import BigYellowTemplate from "../images/yellow-template-big.png";
import SmallRedTemplate from "../images/red-template-small.png";
import BigRedTemplate from "../images/red-template-big.png";
import SmallBlueTemplate from "../images/blue-template-small.png";
import BigBlueTemplate from "../images/blue-template-big.png";
import SmallGreenTemplate from "../images/green-template-small.png";
import BigGreenTemplate from "../images/green-template-big.png";
import SmallPinkTemplate from "../images/pink-template-small.png";
import BigPinkTemplate from "../images/pink-template-big.png";
import SmallDefaultTemplate from "../images/default-template-small.jpg";
import BigDefaultTemplate from "../images/default-template-big.jpg";
import SmallNeckbandTemplate from "../images/neckband-template-small.jpg";
import BigNeckbandTemplate from "../images/neckband-template-big.jpg";
import CustomTemplate from "../images/custom-template.png";

import { handleResponse, enterpriseIdHeader } from "./authActions";
import { types } from "../types/types";
const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

var templates = [
  {
    id: "37938662-0f4f-41d5-af99-9f6b27e91d0a",
    nombre: "Plantilla 1",
    srcSmall: SmallDefaultTemplate,
    srcBig: BigDefaultTemplate,
  },
  {
    id: "9e192a73-c7d7-4de0-a85d-2d68001ced66",
    nombre: "Plantilla 2",
    srcSmall: SmallYellowTemplate,
    srcBig: BigYellowTemplate,
  },
  {
    id: "4648d8c1-8574-468d-9dea-61651a831a1b",
    nombre: "Plantilla 3",
    srcSmall: SmallRedTemplate,
    srcBig: BigRedTemplate,
  },
  {
    id: "6dab59c2-dc40-45b3-b2ab-43c92e71f900",
    nombre: "Plantilla 4",
    srcSmall: SmallGreenTemplate,
    srcBig: BigGreenTemplate,
  },
  {
    id: "7bc3f8a9-adf0-4bf9-8085-2564d3f3aa28",
    nombre: "Plantilla 5",
    srcSmall: SmallBlueTemplate,
    srcBig: BigBlueTemplate,
  },
  {
    id: "b0251d2e-fb96-4b6e-ae3d-8cf8a236811d",
    nombre: "Plantilla 6",
    srcSmall: SmallPinkTemplate,
    srcBig: BigPinkTemplate,
  },
  {
    id: "ce61a90a-9f1e-4fad-acc9-bd1ee6ae9d41",
    nombre: "Plantilla 7",
    srcSmall: SmallNeckbandTemplate,
    srcBig: BigNeckbandTemplate,
  },
];

export const getTemplate = () => async (dispatch) => {
  try {
    changGetTemplateLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.get(
      `${urlEnterprise}/GetEnterpriseTemplates`,
      config
    );

    //Si obtiene alguna plantilla personalizada la ingresa en el listado
    if (response.data.result.length > 0) {
      response.data.result.forEach((customTemplate) => {
        if (!templates.some((el) => el.id === customTemplate.identificador))
          templates.push({
            id: customTemplate.identificador,
            nombre: customTemplate.descripcion,
            srcSmall: CustomTemplate,
          });
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "No se ha podido obtener listado de plantillas personalizadas.",
        error: err,
      },
    });
  } finally {
    changGetTemplateLoading(dispatch, false);
  }

  dispatch({
    type: types.GET_TEMPLATE,
    payload: templates,
  });
};

/**
 * Actualiza estado loading consultando listado plantillas personalizadas
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changGetTemplateLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_TEMPLATE,
    payload: status,
  });
}

/**
 * Registra o actualiza información de plantilla y logo de empresa
 * @param {*} data Información plantilla
 */
export const postTemplate = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateTemplateData`,
      data,
      config
    );
    dispatch({
      type: types.SAVE_TEMPLATE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado de documentos.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Registra o actualiza información de plantilla y logo de empresa
 * @param {object} data Información plantilla
 */
export const saveTemplateDataAction = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);

    data = {
      ...data,
      EmpresaId: enterpriseIdHeader(),
    };

    var response = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateTemplateData`,
      data,
      defaultHeaders()
    );

    dispatch({
      type: types.SAVE_TEMPLATE,
      payload: response.data,
    });
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar Logo y plantilla.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta reistro o actualizaci´n información plantilla
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_TEMPLATE,
    payload: status,
  });
}

export const changeStepStatusAction = (status) => (dispatch) => {
  dispatch({
    type: types.STATUS_STEP_TEMPLATE,
    payload: status,
  });
};
