import React from 'react';

import { DialogActions, Grid, makeStyles } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FlareComponent from "flare-react";
import LoadingAnimation from "../../../images/animations/cargando1.flr";



const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        backgroundcolor: "red",
    },
    paper: {
        maxHeight: 500,
        backgroundColor: "#393939",
        border: "none",
        borderRadius: "5px",
        overflowY: "auto",
        overflowX: "hidden",
        color: "rgba(216,216,216,0.5)",
        outline: 0,
    },
    subTitle: {
        color: theme.palette.secondary,
        fontSize: 18,
        textAlign: "center",
    },
    title: {
        color: theme.palette.thirdColor,
        fontSize: 25,
        fontWeight: "bold",
        textAlign: "center",
    },
    blueColor: {
        color: theme.palette.thirdColor,
        fontSize: 16,
    },
    contentText: {
        color: "white",
        fontsize: "5",
        textAlign: "left",
        padding: "10px 80px",
    },

    dialog: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        "& .MuiPaper-root": {
            backgroundColor: "#393939",
            color: theme.palette.primaryColor,
            height: '464px',
            width: '1000px',
            borderRadius: '5px'
        },
    },
    backgroundContent: {
        color: theme.palette.primaryColor,
    },
    loading: {
        verticalAlign: "middle",
        padding: "50px",
        justifyContent: "center",
        display: "flex",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.thirdColor,
    },

}));


const DialogTitle = ({ children, onClose }) => {
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography>
            <Typography variant="h6">{children}</Typography>
            {
                onClose
                    ? (
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                    : null
            }
        </MuiDialogTitle>
    );
};


export const LoadRecordsModal = ({
    open,
    quantityProcess = 0
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
        >
            <DialogTitle id="form-dialog-title">
                <center>
                    <span className={classes.title}>Carga masiva de clientes</span>
                </center>
            </DialogTitle>
            <DialogContent className={classes.backgroundContent}>
                <DialogContentText   >
                    <div className={classes.loading}>
                        {
                            <FlareComponent
                                width={200}
                                height={200}
                                animationName="music_walk"
                                file={LoadingAnimation}
                            />
                        }
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="backgroundContent" >
                <Grid container >
                    <Grid item lg={12} xs={12} >
                        <div className={classes.subTitle}>
                            Cargando <span className={classes.blueColor}>{quantityProcess}</span> Registro(s), espera un momento por favor.
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>

        </Dialog>
    )
}
