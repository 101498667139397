import React, { useCallback, useState } from "react";

import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import { format, isAfter } from "date-fns";

import { QUANTITY_DATA_DOCUMENT } from "../../../../config/config";

import { FilterComponent } from "./filter";
import { ListCardEquivalentDoc } from "./ListCardEquivalentDoc";
import { WarningAlert } from "../../../../helpers/alert.helpers";

import { enterpriseIdHeader } from "../../../../actions/authActions";

const useStyles = makeStyles({
  letter: {
    color: "#FFFFFF",
    height: "100%",
  },
});

/**
 * * Crea el estado inicial del filtro para la búsqueda de documentos.
 * @param {boolean} edit - Indica si se va a cargar la lista de documentos editados o no.
 * @returns {Object} - Estado inicial del filtro.
 */
const InitialFilterState = (edit) => {
  return {
    txtFilter: "",
    txtFilterConfirmed: "",
    filterData: {
      Anulacion: false,
      Destacadas: false,
      Editadas: !!edit,
      Empresaid: +enterpriseIdHeader(),
      Fechadesde: null,
      Fechahasta: null,
      Filtro: "",
      Limit: QUANTITY_DATA_DOCUMENT,
      LoadMore: false,
      Pagadas: false,
      Pendientes: false,
      Skip: 0,
      Vencidas: false,
    },
  };
};

/**
 * * Estado inicial para las fechas.
 * @type {Object}
 * @property {string} dateStart - Fecha de inicio en formato "yyyy/MM/dd".
 * @property {string} dateEnd - Fecha de fin en formato "yyyy/MM/dd".
 */
const InitialDatesState = {
  dateStart: format(new Date(), "yyyy/MM/dd"),
  dateEnd: format(new Date(), "yyyy/MM/dd"),
};

/**
 * * Estado inicial para los anclajes de los modales.
 * @type {Object}
 * @property {HTMLElement | null} modalDatesAnchorEl - Anclaje del modal de fechas.
 * @property {HTMLElement | null} modalChecksAnchorEl - Anclaje del modal de verificaciones.
 */
const InitialModalState = {
  modalDatesAnchorEl: null,
  modalChecksAnchorEl: null,
};

export const ListEquivalentDoc = ({ edit }) => {
  const classes = useStyles();

  const { loadingStateEquivalentDoc, loadingGetEquivalentDoc } = useSelector(
    (state) => state.equivalentDocReducer
  );

  const [datesState, setDatesState] = useState(InitialDatesState);
  const [filterState, setFilterState] = useState(InitialFilterState(edit));
  const [modalState, setModalState] = useState(InitialModalState);

  const { dateStart, dateEnd } = datesState;
  const { txtFilter, filterData } = filterState;
  const { modalDatesAnchorEl, modalChecksAnchorEl } = modalState;

  /**
   * * Actualiza el estado del filtro con los nuevos datos proporcionados.
   * @param {Object} data - Nuevos datos del filtro.
   */
  const setFilterData = (data) => {
    setFilterState({
      ...filterState,
      filterData: {
        ...filterData,
        ...data,
      },
    });
  };

  /**
   * * Sincroniza los cambios en el filtro con el estado según el valor introducido.
   * @param {Event} e - Evento que desencadenó el cambio.
   */
  const syncChangesFilter = (e) => {
    let value = e.target.value;

    if (value === "") {
      cleanFilter();
    } else {
      setFilterState({
        ...filterState,
        txtFilter: value,
      });
    }
  };

  /**
   * * Limpia el estado del filtro y restablece los valores predeterminados.
   */
  const cleanFilter = () => {
    setFilterState({
      ...filterState,
      txtFilter: "",
      filterData: {
        ...filterData,
        Filtro: null,
        Skip: 0,
        LoadMore: false,
      },
    });
  };

  /**
   * * Ejecuta una búsqueda utilizando los términos del filtro actual.
   */
  const onSearch = () => {
    setFilterState({
      ...filterState,
      filterData: {
        ...filterData,
        Filtro: txtFilter,
        Skip: 0,
        LoadMore: false,
      },
    });
  };

  /**
   * * Actualiza una propiedad específica del estado del modal con el valor proporcionado.
   * @param {string} propertyName - Nombre de la propiedad del estado del modal que se actualizará.
   * @param {*} value - Nuevo valor para la propiedad.
   */
  const setModalStateProperty = (propertyName, value) => {
    setModalState((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  /**
   * * Comprueba si los filtros están desactivados basándose en el estado de carga de los documentos equivalentes.
   * @returns {boolean} - Devuelve true si los filtros están desactivados, de lo contrario devuelve false.
   */
  const areFiltersDisabled = useCallback(() => {
    return loadingStateEquivalentDoc || loadingGetEquivalentDoc;
  }, [loadingStateEquivalentDoc, loadingGetEquivalentDoc]);

  /**
   * * Filtra los documentos por fecha y actualiza el estado del filtro.
   * * Si las fechas son válidas, se actualiza el estado del filtro con las fechas seleccionadas y se cierra el modal de fechas.
   * *Si las fechas no son válidas, se muestra una alerta de advertencia.
   */
  const filterByDate = () => {
    if (isAfter(new Date(dateStart), new Date(dateEnd)))
      return WarningAlert(
        "La fecha inicial no puede ser mayor a la fecha final"
      );
    if (dateStart && dateEnd) {
      setFilterState({
        ...filterState,
        filterData: {
          ...filterData,
          Fechadesde: dateStart,
          Fechahasta: dateEnd,
          Skip: 0,
          LoadMore: false,
        },
      });
    }

    setModalState({ ...modalState, modalDatesAnchorEl: null });
  };

  /**
   * * Limpia los filtros de fecha y restablece los valores predeterminados.
   * * Se restablecen las fechas seleccionadas y se actualiza el estado del filtro.
   * * Se cierra el modal de fechas.
   */
  const cleanFilterByDate = () => {
    setDatesState(InitialDatesState);
    setFilterState({
      ...filterState,
      filterData: {
        ...filterData,
        Fechadesde: null,
        Fechahasta: null,
        Skip: 0,
        LoadMore: false,
      },
    });

    setModalState({ ...modalState, modalDatesAnchorEl: null });
  };

  /**
   * * Evento de selección de fecha en filtro de documentos
   * @param {string} field Campo de fecha que se está actualizando ('dateStart' o 'dateEnd')
   * @param {*} date Fecha seleccionada
   */
  const handleDateChange = (field, date) => {
    setDatesState((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  /**
   * * Maneja el cambio en los filtros por estado de documento y actualiza el estado del filtro correspondiente en el checkbox.
   * @param {Event} e - Evento que desencadenó el cambio en los filtros de estado.
   */
  const handlecheckFilters = (e) => {
    const { name, checked } = e.target;

    setFilterState({
      ...filterState,
      filterData: {
        ...filterData,
        [name]: checked,
        Skip: 0,
        LoadMore: false,
      },
    });
  };

  /**
   * * Limpia los filtros de estado  y restablece los valores predeterminados.
   * * Se desmarcan todas las opciones de los filtros de estado y se actualiza el estado del filtro.
   * * Se cierra el modal de verificaciones.
   */
  const cleanFilterByChecks = () => {
    setFilterState({
      ...filterState,
      filterData: {
        ...filterData,
        Anulacion: false,
        Destacadas: false,
        Pagadas: false,
        Pendientes: false,
        Vencidas: false,
        Skip: 0,
        LoadMore: false,
      },
    });

    setModalState({ ...modalState, modalChecksAnchorEl: null });
  };

  return (
    <div className={classes.letter}>
      <div className="spacingInvoice" />
      <Grid container>
        <FilterComponent
          cleanFilter={cleanFilterByChecks}
          cleanFilterDate={cleanFilterByDate}
          dates={datesState}
          filterData={filterData}
          filterDate={filterByDate}
          handlecheckFilters={handlecheckFilters}
          handleCloseModal={setModalStateProperty}
          handleDateChange={handleDateChange}
          loading={areFiltersDisabled()}
          modalChecksAnchorEl={modalChecksAnchorEl}
          modalDatesAnchorEl={modalDatesAnchorEl}
          onSearch={onSearch}
          setModalState={setModalStateProperty}
          syncChangesFilter={syncChangesFilter}
          txtFilter={txtFilter}
        />
        <div className="spacingInvoice" />
        <ListCardEquivalentDoc
          dataFilter={filterData}
          setFilterData={setFilterData}
        />
      </Grid>
    </div>
  );
};
