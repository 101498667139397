import { types } from "../types/types";

const initialState = {
    discounts: [],
    loadingGetDiscounts: false,
    loadingSaveDiscount: false,
    activeDocumentDiscount: null,
    reasonsDiscount: []

}


export const newDiscountReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DISCOUNTS_LIST:
            return {
                ...state,
                discounts: action.payload
            }
        case types.ADD_NEW_DISCOUNT:
            return {
                ...state,
                discounts: [...state.discounts, action.payload]
            }
        case types.LOADING_DISCOUNTS_LIST:
            return {
                ...state,
                loadingGetDiscounts: action.payload
            }
        case types.LOADING_ADD_DISCOUNT:
            return {
                ...state,
                loadingSaveDiscount: action.payload
            }
        case types.LOAD_DOCUMENT_DISCOUNT:
            return {
                ...state,
                activeDocumentDiscount: action.payload
            }
        case types.GET_REASONS_DISCOUNTS:
            return {
                ...state,
                reasonsDiscount: action.payload
            }
        case types.CLEAN_DISCOUNTS_DATA:
            return {
                ...initialState
            }
        default:
            return state;
    }
}