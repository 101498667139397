import ResultTest from "./ResultTest";

export default class ValidateField {
	constructor(validations) {
		this.validations = validations;
	}

	/**
	 * Meotod encargado de delegar la validacion de la celda
	 *
	 * @param {valor del campo} value
	 * @param {posicion del campo a validar} position
	 * @param {numero de registro dentro del archivo} line
	 * @param {nombre de la columna} key
	 */
	validate = (value, position, line, key) => {
		return this.validateField(value, this.validations[position], line, key);
	};

	testValue = (value, validation, line, key) => {
		let valueprefijo = validation.pattern.value;
		if (valueprefijo) {
			const test = validation.pattern.value.test(value);
			if (!test) {
				const validationError = new ResultTest(
					validation.pattern.message + " [" + value + "]",
					line,
					key
				);
				return validationError;
			}
			return null;
		} else {
			const validationError = new ResultTest(
				validation.pattern.message + " [" + value + "]",
				line,
				key
			);
			return validationError;
		}
	};

	/**
	 * Metodo encargado de validar si cumple con la expresion regular
	 *
	 * @param {*} value
	 * @param {*} validation
	 * @param {*} line
	 * @param {*} key
	 */
	validateField = (value, validation, line, key) => {
		if (validation) {
			if (validation.required) {
				//validacion de campos obligatorios
				if (value === "") {
					const validationError = new ResultTest(
						validation.required,
						line,
						key
					);
					return validationError;
				} else {
					//validacion de expresiones regulares
					return this.testValue(value, validation, line, key);
				}
			} else {
				if (value !== "") {
					//validacion de expresiones regulares
					return this.testValue(value, validation, line, key);
				}
			}
		}
		return null;
	};
}
