import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./routes/privateRoutes";
import PublicRoute from "./routes/publicRoutes";
import PrivateRouteWithoutMenu from "./routes/privateRouteWithoutMenu";

import { createBrowserHistory } from "history";
import {
  getStatesAction,
  getCities,
  getDocumentTypesAction,
  getRegimeTypesAction,
  getTaxResponsibilityAction,
  getFiscalResponsibilityAction,
} from "./actions/configAction";

import MyAccount from "./views/myAccount/myAccount.view";
import Home from "./views/dashboard/home.view";
import CatalogCategory from "./views/catalogCategory/catalogCategory.view";
import Invoice from "./views/invoice/invoice.view";
import Charges from "./views/charges/charges.view";
import Discounts from "./views/discount/discount.view";
import Quotation from "./views/invoice/quotation/quotation.view";
import Index from "./views/home/index";
import Informe from "./views/informe/informe.view";
import Help from "./views/helps/help.view";
import Training from "./views/training/training.view";
import Steps from "./views/wizard/steps";
import documentSupport from "./views/documentSupport/documentSupport.view";
import { EquivalentDocument } from "./views/EquivalentDocument";
import { CustomerView } from "./views/customers/customerView";
import { Report } from "./views/siigoReport/report.view";

import { CHATBOT_WOLKVOX } from "./config/config";
import { readError } from "./helpers/readError";

let hist = createBrowserHistory();

const Startup = () => {
  //Se inicializa el Chatbot de ayuda
  const script = document.createElement("script");
  script.id = "prodId";
  script.type = "text/javascript";
  script.src = CHATBOT_WOLKVOX;
  script.async = "";
  document.body.appendChild(script);

  const dispatch = useDispatch();
  const error = useSelector((state) => state.errorReducer);

  useEffect(() => {
    dispatch(getStatesAction());
    dispatch(getRegimeTypesAction());
    dispatch(getTaxResponsibilityAction());
    dispatch(getFiscalResponsibilityAction());
    dispatch(getDocumentTypesAction());
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    if (!!error.error) {
      readError(error);
    }
  }, [error]);

  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute
            exact
            path="/dashboard"
            component={Home}
            history={hist}
            menuid={0}
          />
          <PrivateRoute
            exact
            path="/myAccount"
            component={MyAccount}
            history={hist}
            menuid={1}
          />
          <PrivateRoute
            exact
            path="/invoice"
            component={Invoice}
            history={hist}
            menuid={2}
          />
          <PrivateRoute
            exact
            path="/equivalentDocument"
            component={EquivalentDocument}
            history={hist}
            menuid={16}
          />
          <PrivateRoute
            exact
            path="/quotation"
            component={Quotation}
            history={hist}
            menuid={3}
          />
          <PrivateRoute
            exact
            path="/documentSupport"
            component={documentSupport}
            history={hist}
            menuid={15}
          />
          <PrivateRoute
            exact
            path="/catalogCategory"
            component={CatalogCategory}
            history={hist}
            menuid={4}
          />
          <PrivateRoute
            exact
            path="/charges"
            component={Charges}
            history={hist}
            menuid={5}
          />
          <PrivateRoute
            exact
            path="/discounts"
            component={Discounts}
            history={hist}
            menuid={6}
          />
          <PrivateRoute
            exact
            path="/clients"
            component={CustomerView}
            history={hist}
            menuid={7}
          />
          <PrivateRoute
            exact
            path="/informe"
            component={Informe}
            history={hist}
            menuid={8}
          />
          <PrivateRoute
            exact
            path="/reportSiigo"
            component={Report}
            history={hist}
            menuid={14}
          />
          <PrivateRoute
            exact
            path="/helps"
            component={Help}
            history={hist}
            menuid={9}
          />
          <PrivateRoute
            exact
            path="/training"
            component={Training}
            history={hist}
            menuid={10}
          />
          <PublicRoute
            exact
            path="/home/:queryString"
            component={Index}
            history={hist}
          />
          <PrivateRouteWithoutMenu exact path="/steps" component={Steps} />
          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </div>
    </Router>
  );
};

export default Startup;
