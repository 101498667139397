import React from "react";

/**
 * * Obtiene el mensaje de carrito vacío según el tipo de documento.
 * @param {string} typedocument - El tipo de documento.
 * @returns {Object} - El mensaje de carrito vacío.
 * @property {string} description - La descripción del mensaje de carrito vacío.
 */
export const getEmptyCartMessage = (typedocument) => {
  const documentType = typedocument.replace(/_NOTE$/, "");

  const textTypeDocument = {
    Document: (
      <>
        Para agregar los servicios de tu proveedor, has clic en el botón
        <b>"Agregar servicio"</b> ubicado en la parte derecha y diligencia el
        formulario.
      </>
    ),
    Invoice: (
      <>
        Para agregar los artículos o productos a tu factura, has clic en el
        botón <b>"Crear Articulo"</b> o <b>"Agregar desde Catalogo"</b> ubicado
        en la parte derecha.
      </>
    ),
    Equivalent: (
      <>
        Para agregar los artículos o productos a tu documento equivalente, has
        clic en el botón <b>"Crear Articulo"</b> o{" "}
        <b>"Agregar desde Catalogo"</b> ubicado en la parte derecha.
      </>
    ),
    Quotation: (
      <>
        Para agregar los artículos o productos a tu cotización, has clic en el
        botón <b>"Crear Articulo"</b> o <b>"Agregar desde Catalogo"</b> ubicado
        en la parte derecha.
      </>
    ),
  };

  const typesDocument = {
    CO: textTypeDocument.Quotation,
    DE: textTypeDocument.Equivalent,
    DS: textTypeDocument.Document,
    FA: textTypeDocument.Invoice,
  };

  return { description: typesDocument[documentType] || "" };
};
