import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";
import { enterpriseIdHeader } from "./authActions";
import { filterByPlace } from "./configAction";
import { types } from "../types/types";

const urlBranchOffice = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/sucursal`;

var branchOffices = [];
var filteredBranchOffices = [];

/**
 * Obtiene y filtra listado de sucursales asociadas a una empresa
 */


export const getBranchOfficeAction = (filter = "", refresh = true) => async (
  dispatch
) => {
  if (!refresh) {
    filteredBranchOffices = filerList(branchOffices, filter);
    dispatch({
      type: types.GET_BRANCH_OFFICES,
      payload: filteredBranchOffices,
      payload1: branchOffices,
    });
  } else {
    try {
      changeGetListLoading(dispatch, true);
      let config = defaultHeaders();
      Object.assign(config.headers, {
        pEnterpriseId: `${enterpriseIdHeader()}`,
      });

      var response = await axiosApiInstance.get(
        `${urlBranchOffice}/GetByEmpresaId`,
        config
      );
      branchOffices = response.data.result.map((item) => {
        return {
          ...item,
          value: item.id,
          text: item.nombre,
        };
      });
      filteredBranchOffices = filerList(branchOffices, filter);

      dispatch({
        type: types.GET_BRANCH_OFFICES,
        payload: filteredBranchOffices,
        payload1: branchOffices,
      });
    } catch (err) {
      dispatch({
        type: types.showError,
        payload: {
          message: "No se ha podido obtener información de la empresa.",
          error: err,
        },
      });
    } finally {
      changeGetListLoading(dispatch, false);
    }
  }
};

/**
 * Actualiza estado loading consulta listado sucursales
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_BRANCH_OFFICES,
    payload: status,
  });
}

/**
 * @description Filtra listado sucursales
 * @param {*} list Listado sucursales
 * @param {*} filter FIltro
 */
function filerList(list, filter) {
  if (filter === "" || filter === null || filter === undefined) return list;

  filter = filter.toLowerCase();
  // eslint-disable-next-line array-callback-return
  let newList = list.filter((x) => {
    const nombre = x.nombre.toLowerCase().indexOf(filter);
    const direccion = x.direccion.toLowerCase().indexOf(filter);
    const sucursal = filterByPlace(x.ciudadid, filter);
    let numeracion = -1;
    let i = 0;
    while (i < x.numeraciones.length && numeracion < 0) {
      numeracion = !!x.numeraciones[i].nombre
        ? x.numeraciones[i].nombre.toLowerCase().indexOf(filter)
        : -1;
      i += 1;
    }
    if (nombre > -1) return true;
    else if (numeracion > -1) return true;
    else if (direccion > -1) return true;
    else if (sucursal > -1) return true;
  });
  return newList;
}

/**
 * Carga informacion de sucursal en redux para edición
 * @param {object} data Información sucursal
 */
export const loadBranchOfficeAction = (data) => async (dispatch) => {
  dispatch({
    type: types.LOAD_BRANCH_OFFICE,
    payload: data,
  });
};

/**
 * Abre el modal que informa que no hay cupo para crear sucursales
 * @param {object} data boolean en true
 */
export const updateOpenModalItem = (data) => (dispatch) => {
  dispatch({
    type: types.INFO_SAVE_BRANCH_OFFICES,
    payload: data,
  });
};

/**
 * Registra información de sucursal en BD
 * @param {Array<object>} body Información sucursal
 */
export const saveBranchOfficeAction = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    data = data.map((item) => {
      return {
        ...item,
        empresaid: `${enterpriseIdHeader()}`,
      };
    });
    var response = await axiosApiInstance.post(
      `${urlBranchOffice}/CreateOffice`,
      data,
      defaultHeaders()
    );

    dispatch({
      type: types.SAVE_BRANCH_OFFICE,
      payload: response.data,
    });
  } catch (err) {
    if (
      err.hasOwnProperty("response") &&
      err?.response?.hasOwnProperty("data") &&
      err?.response?.data?.statusCode === "404" &&
      err?.response?.data?.statusMessage === "Unauthorized, no quota"
    ) {
      dispatch({
        type: types.INFO_LICENSE_BRANCH_OFFICES,
        payload: err.response.data,
      });

      if (
        err.response.data.result.licenseActiveBase &&
        !err.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_SAVE_BRANCH_OFFICES,
          payload: true,
        });
      }
    } else {
      dispatch({
        type: types.showError,
        payload: {
          message: "No se ha podido gurdar la sucursal.",
          error: err,
        },
      });
    }
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Elimina la licencia de la sucursal consultada
 *
 */

export const cleanLicenseResponse = () => (dispatch) => {
  dispatch({
    type: types.INFO_LICENSE_BRANCH_OFFICES,
    payload: null,
  });
};

/**
 * Actualiza información de sucursal en BD
 * @param {object} body Nueva informacion de sucursal
 */
export const updateBranchOfficeAction = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    data = { ...data, empresaid: enterpriseIdHeader() };

    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: enterpriseIdHeader(),
    });

    const response = await axiosApiInstance.put(
      `${urlBranchOffice}/UpdateOfficeCompany`,
      data,
      config
    );

    dispatch({
      type: types.UPDATE_BRANCH_OFFICE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar la sucursal.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading guardado o actualización sucursal
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_BRANCH,
    payload: status,
  });
}

/**
 * Actualiza estado de una sucursal
 * @param {*} id Identificador sucursal
 * @param {*} idState Identificador nuevo estado sucursal (0--> activo, 1--> inactivo)
 */
export const updateStateBranchOfficeAction = (id, idState) => async (
  dispatch
) => {
  try {
    changeUpdateStateLoading(dispatch, true, id);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pId: id,
      pIdState: idState,
    });
    var result = await axiosApiInstance.put(
      `${urlBranchOffice}/UpdateState`,
      null,
      config
    );

    //Agrega nuevo estado de sucursal
    let response = {
      ...result.data,
      id: id,
      idstate: idState,
    };

    dispatch({
      type: types.UPDATE_STATE_BRANCH_OFFICE,
      payload: response,
    });
  } catch (err) {
    if (
      err.hasOwnProperty("response") &&
      err.response.hasOwnProperty("data") &&
      err.response.data.statusCode === "404" &&
      err.response.data.statusMessage === "Unauthorized, no quota"
    ) {
      dispatch({
        type: types.INFO_SAVE_BRANCH_OFFICES,
        payload: true,
      });
    } else {
      dispatch({
        type: types.showError,
        payload: {
          message: "No se ha podido actualizar la sucursal.",
          error: err,
        },
      });
    }
  } finally {
    changeUpdateStateLoading(dispatch, false, id);
  }
};

/**
 * Cambia estado loading para cambio de estado dentro del listado de sucursales
 * @param {object} dispatch
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateLoading(dispatch, status, id) {
  let newData = filteredBranchOffices.find((n) => n.id === id);

  if (!!newData) {
    const index = filteredBranchOffices.findIndex((e) => e.id === id);
    let newArray = [...filteredBranchOffices];
    newArray[index] = {
      ...newData,
      loadingStatus: status,
    };
    filteredBranchOffices = newArray;
  }

  dispatch({
    type: types.GET_BRANCH_OFFICES,
    payload: filteredBranchOffices,
    payload1: branchOffices,
  });
}

/**
 * Actualiza listado de sucursales, despues de cambiar el estado (activar, inactivar)
 * @param {int} id Identificador sucursal a actualizadar
 * @param {int} idstate Identificador nuevo estado sucursal
 */
export const refreshAfterChangeStatusAction = (id, idstate, filter) => async (
  dispatch
) => {
  let newData = branchOffices.find((n) => n.id === id);

  if (!!newData) {
    const index = branchOffices.findIndex((e) => e.id === id);
    let newArray = [...branchOffices];
    newArray[index] = { ...newData, idstate: idstate };
    branchOffices = newArray;
  }

  filteredBranchOffices = filerList(branchOffices, filter);
  dispatch({
    type: types.GET_BRANCH_OFFICES,
    payload: filteredBranchOffices,
    payload1: branchOffices,
  });
};

/**
 * Actualiza sucursal principal  de una empresa,
 * marca como principal la nueva sucursal y desmarca como princial
 * la anterior
 * @param {*} idOldPrincipal Identificador actual sucursal principal
 * @param {*} idNewPrincipal  Identificador nueva sucursal principal
 */
export const updatePrincipalBranchOfficeAction = (
  idOldPrincipal,
  idNewPrincipal
) => async (dispatch) => {
  try {
    changeUpdatePrincipalLoading(
      dispatch,
      true,
      idOldPrincipal,
      idNewPrincipal
    );
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pIdOldPrincipal: idOldPrincipal,
      pIdNewPrincipal: idNewPrincipal,
    });

    var result = await axiosApiInstance.put(
      `${urlBranchOffice}/UpdatePrincipal`,
      null,
      config
    );

    let response = {
      ...result.data,
      idOldPrincipal: idOldPrincipal,
      idNewPrincipal: idNewPrincipal,
    };

    dispatch({
      type: types.UPDATE_PRINCIPAL_BRANCH,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar la sucursal.",
        error: err,
      },
    });
  } finally {
    changeUpdatePrincipalLoading(
      dispatch,
      false,
      idOldPrincipal,
      idNewPrincipal
    );
  }
};

/**
 * Cambia estado loading para cambio de sucursal principal dentro del listado de sucursales
 * @param {object} dispatch
 * @param {boolean} status  Estado loading
 */
function changeUpdatePrincipalLoading(
  dispatch,
  status,
  idOldPrincipal,
  idNewPrincipal
) {
  let oldPrincipal = filteredBranchOffices.find((n) => n.id === idOldPrincipal);
  let newPrincipal = filteredBranchOffices.find((n) => n.id === idNewPrincipal);

  let newArray = [...filteredBranchOffices];
  if (!!oldPrincipal) {
    const indexOld = filteredBranchOffices.findIndex(
      (e) => e.id === idOldPrincipal
    );
    newArray[indexOld] = {
      ...oldPrincipal,
      loadingPrincipal: status,
    };
    filteredBranchOffices = newArray;
  }

  if (!!newPrincipal) {
    const indexNew = filteredBranchOffices.findIndex(
      (e) => e.id === idNewPrincipal
    );
    newArray[indexNew] = {
      ...newPrincipal,
      loadingPrincipal: status,
    };
    filteredBranchOffices = newArray;
  }

  dispatch({
    type: types.GET_BRANCH_OFFICES,
    payload: filteredBranchOffices,
    payload1: branchOffices,
  });
}

/**
 * Actualiza listado de sucursales, despues de cambiar sucursal principal
 * @param {int} id Identificador sucursal a actualizadar
 * @param {int} idstate Identificador nuevo estado sucursal
 */
export const refreshAfterChangePrincipalAction = (
  idOldPrincipal,
  idNewPrincipal,
  filter
) => async (dispatch) => {
  let oldBranch = branchOffices.find((n) => n.id === idOldPrincipal);
  let newBranch = branchOffices.find((n) => n.id === idNewPrincipal);

  if (!!oldBranch && !!newBranch) {
    const oldIndex = branchOffices.findIndex((n) => n.id === idOldPrincipal);
    const newIndex = branchOffices.findIndex((n) => n.id === idNewPrincipal);
    let newArray = [...branchOffices];
    newArray[oldIndex] = { ...oldBranch, principal: false };
    newArray[newIndex] = { ...newBranch, principal: true };
    branchOffices = newArray;
  }

  filteredBranchOffices = filerList(branchOffices, filter);
  dispatch({
    type: types.GET_BRANCH_OFFICES,
    payload: filteredBranchOffices,
    payload1: branchOffices,
  });
};

export const fillListOffices = (listOffices) => {
  branchOffices = listOffices;
};

export const changeStepStatusAction = (status) => (dispatch) => {
  dispatch({
    type: types.STATUS_STEP_BRANCH_OFFICE,
    payload: status,
  });
};

export const changeEditStatusAction = (status) => (dispatch) => {
  dispatch({
    type: types.STATUS_EDIT_BRANCH_OFFICE,
    payload: status,
  });
};
