import React, { useRef, useState, useCallback, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import DateForm from "../../../components/datepicker/datepicker.component";
import InputForm from "../../../components/input/input.component";
import SwitchForm from "../../../components/switch/switch.component.js";
import SelectForm from "../../../components/select/select.component";
import ExpandableSelect from "../../../components/select/expandableSelect.component";
import ToggleButtons from "../../../components/toggle/toggle.component";
import { GroupRangeDate } from "../ui/groupRangeDate.component";

import { getPaymentMethodsAction } from "../../../actions/configAction";
import { getAditionalData } from "../../../actions/invoiceActions";
import {
  setAvailableNumerationAction,
  setStatusContingencyAction,
} from "../../../actions/documentAction";
import { loadDocumentReferenceAction } from "../../../actions/documentReferenceAction";

import { useFormComplete } from "../../../hooks/useFormComplete";

import { format } from "date-fns";
import {
  getDateNotBefore,
  getDateNotAfter,
  getDateMinPay,
  getDatepay,
} from "../../../utils/rangeDates";
import {
  getEnterpriseId,
  getUserIdSecurity,
} from "../../../utils/dataUserLogin";
import CustomTooltip from "../../../components/tooltip/tooltip.component";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";

const DocumentForm = ({
  handleContinueToNext,
  typedocument,
  origin,
  responsabilidadtributariacliente,
}) => {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const {
    values,
    handleInputChange,
    handleUpdateForm,
    handleCheckChange,
  } = useFormComplete({
    fecha: format(new Date(), "yyyy/MM/dd"),
    formaPago: "1",
    fechapago: format(getDatepay(), "yyyy/MM/dd"),
    dianmediopagoid: "",
    vendedorid: "",
    incluirVendedor: false,
    sucursalid: "",
    numeracionid: "",
    observaciones: "",
    rangofecha: 0,
    resptributariacliente: "",
  });
  const [availableNumeration, setAvailableNumeration] = useState([]);
  const [availableOffices, setAvailableOffices] = useState([]);

  const {
    fecha,
    formaPago,
    fechapago,
    dianmediopagoid,
    vendedorid,
    rangofecha,
    incluirVendedor,
    sucursalid,
    numeracionid,
    observaciones,
    resptributariacliente,
  } = values;

  const { opdocumento } = useSelector((s) => s.documentReducer);
  const {
    defaultPaymentMethods,
    othersPaymentMethods,
    getTaxResponsibility: taxResponsibilityList,
  } = useSelector((s) => s.configReducer, shallowEqual);
  const {
    advendedor,
    adsucursalesempresa,
    adnumeracion,
    adobservaciones,
  } = useSelector(
    (s) => s.invoiceReducer.aditionaldataenterprise,
    shallowEqual
  );
  const { documentoreferenciaactivo } = useSelector(
    (s) => s.documentReferenceReducer
  );

  useEffect(() => {
    handleUpdateForm({
      resptributariacliente:
        responsabilidadtributariacliente === 5
          ? ""
          : responsabilidadtributariacliente,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsabilidadtributariacliente]);

  useEffect(() => {
    dispatch(getPaymentMethodsAction());
    dispatch(getAditionalData(getEnterpriseId(), getUserIdSecurity()));
  }, [dispatch]);

  useEffect(() => {
    if (adsucursalesempresa.length > 0) {
      let available = adsucursalesempresa.filter((c) => c.idstate !== 2); //Activas/Inactivas
      let associated = available.filter((c) => c.asociada === true);
      if (associated.length > 0) {
        associated = associated.filter((c) => c.idstate === 0); // Solo carga sucursales activas
        setAvailableOffices(associated);
        if (associated.length === 1) {
          setDefaultOffice(associated[0].id);
        }
      } else {
        setAvailableOffices(available.filter((c) => c.idstate === 0));
        if (available.length === 1) {
          setDefaultOffice(available[0].id);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adsucursalesempresa]);

  /**
   * Cargando información ya diligenciada
   */
  useEffect(() => {
    if (!!opdocumento.fecha) {
      const {
        fecha,
        formaPago,
        fechapago,
        vendedorid,
        incluirVendedor,
        sucursalid,
        numeracionid,
        observaciones,
        rangofecha,
        dianmediopagoid,
      } = opdocumento;

      const newList = adnumeracion.filter(
        (n) => n.sucursalempresaid === sucursalid
      );
      const active = adsucursalesempresa.find(
        (c) => c.idstate === 0 && c.id === sucursalid
      );
      setAvailableNumeration(newList);

      if (origin === "CO") {
        handleUpdateForm({
          formaPago: formaPago,
          dianmediopagoid: dianmediopagoid,
          vendedorid: vendedorid,
          incluirVendedor: incluirVendedor,
          sucursalid: !!active ? sucursalid : "",
          numeracionid:
            newList.length === 1
              ? newList[0].id
              : !numeracionid
              ? ""
              : numeracionid,
          observaciones: observaciones,
        });
      } else {
        handleUpdateForm({
          fecha: format(new Date(fecha), "yyyy/MM/dd"),
          formaPago: formaPago,
          dianmediopagoid: dianmediopagoid,
          fechapago:
            fechapago !== "" ? format(new Date(fechapago), "yyyy/MM/dd") : "",
          vendedorid: vendedorid,
          incluirVendedor: incluirVendedor,
          sucursalid: !!active ? sucursalid : "",
          numeracionid:
            newList.length === 1
              ? newList[0].id
              : !numeracionid
              ? ""
              : numeracionid,
          observaciones: observaciones,
          rangofecha: rangofecha,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adnumeracion, opdocumento]);

  useEffect(() => {
    if (adobservaciones.length > 0) {
      handleUpdateForm({
        observaciones: adobservaciones[0].texto,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adobservaciones]);

  /**
   * Sincroniza nuevo valor Date Picker con state
   */
  const handleDate = (id, date, name) => {
    let range = rangofecha > 0 ? rangofecha : 1;
    let datepay = new Date(date);
    datepay.setDate(datepay.getDate() + range);
    handleUpdateForm({
      fecha: new Date(date).toISOString(),
      fechapago: formaPago === "1" ? datepay.toISOString() : "",
    });
  };

  /**
   * Sincroniza nuevo valor fecha pago
   * @param {*} id
   * @param {*} date
   * @param {*} name
   */
  const handleDatePay = (id, date, name) => {
    handleUpdateForm({
      fechapago: new Date(date).toISOString(),
      rangofecha: 0,
    });
  };

  /**
   * Sincroniza nuevo valor rango fecha
   */
  const changeRange = useCallback(
    (value) => {
      let date = !!fecha ? fecha : new Date();
      let datePay = new Date(date);
      let days = parseInt(value);
      datePay.setDate(datePay.getDate() + days - 1); //Cuenta dia actual
      handleUpdateForm({
        rangofecha: value,
        fechapago: format(datePay, "yyyy/MM/dd"),
      });
    },
    [fecha, handleUpdateForm]
  );

  /**
   * Sincroniza nuevo valor sucursal y asigna nueraciones disponiles.
   * @param {*} e
   */
  const handleOfficeChange = (e) => {
    setDefaultOffice(e.target.value);
  };

  /**
   * Setea listado numeraciones asociadas a sucursal seleccionada
   * @param {*} id
   */
  const setDefaultOffice = (id) => {
    const newList = adnumeracion.filter(
      (n) =>
        n.sucursalempresaid === id &&
        n.diantipomodalidadid !== 5 &&
        n.diantipomodalidadid !== 6
    );

    let numerationid = newList.length === 1 ? newList[0].id : "";
    if (numerationid !== "") {
      const modality = newList?.find((m) => m.id === numerationid)
        ?.diantipomodalidadid;

      dispatch(setStatusContingencyAction(modality === 5 ? true : false));

      if (!!documentoreferenciaactivo) {
        dispatch(loadDocumentReferenceAction(null));
      }
    }

    handleUpdateForm({
      sucursalid: id,
      numeracionid: numerationid,
    });

    setAvailableNumeration(newList);
    dispatch(setAvailableNumerationAction(newList));
  };

  /**
   * Sincroniza nuevo valor forma de pago, limpia medio de pago para forma de pago "credito"
   * @param {*} e
   */
  const handleChangePaymentMethod = (e, value) => {
    const defaultPay = defaultPaymentMethods.find((p) => p.codigo === "10");
    handleUpdateForm({
      formaPago: value,
      dianmediopagoid: value === "1" ? "" : defaultPay?.id ?? "",
    });
  };

  /**
   * Sincroniza nuevo valor numeracion con state
   * @param {*} e
   */
  const handleChangeNumeration = (e) => {
    loadStatusContingency(parseInt(e.target.value));
    handleInputChange(e);
  };

  /**
   * Carga estado contigencia, numeración tipo contingencia
   * @param {*} id Identificador numeración
   */
  const loadStatusContingency = (id) => {
    const modality = availableNumeration?.find((m) => m.id === id)
      ?.diantipomodalidadid;
    dispatch(setStatusContingencyAction(modality === 5 ? true : false));

    if (!!documentoreferenciaactivo) {
      dispatch(loadDocumentReferenceAction(null));
    }
  };

  /**
   * Valida formulario, envia información para gestión
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const formValid = validator.allValid();
    if (!formValid) {
      validator.showMessages();
      forceUpdate(1);
      handleContinueToNext(null);
    } else {
      const allPayMethods = defaultPaymentMethods.concat(othersPaymentMethods);
      const numeration = availableNumeration.find(
        (n) => n.id === parseInt(numeracionid) ?? null
      );
      let data = {
        ...values,
        dianformapagoid: formaPago === "1" ? 2 : 1,
        dianformapagodescripcion: formaPago === "1" ? "Crédito" : "Contado",
        dianmediopagodescripcion:
          allPayMethods.find((c) => c.id === parseInt(dianmediopagoid))?.text ??
          "",
        vendedor: advendedor.find((c) => c.id === parseInt(vendedorid)) ?? null,
        numeracion: numeration,
        responsabilidadtributariaid: resptributariacliente,
      };
      handleContinueToNext(data);
    }
  };

  return (
    <form id={"head-form"} onSubmit={handleSubmit}>
      <Grid container>
        {typedocument === "CO" && (
          <>
            <Grid item lg={6} xs={12}>
              <DateForm
                name={"fecha"}
                value={fecha}
                onChange={handleDate}
                label="Fecha Cotización"
                //Se comenta la propiedad del date picker para que permita seleccionar la fecha de emisión de factura s10-45334-OcultarDatepicker_Fact_Cot
                disabled={true}
                validator={validator}
                validateOptions={"required"}
              />
            </Grid>
            <Grid item lg={3} xs={12}>
              <DateForm
                name={"fechapago"}
                value={fechapago}
                onChange={handleDatePay}
                label="Fecha Vigencia"
                minDate={getDateMinPay(fecha)}
                validator={validator}
                validateOptions={
                  formaPago === "1"
                    ? ["required", { dateNotLessThanOrEqual: fecha }]
                    : "void"
                }
              />
            </Grid>
            <Grid item lg={3} xs={12}>
              <GroupRangeDate value={rangofecha} onChange={changeRange} />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
                <Grid item lg={6} xs={12}>
                  <ToggleButtons
                    name={"formaPago"}
                    label={"Forma de pago"}
                    value={formaPago}
                    onChange={handleChangePaymentMethod}
                    titleOne={"Crédito "}
                    titleTwo={"Contado"}
                  />
                </Grid>
                {formaPago === "1" ? (
                  <>
                    <Grid item lg={3} xs={12}>
                      <DateForm
                        name={"fechapago"}
                        value={fechapago}
                        onChange={handleDatePay}
                        label="Fecha de Pago"
                        minDate={getDateMinPay(fecha)}
                        validator={validator}
                        validateOptions={
                          formaPago === "1"
                            ? ["required", { dateNotLessThanOrEqual: fecha }]
                            : "void"
                        }
                      />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <GroupRangeDate
                        value={rangofecha}
                        onChange={changeRange}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item lg={6} xs={12}>
                    <ExpandableSelect
                      name={"dianmediopagoid"}
                      label={"Medio Pago"}
                      value={dianmediopagoid}
                      options={defaultPaymentMethods}
                      optionsSeeMore={othersPaymentMethods}
                      onChange={handleInputChange}
                      validator={validator}
                      validateOptions={formaPago === "1" ? "void" : "required"}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {typedocument === "FA" && (
          <>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={11}>
                <DateForm
                  name={"fecha"}
                  value={fecha}
                  onChange={handleDate}
                  minDate={getDateNotBefore()}
                  maxDate={getDateNotAfter()}
                  disabled={true}
                  label="Fecha Factura"
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>

              <Grid item xs>
                <CustomTooltip
                  message={
                    <TooltipMessage
                      title={
                        "¿ Porque no puedo cambiar la fecha de generación de la factura?"
                      }
                      message={
                        "Porque de acuerdo al anexo técnico de Facturación electrónica Versión 1.9, Se implemento la regla FAD09e - en la cual se valida que la fecha de generación de la factura sea igual a la fecha de firma de la factura."
                      }
                      botton={null}
                      noButton={true}
                    />
                  }
                  size={22}
                />
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ToggleButtons
                name={"formaPago"}
                label={"Forma de pago"}
                value={formaPago}
                onChange={handleChangePaymentMethod}
                titleOne={"Crédito "}
                titleTwo={"Contado"}
              />
            </Grid>
            {formaPago === "1" ? (
              <>
                <Grid item lg={3} xs={12}>
                  <DateForm
                    name={"fechapago"}
                    value={fechapago}
                    onChange={handleDatePay}
                    label="Fecha de Pago"
                    minDate={getDateMinPay(fecha)}
                    validator={validator}
                    validateOptions={
                      formaPago === "1"
                        ? ["required", { dateNotLessThanOrEqual: fecha }]
                        : "void"
                    }
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <GroupRangeDate value={rangofecha} onChange={changeRange} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={6} xs={12}>
                  <ExpandableSelect
                    name={"dianmediopagoid"}
                    label={"Medio Pago"}
                    value={dianmediopagoid}
                    options={defaultPaymentMethods}
                    optionsSeeMore={othersPaymentMethods}
                    onChange={handleInputChange}
                    validator={validator}
                    validateOptions={formaPago === "1" ? "void" : "required"}
                  />
                </Grid>
              </>
            )}
          </>
        )}
        <Grid item lg={6} xs={12}>
          <SelectForm
            label={"Vendedor"}
            name="vendedorid"
            value={vendedorid}
            options={advendedor}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          {!!vendedorid && (
            <SwitchForm
              name="incluirVendedor"
              titleOn={
                typedocument === "FA"
                  ? "Incluir datos del vendedor en la factura"
                  : "Incluir datos del vendedor en la cotización"
              }
              checked={incluirVendedor}
              onChange={handleCheckChange}
              validator={validator.cuurent}
              validateOptions={"required"}
            />
          )}
        </Grid>

        <Grid item lg={6} xs={12}>
          <SelectForm
            label={"Sucursal *"}
            name="sucursalid"
            value={sucursalid}
            validator={validator}
            validateOptions={"required"}
            options={availableOffices}
            onChange={handleOfficeChange}
          />
        </Grid>
        {typedocument === "FA" && (
          <Grid item lg={6} xs={12}>
            <SelectForm
              label={"Resolución Factura*"}
              name="numeracionid"
              value={numeracionid}
              validator={validator}
              validateOptions={"required"}
              options={availableNumeration}
              onChange={handleChangeNumeration}
            />
          </Grid>
        )}
        <Grid item xl={4} lg={6} sm={12} xs={12}>
          <SelectForm
            label={"Responsabilidad Tributaria"}
            name="resptributariacliente"
            value={resptributariacliente}
            options={taxResponsibilityList}
            // validator={validator}
            // validateOptions={"required"}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <InputForm
            name="observaciones"
            maxLength={1000}
            label={"Observaciones"}
            multiline={true}
            value={observaciones}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default DocumentForm;
