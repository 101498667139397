/**
 * * Obtiene el label del input tipo select según el tipo de documento y el tipo de texto especificado.
 *
 * @param {string} typedocument - El tipo de documento.
 * @param {string} textType - El tipo de texto específico (por ejemplo, título o retención).
 * @returns {string} - El texto del label correspondiente al tipo de documento y tipo de texto especificado.
 */
export const getButtonTextSelect = (typedocument, textType) => {
  /**
   * * Tipos de textos para los tipos de retención y títulos para los items.
   */
  const selectTypes = {
    TITLE: {
      INVIOCE: "Selecciona Rete - Fuente",
      DOCUMENT: "Selecciona Rete - Renta",
    },
    RETENTION: {
      INVIOCE: "Agregar Rete - Fuente",
      DOCUMENT: "Agregar Rete - Renta",
    },
    NOTE: {
      INVIOCE: "Motivos",
      DOCUMENT: "Concepto de Corrección",
    },
    TITLECARD: {
      INVIOCE: "Rete Fuente",
      DOCUMENT: "Rete Renta",
    },
    TOOLTIPTITLE: {
      INVIOCE: "Rete-Fuente",
      DOCUMENT: "Rete-Renta",
    },
    TOOLTIPMESSAGE: {
      INVIOCE: "Valor rete-fuente aplicado a este item",
      DOCUMENT: "Valor rete-renta aplicado a este item",
    },
  };

  /**
   * * Mensajes por defecto para cada tipo de texto.
   */
  const defaultMessages = {
    TITLE: "Selecciona Retención",
    RETENTION: "Agregar Retención",
    NOTE: "Motivos",
    TITLECARD: "Retención",
    TOOLTIPTITLE: "Retención",
    TOOLTIPMESSAGE: "Valor retención aplicado a este item",
  };

  /**
   * * Objeto que mapea tipos de documento a tipos de retención y títulos.
   */
  const document = {
    FA: selectTypes[textType]?.INVIOCE,
    CO: selectTypes[textType]?.INVIOCE,
    DE: selectTypes[textType]?.INVIOCE,
    DS: selectTypes[textType]?.DOCUMENT,
  };

  const documentType = typedocument.replace(/_NOTE$/, "");

  return document[documentType] || defaultMessages[textType];
};
