import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";

import ResponseModal from "../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../components/modal/responseRedirectModal.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import CustomProgress from "../../components/Progress/progress.component";
import { DocumentManage } from "./head/documentManage.view";
import { DocumentCartManage } from "./cart/documentCartManage.view";
import { DocumentReview } from "./review/documentReview.view";

import {
  standOutDocumentAction,
  cleanDocumentCreateAction,
} from "../../actions/documentAction";
import {
  createElectronicInvoice,
  createQoutation,
  getDocumentPreviewAction,
  editQoutation,
  updateOpenModalItem,
  cleanLicenseBaseAction,
} from "../../actions/invoiceActions";

import { ConfirmAlert, WarningAlert } from "../../helpers/alert.helpers";
import {
  generateRequestDocument,
  generateRequestQuotation,
} from "../../helpers/generateRequestDocument";
import ArchiveModal from "../../components/modal/archiveModal.component";
import { CustomModal } from "../../components/modal/CustomModal";
import { NumerationInvoice } from "./numeration/numerationInvoice";

import {
  CLAIM_DOCUMENTS,
  MESSAGE_NEXT_CONFIRM,
  READING_MODE,
} from "../../config/config";
import { Tooltip } from "@material-ui/core";
import { TooltipMessageReadingMode } from "../../components/tooltip/TooltipMessageReadingMode";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: "white",
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {" "}
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}{" "}
    </div>
  );
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

function getSteps() {
  return ["Información de Cotización", "Artículos", "Revisar Cotización"];
}

const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

export const CreateQuotationView = ({ HandleBack, editQuotation }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const steps = getSteps();
  const [loading, setLoading] = React.useState(false);
  const [openModalResolution, setOpenModalResolution] = React.useState(false);
  const [openModalFile, setOpenModalFile] = React.useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = React.useState(
    initialModal
  );
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [base64File, setBase64File] = React.useState(null);
  const [modalResponse, setModalResponse] = React.useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
    closeFromModalBody: false,
  });

  const { openModalItem: modal, licenseBase } = useSelector(
    (state) => state.invoiceReducer
  );
  const documentdata = useSelector((state) => state.documentReducer);
  const { favorito } = documentdata;
  const totals = useSelector((state) => state.documentCartReducer);
  const {
    showCatalog,
    valorapagar,
    opdetalledocumento,
    valorcargosaplicados,
    valorbase,
  } = totals;

  /**
   * Desmontado componente
   */
  useEffect(() => {
    return () => {
      dispatch(cleanDocumentCreateAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (loading) {
      setModalResponse({
        type: "success",
        title: "",
        subtitle: "Generando documento, por favor espere...",
        body: (
          <div>
            <div>
              <>
                {" "}
                <CustomProgress />
                {"Procesando"}{" "}
              </>
            </div>
          </div>
        ),
        modalImage: "noimagen",
        open: true,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
  }, [licenseBase]);

  /**
   * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    dispatch(cleanLicenseBaseAction());
  };

  /**
   * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    dispatch(updateOpenModalItem(false));
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  /**
   * Avanza paso siguiente
   */
  const handleNextStep = (step) => {
    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < step; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
    setActiveStep(step);
  };

  /**
   * Regresa a paso anterior
   * @param {*} step
   */
  const handleBackStep = (step) => {
    setActiveStep(step - 1);
  };

  /**
   * Desctacar documento
   */
  const handleStandOut = () => {
    dispatch(standOutDocumentAction());
  };

  /**
   * Desmontar componente limpiar redux
   * vuleve a listado documentos
   */
  const handleCancelDocument = () => {
    HandleBack();
  };

  /**
   * Valida información de documento antes de guardar
   */
  const validateDocument = () => {
    let errorMessages = [];
    if (opdetalledocumento.length === 0) {
      errorMessages.push("Debes seleccionar al menos un producto.");
    } else {
      if (valorapagar < 0)
        errorMessages.push(
          "El valor a pagar de la factura no puede ser negativo."
        );

      //Campos obligatorios por item
      let valid = true;
      opdetalledocumento.forEach((item) => {
        if (item.esregalo) {
          if (!item.asumeresponsabilidad || !item.valorreferencia) {
            valid = false;
          }
        } else {
          if (item.valorneto === 0 || !item.valorneto) {
            valid = false;
          }
        }
      });

      if (!valid) {
        errorMessages.push("Tienes campos pendientes por completar.");
      }

      //Valores cargos
      if (valorbase < valorcargosaplicados) {
        errorMessages.push(
          "El valor de los cargos no puede ser superior a la factura con descuentos"
        );
      }
    }

    if (errorMessages.length > 0) {
      let message = errorMessages.join("  ");
      WarningAlert("Upss...!", message);
      return false;
    }

    return true;
  };

  /**
   * Envia documento para registro como cotización
   * @param {*} e
   */
  const handleSaveQuotation = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      const deleteData = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          let document = generateRequestQuotation(documentdata, totals);

          if (editQuotation) {
            dispatch(
              editQoutation(
                document,
                readResponseUpdateQuotation,
                readErrorSendQuotation
              )
            );
          } else {
            dispatch(
              createQoutation(
                document,
                readResponseSendQuotation,
                readErrorSendQuotation
              )
            );
          }
        }
      };
      ConfirmAlert(
        `¿Estás seguro de guardar esta factura como una cotización?`,
        deleteData,
        MESSAGE_NEXT_CONFIRM
      );
    }
  };

  /**
   * Genera previsualización de documento
   * @param {*} e
   */
  const handlePreviewDocument = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      setOpenModalFile(true);
      setLoadingFile(true);
      let document = generateRequestQuotation(documentdata, totals);
      dispatch(getDocumentPreviewAction(document, readResponseGetFile));
    }
  };

  /**
   * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response);
    setLoadingFile(false);
  };

  /**
   * Envia documento para registro
   */
  const handleConfirmNumeration = (numeration) => {
    if (validateDocument(true)) {
      const deleteData = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          let document = generateRequestDocument(
            documentdata,
            totals,
            numeration
          );

          dispatch(
            createElectronicInvoice(
              document,
              readResponseSendInvoice,
              readErrorSendInvoice
            )
          );
        }
      };

      ConfirmAlert(
        `¿Estás seguro de guardar y enviar la factura?`,
        deleteData,
        MESSAGE_NEXT_CONFIRM
      );
    }
  };

  const readResponseSendInvoice = (response) => {
    setLoading(false);
    setModalResponse({
      type: "success",
      title: "Fenomenal!!!",
      subtitle: "Has generado una nueva factura",
      body: (
        <div>
          <div>
            {response.data.statusMessage.indexOf("Rechazo") !== -1
              ? "Factura generada con error al enviar a Dian"
              : "Proceso Exitoso"}{" "}
          </div>
          <div class="fenomenalWhiteLittle">
            {response.data.statusMessage}{" "}
            <span className="fenomenalBlueLittle">
              No. {response.data.result}
            </span>
          </div>
        </div>
      ),
      modalImage: "success",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 2,
      closeFromModalBody: true,
    });
  };

  const readResponseSendQuotation = (response) => {
    setLoading(false);
    setModalResponse({
      type: "success",
      title: "Perfecto!!!",
      subtitle: "Has generado una nueva cotización",
      body: (
        <div>
          <div>
            Perfecto, tenemos un cliente interesado, hay que convencerlo.
          </div>
          <div class="fenomenalWhiteLittle">
            {response.data.statusMessage} Se genero exitosamente la cotización{" "}
            <span className="fenomenalBlueLittle">
              No. {response.data.result}
            </span>
          </div>
        </div>
      ),
      modalImage: "quotation",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 2,
      closeFromModalBody: true,
    });
  };

  const readResponseUpdateQuotation = (response) => {
    setLoading(false);
    setModalResponse({
      type: "success",
      title: "Perfecto!!!",
      subtitle: "Has modificado una cotización",
      body: (
        <div>
          <div>
            Perfecto, tenemos un cliente interesado, hay que convencerlo.
          </div>
          <div class="fenomenalWhiteLittle">
            {response.data.statusMessage} Se editado exitosamente la cotización{" "}
            <span className="fenomenalBlueLittle">
              No. {response.data.result}
            </span>
          </div>
        </div>
      ),
      modalImage: "quotation",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 2,
      closeFromModalBody: true,
    });
  };

  const readErrorSendInvoice = (response) => {
    setLoading(false);
    const statusCode = response.data.statusCode;
    const statusMessage = response.data.statusMessage;
    if (statusCode === "404" && statusMessage === "Unauthorized, no quota") {
      setModalResponse({
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    } else {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>
              {JSON.stringify(
                response === undefined
                  ? "Error inesperado"
                  : response.data.statusMessage
              )}
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    }
  };

  const readErrorSendQuotation = (response) => {
    setLoading(false);
    setModalResponse({
      type: "warning",
      title: "Upss...!!!",
      subtitle: "Ocurrió algo inesperado",
      body: (
        <div>
          <div>
            {JSON.stringify(
              response === undefined
                ? "Error inesperado"
                : response.data.statusMessage
            )}
          </div>
        </div>
      ),
      modalImage: "warning",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 0,
      closeFromModalBody: true,
    });
  };

  const handleResponseModal = (rta) => {
    if (rta === 2) {
      HandleBack();
    }
    setModalResponse({
      type: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: "",
      closeFromModalBody: false,
    });
  };

  /**
   * Cierra modal numeraciones
   */
  const handleCloseModalResolution = () => {
    setOpenModalResolution(false);
  };

  /**
   * Cierra modal numeraciones
   */
  const handleOpenModalResolution = () => {
    setOpenModalResolution(true);
  };

  /**
   * Cierra modal previsualizació documento
   */
  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
  };

  const footPage = (step) => {
    return (
      <>
        {step === 0 ? (
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <div className="bottomButtonsInvoiceRevision">
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleStandOut}
                    >
                      {favorito ? <StarIcon /> : <StarBorderIcon />}
                      <span style={{ marginTop: 5 }}>Favorito</span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleCancelDocument}
                    >
                      <ClearIcon />
                      <span style={{ marginTop: 5 }}>Cancelar</span>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <div className="bottomButtonsInvoiceRevision">
                <Grid container spacing={1}>
                  <Grid item lg={3} xs={3} style={{ textAlign: "left" }}>
                    <IconButton
                      classes={{
                        root: classes.button,
                        label: classes.labelHorizontal,
                      }}
                      variant="raised"
                      disableRipple={true}
                      onClick={() => handleBackStep(step)}
                      style={{ marginLeft: 10, marginTop: 10 }}
                    >
                      <ArrowBackIosIcon />
                      <span style={{ marginLeft: 5, fontSize: 14 }}>
                        Volver
                      </span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleStandOut}
                    >
                      {favorito ? <StarIcon /> : <StarBorderIcon />}
                      <span style={{ marginTop: 5 }}>Favorito</span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={3} xs={3}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handlePreviewDocument}
                    >
                      <VisibilityIcon />
                      <span style={{ marginTop: 5 }}>Previsualizar</span>
                    </IconButton>
                  </Grid>

                  <Grid item lg={3} xs={3}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleCancelDocument}
                      style={{ marginRight: 10 }}
                    >
                      <ClearIcon />
                      <span style={{ marginTop: 5 }}>Cancelar</span>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2F2E2E",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),
    },
  }))(Tooltip);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <DocumentManage
              handleChangeStep={handleNextStep}
              typedocument={"CO"}
            />
            {footPage(0)}
          </>
        );
      case 1:
        return (
          <>
            <DocumentCartManage typedocument={"CO"} />
            {!showCatalog && (
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xl={11} lg={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} xs={12}>
                      <HtmlTooltip
                        title={
                          READING_MODE ? <TooltipMessageReadingMode /> : ""
                        }
                      >
                        <span>
                          {editQuotation !== true && (
                            <ButtonPrimary
                              type="button"
                              onClick={handleOpenModalResolution}
                              text={"Enviar factura"}
                              disabled={READING_MODE}
                            />
                          )}
                        </span>
                      </HtmlTooltip>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={() => handleNextStep(2)}
                        text={"Revisar cotización"}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={handleSaveQuotation}
                        text={"Enviar cotización"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {footPage(1)}
          </>
        );
      case 2:
        return (
          <>
            <DocumentReview typedocument={"CO"} />
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xl={11} lg={12} sm={12}>
                <Grid container spacing={1} style={{ textAlign: "right" }}>
                  <Grid item lg={4} xs={12}>
                    <HtmlTooltip
                      title={READING_MODE ? <TooltipMessageReadingMode /> : ""}
                    >
                      <span>
                        {editQuotation !== true && (
                          <ButtonPrimary
                            type="button"
                            onClick={handleOpenModalResolution}
                            text={"Guardar como factura"}
                            disabled={READING_MODE}
                          />
                        )}
                      </span>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <ButtonPrimary
                      type="button"
                      onClick={handleSaveQuotation}
                      text={"Enviar cotización"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {footPage(2)}
          </>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <div className="container-form" xs={12} lg={12}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.root}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              className={activeStep === index ? classes.activeButton : ""}
            >
              <StepButton completed={isStepComplete(index)} {...buttonProps}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className="stepper-title">{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={
          "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
        }
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />

      <ArchiveModal
        loading={loadingFile}
        open={openModalFile}
        pdfBase64={base64File}
        handleClose={handleCloseFileModal}
      />

      <CustomModal
        onClose={handleCloseModalResolution}
        open={openModalResolution}
        component={
          <NumerationInvoice handleConfirm={handleConfirmNumeration} />
        }
        title={"Resolución"}
        width={600}
        handleOpenModalResolution={true}
      />
    </div>
  );
};

export default CreateQuotationView;
