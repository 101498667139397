import React, {  } from 'react';
import PropTypes from 'prop-types'
import { Grid, IconButton } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY:'initial'
    },
    paper: {
        maxHeight: 700,
        backgroundColor: '#272727',
        border: 'none',
        borderRadius: '5px',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: theme.palette.primaryColor,
        outline:0,
    },
    paperWhite: {
        maxHeight: 700,
        backgroundColor: '#FFFFFF',
        border: 'none',
        borderRadius: '5px',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: '#272727',
        outline:0,
    },
    iconClose: {
        color: theme.palette.thirdColor,
        padding: 0,
    },
    buttonClose: {
        textAlign: 'end',
        padding: 8
    },
    title: {
        textAlign: 'start',
        padding: 10,
        fontWeight : "bold"
    }
}));

const PopoverModal = ({ component: Component, ...props }) => {
    const classes = useStyles();
    const { open, onClose, title, width, showCloseButton, disabled, noPadding, popoverAnchorEl, handleClose, modalWarning = false, anchorPosition=null } = props

    return (
        <Popover 
          className={classes.modal} 
          open={open}  
          anchorEl={anchorPosition != null ? null : popoverAnchorEl}
          anchorPosition={anchorPosition != null ? anchorPosition : null}
          anchorReference={anchorPosition != null ? "anchorPosition": null}
          onClose={handleClose}
          anchorOrigin={modalWarning ? {
            vertical: 'bottom',
            horizontal: 'left',
          }: {
            vertical: 'top',
            horizontal: 'center',  
          }}
          transformOrigin={modalWarning ? {
            vertical: 'top',
            horizontal: 'right',
          } : {
            vertical: 'top',
            horizontal: 'center',
          }}>
            <div 
            className={modalWarning ? classes.paperWhite : classes.paper} 
            style={{
                 width:!!width? width:  600 ,
                 padding: noPadding === true ? 0:'5px 9px 9px'
                 }} >
                <Grid container>
                    <Grid item lg={10} xs={10} className={classes.title}>
                        {title}
                    </Grid>
                    <Grid item lg={2} xs={2} className={classes.buttonClose}>
                        {showCloseButton === false ?
                            '' :
                            <IconButton className={classes.iconClose} onClick={onClose}  disabled={disabled} >
                                <CloseIcon style={{fontSize:18}} />
                            </IconButton>
                        }
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container>
                            <Component />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Popover>
    );
}

PopoverModal.propTypes = {
    open: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired,
}


export default PopoverModal;