import React from "react";

import { DocumentCartButtons } from "../components/createEquivalentDocument/DocumentCartButtons";
import { DocumentCartManage } from "../../document/cart/documentCartManage.view";
import { EquivalentDocumentForm } from "../components/createEquivalentDocument";
import { FooterEquivalentDocForm } from "../components/createEquivalentDocument/stepFooter";
import { ButtonIconRenderData } from "./";

export const getStepContent = (stepIndex, showCatalog, typedocument = "DE") => {
  const { renderIconStepOne, renderIconStepTwo, renderIconStepThree } = ButtonIconRenderData(typedocument);

  const renderFooterIsCatalog = !showCatalog ? renderIconStepThree : renderIconStepTwo;

  const steps = {
    0: {
      content: (
        <EquivalentDocumentForm>
          <FooterEquivalentDocForm renderIcon={renderIconStepOne} />
        </EquivalentDocumentForm>
      ),
    },
    1: {
      content: (
        <div className="animate__animated animate__fadeInRight animate__faster">
          <DocumentCartManage typedocument={typedocument} />
          {!showCatalog && <DocumentCartButtons typedocument={typedocument} />}
          <FooterEquivalentDocForm renderIcon={renderFooterIsCatalog} />
        </div>
      ),
    },
    // 2: {
    //   content: "Revisar documento",
    // }, //! Pendiente de implementar
  };

  return steps[stepIndex] || {};
};
