import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  styleCard: {
    backgroundColor: theme.palette.ninethBackgroundColor,
    borderRadius: "5px",
    color: theme.palette.primaryColor,
    border: "none",
    margin: 10,
    "& .MuiCardContent-root": {
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
    },
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    fontWeight: 500,
  },
  content: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  containerValue: {
    display: "grid",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    height: "100%",
    minHeight: 50,
  },
  label: {
    fontSize: 11,
    color: "rgba(255, 255, 255, 0.5)",
  },
  value: {
    color: "white",
    fontWeight: 500,
    fontSize: 16,
  },

  line: {
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    height: 0.1,
    margin: "1px 0px",
  },
}));

export const DianDataCard = React.memo(
  ({
    Fecha,
    MedioPago,
    FormaPago,
    FechaVencimiento,
    FechaAceptacion,
    Observaciones,
    Codigounico,
    MotivoRechazo,
  }) => {
    const classes = useStyles();

    return (
      <Card
        className={`${classes.styleCard} animate__animated animate__fadeIn animate__faster`}
      >
        <CardContent className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xl={4} lg={4} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Fecha Factura</span>
                <span className={classes.value}>
                  {!!Fecha ? format(Fecha, "dd/MM/yyyy hh:mm aaaa") : ""}
                </span>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Forma de Pago</span>
                <span className={classes.value}>{FormaPago ?? ""}</span>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Fecha de Pago</span>
                <span className={classes.value}>
                  {!!FechaVencimiento && FormaPago === "Crédito"
                    ? format(FechaVencimiento, "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
            </Grid>
            <Grid item xl={8} lg={8} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Medio Pago</span>
                <span className={classes.value}>{MedioPago ?? ""}</span>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Fecha Aceptación/Rechazo</span>
                <span className={classes.value}>
                  {!!FechaAceptacion
                    ? format(FechaAceptacion, "dd/MM/yyyy hh:mm aaaa")
                    : "-"}
                </span>
              </div>
            </Grid>
            <Grid item xl={12} lg={12} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Concepto rechazo</span>
                <span className={classes.value}>{MotivoRechazo || "-"}</span>
              </div>
            </Grid>
            <Grid item xl={12} lg={12} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Código único</span>
                <span
                  className={classes.value}
                  style={{ wordBreak: "break-all" }}
                >
                  {Codigounico}
                </span>
              </div>
            </Grid>
            <Grid item xl={12} lg={12} xs={12}>
              <div className={classes.containerValue}>
                <span className={classes.label}>Observaciones</span>
                <span className={classes.value}>{Observaciones}</span>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);
