import { axiosApiInstance } from "../config/axios-instance";
import {
  API_ENDPOINT_CUSTOMERS,
  defaultHeaders,
  QUANTITY_DATA_CUSTOMER,
} from "../config/config";
import { SuccessAlert } from "../helpers/alert.helpers";
import { filterCustomerList } from "../helpers/filterList";
import { orderByDate, orderByName } from "../helpers/orderCustomerList";
import { types } from "../types/types";
import { enterpriseIdHeader } from "./authActions";
import { loadCustomerDocument } from "./documentAction";
import {
  TIME_CREATE_REGISTER,
  TIME_UPDATE_REGISTER,
} from "../config/config.js";

const urlEndPoint = `${API_ENDPOINT_CUSTOMERS}/customer/api/Cliente`;

/**
 * Consulta listado clientes
 * @returns
 */
export const getCustomersAction = (
  consultFirst,
  type,
  refresh = false
) => async (dispatch, getState) => {
  try {
    const { customersList } = getState().customerReducer;
    if (customersList.length > 0 && refresh === false) {
      dispatch({
        type: types.REFRESH_CURRENT_CUSTOMERS,
        payload: customersList.slice(0, QUANTITY_DATA_CUSTOMER),
      });
      return;
    }

    changeStatusLoading(dispatch, true);
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      EnterpriseId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.get(
      `${urlEndPoint}${"/GetCustomerSummary"}`,
      myConfig
    );
    let customers = filterByCustomerType(resp.data.result, type);

    if (consultFirst === true && customers.length > 0) {
      await loadFirstCustomer(customers[0].id, dispatch);
    }

    dispatch({
      type: types.GET_CUSTOMERS_LIST,
      payload: customers,
    });

    dispatch({
      type: types.REFRESH_CURRENT_CUSTOMERS,
      payload: customers.slice(0, QUANTITY_DATA_CUSTOMER),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar listado clientes.",
        error: err,
      },
    });
  } finally {
    changeStatusLoading(dispatch, false);
  }
};

/**
 * Carga id de cliente seleccionado
 * @returns
 */
export const setCustomerIdAction = (id) => (dispatch) => {
  dispatch({
    type: types.SET_CUSTOMER_ID,
    payload: id,
  });
};

/**
 * Guarda nuevo cliente
 * @param {*} customer
 * @returns
 */
export const saveCustomerAction = (
  customer,
  completeSave,
  loadForDocument = false,
  type = 0
) => async (dispatch, getState) => {
  try {
    dispatch(changeSaveLoading(true));
    customer = { ...customer, empresaId: `${enterpriseIdHeader()}` };
    const resp = await axiosApiInstance.post(
      `${urlEndPoint}${"/CreateCustomer"}`,
      customer,
      defaultHeaders()
    );

    await updateCustomerList(
      resp.data.result.id ?? 0,
      dispatch,
      getState,
      loadForDocument,
      type
    );
    SuccessAlert(
      null,
      "Creaste un nuevo cliente de manera exitosa, sigamos Facturando",
      TIME_CREATE_REGISTER
    );
    completeSave();
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido registrar cliente.",
        error: err,
      },
    });
  } finally {
    dispatch(changeSaveLoading(false));
  }
};

/**
 * Actualiza información cliente
 * @param {}} customer
 * @param {*} reset
 * @param {*} collapse
 * @returns
 */
export const updateCustomerAction = (
  customer,
  completeUpdate,
  loadForDocument = false,
  type = 0
) => async (dispatch, getState) => {
  try {
    dispatch(changeSaveLoading(true));

    customer = { ...customer, empresaId: `${enterpriseIdHeader()}` };
    await axiosApiInstance.put(
      `${urlEndPoint}${"/UpdateCustomer"}`,
      customer,
      defaultHeaders()
    );

    await updateCustomerList(
      customer.Id ?? 0,
      dispatch,
      getState,
      loadForDocument,
      type
    );
    SuccessAlert(
      null,
      `Actualizaste el cliente ${customer.RazonSocial} de manera exitosa, sigamos Facturando`,
      TIME_UPDATE_REGISTER
    );
    completeUpdate();
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar cliente.",
        error: err,
      },
    });
  } finally {
    dispatch(changeSaveLoading(false));
  }
};

/**
 * Actualiza listado clientes despues de agregar o actualizar
 * @param {*} customer
 * @param {*} currentCustomerList
 * @param {*} customersList
 * @param {*} dispatch
 */
const updateCustomerList = async (
  id,
  dispatch,
  getState,
  loadForDocument,
  type
) => {
  let { customersList, currentCustomerList } = getState().customerReducer;

  const oldCustomer = customersList.find((c) => c.id === id);
  customersList = customersList.filter((c) => c.id !== id);
  const newcustomer = await getCustomerById(id);
  if (!!newcustomer) {
    const detail = {
      id: newcustomer.id,
      documento: newcustomer.documento,
      razonsocial: newcustomer.razonsocial,
      text: newcustomer.razonsocial,
      favorito: newcustomer.favorito,
      fechaReciente: oldCustomer?.fechaReciente ?? null, //Debe llevar condicional
    };

    const end = currentCustomerList.length;
    customersList.push(detail);
    if (type !== 2) {
      customersList = orderByName(customersList);
    } else {
      customersList = orderByDate(customersList);
    }

    dispatch({
      type: types.GET_CUSTOMERS_LIST,
      payload: customersList,
    });

    dispatch({
      type: types.REFRESH_CURRENT_CUSTOMERS,
      payload: customersList.slice(0, end),
    });

    dispatch({
      type: types.SET_CUSTOMER_ID,
      payload: newcustomer.id,
    });

    if (loadForDocument === true) {
      dispatch({
        type: types.LOAD_CUSTOMER_DOCUMENT,
        payload: { ...newcustomer, inactiveEdit: true },
      });
    } else {
      dispatch({
        type: types.LOAD_CUSTOMER_DETAIL,
        payload: newcustomer,
      });
    }
  }
};

/**
 * Cambia estado loading action Save o Update
 * @param {*} dispatch
 * @param {*} status  Estado loading
 */
function changeSaveLoading(status) {
  return {
    type: types.LOADING_SAVE_CUSTOMER,
    payload: status,
  };
}

/**
 * Obtiene pagina siguiente listado clientes
 * @param {*} start Inicio pagina
 * @param {*} end Fin pagina
 * @param {*} filter Filtro
 * @returns
 */
export const getNextPageAction = (start, end, filter) => (
  dispatch,
  getState
) => {
  const { customersList, currentCustomerList } = getState().customerReducer;
  let customers = filterCustomerList(filter, customersList);
  customers = currentCustomerList.concat(customers.slice(start, end));

  dispatch({
    type: types.REFRESH_CURRENT_CUSTOMERS,
    payload: customers,
  });
};

/**
 * Filtra listado clientes
 * @param {*} filter
 * @returns
 */
export const filterCustomersAction = (filter) => (dispatch, getState) => {
  const { customersList } = getState().customerReducer;
  const customers = filterCustomerList(filter, customersList);
  dispatch({
    type: types.REFRESH_CURRENT_CUSTOMERS,
    payload: customers.slice(0, QUANTITY_DATA_CUSTOMER),
  });
};

export const getCustomerById = async (id) => {
  let myConfig = defaultHeaders();
  Object.assign(myConfig.headers, { pId: `${id}` });
  const resp = await axiosApiInstance.get(
    `${urlEndPoint}${"/GetClientById"}`,
    myConfig
  );
  return resp.data.result;
};

/**
 * Consulta detalle cliente
 */
export const getCustomerDetailAction = (id) => async (dispatch) => {
  try {
    if (id === null) {
      dispatch({
        type: types.LOAD_CUSTOMER_DETAIL,
        payload: null,
      });
      return;
    }

    changeStatusLoadingGetDetail(dispatch, true);
    const customer = await getCustomerById(id);
    dispatch({
      type: types.LOAD_CUSTOMER_DETAIL,
      payload: customer,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar información de cliente.",
        error: err,
      },
    });
  } finally {
    changeStatusLoadingGetDetail(dispatch, false);
  }
};

/**
 * Carga cliente en generación nuevo documento
 * @returns
 */
export const addCustomerInDocument = (id) => async (dispatch) => {
  try {
    changeStatusLoadingGetDetail(dispatch, true);
    const customer = await getCustomerById(id);
    dispatch(loadCustomerDocument({ ...customer, inactiveEdit: false }));

    dispatch({
      type: types.LOAD_ADDRESSS_CUSTOMER,
      payload: null,
    });

    dispatch({
      type: types.LOAD_CONTACT_CUSTOMER,
      payload: null,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar información de cliente.",
        error: err,
      },
    });
  } finally {
    changeStatusLoadingGetDetail(dispatch, false);
  }
};

/**
 * Consulta información de cliente y carga información en redux
 */
export const getCustomerForForm = (id) => async (dispatch) => {
  try {
    if (id === null) {
      dispatch({
        type: types.ADD_CUSTOMER_ACTIVE,
        payload: null,
      });
      return;
    }

    dispatch(changeStatusLoadingGetActive(true));
    const customer = await getCustomerById(id);
    dispatch({
      type: types.ADD_CUSTOMER_ACTIVE,
      payload: customer,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar información de cliente.",
        error: err,
      },
    });
  } finally {
    dispatch(changeStatusLoadingGetActive(false));
  }
};

/**
 * Carga cliente activo en redux
 * @param {*} customer
 * @returns
 */
export const loadCustomerActive = (customer) => (dispatch) => {
  dispatch({
    type: types.ADD_CUSTOMER_ACTIVE,
    payload: customer,
  });
};

/**
 * Cambia valor loading proceso obtener cliente para formulario
 * @param {*} status
 * @returns
 */
const changeStatusLoadingGetActive = (status) => {
  return {
    type: types.LOADING_GET_CUSTOMER_ACTIVE,
    payload: status,
  };
};

/**
 * Carga detalle cliente en documento
 * @param {*} customer
 * @returns
 */
export const loadCustomerForDocumentAction = (customer) => (dispatch) => {
  dispatch(
    loadCustomerDocument({
      ...customer,
      inactiveEdit: false,
    })
  );
  dispatch({
    type: types.SET_CUSTOMER_ID,
    payload: customer.id,
  });
};

/**
 * Actualiza estado favorito de un cliente
 * @param {int} id
 */
export const standOutCustomerAction = (id) => async (dispatch, getState) => {
  try {
    changeStatusLoadingStandOut(dispatch, true);
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      Id: id,
    });

    const resp = await axiosApiInstance.put(
      `${urlEndPoint}${"/UpdateStandOut"}`,
      "",
      myConfig
    );
    if (!!resp) {
      dispatch({
        type: types.STAND_OUT_CUSTOMER,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cambiar estado de cliente.",
        error: err,
      },
    });
  } finally {
    changeStatusLoadingStandOut(dispatch, false);
  }
};

/**
 * Limpia información  de cliente en redux
 * @returns
 */
export const cleanCustomerDataAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_CUSTOMERS_DATA,
  });

  dispatch({
    type: types.CLEAN_CUSTOMER_ADDRESS,
  });

  dispatch({
    type: types.CLEAN_CUSTOMER_CONTACT,
  });
};

/**
 * Borrado logico cliente
 * @method PUT
 * @param {*} id Identificador cliente
 */
export const deleteCustomerAction = (id, completeDelete) => async (
  dispatch,
  getState
) => {
  try {
    changeLoadingDelete(dispatch, true);
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      Id: id,
      Status: 2,
    });

    const resp = await axiosApiInstance.put(
      `${urlEndPoint}${"/UpdateStatus"}`,
      "",
      myConfig
    );
    if (!!resp) {
      updateAfterDelete(id, dispatch, getState);
      completeDelete();
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido eliminar cliente.",
        error: err,
      },
    });
  } finally {
    changeLoadingDelete(dispatch, false);
  }
};

/**
 * Consulta y carga información de primer cliente
 * @param {*} customer
 */
const loadFirstCustomer = async (id, dispatch) => {
  const customer = await getCustomerById(id);
  dispatch({
    type: types.LOAD_CUSTOMER_DETAIL,
    payload: customer,
  });

  dispatch({
    type: types.SET_CUSTOMER_ID,
    payload: customer.id,
  });
};

/**
 * Actualiza  ventana clientes, despues de borrar
 * @param {*} id Identificador cliente a borrar
 * @param {*} dispatch
 * @param {*} getState
 */
const updateAfterDelete = async (id, dispatch, getState) => {
  let { currentCustomerList, customerActive } = getState().customerReducer;

  //Carga información de primer cliente en la lista
  currentCustomerList = currentCustomerList.filter((c) => c.id !== id);
  if (currentCustomerList.length > 0)
    await loadFirstCustomer(currentCustomerList[0].id, dispatch);

  //Borra informacion de formulario
  if (!!customerActive) {
    dispatch({
      type: types.ADD_CUSTOMER_ACTIVE,
      payload: null,
    });

    dispatch({
      type: types.CLEAN_CUSTOMER_ADDRESS,
    });

    dispatch({
      type: types.CLEAN_CUSTOMER_CONTACT,
    });
  }

  //Actualiza listado
  dispatch({
    type: types.DELETE_CUSTOMER,
    payload: id,
  });
};

const changeStatusLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_CUSTOMERS,
    payload: status,
  });
};

export const changeStatusLoadingGetDetail = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_CUSTOMER_DOCUMENT,
    payload: status,
  });
};

const changeStatusLoadingStandOut = (dispatch, status) => {
  dispatch({
    type: types.LOADING_STAND_OUT_CUSTOMERS,
    payload: status,
  });
};

const changeLoadingDelete = (dispatch, status) => {
  dispatch({
    type: types.LOADING_DELETE_CUSTOMER,
    payload: status,
  });
};

/**
 * Filtra listado clientes por tipo
 * @param {Array} list lista a filtrar
 */
const filterByCustomerType = (list, type) => {
  if (!list || list?.length === 0) return [];

  switch (type) {
    case 0:
      return orderByName(list);
    case 1:
      return orderByName(list.filter((c) => c.favorito === true));
    case 2:
      return orderByDate(list);
    default:
      return list;
  }
};
