/**
 * * Limita el número de decimales e enteros en un valor dado.
 *
 * @param {string} value - El valor que se va a limitar.
 * @param {number} totalDecimals - El número total de decimales permitidos.
 * @param {number} totalIntegers - El número total de dígitos enteros permitidos.
 * @returns {string} El valor limitado con la cantidad específica de decimales e enteros.
 */
export const limitDecimalsAndIntegers = (
  value,
  totalDecimals,
  totalIntegers
) => {
  if (totalDecimals > 0 && value.includes(".")) {
    const parts = value.split(".");
    if (parts[1] && parts[1].length > totalDecimals) {
      parts[1] = parts[1].substring(0, totalDecimals);
      value = parts.join(".");
    }
  }

  const integerPart = value.split(".")[0];
  if (integerPart.length > totalIntegers) {
    value = value.substring(0, totalIntegers);
  }

  return value;
};
