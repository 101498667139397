import React, { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import CustomTooltip from '../tooltip/tooltip.component';
import { Grid } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        '& .MuiFormGroup-root': {
            justifyContent: 'space-between',
            padding: '5px 10px'
        }

    },
    label: {
        color: theme.palette.primaryColor,
        fontSize: 12,
        marginTop: 15,

        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.primaryColor
        }
    },

}));

const CustomRadio = withStyles((theme) => ({
    root: {
        color: 'gray',
        '&$checked': {
            color: theme.palette.secundaryColor
        },
        '& .MuiFormControlLabel-labelPlacementStart': {
            marginLeft: 0,
            marginRight: 5
        }

    },
    checked: {},
}))((props) => <Radio  {...props} />);


/**
 * @Component
 * @description Radio Button Group
 * @param {Identificador en DOM}  id
 * @param {Texto de label descipción}  label
 * @param {Evento cambio de seleccion}  onChange
 * @param {Listado de opciones--> [{value: '1', text: 'uno'}, {value: '2', text: 'dos'}]}  options
 * @param {Instancia simple react validator}  validator
 * @param {Opciones validador}  validateOptions
 * @param {Nombre de Validador}  nameValidator
 */

function RadioButtonGroup(props) {
    const classes = useStyles();
    const {  label, value, onChange, options, validator, validateOptions, nameValidator, name, disabled, txtTooltip } = props;

    let errorValidator = validator
        ? validator.message(
            !!nameValidator ? nameValidator : label,
            value,
            validateOptions
        )
        : "";

    return (
        <FormControl
            name={name}
            error={!!errorValidator ? true : false}
            className={classes.formControl}
            disabled={disabled === true ? true : false} >
            {txtTooltip ? (
                <Fragment>
                    <RadioGroup row aria-label="position" name={name} value={value} onChange={onChange}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={5}>
                                <FormLabel className={classes.label} >{label}</FormLabel>
                            </Grid>
                            <Grid item xs={1}>
                                <CustomTooltip message={txtTooltip} />
                            </Grid>
                            <Grid item xs={6}>
                                {options.map((item, index) =>
                                    <FormControlLabel
                                        key={index}
                                        value={item.value}
                                        control={<CustomRadio />}
                                        label={item.text}
                                        labelPlacement="start" />
                                )}
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    <FormHelperText>{errorValidator}</FormHelperText>
                </Fragment>
            ) : (
                    <Fragment>
                        <RadioGroup row aria-label="position" name={name} value={value} onChange={onChange}>
                            <FormLabel className={classes.label} >{label}</FormLabel>
                            {options.map((item, index) =>
                                <FormControlLabel
                                    key={index}
                                    value={item.value}
                                    control={<CustomRadio />}
                                    label={item.text}
                                    labelPlacement="start" />
                            )}
                        </RadioGroup>
                        <FormHelperText>{errorValidator}</FormHelperText>
                    </Fragment>
                )}
        </FormControl>
    );
}

RadioButtonGroup.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string
}


export default RadioButtonGroup;
