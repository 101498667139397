import isEmpty from "../utils/isEmpty";
import {
  homologatePaymentMethod,
  homologateRejectReason,
  homologateTypeDocument,
  homologateTypeIdentification,
  homologateTypeRegimen,
  homologateWatToPay,
} from "./catalogDian";

/**
 * Lee detalle documento
 */
export const readDetailDocument = (data) => {
  let object = JSON.parse(data.objeto ?? {});
  data = {
    ...data,
    ...object,
    objeto: null,
  };

  if (!!data.DatosNota) {
    data = {
      ...data,
      DatosFactura: data.DatosNota,
    };
  }

  //Leer linea detalle
  let LineaDetalle = [];
  let dataFactura =
    data?.DatosFactura?.LineaDetalle ??
    data?.DatosDocumento?.LineaDetalle ??
    [];
  for (let i = 0; i < dataFactura?.length; i++) {
    const item = dataFactura[i];

    const tributoiva =
      item?.Tributos?.find((c) => c?.Tributo?.Codigo === "01") ?? null;
    const iva =
      tributoiva?.SubtotalPorcentaje?.length > 0
        ? tributoiva?.SubtotalPorcentaje[0]?.Porcentaje
        : 0;

    const tributoinc =
      item?.Tributos?.find((c) => c?.Tributo?.Codigo === "02") ?? null;
    const inc =
      tributoinc?.SubtotalPorcentaje?.length > 0
        ? tributoinc?.SubtotalPorcentaje[0]?.Porcentaje
        : 0;

    let prefijo = item?.Referencia?.Descripcion ?? "";
    prefijo = prefijo.length >= 2 ? prefijo.substring(0, 2).toUpperCase() : "";
    let descuentos = item?.Descuentos ?? [];
    let valordescuentos = 0;
    for (let i = 0; i < descuentos.length; i++) {
      valordescuentos += descuentos[i].Valor;
    }

    const newItem = {
      Cantidad: item?.Referencia?.Cantidad ?? 0,
      Descripcion: item?.Referencia?.Descripcion ?? "",
      Prefijo: prefijo,
      ValorTotal: item?.Referencia?.ValorTotal ?? 0,
      ValorUnitario: item?.Referencia?.ValorUnitario ?? 0,
      PorcetanjeIva: iva,
      PorcetanjeInc: inc,
      Color: getRandomColor(),
      ValorDescuentos: valordescuentos,
    };
    LineaDetalle.push(newItem);
  }

  //Leer Cargos
  let Cargos = [];
  for (let i = 0; i < data?.DatosFactura?.Cargos?.length; i++) {
    const item = data?.DatosFactura?.Cargos[i];
    Cargos.push(item);
  }

  //Leer Descuentos
  let Descuentos = [];
  for (let i = 0; i < data?.DatosFactura?.Descuentos?.length; i++) {
    const item = data?.DatosFactura?.Descuentos[i];
    Descuentos.push(item);
  }

  const vendedor = data?.DatosAdicionales?.find(
    (c) => c.Codigo === "NOMBREVENDEDOR"
  )?.Valor;
  const observaciones =
    data?.DatosFactura?.Nota?.length > 0 ? data?.DatosFactura?.Nota[0] : "";

  return {
    Id: data.documentoid ?? 0,
    Numero: data?.DatosFactura?.Numero ?? data?.numero ?? "",
    Fecha: !!data?.DatosFactura?.Fecha
      ? new Date(data?.DatosFactura?.Fecha)
      : new Date(data?.fecha),
    MedioPago: homologatePaymentMethod(
      data?.DatosFactura?.MetodoPago?.MedioPago ??
        data?.DatosDocumento?.MetodoPago?.MedioPago
    ),
    FormaPago: homologateWatToPay(
      data?.DatosFactura?.MetodoPago?.FormaPago ??
        data?.DatosDocumento?.MetodoPago?.FormaPago
    ),
    FechaVencimiento: !!data?.DatosFactura?.MetodoPago?.FechaVencimiento
      ? new Date(data?.DatosFactura?.MetodoPago?.FechaVencimiento)
      : !!data?.DatosDocumento?.MetodoPago?.FechaVencimiento
      ? new Date(data?.DatosDocumento?.MetodoPago?.FechaVencimiento)
      : null,
    Codigounico: data?.codigounico ?? "",
    TipoDocumentoEmisor: data?.DatosEmisor?.TipoDocumento ?? "",
    DocumentoEmisor: data?.DatosEmisor?.Documento ?? "",
    NombreEmisor: data?.DatosEmisor?.Nombre ?? "",
    NombreAdquiriente: data?.DatosAdquiriente?.Nombre ?? "",
    TipoDocumentoAdquiriente: homologateTypeIdentification(
      data?.DatosAdquiriente?.TipoDocumento ?? ""
    ),
    CodigoDocumentoAdquiriente: data?.DatosAdquiriente?.TipoDocumento ?? "",
    DocumentoAdquiriente: data?.DatosAdquiriente?.Documento ?? "",
    TipoRegimenAdquiriente: homologateTypeRegimen(
      data?.DatosAdquiriente?.RegimenFiscal ?? ""
    ),
    CorreoElectronicoAdquiriente:
      data?.DatosAdquiriente?.DatosContacto?.CorreoElectronico ?? "-",
    NumeroTelefonicoAdquiriente:
      data?.DatosAdquiriente?.DatosContacto?.NumeroTelefonico ?? "-",
    DireccionAquiriente:
      data?.DatosAdquiriente?.DireccionFisica?.Direccion ?? "-",
    DepartamentoAquiriente:
      data?.DatosAdquiriente?.DireccionFisica?.Departamento?.Nombre ?? "",
    MunicipioAquiriente:
      data?.DatosAdquiriente?.DireccionFisica?.Municipio?.Nombre ?? "",
    Favorito: data?.favorito ?? false,
    Valorapagar: data?.valorapagar ?? 0,
    Vendedor: isEmpty(vendedor) ? "" : vendedor,
    Fechaacusedian: !!data?.fechaacusedian
      ? new Date(data?.fechaacusedian)
      : null,
    Fechaacuseadq: !!data?.fechaacuseadq ? new Date() : null,
    Descripcionestadodian: `${data?.descripcionestadodian ??
      ""} ${data?.conceptodian ?? ""}`,
    Observaciones: isEmpty(observaciones) ? "" : observaciones,
    LineaDetalle: LineaDetalle,
    Cargos: Cargos,
    Descuentos: Descuentos,
    TipoDocumentoElectronicocodigo: data?.tipodocumentoelectronicocodigo ?? "",
    CodigoEstadoDian: data?.codigoestadodian ?? "",
  };
};

/**
 * Lee detalle documento
 */
export const readDetailReceivedDocument = (data) => {
  let object = JSON.parse(data.documento ?? {});
  data = {
    ...data,
    ...object,
    documento: null,
  };

  //Leer linea detalle
  let LineaDetalle = [];
  for (let i = 0; i < data?.DatosFactura?.LineaDetalle?.length; i++) {
    const item = data?.DatosFactura?.LineaDetalle[i];

    const tributoiva =
      item?.Tributos?.find((c) => c?.Tributo?.Codigo === "01") ?? null;
    const iva =
      tributoiva?.SubtotalPorcentaje?.length > 0
        ? tributoiva?.SubtotalPorcentaje[0]?.Porcentaje
        : 0;

    const tributoinc =
      item?.Tributos?.find((c) => c?.Tributo?.Codigo === "04") ?? null; //04 INC
    const inc =
      tributoinc?.SubtotalPorcentaje?.length > 0
        ? tributoinc?.SubtotalPorcentaje[0]?.Porcentaje
        : 0;

    let prefijo = item?.Referencia?.Descripcion ?? "";
    prefijo = prefijo.length >= 2 ? prefijo.substring(0, 2).toUpperCase() : "";
    let descuentos = item?.Descuentos ?? [];
    let valordescuentos = 0;
    for (let i = 0; i < descuentos.length; i++) {
      valordescuentos += descuentos[i].Valor;
    }

    const newItem = {
      Cantidad: item?.Referencia?.Cantidad ?? 0,
      Descripcion: item?.Referencia?.Descripcion ?? "",
      Prefijo: prefijo,
      ValorTotal: item?.Referencia?.ValorTotal ?? 0,
      ValorUnitario: item?.Referencia?.ValorUnitario ?? 0,
      PorcetanjeIva: iva,
      PorcetanjeInc: inc,
      Color: getRandomColor(),
      ValorDescuentos: valordescuentos,
    };
    LineaDetalle.push(newItem);
  }

  //Leer Cargos
  let Cargos = [];
  for (let i = 0; i < data?.DatosFactura?.Cargos?.length; i++) {
    const item = data?.DatosFactura?.Cargos[i];
    Cargos.push(item);
  }

  //Leer Descuentos
  let Descuentos = [];
  for (let i = 0; i < data?.DatosFactura?.Descuentos?.length; i++) {
    const item = data?.DatosFactura?.Descuentos[i];
    Descuentos.push(item);
  }

  const observaciones =
    data?.DatosFactura?.Nota?.length > 0 ? data?.DatosFactura?.Nota[0] : "";

  return {
    Id: data.id ?? 0,
    Numero: data?.DatosFactura?.Numero ?? "",
    Fecha: !!data?.DatosFactura?.Fecha
      ? new Date(data?.DatosFactura?.Fecha)
      : null,
    MedioPago: homologatePaymentMethod(
      data?.DatosFactura?.MetodoPago?.MedioPago
    ),
    FormaPago: homologateWatToPay(data?.DatosFactura?.MetodoPago?.FormaPago),
    FechaVencimiento: !!data?.DatosFactura?.MetodoPago?.FechaVencimiento
      ? new Date(data?.DatosFactura?.MetodoPago?.FechaVencimiento)
      : null,
    Codigounico: data?.codigounico ?? "",
    TipoDocumentoEmisor: homologateTypeIdentification(
      data?.DatosEmisor?.TipoDocumento
    ),
    DocumentoEmisor: data?.DatosEmisor?.Documento ?? "",
    NombreEmisor: data?.DatosEmisor?.Nombre ?? "",
    TipoRegimenEmisor: homologateTypeRegimen(
      data?.DatosEmisor?.RegimenFiscal ?? ""
    ),
    CorreoElectronicoEmisor:
      data?.DatosEmisor?.DatosContacto?.CorreoElectronico ?? "-",
    NumeroTelefonicoEmisor:
      data?.DatosEmisor?.DatosContacto?.NumeroTelefonico ?? "-",
    DireccionEmisor: data?.DatosEmisor?.DireccionFisica?.Direccion ?? "-",
    DepartamentoEmisor:
      data?.DatosEmisor?.DireccionFisica?.Departamento?.Nombre ?? "",
    MunicipioEmisor:
      data?.DatosEmisor?.DireccionFisica?.Municipio?.Nombre ?? "",
    Favorito: data?.favorito ?? false,
    Valorapagar: data?.valor ?? 0,
    Observaciones: isEmpty(observaciones) ? "" : observaciones,
    LineaDetalle: LineaDetalle,
    Cargos: Cargos,
    Descuentos: Descuentos,
    EstadoAceptacion: data?.estadoaceptacion ?? "Pendiente",
    EstadoAceptacionid: data?.estadoaceptacionid ?? 1,
    FechaAceptacion: !!data?.fechaaceptacion
      ? new Date(data?.fechaaceptacion)
      : null,
    Acuse: data?.acuse ?? false,
    Recibido: data?.recibido ?? false,
    MotivoRechazo: homologateRejectReason(data?.motivorechazo ?? ""),
  };
};

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const readDetailIntegrationReport = (data) => {
  return {
    TipoIdentificacion: homologateTypeIdentification(data?.tipoIdentificacion),
    Documento: data?.documento ?? "",
    Nombre: data?.nombre ?? "",
    CorreoElectronico: data?.correoElectronico ?? "",
    TipoDocumentoElectronico: homologateTypeDocument(
      data?.tipoDocumentoElectronico
    ),
    Numero: data?.numero ?? "",
    Fecha: !!data?.fecha ? new Date(data?.fecha) : null,
    CodigoUnico: data?.codigoUnico ?? "",
    DatosQR: data?.datosQR ?? "",
    Valor: data?.valor ?? 0,
    EstadoDian: data?.estadoDian ?? "Pendiente",
    FechaAcuseDian: !!data?.fechaAcuseDian
      ? new Date(data?.fechaAcuseDian)
      : null,
  };
};
