import React from 'react';


import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { Create, Delete } from "@material-ui/icons";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#3B3B3B',
        color: '#FFFFF',
        marginTop: 10
    },
    header: {
        padding: '10px 16px 5px 16px'
    },
    cardContent: {
        paddingTop: 0
    },
    iconButton: {
        padding: '0px 10px',
        cursor: 'pointer'
    },
    icon: {
        color: '#2CC63E'
    },
    wordBreak: {
        wordBreak: 'break-all'
    },
    iconAction: {
        color: "white"
    },


}));

export const CustomerCard = React.memo(
    ({
        razonsocial,
        correoelectronico,
        documento,
        telefono,
        diantipopersonaid,
        direccion,
        handleEdit,
        handleDelete
    }) => {
        const classes = useStyles();


        return (
            <Card className={`${classes.root} animate__animated animate__fadeIn animate__faster`}>
                <CardHeader className={classes.header}
                    action={
                        <>
                            <IconButton
                                className={classes.iconButton}
                                onClick={handleEdit}>
                                <Create className={classes.iconAction} />
                            </IconButton>
                            <IconButton
                                className={classes.iconButton}
                                onClick={handleDelete} >
                                <Delete className={classes.iconAction} />
                            </IconButton>
                        </>
                    }
                    classes={{
                        title: classes.title,
                    }}
                    title={''}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container >
                        <Grid item xl={6} xs={6} className="totalInvoiceDetail">
                            {razonsocial}
                        </Grid>

                        <Grid item xl={6} xs={6} className="titleArticleInvoiceItalic">
                            <EmailIcon className={classes.icon} />
                            <div className={classes.wordBreak}> {correoelectronico}</div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="fieldsReadBig">
                            {documento}
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="titleArticleInvoiceItalic">
                            <PhoneIcon className={classes.icon} />
                            <div className={classes.wordBreak}> {telefono}</div>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="fieldsReadBig">
                            {diantipopersonaid === 1 ? 'Persona Jurídica' : 'Persona Natural'}
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="titleArticleInvoiceItalic">
                            <RoomIcon className={classes.icon} />
                            <div className={classes.wordBreak}>{direccion}</div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
)