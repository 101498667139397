import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
// import InputIcon from "../../components/input/inputIcon.component";
// import EventIcon from "@material-ui/icons/Event";
// import PopoverModal from "../../components/modal/popoverModal.component";
// import SimpleModal from "../../components/modal/simpleModal.component";
// import ResponseModal from "../../components/modal/responseModal.component";
import SupportItemCard from "./SupportItemCard";
import FilterContainerSupport from "./detailSupportComponet/FilterContainerSupport";
import { getDocumentsSupportAction } from "../../actions/documentSupportAction";
import CustomProgress from "../../components/Progress/progress.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import { format } from "date-fns";
import { WarningAlert } from "../../helpers/alert.helpers";
import { QUANTITY_DATA_DOCUMENT } from "../../config/config";

const useStyles = makeStyles({
  letter: {
    color: "#FFFFFF",
    height: "100%",
  },
  buttonIcon: {
    color: "white",
    marginTop: "50%",
  },
  listCard: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "80vh",

    // border: "1px solid red",
  },
});

const CastListSupport = ({ setDocumentSelected, documentIdSelected, edit }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [txtFilter, setTxtFilter] = useState("");
  const [txtFilterConfirmed, setTxtFilterConfirmed] = useState("");
  const [datesTmp, setDatesTmp] = useState({
    Fechini: format(new Date(), "yyyy/MM/dd"),
    Fechfin: format(new Date(), "yyyy/MM/dd"),
  });
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = useState(null);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [filterData, setFilterData] = useState({
    Filtro: null,
    Skip: 0,
    Limit: QUANTITY_DATA_DOCUMENT,
    Pagadas: null,
    Pendientes: null,
    Vencidas: null,
    Fechadesde: null,
    Fechahasta: null,
    loadMore: false,
    Destacadas: null,
    Editada: !edit ? false : true,
    Anulacion: null,
  });

  const classes = useStyles();
  const {
    documentSupportFilter,
    loadingGetDocumentSupportData,
    loadingGetdocuments,
    totaldocuments,
  } = useSelector((state) => state.documentSupportReducer);

  useEffect(() => {
    dispatch(
      getDocumentsSupportAction(
        filterData,
        documentSupportFilter,
        selectDefaultItem
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    if (documentIdSelected !== null && selected === 0) {
      setSelected(documentIdSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentIdSelected]);

  /**
   * Marca item seleccionado
   */
  const handleSelectedItem = (id) => {
    setSelected(id);
    setDocumentSelected(id);
  };

  /**
   * Carga identificador  de documento por defecto, para realizar consulta
   * @param {*} id Identificador documento
   */
  const selectDefaultItem = (id) => {
    setDocumentSelected(id);
    setSelected(id);
  };

  /**
   * Carga  siguiente lote de documentos
   */
  const loadMoreDocuments = () => {
    setFilterData({
      ...filterData,
      Skip: documentSupportFilter.length,
      LoadMore: true,
    });
    setTxtFilter(txtFilterConfirmed);
  };

  /**
   *  * Cierra modal filtro por fechas
   */
  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * Sincroniza nuevo valor filtro
   * @param {*} e Evento
   */
  const syncChangesFilter = (e) => {
    let value = e.target.value;
    setTxtFilter(value);

    if (value === "") {
      setTxtFilterConfirmed("");
      setFilterData({
        ...filterData,
        Skip: 0,
        LoadMore: false,
        Filtro: "",
      });
    }
  };

  /**
   * Evento, click sobre boton busqueda, consulta nuevo listado documentos
   * @param {*} e Evento
   */
  const onSearch = (e) => {
    setFilterData({
      ...filterData,
      Filtro: txtFilter,
      Skip: 0,
      LoadMore: false,
    });

    setTxtFilterConfirmed(txtFilter);
  };

  /**
   * Evento seleccion fecha inicial en filtro de documentos
   * @param {*} date
   */
  const handleDateInitial = (date) => {
    setDatesTmp((prevState) => ({ ...prevState, Fechini: date }));
  };

  /**
   * Evento seleccion fecha final en filtro de documentos
   * @param {*} date
   */
  const handleDateFinal = (date) => {
    setDatesTmp((prevState) => ({ ...prevState, Fechfin: date }));
  };

  /**
   * Filtra listado documentos por rango de fechas
   */
  const filterDate = () => {
    if (datesTmp.Fechini === "" || datesTmp.Fechfin === "") {
      WarningAlert("Debe seleccionar un rango de fechas válido");
    } else {
      if (new Date(datesTmp.Fechini) > new Date(datesTmp.Fechfin)) {
        WarningAlert("La fecha inicial no puede ser mayor a la final");
      } else {
        setFilterData({
          ...filterData,
          Fechadesde: datesTmp.Fechini,
          Fechahasta: datesTmp.Fechfin,
          Skip: 0,
          LoadMore: false,
        });

        handleCloseModalDates();
        setTxtFilter(txtFilterConfirmed);
      }
    }
  };

  /**
   * Limpia Filtro fechas, consulta listado con valores por defecto
   */
  const cleanFilterDate = () => {
    setDatesTmp((prevState) => ({
      ...prevState,
      Fechini: format(new Date(), "yyyy/MM/dd"),
      Fechfin: format(new Date(), "yyyy/MM/dd"),
    }));

    setFilterData({
      ...filterData,
      Fechadesde: null,
      Fechahasta: null,
      Skip: 0,
      LoadMore: false,
    });

    handleCloseModalDates();
    setTxtFilter(txtFilterConfirmed);
  };

  /**
   * Escucha evento, filtro por estado en documentos
   * @param {*} event
   */
  const handlecheckFilters = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;

    if (name === "chkPagadas") {
      setFilterData({
        ...filterData,
        Pagadas: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    if (name === "chkPendientes") {
      setFilterData({
        ...filterData,
        Pendientes: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    if (name === "chkVencidas") {
      setFilterData({
        ...filterData,
        Vencidas: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    if (name === "chkDestacadas") {
      setFilterData({
        ...filterData,
        Destacadas: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    if (name === "chkAnulacion") {
      setFilterData({
        ...filterData,
        Anulacion: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    setTxtFilter(txtFilterConfirmed);
  };

  /**
   * Limpia filtro por estado de documentos
   */
  const cleanFilter = () => {
    setFilterData({
      ...filterData,
      Skip: 0,
      Editado: false,
      Destacadas: false,
      Pagadas: false,
      Pendientes: false,
      Vencidas: false,
      LoadMore: false,
      Masivo: false,
      Anulacion: false,
      Notacredito: false,
      Notadebito: false,
    });

    handleCloseModalChecks();
    setTxtFilter(txtFilterConfirmed);
  };

  // /**
  //  * * Cerrar modal filtro estado documentos
  //  */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   *  *  Abrir modal filtro estado documentos
   * @param {*} event Evento
   */
  const handleOpenModalChecks = (event) => {
    if (loadingGetdocuments !== true) {
    }
    setModalChecksAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.letter}>
      <div className="spacingInvoice" />
      <Grid container>
        <Grid item xs={12}>
          <FilterContainerSupport
            onSearch={onSearch}
            txtFilter={txtFilter}
            syncChangesFilter={syncChangesFilter}
            datesTmp={datesTmp}
            handleDateInitial={handleDateInitial}
            handleDateFinal={handleDateFinal}
            cleanFilterDate={cleanFilterDate}
            modalDatesAnchorEl={modalDatesAnchorEl}
            setModalDatesAnchorEl={setModalDatesAnchorEl}
            handleCloseModalDates={handleCloseModalDates}
            filterDate={filterDate}
            handlecheckFilters={handlecheckFilters}
            filterData={filterData}
            cleanFilter={cleanFilter}
            modalChecksAnchorEl={modalChecksAnchorEl}
            setModalChecksAnchorEl={setModalChecksAnchorEl}
            handleOpenModalChecks={handleOpenModalChecks}
            handleCloseModalChecks={handleCloseModalChecks}
            loadingGetdocuments={loadingGetdocuments}
          />
          <div className="spacingInvoice" />
          {documentSupportFilter?.length === 0 &&
          loadingGetDocumentSupportData !== true ? (
            <div>No se encontraron Documentos Soporte</div>
          ) : null}
          {loadingGetDocumentSupportData &&
          documentSupportFilter?.length === 0 ? (
            <CustomProgress />
          ) : (
            <div style={{ overflow: "auto", height: "77.0vh" }}>
              {documentSupportFilter?.map((dat) => (
                <SupportItemCard
                  key={dat.id}
                  dat={dat}
                  onClick={() => handleSelectedItem(dat.id)}
                  selected={dat?.id === selected}
                />
              ))}
              {documentSupportFilter?.length > 0 &&
              documentSupportFilter?.length < totaldocuments ? (
                <Grid container justify="center" alignItems="center">
                  <Grid item lg={7} xs={9}>
                    <ButtonPrimary
                      onClick={loadMoreDocuments}
                      text={"Mostrar más..."}
                      loading={loadingGetDocumentSupportData === true}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CastListSupport;
