import {
  API_ENDPOINT_ENTERPRISE,
  defaultHeaders,
  URL_SECURITY,
} from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";
import {
  handleResponse,
  enterpriseIdHeader,
  enterpriseDocumentNumber,
} from "./authActions";
import { types } from "../types/types";
const urlNumeration = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/numeracion`;
const urlModality = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/modalidad`;
const urlDevices = `${URL_SECURITY}/security/api/Device`;

let numerations = [];
let filteredNumerations = [];

/**
 * Consulta y filtra listado numeraciones
 * @param {string} filter Parametro para filtro
 * @param {boolean} refresh Estado realizar consulta a Api
 */
export const getNumerationsFilterAction = (
  filter = "",
  refresh = true
) => async (dispatch) => {
  if (!refresh) {
    filteredNumerations = filterList(numerations, filter);
    dispatch({
      type: types.GET_NUMERATIONS,
      payload: numerations,
      payload1: filteredNumerations,
    });
  } else {
    try {
      changeGetLoading(dispatch, true);
      let config = defaultHeaders();
      Object.assign(config.headers, {
        empresaId: `${enterpriseIdHeader()}`,
      });
      var result = await axiosApiInstance.get(
        `${urlNumeration}/GetByEmpresaIdModalidadId`,
        config
      );
      numerations = result.data.result;
      filteredNumerations = filterList(numerations, filter);
      dispatch({
        type: types.GET_NUMERATIONS,
        payload: numerations,
        payload1: filteredNumerations,
      });
    } catch (err) {
      dispatch({
        type: types.showError,
        payload: {
          message: "No se ha podido obtener listado de numeraciones.",
          error: err,
        },
      });
    } finally {
      changeGetLoading(dispatch, false);
    }
  }
};

/**
 * Actualiza estado loading consulta listado numeraciones
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado
 */
function changeGetLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_NUMERATIONS,
    payload: status,
  });
}

/**
 * Filta listado de numeraciones
 * @param {*} list Listado numeraciones
 * @param {*} filter Parametro para filtro
 */
function filterList(list, filter) {
  if (filter === "") return list;

  list = list.filter(
    (c) =>
      c.resolucion
        ?.toLowerCase()
        ?.match(new RegExp(filter.toLowerCase() + ".*")) ||
      c.prefijo
        ?.toLowerCase()
        ?.match(new RegExp(filter.toLowerCase() + ".*")) ||
      c.nombre?.toLowerCase()?.match(new RegExp(filter.toLowerCase() + ".*")) ||
      c.diantipomodalidaddescripcion
        ?.toLowerCase()
        ?.match(new RegExp(filter.toLowerCase() + ".*"))
  );
  return list;
}

/**
 * Sincroniza  numeraciones con existentes en DIAN
 */
export const getSynchronizationDian = () => async (dispatch) => {
  try {
    changeSyncStatusAction(dispatch, true);
    const body = {
      empresaId: `${enterpriseIdHeader()}`,
    };

    var result = await axiosApiInstance.post(
      `${urlNumeration}/getSynchronizationDian`,
      body,
      defaultHeaders()
    );
    dispatch({
      type: types.SYNC_GET_NUMERATION,
      payload: result.data,
    });
  } catch (err) {
    if (err.response && err.response.status === 423) return;
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido sincronizar listado de numeraciones.",
        error: err,
      },
    });
  } finally {
    changeSyncStatusAction(dispatch, false);
  }
};

/**
 * Actualiza estado loading sincronización DIAN
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado
 */
function changeSyncStatusAction(dispatch, status) {
  dispatch({
    type: types.STATUS_SYNC_NUMERATION,
    payload: status,
  });
}

export const getNumeration = (id) => async (dispatch) => {
  let numeration = [];

  for (let index = 0; index < numerations.length; index++) {
    if (numerations[index].id === id) {
      numeration = numerations[index];
    }
  }
  const response = {
    httpCode: "200",
    responseCode: "OK",
    content: numeration,
  };
  dispatch({
    type: types.GET_NUMERATION,
    payload: response,
  });
};

/**
 * Consulta listado de modalidades existentes.
 */
export const getModalities = () => async (dispatch, getState) => {
  try {
    const { modalities } = getState().numerationReducer;
    if (modalities.length > 0) return;

    const result = await axiosApiInstance.get(
      `${urlModality}`,
      defaultHeaders()
    );
    dispatch({
      type: types.GET_MODALITY_TYPES,
      payload: result.data.map((item) => ({
        ...item,
        value: item.id,
        text: item.descripcion,
      })),
    });
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado de modalidades.",
        error: err,
      },
    });
  }
};

/**
 * Registra información de  una numeración
 * @param {object} data Información numeración
 */
export const createNumerationAction = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    data = {
      ...data,
      empresaId: `${enterpriseIdHeader()}`,
    };

    var result = await axiosApiInstance.post(
      `${urlNumeration}`,
      data,
      defaultHeaders()
    );
    dispatch({
      type: types.SAVE_NUMERATION,
      payload: result.data,
    });
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido guardar numeración.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza información  una numeracion
 * @param {object} body Nueva información de nuemración
 */
export const updateNumerationAction = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    data = {
      ...data,
      empresaId: `${enterpriseIdHeader()}`,
    };

    var result = await axiosApiInstance.put(
      `${urlNumeration}/UpdateNumeration`,
      data,
      defaultHeaders()
    );
    dispatch({
      type: types.UPDATE_NUMERATION,
      payload: result.data,
    });
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar numeracion.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading guardado o actualización numeración
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_NUMERATION,
    payload: status,
  });
}

/**
 * Actualiza estado de numeracion
 * @param {object} data Información de numeración
 */
export const updteNumerationStateAction = (data) => async (dispatch) => {
  try {
    changeUpdateStateLoading(dispatch, true, data.idnumeracion);

    let config = defaultHeaders();
    Object.assign(config.headers, {
      idnumeracion: data.idnumeracion,
      idstate: data.idstate,
    });

    var result = await axiosApiInstance.put(
      `${urlNumeration}/UpdateState`,
      null,
      config
    );

    //Agrega nuevo estado de numeración
    let response = {
      ...result.data,
      idstate: data.idstate,
      idnumeracion: data.idnumeracion,
    };

    dispatch({
      type: types.UPDATE_NUMERATION_STATE,
      payload: response,
    });
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar estado de numeración.",
        error: err,
      },
    });
  } finally {
    changeUpdateStateLoading(dispatch, false, data.idnumeracion);
  }
};

/**
 * Cambia estado loading para cambio de estado dentro del listado de numeraciones
 * @param {object} dispatch
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateLoading(dispatch, status, id) {
  let newData = filteredNumerations.find((n) => n.id === id);

  if (!!newData) {
    const index = filteredNumerations.findIndex((e) => e.id === id);
    let newArray = [...filteredNumerations];
    newArray[index] = {
      ...newData,
      loading: status,
    };
    filteredNumerations = newArray;
  }

  dispatch({
    type: types.GET_NUMERATIONS,
    payload: numerations,
    payload1: filteredNumerations,
  });
}

/**
 * Actualiza listado de nuemraciónes, despues de cambiar el estado (activar, inactivar)
 * @param {int} id Identificador numeración actualizada
 * @param {int} idstate Identificador nuevo estado numeración
 */
export const refreshAfterChangeStatusAction = (id, idstate, filter) => async (
  dispatch
) => {
  let newData = numerations.find((n) => n.id === id);

  if (!!newData) {
    const index = numerations.findIndex((e) => e.id === id);
    let newArray = [...numerations];
    newArray[index] = { ...newData, idstate: idstate };
    numerations = newArray;
  }

  filteredNumerations = filterList(numerations, filter);
  dispatch({
    type: types.GET_NUMERATIONS,
    payload: numerations,
    payload1: filteredNumerations,
  });
};

/**
 * CRaga información de numeración en Redux
 * para edición.
 * @param {object} data Información numeración
 */
export const loadNumerationAction = (data) => async (dispatch) => {
  dispatch({
    type: types.LOAD_NUMERATION,
    payload: data,
  });
};

export const fillListNumerations = (listNumerations) => {
  numerations = listNumerations;
};

export const changeStepStatusAction = (status) => (dispatch) => {
  dispatch({
    type: types.STATUS_STEP_NUMERATION,
    payload: status,
  });
};

export const getDevices = () => async (dispatch) => {
  try {
    let config = defaultHeaders();

    const body = {
      DocumentNumber: enterpriseDocumentNumber(),
      DocumentType: 9,
    };
    var result = await axiosApiInstance.post(
      `${urlDevices}/GetDevicesByEnterprise`,
      body,
      config
    );
    const numerations = result.data.result.records.devices;
    dispatch({
      type: types.GET_DEVICES,
      payload: numerations,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado de dispositivos.",
        error: err,
      },
    });
  }
};

/**
 * Actualiza deviceMac de numeracion
 * @param {object} data Información de numeración
 */
export const putDeviceMacState = (data) => async (dispatch) => {
  try {
    let config = defaultHeaders();
    Object.assign(config.headers, {
      idnumeracion: data.idnumeracion,
      deviceMac: data.deviceMac,
    });

    await axiosApiInstance.put(
      `${urlNumeration}/UpdateDeviceMac`,
      null,
      config
    );
    refreshAfterChangeMacAction(data.idnumeracion, data.deviceMac)(dispatch);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar estado de numeración.",
        error: err,
      },
    });
  }
};

/**
 * Actualiza listado de nuemraciónes, despues de cambiar el estado (activar, inactivar)
 * @param {int} id Identificador numeración actualizada
 * @param {int} macPhone nueva mac numeración
 */
export const refreshAfterChangeMacAction = (id, macPhone) => async (
  dispatch
) => {
  let newData = numerations.find((n) => n.id === id);

  if (!!newData) {
    const index = numerations.findIndex((e) => e.id === id);
    let newArray = [...numerations];
    newArray[index] = { ...newData, devicemac: macPhone };
    numerations = newArray;
  }

  dispatch({
    type: types.GET_NUMERATIONS,
    payload: numerations,
  });
};
