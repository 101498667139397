import { types } from "../types/types";
import isEmpty from "../utils/isEmpty";

const initialState = {
  responseSaveBranchOffice: {},
  responseUpdateBranchOffice: {},
  responseUpdateStateBranchOffice: {},
  editBranchStatus: false,
  stepBranchOfficeComplete: false,
  loadingSaveBranch: false,
  loadingGetBranchOffices: false,
  openModalItem: false,
  licenseBranchOffice: null,
  branchOfficesList: [],
  filteredBranchOfficesList: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_BRANCH_OFFICES:
      return {
        ...state,
        filteredBranchOfficesList: action.payload,
        branchOfficesList: action.payload1,
      };
    case types.SAVE_BRANCH_OFFICE:
      return {
        ...state,
        responseSaveBranchOffice: action.payload,
      };
    case types.UPDATE_BRANCH_OFFICE:
      return {
        ...state,
        responseUpdateBranchOffice: action.payload,
      };
    case types.UPDATE_STATE_BRANCH_OFFICE:
      return {
        ...state,
        responseUpdateStateBranchOffice: action.payload,
      };
    case types.LOAD_BRANCH_OFFICE:
      return {
        ...state,
        loadBranchOffice: action.payload,
        editBranchStatus: !isEmpty(action.payload),
      };

    case types.GET_FILTER_BRANCH_OFFICES:
      return {
        ...state,
        getFilterBranchOffices: action.payload,
      };

    case types.UPDATE_PRINCIPAL_BRANCH:
      return {
        ...state,
        updatePrincipalBranchResponse: action.payload,
      };
    case types.LOADING_SAVE_BRANCH:
      return {
        ...state,
        loadingSaveBranch: action.payload,
      };
    case types.LOADING_GET_BRANCH_OFFICES:
      return {
        ...state,
        loadingGetBranchOffices: action.payload,
      };
    case types.INFO_SAVE_BRANCH_OFFICES:
      return {
        ...state,
        openModalItem: action.payload,
      };
    case types.INFO_LICENSE_BRANCH_OFFICES:
      return {
        ...state,
        licenseBranchOffice: action.payload,
      };
    default:
      return state;
  }
}

