import React, { Fragment, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";


import { Grid } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";

import { idUser } from '../../actions/authActions';

import InputIcon from '../../components/input/inputIcon.component'
import CardMultimedia from './cardMultimedia.component'

import { getMultimediaVideoFilter } from '../../actions/trainingActions';
import withWidth from '@material-ui/core/withWidth';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import PopoverModalMultimedia from '../../components/modal/poperModalMultimedia.component';
import StyledCheckbox from '../../components/checkForm/styledCheckbox.component';
import ButtonPrimary from '../../components/button/buttonPrimary.component';
import CustomProgress from '../../components/Progress/progress.component';

import filterImage from "../../../src/images/filter.png";

import DATA_NOT_FOUND_IMAGE from '../../images/NotificacionesColfactura.png'
import { QUANTITY_DATA_MULTIMEDIA } from '../../config/config';


const useStyles = () => ({
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center"
  },
  imageFilter: {
    marginLeft: '-15px'
  },
  letter: {
    color: '#FFFFFF'
  },
  datanotfound: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  datanotfoundText: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontWeight: 'bold',
    fontSize: '20px'

  },
});

const MultimediaView = (props) => {
  const { classes } = props;
  const { getVideoMultimedia, loadingGetdocuments, countVideo, loadingGetVideo } = props;
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
  const [txtFilterSearch, setTxtFilterSearch] = useState('');

  const [filterData, setFilterData] = useState({
    Skip: 0,
    Take: QUANTITY_DATA_MULTIMEDIA,
    Filter: 0,
    Numeracion: false,
    Facturacion: false,
    Articulo: false,
    Cotizacion: false,
    Favorito: false,
    Cliente: false,
    Cargo: false,
    Descuento: false,
    FiltroBusqueda: '',
    IdUsuario: parseInt(idUser())
  });

  /**
  * Abrir modela de filtros de videos.
  * @param {*} event Evento
  */
  const handleOpenModalChecks = (event) => {
    if (loadingGetdocuments !== true) {
      setModalChecksAnchorEl(event.currentTarget);
    }
  };
  /**
   * Cerrar modal filtro de videos de capacitación
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  const cleanFilter = () => {
    setFilterData({
      ...filterData,
      Skip: 0,
      Filter: 0,
      Numeracion: false,
      Facturacion: false,
      Articulo: false,
      Cotizacion: false,
      Favorito: false,
      Cliente: false,
      Cargo: false,
      Descuento: false,
      FiltroBusqueda: '',
      LoadMore: false
    })
    handleCloseModalChecks();
  }
  /**
   * Escucha evento del filtro de tipo de videos
   * @param {*} event 
   */
  const handlecheckFilters = (event) => {

    let name = event.target.name;
    let checked = event.target.checked;

    if (name === "chkFacturacion") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 3 : 0,
        Facturacion: checked,
        LoadMore: false
      })
    }

    if (name === "chkNumeracion") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 2 : 0,
        Numeracion: checked,
        LoadMore: false
      })
    }
    if (name === "chkArticulo") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 5 : 0,
        Articulo: checked,
        LoadMore: false
      })
    }
    if (name === "chkCotizacion") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 4 : 0,
        Cotizacion: checked,
        LoadMore: false
      })
    }
    if (name === "chkCliente") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 8 : 0,
        Cliente: checked,
        LoadMore: false
      })
    }
    if (name === "chkCargo") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 6 : 0,
        Cargo: checked,
        LoadMore: false
      })
    }
    if (name === "chkDescuento") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 7 : 0,
        Descuento: checked,
        LoadMore: false
      })
    }
    if (name === "chkFavorito") {
      setFilterData({
        ...filterData,
        Skip: 0,
        Filter: checked === true ? 7 : 0,
        Favorito: checked,
        LoadMore: false
      })
    }
  }

  /**
  * Carga  siguiente lote de videos
  */
  const loadMoreVideos = () => {
    setFilterData({
      ...filterData,
      Skip: getVideoMultimedia.length,
      LoadMore: true,
    });
  }
  /**
     * Sincroniza nuevo valor filtro
     * @param {*} e Evento
     */
  const syncChangesFilter = (e) => {
    let value = e.target.value;
    setTxtFilterSearch(value);

    if (value === '') {
      setFilterData({
        ...filterData,
        Skip: 0,
        LoadMore: false,
        FiltroBusqueda: ''
      })
    }
  }

  /**
   * Evento, click sobre boton busqueda, consulta nuevo listado de videos
   * @param {*} e Evento
   */
  const onSearch = (e) => {

    setFilterData({
      ...filterData,
      FiltroBusqueda: txtFilterSearch,
      Skip: 0,
      LoadMore: false
    });

  }

  /**
 * Consulta listado facturas paginando y filtrando
*/
  useEffect(() => {
    props.getMultimediaVideoFilter(filterData, getVideoMultimedia)

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterData]);

  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Facturacion}
              onChange={handlecheckFilters}
              name='chkFacturacion'
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Facturación
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Numeracion}
              onChange={handlecheckFilters}
              name="chkNumeracion"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Numeraciones
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Articulo}
              onChange={handlecheckFilters}
              name="chkArticulo"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Artículos
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Cotizacion}
              onChange={handlecheckFilters}
              name="chkCotizacion"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cotizaciones
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Cliente}
              name="chkCliente"
              onChange={handlecheckFilters}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Clientes
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Cargo}
              name="chkCargo"
              onChange={handlecheckFilters}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cargo
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Descuento}
              name="chkDescuento"
              onChange={handlecheckFilters}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Descuentos
        </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox className={classes.letter}
              checked={filterData.Favorito}
              name="chkFavorito"
              onChange={handlecheckFilters}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Favorito
        </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link href="#" onClick={cleanFilter} variant="inherit" underline="none" className={classes.link}>
              Limpiar Filtro
          </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <Fragment >
      <Grid container spacing={3}>
        <Grid item lg={11} xs={11} md={11}>
          <InputIcon
            name={'txtFilterSearch'}
            onChange={(e) => syncChangesFilter(e)}
            onSearch={onSearch}
            value={txtFilterSearch}
            disabled={loadingGetVideo === true}
            label={"Buscar"}
            maxLength={100}
          />
        </Grid>
        <Grid item lg={1} xs={1} md={11}>
          <Tooltip title="Filtrar por estado">
            <IconButton
              onClick={handleOpenModalChecks}
              disabled={loadingGetVideo === true}
            >
              <img alt={'Filtrar'} width={18.75} height={20} src={filterImage} className={classes.imageFilter} />
            </IconButton>
          </Tooltip>
        </Grid>

        <PopoverModalMultimedia
          handleClose={handleCloseModalChecks}
          open={Boolean(modalChecksAnchorEl)}
          component={modalFilters}
          width={191}
          popoverAnchorEl={modalChecksAnchorEl}
          showCloseButton={false}
        />
        <br />
        <br />
        <br />
        <Grid container spacing={2}>
          {loadingGetVideo === true && getVideoMultimedia?.length === 0 ? 
            <div className={classes.datanotfound}>
              <CustomProgress />
            </div> :
              getVideoMultimedia === null ? '' : 
                getVideoMultimedia !== undefined ? 
                  getVideoMultimedia.length !== 0 ? 
                    getVideoMultimedia.map((data) => {
                      return (
                  <CardMultimedia
                  url={data.url}
                  name={data.descripcion}
                  id={data.id}
                  favorito={data.favorito == null ? false : data.favorito}
                />
              )
            }) : <Grid container className={classes.datanotfound}>
              <Grid item lg={12} xs={12}><img src={DATA_NOT_FOUND_IMAGE} alt='Datos no encontrados' />
                <div className={classes.datanotfoundText}>No se encontraron registros.</div>
              </Grid>
            </Grid> : ''}

          {getVideoMultimedia?.length > 0 && getVideoMultimedia?.length < countVideo ?
            <Grid container justify="center" alignItems="center" >
              <Grid item lg={4} xs={12}>
                <ButtonPrimary
                  onClick={loadMoreVideos}
                  text={'Mostrar más...'}
                  loading={loadingGetVideo === true}
                />
              </Grid>
            </Grid> : ''
          }

        </Grid>
      </Grid>
    </Fragment>
  );
}
MultimediaView.propTypes = {
  getMultimediaVideoFilter: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  trainingReducer: state.trainingReducer,
  getVideoMultimedia: state.trainingReducer.getVideoMultimedia,
  loadingGetdocuments: state.invoiceReducer.loadingGetdocuments,
  countVideo: state.trainingReducer.countVideo,
  loadingGetVideo: state.trainingReducer.loadingGetVideo
})

export default connect(mapStateToProps, {
  getMultimediaVideoFilter
})(
  withStyles(useStyles)(withWidth()(MultimediaView)));
