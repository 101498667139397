import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import PersonIcon from "@material-ui/icons/Person";
import AssessmentIcon from "@material-ui/icons/Assessment";
import StyleIcon from "@material-ui/icons/Style";
import SchoolIcon from "@material-ui/icons/School";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Dashboard from "@material-ui/icons/Dashboard";
import Grid from "@material-ui/core/Grid";
import { Box, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import * as SignalR from "@microsoft/signalr";
import {
  enterpriseIdHeader,
  idUser,
  isSiigoEnterprise,
} from "../../actions/authActions";

import CustomProgress from "../../components/Progress/progress.component";

import {
  changeStepAction,
  joinGroupSignalAction,
  resultSignal,
} from "../../actions/invoiceActions";
import { getConfigurableMenuAction } from "../../actions/menuAction";
import {
  connectionSignalAction,
  getStatesAction,
  changeStateFlagAction,
  getMenusAction,
  getFrequentQuestions,
  getReportProblem,
  getSecurityPolicy,
  getreason,
  getIssueAction,
  getTypeDocumentReferenceAction,
} from "../../actions/configAction";
import {
  getCertificateAction,
  requestCertificateAction,
} from "../../actions/certificateAction";

import SelectedMenuImage from "../../images/selected-menu.png";
import SelectedMyAccountIcon from "../../images/selected-colfactura-icon.png";
import MyAccountIcon from "../../images/colfactura-icon.png";
import PowerBy from "../../images/PowerBy.png";
import {
  logoutUserAction,
  getRoleOfLoggedUser,
  encryptParameters,
  userName,
} from "../../actions/authActions";

import BulkLoadStatus from "../../components/bulkLoadStatus.component";
import ResponseModal from "../modal/responseModal.component";
import ButtonPrimary from "../button/buttonPrimary.component";
import { SecondCustomModal } from "../modal/SecondCustomModal";
import { CertificateRenewalForm } from "./CertificateRenewalForm";

import {
  URL_LICENCE,
  API_ENDPOINT_SIGNALR,
  READING_MODE,
} from "../../config/config";
import { formatDate } from "../../utils/general";

import AlertIcon from "../../images/alert-fill.png";
import { DocSupportIcon, EquivalentDocIcon } from "../../images/svgComponents";

const urlSignalR = `${API_ENDPOINT_SIGNALR}/signal`;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  readingMode: {
    width: "100%",
    background: "#F5D13D",
    height: "40px",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    color: "#000",
    textAlign: "center",
    fontFamily: "Muli",
    fontSize: "14px",
    fontWeight: 700,
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      height: "50px",
    },
  },
  readingModeOff: {
    display: "none",
  },
  labelVesrion: {
    height: "22px",
    width: "133px",
    color: "rgba(255,255,255,0.6)",
    fontFamily: theme.palette.fontFamily,
    fontSize: "15px",
    letterSpacing: "0.3px",
    lineHeight: "22px",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    color: theme.palette.primaryColor,
    backgroundColor: theme.palette.primaryBackgroundColor,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    flexWrap: "wrap",
    background: theme.palette,
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  toolbarTitleContent: {
    // ...theme.mixins.toolbar,
  },
  boxTitle: {
    display: "contents",
    flexDirection: "row",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primaryBackgroundColor,
  },
  content: {
    padding: "0px",
    backgroundColor: theme.palette.primaryBackgroundColor,
    height: "100vh",
    overflow: "auto !important",
    width: "100%",
  },
  itemText: {
    color: theme.palette.primaryColor,
    "&.Mui-selected": {
      background: `url('${SelectedMenuImage}')`,
      backgroundPosition: "right",
    },
    "&.MuiListItem-root:hover": {
      background: `url('${SelectedMenuImage}')`,
      backgroundPosition: "right",
    },
  },
  icon: {
    color: theme.palette.primaryColor,
  },
  divider: {
    backgroundColor: "#232323",
    marginLeft: "10px",
    marginRight: "10px",
    height: "2px",
  },
  imageApp: {
    padding: "30px 10px 20px 10px",
  },
  wrapTypography: {
    fontSize: 0,
  },
  navUser: {
    color: theme.palette.primaryColor,
    fontWeight: "bold",
    fontSize: "14px",
    margin: "2px",
    padding: "2px",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  navUserEmail: {
    color: theme.palette.primaryColor,
    fontSize: "12px",
    margin: "2px",
    padding: "2px",
  },
  navWrapperUser: {
    textAlign: "right",
  },
  divContainer: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    textAlign: "center",
  },
  gridAling: {
    margin: "20px",
    padding: "20px",
  },
  logoutShort: {
    paddingTop: "0px",
  },
  logoutLong: {
    paddingTop: "300px",
  },
  textDisabled: {
    color: theme.palette.eighthColor,
  },
  containerCertificateModal: {
    justifyContent: "center",
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const MasterPage = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [urlMiPlan, setUrlMiPlan] = React.useState("");
  const [urlHome, setUrlHome] = React.useState("");
  const { getStatesAction } = props;
  const {
    logoutUserAction,
    informationBulk,
    stateBulkLoad,
    conexion,
    flagConnection,
    menuDashboard,
  } = props;
  const company = JSON.parse(localStorage.getItem("dataCompany"));
  const menu = props.configReducer.menu;

  const { indexSelected } = props.generalReducer;
  let menuList = [];

  //Constantes certificado
  const [openCertificateModal, setOpenCertificateModal] = useState(false);
  const [certificateData, setCertificateData] = useState({});
  const [loadingRequestCertificate, setLoadingRequestCertificate] = useState(
    false
  );
  const [modalData, setModalData] = React.useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  //GEnerar una conexion con SingAlert
  useEffect(
    () => {
      if (flagConnection) {
        const hubConnection = new SignalR.HubConnectionBuilder()
          .withUrl(urlSignalR)
          .withAutomaticReconnect()
          .configureLogging(SignalR.LogLevel.Information)
          .build();
        props.connectionSignalAction(hubConnection);
        props.changeStateFlagAction(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flagConnection]
  );

  useEffect(
    () => {
      if (conexion !== null) {
        conexion
          .start()
          .then(() => {
            try {
              conexion
                .invoke(
                  "JoinGroup",
                  enterpriseIdHeader(),
                  idUser(),
                  conexion.connectionId
                )
                .catch((error) => {
                  console.log("Error realizando conexión de grupo ", error);
                });

              conexion.on("ReceiveMessage", function(
                status,
                processed,
                total,
                detalleId,
                estadoDocumento,
                numeroFactura,
                fechaAcuse
              ) {
                var send = {
                  status: status,
                  total: total,
                  detailId: detalleId,
                  stateDocument: estadoDocumento,
                  numberinvoice: numeroFactura,
                  dateAcuse: fechaAcuse,
                  processed: processed,
                };

                props.resultSignal(send);
                props.resultSignal(); // ?
              });
            } catch (error) {
              console.log("Error connection", error);
            }
          })
          .catch((err) => console.log("Unable to start Connection: " + err));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conexion]
  );

  const HeaderView = () => {
    const location = useLocation();
    return location.pathname;
  };
  const nameMenu = (dbName, name) => {
    return !!dbName ? dbName : name;
  };

  if (!!menu && menu.length > 0) {
    menuList = [
      {
        id: 0,
        text: nameMenu(
          menu.find((element) => element.id === 1).descripcion,
          "Inicio"
        ),
        link: "/home",
        icon: <HomeIcon />,
      },
      {
        id: 1,
        text: nameMenu(
          menu.find((element) => element.id === 2).descripcion,
          "Mi Cuenta"
        ),
        link: getRoleOfLoggedUser() ? "/" : "/myAccount",
        icon: getRoleOfLoggedUser() ? (
          <i>
            <img src={MyAccountIcon} alt="" />
          </i>
        ) : (
          <i>
            <img src={MyAccountIcon} alt="" />
          </i>
        ),
        selectedIcon: (
          <i>
            <img src={SelectedMyAccountIcon} alt="" />
          </i>
        ),
        disabled: getRoleOfLoggedUser() ? true : false,
      },
      {
        id: 2,
        text: nameMenu(
          menu.find((element) => element.id === 3).descripcion,
          "Facturas"
        ),
        link: "/invoice",
        icon: <ReceiptIcon />,
      },
      {
        id: 16,
        text: nameMenu(
          menu.find((element) => element.id === 16)?.descripcion,
          "Doc. Equivalente"
        ),
        link: "/equivalentDocument",
        icon: <EquivalentDocIcon />,
      },
      {
        id: 3,
        text: nameMenu(
          menu.find((element) => element.id === 4).descripcion,
          "Cotizaciones"
        ),
        link: "/quotation",
        icon: <InsertDriveFileIcon />,
      },
      {
        id: 15,
        text: nameMenu(
          menu.find((element) => element.id === 15).descripcion,
          "Doc. Soporte"
        ),
        link: "/documentSupport",
        icon: <DocSupportIcon />,
      },
      {
        id: 4,
        text: nameMenu(
          menu.find((element) => element.id === 5).descripcion,
          "Artículos"
        ),
        link: "/catalogCategory",
        icon: <StyleIcon />,
      },
      {
        id: 5,
        text: nameMenu(
          menu.find((element) => element.id === 6).descripcion,
          "Cargos"
        ),
        link: "/charges",
        icon: <AddToPhotosIcon />,
      },
      {
        id: 6,
        text: nameMenu(
          menu.find((element) => element.id === 12).descripcion,
          "Descuentos"
        ),
        link: "/discounts",
        icon: <LocalOfferIcon />,
      },
      {
        id: 7,
        text: nameMenu(
          menu.find((element) => element.id === 7).descripcion,
          "Clientes"
        ),
        link: "/clients",
        icon: <PersonIcon />,
      },
      {
        id: 8,
        text: nameMenu(
          menu.find((element) => element.id === 8).descripcion,
          "Informes"
        ),
        link: "/informe",
        icon: <AssessmentIcon />,
      },
      {
        id: 14,
        text: nameMenu(
          menu.find((element) => element.id === 14)?.descripcion,
          "Informes Siigo"
        ),
        link: "/reportSiigo",
        icon: <AssessmentIcon />,
        available: isSiigoEnterprise(),
      },
      {
        id: 9,
        text: nameMenu(
          menu.find((element) => element.id === 9).descripcion,
          "Ayuda"
        ),
        link: "/helps",
        icon: <HelpIcon />,
      },
      {
        id: 10,
        text: nameMenu(
          menu.find((element) => element.id === 13).descripcion,
          "Capacitación"
        ),
        link: "/training",
        icon: <SchoolIcon />,
      },
      {
        text: nameMenu(
          menu.find((element) => element.id === 10).descripcion,
          "Ir a la tienda"
        ),
        link: "/",
        icon: <ShoppingBasket />,
        external: true,
        href: urlHome,
      },
      {
        text: nameMenu(
          menu.find((element) => element.id === 11).descripcion,
          "Mi Plan"
        ),
        link: "/",
        icon: <Dashboard />,
        external: true,
        href: urlMiPlan,
      },
    ];

    menuList = menuList.filter((menu) => menu.available ?? true);
  }

  //Inicia Componente
  useEffect(() => {
    props.getConfigurableMenuAction();
    props.getFrequentQuestions();
    props.getSecurityPolicy();
    props.getIssueAction();
    props.getMenusAction();
    props.getTypeDocumentReferenceAction();
    props.getCertificateAction(handleOpenResponseModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStatesAction();

    //urls externas
    const jwtToken = localStorage.getItem("jwtToken");
    const requestParameters = {
      token: jwtToken,
      user: userName(jwtToken),
      parameters: "",
    };

    const response = encryptParameters(requestParameters);
    var urlMiPlan = `${URL_LICENCE}/myplan/${response}`;
    var urlHome = `${URL_LICENCE}/${response}`;
    setUrlMiPlan(urlMiPlan);
    setUrlHome(urlHome);
  }, [getStatesAction]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const stateBulk = (state) => {
    if (state === 1) return "en proceso";

    if (state === 2) return "cancelado";

    if (state === 3) return "finalizado";

    if (state === 4) return "pausado";
  };

  const logout = () => {
    logoutUserAction(conexion);
  };

  const drawer = (
    <div style={READING_MODE ? { marginTop: "40px" } : {}}>
      <div className={classes.toolbar}>
        <img
          className={classes.imageApp}
          src={require("./../../images/logo-small.png")}
          width="200"
          alt={"Logo"}
        ></img>
      </div>
      <Divider className={classes.divider} />
      <List>
        {!!menuList && menuList.length > 0 ? (
          menuList.map((menu, index) => (
            <div key={index}>
              {menu.disabled === true ? (
                <LightTooltip
                  title="No tiene permisos sobre esta acción"
                  placement="right"
                >
                  <ListItem
                    button
                    key={menu.text}
                    className={classes.itemText}
                    component={Link}
                    selected={indexSelected === menu.id}
                  >
                    <ListItemIcon className={classes.icon}>
                      {indexSelected === 1 && indexSelected === menu.id
                        ? menu.selectedIcon
                        : menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography>
                          <span className={classes.textDisabled}>
                            {menu.text}
                          </span>
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                </LightTooltip>
              ) : (
                <ListItem
                  key={menu.text}
                  button
                  className={classes.itemText}
                  component={menu.external ? "a" : Link}
                  to={menu.link}
                  href={menu.external ? menu.href : null}
                  target={menu.external ? "blank" : null}
                  selected={indexSelected === menu.id}
                >
                  <ListItemIcon className={classes.icon}>
                    {indexSelected === 1 && indexSelected === menu.id
                      ? menu.selectedIcon
                      : menu.icon}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography>
                        <span>{menu.text}</span>
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              )}
              {index % 2 !== 0 ? <Divider className={classes.divider} /> : ""}
            </div>
          ))
        ) : (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} className={classes.gridAling}>
              <div className={classes.divContainer}>
                <CustomProgress />
              </div>
            </Grid>
          </Grid>
        )}
      </List>
      <List
        className={
          menuList.length > 0 ? classes.logoutShort : classes.logoutLong
        }
        component="nav"
        aria-label="secondary mailbox folders"
      >
        <ListItem button>
          <ListItemText
            className={classes.itemText}
            primary={
              <Fragment>
                <PowerSettingsNewIcon
                  onClick={() => {
                    logout();
                  }}
                />
                <br />
                <br />
                <div className={classes.labelVesrion}>
                  {!!menuDashboard && menuDashboard.labelVersion}
                </div>
                <br />
                <div>
                  <img src={PowerBy} alt="" />
                </div>
              </Fragment>
            }
          ></ListItemText>
        </ListItem>
      </List>
    </div>
  );

  /**
   * Cierra Modal, Certificado a punto de vencerse
   */
  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });

    setCertificateData({});
  };

  /**
   * Cierra el modal para el mensaje de certificado vencido pero no limpia la informacion
   */
  const handleCloseResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  /**
   * Abre el modal informando que el certificado actual esta vencido
   * @param {*} data
   */
  const handleOpenResponseModal = (data) => {
    setCertificateData(data);
    setModalData({
      modalType: "warning",
      title:
        "Tu certificado digital " +
        (data.diasVencimiento < 0
          ? "ha vencido"
          : "vence en " + data.diasVencimiento + " día(s)"),
      body: (
        <>
          {data.estadoNuevoCertificado === "SOL" ? (
            "No olvides que el representante legal debe actualizar la información y documentación solicitada a través del correo electrónico para la renovación del certificado digital. "
          ) : (
            <Grid container className={classes.containerCertificateModal}>
              <Grid item lg={12} xs={12}>
                {`Tu certificado digital ${
                  data.diasVencimiento < 0 ? "venció" : "vence"
                } el ${formatDate(
                  data.certificadoHasta,
                  "dd/MM/yyyy"
                )}, es necesario renovarlo
                para que puedas seguir emitiendo documentos, da clic en el siguiente
                botón para iniciar el proceso.`}
              </Grid>
              <Grid item lg={3} xs={12}>
                <ButtonPrimary
                  text={"Continuar"}
                  onClick={() => handleOpenCertificateModal()}
                />
              </Grid>
            </Grid>
          )}
        </>
      ),
      modalImage: "warning",
      open: true,
      closeElement: "Cerrar",
      onCloseElement: 0,
    });
  };

  /**
   * Abre el modal para enviar la nueva peticion de certificado.
   */
  const handleOpenCertificateModal = () => {
    handleCloseResponseModal();
    setOpenCertificateModal(true);
  };

  /**
   * Envia la nueva peticion de certificado
   * @param {*} data Datos del representante legal
   * @param {*} personType Codigo tipo persona
   * @param {*} enterpriseName Razon social de la empresa
   */
  const handleRequestCertificate = (data, personType, enterpriseName) => {
    props.requestCertificateAction(
      data,
      personType,
      enterpriseName,
      setLoadingRequestCertificate,
      handleCloseRequestCertificate
    );
  };

  /**
   * Cierra el modal de la peticion de certificado
   */
  const handleCloseRequestCertificate = () => {
    if (!loadingRequestCertificate) {
      setOpenCertificateModal(false);
      setCertificateData({});
    }
  };

  return (
    <>
      <div
        className={READING_MODE ? classes.readingMode : classes.readingModeOff}
      >
        <img src={AlertIcon} alt="AlertIcon" style={{ marginRight: "12px" }} />
        DIAN está realizando mantenimiento en el sistema de Facturación
        Electrónica. Durante este tiempo, no podrás emitir facturas ni
        documentos electrónicos.
      </div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={READING_MODE ? { marginTop: "40px" } : {}}
        >
          <Toolbar className={classes.toolbarTitleContent}>
            <Box className={classes.boxTitle}>
              <Grid item xs={10} xl={6} lg={10} md={6}>
                {informationBulk !== null &&
                  stateBulkLoad !== 0 &&
                  HeaderView() !== "/invoice" && (
                    <BulkLoadStatus
                      cargueId={informationBulk.id}
                      cantidad={informationBulk.cantidad}
                      procesadas={informationBulk.procesada}
                      estado={stateBulk(informationBulk.estado)}
                      global={false}
                      view={1}
                    />
                  )}
              </Grid>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h5"
                color="inherit"
                noWrap
                className={classes.toolbarTitle}
              ></Typography>
              <Typography
                variant="h6"
                noWrap
                className={classes.wrapTypography}
              >
                <Grid container className={classes.navWrapperUser}>
                  <Grid
                    item
                    lg={12}
                    mg={12}
                    xs={12}
                    className={classes.navWrapperUser}
                  >
                    <span className={classes.navUser}>
                      {!!company?.companyName ? company?.companyName : ""}
                    </span>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.navWrapperUser}
                  >
                    <div style={{ background: "red" }}>Hola Mundo</div>
                    <span className={classes.navUserEmail}>
                      {!!company?.userEmail ? company?.userEmail : ""}
                    </span>
                  </Grid>
                </Grid>
              </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                edge="end"
                className="iconAccount"
              />

              <Avatar
                alt={!!company?.userName ? company?.userName.toUpperCase() : ""}
                src="/static/image/3.jpg"
              />
              {/* <IconButton edge="end" aria-label="show 3 new notifications" color="inherit">
                        <Badge badgeContent={3} color="secondary">
                        <NotificationImportant />
                        </Badge>
                    </IconButton>
                    */}
            </Box>
          </Toolbar>
        </AppBar>
        <nav
          className={`${classes.drawer} 'custom-drawer'`}
          aria-label="mailbox folders"
          style={READING_MODE ? { marginTop: "40px" } : {}}
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main
          className={classes.content}
          style={READING_MODE ? { marginTop: "40px" } : {}}
        >
          <div className={classes.toolbar} />
          <Component {...props} history={props.history} />
        </main>

        <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleResponseModal}
        />

        <SecondCustomModal
          onClose={handleCloseRequestCertificate}
          open={openCertificateModal}
          component={
            <CertificateRenewalForm
              data={certificateData}
              handleRequestCertificate={handleRequestCertificate}
              loadingRequestCertificate={loadingRequestCertificate}
            />
          }
          title={"Renueva tu certificado"}
          showCloseButton={true}
          width={790}
        />
      </div>
    </>
  );
};

MasterPage.propTypes = {
  container: PropTypes.any,
  getStatesAction: PropTypes.func.isRequired,
  configReducer: PropTypes.object.isRequired,
  generalReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configReducer: state.configReducer,
  generalReducer: state.generalReducer,
  basicDataReducer: state.basicDataReducer,
  informationBulk: state.invoiceReducer.informationBulkLoad,
  stateBulkLoad: state.invoiceReducer.stateBulkLoad,
  resultData: state.invoiceReducer.resultData,
  conexion: state.configReducer.connectionSignal,
  flagConnection: state.configReducer.flagConnection,
  informacion: state.invoiceReducer.informationBulkLoad,
  menuDashboard: state.menuReducer.menuDashboard,
});

export default connect(mapStateToProps, {
  getStatesAction,
  logoutUserAction,
  changeStepAction,
  getConfigurableMenuAction,
  getMenusAction,
  getFrequentQuestions,
  getReportProblem,
  getSecurityPolicy,
  getreason,
  getIssueAction,
  getTypeDocumentReferenceAction,
  joinGroupSignalAction,
  resultSignal,
  connectionSignalAction,
  changeStateFlagAction,
  getCertificateAction,
  requestCertificateAction,
})(MasterPage);
