
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core'
import { DocumentChargeCard } from '../charges/documentChargeCard';
import { DocumentDiscountCard } from '../discounts/documentDiscountCard';
import { RefDocumentReviewCard } from '../reference/refDocumentReviewCard';
import { formatDate } from '../../../utils/general';



const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primaryColor,
        backgroundColor: 'rgba(216,216,216,0.09)'
    },
    title: {
        fontsize: 16,
    },
    containerTitle: {
        textAlign: 'center',
        padding: '20px 10px',
        fontWeight: 600,
        borderBottom: '0.1px solid #16B1F3',
        marginBottom: 50
    },
    containerData: {
        backgroundColor: '#4B4B4B',
        borderRadius: 5,
        padding: 25
    },
    containerHeader: {
        backgroundColor: '#5E5E5E',
        borderRadius: 5,
        padding: 10,
        marginBottom: 20
    },
    numberContainer: {
        margin: '10px auto'
    },
    seccionTitle: {
        minHeight: 36,
        width: '100%',
        borderRadius: 5,
        backgroundColor: 'rgba(18,18,18,0.3)',
        color: '#2CC63E',
        fontFamily: 'Muli',
        fontSize: 18,
        fontWeight: 600,
        textAlign: 'center',
        paddingTop: '0.8%',
        margin: '15px auto'
    },
    gridData: {
        display: 'grid',
        width: '100%',
    },
    grayline: {
        height: 1,
        width: '100%',
        borderBottom: '1px solid gray',
        marginTop: 5,
    },
    label: {
        color: 'rgba(255,255,255,0.5)',
        fontSize: 12
    },
    valueTotal: {
        color: '#2CC63E',
        fontSize: 20,
        fontWeight: 600,
    },
    legend: {
        fontSize: '0.9rem',
        fontStyle: 'italic',
        color: 'rgba(255,255,255,0.5)',
    },
    containerValue: {
        textAlign: 'center',
        margin: '10px auto'
    },
    name: {
        fontSize: '1.2rem',
        color: '#F6D746',
        fontWeight: 800
    },
    wordBreak: {
        wordBreak: 'break-all'
    },
    flexContent: {
        display: 'flex'
    },
    icon: {
        color: '#2CC63E',
        marginLeft: 0,
        marginRight: 10,
        fontSize: '1.3rem'
    },
    containerActions: {
        backgroundColor: '#393939',
        padding: 0
    },
    button: {
        borderRadius: '15px !important',
    },
    labelButton: {
        flexDirection: 'column',
        fontSize: 12,
        color: theme.palette.primaryColor
    },
    labelHorizontal: {
        flexDirection: 'row',
        fontSize: 12,
        color: theme.palette.primaryColor
    },
    iconAction: {
        color: 'white',
        fontSize: '1.3rem'
    }
}));


export const AdditionalDataReview = React.memo(({
    opdocumentocargos,
    opdocumentodescuentos,
    opdocumentosreferencia,
    dianformapagodescripcion,
    dianmediopagodescripcion,
    fecha,
    fechapago,
    observaciones,
    vendedor,
    dianformapagoid,
    motivonota,
    typedocument
}) => {

    const classes = useStyles();
    return (
        <Grid container>
            <Grid item lg={12} xs={12}>

                {!motivonota &&
                    <Grid container style={{ fontWeight: 500 }}>
                        <Grid item lg={4} xs={12} >
                            <div className={classes.gridData} style={{ paddingRight: 10 }}>
                                <span className={classes.label}>{typedocument === "CO" ? "Fecha Cotización" : "Fecha de Factura"}</span>
                                <span>{formatDate(fecha, "dd/MM/yyyy")}</span>
                                <div className={classes.grayline} />
                            </div>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <div className={classes.gridData} style={{ paddingRight: 10 }}>
                                <span className={classes.label}>Forma de Pago</span>
                                <span>{dianformapagodescripcion === "" ? "-" : dianformapagodescripcion}</span>
                                <div className={classes.grayline} />
                            </div>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <div className={classes.gridData} style={{ paddingRight: 10 }}>
                                <span className={classes.label}>{typedocument === "CO" ? "Fecha Vigencia" : "Fecha de Pago"}  </span>
                                <span>
                                    {
                                        (fechapago === "" || dianformapagoid === 1) //Contado
                                            ? "-"
                                            : formatDate(fechapago, "dd/MM/yyyy")
                                    }
                                </span>
                                <div className={classes.grayline} />
                            </div>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <div className={classes.gridData} style={{ paddingRight: 10 }}>
                                <span className={classes.label}>Medio de Pago</span>
                                <span>{!dianmediopagodescripcion ? "-" : dianmediopagodescripcion}</span>
                                <div className={classes.grayline} />
                            </div>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <div className={classes.gridData} style={{ paddingRight: 10 }}>
                                <span className={classes.label}>Vendedor</span>
                                <span>{!!vendedor ? vendedor.text : "-"}</span>
                                <div className={classes.grayline} />
                            </div>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <div className={classes.gridData} style={{ paddingRight: 10 }}>
                                <span className={classes.label}>Observaciones</span>
                                <span>{observaciones === "" ? "-" : observaciones}</span>
                                <div className={classes.grayline} />
                            </div>
                        </Grid>
                    </Grid>
                }

            </Grid>


            <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                <span>Cargos</span>
            </Grid>
            <Grid item lg={12} xs={12} >
                <Grid container spacing={1}>
                    {opdocumentocargos?.map((item) => (
                        <Grid item key={item.id} lg={6} xs={6}>
                            <DocumentChargeCard
                                data={item}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>


            <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                <span>Descuentos</span>
            </Grid>
            <Grid item lg={12} xs={12} >
                <Grid container spacing={1}>
                    {opdocumentodescuentos?.map((item) => (
                        <Grid item key={item.id} lg={6} xs={6}>
                            <DocumentDiscountCard
                                data={item}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            {typedocument === "FA" &&
                <>
                    <Grid item lg={12} xs={12} className={classes.seccionTitle}>
                        <span>Documentos de referencia</span>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={1}>
                            {opdocumentosreferencia?.map((item) => (
                                <Grid item key={item.id} lg={6} xs={6}>
                                    <RefDocumentReviewCard
                                        {...item}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </>
            }


        </Grid>
    );

})
