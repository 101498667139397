import React from "react";
import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

export const ExcelIntegration = ({ element, filename, data }) => {
  return (
    <>
      <ExcelFile element={element} filename={filename}>
        <ExcelSheet data={data} name="Documentos">
          <ExcelColumn label="Tipo Identificación" value="TipoIdentificacion" />
          <ExcelColumn label="Documento" value="Documento" />
          <ExcelColumn label="Nombre" value="Nombre" />
          <ExcelColumn label="Correo electronico" value="CorreoElectronico" />
          <ExcelColumn label="Tipo documento" value="TipoDocumentoElectronico" />
          <ExcelColumn label="Numero" value="Numero" />
          <ExcelColumn label="Fecha" value="Fecha" />
          <ExcelColumn label="Valor" value="Valor" />
          <ExcelColumn label="Código Único" value="CodigoUnico" />
          <ExcelColumn label="Datos QR" value="DatosQR" />
          <ExcelColumn label="Estado Dian" value="EstadoDian" />
          <ExcelColumn label="Fecha Acuse Dian" value="FechaAcuseDian" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
