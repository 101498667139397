import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";

import ArchiveModal from "../../../components/modal/archiveModal.component";
import CustomProgress from "../../../components/Progress/progress.component";
import SimpleModal from "../../../components/modal/simpleModal.component";
import DocSupportTypeNote from "../UpdateDocSupport/DocSupportTypeNote";
import AnnulmentNoteDocSupport from "../UpdateDocSupport/AnnulmentNoteDocSupport";
import ModalFileForm from "../../../views/invoice/modal-file-form.component";

import {
  cleanDocumentSupportAction,
  createNoteAdjusment,
  getDocumentSupportConsultForIdAction,
  updateDocumentSupportAction,
} from "../../../actions/documentSupportAction";
import { checkCertificateToEditInvoiceAction } from "../../../actions/basicDataActions";
import {
  cleanDocumentCreateAction,
  loadNoteDocumentDataAction,
} from "../../../actions/documentAction";

import imageMoney from "../../../../src/images/Icon-Shape.png";

import { formatDate } from "../../../utils/general";
import { ConfirmAlert, WarningAlert } from "../../../helpers/alert.helpers";
import { getDataNoteDocSupport } from "../../../helpers/dataDocumentSaved";
import { generateRequestNoteAdjusment } from "../../../helpers/generateRequestDocument";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../helpers/toast.helpers";

import { MESSAGE_NEXT_CONFIRM, READING_MODE } from "../../../config/config";
import { showCartDocumentSupportAction } from "../../../actions/documentCartAction";
import ResponseModal from "../../../components/modal/responseModal.component";
import { TooltipMessageReadingMode } from "../../../components/tooltip/TooltipMessageReadingMode";

const FooterButtonsSupport = ({
  loadingUpdateStatusDocSupport,
  loadingUpdateStatusDocSupportFavorite,
  detailDocumentSupportid,
  setDocumentSelected,
  handleEdit,
  detailDocumentSupport,
  modalError,
  setmodalError,
}) => {
  const dispatch = useDispatch();
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [base64XML, setBase64XML] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalMail, setOpenModalMail] = useState(false);
  const [mailData, setMailData] = React.useState({});
  const [email, setEmail] = useState("");
  const [openModalAnnulment, setOpenModalAnnulment] = useState(false);
  const [loadingNoteAnnulment, setLoadingNoteAnnulment] = useState(false);
  const [modalData, setModalData] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  const totals = useSelector((state) => state.documentCartReducer);
  const { documentSupportFilter, sendMailDocSupport } = useSelector(
    (state) => state.documentSupportReducer
  );
  const document = documentSupportFilter.find(
    (item) => item.id === detailDocumentSupportid
  );

  useEffect(() => {
    cleanMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMailDocSupport]);

  useEffect(() => {
    if (modalError === 1 || modalError === 2) {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Oops!!!",
        subtitle: "Documento no disponible",
        body: (
          <div>
            {`Documento en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá ${
              modalError === 2 ? "visualizarlo." : "enviarlo."
            }`}
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalError]);

  /**
   * * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response?.result[0]?.archivoBase64);
    setBase64XML(response?.result[1]?.archivoBase64);
    setLoadingFile(false);
  };

  // *Cierra modal previsualización documento

  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
    setBase64XML(null);
  };

  //  * Genera previsualización de documento
  //  * @param {*} e
  //  */
  const handlePreviewDocument = (data) => {
    if (
      data.document.tipoestadodocumentoid === 3 ||
      data.document.tipoestadodocumentoid === 2
    ) {
      setmodalError(2);
    } else {
      // e.preventDefault();
      setOpenModalFile(true);
      setLoadingFile(true);
      dispatch(getDocumentSupportConsultForIdAction(readResponseGetFile));
    }
  };

  /**
   *  * Obtiene nombre del boton del cambio de estado
   * @param {*} item
   * @returns
   */
  const getNameChangeState = (item) => {
    if (item.anulacion === true) return "Anulada";

    if (item.estadoid === "03") return "En mora";

    if (item.estadoid === "02") return "Por Pagar";

    return "Pagada";
  };

  /**
   *  * Evento, controla cambio de estado en documentos
   */
  const handlePayDocument = () => {
    const changeStatus = (confirmed) => {
      if (confirmed) {
        let data = {
          Id: document.id,
          Pagado: document.estadoid === "01" ? false : true,
          Operacion: "PAGADO",
        };
        dispatch(updateDocumentSupportAction(data, setDocumentSelected));
      }
    };

    stateInvoice(document);
    if (document?.anulacion) {
      WarningAlert("Upsss...!", "El Documento Soporte se encuentra anulado.");
    } else {
      const messageText =
        'Actualmente el Documento Soporte se encuentra en estado "' +
        getNameChangeState(document) +
        '" ¿Desea cambiar el estado del Documento Soporte a "' +
        stateInvoice(document) +
        '"?';

      ConfirmAlert(messageText, changeStatus, MESSAGE_NEXT_CONFIRM);
    }
  };

  /**
   * * Determinar el estado al cual pasa el Documento Soporte, cuando se le va a cambiar un estado.
   */
  const stateInvoice = (state) => {
    if (state?.anulacion) {
      return "Anulada";
    }

    if (!!state?.fechapago) {
      let payDate = new Date(
        formatDate(new Date(state.fechapago), "yyyy/MM/dd")
      );
      let currentDate = new Date(formatDate(new Date(), "yyyy/MM/dd"));

      if (state?.estadoid === "02" || state.estadoid === "03") {
        return "Pagada";
      }

      if (payDate > currentDate) {
        return "Por pagar";
      } else {
        return "En mora";
      }
    }

    if (state?.estadoid === "01") {
      return "Por pagar";
    }
    if (state?.estadoid === "02") {
      return "Pagada";
    }
  };

  /**
   * * Escucha evento para edición de Documento Soporte, generear Nota
   *  @param {object} docSupport  Información Documento Soporte
   */
  const editDocSupport = (docSupport, detailDocumentSupport) => {
    const documentSupport = {
      ...docSupport,
      ...detailDocumentSupport,
    };

    if (docSupport.anulacion === true) {
      warningToast(
        "No es posible editar un Documento soporte anulado",
        "",
        "top-center"
      );
      return;
    }

    if ([2, 3].includes(documentSupport.tipoestadodocumentoid)) {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Upss...!!!",
        subtitle: `No puedes editar este documento.`,
        body: (
          <div>
            {
              "No puedes editar este documento por que aun no se ha sincronizado con la DIAN."
            }
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
    } else {
      dispatch(
        checkCertificateToEditInvoiceAction(
          checkCertificateToEditDocSupport,
          loadingEditDocSupport,
          documentSupport
        )
      );
    }
  };
  /**
   * *Valida  si cuenta con certificado vigente para edición de Documento Soporte
   * @param {*} data Información certificado
   * @param {*} docSupport Información Documento soporte registrado en BD
   */
  const checkCertificateToEditDocSupport = (data, docSupport) => {
    //Leer respuesta certificado
    let requiere = data?.requiere ?? true;
    let activo = data?.activo === true ? true : false;

    if (requiere === true && activo === false) {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Upss...!!!",
        subtitle: `Esta opción aún no está disponible.`,
        body: (
          <div>
            {"Todavía no cuentas con un certificado de firma digital vigente."}
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
    } else {
      dispatch(showCartDocumentSupportAction(true));
      const document = getDataNoteDocSupport(docSupport);
      dispatch(loadNoteDocumentDataAction(document));
      setOpenModalEdit(true);
    }
  };

  /**
   *  * Actualiza estado loading para edición de Documento Soporte
   * @param {boolean} status Nuevo valor loading editar
   */
  const loadingEditDocSupport = (status) => {
    setLoadingEdit(status);
  };

  const handleFavourite = () => {
    let data = {
      Id: document.id,
      Operacion: "FAVORITO",
      Favorito: !document.favorito,
    };

    dispatch(updateDocumentSupportAction(data, setDocumentSelected));
  };

  /**
   * * Evento click cerrar modal confirmación tipo nota
   */
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  /**
   * Confirmar tipo nota a generar
   * @param {*} type Tipo nota
   */
  const confirmEditInvoice = (type) => {
    if (type === "ANU") {
      setOpenModalEdit(false);
      setOpenModalAnnulment(true);
    } else {
      setOpenModalEdit(false);
      handleEdit(false, true);
    }
  };

  /**
   * * Evento click cerrar modal anular Documento Soporte
   */
  const handleCloseModalAnnulment = () => {
    setOpenModalAnnulment(false);
  };

  /**
   * * Confirmar anulación factura
   */
  const confirmAnnulmentDocSupport = (observations) => {
    setLoadingNoteAnnulment(true);
    const docSupport = documentSupportFilter.find(
      (item) => item.id === detailDocumentSupport.id
    );
    docSupport.codigoTipoTransmision =
      detailDocumentSupport.codigoTipoTransmision;
    const note = generateRequestNoteAdjusment(
      docSupport,
      totals,
      observations,
      true
    );
    dispatch(
      createNoteAdjusment(note, confirmSendOkAnnulment, confirmErrorAnnulment)
    );
  };

  /**
   *  * Lee response exitoso anulación Documento Soporte
   * @param {*} response
   */
  const confirmSendOkAnnulment = (response) => {
    setLoadingNoteAnnulment(false);
    setOpenModalAnnulment(false);
    if (response.data.statusCode === "201")
      successToast(
        "",
        `El Documento Soporte ${detailDocumentSupport.prefijo}-${detailDocumentSupport.consecutivo} fue anulada de manera exitosa. Sigamos facturando`,
        "top-center"
      );
    else
      warningToast(
        "Ocurrió algo inesperado",
        `El Documento Soporte  ${detailDocumentSupport.prefijo}-${detailDocumentSupport.consecutivo} fue anulada de manera exitosa, ${response.data.result}. ${response.data.statusMessage}`
      );
    dispatch(cleanDocumentSupportAction());
    dispatch(cleanDocumentCreateAction());
    handleEdit(true, false);
  };

  /**
   * * Lee response con error anulación Documento Soporte
   * @param {*} response
   */
  const confirmErrorAnnulment = (response) => {
    setLoadingNoteAnnulment(false);
    setOpenModalAnnulment(false);
    if (response?.data?.statusMessage !== "Unauthorized, no quota") {
      errorToast(
        "Ocurrió algo inesperado",
        `${JSON.stringify(
          response?.data?.statusMessage ??
            "Error inesperado, no se ha podido anular el Documeto Soporte"
        )}`
      );
    }
    dispatch(cleanDocumentSupportAction());
    dispatch(cleanDocumentCreateAction());
    handleEdit(true, false);
  };

  /**
   *  * Cierra Modal, Notificación certificado no valido
   */
  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
    setmodalError(null);
  };

  /**
   *  * Cierra modal reenvio documento
   */
  const handleCloseModalMail = () => {
    setOpenModalMail(false);
  };

  /**
   * Modal filtro reenvio documento
   */
  const modalMails = () => {
    return (
      <ModalFileForm
        email={email}
        isInvoiceMail={false}
        isQuotationMail={false}
        isDocSupportMail={true}
        data={mailData}
        type={"DOC"}
      />
    );
  };

  /**
   * Limpiar email
   */
  const cleanMail = () => {
    setOpenModalMail(false);
    setMailData({});
    setEmail("");
    // setIsInvoiceMail(true);
  };

  /**
   * Abre notificción reenvio de documento
   * @param {*} email
   * @param {*} isInvoiceMail
   * @param {*} tipodocumentoentidad
   * @param {*} documentompresa
   * @param {*} codigounico
   * @param {*} numero
   * @param {*} razonsocialempresa
   * @param {*} razonsocialcliente
   */
  const openMailModal = (data) => {
    if (
      data.document.tipoestadodocumentoid === 3 ||
      data.document.tipoestadodocumentoid === 2
    ) {
      setModalData({
        ...modalData,
        type: "warning",
        title: "Oops!!!",
        subtitle: "Documento no disponible",
        body: (
          <div>
            {
              "Documento en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá enviarlo."
            }
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Cerrar",
        onCloseElement: 0,
      });
    } else {
      setEmail(data.detailDocumentSupport.correoElectronico);
      setMailData({
        TipoDocumento: "31",
        Documento: data.detailDocumentSupport.documentompresa,
        CodigoUnico: null,
        NumeroDocumento:
          data.document.prefijo + "-" + data.document.consecutivo,
        RazonSocialEmpresa: data.detailDocumentSupport.razonSocialEmpresa,
        RazonSocialCliente: data.detailDocumentSupport.razonSocial,
        DocumentoId: data.document.id,
      });
      // setIsInvoiceMail(isInvoiceMail);
      setOpenModalMail(true);
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2F2E2E",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),
    },
  }))(Tooltip);

  return (
    <div className="bottomButtonsSupport">
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <>
            {loadingUpdateStatusDocSupportFavorite ? (
              <CustomProgress />
            ) : document?.favorito ? (
              <StarIcon
                className="buttonInvoiceBehavior"
                onClick={handleFavourite}
              />
            ) : (
              <StarBorderIcon
                className="buttonInvoiceBehavior"
                onClick={handleFavourite}
              />
            )}
            <div className="textButtonsSupport">Destacar</div>
          </>
        </Grid>
        <Grid item xs={2}>
          <>
            {loadingUpdateStatusDocSupport ? (
              <CustomProgress />
            ) : (
              <div title="Cambiar estado factura">
                <div
                  className={
                    document?.estadoid === "01" ? "" : "panelInvisibleInvoice"
                  }
                >
                  <img
                    alt="Pagada"
                    src={imageMoney}
                    onClick={handlePayDocument}
                    className="buttonInvoiceBehavior"
                  />
                </div>
                <div
                  className={
                    document?.estadoid !== "01" ? "" : "panelInvisibleInvoice"
                  }
                >
                  <img
                    alt="Pagada"
                    src={imageMoney}
                    onClick={handlePayDocument}
                    className="buttonInvoiceBehavior"
                  />
                </div>
                <div className="textButtonsInvoice">
                  {stateInvoice(document)}
                </div>
              </div>
            )}
          </>
        </Grid>
        <Grid
          item
          xs={2}
          onClick={() =>
            handlePreviewDocument({ document, detailDocumentSupport })
          }
        >
          <VisibilityIcon className="buttonInvoiceBehavior" />
          <div className="textButtonsSupport">Ver</div>
        </Grid>
        <Grid item xs={2}>
          {loadingEdit ? (
            <CustomProgress size={20} />
          ) : (
            <>
              <HtmlTooltip
                title={READING_MODE ? <TooltipMessageReadingMode /> : ""}
              >
                <span>
                  <EditIcon
                    className="buttonInvoiceBehavior"
                    style={READING_MODE ? { color: "#919191" } : {}}
                    onClick={
                      !READING_MODE
                        ? () => editDocSupport(document, detailDocumentSupport)
                        : null
                    }
                  />
                  <br />
                  <div
                    className="textButtonsInvoice"
                    style={READING_MODE ? { color: "#919191" } : {}}
                  >
                    Editar
                  </div>
                </span>
              </HtmlTooltip>
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          <EmailIcon
            className="buttonInvoiceBehavior"
            onClick={() => openMailModal({ document, detailDocumentSupport })}
          />
          <div className="textButtonsSupport">Enviar</div>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <SimpleModal
        onClose={handleCloseModalEdit}
        open={openModalEdit}
        title={""}
        width={800}
        noPadding={true}
        component={() => <DocSupportTypeNote onClick={confirmEditInvoice} />}
      />
      <SimpleModal
        onClose={handleCloseModalAnnulment}
        open={openModalAnnulment}
        title={""}
        width={800}
        noPadding={true}
        disabled={loadingNoteAnnulment}
        disableBackdropClick={loadingNoteAnnulment}
        component={() => (
          <AnnulmentNoteDocSupport
            onClick={confirmAnnulmentDocSupport}
            number={
              `${detailDocumentSupport.prefijo}${detailDocumentSupport.consecutivo}` ??
              ""
            }
            loading={loadingNoteAnnulment}
          />
        )}
      />
      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleResponseModal}
      />
      <SimpleModal
        onClose={handleCloseModalMail}
        open={openModalMail}
        component={modalMails}
        title={"Envio de Documento Soporte"}
        width={365}
        showCloseButton={false}
      />
      <ArchiveModal
        loading={loadingFile}
        open={openModalFile}
        pdfBase64={base64File}
        xmlBase64={base64XML}
        handleClose={handleCloseFileModal}
      />
    </div>
  );
};

export default FooterButtonsSupport;
