import React, { Component } from "react"; //Formateo
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { regexNames } from "../../../helpers/customRegex.hepers";
import InputForm from "../../../components/input/input.component";
import SwitchForm from "../../../components/switch/switch.component.js";
import AutoCompleteForm from "../../../components/autocomplete/autocomplete.component";
import MultiSelectForm from "../../../components/multiselect/multiselect.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import ResponseRedirectModal from "../../../components/modal/responseRedirectModal.component";

import {
  updateBranchOfficeAction,
  saveBranchOfficeAction,
  loadBranchOfficeAction,
  cleanLicenseResponse,
  getBranchOfficeAction,
  updateOpenModalItem,
  updatePrincipalBranchOfficeAction,
} from "../../../actions/branchOfficeActions";
import {
  updateNumerationAction,
  getNumerationsFilterAction,
} from "../../../actions/numerationActions";
import {
  getStatesAction,
  getAllCitiesAction,
} from "../../../actions/configAction";
import { CLAIM_BRANCH_OFFICES } from "../../../config/config";

import {
  SuccessAlert,
  WarningAlert,
  ConfirmAlert,
} from "../../../helpers/alert.helpers";
import { getComponentName } from "../../../utils/general";
import isEmpty from "../../../utils/isEmpty";
import {
  MESSAGE_NEXT_CONFIRM,
  TIME_UPDATE_REGISTER,
  TIME_CREATE_REGISTER,
} from "../../../config/config";
import ResponseModal from "../../../components/modal/responseModal.component";

class FormBranchOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchOfficesList: [],
      requestCompany: {},
      components: [],
      modalities: [],
      numerations: [],
      numerationsFree: [],
      numerationsOptions: [],
      principal: {},
      oldPrincipal: {},
      editBranchStatus: false,
      loadBranchOffice: {},
      responseSaveBranchOffice: [],
      responseUpdateBranchOffice: [],
      form: {
        nombre: "",
        principal: false,
        numeraciones: [],
        direccion: "",
        departamentoid: null,
        ciudadid: null,
        idstate: true,
      },
      loadingSaveBranch: false,
      getStatesResponse: [],
      allCitiesCountry: [],
      cities: [],
      filteredBranchOfficesList: [],
      modalLicense: false,
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getStatesAction();
    this.props.getAllCitiesAction();
    this.props.getNumerationsFilterAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.configReducer.getStatesResponse &&
      nextProps.configReducer.getStatesResponse !== state.getStatesResponse
    )
      update.getStatesResponse = nextProps.configReducer.getStatesResponse;

    if (
      !!nextProps.configReducer.allCitiesCountry &&
      nextProps.configReducer.allCitiesCountry !== state.allCitiesCountry
    )
      update.allCitiesCountry = nextProps.configReducer.allCitiesCountry;

    if (
      !!nextProps.numerationReducer.numerations &&
      nextProps.numerationReducer.numerations !== state.numerations
    ) {
      update.numerations = nextProps.numerationReducer.numerations;
      update.numerationsOptions = nextProps.numerationReducer.numerations.filter(
        (n) => n.sucursalempresaid === null && n.idstate === 0
      );
    }

    if (!!nextProps.numerationReducer.modalities) {
      if (nextProps.numerationReducer.modalities !== state.modalities) {
        update.modalities = nextProps.numerationReducer.modalities;
      }
    }

    if (
      !!nextProps.configReducer.components &&
      nextProps.configReducer.components !== state.components
    ) {
      update.components = nextProps.configReducer.components;
    }

    if (
      !!nextProps.basicDataReducer.requestCompany &&
      nextProps.basicDataReducer.requestCompany !== state.requestCompany
    ) {
      update.requestCompany = nextProps.basicDataReducer.requestCompany;
    }

    if (
      !!nextProps.branchOfficeReducer.branchOfficesList &&
      nextProps.branchOfficeReducer.branchOfficesList !==
        state.branchOfficesList
    ) {
      update.branchOfficesList =
        nextProps.branchOfficeReducer.branchOfficesList;
      update.principal = nextProps.branchOfficeReducer.branchOfficesList.find(
        (o) => o.principal === true
      );
    }

    if (
      !isEmpty(nextProps.branchOfficeReducer.loadBranchOffice) &&
      nextProps.branchOfficeReducer.loadBranchOffice !== state.loadBranchOffice
    ) {
      update.loadBranchOffice = nextProps.branchOfficeReducer.loadBranchOffice;
    }

    if (
      !!nextProps.branchOfficeReducer.loadBranchOffice &&
      nextProps.branchOfficeReducer.loadBranchOffice !== state.loadBranchOffice
    )
      update.loadBranchOffice = nextProps.branchOfficeReducer.loadBranchOffice;

    if (
      !!nextProps.branchOfficeReducer.responseSaveBranchOffice &&
      nextProps.branchOfficeReducer.responseSaveBranchOffice !==
        state.responseSaveBranchOffice
    ) {
      update.responseSaveBranchOffice =
        nextProps.branchOfficeReducer.responseSaveBranchOffice;
    }

    if (
      !!nextProps.branchOfficeReducer.responseUpdateBranchOffice &&
      nextProps.branchOfficeReducer.responseUpdateBranchOffice !==
        state.responseUpdateBranchOffice
    ) {
      update.responseUpdateBranchOffice =
        nextProps.branchOfficeReducer.responseUpdateBranchOffice;
    }

    if (
      !isEmpty(nextProps.branchOfficeReducer.loadingSaveBranch) &&
      nextProps.branchOfficeReducer.loadingSaveBranch !==
        state.loadingSaveBranch
    )
      update.loadingSaveBranch =
        nextProps.branchOfficeReducer.loadingSaveBranch;

    if (
      !!nextProps.branchOfficeReducer.filteredBranchOfficesList &&
      nextProps.branchOfficeReducer.filteredBranchOfficesList !==
        state.filteredBranchOfficesList
    )
      update.filteredBranchOfficesList =
        nextProps.branchOfficeReducer.filteredBranchOfficesList;

    if (
      nextProps.branchOfficeReducer.editBranchStatus !== state.editBranchStatus
    )
      update.editBranchStatus = nextProps.branchOfficeReducer.editBranchStatus;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.licenseBranches?.result &&
      !this.props.licenseBranches.result.licenseActiveBase &&
      !this.state.modalLicense
    ) {
      if (prevState.modalLicense === this.state.modalLicense) {
        this.setState({
          ...this.state,
          modalLicense: true,
        });
      }
    }
    if (
      prevState.responseSaveBranchOffice !== this.state.responseSaveBranchOffice
    ) {
      if (
        this.state.responseSaveBranchOffice.statusCode === "200" ||
        this.state.responseSaveBranchOffice.statusCode === "201"
      ) {
        SuccessAlert(
          null,
          getComponentName(
            this.state.components,
            24,
            90,
            "Creaste una nueva sucursal de manera exitosa, sigamos Facturando"
          ),
          TIME_CREATE_REGISTER
        );
        this.props.getBranchOfficeAction();
        this.props.getNumerationsFilterAction();
        this.cleanData();
      }
    }

    if (
      prevState.responseUpdateBranchOffice !==
      this.state.responseUpdateBranchOffice
    ) {
      if (
        this.state.responseUpdateBranchOffice.statusCode === "200" ||
        this.state.responseUpdateBranchOffice.statusCode === "201"
      ) {
        SuccessAlert(
          null,
          getComponentName(
            this.state.components,
            25,
            94,
            "Actualizaste la Sucursal $nombreSucursal de manera exitosa, sigamos facturando."
          ).replace("$nombreSucursal", this.state.loadBranchOffice.nombre),
          TIME_UPDATE_REGISTER
        );
        this.props.getBranchOfficeAction();
        this.props.getNumerationsFilterAction();
        this.cleanData();
      }
    }

    if (
      prevState.responseSaveBranchOffice !== this.state.responseSaveBranchOffice
    ) {
      const statusCode = this.state.responseSaveBranchOffice.statusCode;
      if (statusCode === "200" || statusCode === "201") {
        SuccessAlert(
          null,
          getComponentName(
            this.state.components,
            24,
            90,
            "Creaste una nueva sucursal para tu empresade manera exitosa, sigamos Facturando"
          ),
          TIME_CREATE_REGISTER
        );
        this.props.getBranchOfficeAction();
        this.props.getNumerationsFilterAction();
        this.cleanData();
      }
    }

    if (
      prevState.responseUpdateBranchOffice !==
      this.state.responseUpdateBranchOffice
    ) {
      const statusCode = this.state.responseUpdateBranchOffice.statusCode;
      if (
        statusCode === "200" ||
        statusCode === "201" ||
        statusCode === "203"
      ) {
        if (statusCode === "203") {
          WarningAlert(
            "Upss...!",
            "No se ha podido actualizar listado numeraciones asociadas."
          );
        } else {
          SuccessAlert(
            null,
            getComponentName(
              this.state.components,
              25,
              94,
              "Actualizaste la Sucursal $nombreSucursal de manera exitosa, sigamos facturando."
            ).replace("$nombreSucursal", this.state.loadBranchOffice.nombre),
            TIME_UPDATE_REGISTER
          );
        }
        this.props.getBranchOfficeAction();
        this.props.getNumerationsFilterAction();
        this.cleanData();
      }
    }

    if (prevState.loadBranchOffice !== this.state.loadBranchOffice) {
      if (
        !!this.state.loadBranchOffice &&
        Object.keys(this.state.loadBranchOffice).length > 0
      ) {
        this.loadInfo(this.state.loadBranchOffice);
      } else {
        this.cleanData(false);
      }
    }
  }

  /**
   * Sincroniza nuevo valor de input con state
   * @param {*} e Evento
   */
  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  };

  /**
   * Sincroniza nuevo valor de checkBox con state
   * @param {*} e Evento
   */
  syncCheckChange = (e) => {
    const name = e.target.name;
    let checked = e.target.checked;
    if (name === "principal") {
      this.changePrincipal(checked);
    } else if (name === "idstate") {
      this.changeState(checked);
    }
  };

  onCloseModalItem = (e) => {
    this.props.updateOpenModalItem(false);
  };

  handleCloseResponseModal = () => {
    this.props.cleanLicenseResponse();
    this.setState({
      ...this.state,
      modalLicense: false,
    });
  };

  /**
   * Limpia información de formulario
   */
  cleanData(cleanRedux = true) {
    this.setState({
      form: {
        nombre: "",
        principal: false,
        numeraciones: [],
        direccion: "",
        departamentoid: "",
        ciudadid: "",
        idstate: true,
      },
      loadBranchOffice: null,
    });
    this.validator.hideMessages();
    this.forceUpdate();

    if (cleanRedux) this.props.loadBranchOfficeAction(null);
  }

  onCloseModalItem = (e) => {
    this.props.updateOpenModalItem(false);
  };

  /**
   * Actualiza sucursal principal
   * @param {*} checked Nuevo valor
   */
  changePrincipal = (checked) => {
    if (this.state.editBranchStatus) {
      //Editando sucursal, no permite actualizar estado principal
      let loadedBrach = this.state.loadBranchOffice;
      if (!!loadedBrach && loadedBrach.principal) {
        WarningAlert("Upss...!!!", "Debe existir una sucursal principal.");
        return;
      }
    }

    //Actualizando
    if (checked) {
      const setPrincipal = (confirmed) => {
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            principal: confirmed ? true : false,
          },
        }));
      };

      ConfirmAlert(
        "¿Estás seguro que quieres marcar esta sucursal como principal? solo puede existir una",
        setPrincipal,
        MESSAGE_NEXT_CONFIRM
      );
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          principal: false,
        },
      }));
    }
  };

  /**
   * Actualiza estado de una sucursal
   * @param {*} checked Nuevo estado
   */
  changeState = (checked) => {
    if (!checked && this.state.form.principal) {
      WarningAlert(
        "Upss...!!!",
        "La sucursal principal no puede estar inactiva."
      );
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          idstate: checked,
        },
      }));
    }
  };

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];

    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [options.name]: value,
      },
    }));
  };

  /**
   * Evento cambio departamento seleccionado
   * @param {*} event Evento
   * @param {*} values Nuevo valor
   */
  changeBranchState(event, values) {
    event.preventDefault();
    let newCities = [];
    if (!!values)
      newCities = this.state.allCitiesCountry.filter(
        (c) => c.departamentoid === parseInt(values.value)
      );
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        ciudadid: null,
      },
      cities: newCities,
    }));
  }

  /**
   * Sincroniza Autocmplete con state
   * @param {*} value Nuevo valor
   * @param {*} id Identificador propiedad state
   */
  syncAutoCompleteChanges(value, id) {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Elimina caracteres invalidos, solo permite valores permitidos en una dirección
   * @param {*} e Evento
   */
  validateAddress(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexNames, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Evento, Guarda información de sucursal y actualiza numeraciones liberadas
   * @param {*} e Evento
   */
  onSubmitBranchOffice = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const id = this.state.loadBranchOffice.id;
      const name = this.state.form.nombre.replaceAll(" ", "").toLowerCase();

      const existBranch = this.state.branchOfficesList.find(
        (b) =>
          b.nombre.replaceAll(" ", "").toLowerCase() === name &&
          b.id !== id &&
          b.idstate === 0
      );

      if (!!existBranch) {
        WarningAlert(
          "Sucursal existente....",
          `Ya se encuentra registrada una sucursal con el nombre ${this.state.form.nombre} `
        );
        return;
      }

      this.state.editBranchStatus === true
        ? this.updateBranchOffices()
        : this.saveBranchOffice();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  /**
   * Actualiza numeraciónes liberadas,
   * sin asinación de sucursal
   */
  updateFreeNumerations = () => {
    for (let i = 0; i < this.state.numerationsFree.length; i++) {
      let item = this.state.numerationsFree[i];
      item = {
        ...item,
        sucursalempresaid: null,
      };
      this.props.updateNumerationAction(item);
    }
  };

  /**
   * Captura y actualiza información de sucursal y numeraciones
   */
  updateBranchOffices = () => {
    let currentNumeration =
      this.state.form.numeraciones?.filter((c) => c !== null) ?? [];
    currentNumeration = currentNumeration.map((item) => ({
      ...item,
      consecactual: item.consecactual.replaceAll(" ", ""),
      consecinicial: item.consecinicial.replaceAll(" ", ""),
      consecfinal: item.consecfinal.replaceAll(" ", ""),
    }));

    let updateOffice = {
      ...this.state.loadBranchOffice,
      nombre: this.state.form.nombre,
      principal: this.state.form.principal,
      numeraciones: currentNumeration,
      direccion: this.state.form.direccion,
      ciudadid: this.state.form.ciudadid.value,
      idstate: this.state.form.idstate ? 0 : 1,
    };

    this.props.updateBranchOfficeAction(updateOffice);
  };

  //Carga información en formulario para edición
  loadInfo(data) {
    let city = this.state.allCitiesCountry.filter(
      (c) => c.value === data.ciudadid.toString()
    );
    let stateid = !!city && city.length ? city[0].departamentoid : 0;
    let state = this.state.getStatesResponse.filter(
      (s) => s.value === stateid.toString()
    );
    let cities = this.state.allCitiesCountry.filter(
      (c) => c.departamentoid === stateid
    );

    this.setState((prevState) => ({
      form: {
        nombre: data.nombre,
        principal: data.principal,
        numeraciones: data.numeraciones,
        direccion: data.direccion,
        departamentoid: state.length ? state[0] : null,
        ciudadid: city.length ? city[0] : null,
        idstate: data.idstate === 0 ? true : false,
      },
      cities: cities,
    }));
  }

  /**
   * Captura y gurda información de sucursal
   */
  saveBranchOffice() {
    let newData = [
      {
        nombre: this.state.form.nombre,
        principal: this.state.form.principal,
        numeraciones: this.state.form.numeraciones?.filter((c) => c !== null),
        direccion: this.state.form.direccion,
        ciudadid: this.state.form.ciudadid.value,
        idstate: this.state.form.idstate ? 0 : 1,
      },
    ];

    let oldPrincipal = this.state.branchOfficesList.find(
      (c) => c.principal === true
    );
    if (oldPrincipal !== undefined && this.state.form.principal === true) {
      newData = [
        ...newData,
        {
          ...oldPrincipal,
          principal: false,
        },
      ];
    }
    this.props.saveBranchOfficeAction(newData);
  }

  /**
   * Obtine descripción modalidad
   * @param {*} numeration
   */
  getNumerationName = (numeration) => {
    if (!!numeration) {
      const modality = this.state.modalities.find(
        (x) => x.id === numeration.diantipomodalidadid
      );

      return !!numeration.nombre
        ? modality?.descripcion ??
            "" +
              "- Prefijo:" +
              (!!numeration.prefijo ? numeration.prefijo : "Sin prefijo") +
              " - " +
              numeration.nombre
        : modality?.descripcion ??
            "" +
              "- Prefijo:" +
              (!!numeration.prefijo ? numeration.prefijo : "Sin prefijo");
    } else {
      return "";
    }
  };

  render() {
    //Numeraciones disponibles.
    let numerationsAvailable = this.state.numerationsOptions;
    if (this.state.editBranchStatus) {
      this.state.loadBranchOffice.numeraciones.forEach((item) => {
        let exist = this.state.numerationsOptions.find((n) => n.id === item.id);
        if (exist === undefined || exist === null) {
          numerationsAvailable.push(item);
        }
      });
    }

    let numerationsOptions = numerationsAvailable.map((item) => {
      return {
        value: item,
        text: this.getNumerationName(item),
      };
    });

    return (
      <form
        id="numerationForm"
        onSubmit={(e) => this.onSubmitBranchOffice(e)}
        noValidate
        autoComplete="off"
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={"nombre"}
              label={"Nombre *"}
              maxLength={300}
              value={this.state.form.nombre}
              onChange={this.syncChanges}
              validator={this.validator}
              validateOptions={"required|min:5|max:300"}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SwitchForm
              name="principal"
              titleOn="Principal"
              checked={this.state.form.principal}
              onChange={this.syncCheckChange}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <MultiSelectForm
              name="numeraciones"
              label={"Numeraciones"}
              value={this.state.form.numeraciones}
              options={numerationsOptions}
              optional={true}
              onChange={this.syncMultipleChange}
              renderValue={(selected) =>
                this.state.form.numeraciones
                  .map((value) => {
                    return this.getNumerationName(value);
                  })
                  .join(",")
              }
              placeholder={"Seleccione..."}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={"direccion"}
              label={"Dirección *"}
              maxLength={100}
              value={this.state.form.direccion}
              onChange={(e) => {
                this.syncChanges(e);
                this.validateAddress(e);
              }}
              validator={this.validator}
              validateOptions={"required|min:3|max:100"}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AutoCompleteForm
              label={"Departamento *"}
              name="departamentoid"
              textNoOption={"No hay registros"}
              value={this.state.form.departamentoid}
              options={this.state.getStatesResponse}
              validator={this.validator}
              validateOptions={"required"}
              onChange={(event, values) => {
                this.syncAutoCompleteChanges(values, "departamentoid");
                this.changeBranchState(event, values);
              }}
              placeholder={"Seleccione..."}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AutoCompleteForm
              label={"Ciudad *"}
              name="ciudadid"
              textNoOption={"No hay registros"}
              value={this.state.form.ciudadid}
              options={this.state.cities}
              validator={this.validator}
              validateOptions={"required"}
              onChange={(event, value) =>
                this.syncAutoCompleteChanges(value, "ciudadid")
              }
              placeholder={"Seleccione..."}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SwitchForm
              name="idstate"
              checked={this.state.form.idstate}
              titleOn={
                this.state.form.idstate
                  ? "Desactivar sucursal"
                  : "Activar sucursal"
              }
              onChange={this.syncCheckChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="flex-end" alignItems="center">
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ButtonPrimary
              text={getComponentName(this.state.components, 27, 95, "Listo")}
              loading={this.state.loadingSaveBranch}
              type={"submit"}
            />
          </Grid>
        </Grid>
        <ResponseRedirectModal
          modalType={"success"}
          title={"Upps!!!"}
          subtitle={
            "Ya usaste todas las sucursales disponibles de tu plan. \n No te preocupes, consigue todas las que necesites"
          }
          body={""}
          modalImage={"package"}
          open={this.props.modal}
          closeElement={"Skip"}
          onCloseElement={this.onCloseModalItem}
          claim={CLAIM_BRANCH_OFFICES}
        ></ResponseRedirectModal>
        <ResponseModal
          modalType={"warning"}
          title={"Tu plan de Colfactura venció"}
          body={
            <div>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 25,
                }}
              >
                {
                  "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
                }
              </p>
              <p
                style={{
                  marginTop: 10,
                  fontWeight: 700,
                  fontSize: 25,
                }}
              >
                {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
              </p>
            </div>
          }
          modalImage={"warning"}
          open={this.state.modalLicense}
          textButton={"Comprar"}
          closeElement={"Skip"}
          onCloseElement={this.handleCloseResponseModal}
          closeFromModalBody={true}
        />
      </form>
    );
  }
}

FormBranchOffice.propTypes = {
  numerationReducer: PropTypes.object.isRequired,
  branchOfficeReducer: PropTypes.object.isRequired,
  getNumerationsFilterAction: PropTypes.func.isRequired,
  updateNumerationAction: PropTypes.func.isRequired,
  loadBranchOfficeAction: PropTypes.func.isRequired,
  cleanLicenseResponse: PropTypes.func.isRequired,
  getStatesAction: PropTypes.func.isRequired,
  getAllCitiesAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  numerationReducer: state.numerationReducer,
  branchOfficeReducer: state.branchOfficeReducer,
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
  modal: state.branchOfficeReducer.openModalItem,
  licenseBranches: state.branchOfficeReducer.licenseBranchOffice,
});

export default connect(mapStateToProps, {
  getNumerationsFilterAction,
  getStatesAction,
  getAllCitiesAction,
  saveBranchOfficeAction,
  updateBranchOfficeAction,
  updateNumerationAction,
  loadBranchOfficeAction,
  cleanLicenseResponse,
  getBranchOfficeAction,
  updateOpenModalItem,
  updatePrincipalBranchOfficeAction,
})(FormBranchOffice);
