import axios from 'axios';
import { defaultHeaders, API_ENDPOINT_CONFIGURATION } from '../config/config';
import { idUser } from './authActions';
import { types } from '../types/types';

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/config/api`;

/**
* Method to Upload training module videos whit Filter
* @param {*} 
*/
export const getMultimediaVideoFilter = (filterData, videos) => async (dispatch) => {
  const url = `${urlConfiguration}${'/multimedia/GetMultimediaFilterPagination'}`;
  try {

    //Limpia listado en pantalla para nueva consulta
    if (filterData.LoadMore !== true) {
      dispatch({
        type: types.GET_VIDEO_MULTIMEDIA,
        payload: [],
        payload1: 0
      });
    }

    changeGetLoading(dispatch, true);
    await axios.post(url, filterData, defaultHeaders())
      .then(res => {
        let newVideo = [];

        if (filterData.LoadMore === true) {
          newVideo = videos.concat(res.data.result.multimedia);
        } else {
          newVideo = res.data.result.multimedia;
        }
        dispatch({
          type: types.GET_VIDEO_MULTIMEDIA,
          payload: newVideo,
          payload1: res.data.result.cantidad
        })
      })
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido cargar la cantidad de videos disponibles.',
        error: err
      }
    })

  } finally {
    changeGetLoading(dispatch, false);
  }
}

/**
* Actualiza estado loading consulta listado de Videos
* @param {*} dispatch 
* @param {*} status 
*/
function changeGetLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_VIDEO,
    payload: status
  })
}


/**
* Method to Change State Favorite Multimedia
* @param {*} 
*/
export const putStateChangeFavorite = (id, favorite) => async (dispatch) => {
  const url = `${urlConfiguration}${'/multimedia/ChangeFavorite'}`;
  try {
    const dataChange = {};
    dataChange.Favorito = favorite;
    dataChange.idusuario = parseInt(idUser())
    dataChange.idmultimedia = id;

    await axios.put(url, dataChange, defaultHeaders());


    /*   changeGetLoading(dispatch, true);
       await axios.post(url, filterData, defaultHeaders())
         .then(res => {
           let newVideo = [];
   
           if (filterData.LoadMore === true) {
             newVideo = videos.concat(res.data.result.multimedia);
           } else {
             newVideo = res.data.result.multimedia;
           }
           dispatch({
             type: GET_VIDEO_MULTIMEDIA,
             payload: newVideo,
             payload1: res.data.result.cantidad
           })
         }) */
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido cargar la cantidad de videos disponibles.',
        error: err
      }
    })

  } finally {
    changeGetLoading(dispatch, false);
  }
}