/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { WarningAlert } from "../../../helpers/alert.helpers";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import InputForm from "../../../components/input/input.component";
import SwitchForm from "../../../components/switch/switch.component.js";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import CustomAutoComplete from "../../../components/autocomplete/autocomplete.component";
import MultiSelectForm from "../../../components/multiselect/multiselect.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import SimpleModal from "../../../components/modal/simpleModal.component";
import CustomCropper from "../../../components/customCropper/customCropper.component";
import ModalTax from "./modalTax.component";

import {
  regexOnlyNumbers,
  regexSpace,
  regexLettersAndNumbers,
} from "../../../helpers/customRegex.hepers";

import {
  clearLoadArticleAction,
  createAndAddArticleAction,
  createArticleAction,
  getUnitMeasureAction,
  updateArticleAction,
  createAndAddProductAction,
} from "../../../actions/articleActions";

import { getCategoryAction } from "../../../actions/categoryActions";
import {
  cleanMenuComponentAction,
  getTaxDianAction,
  getUvtAction,
} from "../../../actions/configAction";

import { bytesToSize } from "../../../utils/general";
import isEmpty from "../../../utils/isEmpty";
import { regexDecimal } from "../../../helpers/customRegex.hepers";

import {
  DIGITS_AFTER_DECIMAL,
  imageSize,
  URL_IMAGES,
} from "../../../config/config";
import imageDefault from "../../../../src/images/image.png";
import panoramaIcon from "../../../../src/images/panoramaIcon.png";

import "../article/article.css";
import { roundDecimalValue } from "../../../utils/managementNumber";
import { GetUVTYear } from "../../../utils/getUvtYear";
import createTaxHandlers from "../../../utils/createTaxHandlers";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    outline: 0,
  },
  paperModalImage: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    width: 400,

    outline: 0,
  },
  Iconclose: {
    color: theme.palette.thirdColor,
    fontSize: 15,
    padding: 0,
  },
  buttonClose: {
    textAlign: "end",
  },
  rowsLines: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class CreateArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txtArticleName: "",
        txtArticleDescription: "",
        txtArticlePrice: "0",
        chkArticlePrice: false,
        txtArticleIva: null,
        chkArticleIva: false,
        taxIvaID: null,
        txtArticleInc: "0",
        chkArticleInc: false,
        taxIncID: null,
        txtArticleIbua: 0,
        txtMiliters: 0,
        chkArticleIbua: false,
        taxIbuaID: null,
        txtArticleIcui: "0",
        chkArticleIcui: false,
        taxIcuiID: null,
        chkArticleIcl: false,
        taxIclID: null,
        drinkTypeIcl: 0,
        cubicCentimetersIcl: 0,
        alcoholLevelIcl: 0,
        chkArticleInpp: false,
        taxInppID: null,
        weightInGramsInpp: 0,
        taxRateUvt: 0,
        chkArticleAdV: 0,
        txtArticleAdv: "0",
        taxAdvID: null,
        chkArticleTax: false,
        txtArticleNetWorth: "0",
        txtArticleTotal: "0",
        txtArticleCost: "",
        ddlArticleUnitMeasure: null,
        ddlArticleCategory: [],
        txtArticleReference: "",
        chkArticleFavorite: false,
        txtArticleBarcode: "",
        imgArticleSrc: "",
        categories: [],
        chkAplicaRegalo: false,
      },
      getCategories: [],
      valueCategory: [],
      unitMeasure: [],
      loadingSaveArticle: false,
      loadObjectArticle: {},
      editArticleStatus: false,
      stateModal: false,
      openModalIVA: false,
      openModalINC: false,
      openModalIbua: false,
      openModalIcl: false,
      openModalInpp: false,
      openModalAdV: false,
      image: null,
      menuArticle: {},
      imgArticleSrcOld: "",
      taxList: [],
      taxIvaList: [],
      taxIncList: [],
      optionsIbuaList: [],
      optionsIcuiList: [],
      optionsIclList: [],
      optionsInppList: [],
      optionsAdvList: [],
      allUvt: [],
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
    this.cleanForm = this.cleanForm.bind(this);
  }

  componentDidMount() {
    this.props.getUnitMeasureAction();
    this.props.getCategoryAction();
    this.props.getTaxDianAction();
    this.props.getUvtAction();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.articleReducer.requestUnitMeasure &&
      nextProps.articleReducer.requestUnitMeasure.responseCode !==
        state.unitMeasure
    )
      update.unitMeasure = nextProps.articleReducer.requestUnitMeasure.result;

    if (
      !!nextProps.categoryReducer.getCategories &&
      nextProps.categoryReducer.getCategories !== state.getCategories
    )
      update.getCategories = nextProps.categoryReducer.getCategories.filter(
        (c) => c.id !== 0
      );

    if (
      !!nextProps.menuReducer.menuArticle &&
      nextProps.menuReducer.menuArticle !== state.menuArticle
    )
      update.menuArticle = nextProps.menuReducer.menuArticle;

    if (
      !!nextProps.articleReducer.loadArticleObject &&
      nextProps.articleReducer.loadArticleObject !== state.loadArticleObject
    )
      update.loadArticleObject = nextProps.articleReducer.loadArticleObject;

    if (
      !isEmpty(nextProps.articleReducer.editArticleStatus) &&
      nextProps.articleReducer.editArticleStatus !== state.editArticleStatus
    )
      update.editArticleStatus = nextProps.articleReducer.editArticleStatus;

    if (
      !isEmpty(nextProps.articleReducer.loadingSaveArticle) &&
      nextProps.articleReducer.loadingSaveArticle !== state.loadingSaveArticle
    )
      update.loadingSaveArticle = nextProps.articleReducer.loadingSaveArticle;

    if (nextProps.configReducer.taxIvaList !== state.taxIvaList)
      update.taxIvaList = nextProps.configReducer.taxIvaList;

    if (nextProps.configReducer.taxIncList !== state.taxIncList)
      update.taxIncList = nextProps.configReducer.taxIncList;

    if (nextProps.configReducer.optionsIbuaList !== state.optionsIbuaList)
      update.optionsIbuaList = nextProps.configReducer.optionsIbuaList;

    if (nextProps.configReducer.optionsIcuiList !== state.optionsIcuiList)
      update.optionsIcuiList = nextProps.configReducer.optionsIcuiList;

    if (nextProps.configReducer.optionsIclList !== state.optionsIclList)
      update.optionsIclList = nextProps.configReducer.optionsIclList;

    if (nextProps.configReducer.optionsInppList !== state.optionsInppList)
      update.optionsInppList = nextProps.configReducer.optionsInppList;

    if (nextProps.configReducer.optionsAdvList !== state.optionsAdvList)
      update.optionsAdvList = nextProps.configReducer.optionsAdvList;

    if (nextProps.configReducer.allUvt !== state.allUvt)
      update.allUvt = nextProps.configReducer.allUvt;

    if (nextProps.configReducer.taxList !== state.taxList)
      update.taxList = nextProps.configReducer.taxList;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loadArticleObject !== this.state.loadArticleObject) {
      if (this.state.editArticleStatus === true)
        this.loadInfoForm(this.state.loadArticleObject);
      else this.cleanForm();
    }
  }

  componentWillUnmount() {
    this.props.clearLoadArticleAction();
  }

  /**
   * Sincroniza nuevo valor de  input con state
   * @param {object} e Evento
   */
  syncChanges = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  //Sincroniza valores decimales
  syncChangeDecimalValue = ({ target }) => {
    let { value } = { ...target };
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [target.name]: value,
      },
    }));
  };

  /**
   * Sincroniza Autocomplete con state
   * @param {object} event evento
   * @param {object} values nevo valor state
   * @param {string} id Identificador autocomplete
   */
  syncAutoCompleteChanges(event, values, id) {
    event.preventDefault();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: values,
      },
    }));
  }

  /**
   * Sincroniza checkBox con state
   * @param {object} e evento
   */
  syncCheckChange(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const checked = e.target.checked;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: checked,
      },
    }));
  }

  /**
   * Sincroniza componente multiple select con state
   * @param {*} event
   */
  syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];
    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        ddlArticleCategory: value,
      },
    }));
  };

  /**
   * Regex solo numeros
   * @param {*} e
   */
  validateOnlyNumber(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexOnlyNumbers, "");

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Regex Alfanumerico con espacios
   * @param {*} e
   */
  validateSpace(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexSpace, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos, solo letras y numeros
   * @param {*} e Evento
   */
  validateLettersAndNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexLettersAndNumbers, "");
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Recalcula valor articulo con nuevo valor IVA
   * @param {*} value Valor IVA
   */
  confirmIVA = (value, idTax) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        txtArticleIva: value,
        taxIvaID: idTax,
      },
      openModalIVA: false,
    }));

    const iva = value;
    const inc = this.state.form.txtArticleInc;
    const icui = this.state.form.txtArticleIcui;
    const ibua =
      (this.state.form.txtMiliters * this.state.form.txtArticleIbua) / 100;
    const icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
    const inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
    const adv = this.state.form.txtArticleAdv;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(
      iva,
      inc,
      price,
      this.state.form.chkArticleTax,
      icui,
      ibua,
      icl,
      inpp,
      adv
    );
  };

  /**
   * Recalcula valor articulo con nuevo valor INC
   *
   */
  confirmINC = (value, idTax) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        txtArticleInc: value,
        taxIncID: idTax,
      },
      openModalINC: false,
    }));

    const iva = this.state.form.txtArticleIva;
    const icui = this.state.form.txtArticleIcui;
    const ibua =
      (this.state.form.txtMiliters * this.state.form.txtArticleIbua) / 100;
    const icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
    const inc = value;
    const inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
    const adv = this.state.form.txtArticleAdv;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(
      iva,
      inc,
      price,
      this.state.form.chkArticleTax,
      icui,
      ibua,
      icl,
      inpp,
      adv
    );
  };

  /**
   * Actualiza el estado del formulario y realiza cálculos relacionados con IBUA.
   *
   * @param {number} baseAmount - Cantidad base para el cálculo de IBUA.
   * @param {number} militers - Cantidad en mililitros para el cálculo de IBUA.
   * @returns {void}
   */
  confirmIBUA = (baseAmount, idTax, militers) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        txtArticleIbua: baseAmount,
        txtMiliters: militers,
        taxIbuaID: idTax,
      },
      openModalIbua: false,
    }));

    const iva = this.state.form.txtArticleIva;
    const icui = this.state.form.txtArticleIcui;
    const inc = this.state.form.txtArticleInc;
    const ibua = ((militers * baseAmount) / 100).toFixed(2);
    const icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
    const inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
    const adv = this.state.form.txtArticleAdv;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(
      iva,
      inc,
      price,
      this.state.form.chkArticleTax,
      icui,
      ibua,
      icl,
      inpp,
      adv
    );
  };

  /**
   * Actualiza el estado del formulario y realiza cálculos relacionados con ICL.
   *
   * @param {number} drinkType - Tipo de bebida (Licor, Vinos o aperitivos) para el cálculo de ICL.
   * @param {number} cubicCentimeters - Cantidad en centímetros cúbicos del envase para el cálculo de ICL.
   * @param {number} alcoholLevelIcl - Nivel de alcohol de la bebida para el cálculo de ICL.
   * @returns {void}
   */
  confirmICL = (drinkType, idTax, cubicCentimeters, alcoholLevelIcl) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        drinkTypeIcl: (cubicCentimeters * drinkType) / 750,
        cubicCentimetersIcl: cubicCentimeters,
        alcoholLevelIcl: alcoholLevelIcl,
        taxIclID: idTax,
      },
      openModalIcl: false,
    }));

    const priceDrinkType = (cubicCentimeters * drinkType) / 750;

    const iva = this.state.form.txtArticleIva;
    const icui = this.state.form.txtArticleIcui;
    const inc = this.state.form.txtArticleInc;
    const ibua =
      (this.state.form.txtMiliters * this.state.form.txtArticleIbua) / 100;
    const icl = priceDrinkType * alcoholLevelIcl;
    const inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
    const adv = this.state.form.txtArticleAdv;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(
      iva,
      inc,
      price,
      this.state.form.chkArticleTax,
      icui,
      ibua,
      icl,
      inpp,
      adv
    );
  };

  /**
   * Actualiza el estado del formulario y realiza cálculos relacionados con INPP.
   *
   * @param {any} _ - Parámetro no utilizado.
   * @param {number} weightInGrams - Peso en gramos para el cálculo de INPP.
   * @returns {void}
   */
  confirmINPP = (_, idTax, weightInGrams) => {
    const uvtx5 =
      GetUVTYear(new Date().getFullYear(), this.state.allUvt) * 0.00005;

    const uvt = uvtx5.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        weightInGramsInpp: weightInGrams,
        taxRateUvt: uvt,
        taxInppID:
          weightInGrams === 0 ? null : this.state.optionsInppList[0].id,
      },
      openModalInpp: false,
    }));

    const iva = this.state.form.txtArticleIva;
    const icui = this.state.form.txtArticleIcui;
    const inc = this.state.form.txtArticleInc;
    const ibua =
      (this.state.form.txtMiliters * this.state.form.txtArticleIbua) / 100;
    const icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
    const inpp = weightInGrams * uvt;
    const adv = this.state.form.txtArticleAdv;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(
      iva,
      inc,
      price,
      this.state.form.chkArticleTax,
      icui,
      ibua,
      icl,
      inpp,
      adv
    );
  };

  /**
   * Recalcula valor articulo con nuevo valor Ad Valorem
   *
   */
  confirmADV = (value, idTax) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        txtArticleAdv: value,
        taxAdvID: idTax,
      },
      openModalAdV: false,
    }));

    const iva = this.state.form.txtArticleIva;
    const icui = this.state.form.txtArticleIcui;
    const ibua =
      (this.state.form.txtMiliters * this.state.form.txtArticleIbua) / 100;
    const icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
    const inc = this.state.form.txtArticleInc;
    const inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
    const adv = value;
    const price = this.state.form.txtArticlePrice;
    this.calculateValues(
      iva,
      inc,
      price,
      this.state.form.chkArticleTax,
      icui,
      ibua,
      icl,
      inpp,
      adv
    );
  };

  /**
   * Calcula valores de articulo dependendo de la configuración de usuario,
   * aplica o no impuesto
   */
  calculateValues(
    pIVA,
    pINC,
    pPrice,
    pIncludesTax,
    pICUI,
    pIBUA,
    pICL,
    pINPP,
    pAdv
  ) {
    const iva = isEmpty(pIVA) ? 0 : parseFloat(pIVA);
    const inc = isEmpty(pINC) ? 0 : parseFloat(pINC);
    const icui = isEmpty(pICUI) ? 0 : parseFloat(pICUI);
    const ibua = isEmpty(pIBUA) ? 0 : parseFloat(pIBUA);
    const icl = isEmpty(pICL) ? 0 : parseFloat(pICL);
    const inpp = isEmpty(pINPP) ? 0 : parseFloat(pINPP);
    const adv = isEmpty(pAdv) ? 0 : parseFloat(pAdv);
    const price = isEmpty(pPrice) ? 0 : parseFloat(pPrice);
    let ivavalue = price * (iva / 100);
    let incvalue = price * (inc / 100);
    let icuivalue = price * (icui / 100);
    let advvalue = price * (adv / 100);
    let digits = DIGITS_AFTER_DECIMAL;

    if (pIncludesTax === true) {
      let taxTotal = iva + inc + icui + adv;
      let totalWithoutTax = price / (1 + taxTotal / 100) - ibua - icl - inpp;

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleNetWorth: roundDecimalValue(totalWithoutTax, digits),
          txtArticleTotal: roundDecimalValue(pPrice, digits),
        },
      }));
    }

    if (pIncludesTax === false) {
      let total =
        price + ivavalue + incvalue + icuivalue + ibua + icl + inpp + advvalue;

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleNetWorth: roundDecimalValue(price, digits),
          txtArticleTotal: roundDecimalValue(total, digits),
        },
      }));
    }
  }

  /**
   * Abre modal para registro de IVA,
   * cuando aplica (checkeo apiaca IVA)
   * @param {object} e
   */
  handleOpenModalIVA(e) {
    e.preventDefault();
    const checked = e.target.checked;
    if (checked === true) {
      this.setState({
        openModalIVA: true,
      });
    }
  }

  /**
   * Evento check, cambio aplica iva
   * @param {*} e
   */
  changeCheckIVA = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalIVA: true,
        form: {
          ...prevState.form,
          chkArticleIva: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleIva: null,
          taxIvaID: null,
          chkArticleIva: checked,
        },
      }));

      let icui = this.state.form.txtArticleIcui;
      let iva = null;
      let inc = this.state.form.txtArticleInc;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Evento check, cambio aplica inc
   * @param {*} e
   */
  changeCheckINC = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalINC: true,
        form: {
          ...prevState.form,
          chkArticleInc: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleInc: 0,
          taxIncID: null,
          chkArticleInc: checked,
        },
      }));

      let icui = this.state.form.txtArticleIcui;
      let iva = this.state.form.txtArticleIva;
      let inc = 0;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Evento check, cambio aplica ibua
   * @param {*} e
   */
  changeCheckIbua = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalIbua: true,
        form: {
          ...prevState.form,
          chkArticleIbua: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleIbua: 0,
          chkArticleIbua: checked,
          txtMiliters: 0,
          taxIbuaID: null,
        },
      }));

      let iva = this.state.form.txtArticleIva;
      let inc = this.state.form.txtArticleInc;
      let icui = this.state.form.txtArticleIcui;
      let ibua = 0;
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Evento check, cambio aplica icl
   * @param {*} e
   */
  changeCheckIcl = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalIcl: true,
        form: {
          ...prevState.form,
          chkArticleIcl: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          chkArticleIcl: checked,
          alcoholLevelIcl: 0,
          drinkTypeIcl: 0,
          cubicCentimetersIcl: 0,
          taxIclID: null,
        },
      }));

      let iva = this.state.form.txtArticleIva;
      let inc = this.state.form.txtArticleInc;
      let icui = this.state.form.txtArticleIcui;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = 0;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Evento check, cambio aplica inpp
   * @param {*} e
   */
  changeCheckInpp = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalInpp: true,
        form: {
          ...prevState.form,
          chkArticleInpp: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          chkArticleInpp: checked,
          weightInGramsInpp: 0,
          taxRateUvt: 0,
          taxInppID: null,
        },
      }));

      let iva = this.state.form.txtArticleIva;
      let inc = this.state.form.txtArticleInc;
      let icui = this.state.form.txtArticleIcui;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = 0;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Evento check, cambio aplica icui
   * @param {*} e
   */
  changeCheckIcui = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          chkArticleIcui: checked,
          txtArticleIcui: this.state.optionsIcuiList[0].value,
          taxIcuiID: this.state.optionsIcuiList[0].id,
        },
      }));

      let iva = this.state.form.txtArticleIva;
      let inc = this.state.form.txtArticleInc;
      let icui = this.state.optionsIcuiList[0].value;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleIcui: 0,
          chkArticleIcui: checked,
          taxIcuiID: null,
        },
      }));

      let iva = this.state.form.txtArticleIva;
      let inc = this.state.form.txtArticleInc;
      let icui = 0;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = this.state.form.txtArticleAdv;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Evento check, cambio aplica adv
   * @param {*} e
   */
  changeCheckADV = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      this.setState((prevState) => ({
        openModalAdV: true,
        form: {
          ...prevState.form,
          chkArticleAdV: checked,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          txtArticleAdv: 0,
          chkArticleAdV: checked,
          taxAdvID: null,
        },
      }));

      let icui = this.state.form.txtArticleIcui;
      let iva = this.state.form.txtArticleIva;
      let inc = this.state.form.txtArticleInc;
      let ibua = (
        (this.state.form.txtMiliters * this.state.form.txtArticleIbua) /
        100
      ).toFixed(2);
      let icl = this.state.form.drinkTypeIcl * this.state.form.alcoholLevelIcl;
      let inpp = this.state.form.weightInGramsInpp * this.state.form.taxRateUvt;
      let adv = 0;
      let price = this.state.form.txtArticlePrice;
      this.calculateValues(
        iva,
        inc,
        price,
        this.state.form.chkArticleTax,
        icui,
        ibua,
        icl,
        inpp,
        adv
      );
    }
  };

  /**
   * Abre modal Imagen
   */
  handleOpen = () => {
    this.setState({
      stateModal: true,
    });
  };

  /**
   * Cierra modal Imagen
   */
  handleClose = () => {
    this.setState({
      stateModal: false,
    });
  };

  /**
   * Guarda o actualiza información de un articulo
   * @param {Object} e Evento
   */
  saveArticle = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      let data = {};
      let categories = this.state.form.ddlArticleCategory.map((index, unit) => {
        return { categoriaid: index };
      });

      const listTax = {
        taxIvaID: this.state.form.taxIvaID,
        taxIncID: this.state.form.taxIncID,
        taxIbuaID: this.state.form.taxIbuaID,
        taxIcuiID: this.state.form.taxIcuiID,
        taxIclID: this.state.form.taxIclID,
        taxInppID: this.state.form.taxInppID,
        taxAdvID: this.state.form.taxAdvID,
      };

      data = {
        id:
          this.state.editArticleStatus === true
            ? this.state.loadArticleObject.id
            : 0,
        imagen: this.state.form.imgArticleSrc,
        nombrearticulo: this.state.form.txtArticleName,
        valor:
          this.state.form.txtArticlePrice !== ""
            ? parseFloat(this.state.form.txtArticlePrice)
            : 0,
        precioeditable: this.state.form.chkArticlePrice,
        incluyeimpuesto: this.state.form.chkArticleTax,
        neto:
          this.state.form.txtArticleNetWorth !== ""
            ? parseFloat(this.state.form.txtArticleNetWorth)
            : 0,
        total:
          this.state.form.txtArticleTotal !== ""
            ? parseFloat(this.state.form.txtArticleTotal)
            : 0,
        costo:
          this.state.form.txtArticleCost !== ""
            ? parseFloat(this.state.form.txtArticleCost)
            : null,
        dianunidadmedidaid: parseInt(
          this.state.form.ddlArticleUnitMeasure.value
        ),
        codigo:
          this.state.editArticleStatus === true
            ? this.state.loadArticleObject.codigo
            : this.state.form.txtArticleReference,
        descripcion: this.state.form.txtArticleDescription,
        favorito: this.state.form.chkArticleFavorite,
        codigobarras: this.state.form.txtArticleBarcode,
        adcategoriaarticulo: categories,
        color:
          this.state.editArticleStatus === true
            ? this.state.loadArticleObject.color
            : "",
        tipodescuento: null,
        descuento: 0,
        activo: true,
        imgArticleSrcOld: this.state.imgArticleSrcOld,
        aplicaregalo: this.state.form.chkAplicaRegalo,
        Adimpuestoarticulo: this.addTaxArticle(listTax),
      };

      if (this.state.editArticleStatus === true) {
        this.props.updateArticleAction(
          data,
          this.props.type ?? 0,
          this.completeSaveUpdate
        );
      } else {
        if (this.props.origin === "cart-hooks") {
          this.props.createAndAddProductAction(data, this.cleanForm);
        } else {
          this.props.createArticleAction(
            data,
            this.props.type ?? 0,
            this.completeSaveUpdate
          );
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addTaxArticle = ({
    taxIvaID = null,
    taxIncID = null,
    taxIbuaID = null,
    taxIcuiID = null,
    taxIclID = null,
    taxInppID = null,
    taxAdvID = null,
  }) => {
    const listTaxs = [];

    const taxIdsToAdd = [
      {
        id: taxIvaID,
        list: this.state.taxIvaList,
        otherValueTax: this.state.form.txtArticleIva,
      },
      {
        id: taxIncID,
        list: this.state.taxIncList,
        otherValueTax: this.state.form.txtArticleInc,
      },
      { id: taxIbuaID, list: null, otherValueTax: null },
      { id: taxIcuiID, list: null, otherValueTax: null },
      { id: taxIclID, list: [], otherValueTax: null },
      { id: taxInppID, list: null, otherValueTax: null },
      { id: taxAdvID, list: null, otherValueTax: null },
    ].filter(({ id }) => id !== null);

    taxIdsToAdd.forEach(({ id, list, otherValueTax }) => {
      const otherValue = this.findOtherValue(id, list, otherValueTax);
      const cantidad = this.getCantidad(id, [taxInppID, taxIclID, taxIbuaID]);
      const cantidadcc = this.getCantidadCC(id, taxIclID);

      listTaxs.push({
        Impuestoid: id,
        Otrovalor: otherValue,
        Cantidad: cantidad,
        Cantidadcc: cantidadcc,
      });
    });

    return listTaxs;
  };

  findOtherValue = (id, list, otherValueTax) => {
    if (!list) return null;

    return id === this.state.form.taxIclID
      ? this.state.form.cubicCentimetersIcl === 750
        ? null
        : this.state.form.drinkTypeIcl
      : list.find((item) => item.descripcion === "Otro Porcentaje")?.id === id
      ? otherValueTax
      : null;
  };

  getCantidad = (id, taxs) => {
    const [taxInppID, taxIclID, taxIbuaID] = taxs;

    const idToFieldMap = {
      [taxIbuaID]: "txtMiliters",
      [taxIclID]: "alcoholLevelIcl",
      [taxInppID]: "weightInGramsInpp",
    };

    const fieldName = idToFieldMap[id];
    return fieldName ? parseFloat(this.state.form[fieldName]) : null;
  };

  getCantidadCC = (id, taxIclID) =>
    id === taxIclID ? parseFloat(this.state.form.cubicCentimetersIcl) : null;

  /**
   * Limpia y cierra formulario despues de guardar o actualizar
   */
  completeSaveUpdate = () => {
    this.cleanForm();
    if (!!this.props.handleCloseCollapse) {
      this.props.handleCloseCollapse();
    }
  };

  /**
   * Limpia formulario, despues de guardado o actualización
   */
  cleanForm() {
    this.setState({
      form: {
        txtArticleName: "",
        txtArticleDescription: "",
        txtArticlePrice: 0,
        chkArticlePrice: false,
        txtArticleIva: null,
        chkArticleIva: false,
        taxIvaID: null,
        txtArticleInc: 0,
        chkArticleInc: false,
        taxIncID: null,
        txtArticleIbua: 0,
        txtMiliters: 0,
        chkArticleIbua: false,
        taxIbuaID: null,
        txtArticleIcui: 0,
        chkArticleIcui: false,
        taxIcuiID: null,
        chkArticleIcl: false,
        taxIclID: null,
        drinkTypeIcl: 0,
        cubicCentimetersIcl: 0,
        alcoholLevelIcl: 0,
        chkArticleInpp: false,
        taxInppID: null,
        weightInGramsInpp: 0,
        taxRateUvt: 0,
        chkArticleAdV: 0,
        txtArticleAdv: 0,
        taxAdvID: null,
        chkArticleTax: false,
        txtArticleNetWorth: 0,
        txtArticleTotal: 0,
        txtArticleCost: "",
        ddlArticleUnitMeasure: null,
        ddlArticleCategory: [],
        txtArticleReference: "",
        chkArticleFavorite: false,
        txtArticleBarcode: "",
        imgArticleSrc: "",
        categories: [],
      },
      openModalIVA: false,
      openModalINC: false,
      openModalIbua: false,
      openModalIcl: false,
      openModalInpp: false,
      openModalAdV: false,
      imgArticleSrcOld: "", //Limpiar valor
    });
    this.validator.hideMessages();
    this.props.clearLoadArticleAction();
  }

  /**
   * * Carga información de articulo en formulario para edición
   * @param {object} object Información articulo para edición
   */
  loadInfoForm(item) {
    let categories = [];
    item.adcategoriaarticulo.map((index, item) => {
      return categories.push(index.categoriaid);
    });

    const unitMeasure = this.state.unitMeasure.find(
      (c) => c.id === item.dianunidadmedidaid
    );

    const {
      valoriva,
      chkArticleIva,
      taxIvaID,
      valorinc,
      chkArticleInc,
      taxIncID,
      valoribua,
      chkArticleIbua,
      taxIbuaID,
      cantidadmililitros,
      valoricui,
      chkArticleIcui,
      taxIcuiID,
      chkArticleIcl,
      drinkTypeIcl,
      cubicCentimetersIcl,
      alcoholLevelIcl,
      taxIclID,
      chkArticleInpp,
      weightInGramsInpp,
      taxRateUvt,
      taxInppID,
      chkArticleAdV,
      valoradv,
      taxAdvID,
    } = this.convertTaxs(item.adimpuestoarticulo);
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        imgArticleSrc: item.imagen && URL_IMAGES + item.imagen,
        txtArticleName: item.nombrearticulo,
        txtArticleDescription: item.descripcion,
        txtArticlePrice: item.valor,
        chkArticlePrice: item.precioeditable,
        txtArticleIva: valoriva || null,
        chkArticleIva: chkArticleIva || false,
        taxIvaID: taxIvaID || null,
        txtArticleInc: valorinc || 0,
        chkArticleInc: chkArticleInc || false,
        taxIncID: taxIncID || null,
        txtArticleIbua: valoribua || 0,
        chkArticleIbua: chkArticleIbua || false,
        txtMiliters: cantidadmililitros || 0,
        taxIbuaID: taxIbuaID || null,
        txtArticleIcui: valoricui || 0,
        chkArticleIcui: chkArticleIcui || false,
        taxIcuiID: taxIcuiID || null,
        chkArticleIcl: chkArticleIcl || false,
        drinkTypeIcl: drinkTypeIcl || 0,
        cubicCentimetersIcl: cubicCentimetersIcl || 0,
        alcoholLevelIcl: alcoholLevelIcl || 0,
        taxIclID: taxIclID || null,
        chkArticleInpp: chkArticleInpp || false,
        weightInGramsInpp: weightInGramsInpp || 0,
        taxRateUvt: taxRateUvt || 0,
        taxInppID: taxInppID || null,
        chkArticleAdV: chkArticleAdV || false,
        txtArticleAdv: valoradv || 0,
        taxAdvID: taxAdvID || null,
        chkArticleTax: item.incluyeimpuesto,
        txtArticleNetWorth: item.neto,
        txtArticleTotal: item.total,
        txtArticleCost: item.costo ?? "",
        ddlArticleUnitMeasure: !!unitMeasure ? unitMeasure : null,
        ddlArticleCategory: categories,
        txtArticleReference: item.codigo,
        chkArticleFavorite: item.favorito,
        txtArticleBarcode: item.codigobarras,
        chkAplicaRegalo: item.aplicaregalo,
      },
      imgArticleSrcOld: item.imagen,
    }));
  }

  /**
   *  * Convierte una lista de impuestos según el tipo de impuesto y actualiza el objeto taxList.
   *
   * @param {Array} itemToLoad - Lista de impuestos a cargar.
   * @returns {Object} - Objeto taxList actualizado con los impuestos correspondientes.
   */
  convertTaxs(itemToLoad) {
    const taxList = {};

    const taxHandlers = createTaxHandlers(taxList, this.state.allUvt);

    this.state.taxList.forEach(({ id, valor, tipo }) => {
      const taxFound = itemToLoad.find(
        (adimpuesto) => adimpuesto.impuestoid === id
      );

      if (taxFound && taxHandlers[tipo]) taxHandlers[tipo](valor, taxFound);
    });

    return taxList;
  }

  onChange(e) {
    e.preventDefault();
    try {
      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
        var objetFile = files[0];
        if (!/\.(jpg|png)$/i.test(objetFile.name)) {
          WarningAlert("Formato no permitido. Formatos permitidos .jpg o .png");
          this.handleClose();
        } else if (objetFile.size >= imageSize) {
          WarningAlert(
            `El tamaño de la imagen no puede se superior a ${bytesToSize(
              imageSize
            )}`
          );
          this.handleClose();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({ image: reader.result });
          };
          reader.readAsDataURL(files[0]);
          this.handleOpen();
        }
      }
      e.target.value = null;
    } catch (error) {
      console.log("Error cargando imagen", error);
    }
  }

  onClickSave = async () => {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          imgArticleSrc: canvas,
        },
      }));
    }
    this.handleClose();
  };

  setEditorRef = (editor) => (this.editor = editor);

  validateFieldsOnBlur(e, name) {
    e.preventDefault();
    if (!this.validator.fieldValid(name)) {
      this.validator.showMessageFor(name);
    }
  }

  /**
   * Limpia valor cero por defecto de caja de texto
   * @param {} e Evento
   */
  onClickNotZero = (e, name) => {
    let value = e.target.value;
    if (value !== null && value !== undefined) {
      value = value?.replace(regexDecimal, "");
      if (value === 0 || value === "0") {
        this.setState((prev) => ({
          form: {
            ...prev.form,
            [name]: "",
          },
        }));
      }
    }
  };

  /**
   * Cierra modal IVA, Recalcula con valor cero
   */
  handleCloseModalIVA = () => {
    this.setState((prev) => ({
      openModalIVA: false,
      form: {
        ...prev.form,
        chkArticleIva: false,
      },
    }));

    this.confirmIVA(null, null);
  };

  /**
   * Cierra modal INC, Recalcula con valor cero
   */
  handleCloseModalINC = () => {
    this.setState((prev) => ({
      openModalINC: false,
      form: {
        ...prev.form,
        chkArticleInc: false,
      },
    }));

    this.confirmINC(0, null);
  };

  /**
   * Cierra modal IBUA, Recalcula con valor cero
   */
  handleCloseModalIbua = () => {
    this.setState((prev) => ({
      openModalIbua: false,
      form: {
        ...prev.form,
        chkArticleIbua: false,
      },
    }));

    this.confirmIBUA(0, null, 0);
  };

  /**
   * Cierra modal ICL, Recalcula con valor cero
   */
  handleCloseModalIcl = () => {
    this.setState((prev) => ({
      openModalIcl: false,
      form: {
        ...prev.form,
        chkArticleIcl: false,
      },
    }));

    this.confirmICL(0, null, 0, 0);
  };

  /**
   * Cierra modal INPP, Recalcula con valor cero
   */
  handleCloseModalInpp = () => {
    this.setState((prev) => ({
      openModalInpp: false,
      form: {
        ...prev.form,
        chkArticleInpp: false,
      },
    }));

    this.confirmINPP(0, null, 0);
  };

  /**
   * Cierra modal AD Valorem, Recalcula con valor cero
   */
  handleCloseModalADV = () => {
    this.setState((prev) => ({
      openModalAdV: false,
      form: {
        ...prev.form,
        chkArticleAdV: false,
      },
    }));

    this.confirmADV(0, null);
  };

  render() {
    let categoryOption = [];
    if (!!this.state.getCategories) {
      categoryOption = this.state.getCategories.map((item) => {
        return {
          ...item,
          value: item.id,
          text: item.nombre,
          color: item.color,
        };
      });
    }

    return (
      <>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item lg={4} xs={12}>
            <input
              accept="image/*"
              style={{ display: "none", zIndex: -1 }}
              id="icon-button-file"
              type="file"
              onChange={(e) => this.onChange(e)}
            />
            <div className="container">
              <div
                className={"container-img"}
                style={{
                  backgroundImage: `url( ${
                    isEmpty(this.state.form.imgArticleSrc)
                      ? imageDefault
                      : this.state.form.imgArticleSrc
                  } )`,
                  backgroundPosition: "center center",
                }}
              ></div>
              <label htmlFor="icon-button-file">
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  className="btn"
                >
                  <img width="30" src={panoramaIcon} alt="panorama" />
                </IconButton>
              </label>
            </div>
          </Grid>

          <Grid item lg={8} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticleReference"
                  value={this.state.form.txtArticleReference}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.validateLettersAndNumbers(e);
                  }}
                  maxLength={50}
                  label={"Referencia *"}
                  nameValidator={"Referencia"}
                  onBlur={(e) => this.validateFieldsOnBlur(e, "Referencia")}
                  disabled={this.state.editArticleStatus}
                  validator={this.validator}
                  validateOptions={"required"}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticleName"
                  maxLength={250}
                  value={this.state.form.txtArticleName}
                  label={"Nombre artículo *"}
                  validator={this.validator}
                  validateOptions={"required|min:3"}
                  onChange={this.syncChanges}
                />
              </Grid>

              <Grid item lg={12} xs={12}>
                <InputForm
                  name="txtArticleDescription"
                  maxLength={2000}
                  value={this.state.form.txtArticleDescription}
                  multiline={true}
                  rowsMax={2}
                  label={"Descripción *"}
                  validator={this.validator}
                  validateOptions={"required"}
                  onChange={this.syncChanges}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <InputForm
                  name="txtArticlePrice"
                  value={this.state.form.txtArticlePrice}
                  label={"Precio"}
                  validator={this.validator}
                  validateOptions={"required"}
                  maxLength={21}
                  format={true}
                  onClick={this.onClickNotZero}
                  onChange={this.syncChangeDecimalValue}
                  onBlur={(e) => {
                    this.calculateValues(
                      this.state.form.txtArticleIva,
                      this.state.form.txtArticleInc,
                      this.state.form.txtArticlePrice,
                      this.state.form.chkArticleTax,
                      this.state.form.txtArticleIcui,
                      (this.state.form.txtMiliters *
                        this.state.form.txtArticleIbua) /
                        100,
                      this.state.form.drinkTypeIcl *
                        this.state.form.alcoholLevelIcl,
                      this.state.form.weightInGramsInpp *
                        this.state.form.taxRateUvt,
                      this.state.form.txtArticleAdv
                    );
                  }}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <SwitchForm
                  name={"chkArticlePrice"}
                  value={this.state.form.chkArticlePrice}
                  checked={this.state.form.chkArticlePrice}
                  titleOn={"¿El precio es editable?"}
                  onChange={(e) => this.syncCheckChange(e)}
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.menuArticle.titlePrice}
                      message={this.state.menuArticle.messagePrice}
                      botton={this.state.menuArticle.bottonPrice}
                      href={this.state.menuArticle.hrefPrice}
                      noButton={
                        this.state.menuArticle.hrefPrice !== "" ? false : true
                      }
                    />
                  }
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <SwitchForm
                  name={"chkArticleIva"}
                  value={this.state.form.chkArticleIva}
                  checked={this.state.form.chkArticleIva}
                  titleOn="¿Este producto aplica IVA? "
                  text={
                    this.state.form.txtArticleIva === null
                      ? undefined
                      : this.state.form.txtArticleIva
                  }
                  maxLength={5}
                  onChange={this.changeCheckIVA}
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.menuArticle.titleIVA}
                      message={this.state.menuArticle.messageIVA}
                      botton={this.state.menuArticle.bottonIVA}
                      href={this.state.menuArticle.hrefIVA}
                      noButton={
                        this.state.menuArticle.hrefIVA !== "" ? false : true
                      }
                    />
                  }
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <SwitchForm
                  name={"chkArticleInc"}
                  titleOn={"¿Este producto aplica INC? "}
                  value={this.state.form.chkArticleInc}
                  checked={this.state.form.chkArticleInc}
                  onChange={this.changeCheckINC}
                  text={
                    isEmpty(this.state.form.txtArticleInc)
                      ? 0
                      : this.state.form.txtArticleInc
                  }
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.menuArticle.titleINC}
                      message={this.state.menuArticle.messageINC}
                      botton={this.state.menuArticle.bottonINC}
                      href={this.state.menuArticle.hrefINC}
                      noButton={
                        this.state.menuArticle.hrefINC !== "" ? false : true
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item lg={4} xs={12}>
              <SwitchForm
                name={"chkArticleIcl"}
                titleOn={"¿Este producto aplica ICL?"}
                value={this.state.form.chkArticleIcl}
                checked={this.state.form.chkArticleIcl}
                onChange={this.changeCheckIcl}
                txtTooltip={
                  <TooltipMessage
                    title={this.state.menuArticle.titleICL}
                    message={this.state.menuArticle.messageICL}
                    botton={this.state.menuArticle.bottonICL}
                    href={this.state.menuArticle.hrefICL}
                    noButton={
                      this.state.menuArticle.hrefICL !== "" ? false : true
                    }
                  />
                }
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <SwitchForm
                name={"chkArticleInpp"}
                titleOn={"¿Este producto aplica INPP?"}
                value={this.state.form.chkArticleInpp}
                checked={this.state.form.chkArticleInpp}
                onChange={this.changeCheckInpp}
                txtTooltip={
                  <TooltipMessage
                    title={this.state.menuArticle.titleINPP}
                    message={this.state.menuArticle.messageINPP}
                    botton={this.state.menuArticle.bottonINPP}
                    href={this.state.menuArticle.hrefINPP}
                    noButton={
                      this.state.menuArticle.hrefINPP !== "" ? false : true
                    }
                  />
                }
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <SwitchForm
                name={"chkArticleIbua"}
                titleOn={"¿Este producto aplica IBUA? "}
                value={this.state.form.chkArticleIbua}
                checked={this.state.form.chkArticleIbua}
                onChange={this.changeCheckIbua}
                txtTooltip={
                  <TooltipMessage
                    title={this.state.menuArticle.titleIBUA}
                    message={this.state.menuArticle.messageIBUA}
                    botton={this.state.menuArticle.bottonIBUA}
                    href={this.state.menuArticle.hrefIBUA}
                    noButton={
                      this.state.menuArticle.hrefIBUA !== "" ? false : true
                    }
                  />
                }
              />
            </Grid>
            <Grid container style={{ alignItems: "center" }}>
              <Grid item lg={4} xs={12}>
                <SwitchForm
                  name={"chkArticleIcui"}
                  titleOn={"¿Este producto aplica ICUI? "}
                  value={this.state.form.chkArticleIcui}
                  checked={this.state.form.chkArticleIcui}
                  onChange={this.changeCheckIcui}
                  text={
                    isEmpty(this.state.form.txtArticleIcui)
                      ? 0
                      : this.state.form.txtArticleIcui
                  }
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.menuArticle.titleICUI}
                      message={this.state.menuArticle.messageICUI}
                      botton={this.state.menuArticle.bottonICUI}
                      href={this.state.menuArticle.hrefICUI}
                      noButton={
                        this.state.menuArticle.hrefICUI !== "" ? false : true
                      }
                    />
                  }
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <SwitchForm
                  name={"chkArticleAdV"}
                  value={this.state.form.chkArticleAdV}
                  checked={this.state.form.chkArticleAdV}
                  titleOn="¿Este producto aplica AD VALOREM? "
                  text={
                    isEmpty(this.state.form.txtArticleAdv)
                      ? 0
                      : this.state.form.txtArticleAdv
                  }
                  maxLength={5}
                  onChange={this.changeCheckADV}
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.menuArticle.titleADV}
                      message={this.state.menuArticle.messageADV}
                      botton={this.state.menuArticle.bottonADV}
                      href={this.state.menuArticle.hrefADV}
                      noButton={
                        this.state.menuArticle.hrefADV !== "" ? false : true
                      }
                    />
                  }
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <SwitchForm
                  name={"chkArticleTax"}
                  value={this.state.form.chkArticleTax}
                  checked={this.state.form.chkArticleTax}
                  titleOn="¿El precio incluye impuesto?"
                  onChange={(e) => {
                    this.syncCheckChange(e);
                    this.calculateValues(
                      this.state.form.txtArticleIva,
                      this.state.form.txtArticleInc,
                      this.state.form.txtArticlePrice,
                      e.target.checked,
                      this.state.form.txtArticleIcui,
                      (this.state.form.txtMiliters *
                        this.state.form.txtArticleIbua) /
                        100,
                      this.state.form.drinkTypeIcl *
                        this.state.form.alcoholLevelIcl,
                      this.state.form.weightInGramsInpp *
                        this.state.form.taxRateUvt,
                      this.state.form.txtArticleAdv
                    );
                  }}
                  txtTooltip={
                    <TooltipMessage
                      title={this.state.menuArticle.titleTax}
                      message={this.state.menuArticle.messageTax}
                      botton={this.state.menuArticle.bottonTax}
                      href={this.state.menuArticle.hrefTax}
                      noButton={
                        this.state.menuArticle.hrefTax !== "" ? false : true
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={4} xs={12}>
            <InputForm
              name="txtArticleNetWorth"
              label={"Valor sin impuesto"}
              value={this.state.form.txtArticleNetWorth}
              disabled={true}
              onChange={() => {}}
              format={true}
              validator={this.validator}
              validateOptions={"noNegative"}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <InputForm
              name="txtArticleTotal"
              label={"Valor total con impuestos"}
              value={this.state.form.txtArticleTotal}
              onChange={() => {}}
              format={true}
              disabled
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <InputForm
              name="txtArticleCost"
              label={"Costo"}
              value={this.state.form.txtArticleCost}
              validator={this.validator}
              maxLength={21}
              validateOptions={"isNumber"}
              onChange={this.syncChangeDecimalValue}
              format={true}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <CustomAutoComplete
              name="ddlArticleUnitMeasure"
              label={"Unidad de medida * "}
              value={this.state.form.ddlArticleUnitMeasure}
              options={this.state.unitMeasure}
              textNoOption="No se encontraron resultados."
              onChange={(event, value) =>
                this.syncAutoCompleteChanges(
                  event,
                  value,
                  "ddlArticleUnitMeasure"
                )
              }
              validator={this.validator}
              validateOptions={"required"}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <MultiSelectForm
              label={"Categorías"}
              name="categorias"
              value={this.state.form.ddlArticleCategory}
              options={categoryOption}
              onChange={this.syncMultipleChange}
              renderValue={(selected) =>
                this.state.form.ddlArticleCategory
                  .map((value) => {
                    let name = categoryOption.filter(
                      (category) => category.id === value
                    );
                    return name[0]?.nombre;
                  })
                  .join(",")
              }
              model={"Articulo"}
              placeholder={"Seleccione..."}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <InputForm
              maxLength={100}
              name="txtArticleBarcode"
              value={this.state.form.txtArticleBarcode}
              label={"Código de barras"}
              onChange={(e) => this.syncChanges(e)}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SwitchForm
              name={"chkAplicaRegalo"}
              value={this.state.form.chkAplicaRegalo}
              checked={this.state.form.chkAplicaRegalo}
              titleOn="¿Este articulo puede ser un regalo?"
              onChange={(e) => this.syncCheckChange(e)}
              txtTooltip={
                <TooltipMessage
                  title={this.state.menuArticle.titleGift}
                  message={this.state.menuArticle.messageGift}
                  botton={this.state.menuArticle.buttonGift}
                  href={this.state.menuArticle.hrefGift}
                  noButton={
                    this.state.menuArticle.hrefGift !== "" ? false : true
                  }
                />
              }
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SwitchForm
              name={"chkArticleFavorite"}
              value={this.state.form.chkArticleFavorite}
              checked={this.state.form.chkArticleFavorite}
              titleOn="Marcar como favorito"
              onChange={(e) => this.syncCheckChange(e)}
            />
          </Grid>
        </Grid>

        <Grid container justify="flex-end">
          <Grid item xs={12} md={4}>
            <ButtonPrimary
              text={this.state.menuArticle.saveButton}
              loading={this.state.loadingSaveArticle}
              type={"button"}
              onClick={(e) => this.saveArticle(e)}
              disabled={false}
            />
          </Grid>
        </Grid>

        {/* Modal Imagen */}
        <SimpleModal
          onClose={this.handleClose}
          open={this.state.stateModal}
          title="Actualizar imagen del artículo"
          component={() => (
            <CustomCropper
              onClickSave={this.onClickSave}
              handleClose={this.handleClose}
              image={this.state.image}
              setEditorRef={this.setEditorRef}
            />
          )}
        />

        {/* Modal para IVA */}
        <ModalTax
          open={this.state.openModalIVA}
          label={"Seleccione el valor del IVA"}
          options={this.state.taxIvaList}
          confirmTax={this.confirmIVA}
          title={"Valor % Impuesto IVA"}
          onClose={this.handleCloseModalIVA}
        />

        {/* Modal para INC */}
        <ModalTax
          open={this.state.openModalINC}
          label={"Seleccione el valor del INC"}
          options={this.state.taxIncList}
          confirmTax={this.confirmINC}
          title={"Valor % Impuesto INC"}
          onClose={this.handleCloseModalINC}
        />
        {/* Modal para IBUA */}
        <ModalTax
          open={this.state.openModalIbua}
          label={"Seleccione el valor del IBUA"}
          options={this.state.optionsIbuaList}
          confirmTax={this.confirmIBUA}
          title={"Cantidad en mililitros del envase - Impuesto IBUA"}
          onClose={this.handleCloseModalIbua}
          addInput={[
            {
              visibleInput: true,
              labelInput: "Cantidad de mililitros",
              endAdornment: "ml",
              inputName: "txtIbuaMilliliters",
            },
          ]}
        />
        {/* Modal para ICL */}
        <ModalTax
          open={this.state.openModalIcl}
          label={"Seleccione tipo de bebida"}
          options={this.state.optionsIclList}
          confirmTax={this.confirmICL}
          title={"Valor - Impuesto ICL"}
          onClose={this.handleCloseModalIcl}
          addInput={[
            {
              visibleInput: true,
              labelInput: "Centimetros cúbicos",
              endAdornment: "cc",
              inputName: "txtIclcubicCentimeters",
            },
            {
              visibleInput: true,
              labelInput: "Grados de alcohol",
              endAdornment: "grados",
              inputName: "txtIclAlcoholLevel",
            },
          ]}
          regexField={"decimal"}
          totalIntegers={5}
          totalDecimals={2}
        />
        {/* Modal para INPP */}
        <ModalTax
          open={this.state.openModalInpp}
          confirmTax={this.confirmINPP}
          title={"Peso en gramos - Impuesto INPP"}
          onClose={this.handleCloseModalInpp}
          addInput={[
            {
              visibleInput: true,
              labelInput: "Peso en gramos",
              endAdornment: "grs",
              inputName: "txtInppGrams",
            },
          ]}
          regexField={"decimal"}
          totalIntegers={5}
          totalDecimals={2}
        />
        {/* Modal para ADV */}
        <ModalTax
          open={this.state.openModalAdV}
          label={"Seleccione el valor del Ad Valorem"}
          options={this.state.optionsAdvList}
          confirmTax={this.confirmADV}
          title={"Valor % Impuesto Ad Valorem"}
          onClose={this.handleCloseModalADV}
        />
      </>
    );
  }
}

CreateArticle.propTypes = {
  getUnitMeasureAction: PropTypes.func.isRequired,
  createArticleAction: PropTypes.func.isRequired,
  updateArticleAction: PropTypes.func.isRequired,
  clearLoadArticleAction: PropTypes.func.isRequired,
  cleanMenuComponentAction: PropTypes.func.isRequired,
  createAndAddArticleAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  articleReducer: state.articleReducer,
  categoryReducer: state.categoryReducer,
  menuReducer: state.menuReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  getUnitMeasureAction,
  clearLoadArticleAction,
  createArticleAction,
  updateArticleAction,
  cleanMenuComponentAction,
  createAndAddArticleAction,
  getCategoryAction,
  createAndAddProductAction,
  getTaxDianAction,
  getUvtAction,
})(withStyles(useStyles)(CreateArticle));
