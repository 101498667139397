import { homologateRejectReason } from "../helpers/catalogDian";
import { types } from "../types/types";

const initialState = {
  documentList: [],
  documentDetail: null,
  loadingList: false,
  loadingDetail: false,
  loadingStandOut: false,
  loadingUploadDocument: false,
  loadingReceiptDocument: false,
  loadingAcceptDocument: false,
  loadingRejectDocument: false,
  rejectionReasons: [],
  documentAvailableZero: false,
};

export const documentReceivedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILL_RECEIVED_DOCUMENT:
      return {
        ...state,
        documentList: [...action.payload],
      };
    case types.LOADING_RECEIVED_DOCUMENTS:
      return {
        ...state,
        loadingList: action.payload,
      };
    case types.FILL_RECEIVED_DETAIL:
      return {
        ...state,
        documentDetail: action.payload,
      };
    case types.LOADING_GET_RECEIVED_DETAIL:
      return {
        ...state,
        loadingDetail: action.payload,
      };
    case types.LOADING_STAND_OUT_RECEIVED:
      return {
        ...state,
        loadingStandOut: action.payload,
      };
    case types.UPDATE_STAND_OUT_RECEIVED:
      return {
        ...state,
        documentList: state.documentList.map((item) =>
          item.id === action.payload
            ? { ...item, favorito: !item.favorito }
            : item
        ),
        documentDetail:
          state.documentDetail.Id === action.payload
            ? {
                ...state.documentDetail,
                Favorito: !state.documentDetail.Favorito,
              }
            : { ...state.documentDetail },
      };
    case types.LOADING_RECEIVED_DOCUMENT_LIST:
      return {
        ...state,
        documentList: state.documentList.map((item) =>
          item.id === action.payload.id
            ? { ...item, loading: action.payload.status }
            : item
        ),
      };
    case types.GET_REJECTION_REASONS:
      return {
        ...state,
        rejectionReasons: action.payload,
      };
    case types.LOADING_UPLOAD_RECEIVED:
      return {
        ...state,
        loadingUploadDocument: action.payload,
      };
    case types.SUCESS_ACKNOWLEDGEMENT_DOCUMENT_RECEIVED:
      return {
        ...state,
        documentDetail:
          state.documentDetail.Id === action.payload
            ? {
                ...state.documentDetail,
                Acuse: true,
              }
            : { ...state.documentDetail },
      };
    case types.SUCESS_RECEIPT_DOCUMENT_RECEIVED:
      return {
        ...state,
        documentDetail:
          state.documentDetail.Id === action.payload
            ? {
                ...state.documentDetail,
                Recibido: true,
              }
            : { ...state.documentDetail },
      };
    case types.LOADING_RECEIPT_DOCUMENT_RECEIVED:
      return {
        ...state,
        loadingReceiptDocument: action.payload,
      };
    case types.LOADING_ACCEPT_DOCUMENT_RECEIVED:
      return {
        ...state,
        loadingAcceptDocument: action.payload,
      };
    case types.SUCESS_ACCEPT_DOCUMENT_RECEIVED:
      return {
        ...state,
        documentDetail:
          state.documentDetail.Id === action.payload.id
            ? {
                ...state.documentDetail,
                EstadoAceptacion: action.payload.estadoaceptacion,
                EstadoAceptacionid: action.payload.estadoaceptacionid,
                FechaAceptacion: !!action.payload.fechaaceptacion
                  ? new Date(action.payload.fechaaceptacion)
                  : null,
              }
            : { ...state.documentDetail },
        documentList: state.documentList.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                estadoaceptacion: action.payload.estadoaceptacion,
                estadoaceptacionid: action.payload.estadoaceptacionid,
              }
            : item
        ),
      };
    case types.LOADING_REJECT_DOCUMENT_RECEIVED:
      return {
        ...state,
        loadingRejectDocument: action.payload,
      };
    case types.SUCESS_REJECT_DOCUMENT_RECEIVED:
      return {
        ...state,
        documentDetail:
          state.documentDetail.Id === action.payload.id
            ? {
                ...state.documentDetail,
                EstadoAceptacion: action.payload.estadoaceptacion,
                EstadoAceptacionid: action.payload.estadoaceptacionid,
                FechaAceptacion: !!action.payload.fechaaceptacion
                  ? new Date(action.payload.fechaaceptacion)
                  : null,
                MotivoRechazo: homologateRejectReason(
                  action.payload.motivorechazo ?? ""
                ),
              }
            : { ...state.documentDetail },
        documentList: state.documentList.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                estadoaceptacion: action.payload.estadoaceptacion,
                estadoaceptacionid: action.payload.estadoaceptacionid,
              }
            : item
        ),
      };
    case types.DOCUMENT_AVAILABLE_ZERO:
      return {
        ...state,
        documentAvailableZero: action.payload,
      };
    case types.CLEAN_REDUX_RECEIVED:
      return {
        ...state,
        documentList: [],
        documentDetail: null,
        loadingList: false,
        loadingDetail: false,
        loadingStandOut: false,
        loadingUploadDocument: false,
        loadingReceiptDocument: false,
        loadingAcceptDocument: false,
        loadingRejectDocument: false,
        documentAvailableZero: false,
      };

    default:
      return state;
  }
};
