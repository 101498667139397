import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import {
  formatDate,
  // getComponentName,
  // getComponentUrl,
} from "../../../utils/general";
// import CustomCardAditionalData from "../../invoice/aditional-data-card.component";
// import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  letter: {
    color: "#FFFFFF",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  widthMax: {
    width: "100%",
  },
  paddingIcons: {
    paddingTop: "20%",
  },
  width99: {
    width: "99%",
  },
  width98: {
    width: "98%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  width97: {
    width: "97%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  width90: {
    width: "90%",
    boxSizing: "border-box",
    height: "1px",
    border: "1px solid rgba(255,255,255,0.2)",
  },
  letterTaxes: {
    color: "#2CC63E",
  },
  totalInvoiceDetail: {
    color: "#FFFFFF",
    fontFamily: "Muli",
    fontSize: "18px",
    fontWeight: "800",
    letterSpacing: "0",
    textAlign: "left",
  },
  colorIcon: {
    color: "#2CC63E",
  },
  h1Card: {
    justifyContent: "center",
    textAlign: "center",
    color: "white",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  containerLoading: {
    paddingTop: 200,
  },
});

const GetInfoAditional = () => {
  const [document, setDocument] = useState({
    opdocumentocargos: [],
    opdocumentodescuentos: [],
    descripcionforma: "",
    textoobservacion: "",
    descripcionmedio: null,
  });
  // const { menuComponent } = useSelector((s) => s.configReducer);
  const classes = useStyles();
  const { opdocumentocargos, opdocumentodescuentos } = useSelector(
    (state) => state.documentCartReducer
  );

  const { documentData } = useSelector((state) => state.documentSupportReducer);
  const {
    fechaFactura,
    fechapago,
    descripcionPaymethodid,
    formaPago,
    observaciones,
  } = documentData;

  useEffect(() => {
    if (documentData) {
      const data = {
        opdocumentocargos,
        opdocumentodescuentos,
        fecha: fechaFactura,
        fechapago: formaPago === "1" ? fechapago : null,
        descripcionforma: formaPago === "1" ? "Crédito" : "Contado",
        textoobservacion: observaciones,
        descripcionmedio: null,
      };
      if (formaPago !== "1") {
        data.descripcionmedio = descripcionPaymethodid;
      }
      setDocument(data);
    }
  }, [
    descripcionPaymethodid,
    documentData,
    fechaFactura,
    fechapago,
    formaPago,
    observaciones,
    opdocumentocargos,
    opdocumentodescuentos,
  ]);

  // let cargos = document.opdocumentocargos;
  // let descuentos = document.opdocumentodescuentos;
  // let notas = document.opdocumentonotas;
  // let documentosreferencia = document?.documentosreferencia ?? [];

  return (
    <div>
      <div className="cardDetailInfoAditional">
        <Grid container>
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Fecha Documento{" "}
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Forma de Pago
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document?.fecha !== null && document?.fecha !== undefined
              ? formatDate(new Date(document.fecha), "dd/MM/yyyy")
              : "-"}
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document?.descripcionforma}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>
        <Grid container className="fieldsReadContainer">
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Medio Pago
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadMini">
            Fecha de Pago
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document.descripcionmedio === null
              ? "-"
              : document.descripcionmedio}
          </Grid>
          <Grid item lg={6} sm={12} className="fieldsReadBig">
            {document?.fechapago !== null && document?.fechapago !== undefined
              ? formatDate(new Date(document.fechapago), "dd/MM/yyyy")
              : "-"}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
          <Grid item lg={6} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>

        {/* Nuevos datos
        <Grid container className="fieldsReadContainer">
          <Grid item lg={4} sm={12} className="fieldsReadMini">
            Aceptada/Rechazada
          </Grid>
          <Grid item lg={4} sm={12} className="fieldsReadMini">
            Fecha Aceptación/Rechazo
          </Grid>
          <Grid item lg={4} sm={12} className="fieldsReadMini">
            Fecha validación DIAN
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={4} sm={12} className="fieldsReadBig">
            {document?.descripcionestado}
          </Grid>
          <Grid item lg={4} sm={12} className="fieldsReadBig">
            {document?.fechaacusecliente !== null &&
            document?.fechaacusecliente !== undefined
              ? formatDate(
                  new Date(document.fechaacusecliente),
                  "dd/MM/yyyy hh:mm aaaa"
                )
              : "-"}
          </Grid>
          <Grid item lg={4} sm={12} className="fieldsReadBig">
            {document?.fechaacusedian !== null &&
            document?.fechaacusedian !== undefined
              ? formatDate(
                  new Date(document.fechaacusedian),
                  "dd/MM/yyyy hh:mm aaaa"
                )
              : "-"}
          </Grid>
        </Grid> */}
        {/* <Grid container>
          <Grid item lg={4} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
          <Grid item lg={4} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
          <Grid item lg={4} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid> */}
        {/* <Grid container className="fieldsReadContainer">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="fieldsReadMini"
            style={{ width: "96%" }}
          >
            Concepto rechazo cliente
          </Grid>
        </Grid> */}

        {/* <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="fieldsReadBig"
            style={{ wordBreak: "break-word" }}
          >
            {"-"}{" "}
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12}>
              <div className={classes.width97}></div>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid container className="fieldsReadContainer">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="fieldsReadMini"
            style={{ width: "96%" }}
          >
            Observaciones
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="fieldsReadBig"
            style={{ wordBreak: "break-word" }}
          >
            {document.textoobservacion || "-"}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12}></Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} sm={12}>
            <div className={classes.width97}></div>
          </Grid>
        </Grid>
      </div>
      <div className="spacingInvoice"></div>
      {/* <div className="titleAdicitional">Cargos</div>
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        {cargos !== undefined
          ? cargos !== null
            ? cargos.map((data, index) => (
                <Grid item key={index} lg={6} md={6} sm={12} xs={12}>
                  <CustomCardAditionalData
                    data={data}
                    type="charges"
                    onClick=""
                  />
                </Grid>
              ))
            : ""
          : ""}
      </Grid>
      <div className="spacingInvoice"></div> */}
      {/* <div className="titleAdicitional">Descuentos sobre el total</div>
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        {descuentos !== undefined
          ? descuentos !== null
            ? descuentos.map((data, index) => (
                <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                  <CustomCardAditionalData
                    data={data}
                    type="discounts"
                    tooltipMessage={
                      data.modelodescuento === 1 ? (
                        <TooltipMessage
                          title={getComponentName(
                            menuComponent,
                            93,
                            157,
                            "¿Cómo afecta la factura este descuento?"
                          )}
                          message={getComponentName(
                            menuComponent,
                            93,
                            158,
                            "Los valores de los descuentos se distribuirán equitativamente en los artículos agregados a la factura."
                          )}
                          botton={getComponentName(
                            menuComponent,
                            93,
                            159,
                            "Más información"
                          )}
                          href={getComponentUrl(menuComponent, 93, 150, null)}
                        />
                      ) : (
                        <TooltipMessage
                          title={getComponentName(
                            menuComponent,
                            94,
                            160,
                            "¿Cómo afecta la factura este descuento?"
                          )}
                          message={getComponentName(
                            menuComponent,
                            94,
                            161,
                            "Es un descuento que afecta el valor total a pagar pero no el valor total de la factura."
                          )}
                          botton={getComponentName(
                            menuComponent,
                            94,
                            162,
                            "Más información"
                          )}
                          href={getComponentUrl(menuComponent, 94, 162, null)}
                        />
                      )
                    }
                    onClick=""
                  />
                </Grid>
              ))
            : ""
          : ""}
      </Grid>

      <div className="spacingInvoice"></div> */}
      {/* <div className="titleAdicitional">Notas</div> */}
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        {/* {notas !== undefined
          ? notas !== null
            ? notas.map((data, index) => (
                <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                  <CustomCardAditionalData
                    data={data}
                    type="notes"
                    diantipomodalidadid={document.diantipomodalidadid}
                    tipodocumentoentidad={document.tipodocumentoentidad}
                    documentompresa={document.documentompresa}
                    razonsocialempresa={document.razonsocialempresa}
                    // openFileModal={OpenFileModal}
                    // openMailModal={OpenMailModal}
                    // handleOpenModalWarning={handleOpenModalWarning}
                    // handleShowDetailNote={handleShowDetailNote}
                    // onClick={notificationNote}
                    // loadingGetNoteId={loadingGetNoteId}
                  />
                </Grid>
              ))
            : ""
          : ""} */}
      </Grid>

      <div className="spacingInvoice"></div>
      {/* <div className="titleAdicitional">Documentos de referencia</div>
      <div className="spacingInvoice"></div>
      <Grid container spacing={1}>
        {documentosreferencia.map((data, index) => (
          <Grid item key={index} lg={12} xs={12}>
            <CustomCardAditionalData data={data} type="docref" />
          </Grid>
        ))}
      </Grid> */}
    </div>
  );
};

export default GetInfoAditional;
