import {
  MIN_DAYS_DATE_DOCUMENT,
  MAX_DAYS_DATE_DOCUMENT,
} from "../config/config";

/**
 * Obtiene fecha minima para generación factura
 * @returns
 */
export const getDateNotBefore = () => {
  let date = new Date();
  date.setDate(date.getDate() - (MIN_DAYS_DATE_DOCUMENT ?? 10) + 1); // Cuenta dia actual
  return date;
};

/**
 * Obtiene fecha maxima para generación factura
 * @returns
 */
export const getDateNotAfter = () => {
  let date = new Date();
  date.setDate(date.getDate() + (MAX_DAYS_DATE_DOCUMENT ?? 10) - 1); // Cuenta dia actual
  return date;
};

/**
 * Obtiene fecha minima para generación factura
 * Documento soporte
 * @returns
 */
export const getDateNotBeforeDS = (minDays) => {
  let date = new Date();
  date.setDate(date.getDate() - (minDays ?? 10) + 1); // Cuenta dia actual
  return date;
};

/**
 * Obtiene fecha maxima para generación factura
 * Documento soporte
 * @returns
 */
export const getDateNotAfterDS = (maxDays) => {
  let date = new Date();
  date.setDate(date.getDate() + (maxDays ?? 10) - 1); // Cuenta dia actual
  return date;
};

/**
 * Obtener fecha minima para fecha vencimiento
 */
export const getDateMinPay = (dateCurrent) => {
  let date = new Date(dateCurrent);
  date.setDate(date.getDate() + 1);
  return date;
};

/**
 * Fecha de pago por defecto
 * @returns
 */
export const getDatepay = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};
