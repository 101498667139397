import { types } from "../types/types";

const initialState = {
  listMenu: [],
  loading: false,
  errorResponse: null,
  bulkLoadStatus: {},
  certificate: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_MENU:
      return {
        ...state,
        listMenu: action.payload,
      };
    case types.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_INDEX_SELECTED:
      return {
        ...state,
        indexSelected: action.payload,
      };
    case types.CERTIFICATE_CONSULTED :
      return {
        ...state,
        certificate: action.payload,
      };
    default:
      return state;
  }
}
