import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import {
  getClientReportAction,
  getInvoiceReportAction,
} from "../../actions/invoiceActions";
import { isSiigoEnterprise } from "../../actions/authActions";

import { CardReport } from "./cardReport.component";
import { ExcelInvoice } from "./excelInvoice";
import { ExcelClient } from "./excelClient";

export const Report = () => {
  const history = useHistory();

  useEffect(() => {
    if (!isSiigoEnterprise()) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-form">
      <Grid container spacing={2} direction="column">
        <Grid item lg={5} md={12} xs={12} direction="column">
          <CardReport
            title={"Informe para Contabilidad"}
            subtitle={
              "En este informe tendrá los datos específicos para llevar la contabilidad de SIIGO."
            }
            nameValidator={"alphanumeric2"}
            titleModal={"Informe Facturación"}
            textModal={
              "Usted podrá descargar información de las facturas en un rango de tiempo a su vez, si requiere descargar una factura en específico, puede realizar la búsqueda y luego descargar el informe."
            }
            filterText={"Número de factura o Documento"}
            reportTitle={"informeFacturacion"}
            action={getInvoiceReportAction}
            ExcelComponent={ExcelInvoice}
          />
        </Grid>
        <Grid item lg={5} md={12} xs={12} direction="column">
          <CardReport
            title={"Informe para terceros"}
            subtitle={
              "En este informe tendrá los datos específicos de los terceros (Adquirientes)."
            }
            nameValidator={"alphanumeric2"}
            titleModal={"Informe Terceros"}
            filterText={"Número de Documento o Nombres"}
            reportTitle={"informeTerceros"}
            textModal={
              "Usted podrá descargar información de los terceros en un rango de tiempo a su vez, si requiere descargar un tercero en específico, puede realizar la búsqueda y luego descargar el informe."
            }
            action={getClientReportAction}
            ExcelComponent={ExcelClient}
          />
        </Grid>
      </Grid>
    </div>
  );
};
