import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";

import { CollapseFormPanel } from "../../../components/ui/collapseFormPanel";
import { PatientForm } from "./patientForm";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import SwitchForm from "../../../components/switch/switch.component";
import { PatientCard } from "./patientCard";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";

import {
	deletePatientAction,
	loadPatientAction,
	cleanDataPatientAction,
	refreshDataPatientAction,
} from "../../../actions/patientAction";
import { addDataPatientAction } from "../../../actions/documentAction";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config";
import { ConfirmAlert } from "../../../helpers/alert.helpers";


const animate = "animate__animated animate__fadeIn animate__faster";

const useStyles = makeStyles((theme) => ({
	containerManage: {
		overflow: "auto",
		height: "73vh",
		width: "100%",
		overflowX: "hidden",
	},
	title: {
		color: "#7DD1F8",
		fontStyle: "italic",
		fontSize: 18,
		fontWeight: 500,
		textAlign: "center",
	},
	containerList: {
		height: "50vh",
		overflow: "auto",
		overflowX: "hidden",
		margin: "15px auto",
	},
}));

export const PatientManage = ({ handleChangeStep, handleBackStep }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [expandPanelPatient, setExpandPanelPatient] = useState(false);
	const [generateDocument, setGenerateDocument] = useState(false);
	const { patientList, active: loadedPatient } = useSelector(
		(s) => s.patientReducer
	);
	const { oppacientes, generardocumentopaciente } = useSelector(
		(s) => s.documentReducer
	);

	/**
	 * Inicializa componente
	 */
	useEffect(() => {
		return () => {
			dispatch(cleanDataPatientAction());
		};
	}, [dispatch]);

	/**
	 * Actualiza listado pacientes
	 */
	useEffect(() => {
		dispatch(refreshDataPatientAction(oppacientes));
	}, [dispatch, oppacientes]);

	/**
	 * Carga estado generar documento soporte pacientes
	 */
	useEffect(() => {
		setGenerateDocument(generardocumentopaciente);
	}, [generardocumentopaciente]);

	/**
	 * Sincroniza estado generar documento pacientes
	 * @param {*} e
	 */
	const handleCheckGenerateDocument = (e) => {
		const checked = e.target.checked;
		setGenerateDocument(checked);
	};

	/**
	 * Expande o contrae formulario paciente
	 */
	const handleExpandPatient = () => {
		setExpandPanelPatient((prev) => !prev);
	};

	/**
	 * Contrae formulario paciente
	 */
	const handleClosePanel = (status) => {
		setExpandPanelPatient(status);
	};

	/**
	 * Carga información de paciente para edición
	 */
	const handleLoadData = useCallback(
		(data) => {
			dispatch(loadPatientAction(data));
		},
		[dispatch]
	);

	/**
	 * Elimina información de paciente
	 */

	const handleDelete = useCallback(
		(id, nombres) => {
			const deleteData = (confirmed) => {
				if (confirmed) {
					dispatch(deletePatientAction(id));
					dispatch(loadPatientAction(null));
				}
			};
			ConfirmAlert(
				`¿Estás seguro de eliminar el paciente ${nombres}?`,
				deleteData,
				MESSAGE_DELETE_CONFIRM
			);
		},
		[dispatch]
	);

	/**
	 * Guarda información de paciente
	 */
	const handleSaveData = () => {
		dispatch(addDataPatientAction(patientList, generateDocument));
		handleChangeStep();
	};

	return (
		<div style={{ paddingRight: 15, paddingLeft: 15 }}>
			<Grid container spacing={3}>
				<Grid item lg={12} xs={12} className={animate}>
					<Grid container spacing={3} justify="center">
						<Grid item lg={8} xs={12}>
							<div className={classes.title}>
								Esta sección es opcional, aquí podrás diligenciar la información
								de los pacientes relacionados con los artículos, productos o
								servicios que vas a facturar.
							</div>
							<div className="spacingInvoice" />
							<div className="lineDivision" />
							<div className="spacingInvoice" />
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item lg={12} xs={12}>
							<CollapseFormPanel
								component={<PatientForm handleClosePanel={handleClosePanel} />}
								title={"Agregar paciente"}
								titleOpen={
									!!loadedPatient ? "Editar paciente" : "Nuevo paciente"
								}
								open={expandPanelPatient}
								onClick={handleExpandPatient}
							/>
						</Grid>
					</Grid>
					<div className={classes.containerList}>
						<Grid container spacing={2}>
							{patientList.map((item) => (
								<Grid item key={item.id} lg={12} xs={12}>
									<PatientCard
										handleDelete={handleDelete}
										handleLoadData={handleLoadData}
										data={item}
									/>
								</Grid>
							))}
						</Grid>
					</div>

					<Grid container justify={"space-between"}>
						<Grid item xl={4} lg={5} md={6} xs={12}>
							<SwitchForm
								name={"chkGenerarDocumento"}
								checked={generateDocument}
								titleOn="Generar documento de relación de pacientes"
								onChange={handleCheckGenerateDocument}
								txtTooltip={
									<TooltipMessage
										title={"¿Que es esta opción?"}
										message={
											"Te permitirá generar un documento adicional a la factura con la información que diligencies en esta sección, ten en cuenta que esto se verá reflejado en la capacidad de almacenamiento de tu plan."
										}
										botton={"Más información"}
										href={""}
									/>
								}
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<ButtonPrimary
								text={"Continuar"}
								onClick={handleSaveData}
								type={"button"}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
