import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";

import CustomProgress from "../../components/Progress/progress.component";
import ResponseModal from "../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../components/modal/responseRedirectModal.component";
import { DocumentManage } from "./head/documentManage.view";
import { DocumentCartManage } from "./cart/documentCartManage.view";
import { DocumentReview } from "./review/documentReview.view";
import { PatientManage } from "./patient/patientManage";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import ArchiveModal from "../../components/modal/archiveModal.component";

import { DIGITS_AFTER_DECIMAL, MESSAGE_NEXT_CONFIRM } from "../../config/config";

import {
  createElectronicInvoice,
  createQoutation,
  updateOpenModalItem,
  getDocumentPreviewAction,
  cleanLicenseBaseAction,
} from "../../actions/invoiceActions";
import { standOutDocumentAction, cleanDocumentCreateAction } from "../../actions/documentAction";
import { getCompanyAction } from "../../actions/basicDataActions";

import { CLAIM_DOCUMENTS } from "../../config/config";
import { ConfirmAlert, WarningAlert } from "../../helpers/alert.helpers";
import { warningToast } from "../../helpers/toast.helpers";
import { checkTotalDocument } from "../../helpers/validateDocument";

import { generateRequestDocument, generateRequestQuotation } from "../../helpers/generateRequestDocument";
import { getUvtAction } from "../../actions/configAction";
import { GetUVTYear } from "../../utils/getUvtYear";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: "white",
  },
  container: {
    backgroundColor: "#232323",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {" "}
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}{" "}
    </div>
  );
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function getSteps() {
  return ["Información de Factura", "Datos del paciente", "Artículos", "Revisar Factura"];
}

const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "}
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

export const CreateDocumentView = ({ HandleBack, HandleBulkUpload }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [stepLabels, setStepLabels] = useState([]);
  const [isHealthService, setIsHealthService] = useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = useState(initialModal);
  const [modalResponse, setModalResponse] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
    closeFromModalBody: false,
  });

  const { informationBulkLoad, stateBulkLoad, openModalItem: modal, licenseBase } = useSelector((state) => state.invoiceReducer);
  const documentdata = useSelector((state) => state.documentReducer);
  const { favorito, opdocumentosreferencia } = documentdata;
  const totals = useSelector((state) => state.documentCartReducer);
  const { allUvt } = useSelector((state) => state.configReducer);
  const { requestCompany: company } = useSelector((s) => s.basicDataReducer);
  const { showCatalog, valorapagar, opdetalledocumento, valorcargosaplicados, valorbase } = totals;

  /**
   * Desmontado componente
   */
  useEffect(() => {
    dispatch(getCompanyAction(true));
    dispatch(getUvtAction());

    return () => {
      dispatch(cleanDocumentCreateAction());
    };
  }, [dispatch]);

  useEffect(() => {
    let labels = getSteps();
    //company?.esprestadorserviciosalud === false
    if (true) {
      labels = labels.filter((c) => c !== "Datos del paciente");
    }
    //setIsHealthService(company?.esprestadorserviciosalud ?? false);
    setIsHealthService(false); //Siempre false sprint 13
    setStepLabels(labels);
  }, [company]);

  useEffect(() => {
    if (loading) {
      setModalResponse({
        type: "success",
        title: "",
        subtitle: "Generando documento, por favor espere...",
        body: (
          <>
            {" "}
            <CustomProgress />
            {"Procesando"}{" "}
          </>
        ),
        modalImage: "noimagen",
        open: true,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
  }, [licenseBase]);

  /**
   * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    dispatch(cleanLicenseBaseAction());
  };

  /**
   * Avanza paso siguiente
   */
  const handleNextStep = () => {
    let i = 0;
    let newCompleted = new Set();
    for (i === 0; i < activeStep + 1; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
    setActiveStep(activeStep + 1);
  };

  /**
   * Regresa a paso anterior
   * @param {*} step
   */
  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  /**
   * Valida totales generados en carrito
   * @returns
   */
  const validateTotalsDocument = () => {
    const uvtx5 = GetUVTYear(new Date().getFullYear(), allUvt) * 5;
    const uvt = uvtx5.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });
    const valueBruto = totals.valorbruto.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });

    if (documentdata.opdocumento?.numeracion?.diantipomodalidadid === 4 && totals.valorbruto >= uvtx5) {
      WarningAlert(
        "Upsss...!",
        `El valor bruto de la factura tipo POS sin incluir impuestos es de <span style = "white-space: nowrap;">($ ${valueBruto})</span> supera los 5 UVT <span style = "white-space: nowrap;">($ ${uvt})</span> para el año ${new Date().getFullYear()}. `,
        10000
      );
      return;
    }
    const valid = checkTotalDocument(valorapagar, opdocumentosreferencia);
    if (!valid) {
      warningToast("Valor anticipo no valido.", "El valor del anticipo es superior al valor de la factura. Por favor verifique la información.");
      return;
    } else {
      handleNextStep();
    }
  };

  /**
   * Desctacar documento
   */
  const handleStandOut = () => {
    dispatch(standOutDocumentAction());
  };

  /**
   * Marca paso a completo
   * @param {*} step
   * @returns
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  /**
   * Valida información de documento antes de guardar
   */
  const validateDocument = () => {
    let errorMessages = [];
    if (opdetalledocumento.length === 0) {
      errorMessages.push("Debes seleccionar al menos un producto.");
    } else {
      if (valorapagar < 0) errorMessages.push("El valor a pagar de la factura no puede ser negativo.");

      //Campos obligatorios por item
      let valid = true;
      opdetalledocumento.forEach((item) => {
        if (item.esregalo) {
          if (!item.asumeresponsabilidad || !item.valorreferencia) {
            valid = false;
          }
        } else {
          if (item.valorneto === 0 || !item.valorneto) {
            valid = false;
          }
        }
      });

      if (!valid) errorMessages.push("Tienes campos pendientes por completar.");

      opdetalledocumento.forEach((item) => {
        if (item.total < 0) {
          errorMessages.push(`El artículo "${item.nombrearticulo}" tiene un valor total negativo de $${item.total}.`);
        }
      });

      //Valores anticipo
      let anticipovalido = checkTotalDocument(valorapagar, opdocumentosreferencia);
      if (!anticipovalido) errorMessages.push("El valor del anticipo es superior al valor de la factura. Por favor verifique la información.");

      //Valores cargos
      if (valorbase < valorcargosaplicados) {
        errorMessages.push("El valor de los cargos no puede ser superior a la factura con descuentos");
      }
    }

    if (errorMessages.length > 0) {
      let message = errorMessages.join("       ");
      WarningAlert("Upss...!", message);
      return false;
    }

    return true;
  };

  /**
   * Envia documento para registro
   */
  const handleSaveDocument = (e) => {
    e.preventDefault();
    const uvtx5 = GetUVTYear(new Date().getFullYear(), allUvt) * 5;
    const uvt = uvtx5.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });
    const valueBruto = totals.valorbruto.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });

    if (documentdata.opdocumento?.numeracion?.diantipomodalidadid === 4 && totals.valorbruto >= uvtx5) {
      WarningAlert(
        "Upsss...!",
        `El valor bruto de la factura tipo POS sin incluir impuestos es de <span style = "white-space: nowrap;">($ ${valueBruto})</span> supera los 5 UVT <span style = "white-space: nowrap;">($ ${uvt})</span> para el año ${new Date().getFullYear()}. `,
        10000
      );
      return;
    }
    if (validateDocument()) {
      const deleteData = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          let document = generateRequestDocument(documentdata, totals);
          dispatch(createElectronicInvoice(document, readResponseSendInvoice, readErrorSendInvoice));
        }
      };
      ConfirmAlert(`¿Estás seguro de guardar y enviar la factura?`, deleteData, MESSAGE_NEXT_CONFIRM);
    }
  };

  /**
   * Genera previsualización de documento
   * @param {*} e
   */
  const handlePreviewDocument = (e) => {
    e.preventDefault();

    const uvtx5 = GetUVTYear(new Date().getFullYear(), allUvt) * 5;
    const uvt = uvtx5.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });
    const valueBruto = totals.valorbruto.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });

    if (documentdata.opdocumento?.numeracion?.diantipomodalidadid === 4 && totals.valorbruto >= uvtx5) {
      WarningAlert(
        "Upsss...!",
        `El valor bruto de la factura tipo POS sin incluir impuestos es de <span style = "white-space: nowrap;">($ ${valueBruto})</span> supera los 5 UVT <span style = "white-space: nowrap;">($ ${uvt})</span> para el año ${new Date().getFullYear()}. `,
        10000
      );
      return;
    }

    if (validateDocument()) {
      setOpenModalFile(true);
      setLoadingFile(true);
      let document = generateRequestDocument(documentdata, totals);

      dispatch(getDocumentPreviewAction(document, readResponseGetFile));
    }
  };

  /**
   * Envia documento para registro como cotización
   * @param {*} e
   */
  const handleSaveQuotation = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      const deleteData = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          let document = generateRequestQuotation(documentdata, totals);
          dispatch(createQoutation(document, readResponseSendQuotation, readErrorSendQuotation));
        }
      };
      ConfirmAlert(`¿Estás seguro de guardar esta factura como una cotización?`, deleteData, MESSAGE_NEXT_CONFIRM);
    }
  };

  /**
   * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response);
    setLoadingFile(false);
  };

  /**
   * Cierra modal previsualizació documento
   */
  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
  };

  /**
   * Cancelar creación documento
   */
  const handleCancelDocument = () => {
    HandleBack();
  };

  /**
   * Lee respuesta de envio documento
   * @param {*} response
   */
  const readResponseSendInvoice = (response) => {
    if (response.data.statusCode === "201") {
      setLoading(false);
      setModalResponse({
        type: "success",
        title: "Fenomenal!!!",
        subtitle: "Has generado una nueva factura",
        body: (
          <div>
            <div>{response.data.statusMessage.indexOf("Rechazo") !== -1 ? "Factura generada con error al enviar a Dian" : "Proceso Exitoso"}</div>
            <div className="fenomenalWhiteLittle">
              {response.data.statusMessage} <span className="fenomenalBlueLittle">No. {response.data.result}</span>
            </div>
          </div>
        ),
        modalImage: "success",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    } else {
      setLoading(false);
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>{"Factura generada con error al enviar a Dian"}</div>
            <div className="fenomenalWhiteLittle">
              {response.data.statusMessage} Factura Generada: <span className="fenomenalBlueLittle">No. {response.data.result}</span>
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: false,
      });
    }
  };

  /**
   * Lee error envio documento
   * @param {*} response
   */
  const readErrorSendInvoice = (response) => {
    setLoading(false);
    const statusCode = response.data.statusCode;
    const statusMessage = response.data.statusMessage;
    if (statusCode === "404" && statusMessage === "Unauthorized, no quota") {
      setModalResponse({
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    } else {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>{JSON.stringify(response === undefined ? "Error inesperado" : response?.data?.statusMessage)}</div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    }
  };

  /**
   * Lee respuesta envio cotización
   * @param {*} response
   */
  const readResponseSendQuotation = (response) => {
    setLoading(false);
    setModalResponse({
      type: "success",
      title: "Perfecto!!!",
      subtitle: "Has generado una nueva cotización",
      body: (
        <div>
          <div>Perfecto, tenemos un cliente interesado, hay que convencerlo.</div>
          <div className="fenomenalWhiteLittle">
            {response.data.statusMessage} Se genero exitosamente la cotización <span className="fenomenalBlueLittle">No. {response.data.result}</span>
          </div>
        </div>
      ),
      modalImage: "quotation",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 1,
      closeFromModalBody: true,
    });
  };

  /**
   * Lee error envio cotización
   * @param {*} response
   */
  const readErrorSendQuotation = (response) => {
    setLoading(false);
    setModalResponse({
      type: "warning",
      title: "Upss...!!!",
      subtitle: "Ocurrió algo inesperado",
      body: (
        <div>
          <div>{JSON.stringify(response.data.statusMessage)}</div>
        </div>
      ),
      modalImage: "warning",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 0,
      closeFromModalBody: true,
    });
  };

  /**
   * Cierra modal mensaje envio documento
   * @param {*} rta
   */
  const handleResponseModal = (rta) => {
    if (rta === 1) {
      HandleBack();
    }

    setModalResponse({
      type: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: 0,
      closeFromModalBody: false,
    });
  };

  /**
   * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    dispatch(updateOpenModalItem(false));
  };

  const footPage = (step) => {
    if (step === "HEAD") {
      return (
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <div className="bottomButtonsInvoiceRevision">
              <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                  <IconButton classes={{ root: classes.button, label: classes.label }} variant="raised" disableRipple={true} onClick={handleStandOut}>
                    {favorito ? <StarIcon /> : <StarBorderIcon />}
                    <span style={{ marginTop: 5 }}>Favorito</span>
                  </IconButton>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleCancelDocument}
                  >
                    <ClearIcon />
                    <span style={{ marginTop: 5 }}>Cancelar</span>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      );
    } else if (step === "PATIENT") {
      return (
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <div className="bottomButtonsInvoiceRevision">
              <Grid container spacing={1}>
                <Grid item lg={4} xs={4} style={{ textAlign: "left" }}>
                  <IconButton
                    classes={{
                      root: classes.button,
                      label: classes.labelHorizontal,
                    }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleBackStep}
                    style={{ marginLeft: 10, marginTop: 10 }}
                  >
                    <ArrowBackIosIcon />
                    <span style={{ marginLeft: 5, fontSize: 14 }}>Volver</span>
                  </IconButton>
                </Grid>
                <Grid item lg={4} xs={4}>
                  <IconButton classes={{ root: classes.button, label: classes.label }} variant="raised" disableRipple={true} onClick={handleStandOut}>
                    {favorito ? <StarIcon /> : <StarBorderIcon />}
                    <span style={{ marginTop: 5 }}>Favorito</span>
                  </IconButton>
                </Grid>
                <Grid item lg={4} xs={4}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleCancelDocument}
                  >
                    <ClearIcon />
                    <span style={{ marginTop: 5 }}>Cancelar</span>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <div className="bottomButtonsInvoiceRevision">
              <Grid container spacing={1}>
                <Grid item lg={3} xs={3} style={{ textAlign: "left" }}>
                  <IconButton
                    classes={{
                      root: classes.button,
                      label: classes.labelHorizontal,
                    }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleBackStep}
                    style={{ marginLeft: 10, marginTop: 10 }}
                  >
                    <ArrowBackIosIcon />
                    <span style={{ marginLeft: 5, fontSize: 14 }}>Volver</span>
                  </IconButton>
                </Grid>
                <Grid item lg={3} xs={3}>
                  <IconButton classes={{ root: classes.button, label: classes.label }} variant="raised" disableRipple={true} onClick={handleStandOut}>
                    {favorito ? <StarIcon /> : <StarBorderIcon />}
                    <span style={{ marginTop: 5 }}>Favorito</span>
                  </IconButton>
                </Grid>
                <Grid item lg={3} xs={3}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handlePreviewDocument}
                  >
                    <VisibilityIcon />
                    <span style={{ marginTop: 5 }}>Previsualizar</span>
                  </IconButton>
                </Grid>

                <Grid item lg={3} xs={3}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleCancelDocument}
                    style={{ marginRight: 10 }}
                  >
                    <ClearIcon />
                    <span style={{ marginTop: 5 }}>Cancelar</span>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      );
    }
  };

  /**
   * Componente por paso
   * @param {*} step
   * @returns
   */
  const getStepContent = (step) => {
    const head = (
      <>
        <DocumentManage
          handleChangeStep={handleNextStep}
          HandleBulkUpload={HandleBulkUpload}
          InformationBulk={informationBulkLoad}
          StateBulk={stateBulkLoad}
          typedocument={"FA"}
        />
        {footPage("HEAD")}
      </>
    );

    const patient = (
      <>
        <PatientManage handleChangeStep={handleNextStep} handleBackStep={handleBackStep} />
        {footPage("PATIENT")}
      </>
    );

    const cart = (
      <>
        <DocumentCartManage typedocument={"FA"} />
        {!showCatalog && (
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xl={11} lg={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={4} xs={12}>
                  <ButtonPrimary type="button" onClick={handleSaveQuotation} text={"Guardar como cotización"} />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <ButtonPrimary type="button" onClick={validateTotalsDocument} text={"Revisar factura"} />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <ButtonPrimary type="button" onClick={handleSaveDocument} text={"Enviar factura"} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {footPage()}
      </>
    );

    const review = (
      <>
        <DocumentReview typedocument={"FA"} />
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xl={11} lg={12} sm={12}>
            <Grid container spacing={1} style={{ textAlign: "right" }}>
              <Grid item lg={4} xs={12}>
                <ButtonPrimary type="button" onClick={handleSaveQuotation} text={"Guardar como cotización"} />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ButtonPrimary type="button" onClick={handleSaveDocument} text={"Enviar factura"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {footPage()}
      </>
    );

    if (isHealthService) {
      switch (step) {
        case 0:
          return head;
        case 1:
          return patient;
        case 2:
          return cart;
        case 3:
          return review;
        default:
          return <>No valido</>;
      }
    } else {
      switch (step) {
        case 0:
          return head;
        case 1:
          return cart;
        case 2:
          return review;
        default:
          return <>No valido</>;
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<QontoConnector />} className={classes.root}>
          {stepLabels.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            return (
              <Step key={label} {...stepProps} className={activeStep === index ? classes.activeButton : ""}>
                <StepButton completed={isStepComplete(index)} {...buttonProps}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    <label className="stepper-title">{label}</label>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>{getStepContent(activeStep)}</div>
      </div>
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={"Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"}
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />
      <ArchiveModal loading={loadingFile} open={openModalFile} pdfBase64={base64File} handleClose={handleCloseFileModal} />
    </>
  );
};
