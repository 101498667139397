export const getDescriptionRole = (code) => {

    switch (code) {
        case 'CFADMIN':
            return "Administrador";
        case 'CFCOLAB':
            return "Colaborador"
        case 'CFREPLEG':
            return "Representante Legal"
        default:
            return '';
    }

}