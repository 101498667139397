import {
  API_ENDPOINT_ARTICLE,
  API_ENDPOINT_CATALOG,
  API_IBUHOO_IMAGEMANAGER,
} from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";

import isEmpty from "../utils/isEmpty";
import {
  QUANTITY_DATA,
  defaultHeaders,
  TIME_CREATE_REGISTER,
  TIME_UPDATE_REGISTER,
} from "../config/config.js";
import { SuccessAlert, WarningAlert } from "../helpers/alert.helpers";

import { enterpriseIdHeader } from "./authActions";
import { addArticlesToCart } from "../actions/valuesInvoiceAction";
import { types } from "../types/types";
import { generateDocumentItem } from "../helpers/calculateDocumentValues";
import { updateDocumentTotals } from "./documentCartAction";
import createTaxHandlers from "../utils/createTaxHandlers";

let allArticles = [];
let totalArticles = 0;
let checkedArticles = [];

/**
 * Obtiene listado unidades de medida
 */
export const getUnitMeasureAction = () => async (dispatch) => {
  try {
    var result = await axiosApiInstance.get(
      `${API_ENDPOINT_ARTICLE}${"/item/api/unidadmedida"}`,
      defaultHeaders()
    );

    let response = {
      statusCode: "200",
      StatusMessage: "Registro exitoso",
      result: result.data.map((item) => {
        return {
          ...item,
          value: item.id,
          text: item.descripcion,
        };
      }),
    };

    dispatch({
      type: types.getUnitMeasure,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado unidades de medida",
        error: err,
      },
    });
  }
};

/**
 * Realiza consumo de web api para registro de articulo y categorias asociadas
 * @param {object} data
 */
export const createArticleAction = (data, type = 0, completeSave) => async (
  dispatch,
  getState
) => {
  try {
    changeSaveLoading(dispatch, true);
    data = await registerImageArticle(data);

    data = {
      ...data,
      idowner: 1,
      createdby: 1,
      modifiedby: 1,
      empresaid: `${enterpriseIdHeader()}`,
    };

    const resp = await axiosApiInstance.post(
      `${API_ENDPOINT_ARTICLE}${"/item/api/articulo/CreateArticle"}`,
      data,
      defaultHeaders()
    );

    const { statusCode, result } = resp.data;
    if (statusCode === "201") {
      const { allUvt, taxList } = getState().configReducer;

      await refreshAfterSave(result, type, dispatch, allUvt, taxList);
      completeSave();
      SuccessAlert(
        null,
        `Creaste el artículo ${data.nombrearticulo} de manera exitosa, sigamos Facturando`,
        TIME_CREATE_REGISTER
      );
    } else if (statusCode === "210") {
      completeSave();
      WarningAlert(
        "Registro incompleto.",
        "Ha ocurrido un error, es posible que la información de articulo no se haya registrado por completo."
      );
    } else if (statusCode === "211") {
      WarningAlert(
        "Upss...",
        "Ya se existe un articulo con la referencia digitada."
      );
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido registrar articulo.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Registra imagen asociada a un articulo
 * @param {*} data Información articulo
 */
const registerImageArticle = async (data, action = null) => {
  try {
    if (
      isEmpty(data.imagen) ||
      data.imagen === `${API_IBUHOO_IMAGEMANAGER}${data.imgArticleSrcOld}`
    )
      return { ...data, imagen: data.imgArticleSrcOld };

    let body = {
      ApplicationName: "ColFactura",
      Extension: ".png",
      ImageBase64: data.imagen.replace("data:image/png;base64,", ""),
    };

    if (action === "UPDATE") {
      Object.assign(body, {
        RelativePath: data.imgArticleSrcOld,
      });
    }

    const resultSaveImage = await axiosApiInstance.post(
      `${API_IBUHOO_IMAGEMANAGER}${"/imagemanager/api/image"}`,
      body,
      defaultHeaders()
    );

    const urlimagen = resultSaveImage?.data?.result?.relativePath;
    return { ...data, imagen: urlimagen };
  } catch (err) {
    console.log(
      "Error guardando imagen de ariculo,",
      !!err.response ? err.response : err
    );

    if (action === "UPDATE") return { ...data, imagen: data.imgArticleSrcOld };

    return { ...data, imagen: "" };
  }
};

/**
 * Registra información articulo y lo agrega al carrito si el registro es exitoso
 * @param {object} data Infromación articulo
 */
export const createAndAddArticleAction = (data, cleanForm) => async (
  dispatch
) => {
  try {
    changeSaveLoading(dispatch, true);
    data = await registerImageArticle(data);

    data = {
      ...data,
      idowner: 1,
      createdby: 1,
      modifiedby: 1,
      empresaid: `${enterpriseIdHeader()}`,
    };

    var resp = await axiosApiInstance.post(
      `${API_ENDPOINT_ARTICLE}${"/item/api/articulo/CreateArticle"}`,
      data,
      defaultHeaders()
    );

    const { statusCode, result: id } = resp.data;
    if (statusCode === "201") {
      await updateValuesInvoice(id, dispatch);
      cleanForm();
      SuccessAlert(
        null,
        "Creaste un nuevo artículo de manera exitosa, sigamos Facturando",
        TIME_CREATE_REGISTER
      );
    } else if (statusCode === "210") {
      cleanForm();
      WarningAlert(
        "Registro incompleto.",
        "Ha ocurrido un error, es posible que la información de articulo no se haya registrado por completo."
      );
    } else if (statusCode === "211") {
      WarningAlert(
        "Upss...",
        "Ya se existe un articulo con la referencia digitada."
      );
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido registrar articulo.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

//Migración Hooks
export const createAndAddProductAction = (
  product,
  reset,
  setOpenModalProduct
) => async (dispatch, getState) => {
  try {
    const { cartDocumentSupport } = getState().documentCartReducer;
    if (cartDocumentSupport) {
      addProductToDocumentSupport(
        product,
        dispatch,
        reset,
        getState,
        setOpenModalProduct
      );
      return;
    }
    changeSaveLoading(dispatch, true);
    product = await registerImageArticle(product);
    product = { ...product, empresaid: `${enterpriseIdHeader()}` };

    const resp = await axiosApiInstance.post(
      `${API_ENDPOINT_ARTICLE}${"/item/api/articulo/CreateArticle"}`,
      product,
      defaultHeaders()
    );
    const { statusCode, result: id } = resp.data;

    switch (statusCode) {
      case "201":
        await addProductToDocument(id, dispatch, getState);
        reset();
        SuccessAlert(
          null,
          "Creaste un nuevo artículo de manera exitosa, sigamos Facturando",
          TIME_CREATE_REGISTER
        );
        break;
      case "210":
        reset();
        WarningAlert(
          "Registro incompleto.",
          "Ha ocurrido un error, es posible que la información de articulo no se haya registrado por completo."
        );
        break;
      case "211":
        WarningAlert(
          "Upss...",
          "Ya se existe un articulo con la referencia digitada."
        );
        break;
      default:
        break;
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido registrar articulo.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

const addProductToDocument = async (id, dispatch, getState) => {
  let configItem = defaultHeaders();
  Object.assign(configItem.headers, {
    pId: id,
  });

  const url = `${API_ENDPOINT_CATALOG}${"/catalog/api/Articulo/GetArticleById/"}`;
  const resp = await axiosApiInstance.get(url, configItem);

  let createdItem = { ...resp.data.result.records };

  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  const { allUvt, taxList } = getState().configReducer;

  const convertData = convertDataList(
    [resp.data?.result?.records],
    allUvt,
    taxList
  );

  createdItem = generateDocumentItem(convertData[0]);

  let allitems = [...opdetalledocumento, createdItem];
  dispatch(
    updateDocumentTotals(
      allitems,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

const addProductToDocumentSupport = async (
  product,
  dispatch,
  reset,
  getState,
  setOpenModalProduct
) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
  } = getState().documentCartReducer;

  product = generateDocumentItem(product);

  let allitems = [...opdetalledocumento, product];
  dispatch(
    updateDocumentTotals(
      allitems,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport
    )
  );
  reset();
  SuccessAlert(
    null,
    "Creaste un nuevo Servicio de manera exitosa, sigamos Facturando",
    TIME_CREATE_REGISTER
  );

  setOpenModalProduct(false);
};

/**
 * Actualiza valores de factura despues de crear un nuevo articulo.
 * @param {*} id Identificador articulo
 * @param {*} dispatch
 */
const updateValuesInvoice = async (id, dispatch) => {
  let configItem = defaultHeaders();
  Object.assign(configItem.headers, {
    pId: id,
  });

  const url = `${API_ENDPOINT_CATALOG}${"/catalog/api/Articulo/GetArticleById/"}`;
  var resp = await axiosApiInstance.get(url, configItem);
  let items = [];

  let newItem = {
    ...resp.data.result.records,
    dateAdded: Date.now(),
  };

  items.push(newItem);
  let totals = addArticlesToCart(items);

  //Actualiza items en Redux
  dispatch({
    type: types.GET_DOCUMENT_DETAIL,
    payload: totals.detalleDocumento,
  });

  //Actualiza totales en Redux
  dispatch({
    type: types.GET_TOTAL_DETAIL,
    payload: totals,
  });
};

/**
 * Realiza consumo de web api para actualizacion de articulo y categorias asociadas
 * @param {object} data
 */
export const updateArticleAction = (data, type = 0, completeUpdate) => async (
  dispatch,
  getState
) => {
  try {
    changeSaveLoading(dispatch, true);
    data = await registerImageArticle(data, "UPDATE");

    data = {
      ...data,
      idowner: 1,
      createdby: 1,
      modifiedby: 1,
      empresaid: `${enterpriseIdHeader()}`,
    };

    var resp = await axiosApiInstance.post(
      `${API_ENDPOINT_ARTICLE}${"/item/api/articulo/UpdateArticle"}`,
      data,
      defaultHeaders()
    );

    const { statusCode, result } = resp.data;
    if (statusCode === "201") {
      const { allUvt, taxList } = getState().configReducer;

      await refreshAfterUpdate(result, type, dispatch, allUvt, taxList);
      completeUpdate();
      SuccessAlert(
        null,
        `Actualizaste el articulo ${data.nombrearticulo} de manera exitosa, sigamos Facturando`,
        TIME_UPDATE_REGISTER
      );
    } else if (statusCode === "210") {
      completeUpdate();
      WarningAlert(
        "Actualización incompleta.",
        "Ha ocurrido un error, es posible que la información de articulo no se haya actualizado por completo."
      );
    } else if (statusCode === "212") {
      completeUpdate();
      WarningAlert("Upss...", "No se ha encontrado información del articulo.");
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar articulo.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading guardado articulo
 * @param {*} dispatch
 * @param {*} status
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.loadingSaveArticle,
    payload: status,
  });
}

/**
 * Actualiza estado favorito de un articulo
 * @param {int} id Identificador articulo
 * @param {boolean} status  Estado de articulo (favorito)
 */
export const updateFavoriteStatusAction = (id, status, type = 0) => async (
  dispatch,
  getState
) => {
  try {
    changeUpdateLoading(dispatch, true);
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      articleId: id,
      articleState: status,
    });

    await axiosApiInstance.put(
      `${API_ENDPOINT_ARTICLE}${"/item/api/articulo/UpdateFavorite/"}`,
      {},
      myConfig
    );

    const { allUvt, taxList } = getState().configReducer;

    await refreshAfterUpdate(id, type, dispatch, allUvt, taxList);

    dispatch({
      type: types.updateFavoriteArticle,
      payload: new Date().getTime(),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar estado favorito.",
        error: err,
      },
    });
  } finally {
    changeUpdateLoading(dispatch, false);
  }
};

/**
 * Eliminado logico Articulo, Cambio de estado
 * @param {int} id Identificador articulo
 */
export const deleteArticleAction = (id) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      articleId: id,
    });

    await axiosApiInstance.put(
      `${API_ENDPOINT_ARTICLE}${"/item/api/articulo/DeleteArticle/"}`,
      null,
      myConfig
    );

    await refreshAfterDelete(id, dispatch);
    SuccessAlert(
      null,
      "Se ha eliminado el artículo de manera exitosa, sigamos Facturando",
      TIME_UPDATE_REGISTER
    );
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido eliminar articulo.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Carga información de articulo en Redux, para edición en componente
 * @param {object} data  Información de articulo
 */
export const loadArticleAction = (data) => (dispatch) => {
  dispatch({
    type: types.loadArticle,
    payload: data,
  });
};

/**
 * Limpia información de articulo en Redux, Despues de Edición
 */
export const clearLoadArticleAction = () => (dispatch) => {
  dispatch({
    type: types.loadArticle,
    payload: null,
  });
};

export const sendTabArticle = (index) => (dispatch) => {
  dispatch({
    type: types.sendIndexTab,
    payload: index,
  });
};

/**
 * Obtiene listado de articulos disponibles  por empresa,
 * obteniendo  lotes de 'N'  articulos de acuerdo a configuración de paginación y
 * parametros de busqueda definidos por usuario
 * @param {object} data Parametros de busqueda
 */
export const getArticleListAction = (data, refresh, initial) => async (
  dispatch,
  getState
) => {
  try {
    changeGetListLoading(dispatch, true);

    if (refresh === false) {
      allArticles = checkSelectedItems(allArticles);

      dispatch({
        type: types.getArticleList,
        payload: allArticles,
        payload1: totalArticles,
      });
    } else {
      var body = {
        companyId: `${enterpriseIdHeader()}`,
        filter: data.filter,
        quantity: QUANTITY_DATA,
        skip: data.skip,
        categories: !!data.categories ? data.categories : [],
        type: data.type,
        typeOrder: data.typeOrder,
      };

      const url = `${API_ENDPOINT_CATALOG}${"/catalog/api/Articulo/GetArticlesPaginationSearch"}`;
      var res = await axiosApiInstance.post(url, body, defaultHeaders());
      const { allUvt, taxList } = getState().configReducer;

      const convertData = convertDataList(
        res.data?.result?.records?.items,
        allUvt,
        taxList
      );

      if (initial === true) {
        allArticles = convertData;
        totalArticles = res.data?.result?.records?.total;
      } else {
        allArticles = removeDuplicateArticles([...allArticles, ...convertData]);
        allArticles = checkSelectedItems(allArticles);
        totalArticles = res.data?.result?.records?.total;
      }

      dispatch({
        type: types.getArticleList,
        payload: allArticles,
        payload1: totalArticles,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado de articulos disponibles.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * * Elimina los artículos duplicados de una lista de artículos.
 *
 * @param {Array} allArticles - La lista de artículos.
 * @returns {Array} - La lista de artículos sin duplicados.
 */
const removeDuplicateArticles = (allArticles) => {
  const uniqueArticlesMap = new Map();
  allArticles.forEach((article) => {
    uniqueArticlesMap.set(article.id, article);
  });
  return Array.from(uniqueArticlesMap.values());
};

/**
 * * Limpia la lista de artículos.
 *
 * @returns {Function} La función de acción que limpia la lista de artículos.
 */
export const cleanArticleListAction = () => (dispatch) => {
  dispatch({
    type: types.getArticleList,
    payload: [],
    payload1: 0,
  });
};

const convertDataList = (data, allUvt, taxList) => {
  data.forEach((item, index) => {
    const tax = convertTaxs(item.adimpuestoarticulo, allUvt, taxList);
    data[index] = { ...tax, ...item };
  });

  return data;
};

const convertTaxs = (itemToLoad, allUvt, listTax) => {
  const taxList = {};

  const taxHandlers = createTaxHandlers(taxList, allUvt);

  listTax.forEach(({ id, valor, tipo }) => {
    const taxFound = itemToLoad.find(
      (adimpuesto) => adimpuesto.impuestoid === id
    );

    if (taxFound && taxHandlers[tipo]) taxHandlers[tipo](valor, taxFound);
  });

  return taxList;
};

/**
 * Obtiene listado de articulos disponibles  por empresa y
 * parametros de busqueda definidos por usuario
 * @param {object} data Parametros de busqueda
 */
export const filterArticleListAction = (data) => async (dispatch, getState) => {
  try {
    changeGetListLoading(dispatch, true);

    //Limpia listado en memoria.
    allArticles = [];

    var body = {
      companyId: `${enterpriseIdHeader()}`,
      filter: data.filter,
      quantity: QUANTITY_DATA,
      skip: data.skip,
      categories: !!data.categories ? data.categories : [],
      type: data.type,
      typeOrder: data.typeOrder,
    };

    const url = `${API_ENDPOINT_CATALOG}${"/catalog/api/Articulo/GetArticlesPaginationSearch/"}`;
    var res = await axiosApiInstance.post(url, body, defaultHeaders());
    const { allUvt, taxList } = getState().configReducer;
    const convertData = convertDataList(
      res.data?.result?.records?.items,
      allUvt,
      taxList
    );
    allArticles = res.data?.result?.records?.items;
    allArticles = checkSelectedItems(convertData);
    totalArticles = res.data?.result?.records?.total;

    dispatch({
      type: types.getArticleList,
      payload: allArticles,
      payload1: totalArticles,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido filtrar listado de articulo.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Consulta articulo despues de actualizar y refresca listado en redux
 * @param {int} id
 */
const refreshAfterUpdate = async (id, type, dispatch, allUvt, taxList) => {
  try {
    dispatch({
      type: types.getArticleList,
      payload: [],
      payload1: 0,
    });

    changeGetListLoading(dispatch, true);

    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      pId: id,
    });

    const url = `${API_ENDPOINT_CATALOG}${"/catalog/api/Articulo/GetArticleById/"}`;
    var res = await axiosApiInstance.get(url, myConfig);

    const convertData = convertDataList(
      [res.data?.result?.records],
      allUvt,
      taxList
    );

    const newData = convertData[0];
    if (!!newData) {
      // Verifica si el articulo esta marcado como favorito
      // y el origen de la peticion (seccion todos---> 0 o seccion favoritos--> 1)
      if (type === 1 && newData.favorito === false) {
        allArticles = allArticles.filter((c) => c.id !== newData.id);
      } else {
        const index = allArticles.findIndex((e) => e.id === id);
        if (index < 0) {
          allArticles.push(newData);
        } else {
          let newArray = [...allArticles];
          newArray[index] = newData;
          allArticles = newArray;
        }
      }
    }

    dispatch({
      type: types.getArticleList,
      payload: allArticles,
      payload1: allArticles.count,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar articulo actualizado.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Consulta articulo despues de guardar y refresca listado en redux
 * @param {int} id
 */
const refreshAfterSave = async (id, type, dispatch, allUvt, taxList) => {
  try {
    dispatch({
      type: types.getArticleList,
      payload: [],
      payload1: 0,
    });

    changeGetListLoading(dispatch, true);

    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      pId: id,
    });

    const url = `${API_ENDPOINT_CATALOG}${"/catalog/api/Articulo/GetArticleById/"}`;
    const res = await axiosApiInstance.get(url, myConfig);

    const convertData = convertDataList(
      [res.data?.result?.records],
      allUvt,
      taxList
    );

    const newData = convertData[0];
    //Verifica si el articulo esta marcado como favorito
    // y el origen de la peticion (seccion todos---> 0 o seccion favoritos--> 1)
    if (type === 1 && newData.favorito === false) {
      //No se agrega a lista si no esta marcado como favorito
    } else {
      if (!isEmpty(newData)) allArticles.splice(0, 0, newData);
    }

    dispatch({
      type: types.getArticleList,
      payload: allArticles,
      payload1: allArticles.count,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar articulo creado.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Quita de la lista articulo eliminado y actualiz en Redux
 * @param {int} id
 */
const refreshAfterDelete = async (id, dispatch) => {
  try {
    dispatch({
      type: types.getArticleList,
      payload: [],
      payload1: 0,
    });

    changeGetListLoading(dispatch, true);

    allArticles = allArticles.filter((a) => a.id !== id);

    dispatch({
      type: types.getArticleList,
      payload: allArticles,
      payload1: allArticles.count,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar nuevo listado articulos.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Limpia listado de articulos cargados en memoria
 * @action
 */
export const clearArticleListAction = () => async (dispatch) => {
  allArticles = [];
  dispatch({
    type: types.getArticleList,
    payload: allArticles,
  });
};

/**
 * Ordena listado articulos de acuerdo a la selección del usuario
 * @param {int} type  Tipo de ordenamiento
 */
export const orderArticleListAction = (type) => async (dispatch) => {
  changeGetListLoading(dispatch, true);
  allArticles = orderArticlesList(type, allArticles);

  dispatch({
    type: types.getArticleList,
    payload: allArticles,
  });

  changeGetListLoading(dispatch, false);
};

/**
 * Carga en memoria  articulos seleccionados en catalogo
 * @param {object} item
 * @param {boolean} checked
 */
export const setArticleCheckedAction = (item, checked) => async (dispatch) => {
  if (checked === true) {
    const exist = checkedArticles.filter((c) => c.id === item.id);

    if (exist[0] === null || exist[0] === undefined) {
      checkedArticles.push(item);
    }
  } else {
    checkedArticles = checkedArticles.filter((c) => c.id !== item.id);
  }

  dispatch({
    type: types.getArticleChecked,
    payload: checkedArticles,
  });
};

/**
 * Obtine listado de articulos seleccionados en el catalogo
 */
export const getArticleCheckedAction = () => async (dispatch) => {
  dispatch({
    type: types.getArticleChecked,
    payload: checkedArticles,
  });
};

/**
 * Limpia listado de articulos seleccionados en catalogo
 */
export const cleanArticleCheckedAction = () => async (dispatch) => {
  checkedArticles = [];
  dispatch({
    type: types.getArticleChecked,
    payload: checkedArticles,
  });
};

/**
 * Actualiza estado loading consulta listado articulos
 * @param {*} dispatch
 * @param {*} status
 */
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: types.loadingGetArticles,
    payload: status,
  });
}

/**
 * Actualiza estado loading consulta listado articulos
 * @param {*} dispatch
 * @param {*} status
 */
function changeUpdateLoading(dispatch, status) {
  dispatch({
    type: types.loadingUpdateArticle,
    payload: status,
  });
}

// { value: 1, text: "Menor precio" },
// { value: 2, text: "Mayor precio" },
// { value: 3, text: "A-Z" },
// { value: 4, text: "Z-A" },
// { value: 5, text: "Ver todo" }

/**
 * Ordena listado de articulos de acuerdo al tipo seleccionado por el usuario
 * @param {int} type Tipo de ordenamiento
 * @param {*} list Lista articulos
 */
function orderArticlesList(type, list) {
  let temp = [];
  if (type === 3) {
    temp = list.sort((x, y) => {
      let a = x.nombrearticulo.toUpperCase();
      let b = y.nombrearticulo.toUpperCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
  } else if (type === 4) {
    temp = list.sort((x, y) => {
      let a = x.nombrearticulo.toUpperCase();
      let b = y.nombrearticulo.toUpperCase();
      return a === b ? 0 : a < b ? 1 : -1;
    });
  } else if (type === 1) {
    temp = list.sort((x, y) => {
      return x.valor - y.valor;
    });
  } else if (type === 2) {
    temp = list.sort((x, y) => {
      return y.valor - x.valor;
    });
  } else {
    temp = list.sort((x, y) => {
      let a = new Date(x.modifiedon);
      let b = new Date(y.modifiedon);
      return b - a;
    });
  }

  return temp;
}
/**
 * Verifica si existen elementos seleccionados y
 * agrega propiedad check segun corresponda
 * @param {Array} listArticles
 */
function checkSelectedItems(listArticles) {
  for (let i = 0; i < listArticles.length; i++) {
    const exist = checkedArticles.find((c) => c.id === listArticles[i].id);
    if (!!exist) {
      listArticles[i] = {
        ...listArticles[i],
        checked: true,
      };
    } else {
      listArticles[i] = {
        ...listArticles[i],
        checked: false,
      };
    }
  }

  return listArticles;
}
