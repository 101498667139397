/* eslint-disable import/prefer-default-export */
export const Rules = {
  agrupacionColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[0-9a-zA-Z\-_]*$/,
      message: "Solo se permite caracteres alfanuméricos y -_",
    },
  },
  siNoOption: {
    pattern: {
      value: /^(SI|NO|Si|No|sI|nO|si|no)$/,
      message: "Opciones Validas [SI|NO|SI|NO|Si|No|sI|nO|si|no]",
    },
  },
  fechaColumn: {
    pattern: {
      value: /^(?:3[01]|[12][0-9]|0?[1-9])(\/|-)(0?[0-9]|1[0-2])\1\d{2,4}$/,
      message: "Formato fecha inválido (DD/MM/YYYY)-(DD-MM-YYYY)",
    },
  },
  required: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.;:_/)()]*$/,
      message:
        "Únicamente es permitido letras, tildes, números y algunos caracteres especiales",
    },
  },

  noRequired: {
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.;:_/]*$/,
      message:
        "Únicamente es permitido letras, tildes, números y algunos caracteres especiales",
    },
  },
  medioColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,1000}$/,
      message:
        "Únicamente es permitido letras, tildes, números y algunos caracteres especiales",
    },
  },
  vencimientoColumn: {
    pattern: {
      value: /^(?:3[01]|[12][0-9]|0?[1-9])(\/|-)(0?[0-9]|1[0-2])\1\d{2,4}$/,
      message: "Formato fecha inválido (DD/MM/YYYY)-(DD-MM-YYYY)",
    },
  },
  vendedorColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,100}$/,
      message:
        "Únicamente es permitido letras y tildes, Máximo 100 caracteres.",
    },
  },
  prefijoColumn: {
    pattern: {
      value: /^[a-zA-Z0-9]{1,4}$/,
      message: "Solo se permite caracteres alfanuméricos, Máximo 4",
    },
  },
  prefijoNoDisponible: {
    pattern: {
      value: false,
      message: "Prefijo no disponible",
    },
  },
  prefijoResolucion: {
    pattern: {
      value: false,
      message: "La resolución selecciona esta vencida",
    },
  },
  prefijoConsecutivo: {
    pattern: {
      value: false,
      message: "No tiene consecutivos para emitir",
    },
  },
  observacionesColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Longitud mínima 0 , máxima longitud 500.",
    },
  },
  tipoDocumentoColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: "Opciones Validas [CC|NIT|PA]",
    },
  },
  docClienteColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[a-zA-Z0-9]{1,15}$/,
      message: "Solo se permite caracteres alfanuméricos, Máximo 15",
    },
  },
  dosDecimales: {
    pattern: {
      // value:/^([0-9]+(?:[.][0-9]{1,2})?|[0-9]+)$/,
      // message: "Valores decimales enteros positivos, Máximo 2 decimales",
      value: /^([0-9]{1,12}(?:[.][0-9]{1,4})?|.[0-9]+)$/,
      message: "Valores decimales positivos, máximo 12 enteros y 4 decimales",
    },
  },
  descripcionColumn: {
    pattern: {
      value: /^\s*(?:\S\s*)*$/,
      message: "Únicamente es permitido letras, tildes, números",
    },
  },
  totalFilasColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[0-9]*$/,
      message: "Únicamente es permitido números",
    },
  },
  referenciaArticuloColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[a-zA-Z0-9 #\-\s@$!¡%*?#=.:_/]{1,50}$/,
      message: "Solo se permite caracteres alfanuméricos, Máximo 50",
    },
  },
  nombreArticuloColumn: {
    required: "Campo obligatorio,porque la referencia agregada no existe",
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF-\u00d1-\u00f1 #\-\s@$!¡%*?#=.:_/]{1,250}$/,
      message: "Mínima longitud del nombre del articulo 3 , Máxima 250",
    },
  },
  letters: {
    pattern: {
      value: /^[a-zA-Z]*$/,
      message: "Solo se permite caracteres alfanuméricos, Máximo 100",
    },
  },
  tipoDescuentoColumn: {
    pattern: {
      value: /^(%|\$)$/,
      message: "Opciones Validas [%|$]",
    },
  },
  cantidadColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^([0-9]+(?:[.][0-9]{1,2})?|.[0-9]+)$/,
      message: "Valores decimales positivos, máximo 2 decimales",
    },
  },
  valorUnitColumn: {
    required: "Campo obligatorio",
    pattern: {
      //value: /^\s*-?\d{1,20}(\.\d{1,2})?\s*$/,
      value: /^([0-9]{1,12}(?:[.][0-9]{1,4})?|.[0-9]+)$/,
      message: "Valores decimales positivos, máximo 12 enteros y 4 decimales",
    },
  },
  unidadMedidaColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF-\u00d1-\u00f1 #\-\s@$!¡%*?#=.:_/]{1,250}$/,
      message: "Mínima longitud del nombre del articulo 3 , Máxima 250",
    },
  },
  unidadMedidaColumn1: {
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF-\u00d1-\u00f1 #\-\s@$!¡%*?#=.:_/]{1,250}$/,
      message: "Mínima longitud del nombre del articulo 3 , Máxima 250",
    },
  },

  medioPagoColumn: {
    required: "Campo obligatorio",
    pattern: {
      value: false,
      message: "Campo Obligatorio",
    },
  },
  valorDescuento: {
    pattern: {
      value: /^\d+$/,
      message: "El valor de descuento no puede ser negativo",
    },
  },
  fechaEmision: {
    pattern: {
      value: false,
      message:
        "La fecha de emisión de la factura debe ser igual a la fecha actual.",
    },
  },
  descuentoSuperaMonto: {
    pattern: {
      value: false,
      message:
        "El valor del descuento no puede ser mayor o igual al valor unitario por la cantidad del artículo.",
    },
  },
  descuentoNoNegativo: {
    pattern: {
      value: false,
      message: "El valor del descuento no puede ser menor a 0.",
    },
  },
  descuentoValorMayor: {
    pattern: {
      value: false,
      message: "El valor del porcentaje no puede ser mayor a 100.",
    },
  },
  motivoFormaPagoContado: {
    pattern: {
      value: false,
      message: "Campo obligatorio por motivo que la forma de Pago es Contado.",
    },
  },
  motivoFormaPagoCredito: {
    pattern: {
      value: false,
      message: "Campo obligatorio por motivo que la forma de Pago es Crédito",
    },
  },
  fechaVencimiento: {
    pattern: {
      value: false,
      message:
        "La fecha de vencimiento no puede ser menor a la fecha de generación de la factura",
    },
  },
  totalFactura: {
    pattern: {
      value: false,
      message:
        "El total de la factura no puede ser negativo o 0, por favor revisar los descuentos comerciales, el valor de estos no puede superar el total de la factura.",
    },
  },
  tituloTotalFacura: {
    pattern: {
      value: false,
      message: "Observación general",
    },
  },
  diaMayor: {
    pattern: {
      value: false,
      message: "La fecha del día no puede ser mayor a 31",
    },
  },
  diaMenor: {
    pattern: {
      value: false,
      message: "La fecha del día no puede ser menor a 1",
    },
  },
  mesMayor: {
    pattern: {
      value: false,
      message: "La fecha del mes no puede ser mayor a 12",
    },
  },
  mesMenor: {
    pattern: {
      value: false,
      message: "La fecha del mes no puede ser menor a 1",
    },
  },
  decimalesCargo: {
    pattern: {
      //value:/^[0-9.]+$/,
      value: /^([0-9]{1,12}(?:[.][0-9]{1,4})?|.[0-9]+)$/,
      message: "Valores positivos, máximo 12 enteros y 4 decimales ",
    },
  },
  totalBaseFactura: {
    pattern: {
      value: false,
      message:
        "El valor de los cargos no puede ser superior a la factura con descuentos.",
    },
  },
};

export const ColumnsFile = {
  agrupacionColumn: {
    name: "agrupacion*",
  },
  redondeoColumn: {
    name: "RedondeaDecimales",
  },
  fechaColumn: {
    name: "fecha*",
  },
  formaPagoColumn: {
    name: "forma pago*",
    msgBackValidations: "No existe esta forma de pago",
  },
  vencimientoColumn: {
    name: "Vencimiento",
  },
  medioPagoColumn: {
    name: "Medio pago",
    msgBackValidations: "No existe este medio de pago",
  },
  vendedorColumn: {
    name: "vendedor",
    msgBackValidations: "No existe este vendedor",
  },
  prefijoColumn: {
    name: "prefijo *",
    msgBackValidations: "No existe este prefijo",
  },
  observacionesColumn: {
    name: "Observaciones",
  },
  tipoDocColumn: {
    name: "tipodocu*",
    msgBackValidations: "No existe este cliente",
  },
  docClienteColumn: {
    name: "doccliente *",
    msgBackValidations: "No existe este cliente",
  },
  clienteColumn: {
    name: "cliente",
    msgBackValidations: "No existe este cliente",
  },
  reteicaColumn: {
    name: "% Reteica",
    msgBackValidations: "No existe este Rete Ica",
  },
  reteivaColumn: {
    name: "% Reteiva",
    msgBackValidations: "No existe este Rete Iva",
  },
  descripcionCargoColumn: {
    name: "Descripcion Cargo",
    msgBackValidations: "No existe esta descripción de cargo",
  },
  valorCargoColumn: {
    name: "Valor Cargo",
  },
  descripcionDescuentoFinancieroColumn: {
    name: "Descripcion Descuento Financiero",
    msgBackValidations: "No existe esta descripción de descuento financiero",
  },
  valorDescuentoFinancieroColumn: {
    name: "Valor Descuento financiero",
  },
  totalFilasColumn: {
    name: "Total filas*",
  },
  refArticuloColumn: {
    name: "Referencia Articulo *",
    msgBackValidations: "No existe este articulo",
  },
  nombreArticuloColumn: {
    name: "Nombre articulo",
  },
  cantidadColumn: {
    name: "cantidad*",
  },
  unidadMedidaColumn: {
    name: "UnidadMedida",
    msgBackValidations: "No existe esta unidad de medida",
  },
  valorUnitarioColumn: {
    name: "valorunit*",
  },
  tipoDescuentoColumn: {
    name: "TipoDecuento",
  },
  valorDescuentoColumn: {
    name: "Valor Descuento",
  },
  ivaColumn: {
    name: "% IVA",
  },
  incColumn: {
    name: "% INC",
  },
  reteFuenteColumn: {
    name: "% RetFuente",
    msgBackValidations: "No existe esta Rete fuente",
  },
};
