import React from "react";
import PropTypes from "prop-types";
import { Grid, IconButton } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflowY: "initial",
		backgroundColor: "rgba(0, 0, 0, 0.7)",
	},
	paper: {
		maxHeight: 800,
		backgroundColor: "#393939",
		border: "none",
		borderRadius: "5px",
		overflowY: "auto",
		overflowX: "hidden",
		color: "rgba(216,216,216,0.5)",
		outline: 0,
	},
	iconClose: {
		color: theme.palette.thirdColor,
		padding: 0,
	},
	buttonClose: {
		textAlign: "end",
		padding: 10,
	},
	title: {
		textAlign: "start",
		color: "#A2E3A4",
		padding: 10,
		fontSize: "16px",
		fontWeight: "600",
	},
}));

export const CustomModal = ({
	component,
	open,
	onClose,
	title,
	width,
	showCloseButton,
	disabled,
	noPadding,
	height,
	disableBackdropClick,
}) => {
	const classes = useStyles();

	return (
		<Modal
			className={`${classes.modal} animate__animated animate__fadeIn animate__faster`}
			open={open}
			onClose={onClose}
			disableBackdropClick={disableBackdropClick ?? false}
		>
			<div
				className={classes.paper}
				style={{
					width: !!width ? width : 600,
					height: !!height ? height : "",
					padding:
						noPadding === true ? "0px 0px 10px 0px" : "5px 20px 10px 20px",
				}}
			>
				<Grid container>
					<Grid item lg={10} xs={10} className={classes.title}>
						{title}
					</Grid>
					<Grid item lg={2} xs={2} className={classes.buttonClose}>
						{showCloseButton === false ? (
							""
						) : (
							<IconButton
								className={classes.iconClose}
								onClick={onClose}
								disabled={disabled}
							>
								<CloseIcon style={{ fontSize: 18 }} />
							</IconButton>
						)}
					</Grid>
					<Grid item lg={12} xs={12}>
						<Grid container>{component}</Grid>
					</Grid>
				</Grid>
			</div>
		</Modal>
	);
};

CustomModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	width: PropTypes.number,
	showCloseButton: PropTypes.bool,
	disabled: PropTypes.bool,
	noPadding: PropTypes.bool,
};
