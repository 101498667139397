/**
 * * Calcula los elementos de impuestos basados en los parámetros dados.
 *
 * @param {number} porcentajeiva - Porcentaje de IVA.
 * @param {number} porcentajeinc - Porcentaje de INC.
 * @param {number} tipobebida - Tipo de bebida.
 * @param {number} gradosalcohol - Grados de alcohol.
 * @param {number} cantidad - Cantidad.
 * @param {number} pesogramos - Peso en gramos.
 * @param {number} tasauvtimp - Tasa UV/TIMP.
 * @param {number} cantidadmililitros - Cantidad en mililitros.
 * @param {number} valoribua - Valor IBUA.
 * @param {number} porcentajeicui - Porcentaje de ICUI.
 * @param {number} porcentajeadv - Porcentaje de ADV.
 *
 * @returns {Array} Un array de objetos que representa los elementos de impuestos calculados.
 */

export const getTaxItems = (
  porcentajeiva,
  porcentajeinc,
  tipobebida,
  gradosalcohol,
  cantidad,
  pesogramos,
  tasauvtimp,
  cantidadmililitros,
  valoribua,
  porcentajeicui,
  porcentajeadv
) => {
  const taxItems = [
    {
      label: "IVA",
      value: porcentajeiva,
      suffix: "%",
      aplica: porcentajeiva !== null && porcentajeiva >= 0,
    },
    {
      label: "INC",
      value: porcentajeinc,
      suffix: "%",
      aplica: porcentajeinc > 0,
    },
    {
      label: "ICL",
      value: (tipobebida * gradosalcohol * cantidad).toFixed(2),
      prefix: "$",
      aplica: tipobebida > 0,
    },
    {
      label: "INPP",
      value: (pesogramos * tasauvtimp * cantidad).toFixed(2),
      prefix: "$",
      aplica: pesogramos > 0,
    },
    {
      label: "IBUA",
      value: ((cantidadmililitros * valoribua) / 100) * cantidad,
      prefix: "$",
      aplica: cantidadmililitros > 0,
    },
    {
      label: "ICUI",
      value: porcentajeicui,
      suffix: "%",
      aplica: porcentajeicui > 0,
    },
    {
      label: "ADV",
      value: porcentajeadv,
      suffix: "%",
      aplica: porcentajeadv > 0,
    },
  ];
  return taxItems;
};
