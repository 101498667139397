import React from 'react';
import { makeStyles } from '@material-ui/styles';
import './step.css';
import imgstep2 from '../../images/wizard/wizard_2.png'
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        height: 'auto'
    },
    message: {
        width: '24vw',
        textAlign: 'center',
        position: 'absolute',
        bottom: '5%',
        right: '70%',
        backgroundColor: 'rgba(65, 65, 65, 0.95)',
        color: theme.palette.primarycolor,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '2vw',
        fontSize: '1.1vw',
        padding: '1vw',
        fontStyle: 'italic',
    },
    containerImage: {
        width: '45%',
        margin: '0 auto',
        position: 'relative'
    }

}));

const Step2 = () => {
    const classes = useStyles();
    return (
        <div className={classes.containerImage}>
            <img alt='' src={imgstep2} className={classes.image}></img>
            <Typography className={classes.message}>
                Tendrás todo tu catálogo de artículos <span className="linkColfactura">disponible y a la mano</span>,
              podrás agregarlos a tu facturas, modificarlos según tus
              necesidades, categorizarlos y muchas cosas más…
          </Typography>
        </div>
    );
}

export default Step2;