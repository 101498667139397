import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";

//Steppers
import InfoUpload from "../stepper/s1.info-upload.view";
import UploadedRecords from "../stepper/s2.uploaded-records.view";
import IssuedInvoices from "../stepper/s3.issued-invoices.view";

import { updateStateAction } from "../../../../actions/invoiceActions";
import {
  getTemplateBulkUploadAction,
  getMenuComponentAction,
} from "../../../../actions/configAction";
import { ConfirmAlert } from "../../../../helpers/alert.helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {" "}
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}{" "}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function getSteps() {
  return ["Carga de información", "Registros Cargadas", "Facturas Emitidas"];
}

const BulkUpload = (props) => {
  const classes = useStyles();

  const steps = getSteps();
  const {
    cancelOption,
    getUploadedInvoice,
    updateState,
    handleCreate,
    stepChange,
    HandleBulkUpload,
    informationBulkLoad,
  } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [stepsCompleted, setStepCompleted] = React.useState([]);

  /**
   * Agrega paso completo a listado
   * @param {int} index Identificador paso
   */
  const handleCompleteStep = (index) => {
    let exist = stepsCompleted.find((s) => s === index);
    let newArray = [];
    newArray = stepsCompleted;
    if (exist === null || exist === undefined) {
      newArray.push(index);
      setStepCompleted(newArray);
      setCompleted(new Set(newArray));
    }
  };

  function isStepCompleted(step) {
    return completed.has(step);
  }

  useEffect(() => {
    if (!!stepChange) {
      handleCompleteStep(stepChange);
      setActiveStep(stepChange);
    }
    if (stepChange === 0 && informationBulkLoad === null) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepChange, informationBulkLoad]);

  const handleReset = () => {
    setStepCompleted([0]);
    setCompleted(new Set([0]));
    setActiveStep(0);
  };

  const userId = () => {
    return JSON.parse(localStorage.getItem("dataCompany")).userId;
  };

  const enterpriseId = () => {
    return JSON.parse(localStorage.getItem("dataCompany")).companyId;
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <InfoUpload
            setActiveStep={setActiveStep}
            cancelOption={cancelOption}
            enterpriseId={enterpriseId()}
            userId={userId()}
          />
        );
      case 1:
        return (
          <UploadedRecords
            confirmAlert={ConfirmAlert}
            updateState={updateState}
            backOption={cancelOption}
            setActiveStep={setActiveStep}
            enterpriseId={enterpriseId()}
            userId={userId()}
            getUploadedInvoice={getUploadedInvoice}
            activeStep={activeStep}
            handleCreate={handleCreate}
            handleBulkUpload={HandleBulkUpload}
          />
        );
      case 2:
        return (
          <IssuedInvoices
            setActiveStep={setActiveStep}
            backOption={updateState}
            loadingListData={false}
            cancelOption={cancelOption}
            enterpriseId={enterpriseId()}
            userId={userId()}
            getUploadedInvoice={getUploadedInvoice}
            activeStep={activeStep}
            handleCreate={handleCreate}
          />
        );
      default:
        return "";
    }
  }

  return (
    <div className="container-form" xs={12} lg={12}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.root}
        index={2}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label} index={2}>
              <StepButton
                index={1}
                completed={isStepCompleted(index)}
                className={activeStep === index ? classes.activeButton : ""}
              >
                <StepLabel StepIconComponent={QontoStepIcon} index={2}>
                  <label className="stepper-title">{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>{getStepContent(stepChange)}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.invoiceReducer.openModalItem,
  configReducer: state.configReducer,
  validateInformation: state.invoiceReducer.validateInformation,
  stepChange: state.invoiceReducer.stepChange,
  informationBulkLoad: state.invoiceReducer.informationBulkLoad,
});

const mapDispatchToProps = {
  getTemplateBulkUpload: getTemplateBulkUploadAction,
  getMenuComponent: getMenuComponentAction,
  updateState: updateStateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);
