import { types } from '../types/types'
import isEmpty from '../utils/isEmpty'

const initialState = {
    loadingSave: false,
    loadingDetail: false,
    selectedId: 0,
    loadingGetClient: false,
    clientIdSelected: null,
    loadingGetExistingClient: false,
    selectedIdClientDocument: 0,
    clientsResumeList:[],
    loadingListClients: true,
    loadClientDocument: null,
    editClientStatus: false
    
}


export default function (state = initialState, action) {

    switch (action.type) {
        case types.SAVE_CLIENT:
            return {
                ...state,
                saveClientResponse: action.payload
            }
        case types.GET_CLIENTS_LIST:
            return {
                ...state,
                getClientListResponse: action.payload
            }
        case types.LOAD_CLIENT:
            return {
                ...state,
                loadClientObject: action.payload,
                editClientStatus: !isEmpty(action.payload) ? true : false
            }
        case types.UPDATE_CLIENT:
            return {
                ...state,
                updateClientResponse: action.payload,
            }

        case types.LOAD_CLIENT_DETAIL:
            return {
                ...state,
                loadClientDetail: action.payload,
            }
        case types.LOAD_CLIENT_DOCUMENT:
            return {
                ...state,
                loadClientDocument: action.payload,
            }
        case types.LOADING_SAVE_CLIENT:
            return {
                ...state,
                loadingSave: action.payload,
            }
        case types.LOADING_LIST_CLIENT:
            return {
                ...state,
                loadingListClients: action.payload,
            }
        case types.DELETE_CLIENT:
            return {
                ...state,
                deleteClientResponse: action.payload,
            }

        case types.GET_FILTERLIST_CLIENT:
            return {
                ...state,
                getFilterListClient: action.payload,
            }

        case types.LOADING_FILTERLIST_CLIENT:
            return {
                ...state,
                loadingFilterList: action.payload,
            }
        case types.LOADING_DETAIL_CLIENT:
            return {
                ...state,
                loadingDetail: action.payload
            }
        case types.LOADING_GET_CLIENT:
            return {
                ...state,
                loadingGetClient: action.payload
            }
        case types.GET_CLIENTS_RESUME_LIST:
            return {
                ...state,
                clientsResumeList: action.payload
            }
        case types.LOADING_STANDOUT_CLIENT:
            return {
                ...state,
                loadingStandOutClient: action.payload
            }
        case types.LOADING_DELETE_CLIENT:
            return {
                ...state,
                loadingDeleteClient: action.payload
            }

        case types.SELECTED_ID_CLIENT:
            return {
                ...state,
                clientIdSelected: action.payload
            }
        case types.LOADING_GET_EXISTING_CLIENT:
            return {
                ...state,
                loadingGetExistingClient: action.payload
            }
        case types.SELECTED_ID_CLIENT_DOCUMENT:
            return {
                ...state,
                selectedIdClientDocument: action.payload
            }
        default:
            return state
    }
}