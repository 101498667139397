import React, { useState, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import InputForm from "../../components/input/input.component";
import ButtonSecundary from "../../components/button/buttonSecundary.component";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { useFormComplete } from "../../hooks/useFormComplete";
import { regexEmail } from "../../helpers/customRegex.hepers";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%s",
	},
	title: {
		padding: "6px 10px 20px 10px",
	},
}));

export const EmailConfirmForm = ({
	emailUser,
	handleConfirmEmail,
	loading,
}) => {
	const classes = useStyles();
	const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
		.current;
	const [, forceUpdate] = useState();
	const { values, handleInputChange } = useFormComplete({
		email: emailUser ?? "",
	});

	const { email } = values;

	/**
	 * VAlida formulario y confirma petición
	 * @param {*} e
	 * @returns
	 */
	const handleSubmit = (e) => {
		e.preventDefault();

		if (!validator.allValid()) {
			validator.showMessages();
			forceUpdate(Math.random());
			return;
		}
		handleConfirmEmail([email.replace(regexEmail, "")]);
	};

	return (
		<form onSubmit={handleSubmit} className={classes.root}>
			<Grid container>
				<Grid item lg={12} xs={12} className={classes.title}>
					Enviaremos la Factura al siguiente correo electrónico, puedes
					modificarlo si lo requieres
				</Grid>
				<Grid item lg={12} xs={12}>
					<InputForm
						name="email"
						label={"Correo electrónico *"}
						value={email}
						maxLength={200}
						onChange={(e) => handleInputChange(e, "email")}
						disabled={loading}
						validator={validator}
						validateOptions={"required|correo|min:10|max:200"}
					/>
				</Grid>
				<Grid item lg={12} xs={12}>
					<ButtonSecundary text={"Enviar"} loading={loading} type={"submit"} />
				</Grid>
			</Grid>
		</form>
	);
};
