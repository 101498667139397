import { types } from "../types/types"

/**
 * Carga listado direcciones en redux
 * @param {*} addresses 
 * @returns 
 */
export const loadListAddressesAction = (addresses) => dispatch => {

    dispatch({
        type: types.GET_ADDRESS_LIST,
        payload: addresses
    })
}


/**
 * Agrega nueva dirección adicional
 * @param {*} address 
 * @returns 
 */
export const addAddressAction = (address) => dispatch => {
    dispatch({
        type: types.ADD_CUSTOMER_ADDRESS,
        payload: address
    })
}

/**
 * Actualiza direccion adicional
 * @param {*} address 
 * @returns 
 */
export const updateAddressAction = (address) => dispatch => {
    dispatch({
        type: types.UPDATE_CUSTOMER_ADDRESS,
        payload: address
    })
}

/**
 * Borra direccion adicional
 * @param {*} address 
 * @returns 
 */
export const deleteAddressAction = (id) => dispatch => {
    dispatch({
        type: types.DELETE_CUSTOMER_ADDRESS,
        payload: id
    })
}

/**
 * Carga información de dirección activa en redux
 * @param {*} address 
 * @returns 
 */
export const setActiveAddress = (address) => dispatch => {
    dispatch({
        type: types.SET_ACTIVE_CUSTOMER_ADDRESS,
        payload: address
    })

}


export const cleanDataAddressAction = ()=>dispatch=>{
    dispatch({
        type: types.CLEAN_CUSTOMER_ADDRESS
    })
}

