import { DIGITS_AFTER_DECIMAL } from "../config/config";
import { roundDecimalValue } from "./managementNumber";

//Calcula valor impuestos y totales por Item
export const calculateValuesItem = (item) => {
  try {
    let cValorBruto = item.valorNeto * item.cantidad;
    let cValorDescuento = item.valordescuento;
    let cPorcentajeDescuento = item.porcentajedescuento;
    let cValorDescuentoFinal =
      item.tipodescuento === 2
        ? roundDecimalValue(cValorBruto * (cPorcentajeDescuento / 100))
        : cValorDescuento; //Fernando Ruiz: Se debe redondear valor
    let cValorSubtotal = roundDecimalValue(cValorBruto - cValorDescuentoFinal);
    let cValorIva = roundDecimalValue(
      cValorSubtotal * (item.porcentajeiva / 100)
    );
    let cValorInc = roundDecimalValue(
      cValorSubtotal * (item.porcentajeinc / 100)
    );
    let cTotal = roundDecimalValue(cValorSubtotal + cValorIva + cValorInc);

    item.totaldescuento = cValorDescuentoFinal;
    item.valorbruto = cValorBruto;
    item.subtotal = cValorSubtotal;
    item.totaliva = cValorIva;
    item.totalinc = cValorInc;
    item.total = cTotal;
  } catch (err) {
    console.error("Ha ocurrido un error calculando valores de item", err);
    throw err;
  }
};

//Calcula valor impuestos y totales para Factura
export const calculateTotal = (
  detailDocument,
  charges = [],
  discounts = [],
  round
) => {
  try {
    //Precison de redondeo
    const precision = round === true ? 0 : DIGITS_AFTER_DECIMAL;
    const precisionTotal =
      round === true ? 0 : DIGITS_AFTER_DECIMAL > 2 ? 2 : DIGITS_AFTER_DECIMAL;
    //Agrega campo nuevo a descuentos
    discounts = discounts.map((item) => {
      return {
        ...item,
        valoradescontar: 0,
      };
    });

    let cValorBruto = 0;
    let cTotalBaseImponible = 0;
    let cValorCargos = 0;
    let cValorCargosGlobales = 0;
    let cValorDescuentosFinancieros = 0;

    let cValorIva = 0;
    let cValorInc = 0;
    let cValorDescuento = 0;
    let cValorTotal = 0;

    let cValorIVAReal = 0;
    let cValorINCReal = 0;
    let cValorDescuentoReal = 0;
    let cValorTotalReal = 0;

    let cTotalReteFte = 0;
    let cTotalReteIca = 0;
    let aplicaReteIca = false;
    let cTotalReteIva = 0;

    let cTotalAPagar = 0;
    let cTotalCantidadesItem = 0;
    let cTotalAPagarDian = 0;

    //Calcula descuentos adicionales, modelo descuento 1--> Comercial, 2--> Financiero
    let financialDiscounts = [];
    let commercialDiscounts = [];
    discounts = !!discounts && discounts.length > 0 ? discounts : [];

    commercialDiscounts = discounts.filter((d) => d.modelodescuento === 1);
    financialDiscounts = discounts.filter((d) => d.modelodescuento === 2);

    //Calculo para descuentos comerciales
    calculeValueCommercialDiscounts(
      detailDocument,
      commercialDiscounts,
      precision
    );
    //Verifica si debe aplicar reteIca y reteIva a todo el listado
    let retica = detailDocument.find((c) => c.idretica > 0)?.retica;
    let retiva = detailDocument.find((c) => c.idretiva > 0)?.retiva;

    //Suma totales por Items en carrito, Calcula retenciones

    for (var i = 0; i < detailDocument.length; i++) {
      const item = detailDocument[i];

      //Rete-Fuente
      if (item.idretfte > 0) {
        let retfte = item.retfte;
        let tarifaReteFte = !!retfte?.tarifa ? retfte.tarifa : 0;
        item.baseretfte = item.subtotalreal;
        item.tarifaretfte = tarifaReteFte;
        item.valorretfte = roundDecimalValue(
          item.subtotalreal * (tarifaReteFte / 100)
        );
      } else {
        item.baseretfte = 0;
        item.tarifaretfte = 0;
        item.valorretfte = 0;
        item.retfte = null;
      }

      //Rete-ICA
      if (retica !== null && retica !== undefined) {
        let tarifaReteIca = !!retica?.tarifa ? retica.tarifa : 0;
        item.idretica = retica.id;
        item.baseretica = item.subtotalreal;
        item.tarifaretica = tarifaReteIca / 10;
        item.valorretica = roundDecimalValue(
          item.subtotalreal * (tarifaReteIca / 1000)
        );
        item.retica = retica;
        aplicaReteIca = true;
      } else {
        item.idretica = 0;
        item.baseretica = 0;
        item.tarifaretica = 0;
        item.valorretica = 0;
        item.retica = null;
      }

      //Rete-IVA
      if (retiva !== null && retiva !== undefined) {
        let tarifaReteIva = !!retiva?.tarifa ? retiva.tarifa : 0;
        item.idretiva = retiva.id;
        item.baseretiva = item.totalivareal;
        item.tarifaretiva = tarifaReteIva;
        item.valorretiva = roundDecimalValue(
          item.totalivareal * (tarifaReteIva / 100)
        );
        item.retiva = retiva;
      } else {
        item.idretiva = 0;
        item.baseretiva = 0;
        item.tarifaretiva = 0;
        item.valorretiva = 0;
        item.retiva = null;
      }

      cValorBruto = roundDecimalValue(cValorBruto + item.valorbruto); //Total sumatoria valor bruto items (VlrUnitario * Cantidad)
      //VALORES FACTURA SIN DESCUENTOS COMERCIALES
      const hasTax = (item) =>
        (item.porcentajeiva !== null && item.porcentajeiva >= 0) ||
        item.porcentajeinc > 0 ||
        item.porcentajeicui > 0 ||
        item.porcentajeadv > 0;

      if (hasTax(item)) {
        cTotalBaseImponible = roundDecimalValue(
          cTotalBaseImponible + item.subtotalreal,
          DIGITS_AFTER_DECIMAL
        );
      }
      cValorDescuento = roundDecimalValue(
        cValorDescuento + item.totaldescuento
      ); //Total sumatoria descuentos items
      cValorIva = roundDecimalValue(item.totaliva); //Total sumatoria valores IVA items
      cValorInc = roundDecimalValue(item.totalinc); //Total sumatoria valores INC items
      cValorTotal = roundDecimalValue(item.total); // Total sumatoria totales item

      //VALORES REALES FACTURA, INCLUYE DESCUENTOS COMERCIALES

      cValorDescuentoReal = roundDecimalValue(
        cValorDescuentoReal + item.totaldescuentoreal
      );
      cValorIVAReal = roundDecimalValue(cValorIVAReal + item.totalivareal);
      cValorINCReal = roundDecimalValue(cValorINCReal + item.totalincreal);
      cValorTotalReal = roundDecimalValue(cValorTotalReal + item.totalreal);

      //RETENCIONES
      cTotalReteFte = roundDecimalValue(cTotalReteFte + item.valorretfte);
      cTotalReteIca = roundDecimalValue(cTotalReteIca + item.valorretica);
      cTotalReteIva = roundDecimalValue(cTotalReteIva + item.valorretiva);

      cTotalCantidadesItem = roundDecimalValue(
        cTotalCantidadesItem + item.cantidad
      ); // Sumatoria cantidades productos
    }

    //Suma cargos adicionales de documento
    if (!!charges && charges.length > 0) {
      for (let i = 0; i < charges.length; i++) {
        const item = charges[i];

        let valueCharge = 0;
        if (item.tipocargo === 1) {
          valueCharge =
            item.esglobal === true
              ? parseFloat(item.valorcalculado)
              : parseFloat(item.valor);
          cValorCargos = roundDecimalValue(cValorCargos + valueCharge);
          cValorCargosGlobales =
            item.esglobal === true
              ? roundDecimalValue(cValorCargosGlobales + valueCharge)
              : cValorCargosGlobales;
        } else if (item.tipocargo === 2) {
          valueCharge =
            item.esglobal === true
              ? parseFloat(item.valorcalculado)
              : cValorBruto * (parseFloat(item.valor) / 100);
          cValorCargos = roundDecimalValue(cValorCargos + valueCharge);
          cValorCargosGlobales =
            item.esglobal === true
              ? roundDecimalValue(cValorCargosGlobales + valueCharge)
              : cValorCargosGlobales;
        }

        //Agrega valor calculado a sumar por cargo, al valorBruto de factura.
        charges[i] = {
          ...charges[i],
          valorasumar: roundDecimalValue(valueCharge),
        };
      }
    }

    //Calculo para descuentos Financieros sobre total Real
    for (let i = 0; i < financialDiscounts.length; i++) {
      const item = financialDiscounts[i];
      let valuediscount = 0;
      if (item.tipodescuento === 1) {
        valuediscount = parseFloat(item.valor);
        cValorDescuentosFinancieros = roundDecimalValue(
          cValorDescuentosFinancieros + valuediscount
        );
      } else if (item.tipodescuento === 2) {
        valuediscount = roundDecimalValue(
          cValorTotalReal * (parseFloat(item.valor) / 100)
        );
        cValorDescuentosFinancieros = roundDecimalValue(
          cValorDescuentosFinancieros + valuediscount
        );
      }

      //Agrega valor calculado a descontar de total Factura
      financialDiscounts[i] = {
        ...financialDiscounts[i],
        valoradescontar: roundDecimalValue(valuediscount),
      };
    }

    discounts = financialDiscounts.concat(commercialDiscounts);

    //Total Factura
    let total = roundDecimalValue(
      cValorTotalReal +
        cValorCargos -
        cValorDescuentosFinancieros -
        cTotalReteFte -
        cTotalReteIca -
        cTotalReteIva
    );
    let totalDian = roundDecimalValue(
      cValorTotalReal + cValorCargos - cValorDescuentosFinancieros
    );

    cTotalAPagar = roundDecimalValue(total, precisionTotal);
    cTotalAPagarDian = roundDecimalValue(totalDian, precisionTotal);
    let cValorBase = roundDecimalValue(
      cValorBruto - cValorDescuentosFinancieros - cValorDescuentoReal
    );

    let totals = {
      detalleDocumento: detailDocument,
      cargos: charges,
      descuentosFinancieros: financialDiscounts,
      descuentosComerciales: commercialDiscounts,
      otrosdescuentos: discounts, //Cambio nombre otrosdescuentos por descuentos
      valorbase: cValorBase,
      valorbruto: cValorBruto,
      valorbaseimponible: cTotalBaseImponible,
      valorcargos: cValorCargos,
      valorcargosaplicados: roundDecimalValue(
        cValorCargos - cValorCargosGlobales
      ),

      valordescuento: cValorDescuento,
      valoriva: cValorIva,
      valorinc: cValorInc,
      totalfactura: cValorTotal,

      valordescuentoreal: cValorDescuentoReal,

      valorivareal: cValorIVAReal,
      valorincreal: cValorINCReal,

      valorretfte: cTotalReteFte,
      valorretica: cTotalReteIca,
      valorretiva: cTotalReteIva, //Debe ser valorretiva
      aplicareteica: aplicaReteIca,

      totalfacturaReal: cValorTotalReal,

      cantidad: detailDocument.length,
      totalCantidades: cTotalCantidadesItem,

      valorOtrosDescuentos: cValorDescuentosFinancieros, //Descuentos financieros
      totalapagar: cTotalAPagar,
      valorapagardian: cTotalAPagarDian,
      redondeo: round,
      decimales: precision,
    };
    return totals;
  } catch (err) {
    console.error(
      "Ha ocurrido un error calculando Totales, Por favor contacte al administrador",
      err
    );
    throw err;
  }
};

/**
 * Calcula valores para modelo de descuento comercial
 * @param {Array} detailDocument  Listado items de Factura
 * @param {*} commercialDiscounts Listado de descuentos comerciales
 */
const calculeValueCommercialDiscounts = (
  detailDocument,
  commercialDiscounts,
  precision
) => {
  //Calcular total bruto (vlrUnitario * cantidad)
  let cValorTotalBruto = 0;
  for (let i = 0; i < detailDocument.length; i++) {
    const item = detailDocument[i];
    cValorTotalBruto += item.valorbruto;
  }

  //Calcular Prorrateo
  let acumuladoProrrateo = 0;
  let prorrateo = 0;
  for (let i = 0; i < detailDocument.length; i++) {
    const item = detailDocument[i];
    if (i + 1 === detailDocument.length) {
      prorrateo = roundDecimalValue(100 - acumuladoProrrateo);
    } else {
      let temp = (item.valorbruto * 100) / cValorTotalBruto; // cero dividido en cero = valor indefinido
      prorrateo = roundDecimalValue(isNaN(temp) ? 0 : temp);
      acumuladoProrrateo = roundDecimalValue(acumuladoProrrateo + prorrateo);
    }

    detailDocument[i] = {
      ...detailDocument[i],
      prorrateo: prorrateo,
    };
  }

  //Calcula valores de descuento comercial por item
  if (commercialDiscounts.length <= 0) {
    for (let i = 0; i < detailDocument.length; i++) {
      const item = detailDocument[i];

      let cValorBruto = item.valorNeto * item.cantidad;
      let cValorDescuento = item.valordescuento;
      let cPorcentajeDescuento = item.porcentajedescuento;
      let cValorDescuentoFinal =
        item.tipodescuento === 2
          ? roundDecimalValue(cValorBruto * (cPorcentajeDescuento / 100))
          : cValorDescuento; //Fernando Ruiz: Redondear valor
      let cValorSubtotal = roundDecimalValue(
        cValorBruto - cValorDescuentoFinal
      );
      let cValorIva = roundDecimalValue(
        cValorSubtotal * (item.porcentajeiva / 100)
      );
      let cValorInc = roundDecimalValue(
        cValorSubtotal * (item.porcentajeinc / 100)
      );
      let cTotal = roundDecimalValue(cValorSubtotal + cValorIva + cValorInc);

      item.totaldescuento = cValorDescuentoFinal;
      item.valorbruto = cValorBruto;
      item.subtotal = cValorSubtotal;
      item.totaliva = cValorIva;
      item.totalinc = cValorInc;
      item.total = cTotal;

      detailDocument[i] = {
        ...detailDocument[i],
        valordescuentocomercial: 0,
        totaldescuentoreal: item.totaldescuento,
        subtotalreal: item.subtotal,
        totalivareal: item.totaliva,
        totalincreal: item.totalinc,
        totalreal: item.total,
      };
    }
  }

  //Limpiar valores anteriores antes de calculo
  if (commercialDiscounts.length > 0) {
    for (let i = 0; i < detailDocument.length; i++) {
      detailDocument[i] = {
        ...detailDocument[i],
        valordescuentocomercial: 0,
        totaldescuentoreal: 0,
        subtotalreal: 0,
        totalivareal: 0,
        totalincreal: 0,
        totalreal: 0,
      };
    }
  }

  for (let i = 0; i < commercialDiscounts.length; i++) {
    const discount = commercialDiscounts[i];
    let cDescuentoComercial = 0;
    let valorDescuento = discount.tipodescuento === 1 ? discount.valor : 0;
    let acumuladoDescuento = 0;

    for (let d = 0; d < detailDocument.length; d++) {
      const item = detailDocument[d];
      let cValorDescuento = 0;

      if (discount.tipodescuento === 1) {
        // Tipo valor

        //Ajusta descuento en ultimo item
        if (d + 1 === detailDocument.length) {
          cValorDescuento = roundDecimalValue(
            valorDescuento - acumuladoDescuento
          );
          acumuladoDescuento = roundDecimalValue(
            acumuladoDescuento + cValorDescuento
          );
        } else {
          cValorDescuento = roundDecimalValue(
            discount.valor * (item.prorrateo / 100)
          );
          acumuladoDescuento = roundDecimalValue(
            acumuladoDescuento + cValorDescuento
          );
        }
      } else {
        // Tipo porcentaje
        cValorDescuento = roundDecimalValue(
          item.valorbruto * (discount.valor / 100)
        );
      }

      cDescuentoComercial = roundDecimalValue(
        cDescuentoComercial + cValorDescuento
      );

      let cTotalDescuentoReal = roundDecimalValue(
        item.totaldescuento + item.valordescuentocomercial + cValorDescuento
      );
      let cSubtotalReal = roundDecimalValue(
        item.valorbruto - cTotalDescuentoReal
      );
      let cValorIVAReal = roundDecimalValue(
        cSubtotalReal * (item.porcentajeiva / 100)
      );
      let cValorINCReal = roundDecimalValue(
        cSubtotalReal * (item.porcentajeinc / 100)
      );
      let cTotalReal = roundDecimalValue(
        cSubtotalReal + cValorIVAReal + cValorINCReal
      );

      let nuevoComercial = roundDecimalValue(
        detailDocument[d].valordescuentocomercial + cValorDescuento
      );
      detailDocument[d] = {
        ...detailDocument[d],
        valordescuentocomercial: nuevoComercial,
        totaldescuentoreal: cTotalDescuentoReal,
        subtotalreal: cSubtotalReal,
        totalivareal: cValorIVAReal,
        totalincreal: cValorINCReal,
        totalreal: cTotalReal,
      };
    }

    let nuevoDescuento =
      commercialDiscounts[i].valoradescontar + cDescuentoComercial;
    commercialDiscounts[i] = {
      ...commercialDiscounts[i],
      valoradescontar: roundDecimalValue(nuevoDescuento),
    };
  }
};

/**
 * @function
 * @description Calcula valor de cargo global respecto a cantidad
 * @param {int} quantity Cantidad
 * @param {float} value Valor cargo
 * @returns {float} valor calculado
 */
export const calculateValueGlobalCharge = (quantity, value) => {
  try {
    let result = parseFloat(value * quantity);
    return roundDecimalValue(result);
  } catch (err) {
    console.error(
      "Ha ocurrido un error calculando valor de cargo, Por favor contacte al administrador",
      err
    );
    throw err;
  }
};
