import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { useFormComplete } from "../../../hooks/useFormComplete";

import { Grid } from "@material-ui/core";
import SelectForm from "../../../components/select/select.component";
import InputForm from "../../../components/input/input.component";
import DatePickerForm from "../../../components/datepicker/datepicker.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";

import { getHealthCatalogAction } from "../../../actions/configAction";
import {
	addPatientAction,
	updatePatientAction,
	getPatientByDocument,
} from "../../../actions/patientAction";

import { format } from "date-fns";
import generateId from "../../../utils/generateId";
import CustomProgress from "../../../components/Progress/progress.component";
import { WarningAlert } from "../../../helpers/alert.helpers";

/**
 * Fecha de final por defecto
 * @returns
 */
const getDateEnd = () => {
	let date = new Date();
	date.setDate(date.getDate() + 1);
	return date;
};

export const PatientForm = ({ handleClosePanel }) => {
	const dispatch = useDispatch();
	const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
		.current;
	const [, forceUpdate] = useState();
	const [consultedDocument, setConsultedDocument] = useState("");
	const {
		values,
		handleInputChange,
		handlePickerChange,
		handleUpdateForm,
		reset,
	} = useFormComplete({
		tipodocumentoid: "",
		numerodocumento: "",
		nombres: "",
		tipousuarioid: "",
		tipomodalidadid: "",
		tipocoberturaid: "",
		numeroautorizacion: "",
		numeroprescripcion: "",
		idsuministro: "",
		numerocontrato: "",
		numeropoliza: "",
		fechainicio: format(new Date(), "yyyy/MM/dd"),
		fechafin: format(getDateEnd(), "yyyy/MM/dd"),
		copago: "",
		cuotamoderadora: "",
		cuotarecuperacion: "",
		pagoscompartidos: "",
	});

	const {
		typesCoverage,
		modalitiesContracting,
		typesIdentificationHealth,
		typesUserHealth,
	} = useSelector((s) => s.configReducer);
	const {
		active: patientActive,
		loading,
		existing: existingPatient,
		patientList,
	} = useSelector((s) => s.patientReducer);

	const {
		tipodocumentoid,
		numerodocumento,
		nombres,
		tipousuarioid,
		tipomodalidadid,
		tipocoberturaid,
		numeroautorizacion,
		numeroprescripcion,
		idsuministro,
		numerocontrato,
		numeropoliza,
		fechainicio,
		fechafin,
		copago,
		cuotamoderadora,
		cuotarecuperacion,
		pagoscompartidos,
	} = values;

	/**
	 * Cargue inicial
	 */
	useEffect(() => {
		dispatch(getHealthCatalogAction());
	}, [dispatch]);

	/**
	 * Carga información de paciente en formulario
	 */
	useEffect(() => {
		if (!!patientActive) {
			loadDataInForm(patientActive);
			handleClosePanel(true);
		} else {
			reset();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientActive]);

	/**
	 * Carga infromación de paciente registrado en BD
	 */
	useEffect(() => {
		if (!!existingPatient) {
			loadDataInForm(existingPatient);
		} else {
			cleanDataExistingPatient();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [existingPatient]);

	/**
	 * Sincroniza nueva valor tipo documento con state
	 * @param {*} e
	 */
	const handleChangeTypeDocument = (e) => {
		if (numerodocumento.length > 0) {
			dispatch(getPatientByDocument(null, null));
		}
		handleUpdateForm({
			tipodocumentoid: e.target.value,
			numerodocumento: "",
		});
	};

	/**
	 * Sincroniza valor numero documento con state
	 * @param {*} e
	 */
	const handleChangeDocument = (e) => {
		const type = typesIdentificationHealth.find(
			(c) => c.id === tipodocumentoid
		);
		if (type?.codigo === "PA") {
			handleInputChange(e, "alphanumeric");
		} else {
			handleInputChange(e, "number");
		}
	};

	/**
	 * Guarda o actualiza información del paciente
	 * @param {*} e
	 */
	const onSubmitPatient = (e) => {
		e.preventDefault();

		if (validator.allValid()) {
			//Consultar
			if (!patientActive) {
				const exist = patientList.find(
					(c) =>
						c.tipodocumentoid === tipodocumentoid &&
						c.numerodocumento === numerodocumento
				);

				if (!!exist) {
					WarningAlert(
						"Upss...!!",
						"Ya agregaste un paciente con este tipo y numero identificación."
					);
					return;
				}
			}

			const typedoc = typesIdentificationHealth.find(
				(c) => c.id === tipodocumentoid
			);
			const typeuser = typesUserHealth.find((c) => c.id === tipousuarioid);
			const modality = modalitiesContracting.find(
				(c) => c.id === tipomodalidadid
			);
			const coverage = typesCoverage.find((c) => c.id === tipocoberturaid);

			const data = {
				id: !!patientActive ? patientActive.id : generateId(),
				tipodocumentoid: tipodocumentoid,
				tipodocumentocodigo: typedoc.codigo,
				numerodocumento: numerodocumento,
				nombres: nombres,
				tipousuarioid: tipousuarioid,
				tipousuariocodigo: typeuser?.codigo ?? null,
				tipousuariodescripcion: typeuser?.descripcion ?? "",
				tipomodalidadid: tipomodalidadid,
				tipomodalidadcodigo: modality?.codigo ?? null,
				tipomodalidaddescripcion: modality?.descripcion ?? "",
				tipocoberturaid: tipocoberturaid,
				tipocoberturacodigo: coverage?.codigo ?? null, 
				tipocoberturadescripcion: coverage?.descripcion ?? "",
				numeroautorizacion: numeroautorizacion,
				numeroprescripcion: numeroprescripcion,
				idsuministro: idsuministro,
				numerocontrato: numerocontrato,
				numeropoliza: numeropoliza,
				fechainicio: fechainicio,
				fechafin: fechafin,
				copago: copago,
				cuotamoderadora: cuotamoderadora,
				cuotarecuperacion: cuotarecuperacion,
				pagoscompartidos: pagoscompartidos,
			};

			if (!!patientActive) {
				dispatch(updatePatientAction(data));
			} else {
				dispatch(addPatientAction(data));
			}
			handleEndSave();
		} else {
			validator.showMessages();
			forceUpdate(Math.random());
		}
	};

	/**
	 * Limpia formulario despues de agregar o actualizar
	 */
	const handleEndSave = () => {
		validator.hideMessages();
		forceUpdate(Math.random());
		reset();
		handleClosePanel(false);
		setConsultedDocument("");
	};

	/**
	 * Consulta información paciente
	 * @param {*} e
	 */
	const handleGetPatient = (e) => {
		const nuevonumero = e.target.value;
		if (consultedDocument !== nuevonumero) {
			setConsultedDocument(nuevonumero);
			dispatch(getPatientByDocument(tipodocumentoid, nuevonumero));
		}
	};

	/**
	 * Carga información de paciente en formulario
	 * @param {*} data
	 */
	const loadDataInForm = (data) => {
		handleUpdateForm({
			tipodocumentoid: data.tipodocumentoid,
			numerodocumento: data.numerodocumento,
			nombres: data.nombres,
			tipousuarioid: data.tipousuarioid,
			tipomodalidadid: data.tipomodalidadid,
			tipocoberturaid: data.tipocoberturaid,
			numeroautorizacion: data.numeroautorizacion ?? "",
			numeroprescripcion: data.numeroprescripcion ?? "",
			idsuministro: data.idsuministro ?? "",
			numerocontrato: data.numerocontrato ?? "",
			numeropoliza: data.numeropoliza ?? "",
			fechainicio: format(new Date(data.fechainicio), "yyyy/MM/dd"),
			fechafin: format(new Date(data.fechafin), "yyyy/MM/dd"),
			copago: data.copago ?? "",
			cuotamoderadora: data.cuotamoderadora ?? "",
			cuotarecuperacion: data.cuotarecuperacion ?? "",
			pagoscompartidos: data.pagoscompartidos ?? "",
		});
	};

	/**
	 * Borra información de paciente
	 * existente en BD
	 */
	const cleanDataExistingPatient = () => {
		handleUpdateForm({
			nombres: "",
			tipousuarioid: "",
			tipomodalidadid: "",
			tipocoberturaid: "",
			numeroautorizacion: "",
			numeroprescripcion: "",
			idsuministro: "",
			numerocontrato: "",
			numeropoliza: "",
			fechainicio: format(new Date(), "yyyy/MM/dd"),
			fechafin: format(getDateEnd(), "yyyy/MM/dd"),
			copago: "",
			cuotamoderadora: "",
			cuotarecuperacion: "",
			pagoscompartidos: "",
		});
	};

	return (
		<form noValidate autoComplete="off" onSubmit={onSubmitPatient}>
			<Grid container>
				<Grid item lg={4} xs={12}>
					<SelectForm
						name={"tipodocumentoid"}
						label={"Tipo de documento"}
						value={tipodocumentoid}
						disabled={loading || !!patientActive}
						options={typesIdentificationHealth}
						onChange={handleChangeTypeDocument}
						validator={validator}
						validateOptions={"required"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"numerodocumento"}
						label={"Número de documento"}
						value={numerodocumento}
						maxLength={16}
						onChange={handleChangeDocument}
						onBlur={handleGetPatient}
						disabled={!tipodocumentoid || loading  || !!patientActive}
						validator={validator}
						validateOptions={"required|min:3|max:16"}
						endAdornment={loading ? <CustomProgress size={20} /> : null}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"nombres"}
						label={"Nombres(s) y Apellidos(s)"}
						value={nombres}
						maxLength={200}
						onChange={(e) => handleInputChange(e, "alphanumeric")}
						validator={validator}
						validateOptions={"required|min:2|max:200"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<SelectForm
						name={"tipousuarioid"}
						label={"Tipo de usuario"}
						value={tipousuarioid}
						options={typesUserHealth}
						onChange={handleInputChange}
						validator={validator}
						validateOptions={"required"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<SelectForm
						name={"tipomodalidadid"}
						label={"Modalidad de contratación y pago"}
						value={tipomodalidadid}
						options={modalitiesContracting}
						onChange={handleInputChange}
						validator={validator}
						validateOptions={"required"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<SelectForm
						name={"tipocoberturaid"}
						label={"Cobertura plan de beneficios"}
						value={tipocoberturaid}
						options={typesCoverage}
						onChange={handleInputChange}
						validator={validator}
						validateOptions={"required"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"numeroautorizacion"}
						label={"Número de Autorización"}
						value={numeroautorizacion}
						maxLength={20}
						onChange={(e) => handleInputChange(e, "alphanumeric")}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"numeroprescripcion"}
						label={"Número de mi prescripción (MIPRES)"}
						value={numeroprescripcion}
						maxLength={20}
						onChange={(e) => handleInputChange(e, "alphanumeric")}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"idsuministro"}
						label={"ID Suministro prescripción (MIPRES)"}
						value={idsuministro}
						maxLength={20}
						onChange={(e) => handleInputChange(e, "alphanumeric")}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"numerocontrato"}
						label={"Número de contrato"}
						value={numerocontrato}
						maxLength={20}
						onChange={(e) => handleInputChange(e, "alphanumeric")}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"numeropoliza"}
						label={"Número de póliza"}
						value={numeropoliza}
						maxLength={20}
						onChange={(e) => handleInputChange(e, "alphanumeric")}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<DatePickerForm
						name={"fechainicio"}
						label="Fecha de inicio periodo facturación"
						value={fechainicio}
						onChange={handlePickerChange}
						maxDate={new Date(fechafin)}
						validator={validator}
						validateOptions={"required"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<DatePickerForm
						name={"fechafin"}
						label="Fecha de fin periodo facturación"
						value={fechafin}
						onChange={handlePickerChange}
						minDate={new Date(fechainicio)}
						validator={validator}
						validateOptions={"required"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"copago"}
						label={"Copago"}
						value={copago}
						onChange={handleInputChange}
						maxLength={21}
						format={"money"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"cuotamoderadora"}
						label={"Cuota moderadora"}
						value={cuotamoderadora}
						onChange={handleInputChange}
						maxLength={21}
						format={"money"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"cuotarecuperacion"}
						label={"Cuota recuperación"}
						value={cuotarecuperacion}
						onChange={handleInputChange}
						maxLength={21}
						format={"money"}
					/>
				</Grid>
				<Grid item lg={4} xs={12}>
					<InputForm
						name={"pagoscompartidos"}
						label={"Pagos compartidos"}
						value={pagoscompartidos}
						onChange={handleInputChange}
						maxLength={21}
						format={"money"}
					/>
				</Grid>
			</Grid>

			<Grid container justify="flex-end">
				<Grid item lg={4} xs={12}>
					<ButtonPrimary text={!!patientActive ? "Editar" : "Agregar"} />
				</Grid>
			</Grid>
		</form>
	);
};
