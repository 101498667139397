/**
* Verifica que el valor a pagar del documento sea mayor al valor de los Anticipos
* @param {*} total Total a pagar 
* @param {*} refdocuments Listado documentos de referencia
*/
export const checkTotalDocument = (total, refdocuments) => {

    refdocuments = refdocuments ?? [];
    total = total ?? 0;

    const anticipos = refdocuments.filter(c => c.tipodocumentoid.toString() === '1');
    if (anticipos.length === 0)
        return true;

      
    let valoranticipo = 0;
    let anticipovalido = true;
    for (let i = 0; i < anticipos?.length; i++) {
        const item = anticipos[i];
        let valor = parseFloat(item?.valorapagar) ?? 0;
        valoranticipo = valoranticipo + valor;

        if (valoranticipo > total) {
            anticipovalido = false;
            break;
        }
    }
    return anticipovalido;

}