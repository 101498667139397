import React, { Component } from "react";

import {ComposedChart,Line,Bar,XAxis,YAxis,Tooltip} from "recharts";

import {Card,CardContent,FormControlLabel,CardHeader} from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { withStyles } from "@material-ui/core/styles";

import CustomProgress from "../../../components/Progress/progress.component";

import "../Components/dashboard.css";

const sizeIcon = 10;


const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor
  },
  composedChart: {
    color: theme.palette.primaryColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize
  },
  divMessage: {
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  },
  sizeIconIcome: {
    fontSize: sizeIcon,
    color: "#50C1EE"
  },
  sizeIconCost: {
    fontSize: sizeIcon,
    color: "#F1474A"
  },
  sizeIconBenefit: {
    fontSize: sizeIcon,
    color: "#DDDD1C"
  },
  styleLabel: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 100
  }
});

const getIntroOfPage = (label) => {
  if (label === "Ene") {
    return "Enero";
  }
  if (label === "Feb") {
    return "Febrero";
  }
  if (label === "Mar") {
    return "Marzo";
  }
  if (label === "Abr") {
    return "Abril";
  }
  if (label === "May") {
    return "Mayo";
  }
  if (label === "Jun") {
    return "Junio";
  }
  if (label === "Jul") {
    return "Julio";
  }
  if (label === "Ago") {
    return "Agosto";
  }
  if (label === "Sep") {
    return "Septiembre";
  }
  if (label === "Oct") {
    return "Octubre";
  }
  if (label === "Nov") {
    return "Noviembre";
  }
  if (label === "Dic") {
    return "Diciembre";
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  const options = {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 10,
  };
  const numberFormat = new Intl.NumberFormat("en-US", options);
  if (active) {
    return (
      <div
        style={{
          background: "#fff",
          marginLeft: "20px",
          borderRadius: 5,
          height: 80,
        }}
        className="custom-tooltip"
      >
        <p
          style={{ color: "black", textAlign: "center", lineHeight: "15px" }}
          className="label"
        >
          {getIntroOfPage(label)}
        </p>
        <p
          style={{ color: payload[0].fill, lineHeight: "2px" }}
          className="intro"
        >{`${"Ingresos"} : ${numberFormat.format(payload[0].value)}`}</p>
        <p
          style={{ color: payload[1].fill, lineHeight: "2px" }}
          className="intro"
        >{`${"Costos"} : ${numberFormat.format(payload[1].value)}`}</p>
        <p
          style={{ color: payload[2].stroke, lineHeight: "2px" }}
          className="intro"
        >{`${"Beneficios"} : ${numberFormat.format(payload[2].value)}`}</p>
      </div>
    );
  }

  return null;
};

class IncomeChart extends Component {
  


  getGridListCols = () => {
    
    if (isWidthUp("xl", this.props.width)) return 1600;

    if (isWidthUp("lg", this.props.width)) return 1050;

    if (isWidthUp("md", this.props.width)) return 1000;


    return 1100;
  };

  getReturnValue(dataIncome) {
    let result;
    let response;
    for (let i = 0; i < dataIncome.length; i++) {
      result = dataIncome[0].ingresos;
    }
    if (result === null) {
      response = true;
    }
    return response;
  }

  formatter = (value) => {
    const options = {
      style: "currency",
      currency: "USD",
      maximumSignificantDigits: 10,
    };
    const numberFormat = new Intl.NumberFormat("en-US", options);
    return numberFormat.format(value);
  };

  render() {
    const { classes, dataIncome, statusCode } = this.props;

    return (
      <Card className={classes.colorCard}>
        <CardHeader title="Ingresos" className={"title"} />

        <CardContent>
          {dataIncome.length === 0 && statusCode === undefined ? (
            <CustomProgress />
          ) : (dataIncome.length === 0 && statusCode === 0) ||
            (dataIncome.length === 0 && statusCode === 200) ||
            this.getReturnValue(dataIncome) === true ? (
            <div className={classes.divMessage}>
              No tiene facturas registradas
            </div>
          ) : (
            
            <ComposedChart
              width={this.getGridListCols()}
              height={250}
              data={dataIncome}
              className={classes.composedChart}
              margin={{
                top: 20,
                bottom: 20,
                left: 160,
              }}
            >
              <XAxis dataKey="name" tick={{ stroke: "#FFFFFF" }} />
              <YAxis
                tickFormatter={this.formatter}
                tick={{ stroke: "#FFFFFF" }}
                tickSize={4}
                orientation="left"
                minTickGap={2}
              />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Bar dataKey="ingresos" barSize={20} fill="#50C1EE" />
              <Bar dataKey="costos" barSize={20} fill="#F1474A" />

              <Line
                type="monotone"
                dataKey="beneficios"
                stroke="#DDDD1C"
                strokeWidth={3}
                dot={false}
                activeDot={false}
              />
              <YAxis />
            </ComposedChart>
          )}
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              control={<Brightness1Icon className={classes.sizeIconIcome} />}
              label={<div className={classes.styleLabel}> &nbsp; Ingresos</div>}
            />
            &nbsp; &nbsp; &nbsp;
            <FormControlLabel
              control={<Brightness1Icon className={classes.sizeIconCost} />}
              label={<div className={classes.styleLabel}> &nbsp; Costos</div>}
            />
            &nbsp; &nbsp; &nbsp;
            <FormControlLabel
              control={<Brightness1Icon className={classes.sizeIconBenefit} />}
              label={<div className={classes.styleLabel}>&nbsp; Beneficios</div>}
            />
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withWidth()(withStyles(useStyles)(IncomeChart));
