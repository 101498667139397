import React from "react";
import ReactDOM from "react-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		margin: theme.spacing(1),
		minWidth: 120,
	},
	select: {
		"& ul": {
			backgroundColor: theme.palette.primaryBackgroundColor,
			maxWidth: 532,
		},
		"& li span": {
			color: `${theme.palette.primaryColor} !important`,
		},
	},
	itemText: {
		fontWeight: 600,
		color: theme.palette.primaryColor,
		whiteSpace: "initial",
	},
	textOverflow: {
		wordBreak: "break-word",
		fontWeight: 600,
	},
}));

const CustomForm = withStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			color: theme.palette.thirdColor, //azul
		},
		"& label.Mui-disabled": {
			color: "gray", //gris
		},
		"& label.MuiFormLabel-filled:not(.Mui-focused)": {
			color: "gray", //gris
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.thirdColor, //azul
		},
		"& .MuiInput-underline:before": {
			borderBottomColor: "gray", //gris
		},
		"& .MuiInput-underline:hover": {
			borderBottomColor: "gray", //gris
		},
		"& .MuiInput-underline:focus": {
			borderBottomColor: "gray", //gris
		},
		"& .MuiInputBase-input": {
			color: theme.palette.primaryColor,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "2px solid",
				borderColor: " rgba(255,255,255,0.3)", //gris
			},
			"&:hover fieldset": {
				borderColor: "gray",
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.thirdColor, //azul
			},
			"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
				borderColor: "gray", //gris
			},
			"& svg": {
				color: theme.palette.fifthBackgroundColor,
			},
		},
	},
}))(FormControl);

export const MultipleSelect = ({
	label,
	name,
	value = [],
	options = [],
	onChange,
}) => {
	const classes = useStyles();
	const labelRef = React.useRef(null);
	const labelNode = ReactDOM.findDOMNode(labelRef.current);
	const labelWidth = labelNode != null ? labelNode.offsetWidth : 280;

	return (
		<CustomForm variant="outlined" className={classes.root}>
			<InputLabel
				id={"label-checkbox"}
				ref={labelRef}
				htmlFor={"mutiple-checkbox"}
			>
				{label}
			</InputLabel>
			<Select
				name={name}
				fullWidth
				multiple
				value={value}
				onChange={onChange}
				variant="outlined"
				input={
					<OutlinedInput
						id={"mutiple-checkbox"}
						name={name}
						labelWidth={labelWidth}
					/>
				}
				renderValue={(selected) =>
					selected
						.map((item) => {
							return options.find((c) => c.id === item)?.descripcion ?? "";
						})
						.join(",")
				}
				MenuProps={{ classes: { paper: classes.select } }}
				IconComponent={(props) => <ExpandMoreIcon {...props} />}
			>
				{options.map((item) => (
					<MenuItem key={item.id} value={item.id}>
						<ListItemText className={classes.itemText} component={"div"}>
							<span className={classes.textOverflow}>{item.descripcion}</span>
						</ListItemText>
						<Checkbox checked={value.find((c) => c === item.id)} />
					</MenuItem>
				))}
			</Select>
		</CustomForm>
	);
};
