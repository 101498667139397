import { types } from "../types/types";

const initialState = {
	opdocumentosreferencia: [],
	documentoreferenciaactivo: null,
};

export const documentReferenceReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.ADD_DOCUMENT_REFERENCE:
			return {
				...state,
				opdocumentosreferencia: [
					...state.opdocumentosreferencia,
					action.payload,
				],
			};
		case types.LOAD_DOCUMENT_REFERENCE:
			return {
				...state,
				documentoreferenciaactivo:
					state.opdocumentosreferencia.find((c) => c.id === action.payload) ??
					null,
			};
		case types.UPDATED_DOCUMENT_REFERENCE:
			return {
				...state,
				documentoreferenciaactivo: null,
				opdocumentosreferencia: state.opdocumentosreferencia.map((doc) =>
					doc.id === action.payload.id ? action.payload : doc
				),
			};
		case types.DELETE_DOCUMENT_REFERENCE:
			return {
				...state,
				documentoreferenciaactivo: null,
				opdocumentosreferencia: state.opdocumentosreferencia.filter(
					(d) => d.id !== action.payload
				),
			};
		case types.REFRESH_DOCUMENTS_REFERENCE:
			return {
				...state,
				opdocumentosreferencia: action.payload,
			};
		case types.CLEAN_DOCUMENTS_REFERENCE:
			return initialState;
		default:
			return state;
	}
};
