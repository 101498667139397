import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

import { reloadComponentOnRequestFailure } from "../../../../../actions/equivalentDocAction";

import warningImage from "../../../../../images/modal/14Ocurriounerror-14.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "77.9vh",
    overflow: "none",
    padding: "3%",
  },
  warningTitle: {
    color: theme.palette.fourthColor,
  },
  divContainer: {
    alignItems: "center",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  titleModal: {
    fontFamily: "Muli",
    fontSize: "30px",
    fontWeight: "800",
  },
  subTitleModal: {
    color: theme.palette.primaryColor,
    fontFamily: "Muli",
    fontSize: "30px",
    fontWeight: "400",
  },
  bodyModal: {
    color: theme.palette.primaryColor,
    fontFamily: "Muli",
    fontWeight: "800",
    marginBottom: "2rem",
  },
  buttonSuccessSmall: {
    backgroundColor: theme.palette.thirdColor,
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "none",
    width: "100%",
    "&:hover": {
      backgroundColor: blue[800],
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
  },
  image: {
    cursor: "pointer",
    width: "60%",
  },
}));

export const ErrorPage = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.errorReducer);

  const { selectedDocumentID } = useSelector(
    (state) => state.equivalentDocReducer
  );

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={4} md={4} sm={12}></Grid>
        <Grid item lg={4} md={4} sm={12}>
          <div className={classes.divContainer}>
            <div id="response-modal-title" className={classes.titleModal}>
              <span className={classes.warningTitle}>Upss...!!!</span>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={3} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.divContainer}>
            <div
              id="response-modal-description"
              className={classes.subTitleModal}
            >
              Error al cargar el documento
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={3} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.divContainer}>
            <img
              src={warningImage}
              alt={"imageWarning"}
              className={classes.image}
            />
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={3} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.divContainer}>
            <div className={classes.bodyModal}>{message}</div>
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12}></Grid>
      </Grid>
      <div
        style={{ width: "10%", marginTop: "5px", marginBottom: "20px" }}
        className={classes.divContainer}
      >
        <Button
          variant="contained"
          className={classes.buttonSuccessSmall}
          onClick={() => {
            dispatch(reloadComponentOnRequestFailure(selectedDocumentID));
          }}
        >
          Recargar
        </Button>
      </div>
    </div>
  );
};
