/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Grid, Box } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import InputForm from "../../../components/input/input.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import InputIcon from "../../../components/input/inputIcon.component";
import CardDevice from "./cardDevice.component";
import CollapsePanel from "../../../components/collapse/collapsestyles.component";
import {
  getDevices,
  putDeviceMacState,
} from "../../../actions/numerationActions";
import { connect } from "react-redux";
import { WarningAlert } from "../../../helpers/alert.helpers";
import { makeStyles } from "@material-ui/core/styles";
import CustomProgress from "../../../components/Progress/progress.component";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButtonBase-root": {
      borderRadius: 5,
    },
  },
  titleStyle: {
    color: theme.palette.thirdColor, //azul
    fontSize: "28px",
    fontWeight: "bold",
    textAlign: "center",
  },
  subTitleStyle: {
    color: theme.palette.thirdColor, //azul
    fontSize: "23px",
    textAlign: "center",
    paddingBottom: "20px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid gray",
    display: "inline-grid",
  },
  listStyle: {
    color: theme.palette.secundaryColor, //Green
    display: "inline-grid",
    paddingLeft: "23px",
    paddingRight: "4px",
  },
  paddingLeftInfo: {
    paddingLeft: "15px",
  },
  txtSearch: {
    paddingTop: "15px",
  },
}));

const AssignContingency = (props) => {
  const classes = useStyles();

  const {
    devices,
    getDevices,
    numerationId,
    putDeviceMacState,
    numerationName,
    prefijo,
  } = props;
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    if (devices.length === 0) {
      const rta = async () => {
        await getDevices();
      };
      rta();
      setCargando(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [devicesFilter, setDevicesFilter] = useState(devices);
  let [txtFilter, setTxtFilter] = useState("");

  const onChangeFilter = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setTxtFilter(value);
    setDevicesFilter(
      devices.filter((item) => {
        return (
          item.deviceName.toLowerCase().includes(value.toLowerCase()) ||
          (item.users && filterUsers(item.users, value))
        );
      })
    );
  };

  const filterUsers = (users, value) => {
    const bool = users.filter((user) => {
      return (
        user.userName.toLowerCase().includes(value.toLowerCase()) ||
        user.email.toLowerCase().includes(value.toLowerCase())
      );
    });
    return bool.length !== 0;
  };
  const updateDevices = () => {
    getDevices();
    setDevicesFilter(devices);
  };
  const getVincularDispositivos = () => {
    return (
      <div>
        <Grid item lg={12} xs={12}>
          <Typography variant="body1" align="center">
            {"¿Como vincular mis dispositivos?"}
          </Typography>
          <br />
          <span>
            <div className={classes.listStyle}>{"1."}</div>
            {
              "Descarga el app de Colfactura en el dispositivo (Celular o Tablet)."
            }
          </span>
          <br />
          <span>
            <div className={classes.listStyle}>{"2."}</div>
            {"Inicia sesión con un usuario registrado"}
          </span>
          <br />
          <span>
            <div className={classes.listStyle}>{"3."}</div>
            {
              "Listo!!! tu nuevo dispositivo aparecerá en el listado de la parte de abajo"
            }
          </span>
          <span className={classes.divider}></span>

          <Grid container spacing={1} className={classes.paddingLeftInfo}>
            <span className={classes.paddingLeftInfo}>
              {
                "¿Necesitas que tu equipo de trabajo descargue el APP movil de Colfactura? Envia el link de descarga a continuación"
              }
            </span>
            <Grid item md={6} xs={12}>
              {" "}
              <InputForm
                width="100%"
                id={"mail"}
                label={"Correo Electrónico"}
                maxLength="30"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <ButtonPrimary
                text="Enviar link"
                type={"submit"}
                onClick={() => {
                  WarningAlert(
                    "Ups!!!",
                    "Esta funcionalidad no ha sido implementada."
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const associateDevice = async (deviceMac) => {
    const data = { idnumeracion: numerationId, deviceMac: deviceMac };
    return putDeviceMacState(data);
  };

  const numerationTitle = () => {
    return (
      "Numeración para " +
      (numerationName ? numerationName + " - " : "") +
      (prefijo ? prefijo : "Sin Prefijo")
    );
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ padding: "0px 20px" }}>
        <Grid item lg={12} xs={12} className={classes.titleStyle}>
          <span>{"Asignación de dispositivo"}</span>
        </Grid>
        <br />
        <br />
        <Grid item lg={12} xs={12} className={classes.subTitleStyle}>
          <span>{numerationTitle()}</span>
        </Grid>
        <br></br>
        <br></br>

        <Grid container spacing={3}>
          <br></br>
          <Grid item lg={12} xs={12}>
            <CollapsePanel
              component={getVincularDispositivos}
              title={"Vincular dispositivos"}
              titleOpen={"Vincular dispositivos"}
              edit={false}
              open={""}
              //className={classes.collapsePanel}
              cleanData={false}
              backgroundColorSummary={{
                backgroundColor: "#2CC63E",
                color: "#393939",
                paddingLeft: "43%",
              }}
              backgroundColorDetail={{ backgroundColor: "#5E5E5E" }}
              type="showinfo"
            />
          </Grid>
        </Grid>
        <Grid container className={classes.txtSearch}>
          <Grid item md={8} xs={12}>
            <Box pt={3}>
              <InputIcon
                id={"txtFilter"}
                label={""}
                value={txtFilter}
                onChange={(e) => {
                  onChangeFilter(e);
                }}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <ButtonPrimary
              text="Refrescar Lista"
              type={"submit"}
              onClick={() => updateDevices()}
            />
          </Grid>
        </Grid>

        {cargando ? (
          <Grid container spacing={1}>
            {devicesFilter && devicesFilter.length > 0 ? (
              devicesFilter.map((device) => (
                <Grid key={device.deviceName} item xs={12} md={6} xl={4}>
                  <CardDevice
                    device={device}
                    numerationName={numerationName}
                    associateDevice={() => associateDevice(device.deviceMac)}
                  ></CardDevice>
                </Grid>
              ))
            ) : (
              <Typography>No se encontraron dispositivos</Typography>
            )}
          </Grid>
        ) : (
          <Grid item lg={12} xs={12}>
            <Grid container alignItems="center" justify="center">
              <CustomProgress />
            </Grid>
          </Grid>
        )}
      </Grid>
      <br></br>
    </div>
  );
};

const mapStateToProps = ({ numerationReducer }) => ({
  devices: numerationReducer.devices,
});

const mapDispatchToProps = {
  getDevices,
  putDeviceMacState,
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignContingency);
