import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, IconButton } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";

import { ItemReceivedCard } from "./itemReceivedCard";
import { FormatDecimal } from "../../components/common/formatDecimal";
import { CollapseDataPanel } from "../../components/ui/collapseDataPanel";
import CustomProgress from "../../components/Progress/progress.component";
import { AdditionalDataPanel } from "./AdditionalDataPanel";
import { ReceiptDocumentForm } from "./ReceiptDocumentForm";
import { RejectInvoiceForm } from "./RejectInvoiceForm";
import { SecondCustomModal } from "../../components/modal/SecondCustomModal";
import { UploadProgress } from "./UploadProcess";

import {
  acceptDocumentAction,
  changeDocumetAvailableZeroAction,
  receiptDocumentAction,
  rejectDocumentAction,
  standOutDocumentAction,
} from "../../actions/documentReceivedActions";

import { format } from "date-fns";
import { ConfirmAlert } from "../../helpers/alert.helpers";
import ResponseModal from "../../components/modal/responseModal.component";
// import ResponseRedirectModal from "../../components/modal/responseRedirectModal.component";
import {
  cleanLicenseBaseAction,
  // updateOpenModalItem,
} from "../../actions/invoiceActions";
// import { CLAIM_DOCUMENTS } from "../../config/config";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#4B4B4B",
    borderRadius: 5,
    height: "80.88vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "justify",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
    cursor: "pointer",
    "&.MuiIconButton-root.Mui-disabled": {
      opacity: 0.4,
    },
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  content: {
    width: "100%",
    padding: 15,
    fontSize: 16,
    fontWeight: 300,
    overflow: "auto",
  },
  actions: {
    backgroundColor: "#393939",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    textAlign: "center",
    alignSelf: "flex-end",
    width: "100%",
    height: 80,
    padding: 15,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  value: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#F5D13D",
  },
  valueAccepted: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#2CC63E",
  },
  valueRejected: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#EC005F",
  },
  line: {
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    height: 0.5,
    margin: "10px 5px",
  },
  name: {
    fontSize: 20,
    color: "#F6D746",
    fontWeight: "bold",
  },
  icondata: {
    color: "#2CC63E",
    marginRight: 5,
    fontSize: 22,
  },
  flexdisplay: {
    display: "flex",
  },
  titleItems: {
    width: "100%",
    backgroundColor: "rgba(18, 18, 18, 0.3)",
    borderRadius: 5,
    color: "#2CC63E",
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px auto",
    padding: 5,
  },
  status: {
    fontSize: 20,
    color: "#F5D13D",
  },
  statusAccepted: {
    fontSize: 20,
    color: "#2CC63E;",
  },
  statusRejected: {
    fontSize: 20,
    color: "#EC005F;",
  },
  containerFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

export default function ReceivedDetail({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openReceiptModal, setOpenReceiptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = useState(
    initialModal
  );
  const [reject, setReject] = useState(false);
  const [modalResponse, setModalResponse] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  const {
    Id,
    Numero,
    Fecha,
    Codigounico,
    TipoDocumentoEmisor,
    DocumentoEmisor,
    NombreEmisor,
    CorreoElectronicoEmisor,
    NumeroTelefonicoEmisor,
    DireccionEmisor,
    DepartamentoEmisor,
    MunicipioEmisor,
    TipoRegimenEmisor,
    Favorito,
    Valorapagar,
    LineaDetalle,
    EstadoAceptacion,
    EstadoAceptacionid,
    Acuse,
    Recibido,
  } = data;

  const {
    loadingStandOut,
    loadingReceiptDocument,
    loadingRejectDocument,
    loadingAcceptDocument,
    documentAvailableZero,
  } = useSelector((s) => s.documentReceivedReducer);

  const { licenseBase } = useSelector((state) => state.invoiceReducer);

  useEffect(() => {
    if (documentAvailableZero && licenseBase?.result?.licenseActiveBase) {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            "No tienes suficientes Documentos Disponibles para Aceptar o
            Rechazar Facturas."
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentAvailableZero]);

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
  }, [licenseBase]);

  /**
   * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    dispatch(cleanLicenseBaseAction());
  };

  /**
   * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  // const onCloseModalItem = (e) => {
  //   dispatch(updateOpenModalItem(false));
  // };

  /**
   * Marca documento como destacado
   */
  const handleStandOut = () => {
    dispatch(standOutDocumentAction(Id, !Favorito));
  };

  /**
   * Abre modal acuse y recepcion del documento
   */
  const handleOpenReceiptModal = () => {
    setOpenReceiptModal(true);
  };

  /**
   * Abre modal rechazo factura
   */
  const handleOpenRejectModal = () => {
    setOpenRejectModal(true);
  };

  /**
   * Rechazo del documento
   */
  const handleReject = () => {
    if (!Acuse || !Recibido) {
      handleOpenReceiptModal();
      setReject(true);
    } else {
      handleOpenRejectModal();
    }
  };

  /**
   * Rechazo del documento
   */
  const handleRejectDocument = (rejectReason) => {
    setOpenRejectModal(false);

    const confirmDocumentRejectance = async (confirmed) => {
      if (confirmed) {
        dispatch(rejectDocumentAction(rejectReason, Codigounico, Numero));
      }
    };

    ConfirmAlert(
      "¿Estás seguro que deseas rechazar la factura?",
      confirmDocumentRejectance,
      "Si",
      "No"
    );
  };

  /**
   * Acuse y recepcion del documento
   */
  const handleReceiptDocument = (receiver) => {
    setOpenReceiptModal(false);
    dispatch(
      receiptDocumentAction(
        receiver,
        Codigounico,
        Acuse,
        Numero,
        reject,
        handleOpenRejectModal
      )
    );
  };

  /**
   * Aceptacion del documento
   */
  const handleAcceptDocument = () => {
    if (!Acuse || !Recibido) {
      handleOpenReceiptModal();
      setReject(false);
    } else {
      const confirmDocumentAcceptance = async (confirmed) => {
        if (confirmed) {
          dispatch(acceptDocumentAction(Codigounico, Numero));
        }
      };

      ConfirmAlert(
        "¿Estás seguro que deseas aceptar la factura?",
        confirmDocumentAcceptance,
        "Si",
        "No"
      );
    }
  };

  /**
   * * Cerrar Modal Sin Documentos
   */

  const handleResponseModal = () => {
    setModalResponse({
      type: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: 0,
    });
    dispatch(changeDocumetAvailableZeroAction());
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container>
            <Grid item lg={12} xs={12} className={classes.containerFlex}>
              <div className={classes.title}>No. {Numero}</div>
              {Favorito ? <StarIcon /> : <StarBorderIcon />}
            </Grid>
            <Grid item lg={12} xs={12} className={classes.containerFlex}>
              <div
                className={
                  EstadoAceptacionid === 3
                    ? classes.valueAccepted
                    : EstadoAceptacionid === 4
                    ? classes.valueRejected
                    : classes.value
                }
              >
                <FormatDecimal value={Valorapagar} prefix={"$"} />
              </div>
              <div
                className={
                  EstadoAceptacionid === 3
                    ? classes.statusAccepted
                    : EstadoAceptacionid === 4
                    ? classes.statusRejected
                    : classes.status
                }
              >
                {EstadoAceptacion}
              </div>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{!!Fecha ? format(Fecha, "dd/MM/yyyy") : ""}</span>
            </Grid>
            <div className={classes.line} />
            <Grid item lg={12} xs={12}>
              <span className={classes.name}>{NombreEmisor}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{`${TipoDocumentoEmisor} ${DocumentoEmisor}`}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{TipoRegimenEmisor}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <EmailIcon className={classes.icondata}></EmailIcon>
              <span>{CorreoElectronicoEmisor}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <PhoneIcon className={classes.icondata}></PhoneIcon>
              <span>{NumeroTelefonicoEmisor}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <RoomIcon className={classes.icondata}></RoomIcon>
              <span>
                {`${DireccionEmisor} ${MunicipioEmisor} - ${DepartamentoEmisor.toUpperCase()}`}
              </span>
            </Grid>
          </Grid>
          <div className={classes.titleItems}>
            {`Artículos (${LineaDetalle.length})`}
          </div>

          <Grid container>
            {LineaDetalle.map((item, index) => (
              <Grid item lg={12} xs={12} key={index}>
                <ItemReceivedCard {...item} />
              </Grid>
            ))}
          </Grid>

          <Grid container>
            <Grid item lg={12} xs={12}>
              <CollapseDataPanel
                backgroundColor={"#4B4B4B"}
                component={<AdditionalDataPanel data={data} />}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.actions}>
          <Grid container>
            <Grid item lg={4} xs={4}>
              {loadingStandOut ? (
                <CustomProgress size={20} />
              ) : (
                <IconButton
                  classes={{ root: classes.button, label: classes.label }}
                  variant="raised"
                  disableRipple={true}
                  onClick={handleStandOut}
                >
                  {Favorito ? <StarIcon /> : <StarBorderIcon />}
                  <span style={{ marginTop: 5 }}>Favorito</span>
                </IconButton>
              )}
            </Grid>
            <Grid item lg={4} xs={4}>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                disabled={EstadoAceptacionid > 2}
                onClick={handleAcceptDocument}
              >
                <CheckCircleIcon />
                <span style={{ marginTop: 5 }}>Aceptar Factura</span>
              </IconButton>
            </Grid>
            <Grid item lg={4} xs={4}>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                disabled={EstadoAceptacionid > 2}
                onClick={handleReject}
              >
                <CancelIcon />
                <span style={{ marginTop: 5 }}>Rechazar Factura</span>
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Modal rechazar factura */}
      <SecondCustomModal
        onClose={() => setOpenRejectModal(false)}
        open={openRejectModal}
        component={
          <RejectInvoiceForm handleRejectDocument={handleRejectDocument} />
        }
        title={"Rechazar factura"}
        showCloseButton={true}
      />

      {/* Modal recibo del bien o servicio */}
      <SecondCustomModal
        onClose={() => setOpenReceiptModal(false)}
        open={openReceiptModal}
        component={
          <ReceiptDocumentForm handleReceiptDocument={handleReceiptDocument} />
        }
        title={"Recibo del bien o servicio"}
        showCloseButton={true}
        width={800}
      />

      {/* Modal carga recibo de bien */}
      <SecondCustomModal
        open={loadingReceiptDocument}
        component={<UploadProgress text="Espera un momento por favor." />}
        title={"Recibo del bien o servicio"}
        showCloseButton={false}
        disableBackdropClick={true}
      />

      {/* Modal carga rechazar factura */}
      <SecondCustomModal
        open={loadingRejectDocument}
        component={<UploadProgress text="Espera un momento por favor." />}
        title={"Rechazar factura"}
        showCloseButton={false}
        disableBackdropClick={true}
      />

      {/* Modal carga aceptar factura */}
      <SecondCustomModal
        open={loadingAcceptDocument}
        component={<UploadProgress text="Espera un momento por favor." />}
        title={"Aceptar factura"}
        showCloseButton={false}
        disableBackdropClick={true}
      />

      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleResponseModal}
      />

      {/* <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={
          "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
        }
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      /> */}
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />
    </>
  );
}
