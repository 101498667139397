import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import SingleCustomTabs from "../../components/tabs/tabsSingle.component";
import DiscountManage from './discountManage.component';
import { getComponentsAction, ClearGetComponentsAction } from '../../actions/configAction'

const tabs = [
  {
    title: '',
    component: null,
    disabled: true,
  },
  {
    title: 'Descuentos',
    component: <DiscountManage />,
    disabled: false,
  },
  {
    title: '',
    component: null,
    disabled: true,
  },
];

class Discounts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menu: {},
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (!!nextProps.configReducer.menu) {
      update.menu = nextProps.configReducer.menu;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.menu !== this.state.menu) {
      if (!!this.state.menu && this.state.menu.length > 0) {
        this.props.getComponentsAction(12);
      }
    }
  }

  componentDidMount() {
    this.props.getComponentsAction(12);
  }

  componentWillUnmount() {
    this.props.ClearGetComponentsAction();
  }

  render() {
    return (
      <Fragment>
        <div className="container-form" >
          <SingleCustomTabs tabs={tabs} tabSelected={1} />
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  getComponentsAction,
  ClearGetComponentsAction,
})(Discounts);