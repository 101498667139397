import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import SimpleReactValidator from "simple-react-validator";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import InputForm from "../../../components/input/input.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
    color: "white",
    textAlign: "justify",
    padding: "0px 30px 25px 30px !important",
  },
  title: {
    color: "#16B1F3",
    fontSize: 25,
    fontWeight: 600,
  },
  subtitle: {
    color: "#16B1F3",
    fontSize: 18,
  },
  containerTitle: {
    textAlign: "center",
    paddingBottom: "20px !important",
    paddingTop: "20px !important",
  },
}));

const AnnulmentNoteDocSupport = (props) => {
  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
  const [, forceUpdate] = useState();
  const { onClick, number, loading } = props;
  const [observations, setObservations] = useState("");
  const [menuDocSupport, setMenuDocSupport] = useState({});

  useEffect(() => {
    setMenuDocSupport(props.menuReducer.menuDocSupport);
  }, [props.menuReducer.menuDocSupport]);

  /**
   * Sincroniza valor select con state
   * @param {*} e
   */
  const syncChanges = (e) => {
    setObservations(e.target.value);
  };

  /**
   * Valida formulario, continua proceso edición
   */
  const handleClickContinue = () => {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    } else {
      onClick(observations);
    }
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} xs={12} className={classes.containerTitle}>
        <span className={classes.title}>Anular Documento Soporte</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              menuDocSupport?.messageModalAnnulmentDocSupport?.replace(
                "NUMERO",
                number
              ) ?? "",
          }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <InputForm
          name="observaciones"
          maxLength={500}
          label={"Observaciones *"}
          multiline={true}
          noMargin={true}
          value={observations}
          onChange={syncChanges}
          validator={validator.current}
          validateOptions={"required"}
          disabled={loading}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems="center" justify="center">
          <Grid item lg={6} xs={6}>
            <ButtonPrimary
              text="Continuar"
              onClick={handleClickContinue}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {})(AnnulmentNoteDocSupport);
