import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import EventIcon from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { Tooltip } from "@material-ui/core";
import RcCalendar from "../../../components/datepicker/rcdatepicker.component";

import {
  updateDocument,
  getTotalDocs,
  getQuotationsAction,
  getDocumentDetailAction,
  updateDocumentAction,
  updateDocumentFromListAction,
} from "../../../actions/invoiceActions";
import {
  addChargeToInvoiceAction,
  openModalChargeAction,
  addDiscountToInvoiceAction,
  setStatusShowCartAction,
  calculeTotalNoteAction,
} from "../../../actions/valuesInvoiceAction";
import { getAdditionalDataAction } from "../../../actions/basicDataActions";

import { WarningAlert } from "../../../helpers/alert.helpers";

import QuotationDetails from "../../invoice/quotation/quotationDetails.view";
import CustomProgress from "../../../components/Progress/progress.component";
import SimpleModal from "../../../components/modal/simpleModal.component";
import PopoverModal from "../../../components/modal/popoverModal.component";
import StyledCheckbox from "../../../components/checkForm/styledCheckbox.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import InputIcon from "../../../components/input/inputIcon.component";
import ModalFileForm from "../../invoice/modal-file-form.component";

import { QUANTITY_DATA_DOCUMENT } from "../../../config/config";
import { formatDate } from "../../../utils/general";
import ResponseModal from "../../../components/modal/responseModal.component";
import { FormatDecimal } from "../../../components/common/formatDecimal";
import { cleanCartSupportAction } from "../../../actions/documentAction";
import { cleanDocumentSupportAction } from "../../../actions/documentSupportAction";

const useStyles = makeStyles({
  root: {
    marginBottom: 5,
    height: 90,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 14,
  },
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  letter: {
    color: "#FFFFFF",
  },
  letterSearch: {
    color: "#848484",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
  widthMax: {
    width: "100%",
  },
  paddingIcons: {
    paddingTop: "20%",
  },
  width200: {
    width: "200px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: "1%",
  },
  width340: {
    width: "340px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: "1%",
  },
  width400: {
    width: "400px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: "1%",
  },
  buttonIcon: {
    color: "white",
    marginTop: "50%",
  },
  iconButton: {
    padding: 0,
    color: "white",
  },
});

const QuotationGrid = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    HandleCreate,
    Empresaid,
    HandleEdit,
    HandleBill,
    Type,
    loadingGetdocuments,
    sendMailResponse,
  } = props;
  const { invoices, totaldocuments, loadingFavouriteFromList } = props; //Reducer
  // const windowsHeight = parseInt(window.innerHeight * 0.58, 10);
  // const heightDiv = windowsHeight + "px";
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = React.useState(null);
  const [openModalMail, setOpenModalMail] = useState(false);
  const [setOpenModalFile] = useState(false);
  const [documentIdSelected, setDocumentSelected] = useState(null);
  const [txtFilter, setTxtFilter] = useState("");
  const [txtFilterConfirmed, setTxtFilterConfirmed] = useState("");
  const [filterData, setFilterData] = useState({
    Fechadesde: null,
    Fechahasta: null,
    Empresaid: Empresaid,
    Filtro: "",
    Skip: 0,
    Limit: QUANTITY_DATA_DOCUMENT,
    Tipoaceptaciondocumentoid: Type,
  });
  const [datesTmp, setDatesTmp] = useState({
    Fechini: format(new Date(), "MM-dd-yyyy"),
    Fechfin: format(new Date(), "MM-dd-yyyy"),
  });
  const [email, setEmail] = useState("");
  const [mailData, setMailData] = React.useState({});

  const [modalData, setModalData] = React.useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  const filterImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC";

  /**
   * Consulta listado cotizaciones paginando y filtrando
   */
  useEffect(() => {
    props.getQuotationsAction(filterData, invoices, selectDefaultItem);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  /**
   * Consulta detalle de  documento seleccionado
   */
  useEffect(() => {
    if (documentIdSelected !== null && documentIdSelected !== undefined) {
      props.getDocumentDetailAction(documentIdSelected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentIdSelected]);

  /**
   * Sincroniza nuevo valor filtro
   * @param {*} e Evento
   */
  const syncChangesFilter = (e) => {
    let value = e.target.value;
    setTxtFilter(value);

    if (value === "") {
      setTxtFilterConfirmed("");
      setFilterData({
        ...filterData,
        Skip: 0,
        LoadMore: false,
        Filtro: "",
      });
    }
  };

  /**
   * Evento, click sobre boton busqueda, consulta nuevo listado documentos
   * @param {*} e Evento
   */
  const onSearch = (e) => {
    setFilterData({
      ...filterData,
      Filtro: txtFilter,
      Skip: 0,
      LoadMore: false,
    });

    setTxtFilterConfirmed(txtFilter);
  };

  /**
   * Cierra Modal, Notificación certificado no valido
   */
  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  /**
   * Carga identificador  de documento por defecto, para realizar consulta
   * @param {*} id Identificador documento
   */
  const selectDefaultItem = (id) => {
    setDocumentSelected(id);
  };

  /**
   * Cierra modal filtro por estado documentos
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Abre modal filtro por estado documentos
   * @param {*} event
   */
  const handleOpenModalChecks = (event) => {
    if (loadingGetdocuments !== true) {
      setModalChecksAnchorEl(event.currentTarget);
    }
  };

  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * Abre modal para filtro por fechas
   * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    if (loadingGetdocuments !== true) {
      setModalDatesAnchorEl(event.currentTarget);
    }
  };

  const handleCloseModalMail = () => {
    setOpenModalMail(false);
  };

  /**
   * Escucha evento, filtro por estado en documentos
   * @param {*} event
   */
  const handlecheckFilters = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;

    if (name === "chkVencidas") {
      setFilterData({
        ...filterData,
        Vencidas: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    if (name === "chkDestacadas") {
      setFilterData({
        ...filterData,
        Destacadas: checked,
        Skip: 0,
        LoadMore: false,
      });
    }

    setTxtFilter(txtFilterConfirmed);
  };

  /**
   * LImpia filtro por estado de documentos
   */
  const cleanFilter = () => {
    setFilterData({
      ...filterData,
      Skip: 0,
      Destacadas: false,
      Vencidas: false,
      LoadMore: false,
    });

    handleCloseModalChecks();
    setTxtFilter(txtFilterConfirmed);
  };

  /**
   * Seleciona fecha inicial en filtro
   * @param {*} date
   */
  const handleDateFinal = (date) => {
    setDatesTmp((prevState) => ({
      ...prevState,
      Fechfin: date,
    }));
  };

  /**
   * Seleciona fecha final en filtro
   * @param {*} date
   */
  const handleDateInitial = (date) => {
    setDatesTmp((prevState) => ({
      ...prevState,
      Fechini: date,
    }));
  };

  /**
   * Filtra listado documentos por rango de fechas
   */
  const filterDate = () => {
    if (datesTmp.Fechini === "" || datesTmp.Fechifin === "") {
      WarningAlert("Debe seleccionar un rango de fechas válido");
    } else {
      if (new Date(datesTmp.Fechini) > new Date(datesTmp.Fechfin)) {
        WarningAlert("La fecha inicial no puede ser mayor a la final");
      } else {
        setFilterData({
          ...filterData,
          Fechadesde: datesTmp.Fechini,
          Fechahasta: datesTmp.Fechfin,
          Skip: 0,
          LoadMore: false,
        });

        handleCloseModalDates();
        setTxtFilter(txtFilterConfirmed);
      }
    }
  };

  /**
   * Limpia Filtro fechas, consulta listado con valores por defecto
   */
  const cleanFilterDate = () => {
    setDatesTmp((prevState) => ({
      ...prevState,
      Fechini: format(new Date(), "yyyy/MM/dd"),
      Fechfin: format(new Date(), "yyyy/MM/dd"),
    }));

    setFilterData({
      ...filterData,
      Fechadesde: null,
      Fechahasta: null,
      Skip: 0,
      LoadMore: false,
    });

    handleCloseModalDates();
    setTxtFilter(txtFilterConfirmed);
  };

  /**
   * Limpiar email
   */
  const cleanMail = () => {
    handleCloseModalMail();
    setMailData({});
    setEmail("");
  };

  useEffect(() => {
    cleanMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMailResponse]);

  /**
   * Abre notificción reenvio de documento
   * @param {*} email
   * @param {*} isInvoiceMail
   * @param {*} tipodocumentoentidad
   * @param {*} documentompresa
   * @param {*} codigounico
   * @param {*} numero
   * @param {*} razonsocialempresa
   * @param {*} razonsocialcliente
   */
  const openMailModal = (
    email,
    tipodocumentoentidad,
    documentompresa,
    numero,
    razonsocialempresa,
    razonsocialcliente,
    documentoid
  ) => {
    setEmail(email);
    setMailData({
      TipoDocumento: tipodocumentoentidad,
      Documento: documentompresa,
      NumeroDocumento: numero,
      RazonSocialEmpresa: razonsocialempresa,
      RazonSocialCliente: razonsocialcliente,
      TipoModalidad: 0,
      DocumentoId: documentoid,
    });
    setOpenModalMail(true);
  };

  /**
   * Cierra modal email
   */
  const openFileModal = () => {
    setOpenModalFile(true);
  };

  /**
   * Evento, actualiza estado destacado desde listado de documentyos
   * @param {*} data Informacion documento
   */
  const handleFavourite = (data) => {
    let newData = {
      Id: data.id,
      Operacion: "FAVORITO",
      Favorito: !data.favorito,
    };

    props.updateDocumentFromListAction(newData, invoices, documentIdSelected);
  };

  /**
   * Evento, seleccion documento
   * @param {*} id Identificador documento
   */
  const handleIdChange = (id) => {
    setDocumentSelected(id);
  };

  //Enevto click nueva o editar Factura
  const openCreate = () => {
    props.getAdditionalDataAction();
    HandleCreate();
  };

  /**
   * Carga  siguiente lote de documentos
   */
  const loadMoreDocuments = () => {
    setFilterData({
      ...filterData,
      Skip: invoices.length,
      LoadMore: true,
    });
    //setTxtFilter(txtFilterConfirmed);
  };

  /**
   * Actualiza estado  de un documento
   * @param {*} data Información documento
   */
  const updateDocumentStatus = (data) => {
    props.updateDocumentAction(data, invoices, selectDefaultItem);
  };

  /**
   * Arma objeto para edición de documento
   * @param {*} invoice Informacion documento
   * @param {*} type tipo documento
   */
  const openEdit = (invoice, type) => {
    dispatch(cleanCartSupportAction());
    dispatch(cleanDocumentSupportAction());
    if (type === 1) {
      HandleEdit(invoice);
    } else {
      HandleBill(invoice);
    }
  };

  /**
   * Panel, Genera listado de cotizaciones
   */
  const cardsFacturas = () => {
    const cards = invoices?.map((item) => (
      <div
        key={item.id}
        className={
          documentIdSelected === item.id
            ? "cardDetailInvoiceSelected"
            : "cardDetailInvoice"
        }
      >
        <Grid container>
          <Grid
            item
            lg={9}
            sm={12}
            className="nameCostumer"
            onClick={() => handleIdChange(item.id)}
          >
            {item.razonsocialcliente}
          </Grid>
          <Grid item lg={3} sm={12} className={classes.alignRight}>
            {loadingFavouriteFromList === item.id ? (
              <div className={classes.containerProgress}>
                <CustomProgress size={20} aling={"end"} />
              </div>
            ) : (
              <IconButton
                className={classes.iconButton}
                onClick={() => handleFavourite(item)}
              >
                {item.favorito ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
            )}
          </Grid>
        </Grid>

        <Grid container onClick={() => handleIdChange(item.id)}>
          <Grid item lg={6} md={6} sm={12} className="dataInvoiceGrid">
            {item?.fecha !== null && item?.fecha !== undefined
              ? formatDate(new Date(item.fecha), "dd/MM/yyyy hh:mm aaaa")
              : "-"}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            className={
              documentIdSelected === item.id
                ? "totalInvoiceGridSelected"
                : item.estadocotizacionid === "05"
                ? "totalInvoiceGridExpired"
                : "totalInvoiceGridPaidout"
            }
          >
            <FormatDecimal value={item.valortotal} prefix={"$"} size="small" />
          </Grid>
        </Grid>

        <Grid container onClick={() => handleIdChange(item.id)}>
          <Grid item lg={6} sm={12} className="dataNumberInvoice">
            {"Cotización: " + item.numero}
          </Grid>
          <Grid
            item
            lg={6}
            sm={12}
            className={
              documentIdSelected === item.id
                ? "stateInvoiceSelected"
                : item.estadocotizacionid === "05"
                ? "stateInvoiceExpired"
                : "stateInvoicePaidout"
            }
          >
            {item.estadocot}
          </Grid>
        </Grid>
      </div>
    ));
    return cards;
  };

  /**
   * Panel, Modal reenvio  documento
   */
  const modalMails = () => {
    return (
      <ModalFileForm
        email={email}
        isInvoiceMail={false}
        isQuotationMail={true}
        data={mailData}
        type={"CO"}
      />
    );
  };

  /**
   * Panel, Modal filtro por estados
   */
  const modalFilters = () => {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Grid item lg={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={filterData.Vencidas}
            onChange={handlecheckFilters}
            name="chkVencidas"
          />
        </Grid>
        <Grid item lg={9} sm={9}>
          Vencidas
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            onClick={handlecheckFilters}
            checked={filterData.Destacadas}
            onChange={handlecheckFilters}
            name="chkDestacadas"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Destacadas
        </Grid>
        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <Link
            href="#"
            onClick={cleanFilter}
            variant="inherit"
            underline="none"
            className={classes.link}
          >
            Limpiar Filtro
          </Link>
        </Grid>
      </Grid>
    );
  };

  /**
   * Panel selección filtro rango fechas
   */
  const modalDates = () => {
    return (
      <Fragment>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={datesTmp.Fechini}
              className={"colorCalendarRC"}
              id={"txtfechini"}
              onChange={handleDateInitial}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={datesTmp.Fechfin}
              className={"colorCalendarRC"}
              id={"txtfechfin"}
              onChange={handleDateFinal}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={filterDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div className={classes.letter}>
      <div className="createInvoice" onClick={openCreate}>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} className={classes.alignRight}>
            Crear nueva cotización
          </Grid>
          <Grid item lg={6} md={6} sm={12} className={classes.alignLeft}>
            &nbsp;&nbsp;&nbsp;<AddCircleRoundedIcon></AddCircleRoundedIcon>
          </Grid>
        </Grid>
      </div>

      <div className="spacingInvoice" />

      <Grid container spacing={1}>
        <Grid item lg={5} md={6} sm={12}>
          <Grid container>
            <Grid item lg={10} xs={12}>
              <InputIcon
                name={"txtFilter"}
                label={"Buscar"}
                value={txtFilter}
                onChange={(e) => syncChangesFilter(e)}
                onSearch={onSearch}
                maxLength={100}
                disabled={loadingGetdocuments === true}
              />
            </Grid>
            <Grid item lg={2} sm={12}>
              <div style={{ display: "flex" }}>
                <Tooltip title="Filtrar por fechas">
                  <IconButton
                    onClick={handleOpenModalDates}
                    disabled={loadingGetdocuments === true}
                  >
                    <EventIcon className={classes.buttonIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Filtrar por estado">
                  <IconButton
                    className={classes.buttonFilterStatus}
                    onClick={handleOpenModalChecks}
                    disabled={loadingGetdocuments === true}
                  >
                    <img
                      alt={"Filtrar"}
                      src={filterImage}
                      style={{ marginTop: "60%" }}
                    ></img>
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>

          <div className="spacingInvoice" />

          <div style={{ overflow: "auto", height: "73.0vh" }}>
            {loadingGetdocuments === true && invoices?.length === 0 ? (
              <CustomProgress />
            ) : (
              cardsFacturas()
            )}

            {invoices?.length > 0 && invoices?.length < totaldocuments ? (
              <Grid container justify="center" alignItems="center">
                <Grid item lg={7} xs={9}>
                  <ButtonPrimary
                    onClick={loadMoreDocuments}
                    text={"Mostrar más..."}
                    loading={loadingGetdocuments === true}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            {invoices.length === 0 && loadingGetdocuments !== true ? (
              <div>No se encontraron cotizaciones</div>
            ) : null}
          </div>
        </Grid>
        <Grid item lg={7} md={6} sm={12}>
          <QuotationDetails
            heightDiv={"2%"}
            vista="Grid"
            OpenMailModal={openMailModal}
            OpenFileModal={openFileModal}
            OpenEdit={openEdit}
            updateDocumentStatus={updateDocumentStatus}
          ></QuotationDetails>
        </Grid>
      </Grid>
      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
      <PopoverModal
        handleClose={handleCloseModalDates}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={""}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />
      <SimpleModal
        onClose={handleCloseModalMail}
        open={openModalMail}
        component={modalMails}
        title={"Enviar Cotización"}
        width={365}
        showCloseButton={false}
      />

      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleResponseModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoices: state.invoiceReducer.invoices,
  sucessupdate: state.invoiceReducer.sucess,
  document: state.invoiceReducer.document,
  sendMailResponse: state.invoiceReducer.sendmail,
  loadingGetdocuments: state.invoiceReducer.loadingGetdocuments,
  totaldocuments: state.invoiceReducer.totaldocuments,
  loadingFavouriteFromList: state.invoiceReducer.loadingFavouriteFromList,
});

const mapDispatchToProps = {
  updateDocument,
  getTotalDocs,
  setStatusShowCartAction,
  addChargeToInvoiceAction,
  openModalChargeAction,
  addDiscountToInvoiceAction,
  calculeTotalNoteAction,
  getQuotationsAction,
  getDocumentDetailAction,
  updateDocumentAction,
  updateDocumentFromListAction,
  getAdditionalDataAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuotationGrid);
