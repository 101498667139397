import isEmpty from "../utils/isEmpty";

/**
 * @function DEVUELVE DESCRIPCIÓN DE TIPO DOCUMENTO IDENTIFICACIÓN
 * @param {*} value Identificador tipo documento identidad
 */
const documentDescription = (value) => {
	if (value === null || value === undefined || isEmpty(value)) return "";

	switch (value) {
		case 1:
			return "RC.";
		case 2:
			return "TI.";
		case 3:
			return "CC.";
		case 4:
			return "TE.";
		case 5:
			return "CE.";
		case 6:
			return "N.I.T";
		case 7:
			return "PA.";
		case 8:
			return "DE.";
		case 10:
			return "PEP.";
		default:
			return "";
	}
};

export default documentDescription;
