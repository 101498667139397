import React from "react"
import { makeStyles, withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import NumberFormat from 'react-number-format';

import { validateNumberFormat, validateNumberPorcentaje } from "../../utils/managementNumber";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: 0
    },
    iconLock: {
        color: '#B8B8B8',
        fontSize: 18
    },
}));

const CustomInput = withStyles({
    root: {
        '& .MuiInputLabel-formControl': {
            fontSize: 12
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: '#FFFF',
        },
        '& label.Mui-focused': {
            color: '#16B1F3', //verde
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#16B1F3', //verde
        },
        '& .MuiInputBase-input': {
            color: '#FFFF',
            fontSize: 12,
            paddingTop: 0,
            paddingBottom: 5
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'gray', //gris
        },
        '& input.Mui-disabled': {
            color: 'rgba(255,255,255,0.5);'
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#16B1F3', //verde
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: 10
        }

    }
})(TextField);

function NumberFormatCustom({ inputRef, onChange, name, ...other }) {

    const handleChange = (values) => {
        const validNumber = validateNumberFormat(values.value ?? '');
        onChange({
            target: {
                name: name,
                value: validNumber
            }
        })

    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={handleChange}
            isNumericString={false}
            thousandSeparator=","
            decimalSeparator="."
            prefix="$"
            allowNegative={false}
            allowedDecimalSeparators={['.']}
        />
    );
}

const NumberFormatPorcentaje = ({ inputRef, onChange, name, ...other }) => {
    const handleChange = (values) => {
        const validNumber = validateNumberPorcentaje(values.value ?? '');

        onChange({
            target: {
                name: name,
                value: validNumber
            }
        })

    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={handleChange}
            isNumericString={false}
            thousandSeparator=","
            decimalSeparator="."
            suffix="%"
            allowNegative={false}
            allowedDecimalSeparators={['.']}
        />
    );
}

const InputSmall = ({
    onChange,
    value,
    label,
    disabled,
    validator,
    validateOptions,
    hidden,
    onKeyPress,
    onBlur,
    maxLength,
    nameValidator,
    variant,
    name,
    lockIcon,
    format,
    onClick,
    fullWidth
   }) => {

    const classes = useStyles();

    let errorValidator = validator
        ? validator.message(
            !!nameValidator ? nameValidator : label,
            value,
            validateOptions,
        )
        : "";

    if (!!hidden) return null;

    let myFormat = 'input';
    switch (format) {
        case true:
            myFormat = NumberFormatCustom;
            break;
        case 'money':
            myFormat = NumberFormatCustom;
            break;
        case 'porcentage':
            myFormat = NumberFormatPorcentaje;
            break;
        default:
            break;
    }

    return (
        <div className={classes.root} >
            <CustomInput
                fullWidth ={!!fullWidth? fullWidth : true}
                className={classes.margin}
                disabled={disabled === true ? true : false}
                label={label}
                variant={!!variant ? variant : "outlined"}
                name={name}
                value={value}
                onChange={onChange}
                error={!!errorValidator ? true : false}
                helperText={errorValidator}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                size={'small'}
                inputProps={{ maxLength: maxLength }}
                autoComplete='off'
                onClick={onClick}
                InputProps={
                    {
                        inputComponent: myFormat,
                        endAdornment:
                            lockIcon === true
                                ? <InputAdornment position="end">
                                    <LockIcon className={classes.iconLock}> </LockIcon>
                                </InputAdornment>
                                : ''
                    }
                }
            />
        </div>

    );
}


InputSmall.propTypes = {

}

export default InputSmall
