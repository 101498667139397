import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import RcCalendar from "../../components/datepicker/rcdatepicker.component";
import { format } from "date-fns";
import isEmpty from "../../utils/isEmpty";
import { WarningAlert } from "../../helpers/alert.helpers";

const useStyles = makeStyles((theme) => ({
	alignCenter: {
		textAlign: "center",
	},
}));

export const FilterDate = ({
	handleConfirmDate,
	handleCleanFilter,
	dateFrom,
	dateTo,
}) => {
	const classes = useStyles();
	const [form, setForm] = useState({
		dateFrom: format(new Date(), "yyyy/MM/dd"),
		dateTo: format(new Date(), "yyyy/MM/dd"),
	});

	useEffect(() => {
		setForm((prev) => ({
			dateFrom: dateFrom ?? prev.dateFrom,
			dateTo: dateTo ?? prev.dateTo,
		}));
	}, [dateFrom, dateTo]);

	/**
	 * Sincroniza nuevo valor fecha desde con state
	 */
	const handleChangeDateFrom = (date) => {
		setForm((prev) => ({ ...prev, dateFrom: date }));
	};

	/**
	 * Sincroniza nuevo valor fecha hasta con state
	 */
	const handleChangeDateTo = (date) => {
		setForm((prev) => ({ ...prev, dateTo: date }));
	};

	/**
	 * Valida y confirma rango sde fechas seleccionado
	 */
	const validateRangeDates = () => {
		const { dateFrom, dateTo } = form;

		if (isEmpty(dateFrom) || isEmpty(dateTo)) {
			WarningAlert("Debes seleccionar un rango de fechas válido");
			return;
		}

		if (new Date(dateFrom) > new Date(dateTo)) {
			WarningAlert("La fecha inicial no puede ser mayor a la final");
			return;
		}

		handleConfirmDate(dateFrom, dateTo);
	};

	/**
	 * Limpia fechas seleccionadas en filtro
	 */
	const cleanRangeDates = () => {
		setForm({
			dateFrom: format(new Date(), "yyyy/MM/dd"),
			dateTo: format(new Date(), "yyyy/MM/dd"),
		});
		handleCleanFilter();
	};

	return (
		<>
			<Grid container>
				<Grid item lg={5} sm={5} className={classes.alignCenter}>
					Desde
				</Grid>
				<Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
				<Grid item lg={6} sm={6} className={classes.alignCenter}>
					Hasta
				</Grid>
			</Grid>

			<Grid container alignItems="center">
				<Grid item lg={6} sm={6} className={classes.alignCenter}>
					<div className="lineDivision"></div>
					<div className="spacingInvoiceMini"></div>
				</Grid>
				<Grid item lg={6} sm={6} className={classes.alignCenter}>
					<div className="lineDivision"></div>
					<div className="spacingInvoiceMini"></div>
				</Grid>
			</Grid>

			<Grid container justify="center" alignItems="center">
				<Grid item lg={5} sm={5}>
					<RcCalendar
						disablePast={false}
						disableFuture={true}
						value={form.dateFrom}
						className={"colorCalendarRC"}
						id={"dateFrom"}
						onChange={handleChangeDateFrom}
					/>
				</Grid>
				<Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
				<Grid item lg={6} sm={6}>
					<RcCalendar
						disablePast={false}
						disableFuture={true}
						value={form.dateTo}
						className={"colorCalendarRC"}
						id={"dateTo"}
						onChange={handleChangeDateTo}
					/>
				</Grid>
			</Grid>

			<div className="spacingInvoice" />
			<div className="lineDivision" />
			<div className="spacingInvoice" />
			<Grid container>
				<Grid item lg={6} sm={6} className={classes.alignCenter}>
					<button className="btnFilterGray" onClick={cleanRangeDates}>
						Limpiar Filtro
					</button>
				</Grid>
				<Grid item lg={6} sm={6} className={classes.alignCenter}>
					<button className="btnFilterBlue" onClick={validateRangeDates}>
						Filtrar
					</button>
				</Grid>
			</Grid>
		</>
	);
};
