import { axiosApiInstance } from "../config/axios-instance";
import {
  API_ENDPOINT_ARTICLE,
  defaultHeaders,
  TIME_CREATE_REGISTER,
} from "../config/config";
import { SuccessAlert } from "../helpers/alert.helpers";
import { types } from "../types/types";
import { enterpriseIdHeader } from "./authActions";

const urlEndPoint = `${API_ENDPOINT_ARTICLE}/item/api/descuento`;
const urlEndPointReason = `${API_ENDPOINT_ARTICLE}/item/api/motivodescuento`;

/**
 * @action
 * @description Consulta listado descuentos por empresa
 * @param {*} data
 * @param {*} filter
 * @param {*} refresh
 */
export const getDiscountsAction = () => async (dispatch) => {
  try {
    changeGetListLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      enterpriseId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.get(
      `${urlEndPoint}/getallbyenterpriseid`,
      config
    );

    dispatch({
      type: types.GET_DISCOUNTS_LIST,
      payload: resp.data.result.map((item) => ({
        ...item,
        motivodescripcion: item.motivo?.descripcion,
      })),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar listado de descuentos.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Obtine listado motivos descuento
 * @returns
 */
export const getDiscountReasonAction = () => async (dispatch, getState) => {
  try {
    const { reasonsDiscount } = getState().newDiscountReducer;
    if (reasonsDiscount.length > 0) return;

    const response = await axiosApiInstance.get(
      `${urlEndPointReason}/getallreason`,
      defaultHeaders()
    );

    dispatch({
      type: types.GET_REASONS_DISCOUNTS,
      payload: response.data.result.map((item) => ({
        ...item,
        value: item.id,
        text: item.descripcion,
      })),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar listado razones de descuento.",
        error: err,
      },
    });
  }
};

/**
 * Guarda información nuevo descuento
 * @returns
 */
export const saveDiscountAction = (discount, reset) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);

    discount = {
      ...discount,
      empresaid: `${enterpriseIdHeader()}`,
    };

    const response = await axiosApiInstance.post(
      `${urlEndPoint}/creatediscount`,
      discount,
      defaultHeaders()
    );
    if (response.data.statusCode === "201") {
      dispatch({
        type: types.ADD_NEW_DISCOUNT,
        payload: {
          ...response.data.result,
          motivodescripcion: discount.motivodescripcion,
        },
      });
      SuccessAlert(
        null,
        `Creaste el descuento ${discount.descripcion} de manera exitosa, sigamos Facturando`,
        TIME_CREATE_REGISTER
      );
      reset();
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido registrar descuento.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Carga información de descuento para edición
 * @param {*} discount
 * @returns
 */
export const loadDiscountInFormAction = (discount) => (dispatch) => {
  dispatch({
    type: types.LOAD_DOCUMENT_DISCOUNT,
    payload: discount,
  });
};

const changeSaveLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_ADD_DISCOUNT,
    payload: status,
  });
};

const changeGetListLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_DISCOUNTS_LIST,
    payload: status,
  });
};
