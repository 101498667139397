import React from "react";

import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import { buildAddress } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: "12px",
  },
  wordBreak: {
    wordBreak: "break-word",
  },
  iconsDetail: {
    alignItems: "center",
    display: "flex",
  },
  colorIcon: {
    color: "#2CC63E",
  },
}));

export const AcquirerInformation = () => {
  const classes = useStyles();

  const { acquirerInformation } = useSelector(
    (state) => state.equivalentDocReducer
  );

  const {
    customerAddress,
    customerCity,
    customerDepartment,
    customerDocument,
    customerEmailAddress,
    customerLegalName,
    customerPhoneNumber,
    documentTypeDescription,
    personType,
    regime,
  } = acquirerInformation;

  const customerAddressString = buildAddress(
    customerAddress,
    customerCity,
    customerDepartment
  );

  return (
    <>
      <div className="constumerNameDetailSupport">{customerLegalName}</div>
      <div className="detailSupportInfo">
        {documentTypeDescription} {customerDocument}
      </div>
      <div className="detailInvoiceInfo">{personType}</div>
      <div className="detailInvoiceInfo">{regime}</div>
      <div className="spacingInvoice"></div>
      <Grid container className={classes.marginTop}>
        <Grid item xs={12} className={classes.iconsDetail}>
          <EmailIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{customerEmailAddress}</div>
        </Grid>

        {!!customerPhoneNumber && (
          <Grid item xs={12} className={classes.iconsDetail}>
            <PhoneIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={classes.wordBreak}>{customerPhoneNumber}</div>
          </Grid>
        )}

        {!!customerAddressString && (
          <Grid item xs={12} className={classes.iconsDetail}>
            <RoomIcon className={classes.colorIcon} />
            &nbsp;&nbsp;
            <div className={classes.wordBreak}>{customerAddressString}</div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
