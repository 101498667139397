import { types } from "../types/types";

const initialState = {
	documentList: [],
	documentdetail: null,
	loadingList: false,
	loadingDetail: false,
	loadingStandOut: false,
	loadingSendEmail: false,
};

export const documentIntegrationReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.FILL_INTEGRATION_DOCUMENT:
			return {
				...state,
				documentList: [...action.payload],
			};
		case types.LOADING_INTEGRATION_DOCUMENTS:
			return {
				...state,
				loadingList: action.payload,
			};
		case types.FILL_INTEGRATION_DETAIL:
			return {
				...state,
				documentdetail: action.payload,
			};
		case types.LOADING_GET_INTEGRATION_DETAIL:
			return {
				...state,
				loadingDetail: action.payload,
			};
		case types.LOADING_STAND_OUT_INTEGRATION:
			return {
				...state,
				loadingStandOut: action.payload,
			};
		case types.UPDATE_STAND_OUT_INTEGRATION:
			return {
				...state,
				documentList: state.documentList.map((item) =>
					item.id === action.payload
						? { ...item, favorito: !item.favorito }
						: item
				),
				documentdetail:
					state.documentdetail.Id === action.payload
						? {
								...state.documentdetail,
								Favorito: !state.documentdetail.Favorito,
						  }
						: { ...state.documentdetail },
			};
		case types.LOADING_INTEGRATION_DOCUMENT_LIST:
			return {
				...state,
				documentList: state.documentList.map((item) =>
					item.id === action.payload.id
						? { ...item, loading: action.payload.status }
						: item
				),
			};
		case types.LOADING_SEND_MAIL_INTEGRATION:
			return {
				...state,
				loadingSendEmail: action.payload,
			};
		case types.CLEAN_REDUX_INTEGRATION:
			return {
				...initialState,
			};

		default:
			return state;
	}
};
