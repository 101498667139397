import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { FormatDecimal } from '../../../components/common/formatDecimal';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.11)',
        color: theme.palette.primaryColor,
        fontSize: 16,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: -5,
            paddingLeft: 55
        }
    },
    header: {
        paddingBottom: 0,
        paddingTop: 5,
        marginLeft: -5,
        '& .MuiCardHeader-action': {
            display: 'flex'
        },
        '& .MuiCardHeader-root': {
            padding: 10
        }
    },
    content: {
        fontSize: '0.9rem',
    },
    title: {
        color: theme.palette.primaryColor,
        fontSize: '0.9rem',
        fontWeight: 600
    },
    icon: {
        color: theme.palette.secundaryColor
    }
}));

export const DocumentDiscountCard = ({ data }) => {
    const classes = useStyles();


    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={
                    data.modelodescuento === 1 ?
                    <LocalOfferIcon className={classes.icon} />:
                    <AccountBalanceIcon className={classes.icon} /> 
                }
                classes={{
                    title: classes.title,
                }}
                title={
                    data.modelodescuento === 1
                        ? 'Descuento Comercial'
                        : 'Descuento Financiero'
                }
            />
            <CardContent className={classes.content}>
                <div style={{ display: 'grid' }}>
                    <span>
                        {
                            data.tipodescuento === 1
                                ? <span style={{ color: "#F5D13D" }}>
                                    <FormatDecimal
                                        value={data.valor}
                                        prefix={"$"}
                                    />
                                </span>
                                : <FormatDecimal
                                    value={data.valor}
                                    suffix={"%"}
                                />
                        }
                        {data.tipodescuento === 2
                            && <span style={{ color: "#F5D13D" }}>
                                <FormatDecimal
                                    value={data.valoradescontar}
                                    prefix={"($"}
                                    suffix={")"}
                                />
                            </span>
                        }
                    </span>

                    <span>
                        {`Motivo: ${!data?.motivo  || typeof data?.motivo === 'object'
                            ? data.motivodescripcion
                            : data.motivo}`}
                    </span>

                    <span>{data.descripcion}</span>
                </div>
            </CardContent>
        </Card>
    )
}




