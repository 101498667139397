import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import clsx from "clsx";

import {
  makeStyles,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";

import DocumentInformation from "./DocumentInformation";
import FootPage from "./FootPage";
import { DocumentCartManage } from "../../document/cart/documentCartManage.view";
import ButtonsCart from "../serviceDetails/ButtonsCart";
import ReviewDocument from "../ReviewDocument/ReviewDocument";
import ButtonSendDocument from "../ReviewDocument/ButtonSendDocument";
import { generateRequestSupport } from "../../../helpers/generateRequestDocument";
import { validateDocument } from "../../../helpers/documentValidate";
import ArchiveModal from "../../../components/modal/archiveModal.component";
import ResponseModal from "../../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../../components/modal/responseRedirectModal.component";

import {
  changeShowCartAction,
  showCartDocumentSupportAction,
} from "../../../actions/documentCartAction";
import { warningToast } from "../../../helpers/toast.helpers";
import {
  cleanDocumentSupportAction,
  getDocumentSupportPreviewAction,
} from "../../../actions/documentSupportAction";
import { cleanDocumentCreateAction } from "../../../actions/documentAction";
import CustomProgress from "../../../components/Progress/progress.component";
import { CLAIM_DOCUMENTS } from "../../../config/config";
import { updateOpenModalItem } from "../../../actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

function getSteps() {
  return [
    "Información del Documento",
    "Detalles del Servicio",
    "Revisar Documento",
  ];
}

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

const CreateDocumentSupportView = ({ handleCreate }) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [loading, setLoading] = useState(false);

  const { documentData, favorite } = useSelector(
    (state) => state.documentSupportReducer
  );

  const [loadingFile, setLoadingFile] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const totals = useSelector((state) => state.documentCartReducer);
  const { openModalItem: modal } = useSelector((state) => state.invoiceReducer);

  const { showCatalog, opdetalledocumento } = totals;
  const classes = useStyles();
  const steps = getSteps();
  const [modalResponse, setModalResponse] = useState({
    modalType: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });

  useEffect(() => {
    if (loading) {
      setModalResponse({
        type: "success",
        title: "",
        subtitle: "Generando documento soporte, por favor espere...",
        body: (
          <div>
            <div>
              <>
                {" "}
                <CustomProgress />
                {"Procesando"}{" "}
              </>
            </div>
          </div>
        ),
        modalImage: "noimagen",
        open: true,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    }
  }, [loading]);

  /**
   * * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response);
    setLoadingFile(false);
  };

  //  * Genera previsualización de documento
  //  * @param {*} e
  //  */
  const handlePreviewDocument = (e) => {
    e.preventDefault();
    if (validateDocument(totals, totals?.cartDocumentSupport)) {
      setOpenModalFile(true);
      setLoadingFile(true);
      let document = generateRequestSupport(totals, documentData, favorite);
      dispatch(getDocumentSupportPreviewAction(document, readResponseGetFile));
    }
  };

  /**
   * *Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    dispatch(updateOpenModalItem(false));
    handleCreate({
      showGrid: true,
      showCreate: false,
    });
    dispatch(cleanDocumentSupportAction());
    dispatch(cleanDocumentCreateAction());
  };

  /**
   * * Cierra modal previsualizació documento
   */
  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
  };

  /**
   * *Lee respuesta de envio documento
   * @param {*} response
   */
  const readResponseSendDocumentSupport = (response) => {
    if (["201", "200"].includes(response.statusCode)) {
      setLoading(false);
      setModalResponse({
        type: "success",
        title: "Fenomenal!!!",
        subtitle: "Has generado un nuevo Documento Soporte",
        body: (
          <div>
            <div>
              {response.statusMessage.indexOf("Rechazo") !== -1
                ? "Documento Soporte generado con error al enviar a Dian"
                : "Proceso Exitoso"}
            </div>
            <div className="fenomenalWhiteLittle">
              {response.statusMessage}{" "}
              <span className="fenomenalBlueLittle">No. {response.result}</span>
            </div>
          </div>
        ),
        modalImage: "success",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    } else {
      setLoading(false);
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>{"Documento Soporte  con error al enviar a Dian"}</div>
            <div className="fenomenalWhiteLittle">
              {response.statusMessage} Documento Soporte Generado:{" "}
              <span className="fenomenalBlueLittle">No. {response.result}</span>
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: false,
      });
    }
  };

  /**
   * *Lee error envio documento
   * @param {*} response
   */
  const readErrorSendInvoice = (response) => {
    setLoading(false);
    const statusCode = response?.data?.statusCode;
    const statusMessage = response?.data?.statusMessage;
    if (
      statusCode === "404" &&
      statusMessage === "Unauthorized, no quota" &&
      !response?.data?.result?.licenseActiveBase &&
      !response?.data?.result?.exist
    ) {
      setModalResponse(basePlanExpired);
    } else if (
      response?.data?.result?.licenseActiveBase &&
      !response?.data?.result?.exist
    ) {
      setModalResponse({
        modalType: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
      });
      dispatch(updateOpenModalItem(true));
    } else {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>
              {JSON.stringify(
                response === undefined
                  ? "Error inesperado"
                  : response?.data?.statusMessage
              )}
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 0,
        closeFromModalBody: true,
      });
    }
  };

  /**
   * * Cierra modal estado  valdacion cargue masivo
   */
  const handleCloseResponseModal = () => {
    setModalResponse({
      modalType: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
    });

    handleCreate({
      showGrid: true,
      showCreate: false,
    });
    dispatch(cleanDocumentSupportAction());
    dispatch(cleanDocumentCreateAction());
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {" "}
        {completed ? (
          <Check className={classes.completed} />
        ) : (
          <div className={classes.circle} />
        )}{" "}
      </div>
    );
  }

  /**
   * *Avanza paso siguiente
   */
  const handleNextStep = (step) => {
    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < step; i++) {
      newCompleted.add(i);
    }
    if (opdetalledocumento.length === 0 && step === 2) {
      warningToast(
        "No se puede continuar.",
        "Por favor agregue al menos un Servicio a la factura."
      );
      return;
    }
    setCompleted(newCompleted);
    setActiveStep(step);
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  /**
   * *Marca paso a completo
   * *@param {*} step
   * *@returns
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  /**
   * *Activa y desctiva el carrito  de documento soporte
   */
  useEffect(() => {
    if (activeStep === 1) {
      dispatch(changeShowCartAction(false));
      dispatch(showCartDocumentSupportAction(true));
      return;
    }
    dispatch(changeShowCartAction(true));
    dispatch(showCartDocumentSupportAction(false));
  }, [activeStep, dispatch]);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <DocumentInformation handleChangeStep={handleNextStep} />
            <div className="spacingInvoice" />
            {FootPage(
              0,
              setActiveStep,
              handleCreate,
              handleBackStep,
              handlePreviewDocument
            )}
          </>
        );

      case 1:
        return (
          <>
            <DocumentCartManage typedocument={"DS"} />
            {!showCatalog && (
              <ButtonsCart
                handleChangeStep={handleNextStep}
                readResponseSendDocumentSupport={
                  readResponseSendDocumentSupport
                }
                readErrorSendInvoice={readErrorSendInvoice}
                setActiveStep={setActiveStep}
                setLoading={setLoading}
              />
            )}
            <div className="spacingInvoice" />

            {FootPage(
              1,
              setActiveStep,
              handleCreate,
              handleBackStep,
              handlePreviewDocument
            )}
          </>
        );
      case 2:
        return (
          <>
            <ReviewDocument />
            <ButtonSendDocument
              readResponseSendDocumentSupport={readResponseSendDocumentSupport}
              readErrorSendInvoice={readErrorSendInvoice}
              setActiveStep={setActiveStep}
              setLoading={setLoading}
            />
            {FootPage(
              1,
              setActiveStep,
              handleCreate,
              handleBackStep,
              handlePreviewDocument
            )}
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className="container-form">
      <Stepper
        alternativeLabel
        nonLinear
        connector={<QontoConnector />}
        activeStep={activeStep}
        className={classes.root}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              className={activeStep === index ? classes.activeButton : ""}
            >
              <StepButton completed={isStepComplete(index)} {...buttonProps}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className="stepper-title">{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        textButton={modalResponse?.textButton}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={
          "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
        }
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ArchiveModal
        loading={loadingFile}
        open={openModalFile}
        pdfBase64={base64File}
        handleClose={handleCloseFileModal}
      />
    </div>
  );
};

export default CreateDocumentSupportView;
