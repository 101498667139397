import React, { useRef, useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import SelectForm from "../../components/select/select.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
    color: "white",
    textAlign: "justify",
    padding: "0px 30px 25px 30px !important",
  },
  title: {
    color: "#16B1F3",
    fontSize: 25,
    fontWeight: 600,
  },
  subtitle: {
    color: "#16B1F3",
    fontSize: 18,
  },
  containerTitle: {
    textAlign: "center",
    paddingBottom: "20px !important",
    paddingTop: "20px !important",
  },
}));

const InvoiceTypeNote = (props) => {
  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
  const [, forceUpdate] = useState();
  const { onClick, loadedType } = props;
  const [typeNote, setTypeNote] = useState("none");
  const [menuInvoice, setMenuInvoice] = useState({});

  useEffect(() => {
    setMenuInvoice(props.menuReducer.menuInvoice);
  }, [props.menuReducer.menuInvoice]);

  useEffect(() => {
    if (loadedType !== null && loadedType !== undefined)
      setTypeNote(loadedType);
  }, [loadedType]);

  /**
   * Sincroniza valor select con state
   * @param {*} e
   */
  const syncChanges = (e) => {
    setTypeNote(e.target.value);
  };

  /**
   * Valida formulario, continua proceso edición
   */
  const handleClickContinue = () => {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    } else {
      onClick(typeNote);
    }
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} xs={12} className={classes.containerTitle}>
        <span className={classes.title}>Editar factura</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div
          className={classes.subTitle}
          dangerouslySetInnerHTML={{
            __html: menuInvoice?.messageModalEditInvoice ?? "",
          }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <span className={classes.subtitle}>Nota débito: </span>
        <span> {menuInvoice?.descripcionNotaDebito ?? ""}</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <span className={classes.subtitle}>Nota Crédito: </span>
        <span> {menuInvoice?.descripcionNotaCredito ?? ""}</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <span className={classes.subtitle}>Anulación: </span>
        <span>{menuInvoice?.descripcionAnulacion ?? ""}</span>
      </Grid>

      {/* Se realiza ocultamiento del botón para Anulación de Factura Previamente Aceptada
      según Task: 55189 a petición de producto */}

      {/* <Grid item lg={12} xs={12}>
        <span className={classes.subtitle}>
          Anulación de Factura Previamente Aceptada :{" "}
        </span>
        <span>
          {menuInvoice?.descripcionAnulacionPreviamenteAceptada ?? ""}
        </span>
      </Grid> */}

      <Grid item lg={12} xs={12}>
        <span>¿Qué deseas hacer?</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems="center" justify="center">
          <Grid item lg={6} xs={6}>
            <SelectForm
              label={""}
              name="ddlTypeNote"
              value={typeNote}
              options={[
                {
                  value: "ND",
                  text: "Nota Débito",
                },
                {
                  value: "NC",
                  text: "Nota Crédito",
                },
                {
                  value: "ANU",
                  text: "Anulación",
                },
                /* Se realiza ocultamiento del botón para Anulación de Factura Previamente Aceptada
                según Task: 55189 a petición de producto */
                /* {
                  value: "ANU_ACEP",
                  text: "Anulación de Factura Previamente Aceptada",
                }, */
              ]}
              validator={validator.current}
              validateOptions={"required|notNone"}
              nameValidator={"tipoRegimen"}
              onChange={syncChanges}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems="center" justify="center">
          <Grid item lg={6} xs={6}>
            <ButtonPrimary text="Continuar" onClick={handleClickContinue} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {})(InvoiceTypeNote);
