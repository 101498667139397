import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { READING_MODE } from "../../../../config/config";

import {
  changeEquivalentDocComponentAction,
  getEquivalentDocDetailAction,
} from "../../../../actions/equivalentDocAction";

import { ButtonCreateDoc } from "../../../../components/button/ButtonCreateDoc";

import {
  EquivalentDocumentInformationSection,
  ErrorPage,
} from "./detailComponents";

import CustomProgress from "../../../../components/Progress/progress.component";

export const DetailEquivalentDoc = () => {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.errorReducer);

  const errorDoc = error && error?.name === "Error Equivalente";

  const {
    equivalentDocDetail,
    equivalentDocument,
    loadingGetEquivalentDocDetail,
    selectedDocumentID,
  } = useSelector((state) => state.equivalentDocReducer);

  useEffect(() => {
    if (selectedDocumentID)
      dispatch(getEquivalentDocDetailAction(selectedDocumentID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentID]);

  /**
   * * Función que maneja el evento de creación de un documento equivalente a nivl componente.
   */
  const handleCreate = () => {
    dispatch(changeEquivalentDocComponentAction("showCreate"));
  };

  return (
    <div className="spacingDetail">
      <div className="spacingInvoice" />
      <ButtonCreateDoc
        isReadingMode={READING_MODE}
        onClick={!READING_MODE ? handleCreate : undefined}
        title={"Crear nuevo documento equivalente"}
      />
      <div className="spacingInvoice" />
      {/* Fin Seccion 1 */}

      {errorDoc ? (
        <ErrorPage />
      ) : loadingGetEquivalentDocDetail || equivalentDocDetail === null ? (
        selectedDocumentID && <CustomProgress />
      ) : (
        <EquivalentDocumentInformationSection
          equivalentDocument={equivalentDocument}
        />
      )}
    </div>
  );
};
