export const types = {
  showError: "[Error] Show error in app",
  hideErrors: "[Error] Hide alerts to errors",

  //Article
  getUnitMeasure: "getUnitMeasure",
  getArticle: "getArticle",
  loadArticle: "loadArticle",
  loadArticlesInCar: "loadArticlesInCar",
  closeCollapseArticle: "closeCollapseArticle",
  sendIndexTab: "sendIndexTab",
  getFilterArticle: "getFilterArticle",
  getCategoryArticle: "getCategoryArticle",
  cleanArticle: "cleanArticle",
  endSearchArticle: "endSearchArticle",
  cleanRequest: "cleanRequest",
  loadingGetArticles: "loadingGetArticles",
  getArticleList: "getArticleList",
  getArticleChecked: "getArticleChecked",
  loadingUpdateArticle: "loadingUpdateArticle",
  loadingSaveArticle: "loadingSaveArticle",
  updateFavoriteArticle: "updateFavoriteArticle",
  saveAddArticle: "saveAddArticle",
  refreshAfterSAveArticle: "refreshAfterSAveArticle",

  //Category

  getCategory: "getCategory",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  LOADING: "LOADING",
  SET_INDEX_SELECTED: "SET_INDEX_SELECTED",
  CERTIFICATE_CONSULTED: "Indicates if the certificate has been consulted",

  //Begin users
  SAVE_BILLER: "SAVE_BILLER",
  INFO_BILLER: "INFO_BILLER",
  INFO_LICENSE_BILLER: "INFO_LICENSE_BILLER",
  UPDATE_BILLER: "UPDATE_BILLER",
  UPDATE_STATUS_BILLER: "UPDATE_STATUS_BILLER",
  GET_BILLERS: "GET_BILLERS",
  LOAD_BILLER: "LOAD_BILLER",
  LOADING_GET_BILLERS: "LOADING_GET_BILLERS",
  LOADING_SAVE_BILLER: "LOADING_SAVE_BILLER",
  SAVE_SELLER: "SAVE_SELLER",
  UPDATE_SELLER: "UPDATE_SELLER",
  UPDATE_STATUS_SELLER: "UPDATE_STATUS_SELLER",
  GET_SELLERS: "GET_SELLERS",
  LOAD_SELLER: "LOAD_SELLER",
  LOADING_GET_SELLERS: "LOADING_GET_SELLERS",
  LOADING_SAVE_SELLER: "LOADING_SAVE_SELLER",

  //Begin offices
  GET_BRANCH_OFFICES: "GET_BRANCH_OFFICES",
  GET_OFFICES: "GET_OFFICES",
  GET_COMPANY: "GET_COMPANY",
  LOAD_BRANCH_OFFICE: "LOAD_BRANCH_OFFICE",
  SAVE_BRANCH_OFFICE: "SAVE_BRANCH_OFFICE",
  INFO_SAVE_BRANCH_OFFICES: "INFO_SAVE_BRANCH_OFFICES",
  INFO_LICENSE_BRANCH_OFFICES: "INFO_LICENSE_BRANCH_OFFICES",
  UPDATE_BRANCH_OFFICE: "UPDATE_BRANCH_OFFICE",
  UPDATE_STATE_BRANCH_OFFICE: "UPDATE_STATE_BRANCH_OFFICE",
  GET_FILTER_BRANCH_OFFICES: "GET_FILTER_BRANCH_OFFICES",
  STATUS_EDIT_BRANCH_OFFICE: "STATUS_EDIT_BRANCH_OFFICE",
  STATUS_STEP_BRANCH_OFFICE: "STATUS_STEP_BRANCH_OFFICE",
  UPDATE_PRINCIPAL_BRANCH: "UPDATE_PRINCIPAL_BRANCH",
  LOADING_SAVE_BRANCH: "LOADING_SAVE_BRANCH",
  LOADING_GET_BRANCH_OFFICES: "LOADING_GET_BRANCH_OFFICES",
  UPDATE_INITIAL_DATA: "UPDATE_INITIAL_DATA",
  UPDATE_DIAN_DATA: "UPDATE_DIAN_DATA",

  //Begin numeration
  GET_NUMERATION: "GET_NUMERATION",
  GET_NUMERATIONS: "GET_NUMERATIONS",
  SAVE_NUMERATION: "SAVE_NUMERATION",
  UPDATE_NUMERATION: "UPDATE_NUMERATION",
  LOAD_NUMERATION: "LOAD_NUMERATION",
  STATUS_STEP_NUMERATION: "STATUS_STEP_NUMERATION",
  GET_FILTER_NUMERATIONS: "GET_FILTER_NUMERATIONS",
  SYNC_GET_NUMERATION: "SYNC_GET_NUMERATION",
  UPDATE_NUMERATION_STATE: "UPDATE_NUMERATION_STATE",
  START_SYNC_NUMERATION: "START_SYNC_NUMERATION",
  STATUS_SYNC_NUMERATION: "STATUS_SYNC_NUMERATION",
  LOADING_UPDATE_NUMERATION: "LOADING_UPDATE_NUMERATION",
  LOADING_SAVE_NUMERATION: "LOADING_SAVE_NUMERATION",
  LOADING_GET_NUMERATIONS: "LOADING_GET_NUMERATIONS",
  GET_DEVICES: "GET_DEVICES",

  //Begin basicData
  SAVE_BASIC_DATA: "SAVE_BASIC_DATA",
  GET_TYPE_DOCUMENT: "GET_TYPE_DOCUMENT",
  GET_REGIMEN: "GET_REGIMEN",
  STATUS_STEP_BASIC_DATA: "STATUS_STEP_BASIC_DATA",
  LOADING_SAVE_BASIC_DATA: "LOADING_SAVE_BASIC_DATA",
  LOADING_GET_BASIC_DATA: "LOADING_GET_BASIC_DATA",
  LOADING_SAVE_DIAN_DATA: "LOADING_SAVE_DIAN_DATA",
  LIST_CUSTOM_FIELDS: "LIST_CUSTOM_FIELDS",
  LOADING_SAVE_CUSTOM_FIELD: "LOADING_SAVE_CUSTOM_FIELD",
  SAVE_CUSTOM_FIELD: "SAVE_CUSTOM_FIELD",
  LOADING_DELETE_CUSTOM_FIELD: "LOADING_DELETE_CUSTOM_FIELD",
  LOADING_GET_CUSTOM_FIELD: "LOADING_GET_CUSTOM_FIELD",
  GET_DOCUMENT_TYPE_INFO: "GET_DOCUMENT_TYPE_INFO",

  //Begin Template
  GET_TEMPLATE: "GET_TEMPLATE",
  SAVE_TEMPLATE: "SAVE_TEMPLATE",
  STATUS_STEP_TEMPLATE: "STATUS_STEP_TEMPLATE",
  GET_DEPARTMENTS: "GET_DEPARTMENTS",
  GET_MODALITY_TYPES: "GET_MODALITY_TYPES",
  GET_TOWNS: "GET_TOWNS",
  LOADING_SAVE_TEMPLATE: "LOADING_SAVE_TEMPLATE",
  LOADING_GET_TEMPLATE: "LOADING_GET_TEMPLATE",

  //Begin Config
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_STATES: "GET_STATES",
  GET_CITIES: "GET_CITIES",
  GET_CITIES_STATE: "GET_CITIES_STATE",
  GET_DOCUMENT_TYPES: "GET_DOCUMENT_TYPES",
  GET_DOCUMENT_TYPES_CODE: "GET_DOCUMENT_TYPES_CODE",
  GET_DOCUMENT_TYPES_CODE_RECEPTION: "GET_DOCUMENT_TYPES_CODE_RECEPTION",
  GET_REGIME_TYPES: "GET_REGIME_TYPES",
  GET_TAX_RESPONSIBILITY_TYPES: "GET_TAX_RESPONSIBILITY_TYPES",
  GET_FISCAL_RESPONSIBILITY_TYPES: "GET_FISCAL_RESPONSIBILITY_TYPES",
  GET_MENU: "GET_MENU",
  GET_COMPONENTS: "GET_COMPONENTS",
  GET_PAYMENT_METHODS: "GET_PAYMENT_METHODS",
  GET_CITIES_COUNTRY: "GET_CITIES_COUNTRY",
  GET_POSTAL_CODES: "GET_POSTAL_CODES",
  GET_UVT: "GET_UVT",
  GET_POSTAL_CODES_STATE: "GET_POSTAL_CODES_STATE",
  GET_MENU_COMPONENT: "GET_MENU_COMPONENT",
  GET_FREQUENT_QUESTIONS: "GET_FREQUENT_QUESTIONS",
  GET_REPORT_PROBLEM: "GET_REPORT_PROBLEM",
  GET_SECURITY_POLICY: "GET_SECURITY_POLICY",
  SEND_MAIL: "SEND_MAIL",
  GET_QUESTION_FILTER: "GET_QUESTION_FILTER",
  GET_REASON: "GET_REASON",
  SEND_MAIL_CONTACT: "SEND_MAIL_CONTACT",
  GET_TEMPLATE_BULK_LOAD_CLIENT: "GET_TEMPLATE_BULK_LOAD_CLIENT",
  GET_TEMPLATE_BULK_LOAD: "GET_TEMPLATE_BULK_LOAD",
  GET_REQUEST_TRANSMISSION_TYPE: "GET_REQUEST_TRANSMISSION_TYPE",
  GET_MENU_ARTICLE: "GET_MENU_ARTICLE",
  GET_MENU_INVOICE: "GET_MENU_INVOICE",
  GET_MENU_DOC_SUPPORT: "GET_MENU_DOC_SUPPORT",
  GET_MENU_DASHBOARD: "GET_MENU_DASHBOARD",
  GET_MENU_HELP: "GET_MENU_HELP",
  GET_MENU_CHARGE: "GET_MENU_CHARGE",
  GET_MENU_DISCOUNT: "GET_MENU_DISCOUNT",
  GET_MENU_INVOICE_RECEIVED: "GET_MENU_INVOICE_RECEIVED",
  GET_MENU_CUSTOMER: "GET_MENU_CUSTOMER",
  LOADING_GET_TEMPLATE_CUSTOMER: "[Template] Loading get template customers",

  GET_CONFIGURABLE_MENU: "GET_CONFIGURABLE_MENU",
  GET_MENU_MYACCOUNT: "GET_MENU_MYACCOUNT",
  GET_TYPE_DOCUMENT_REFERENCE: "GET_TYPE_DOCUMENT_REFERENCE",
  GET_CONNECTION_SIGNAL: "GET_CONNECTION_SIGNAL",
  CHANGE_FLAG_CONNECTION: "CHANGE_FLAG_CONNECTION",
  DEFAULT_PAYMENT_METHODS: "DEFAULT_PAYMENT_METHODS",
  OTHERS_PAYMENT_METHODS: "OTHERS_PAYMENT_METHODS",
  GET_ISSUES_LIST: "GET_ISSUES_LIST",
  GET_REASON_LIST: "GET_REASON_LIST",
  GET_TYPES_HEALTH_SERVICE: "[Config] Get types health service",
  GET_TYPES_COVERAGE: "[Config] Get types coverage health",
  GET_MODALITIES_CONTRACTING: "[Config] Get modalities contracting health",
  GET_TYPES_IDENTIFICATION_HEALTH: "[Config] Get types identification health",
  GET_TYPES_USER_HEALTH: "[Config] Get types user health",
  GET_TYPES_SUPPORT_DOCUMENT: "[Config] Get types support document",
  CLEAN_CODEPOSTAL_STATE: "[Config] Clean code postal state",
  CLEAN_CITIES_STATE: "[Config] Clean cities state",
  TYPES_EQUIVALENT_DOCUMENT: "[Config] Types equivalent document",
  //End Config

  //Begin Category
  SAVE_CATEGORY: "SAVE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  LOAD_CATEGORY: "LOAD_CATEGORY",
  CLEAN_DATA_CATEGORY: "CLEAN_DATA_CATEGORY",
  CLEAN_STATUS: "CLEAN_STATUS",
  LOADING_GET_CATEGORIES: "LOADING_GET_CATEGORIES",

  //Begin Favorite
  SUCCES_CATEGORY_FAVORITE: "SUCCES_CATEGORY_FAVORITE",
  UPDATE_FAVORITE: "UPDATE_FAVORITE",

  //Begin Invoice
  GET_INVOICES: "GET_INVOICES",
  SUCESS_DOCUMENT_UPDATE: "SUCESS_DOCUMENT_UPDATE",
  GET_DOCUMENT: "GET_DOCUMENT",
  CLEAN_DOCUMENT_DETAIL: "CLEAN_DOCUMENT_DETAIL",
  SUCESS_DOCUMENT_CREATE: "SUCESS_DOCUMENT_CREATE",
  INFO_DOCUMENT_CREATE: "INFO_DOCUMENT_CREATE",
  INFO_LICENSE_BASE: "INFO_LICENSE_BASE",
  SUCESS_QUOTATION_CREATE: "SUCESS_QUOTATION_CREATE",
  RESEND_ELECTRONIC_INVOICE: "RESEND_ELECTRONIC_INVOICE",
  GET_ADITIONAL_DATA_INVOICE: "GET_ADITIONAL_DATA_INVOICE",
  CLEAN_ADITIONAL_DATA_INVOICE: "CLEAN_ADITIONAL_DATA_INVOICE",
  SUCESS_SEND_INVOICE: "SUCESS_SEND_INVOICE",
  GET_ITEMS_CART: "GET_ITEMS_CART",
  SHOW_CATALOG_ITEMS: "SHOW_CATALOG_ITEMS",
  GET_TOTAL_DOCUMENTS: "GET_TOTAL_DOCUMENTS",
  CALCULE_INVOICE_VALUES: "CALCULE_INVOICE_VALUES",
  GET_QUOTATION: "GET_QUOTATION",
  GET_REASONS_NOTES: "GET_REASONS_NOTES",
  LOADING_REASONS_NOTES: "LOADING_REASONS_NOTES",
  GET_FILE_INVOICE: "GET_FILE_INVOICE",
  ACTIVE_STEP_INVOICE: "ACTIVE_STEP_INVOICE",
  GET_INFORME: "GET_INFORME",
  SHOW_INVOICE_CREATION: "SHOW_INVOICE_CREATION",
  LOADING_GET_DOCUMENTS: "LOADING_GET_DOCUMENTS",
  GET_LIST_RETEFUENTE: "GET_LIST_RETEFUENTE",
  GET_LIST_RETERENTA: "GET_LIST_RETERENTA",
  GET_LIST_RETEICA: "GET_LIST_RETEICA",
  GET_LIST_RETEIVA: "GET_LIST_RETEIVA",
  LOADING_GET_DETAIL_DOCUMENT: "LOADING_GET_DETAIL_DOCUMENT",
  LOADING_UPDATE_STATUS_DOCUMENT: "LOADING_UPDATE_STATUS_DOCUMENT",
  LOADING_UPDATE_FAVOURITE_DOCUMENT: "LOADING_UPDATE_FAVOURITE_DOCUMENT",
  LOADING_UPDATE_FROM_LIST: "LOADING_UPDATE_FROM_LIST",
  LOADING_DELETE_DOCUMENT: "LOADING_DELETE_DOCUMENT",
  GET_VALIDATION_BULK_LOAD: "GET_VALIDATION_BULK_LOAD",
  SAVE_REGISTER_BULK_LOAD: "SAVE_REGISTER_BULK_LOAD",
  GET_INFORMATION_BULK_LOAD: "GET_INFORMATION_BULK_LOAD",
  UPDATE_STATE_BULK: "UPDATE_STATE_BULK",
  CHANGE_STEP_BULK: "CHANGE_STEP_BULK",
  INFORMATION_INVOICE_BULK: "INFORMATION_INVOICE_BULK",
  STATUS_GET_INFORMATION_BULK_LOAD: "STATUS_GET_INFORMATION_BULK_LOAD",
  SHOW_BULK: "SHOW_BULK",
  GET_DOCUMENTS_REFERENCE: "GET_DOCUMENTS_REFERENCE",
  GET_RESULT_SIGNAL: "GET_RESULT_SIGNAL",
  SET_TYPE_NOTE: "SET_TYPE_NOTE",
  LOADING_GET_NOTE_DOCUMENT: "LOADING_GET_NOTE_DOCUMENT",
  LOADING_REPORT_DOCUMENT: "LOADING_REPORT_DOCUMENT",
  GET_DATA_EMAIL_REPORT: "GET_DATA_EMAIL_REPORT",
  GET_CHANGE_STATE: "GET_CHANGE_STATE",
  GET_TAX_LIST: "GET_TAX_LIST",
  GET_IVA_LIST: "GET_IVA_LIST",
  GET_INC_LIST: "GET_INC_LIST",
  GET_ICUI_LIST: "GET_ICUI_LIST",
  GET_IBUA_LIST: "GET_IBUA_LIST",
  GET_ICL_LIST: "GET_ICL_LIST",
  GET_INPP_LIST: "GET_INPP_LIST",
  GET_ADV_LIST: "GET_ADV_LIST",
  SET_DEFAULT_FILTER: "SET_DEFAULT_FILTER",

  ADD_DOCUMENT_REFERENCE: "[Document] Add new document reference",
  LOAD_DOCUMENT_REFERENCE: "[Document] load document reference in form",
  UPDATED_DOCUMENT_REFERENCE: "[Document] Updated document reference",
  DELETE_DOCUMENT_REFERENCE: "[Document] Delete document reference",
  REFRESH_DOCUMENTS_REFERENCE: "[Document] Updated document reference list",
  CLEAN_DOCUMENTS_REFERENCE: "[Document] Clean document reference reducer",

  ADD_CUSTOM_FIELD: "[Document] Add new custom field",
  DELETE_CUSTOM_FIELD: "[Document] Delete custom field",
  LOADING_SAVE_FIELD: "[Document] loading save field",
  LOADING_GET_FIELDS: "[Document] loading get fields list",
  LOADING_DELETE_FIELDS: "[Document] loading delete field",
  REFRESH_FIELD_LIST: "[Document] update list custom fileds",
  //End Invoice

  //Create Document
  LOAD_ADDRESSS_CUSTOMER: "[Create document] load address customer",
  LOAD_CONTACT_CUSTOMER: "[Create document] load contact customer",
  LOAD_HEAD_DOCUMENT: "[Create document] load head for document",
  LOAD_CUSTOMER_DOCUMENT: "[Create document] load costomer for document",
  LOAD_CUSTOM_FIELDS_DOCUMENT: "[Create document] load fields for document",
  LOAD_CUSTOM_FIELDS_COMPANY: "[Create document] load fields of company",
  LOAD_DOCS_REFERENCE_DOCUMENT: "[Create document] load reference documents for document",
  STAND_OUT_DOCUMENT: "[Create document] Change status stand out document",
  LOAD_SAVED_CUSTOM_FIELDS: "[Create document] load saved fields of document",
  CLEAN_DOCUMENT: "[Create document] Clean document reducer",
  SET_CURRENT_TYPE_NOTE: "[Create document] Set type note",
  ADD_OBSERVATIONS_NOTE: "[Create document] Add observation to note",
  ADD_REASON_NOTE: "[Create document] Add reason to note",
  SET_AVAILABLE_NUMERTAION: "[Create document] Load available numeration",
  ADD_NUMERATION_DOCUMENT: "[Create document] Add  numeration new document",
  LOAD_FIELDS_DOCUMENT: "[Create document] Load fileds saved in document",
  LOAD_PATIENTS_DOCUMENT: "[Create document] load patients for document",
  SET_GENERATE_PATIENT_DOCUMENT: "[Create document] Set status generate document patient",
  SET_CONTINGENCY_STATUS: "[Create document] Set status contingency document",

  //Customers
  GET_CUSTOMERS_LIST: "[Customer] Get customers list",
  REFRESH_CURRENT_CUSTOMERS: "[Customer] refresh list customer to view",
  LOAD_CUSTOMER_DETAIL: "[Customer] Load customer detail",
  SET_CUSTOMER_ID: "[Customer] Set customer id for selection list",
  CLEAN_CUSTOMERS_DATA: "[Customer] Clean customer reducer",
  ADD_CUSTOMER_ACTIVE: "[Customer] Load data customer for form",
  DELETE_CUSTOMER: "[Customer] delete customer",
  STAND_OUT_CUSTOMER: "[Customer] Stand out customer",
  LOADING_GET_CUSTOMERS: "[Customer] loading get customers",
  LOADING_GET_CUSTOMER_DOCUMENT: "[Customer] loading get customer detail for document",
  LOADING_GET_CUSTOMER_ACTIVE: "[Customer] loading get customer detail for form",
  LOADING_SAVE_CUSTOMER: "[Customer] loading save or update customer",
  LOADING_STAND_OUT_CUSTOMERS: "[Customer] loading stand out customer",
  LOADING_DELETE_CUSTOMER: "[Customer] loading delete customer",

  //Address customer
  GET_ADDRESS_LIST: "[Customer] Get address customers list",
  ADD_CUSTOMER_ADDRESS: "[Customer] Add new customer address",
  UPDATE_CUSTOMER_ADDRESS: "[Customer] Update new customer address",
  DELETE_CUSTOMER_ADDRESS: "[Customer] Delete customer address",
  SET_ACTIVE_CUSTOMER_ADDRESS: "[Customer]  Set active customer address",
  CLEAN_CUSTOMER_ADDRESS: "[Customer]  Clean data customer address",

  //Contact customer
  GET_CONTACT_LIST: "[Customer] Get contact customers list",
  ADD_CUSTOMER_CONTACT: "[Customer] Add new customer contact",
  UPDATE_CUSTOMER_CONTACT: "[Customer] Update new customer contact",
  DELETE_CUSTOMER_CONTACT: "[Customer] Delete customer contact",
  SET_ACTIVE_CUSTOMER_CONTACT: "[Customer]  Set active customer contact",
  CLEAN_CUSTOMER_CONTACT: "[Customer]  Clean data customer contact",

  //Cart
  SHOW_CATALOG: "[Cart] Set status show catalog",
  UPDATE_TOTALS_DOCUMENT: "[Cart] Update totals of document",
  UPDATE_ITEM_DOCUMENT: "[Cart] Update list items of document",
  ADD_RETE_IVA_DOCUMENT: "[Cart] Add reteiva to document",
  ADD_RETE_ICA_DOCUMENT: "[Cart] Add reteica to document",
  UPDATE_ROUND_DOCUMENT: "[Cart] Update round status document",
  CLEAN_DOCUMENT_CART: "[Cart] Clean document cart reducer",
  SHOW_CART_DOCUMENT_SUPPORT: "[Cart] Show cart document support",
  DIGITS_AFTER_DECIMAL_TYPE_DOC: "[Cart] Digits after decimal type doc",

  //Charges document
  ADD_NEW_CHARGE: "[Charge] Add new charge",
  GET_CHARGES_LIST: "[Charge] Get charges list",
  LOADING_CHARGES_LIST: "[Charge] Loading status, get charges list",
  LOADING_ADD_CHARGE: "[Charge] Loading save new charge",
  LOAD_DOCUMENT_CHARGE: "[Charge] Load charge in form for update",
  CLEAN_CHARGES_DATA: "[Charge] Clean new charge reducer",

  //Discount document
  ADD_NEW_DISCOUNT: "[Discount] Add new discount",
  GET_DISCOUNTS_LIST: "[Discount] Get discounts list",
  LOADING_DISCOUNTS_LIST: "[Discount] Loading status, get discounts list",
  LOADING_ADD_DISCOUNT: "[Discount] Loading save new discount",
  LOAD_DOCUMENT_DISCOUNT: "[Discount] Load discount in form for update",
  GET_REASONS_DISCOUNTS: "[Discount] Get reasons discounts list",
  CLEAN_DISCOUNTS_DATA: "[Charge] Clean new discount reducer",

  //Begin Values Invoice
  GET_DOCUMENT_DETAIL: "GET_DOCUMENT_DETAIL",
  GET_TOTAL_DETAIL: "GET_TOTAL_DETAIL",
  GET_CHARGES_DOCUMENT: "GET_CHARGES_DOCUMENT",
  LOAD_CHARGE_DOCUMENT: "LOAD_CHARGE_DOCUMENT",
  UPDATE_CHARGE_DOCUMENT: "UPDATE_CHARGE_DOCUMENT",
  OPEN_MODAL_CHARGE: "OPEN_MODAL_CHARGE",
  GET_DISCOUNTS_DOCUMENT: "GET_DISCOUNTS_DOCUMENT",
  LOAD_DISCOUNT_DOCUMENT: "LOAD_DISCOUNT_DOCUMENT",
  OPEN_MODAL_DISCOUNT: "OPEN_MODAL_DISCOUNT",
  UPDATE_DISCOUNT_DOCUMENT: "UPDATE_DISCOUNT_DOCUMENT",
  SHOW_CART_INVOICE: "SHOW_CART_INVOICE",
  DOCUMENT_VALID: "DOCUMENT_VALID",
  ROUND_VALUES_STATUS: "ROUND_VALUES_STATUS",
  //End Values Invoice

  //Begin Client
  SAVE_CLIENT: "SAVE_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_STATUS: "UPDATE_CLIENT_STATUS",
  GET_FILTERLIST_CLIENT: "GET_FILTERLIST_CLIENT",
  GET_CLIENTS_LIST: "GET_CLIENTS_LIST",
  LOAD_CLIENT: "LOAD_CLIENT",
  LOAD_CLIENT_DETAIL: "LOAD_CLIENT_DETAIL",
  LOADING_SAVE_CLIENT: "LOADING_SAVE_CLIENT",
  LOADING_LIST_CLIENT: "LOADING_LIST_CLIENT",
  LOADING_UPDATE_CLIENT_STATUS: "LOADING_UPDATE_CLIENT_STATUS",
  DELETE_CLIENT: "DELETE_CLIENT",
  LOADING_DELETE_CLIENT: "LOADING_DELETE_CLIENT",
  STANDOUT_CLIENT: "STANDOUT_CLIENT",
  LOADING_STANDOUT_CLIENT: "LOADING_STANDOUT_CLIENT",
  LOADING_FILTERLIST_CLIENT: "LOADING_FILTERLIST_CLIENT",
  LOADING_DETAIL_CLIENT: "LOADING_DETAIL_CLIENT",
  LOADING_GET_CLIENT: "LOADING_GET_CLIENT",
  GET_CLIENTS_RESUME_LIST: "GET_CLIENTS_RESUME_LIST",
  SELECTED_ID_CLIENT: "SELECTED_ID_CLIENT",
  LOADING_GET_EXISTING_CLIENT: "LOADING_GET_EXISTING_CLIENT",
  LOAD_CLIENT_DOCUMENT: "LOAD_CLIENT_DOCUMENT",
  SELECTED_ID_CLIENT_DOCUMENT: "SELECTED_ID_CLIENT_DOCUMENT",
  //End Client

  //Begin Address
  SAVE_ADDRESS: "SAVE_ADDRESS",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  GET_ADDRESSLIST: "GET_ADDRESSLIST",
  LOAD_ADDRESS: "LOAD_ADDRESS",
  //End Address

  //Begin contact
  SAVE_CONTACT: "SAVE_CONTACT",
  GET_CONTACTSLIST: "GET_CONTACTSLIST",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  LOAD_CONTACT: "LOAD_CONTACT",
  DELETE_CONTACT: "DELETE_CONTACT",
  //End contact

  //Begin Charge
  SAVE_CHARGE: "SAVE_CHARGE",
  UPDATE_CHARGE: "UPDATE_CHARGE",
  DELETE_CHARGE: "DELETE_CHARGE",
  GET_CHARGELIST: "GET_CHARGELIST",
  GET_CHARGE: "GET_CHARGE",
  GET_FILTER_CHARGES: "GET_FILTER_CHARGES",
  GET_FILTER_CHARGE: "GET_FILTER_CHARGE",
  LOADING_SAVE_CHARGE: "LOADING_SAVE_CHARGE",
  CHANGE_EDITING_CHARGE_STATUS: "CHANGE_EDITING_CHARGE_STATUS",
  LOADING_GET_CHARGES: "LOADING_GET_CHARGES",
  //End Charge

  //Begin Discount
  SAVE_DISCOUNT: "SAVE_DISCOUNT",
  UPDATE_DISCOUNT: "UPDATE_DISCOUNT",
  DELETE_DISCOUNT: "DELETE_DISCOUNT",
  GET_DISCOUNTLIST: "GET_DISCOUNTLIST",
  GET_DISCOUNT: "GET_DISCOUNT",
  GET_REASON_DISCOUNT: "GET_REASON_DISCOUNT",
  GET_FILTER_DISCOUNTS: "GET_FILTER_DISCOUNTS",
  GET_FILTER_DISCOUNT: "GET_FILTER_DISCOUNT",
  LOADING_SAVE_DISCOUNT: "LOADING_SAVE_DISCOUNT",
  CHANGE_EDITING_DISCOUNT_STATUS: "CHANGE_EDITING_DISCOUNT_STATUS",
  LOADING_GET_DISCOUNTS: "LOADING_GET_DISCOUNTS",
  //End Discount

  //Begin Dashboard
  GET_BILL_CLIENT: "GET_BILL_CLIENT",
  GET_COLLABORATOR_RANKING: "GET_COLLABORATOR_RANKING",
  GET_BILLING: "GET_BILLING",
  GET_BRANCH_RAKING: "GET_BRANCH_RAKING",
  GET_INCOME: "GET_INCOME",
  GET_BEST_CUSTOMER: "GET_BEST_CUSTOMER",
  GET_RAKING_ITEMS: "GET_RAKING_ITEMS",
  LOADING_GET_GRAPHIC: "LOADING_GET_GRAPHIC",
  GET_ISSUED_INVOICE: "GET_ISSUED_INVOICE",
  //End Dashboard

  //Begin Training
  GET_VIDEO_MULTIMEDIA: "GET_VIDEO_MULTIMEDIA",
  LOADING_GET_VIDEO: "LOADING_GET_VIDEO",
  //End  Training

  //Begin Invoices Receied
  CLEAR_CODE_AUTHENTICATION: "CLEAR_CODE_AUTHENTICATION",
  GET_COMPANY_EMAIL_DIAN: "GET_COMPANY_EMAIL_DIAN",
  GET_ERROR_INVOICE_RECEIVED: "GET_ERROR_INVOICE_RECEIVED",
  GET_INFO_AUTHENTICATION_EMAIL: "GET_INFO_AUTHENTICATION_EMAIL",
  GET_URL_AUTHENTICATION_OAUTH: "GET_URL_AUTHENTICATION_OAUTH",
  SET_AUTHENTICATION_OAUTH_ERROR: "SET_AUTHENTICATION_OAUTH_ERROR",
  CHANGE_VALUE_VAR: "CHANGE_VALUE_VAR",
  SET_AUTHENTICATION_OAUTH_EXITO: "SET_AUTHENTICATION_OAUTH_EXITO",
  SET_AUTHENTICATION_OAUTH_DATA: "SET_AUTHENTICATION_OAUTH_DATA",
  SET_AUTHENTICATION_OAUTH_EMAIL_DIFERENTE: "SET_AUTHENTICATION_OAUTH_EMAIL_DIFERENTE",
  GET_ERRORS_INVOICE_RECEIVED: "GET_ERRORS_INVOICE_RECEIVED",
  GET_INVOICES_RECEIVED: "GET_INVOICES_RECEIVED",
  SET_CONSULTANDO_DOCUMENTOS: "SET_CONSULTANDO_DOCUMENTOS",
  SET_INVOICE_SELECTED: "SET_INVOICE_SELECTED",
  LOADING_UPDATE_FAV_RECEIVED_FROM_LIST: "LOADING_UPDATE_FAV_RECEIVED_FROM_LIST",
  LOADING_CAMBIAR_ESTADO_PAGO: "LOADING_CAMBIAR_ESTADO_PAGO",
  SHOW_SUCCES_CHANGE_STATE_PAY: "SHOW_SUCCES_CHANGE_STATE_PAY",
  GET_TIPOS_MOTIVO_RECHAZO: "GET_TIPOS_MOTIVO_RECHAZO",
  LOADING_TIPOS_MOTIVO_RECHAZO: "LOADING_TIPOS_MOTIVO_RECHAZO",
  GET_FILE_INVOICE_RECEIVED: "GET_FILE_INVOICE_RECEIVED",
  LOADING_INFO_AUTH: "LOADING_INFO_AUTH",
  FILTRANDO_DOCUMENTOS: "FILTRANDO_DOCUMENTOS",
  CHANGE_STATE_INVOICE_RECEIVE: "CHANGE_STATE_INVOICE_RECEIVE",
  //End  Invoices Receied

  //Begin patient
  ADD_PATIENT: "[Patient] Add new patient",
  UPDATED_PATIENT: "[Patient] Updated patient",
  DELETE_PATIENT: "[Patient] Delete patient",
  LOAD_PATIENT: "[Patient] Load patient in form",
  CLEAN_DATA_PATIENT: "[Patient] Clean data patient to redux",
  REFRESH_PATIENT_LIST: "[Patient] Update list patient",
  LOADING_GET_PATIENT: "[Patient] Loading get patient process",
  LOAD_EXISTING_PATIENT: "[Patient] Load data exiting patient in BD",
  //End patient

  //Begin document Integration
  FILL_INTEGRATION_DOCUMENT: "[Document integration] FIll integration documents list.",
  LOADING_INTEGRATION_DOCUMENTS: "[Document integration] Loading get documents list.",
  FILL_INTEGRATION_DETAIL: "[Document integration] Fill detail integration document detail.",
  LOADING_GET_INTEGRATION_DETAIL: "[Document integration] Loading get datail.",
  LOADING_STAND_OUT_INTEGRATION: "[Document integration] Loading stand out document integration",
  UPDATE_STAND_OUT_INTEGRATION: "[Document integration] Update status stand out document integration",
  LOADING_INTEGRATION_DOCUMENT_LIST: "[Document integration] Update status stand out document integration from list",
  LOADING_SEND_MAIL_INTEGRATION: "[Document integration] Send email with PDF integration document",
  CLEAN_REDUX_INTEGRATION: "[Document integration] Clean reducer document integration",
  //End document Integration

  //Begin document received
  FILL_RECEIVED_DOCUMENT: "[Document received] FIll received documents list.",
  LOADING_RECEIVED_DOCUMENTS: "[Document received] Loading get documents list.",
  FILL_RECEIVED_DETAIL: "[Document received] Fill detail received document detail.",
  LOADING_GET_RECEIVED_DETAIL: "[Document received] Loading get datail.",
  LOADING_STAND_OUT_RECEIVED: "[Document received] Loading stand out document received",
  UPDATE_STAND_OUT_RECEIVED: "[Document received] Update status stand out document received",
  LOADING_RECEIVED_DOCUMENT_LIST: "[Document received] Update status stand out document received from list",
  GET_REJECTION_REASONS: "[Document received] Get rejection reasons list",
  LOADING_UPLOAD_RECEIVED: "[Document received] Loading upload document received",
  SUCESS_ACKNOWLEDGEMENT_DOCUMENT_RECEIVED: "[Document received] Acknowledgement a document received",
  SUCESS_RECEIPT_DOCUMENT_RECEIVED: "[Document received] Receipt a document received",
  LOADING_RECEIPT_DOCUMENT_RECEIVED: "[Document received] Loading receipt document received",
  SUCESS_REJECT_DOCUMENT_RECEIVED: "[Document received] Sucess reject a document received",
  LOADING_ACCEPT_DOCUMENT_RECEIVED: "[Document received] Loading acceptance document received",
  SUCESS_ACCEPT_DOCUMENT_RECEIVED: "[Document received] Sucess acept a document received",
  LOADING_REJECT_DOCUMENT_RECEIVED: "[Document received] Loading reject document received",
  DOCUMENT_AVAILABLE_ZERO: "[Document received] Document available zero",
  CLEAN_REDUX_RECEIVED: "[Document received] Clean reducer document received",
  //End document received

  // Document Support

  GET_SUPPLIER_DATA: "[Document support] Get supplier data",
  FILL_PROVIDER_DATA: "[Document support] Fill provider data ",
  FILL_DOCUMENT_DATA: "[Document support] Fill Document data ",
  CREATE_PROVIDER_DATA: "[Documento support] Create provider data with information complete ",
  CREATE_DOCUMENT_SUPPORT: "[Document support] Create document support",
  SET_FAVORITE_PROVIDER: "[Document support] Set favorite provider",
  CLEAN_DOCUMENT_SUPPORT: "[Document support] Clean document support",
  GET_DOCUMENT_SUPPORT: "[Document support] Get document support",
  GET_DETAIL_DOCUMENT_SUPPORT: "[Document support] Get detail document support",
  LOADING_GET_DOCUMENT_SUPPORT_DATA: "[Document support] Loading get document support data",
  LOADING_GET_DOCUMENT_SUPPORT_DETAIL: "[Document support] Loading get document support detail",
  LOADING_REPORT_DOCUMENT_SUPPORT: "[Document support] Loading report document support",
  LOADING_UPDATE_STATUS_DOCUMENT_SUPPORT: "[Document support] Loading update status document support",
  GET_CONCEPTS_NOTES_ADJUSTMENT: "[Document support] Get concepts notes adjustment",
  LOADING_UPDATE_FAVORITE_DOCUMENT_SUPPORT: "[Document support] Loading favorite status document support",
  LOADING_GET_NOTE_DOCUMENT_SUPPORT: "LOADING_GET_NOTE_DOCUMENT",
  SUCESS_SEND_DOCSUPPORT: "SUCESS_SEND_DOCSUPPORT",

  // End document support

  //Begin Equivalent Document
  CLEAR_EQUIVALENT_DOC: "CLEAR_EQUIVALENT_DOC",
  GET_EQUIVALENT_DOC_DETAIL: "GET_EQUIVALENT_DOC_DETAIL",
  GET_EQUIVALENT_DOC: "GET_EQUIVALENT_DOC",
  LOAD_CUSTOMER_EQUIVALENT_DOC: "LOAD_CUSTOMER_EQUIVALENT_DOC",
  LOADING_GET_EQUIVALENT_DOC_DETAIL: "LOADING_GET_EQUIVALENT_DOC_DETAIL",
  LOADING_GET_EQUIVALENT_DOC: "LOADING_GET_EQUIVALENT_DOC",
  LOADING_REPORT_EQUIVALENT_DOC: "LOADING_REPORT_EQUIVALENT_DOC",
  LOADING_STATE_EQUIVALENT_DOC: "LOADING_STATE_EQUIVALENT_DOC",
  LOADING_STATE_EQUIVALENT_DOC_PAID: "LOADING_STATE_EQUIVALENT_DOC_PAID",
  LOADING_EQUIVALENT_DOC_TYPES: "LOADING_EQUIVALENT_DOC_TYPES",
  SET_ACQUIRER_INFORMATION: "SET_ACQUIRER_INFORMATION",
  SET_ADDITIONAL_DOC_INFO: "SET_ADDITIONAL_DOC_INFO",
  SET_ADDITIONAL_INFO_BOX: "SET_ADDITIONAL_INFO_BOX",
  SET_CREATE_EQUIVALENT_DOCUMENT_DATA: "SET_CREATE_EQUIVALENT_DOCUMENT_DATA",
  SET_EQUIVALENT_DOC_DATA: "SET_EQUIVALENT_DOC_DATA",
  SET_EQUIVALENT_DOCUMENT_ITEMS: "SET_EQUIVALENT_DOCUMENT_ITEMS",
  SET_ERRORS_FORM_EQUIVALENT_DOC: "SET_ERRORS_FORM_EQUIVALENT_DOC",
  SET_INFO_FOR_CREATING_MODAL: "SET_INFO_FOR_CREATING_MODAL",
  SET_INITIAL_LOAD_CREATE_EQUIVALENT: "SET_INITIAL_LOAD_CREATE_EQUIVALENT",
  SET_SELECTED_DOCUMENT_DATA: "SET_SELECTED_DOCUMENT_DATA",
  SET_SELECTED_DOCUMENT_ID: "SET_SELECTED_DOCUMENT_ID",
  SET_TEST_ID_EQUIVALENT_DOC_DATA: "SET_TEST_ID_EQUIVALENT_DOC_DATA",
  SHOW_COMPONENTS_EQUIVALENT_DOC: "SHOW_COMPONENTS_EQUIVALENT_DOC",
  NOTE_EQUIVALENT_DOC_ACTIVE: "NOTE_EQUIVALENT_DOC_ACTIVE",
  //End Equivalent Document
};
