import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import Grid from '@material-ui/core/Grid';
import InputForm from "../../../components/input/input.component";
import SelectForm from '../../../components/select/select.component'
import RadioButtonGroup from '../../../components/radioButton/radioButton.component'
import ButtonSecundary from '../../../components/button/buttonSecundary.component'
import ToggleButtons from '../../../components/toggle/toggle.component'
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";


import { getDiscountReasonAction, saveDiscountAction } from '../../../actions/newDiscountAction';
import { updateDiscountDocumentAction } from '../../../actions/documentCartAction';


import { regexDecimal, regexNames } from '../../../helpers/customRegex.hepers'
import getTypeValue from '../../../utils/typeValue';
import { useFormComplete } from "../../../hooks/useFormComplete";


export const DiscountForm = ({
    handleCloseModal
}) => {
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
    const [, forceUpdate] = useState();
    const dispatch = useDispatch();
    const { values, handleInputChange, handleUpdateForm, reset } = useFormComplete({
        model: "1",
        motive: "",
        type: "1",
        value: 0,
        description: ""
    });
    const { model, motive, type, value, description } = values;
    const { reasonsDiscount, loadingSaveDiscount, activeDocumentDiscount } = useSelector(state => state.newDiscountReducer);
    const {
        tooltipModelDiscountHeader,
        tooltipModelDiscountBody,
        tooltipModelDiscountBotton,
        tooltipModelDiscountUrl,
        tooltipTypeDiscountHeader,
        tooltipTypeDiscountBody,
        tooltipTypeDiscountBotton,
        tooltipTypeDiscountUrl
    } = useSelector(state => state.menuReducer)

    useEffect(() => {
        dispatch(getDiscountReasonAction());
    }, [dispatch])


    useEffect(() => {
        if (!!activeDocumentDiscount) {
            const { modelodescuento, motivoid, tipodescuento, valor, descripcion } = activeDocumentDiscount;
            handleUpdateForm({
                model: modelodescuento.toString(),
                motive: motivoid.toString(),
                type: tipodescuento.toString(),
                value: valor,
                description: descripcion
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocumentDiscount])


    /**
     * Sincroniza nuevo valor modelo de descuento con state
     * @param {*} e 
     * @param {*} newAlignment 
     */
    const handleChangeModel = (e, newAlignment) => {
        handleUpdateForm({
            model: !!newAlignment ? newAlignment : model
        })
    }

    /**
    * Sincroniza tipo cargo con state
    * @param {*} param0 
    */
    const handleChangeTypeDiscount = ({ target }) => {
        handleUpdateForm({
            type: target.value,
            value: 0
        })
    }

    /**
     * Sincroniza nueva descripción con state
     * @param {*} param0 
     */
    const handleChangeDescription = ({ target }) => {
        handleUpdateForm({
            description: target.value.replace(regexNames, '')
        });
    }

    /**
  * Limpia cero de input
  * @param {*} e 
  * @param {*} name 
  */
    const onClickNotZero = (e, name) => {
        let value = e.target.value;
        if (!!value) {
            value = value.replace(regexDecimal, '');
            if (value === 0 || value === '0') {
                handleUpdateForm({
                    [name]: ""
                })
            }
        }
    }

    /**
 * Asigna valor por defecto si no se digita valor
 * @param {*} param0 
 */
    const handleBlurValue = ({ target }) => {
        if (target.value.trim().length === 0)
            handleUpdateForm({ value: 0 });
    }

    /**
     * Guarda o actualiza descuento
     * @param {*} e 
     */
    const handleSaveDiscount = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {

            if (!!activeDocumentDiscount) {
                let discount = {
                    ...activeDocumentDiscount,
                    motivoid: parseInt(motive),
                    motivodescripcion: reasonsDiscount.find(c => c.id === parseInt(motive))?.descripcion ?? "",
                    modelodescuento: parseInt(model),
                    tipodescuento: parseInt(type),
                    valor: parseFloat(value)
                }

                dispatch(updateDiscountDocumentAction(discount, reset));

            } else {
                let discount = {
                    descripcion: description,
                    motivoid: parseInt(motive),
                    motivodescripcion: reasonsDiscount.find(c => c.id === motive)?.descripcion ?? "",
                    modelodescuento: parseInt(model),
                    tipodescuento: parseInt(type),
                    valor: parseFloat(value)
                }

                dispatch(saveDiscountAction(discount, reset));
            }

            handleCloseModal();
            validator.current.hideMessages();
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                    <ToggleButtons
                        name={"model"}
                        label={"Modelo de descuento"}
                        value={model}
                        onChange={handleChangeModel}
                        titleOne={'Comercial'}
                        titleTwo={'Financiero'}
                        txtTooltip={
                            <TooltipMessage
                                title={tooltipModelDiscountHeader}
                                message={tooltipModelDiscountBody}
                                botton={tooltipModelDiscountBotton}
                                href={tooltipModelDiscountUrl}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <SelectForm
                        label={"Motivo descuento *"}
                        name="motive"
                        value={motive}
                        options={reasonsDiscount}
                        onChange={handleInputChange}
                        validator={validator.current}
                        validateOptions={"required"}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <RadioButtonGroup
                        name={'type'}
                        value={type}
                        onChange={(e) => handleChangeTypeDiscount(e)}
                        options={getTypeValue()}
                        label={"Tipo Descuento:"}
                        validator={validator.current}
                        validateOptions={"required"}
                        txtTooltip={
                            <TooltipMessage
                                title={tooltipTypeDiscountHeader}
                                message={tooltipTypeDiscountBody}
                                botton={tooltipTypeDiscountBotton}
                                href={tooltipTypeDiscountUrl}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <InputForm
                        name={"value"}
                        label={type === '2' ? "% Descuento *" : "Valor Descuento *"}
                        maxLength={type === '2' ? 9 : 21}
                        value={value}
                        onChange={handleInputChange}
                        onClick={onClickNotZero}
                        onBlur={handleBlurValue}
                        nameValidator={"valorDescuento"}
                        validator={validator.current}
                        validateOptions={type === '1' ? "required" : "required|porcentage"}
                        format={type === '1' ? 'money' : 'porcentage'}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <InputForm
                        name={"description"}
                        label={"Descripción *"}
                        maxLength={100}
                        value={description}
                        onChange={handleChangeDescription}
                        validator={validator.current}
                        validateOptions={"required"}
                        disabled={!!activeDocumentDiscount ? true : false}
                    />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item lg={6} xs={12}>
                    <ButtonSecundary
                        text={'Confirmar'}
                        loading={loadingSaveDiscount}
                        onClick={handleSaveDiscount} />
                </Grid>
            </Grid>
        </>
    )
}
