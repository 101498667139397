import React, { Fragment,Component } from "react";

import { withStyles } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";
import {Card,CardContent,Grid} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import {Tooltip} from "@material-ui/core";

import goldPlate from "../../../src/images/PlacaORO.png";
import silverPlate from "../../../src/images/PlacaPlata.png";
import bronzePlate from "../../../src/images/PlacaBronce.png";

import CustomProgress from '../Progress/progress.component';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { FormatDecimal } from "../common/formatDecimal";



const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  cardGoldPlate: {
    background: "linear-gradient( #1879C7 0%, #014780 100%)",
    height:135,
    radius:5
  },
  cardSilverPlate: {
    background: "linear-gradient( #E39117 0%, #935801 100%)",
    height:135,
    radius:5
  },
  cardBrozePlate: {
    background: "linear-gradient( #76A97B 0%, #29652F 100%)",
    radius:5,
    height:135,
  },
  contentCard: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: 800,
    textOverflow: 'ellipsis',
    overflow:'hidden',
    marginLeft:'-13px',
    lineHeight:'1.5em',
    height: '3em',
    //whiteSpace: 'nowrap'
  },
  contentCardResponsive: {
   /* fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: 800,
    width:10,
    textOverflow: 'ellipsis',
    overflow:'hidden',*/

  },
  priceCard: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: "bold",
    marginLeft:'-13px',
    textOverflow: 'ellipsis',
    overflow:'hidden',
    width:90
  },
  priceCardResponsive: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: "bold",
    marginLeft:'-13px',
    textOverflow: 'ellipsis',
    overflow:'hidden',
    width:'100%'
    
  },
  contentCardInvoice: {
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 10,
    marginLeft: '-14px',
    width: 100,
    lineHeight:'30px'
  },

  contentCardInvoiceResponsive: {
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 10,
    marginLeft: '10x',
    width: 100,
    lineHeight:'30px'
  },

  contentCardInvoice2: {
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 10,
    marginLeft: '-2px',
    width: 50
  },
  cardGeneral: {
    background: "linear-gradient( #7C7B7B 0%, #434242 100%)",
    height:135,
    radius:5
  },
  cardDisable: {
    background: "linear-gradient( #7C7B7B 0%, #434242 100%)",
    opacity: 0.3,
    radius:5,
    height:135
  },
  platePosition: {
    top: '61%',
    marginLeft: "2.1rem",
    position: "absolute",
  },

  platePositionResponsive: {
    top: '61%',
    marginLeft: "6rem",
    position: "absolute",
  },
  icon: {
    fill: theme.palette.primaryColor,
  },
  cardContent:{
    position: "realtive",
    paddingBottom: 60 
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  }
});

class BasicCarrousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false,
      timer: 500,
      animation: "slide",
      indicators: false,
      timeout: 200,
      numItemPerPage: 6,
      pages: 0,
      navButtonsAlwaysVisible: true,
    };
  }

  formatter = (value) => {
    const options = { style: 'currency', currency: 'USD', maximumSignificantDigits:10};
    const numberFormat = new Intl.NumberFormat('en-US', options);
    return numberFormat.format(value)
  } 
  getGridListCols = () => {
    if (isWidthUp('xl', this.props.width))
      return "xl";
    if (isWidthUp('lg', this.props.width))
      return "lg";
    if (isWidthUp('md', this.props.width))
      return "md";
      return "xl";
    
  }
  /**
   *
   * @param {*} classes
   * @param {*} data
   * @param {*} index
   */
  Card(classes, data, index,type) {
    return (
      
      <Grid item lg={2} xs={2}>
        
        <Card lg={2} xs={2} key={index}
        
          className={data.rango === 1 ? classes.cardGoldPlate: data.rango === 2 ? classes.cardSilverPlate: data.rango === 3
              ? classes.cardBrozePlate : index===5 ? classes.cardDisable : classes.cardGeneral}>

          <CardContent className={classes.cardContent}>
            <div className={classes.contentCard}>{type === 'Colaborador' ? data.vendedor : data.nombre }</div>
            <br/>

           
            <Tooltip
              title={
                <FormatDecimal
                  value={data.valortotal}
                  prefix={"$"}
                />
              }
            >
              <div className={this.getGridListCols() !== 'xl' ? classes.priceCard : classes.priceCardResponsive}>
                <FormatDecimal
                  value={data.valortotal}
                  prefix={"$"}
                />
              </div>
            </Tooltip>
            
            {this.getGridListCols() === 'lg' ?
            <div
            className={this.getGridListCols() === 'lg' ? classes.contentCardInvoice : classes.contentCardInvoiceResponsive }  > {data.cantidad} Facturas
         </div>
          : <div
          className={this.getGridListCols() === 'lg' ? classes.contentCardInvoice : classes.contentCardInvoiceResponsive }  > {data.cantidad} Facturas Emitidas
       </div>}
            {this.getGridListCols() ==='lg' ?
          <div
          className={classes.contentCardInvoice2} style={{lineHeight:'3px'}}> Emitidas
       </div> : ''  
          }
            
            <div className={this.getGridListCols() === 'lg'?classes.platePosition:classes.platePositionResponsive}>
              <img alt ='' src = {data.rango === 1 ? goldPlate:data.rango === 2 ? silverPlate:data.rango === 3 
              ? bronzePlate : index === 5 ? '' : ''}/>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  render() {
    const { name, classes, items, statusCode, type} = this.props;
    let list = [];
    let listCards = [];
    for (let index = 0; index < items.length; index++) {
      if (
        (index + 1) % this.state.numItemPerPage !== 0 &&
        index + 1 !== items.length
      ) {
        list.push(items[index]);
      } else {
        list.push(items[index]);
        listCards.push(list);
        list = [];
      }
    }
    return (
      
      <Card className={classes.colorCard}>
        <CardContent>
          <CardHeader title={name} className={"title"} />
          <Fragment>
             
            { items.length === 0 && statusCode === undefined ? <CustomProgress/> : 
            (items.length === 0 && statusCode === 0)  || (items.length === 0 && statusCode === 200) ? 
            <div className={classes.divMessage}>No tiene facturas registradas</div> :
            !!listCards && listCards.length && (
              <Carousel
                autoPlay={this.state.autoPlay}
                timer={this.state.timer}
                animation={this.state.animation}
                indicators={this.state.indicators}
                timeout={this.state.timeout}
                navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                
              >
                {listCards.map((cards, index) => {
                  return (
                    <Grid container spacing={1} key={index} style={{marginTop:5}}>
                      {cards.map((data, indexCard) =>
                        this.Card(classes, data, indexCard,type)
                      )}
                    </Grid>
                  );
                })}
              </Carousel>
            )    
            }
          </Fragment>
        </CardContent>
      </Card>
    );
  }
}
export default (withWidth() (withStyles(useStyles) (BasicCarrousel)));
