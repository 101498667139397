import { DIGITS_AFTER_DECIMAL, DIGITS_BEFORE_DECIMAL } from "../config/config";
import isEmpty from "../utils/isEmpty";
import { roundDecimalValue } from "../utils/managementNumber";




export const validateQuantityItem = (unitValue, quantity) => {
    let maxLength = DIGITS_BEFORE_DECIMAL;
    const lengthValid = 15;
    if (isEmpty(quantity))
        return "";

    quantity = quantity.toString().replace(/[^0-9\\.]+/g, ""); //Limpia caracteres invalidos


    const arrayQuantity = quantity.split('.');
    const arrayValue = unitValue.toString().split('.');


    const integerQuantity = arrayQuantity[0] ?? "";
    const decimalQuantity = arrayQuantity[1] ?? "";
    const integerValue = arrayValue[0] ?? "";

    if (integerQuantity.length + integerValue.length > lengthValid)
        maxLength = lengthValid - integerValue.length;

    let decimal = `${integerQuantity.substr(0, maxLength)}${quantity.indexOf('.') !== -1 ? '.' : ''}${decimalQuantity.substr(0, DIGITS_AFTER_DECIMAL)}`;
    return decimal;

}


export const validateUnitValueItem = (unitValue, quantity) => {

    let maxLength = DIGITS_BEFORE_DECIMAL;
    const lengthValid = 15;
    if (isEmpty(unitValue))
        return "";

    unitValue = unitValue.toString().replace(/[^0-9\\.]+/g, ""); //Limpia caracteres invalidos

    const arrayValue = unitValue.split('.');
    const arrayQuantity = quantity.toString().split('.');

    const integerValue = arrayValue[0] ?? "";
    const decimalValue = arrayValue[1] ?? "";
    const integerQuantity = arrayQuantity[0] ?? "";

    if (integerValue.length + integerQuantity.length > lengthValid)
        maxLength = lengthValid - integerQuantity.length;

    return `${integerValue.substr(0, maxLength)}${unitValue.indexOf('.') !== -1 ? '.' : ''}${decimalValue.substr(0, DIGITS_AFTER_DECIMAL)}`;

}


export const incrementQuantityItem = (unitValue, quantity) => {

    const lengthValid = 15;
    quantity = quantity !== '' ? parseFloat(quantity) : 0;
    let newQuantity = roundDecimalValue(quantity + 1, DIGITS_AFTER_DECIMAL);

    const arrayNewQuantity = newQuantity.toString().split('.');
    const arrayValue = unitValue.toString().split('.');

    const integerNewQuantity = arrayNewQuantity[0] ?? "";
    const integerValue = arrayValue[0] ?? "";

    if (integerNewQuantity.length + integerValue.length > lengthValid)
        return quantity;
    else
        return newQuantity

}

export const decrementQuantityItem = (quantity) => {

    if (quantity === null || quantity === undefined || quantity.length <= 0)
        return 1;

    const arrayQuantity = quantity.toString().split('.');

    let integerQuantity = arrayQuantity[0] ?? "";
    const decimalQuantity = arrayQuantity[1] ?? "";

    if (parseFloat(integerQuantity) > 0)
        integerQuantity = parseFloat(integerQuantity) - 1;

    let newQuantity = parseFloat(`${integerQuantity}${quantity.toString().indexOf('.') !== -1 ? '.' : ''}${decimalQuantity}`);
    return newQuantity <= 0 ? 1 : newQuantity;
}