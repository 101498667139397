import jwt_decode from 'jwt-decode';

export const getEnterpriseId=()=> {
    const company = JSON.parse(localStorage.getItem("dataCompany"));
    return !!company ? company.companyId: 0;
  }
  

export function getUserId() {
    const decodedToken = jwt_decode(localStorage.getItem("jwtToken"));
    if (decodedToken)
        return decodedToken.nameid;
    else
        return 0;

}

export const getUserIdSecurity = () => {
    const data = JSON.parse(localStorage.getItem("dataCompany"));
    let id = isNaN(data?.userId)
        ? 0
        : data?.userId ?? 0;
    return id;

}