import { Card, CardMedia, Grid, makeStyles } from "@material-ui/core";
import React from "react";

import { FormatDecimal } from "../../../components/common/formatDecimal";

const useStyles = makeStyles({
  cover: {
    width: "100%",
    height: "100%",
  },
});

const ArticlesDocuSupport = ({ detail }) => {
  const classes = useStyles();

  return (
    <div>
      <div className="spacingInvoice" />
      <div className="cardArticleSupport">
        <div className="titleArticle" style={{ wordBreak: "break-word" }}>
          {detail?.descripcion}
        </div>
        <div className="lineDivisionSupport" />
        <Grid container>
          <Grid item lg={3} md={3} sm={12} className="contentImgDetail">
            <Card
              // key={items.id}
              style={{
                height: "90%",
                width: "97%",
                backgroundColor:
                  detail?.color === null ||
                  detail?.color === undefined ||
                  detail?.color.length === 0
                    ? "#33BBFF"
                    : detail?.color,
              }}
            >
              <CardMedia
                className={classes.cover}
                image={`https://qanet.colfactura.com${detail?.imagen}`}
                style={{
                  background: `${
                    detail?.imagen === null ||
                    detail?.imagen === undefined ||
                    detail?.imagen.length === 0
                      ? detail?.color === null ||
                        detail?.color === undefined ||
                        detail?.color.length === 0
                        ? "#33BBFF"
                        : detail?.color
                      : null
                  }`,

                  textAlign: "center",
                }}
              >
                {detail?.imagen === null ||
                detail?.imagen === undefined ||
                detail?.imagen.length === 0 ? (
                  <h1 className={classes.h1Card}>
                    {detail?.descripcion?.substring(0, 2).toUpperCase()}
                  </h1>
                ) : null}
              </CardMedia>
            </Card>
          </Grid>
          <Grid item lg={9} md={9} sm={12}>
            <Grid container className="fieldsReadContainer">
              <Grid
                item
                xs={11}
                lg={6}
                style={{
                  padding: "0 5px",
                }}
              >
                <div className="valueinfoCard">Valor Unitario</div>
                <div className="unitValueArticle">
                  <FormatDecimal
                    value={detail?.valorUnitario || detail?.valorunitario}
                    prefix={"$"}
                  />
                </div>
                <div className="lineDivisionSupport" />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{
                  padding: "0 5px",
                }}
              >
                <div className="valueinfoCard">cantidad</div>
                <div className="amountArticle">
                  <FormatDecimal value={detail?.cantidad} />
                </div>
                <div className="lineDivisionSupport" />
              </Grid>
            </Grid>
            <div className="spacingInvoice" />
            <Grid
              container
              style={{
                padding: "0 5px",
              }}
            >
              <Grid item xs={12} className="valueinfoCard">
                Impuestos:
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container className="infoIva">
                  <Grid item xs={6}>
                    IVA:{" "}
                    <span className={classes.greenText}>
                      <FormatDecimal
                        value={detail?.porcentajeIva || detail?.porcentajeiva}
                        suffix={"%"}
                        size={"inherit"}
                      />
                    </span>
                  </Grid>
                  {/* <Grid item xs={6}>
                    INC:{" "}
                    <span className={classes.greenText}>
                      <FormatDecimal
                        value={detail?.porcentajeinc}
                        suffix={"%"}
                        size={"inherit"}
                      />
                    </span>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <div className="spacingInvoice" />
          </Grid>
        </Grid>
        <div className="lineDivisionSupport" />
        <div className="spacingInvoice" />

        <Grid container>
          <Grid item xs={12} className="totalNumber">
            <span className="totalNumberSpan">Total:</span>
            <FormatDecimal value={detail?.total} prefix={"$"} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ArticlesDocuSupport;
