import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

import CustomProgress from "../../components/Progress/progress.component";
import { FormatDecimal } from "../../components/common/formatDecimal";

import { format } from "date-fns";

import { standOutDocumentFormListAction } from "../../actions/documentReceivedActions";

const animate = " animate__animated animate__fadeIn animate__faster";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.11)",
    color: theme.palette.primaryColor,
    "& .MuiCardContent-root": {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
    },
    cursor: "pointer",
  },
  selected: {
    width: "100%",
    backgroundColor: "#16B1F3",
    color: theme.palette.primaryColor,
    "& .MuiCardContent-root": {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
    },
    cursor: "pointer",
  },
  header: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  content: {
    fontSize: 14,
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: 20,
    fontWeight: 800,
    textAlign: "justify",
  },
  iconAction: {
    color: theme.palette.primaryColor,
  },
  actions: {
    display: "flex",
  },
  spaceBetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  styleValue: {
    color: theme.palette.eleventhBackgroundColor,
    fontSize: 20,
    fontWeight: 800,
  },
  styleValueAccepted: {
    color: theme.palette.secundaryColor,
    fontSize: 20,
    fontWeight: 800,
  },
  styleValueRejected: {
    color: theme.palette.sixthColor,
    fontSize: 20,
    fontWeight: 800,
  },
  styleValueSelected: {
    fontSize: 20,
    fontWeight: 800,
  },
  styleType: {
    color: theme.palette.eleventhBackgroundColor,
    fontSize: 18,
    fontWeight: 500,
  },
  styleTypeAccepted: {
    color: theme.palette.secundaryColor,
    fontSize: 18,
    fontWeight: 500,
  },
  styleTypeRejected: {
    color: theme.palette.sixthColor,
    fontSize: 18,
    fontWeight: 500,
  },
  styleTypeSelected: {
    fontSize: 18,
    fontWeight: 500,
  },
  iconButton: {
    padding: "12px 3px",
  },
}));

export const ReceivedCard = React.memo(
  ({
    id,
    razonsocialemisor,
    fecha,
    valor,
    numero,
    favorito,
    onClick,
    selectedId,
    loading = false,
    estadoaceptacion,
    estadoaceptacionid,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    /**
     * Actualiza estado favorito
     * @param {*} e
     */
    const handleStandOut = () => {
      dispatch(standOutDocumentFormListAction(id, !favorito));
    };

    return (
      <Card
        className={`${
          selectedId === id ? classes.selected : classes.root
        } ${animate}`}
      >
        <CardHeader
          className={classes.header}
          action={
            <div className={classes.actions}>
              <Tooltip title="Destacar">
                {loading === true ? (
                  <div style={{ padding: "14px 15px 0px 15px" }}>
                    <CustomProgress size={15} />
                  </div>
                ) : (
                  <IconButton
                    aria-label="Destacar"
                    onClick={handleStandOut}
                    className={classes.iconButton}
                  >
                    {favorito ? (
                      <StarIcon className={classes.iconAction} />
                    ) : (
                      <StarBorderIcon className={classes.iconAction} />
                    )}
                  </IconButton>
                )}
              </Tooltip>
            </div>
          }
          classes={{
            title: classes.title,
          }}
          title={<div onClick={() => onClick(id)}>{razonsocialemisor}</div>}
        />
        <CardContent className={classes.content} onClick={() => onClick(id)}>
          <Grid container>
            <Grid item lg={12} xs={12} className={classes.spaceBetween}>
              <div>
                {!!fecha ? format(new Date(fecha), "dd/MM/yyyy") : "dd/MM/yyyy"}
              </div>
              <div
                className={
                  selectedId === id
                    ? classes.styleValueSelected
                    : estadoaceptacionid === 3
                    ? classes.styleValueAccepted
                    : estadoaceptacionid === 4
                    ? classes.styleValueRejected
                    : classes.styleValue
                }
              >
                <FormatDecimal value={valor} prefix={"$"} />
              </div>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.spaceBetween}>
              <div>Factura: {numero}</div>
              <div
                className={
                  selectedId === id
                    ? classes.styleTypeSelected
                    : estadoaceptacionid === 3
                    ? classes.styleTypeAccepted
                    : estadoaceptacionid === 4
                    ? classes.styleTypeRejected
                    : classes.styleType
                }
              >
                {estadoaceptacion ?? "Pendiente"}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);
