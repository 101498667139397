import {
  ChargesSection,
  DiscountsSection,
  DocumentTotalSection,
  NotesSection,
  RetentionICASection,
  RetentionIVASection,
} from "../documentCartSections";

/**
 * * Obtiene las secciones del carrito de documentos.
 *
 * @param {Object} options - Opciones para obtener las secciones del carrito de documentos.
 * @param {string} options.typedocument - Tipo de documento.
 * @returns {Object} - Objeto que contiene las secciones del carrito de documentos.
 */
export const getDocumentCartSections = ({ typedocument }) => ({
  sectionDocumentTotal: {
    component: DocumentTotalSection,
    props: { typedocument },
  },
  sectionReteIva: {
    component: RetentionIVASection,
    props: {},
  },
  sectionRetIca: {
    component: RetentionICASection,
    props: {},
  },
  sectionCharges: {
    component: ChargesSection,
    props: {},
  },
  sectionDiscounts: {
    component: DiscountsSection,
    props: {},
  },
  sectionNotes: {
    component: NotesSection,
    props: { typedocument },
  },
});
