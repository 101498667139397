export const filterCustomerList = (filter, list) => {
    if (!!filter && filter !== '') {
        list = list.filter(
            (c) =>
                c.text.toLowerCase().match(new RegExp(filter.toLowerCase() + ".*")) ||
                c.documento.toLowerCase().match(new RegExp(filter.toLowerCase() + ".*"))
        );
    }
    return list;

}