import { DIGITS_AFTER_DECIMAL } from "../config/config";
import { GetUVTYear } from "./getUvtYear";

/**
 * * Crea manejadores de impuestos para varios tipos de impuestos .
 * @param {Object} taxList - El objeto para almacenar información relacionada con los impuestos.
 * @param {Object} allUvt - El objeto que contiene información sobre UVT (Unidad de Valor Tributario).
 * @returns {Object} - Objeto con métodos para manejar diferentes tipos de impuestos.
 */

const createTaxHandlers = (taxList, allUvt) => ({
  IVA: (valor, taxFound) => {
    taxList.valoriva = valor === 999 ? taxFound.otrovalor : valor;
    taxList.chkArticleIva = true;
    taxList.taxIvaID = taxFound.impuestoid;
  },
  INC: (valor, taxFound) => {
    taxList.valorinc = valor === 999 ? taxFound.otrovalor : valor;
    taxList.chkArticleInc = true;
    taxList.taxIncID = taxFound.impuestoid;
  },
  IBUA: (valor, taxFound) => {
    taxList.valoribua = valor;
    taxList.chkArticleIbua = true;
    taxList.cantidadmililitros = taxFound.cantidad;
    taxList.taxIbuaID = taxFound.impuestoid;
  },
  ICUI: (valor, taxFound) => {
    taxList.valoricui = valor;
    taxList.chkArticleIcui = true;
    taxList.taxIcuiID = taxFound.impuestoid;
  },
  ICL: (valor, taxFound) => {
    taxList.chkArticleIcl = true;
    taxList.drinkTypeIcl =
      taxFound.cantidadcc === 750 ? valor : taxFound.otrovalor;
    taxList.cubicCentimetersIcl = taxFound.cantidadcc;
    taxList.alcoholLevelIcl = taxFound.cantidad;
    taxList.taxIclID = taxFound.impuestoid;
  },
  INPP: (valor, taxFound) => {
    const uvtx5 = GetUVTYear(new Date().getFullYear(), allUvt) * 0.00005;
    const uvt = uvtx5.toLocaleString("en", {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });
    taxList.chkArticleInpp = true;
    taxList.weightInGramsInpp = taxFound.cantidad;
    taxList.taxRateUvt = uvt;
    taxList.taxInppID = taxFound.impuestoid;
  },
  ADV: (valor, taxFound) => {
    taxList.chkArticleAdV = true;
    taxList.taxAdvID = taxFound.impuestoid;
    taxList.valoradv = valor;
  },
});

export default createTaxHandlers;
