import { getDetailDocument } from "../../../helpers/generateRequestDocument";
import { formatDate } from "../../../utils/general";
import { roundDecimalValue } from "../../../utils/managementNumber";

export const generateRequestEquivalentDoc = ({ equivalentDoc, totalsCart, customerDoc }) => {
  const {
    boxLocation,
    boxPlate,
    boxType,
    cashier,
    dianPaymentMethodID,
    documentDate,
    favorite,
    numericalID,
    observations,
    paymentDate,
    paymentMethod,
    saleCode,
    subtotal,
  } = equivalentDoc;

  let {
    opdetalledocumento,
    redondeo,
    totalfacturaReal,
    valoradvreal,
    valorapagar,
    valorapagardian,
    valorbaseimponible,
    valorbruto,
    valorcargos,
    valordescuento,
    valordescuentoreal,
    valoribuareal,
    valoriclreal,
    valoricuireal,
    valorinc,
    valorincreal,
    valorinppreal,
    valoriva,
    valorivareal,
    valorotrosdescuentos,
    valorretfte,
    valorretica,
    valorretiva,
    valortotal,
  } = totalsCart;

  const { customerId, addressId, enterpriseId } = customerDoc;
  let generateddocument = {
    Opdocumento: {
      Archivovisualizar: "EQUIVALENT DOCUMENT",
      Clienteid: customerId,
      Contactoclienteid: null, //! Pendiente de implementar
      Decimales: 2,
      Dianformapagoid: paymentMethod === "1" ? 2 : 1,
      Dianmediopagoid: paymentMethod === "2" ? dianPaymentMethodID : null,
      Diantipodocumentoelectronicoid: 7,
      Direccionclienteid: addressId,
      Empresaid: enterpriseId,
      Estadodocumentoid: 0,
      Favorito: favorite,
      Fecha: documentDate,
      Fechapago: !!paymentDate && paymentMethod === "1" ? paymentDate : null,
      Generardocumentopacientes: false,
      Numeracionid: numericalID,
      Origendocumentoid: 1,
      Redondeo: redondeo,
      Responsabilidadtributariaid: null,
      Subtotal: valorbruto,
      Textoobservacion: observations,
      Tipoaceptaciondocumentoid: 1,
      Tipoacusedocumentoid: 3,
      TotalBaseImponible: valorbaseimponible,
      TotalfacturaReal: totalfacturaReal,
      Valoradv: valoradvreal === null ? null : valoradvreal,
      Valoranticipo: 0,
      Valorapagar: valorapagar,
      Valorapagardian: valorapagardian,
      Valorcargodocumento: valorcargos,
      Valorcargos: valorcargos,
      Valordescuento: valordescuento,
      Valordescuentodocumento: valorotrosdescuentos,
      Valordescuentoreal: valordescuentoreal,
      Valoribua: valoribuareal === null ? null : valoribuareal,
      Valoricl: valoriclreal === null ? null : valoriclreal,
      Valoricui: valoricuireal === null ? null : valoricuireal,
      Valorinc: valorinc,
      Valorincreal: valorincreal,
      Valorinpp: valorinppreal === null ? null : valorinppreal,
      Valoriva: valoriva,
      Valorivareal: valorivareal,
      Valorretfte: valorretfte,
      Valorretica: valorretica,
      Valorretiva: valorretiva,
      Valortotal: valortotal,
    },
    Lstopdetalledocumento: getDetailDocument(opdetalledocumento),
    Datoscaja: {
      Codigoventa: saleCode,
      Nombrecajero: cashier,
      Placa: boxPlate,
      Subtotal: subtotal,
      Tipo: boxType,
      Ubicacion: boxLocation,
    },
    Lstcargos: [],
    Lstdescuentos: [],
    Datosadicionales: [],
    Documentosreferencia: [],
    Oppacientes: [],
  };

  return generateddocument;
};

export const generateRequestEquivalentDocNote = ({ equivalentDocNote, totalsCart, isAnnulment = false, observationAnnulment = "" }) => {
  const {
    adclientedirecciones,
    caja,
    clientedv,
    clienteid,
    correoElectronico,
    dianformapagoid,
    dianmediopagoid,
    diantipodocumentoidentidadid,
    diantipopersonaid,
    documento,
    empresaid,
    estadodocumentoid,
    favorito,
    fechaPago,
    id,
    nombreCliente,
    numeracionid,
    numero,
    origendocumentoid,
    telefono,
    tipoaceptaciondocumentoid,
  } = equivalentDocNote;

  let {
    opdetalledocumento,
    redondeo,
    totalfacturaReal,
    valoradvreal,
    valorapagar,
    valorapagardian,
    valorbaseimponible,
    valorbruto,
    valorcargos,
    valordescuento,
    valordescuentoreal,
    valoribuareal,
    valoriclreal,
    valoricuireal,
    valorinc,
    valorincreal,
    valorinppreal,
    valoriva,
    valorivareal,
    valorotrosdescuentos,
    valorretfte,
    valorretica,
    valorretiva,
    valortotal,
    motivonota,
    observacionesnota,
  } = totalsCart;

  let generateddocument = {
    Opdocumento: {
      Fecha: formatDate(Date.now(), "yyyy-MM-dd"),
      Numeracionid: numeracionid,
      Estadodocumentoid: estadodocumentoid,
      Tipoaceptaciondocumentoid: tipoaceptaciondocumentoid,
      Tipoacusedocumentoid: 3,
      Valorapagar: valorapagar,
      Dianformapagoid: dianformapagoid,
      Valordescuento: valordescuento,
      Valorcargos: valorcargos,
      Subtotal: valorbruto,
      TotalBaseImponible: valorbaseimponible,
      Valortotal: valortotal,
      Valordescuentodocumento: valorotrosdescuentos,
      Valorcargodocumento: valorcargos,
      Valoranticipo: 0,
      Valorinc: valorinc,
      Valoriva: valoriva,
      Textoobservacion: isAnnulment ? observationAnnulment : observacionesnota,
      Vendedorid: null,
      Diantipodocumentoelectronicoid: 8, //Nota Credito
      Clienteid: clienteid,
      Dianmediopagoid: dianmediopagoid,
      Origendocumentoid: origendocumentoid,
      Dianconceptonotacreditoid: isAnnulment ? 2 : motivonota.id,
      Fechapago: fechaPago,
      Favorito: favorito,
      Empresaid: empresaid,
      Incluirvendedor: false,
      Documentoreferenciaid: id,
      Documentoref: numero,
      Valordescuentoreal: valordescuentoreal,
      Valorivareal: valorivareal,
      Valorincreal: valorincreal,
      Valoradv: valoradvreal === null ? null : valoradvreal,
      Valoricui: valoricuireal === null ? null : valoricuireal,
      Valoribua: valoribuareal === null ? null : valoribuareal,
      Valoricl: valoriclreal === null ? null : valoriclreal,
      Valorinpp: valorinppreal === null ? null : valorinppreal,
      TotalfacturaReal: totalfacturaReal,
      Redondeo: redondeo,
      Decimales: 4,
      Valorretfte: valorretfte,
      Valorretica: valorretica,
      Valorretiva: valorretiva,
      Valorapagardian: valorapagardian,
      Cliente: {
        Id: clienteid,
        Documento: documento,
        Documentodv: clientedv,
        Razonsocial: nombreCliente,
        Telefono: telefono,
        Correoelectronico: correoElectronico,
        Diantipodocumentoidentidadid: diantipodocumentoidentidadid,
        Diantipopersonaid: diantipopersonaid,
        Clientedireccion: adclientedirecciones?.[0] ?? null,
      },
    },
    Lstopdetalledocumento: getDetailDocumentNote(opdetalledocumento),
    Datoscaja: {
      Codigoventa: caja.codigoVenta,
      Nombrecajero: caja.nombreCajero,
      Placa: caja.placa,
      Subtotal: caja.subtotal,
      Tipo: caja.tipo,
      Ubicacion: caja.ubicacion,
    },
    Lstcargos: [],
    Lstdescuentos: [],
    Datosadicionales: [],
    Documentosreferencia: [],
  };

  return generateddocument;
};

export const getDetailDocumentNote = (items) => {
  const opdetalledocumento = items.map((item) => ({
    codigo: item.codigo,
    descripcion: item.descripcion,
    cantidad: item.cantidad,

    valorunitario: item.valorneto,
    porcentajedescuento: item.porcentajedescuento,
    valordescuento: item.valordescuento,
    id: !item.detalleid ? 0 : item.detalleid,
    dianunidadmedidaid: item.dianunidadmedidaid,
    articuloid: item.articuloid,
    totaliva: item.totaliva,
    totalinc: item.totalinc,
    total: item.total,
    subtotal: item.subtotal,
    tipodescuento: item.tipodescuento,
    articuloId: item.articuloId,
    totaldescuento: item.totaldescuento,
    valordescuentocomercial: item.valordescuentocomercial,
    pororrateo: item.prorrateo,
    totaldescuentoreal: item.totaldescuentoreal,
    subtotalreal: item.subtotalreal,
    totalivareal: item.totalivareal,
    totalincreal: item.totalincreal,
    TotalIcui: item.totalicuireal,
    TotalAdv: item.totaladvreal,
    PorcentajeIcui: item.porcentajeicui,
    PorcentajeAdv: item.porcentajeadv,
    TotalIbua: item.totalibuareal === null ? null : item.totalibuareal,
    CantidadMililitros: item.cantidadmililitros === null ? null : item.cantidadmililitros * item.cantidad,
    TarifaIbua: item.valoribua === null ? null : item.valoribua,
    TotalIcl: item.totaliclreal === null ? null : item.totaliclreal,
    CantidadGrados: item.gradosalcohol === null ? null : item.gradosalcohol,
    TarifaIcl: item.tipobebida === null ? null : roundDecimalValue(item.tipobebida * item.cantidad),
    TotalInpp: item.totalinppreal === null ? null : item.totalinppreal,
    CantidadGramos: item.pesogramos === null ? null : item.pesogramos * item.cantidad,
    TarifaInpp: item.tasauvtimp === null ? null : item.tasauvtimp,
    totalreal: item.totalreal,
    costo: item.costo ?? 0,
    porcentajeiva: item.porcentajeiva,
    porcentajeinc: item.porcentajeinc,
    idretfte: item.idretfte,
    baseretfte: item.baseretfte,
    tarifaretfte: item.tarifaretfte,
    valorretfte: item.valorretfte,
    idretica: item.idretica,
    baseretica: item.baseretica,
    tarifaretica: item.tarifaretica,
    valorretica: item.valorretica,
    idretiva: item.idretiva,
    baseretiva: item.baseretiva,
    tarifaretiva: item.tarifaretiva,
    valorretiva: item.valorretiva,
    aplicaregalo: item.aplicaregalo ?? false,
    esregalo: item.esregalo ?? false,
    asumeresponsabilidad: !item.asumeresponsabilidad ? null : item.asumeresponsabilidad,
    valorreferencia: !item.valorreferencia ? null : item.valorreferencia,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), "yyyy-MM-dd"),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), "yyyy-MM-dd"),
    idbusinessunit: 1,
    idowner: 1,
  }));

  return opdetalledocumento;
};
