import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import InputForm from "../../../components/input/input.component";
import SelectForm from "../../../components/select/select.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import DateForm from "../../../components/datepicker/datepicker.component";

import { useFormComplete } from "../../../hooks/useFormComplete";

import {
	addDocumentReferenceAction,
	updatedDocumentReferenceAction,
} from "../../../actions/documentReferenceAction";

import generateId from "../../../utils/generateId";
import {
	regexDecimal,
	regexOrder,
	regexAlphaNumericAndSymbols,
} from "../../../helpers/customRegex.hepers";
import { format } from "date-fns";

const useStyles = makeStyles({
	containerAction: {
		justifyContent: "flex-end",
	},
	legend: {
		color: "white",
		fontSize: 15,
		fontStyle: "italic",
		textAlign: "center",
	},
});

export const ReferenceDocumentForm = () => {
	const classes = useStyles();
	const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
		.current;
	const [, forceUpdate] = useState();
	const dispatch = useDispatch();
	const [disabledType, setdisabledType] = useState(false);
	const [availableTypes, setAvailableTypes] = useState([]);
	const [showMessage, setShowMessage] = useState(false);
	const {
		values,
		handleInputChange,
		handleUpdateForm,
		reset,
	} = useFormComplete({
		id: "",
		typeDocument: "",
		numberReference: "",
		paidValue: "",
		dateReference: format(new Date(), "yyyy/MM/dd"),
	});

	const {
		id,
		typeDocument,
		numberReference,
		paidValue,
		dateReference,
	} = values;

	const { typesDocumentReference } = useSelector((s) => s.configReducer);
	const { escontingencia } = useSelector((s) => s.documentReducer);
	const {
		opdocumentosreferencia,
		documentoreferenciaactivo: activo,
	} = useSelector((s) => s.documentReferenceReducer);

	useEffect(() => {
		if (!!activo) {
			let dataForm = {
				id: activo.id,
				typeDocument: activo.tipodocumentoid,
				numberReference: activo.numero,
				paidValue: activo.valorapagar ?? "",
				dateReference: format(new Date(activo.fecha), "yyyy/MM/dd"),
			};

			if (activo?.tipodocumentoid.toString() === "9") {
				setdisabledType(true);
			}

			handleUpdateForm(dataForm);
		} else {
			reset();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activo]);

	/**
	 * Lee estado generación factura, numeración tipo continencia
	 */
	useEffect(() => {
		const exist = opdocumentosreferencia.find(
			(c) => c.tipodocumentoid?.toString() === "9"
		);

		if (escontingencia && !exist) {
			setdisabledType(true);
			handleUpdateForm({
				typeDocument: "9",
				numberReference: "",
				paidValue: "",
				dateReference: format(new Date(), "yyyy/MM/dd"),
			});
		} else {
			setdisabledType(false);
			handleUpdateForm({
				typeDocument: "",
				numberReference: "",
				paidValue: "",
				dateReference: format(new Date(), "yyyy/MM/dd"),
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [escontingencia, opdocumentosreferencia]);

	/**
	 * Carga listado tipos documentos de refrencia disponibles
	 */
	useEffect(() => {
		let types = typesDocumentReference;
		if (!escontingencia) {
			types = typesDocumentReference.filter((c) => c.id !== 9);
		}

		setAvailableTypes(types);
	}, [typesDocumentReference, escontingencia]);

	useEffect(() => {
		const contingency = opdocumentosreferencia.filter(
			(c) => c.tipodocumentoid.toString() === "9"
		);

		setShowMessage(contingency.length > 0 ? false : true);
	}, [opdocumentosreferencia]);

	/**
	 * Sincroniza nuevo valor input con state
	 * @param {*} e
	 */
	const handleChangeValue = (e) => {
		let regex = parseInt(typeDocument) === 4 ? regexOrder : regexAlphaNumericAndSymbols;
		e.target.value = e.target.value.replace(regex, "");
		handleInputChange(e);
	};

	/**
	 * Sincroniza nuevo valor datePicker
	 * @param {*} id Identificador
	 * @param {*} date Nueva Fecha
	 * @param {*} name Nombre datePicker
	 */
	const handleChangeDate = (id, date, name) => {
		handleInputChange({
			target: {
				name: name,
				value: new Date(date).toISOString(),
			},
		});
	};

	/**
	 * Limpia y sicroniza valor decimal con state
	 * @param {*} e
	 */
	const handleChangeDecimal = (e) => {
		e.target.value = e.target.value.replace(regexDecimal, "");
		handleInputChange(e);
	};

	/**
	 * Guarda o actualiza documento referencia
	 * @param {*} e
	 */
	const onSaveForm = (e) => {
		e.preventDefault();
		const formValid = validator.allValid();
		if (!formValid) {
			validator.showMessages();
			forceUpdate(1);
		} else {
			const newData = {
				id: !id ? generateId() : id,
				tipodocumentoid: typeDocument,
				tipodocumentodescripcion: typesDocumentReference.find(
					(c) => c.value === typeDocument
				)?.text,
				numero: numberReference,
				fecha: dateReference,
				valorapagar: typeDocument === "1" ? paidValue : "",
			};

			if (id !== "" && id !== null) {
				dispatch(updatedDocumentReferenceAction(newData));
			} else {
				dispatch(addDocumentReferenceAction(newData));
			}

			reset();
			validator.hideMessages();
		}
	};

	return (
		<>
			<Grid container>
				<Grid item lg={12} xs={12}>
					{escontingencia && showMessage && (
						<div className={classes.legend}>
							{`Al seleccionar una resolución de tipo "Talonario Contingencia FE" es necesario que especifiques en el siguiente formulario los datos de tu "Factura talonario”.`}
						</div>
					)}
				</Grid>
				<Grid item lg={6} xs={12}>
					<SelectForm
						label={"Tipo de documento *"}
						name="typeDocument"
						value={typeDocument}
						options={availableTypes}
						disabled={disabledType}
						validator={validator}
						validateOptions={"required"}
						nameValidator={"typeDocument"}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item lg={6} xs={12}>
					<InputForm
						name="numberReference"
						maxLength={20}
						label={"Numero *"}
						value={numberReference}
						validator={validator}
						validateOptions={"required|alphanumericAndSymbols"}
						onChange={handleChangeValue}
					/>
				</Grid>
				<Grid item lg={6} xs={12}>
					<DateForm
						name={"dateReference"}
						value={dateReference}
						onChange={handleChangeDate}
						label="Fecha *"
						disableFuture={true}
					/>
				</Grid>

				<Grid
					item
					lg={6}
					xs={12}
					style={{ display: typeDocument === "1" ? "block" : "none" }}
				>
					<InputForm
						name="paidValue"
						label={"Valor del pago *"}
						value={paidValue}
						maxLength={20}
						validator={validator}
						validateOptions={
							typeDocument === "1" ? "required|min:4|max:20" : "void"
						}
						format={true}
						onChange={handleChangeDecimal}
					/>
				</Grid>
			</Grid>
			<Grid container className={classes.containerAction}>
				<Grid item lg={6} xs={12}>
					<ButtonPrimary
						text={!!activo ? "Editar" : "Listo"}
						loading={false}
						type={"button"}
						onClick={onSaveForm}
					/>
				</Grid>
			</Grid>
		</>
	);
};
