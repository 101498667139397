import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/*export const SuccessAlert = (title, message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        icon: 'success',
        title: <p>{title}</p>,
        text: message,
        background: '#000000'
    })
}*/

/*export const SuccessAlertConfirm = (message, callback) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        icon: 'success',
        title: '',
        text: message,
        background: '#000000',
        confirmButtonText: 'Aceptar',
    }).then(function (confirmed) {
        callback(confirmed && confirmed.value === true)
    })
}*/

/*export const WarningAlert = (title, message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        icon: 'warning',
        title: <p>{title}</p>,
        text: message,
        background: '#000000'
    })
} */

/*export const ConfirmAlert = (title, message, callback) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        title: <p>{title}</p>,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        background: '#000000'
    }).then(function (confirmed) {
        callback(confirmed && confirmed.value === true)
    })
}*/

export const SuccessAlertConfirm = (message, callback) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `<div text-align="left" color="red"><font color="white">${message} </font></div>`,
    width: 400,
    heightAuto: true,
    allowOutsideClick: false,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
    confirmButtonText: "Aceptar",
  }).then(function(confirmed) {
    callback(confirmed && confirmed.value === true);
  });
};

export const WarningAlert = (title = "", message = "", time = 3000) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: `<font size="4" color="#F5D13D">${title}</font>`,
    html: `<p><font color="white">${message} </font></p>`,
    width: 400,
    heightAuto: true,
    timer: time,
    showConfirmButton: false,
    allowOutsideClick: true,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

export const WarningAlertConfirm = (message, callback) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "warning",
    title: "",
    text: message,
    confirmButtonText: "Aceptar",
    allowOutsideClick: false,
  }).then(function(confirmed) {
    callback(confirmed && confirmed.value === true);
  });
};

export const ErrorAlert = (title, message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    title: <p>{title}</p>,
    text: message,
    width: 600,
  });
};

export const SuccessAlert = (tittle, message, time) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: `<font size="4" color="#F5D13D">${
      tittle === null ? "Buen trabajo!!!" : tittle
    }</font>`,
    html: `<p><font color="white">${message} </font></p>`,
    width: 400,
    heightAuto: true,
    timer: time,
    showConfirmButton: false,
    allowOutsideClick: true,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

export const ConfirmAlert = (
  message,
  callback,
  txtConfirm = "Confirmar",
  txtCancel = "Cancelar"
) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `<p align="left"><font color="white">${message} </font></p>`,
    width: 430,
    heightAuto: true,
    allowOutsideClick: false,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: txtConfirm,
    cancelButtonText: txtCancel,
    reverseButtons: true,
  }).then(function(confirmed) {
    callback(confirmed && confirmed.value === true);
  });
};

export const LoadMassive = (title, message) => {
  var el = document.createElement("span"),
    t = document.createTextNode("Custom HTML Message!!");
  el.style.cssText = "color:#F6BB42";
  el.appendChild(t);
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html:
      '<font size="5" color="#00BFFF">Cargue masivo de artículos</font>' +
      '<p align="left"><font color="white"> Bienvenido al cargue masivo, aquí podras cargar toda la información  de varios artículos al mismo tiempo, solo debes segir estas instrucciones:</font></p>, ' +
      '<p align="left"><font color="white" ><font color="#00BFFF" >1.</font> Descarga la plantilla de cargue, deberas completarla con la información solicitada de cada artículo que desea subir;por favor, no la modifiques ni cambies el nombre del archivo.</font></p>' +
      '<p align="left"><font  color="white"><font color="#00BFFF" >2.</font> Sube la plantilla con la información diligenciada mediante el botón "Cargar Plantilla Masiva", nosotros haremos el resto...tu,tranquilo.</font></p>' +
      '<p align="left"><font color="white"><font color="#00BFFF" >3.</font> Listo!!!, La plataforma te mostrará el resultado del cargue, por favor verifica que todo quede en orden. </font></p>',
    showCancelButton: true,
    confirmButtonColor: "#00BFFF",
    cancelButtonColor: "#00BFFF",
    confirmButtonText: "Descargar Plantilla",
    cancelButtonText: "Cargar Plantilla Masiva",
    padding: "3em",
    background: "#2E2E2E",
    showCloseButton: true,
    position: "center-right",
    backdrop: `
        rgba(0,0,0,0.4)
        left top
        no-repeat`,
    width: "80%",
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

export const ShowTemplateAlert = (title, src) => {
  var el = document.createElement("span"),
    t = document.createTextNode("Custom HTML Message!!");
  el.style.cssText = "color:#F6BB42";
  el.appendChild(t);
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html:
      '<font size="5" color="#00BFFF">' +
      title +
      "</font>" +
      '<p><img src="' +
      src +
      '" width="83%" height="83%"/></p>',
    confirmButtonColor: "#00BFFF",
    confirmButtonText: "Cerrar",
    padding: "0em",
    background: "#2E2E2E",
    showCloseButton: true,
    position: "center",
    backdrop: `

        rgba(0,0,0,0.4)
        left top
        no-repeat`,
    width: "30%",
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};
