import { DIGITS_AFTER_DECIMAL } from "../config/config";
import {
  calculateTotalDocument,
  calculateTotalDocumentSupport,
  generateDocumentItem,
} from "../helpers/calculateDocumentValues";
import { types } from "../types/types";

/**
 * Actualiza estado, ver oculatr catalogo
 * @param {*} status
 * @returns
 */
export const changeShowCartAction = (status) => (dispatch) => {
  dispatch({
    type: types.SHOW_CATALOG,
    payload: status,
  });
};

/**
 * *Muestra el carrito de  Documento soporte
 */
export const showCartDocumentSupportAction = (status) => (dispatch) => {
  dispatch({
    type: types.SHOW_CART_DOCUMENT_SUPPORT,
    payload: status,
  });
};

/**
 * Carga y calcula totales para listados de items seleccionados desde catalogo
 * @param {*} itemList
 * @returns
 */
export const loadItemsCheckedInCartAction = (itemList) => (
  dispatch,
  getState
) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let detaildocument = [];

  itemList.forEach((item) => {
    const existingItemIndex = opdetalledocumento.findIndex(
      (existingItem) => existingItem.articuloid === item.id
    );

    if (existingItemIndex !== -1) {
      opdetalledocumento[existingItemIndex].cantidad += 1;
    } else {
      let detail = generateDocumentItem(item);
      detaildocument.push(detail);
    }
  });

  let allitems = opdetalledocumento.concat(detaildocument);
  dispatch(
    updateDocumentTotals(
      allitems,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Actualiza y recalcula totales para item
 * @param {*} data
 * @returns
 */
export const updateItemTotalAction = (data) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newItems = opdetalledocumento.map((item) =>
    item.id === data.id ? data : item
  );

  dispatch(
    updateDocumentTotals(
      newItems,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Actualiza información item
 * @param {*} item
 * @returns
 */
export const updateItemAction = (item) => (dispatch) => {
  dispatch({
    type: types.UPDATE_ITEM_DOCUMENT,
    payload: item,
  });
};

/**
 * Elimina item y recalcula totales.
 * @param {*} id
 * @returns
 */
export const deleteItemAction = (id) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newItems = opdetalledocumento.filter((d) => d.id !== id);
  dispatch(
    updateDocumentTotals(
      newItems,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Agrega nuevo cargo a documento, recalcula totales
 * @param {*} charge
 * @returns
 */
export const addChargeDocumentAction = (charge) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newCharges = [];
  if (charge.esglobal) newCharges = [charge, ...opdocumentocargos];
  else newCharges = [...opdocumentocargos, charge];

  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      newCharges,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Actualiza información cargos, recalcula totales
 * @param {*} charge
 * @returns
 */
export const updateChargeDocumentAction = (charge, reset) => (
  dispatch,
  getState
) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newCharges = opdocumentocargos.map((item) =>
    item.id === charge.id ? charge : item
  );

  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      newCharges,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
  reset();
};

/**
 * Elimina cargo de documento, recalcula totales
 * @param {*} id
 * @returns
 */
export const deleteChargeDocumentAction = (id) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newCharges = opdocumentocargos.filter((c) => c.id !== id);
  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      newCharges,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Actualiza cantidad cargo global, recalcula totales
 * @param {*} charge
 * @returns
 */
export const updateQuantityGlobalChargeAction = (charge) => (
  dispatch,
  getState
) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newCharges = opdocumentocargos.map((item) =>
    item.id === charge.id ? charge : item
  );
  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      newCharges,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Agrega nuevo descuento a documento, recalcula totales
 * @param {*} charge
 * @returns
 */
export const addDiscountDocumentAction = (discount) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newDiscounts = [...opdocumentodescuentos, discount];
  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      opdocumentocargos,
      newDiscounts,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Elimina descuento de documento, recalcula totales
 * @param {*} id
 * @returns
 */
export const deleteDiscountDocumentAction = (id) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newDiscounts = opdocumentodescuentos.filter((c) => c.id !== id);
  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      opdocumentocargos,
      newDiscounts,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Actualiza información descuentos, recalcula totales
 * @param {*} discount
 * @returns
 */
export const updateDiscountDocumentAction = (discount, reset) => (
  dispatch,
  getState
) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newDiscounts = opdocumentodescuentos.map((item) =>
    item.id === discount.id ? discount : item
  );

  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      opdocumentocargos,
      newDiscounts,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
  reset();
};

/**
 * Carga retención a item seleccionado, recalcula totales
 * @returns
 */
export const addRteFuenteItemAction = (id, rteFte) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newListItem = opdetalledocumento.map((item) =>
    item.id === id
      ? {
          ...item,
          idretfte: rteFte.id,
          retfte: rteFte,
        }
      : item
  );

  dispatch(
    updateDocumentTotals(
      newListItem,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Carga retención a listado items, recalcula totales
 * @returns
 */
export const addRteFuenteAllItemsAction = (rteFte) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newListItem = opdetalledocumento.map((item) => ({
    ...item,
    idretfte: rteFte.id,
    retfte: rteFte,
  }));

  dispatch(
    updateDocumentTotals(
      newListItem,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Elimina rete fuente de item, recalcula total
 * @param {*} id
 * @returns
 */
export const deleteRteFuenteItemAction = (id) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newListItem = opdetalledocumento.map((item) =>
    item.id === id
      ? {
          ...item,
          idretfte: 0,
          retfte: null,
        }
      : item
  );

  dispatch(
    updateDocumentTotals(
      newListItem,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};

/**
 * Carga rete IVA a listado items, recalcula totales
 * @param {*} tarifaRteiva
 * @returns
 */
export const addRteIvaAllItemsAction = (tarifaRteiva) => (
  dispatch,
  getState
) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retica,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newListItem = opdetalledocumento.map((item) => ({
    ...item,
    idretiva: tarifaRteiva?.id ?? 0,
    retiva: tarifaRteiva ?? null,
  }));

  dispatch(
    updateDocumentTotals(
      newListItem,
      opdocumentocargos,
      opdocumentodescuentos,
      tarifaRteiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
  dispatch({
    type: types.ADD_RETE_IVA_DOCUMENT,
    payload: tarifaRteiva ?? null,
  });
};

/**
 * Carga rete IVA a listado items, recalcula totales
 * @param {*} rteiva
 * @returns
 */
export const addRteIcaAllItemsAction = (retica) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    redondeo,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  let newListItem = opdetalledocumento.map((item) => ({
    ...item,
    idretica: retica?.id ?? 0,
    retica: retica ?? null,
  }));

  dispatch(
    updateDocumentTotals(
      newListItem,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica, // Se debe leer de parametro
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
  dispatch({
    type: types.ADD_RETE_ICA_DOCUMENT,
    payload: retica ?? null,
  });
};

/**
 * Actualiza estado redondeo, recalcula totales
 * @param {*} status
 * @returns
 */
export const changeRoundStatusAction = (status) => (dispatch, getState) => {
  const {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  dispatch(
    updateDocumentTotals(
      opdetalledocumento,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      status,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
  dispatch({
    type: types.UPDATE_ROUND_DOCUMENT,
    payload: status,
  });
};

/**
 * Agrega observaciones  a documento tipo nota
 * @param {*} observation
 * @returns
 */
export const addObservationsNote = (observation) => (dispatch) => {
  dispatch({
    type: types.ADD_OBSERVATIONS_NOTE,
    payload: observation,
  });
};

/**
 * Agrega motivo edición nota
 * @param {*} reason
 * @returns
 */
export const addReasonNoteAction = (reason) => (dispatch) => {
  dispatch({
    type: types.ADD_REASON_NOTE,
    payload: reason,
  });
};

/**
 * Actualiza totales de documento en Redux
 * @param {*} detailDocument
 * @param {*} charges
 * @param {*} discounts
 * @param {*} retiva
 * @param {*} retica
 * @param {*} round
 * @returns
 */
export const updateDocumentTotals = (
  detailDocument,
  charges = [],
  discounts = [],
  retiva,
  retica,
  round = true,
  cartDocumentSupport,
  digitsAfterDecimalDoc = DIGITS_AFTER_DECIMAL
) => {
  let document;
  if (cartDocumentSupport) {
    document = calculateTotalDocumentSupport(
      detailDocument,
      charges,
      discounts,
      retiva,
      retica,
      round,
      cartDocumentSupport
    );
  } else {
    document = calculateTotalDocument(
      detailDocument,
      charges,
      discounts,
      retiva,
      retica,
      round,
      digitsAfterDecimalDoc
    );
  }

  return {
    type: types.UPDATE_TOTALS_DOCUMENT,
    payload: document,
  };
};

/**
 * * Cambia la cantidad de dígitos después del decimal en la acción del documento.
 * @param {number} numberDigits - La cantidad de dígitos después del decimal.
 * @returns {Function} - La función de acción asíncrona.
 */
export const changeDigitsAfterDecimalDocAction = (numberDigits) => async (
  dispatch
) => {
  dispatch({
    type: types.DIGITS_AFTER_DECIMAL_TYPE_DOC,
    payload: numberDigits,
  });
};
