import { types } from "../types/types"


const initialState = {
    addresses: [],
    active: null,
    loading: false

}

export const addressCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ADDRESS_LIST:
            return {
                ...state,
                addresses: action.payload
            }
        case types.ADD_CUSTOMER_ADDRESS:
            return {
                ...state,
                addresses: [...state.addresses, action.payload]
            }
        case types.UPDATE_CUSTOMER_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.map(item =>
                    item.id === action.payload.id
                        ? action.payload
                        : item)
            }
        case types.DELETE_CUSTOMER_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.filter(c => c.id !== action.payload)
            }
        case types.SET_ACTIVE_CUSTOMER_ADDRESS:
            return {
                ...state,
                active: action.payload
            }
        case types.CLEAN_CUSTOMER_ADDRESS:
            return initialState;

        default:
            return state
    }
}