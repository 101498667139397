import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { Grid } from "@material-ui/core";

import InputForm from "../../../components/input/input.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import { useFormComplete } from "../../../hooks/useFormComplete";

import {
  addContactAction,
  updateContactAction,
  setActiveContactAction,
} from "../../../actions/contactCustomerAction";

import generateId from "../../../utils/generateId";

export const ContactForm = React.memo(() => {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const {
    values,
    handleInputChange,
    handleUpdateForm,
    reset,
  } = useFormComplete({
    nombre: "",
    email: "",
    telefono: "",
  });

  const { nombre, email, telefono } = values;

  const { active: activeContact } = useSelector(
    (state) => state.contactCustomerReducer
  );

  /**
   * Carga información para edición
   */
  useEffect(() => {
    if (!!activeContact) {
      handleUpdateForm({
        nombre: activeContact.nombre,
        email: activeContact.email,
        telefono: activeContact.telefono,
      });
    } else {
      reset();
      validator.hideMessages();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContact]);

  /**
   * Guarda o actualiza  informacion contacto adicional
   * @param {*} e
   * @returns
   */
  const handleSave = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(3);
      return;
    }

    let currentContact = {
      id: !activeContact ? generateId() : activeContact.id,
      nombre: nombre,
      telefono: telefono,
      celular: telefono,
      email: email,
    };

    if (!activeContact) {
      dispatch(addContactAction(currentContact));
    } else {
      dispatch(updateContactAction(currentContact));
      dispatch(setActiveContactAction(null));
    }

    reset();
    validator.hideMessages();
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} xs={12}>
        <InputForm
          name={"nombre"}
          label={"Nombre *"}
          maxLength={100}
          value={nombre}
          onChange={(e) => handleInputChange(e, "alphanumeric")}
          nameValidator={"Nombre"}
          validator={validator}
          validateOptions={"required|min:3|max:100"}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <InputForm
          name={"email"}
          label={"Correo electrónico *"}
          maxLength={200}
          value={email}
          onChange={(e) => handleInputChange(e, "email")}
          validator={validator}
          validateOptions={"required|email|min:10|max:200"}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <InputForm
          name={"telefono"}
          label={"Teléfono *"}
          maxLength={15}
          value={telefono}
          onChange={(e) => handleInputChange(e, "Teléfono")}
          nameValidator={"Teléfono"}
          validator={validator}
          validateOptions={"required|min:7|max:15"}
        />
      </Grid>

      <Grid item lg={4} xs={12}>
        <ButtonPrimary
          text={!!activeContact ? "Actualizar" : "Agregar"}
          type="button"
          loading={false}
          onClick={handleSave}
        />
      </Grid>
    </Grid>
  );
});
