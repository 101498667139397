import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  width98: {
    borderBottom: "1px solid rgba(255,255,255,0.2)",
    boxSizing: "border-box",
    height: "1px",
    marginBottom: "8px",
    width: "98%",
    [theme.breakpoints.down("lg")]: {
      width: "95%",
    },
  },

  amountArticle: {
    alignItems: "center",
    color: "#FFF",
    display: "flex",
    fontFamily: "Muli",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "normal",
    marginBottom: "5px",
    wordBreak: "break-word",
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
  },
}));
export const ValueInfoCard = ({
  title,
  value,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 12,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <div className="valueinfoCard">{title}</div>
      <div className={classes.amountArticle}>{value}</div>
      <div className={classes.width98} />
    </Grid>
  );
};
