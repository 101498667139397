import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid } from "@material-ui/core";

import { FormatDecimal } from "../../../components/common/formatDecimal";
import { formatDate } from "../../../utils/general";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: "#363636",
		color: theme.palette.primaryColor,
		borderRadius: 5,
		"& .MuiCardContent-root": {
			paddingTop: 0,
			paddingBottom: 5,
			marginBottom: 0,
			marginTop: 0,
			paddingLeft: 55,
		},
	},
	header: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	actions: {
		display: "flex",
		color: theme.palette.primaryColor,
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	avatar: {
		color: "#2CC63E",
	},
	title: {
		color: theme.palette.primaryColor,
		fontSize: 18,
		fontWeight: "bold",
	},
	icon: {
		color: theme.palette.primaryColor,
	},
	iconExpand: {
		color: "#2CC63E",
	},
	content: {
		color: theme.palette.primaryColor,
		fontSize: 13,
		fontWeight: 600,
	},
	actionExpand: {
		textAlign: "end",
		display: "block",
		padding: "0px 15px",
	},
	label: {
		color: "#2CC63E",
		fontSize: 14,
	},
	opacity: {
		opacity: 0.7,
	},
}));

export const PatientCard = React.memo(
	({ data, handleDelete, handleLoadData }) => {
		const classes = useStyles();
		const [expanded, setExpanded] = React.useState(false);

		const {
			id,
			tipodocumentocodigo,
			numerodocumento,
			nombres,
			tipousuariodescripcion,
			tipomodalidaddescripcion,
			tipocoberturadescripcion,
			numeroautorizacion,
			numeroprescripcion,
			idsuministro,
			numerocontrato,
			numeropoliza,
			fechainicio,
			fechafin,
			copago,
			cuotamoderadora,
			cuotarecuperacion,
			pagoscompartidos,
		} = data;

		const handleExpandClick = () => {
			setExpanded(!expanded);
		};

		return (
			<Card className={classes.root}>
				<CardHeader
					className={classes.header}
					avatar={
						<img src={require("../../../images/shape.png")} alt={"shape"} />
					}
					action={
						<div className={classes.actions}>
							<IconButton onClick={() => handleLoadData(data)}>
								<CreateIcon className={classes.icon} />
							</IconButton>
							<IconButton onClick={() => handleDelete(id, nombres)}>
								<DeleteIcon className={classes.icon} />
							</IconButton>
						</div>
					}
					classes={{
						title: classes.title,
					}}
					title={`${nombres} - ${tipodocumentocodigo} ${numerodocumento}`}
				/>
				<CardContent className={classes.content}>
					<Typography variant="body2" component="p">
						<span className={classes.opacity}>
							{"Fecha de inicio periodo facturación: "}
						</span>
						{formatDate(fechainicio, "dd/MM/yyyy")}
						<span className={classes.opacity}>
							{" Fecha de fin periodo facturación: "}
						</span>
						{formatDate(fechafin, "dd/MM/yyyy")}
					</Typography>
				</CardContent>
				<CardActions disableSpacing className={classes.actionExpand}>
					<label className={classes.label}>
						{expanded ? "Ver menos" : "Ver mas"}
					</label>
					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						style={{ padding: 5 }}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="Ver mas."
					>
						<ExpandMoreIcon className={classes.iconExpand} />
					</IconButton>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent>
						<Grid container>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>{"Tipo de usuario: "}</span>
								{tipousuariodescripcion}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"Modalidad de contratación y pago: "}
								</span>
								{tipomodalidaddescripcion}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"Cobertura plan de beneficios: "}
								</span>
								{tipocoberturadescripcion}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"Número de Autorización: "}
								</span>
								{!!numeroautorizacion ? numeroautorizacion : "N/A"}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"Número de mi prescripción (MIPRES): "}
								</span>
								{!!numeroprescripcion ? numeroprescripcion : "N/A"}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"ID Suministro prescripción (MIPRES): "}
								</span>
								{!!idsuministro ? idsuministro : "N/A"}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"Número de contrato: "}
								</span>
								{!!numerocontrato ? numerocontrato : "N/A"}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>{"Número de póliza: "}</span>
								{!!numeropoliza ? numeropoliza : "N/A"}
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>{"Copago: "}</span>
								<FormatDecimal prefix={"$"} value={!!copago ? copago : "N/A"} />
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>{"Cuota moderadora: "}</span>
								<FormatDecimal
									prefix={"$"}
									value={!!cuotamoderadora ? cuotamoderadora : "N/A"}
								/>
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>
									{"Cuota recuperación: "}
								</span>
								<FormatDecimal
									prefix={"$"}
									value={!!cuotarecuperacion ? cuotarecuperacion : "N/A"}
								/>
							</Grid>
							<Grid item lg={6} xs={12}>
								<span className={classes.opacity}>{"Pagos compartidos: "}</span>
								<FormatDecimal
									prefix={"$"}
									value={!!pagoscompartidos ? pagoscompartidos : "N/A"}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Collapse>
			</Card>
		);
	}
);
