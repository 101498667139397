import { axiosApiInstance } from "../config/axios-instance";
import {
  API_ENDPOINT_CUSTOMERS,
  API_ENDPOINT_DOCUMENT,
  defaultHeaders,
  DEFAULTRESPONSE,
  QUANTITY_DATA_DOCUMENT,
  TIME_UPDATE_REGISTER,
} from "../config/config";
import { SuccessAlert } from "../helpers/alert.helpers";
import {
  errorToast,
  successToast,
  warningToast,
} from "../helpers/toast.helpers";
import { types } from "../types/types";
import { enterpriseIdHeader, handleResponse } from "./authActions";
import { cleanDataConfigAction } from "./configAction";

const urlEndPoint = `${API_ENDPOINT_CUSTOMERS}/customer/api/`;
const urlDocument = `${API_ENDPOINT_DOCUMENT}/document/api/`;
const urlReport = `${API_ENDPOINT_DOCUMENT}/document/api/report/`;

/**
 * Arreglo donde se guarda el id del documento soporte para el loading
 */
let loadingReportDocument = [];

/**
 * * Consulta listado  datos proveedor
 *
 */
export const getSupplierDataAction = (tipoDocumento, numDocumento) => async (
  dispatch
) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      enterpriseId: `${enterpriseIdHeader()}`,
      tipoDocumento,
      numeroDocumento: numDocumento,
    });
    const { data } = await axiosApiInstance.get(
      `${urlEndPoint}${"Proveedor/GetDataProvider"}`,
      myConfig
    );

    dispatch({
      type: types.GET_SUPPLIER_DATA,
      payload: data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se pudo obtener los datos del proveedor",
        error: err,
      },
    });
  }
};

/**
 * Acción para diligenciar la data del proveedor.
 * @param {Object} dataProvider -  Datos Proveedor.
 * @returns {Function} - La función de acción asíncrona.
 */
export const dataProviderAction = (dataProvider) => async (dispatch) => {
  dispatch({
    type: types.FILL_PROVIDER_DATA,
    payload: dataProvider,
  });
};

export const documentDataAction = (documentData) => async (dispatch) => {
  dispatch({
    type: types.FILL_DOCUMENT_DATA,
    payload: documentData,
  });
};

/**
 * * Crea el documento soporte
 */
export const createDataProviderAction = (dataProvider) => async (dispatch) => {
  try {
    dataProvider = {
      ...dataProvider,
      idstate: 0,
      createdby: 1,
      modifiedby: 1,
      idbusinessunit: 1,
      idowner: 1,
      empresaid: `${enterpriseIdHeader()}`,
    };

    const { data } = await axiosApiInstance.post(
      `${urlEndPoint}${"Proveedor/RegisterProvider"}`,
      dataProvider,
      defaultHeaders()
    );

    dispatch({
      type: types.CREATE_PROVIDER_DATA,
      payload: data.result,
    });

    return data;
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido crear el proveedor",
        error: err,
      },
    });
  }
};

/**
 * * Crea el documento de solicitud de soporte
 *
 */

export const createDocumentSupportAction = (
  documentData,
  readResponseSendDocumentSupport,
  readErrorSendInvoice,
  setActiveStep
) => async (dispatch, getState) => {
  try {
    const { providerData } = getState().documentSupportReducer;
    const datas = await dispatch(createDataProviderAction(providerData));
    if (["201", "200"].includes(datas.statusCode)) {
      documentData = {
        ...documentData,
        Documento: {
          ...documentData.Documento,
          ProveedorId: datas.result,
        },
      };

      const { data } = await axiosApiInstance.post(
        `${urlDocument}${"DocumentoSoporte/RegistrarDocumentoSoporte"}`,
        documentData,
        defaultHeaders()
      );

      readResponseSendDocumentSupport(data, datas);

      dispatch({
        type: types.CREATE_DOCUMENT_SUPPORT,
        payload: documentData,
      });
    }
  } catch (error) {
    readErrorSendInvoice(error.response);
  } finally {
    dispatch({
      type: types.CLEAN_DOCUMENT_SUPPORT,
    });
    dispatch(cleanDataConfigAction());
  }
};

/**
 * * Setea el favorito del proveedor
 */

export const setFavoriteProviderAction = (favorite) => {
  return {
    type: types.SET_FAVORITE_PROVIDER,
    payload: favorite,
  };
};

/**
 * * Limpia el estado de la solicitud de soporte
 */

export const cleanDocumentSupportAction = () => {
  return {
    type: types.CLEAN_DOCUMENT_SUPPORT,
  };
};

/**
 * Limpia los datos del proveedor.
 * @function cleanSuplierAndProviderAction
 * @returns {Function} Función que realiza la limpieza de los datos del proveedor.
 */
export const cleanSuplierAndProviderAction = () => (dispatch) => {
  dispatch({
    type: types.GET_SUPPLIER_DATA,
    payload: [],
  });
  dispatch({
    type: types.FILL_PROVIDER_DATA,
    payload: [],
  });
};

/**
 * Consulta listado de documentos por parametros de busqueda
 * @param {object} filterData Datos filtro
 */
export const getDocumentsSupportAction = (
  filterData,
  docsupport,
  selectDefaultItem
) => async (dispatch) => {
  try {
    if (filterData.LoadMore !== true) {
      changeGetLoadingDetail(dispatch, true);
    }
    changeGetLoading(dispatch, true);

    filterData = {
      ...filterData,
      Empresaid: Number(enterpriseIdHeader()),
    };

    //Limpia listado en pantalla para nueva consulta
    if (filterData.LoadMore !== true) {
      dispatch({
        type: types.GET_DOCUMENT_SUPPORT,
        payload: [],
        payload1: 0,
      });
      selectDefaultItem(null);
    }

    const response = await axiosApiInstance.post(
      `${urlDocument}DocumentoSoporte/GetDocSoportPaginationSearch`,
      filterData,
      defaultHeaders()
    );

    if (response.data.result.length === 0) {
      dispatch({
        type: types.GET_DETAIL_DOCUMENT_SUPPORT,
        payload: {},
      });
      changeGetLoadingDetail(dispatch, false);
    }
    let newDocsSupports = [];
    let total = 0;

    if (filterData.LoadMore === true) {
      newDocsSupports = docsupport.concat(response.data.result);
      total = response?.data?.result[0]?.totalregistros;
    } else {
      newDocsSupports = response.data.result;
      total = response?.data?.result[0]?.totalregistros;
      selectDefaultItem(newDocsSupports[0]?.id);
    }

    dispatch({
      type: types.GET_DOCUMENT_SUPPORT,
      payload: newDocsSupports,
      payload1: total,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener listado de documentos soporte.",
        error: err,
      },
    });
  } finally {
    changeGetLoading(dispatch, false);
  }
};

/**
 * Consulta información de un documento
 * @param {*} id Identificador documento
 * @param {function} functionLoad Actualiza estado Loading, durante consulta de informaci´´on
 */
export const getDocumentSupportDetailAction = (id) => async (dispatch) => {
  try {
    changeGetLoadingDetail(dispatch, true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      idDocumento: id,
    });

    const response = await axiosApiInstance.get(
      `${urlDocument}DocumentoSoporte/GetDocumentoSoporteById`,
      config
    );

    dispatch({
      type: types.GET_DETAIL_DOCUMENT_SUPPORT,
      payload: response.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener información de documento.",
        error: err,
      },
    });
  } finally {
    changeGetLoadingDetail(dispatch, false);
  }
};

// Loading
const changeGetLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_DOCUMENT_SUPPORT_DATA,
    payload: status,
  });
};

export const changeGetLoadingDetail = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_DOCUMENT_SUPPORT_DETAIL,
    payload: status,
  });
};

/**
 * Genera previsualización de documento en construccion
 * @param {*} data
 * @param {*} readResponseGetFiles
 * @returns
 */
export const getDocumentSupportPreviewAction = (
  data,
  readResponseGetFiles
) => async (dispatch, getState) => {
  try {
    const {
      providerData,
      detailDocumentSupport,
    } = getState().documentSupportReducer;

    if (data.Documento.DianTipoDocumentoElectronicoId !== 6) {
      const datas = await dispatch(createDataProviderAction(providerData));
      if (["201", "200"].includes(datas.statusCode)) {
        data = {
          ...data,
          Documento: {
            ...data.Documento,
            ProveedorId: datas.result,
          },
        };
      }
    } else {
      data = {
        ...data,
        Documento: {
          ...data.Documento,
          ProveedorId: detailDocumentSupport.proveedorId,
        },
      };
    }

    const response = await axiosApiInstance.post(
      `${urlDocument}DocumentoSoporte/PrevisualizarDocumento`,
      data,
      defaultHeaders()
    );
    readResponseGetFiles(response?.data?.result ?? null);
  } catch (err) {
    readResponseGetFiles(null);
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error, No hemos podido generar previsualización.",
        error: err,
      },
    });
  }
};

/**
 * Genera previsualización de documento Soporte
 * @param {*} readResponseGetFiles
 * @returns
 */
export const getDocumentSupportConsultForIdAction = (
  readResponseGetFiles
) => async (dispatch, getState) => {
  try {
    const { detailDocumentSupport } = getState().documentSupportReducer;

    if (detailDocumentSupport && detailDocumentSupport.id) {
      const config = defaultHeaders();
      Object.assign(config.headers, {
        pDocumentoId: detailDocumentSupport.id,
      });
      const response = await axiosApiInstance.get(
        `${urlDocument}DocumentoSoporte/ConsultarArchivosDocumento `,
        config
      );
      readResponseGetFiles(response?.data ?? null);
    }
  } catch (err) {
    readResponseGetFiles(null);
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Ha ocurrido un error, No hemos podido generar previsualización.",
        error: err,
      },
    });
  }
};

/**
 * Envia correo electrónico  con reporte documento soporte rechazado por DIAN
 * @param {*} data Información usuario y documento
 * @returns
 */
export const sendMailReportDocSupportAction = (data) => async (
  dispatch,
  getState
) => {
  try {
    const { documentSupportFilter } = getState().documentSupportReducer;

    changeReportLoading(dispatch, data.documentId, true);

    const result = await axiosApiInstance.post(
      `${urlDocument}${"DocumentoSoporte/SendEmailReportSupportDocument"}`,
      data,
      defaultHeaders()
    );

    if (result.status === 200) {
      let total = documentSupportFilter[0]?.totalregistros ?? 0;
      let index = documentSupportFilter.findIndex(
        (c) => c.id === data.documentId
      );
      if (index !== undefined) {
        documentSupportFilter[index].reporterechazo = true;
        dispatch({
          type: types.GET_DOCUMENT_SUPPORT,
          payload: documentSupportFilter,
          payload1: total,
        });

        successToast(
          `Reporte exitoso documento soporte numero: ${data.prefijo}-${data.consecutivo}`,
          "Has reportado el caso exitosamente, no te preocupes nosotros nos encargaremos."
        );
      } else {
        warningToast(
          `No hemos podido actualizar estado reporte del documento soporte numero: ${data.prefijo}-${data.consecutivo}`
        );
      }
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "No hemos podido enviar el correo electrónico con reporte, por favor contacta al administrador.",
        error: err,
      },
    });
  } finally {
    changeReportLoading(dispatch, data.documentId, false);
  }
};

/**
 * Actualiza estado loading reporte documento soporte
 * @param {*} dispatch
 * @param {*} status
 */
function changeReportLoading(dispatch, id, status) {
  if (status === true) {
    loadingReportDocument.push(id);
  } else {
    loadingReportDocument = loadingReportDocument.filter((c) => c !== id);
  }
  dispatch({
    type: types.LOADING_REPORT_DOCUMENT_SUPPORT,
    payload: loadingReportDocument,
  });
}

/**
 *  *Actualiza estado de documento soporte por pagar a pagado y  documento destacado
 * @param {*} data Informacion documento
 */
export const updateDocumentSupportAction = (
  data,
  setDocumentSelected
) => async (dispatch, getState) => {
  try {
    changeUpdateStatusLoading(dispatch, true, data.Operacion, data.Id);

    const {
      documentSupportFilter,
      totaldocuments,
    } = getState().documentSupportReducer;

    const filterData = {
      Filtro: null,
      Skip: 0,
      Limit: QUANTITY_DATA_DOCUMENT,
      Pagadas: null,
      Pendientes: null,
      Vencidas: null,
      Fechadesde: null,
      Fechahasta: null,
      loadMore: false,
      Destacadas: null,
      Editada: false,
      Anulacion: null,
      Empresaid: Number(enterpriseIdHeader()),
    };

    const response = await axiosApiInstance.post(
      `${urlDocument}DocumentoSoporte/ActualizarDocSoporte`,
      data,
      defaultHeaders()
    );

    //Actualiza detalle documento
    if (response.status === 200) {
      const response = await axiosApiInstance.post(
        `${urlDocument}DocumentoSoporte/GetDocSoportPaginationSearch`,
        filterData,
        defaultHeaders()
      );

      const documentFilter = response.data.result.find((d) => d.id === data.Id);

      let newDocsSupports = [];

      newDocsSupports = updateDocumentsAfterUpdate(
        documentSupportFilter,
        documentFilter
      );

      dispatch({
        type: types.GET_DOCUMENT_SUPPORT,
        payload: newDocsSupports,
        payload1: totaldocuments,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error actualizando documento.",
        error: err,
      },
    });
  } finally {
    changeUpdateStatusLoading(dispatch, false, data.Operacion);
  }
};

/**
 * Actualiza listado de documentos despues  de actualizar estado
 * @param {*} document Listado documentos soporte
 * @param {*} data nueva informacion documento
 */
const updateDocumentsAfterUpdate = (document, data) => {
  if (data !== null && data !== undefined) {
    let newData = document.find((d) => d.id === data?.id);
    newData = {
      ...newData,
      estado: data.estado,
      favorito: data.favorito,
      estadoid: data.estadoid,
    };

    const index = document.findIndex((d) => d.id === data?.id);
    let newDocument = [];
    if (index >= 0) {
      newDocument = [...document];
      newDocument[index] = newData;
    }

    return newDocument;
  }
};

/**
 * *Actualiza estado loading actualizacion estado documento
 * @param {*} dispatch
 * @param {*} status
 */
function changeUpdateStatusLoading(dispatch, status, operacion, id) {
  if (operacion === "PAGADO") {
    dispatch({
      type: types.LOADING_UPDATE_STATUS_DOCUMENT_SUPPORT,
      payload: status,
    });
  }

  if (operacion === "FAVORITO") {
    dispatch({
      type: types.LOADING_UPDATE_FAVORITE_DOCUMENT_SUPPORT,
      payload: status,
      payload1: id,
    });
  }
}

/**
 *  * Consulta listado Conceptos Notas Ajuste
 * @returns
 */
export const getConceptsNotesAdjustment = () => async (dispatch, getState) => {
  try {
    const { conceptsNotesAdjustment } = getState().documentSupportReducer;
    if (conceptsNotesAdjustment.length > 0) return;

    dispatch(statusLoadingReasons(true));
    const response = await axiosApiInstance.get(
      `${urlDocument}DocumentoSoporte/ConsultarConceptosNotasAjuste`,
      defaultHeaders()
    );

    if (response.status === 200) {
      dispatch({
        type: types.GET_CONCEPTS_NOTES_ADJUSTMENT,
        payload: response.data.result,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error obteniendo listado conceptos notas.",
        error: err,
      },
    });
  } finally {
    dispatch(statusLoadingReasons(false));
  }
};

const statusLoadingReasons = (status) => {
  return {
    type: types.LOADING_REASONS_NOTES,
    payload: status,
  };
};

/**
 * * Registra información de un nota ajuste
 * @param {*} data Información documento
 * @param {*} funtionResponse
 * @param {*} functionError
 */
export const createNoteAdjusment = (
  data,
  funtionResponse,
  functionError
) => async (dispatch, getState) => {
  try {
    const { documentSupportFilter } = getState().documentSupportReducer;
    const response = await axiosApiInstance.post(
      `${urlDocument}DocumentoSoporte/RegistrarNotaAjuste`,
      data,
      defaultHeaders()
    );

    funtionResponse(response);
    dispatch({
      type: types.SUCESS_DOCUMENT_CREATE,
      payload: response.data,
    });
    //Actualiza listado y detalle documentos
    if (data.Documento.DianConceptoNotaAjusteId === 2) {
      const index = documentSupportFilter.findIndex(
        (d) => d.id === data?.Documento?.DocumentoReferenciaId
      );
      const config = defaultHeaders();
      Object.assign(config.headers, {
        idDocumento: data?.Documento?.DocumentoReferenciaId,
      });
      documentSupportFilter[index].anulacion = true;
      let total = documentSupportFilter[0]?.totalregistros ?? 0;
      const response = await axiosApiInstance.get(
        `${urlDocument}DocumentoSoporte/GetDocumentoSoporteById`,
        config
      );
      dispatch({
        type: types.GET_DOCUMENT_SUPPORT,
        payload: documentSupportFilter,
        payload1: total,
      });

      dispatch({
        type: types.GET_DETAIL_DOCUMENT_SUPPORT,
        payload: response.data.result,
      });
    }
  } catch (err) {
    console.log("Error registrando Nota", !!err?.response ? err.response : err);
    functionError(err.response);
    if (
      err?.hasOwnProperty("response") &&
      err?.response?.hasOwnProperty("data") &&
      err?.response?.data?.statusCode === "404" &&
      err?.response?.data?.statusMessage === "Unauthorized, no quota"
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: err.response.data,
      });

      if (
        err.response.data.result.licenseActiveBase &&
        !err.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
    } else {
      handleResponse(err.response);
    }
  }
};

/**
 * Consulta información documento Nota credito, debito
 * @param {*} id Identificador nota
 * @param {*} getresponse Funcion leer response
 * @returns
 */
export const getNoteDetailDSAction = (id, getresponse) => async (
  dispatch,
  getState
) => {
  try {
    changeGetNoteLoading(dispatch, id);
    const {
      detailDocumentSupport,
      documentSupportFilter,
    } = getState().documentSupportReducer;
    const config = defaultHeaders();
    Object.assign(config.headers, {
      idDocumento: id,
    });

    const response = await axiosApiInstance.get(
      `${urlDocument}DocumentoSoporte/GetDocumentoSoporteById`,
      config
    );

    let docFilter = detailDocumentSupport.opdocumentonotas.find(
      (item) => item.id === response.data.result.id
    );

    const { prefijo, consecutivo } = documentSupportFilter.find(
      (item) => item.id === response.data.result.documentoReferenciaId
    );

    docFilter = {
      ...docFilter,
      prefijoDoc: prefijo,
      consecutivoDoc: consecutivo,
    };
    getresponse(response.data.result, docFilter, true, false);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener información de nota.",
        error: err,
      },
    });
  } finally {
    changeGetNoteLoading(dispatch, 0);
  }
};

/**
 * * Actualiza estado loading detalle nota (credito, debito)
 * @param {*} dispatch
 * @param {*} status
 */
const changeGetNoteLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_NOTE_DOCUMENT,
    payload: status,
  });
};

/**
 * Consula PDF en base64 de documento electronico
 * @param {*} data Información documento
 * @returns
 */
export const getDocumentDocSupportNoteAction = (
  dataId,
  readResponseGetFiles
) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: dataId,
    });

    const response = await axiosApiInstance.get(
      `${urlDocument}DocumentoSoporte/ConsultarArchivosDocumento`,
      config
    );
    readResponseGetFiles(response?.data?.result ?? null);
  } catch (err) {
    readResponseGetFiles(null);
    dispatch({
      type: types.showError,
      payload: {
        message: "Ha ocurrido un error, No hemos podido obtener PDF.",
        error: err,
      },
    });
  }
};

/**
 * Envia el documento soporte o nota a un correo
 * @param {*} body Informacion de el Doc Soporte a enviar
 */
export const sendDocSupportAction = (body, type) => async (dispatch) => {
  try {
    let config = defaultHeaders();
    const response = await axiosApiInstance.post(
      `${urlDocument}DocumentoSoporte/SendDocSupport`,
      body,
      config
    );
    if (response.status === 200) {
      if (type === "DOC") {
        SuccessAlert(
          null,
          "Se realizó el reenvío del Documento Soporte exitosamente",
          TIME_UPDATE_REGISTER
        );
      } else {
        SuccessAlert(
          null,
          "Se realizó el reenvío de la Nota exitosamente",
          TIME_UPDATE_REGISTER
        );
      }
    }

    dispatch({
      type: types.SUCESS_SEND_DOCSUPPORT,
      payload: response.data,
    });
  } catch (err) {
    console.log(
      "Error en el envio de un documento",
      !!err?.response ? err.response : err
    );
    let response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    readResponseEmailService(response);
    dispatch({
      type: types.SUCESS_SEND_DOCSUPPORT,
      payload: response,
    });
  }
};

/**
 *  Lee repuesta servico email, reenvio documentos
 * @param {*} response
 */
const readResponseEmailService = (response) => {
  let message = response.statusMessage ?? "";
  switch (response.httpCode) {
    case 500:
      errorToast("Error 500", message);
      break;
    case 400:
      warningToast("Error 400, petición invalida", message);
      break;
    case 404:
      warningToast("Error 404 usuario no autorizado", message);
      break;
    case 401:
      errorToast('Error 401 error no controlado"', message);
      break;
    default:
      errorToast(
        'Upss!!"',
        "Ha ocurrido un error no identificado, No se ha podido enviar correo electrónico, Por favor contacte al administrador"
      );
      break;
  }
};

/**
 * Consulta información  para generar reporte de documentos soportes con rango de fechas
 * @param {string} pFechaDesde Fecha de inicio
 * @param {string} pFechaHasta Fecha de fin
 * @param {function} complete Funcion, completa descarga despues de obtener la información del api
 */
export const getDocSupportReportAction = (
  pFechaDesde,
  pFechaHasta,
  complete,
  changeLoadingStatus
) => async (dispatch) => {
  try {
    changeLoadingStatus(true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: `${enterpriseIdHeader()}`,
      pFechaDesde: pFechaDesde,
      pFechaHasta: pFechaHasta,
    });

    const response = await axiosApiInstance.get(
      `${urlReport}ConsultarReporteDocSoporte`,
      config
    );

    complete(response.data.result);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar información de reporte.",
        error: err,
      },
    });
  } finally {
    changeLoadingStatus(false);
  }
};
