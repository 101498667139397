import { axiosApiInstance } from "../config/axios-instance";
import { API_ENDPOINT_CUSTOMERS, defaultHeaders } from "../config/config";
import { types } from "../types/types";
import { enterpriseIdHeader } from "./authActions";

const urlEndPoint = `${API_ENDPOINT_CUSTOMERS}/customer/api/Paciente`;

/**
 * Agrega nuevo paciente
 * @param {*} patient
 * @returns
 */
export const addPatientAction = (patient) => (dispatch) => {
	dispatch({
		type: types.ADD_PATIENT,
		payload: patient,
	});
};

/**
 * Actualiza infromación paciente
 * @param {*} patient
 * @returns
 */
export const updatePatientAction = (patient) => (dispatch) => {
	dispatch({
		type: types.UPDATED_PATIENT,
		payload: patient,
	});
};

/**
 * Borra infromación paciente
 * @param {*} patient
 * @returns
 */
export const deletePatientAction = (id) => (dispatch) => {
	dispatch({
		type: types.DELETE_PATIENT,
		payload: id,
	});
};

/**
 * Carga información de paciente
 * @param {*} patient
 * @returns
 */
export const loadPatientAction = (patient) => (dispatch) => {
	dispatch({
		type: types.LOAD_PATIENT,
		payload: patient,
	});
};

/**
 * Actualiza listado pacientes
 * @param {*} patientList
 * @returns
 */
export const refreshDataPatientAction = (patientList) => (dispatch) => {
	dispatch({
		type: types.REFRESH_PATIENT_LIST,
		payload: patientList,
	});
};

/**
 * Consulta información pacioente por identificacion
 * @param {*} type
 * @param {*} number
 * @returns
 */
export const getPatientByDocument = (type, number) => async (dispatch) => {
	
	try {
		if (number === null || number === "" || number.length <= 3) {
			dispatch({
				type: types.LOAD_EXISTING_PATIENT,
				payload: null,
			});
			return;
		}

		changeLoagingGetPatient(dispatch, true);
		let myConfig = defaultHeaders();
		Object.assign(myConfig.headers, {
			EnterpriseId: enterpriseIdHeader(),
			tipoDocumento: type,
			numeroDocumento: number,
		});

		const resp = await axiosApiInstance.get(
			`${urlEndPoint}${"/GetByDocument"}`,
			myConfig
		);

		dispatch({
			type: types.LOAD_EXISTING_PATIENT,
			payload: resp?.data?.result ?? null,
		});
	} catch (err) {
		dispatch({
			type: types.showError,
			payload: {
				message: "No se ha podido consultar infrmación de paciente.",
				error: err,
			},
		});
	} finally {
		changeLoagingGetPatient(dispatch, false);
	}
};

const changeLoagingGetPatient = (dispatch, status) => {
	dispatch({
		type: types.LOADING_GET_PATIENT,
		payload: status,
	});
};

/**
 * Borra información de pacientes en redux
 * @returns
 */
export const cleanDataPatientAction = () => (dispatch) => {
	dispatch({
		type: types.CLEAN_DATA_PATIENT,
	});
};
