import React, { useEffect, useMemo, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

import { List, makeStyles } from "@material-ui/core";

import { getEquivalentDocsAction } from "../../../../actions/equivalentDocAction";

import { EquivalentItemCard } from "./EquivalentItemCard";
import CustomProgress from "../../../../components/Progress/progress.component";

import useNearScreen from "../../../../hooks/useNearScreen";

import { getDataEmailReportAction } from "../../../../actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    height: "77.0vh",
    width: "100%",
    backgroundColor: "transparent",
  },
}));

export const ListCardEquivalentDoc = ({ dataFilter, setFilterData }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const externalRef = useRef();

  const {
    equivalentDocFilter,
    loadingGetEquivalentDoc,
    loadingReportEquivalentDoc,
    loadingStateEquivalentDoc,
    selectedDocumentID,
    totaldocuments,
  } = useSelector((state) => state.equivalentDocReducer);

  const { isNearScreen } = useNearScreen({
    externalRef: loadingGetEquivalentDoc ? null : externalRef,
    distance: "400px",
    once: false,
  });

  useEffect(() => {
    dispatch(getDataEmailReportAction());
  }, [dispatch]);

  /**
   * * Ejecuta la obtención de documentos equivalentes cuando hay un cambio en los datos de filtro.
   */
  useEffect(() => {
    dispatch(
      getEquivalentDocsAction({
        requestData: dataFilter,
        equivalentDocs: equivalentDocFilter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  /**
   * * Actualiza los datos de filtro cuando el usuario se acerca a la pantalla y hay más documentos equivalentes para cargar.
   */
  useEffect(() => {
    if (isNearScreen && equivalentDocFilter.length < totaldocuments) {
      setFilterData({
        ...dataFilter,
        Skip: equivalentDocFilter.length,
        LoadMore: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearScreen]);

  /**
   * * Memoriza la lista de documentos equivalentes.
   * @returns {Array} - Lista de documentos equivalentes.
   */
  const equivalentDocFilterMemo = useMemo(() => {
    return equivalentDocFilter;
  }, [equivalentDocFilter]);

  if (equivalentDocFilter.length === 0 && !loadingGetEquivalentDoc)
    return (
      <div
        className={`${classes.root} animate__animated animate__fadeIn animate__faster`}
      >
        No se encontraron Documentos Equivalentes
      </div>
    );

  return (
    <List component="div" className={classes.root}>
      {equivalentDocFilterMemo.map((equivalentDoc) => (
        <EquivalentItemCard
          key={equivalentDoc.id}
          equivalentDoc={equivalentDoc}
          idStateLoading={loadingStateEquivalentDoc}
          selected={equivalentDoc.id === selectedDocumentID}
          loadingReportEquivalentDoc={loadingReportEquivalentDoc}
        />
      ))}
      {loadingGetEquivalentDoc && <CustomProgress />}
      <div ref={externalRef} id="visor" />
    </List>
  );
};
