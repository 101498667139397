import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

import ImageTooltip from "../../images/AbrirTooltip.png";



const useStyles = makeStyles((theme) => ({
    tooltip: {
        color: theme.palette.secundaryColor,
    }
}));

const CustomTooltip = ({ message, size }) => {
    const classes = useStyles();

    return (
        <Tooltip
            title={message}
            placement="bottom-end"
            interactive
            enterTouchDelay={1000}>
            <img
                src={ImageTooltip}
                className={classes.tooltip}
                alt={'imageTooltip'}
                style={{ width: size ?? 25 }} />
        </Tooltip>
    );
}

CustomTooltip.propTypes = {
    message: PropTypes.object
}

export default CustomTooltip