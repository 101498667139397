import React from "react";

import { Card, CardMedia, Grid, Hidden, makeStyles } from "@material-ui/core";

import { URL_IMAGES } from "../../../../../config/config";

import { FormatDecimal } from "../../../../../components/common/formatDecimal";
import { TaxElement } from "../../../../document/cart/TaxElement";

import { getTaxItems } from "../../../../../utils/getTaxItems";

const useStyles = makeStyles((theme) => ({
  cover: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  wordBreak: {
    wordBreak: "break-word",
  },
  cardContainer: {
    height: "90%",
    marginTop: "10px",
    minHeight: "124px",
    width: "97%",
  },
  h1Card: {
    color: "white",
    justifyContent: "center",
    textAlign: "center",
  },
  width98: {
    borderBottom: "1px solid rgba(255,255,255,0.2)",
    boxSizing: "border-box",
    height: "1px",
    width: "98%",
  },
  marginY30: {
    margin: "30px 0",
  },

  displaySmall: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  containerData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

/**
 * * Obtiene un color especificado o un color predeterminado si no se proporciona ninguno.
 * @function
 * @param {string} color - El color especificado.
 * @param {string} [defaultColor="#33BBFF"] - El color predeterminado en caso de que no se proporcione uno.
 * @returns {string} El color especificado o el color predeterminado.
 */
const getColorOrDefault = (color, defaultColor = "#33BBFF") =>
  color || defaultColor;

/**
 * * Obtiene el color de fondo basado en el detalle proporcionado.
 * * Si el detalle no tiene una imagen o la lista de imágenes está vacía, devuelve el color especificado o un color predeterminado si no se proporciona ninguno.
 * * Si el detalle tiene una imagen, devuelve null.
 * @function
 * @param {Object} detail - El detalle del cual se obtiene el color de fondo.
 * @param {string} [detail.color] - El color especificado para el detalle.
 * @param {Array} [detail.imagen] - La lista de imágenes asociadas al detalle.
 * @returns {string|null} El color especificado, el color predeterminado o null si hay una imagen.
 */
const getBackgroundColor = (detail) => {
  if (!detail?.imagen || !detail.imagen.length) {
    return getColorOrDefault(detail?.color);
  }
  return null;
};

export const ArticleComponent = ({
  advPercentage,
  degreesAmount,
  gramsAmount,
  ibuaRate,
  iclRate,
  icuiPercentage,
  imageColor,
  incPercentage,
  inppRate,
  itemImage,
  itemQuantity,
  itemTitle,
  itemUnitValue,
  ivaPercentage,
  millilitersAmount,
  totalRealItem,
}) => {
  const classes = useStyles();

  const detail = {
    color: imageColor,
    descripcion: itemTitle,
    imagen: itemImage,
  };

  const taxItems = getTaxItems(
    ivaPercentage,
    incPercentage,
    iclRate / itemQuantity,
    degreesAmount,
    itemQuantity,
    gramsAmount / itemQuantity,
    inppRate,
    millilitersAmount / itemQuantity,
    ibuaRate,
    icuiPercentage,
    advPercentage
  );

  const applyTax = taxItems.some((taxitem) => taxitem.aplica);

  return (
    <>
      <div className="cardArticleSupport">
        <div className={`titleArticle ${classes.wordBreak}`}>{itemTitle}</div>
        <div className="lineDivisionSupport" />
        {/* Fin Seccion 1 */}
        <Grid container spacing={1} className={classes.displaySmall}>
          <Grid item lg={3} md={3} sm={6} xs={12} className="contentImgDetail">
            <Hidden xsDown>
              <Card
                className={classes.cardContainer}
                style={{
                  backgroundColor: getColorOrDefault(imageColor),
                }}
              >
                <CardMedia
                  className={classes.cover}
                  image={URL_IMAGES + itemImage}
                  style={{
                    backgroundColor: getBackgroundColor(detail),
                  }}
                >
                  {!itemImage || !itemImage.length ? (
                    <h1 className={classes.h1Card}>
                      {itemTitle?.substring(0, 2).toUpperCase()}
                    </h1>
                  ) : null}
                </CardMedia>
              </Card>
            </Hidden>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={6}
            xs={12}
            className={classes.containerData}
          >
            <Grid container className="fieldsReadContainer">
              <Grid item xs={12} md={6} lg={6}>
                <div className="valueinfoCard">Valor Unitario</div>
                <div className="unitValueArticle">
                  <FormatDecimal value={itemUnitValue} prefix={"$"} />
                </div>
                <div className={classes.width98} />
              </Grid>
              <Hidden mdUp>
                <div className={classes.marginY30} />
              </Hidden>
              <Grid item xs={12} md={6} lg={6}>
                <div className="valueinfoCard">Cantidad</div>
                <div className="amountArticle">
                  <FormatDecimal value={itemQuantity} />
                </div>
                <div className={classes.width98} />
              </Grid>
            </Grid>
            <div className="spacingInvoice" />
            <div className="spacingInvoice" />
            {applyTax && (
              <Grid container>
                <Grid item xs={12} className="valueinfoCard">
                  Impuestos:
                </Grid>
                <Grid item xs={12} className="fieldsReadBig">
                  {taxItems.map(
                    ({ aplica, label, value, prefix, suffix }, index) =>
                      aplica ? (
                        <TaxElement
                          key={index}
                          label={label}
                          value={value}
                          prefix={prefix}
                          suffix={suffix}
                        />
                      ) : null
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Fin Seccion 2 */}
        <div className="lineDivisionSupport" />
        <div className="spacingInvoice" />
        <Grid container>
          <Grid item xs={12} className="totalNumber">
            <span className="totalNumberSpan">Total:</span>
            <FormatDecimal value={totalRealItem} prefix={"$"} />
          </Grid>
        </Grid>
        {/* Fin Seccion 3 */}
      </div>
      <div className="spacingInvoice" />
    </>
  );
};
