import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";

import { FormatDecimal } from "../../../components/common/formatDecimal";

import { getRegimeTypesAction } from "../../../actions/configAction";

import documentDescription from "../../../utils/typeDocument";
import { ItemCard } from "../common/itemCard";
import { CollapseDataPanel } from "../../../components/ui/collapseDataPanel";
import { AdditionalDataReview } from "./AdditionalDataReview";
import { formatDate } from "../../../utils/general";

const useStyles = makeStyles((theme) => ({
	containerSection: {
		overflow: "auto",
		height: "65.1vh",
		width: "100%",
		overflowX: "hidden",
		backgroundColor: "#4B4B4B",
		borderRadius: 5,
		padding: 20,
		color: "white",
	},
	icon: {
		color: "#2CC63E",
		marginRight: 5,
	},
	wordBreak: {
		wordBreak: "break-all",
	},
	dataDocument: {
		color: "#FFFFFF",
		fontSize: 16,
		fontWeight: "500",
		wordBreak: "break-all",
		display: "flex",
	},
	gridData: {
		display: "grid",
		width: "100%",
	},
	label: {
		color: "rgba(255,255,255,0.5)",
		fontSize: 12,
	},
	grayline: {
		height: 1,
		width: "100%",
		borderBottom: "1px solid gray",
		marginTop: 5,
	},
	containernote: {
		backgroundColor: "#5E5E5E",
		borderRadius: 5,
		padding: "10px 5px",
		marginTop: 15,
	},
}));

export const DocumentReview = ({ typedocument }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [regimeType, setregimeType] = useState("");

	const { fecha, documentoref } = useSelector(
		(state) => state.documentReducer.opdocumento
	);
	const { adcliente, opdocumentosreferencia, opdocumento } = useSelector(
		(state) => state.documentReducer
	);
	const {
		valorapagar,
		opdetalledocumento,
		opdocumentocargos,
		opdocumentodescuentos,
		motivonota,
		observacionesnota,
	} = useSelector((state) => state.documentCartReducer);
	const { getRegimeTypesResponse: regimeTypeList } = useSelector(
		(state) => state.configReducer
	);

	//Cargue inicial
	useEffect(() => {
		dispatch(getRegimeTypesAction());
	}, [dispatch]);

	useEffect(() => {
		const name =
			regimeTypeList.find((c) => c.id === adcliente.diantiporegimenid)
				?.descripcion ?? "";
		setregimeType(name);
	}, [adcliente.diantiporegimenid, regimeTypeList]);

	return (
		<>
			<Grid
				container
				spacing={3}
				direction="row"
				alignItems="stretch"
				justify="center"
			>
				<Grid item xl={6} lg={8} md={8} xs={12}>
					<div className={`${classes.containerSection}`}>
						<Grid container spacing={3}>
							<Grid item xl={12} xs={12} style={{ textAlign: "end" }}>
								<span className={"dateRevision"}>
									{fecha !== ""
										? formatDate(new Date(fecha), "yyyy/MM/dd")
										: formatDate(new Date(), "yyyy/MM/dd")}
								</span>
							</Grid>
						</Grid>
						<div className="lineDivision" />

						{!!motivonota && (
							<>
								<Grid container spacing={1} className={classes.containernote}>
									<Grid item lg={6} xs={12}>
										<div
											className={classes.gridData}
											style={{ paddingRight: 10 }}
										>
											<span className={classes.label}>Factura Asociada</span>
											<span>{documentoref}</span>
											<div className={classes.grayline} />
										</div>
									</Grid>
									<Grid item lg={6} xs={12}>
										<div
											className={classes.gridData}
											style={{ paddingRight: 10 }}
										>
											<span className={classes.label}>Tipo de Nota</span>
											<span>
												{motivonota.tipo === "NC" ? "Crédito" : "Débito"}
											</span>
											<div className={classes.grayline} />
										</div>
									</Grid>
									<Grid item lg={12} xs={12}>
										<div
											className={classes.gridData}
											style={{ paddingRight: 10 }}
										>
											<span className={classes.label}>
												Motivo de la Edición
											</span>
											<span>{motivonota.descripcion ?? "-"}</span>
											<div className={classes.grayline} />
										</div>
									</Grid>
									<Grid item lg={12} xs={12}>
										<div
											className={classes.gridData}
											style={{ paddingRight: 10 }}
										>
											<span className={classes.label}>Observaciones</span>
											<span>{observacionesnota}</span>
											<div className={classes.grayline} />
										</div>
									</Grid>
								</Grid>
							</>
						)}

						<Grid container justify="center">
							<Grid item lg={4} md={4} sm={12} style={{ display: "grid" }}>
								<span className="totalRevision">
									<FormatDecimal
										value={valorapagar}
										prefix={"$"}
										size={"small"}
									/>
								</span>
								<span className="commentsRevision">
									Valor total impuestos incluidos
								</span>
							</Grid>
						</Grid>

						<div className="spacingInvoice" />
						<div className="lineDivision" />
						<div className="spacingInvoice" />

						<Grid container>
							<Grid item lg={12} xs={12}>
								<span className="constumerNameDetail">
									{adcliente.razonsocial ?? ""}
								</span>
							</Grid>
							<Grid item lg={12} xs={12}>
								<span className={classes.dataDocument}>
									{`${documentDescription(
										adcliente.diantipodocumentoidentidadid
									)} ${adcliente.documento ?? ""}`}
								</span>
							</Grid>
							<Grid item lg={12} xs={12}>
								<span className={classes.dataDocument}>
									{adcliente.diantipopersonaid === 1
										? "Persona Jurídica"
										: "Persona Natural"}
								</span>
							</Grid>

							<Grid item lg={12} xs={12}>
								<span className={classes.dataDocument}>{regimeType}</span>
							</Grid>
							<div className="spacingInvoice" />
							<Grid item xl={12} xs={12} className={classes.dataDocument}>
								<EmailIcon className={classes.icon} />
								<div className={classes.wordBreak}>
									{adcliente.correoelectronico ?? ""}
								</div>
							</Grid>

							<Grid item xs={12} className={classes.dataDocument}>
								<PhoneIcon className={classes.icon} />
								<div className={classes.wordBreak}>
									{" "}
									{adcliente.telefono ?? ""}
								</div>
							</Grid>
							<Grid item xl={12} xs={12} className={classes.dataDocument}>
								<RoomIcon className={classes.icon} />
								<div className={classes.wordBreak}>
									{adcliente.direccion ?? ""}
								</div>
							</Grid>
						</Grid>

						<div className="spacingInvoice" />
						<div className="spacingInvoice" />

						<Grid container>
							<Grid item lg={12} xs={12}>
								<div className="greenTitleInvoice">{`Artículos (${opdetalledocumento.length})`}</div>
							</Grid>
						</Grid>

						<div className="spacingInvoice" />
						<div className="spacingInvoice" />

						<Grid container>
							{opdetalledocumento.map((item) => (
								<Grid key={item.id} item lg={12} xs={12}>
									<ItemCard {...item} />
								</Grid>
							))}
						</Grid>

						<Grid container>
							<Grid item lg={12} xs={12}>
								<CollapseDataPanel
									backgroundColor={"rgb(94, 94, 94)"}
									component={
										<AdditionalDataReview
											opdocumentocargos={opdocumentocargos}
											opdocumentodescuentos={opdocumentodescuentos}
											opdocumentosreferencia={opdocumentosreferencia}
											motivonota={motivonota}
											typedocument={typedocument}
											{...opdocumento}
										/>
									}
								/>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
		</>
	);
};
