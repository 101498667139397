import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, IconButton, makeStyles, Tooltip, Link } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

import InputIcon from "../../components/input/inputIcon.component";
import { IntegrationCard } from "./integrationCard";
import IntegrationDetail from "./IntegrationDetail";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import CustomProgress from "../../components/Progress/progress.component";
import PopoverModal from "../../components/modal/popoverModal.component";
import StyledCheckbox from "../../components/checkForm/styledCheckbox.component";
import { FilterDate } from "./filterDate";

import {
	getDocumentsIntegrationAction,
	getDetailDocumentAction,
	cleanReduxIntegrationAction,
} from "../../actions/documentIntegrationAction";

const filterImage =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC";

const useStyles = makeStyles((theme) => ({
	root: {},
	buttonIcon: {
		color: "white",
	},
	containerList: {
		height: "80.8vh",
		overflowY: "auto",
		overflowX: "hidden",
	},
	alignCenter: {
		textAlign: "center",
	},
	letter: {
		color: "#FFFFFF",
	},
	link: {
		color: "White",
		fontSize: "16px",
		padding: "7px",
		textAlign: "center",
	},
}));

const initialFilter = {
	Skip: 0,
	Limit: 30,
	Fechadesde: null,
	FechaHasta: null,
	Filtro: null,
	Destacado: null,
	Aprobado: null,
	Rechazado: null,
	Loadmore: false,
};

export const IntegrationManage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [filter, setFilter] = useState(initialFilter);
	const [totalDocuments, setTotalDocuments] = useState(0);
	const [txtfilter, setTxtFilter] = useState("");
	const [modalDatesAnchorEl, setModalDatesAnchorEl] = React.useState(null);
	const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
	const [filterStatus, setFilterStatus] = useState({
		standOut: false,
		accepted: false,
		rejected: false,
	});
	const [selectdId, setSelectdId] = useState(0);

	const {
		documentList,
		loadingList,
		documentdetail,
		loadingDetail,
	} = useSelector((s) => s.documentIntegrationReducer);

	/**
	 * Desmontar componente
	 */
	useEffect(() => {
		return () => {
			dispatch(cleanReduxIntegrationAction());
		};
	}, [dispatch]);

	/**
	 * Consulta listado cuando cambia información de filtro
	 */
	useEffect(() => {
		dispatch(getDocumentsIntegrationAction(filter));
	}, [dispatch, filter]);

	/**
	 * Lee total documentos registrados en BD
	 */
	useEffect(() => {
		setTotalDocuments(
			documentList.length > 0 ? documentList[0].totalregistros : 0
		);
	}, [documentList]);

	useEffect(() => {
		if (!!documentdetail) setSelectdId(documentdetail.Id);
	}, [documentdetail]);

	/**
	 * Carga siguiente pagina listado documentos
	 */
	const handleLoadMore = () => {
		setFilter((prev) => ({
			...prev,
			Skip: documentList.length,
			Loadmore: true,
		}));
	};

	/**
	 * Sincroniza nuevo valor de input con state,
	 * filtra listado si el campo es vacio
	 * @param {*} param0
	 */
	const handleChageTextFilter = ({ target }) => {
		setTxtFilter(target.value);

		if (target.value.length === 0) {
			setFilter((prev) => ({
				...prev,
				Skip: 0,
				Filtro: "",
				Loadmore: false,
			}));
		}
	};

	/**
	 * Filtra por cadena de cararcteres
	 */
	const handleFilterByText = () => {
		setFilter((prev) => ({
			...prev,
			Skip: 0,
			Filtro: txtfilter,
			Loadmore: false,
		}));
	};

	/**
	 * Abre modal para filtro por fechas
	 * @param {*} event
	 */
	const handleOpenFilterDate = (event) => {
		if (loadingList !== true) setModalDatesAnchorEl(event.currentTarget);
	};

	/**
	 * Filtra listado documentos por rango fechas
	 * @param {} dateTo
	 * @param {*} dateFrom
	 */
	const handleConfirmDate = (dateTo, dateFrom) => {
		setFilter((prev) => ({
			...prev,
			Skip: 0,
			Fechadesde: dateTo,
			FechaHasta: dateFrom,
			Loadmore: false,
		}));
		setModalDatesAnchorEl(null);
	};

	/**
	 * Limpia filtro por fechas
	 */
	const handelCleanFilterDate = () => {
		setFilter((prev) => ({
			...prev,
			Skip: 0,
			Fechadesde: null,
			FechaHasta: null,
			Loadmore: false,
		}));
		setModalDatesAnchorEl(null);
	};

	/**
	 * Abre modal para filtro por estado
	 * @param {*} event
	 */
	const handleOpenFilterStatus = (event) => {
		if (loadingList !== true) setModalChecksAnchorEl(event.currentTarget);
	};

	/**
	 *  Filtra listado por estado
	 * @param {*} param0
	 */
	const handleChangeFilterStatus = ({ target }) => {
		const { name, checked } = target;
		setFilterStatus((prev) => ({
			...prev,
			[name]: checked,
		}));

		let standOut = filter.Destacado;
		let accepted = filter.Aprobado;
		let rejected = filter.Rechazado;

		if (name === "accepted") {
			accepted = checked;
		}

		if (name === "rejected") {
			rejected = checked;
		}

		if (name === "standOut") {
			standOut = checked;
		}

		setFilter((prev) => ({
			...prev,
			Skip: 0,
			Destacado: standOut,
			Aprobado: accepted,
			Rechazado: rejected,
			Loadmore: false,
		}));
	};

	/**
	 * Limpia filtro por estado
	 */
	const handleCleanFilterStatus = () => {
		setFilter((prev) => ({
			...prev,
			Skip: 0,
			Destacado: null,
			Aprobado: null,
			Rechazado: null,
			Loadmore: false,
		}));
		setModalChecksAnchorEl(null);

		setFilterStatus({
			standOut: false,
			accepted: false,
			rejected: false,
		});
	};

	/**
	 * Consulta detalle de documento
	 * @param {*} id Identificador documento
	 */
	const handleSelectDocument = useCallback(
		(id) => {
			dispatch(getDetailDocumentAction(id));
			setSelectdId(id);
		},
		[dispatch]
	);

	const modalStatusFilter = () => {
		return (
			<Grid container justify="flex-start" alignItems="center">
				<Grid item lg={3} md={3} sm={3}>
					<StyledCheckbox
						className={classes.letter}
						checked={filterStatus.standOut}
						onChange={handleChangeFilterStatus}
						name="standOut"
					/>
				</Grid>
				<Grid item lg={9} md={9} sm={9}>
					Destacadas
				</Grid>
				<Grid item lg={3} md={3} sm={3}>
					<StyledCheckbox
						className={classes.letter}
						checked={filterStatus.accepted}
						onChange={handleChangeFilterStatus}
						name="accepted"
					/>
				</Grid>
				<Grid item lg={9} md={9} sm={9}>
					Aceptado DIAN
				</Grid>
				<Grid item lg={3} md={3} sm={3}>
					<StyledCheckbox
						className={classes.letter}
						checked={filterStatus.rejected}
						onChange={handleChangeFilterStatus}
						name="rejected"
					/>
				</Grid>
				<Grid item lg={9} md={9} sm={9}>
					Rechazado DIAN
				</Grid>
				<Grid item lg={12} md={12} sm={12} className={classes.link}>
					<Link
						href="#"
						onClick={handleCleanFilterStatus}
						variant="inherit"
						underline="none"
						className={classes.link}
					>
						Limpiar Filtro
					</Link>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item lg={12} xs={12} style={{ display: "flex" }}>
					<div style={{ width: "100%" }}>
						<InputIcon
							name={"txtFilter"}
							label={
								"Buscar por cliente, documento, número de factura o número de nota"
							}
							value={txtfilter}
							onChange={handleChageTextFilter}
							onSearch={handleFilterByText}
							maxLength={100}
							disabled={loadingList}
						/>
					</div>
					<Tooltip title="Filtrar por fechas">
						<IconButton onClick={handleOpenFilterDate} disabled={false}>
							<EventIcon className={classes.buttonIcon} />
						</IconButton>
					</Tooltip>
					<Tooltip title="Filtrar por estado">
						<IconButton
							className={classes.buttonFilterStatus}
							onClick={handleOpenFilterStatus}
							disabled={loadingList}
						>
							<img alt={"Filtrar"} src={filterImage}></img>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item lg={6} xs={12}>
					<div className={classes.containerList}>
						<Grid container spacing={1}>
							{documentList.length === 0 && loadingList && (
								<Grid item lg={12} xs={12} style={{ textAling: "center" }}>
									<CustomProgress />
								</Grid>
							)}

							{documentList.map((item) => (
								<Grid key={item.id} item lg={12} xs={12}>
									<IntegrationCard
										selectedId={selectdId}
										onClick={handleSelectDocument}
										{...item}
									/>
								</Grid>
							))}

							{documentList.length === 0 && !loadingList && (
								<Grid item lg={12} xs={12} style={{ textAling: "center" }}>
									<span>No se han encontado resultados...</span>
								</Grid>
							)}
						</Grid>
						{documentList.length > 0 && totalDocuments !== documentList.length && (
							<Grid container justify={"center"}>
								<Grid item lg={6} xs={12}>
									<ButtonPrimary
										onClick={handleLoadMore}
										text={"Mostrar más..."}
										loading={loadingList}
										disabled={totalDocuments === documentList.length}
									/>
								</Grid>
							</Grid>
						)}
					</div>
				</Grid>
				<Grid item lg={6} xs={12}>
					{loadingDetail ? (
						<CustomProgress />
					) : (
						<>
							{!!documentdetail && documentList.length > 0 && (
								<IntegrationDetail data={documentdetail} />
							)}
						</>
					)}
				</Grid>
			</Grid>

			<PopoverModal
				handleClose={() => setModalDatesAnchorEl(null)}
				open={Boolean(modalDatesAnchorEl)}
				component={() => (
					<FilterDate
						handleConfirmDate={handleConfirmDate}
						handleCleanFilter={handelCleanFilterDate}
						dateFrom={filter.Fechadesde}
						dateTo={filter.FechaHasta}
					/>
				)}
				title={""}
				width={530}
				popoverAnchorEl={modalDatesAnchorEl}
				showCloseButton={false}
			/>
			<PopoverModal
				handleClose={() => setModalChecksAnchorEl(null)}
				open={Boolean(modalChecksAnchorEl)}
				component={modalStatusFilter}
				width={191}
				popoverAnchorEl={modalChecksAnchorEl}
				showCloseButton={false}
			/>
		</>
	);
};
