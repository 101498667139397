import { API_ENDPOINT_ARTICLE, defaultHeaders, QUANTITY_DATA_CATEGORY } from "../config/config";
import { axiosApiInstance } from '../config/axios-instance'
import { enterpriseIdHeader } from './authActions';
import { types } from "../types/types";

let allCategories = [];
let totalCategories = 0;

/**
 * Registra información de nueva categoria
 * @param {object} data  Información categoria
 * @param {function} changeStatusLoading Funcion loading 
 */
export const createCategoryAction = (data, changeStatusLoading) => async (dispatch) => {

  try {
    changeStatusLoading(true);

    data = {
      ...data,
      empresaId: `${enterpriseIdHeader()}`
    }
    var result = await axiosApiInstance.post(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria"}`, data, defaultHeaders());

    //Temporal se debe devolver del backend
    const response = {
      ...result.data,
      statusCode: '200'
    }

    dispatch({
      type: types.SAVE_CATEGORY,
      payload: response,
    })

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido registrar categoria.',
        error: err
      }
    });

  } finally {
    changeStatusLoading(false);
  }
}


/**
 * Actualiza información de categoria
 * @param {object} data Información categoria
 */
export const updateCategoryAction = (data, changeStatusLoading) => async (dispatch) => {

  try {

    changeStatusLoading(true);

    data = {
      ...data,
      empresaId: `${enterpriseIdHeader()}`
    }
    var result = await axiosApiInstance.put(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria"}`, data, defaultHeaders());

    //Temporal se debe devolver del backend
    const response = {
      ...result.data,
      statusCode: '200'
    }

    dispatch({
      type: types.UPDATE_CATEGORY,
      payload: response,
    })

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido registrar categoria.',
        error: err
      }
    });

  } finally {
    changeStatusLoading(false);
  }
}


/**
 * Elimina categoria
 * @param {*} id Identificador categoria
 */
export const deleteCategoryAction = (id) => async (dispatch) => {

  try {
    changeUpdateStateLoading(dispatch, true, id);
    let config = defaultHeaders();
    Object.assign(config.headers,
      {
        'categoryId': id,
      });


    var result = await axiosApiInstance.put(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria/UpdateState"}`, null, config)

    const response = {
      ...result.data,
      statusCode: '200',
      id: id
    };

    dispatch({
      type: types.DELETE_CATEGORY,
      payload: response,
    })


  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido eliminar categoria.',
        error: err
      }
    });

  } finally {
    changeUpdateStateLoading(dispatch, false, id);
  }

}

/**
 * CArga información de categoria en redux
 * @param {object} data Información categoria
 */
export const loadCategoryAction = (data) => (dispatch) => {
  dispatch({
    type: types.LOAD_CATEGORY,
    payload: data,
  })
}

export const getCategoryAction = () => async (dispatch) => {

  try {
    let config = defaultHeaders();
    Object.assign(config.headers,
      {
        'empresaId': `${enterpriseIdHeader()}`,
      });

    var result = await axiosApiInstance.get(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria/GetCategory"}`, config);


    dispatch({
      type: types.getCategoryArticle,
      payload: result.data.result,
    })

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado de categorias.',
        error: err
      }
    });
  }
}


/**
 * Realiza consulta de categorias,paginando y filtrando
 * @param {object} data Información para filtro
 */
export const getCategoryPagination = (data, clean = false) => async (dispatch) => {

  try {
    changeGetListLoading(dispatch, true);

    if (clean) {
      allCategories = [];
      totalCategories = 0;
    }


    let config = defaultHeaders();
    Object.assign(config.headers,
      {
        'skip': data.skip,
        'quantity': QUANTITY_DATA_CATEGORY,
        'filter': data.filter === null ? '' : data.filter,
        'empresaId': `${enterpriseIdHeader()}`
      });

    var res = await axiosApiInstance.get(`${API_ENDPOINT_ARTICLE}${"/item/api/categoria/Pagination"}`, config);
    allCategories = allCategories.concat(res.data.result);
    totalCategories = res.data.count;


    dispatch({
      type: types.getCategory,
      payload: allCategories,
      payload1: totalCategories
    })

  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener consultando listado categorias.',
        error: err
      }
    });

  } finally {
    changeGetListLoading(dispatch, false);
  }

};


/**
 * Actualiza estado loading consulta listado categorias
 * @param {*} dispatch 
 * @param {*} status 
 */
function changeGetListLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_CATEGORIES,
    payload: status
  })
}


/**
 * Quita de la lista categorias eliminado y actualizando en Redux
 * @param {int} id 
 */
export const refreshAfterDeleteAction = (id) => async (dispatch) => {

  try {
    changeGetListLoading(dispatch, true);

    allCategories = allCategories.filter(a => a.id !== id);
    totalCategories = allCategories.count;

    dispatch({
      type: types.getCategory,
      payload: allCategories,
      payload1: allCategories.count
    });


  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar nuevo listado de categorias.',
        error: err
      }
    });
  }
  finally {
    changeGetListLoading(dispatch, false);
  }

}


/**
 * Cambia estado loading, para categoria dentro de listado de categorias
 * @param {object} dispatch 
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateLoading(dispatch, status, id) {

  let newData = allCategories.find(n => n.id === id);

  if (!!newData) {
    const index = allCategories.findIndex(e => e.id === id);
    let newArray = [...allCategories];
    newArray[index] = {
      ...newData,
      loading: status
    };
    allCategories = newArray;
  }

  dispatch({
    type: types.getCategory,
    payload: allCategories,
    payload1: allCategories.count
  })
}


export const dataCleanCloseForm = (data) => async (dispatch) => {
  let sendClean = {
    data: data.data,
    clean: data.clean === undefined ? [] : []
  }
  try {
    dispatch({
      type: types.CLEAN_DATA_CATEGORY,
      payload: sendClean,
      payload1: false
    });
  } catch (error) {
    console.log("Error-->", error);
  }
}

export const cleanStatusEdit = (request) => (dispatch) => {
  dispatch({
    type: types.CLEAN_STATUS,
    payload: request.data
  });
}



