import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEquivalentDocTypesAction } from "../../../actions/equivalentDocAction";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useFormComplete } from "../../../hooks/useFormComplete";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import SimpleReactValidator from "simple-react-validator";

import SelectForm from "../../../components/select/select.component";
import InputForm from "../../../components/input/input.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";

const useStyles = makeStyles(() => ({
  root: {
    width: "80%",
  },
  butonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonDiv: {
    width: "420px",
  },
  warningText: {
    position: "relative",
    width: "100%",
    margin: "10px 15px",
    fontSize: "20px",
    fontStyle: "italic",
    lineHeight: "25.1px",
    textAlign: "left",

    fontWeight: 500,
    color: "#7DD1F8",
    "&>span": {
      fontWeight: 700,
    },
  },
}));
const EquivalentDocumentForm = ({ loading }) => {
  const animated = "animate__animated animate__fadeIn animate__faster";
  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();

  const [avalableForm, setAvalableForm] = useState(false);

  const { values, handleInputChange, reset } = useFormComplete({
    nombreSowftwareDE: "",
    pinSoftwareDE: "",
    identificadorSoftwareDE: "",
    testIdDE: "",
    tipoDocumentoEquivalente: "",
  });

  const {
    nombreSowftwareDE,
    pinSoftwareDE,
    identificadorSoftwareDE,
    testIdDE,
    tipoDocumentoEquivalente,
  } = values;

  const { typesEquivalentDocument } = useSelector((s) => s.configReducer);

  /**
   * Guarda el formulario para el test set id de documento equivalente
   * @param {*} e
   */
  const onSaveForm = (e) => {
    e.preventDefault();
    const formValid = validator.allValid();

    if (!formValid) {
      validator.showMessages();
      forceUpdate(1);
    } else {
      setAvalableForm(true);
      const documentType = typesEquivalentDocument.filter(
        (t) => t.id.toString() === tipoDocumentoEquivalente
      );

      const documentEquivalent = {
        nombreSowftwareDE,
        pinSoftwareDE,
        identificadorSoftwareDE,
        testIdDE,
        tipoDocEquivalente: documentType[0].codigo,
        tipoDocEquivalenteId: documentType[0].id,
        tipoDocEquivalenteDescripcion: documentType[0].descripcion,
      };
      dispatch(setEquivalentDocTypesAction(documentEquivalent, reset));
      if (!loading) {
        validator.hideMessages();
      }
    }
    validator.hideMessages();
  };

  /**
   * Valida los inputs después de hacer click fuera de cada uno de ellos y actualiza el estado del botón para enviar el formulario.
   */
  const validateFieldsOnBlur = (name) => {
    if (validator.fieldValid(name) === false) {
      validator.showMessageFor(name);
      forceUpdate(Math.random());
      setAvalableForm(false);
    } else {
      const validate = validator.allValid();
      validate && setAvalableForm(true);
    }
  };

  return (
    <Grid container>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={animated}>
        <SelectForm
          label={"Tipo de Documento*"}
          name="tipoDocumentoEquivalente"
          value={tipoDocumentoEquivalente}
          options={typesEquivalentDocument}
          disabled={typesEquivalentDocument.length === 0}
          validator={validator}
          validateOptions={"required"}
          nameValidator={"Tipo de Documento*"}
          onChange={handleInputChange}
        />
      </Grid>
      {/* Se comenta por cambios de la dian con el proveedor tecnológico */}
      {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={animated}>
        <InputForm
          name={"nombreSowftwareDE"}
          label={"Nombre del Software"}
          maxLength={100}
          onChange={handleInputChange}
          value={nombreSowftwareDE}
          validator={validator}
          validateOptions={"required|min:1|max:100"}
          nameValidator={"Nombre del Software"}
          onBlur={(e) => validateFieldsOnBlur("Nombre del Software")}
            tooltip={
              <TooltipMessage
                title={this.props.menuMyAccount.titleHealthCode}
                message={this.props.menuMyAccount.messageHealthCode}
                botton={this.props.menuMyAccount.bottonHealthCode}
                href={this.props.menuMyAccount.urlHealthCode}
              />
            }
        />
      </Grid> */}
      {/* Se comenta por cambios de la dian con el proveedor tecnológico */}
      {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={animated}>
        <InputForm
          name={"identificadorSoftwareDE"}
          label={"ID del Software"}
          maxLength={40}
          onChange={handleInputChange}
          value={identificadorSoftwareDE}
          validator={validator}
          validateOptions={"required|uuid|max:40"}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          onBlur={(e) => validateFieldsOnBlur("ID del Software")}
            tooltip={
              <TooltipMessage
                title={this.props.menuMyAccount.titleHealthCode}
                message={this.props.menuMyAccount.messageHealthCode}
                botton={this.props.menuMyAccount.bottonHealthCode}
                href={this.props.menuMyAccount.urlHealthCode}
              />
            }
        />
      </Grid> */}
      {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={animated}>
        <InputForm
          name={"pinSoftwareDE"}
          maxLength={6}
          label={"PIN del Software"}
          format={"onlynNumber"}
          onChange={handleInputChange}
          value={pinSoftwareDE}
          validator={validator}
          validateOptions={"required|min:3|max:6|isNumber|noNegative|noDecimal"}
          placeholder={"123456"}
          onBlur={(e) => validateFieldsOnBlur("PIN del Software")}
            tooltip={
              <TooltipMessage
                title={this.props.menuMyAccount.titleHealthCode}
                message={this.props.menuMyAccount.messageHealthCode}
                botton={this.props.menuMyAccount.bottonHealthCode}
                href={this.props.menuMyAccount.urlHealthCode}
              />
            }
        />
      </Grid> */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={animated}>
        <InputForm
          name={"testIdDE"}
          label={"Test set id de Documento Equivalente"}
          maxLength={36}
          onChange={handleInputChange}
          value={testIdDE}
          validator={validator}
          validateOptions={"required|uuid"}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          onBlur={(e) =>
            validateFieldsOnBlur("Test set id de Documento Equivalente")
          }
          //   tooltip={
          //     <TooltipMessage
          //       title={this.props.menuMyAccount.titleHealthCode}
          //       message={this.props.menuMyAccount.messageHealthCode}
          //       botton={this.props.menuMyAccount.bottonHealthCode}
          //       href={this.props.menuMyAccount.urlHealthCode}
          //     />
          //   }
        />
      </Grid>

      <span className={classes.warningText}>
        <span>Atención:</span>
        &nbsp; Asegúrate de que la información diligenciada esté correcta, una
        vez agregado el documento no podrás editarlo ni eliminarlo.
      </span>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.butonContainer}
      >
        <div className={classes.buttonDiv}>
          <ButtonPrimary
            text={"Agregar Documento"}
            type={"submit"}
            onClick={onSaveForm}
            loading={loading}
            disabled={!avalableForm}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default EquivalentDocumentForm;
