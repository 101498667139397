import { types } from "../types/types";
import isEmpty from "../utils/isEmpty";


const initialState = {
  loadedCategory: null,
  editCategoryStatus: false,
  loadingGetCategories: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SAVE_CATEGORY:
      return {
        ...state,
        saveCategoryResponse: action.payload,
      };
    case types.DELETE_CATEGORY:
      return {
        ...state,
        deleteCategoryResponse: action.payload,
      };
    case types.UPDATE_CATEGORY:
      return {
        ...state,
        updateCategoryResponse: action.payload,
      };
    case types.LOAD_CATEGORY:
      return {
        ...state,
        loadedCategory: action.payload,
        editCategoryStatus: !isEmpty(action.payload),
      }

    case types.getCategoryArticle:
      return {
        ...state,
        getCategories: action.payload
      };

    case types.CLEAN_DATA_CATEGORY:
      return {
        ...state,
        cleanDataCategory: action.payload,
        editCategoryStatus: action.payload1
      }
    case types.CLEAN_STATUS:
      return {
        ...state,
        cleanStatus: action.payload
      }
    case types.getCategory:
      return {
        ...state,
        categoryListResponse: action.payload,
        countCategories: action.payload1
      }
    case types.LOADING_GET_CATEGORIES:
      return {
        ...state,
        loadingGetCategories: action.payload
      }
    default:
      return state;
  }
}
