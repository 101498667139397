import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { FormatDecimal } from "../../../components/common/formatDecimal";
import { formatDate } from "../../../utils/general";
import ArticlesDocuSupport from "../detailSupportComponet/ArticlesDocuSupport";
import DetailNewDocument from "./DetailNewDocument";
import { useSelector } from "react-redux";
import CollapsePanel from "../../../components/collapse/collapsestyles.component";
import GetInfoAditional from "../detailSupportComponet/GetInfoAditional";

const useStyles = makeStyles({
  containerSection: {
    overflow: "auto",
    height: "65.1vh",
    width: "100%",
    overflowX: "hidden",
    backgroundColor: "#4B4B4B",
    borderRadius: 5,
    padding: 20,
    color: "white",
  },
});

const ReviewDocument = () => {
  const classes = useStyles();

  const { opdetalledocumento, valorapagar } = useSelector(
    (state) => state.documentCartReducer
  );

  return (
    <>
      <Grid container direction="row" alignItems="stretch" justify="center">
        <Grid item xl={6} lg={8} md={8} xs={12}>
          <div className={classes.containerSection}>
            <Grid container>
              <Grid
                item
                xs={6}
                className="detailSupportNumber"
                style={{ textAlign: "start" }}
              ></Grid>
              <Grid
                item
                xs={6}
                className="dataInvoiceGrid"
                style={{ textAlign: "end" }}
              >
                {formatDate(new Date(), "dd/MM/yyyy")}
              </Grid>
            </Grid>
            <div className="spacingInvoice" />
            <div className="lineDivision" />
            <Grid container justify="center">
              <Grid item lg={4} md={4} sm={12} style={{ display: "grid" }}>
                <span className="totalRevision">
                  <FormatDecimal
                    value={valorapagar}
                    prefix={"$"}
                    size={"small"}
                  />
                </span>
                <span className="commentsRevision">
                  Valor total impuestos incluidos
                </span>
              </Grid>
            </Grid>
            <div className="lineDivision" />
            <DetailNewDocument />
            <div className="spacingInvoice" />
            <div className="greenTitleSupport">
              Servicios ( {opdetalledocumento.length} )
            </div>
            <div className="spacingInvoice" />
            {opdetalledocumento.map((detail) => (
              <ArticlesDocuSupport key={detail.id} detail={detail} />
            ))}
            <div className="spacingInvoice" />

            <CollapsePanel
              component={GetInfoAditional}
              title={"Información Adicional"}
              titleOpen={"Información Adicional"}
              open={"panel"}
              backgroundColorSummary={{
                backgroundColor: "#2CC63E",
                color: "#000000",
              }}
              backgroundColorDetail={{}}
              type="showinfo"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewDocument;
