import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Grid } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        textTransform: 'capitalize',
        color: theme.palette.primaryColor,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 5,
        backgroundColor: 'hsla(0,0%,100%,.2)',
        '& .MuiButtonBase-root': {
            backgroundColor: theme.palette.fourthBackgroundColor,
            borderRadius: 5,
            border: 'none',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.5)'
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.secundaryColor,
            borderRadius: 5,
            border: 'none'
        },
        '& .Mui-selected:hover': {
            backgroundColor: theme.palette.secundaryColor,
            borderRadius: 5,
            border: 'none'
        }
    },
    group: {
        width: '100%',
        border: 'none',
    },
    styleText: {
        color: theme.palette.primaryColor,
        textTransform: 'capitalize'
    },
    styleToogle: {
        height: 10,
        width: '100%',
        border: 'none',
        fontWeight: 500
    },
    root: {
        color: theme.palette.primaryColor,
        '& .MuiFormLabel-root.Mui-focused ': {
            color: theme.palette.primaryColor
        },
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'initial'
    },
    title: {
        color: "gray",
        fontFamily: theme.typography.fontFamily,
        fontSize: 11,
        boder: 'none',
        lineHeight: '15px',
        padding: 0
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));


export const GroupRangeDate = React.memo(({
    value,
    onChange
}) => {
    const classes = useStyles();
    const [option, setOption] = useState(value);


    const handleTooggleChange = (e, value) => {
        if (!!value) {
            setOption(value);
            onChange(value);
        }
    };

    useEffect(() => {
       setOption(value);
    }, [value])

    return (
        <div className={classes.root}>
            <span className={classes.title}>{"Días"}</span>
            <Grid container spacing={1} >
                <Grid item xs={12} className={classes.container}>
                    <ToggleButtonGroup
                        value={option}
                        exclusive
                        onChange={handleTooggleChange}
                        aria-label="text alignment"
                        size={'large'}
                        className={classes.group}
                    >
                        <ToggleButton value={30} className={classes.styleToogle}  >
                            <span className={classes.styleText} >{'30'}</span>
                        </ToggleButton>
                        <ToggleButton value={60} className={classes.styleToogle}  >
                            <span className={classes.styleText}>{'60'}</span>
                        </ToggleButton>
                        <ToggleButton value={90} className={classes.styleToogle}  >
                            <span className={classes.styleText}>{'90'}</span>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

            </Grid>
        </div>
    );
}


)

