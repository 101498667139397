import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CollapseFormPanel } from "../../document/ui/collapseFormPanel";
import EquivalentDocumentForm from "./EquivalentDocumentForm";
import RenderTypesEquivalentDoc from "./RenderTypesEquivalentDoc";

import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerCollapse: {
    width: "100%",
    maxWidth: "1103px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(800)]: {
      display: "block",
    },
  },
  warningText: {
    position: "relative",
    width: "100%",
    margin: "10px 15px",
    fontSize: "20px",
    fontStyle: "italic",
    lineHeight: "25.1px",
    textAlign: "left",
    fontWeight: 500,
    color: "#7DD1F8",
    "&>span": {
      fontWeight: 700,
    },
  },
}));

const EquivalentDocumentView = ({ docEquivalent }) => {
  const classes = useStyles();
  const [expandPanel, setExpandPanel] = useState(false);
  const [dataCard, setDataCard] = useState([]);

  const { testIdEquivalentDoc, loadingEquivalentDocTypes } = useSelector(
    (s) => s.equivalentDocReducer
  );

  /**
   * Carga inicalmente los documentos equivalente obtenidos por enterprise
   */
  useEffect(() => {
    if (docEquivalent.length > 0) setDataCard(docEquivalent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Escucha cambios en docEquivalent junto con el objeto de testId y actualiza el objeto de documento
   * equivalente
   */
  useEffect(() => {
    if (testIdEquivalentDoc?.length > 0 && docEquivalent.length > 0)
      setDataCard(docEquivalent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testIdEquivalentDoc, docEquivalent]);

  /**
   * Carga la lista de documentos equivalentes al diligenciar el formulario
   */
  useEffect(() => {
    if (!!testIdEquivalentDoc) {
      setDataCard(docEquivalent);
      setExpandPanel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testIdEquivalentDoc]);

  /**
   * Contrae o expande el formulario para documento equivalente
   */
  const handleExpandCollapse = () => {
    setExpandPanel((prev) => !prev);
  };

  /**
   * Contrae panel nuevo documento equivalente
   */
  const handleCollapsePanel = () => {
    setExpandPanel(false);
  };

  return (
    <Grid container>
      <Grid item lg={12} xs={12} style={{ maxWidth: "100%" }}>
        <div className={classes.containerCollapse}>
          <CollapseFormPanel
            component={
              <EquivalentDocumentForm
                handleCollapsePanel={handleCollapsePanel}
                loading={loadingEquivalentDocTypes}
              />
            }
            title={"Agregar documento equivalente"}
            titleOpen={
              !!expandPanel
                ? "Nuevo documento equivalente"
                : "Agregar documento equivalente"
            }
            open={expandPanel}
            onClick={handleExpandCollapse}
          />
        </div>
        {dataCard && dataCard.length > 0 && (
          <RenderTypesEquivalentDoc data={dataCard} />
        )}
      </Grid>
    </Grid>
  );
};

export default EquivalentDocumentView;
