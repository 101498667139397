import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { Grid } from "@material-ui/core";

import InputForm from "../../../components/input/input.component";
import CustomAutoComplete from "../../../components/autocomplete/autocomplete.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";

import { useFormComplete } from "../../../hooks/useFormComplete";
import generateId from "../../../utils/generateId";

import {
  addAddressAction,
  updateAddressAction,
  setActiveAddress,
} from "../../../actions/addressCustomerAction";

export const AddressForm = React.memo(() => {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const {
    values,
    handleInputChange,
    handleAutocompleteChange,
    handleUpdateForm,
    reset,
  } = useFormComplete({
    nombre: "",
    direccion: "",
    departamento: null,
    municipio: null,
  });
  const { nombre, direccion, departamento, municipio } = values;
  const [stateCities, setStateCities] = useState([]);
  const {
    getStatesResponse: statesList,
    allCitiesCountry: citiesList,
  } = useSelector((state) => state.configReducer);
  const { active: activeAddress } = useSelector(
    (state) => state.addressCustomerReducer
  );

  /**
   * Carga direccion para edición
   */
  useEffect(() => {
    if (!!activeAddress) {
      let customercity = citiesList.find(
        (c) => c.id === activeAddress.ciudadid
      );
      let stateid = customercity?.departamentoid ?? 0;
      let state = statesList.find((s) => s.id === stateid);
      let cities = citiesList.filter((c) => c.departamentoid === stateid);

      setStateCities(cities);
      handleUpdateForm({
        nombre: activeAddress.descripcion,
        direccion: activeAddress.direccion,
        departamento: state ?? null,
        municipio: customercity ?? null,
      });
    } else {
      reset();
      validator.hideMessages();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAddress, citiesList, statesList]);

  /**
   * Sincroniza nuevo valor "departamento" con state
   */
  const handleChangeState = (e, value, name) => {
    let newCities = [];
    if (!!value)
      newCities = citiesList.filter(
        (c) => c.departamentoid === parseInt(value.value)
      );

    handleUpdateForm({
      municipio: null,
      departamento: value,
    });
    setStateCities(newCities);
  };

  /**
   * Guarda o actualiza información dirección adicional
   * @param {*} e
   */
  const handleSave = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
      return;
    }

    let currentAddress = {
      id: !activeAddress ? generateId() : activeAddress.id,
      descripcion: nombre,
      direccion: direccion,
      departamentoid: parseInt(departamento.value),
      departamentodescripcion: departamento.text,
      ciudadid: parseInt(municipio.value),
      ciudaddescripcion: municipio.text,
    };

    if (!activeAddress) {
      dispatch(addAddressAction(currentAddress));
    } else {
      dispatch(updateAddressAction(currentAddress));
      dispatch(setActiveAddress(null));
    }

    reset();
    validator.hideMessages();
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} xs={12}>
        <InputForm
          name={"nombre"}
          label={"Nombre Dirección *"}
          maxLength={300}
          value={nombre}
          onChange={handleInputChange}
          nameValidator={"Nombre Dirección"}
          validator={validator}
          validateOptions={"required|min:3|max:300"}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <InputForm
          name={"direccion"}
          label={"Dirección *"}
          maxLength={100}
          value={direccion}
          onChange={handleInputChange}
          nameValidator={"Dirección"}
          validator={validator}
          validateOptions={"required|min:3|max:100"}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <CustomAutoComplete
          name="departamento"
          label="Departamento *"
          value={departamento}
          options={statesList}
          textNoOption="No se encontraron resultados."
          onChange={handleChangeState}
          validator={validator}
          validateOptions={"required"}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <CustomAutoComplete
          name="municipio"
          label="Municipio *"
          value={municipio}
          options={stateCities}
          textNoOption="No se encontraron resultados."
          onChange={handleAutocompleteChange}
          validator={validator}
          validateOptions={"required"}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <ButtonPrimary
          text={!!activeAddress ? "Actualizar" : "Agregar"}
          type="button"
          loading={false}
          onClick={handleSave}
        />
      </Grid>
    </Grid>
  );
});
