import { axiosApiInstance } from "../config/axios-instance";
import {
  API_ENDPOINT_ARTICLE,
  defaultHeaders,
  TIME_CREATE_REGISTER,
} from "../config/config";
import { SuccessAlert } from "../helpers/alert.helpers";
import { types } from "../types/types";
import { enterpriseIdHeader } from "./authActions";

const urlEndPoint = `${API_ENDPOINT_ARTICLE}/item/api/cargo`;

/**
 * Obtine listado cargos asociados a empresa
 * @returns
 */
export const getChargesAction = () => async (dispatch) => {
  try {
    changeGetListLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      enterpriseId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.get(
      `${urlEndPoint}/getallbyenterpriseid`,
      config
    );
    dispatch({
      type: types.GET_CHARGES_LIST,
      payload: resp.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar listado cargos.",
        error: err,
      },
    });
  } finally {
    changeGetListLoading(dispatch, false);
  }
};

/**
 * Guarda información nuevo cargo
 * @param {*} data
 * @returns
 */
export const saveChargeAction = (data, reset) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    };

    const resp = await axiosApiInstance.post(
      `${urlEndPoint}/createcharge`,
      data,
      defaultHeaders()
    );
    if (resp.data.statusCode === "201") {
      dispatch({
        type: types.ADD_NEW_CHARGE,
        payload: resp.data.result,
      });
      SuccessAlert(
        null,
        `Creaste el cargo ${data.descripcion} de manera exitosa, sigamos Facturando`,
        TIME_CREATE_REGISTER
      );
      reset();
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido registrando información de cargo.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Carga información de cargo para edición
 * @param {*} charge
 * @returns
 */
export const loadChargeInFormAction = (charge) => (dispatch) => {
  dispatch({
    type: types.LOAD_DOCUMENT_CHARGE,
    payload: charge,
  });
};

const changeSaveLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_ADD_CHARGE,
    payload: status,
  });
};

const changeGetListLoading = (dispatch, status) => {
  dispatch({
    type: types.LOADING_CHARGES_LIST,
    payload: status,
  });
};
