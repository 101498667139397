import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CustomTabs from '../../components/tabs/tabs.component';

import { getTabsEquivalentDoc } from './utils';

import { clearEquivalentDocumentReducerAction } from '../../actions/equivalentDocAction';
import { changeDigitsAfterDecimalDocAction } from '../../actions/documentCartAction';

import { CreateEquivalentDocumentView } from './components/createEquivalentDocument';

import { DIGITS_AFTER_DECIMAL_EQUIVALENT_DOC } from '../../config/config';

const EquivalentDocument = () => {
  const {
    showGrid,
    showCreate,
    showEdit,
    // showDetailNote
  } = useSelector((state) => state.equivalentDocReducer.showComponents);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeDigitsAfterDecimalDocAction(DIGITS_AFTER_DECIMAL_EQUIVALENT_DOC)
    );
    return () => {
      dispatch(clearEquivalentDocumentReducerAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showGrid && (
        <div className='container-form'>
          <CustomTabs tabs={getTabsEquivalentDoc()} />
        </div>
      )}
      {showCreate && <CreateEquivalentDocumentView />}
      {showEdit && <CreateEquivalentDocumentView edit />}
    </>
  );
};

export default EquivalentDocument;
