import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import { validateNumberFormat } from "../../utils/managementNumber";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: 0
    },
    iconLock: {
        color: '#B8B8B8',
        fontSize: 18
    },
    iconButton:{
        padding: '12px 0px',
        color: 'white'
    }
}));

const CustomInput = withStyles({
    root: {
        '& .MuiInputLabel-formControl': {
            fontSize: 12
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: '#FFFF',
        },
        '& label.Mui-focused': {
            color: '#16B1F3', //verde
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#16B1F3', //verde
        },
        '& .MuiInputBase-input': {
            color: '#FFFF',
            fontSize: 12,
            paddingTop: 0,
            paddingBottom: 5
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: 'gray', //gris
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'gray', //gris
        },
        '& input.Mui-disabled': {
            color: 'rgba(255,255,255,0.5);'
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#16B1F3', //verde
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: 10
        }

    }
})(TextField);


const NumberFormatCustom = ({
    inputRef,
    onChange,
    name,
    ...other
}) => {

    const handleChange = (values) => {
        const validNumber = validateNumberFormat(values.value ?? '');
        onChange({
            target: {
                name: name,
                value: validNumber
            }
        })

    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={handleChange}
            isNumericString={false}
            thousandSeparator=","
            decimalSeparator="."
            prefix=""
            allowNegative={false}
            allowedDecimalSeparators={['.']}
        />
    );
}

const AutoIncrementSmall = ({ ...props }) => {
    const classes = useStyles();
    const { onBlur, onChange, handleIncrement, handleDecrement, name, maxLength, fullWidth } = props;
    let { value } = props;


    return (
        <CustomInput
            fullWidth= {fullWidth ?? true}
            name={name}
            className={classes.margin}
            label={"Cantidad"}
            value={value}
            size="small"
            onBlur={onBlur}
            onChange={onChange}
            autoComplete='off'
            inputProps={{ maxLength: maxLength }}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton onClick={handleIncrement} className={classes.iconButton}>
                            <span className={classes.icon}>+</span>
                        </IconButton>
                    </InputAdornment>,
                startAdornment:
                    <InputAdornment position="start">
                        <IconButton onClick={handleDecrement} className={classes.iconButton} >
                            <span className={classes.icon}>-</span>
                        </IconButton>
                    </InputAdornment>,
                inputComponent: NumberFormatCustom

            }}
        />
    );

}

export default AutoIncrementSmall;
