import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import { red } from '@material-ui/core/colors';
import ButtonLink from '../../components/button/buttonLink.component';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(255,255,255,0.11)',
    color: theme.palette.primaryColor,
    borderRadius: 4
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    color: theme.palette.primaryColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    lineHeight: '30px',
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  divImage: {
    textAlign: 'center',
  },
  contentCard: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 10,
    }
  }
}));

export default function PolicyCard(props) {
  const { title, imageCard, hrefButton, textButton } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.contentCard}>
        <div className={classes.title}
          dangerouslySetInnerHTML={{ __html: title }} />
        <div className={classes.divImage}>
          <img src={imageCard} alt='card' />
        </div>
        <div>
          <ButtonLink
            text={textButton}
            href={hrefButton}
          />
        </div>
      </CardContent>
    </Card>
  );
}
