/**
 * * Obtien el valor de la UVT por año
 * @param {*} year
 * @returns
 */

export const GetUVTYear = (year, allUvt) => {
  let uvt = 0;
  allUvt.forEach((item) => {
    if (item.anio === year.toString()) {
      uvt = item.valor;
    }
  });
  return uvt;
};
