import { types } from "../types/types";
import isEmpty from "../utils/isEmpty";

const initialState = {
  loadingSaveBiller: false,
  loadingSaveSeller: false,
  loadingGetBillers: false,
  editBillerStatus: false,
  editSellerStatus: false,
  openModalItem: false,
  licenseBiller: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_BILLER:
      return {
        ...state,
        saveBillerResponse: action.payload,
      };
    case types.GET_BILLERS:
      return {
        ...state,
        filteredBillersList: action.payload,
      };
    case types.SAVE_SELLER:
      return {
        ...state,
        saveSellerResponse: action.payload,
      };
    case types.GET_SELLERS:
      return {
        ...state,
        filteredSellersList: action.payload,
      };
    case types.UPDATE_BILLER:
      return {
        ...state,
        updateBillerResponse: action.payload,
      };
    case types.LOAD_BILLER:
      return {
        ...state,
        loadedBillerObject: action.payload,
        editBillerStatus: !isEmpty(action.payload),
      };
    case types.LOAD_SELLER:
      return {
        ...state,
        loadSellerObject: action.payload,
        editSellerStatus: !isEmpty(action.payload),
      };
    case types.UPDATE_SELLER:
      return {
        ...state,
        updateSellerResponse: action.payload,
      };

    case types.UPDATE_STATUS_BILLER:
      return {
        ...state,
        updateBillerStatusResponse: action.payload,
      };

    case types.UPDATE_STATUS_SELLER:
      return {
        ...state,
        updateSellerStatusResponse: action.payload,
      };
    case types.LOADING_SAVE_BILLER:
      return {
        ...state,
        loadingSaveBiller: action.payload,
      };
    case types.LOADING_SAVE_SELLER:
      return {
        ...state,
        loadingSaveSeller: action.payload,
      };
    case types.LOADING_GET_BILLERS:
      return {
        ...state,
        loadingGetBillers: action.payload,
      };
    case types.LOADING_GET_SELLERS:
      return {
        ...state,
        loadingGetSellers: action.payload,
      };
    case types.INFO_BILLER:
      return {
        ...state,
        openModalItem: action.payload,
        updateBillerStatusResponse: action.payload1,
      };
    case types.INFO_LICENSE_BILLER:
      return {
        ...state,
        licenseBiller: action.payload,
      };
    default:
      return state;
  }
}
