import { formatDate } from "../../../utils/general";

/**
 * * Obtiene nombre del boton del cambio de estado
 * @param {*} item
 * @returns
 */
export const getNameChangeState = (item) => {
  const stateNames = {
    "03": "En mora",
    "02": "Por Pagar",
    "01": "Pagada",
  };

  return item.anulacion ? "Anulada" : stateNames[item.estadoId] || "Pagada";
};

/**
 * * Determinar el estado al cual pasa el Documento Soporte, cuando se le va a cambiar un estado.
 */
export const stateInvoice = (state) => {
  if (state?.anulacion) {
    return "Anulada";
  }

  if (!!state?.fechaPago) {
    let payDate = new Date(formatDate(new Date(state.fechaPago), "yyyy/MM/dd"));
    let currentDate = new Date(formatDate(new Date(), "yyyy/MM/dd"));

    if (state?.estadoid === "02" || state.estadoId === "03") {
      return "Pagada";
    }

    if (payDate > currentDate) {
      return "Por pagar";
    } else {
      return "En mora";
    }
  }

  if (state?.estadoId === "01") {
    return "Por pagar";
  }
  if (state?.estadoId === "02") {
    return "Pagada";
  }
};
