import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SwitchForm from "../switch/switch.component";
import { capitalizeUpperLetters } from "../../utils/general";
import { Store, Create } from "@material-ui/icons";

const useStyles = (theme) => ({
	root: {
		backgroundColor: theme.palette.thirdBackgroundColor,
		color: theme.palette.primaryColor,
		fontWeight: 300,
	},
	title: {
		color: theme.palette.primaryColor,
		fontWeight: "bold",
		fontSize: 18,
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	content: {
		paddingLeft: 66,
	},
	header: {
		alignItems: "normal",
	},
	headerContent: {
		overflow: "hidden",
	},
	typography: {
		fontSize: 16,
	},
	avatar: {
		color: theme.palette.secundaryColor,
	},
	icon: {
		color: theme.palette.secundaryColor,
	},
});

export class CustomCardBranchOffice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			cities: [],
			states: [],
			modalities: [],
			active: false,
			loadingStatus: false,
			loadingPrincipal: false,
		};
	}

	static getDerivedStateFromProps(nextProps, state) {
		let update = {};

		if (!!nextProps.configReducer.allCitiesCountry) {
			if (nextProps.configReducer.allCitiesCountry !== state.cities) {
				update.cities = nextProps.configReducer.allCitiesCountry;
			}
		}

		if (!!nextProps.configReducer.getStatesResponse) {
			if (nextProps.configReducer.getStatesResponse !== state.cities) {
				update.states = nextProps.configReducer.getStatesResponse;
			}
		}

		if (!!nextProps.numerationReducer.modalities) {
			if (nextProps.numerationReducer.modalities !== state.modalities) {
				update.modalities = nextProps.numerationReducer.modalities;
			}
		}

		if (!!nextProps.data && nextProps.data !== state.data) {
			update.data = nextProps.data;
			update.loadingStatus =
				nextProps.data?.loadingStatus === true ? true : false;
			update.loadingPrincipal =
				nextProps.data?.loadingPrincipal === true ? true : false;
		}

		if (nextProps.principal !== state.principal) {
			update.principal = nextProps.principal;
		}

		return Object.keys(update).length ? update : null;
	}

	getNumerationName = (numeration) => {
		const modality = this.state.modalities.find(
			(x) => x.id === numeration.diantipomodalidadid
		);
		return !!numeration.nombre
			? `${modality?.descripcion ?? ""} - Prefijo: ${numeration.prefijo} - ${
					numeration.nombre
			  }`
			: `${modality?.descripcion ?? ""} - Prefijo: ${numeration.prefijo}`;
	};

	handleClick = () => {
		!this.state.active
			? this.setState({ active: true })
			: this.setState({ active: false });
	};

	render() {
		const classes = this.props.classes;
		const { onClick, onChange } = this.props;

		const city =
			this.state.cities.length > 0
				? this.state.cities.find(
						(element) => element.id === this.state.data.ciudadid
				  )
				: null;

		const stateBranch = !!city
			? this.state.states.length > 0
				? this.state.states.find(
						(element) => Number.parseInt(element.value) === city.departamentoid
				  )
				: null
			: null;

		return (
			<Card className={classes.root}>
				<CardHeader
					className={classes.header}
					avatar={<Store />}
					action={
						<IconButton
							aria-label="settings"
							onClick={(e) => onClick(e, this.state.data)}
						>
							<Create className={classes.icon} />
						</IconButton>
					}
					classes={{
						title: classes.title,
						avatar: classes.avatar,
						content: classes.headerContent,
					}}
					title={this.state.data.nombre}
				/>
				<CardContent className={classes.content}>
					<Typography
						variant="body1"
						className={classes.typography}
						component={"div"}
					>
						<SwitchForm
							name="principal"
							checked={this.state.data.principal === true ? true : false}
							value={this.state.data.principal === true ? true : false}
							titleOn="Sucursal principal"
							withoutMargin={true}
							onChange={(e) => onChange(e, 1, this.state.data)}
							disabled={this.state.data.principal === true ? true : false}
							loading={this.state.loadingPrincipal}
						/>
						<Fragment>
							{!!this.state.data.numeraciones && (
								<Fragment>
									{this.state.data.numeraciones.length > 0
										? this.state.data.numeraciones.map((numeracion, index) => {
												return (
													this.getNumerationName(numeracion) +
													(index === this.state.data.numeraciones.length - 1
														? ""
														: ",")
												);
										  })
										: "Sin numeraciones asignadas"}
									<br />
								</Fragment>
							)}
							{this.state.data.direccion}
							<br />
							{!!stateBranch
								? stateBranch.text
								: "No se ha podido cargar el departamento"}
							<br />
							{!!city
								? capitalizeUpperLetters(city.descripcion)
								: "No se ha podido cargar la ciudad"}
							<br />
						</Fragment>
						<SwitchForm
							name="activo"
							checked={!this.state.data.idstate}
							value={!this.state.data.idstate}
							titleOn={
								!this.state.data.idstate
									? "Desactivar sucursal"
									: "Activar sucursal"
							}
							withoutMargin={true}
							onChange={(e) => onChange(e, 2, this.state.data)}
							loading={this.state.loadingStatus}
						/>
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

CustomCardBranchOffice.propTypes = {
	data: PropTypes.object,
};

const mapStateToProps = (state) => ({
	configReducer: state.configReducer,
	numerationReducer: state.numerationReducer,
});

export default connect(
	mapStateToProps,
	{}
)(withStyles(useStyles)(CustomCardBranchOffice));
