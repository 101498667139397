import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {imageCutterSize} from "../../config/config";

const CustomCropper = props => {
  const {image, handleClose, onClickSave, setEditorRef} = props;

  const [state, setState] = useState({
    allowZoomOut: true,
    scale: 1,
    width: imageCutterSize.width,
    height: imageCutterSize.height,
  });

  const handleScale = (event, newValue) => {
    setState((prevState) => ({...prevState, scale: newValue}));
  };

  return (
    <Grid container>
      <Grid container spacing={2}>
        <AvatarEditor
          ref={setEditorRef}
          scale={parseFloat(state.scale)}
          image={image}
          style={{width: state.width, height: state.height, margin: 'auto'}}
          color={[0, 0, 0, 0.6]}
          border={30}
        />
        <br/>
        <Grid item md={12} xs={12}>
          <div style={{margin: 'auto', width: 300}}>
            <Typography id="discrete-slider" gutterBottom>
              Zoom:
            </Typography>
            <Slider
              style={{color: '#16B1F3'}}
              defaultValue={1}
              onChange={handleScale}
              aria-labelledby="discrete-slider"
              step={0.01}
              min={state.allowZoomOut ? 0.1 : 1}
              max={2}
            />
          </div>
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={2} style={{ paddingRight: 30 }}>
        <Grid item md={6} xs={12}>
          <button
            type="button"
            className="btn-modal-image"
            onClick={handleClose}>
            Cerrar
          </button>
        </Grid>
        <Grid item md={6} xs={12}>
          <button
            type="button"
            className="btn-modal-image"
            onClick={onClickSave}>
            Terminar recorte
          </button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomCropper
