import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import CustomTooltip from "../../../components/tooltip/tooltip.component";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";
import { FormatDecimal } from "../../../components/common/formatDecimal";

import { getRoleOfLoggedUser } from "../../../actions/authActions";

const animate = " animate__animated animate__fadeIn animate__faster";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.thirdBackgroundColor,
		color: theme.palette.primaryColor,
		"& .MuiCardContent-root": {
			paddingTop: 0,
			paddingBottom: 5,
			marginBottom: 0,
			marginTop: 0,
			paddingLeft: 55,
		},
	},
	avatar: {},
	header: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	content: {
		fontSize: 14,
	},
	title: {
		color: theme.palette.primaryColor,
	},

	icon: {
		color: theme.palette.secundaryColor,
	},
	iconAction: {
		color: theme.palette.primaryColor,
	},
	valueText: {
		color: "#F5D13D",
		fontWeight: 500,
	},
	help: {
		marginTop: 10,
	},
	actions: {
		display: "flex",
	},
}));

export const DiscountCard = React.memo(({ data, handleDelete, handleLoad }) => {
	const classes = useStyles();

	const {
		tooltipComercialDiscountHeader,
		tooltipComercialDiscountBody,
		tooltipComercialDiscountBotton,
		tooltipComercialDiscountUrl,
		tooltipFinancialDiscountHeader,
		tooltipFinancialDiscountBody,
		tooltipFinancialDiscountBotton,
		tooltipFinancialDiscountUrl,
	} = useSelector((state) => state.menuReducer.menuDiscount);

	return (
		<Card className={`${classes.root} ${animate}`}>
			<CardHeader
				className={classes.header}
				avatar={
					data.modelodescuento === 1 ? (
						<LocalOfferIcon fontSize="small" className={classes.icon} />
					) : (
						<AccountBalanceIcon fontSize="small" className={classes.icon} />
					)
				}
				action={
					<div className={classes.actions}>
						<div className={classes.help}>
							<CustomTooltip
								message={
									data.modelodescuento === 1 ? (
										<TooltipMessage
											title={tooltipComercialDiscountHeader}
											message={tooltipComercialDiscountBody}
											botton={tooltipComercialDiscountBotton}
											href={tooltipComercialDiscountUrl}
										/>
									) : (
										<TooltipMessage
											title={tooltipFinancialDiscountHeader}
											message={tooltipFinancialDiscountBody}
											botton={tooltipFinancialDiscountBotton}
											href={tooltipFinancialDiscountUrl}
										/>
									)
								}
							/>
						</div>

						{getRoleOfLoggedUser() ? (
							<Tooltip title="No tiene permisos sobre esta acción">
								<IconButton aria-label="Editar">
									<CreateIcon className={classes.iconAction} />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Editar">
								<IconButton aria-label="Editar" onClick={handleLoad}>
									<CreateIcon className={classes.iconAction} />
								</IconButton>
							</Tooltip>
						)}

						<Tooltip title="Eliminar">
							<IconButton aria-label="Eliminar" onClick={handleDelete}>
								<DeleteIcon className={classes.iconAction} />
							</IconButton>
						</Tooltip>
					</div>
				}
				classes={{
					title: classes.title,
				}}
				title={
					data.modelodescuento === 1
						? "Descuento Comercial"
						: "Descuento Financiero"
				}
			/>
			<CardContent className={classes.content}>
				{data.tipodescuento === 1 ? (
					<span className={classes.valueText}>
						<FormatDecimal value={data.valoradescontar} prefix={"$"} />
					</span>
				) : (
					<>
						<span>
							<FormatDecimal value={data.valor} suffix={"%"} size={"inherit"} />
						</span>
						&nbsp;
						<span className={classes.valueText}>
							<FormatDecimal
								value={data.valoradescontar}
								prefix={"($"}
								suffix={")"}
							/>
						</span>
					</>
				)}
				<br />
				<span>{`Motivo: ${data.motivodescripcion}`}</span>
				<br />
				<span>{data.descripcion}</span>
			</CardContent>
		</Card>
	);
});
