import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import imageMode from "../../images/modal/14Ocurriounerror-14.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  typographyNotification: {
    textAlign: "center",
  },
  span: {
    fontSize: 30,
    fontFamily: "Muli",
    color: "#FFFFFF",
    letterSpacing: 0,
  },
}));

export const NotificationReadingMode = ({ text = "" }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12}>
        <img alt="" src={imageMode} />
      </Grid>
      <Grid item xs={6} className={classes.typographyNotification}>
        <span className={classes.span}>{text}</span>
      </Grid>
    </Grid>
  );
};
