import { types } from "../types/types";

const initialState = {
  requestPostTemplate: {},
  stepTemplateComplete: false,
  loadingGetTemplate: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_TEMPLATE:
      return {
        ...state,
        templatesList: action.payload,
      };
    case types.SAVE_TEMPLATE:
      return {
        ...state,
        requestPostTemplate: action.payload,
      };
    case types.STATUS_STEP_TEMPLATE:
      return {
        ...state,
        stepTemplateComplete: action.payload,
      };
    case types.LOADING_GET_TEMPLATE:
      return {
        ...state,
        loadingGetTemplate: action.payload,
      };
    default:
      return state;
  }
}
