import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import MasterPage from "../components/masterPage/masterPage.component.js";
import { setIndexSelectedAction } from "../actions/generalAction";
import { URL_SECURITY } from "../config/config";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  /**
   * Marca opción del menu seleccionada
   */
  useEffect(() => {
    if (rest.menuid !== null && rest.menuid !== undefined) {
      dispatch(setIndexSelectedAction(rest.menuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.menuid]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {localStorage.getItem("isAuthenticated") ? (
            <MasterPage component={Component} {...props} />
          ) : (
            (window.location.href = URL_SECURITY)
          )}
        </>
      )}
    />
  );
};

export default PrivateRoute;
