import { ColumnsFile } from "../validations/Rules";
import ResultTest from "../validations/ResultTest";

/**
 * agrega los errores de back a la lista ya existente de errores de front
 * @param {*} itemDistinctList 
 * @param {*} itemAllList 
 * @param {*} infoColumn 
 * @param {*} results 
 */
export const addBackValidations = (itemDistinctList, itemAllList, infoColumn, results, list) => {
    const BreakException = {}
    itemAllList.forEach((pago, index) => {
        if (pago && pago.trim().length !== 0) {
            try {
                itemDistinctList && itemDistinctList.forEach(item => {
                    let strValdiation = '';
                    strValdiation = getStringToValidate(infoColumn, item);
                    if (infoColumn.name !== ColumnsFile.nombreClienteColumn.name && pago === strValdiation) {
                        list[index][infoColumn.name] = item
                        if (item.id === 0) {
                            const validationBackError = new ResultTest( ColumnsFile.municipioColumn.name === infoColumn.name || ColumnsFile.iAMunicipio1Column.name === infoColumn.name  || ColumnsFile.iAMunicipio2Column.name === infoColumn.name ? 'Municipio no valido.':infoColumn.msgBackValidations + " [" + pago + "]", index + 1, infoColumn.name);
                            results.push(validationBackError);
                            //Se agrega esto para hacer un break y no seguir validando campos
                            throw BreakException
                        }
                    } 
                    

                    else if (infoColumn.name === ColumnsFile.nombreClienteColumn.name && pago === strValdiation) {
                        list[index].cliente = item;
                    }
                });
            } catch (e) {
                if (e !== BreakException) throw e;
            }
        }
    });
}

/**
 * Metodos internos
 */

function getStringToValidate(infoColumn, item) {
    if (infoColumn.name === ColumnsFile.nombreClienteColumn.name) {
        return item.tipo + '-' + item.numero;
    }
    if (infoColumn.name === ColumnsFile.iANombreDireccion1Column.name || infoColumn.name === ColumnsFile.iANombreDireccion2Column.name
        || infoColumn.name === ColumnsFile.cANombreContacto1Column.name || infoColumn.name === ColumnsFile.cANombreContacto2Column.name
    ) {
        return item.cliente + '--' + item.valor;
    }
    return item.valor;
}