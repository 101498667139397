import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { RenderIconButton } from "../../footerActions";

const useStyles = makeStyles((theme) => ({
  root: { height: "6.7vh", background: "#393939" },
  [theme.breakpoints.down("lg")]: {
    root: { height: "10vh" },
  },
}));
export const FooterEquivalentDocForm = ({ renderIcon }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      className={`${classes.root}  animate__animated animate__fadeIn animate__faster`}
    >
      {renderIcon.map((icon, index) => (
        <RenderIconButton key={index} {...icon} />
      ))}
    </Grid>
  );
};
