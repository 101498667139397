import React from "react";
import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles({
  colorIcon: {
    color: "#2CC63E",
  },
  iconsDetail: {
    display: "flex",
    alignItems: "center",
  },
  wordBreak: {
    wordBreak: "break-all",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.32px",
  },
});

const DetailRewiewDocument = () => {
  const classes = useStyles();
  const { detailDocumentSupport } = useSelector(
    (state) => state.documentSupportReducer
  );
  // const {
  //   getSupportDocumentType: typesDocument,
  //   getRegimeTypesResponse: typesRegime,
  // } = useSelector((state) => state.configReducer);

  const {
    numeroDocumentoProveedor,
    razonSocial,
    descTipoRegimen,
    correoElectronico,
    telefono,
    direccion,
    prefijoDoc,
  } = detailDocumentSupport;

  // const textRegime = typesRegime.find(
  //   (reg) => reg.id === Number(descTipoRegimen)
  // );

  // const textDoc = typesDocument?.find(
  //   (doc) => doc.id === tipoDocumentoProveedor
  // );

  return (
    <>
      <div
        className="constumerNameDetailSupport"
        style={{ wordBreak: "break-word" }}
      >
        {razonSocial}
      </div>
      <div className="detailSupportInfo">
        {prefijoDoc} {numeroDocumentoProveedor}
      </div>
      <div className="detailInvoiceInfo">{descTipoRegimen}</div>
      <div className="spacingInvoice"></div>
      <Grid
        container
        style={{
          marginTop: "12px",
        }}
      >
        <Grid item xs={12} className={classes.iconsDetail}>
          <EmailIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{correoElectronico}</div>
        </Grid>

        <Grid item xs={12} className={classes.iconsDetail}>
          <PhoneIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{telefono}</div>
        </Grid>

        <Grid item xs={12} className={classes.iconsDetail}>
          <RoomIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{direccion}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailRewiewDocument;
