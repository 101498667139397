import { types } from "../types/types";

const initialState = {
    customersList: [],
    currentCustomerList: [],
    customerId: 0,
    customerDetail: null,
    customerActive: null,
    loadingSave: false,
    loadingDetail: false,
    loadingActive: false,
    loading: false,
    loadingStandOut: false,
    loadingDelete: false
}


export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CUSTOMERS_LIST:
            return {
                ...state,
                customersList: action.payload
            }
        case types.LOADING_GET_CUSTOMERS:
            return {
                ...state,
                loading: action.payload
            }
        case types.REFRESH_CURRENT_CUSTOMERS:
            return {
                ...state,
                currentCustomerList: action.payload
            }
        case types.LOADING_GET_CUSTOMER_DOCUMENT:
            return {
                ...state,
                loadingDetail: action.payload
            }
        case types.SET_CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload
            }
        case types.LOAD_CUSTOMER_DETAIL:
            return {
                ...state,
                customerDetail: action.payload
            }
        case types.ADD_CUSTOMER_ACTIVE:
            return {
                ...state,
                customerActive: action.payload
            }
        case types.LOADING_GET_CUSTOMER_ACTIVE:
            return {
                ...state,
                loadingActive: action.payload
            }
        case types.STAND_OUT_CUSTOMER:
            return {
                ...state,
                customerDetail: { ...state.customerDetail, favorito: !state.customerDetail.favorito },
                customersList: state.customersList.map(item =>
                    item.id === action.payload
                        ? { ...item, favorito: !item.favorito }
                        : item),
                currentCustomerList: state.currentCustomerList.map(item =>
                    item.id === action.payload
                        ? { ...item, favorito: !item.favorito }
                        : item),
            }
        case types.LOADING_SAVE_CUSTOMER:
            return {
                ...state,
                loadingSave: action.payload
            }
        case types.LOADING_STAND_OUT_CUSTOMERS:
            return {
                ...state,
                loadingStandOut: action.payload
            }
        case types.DELETE_CUSTOMER:
            return {
                ...state,
                customersList: state.customersList.filter(c => c.id !== action.payload),
                currentCustomerList: state.currentCustomerList.filter(c => c.id !== action.payload)
            }
        case types.LOADING_DELETE_CUSTOMER:
            return {
                ...state,
                loadingDelete: action.payload
            }
        case types.CLEAN_CUSTOMERS_DATA:
            return {
                ...initialState
            }

        default:
            return state;
    }
}