import React from "react";

import { Grid, IconButton, makeStyles } from "@material-ui/core";
import CustomProgress from "../../../../components/Progress/progress.component";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    alignItems: "center",
    color: "white",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    "&:hover": {
      animation: "pulse  1s ",
    },
  },
  iconContainer: {
    alignItems: "center",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  textIconButton: {
    fontFamily: "Muli",
    fontSize: "10px",
    fontWeight: "700",
    letterSpacing: "0",
    lineHeight: "18px",
    textAlign: "center",
  },
}));

export const RenderIconButton = ({
  icon,
  isLoading = false,
  onClick,
  text,
  xs = 3,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs}>
      <Grid item xs={12} className={classes.iconContainer}>
        {isLoading ? (
          <CustomProgress size={20} />
        ) : (
          <IconButton className={classes.iconButton} onClick={onClick}>
            {icon}
          </IconButton>
        )}
        <span className={classes.textIconButton}>{text}</span>
      </Grid>
    </Grid>
  );
};
