/**
 * * Filtra un array de datos por el idstate donde 1 es Activo y 0 es Inactivo
 *
 * @param {Array} data - El array de datos a filtrar.
 * @param {number} stateId - El estado de identificación por el cual filtrar los datos.
 * @returns {Array} - El array filtrado por el estado de identificación.
 */
export const filterByIdState = (data, stateId) => {
  return data.filter((item) => item.idstate === stateId);
};

/**
 * Ordena un array de objetos alfabéticamente por una propiedad específica.
 *
 * @param {Array} array - El array de objetos a ordenar.
 * @param {string} property - La propiedad por la cual comparar los objetos.
 * @returns {Array} - El array ordenado alfabéticamente.
 */
export const sortByProperty = (array, property) => {
  return array.sort((a, b) => a[property].localeCompare(b[property]));
};
