import { Rules, ColumnsFile } from "../validations/Rules";
import ValidateField from "../validations/ValidateField";
import ResultTest from "../validations/ResultTest";
import { getFirstPositionSplit, removeNullValuesFromList } from "./util-functions.helpers";
import { addBackValidations } from "./back-validations.helpers";
import { groupByLine } from "../../../invoice/bulkUpload/helpers/util-functions.helpers";
import { buildJsonToSave } from "./json-bulk-load.helpers";
import { checkValidationsClientService, checkAditionalValidationsClientService, saveBulkLoadClientService } from "../../../../services/bulkLoadCustomerService";

/**
* Cabecera necesaria para procesar con exito el arcihvo,
* si se cambia el nombre de la cabecera del excel, 
* cambiar el name de cada columnFile
 */
const fileHeader = [
    ColumnsFile.itemColumn.name,
    ColumnsFile.tipoDocumentoColumn.name,
    ColumnsFile.numeroDocumentoColumn.name,
    ColumnsFile.nombreClienteColumn.name,
    ColumnsFile.tipoPersonaColumn.name,
    ColumnsFile.regimenColumn.name,
    ColumnsFile.correoElectronicoColumn.name,
    ColumnsFile.telefonoColumn.name,
    ColumnsFile.direccionColumn.name,
    ColumnsFile.municipioColumn.name,
    ColumnsFile.iANombreDireccion1Column.name,
    ColumnsFile.iADireccion1Column.name,
    ColumnsFile.iAMunicipio1Column.name,
    ColumnsFile.iANombreDireccion2Column.name,
    ColumnsFile.iADireccion2Column.name,
    ColumnsFile.iAMunicipio2Column.name,
    ColumnsFile.cANombreContacto1Column.name,
    ColumnsFile.cACelularContacto1Column.name,
    ColumnsFile.cAEmailContacto1Column.name,
    ColumnsFile.cANombreContacto2Column.name,
    ColumnsFile.cACelularContacto2Column.name,
    ColumnsFile.cAEmailContacto2Column.name,
]


/**
 * Cargue de validaciones (expresiones regulares) y sus respectivos mensajes si no se cumple la expresion regular 
 **/
const inputValidations = [
    Rules.itemColumn,
    Rules.tipoDocumentoColumn,
    Rules.numeroDocumentoColumn,
    Rules.nombreClienteColumn,
    Rules.tipoPersonaColumn,
    Rules.regimenColumn,
    Rules.correoElectronicoColumn,
    Rules.telefonoColumn,
    Rules.direccionColumn,
    Rules.municipioColumn,
    Rules.iANombreDireccionColumn,
    Rules.iADireccion1Column,
    Rules.iAMunicipioColumn,
    Rules.iANombreDireccionColumn,
    Rules.iADireccionColumn,
    Rules.iAMunicipioColumn,
    Rules.cANombreContactoColumn,
    Rules.cACelularContactoColumn,
    Rules.cAEmailContactoColumn,
    Rules.cANombreContactoColumn,
    Rules.cACelularContactoColumn,
    Rules.cAEmailContactoColumn,
];


const readLine = (line) => {
    if (line.slice(-1) === ',') {
        return line.slice(0, -1);
    }
    return line;
}

/**
 * Función para determinar si un valor ingresado a numero de documento es valido o no.
 * @param {*Numero de documento a validar} value 
 * @returns 
 */
const validateNumber = (type, value) => {
    if (type.toUpperCase() !== 'PA') {
        return isNaN(value)
    }
}

/**
 * Validación  del tamaño y nombre de columnas del archivo
 * @param {*} header
 * @param {*} fileHeader
 */
const validateHeader = (header, fileHeader) => {
    if (header.length === fileHeader.length) {
        for (let index = 0; index < header.length; index++) {
            if (header[index] !== fileHeader[index]) {
                return false;
            }
        }
        return true;
    } else {
        return false;
    }
}




const validateContactAndAditionalData = (row, line, position, results) => {
    if (position === ColumnsFile.iANombreDireccion1Column.position) {
        validateAditionalData(line, results, ColumnsFile.iANombreDireccion1Column, row[ColumnsFile.iANombreDireccion1Column.position],
            row[ColumnsFile.iADireccion1Column.position], row[ColumnsFile.iAMunicipio1Column.position], "información adicional 1")
    } else if (position === ColumnsFile.iANombreDireccion2Column.position) {
        validateAditionalData(line, results, ColumnsFile.iANombreDireccion2Column, row[ColumnsFile.iANombreDireccion2Column.position],
            row[ColumnsFile.iADireccion2Column.position], row[ColumnsFile.iAMunicipio2Column.position], "información adicional 2")
    } else if (position === ColumnsFile.cANombreContacto1Column.position) {
        validateAditionalData(line, results, ColumnsFile.cANombreContacto1Column, row[ColumnsFile.cANombreContacto1Column.position],
            row[ColumnsFile.cACelularContacto1Column.position], row[ColumnsFile.cAEmailContacto1Column.position], "contacto adicional 1")
    } else if (position === ColumnsFile.cANombreContacto2Column.position) {
        validateAditionalData(line, results, ColumnsFile.cANombreContacto2Column, row[ColumnsFile.cANombreContacto2Column.position],
            row[ColumnsFile.cACelularContacto2Column.position], row[ColumnsFile.cAEmailContacto2Column.position], "contacto adicional 2")
    }
};


const validateAditionalData = (line, results, column, field1, field2, field3, aditionalMsg) => {
    const msg = "Por favor diligenciar todos los campos restantes de ";
    if (field1 && !(field2 && field3)) {
        results.push(new ResultTest(msg + aditionalMsg, line, column.name))
    } else if (!field1 && (field2 || field3)) {
        results.push(new ResultTest(msg + aditionalMsg, line, column.name))
    }
}


const data = (error) => {
    let errorList = [];
    error.forEach(item => {
        errorList.push(buildData(item));
    });
    return errorList;
}

const buildData = (error) => {
    return [
        { value: error.line, style: { font: { sz: "14", bold: true } } },
        { value: error.key, style: { font: { sz: "14" } } },
        { value: error.message, style: { sz: "18", inline: true } }
    ];
}

/**
 * process CSV data
 * @param {archivo en Strin} dataString 
 */
export const processDataCSV = async (dataString, showMessageError, setCustomerErrors, handleOpenModalLoading, handleOpenResultModal) => {


    //Se crea un registro por cada linea
    const dataStringLinesTmp = dataString.split(/\r\n|\n/);

    const dataStringLines = [];

    for (let i = 0; i < dataStringLinesTmp.length; i++) {
        //Se omiten filas vacias (algunos formatos de excel agregan registros vacios al final), 
        //si se aumenta o disminuye el numero de columnas, agregar o eliminar comas (,) aqui
        if (dataStringLinesTmp[i] !== ',,,,,,,,,,,,,,,,,,,,,,,,,,' && dataStringLinesTmp[i] !== '') {
            dataStringLines.push(dataStringLinesTmp[i])
        }
    }

    //Se lee la cabecera del archivo
    const headersTmp = readLine(dataStringLines[0]);

    //Se obtiene la cabecera luego de eliminar campos basura que algunos formatos de excel agregan
    const headers = headersTmp.replace(',,', '').split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    //Se valida que la cabecera tenga las columnas definidas en la plantilla
    if (!validateHeader(readLine(headers), fileHeader)) {
        showMessageError("El archivo que estas cargando no corresponde a la plantilla, Colfactura no puede interpretarlo.");
        return;
    }

    var list = [];
    const results = [];

    //Se crea instancia de tipo ValidateField y se envia al contructor las validaciones de los campos (expresiones regulares)
    const validation = new ValidateField(inputValidations);

    //Se muestra dialogo mientras cargan los datos
    handleOpenModalLoading(true, dataStringLines.length - 1);


    //Se recorre los registros del archivo excel
    for (let i = 1; i < dataStringLines.length; i++) {
        const rowTmp = readLine(dataStringLines[i]).split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );

        if (!rowTmp[21])
            rowTmp[21] = "";


        //Se eliminan celdas sobrantes (ya que alguno formatos de excel agregan columnas innecesarias al final,,,)
        const row = rowTmp.slice(0, headers.length);

        //Solo procesa el documento si el numero de columnas del contenido es igual al de la cabecera
        if (headers && row.length === headers.length) {
            const obj = {};

            //Se recorre las columnas de cada registro
            for (let j = 0; j < headers.length; j++) {
                let d = row[j];
                let e = row[j - 1];
                if (d.length > 0) {
                    if (d[0] === '"') d = d.substring(1, d.length - 1);
                    if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
                }

                d = readLine(d);

                //Se agrega el valor en un arreglo de objetos
                if (headers[j]) {
                    obj[headers[j]] = d;
                }

                //Se evalua si el valor cumple con la expresion regular
                const result = validation.validate(d, j, i, headers[j], e);
                //si existe un error se lo agrega en la lista
                if (result) {
                    results.push(result);
                }

                if (j === ColumnsFile.iANombreDireccion1Column.position || j === ColumnsFile.iANombreDireccion2Column.position
                    || j === ColumnsFile.cANombreContacto1Column.position || j === ColumnsFile.cANombreContacto2Column.position) {
                    validateContactAndAditionalData(row, i, j, results);
                }

            }

            // Remove the blank rows
            if (Object.values(obj).filter((x) => x).length > 0) {
                list.push(obj);
            }
        }
    }


    /*Se prepara request para consumir el api de validaciones, 
      se necesitan listas de valores no duplicados para enviar a back y validar si existen en BD*/
    const tipoDocumentoList = list.map(item => getFirstPositionSplit(item[ColumnsFile.tipoDocumentoColumn.name], '-'));
    const tipoDocumento = [...new Set(tipoDocumentoList)].map(item => {
        return { 'valor': item }
    });

    const tipoPersonaList = list.map(item => getFirstPositionSplit(item[ColumnsFile.tipoPersonaColumn.name], '-'));
    const tipoPersona = [...new Set(tipoPersonaList)].map(item => {
        return { 'valor': item }
    });

    const regimenList = list.map(item => getFirstPositionSplit(item[ColumnsFile.regimenColumn.name], '-'));
    const regimen = [...new Set(regimenList)].map(item => {
        return { 'valor': item }
    });

    const ciudadList = list.map(item => getFirstPositionSplit(item[ColumnsFile.municipioColumn.name], '-'));
    const ciudad = [...new Set(ciudadList)].map(item => {
        return { 'valor': item }
    });

    const clienteList = list.map(
        (item) =>
            getFirstPositionSplit(item[ColumnsFile.tipoDocumentoColumn.name], "-") +
            "-" +
            item[ColumnsFile.numeroDocumentoColumn.name]
    );
    const cliente = [...new Set(clienteList)].map((cliente) => {
        const tipoNumero = cliente.trim().split("-");
        const validateDocument = Rules.numeroDocumentoColumn.pattern.value.test(tipoNumero[1]); // true
        return { tipo: tipoNumero[0], numero: !!validateNumber(tipoNumero[0], tipoNumero[1]) ? 0 : !validateDocument ? 0 : tipoNumero[1] };
    });

    const ciudadList1 = list.map(item => getFirstPositionSplit(item[ColumnsFile.iAMunicipio1Column.name], '-'));
    let ciudad1 = [...new Set(ciudadList1)].map(item => {
        return { 'valor': item }
    });

    const ciudadList2 = list.map(item => getFirstPositionSplit(item[ColumnsFile.iAMunicipio2Column.name], '-'));
    let ciudad2 = [...new Set(ciudadList2)].map(item => {
        return { 'valor': item }
    });

    ciudad1 = removeNullValuesFromList(ciudad1);
    ciudad2 = removeNullValuesFromList(ciudad2);

    //Se crea Json con las listas a validar
    const inputList = {
        "tipoDocumento": tipoDocumento, 'tipoPersona': tipoPersona, 'regimen': regimen,
        'ciudad': ciudad, 'cliente': cliente, 'ciudad1': ciudad1, 'ciudad2': ciudad2,
    };

    try {
        if (inputList.tipoDocumento.length === 0 && inputList.tipoPersona.length === 0 && inputList.regimen.length === 0 &&
            inputList.ciudad.length === 0 && inputList.cliente.length === 0 && inputList.ciudad1.length === 0 && inputList.ciudad2.length === 0) {
            showMessageError("El archivo debe tener por lo menos un registro.");
            handleOpenModalLoading(false, 0);
            return;
        }
        //Se consulta endpoint de validacion
        const data = await checkValidationsClientService(inputList);
        if (!data)
            return;


        addBackValidations(data.cliente, clienteList, ColumnsFile.nombreClienteColumn, results, list);
        addBackValidations(data.tipoDocumento, tipoDocumentoList, ColumnsFile.tipoDocumentoColumn, results, list);
        addBackValidations(data.tipoPersona, tipoPersonaList, ColumnsFile.tipoPersonaColumn, results, list);
        addBackValidations(data.regimen, regimenList, ColumnsFile.regimenColumn, results, list);
        addBackValidations(data.ciudad, ciudadList, ColumnsFile.municipioColumn, results, list);
        addBackValidations(data.ciudad1, ciudadList1, ColumnsFile.iAMunicipio1Column, results, list);
        addBackValidations(data.ciudad2, ciudadList2, ColumnsFile.iAMunicipio2Column, results, list);

        const nombreDireccionList1 = list.map(item =>
            item.cliente.id + "--" + item[ColumnsFile.iANombreDireccion1Column.name]
        );
        const nombreDireccionList1Tmp = list.filter(item => item[ColumnsFile.iANombreDireccion1Column.name] && item.cliente.id !== 0).map(item =>
            item.cliente.id + "--" + item[ColumnsFile.iANombreDireccion1Column.name]
        );
        let nombreDireccion1 = [...new Set(nombreDireccionList1Tmp)].map((nombreDireccion) => {
            const direccion = nombreDireccion.trim().split("--");
            return { cliente: direccion[0], valor: direccion[1] };
        });

        const nombreDireccionList2 = list.map(item =>
            item.cliente.id + "--" + item[ColumnsFile.iANombreDireccion2Column.name]
        );

        const nombreDireccionList2Tmp = list.filter(item => item[ColumnsFile.iANombreDireccion2Column.name] && item.cliente.id !== 0).map(item =>
            item.cliente.id + "--" + item[ColumnsFile.iANombreDireccion2Column.name]
        );
        let nombreDireccion2 = [...new Set(nombreDireccionList2Tmp)].map((nombreDireccion) => {
            const direccion = nombreDireccion.trim().split("--");
            return { cliente: direccion[0], valor: direccion[1] };
        });

        const nombreContactoList1 = list.map(item =>
            item.cliente.id + "--" + item[ColumnsFile.cANombreContacto1Column.name]
        );

        const nombreContactoList1Tmp = list.filter(item => item[ColumnsFile.cANombreContacto1Column.name] && item.cliente.id !== 0).map(item =>
            item.cliente.id + "--" + item[ColumnsFile.cANombreContacto1Column.name]
        );
        let nombreContacto1 = [...new Set(nombreContactoList1Tmp)].map((nombreContacto) => {
            const nombre = nombreContacto.trim().split("--");
            return { cliente: nombre[0], valor: nombre[1] };
        });

        const nombreContactoList2 = list.map(item =>
            item.cliente.id + "--" + item[ColumnsFile.cANombreContacto2Column.name]
        );

        const nombreContactoList2Tmp = list.filter(item => item[ColumnsFile.cANombreContacto2Column.name] && item.cliente.id !== 0).map(item =>
            item.cliente.id + "--" + item[ColumnsFile.cANombreContacto2Column.name]
        );
        let nombreContacto2 = [...new Set(nombreContactoList2Tmp)].map((nombreContacto) => {
            const nombre = nombreContacto.trim().split("--");
            return { cliente: nombre[0], valor: nombre[1] };
        });

        nombreDireccion1 = removeNullValuesFromList(nombreDireccion1);
        nombreDireccion2 = removeNullValuesFromList(nombreDireccion2);
        nombreContacto1 = removeNullValuesFromList(nombreContacto1);
        nombreContacto2 = removeNullValuesFromList(nombreContacto2);

        //Se crea Json con las listas a validar
        const inputListAditional = {
            'nombreDireccion1': nombreDireccion1, 'nombreDireccion2': nombreDireccion2,
            'nombreContacto1': nombreContacto1, 'nombreContacto2': nombreContacto2
        };

        //Se consulta endpoint de validacion
        const dataAditional = await checkAditionalValidationsClientService(inputListAditional);

        if (!dataAditional)
            return;

        addBackValidations(dataAditional.nombreDireccion1, nombreDireccionList1, ColumnsFile.iANombreDireccion1Column, results, list);
        addBackValidations(dataAditional.nombreDireccion2, nombreDireccionList2, ColumnsFile.iANombreDireccion2Column, results, list);
        addBackValidations(dataAditional.nombreContacto1, nombreContactoList1, ColumnsFile.cANombreContacto1Column, results, list);
        addBackValidations(dataAditional.nombreContacto2, nombreContactoList2, ColumnsFile.cANombreContacto2Column, results, list);

    } catch (error) {
        console.error(error)
    }

    //Se agrupan errores por cada linea del excel
    let errorsByLine = groupByLine(results);
    setCustomerErrors(results);


    var listWithoutErrors = [];
    list.forEach((item, index) => {
        const errors = errorsByLine[index + 1];
        if (errors) {
            item.errors = errors;
        } else {
            listWithoutErrors.push(item);
        }
    })

    var listToPersist = []

    listWithoutErrors.forEach(item => listToPersist.push(buildJsonToSave(item)));
    const ok = listWithoutErrors.length;
    const error = list.length - ok;

    
    if (error === 0) {
        const responseLoad = await saveBulkLoadClientService(listToPersist);

        if (!!responseLoad) {
            if (!!responseLoad.data.result && responseLoad.data.statusCode === '201') {
                handleOpenResultModal(true, listToPersist.length, null);
                handleOpenModalLoading(false, 0);
                return;
            }

            handleOpenResultModal(false, 0, null);
            handleOpenModalLoading(false, 0);
            showMessageError("Se ha presentado un error, no se han podido guardar todos los registros.");
            return;
        }

    } else {

        if (listToPersist.length > 0) {
            await saveBulkLoadClientService(listToPersist);
        }

    }

    let result ={
        recordsError: error,
        recordsOk: ok,
        multiDataSet: [
            {
                columns: [
                    { title: "Fila", width: { wpx: 40 } }, //pixels width
                    { title: "Columna", width: { wpx: 400 } }, //char width
                    { title: "Observaciones", width: { wpx: 600 } }
                ],
                data: data(results)
            }
        ]
    }

    handleOpenModalLoading(false, 0);
    handleOpenResultModal(true, "", result);


  
};