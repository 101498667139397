import React, {Component} from "react"
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles';
import {Grid} from "@material-ui/core"

import {cleanMenuComponentAction, getMenuComponentAction} from '../../actions/configAction'
import Colfactura from '../../../src/images/logo-Colfactura.png'

const styles = () => ({

  descriptionSpan: {
    backgroundColor: 'red',
    width: '835px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center'
  },
  descriptionName: {
    height: '26px',
    width: '372px',
    color: '#848181',
    fontFamily: 'Muli',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '26px'
  },
  divColor: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.11)',
    width: '935px'
  },
  spanVersion: {
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontSize: '20px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '30px',
    textAlign: 'center',
    margin: 'auto'
  },
  spanPowerBy: {
    height: '32px',
    width: '835px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  divPowerBy: {
    marginLeft: 50
  },
  textPolicy: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.11)',
  },
  divText: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',
    marign: 'auto'
  },
  divText2: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',
    marginLeft: 50,
    marginRight: 50
  },
  mainGrid: {
    margin: 'auto'
  }
})

export class About extends Component {

  constructor() {
    super();
    this.state = {
      menuHelp: {}
    }

  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);

  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    
        if (!!nextProps.menuReducer.menuHelp && nextProps.menuReducer.menuHelp !== state.menuHelp) {
          update.menuHelp = nextProps.menuReducer.menuHelp;
        }


    return Object.keys(update).length ? update : null;
  }

  render() {
    const classes = this.props.classes;
    return (
      <Grid container spacing={2}>
        <Grid item md={8} className={classes.mainGrid}>
          <div className={classes.textPolicy}>
            <br/>
            <div className={classes.divText}>
              <img src={Colfactura} alt='logo'/>
            </div>
            <br/>
            <div className={classes.divText2}>
              {this.state.menuHelp.labelAbout}
              <br/>
              <hr color={'#979797'} size={1}/>
              <br/>
              <div className={classes.spanVersion}>
                {this.state.menuHelp.versionAbout}
              </div>
            </div>
            <br/>
            <br/>
            <div className={classes.divPowerBy}>
              <span className={classes.spanPowerBy}>
                {this.state.menuHelp.byAbout}
              </span>
              <br/>
              <br/>
            </div>
          </div>
          <br/>
        </Grid>
      </Grid>
    )
  }
}

About.propTypes = {}

const mapStateToProps = state => ({
  configReducer: state.configReducer,
  menuReducer: state.menuReducer
})

export default connect(mapStateToProps,
  {
    getMenuComponentAction,
    cleanMenuComponentAction
  })(withStyles(styles)(About))

