import React from "react";

import { AcquirerInformation, SelectedDocumentData } from "./";

export const DocInformationData = () => {
  return (
    <>
      <SelectedDocumentData />
      <div className="lineDivisionSupport" />
      <AcquirerInformation />
    </>
  );
};
