import {
  ApplyGiftSection,
  ItemDiscountsComponent,
  ItemGiftComponent,
  ItemImage,
  ItemQuantitySection,
  ItemRetentionComponent,
  TaxItemComponent,
  TotalItemComponent,
  UnitValueSection,
} from "../itemCartSections";

/**
 * * Obtiene las secciones de los elementos del documento.
 * @param {Object} formValues - Valores del formulario.
 * @param {Function} handleEditRteFuente - Función para editar la retención.
 * @param {Function} handleInputChange - Función para manejar el cambio de entrada.
 * @param {Function} handleOpenModalRteFuente - Función para abrir el modal de la retención .
 * @param {Function} handleUpdateForm - Función para actualizar el formulario.
 * @param {Object} itemDoc - Documento del elemento.
 * @param {string} typedocument - Tipo de documento.
 * @param {Object} validator - Validador.
 * @returns {Object} - Las secciones de los elementos del documento.
 */
export const getDocumentItemSections = ({
  formValues,
  handleEditRteFuente,
  handleInputChange,
  handleOpenModalRteFuente,
  handleUpdateForm,
  itemDoc,
  typedocument,
  validator,
}) => ({
  sectionItemImage: {
    component: ItemImage,
    props: { ...itemDoc },
  },
  sectionApplyGift: {
    component: ApplyGiftSection,
    props: {
      handleUpdateForm,
      isGift: formValues.isGift,
      itemDoc,
    },
  },
  sectionItemDiscounts: {
    component: ItemDiscountsComponent,
    props: {
      ...formValues,
      itemDoc,
      handleUpdateForm,
      handleInputChange,
      validator,
    },
  },
  sectionItemRetention: {
    component: ItemRetentionComponent,
    props: {
      handleEditRteFuente,
      handleOpenModalRteFuente,
      itemDoc,
      retfte: itemDoc.retfte,
      typedocument,
      isGift: formValues.isGift,
    },
  },
  sectionItemGift: {
    component: ItemGiftComponent,
    props: {
      ...formValues,
      handleInputChange,
      itemDoc,
      validator,
    },
  },
  sectionUnitValue: {
    component: UnitValueSection,
    props: {
      ...formValues,
      itemDoc,
      handleUpdateForm,
      validator,
    },
  },
  sectionItemQuantity: {
    component: ItemQuantitySection,
    props: { ...formValues, handleUpdateForm, itemDoc },
  },
  sectionTaxItem: {
    component: TaxItemComponent,
    props: { ...itemDoc },
  },
  sectionTotalItem: {
    component: TotalItemComponent,
    props: { ...itemDoc },
  },
});
