import React, { Component } from "react"
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { Grid } from "@material-ui/core"

import PolicyCard from '../../components/card/policyCard.component'
import { getMenuComponentAction, cleanMenuComponentAction } from '../../actions/configAction'

import DataProtection from "../../../src/images/dataProtection.png";
import TermsAndConditions from "../../../src/images/termsAndConditions.png";

const styles = theme => ({
  descriptionSpan: {
    paddingBottom: 19,
    paddingRight: 37,
    paddingLeft: 37,
    color: '#7DD1F8',
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: '23px',
    textAlign: 'center',
    margin: 'auto'
  },
  divText: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'justify',
    marginLeft: 50,
    marginRight: 50
  },
  mainGrid: {
    margin: 'auto'
  }
})

export class PrivacyPolicy extends Component {

  constructor() {
    super();
    this.state = {
      policy: '',
      menuHelp: {}
    }
  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);

  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (!!nextProps.configReducer.policy && nextProps.configReducer.policy !== state.policy)
        update.policy = nextProps.configReducer.policy;

    if (!!nextProps.menuReducer.menuHelp && nextProps.menuReducer.menuHelp !== state.menuHelp) {
        update.menuHelp = nextProps.menuReducer.menuHelp;
      }

    return Object.keys(update).length ? update : null;
  }

  render() {
    const classes = this.props.classes;
    return (
      <Grid container spacing={2}>
        <Grid item md={7} xs={12} className={classes.mainGrid}>
          <br />
          <br />
          <div className={classes.descriptionSpan}>
            {this.state.menuHelp.privacyPolicy}
          </div>
          <hr color={'#979797'} size={1} />
          <br />
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={6}>
              <PolicyCard
                imageCard={DataProtection}
                title={this.state.menuHelp.protectedData}
                textButton={this.state.menuHelp.buttonProtectedData}
                hrefButton={this.state.menuHelp.urlProtectedData}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6}>
              <PolicyCard
                imageCard={TermsAndConditions}
                title={this.state.menuHelp.termData}
                textButton={this.state.menuHelp.buttonTermData}
                hrefButton={this.state.menuHelp.urlTermData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

PrivacyPolicy.propTypes = {}

const mapStateToProps = state => ({
  configReducer: state.configReducer,
  menuReducer: state.menuReducer
})

export default connect(mapStateToProps,
  {
    getMenuComponentAction,
    cleanMenuComponentAction
  })(withStyles(styles)(PrivacyPolicy))

