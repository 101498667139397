import React from "react";

import { useSelector } from "react-redux";

import { Grid, Typography, makeStyles } from "@material-ui/core";

import ButtonPrimary from "../../../../components/button/buttonPrimary.component";
import InputForm from "../../../../components/input/input.component";

import { preventSpaceIfEmpty } from "../../../../helpers/preventSpaceIfEmpty";

const useStyles = makeStyles((theme) => ({
  formInfoBox: {
    color: "#FFF",
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "Muli",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    margin: theme.spacing(1),
  },
  containerError: {
    color: "#f44336",
    fontSize: 14,
    padding: "16px !important",
  },
}));
export const BoxInformationForm = ({
  boxLocation,
  boxPlate,
  boxType,
  cashier,
  handleInputChange,
  saleCode,
  subtotal,
  validator,
}) => {
  const classes = useStyles();

  const { formErrors } = useSelector((state) => state.equivalentDocReducer);

  const boxInformation = [
    {
      label: "Placa caja",
      maxLength: 100,
      name: "boxPlate",
      validateOptions: "required",
      value: boxPlate,
      onChange: (e) => handleInputChange(e, "alphaNumeric"),
      onKeyPress: preventSpaceIfEmpty,
    },
    {
      label: "Ubicación caja",
      maxLength: 100,
      name: "boxLocation",
      validateOptions: "required",
      value: boxLocation,
      onChange: (e) => handleInputChange(e, "alphaNumeric"),
      onKeyPress: preventSpaceIfEmpty,
    },
    {
      label: "Cajero",
      maxLength: 100,
      name: "cashier",
      validateOptions: "required",
      value: cashier,
      onChange: (e) => handleInputChange(e, "alphaNumeric"),
      onKeyPress: preventSpaceIfEmpty,
    },
    {
      label: "Tipo caja",
      maxLength: 100,
      name: "boxType",
      validateOptions: "required",
      value: boxType,
      onChange: (e) => handleInputChange(e, "alphaNumeric"),
      onKeyPress: preventSpaceIfEmpty,
    },
    {
      label: "Código venta",
      maxLength: 100,
      name: "saleCode",
      validateOptions: "required",
      value: saleCode,
      onChange: (e) => handleInputChange(e, "alphaNumeric"),
      onKeyPress: preventSpaceIfEmpty,
    },
    {
      format: "money",
      label: "Subtotal",
      maxLength: 100,
      name: "subtotal",
      validateOptions: "required|numeric",
      value: subtotal,
      onChange: (e) => handleInputChange(e, "number"),
    },
  ];

  return (
    <Grid container spacing={2} justify="flex-end">
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.formInfoBox}>
          Información de caja
        </Typography>
      </Grid>

      {boxInformation.map((input) => (
        <Grid item lg={6} xs={12} key={input.label}>
          <InputForm {...input} validator={validator} />
        </Grid>
      ))}
      <Grid item lg={6} xs={12} className={classes.containerError}>
        {formErrors.map((err, index) => (
          <div key={index}>
            <span>{`${err}.`}</span>
            <br />
          </div>
        ))}
      </Grid>
      <Grid item lg={6} xs={12}>
        <ButtonPrimary type={"submit"} text={"Continuar"} form={"head-form"} />
      </Grid>
    </Grid>
  );
};
