/* eslint-disable no-useless-constructor */
import React, { Fragment } from "react";
import { PieChart } from "react-minimal-pie-chart";


import Brightness1Icon from '@material-ui/icons/Brightness1';
import { withStyles } from "@material-ui/core/styles";

import { Card, CardContent, Grid, FormControlLabel, } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import CustomProgress from '../../../components/Progress/progress.component';


import "../Components/dashboard.css";
import { FormatDecimal } from "../../../components/common/formatDecimal";

const useStyles = (theme) => ({

  colorCard: {
    background: theme.palette.ninethBackgroundColor,
    height: 320
  },
  optionName: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
  },
  optionNewData: {
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
  },
  optionTotal: {
    height: 20,
    width: 216,
    color: theme.palette.primaryColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center'
  },
  colorPaid: {
    color: '#16B1F3',
    fontSize: 10
  },
  colorSlopes: {
    color: '#DDDD1C',
    fontSize: 10
  },
  colorOverdue: {
    color: '#F1474A',
    fontSize: 10
  },
  chart: {
    width: '100%',
    height: 250,

    fill: "#FFFFFF",
    fontSize: "5px",
    fontFamily: "Muli"
  },
  gridPosition: {
    paddingTop: 10
  },
  icon: {
    fill: theme.palette.primaryColor
  },
  optionSelect: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500
  },
  SelectStyle: {
    width: 180,
    marginLeft: 70
  },
  divMessage: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  }
});

const CustomSelect = withStyles({
  root: {
    '&.MuiSelect-select': {
      width: 150,
      color: '#FFFFFF',
      fontFamily: 'Muli',
      fontWeight: 500,
      fontSize: 14
    }
  },

})(Select);

const CustomForm = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#509b34',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#50C1EE',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#50C1EE',
    },
    '& .MuiInputBase-input': {
      color: '#FFFF'
    }
  },
})(FormControl);


//class BillingChart extends Component {

const BillingChart = (props) => {

  const { classes, data, totalSold, statusCode } = props;

  const getReturnValue = (data) => {
    let result1
    let result2
    let result3
    let response
    for (let i = 0; i < data.length; i++) {
      result1 = data[0].value
      result2 = data[1].value
      result3 = data[2].value
    }
    if (result1 === 0 && result2 === 0 && result3 === 0) {
      response = true
    }
    return response
  }
  /**
   * Función para obtener la informacion de cada arreglo para obtener el valor total de ventas y cantidad de facturas para cada estado.
   * @param {} data 
   * @param {*} value 
   * @returns 
   */
  const getReturnValueCount = (data, value) => {

    var result = {}
    data.forEach(element => {
      if (element.id === value && element.value >= 0) {
        result.total = element.total
        result.value = element.value
      }
      if (element.id === value && element.value >= 0) {
        result.total = element.total
        result.value = element.value
      }
      if (element.id === value && element.value >= 0) {
        result.total = element.total
        result.value = element.value
      }

    });

    return result


  }
  /**
   * Función para calcular el procentaje de un valor ingresado.
   * @param {*} valor 
   * @param {*} valorTotal 
   * @param {*} id 
   * @returns 
   */
  const calculatePocentaje = (valor, valorTotal) => {
    var result = (valor * 100) / valorTotal
    return Math.round(result)
  }

  /**
   * Funci´pn para calcular el prorrateo de porcentajes de los 3 estados de las facturas.
   * @param {*} data 
   * @param {*} totalSold 
   * @returns 
   */
  const calculateDataProrrateo = (data, totalSold) => {

    var valuePag
    var valuePen
    var newData = []
    var newResut = []

    newResut = data.filter((element) => {
      return element.total > 0
    })



    if (newResut.length === 1) {
      newResut.forEach((element, i) => {
        var jsonData = {}
        if (i === 0) {

          jsonData.newPorcentage = 100
          jsonData.color = element.color
          jsonData.tittle = element.title
          jsonData.value = 100
          newData.push(jsonData)
        }
      })

      return newData
    }

    if (newResut.length === 2) {
      var sumTotal = newResut.reduce((a, b) => {
        return a.total + b.total
      })


      newResut.forEach((element, i) => {
        var jsonData = {}
        if (i === 0) {
          valuePag = calculatePocentaje(element.total, sumTotal)
          jsonData.newPorcentage = valuePag
          jsonData.color = element.color
          jsonData.tittle = element.title
          jsonData.value = valuePag
          newData.push(jsonData)
        }


        if (i === 1) {
          jsonData.newPorcentage = 100 - valuePag
          jsonData.color = element.color
          jsonData.tittle = element.title
          jsonData.value = 100 - valuePag
          newData.push(jsonData)
        }
      });

      return newData
    }


    if (newResut.length === 3) {
      newResut.forEach((element, i) => {
        var jsonData = {}
        if (i === 0) {
          valuePag = calculatePocentaje(element.total, totalSold)
          jsonData.newPorcentage = valuePag
          jsonData.color = element.color
          jsonData.tittle = element.title
          jsonData.value = valuePag
          newData.push(jsonData)
        }


        if (i === 1) {
          valuePen = calculatePocentaje(element.total, totalSold)
          jsonData.newPorcentage = valuePen
          jsonData.color = element.color
          jsonData.tittle = element.title
          jsonData.value = valuePen
          newData.push(jsonData)
        }

        if (i === 2) {
          jsonData.newPorcentage = 100 - valuePag - valuePen
          jsonData.color = element.color
          jsonData.tittle = element.title
          jsonData.value = 100 - valuePag - valuePen
          newData.push(jsonData)
        }
      });

      return newData

    }

    return data

  }
  return (



    <Card className={classes.colorCard}>
      <FormControlLabel
        control={<CardHeader title={'Facturación'} className={'titleCard'} />}
        label={<CardHeader title={
          <CustomForm>
            <CustomSelect
              defaultValue={1}
              disabled
              inputProps={{ classes: { icon: classes.icon } }}
              className={classes.SelectStyle}>

              <MenuItem className={classes.optionSelect} value={1}>Balance Mensual</MenuItem>
              <MenuItem className={classes.optionSelect} value={2}>Balance Trimestral</MenuItem>
              <MenuItem className={classes.optionSelect} value={3}>Balance Anual</MenuItem>

            </CustomSelect>
          </CustomForm>
        } />}
      />
      { data.length === 0 && statusCode === undefined ? <CustomProgress /> :
        (data.length === 0 && statusCode === 0) || (data.length === 0 && statusCode === 200) || (getReturnValue(data) === true) ?
          <div className={classes.divMessage}>No tiene facturas registradas</div> : ''}

      {/**
   *  <div>
      <MobileStepper
       
        position="static"
        variant="text"
       
        nextButton={
          <Button size="small" >
           <KeyboardArrowRight/>
          </Button>
        }
        backButton={
          <Button size="small" >
           <KeyboardArrowLeft/>
          </Button>
        }
      />
    </div>
   */}

      <Grid container>
        <Grid item lg={6} md={3} xs={5} direction="column" >
          {data.length === 0 && statusCode === undefined ? '' :
            (data.length === 0 && statusCode === 0) || (data.length === 0 && statusCode === 200) || (getReturnValue(data) === true) ? '' :

              <PieChart
                data={calculateDataProrrateo(data, totalSold)}
                lineWidth={18}
                rounded
                label={({ dataEntry }) => (!!dataEntry.newPorcentage ? dataEntry.newPorcentage : 0) + '%'}
                radius={38}
                labelPosition={103}
                className={classes.chart} />}
        </Grid>

        {data.length === 0 && !!statusCode ? '' :
          (data.length === 0 && statusCode === 0) || (data.length === 0 && statusCode === 200) || (getReturnValue(data) === true) ? '' : data.length > 0 ?
            <Grid item lg={6} md={7} xs={7} direction="column" className={classes.gridPosition} >
              <div style={{ marginTop: '-20px' }}>
                <FormControlLabel
                  control={<Brightness1Icon className={classes.colorPaid} />}
                  label={
                    <Fragment>&nbsp;&nbsp;<span className={classes.optionName}>Pagadas</span>
                      <br />
                      &nbsp;&nbsp;
                        <span className={classes.optionNewData}>
                        {getReturnValueCount(data, 1).value}
                        <FormatDecimal
                          value={getReturnValueCount(data, 1).total}
                          prefix={" Fact / $"}
                        />
                     
                      </span>
                    </Fragment>
                  }
                />
                <br />
                <FormControlLabel
                  control={<Brightness1Icon className={classes.colorSlopes} />}
                  label={
                    <Fragment>
                      &nbsp;&nbsp;
                    <span className={classes.optionName}>Por Pagar</span>
                      <br />
                            &nbsp;&nbsp;
                            <span className={classes.optionNewData}>
                        {getReturnValueCount(data, 2).value}
                        <FormatDecimal
                          value={getReturnValueCount(data, 2).total}
                          prefix={" Fact / $"}
                        />
                              
                      </span>
                    </Fragment>
                  } />
                <br />
                <FormControlLabel
                  control={<Brightness1Icon className={classes.colorOverdue} />}
                  label={
                    <Fragment>
                      &nbsp;&nbsp;
                    <span className={classes.optionName}>En Mora</span>
                      <br />
                            &nbsp;&nbsp;
                            <span className={classes.optionNewData}>
                        {getReturnValueCount(data, 3).value}
                        <FormatDecimal
                          value={getReturnValueCount(data, 3).total}
                          prefix={" Fact / $"}
                        />
                      </span>
                    </Fragment>
                  }
                />
                <br />
                <br />
                <span className={classes.optionTotal}>Total Facturación:</span>
                <br />
                <span className={classes.optionName}>
                  <FormatDecimal
                    value={totalSold}
                    prefix={" $"}
                  />
                </span>

              </div>


            </Grid>
            : ''

        }

      </Grid>
      <CardContent>
      </CardContent>
    </Card>
  );

}

export default withStyles(useStyles)(BillingChart);
