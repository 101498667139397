import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

import InputComponent from "../../components/input/input.component";
import SelectComponent from "../../components/select/select.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";

import {
  getMenuComponentAction,
  cleanMenuComponentAction,
  sendMailReport,
} from "../../actions/configAction";

import { SuccessAlert } from "../../helpers/alert.helpers";
import { TIME_UPDATE_REGISTER } from "../../config/config";

const styles = (theme) => ({
  descriptionSpan: {
    height: "81px",
    width: "480px",
    color: "#7DD1F8",
    fontFamily: "Muli",
    fontSize: "18px",
    fontStyle: "italic",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "23px",
    textAlign: "center",
    margin: "auto",
  },
  descriptionName: {
    height: "26px",
    width: "372px",
    color: "#848181",
    fontFamily: "Muli",
    fontSize: "18px",
    letterSpacing: 0,
    lineHeight: "26px",
  },
});

export class ReportProblem extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        ddlProblem: "",
        txtDescription: "",
        mailSupport: "",
        issue: "",
      },
      issue: [],
      data: [],
      fullName: "",
      emailUser: "",
      idUser: "",
      nameCompany: "",
      nitcompany: "",
      loadingSend: false,
      responseSendReportProblem: {},
      menuHelp: {},
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (
      !!nextProps.configReducer.dataIssue &&
      nextProps.configReducer.dataIssue !== state.data
    ) {
      update.issue = nextProps.configReducer.issue;
      update.data = nextProps.configReducer.dataIssue;
      update.fullName = nextProps.configReducer.fullName;
      update.emailUser = nextProps.configReducer.emailUserProblem;
      update.idUser = nextProps.configReducer.idUser;
      update.nameCompany = nextProps.configReducer.businessName;
      update.nitcompany = nextProps.configReducer.nitCompany;
    }

    if (
      !!nextProps.configReducer.responseSendReportProblem &&
      nextProps.configReducer.responseSendReportProblem !==
        state.responseSendReportProblem
    ) {
      update.responseSendReportProblem =
        nextProps.configReducer.responseSendReportProblem;
    }

    if (
      !!nextProps.menuReducer.menuHelp &&
      nextProps.menuReducer.menuHelp !== state.menuHelp
    ) {
      update.menuHelp = nextProps.menuReducer.menuHelp;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.responseSendReportProblem !==
      this.state.responseSendReportProblem
    ) {
      if (this.state.responseSendReportProblem.statusCode === "200") {
        SuccessAlert(
          null,
          this.state.menuHelp.messageReportProblem,
          TIME_UPDATE_REGISTER
        );
        this.cleanFormProblem();
        this.setState({
          loadingSend: false,
        });
      }
    }
  }

  syncChanges(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    let emailSelect = "";
    let issue = "";

    this.state.issue.forEach((item) => {
      if (item.id === value) {
        emailSelect = item.correo;
        issue = item.descripcion;
      }
    });

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
        mailSupport: emailSelect,
        issue: issue,
      },
    }));
  }

  syncChange(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  onSendReport = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let mailStructure = {};
      var textHtml = this.state.form.txtDescription.replace(
        /\n/g,
        " ",
        /"/g,
        '"'
      );
      mailStructure.mailSupport = this.state.form.mailSupport;
      mailStructure.mailUser = this.state.emailUser;
      mailStructure.issue = this.state.form.issue;
      mailStructure.bodyMail = textHtml;
      mailStructure.idUser =
        this.state.idUser === "CFREPLEG"
          ? "Representante Legal"
          : this.state.idUser === "ROL_CFCOLAB"
          ? "Colaborador"
          : "Administrador";
      mailStructure.nameCompany = this.state.nameCompany;
      mailStructure.nitCompany = this.state.nitcompany;
      mailStructure.userEmail = this.state.emailUser;
      mailStructure.userName = this.state.fullName;
      mailStructure.IsReport = true;
      this.setState({
        loadingSend: true,
      });
      this.props.sendMailReport(mailStructure, true);
      this.cleanFormProblem();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  cleanFormProblem = () => {
    this.setState({
      form: {
        ddlProblem: "",
        txtDescription: "",
        mailSupport: "",
        issue: "",
      },
    });
    this.validator.hideMessages();
  };

  render() {
    const classes = this.props.classes;
    return (
      <form
        id="reportProblem"
        onSubmit={(e) => this.onSendReport(e)}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item md={8} style={{ margin: "auto" }}>
            <br />
            <br />
            <div className={classes.descriptionSpan}>
              {this.state.menuHelp.reportProblem}
            </div>
            <hr color={"#979797"} size={1} />
            <br />
            <span className={classes.descriptionName}>Nombre : </span>
            <span className={classes.descriptionName}>
              {this.state.fullName}
            </span>
            <br />
            <span className={classes.descriptionName}>
              Correo electrónico :{" "}
            </span>
            <span className={classes.descriptionName}>
              {this.state.emailUser}
            </span>
            <br />
            <br />

            <SelectComponent
              name="ddlProblem"
              label={"Asunto *"}
              options={this.state.data}
              value={this.state.form.ddlProblem}
              onChange={(e) => {
                this.syncChanges(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
            <InputComponent
              rowsline={true}
              id="txtDescription"
              label="Observaciones *"
              maxLength={5000}
              multiline={true}
              value={this.state.form.txtDescription}
              onChange={(e) => {
                this.syncChange(e);
              }}
              validator={this.validator}
              validateOptions={"required"}
            />
            <Grid container spacing={5} justify="center" alignItems="center">
              <Grid item lg={6} md={6} sm={12} xs={12} />
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ButtonPrimary
                  loading={this.state.loadingSend}
                  text={this.state.menuHelp.buttonReportProblem}
                  //disabled={this.state.checkedArticles.length > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

ReportProblem.propTypes = {};

const mapStateToProps = (state) => ({
  configReducer: state.configReducer,
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {
  getMenuComponentAction,
  cleanMenuComponentAction,
  sendMailReport,
})(withStyles(styles)(ReportProblem));
