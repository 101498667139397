import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Grid, Tooltip } from "@material-ui/core";

import CustomProgress from "../../../components/Progress/progress.component";
import NumberFormat from "react-number-format";
// import { validateNumberFormat } from "../../../utils/managementNumber";

const useStyles = makeStyles((theme) => ({
  root: {},
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    color: theme.palette.primaryColor,
  },
}));

const CustomInput = withStyles((theme) => ({
  root: {
    "& legend.PrivateNotchedOutline-legendNotched-215": {
      maxWidth: "32vh",
    },
    "& label.MuiInputLabel-outlined": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      right: 22,
    },
    "& label.Mui-focused": {
      color: theme.palette.thirdColor, //azul
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      right: 22,
    },
    ".label-shrinked": {
      right: "unset", //show complete label if shrinked
    },
    "& label.MuiFormLabel-filled:hover": {
      color: theme.palette.thirdColor, //azul
    },
    "& label.Mui-disabled": {
      color: "gray", //gris
    },
    "& input.Mui-disabled": {
      color: "rgba(255,255,255,0.5);",
    },
    "& label.MuiFormLabel-filled:not(.Mui-focused):not(.Mui-error)": {
      color: "gray", //gris
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#16B1F3", //verde
    },
    "& .MuiInputBase-input": {
      color: "#FFFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "gray", //gris
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "gray", //gris
    },
    "& .MuiInput-underline:focus": {
      borderBottomColor: "gray", //gris
    },
    "& .MuiOutlinedInput-root": {
      "&.MuiInputLabel-outlined": {
        color: "white",
      },
      "& fieldset": {
        border: "2px solid",
        borderColor: " rgba(255,255,255,0.3)", //borde de la caja
      },
      "&:hover fieldset": {
        borderColor: "gray",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.thirdColor, //azul
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "gray", //gris
      },
    },
  },
}))(TextField);

// function NumberFormatCustom({ inputRef, onChange, name, ...other }) {
// 	const handleChange = (values) => {
// 		const validNumber = validateNumberFormat(values.value ?? "");
// 		onChange({
// 			target: {
// 				name: name,
// 				value: validNumber,
// 			},
// 		});
// 	};

// 	return (
// 		<NumberFormat
// 			{...other}
// 			getInputRef={inputRef}
// 			onValueChange={handleChange}
// 			isNumericString={false}
// 			thousandSeparator=","
// 			decimalSeparator="."
// 			prefix="$"
// 			allowNegative={false}
// 			allowedDecimalSeparators={["."]}
// 		/>
// 	);
// }

export function NumberFormatMoney(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 900000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      isNumericString
      prefix="$"
      allowNegative={false}
      fixedDecimalScale={false}
      decimalScale={4}
      allowedDecimalSeparators={["."]}
    />
  );
}

export const CustomFieldInput = React.memo(
  ({
    onChange,
    value,
    label,
    disabled,
    name,
    handleDelete,
    loading,
    format = "input",
  }) => {
    const classes = useStyles();

    let myFormat = "input";
    switch (format) {
      case "money":
        myFormat = NumberFormatMoney;
        break;
      default:
        myFormat = "input";
        break;
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item lg={10} xs={10}>
            <CustomInput
              fullWidth
              disabled={disabled}
              label={label}
              name={name}
              value={value}
              onChange={onChange}
              autoComplete="off"
              variant={"outlined"}
              inputProps={{ maxLength: 500 }}
              InputProps={{
                inputComponent: myFormat,
              }}
            />
          </Grid>
          <Grid item lg={2} xs={2}>
            <Tooltip title="Eliminar">
              <IconButton onClick={handleDelete} disabled={disabled}>
                {loading === true ? (
                  <CustomProgress size={20} />
                ) : (
                  <DeleteIcon className={classes.icon} />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    );
  }
);
