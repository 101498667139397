import { types } from "../types/types";

const initialState = {
  loadingSave: false,
  loadingGetChargesList: true,
  activeDocumentCharge: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_CHARGE:
      return {
        ...state,
        getCharge: action.payload,
      };

    case types.GET_CHARGELIST:
      return {
        ...state,
        getChargesEnterprise: action.payload,
      };

    case types.SAVE_CHARGE:
      return {
        ...state,
        createCharge: action.payload,
      };

    case types.UPDATE_CHARGE:
      return {
        ...state,
        updateCharge: action.payload,
      };

    case types.DELETE_CHARGE:
      return {
        ...state,
        deleteCharge: action.payload,
      };

    case types.GET_FILTER_CHARGES:
      return {
        ...state,
        getFilterCharges: action.payload,
      };

    case types.GET_FILTER_CHARGE:
      return {
        ...state,
        currentCharge: action.payload,
        editingChargeData: !!action.payload ? true : false,
      };

    case types.CHANGE_EDITING_CHARGE_STATUS:
      return {
        ...state,
        editingChargeData: action.payload,
      };

    case types.LOADING_SAVE_CHARGE:
      return {
        ...state,
        loadingSave: action.payload,
      };

    case types.LOADING_GET_CHARGES:
      return {
        ...state,
        loadingGetChargesList: action.payload,
      };

    default:
      return state;
  }
}
