import React from "react";

import { Grid } from "@material-ui/core";

import { DetailEquivalentDoc, ListEquivalentDoc } from "./";

export const EquivalentDocGrid = ({ edit = false }) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={5}>
        <ListEquivalentDoc edit={edit} />
      </Grid>
      <Grid item xs={12} lg={7}>
        <DetailEquivalentDoc />
      </Grid>
    </Grid>
  );
};
