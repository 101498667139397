import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CountReport } from './countReport.component';
import { ExcelAccountant } from './excelAccountant';
import { ExcelIntegration } from './excelIntegration';
import { getReportAction } from "../../actions/invoiceActions";
import { getItegracionReportAction } from '../../actions/documentIntegrationAction';
import { ExcelDocumentSupport } from './excelDocumentSupport';
import { getDocSupportReportAction } from '../../actions/documentSupportAction';

const Informe = () => {
    return (
        <div className="container-form">
            <Grid container spacing={2} direction="column">
                <Grid item lg={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={5} md={12} xs={12} direction="column">
                            <CountReport
                                title={'Informe para Contador'}
                                subtitle={'En este informe tendrá los datos específicos sobre las facturas de ventas, notas crédito y débito que se generaron en su empresa.'}
                                ExcelComponent={ExcelAccountant}
                                action={getReportAction}
                                reportTitle={'informe'}
                            />
                        </Grid>
                        <Grid item lg={5} md={12} xs={12} direction="column">
                            <CountReport
                                title={'Informe para documentos soporte'}
                                subtitle={'En este informe tendrá los datos específicos sobre los documentos soportes y notas de ajuste que se generaron en su empresa.'}
                                ExcelComponent={ExcelDocumentSupport}
                                action={getDocSupportReportAction}
                                reportTitle={'informeDocumentoSoporte'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={5} md={12} xs={12} direction="column">
                    <CountReport
                        title={'Informe para la integración'}
                        subtitle={'En este informe tendrá los datos específicos sobre las facturas de ventas, notas crédito y débito que se generaron en su empresa desde un Software diferente a Colfactura y cargadas por integración.'}
                        ExcelComponent={ExcelIntegration}
                        action={getItegracionReportAction}
                        reportTitle={'informeIntegracion'}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

export default Informe;