import React from "react";

import { IconButton, Tooltip } from "@material-ui/core";

import CustomProgress from "../../../components/Progress/progress.component";
import MessageSecundary from "../../../components/tooltip/messageSecundary.component";
import TooltipMessageSecundary from "../../../components/tooltip/tooltipSecundary.component";

import ReportIcon from "@material-ui/icons/Report";

/**
 * * Obtiene el estado DIAN de un documento equivalente.
 *
 * @param {Object} classes - Clases de estilos para los componentes.
 * @param {Object} item - Objeto que representa el documento equivalente.
 * @param {Array} loading - Array de IDs de documentos en proceso de carga.
 * @param {Object} messageTooltip - Objeto con mensajes para el tooltip.
 * @param {Function} reportProblem - Función para reportar un problema.
 * @returns {JSX.Element|null} - Elemento JSX que muestra el estado DIAN del documento o null si no aplica.
 */
export const getStatusDIAN = (
  classes,
  item,
  loading,
  messageTooltip,
  reportProblem
) => {
  const {
    dianTipoModalidadId,
    estadoDocumentoDescripcion,
    id,
    reporteRechazo,
    tipoEstadoDocumentoId,
  } = item;

  if (
    dianTipoModalidadId !== 7 ||
    (tipoEstadoDocumentoId !== 2 && tipoEstadoDocumentoId !== 3)
  ) {
    return null;
  }

  const isError = tipoEstadoDocumentoId === 3;

  const tooltipContent = isError ? (
    <TooltipMessageSecundary
      buttonText={"Reportar a soporte"}
      disabledButton={reporteRechazo === true}
      message={
        reporteRechazo !== true
          ? messageTooltip?.messageDocumentNotReported ?? ""
          : messageTooltip?.messageReportedDocument ?? ""
      }
      onClick={(e) => reportProblem(e, item)}
      title={`Error DIAN - ${estadoDocumentoDescripcion}`}
    />
  ) : (
    <MessageSecundary
      message={"Este Ítem esta pendiente de sincronización con DIAN."}
      title={"Pendiente de sincronización con DIAN"}
    />
  );

  return (
    <Tooltip
      className={classes.tooltip}
      disableHoverListener={loading.includes(id)}
      disableTouchListener={loading.includes(id)}
      enterTouchDelay={1000}
      interactive
      placement="bottom-end"
      title={tooltipContent}
    >
      {loading.includes(id) ? (
        <CustomProgress size={20} aling={"end"} />
      ) : (
        <IconButton className={classes.iconButton}>
          {isError ? (
            <ReportIcon />
          ) : (
            <img
              src={require("./../../../images/icons/cloud.png")}
              alt={"cloud"}
            />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};
