import React, { useEffect, useState } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { Button, Grid, makeStyles } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { addRteIcaAllItemsAction } from "../../../../actions/documentCartAction";
import { getListReteICAAction } from "../../../../actions/invoiceActions";

import { ReteIcaCard } from "../../withholdings/reteIcaCard";
import { ReteicaTable } from "../../withholdings/reteIcaTable";
import { WithholdingModal } from "../../withholdings/withholdingModal";

import { ConfirmAlert } from "../../../../helpers/alert.helpers";

import { MESSAGE_DELETE_CONFIRM } from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  buttonReteica: {
    backgroundColor: "transparent",
    border: "1px solid gray",
    borderRadius: 5,
    color: theme.palette.primaryColor,
    fontSize: 14,
    padding: "10px 15px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    width: "100%",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  iconReteFuente: {
    fontSize: 35,
  },
}));

export const RetentionICASection = () => {
  const classes = useStyles();

  const [openModalReteica, setOpenModalReteica] = useState(false);

  const dispatch = useDispatch();

  const { retica, valorretica } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getListReteICAAction());
  }, [dispatch]);

  /**
   * * Elimina la retención de ICA del listado de elementos.
   * @returns {void}
   */
  const handleDeleteReteica = () => {
    const deleteData = (confirmed) => {
      if (confirmed) {
        dispatch(addRteIcaAllItemsAction(null));
      }
    };
    ConfirmAlert(
      `¿Estás seguro de eliminar rete-ICA?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * * Cierra el modal del listado de retención de ICA.
   * @returns {void}
   */
  const handleCloseModalReteica = () => {
    setOpenModalReteica(false);
  };

  /**
   * * Agrega una retención de ICA al listado de elementos.
   * @param {*} tax - La retención de ICA que se va a agregar al listado de elementos.
   * @returns {void}
   */
  const handleAddReteica = (tax) => {
    setOpenModalReteica(false);
    dispatch(addRteIcaAllItemsAction(tax));
  };

  /**
   * * Abre el modal del listado de retención de ICA.
   * @returns {void}
   */
  const handleOpenModalReteica = () => {
    setOpenModalReteica(true);
  };

  /**
   * * Abre el modal del listado de retención de ICA en modo edición.
   * @function
   * @name handleEditReteica
   * @returns {void}
   */
  const handleEditReteica = () => {
    setOpenModalReteica(true);
  };

  return (
    <>
      <Grid item lg={12} xs={12}>
        {!!retica ? (
          <ReteIcaCard
            onDelete={handleDeleteReteica}
            onEdit={handleEditReteica}
            retica={retica}
            valorretica={valorretica}
          />
        ) : (
          <Button
            className={classes.buttonReteica}
            component="div"
            onClick={handleOpenModalReteica}
            variant="contained"
          >
            Rete - ICA
            <KeyboardArrowDownIcon className={classes.iconReteFuente} />
          </Button>
        )}
      </Grid>
      <WithholdingModal
        component={
          <ReteicaTable
            onClickApply={handleAddReteica}
            activeReteica={retica}
          />
        }
        onClose={handleCloseModalReteica}
        open={openModalReteica}
        showCloseButton={true}
        title={"Selecciona Rete - ICA"}
        width={1000}
      />
    </>
  );
};
