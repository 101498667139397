export const orderByName = (list) => {
    let newList = list.sort((x, y) => {
        let a = x.razonsocial.toUpperCase();
        let b = y.razonsocial.toUpperCase();
        return a === b ? 0 : a > b ? 1 : -1;
    })
    return newList;
}

export const orderByDate = (list)=>{
    let newList =   list.sort((a, b) => {
        return new Date(b.fechaReciente) - new Date(a.fechaReciente);
    });

    return newList;
}