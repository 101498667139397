import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import FlareComponent from "flare-react";

import NumerationCard from "../../../components/card/numerationCard.component";
import FormNumeration from "./formNumeration.view";
import CollapsePanel from "../../../components/collapse/collapse.component";
import InputIcon from "../../../components/input/inputIcon.component";
import ResponseModal from "../../../components/modal/responseModal.component";
import NotificationNotCreated from "../../../components/notification/notificationNotCreated.component";
import CustomProgress from "../../../components/Progress/progress.component";
import ReportIcon from "@material-ui/icons/Report";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";

import { READING_MODE } from "../../../config/config";

import {
  getNumeration,
  changeStepStatusAction,
  createNumerationAction,
  getSynchronizationDian,
  loadNumerationAction,
  getNumerationsFilterAction,
  refreshAfterChangeStatusAction,
} from "../../../actions/numerationActions";
import { getCompanyAction } from "../../../actions/basicDataActions";

import { SuccessAlert, WarningAlert } from "../../../helpers/alert.helpers";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import SimpleReactValidator from "simple-react-validator";

import SyncAnimation from "../../../images/animations/buscando.flr";
import { getComponentName } from "../../../utils/general";
import isEmpty from "../../../utils/isEmpty";
import { TIME_UPDATE_REGISTER } from "../../../config/config";
import { NotificationReadingMode } from "../../../components/notification/NotificationReadingMode";

const styles = (theme) => ({
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },

  syncDian: {
    backgroundColor: "#a2e3a4",
    borderRadius: "5px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 10,
  },
  syncPanel: {
    justifyContent: "center",
    display: "flex",
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
  iconMessage: {
    color: "#F5D13D",
    fontSize: 60,
  },
  textMessage: {
    color: "#F5D13D",
    fontSize: 20,
    fontWeight: 600,
  },
  iconToAssign: {
    color: theme.palette.secundaryColor,
  },
  buttonToAssign: {
    color: theme.palette.secundaryColor,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontStyle: " italic",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  textNumeration: {
    color: theme.palette.primaryColor,
    fontSize: 16,
  },
  divider: {
    width: "100%",
    backgroundColor: "#fde052",
    padding: "2px",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "7px",
    fontSize: "18px",
    margin: "2px 12px",
  },
});

export class Numeration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
      filteredNumerations: [],
      modalities: [],
      syncNumerationResponse: {},
      editNumerationStatus: false,
      txtFilter: "",
      loadedNumeration: {},
      expandPanelNumeration: "",
      loadingSyncNumerations: false,
      applyElectronicTicket: this.props.applyElectronicTicket,
      components: [],
      loadingUpdateState: false,
      updateNumerationStateResponse: {},
      responseModal: {
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
      },
      loadingGetNumerations: false,
      saveNumerationResponse: {},
      updateNumerationResponse: {},
      numerationsWithoutBranch: [],
      handleScroll: this.props.scroll(),
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
    this.props.scroll();
  }

  componentDidMount() {
    if (this.state.applyElectronicTicket === true) {
      this.props.getSynchronizationDian();
    } else {
      this.props.getNumerationsFilterAction();
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.numerationReducer.saveNumerationResponse &&
      nextProps.numerationReducer.saveNumerationResponse !==
        state.saveNumerationResponse
    )
      update.saveNumerationResponse =
        nextProps.numerationReducer.saveNumerationResponse;

    if (
      !!nextProps.numerationReducer.updateNumerationResponse &&
      nextProps.numerationReducer.updateNumerationResponse !==
        state.updateNumerationResponse
    )
      update.updateNumerationResponse =
        nextProps.numerationReducer.updateNumerationResponse;

    if (
      !!nextProps.numerationReducer.filteredNumerations &&
      nextProps.numerationReducer.filteredNumerations !==
        state.filteredNumerations
    ) {
      update.filteredNumerations =
        nextProps.numerationReducer.filteredNumerations;
      update.numerationsWithoutBranch = nextProps.numerationReducer.numerations.filter(
        (n) => n.sucursalempresaid === null
      );
    }

    if (
      !!nextProps.numerationReducer.syncNumerationResponse &&
      nextProps.numerationReducer.syncNumerationResponse !==
        state.syncNumerationResponse
    )
      update.syncNumerationResponse =
        nextProps.numerationReducer.syncNumerationResponse;

    if (
      !!nextProps.numerationReducer.loadedNumeration &&
      nextProps.numerationReducer.loadedNumeration !== state.loadedNumeration
    )
      update.loadedNumeration = nextProps.numerationReducer.loadedNumeration;

    if (
      !isEmpty(nextProps.numerationReducer.editNumerationStatus) &&
      nextProps.numerationReducer.editNumerationStatus !==
        state.editNumerationStatus
    )
      update.editNumerationStatus =
        nextProps.numerationReducer.editNumerationStatus;

    if (
      !isEmpty(nextProps.numerationReducer.loadingSyncNumerations) &&
      nextProps.numerationReducer.loadingSyncNumerations !==
        state.loadingSyncNumerations
    )
      update.loadingSyncNumerations =
        nextProps.numerationReducer.loadingSyncNumerations;

    if (
      !!nextProps.configReducer.components &
      (nextProps.configReducer.components !== state.components)
    )
      update.components = nextProps.configReducer.components;

    if (
      !isEmpty(nextProps.numerationReducer.loadingUpdateState) &&
      nextProps.numerationReducer.loadingUpdateState !==
        state.loadingUpdateState
    )
      update.loadingUpdateState =
        nextProps.numerationReducer.loadingUpdateState;

    if (
      !!nextProps.numerationReducer.updateNumerationStateResponse &&
      nextProps.numerationReducer.updateNumerationStateResponse !==
        state.updateNumerationStateResponse
    )
      update.updateNumerationStateResponse =
        nextProps.numerationReducer.updateNumerationStateResponse;

    if (
      !isEmpty(nextProps.numerationReducer.loadingGetNumerations) &&
      nextProps.numerationReducer.loadingGetNumerations !==
        state.loadingGetNumerations
    )
      update.loadingGetNumerations =
        nextProps.numerationReducer.loadingGetNumerations;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.saveNumerationResponse !== this.state.saveNumerationResponse
    ) {
      if (
        this.state.saveNumerationResponse.statusCode === "200" ||
        this.state.saveNumerationResponse.statusCode === "201"
      ) {
        this.setState({
          expandPanelNumeration: "",
        });
      }
    }

    if (
      prevState.updateNumerationResponse !== this.state.updateNumerationResponse
    ) {
      if (
        this.state.updateNumerationResponse.statusCode === "200" ||
        this.state.updateNumerationResponse.statusCode === "201"
      ) {
        this.setState({
          expandPanelNumeration: "",
        });
      }
    }

    if (
      prevState.syncNumerationResponse !== this.state.syncNumerationResponse
    ) {
      if (this.state.syncNumerationResponse.statusCode === "201") {
        SuccessAlert(
          getComponentName(this.state.components, 20, 84, "Oye...!!!"),
          getComponentName(
            this.state.components,
            20,
            85,
            "Encontramos en DIAN $cantidad numeración(es), las cargaremos a tu cuenta de Colfactura."
          ).replace(
            "$cantidad",
            this.state.syncNumerationResponse.result.length
          ),
          TIME_UPDATE_REGISTER
        );
      } else if (this.state.syncNumerationResponse.statusCode === "401") {
        WarningAlert(
          "Upss...!!!",
          this.state.syncNumerationResponse.statusMessage
        );
      } else if (this.state.syncNumerationResponse.statusCode === "404") {
        this.setState({
          responseModal: {
            type: "warning",
            title: getComponentName(
              this.state.components,
              91,
              150,
              "Upss...!!!"
            ),
            subtitle: getComponentName(
              this.state.components,
              91,
              151,
              "Aun no tienes prefijos asociados a Colfactura"
            ),
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: getComponentName(
                    this.state.components,
                    91,
                    152,
                    'Ingresa por favor al link <a style="color:#16B1F3;" href="https://catalogo-vpfe.dian.gov.co/User/Login" target="_blank">Facturando Electrónicamente</a> de la Dian y realiza el proceso de asignar prefijos a Colfactura. ¿Tienes dudas de cómo realizar el proceso? <a style="color:#16B1F3;" href="https://www.youtube.com/watch?v=Ll-z2yRn_Ss&t=212s" target="_blank">Apréndelo aquí</a>.'
                  ),
                }}
              ></div>
            ),
            modalImage: "warning",
            open: true,
            closeElement: getComponentName(
              this.state.components,
              91,
              153,
              "Skip"
            ),
            onCloseElement: 0,
          },
        });
      }

      this.props.getNumerationsFilterAction();
    }

    if (
      prevState.updateNumerationStateResponse !==
      this.state.updateNumerationStateResponse
    ) {
      if (this.state.updateNumerationStateResponse.statusCode === "201") {
        if (this.state.updateNumerationStateResponse.idstate === 1) {
          SuccessAlert(
            null,
            getComponentName(
              this.state.components,
              19,
              81,
              "Desactivaste la numeración $resolucion de manera exitosa."
            ).replace(
              "$resolucion",
              this.state.updateNumerationStateResponse.result
            ),
            TIME_UPDATE_REGISTER
          );
        } else {
          SuccessAlert(
            null,
            getComponentName(
              this.state.components,
              19,
              81,
              "Actualizaste la numeración $resolucion de manera exitosa, sigamos facturando."
            ).replace(
              "$resolucion",
              this.state.updateNumerationStateResponse.result
            ),
            TIME_UPDATE_REGISTER
          );
        }

        //Actualiza listado de numeraciones despues de cambiar el estado (activo, inactivo)
        let id = parseInt(
          this.state.updateNumerationStateResponse.idnumeracion
        );
        let idstate = this.state.updateNumerationStateResponse.idstate;
        this.props.refreshAfterChangeStatusAction(
          id,
          idstate,
          this.state.txtFilter
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.loadNumerationAction(null);
  }

  /**
   * Cierra Modal
   */
  handleResponseModal = () => {
    this.setState({
      responseModal: {
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: "",
      },
    });
  };

  /**
   * Evento, Carga información de numeración ara edición
   * @param {*} e Evento
   * @param {*} data Infromación numeración
   */
  editNumeration = (e, data) => {
    e.preventDefault();
    this.props.loadNumerationAction(data);
    this.setState({
      expandPanelNumeration: "panel",
    });
    this.props.scroll();
  };

  /**
   * Evento, escucha evento para contraer o expander collapse de formulario
   */
  onClickCollapse = () => {
    this.setState({
      expandPanelNumeration:
        this.state.expandPanelNumeration === "panel" ? "" : "panel",
    });

    if (
      this.state.expandPanelNumeration === "panel" &&
      this.state.editNumerationStatus
    )
      this.props.loadNumerationAction({});
  };

  /**
   * Evento, escucha cambios en filtro, invoca función para filrad de lista
   * @param {*} e Evento
   */
  onChangeFilter(e) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      txtFilter: value,
    });
    this.props.getNumerationsFilterAction(value, false);
  }

  render() {
    const classes = this.props.classes;

    return (
      <Fragment>
        {this.state.loadingSyncNumerations === true && (
          <Fragment>
            <Grid className={classes.syncDian} container spacing={3}>
              <Grid item xs={12} className={classes.syncPanel}>
                <Typography className={classes.wrapIcon}>
                  <FlareComponent
                    width={20}
                    height={20}
                    animationName="music_walk"
                    file={SyncAnimation}
                  />
                  &nbsp;Buscando numeraciones en DIAN
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        )}

        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid item lg={12} xs={12}>
            <CollapsePanel
              component={<FormNumeration />}
              title={
                this.state.editNumerationStatus
                  ? "Editar numeración"
                  : getComponentName(
                      this.state.components,
                      16,
                      73,
                      "Crear nueva numeración"
                    )
              }
              titleOpen={
                this.state.editNumerationStatus
                  ? "Editar numeración"
                  : "Nueva numeración"
              }
              open={this.state.expandPanelNumeration}
              onClick={this.onClickCollapse}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <InputIcon
              name={"txtFilter"}
              label={"Buscar numeración"}
              value={this.state.txtFilter}
              onChange={(e) => {
                this.onChangeFilter(e);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {this.state.numerationsWithoutBranch.length > 0 ? (
            <Grid item lg={12} xs={12}>
              <Grid container>
                <Grid item lg={2} xs={2} style={{ textAlign: "right" }}>
                  <ReportIcon className={classes.iconMessage} />
                </Grid>
                <Grid item lg={10} xs={10}>
                  <span className={classes.textMessage}>
                    Las siguientes numeraciones no se encuentra asignadas a una
                    sucursal, debes asignarlas para poder facturar con ellas.
                  </span>

                  {this.state.numerationsWithoutBranch.map((item, index) => {
                    return (
                      <div key={index}>
                        <span className={classes.textNumeration}>
                          {`- ${item.resolucion} ${
                            isEmpty(item.prefijo) ? "" : `/ ${item.prefijo}`
                          } ${
                            isEmpty(item.nombre) ? "" : `/ ${item.nombre}`
                          } `}{" "}
                        </span>
                        <Button
                          variant="contained"
                          component="span"
                          className={classes.buttonToAssign}
                          onClick={(e) => this.editNumeration(e, item)}
                        >
                          <EditIcon className={classes.iconToAssign} />
                          <span style={{ textDecoration: "underline" }}>
                            Asignar
                          </span>
                        </Button>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {this.state.loadingGetNumerations === true ||
          this.state.loadingSyncNumerations === true ? (
            <Grid item lg={12} xs={12}>
              <Grid container alignItems="center" justify="center">
                <CustomProgress />
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              {this.state.filteredNumerations.length > 0 ? (
                <Fragment>
                  {this.state.filteredNumerations
                    .filter(
                      (item) =>
                        item.diantipomodalidadid !== 5 &&
                        item.diantipomodalidadid !== 6 &&
                        item.diantipomodalidadid !== 7
                    )
                    .map((data, index) => (
                      <Grid item key={index} lg={4} xs={12}>
                        <NumerationCard
                          data={data}
                          onClick={this.editNumeration}
                        />
                      </Grid>
                    ))}

                  {this.state.filteredNumerations?.filter(
                    (item) =>
                      item.diantipomodalidadid === 5 &&
                      item.diantipomodalidaddescripcion ===
                        "Talonario Contingencia FE"
                  )?.length > 0 && (
                    <>
                      <div className={classes.divider}>Contingencia</div>
                      {this.state.filteredNumerations
                        .filter((item) => item.diantipomodalidadid === 5)
                        .map((data, index) => (
                          <Grid item key={index} lg={4} xs={12}>
                            <NumerationCard
                              data={data}
                              onClick={this.editNumeration}
                            />
                          </Grid>
                        ))}
                    </>
                  )}

                  {this.state.filteredNumerations?.filter(
                    (item) => item.diantipomodalidadid === 6
                  )?.length > 0 && (
                    <>
                      <div className={classes.divider}>Documento soporte</div>
                      {this.state.filteredNumerations
                        .filter((item) => item.diantipomodalidadid === 6)
                        .map((data, index) => (
                          <Grid item key={index} lg={4} xs={12}>
                            <NumerationCard
                              data={data}
                              onClick={this.editNumeration}
                            />
                          </Grid>
                        ))}
                    </>
                  )}
                  {this.state.filteredNumerations?.filter(
                    (item) => item.diantipomodalidadid === 7
                  )?.length > 0 && (
                    <>
                      <div className={classes.divider}>
                        Documento Equivalente
                      </div>
                      {this.state.filteredNumerations
                        .filter((item) => item.diantipomodalidadid === 7)
                        .map((data, index) => (
                          <Grid item key={index} lg={4} xs={12}>
                            <NumerationCard
                              data={data}
                              onClick={this.editNumeration}
                            />
                          </Grid>
                        ))}
                    </>
                  )}
                </Fragment>
              ) : (
                <Grid item lg={12} xs={12}>
                  {!READING_MODE ? (
                    <NotificationNotCreated
                      text={"No se han encontrado resultados..."}
                    />
                  ) : (
                    <NotificationReadingMode
                      text={
                        "No puedes realizar esta acción, DIAN se encuentra haciendo mantenimientos en el sistema de Facturación Electrónica."
                      }
                    />
                  )}
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>

        <ResponseModal
          modalType={this.state.responseModal.modalType}
          title={this.state.responseModal.title}
          subtitle={this.state.responseModal.subtitle}
          body={this.state.responseModal.body}
          modalImage={this.state.responseModal.modalImage}
          open={this.state.responseModal.open}
          closeElement={this.state.responseModal.closeElement}
          onCloseElement={() => this.handleResponseModal()}
        />
      </Fragment>
    );
  }
}

Numeration.propTypes = {
  getCompanyAction: PropTypes.func.isRequired,
  getNumerationsFilterAction: PropTypes.func.isRequired,
  refreshAfterChangeStatusAction: PropTypes.func.isRequired,

  branchOfficeReducer: PropTypes.object.isRequired,
  numerationReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  branchOfficeReducer: state.branchOfficeReducer,
  numerationReducer: state.numerationReducer,
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  createNumerationAction,
  getNumerationsFilterAction,
  getNumeration,
  changeStepStatusAction,
  getCompanyAction,
  getSynchronizationDian,
  loadNumerationAction,
  refreshAfterChangeStatusAction,
})(withStyles(styles)(Numeration));
