import React from 'react'
import { makeStyles } from '@material-ui/core/styles';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { FormatDecimal } from '../../../components/common/formatDecimal';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.11)',
        color: theme.palette.primaryColor,
        fontSize: 16,
        '& .MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 5,
            marginBottom: 0,
            marginTop: -5,
            paddingLeft: 55
        }
    },
    header: {
        paddingBottom: 0,
        paddingTop: 5,
        marginLeft: -5,
        '& .MuiCardHeader-action': {
            display: 'flex'
        },
        '& .MuiCardHeader-root': {
            padding: 10
        }
    },
    content: {
        fontSize: '0.9rem'
    },
    title: {
        color: theme.palette.primaryColor,
        fontSize: '0.9rem'
    },
    icon: {
        color: theme.palette.secundaryColor
    }
}));

export const DocumentChargeCard = ({ data }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={<LibraryAddIcon className={classes.icon} />}
                classes={{
                    title: classes.title,
                }}
                title={data.descripcion}
            />
            <CardContent className={classes.content}>
                <span>
                    {
                        data.tipocargo === 1
                            ? <FormatDecimal
                                value={data.valor}
                                prefix={"$"}
                            />
                            : <FormatDecimal
                                value={data.valor}
                                suffix={"%"}
                            />
                    }
                    {
                        data.cantidad > 1
                        && <FormatDecimal
                            value={data.cantidad}
                            prefix="("
                            suffix=")"
                        />
                    }
                </span>
            </CardContent>
        </Card>
    )
}


