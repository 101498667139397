import React, { Component } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import { sendDocumentAction } from "../../actions/invoiceActions";

import { sendDocSupportAction } from "../../actions/documentSupportAction";

import InputForm from "../../components/input/input.component";
import ButtonSecundary from "../../components/button/buttonSecundary.component";

import { regexEmail } from "../../helpers/customRegex.hepers";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

class ModalFileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        txtModalMail: props.email,
      },
      isInvoiceMail: props.isInvoiceMail,
      isQuotationMail: props.isQuotationMail,
      isDocSupportMail: props.isDocSupportMail,
      isDocEquivalentMail: props.isDocEquivalentMail,
      sendQuotation: props.sendQuotation,
      data: props.data,
      loading: false,
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    return Object.keys(update).length ? update : null;
  }

  /**
   * Sincroniza nuevo valor de  input con state
   * @param {object} e Evento
   */
  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  };

  /**
   * Almacena el correo en un array y envia la informacion necesaria para el correo
   * @param {*} e Evento
   */
  sendMails = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let arrMail = [];
      arrMail[0] = this.state.form.txtModalMail;
      arrMail[0] = arrMail[0].replace(regexEmail, "");

      const data = {
        ...this.state.data,
        Correos: arrMail,
      };

      this.validator.hideMessages();
      if (this.state.isDocSupportMail) {
        this.props.sendDocSupportAction(data, this.props.type);
      } else {
        this.props.sendDocumentAction(data, this.props.type);
      }
      this.setState({ loading: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <form onSubmit={(e) => this.sendMails(e)} style={{ width: "100%" }}>
        <div>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="modalEmailText"
            >
              Enviaremos{" "}
              {this.state.isQuotationMail
                ? "la cotización"
                : this.state.isInvoiceMail
                ? "la factura"
                : this.state.isDocSupportMail
                ? "el documento soporte"
                : this.state.isDocEquivalentMail
                ? "el documento equivalente"
                : "la nota"}{" "}
              al siguiente correo electrónico, puedes modificarlo si lo
              requieres
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
              <InputForm
                id="txtModalMail"
                name="txtModalMail"
                label={"Correo electrónico *"}
                value={this.state.form.txtModalMail}
                maxLength={200}
                onChange={(e) => {
                  this.syncChanges(e);
                }}
                disabled={this.state.loading}
                validator={this.validator}
                validateOptions={"required|correo|min:10|max:200"}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
              <ButtonSecundary
                text={"Enviar"}
                loading={this.state.loading}
                type={"submit"}
              />
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  sendDocumentAction,
  sendDocSupportAction,
})(ModalFileForm);
