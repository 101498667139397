import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import InputIcon from '../../components/input/inputIcon.component';
import CustomProgress from '../../components/Progress/progress.component';

import useNearScreen from '../../hooks/useNearScreen';

import { getCustomersAction, getNextPageAction, filterCustomersAction, setCustomerIdAction } from '../../actions/customerAction';
import { QUANTITY_DATA_CUSTOMER } from '../../config/config';



const useStyles = makeStyles((theme) => ({
    listStyle: {
        backgroundColor: theme.palette.secundaryBackgroundColor,
        color: theme.palette.primaryColor,
        overflow: 'auto'
    },
    item: {
        marginBottom: 5,
        width: '95%',
        fontWeight: 600,
        "&.MuiListItem-root:hover": {
            borderRadius: 5,
            border: '1px solid #16B1F6',
        },
        '&.Mui-selected': {
            backgroundColor: '#16B1F3',
            borderRadius: 5,
        }
    },
    itemText: {
        fontWeight: 600,
        color: theme.palette.primaryColor,
    },
    textOverflow: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 600,
        maxWidth: '150vh'
    },
    emptylist: {
        color: 'white'
    }
}));



export const CustomersList = React.memo(
    ({
        handleSelection,
        height = '63vh',
        consultFirst = false,
        type= 0
    }) => {
        const classes = useStyles();
        const externalRef = useRef();
        const dispatch = useDispatch();
        const [filter, setFilter] = useState('');
        const [selectedId, setSelectedId] = useState(0);
        const { currentCustomerList, loading, customerId } = useSelector(state => state.customerReducer);
        const { isNearScreen } = useNearScreen({
            externalRef: loading ? null : externalRef,
            distance: "400px",
            once: false,
        });

        /**
         * Cargue Inicial
         */
        useEffect(() => {
            dispatch(getCustomersAction(consultFirst, type));
        }, [dispatch, consultFirst, type])


        /**
         * Obtiene siguiente pagina listado clientes
         */
        useEffect(() => {
            if (isNearScreen) {
                let newStart = currentCustomerList.length;
                let newEnd = currentCustomerList.length + QUANTITY_DATA_CUSTOMER;

                dispatch(getNextPageAction(newStart, newEnd, filter));
            }


            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isNearScreen]);


     
        useEffect(() => {
            if (customerId !== null && customerId !== undefined)
                setSelectedId(customerId);
        }, [customerId])


     
        /**
         * Sincroniza nuevo valor con state,
         * consulta nuevo listado
         * @param {*} param
         */
        const handleChangeFilter = ({ target }) => {
            setFilter(target.value);
            dispatch(filterCustomersAction(target.value));
        }


        /**
         * Consulta detalle cliente
         */
        const handleChangeSelection = (id) => {
            setSelectedId(id);
            handleSelection(id);
            dispatch(setCustomerIdAction(id));
        }


        return (
            <>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <InputIcon
                            name={"txtFilter"}
                            label={"Buscar cliente por nombre o NIT"}
                            maxLength={15}
                            value={filter}
                            onChange={handleChangeFilter} />

                        {
                            (!loading && currentCustomerList.length === 0) &&
                            <div
                                className={`${classes.emptylist} animate__animated animate__fadeIn animate__faster`}>
                                {"No se han encontrado resultados..."}
                            </div>
                        }

                        {
                            loading
                                ? <CustomProgress />
                                : <List
                                    className={classes.listStyle}
                                    component="div"
                                    aria-label="main mailbox folders"
                                    style={{ maxHeight: height, minHeight: height }}
                                >
                                    {currentCustomerList.map((item) => (
                                        <ListItem
                                            key={item.id}
                                            className={`${classes.item} animate__animated animate__fadeIn animate__faster`}
                                            button
                                            selected={selectedId === item.id}
                                            onClick={() => handleChangeSelection(item.id)} >
                                            <ListItemText className={classes.itemText} component={'div'}>
                                                <div className={classes.textOverflow} >
                                                    {item.text}
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                    <div ref={externalRef} id="visor"></div>
                                </List>
                        }
                    </Grid>

                </Grid>
            </>
        )
    }
)

