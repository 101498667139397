import React, { Fragment, useState, useEffect, useRef } from "react"; //Formateo
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import EventIcon from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import Tooltip from "@material-ui/core/Tooltip";
import ReportIcon from "@material-ui/icons/Report";

import RcCalendar from "../../components/datepicker/rcdatepicker.component";
import InvoiceDetails from "../invoice/invoice-details.view";
import CustomProgress from "../../components/Progress/progress.component";
import SimpleModal from "../../components/modal/simpleModal.component";
import FileModal from "../../components/modal/fileModal.component";
import PopoverModal from "../../components/modal/popoverModal.component";
import StyledCheckbox from "../../components/checkForm/styledCheckbox.component";
import ResponseModal from "../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../components/modal/responseRedirectModal.component";
import ModalFileForm from "./modal-file-form.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import InputIcon from "../../components/input/inputIcon.component";
import MessageSecundary from "../../components/tooltip/messageSecundary.component";
import BulkLoadStatus from "../../components/bulkLoadStatus.component";
import ButtonInvoice from "../../components/button/buttonPrincipalInvoice.component";
import InvoiceTypeNote from "../invoice/invoice-typeNote.view";
import InvoiceAnnulmentNote from "../invoice/invoice-annulmentNote";
import TooltipMessageSecundary from "../../components/tooltip/tooltipSecundary.component";

import {
  getNonElectronicDocumentFileAction,
  getDocumentsAction,
  getDocumentDetailAction,
  updateDocumentAction,
  updateDocumentFromListAction,
  changeStepAction,
  createNoteAnnulmentAction,
  sendMailReportDocumentAction,
  getDataEmailReportAction,
  cleanDocumentDetailAction,
  setDefaultFilterAction,
  updateOpenModalItem,
  cleanLicenseBaseAction,
} from "../../actions/invoiceActions";
import {
  addChargeToInvoiceAction,
  openModalChargeAction,
  addDiscountToInvoiceAction,
  calculeTotalNoteAction,
} from "../../actions/valuesInvoiceAction";

import { setStatusShowCartAction } from "../../actions/valuesInvoiceAction";

import {
  checkCertificateCompanyAction,
  checkCertificateToEditInvoiceAction,
  getAdditionalDataAction,
} from "../../actions/basicDataActions";

import {
  cleanDocumentCreateAction,
  setTypeNoteAction,
} from "../../actions/documentAction";

import { WarningAlert } from "../../helpers/alert.helpers";
import isEmpty from "../../utils/isEmpty";
import {
  CLAIM_DOCUMENTS,
  QUANTITY_DATA_DOCUMENT,
  READING_MODE,
} from "../../config/config";
import { formatDate } from "../../utils/general.js";
import { generateRequestNoteDataBD } from "../../helpers/generateRequestDocument";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../helpers/toast.helpers";
import { getDescriptionRole } from "../../helpers/roleUser";
import { FormatDecimal } from "../../components/common/formatDecimal";
import { TooltipMessageReadingMode } from "../../components/tooltip/TooltipMessageReadingMode";

const useStyles = makeStyles({
  root: {
    marginBottom: 5,
    height: 90,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 14,
  },
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  letter: {
    color: "#FFFFFF",
  },
  letterSearch: {
    color: "#848484",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  link: {
    color: "White",
    fontSize: "16px",
    padding: "7px",
    textAlign: "center",
  },
  widthMax: {
    width: "100%",
  },
  paddingIcons: {
    paddingTop: "20%",
  },
  width200: {
    width: "200px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: "1%",
  },
  width340: {
    width: "340px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: "1%",
  },
  width400: {
    width: "400px",
    borderRadius: "4px",
    backgroundColor: "#2D2D2D",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)",
    color: "white",
    fontFamily: "Muli",
    fontSize: "16px",
    letterSpacing: "0",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: "1%",
  },
  buttonIcon: {
    color: "white",
    marginTop: "50%",
  },
  buttonFilterStatus: {},
  containerProgress: {
    display: "flex",
    paddingLeft: "15%",
  },
  iconButton: {
    padding: 0,
    color: "white",
  },
  buttonBulkLoad: {
    textAlign: "right",
    fontFamily: "Muli",
    fontSize: "16px",
  },
  tooltip: {
    backgroundColor: "transparent",
    "& .MuiTooltip-tooltip": {
      backgroundColor: "blue",
    },
  },
  tooltipModeReading: {
    display: "inline-flex",
    padding: "8px 13px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "2px",
    background: "#2F2E2E",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
  },
  tooltipModeReadingText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Muli",
    fontSize: "13px",
    fontWeight: 400,
  },
});

const initialModal = {
  modalType: "",
  title: "",
  subtitle: "",
  body: "",
  modalImage: "",
  open: false,
  closeElement: "",
};

const basePlanExpired = {
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
};

const InvoiceGrid = React.memo(
  ({
    setDefaultFilterAction,
    getDocumentDetailAction,
    cleanDetailDocumentAction,
    updateOpenModalItem,
    cleanLicenseBaseAction,
    ...props
  }) => {
    const classes = useStyles();
    const {
      HandleCreate,
      HandleEdit,
      Empresaid,
      Editada,
      loadingGetdocuments,
      sendMailResponse,
      HandleBulkUpload,
      informationBulk,
      stateBulk,
      handleShowDetailNote,
      modal,
      licenseBase,
    } = props;
    const {
      loadingFavouriteFromList,
      totaldocuments,
      reasonList,
      dataEmailReport,
    } = props; //Reducers
    const dispatch = useDispatch();
    const { invoiceReducer, menuReducer } = props; //Reducers
    const { defaultFilter } = useSelector((state) => state.invoiceReducer);
    const [modalLicenseResponse, setModalLicenseResponse] = useState(
      initialModal
    );
    const [modalChecksAnchorEl, setModalChecksAnchorEl] = React.useState(null);
    const [modalDatesAnchorEl, setModalDatesAnchorEl] = React.useState(null);
    const [modalWarningAnchorEl, setModalWarningAnchorEl] = React.useState(
      null
    );
    const [openModalMail, setOpenModalMail] = useState(false);
    const [openModalFile, setOpenModalFile] = useState(false);
    const [loadingCreate, setLoadingCreate] = React.useState(false);
    const [loadingEdit, setLoadingEdit] = React.useState(false);
    const [isInvoiceMail, setIsInvoiceMail] = React.useState(true);
    const [mailData, setMailData] = React.useState({});
    const [email, setEmail] = useState("");
    const [txtFilter, setTxtFilter] = useState(defaultFilter ?? "");
    const [txtFilterConfirmed, setTxtFilterConfirmed] = useState(
      defaultFilter ?? ""
    );
    const [documentIdSelected, setDocumentSelected] = useState(null);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [dataInvoice, setDataInvoice] = useState(null);
    const [openModalAnnulment, setOpenModalAnnulment] = useState(false);
    const [loadingNoteAnnulment, setLoadingNoteAnnulment] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [, setLoadingOpenBulkUpload] = useState(false);
    const [reasonEmailList, setReasonList] = useState([]);
    const [loadingListReport, setLoadingListReport] = useState([]);
    const [menuInvoice, setMenuInvoice] = useState({});
    const [typeInvoice, setTypeInvoice] = useState("");

    const [modalData, setModalData] = React.useState({
      type: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: 0,
    });

    const [filterData, setFilterData] = useState({
      Fechadesde: null,
      Fechahasta: null,
      Empresaid: Empresaid,
      Filtro:
        defaultFilter !== null && defaultFilter !== undefined
          ? defaultFilter
          : "",
      Skip: 0,
      Limit: QUANTITY_DATA_DOCUMENT,
      Editada: Editada,
      Destacadas: false,
      Pagadas: false,
      Pendientes: false,
      Vencidas: false,
      Masivo: false,
      Anulacion: false,
      Notadebito: false,
      Notacredito: false,
    });

    const [datesTmp, setDatesTmp] = useState({
      Fechini: format(new Date(), "yyyy/MM/dd"),
      Fechfin: format(new Date(), "yyyy/MM/dd"),
    });

    const [archivo, setArchivo] = useState({
      Documento: "",
      TipoDocumento: "",
      CodigoUnico: "",
      Transacion: Math.random(),
    });

    const [documentNonElectronic, setDocumentNonElectronic] = useState({
      id: null,
      Transacion: Math.random(),
    });

    const filterImage =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC";

    const urlInvoice = useRef();

    useEffect(() => {
      ValidateCartDocSupport();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlInvoice]);

    /**
     * Cargar listado facturas en state
     */
    useEffect(() => {
      setInvoices(invoiceReducer.invoices ?? []);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceReducer.invoices]);

    /**
     * Inicia componente
     */
    useEffect(() => {
      props.getDataEmailReportAction();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Menus configurables
     */
    useEffect(() => {
      setMenuInvoice(menuReducer.menuInvoice);
    }, [menuReducer.menuInvoice]);

    /**
     * Consulta listado facturas paginando y filtrando
     */
    useEffect(() => {
      props.getDocumentsAction(filterData, invoices, selectDefaultItem);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData]);

    /**
     * Consulta detalle de  documento seleccionado
     */
    useEffect(() => {
      if (documentIdSelected !== null && documentIdSelected !== undefined) {
        getDocumentDetailAction(documentIdSelected);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentIdSelected]);

    useEffect(() => {
      cleanMail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMailResponse]);

    /**
     * Carga listado asuntos reporte email
     */
    useEffect(() => {
      if (reasonList?.length > 0) setReasonList(reasonList);
    }, [reasonList]);

    /**
     * Carga listado documentos en varible de estado
     */
    useEffect(() => {
      setInvoices(invoiceReducer.invoices);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceReducer.invoices]);

    /**
     * Carga listado loading reporte documento en variable estado
     */
    useEffect(() => {
      setLoadingListReport(invoiceReducer.loadingReportDocument);
    }, [invoiceReducer.loadingReportDocument]);

    /**
     * Limpia valor filtro por defecto al desmontarse componente
     */
    useEffect(() => {
      return () => {
        setDefaultFilterAction(null);
      };
    }, [setDefaultFilterAction]);

    useEffect(() => {
      return () => {
        cleanDocumentDetailAction();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cleanDocumentDetailAction]);

    useEffect(() => {
      if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
        setModalLicenseResponse(basePlanExpired);
      }
    }, [licenseBase]);

    // BEGIN FUNCIONES

    /**
     * Limpia el carrito  si no se encuentra en documentSupport
     */

    const ValidateCartDocSupport = () => {
      if (!urlInvoice?.current?.baseURI?.includes("documentSupport")) {
        dispatch(cleanDocumentCreateAction());
      }
    };

    /**
     * Cerrar modal licencia vencida
     */
    const handleCloseResponseModal = () => {
      setModalLicenseResponse({
        ...modalLicenseResponse,
        open: false,
      });
      cleanLicenseBaseAction();
    };

    /**
     * Cierra modal, mensaje plan sin cupo
     * @param {*} e
     */
    const onCloseModalItem = (e) => {
      updateOpenModalItem(false);
    };

    /**
     * Sincroniza nuevo valor filtro
     * @param {*} e Evento
     */
    const syncChangesFilter = (e) => {
      let value = e.target.value;
      setTxtFilter(value);

      if (value === "") {
        setTxtFilterConfirmed("");
        setFilterData({
          ...filterData,
          Skip: 0,
          LoadMore: false,
          Filtro: "",
        });
      }
    };

    /**
     * Evento, click sobre boton busqueda, consulta nuevo listado documentos
     * @param {*} e Evento
     */
    const onSearch = (e) => {
      setFilterData({
        ...filterData,
        Filtro: txtFilter,
        Skip: 0,
        LoadMore: false,
      });

      setTxtFilterConfirmed(txtFilter);
    };

    /**
     * Carga identificador  de documento por defecto, para realizar consulta
     * @param {*} id Identificador documento
     */
    const selectDefaultItem = (id) => {
      setDocumentSelected(id);
    };

    /**
     * Limpiar email
     */
    const cleanMail = () => {
      setOpenModalMail(false);
      setMailData({});
      setEmail("");
      setIsInvoiceMail(true);
    };

    /**
     * Cerrar modal filtro estado documentos
     */
    const handleCloseModalChecks = () => {
      setModalChecksAnchorEl(null);
    };

    /**
     * Abrir modal filtro estado documentos
     * @param {*} event Evento
     */
    const handleOpenModalChecks = (event) => {
      if (loadingGetdocuments !== true) {
        setModalChecksAnchorEl(event.currentTarget);
      }
    };

    /**
     * Cerrar modal warning
     */
    const handleCloseModalWarning = () => {
      setModalWarningAnchorEl(null);
    };

    /**
     * Cierra modal filtro por fechas
     */
    const handleCloseModalDates = () => {
      setModalDatesAnchorEl(null);
    };

    /**
     * Abre modal para filtro por fechas
     * @param {*} event Evento
     */
    const handleOpenModalDates = (event) => {
      if (loadingGetdocuments !== true) {
        setModalDatesAnchorEl(event.currentTarget);
      }
    };

    /**
     * Cierra modal reenvio documento
     */
    const handleCloseModalMail = () => {
      setOpenModalMail(false);
    };

    /**
     * Cierra modal editar documento
     */
    const handleCloseModalEdit = () => {
      setOpenModalEdit(false);
      setDataInvoice(null);
    };

    /**
     * Cierra modal anular documento
     */
    const handleCloseModalAnnulment = () => {
      setOpenModalAnnulment(false);
    };

    /**
     * Cierra modal previsualizacion  documento
     */
    const handleCloseModalFile = () => {
      setOpenModalFile(false);
    };

    /**
     * Retorna el estado en el que se encuentra el cargue masivo
     * @param {*} state
     */

    const stateBulkLoad = (state) => {
      if (state === 1) return "en proceso";

      if (state === 2) return "cancelado";

      if (state === 3) return "finalizado";

      if (state === 4) return "pausado";
    };
    /**
     * Actualiza estado loading abrir carga masiva.
     * @param {boolean} status Nuevo estado
     */
    const loadingBulkUpload = (status) => {
      setLoadingOpenBulkUpload(status);
    };

    /**
     * Evento para abir opcion de cargue masivo.
     */
    const openBulkUpload = () => {
      props.checkCertificateCompanyAction(
        checkCertificateToBulkUpload,
        loadingBulkUpload
      );
    };

    /**
     * Escucha evento, filtro por estado en documentos
     * @param {*} event
     */
    const handlecheckFilters = (event) => {
      let name = event.target.name;
      let checked = event.target.checked;

      if (name === "chkPagadas") {
        setFilterData({
          ...filterData,
          Pagadas: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkPendientes") {
        setFilterData({
          ...filterData,
          Pendientes: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkVencidas") {
        setFilterData({
          ...filterData,
          Vencidas: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkDestacadas") {
        setFilterData({
          ...filterData,
          Destacadas: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkMasivo") {
        setFilterData({
          ...filterData,
          Masivo: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkAnulacion") {
        setFilterData({
          ...filterData,
          Anulacion: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkNotaCredito") {
        setFilterData({
          ...filterData,
          Notacredito: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      if (name === "chkNotaDebito") {
        setFilterData({
          ...filterData,
          Notadebito: checked,
          Skip: 0,
          LoadMore: false,
        });
      }

      setTxtFilter(txtFilterConfirmed);
    };

    /**
     * Limpia filtro por estado de documentos
     */
    const cleanFilter = () => {
      setFilterData({
        ...filterData,
        Skip: 0,
        Editado: false,
        Destacadas: false,
        Pagadas: false,
        Pendientes: false,
        Vencidas: false,
        LoadMore: false,
        Masivo: false,
        Anulacion: false,
        Notacredito: false,
        Notadebito: false,
      });

      handleCloseModalChecks();
      setTxtFilter(txtFilterConfirmed);
    };

    /**
     * Evento seleccion fecha final en filtro de documentos
     * @param {*} date
     */
    const handleDateFinal = (date) => {
      setDatesTmp((prevState) => ({ ...prevState, Fechfin: date }));
    };

    /**
     * Evento seleccion fecha inicial en filtro de documentos
     * @param {*} date
     */
    const handleDateInitial = (date) => {
      setDatesTmp((prevState) => ({ ...prevState, Fechini: date }));
    };

    /**
     * Filtra listado documentos por rango de fechas
     */
    const filterDate = () => {
      if (datesTmp.Fechini === "" || datesTmp.Fechifin === "") {
        WarningAlert("Debe seleccionar un rango de fechas válido");
      } else {
        if (new Date(datesTmp.Fechini) > new Date(datesTmp.Fechfin)) {
          WarningAlert("La fecha inicial no puede ser mayor a la final");
        } else {
          setFilterData({
            ...filterData,
            Fechadesde: datesTmp.Fechini,
            Fechahasta: datesTmp.Fechfin,
            Skip: 0,
            LoadMore: false,
          });

          handleCloseModalDates();
          setTxtFilter(txtFilterConfirmed);
        }
      }
    };

    /**
     * Limpia Filtro fechas, consulta listado con valores por defecto
     */
    const cleanFilterDate = () => {
      setDatesTmp((prevState) => ({
        ...prevState,
        Fechini: format(new Date(), "yyyy/MM/dd"),
        Fechfin: format(new Date(), "yyyy/MM/dd"),
      }));

      setFilterData({
        ...filterData,
        Fechadesde: null,
        Fechahasta: null,
        Skip: 0,
        LoadMore: false,
      });

      handleCloseModalDates();
      setTxtFilter(txtFilterConfirmed);
    };

    /**
     * Abre notificción reenvio de documento
     * @param {*} email
     * @param {*} isInvoiceMail
     * @param {*} tipodocumentoentidad
     * @param {*} documentompresa
     * @param {*} codigounico
     * @param {*} numero
     * @param {*} razonsocialempresa
     * @param {*} razonsocialcliente
     */
    const openMailModal = (
      email,
      isInvoiceMail,
      tipodocumentoentidad,
      documentompresa,
      codigounico,
      numero,
      razonsocialempresa,
      razonsocialcliente,
      tipoModalidad,
      documentoid,
      typedocument
    ) => {
      if (
        (codigounico === null && tipoModalidad === 3) ||
        (codigounico === null && tipoModalidad === 5)
      ) {
        setModalData({
          ...modalData,
          type: "warning",
          title: "Oops!!!",
          subtitle: "Documento no disponible",
          body: (
            <div>
              {
                "Factura en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá enviarlo."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        });
      } else {
        setEmail(email);
        setMailData({
          TipoDocumento: tipodocumentoentidad,
          Documento: documentompresa,
          CodigoUnico: codigounico,
          NumeroDocumento: numero,
          RazonSocialEmpresa: razonsocialempresa,
          RazonSocialCliente: razonsocialcliente,
          TipoModalidad: tipoModalidad,
          DocumentoId: documentoid,
        });
        setIsInvoiceMail(isInvoiceMail);
        setOpenModalMail(true);
      }
    };

    /**
     * Consulta documento en base64 para visualización aplica para facturas y Notas
     * @param {*} tipo
     * @param {*} documento NUmero de doumento emisor
     * @param {*} codigounico Identificador unico documento elctronico
     * @param {*} diantipomodalidadid  Identificador tipo modalidad documento, para Notas se toma el documento de refebrencia
     * @param {*} id  Identificador en BD documento
     */
    const openFileModal = (
      tipo,
      documento,
      codigounico,
      diantipomodalidadid,
      id
    ) => {
      //Documento no electronicos
      if (diantipomodalidadid !== 3 && diantipomodalidadid !== 5) {
        setDocumentNonElectronic({
          id: id,
          Transacion: Math.random(),
        });
        setOpenModalFile(true);
        return;
      }

      //Documentos electronicos
      if (
        codigounico === null &&
        (diantipomodalidadid === 3 || diantipomodalidadid === 5)
      ) {
        setModalData({
          ...modalData,
          type: "warning",
          title: "Upss...!!!",
          subtitle: "Archivo no disponible",
          body: (
            <div>
              {
                "Factura en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura no te permitirá visualizarlo."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        });
      } else {
        setArchivo((prevState) => ({
          ...prevState,
          TipoDocumento: tipo,
          Documento: documento,
          CodigoUnico: codigounico,
          Transacion: Math.random(),
        }));
        setOpenModalFile(true);
      }
    };

    /**
     * Evento, marcar documento como favorito
     * @param {*} action
     * @param {*} id
     */
    const handleFavourite = (data) => {
      let newData = {
        Id: data.id,
        Operacion: "FAVORITO",
        Favorito: !data.favorito,
      };

      props.updateDocumentFromListAction(newData, invoices, documentIdSelected);
    };

    /**
     * Evento, seleccion documento
     * @param {*} id Identificador documento
     */
    const handleIdChange = (id) => {
      setDocumentSelected(id);
    };

    //Evento click nueva Factura
    const openCreate = () => {
      props.checkCertificateCompanyAction(
        checkCertificateToCreateInvoice,
        loadingCreateInvoice
      );
    };

    /**
     * Valida si la empresa tiene un certificado valido para facturar antes de crear una factura
     * @param {object} data Infromación del certificado
     */
    const checkCertificateToCreateInvoice = (data) => {
      //Leer respuesta certificado
      let requiere = data?.requiere ?? true;
      let activo = data?.activo === true ? true : false;

      if (requiere === true && activo === false) {
        setModalData({
          ...modalData,
          type: "warning",
          title: "Upss...!!!",
          subtitle: `Esta opción aún no está disponible.`,
          body: (
            <div>
              {
                "Todavía no cuentas con un certificado de firma digital vigente."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        });
      } else {
        props.getAdditionalDataAction();
        HandleCreate();
      }
    };

    /**
     * Valida si la empresa tiene un certificado valido para facturar antes de crear una factura
     * @param {object} data Infromación del certificado
     */
    const checkCertificateToBulkUpload = (data) => {
      if (data.requiere === true && data.activo === false) {
        setModalData({
          ...modalData,
          type: "warning",
          title: "Upss...!!!",
          subtitle: `.`,
          body: (
            <div>
              {
                "Todavía no cuentas con un certificado de firma digital vigente."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        });
      } else {
        HandleBulkUpload();
      }
    };

    /**
     * Escucha evento para edición de factura, genrear Nota
     * @param {object} invoice Información factura
     */
    const editInvoice = (invoice) => {
      if (invoice.anulacion === true) {
        warningToast(
          "No es posible editar una factura anulada",
          "",
          "top-center"
        );
        return;
      }

      if (
        invoice.diantipomodalidadid === 3 &&
        (invoice.estadodocumentoid !== 2 || isEmpty(invoice.codigounico))
      ) {
        setModalData({
          ...modalData,
          type: "warning",
          title: "Upss...!!!",
          subtitle: `No puedes editar este documento.`,
          body: (
            <div>
              {
                "No puedes editar este documento por que aun no se ha sincronizado con la DIAN."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        });
      } else {
        props.checkCertificateToEditInvoiceAction(
          checkCertificateToEditInvoice,
          loadingEditInvoice,
          invoice
        );
      }
    };

    /**
     * Valida  si cuenta con certificado vigente para edición de facturas
     * @param {*} data Información certificado
     * @param {*} invoice Información factura registrada en BD
     */
    const checkCertificateToEditInvoice = (data, invoice) => {
      //Leer respuesta certificado
      let requiere = data?.requiere ?? true;
      let activo = data?.activo === true ? true : false;

      if (requiere === true && activo === false) {
        setModalData({
          ...modalData,
          type: "warning",
          title: "Upss...!!!",
          subtitle: `Esta opción aún no está disponible.`,
          body: (
            <div>
              {
                "Todavía no cuentas con un certificado de firma digital vigente."
              }
            </div>
          ),
          modalImage: "warning",
          open: true,
          closeElement: "Cerrar",
          onCloseElement: 0,
        });
      } else {
        setDataInvoice(invoice);
        setOpenModalEdit(true);
      }
    };

    /**
     * Evento, Escucha click confirmación generar nota
     */
    const confirmEditInvoice = (type) => {
      if (type === "ANU" || type === "ANU_ACEP") {
        setOpenModalEdit(false);
        setOpenModalAnnulment(true);
        setTypeInvoice(type);
      } else {
        props.setTypeNoteAction(type);
        props.getAdditionalDataAction(dataInvoice?.datosAdicionales ?? []);
        HandleEdit(dataInvoice);
        setOpenModalEdit(false);
        setDataInvoice(null);
      }
    };

    /**
     * Registra Nota credito con motivo anulación
     */
    const annulmentInvoice = (observations, isApproved) => {
      setLoadingNoteAnnulment(true);
      let document = generateRequestNoteDataBD(
        dataInvoice,
        observations,
        isApproved
      );
      let data = {
        invoices: invoices,
        detail: dataInvoice,
      };

      props.createNoteAnnulmentAction(
        document,
        confirmSendOk,
        confirmError,
        data
      );
    };

    /**
     * Lee response exitoso anulación factura
     * @param {*} response
     */
    const confirmSendOk = (response) => {
      setLoadingNoteAnnulment(false);
      setOpenModalAnnulment(false);
      if (response.data.statusCode === "201")
        successToast(
          "",
          `La factura ${dataInvoice.numero} fue anulada de manera exitosa, sigamos facturando`,
          "top-center"
        );
      else
        warningToast(
          "Ocurrió algo inesperado",
          `La factura ${dataInvoice.numero} fue anulada de manera exitosa, ${response.data.result}. ${response.data.statusMessage}`
        );
    };

    /**
     * Lee response con error anulación factura
     * @param {*} response
     */
    const confirmError = (response) => {
      setLoadingNoteAnnulment(false);
      setOpenModalAnnulment(false);
      if (response?.data?.statusMessage !== "Unauthorized, no quota") {
        errorToast(
          "Ocurrió algo inesperado",
          `${JSON.stringify(
            response?.data?.statusMessage ??
              "Error inesperado, no se ha podido anular factura"
          )}`
        );
      }
    };

    /**
     * Cierra Modal, Notificación certificado no valido
     */
    const handleResponseModal = () => {
      setModalData({
        ...modalData,
        open: false,
      });
    };

    /**
     * Actualiza estado loading crear nueva factura.
     * @param {boolean} status Nuevo estado
     */
    const loadingCreateInvoice = (status) => {
      setLoadingCreate(status);
    };

    /**
     * Actualiza estado loading para edición de facturas
     * @param {boolean} status Nuevo valor loading editar
     */
    const loadingEditInvoice = (status) => {
      setLoadingEdit(status);
    };

    /**
     * Carga  siguiente lote de documentos
     */
    const loadMoreDocuments = () => {
      setFilterData({
        ...filterData,
        Skip: invoices.length,
        LoadMore: true,
      });
      setTxtFilter(txtFilterConfirmed);
    };

    /**
     * Actualiza estado  de un documento
     * @param {*} data Información documento
     */
    const updateDocumentStatus = (data) => {
      props.updateDocumentAction(data, invoices);
    };

    const reportProblemComponent = (e, item) => {
      e.preventDefault();

      let data = {
        mailSupport: reasonEmailList?.find((c) => c.codigo?.trim() === "12")
          ?.correo, //Soporte
        mailUser: dataEmailReport.emailUser,
        issue: "Factura rechazada ante DIAN",
        roleUser: getDescriptionRole(dataEmailReport.roleUser),
        nameCompany: dataEmailReport.businessName,
        nitCompany: dataEmailReport.nitCompany,
        userName: dataEmailReport.userName,
        bodyMail: "Factura Rechazada por DIAN",
        documentId: item.id,
        numero: item.numero,
      };

      props.sendMailReportDocumentAction(data, invoices);
    };

    const getStatusDIAN = (item) => {
      if (item.diantipomodalidadid !== 3 && item.diantipomodalidadid !== 5) {
        return null;
      }

      if (item.tipoestadodocumentoid === 2) {
        return (
          <Tooltip
            title={
              <MessageSecundary
                title={"Pendiente de sincronización con DIAN"}
                message={"Este Ítem esta pendiente de sincronización con DIAN."}
              />
            }
            placement="bottom-end"
            interactive
            enterTouchDelay={1000}
          >
            <IconButton className={classes.iconButton}>
              <img
                src={require("./../../images/icons/cloud.png")}
                alt={"cloud"}
              />
            </IconButton>
          </Tooltip>
        );
      } else if (item.tipoestadodocumentoid === 3) {
        return (
          <Tooltip
            title={
              <TooltipMessageSecundary
                title={`Error DIAN - ${item.estadodocumentodescripcion}`}
                message={
                  item.reporterechazo !== true
                    ? menuInvoice?.messageDocumentNotReported ?? ""
                    : menuInvoice?.messageReportedDocument ?? ""
                }
                buttonText={"Reportar a soporte"}
                onClick={(e) => reportProblemComponent(e, item)}
                disabledButton={item.reporterechazo === true ? true : false}
              />
            }
            placement="bottom-end"
            interactive
            enterTouchDelay={1000}
            className={classes.tooltip}
            disableTouchListener={
              loadingListReport.find((c) => c === item.id) !== undefined
                ? true
                : false
            }
            disableHoverListener={
              loadingListReport.find((c) => c === item.id) !== undefined
                ? true
                : false
            }
          >
            {loadingListReport.find((c) => c === item.id) !== undefined ? (
              <CustomProgress size={20} aling={"end"} />
            ) : (
              <IconButton className={classes.iconButton}>
                <ReportIcon />
              </IconButton>
            )}
          </Tooltip>
        );
      } else {
        return null;
      }
    };

    /**
     *  Obtiene estilos para label valor documento
     * @param {*} item
     * @returns
     */
    const getStyleTotalItem = (item) => {
      if (item.id === documentIdSelected) return "totalInvoiceGridSelected";

      if (item.anulacion === true) return "totalInvoiceGridAnnulment";

      if (item.estadoid === "03") return "totalInvoiceGridExpired";

      if (item.estadoid === "02") return "totalInvoiceGridPending";

      return "totalInvoiceGridPaidout";
    };

    /**
     * Obtiene estilos para label estado documento
     * @param {*} item
     * @returns
     */
    const getStyleStatusItem = (item) => {
      if (documentIdSelected === item.id) return "stateInvoiceSelected";

      if (item.anulacion === true) return "stateInvoiceAnnulment";

      if (item.estadoid === "03") return "stateInvoiceExpired";

      if (item.estadoid === "02") return "stateInvoicePending";

      return "stateInvoicePaidout";
    };

    /**
     * Genera listado de facturas a mostrar en pantalla
     */
    const cardsFacturas = () => {
      const cards = invoices?.map((item, index) => (
        <div
          key={index}
          className={
            documentIdSelected === item.id
              ? "cardDetailInvoiceSelected"
              : "cardDetailInvoice"
          }
        >
          <Grid container>
            <Grid
              item
              lg={9}
              sm={12}
              className="nameCostumer"
              onClick={() => handleIdChange(item.id)}
            >
              {item.razonsocial}
            </Grid>

            <Grid item lg={3} sm={12}>
              <Grid container style={{ justifyContent: "flex-end" }}>
                {getStatusDIAN(item)}

                {loadingFavouriteFromList === item.id ? (
                  <CustomProgress size={20} aling={"end"} />
                ) : (
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => handleFavourite(item)}
                  >
                    {item.favorito ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container onClick={() => handleIdChange(item.id)}>
            <Grid item lg={6} sm={12} className="dataInvoiceGrid">
              {item?.fecha !== null && item?.fecha !== undefined
                ? formatDate(new Date(item.fecha), "dd/MM/yyyy hh:mm aaaa")
                : "-"}
            </Grid>
            <Grid item lg={6} sm={12} className={getStyleTotalItem(item)}>
              <FormatDecimal
                value={item.valortotal}
                prefix={"$"}
                size={"small"}
              />
            </Grid>
          </Grid>

          <Grid container onClick={() => handleIdChange(item.id)}>
            <Grid item lg={6} sm={12} className="dataNumberInvoice">
              {`Factura: ${item.numero} ${
                item.notacredito === true || item.notadebito === true
                  ? "- Editada"
                  : ""
              }`}
            </Grid>
            <Grid item lg={6} sm={12} className={getStyleStatusItem(item)}>
              {item.anulacion === true ? "Anulada" : item.estado}
            </Grid>
          </Grid>
        </div>
      ));

      return cards;
    };

    /**
     * Modal filtro reenvio documento
     */
    const modalMails = () => {
      return (
        <ModalFileForm
          email={email}
          isInvoiceMail={isInvoiceMail}
          isQuotationMail={false}
          data={mailData}
          type={isInvoiceMail ? "FA" : "NOT"}
        />
      );
    };

    /**
     * Modal filtros por estado documentos
     */
    const modalFilters = () => {
      return (
        <div>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                checked={filterData.Pagadas}
                onChange={handlecheckFilters}
                name="chkPagadas"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Pagadas
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                checked={filterData.Pendientes}
                onChange={handlecheckFilters}
                name="chkPendientes"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Por pagar
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                checked={filterData.Vencidas}
                onChange={handlecheckFilters}
                name="chkVencidas"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              En mora
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                onClick={() => handlecheckFilters}
                checked={filterData.Destacadas}
                onChange={handlecheckFilters}
                name="chkDestacadas"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Destacadas
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                onClick={() => handlecheckFilters}
                checked={filterData.Masivo}
                onChange={handlecheckFilters}
                name="chkMasivo"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Cargue masivo
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                onClick={() => handlecheckFilters}
                checked={filterData.Anulacion}
                onChange={handlecheckFilters}
                name="chkAnulacion"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Anuladas
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                onClick={() => handlecheckFilters}
                checked={filterData.Notadebito}
                onChange={handlecheckFilters}
                name="chkNotaDebito"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Notas débito
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <StyledCheckbox
                className={classes.letter}
                onClick={() => handlecheckFilters}
                checked={filterData.Notacredito}
                onChange={handlecheckFilters}
                name="chkNotaCredito"
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              Notas crédito
            </Grid>
            <Grid item lg={12} md={12} sm={12} className={classes.link}>
              <Link
                href="#"
                onClick={cleanFilter}
                variant="inherit"
                underline="none"
                className={classes.link}
              >
                Limpiar Filtro
              </Link>
            </Grid>
          </Grid>
        </div>
      );
    };

    /**
     * Panel selección filtro rango fechas
     */
    const modalDates = () => {
      return (
        <Fragment>
          <Grid container justify="center" alignItems="center">
            <Grid item lg={5} sm={5} className={classes.alignCenter}>
              Desde
            </Grid>
            <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
            <Grid item lg={6} sm={6} className={classes.alignCenter}>
              Hasta
            </Grid>
          </Grid>

          <Grid container justify="center" alignItems="center">
            <Grid item lg={6} sm={6} className={classes.alignCenter}>
              <div className="lineDivision"></div>
              <div className="spacingInvoiceMini"></div>
            </Grid>
            <Grid item lg={6} sm={6} className={classes.alignCenter}>
              <div className="lineDivision"></div>
              <div className="spacingInvoiceMini"></div>
            </Grid>
          </Grid>

          <Grid container justify="center" alignItems="center">
            <Grid item lg={5} sm={5}>
              <RcCalendar
                disablePast={false}
                disableFuture={true}
                value={datesTmp.Fechini}
                className={"colorCalendarRC"}
                id={"txtfechini"}
                onChange={handleDateInitial}
              />
            </Grid>
            <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
            <Grid item lg={6} sm={6}>
              <RcCalendar
                disablePast={false}
                disableFuture={true}
                value={datesTmp.Fechfin}
                className={"colorCalendarRC"}
                id={"txtfechfin"}
                onChange={handleDateFinal}
              />
            </Grid>
          </Grid>

          <div className="spacingInvoice"></div>
          <div className="lineDivision"></div>
          <div className="spacingInvoice"></div>
          <Grid container>
            <Grid item lg={6} sm={6} className={classes.alignCenter}>
              <button className="btnFilterGray" onClick={cleanFilterDate}>
                Limpiar Filtro
              </button>
            </Grid>
            <Grid item lg={6} sm={6} className={classes.alignCenter}>
              <button className="btnFilterBlue" onClick={filterDate}>
                Filtrar
              </button>
            </Grid>
          </Grid>
        </Fragment>
      );
    };

    /**
     * Modal alerta sincronizaión DIAN
     */
    const modalWarning = () => {
      return (
        <div>
          <div className="titleWarning">
            <b>Pendiente de Sincronización con DIAN</b>
          </div>
          <div className="detailWarning">
            Este ítem esta pendiente de sincronización con DIAN, el proceso se
            hará automaticamente
          </div>
          <div>
            <Grid container>
              <Grid item lg={1} md={1} sm={12}></Grid>
              <Grid item lg={10} md={10} sm={12}>
                <div className="bottonWarning" onClick="">
                  Más información
                </div>
              </Grid>
              <Grid item lg={1} md={1} sm={12}></Grid>
            </Grid>
          </div>
        </div>
      );
    };

    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#2F2E2E",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 280,
        fontSize: theme.typography.pxToRem(12),
      },
    }))(Tooltip);

    return (
      <div className={classes.letter} ref={urlInvoice}>
        <Fragment>
          {!!informationBulk && stateBulk !== 0 ? (
            <BulkLoadStatus
              handleBulkUpload={HandleBulkUpload}
              cargueId={informationBulk.id}
              cantidad={informationBulk.cantidad}
              procesadas={informationBulk.procesada}
              estado={stateBulkLoad(informationBulk.estadoCargue)}
              global={false}
              view={2}
              HandleBulkUpload={HandleBulkUpload}
            />
          ) : (
            ""
          )}
          <br />
          <Grid container spacing={1}>
            <Grid item lg={5} md={6} sm={12}>
              <Grid container>
                <Grid item lg={10} xs={12}>
                  <InputIcon
                    name={"txtFilter"}
                    label={"Buscar"}
                    value={txtFilter}
                    onChange={(e) => syncChangesFilter(e)}
                    onSearch={onSearch}
                    maxLength={100}
                    disabled={loadingGetdocuments === true}
                  />
                </Grid>
                <Grid item lg={2} sm={12}>
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Filtrar por fechas">
                      <IconButton
                        onClick={handleOpenModalDates}
                        disabled={loadingGetdocuments === true}
                      >
                        <EventIcon className={classes.buttonIcon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Filtrar por estado">
                      <IconButton
                        className={classes.buttonFilterStatus}
                        onClick={handleOpenModalChecks}
                        disabled={loadingGetdocuments === true}
                      >
                        <img
                          alt={"Filtrar"}
                          src={filterImage}
                          style={{ marginTop: "60%" }}
                        ></img>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>

              <div className="spacingInvoice" />
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <div style={{ overflow: "auto", height: "77.9vh" }}>
                    {loadingGetdocuments === true && invoices?.length === 0 ? (
                      <CustomProgress />
                    ) : (
                      cardsFacturas()
                    )}
                    {invoices?.length > 0 &&
                    invoices?.length < totaldocuments ? (
                      <Grid container justify="center" alignItems="center">
                        <Grid item lg={7} xs={9}>
                          <ButtonPrimary
                            onClick={loadMoreDocuments}
                            text={"Mostrar más..."}
                            loading={loadingGetdocuments === true}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {invoices.length === 0 && loadingGetdocuments !== true ? (
                      <div>No se encontraron facturas</div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={7} md={6} sm={12}>
              <div>
                <Grid container>
                  {loadingCreate ? (
                    <Grid container alignItems="center" justify="center">
                      <CustomProgress size={20} />
                    </Grid>
                  ) : (
                    <>
                      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <HtmlTooltip
                          title={
                            READING_MODE ? <TooltipMessageReadingMode /> : ""
                          }
                        >
                          <span>
                            <ButtonInvoice
                              text={"Crear nueva Factura"}
                              icon={<AddCircleRoundedIcon />}
                              onClick={openCreate}
                              disabled={loadingCreate || READING_MODE}
                              margin={"-10px"}
                            />
                          </span>
                        </HtmlTooltip>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <HtmlTooltip
                          title={
                            READING_MODE ? <TooltipMessageReadingMode /> : ""
                          }
                        >
                          <span>
                            <ButtonInvoice
                              text={"Carga masiva de facturas"}
                              icon={
                                <img
                                  src={require("./../../images/icons/Upload.png")}
                                  alt={"cloud"}
                                />
                              }
                              onClick={openBulkUpload}
                              disabled={
                                informationBulk !== null && stateBulk !== 0
                                  ? true
                                  : false || READING_MODE
                              }
                              margin={"-8px"}
                            />
                          </span>
                        </HtmlTooltip>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12}>
                    <InvoiceDetails
                      heightDiv={"2%"}
                      vista="Grid"
                      OpenMailModal={openMailModal}
                      OpenFileModal={openFileModal}
                      OpenEdit={editInvoice}
                      loadingEdit={loadingEdit}
                      updateDocumentStatus={updateDocumentStatus}
                      handleShowDetailNote={handleShowDetailNote}
                    ></InvoiceDetails>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Fragment>

        <PopoverModal
          handleClose={handleCloseModalChecks}
          open={Boolean(modalChecksAnchorEl)}
          component={modalFilters}
          width={191}
          popoverAnchorEl={modalChecksAnchorEl}
          showCloseButton={false}
        />

        <PopoverModal
          handleClose={handleCloseModalDates}
          open={Boolean(modalDatesAnchorEl)}
          component={modalDates}
          title={""}
          width={530}
          popoverAnchorEl={modalDatesAnchorEl}
          showCloseButton={false}
        />

        <PopoverModal
          handleClose={handleCloseModalWarning}
          open={Boolean(modalWarningAnchorEl)}
          component={modalWarning}
          width={252}
          popoverAnchorEl={modalWarningAnchorEl}
          showCloseButton={false}
          modalWarning={true}
        />

        <SimpleModal
          onClose={handleCloseModalMail}
          open={openModalMail}
          component={modalMails}
          title={isInvoiceMail ? "Envio de Factura" : "Envio de Nota"}
          width={365}
          showCloseButton={false}
        />

        <FileModal
          open={openModalFile}
          onCloseElement={handleCloseModalFile}
          closeText={"Cerrar"}
          paramFile={archivo}
          documentNonElectronic={documentNonElectronic}
        ></FileModal>

        <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleResponseModal}
        />

        <ResponseRedirectModal
          modalType={"success"}
          title={"Upss...!!!"}
          subtitle={
            "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
          }
          body={""}
          modalImage={"package"}
          open={modal}
          closeElement={"Skip"}
          onCloseElement={onCloseModalItem}
          claim={CLAIM_DOCUMENTS}
        />
        <ResponseModal
          modalType={modalLicenseResponse.modalType}
          title={modalLicenseResponse.title}
          body={modalLicenseResponse.body}
          modalImage={modalLicenseResponse.modalImage}
          open={modalLicenseResponse.open}
          textButton={modalLicenseResponse?.textButton}
          closeElement={modalLicenseResponse.closeElement}
          onCloseElement={handleCloseResponseModal}
          closeFromModalBody={modalLicenseResponse.closeFromModalBody}
        />

        {/* Modal confirmación  generar nota */}
        <SimpleModal
          onClose={handleCloseModalEdit}
          open={openModalEdit}
          title={""}
          width={800}
          noPadding={true}
          component={() => <InvoiceTypeNote onClick={confirmEditInvoice} />}
        />

        <SimpleModal
          onClose={handleCloseModalAnnulment}
          open={openModalAnnulment}
          title={""}
          width={800}
          noPadding={true}
          disabled={loadingNoteAnnulment}
          disableBackdropClick={loadingNoteAnnulment}
          component={() => (
            <InvoiceAnnulmentNote
              onClick={annulmentInvoice}
              number={dataInvoice?.numero ?? ""}
              loading={loadingNoteAnnulment}
              type={typeInvoice}
            />
          )}
        />
      </div>
    );
  }
);

InvoiceGrid.propTypes = {
  addChargeToInvoiceAction: PropTypes.func.isRequired,
  openModalChargeAction: PropTypes.func.isRequired,
  checkCertificateCompanyAction: PropTypes.func.isRequired,
  checkCertificateToEditInvoiceAction: PropTypes.func.isRequired,
  getNonElectronicDocumentFileAction: PropTypes.func.isRequired,
  getDocumentsAction: PropTypes.func.isRequired,
  getDocumentDetailAction: PropTypes.func.isRequired,
  updateDocumentAction: PropTypes.func.isRequired,
  updateDocumentFromListAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceReducer: state.invoiceReducer,
  sucessupdate: state.invoiceReducer.sucess,
  document: state.invoiceReducer.document,
  sendMailResponse: state.invoiceReducer.sendmail,
  totaldocuments: state.invoiceReducer.totaldocuments,
  valuesInvoiceReducer: state.valuesInvoiceReducer,
  loadingGetdocuments: state.invoiceReducer.loadingGetdocuments,
  loadingFavouriteFromList: state.invoiceReducer.loadingFavouriteFromList,
  informationBulk: state.invoiceReducer.informationBulkLoad,
  stepChange: state.invoiceReducer.stepChange,
  stateBulk: state.invoiceReducer.stateBulkLoad,
  reasonList: state.configReducer.reasonList,
  loadingReportDocument: state.invoiceReducer.loadingReportDocument,
  dataEmailReport: state.invoiceReducer.dataEmailReport,
  menuReducer: state.menuReducer,
  modal: state.invoiceReducer.openModalItem,
  licenseBase: state.invoiceReducer.licenseBase,
});

export default connect(mapStateToProps, {
  setStatusShowCartAction,
  addChargeToInvoiceAction,
  openModalChargeAction,
  addDiscountToInvoiceAction,
  calculeTotalNoteAction,
  checkCertificateCompanyAction,
  checkCertificateToEditInvoiceAction,
  getNonElectronicDocumentFileAction,
  getDocumentsAction,
  getDocumentDetailAction,
  updateDocumentAction,
  updateDocumentFromListAction,
  getAdditionalDataAction,
  changeStepAction,
  createNoteAnnulmentAction,
  sendMailReportDocumentAction,
  getDataEmailReportAction,
  setTypeNoteAction,
  cleanDocumentDetailAction,
  updateOpenModalItem,
  cleanLicenseBaseAction,
  setDefaultFilterAction,
})(InvoiceGrid);
