import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { getRoleOfLoggedUser } from '../../actions/authActions';
import { FormatDecimal } from '../../components/common/formatDecimal';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.sixthBackgroundColor,
    color: theme.palette.primaryColor,
  },
  avatar: {
  },
  header: {
    color: theme.palette.primaryColor,
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: '1rem !important',
    fontWeight: 'bold',
  },
  subheader: {
    color: theme.palette.primaryColor
  },
  icon: {
    color: theme.palette.secundaryColor
  },
  iconAction: {
    color: theme.palette.primaryColor
  },
  bodysubheader: {
    color: theme.palette.primaryColor,
    fontSize: '0.9rem !important',
  },
  valueCurrency: {
    color: theme.palette.primaryColor,
  },
  valuePercentage: {
    color: theme.palette.primaryColor,
  },
}));

export default function ChargeCard(props) {
  const { data, onDelete, onLoad } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}
        avatar={
          ''
        }
        action={data.esglobal === false
          ? <Fragment>
            {
              (getRoleOfLoggedUser())
                ? <div>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton aria-label="Editar">
                      <CreateIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="No tiene permisos sobre esta acción">
                    <IconButton aria-label="Eliminar">
                      <DeleteIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                </div>
                : <div>
                  <Tooltip title="Editar">
                    <IconButton aria-label="Editar" onClick={onLoad}>
                      <CreateIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton aria-label="Eliminar" onClick={onDelete}>
                      <DeleteIcon className={classes.iconAction} />
                    </IconButton>
                  </Tooltip>
                </div>
            }
          </Fragment>
          : null
        }
        classes={{
          title: classes.title,
        }}
        title={
          data.descripcion
        }
        subheader={
          <div className={classes.bodysubheader}>
            <div className={
              data.tipocargo === 1
                ? classes.valueCurrency
                : classes.valuePercentage
            }>
              {
                data.tipocargo === 1
                  ? <FormatDecimal
                    value={data.valor}
                    prefix={"$"}
                  />
                  : <FormatDecimal
                    value={data.valor}
                    suffix={"%"}
                    size={"inherit"}
                  />
              }
            </div>
          </div>
        }
      />
    </Card>
  );
}