import React, { useRef } from "react";

import { useDispatch } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import { CustomersList } from "../../../customers/customersList";

import { EquivalentDocFormFields, SelectedCustomerSection } from "./";

import { addCustomerInEquivalentDocument } from "../../../../actions/equivalentDocAction";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "74vh",
  },
  containerForm: {
    height: "73vh",
    marginTop: "0px",
    overflow: "hidden auto",
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
    },
  },
}));

export const EquivalentDocumentForm = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const scrollRef = useRef(null);

  /**
   * * Maneja la selección de un cliente en el formulario de documento equivalente.
   * @param {number} id - El ID del cliente seleccionado.
   */
  const handleSelectionCustomer = (id) => {
    dispatch(addCustomerInEquivalentDocument(id));
  };

  /**
   * * Desplaza la vista hacia la parte superior de la página de manera suave.
   */
  const handleScrollTop = () => {
    scrollRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`${classes.root} animate__animated animate__fadeInRight animate__faster`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <CustomersList handleSelection={handleSelectionCustomer} type={0} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className={classes.containerForm} ref={scrollRef}>
            <SelectedCustomerSection />
            <div className="lineDivisionWithMargin" />
            <EquivalentDocFormFields handleScrollTop={handleScrollTop} />
          </div>
        </Grid>
        {children}
      </Grid>
    </div>
  );
};
