import { types } from "../types/types";

const initialState = {
    charges: [],
    loadingGetCharges: false,
    loadingSaveCharge: false,
    activeDocumentCharge: null

}


export const newChargeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CHARGES_LIST:
            return {
                ...state,
                charges: action.payload
            }
        case types.ADD_NEW_CHARGE:
            return {
                ...state,
                charges: [...state.charges, action.payload]
            }
        case types.LOADING_CHARGES_LIST:
            return {
                ...state,
                loadingGetCharges: action.payload
            }
        case types.LOADING_ADD_CHARGE:
            return {
                ...state,
                loadingSaveCharge: action.payload
            }
        case types.LOAD_DOCUMENT_CHARGE:
            return {
                ...state,
                activeDocumentCharge: action.payload
            }
        case types.CLEAN_CHARGES_DATA:
            return {
                ...initialState
            }
        default:
            return state;
    }
}