import React from "react"
import { InputAdornment, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { theme } from "../../utils/muiTheme";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1)
    },
    tooltip: {
        color: '#52D769',
    },
    icon: {
        color: theme.palette.seventhColor
    }
}));


const CustomInput = withStyles({
    root: {

        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.seventhColor, //verde
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.seventhColor, //gris
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: theme.palette.seventhColor, //gris
        },
        '& input.Mui-disabled': {
            color: 'rgba(255,255,255,0.5);'
        },

        '& .MuiInputBase-input': {
            color: '#FFFF'
        }

    },
})(TextField);



const InputSearch = ({ ...props }) => {
    const { id, onChange, value, label, disabled, validator, validateOptions, hidden, name, onBlur, maxLength, nameValidator, onSearch } = props;
    const classes = useStyles();

    let errorValidator = validator
        ? validator.message(
            !!nameValidator ? nameValidator : label,
            value,
            validateOptions
        )
        : "";


    if (!!hidden) return null

    return (
        <div className={classes.root} >
            <CustomInput
                fullWidth
                disabled={disabled === true ? true : false}
                label={label}
                id={id}
                name={name}
                value={value}
                onKeyPress={onkeypress}
                onChange={onChange}
                error={!!errorValidator ? true : false}
                helperText={errorValidator}
                onBlur={onBlur}
                autoComplete='off'
                inputProps={{ maxLength: maxLength }}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title="Realizar busqueda...">
                                <IconButton onClick={onSearch} disabled={disabled === true ? true : false}>
                                    <SearchIcon className={classes.icon} />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>,
                }}
            />
        </div>
    );
}

InputSearch.propTypes = {

}

export default InputSearch
