import { Grid } from "@material-ui/core";
import React from "react";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import { useSelector, useDispatch } from "react-redux";
import { createDocumentSupportAction } from "../../../actions/documentSupportAction";
import { generateRequestSupport } from "../../../helpers/generateRequestDocument";
import { validateDocument } from "../../../helpers/documentValidate";

const ButtonSendDocument = ({
  readResponseSendDocumentSupport,
  readErrorSendInvoice,
  setActiveStep,
  setLoading,
}) => {
  const dispatch = useDispatch();

  const { documentData, favorite } = useSelector(
    (state) => state.documentSupportReducer
  );

  const totals = useSelector((state) => state.documentCartReducer);

  const handleSaveDocument = () => {
    if (validateDocument(totals, totals?.cartDocumentSupport)) {
      setLoading(true);
      let document = generateRequestSupport(totals, documentData, favorite);
      dispatch(
        createDocumentSupportAction(
          document,
          readResponseSendDocumentSupport,
          readErrorSendInvoice,
          setActiveStep
        )
      );
    }
    // ConfirmAlert(
    // 	`¿Estás seguro de guardar y enviar la factura?`,
    // 	deleteData,
    // 	MESSAGE_NEXT_CONFIRM
    // );
  };

  return (
    <Grid
      container
      style={{ marginTop: 5 }}
      alignItems="stretch"
      justify="center"
    >
      <Grid item xl={6} lg={8} md={8} xs={12}>
        <ButtonPrimary
          type="button"
          onClick={handleSaveDocument}
          text={"Enviar Documento"}
        />
      </Grid>
    </Grid>
  );
};

export default ButtonSendDocument;
