import React from "react";
import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

export const ExcelAccountant = ({ element, filename, data }) => {
  return (
    <>
      <ExcelFile element={element} filename={filename}>
        <ExcelSheet data={data} name="Employees">
          <ExcelColumn
            label="Tipo Identificación"
            value="tipoidentificacion"
          />
          <ExcelColumn
            label="Nombre tipo documento"
            value="nombretipodocumento"
          />
          <ExcelColumn label="Documento" value="documento" />
          <ExcelColumn label="Telefono" value="telefono" />
          <ExcelColumn label="Razon Social" value="razonsocial" />
          <ExcelColumn
            label="Correo electronico"
            value="correoelectronico"
          />
          <ExcelColumn label="Dirección Principal" value="direccion" />
          <ExcelColumn label="Tipo documento" value="tipodocumento" />
          <ExcelColumn label="Numero" value="numero" />
          <ExcelColumn label="Fecha" value="fecha" />
          <ExcelColumn label="Valor bruto" value="valorbruto" />
          <ExcelColumn
            label="Total descuento comercial"
            value="totaldescuentocomercial"
          />
          <ExcelColumn label="Valor iva" value="valoriva" />
          <ExcelColumn label="Valor inc" value="valorinc" />
          <ExcelColumn label="Valor total" value="valortotal" />
          <ExcelColumn
            label="Total descuento financiero"
            value="totaldescuentofinanciero"
          />
          <ExcelColumn label="Total cargo" value="totalcargo" />
          <ExcelColumn label="Valor Bolsas" value="valorbolsas" />
          <ExcelColumn label="Valor ReteFuente" value="valorretfte" />
          <ExcelColumn label="Valor ReteICA" value="valorretica" />
          <ExcelColumn label="Valor ReteIVA" value="valorretiva" />
          <ExcelColumn label="Valor a pagar" value="valorapagar" />
          <ExcelColumn label="Forma de pago" value="formapago" />
          <ExcelColumn label="Medio de pago" value="mediopago" />
          <ExcelColumn
            label="Fecha Vencimiento"
            value="fechavencimiento"
          />
          <ExcelColumn label="Marca pagado" value="marcapagado" />
          <ExcelColumn label="Cufe" value="cufe" />
          <ExcelColumn label="DatoSqr" value="datosqr" />
          <ExcelColumn label="Estado Dian" value="estadodian" />
          <ExcelColumn label="Fecha acusedian" value="fechaacusedian" />
          <ExcelColumn
            label="Documento referencia"
            value="documentoref"
          />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
