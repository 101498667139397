import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";

import SelectForm from "../../components/select/select.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import { useFormComplete } from "../../hooks/useFormComplete";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  title: {
    padding: "6px 10px 20px 10px",
  },
}));

export const RejectInvoiceForm = ({ handleRejectDocument }) => {
  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const { values, handleInputChange } = useFormComplete({
    rejectionReason: "",
  });

  const { rejectionReason } = values;
  const { rejectionReasons } = useSelector((s) => s.documentReceivedReducer);

  /**
   * VAlida formulario y confirma petición
   * @param {*} e
   * @returns
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
      return;
    }

    handleRejectDocument(rejectionReason);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container justify="center">
        <Grid item lg={12} xs={12} className={classes.title}>
          ¿Estás seguro de que deseas rechazar esta factura? Por favor indícanos
          el motivo.
        </Grid>
        <Grid item lg={12} xs={12}>
          <SelectForm
            label={"Selecciona el motivo *"}
            name="rejectionReason"
            onChange={(e) => handleInputChange(e, "rejectionReason")}
            options={rejectionReasons}
            value={rejectionReason}
            validator={validator}
            validateOptions={"required"}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <ButtonPrimary text={"Confirmar"} type={"submit"} />
        </Grid>
      </Grid>
    </form>
  );
};
