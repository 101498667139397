import { types } from '../types/types';
import isEmpty from '../utils/isEmpty'

const initialState = {
    editChargeDocument: false,
    openModalCharge: false,
    editDiscountDocument: false,
    openModalDiscount: false,
    showCartInvoice: false,
    isValidDocument: true,
    roundValuesStatus: true
}


export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DOCUMENT_DETAIL:
            return {
                ...state,
                documentDetail: orderbyDate(action.payload)
            };
        case types.GET_TOTAL_DETAIL:
            return {
                ...state,
                totalDetail: action.payload
            };

        case types.GET_CHARGES_DOCUMENT:
            return {
                ...state,
                documentCharges: action.payload
            };

        case types.LOAD_CHARGE_DOCUMENT:
            return {
                ...state,
                loadChargeDocument: action.payload,
                editChargeDocument: !isEmpty(action.payload) ? true : false,
            };

        case types.UPDATE_CHARGE_DOCUMENT:
            return {
                ...state,
                updateChargeDocumentResponse: action.payload,
            };

        case types.OPEN_MODAL_CHARGE:
            return {
                ...state,
                openModalCharge: action.payload
            }

        case types.GET_DISCOUNTS_DOCUMENT:
            return {
                ...state,
                documentDiscounts: action.payload
            }

        case types.LOAD_DISCOUNT_DOCUMENT:
            return {
                ...state,
                loadDiscountDocument: action.payload,
                editDiscountDocument: !isEmpty(action.payload) ? true : false,
            };

        case types.OPEN_MODAL_DISCOUNT:
            return {
                ...state,
                openModalDiscount: action.payload
            }

        case types.UPDATE_DISCOUNT_DOCUMENT:
            return {
                ...state,
                updateDiscountDocumentResponse: action.payload
            }

        case types.SHOW_CART_INVOICE:
            return {
                ...state,
                showCartInvoice: action.payload
            }

        case types.DOCUMENT_VALID:
            return {
                ...state,
                isValidDocument: action.payload
            }
        case types.ROUND_VALUES_STATUS:
            return {
                ...state,
                roundValuesStatus: action.payload
            }
        default:
            return state
    }
}

//ordena listado
const orderbyDate = (list) => {

    list = list.sort((x, y) => {
        let a = x.dateAdded;
        let b = y.dateAdded;
        return a - b;
    })

    return list;

}