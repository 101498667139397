import { saveAs } from 'file-saver';

/**
 * metodo encargado de construir el archivo excel
 */
export const convertBase64ToExcel = async (data) => {

    var contentType = 'application/ms-excel.sheet.macroEnabled.12';
    var blob1 = b64toBlob(data, contentType);
    var blobUrl1 = URL.createObjectURL(blob1);

    saveAs(blobUrl1, 'Plantilla.xlsm')

}

const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
};