import { DIGITS_AFTER_DECIMAL, DIGITS_BEFORE_DECIMAL } from "../config/config";

export const validateNumberFormat = (
  value,
  beforeDecimal = DIGITS_BEFORE_DECIMAL,
  afterDecimal = DIGITS_AFTER_DECIMAL
) => {
  if (!value || value === "") return "";
  const separator = ".";

  const point =
    value.indexOf(separator) !== -1 ? value.indexOf(separator) : value.length;

  let beforeDigits = value.substring(0, point).substring(0, beforeDecimal);
  let afterDigits = value
    .substring(point + 1, value.length)
    .substring(0, afterDecimal);
  const validNumber = `${beforeDigits}${
    afterDigits.length > 0 ? `${separator}${afterDigits}` : ``
  }`;

  return validNumber;
};

export const validateNumberPorcentaje = (
  value,
  beforeDecimal = 3,
  afterDecimal = DIGITS_AFTER_DECIMAL
) => {
  if (!value || value === "") return "";

  const separator = ".";

  const point =
    value.indexOf(separator) !== -1 ? value.indexOf(separator) : value.length;

  let beforeDigits = value.substring(0, point).substring(0, beforeDecimal);
  beforeDigits =
    parseFloat(beforeDigits) > 100
      ? beforeDigits.substring(0, 2)
      : beforeDigits;

  let afterDigits = value
    .substring(point + 1, value.length)
    .substring(0, afterDecimal);
  afterDigits = parseFloat(beforeDigits) >= 100 ? "" : afterDigits;

  const validNumber = `${beforeDigits}${
    afterDigits.length > 0 ? `${separator}${afterDigits}` : ``
  }`;

  return validNumber;
};

export const roundDecimalValue = (value, precision = DIGITS_AFTER_DECIMAL) => {
  precision = precision === undefined ? 0 : precision;
  value = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  let newValue = parseFloat(value.toFixed(precision));
  return parseFloat(newValue);
};

export const roundValue = (value, precision = 0) => {
  if (precision === 0)
    return +(Math.round(value + `e+${precision}`) + `e-${precision}`);

  return value;
};
