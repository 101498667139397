import React from "react";
import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import { FormatDecimal } from "../../../components/common/formatDecimal";
// import CollapsePanel from "../../../components/collapse/collapsestyles.component";

import ArticlesDocuSupport from "../detailSupportComponet/ArticlesDocuSupport";
// import GetInfoAditional from "../detailSupportComponet/GetInfoAditional";
import DetailRewiewDocument from "./DetailRewiewDocument";

import { formatDate } from "../../../utils/general";

const useStyles = makeStyles((theme) => ({
  containerSection: {
    overflow: "auto",
    height: "65.1vh",
    width: "100%",
    overflowX: "hidden",
    backgroundColor: "#4B4B4B",
    borderRadius: 5,
    padding: 20,
    color: "white",
  },
  icon: {
    color: "#2CC63E",
    marginRight: 5,
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  dataDocument: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "500",
    wordBreak: "break-all",
    display: "flex",
  },
  gridData: {
    display: "grid",
    width: "100%",
  },
  label: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 12,
  },
  grayline: {
    height: 1,
    width: "100%",
    borderBottom: "1px solid gray",
    marginTop: 5,
  },
  containernote: {
    backgroundColor: "#5E5E5E",
    borderRadius: 5,
    padding: "10px 5px",
    marginTop: 15,
  },
}));

const ReviewDocumentUpdate = () => {
  const classes = useStyles();

  const {
    opdetalledocumento,
    valorapagar,
    motivonota,
    observacionesnota,
  } = useSelector((state) => state.documentCartReducer);

  const { detailDocumentSupport } = useSelector(
    (state) => state.documentSupportReducer
  );

  return (
    <>
      <Grid container direction="row" alignItems="stretch" justify="center">
        <Grid item xl={6} lg={8} md={8} xs={12}>
          <div className={classes.containerSection}>
            <Grid container>
              <Grid
                item
                xs={6}
                className="detailSupportNumber"
                style={{ textAlign: "start" }}
              ></Grid>
              <Grid
                item
                xs={6}
                className="dataInvoiceGrid"
                style={{ textAlign: "end" }}
              >
                {formatDate(detailDocumentSupport.fecha, "dd/MM/yyyy")}
              </Grid>
            </Grid>
            <div className="spacingInvoice" />
            <div className="lineDivision" />
            <Grid container spacing={1} className={classes.containernote}>
              <Grid item lg={6} xs={12}>
                <div className={classes.gridData} style={{ paddingRight: 10 }}>
                  <span className={classes.label}>Factura Asociada</span>
                  <span>
                    {detailDocumentSupport.prefijo}
                    {detailDocumentSupport.consecutivo}
                  </span>
                  <div className={classes.grayline} />
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div className={classes.gridData} style={{ paddingRight: 10 }}>
                  <span className={classes.label}>Tipo de Nota</span>
                  <span>
                    {motivonota.tipo === "ANU" ? "Anulación" : "Ajuste"}
                  </span>
                  <div className={classes.grayline} />
                </div>
              </Grid>
              <Grid item lg={12} xs={12}>
                <div className={classes.gridData} style={{ paddingRight: 10 }}>
                  <span className={classes.label}>Motivo de la Edición</span>
                  <span>{motivonota.descripcion ?? "-"}</span>
                  <div className={classes.grayline} />
                </div>
              </Grid>
              <Grid item lg={12} xs={12}>
                <div className={classes.gridData} style={{ paddingRight: 10 }}>
                  <span className={classes.label}>Observaciones</span>
                  <span>{observacionesnota}</span>
                  <div className={classes.grayline} />
                </div>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item lg={4} md={4} sm={12} style={{ display: "grid" }}>
                <span className="totalRevision">
                  <FormatDecimal
                    value={valorapagar}
                    prefix={"$"}
                    size={"small"}
                  />
                </span>
                <span className="commentsRevision">
                  Valor total impuestos incluidos
                </span>
              </Grid>
            </Grid>
            <div className="lineDivision" />
            <DetailRewiewDocument />
            <div className="spacingInvoice" />
            <div className="greenTitleSupport">
              Servicios ( {opdetalledocumento.length} )
            </div>
            <div className="spacingInvoice" />
            {opdetalledocumento.map((detail) => (
              <ArticlesDocuSupport key={detail.id} detail={detail} />
            ))}
            <div className="spacingInvoice" />

            {/* <CollapsePanel
              component={GetInfoAditional}
              title={"Información Adicional"}
              titleOpen={"Información Adicional"}
              open={"panel"}
              backgroundColorSummary={{
                backgroundColor: "#2CC63E",
                color: "#000000",
              }}
              backgroundColorDetail={{}}
              type="showinfo"
            /> */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewDocumentUpdate;
