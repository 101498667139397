/**
 * Homologa codigo tipo identificación DIAN a prefijo
 * @param {*} type
 * @returns
 */
export const homologateTypeIdentification = (type) => {
  switch (type) {
    case "11":
      return "R.C.";
    case "12":
      return "T.I.";
    case "13":
      return "C.C.";
    case "21":
      return "T.E.";
    case "22":
      return "C.E.";
    case "31":
      return "N.I.T";
    case "41":
      return "P.A.";
    case "42":
      return "D.I.E";
    case "47":
      return "P.E.P.";
    case "50":
      return "N.I.T de otro pais.";
    case "91":
      return "NUIP";
    default:
      return "";
  }
};

/**
 * Homologa codigo tipo identificación DIAN a prefijo
 * @param {*} type
 * @returns
 */
export const homologateDescripcionIdentification = (type) => {
  switch (type.replaceAll(" ", "")) {
    case "RC":
      return "11";
    case "TI":
      return "12";
    case "CC":
      return "13";
    case "TE":
      return "21";
    case "CE":
      return "22";
    case "NIT":
      return "31";
    case "PA":
      return "41";
    case "DIE":
      return "42";
    case "PEP":
      return "47";
    // case "50":
    // 	return "N.I.T de otro pais.";
    default:
      return "";
  }
};

/**
 * Homologa codigo tipo regimen DIAN
 * @param {*} type
 * @returns
 */
export const homologateTypeRegimen = (type) => {
  switch (type) {
    case "04":
      return "Régimen Simple";
    case "05":
      return "Régimen Ordinario";
    case "48":
      return "Impuesto sobre las ventas – IVA";
    case "49":
      return "No responsable de IVA";
    default:
      return "";
  }
};

/**
 * Homologa codigo medio pago DIAN
 * @param {*} type
 * @returns
 */
export const homologatePaymentMethod = (type) => {
  switch (type) {
    case "1":
      return "Instrumento no definido";
    case "2":
      return "Crédito ACH";
    case "3":
      return "Débito ACH";
    case "4":
      return "Reversión débito de demanda ACH";
    case "5":
      return "Reversión crédito de demanda ACH";
    case "6":
      return "Crédito de demanda ACH";
    case "7":
      return "Crédito de demanda ACH";
    case "9":
      return "Clearing Nacional o Regional";
    case "10":
      return "Efectivo";
    case "11":
      return "Reversión Crédito Ahorro";
    case "12":
      return "Reversión Débito Ahorro";
    case "13":
      return "Crédito Ahorro";
    case "14":
      return "Débito Ahorro";
    case "15":
      return "Bookentry Crédito";
    case "16":
      return "Bookentry Débito";
    case "17":
      return "Desembolso Crédito (CCD)";
    case "18":
      return "Desembolso (CCD) débito";
    case "19":
      return "Crédito Pago negocio corporativo (CTP)";
    case "20":
      return "Cheque";
    case "21":
      return "Proyecto Bancario";
    case "22":
      return "Proyecto bancario certificado";
    case "23":
      return "Cheque bancario de gerencia";
    case "24":
      return "Nota cambiaria esperando aceptación";
    case "25":
      return "Cheque certificado";
    case "26":
      return "Cheque Local";
    case "27":
      return "Débito Pago Neogcio Corporativo (CTP)";
    case "28":
      return "Crédito Negocio Intercambio Corporativo (CTX)";
    case "29":
      return "Débito Negocio Intercambio Corporativo (CTX)";
    case "30":
      return "Transferecia Crédito";
    case "31":
      return "Transferencia Débito";
    case "32":
      return "Desembolso Crédito plus (CCD+)";
    case "33":
      return "Desembolso Débito plus (CCD+)";
    case "34":
      return "Pago y depósito pre acordado (PPD)";
    case "35":
      return "Desembolso Crédito (CCD)";
    case "36":
      return "Desembolso Débito (CCD)";
    case "37":
      return "Pago Negocio Corporativo Ahorros Crédito (CTP)";
    case "38":
      return "Pago Negocio Corporativo Ahorros Débito (CTP)";
    case "39":
      return "Crédito Intercambio Corporativo (CTX)";
    case "40":
      return "Débito Intercambio Corporativo (CTX)";
    case "41":
      return "Desembolso Crédito plus (CCD+)";
    case "42":
      return "Consignación bancaria";
    case "43":
      return "Desembolso Débito plus (CCD+)";
    case "44":
      return "Nota cambiaria";
    case "45":
      return "Transferencia Crédito Bancario";
    case "46":
      return "Transferencia Débito Interbancario";
    case "47":
      return "Transferencia Débito Bancaria";
    case "48":
      return "Tarjeta Crédito";
    case "49":
      return "Tarjeta Débito";
    case "50":
      return "Postgiro";
    case "51":
      return "Telex estándar bancario";
    case "52":
      return "Pago comercial urgente";
    case "53":
      return "Pago Tesorería Urgente";
    case "60":
      return "Nota promisoria";
    case "61":
      return "Nota promisoria firmada por el acreedor";
    case "62":
      return "Nota promisoria firmada por el acreedor, avalada por el banco";
    case "63":
      return "Nota promisoria firmada por el acreedor, avalada por un tercero";
    case "64":
      return "Nota promisoria firmada pro el banco";
    case "65":
      return "Nota promisoria firmada por un banco avalada por otro banco";
    case "66":
      return "Nota promisoria firmada";
    case "67":
      return "Nota promisoria firmada por un tercero avalada por un banco";
    case "70":
      return "Retiro de nota por el por el acreedor";
    case "71":
      return "Bonos";
    case "72":
      return "Vales";
    case "74":
      return "Retiro de nota por el por el acreedor sobre un banco";
    case "75":
      return "Retiro de nota por el acreedor, avalada por otro banco";
    case "76":
      return "Retiro de nota por el acreedor, sobre un banco avalada por un tercero";
    case "77":
      return "Retiro de una nota por el acreedor sobre un tercero";
    case "78":
      return "Retiro de una nota por el acreedor sobre un tercero avalada por un banco";
    case "91":
      return "Nota bancaria transferible";
    case "92":
      return "Cheque local transferible";
    case "93":
      return "Giro referenciado";
    case "94":
      return "Giro urgente";
    case "95":
      return "Giro formato abierto";
    case "96":
      return "Método de pago solicitado no usado";
    case "97":
      return "Clearing entre partners";
    default:
      return "Otro";
  }
};

/**
 * Homologa codigo tipo regimen DIAN
 * @param {*} type
 * @returns
 */
export const homologateWatToPay = (type) => {
  switch (type) {
    case "1":
      return "Contado";
    case "2":
      return "Crédito";
    default:
      return "";
  }
};

/**
 * Homologa codigo motivo rechazo DIAN
 * @param {*} type
 * @returns
 */
export const homologateRejectReason = (type) => {
  switch (type) {
    case "01":
      return "Documento con inconsistencias";
    case "02":
      return "Mercancía no entregada totalmente";
    case "03":
      return "Mercancía no entregada parcialmente";
    case "04":
      return "Servicio no prestado";
    default:
      return "";
  }
};

/**
 * Homologa codigo motivo rechazo DIAN
 * @param {*} type
 * @returns
 */
export const homologateTypeDocument = (type) => {
  switch (type) {
    case "01":
      return "Factura de Venta Nacional";
    case "02":
      return "Factura de Exportación";
    case "03":
      return "Factura por Contingencia Facturador";
    case "04":
      return "Factura por Contingencia DIAN";
    case "05":
      return "Documento Soporte";
    case "91":
      return "Nota Crédito";
    case "92":
      return "Nota Débito";
    case "95":
      return "Nota de Ajuste al Documento Soporte";
    default:
      return "";
  }
};