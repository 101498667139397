import { DIGITS_AFTER_DECIMAL_DOC } from "../config/config";

import generateId from "../utils/generateId";

import { roundDecimalValue } from "../utils/managementNumber";

//Genera item opdetalledocumento.
export const generateDocumentItem = (item) => {
  let itemDocument = {
    id: !item.detalleid ? generateId() : item.detalleid,
    codigo: item.codigo,
    descripcion: item.nombrearticulo,
    imagen: item.imagen,
    color: item.color,
    nombrearticulo: item.nombrearticulo,
    cantidad: !item.cantidad ? 1 : item.cantidad,
    valorunitario: item.valor,
    valorneto: item.neto,
    valornetoarticulo: item.neto,
    porcentajedescuento: item.porcentajedescuento ?? 0,
    valordescuento: item.valordescuento ?? 0,
    tipodescuento: item.tipodescuento ?? 2,
    dianunidadmedidaid: item.dianunidadmedidaid,
    articuloid: item.id,
    porcentajeiva: item.valoriva,
    porcentajeinc: item.valorinc ?? 0,
    porcentajeicui: item.valoricui ?? 0,
    porcentajeadv: item.valoradv ?? 0,
    valoribua: item.valoribua ?? null,
    cantidadmililitros: item.cantidadmililitros ?? null,
    pesogramos: item.weightInGramsInpp ?? null,
    tasauvtimp: parseFloat(item.taxRateUvt) ?? null,
    tipobebida: item.drinkTypeIcl ?? null,
    centimetroscubicos: item.cubicCentimetersIcl ?? null,
    gradosalcohol: item.alcoholLevelIcl ?? null,
    costo: item.costo ?? 0,
    aplicaregalo: item.aplicaregalo ?? false,
    esregalo: item.esregalo ?? false,
    asumeresponsabilidad: item.asumeresponsabilidad ?? "",
    valorreferencia: item.valorreferencia ?? "",
    valoreditable: item.precioeditable ?? false,
    total: 0,
    totalreal: 0,
    subtotal: 0,
    subtotalreal: 0,
    valorbruto: 0,
    totaldescuento: 0,
    totaldescuentoreal: 0,
    totaliva: 0,
    totalivareal: 0,
    totalinc: 0,
    totalincreal: 0,
    totalicui: 0,
    totalicuireal: 0,
    totaladv: 0,
    totaladvreal: 0,
    prorrateo: 0,
    valordescuentocomercial: 0,
    idretfte: item.idretfte ?? 0,
    retfte: !!item.retfte ? item.retfte : null,
    baseretfte: 0,
    tarifaretfte: 0,
    valorretfte: 0,
    idretica: item.idretica ?? 0,
    retica: item.idretica ? item.retica : null,
    baseretica: 0,
    tarifaretica: 0,
    valorretica: 0,
    idretiva: item.idretiva ?? 0,
    retiva: !!item.idretiva ? item.retiva : null,
    baseretiva: 0,
    tarifaretiva: 0,
    valorretiva: 0,
    documentoid: 0,
    categorias: !!item.adcategoriaarticulo
      ? item.adcategoriaarticulo.map((c) => ({
          id: c.categoria.id,
          nombre: c.categoria.nombre,
          color: c.categoria.color,
          imagen: c.categoria.imagen,
        }))
      : [],
  };

  return itemDocument;
};

/**
 * Calcula totales por item, base, impuestos, descuentos
 * @param {*} item Información articulo
 */
export const calculateItemTotals = (item, digitsAfterDecimalDoc) => {
  try {
    let cValorBruto = item.valorneto * item.cantidad;
    let cValorDescuento = item.valordescuento;
    let cPorcentajeDescuento = item.porcentajedescuento;
    let cValorDescuentoFinal =
      item.tipodescuento === 2 ? roundDecimalValue(cValorBruto * (cPorcentajeDescuento / 100), digitsAfterDecimalDoc) : cValorDescuento;
    let cValorSubtotal = roundDecimalValue(cValorBruto - cValorDescuentoFinal, digitsAfterDecimalDoc);
    let cValorIva = roundDecimalValue(cValorSubtotal * (item.porcentajeiva / 100), digitsAfterDecimalDoc);
    let cValorInc = roundDecimalValue(cValorSubtotal * (item.porcentajeinc / 100), digitsAfterDecimalDoc);
    let cValorIcui = roundDecimalValue(cValorSubtotal * (item.porcentajeicui / 100), digitsAfterDecimalDoc);
    let cValorAdv = roundDecimalValue(cValorSubtotal * (item.porcentajeadv / 100), digitsAfterDecimalDoc);
    let cValorIbua =
      item.cantidadmililitros === null
        ? null
        : roundDecimalValue((item.cantidadmililitros * item.valoribua) / 100, digitsAfterDecimalDoc) * item.cantidad;

    let cValorIcl =
      item.gradosalcohol === null ? null : roundDecimalValue(item.tipobebida * item.gradosalcohol, digitsAfterDecimalDoc) * item.cantidad;

    let cValorInpp = item.pesogramos === null ? null : roundDecimalValue(item.pesogramos * item.tasauvtimp, digitsAfterDecimalDoc) * item.cantidad;
    let cTotal = roundDecimalValue(
      cValorSubtotal + cValorIva + cValorInc + cValorIcui + cValorAdv + cValorIbua + cValorIcl + cValorInpp,
      digitsAfterDecimalDoc
    );

    item.totaldescuento = cValorDescuentoFinal;
    item.valorbruto = cValorBruto;
    item.subtotal = cValorSubtotal;
    item.totaliva = cValorIva;
    item.totalinc = cValorInc;
    item.totalicui = cValorIcui;
    item.totaladv = cValorAdv;
    item.totalibua = cValorIbua;
    item.totalicl = cValorIcl;
    item.totalinpp = cValorInpp;
    item.total = cTotal;

    item.totaldescuentoreal = cValorDescuentoFinal;
    item.subtotalreal = cValorSubtotal;
    item.totalivareal = cValorIva;
    item.totalincreal = cValorInc;
    item.totalicuireal = cValorIcui;
    item.totaladvreal = cValorAdv;
    item.totalibuareal = cValorIbua;
    item.totaliclreal = cValorIcl;
    item.totalinppreal = cValorInpp;
    item.totalreal = cTotal;
  } catch (err) {
    console.error(`Ha ocurrido un error calculando valores de item ${item.codigo}`, err);
    throw err;
  }
};

//Calcula valor impuestos y totales para Factura
export const calculateTotalDocument = (detailDocument, charges = [], discounts = [], retiva = null, retica = null, round, digitsAfterDecimalDoc) => {
  try {
    //Precison de redondeo
    let precision;
    if (round) {
      precision = 0;
    } else {
      precision = digitsAfterDecimalDoc > 2 ? 2 : digitsAfterDecimalDoc;
    }

    for (let item of detailDocument) {
      calculateItemTotals(item, digitsAfterDecimalDoc);
    }

    //Agrega campo nuevo a descuentos
    discounts = discounts.map((item) => {
      return {
        ...item,
        valoradescontar: 0,
      };
    });

    let cValorBruto = 0;
    let cTotalBaseImponible = 0;
    let cValorCargos = 0;
    let cValorCargosGlobales = 0;
    let cValorDescuentosFinancieros = 0;

    let cValorIva = 0;
    let cValorInc = 0;
    let cValorIcui = 0;
    let cValorAdv = 0;
    let cValorIbua = null;
    let cValorIcl = null;
    let cValorInpp = null;
    let cValorDescuento = 0;
    let cValorTotal = 0;

    let cValorIVAReal = 0;
    let cValorINCReal = 0;
    let cValorICUIReal = 0;
    let cValorADVReal = 0;
    let cValorIBUAReal = null;
    let cValorICLReal = null;
    let cValorINPPReal = null;
    let cValorDescuentoReal = 0;
    let cValorTotalReal = 0;

    let cTotalReteFte = 0;
    let cTotalReteIca = 0;
    let cTotalReteIva = 0;

    let cTotalAPagar = 0;
    let cTotalCantidadesItem = 0;
    let cTotalAPagarDian = 0;

    //Calcula descuentos adicionales, modelo descuento 1--> Comercial, 2--> Financiero
    let financialDiscounts = [];
    let commercialDiscounts = [];
    discounts = !!discounts && discounts.length > 0 ? discounts : [];

    commercialDiscounts = discounts.filter((d) => d.modelodescuento === 1);
    financialDiscounts = discounts.filter((d) => d.modelodescuento === 2);

    //Calculo para descuentos comerciales
    calculeValueCommercialDiscounts(detailDocument, commercialDiscounts, digitsAfterDecimalDoc);

    //Suma totales por items en carrito, Calcula retenciones
    for (const item of detailDocument) {
      //Rete-Fuente
      if (item.idretfte > 0) {
        let retfte = item.retfte;
        let tarifaReteFte = retfte?.tarifa ? retfte.tarifa : 0;
        item.baseretfte = item.subtotalreal;
        item.tarifaretfte = tarifaReteFte;
        item.valorretfte = roundDecimalValue(item.subtotalreal * (tarifaReteFte / 100), digitsAfterDecimalDoc);
      } else {
        item.baseretfte = 0;
        item.tarifaretfte = 0;
        item.valorretfte = 0;
        item.retfte = null;
      }

      //Rete-ICA
      if (retica) {
        let tarifaReteIca = retica?.tarifa ? retica.tarifa : 0;
        item.idretica = retica.id;
        item.baseretica = item.subtotalreal;
        item.tarifaretica = tarifaReteIca / 10;
        item.valorretica = roundDecimalValue(item.subtotalreal * (tarifaReteIca / 1000), digitsAfterDecimalDoc);
        item.retica = retica;
      } else {
        item.idretica = 0;
        item.baseretica = 0;
        item.tarifaretica = 0;
        item.valorretica = 0;
        item.retica = null;
      }

      //Rete-IVA
      if (retiva) {
        let tarifaReteIva = retiva?.tarifa ? retiva.tarifa : 0;
        item.idretiva = retiva.id;
        item.baseretiva = item.totalivareal;
        item.tarifaretiva = tarifaReteIva;
        item.valorretiva = roundDecimalValue(item.totalivareal * (tarifaReteIva / 100), digitsAfterDecimalDoc);
        item.retiva = retiva;
      } else {
        item.idretiva = 0;
        item.baseretiva = 0;
        item.tarifaretiva = 0;
        item.valorretiva = 0;
        item.retiva = null;
      }

      cValorBruto = roundDecimalValue(cValorBruto + item.valorbruto, digitsAfterDecimalDoc); //Total sumatoria valor bruto items (VlrUnitario * Cantidad)

      const hasTax = (item) =>
        (item.porcentajeiva !== null && item.porcentajeiva >= 0) || item.porcentajeinc > 0 || item.porcentajeicui > 0 || item.porcentajeadv > 0;

      if (hasTax(item)) {
        cTotalBaseImponible = roundDecimalValue(cTotalBaseImponible + item.subtotalreal, digitsAfterDecimalDoc);
      }

      //VALORES FACTURA SIN DESCUENTOS COMERCIALES
      cValorDescuento = roundDecimalValue(cValorDescuento + item.totaldescuento, digitsAfterDecimalDoc); //Total sumatoria descuentos items
      cValorIva = roundDecimalValue(item.totaliva, digitsAfterDecimalDoc); //Total sumatoria valores IVA items
      cValorInc = roundDecimalValue(item.totalinc, digitsAfterDecimalDoc);
      cValorIcui = roundDecimalValue(item.totalicui, digitsAfterDecimalDoc);
      cValorAdv = roundDecimalValue(item.totaladv, digitsAfterDecimalDoc);
      cValorIbua = item.totalibua === null ? null : roundDecimalValue(item.totalibua, digitsAfterDecimalDoc) * item.cantidad;
      cValorIcl = item.gradosalcohol === null ? null : roundDecimalValue(item.tipobebida * item.gradosalcohol, digitsAfterDecimalDoc) * item.cantidad;
      cValorInpp = item.pesogramos === null ? null : roundDecimalValue(item.pesogramos * item.tasauvtimp, digitsAfterDecimalDoc) * item.cantidad;
      //Total sumatoria valores INC items
      cValorTotal = roundDecimalValue(item.total, digitsAfterDecimalDoc); // Total sumatoria totales item

      //VALORES REALES FACTURA, INCLUYE DESCUENTOS COMERCIALES
      cValorDescuentoReal = roundDecimalValue(cValorDescuentoReal + item.totaldescuentoreal, digitsAfterDecimalDoc);
      cValorIVAReal = roundDecimalValue(cValorIVAReal + item.totalivareal, digitsAfterDecimalDoc);
      cValorINCReal = roundDecimalValue(cValorINCReal + item.totalincreal, digitsAfterDecimalDoc);
      cValorICUIReal = roundDecimalValue(cValorICUIReal + item.totalicuireal, digitsAfterDecimalDoc);
      cValorADVReal = roundDecimalValue(cValorADVReal + item.totaladvreal, digitsAfterDecimalDoc);
      cValorIBUAReal = cValorIBUAReal || item.totalibua ? roundDecimalValue(cValorIBUAReal + item.totalibua, digitsAfterDecimalDoc) : null;
      cValorICLReal = cValorICLReal || item.totalicl ? roundDecimalValue(cValorICLReal + item.totalicl, digitsAfterDecimalDoc) : null;
      cValorINPPReal = cValorINPPReal || item.totalinpp ? roundDecimalValue(cValorINPPReal + item.totalinpp, digitsAfterDecimalDoc) : null;
      cValorTotalReal = roundDecimalValue(cValorTotalReal + item.totalreal, digitsAfterDecimalDoc);

      //RETENCIONES
      cTotalReteFte = roundDecimalValue(cTotalReteFte + item.valorretfte, digitsAfterDecimalDoc);
      cTotalReteIca = roundDecimalValue(cTotalReteIca + item.valorretica, digitsAfterDecimalDoc);
      cTotalReteIva = roundDecimalValue(cTotalReteIva + item.valorretiva, digitsAfterDecimalDoc);

      cTotalCantidadesItem = roundDecimalValue(cTotalCantidadesItem + item.cantidad, digitsAfterDecimalDoc); // Sumatoria cantidades productos
    }

    //Suma cargos adicionales de documento
    if (!!charges && charges.length > 0) {
      for (let i = 0; i < charges.length; i++) {
        const item = charges[i];

        let valueCharge = 0;
        if (item.tipocargo === 1) {
          // Valor
          valueCharge = item.esglobal === true ? roundDecimalValue(item.valor * item.cantidad, digitsAfterDecimalDoc) : parseFloat(item.valor); //IMpuesto
          cValorCargos = roundDecimalValue(cValorCargos + valueCharge, digitsAfterDecimalDoc);
          cValorCargosGlobales =
            item.esglobal === true ? roundDecimalValue(cValorCargosGlobales + valueCharge, digitsAfterDecimalDoc) : cValorCargosGlobales;
        } else if (item.tipocargo === 2) {
          // porcentaje

          valueCharge =
            item.esglobal === true
              ? roundDecimalValue(item.valor * item.cantidad, digitsAfterDecimalDoc)
              : cValorBruto * (parseFloat(item.valor) / 100);
          cValorCargos = roundDecimalValue(cValorCargos + valueCharge, digitsAfterDecimalDoc);
          cValorCargosGlobales =
            item.esglobal === true ? roundDecimalValue(cValorCargosGlobales + valueCharge, digitsAfterDecimalDoc) : cValorCargosGlobales;
        }

        //Agrega valor calculado a sumar por cargo, al valorBruto de factura.
        charges[i] = {
          ...charges[i],
          valorasumar: roundDecimalValue(valueCharge, digitsAfterDecimalDoc),
        };
      }
    }

    //Calculo para descuentos Financieros sobre total Real
    for (let i = 0; i < financialDiscounts.length; i++) {
      const item = financialDiscounts[i];
      let valuediscount = 0;

      if (item.tipodescuento === 1) {
        valuediscount = parseFloat(item.valor);
        cValorDescuentosFinancieros = roundDecimalValue(cValorDescuentosFinancieros + valuediscount, digitsAfterDecimalDoc);
      } else if (item.tipodescuento === 2) {
        valuediscount = roundDecimalValue(cValorTotalReal * (parseFloat(item.valor) / 100), digitsAfterDecimalDoc);
        cValorDescuentosFinancieros = roundDecimalValue(cValorDescuentosFinancieros + valuediscount, digitsAfterDecimalDoc);
      }

      //Agrega valor calculado a descontar de total Factura
      financialDiscounts[i] = {
        ...financialDiscounts[i],
        valoradescontar: roundDecimalValue(valuediscount, digitsAfterDecimalDoc),
      };
    }

    discounts = financialDiscounts.concat(commercialDiscounts);

    //Total Factura
    let total = roundDecimalValue(
      cValorTotalReal + cValorCargos - cValorDescuentosFinancieros - cTotalReteFte - cTotalReteIca - cTotalReteIva,
      digitsAfterDecimalDoc
    );
    let totalDian = roundDecimalValue(cValorTotalReal + cValorCargos - cValorDescuentosFinancieros, digitsAfterDecimalDoc);
    cTotalAPagar = roundDecimalValue(total, precision, digitsAfterDecimalDoc);
    cTotalAPagarDian = roundDecimalValue(totalDian, precision, digitsAfterDecimalDoc);
    let cValorBase = roundDecimalValue(cValorBruto - cValorDescuentosFinancieros - cValorDescuentoReal, digitsAfterDecimalDoc);

    let totals = {
      opdetalledocumento: detailDocument,
      opdocumentocargos: charges,
      opdocumentodescuentos: discounts,

      valorcargos: cValorCargos,
      valorcargosaplicados: roundDecimalValue(cValorCargos - cValorCargosGlobales, digitsAfterDecimalDoc),
      valordescuento: cValorDescuento,

      valorbase: cValorBase,
      valorbruto: cValorBruto,
      valorbaseimponible: cTotalBaseImponible,
      valorapagar: cTotalAPagar,
      valorapagardian: cTotalAPagarDian,
      valortotal: cValorTotal,

      valoriva: cValorIva,
      valorinc: cValorInc,
      valoricui: cValorIcui,
      valoradv: cValorAdv,
      valoribua: cValorIbua,
      valoricl: cValorIcl,
      valorinpp: cValorInpp,

      valordescuentoreal: cValorDescuentoReal,
      valorivareal: cValorIVAReal,
      valorincreal: cValorINCReal,
      valoricuireal: cValorICUIReal,
      valoradvreal: cValorADVReal,
      valoribuareal: cValorIBUAReal,
      valoriclreal: cValorICLReal,
      valorinppreal: cValorINPPReal,
      totalfacturaReal: cValorTotalReal,
      redondeo: round,
      decimales: precision,

      valorretfte: cTotalReteFte,
      valorretica: cTotalReteIca,
      valorretiva: cTotalReteIva,

      totalcantidades: cTotalCantidadesItem,
      valorotrosdescuentos: cValorDescuentosFinancieros,
      retiva,
      retica,
    };

    return totals;
  } catch (err) {
    console.error("Ha ocurrido un error calculando Totales, Por favor contacte al administrador", err);
    throw err;
  }
};

/**
 * Calcula valores para modelo de descuento comercial
 * @param {Array} detailDocument  Listado items de Factura
 * @param {*} commercialDiscounts Listado de descuentos comerciales
 */
const calculeValueCommercialDiscounts = (detailDocument, commercialDiscounts, digitsAfterDecimalDoc) => {
  //Calcular total bruto (vlrUnitario * cantidad)
  let cValorTotalBruto = 0;
  for (const item of detailDocument) {
    cValorTotalBruto = roundDecimalValue(cValorTotalBruto + item.valorbruto, digitsAfterDecimalDoc);
  }

  //Calcular Prorrateo
  let acumuladoProrrateo = 0;
  let prorrateo = 0;
  for (let i = 0; i < detailDocument.length; i++) {
    const item = detailDocument[i];
    if (i + 1 === detailDocument.length) {
      prorrateo = roundDecimalValue(100 - acumuladoProrrateo, digitsAfterDecimalDoc);
    } else {
      let temp = (item.valorbruto * 100) / cValorTotalBruto; // cero dividido en cero = valor indefinido
      prorrateo = roundDecimalValue(isNaN(temp) ? 0 : temp, digitsAfterDecimalDoc);
      acumuladoProrrateo = roundDecimalValue(acumuladoProrrateo + prorrateo, digitsAfterDecimalDoc);
    }

    detailDocument[i] = {
      ...detailDocument[i],
      prorrateo: prorrateo,
    };
  }

  //Calcula valores de descuento comercial por item
  if (commercialDiscounts.length <= 0) {
    for (let i = 0; i < detailDocument.length; i++) {
      const item = detailDocument[i];

      let cValorBruto = item.valorneto * item.cantidad;
      let cValorDescuento = item.valordescuento;
      let cPorcentajeDescuento = item.porcentajedescuento;
      let cValorDescuentoFinal =
        item.tipodescuento === 2 ? roundDecimalValue(cValorBruto * (cPorcentajeDescuento / 100), digitsAfterDecimalDoc) : cValorDescuento; //Fernando Ruiz: Redondear valor
      let cValorSubtotal = roundDecimalValue(cValorBruto - cValorDescuentoFinal, digitsAfterDecimalDoc);
      let cValorIva = roundDecimalValue(cValorSubtotal * (item.porcentajeiva / 100), digitsAfterDecimalDoc);
      let cValorInc = roundDecimalValue(cValorSubtotal * (item.porcentajeinc / 100), digitsAfterDecimalDoc);

      let cValorIcui = roundDecimalValue(cValorSubtotal * (item.porcentajeicui / 100), digitsAfterDecimalDoc);
      let cValorAdv = roundDecimalValue(cValorSubtotal * (item.porcentajeadv / 100), digitsAfterDecimalDoc);
      let cValorIbua =
        item.cantidadmililitros === null
          ? null
          : roundDecimalValue((item.cantidadmililitros * item.valoribua) / 100, digitsAfterDecimalDoc) * item.cantidad;
      let cValorIcl =
        item.gradosalcohol === null ? null : roundDecimalValue(item.tipobebida * item.gradosalcohol, digitsAfterDecimalDoc) * item.cantidad;

      let cValorInpp = item.pesogramos === null ? null : roundDecimalValue(item.pesogramos * item.tasauvtimp, digitsAfterDecimalDoc) * item.cantidad;

      let cTotal = roundDecimalValue(
        cValorSubtotal + cValorIva + cValorInc + cValorIcui + cValorAdv + cValorIbua + cValorIcl + cValorInpp,
        digitsAfterDecimalDoc
      );

      item.totaldescuento = cValorDescuentoFinal;
      item.valorbruto = cValorBruto;
      item.subtotal = cValorSubtotal;
      item.totaliva = cValorIva;
      item.totalinc = cValorInc;
      item.totalicui = cValorIcui;
      item.totaladv = cValorAdv;
      item.totalibua = cValorIbua;
      item.totalicl = cValorIcl;
      item.totalinpp = cValorInpp;
      item.total = cTotal;

      detailDocument[i] = {
        ...detailDocument[i],
        valordescuentocomercial: 0,
        totaldescuentoreal: item.totaldescuento,
        subtotalreal: item.subtotal,
        totalivareal: item.totaliva,
        totalincreal: item.totalinc,
        totalicuireal: item.totalicui,
        totaladvreal: item.totaladv,
        totalibuareal: item.totalibua,
        totaliclreal: item.totalicl,
        totalinppreal: item.totalinpp,
        totalreal: item.total,
      };
    }
  }

  //Limpiar valores anteriores antes de calculo
  if (commercialDiscounts.length > 0) {
    for (let i = 0; i < detailDocument.length; i++) {
      detailDocument[i] = {
        ...detailDocument[i],
        valordescuentocomercial: 0,
        totaldescuentoreal: 0,
        subtotalreal: 0,
        totalivareal: 0,
        totalincreal: 0,
        totalicuireal: 0,
        totaladvreal: 0,
        totalibuareal: 0,
        totaliclreal: 0,
        totalinppreal: 0,
        totalreal: 0,
      };
    }
  }

  for (let i = 0; i < commercialDiscounts.length; i++) {
    const discount = commercialDiscounts[i];
    let cDescuentoComercial = 0;
    let valorDescuento = discount.tipodescuento === 1 ? discount.valor : 0;
    let acumuladoDescuento = 0;

    for (let d = 0; d < detailDocument.length; d++) {
      const item = detailDocument[d];
      let cValorDescuento = 0;

      if (discount.tipodescuento === 1) {
        //Ajusta descuento en ultimo item
        if (d + 1 === detailDocument.length) {
          cValorDescuento = roundDecimalValue(valorDescuento - acumuladoDescuento, digitsAfterDecimalDoc);
          acumuladoDescuento = roundDecimalValue(acumuladoDescuento + cValorDescuento, digitsAfterDecimalDoc);
        } else {
          cValorDescuento = roundDecimalValue(discount.valor * (item.prorrateo / 100), digitsAfterDecimalDoc);
          acumuladoDescuento = roundDecimalValue(acumuladoDescuento + cValorDescuento, digitsAfterDecimalDoc);
        }
      } else {
        // Tipo porcentaje
        cValorDescuento = roundDecimalValue(item.valorbruto * (discount.valor / 100), digitsAfterDecimalDoc);
      }

      cDescuentoComercial = roundDecimalValue(cDescuentoComercial + cValorDescuento, digitsAfterDecimalDoc);

      let cTotalDescuentoReal = roundDecimalValue(item.totaldescuento + item.valordescuentocomercial + cValorDescuento, digitsAfterDecimalDoc);
      let cSubtotalReal = roundDecimalValue(item.valorbruto - cTotalDescuentoReal, digitsAfterDecimalDoc);
      let cValorIVAReal = roundDecimalValue(cSubtotalReal * (item.porcentajeiva / 100), digitsAfterDecimalDoc);
      let cValorINCReal = roundDecimalValue(cSubtotalReal * (item.porcentajeinc / 100), digitsAfterDecimalDoc);
      let cValorICUIReal = roundDecimalValue(cSubtotalReal * (item.porcentajeicui / 100), digitsAfterDecimalDoc);
      let cValorADVReal = roundDecimalValue(cSubtotalReal * (item.porcentajeadv / 100), digitsAfterDecimalDoc);
      let cValorIbuaReal =
        item.cantidadmililitros === null
          ? null
          : roundDecimalValue((item.cantidadmililitros * item.valoribua) / 100, digitsAfterDecimalDoc) * item.cantidad;

      let cValorICLReal =
        item.gradosalcohol === null ? null : roundDecimalValue(item.tipobebida * item.gradosalcohol, digitsAfterDecimalDoc) * item.cantidad;

      let cValorINPPReal =
        item.pesogramos === null ? null : roundDecimalValue(item.pesogramos * item.tasauvtimp, digitsAfterDecimalDoc) * item.cantidad;

      let cTotalReal = roundDecimalValue(
        cSubtotalReal + cValorIVAReal + cValorINCReal + cValorICUIReal + cValorADVReal + cValorIbuaReal + cValorICLReal + cValorINPPReal,
        digitsAfterDecimalDoc
      );

      let nuevoComercial = roundDecimalValue(detailDocument[d].valordescuentocomercial + cValorDescuento, digitsAfterDecimalDoc);
      detailDocument[d] = {
        ...detailDocument[d],
        valordescuentocomercial: nuevoComercial,
        totaldescuentoreal: cTotalDescuentoReal,
        subtotalreal: cSubtotalReal,
        totalivareal: cValorIVAReal,
        totalincreal: cValorINCReal,
        totalicuireal: cValorICUIReal,
        totaladvreal: cValorADVReal,
        totalibuareal: cValorIbuaReal,
        totaliclreal: cValorICLReal,
        totalinppreal: cValorINPPReal,
        totalreal: cTotalReal,
      };
    }

    let nuevoDescuento = commercialDiscounts[i].valoradescontar + cDescuentoComercial;
    commercialDiscounts[i] = {
      ...commercialDiscounts[i],
      valoradescontar: roundDecimalValue(nuevoDescuento, digitsAfterDecimalDoc),
    };
  }
};

/**
 * Calcula totales por item, base, impuestos, descuentos
 * @param {*} item Información Servicio
 */
export const calculateItemTotalsSupport = (item, precision) => {
  try {
    let cValorBruto = item.valorneto * item.cantidad;
    let cValorDescuento = item.valordescuento;
    let cPorcentajeDescuento = item.porcentajedescuento;
    let cValorDescuentoFinal = item.tipodescuento === 2 ? roundDecimalValue(cValorBruto * (cPorcentajeDescuento / 100), precision) : cValorDescuento;
    let cValorSubtotal = roundDecimalValue(cValorBruto - cValorDescuentoFinal, precision);
    let cValorIva = roundDecimalValue(cValorSubtotal * (item.porcentajeiva / 100), precision);
    let cValorInc = roundDecimalValue(cValorSubtotal * (item.porcentajeinc / 100), precision);
    let cTotal = roundDecimalValue(cValorSubtotal + cValorIva + cValorInc, precision);

    item.totaldescuento = cValorDescuentoFinal;
    item.valorbruto = cValorBruto;
    item.subtotal = cValorSubtotal;
    item.totaliva = cValorIva;
    item.totalinc = cValorInc;
    item.total = cTotal;

    item.totaldescuentoreal = cValorDescuentoFinal;
    item.subtotalreal = cValorSubtotal;
    item.totalivareal = cValorIva;
    item.totalincreal = cValorInc;
    item.totalreal = cTotal;
  } catch (err) {
    console.error(`Ha ocurrido un error calculando valores de item ${item.codigo}`, err);
    throw err;
  }
};

//Calcula valor impuestos y totales para Documento Soporte
export const calculateTotalDocumentSupport = (
  detailDocument,
  charges = [],
  discounts = [],
  retiva = null,
  retica = null,
  round,
  cartDocumentSupport
) => {
  try {
    //Precison de redondeo
    let precision;
    if (round) {
      precision = 0;
    } else {
      precision = DIGITS_AFTER_DECIMAL_DOC > 2 ? 2 : DIGITS_AFTER_DECIMAL_DOC;
    }

    for (let item of detailDocument) {
      calculateItemTotalsSupport(item, precision);
    }

    //Agrega campo nuevo a descuentos
    discounts = discounts.map((item) => {
      return {
        ...item,
        valoradescontar: 0,
      };
    });

    let cValorBruto = 0;
    let cValorCargos = 0;
    let cValorCargosGlobales = 0;
    let cValorDescuentosFinancieros = 0;

    let cValorIva = 0;
    let cValorInc = 0;
    let cValorDescuento = 0;
    let cValorTotal = 0;

    let cValorIVAReal = 0;
    let cValorINCReal = 0;
    let cValorDescuentoReal = 0;
    let cValorTotalReal = 0;

    let cTotalReteFte = 0;
    let cTotalReteIca = 0;
    let cTotalReteIva = 0;

    let cTotalAPagar = 0;
    let cTotalCantidadesItem = 0;
    let cTotalAPagarDian = 0;

    //Calcula descuentos adicionales, modelo descuento 1--> Comercial, 2--> Financiero
    let financialDiscounts = [];
    let commercialDiscounts = [];
    discounts = !!discounts && discounts.length > 0 ? discounts : [];

    commercialDiscounts = discounts.filter((d) => d.modelodescuento === 1);
    financialDiscounts = discounts.filter((d) => d.modelodescuento === 2);

    //Calculo para descuentos comerciales
    // calculeValueCommercialDiscounts(detailDocument, commercialDiscounts);

    //Suma totales por items en carrito, Calcula retenciones
    for (const item of detailDocument) {
      //Rete-Fuente
      if (item.idretfte > 0) {
        let retfte = item.retfte;
        let tarifaReteFte = retfte?.tarifa ? retfte.tarifa : 0;
        item.baseretfte = item.subtotalreal;
        item.tarifaretfte = tarifaReteFte;
        item.valorretfte = roundDecimalValue(item.subtotalreal * (tarifaReteFte / 100), precision);
      } else {
        item.baseretfte = 0;
        item.tarifaretfte = 0;
        item.valorretfte = 0;
        item.retfte = null;
      }

      //Rete-ICA
      if (retica) {
        let tarifaReteIca = retica?.tarifa ? retica.tarifa : 0;
        item.idretica = retica.id;
        item.baseretica = item.subtotalreal;
        item.tarifaretica = tarifaReteIca / 10;
        item.valorretica = roundDecimalValue(item.subtotalreal * (tarifaReteIca / 1000), precision);
        item.retica = retica;
      } else {
        item.idretica = 0;
        item.baseretica = 0;
        item.tarifaretica = 0;
        item.valorretica = 0;
        item.retica = null;
      }

      //Rete-IVA
      if (retiva) {
        let tarifaReteIva = retiva?.tarifa ? retiva.tarifa : 0;
        item.idretiva = retiva.id;
        item.baseretiva = item.totalivareal;
        item.tarifaretiva = tarifaReteIva;
        item.valorretiva = roundDecimalValue(item.totalivareal * (tarifaReteIva / 100), precision);
        item.retiva = retiva;
      } else {
        item.idretiva = 0;
        item.baseretiva = 0;
        item.tarifaretiva = 0;
        item.valorretiva = 0;
        item.retiva = null;
      }

      cValorBruto = roundDecimalValue(cValorBruto + item.valorbruto, precision); //Total sumatoria valor bruto items (VlrUnitario * Cantidad)
      //VALORES FACTURA SIN DESCUENTOS COMERCIALES
      cValorDescuento = roundDecimalValue(cValorDescuento + item.totaldescuento, precision); //Total sumatoria descuentos items
      cValorIva = roundDecimalValue(item.totaliv, precision); //Total sumatoria valores IVA items
      cValorInc = roundDecimalValue(item.totalin, precision); //Total sumatoria valores INC items
      cValorTotal = roundDecimalValue(item.total, precision); // Total sumatoria totales item

      //VALORES REALES FACTURA, INCLUYE DESCUENTOS COMERCIALES
      cValorDescuentoReal = roundDecimalValue(cValorDescuentoReal + item.totaldescuentoreal, precision);
      cValorIVAReal = roundDecimalValue(cValorIVAReal + item.totalivareal, precision);
      cValorINCReal = roundDecimalValue(cValorINCReal + item.totalincreal, precision);
      cValorTotalReal = roundDecimalValue(cValorTotalReal + item.totalreal, precision);

      //RETENCIONES
      cTotalReteFte = roundDecimalValue(cTotalReteFte + item.valorretfte, precision);
      cTotalReteIca = roundDecimalValue(cTotalReteIca + item.valorretica, precision);
      cTotalReteIva = roundDecimalValue(cTotalReteIva + item.valorretiva, precision);

      cTotalCantidadesItem = roundDecimalValue(cTotalCantidadesItem + item.cantidad, precision); // Sumatoria cantidades productos
    }

    //Suma cargos adicionales de documento
    if (!!charges && charges.length > 0) {
      for (let i = 0; i < charges.length; i++) {
        const item = charges[i];

        let valueCharge = 0;
        if (item.tipocargo === 1) {
          // Valor
          valueCharge = item.esglobal === true ? roundDecimalValue(item.valor * item.cantidad, precision) : parseFloat(item.valor); //IMpuesto
          cValorCargos = roundDecimalValue(cValorCargos + valueCharge, precision);
          cValorCargosGlobales = item.esglobal === true ? roundDecimalValue(cValorCargosGlobales + valueCharge, precision) : cValorCargosGlobales;
        } else if (item.tipocargo === 2) {
          // porcentaje

          valueCharge =
            item.esglobal === true ? roundDecimalValue(item.valor * item.cantidad, precision) : cValorBruto * (parseFloat(item.valor) / 100);
          cValorCargos = roundDecimalValue(cValorCargos + valueCharge, precision);
          cValorCargosGlobales = item.esglobal === true ? roundDecimalValue(cValorCargosGlobales + valueCharge, precision) : cValorCargosGlobales;
        }

        //Agrega valor calculado a sumar por cargo, al valorBruto de factura.
        charges[i] = {
          ...charges[i],
          valorasumar: roundDecimalValue(valueCharge, precision),
        };
      }
    }

    //Calculo para descuentos Financieros sobre total Real
    for (let i = 0; i < financialDiscounts.length; i++) {
      const item = financialDiscounts[i];
      let valuediscount = 0;

      if (item.tipodescuento === 1) {
        valuediscount = parseFloat(item.valor);
        cValorDescuentosFinancieros = roundDecimalValue(cValorDescuentosFinancieros + valuediscount, precision);
      } else if (item.tipodescuento === 2) {
        valuediscount = roundDecimalValue(cValorTotalReal * (parseFloat(item.valor) / 100), precision);
        cValorDescuentosFinancieros = roundDecimalValue(cValorDescuentosFinancieros + valuediscount, precision);
      }

      //Agrega valor calculado a descontar de total Factura
      financialDiscounts[i] = {
        ...financialDiscounts[i],
        valoradescontar: roundDecimalValue(valuediscount, precision),
      };
    }

    discounts = financialDiscounts.concat(commercialDiscounts);

    //Total Factura
    let total = roundDecimalValue(
      cValorTotalReal + cValorCargos - cValorDescuentosFinancieros - cTotalReteFte - cTotalReteIca - cTotalReteIva,
      precision
    );
    let totalDian = roundDecimalValue(cValorTotalReal + cValorCargos - cValorDescuentosFinancieros, precision);
    cTotalAPagar = roundDecimalValue(total, precision);
    cTotalAPagarDian = roundDecimalValue(totalDian, precision);
    let cValorBase = roundDecimalValue(cValorBruto - cValorDescuentosFinancieros - cValorDescuentoReal, precision);

    let totals = {
      opdetalledocumento: detailDocument,
      opdocumentocargos: charges,
      opdocumentodescuentos: discounts,

      valorcargos: cValorCargos,
      valorcargosaplicados: roundDecimalValue(cValorCargos - cValorCargosGlobales, precision),
      valordescuento: cValorDescuento,

      valorbase: cValorBase,
      valorbruto: cValorBruto,
      valorapagar: cTotalAPagar,
      valorapagardian: cTotalAPagarDian,
      valortotal: cValorTotal,

      valoriva: cValorIva,
      valorinc: cValorInc,

      valordescuentoreal: cValorDescuentoReal,
      valorivareal: cValorIVAReal,
      valorincreal: cValorINCReal,
      totalfacturaReal: cValorTotalReal,
      redondeo: round,
      decimales: precision,

      valorretfte: cTotalReteFte,
      valorretica: cTotalReteIca,
      valorretiva: cTotalReteIva,

      totalcantidades: cTotalCantidadesItem,
      valorotrosdescuentos: cValorDescuentosFinancieros,
      retiva,
      retica,
    };

    return totals;
  } catch (err) {
    console.error("Ha ocurrido un error calculando Totales, Por favor contacte al administrador", err);
    throw err;
  }
};
