import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import RoomIcon from '@material-ui/icons/Room';
import { CardActions, Tooltip, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EditIcon from '@material-ui/icons/Edit';
import CustomTooltip from '../../components/tooltip/tooltip.component';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import imageCloud from '../../../src/images/CombinedShapeCopy4.png';
import imagePorcentaje from '../../../src/images/porcentaje.png';
import imageObs from '../../../src/images/observaciones.png';
import imageRetencion from '../../../src/images/withholdingsImage.png';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ReportIcon from '@material-ui/icons/Report';
import cloudIcon from './../../images/icons/cloud.png';

import CustomProgress from '../../components/Progress/progress.component';

import { formatDate } from '../../utils/general.js';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { FormatDecimal } from '../../components/common/formatDecimal';
import MessageSecundary from '../../components/tooltip/messageSecundary.component';

const useStyles = (theme) => ({
  show: {
    marginLeft: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  actions: {
    justifyContent: 'flex-end',
    padding: 'unset',
  },
  root: {
    backgroundColor: '#5E5E5E',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  rootwocard: {
    backgroundColor: '#5E5E5E',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  rootsel: {
    backgroundColor: '#3B3B3B',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  rootchoose: {
    backgroundColor: '#16B1F3',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Muli',
  },
  icon: {
    color: '#2CC63E',
  },
  buttonsContainer: {
    textAlign: 'end',
  },
  content: {},
  iconAction: {
    cursor: 'pointer',
    color: '#FFFFFF',
    marginTop: 5,
  },
  textOverFlow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
  iconButton: {
    padding: '5px 5px 0px 5px',
    color: 'white',
  },
});
export class CustomCardAditionalData extends Component {
  state = {
    data: this.props.data,
    editAction: this.props.editAditional,
    deleteAction: this.props.deleteAditional,
    onChange: this.props.onChange,
    principal: this.props.principal,
    active: false,
    type: this.props.type,
    functionSel: this.props.functionSel,
    openMailModal: this.props.openMailModal,
    expanded: false,
    loadingGetNoteId: this.props.loadingGetNoteId,
    estadoDocumentoId: this.props.estadoDocumentoId,
    tipoestadodocumentoid: this.props.data.tipoestadodocumentoid,
    descripcion: this.props.data.descripcion,
    hideActionsSend: this.props.hideActionsSend || false,
    hideActionsView: this.props.hideActionsView || false,
  };

  handleClick = () => {
    !this.state.active
      ? this.setState({ active: true })
      : this.setState({ active: false });
  };

  handleExpandClick = () => {
    !this.state.expanded
      ? this.setState({ expanded: true })
      : this.setState({ expanded: false });
  };

  pad = (input, length, padding) => {
    var str = input + '';
    return length <= str.length
      ? str
      : this.pad(padding + str, length, padding);
  };

  render() {
    const classes = this.props.classes;
    if (this.state.type === 'directions') {
      return (
        <Card
          className={
            this.props.selectedId === this.state.data.id
              ? classes.rootchoose
              : classes.rootsel
          }
          onClick={() => this.state.functionSel(this.state.data.id)}
        >
          <CardHeader
            avatar={
              <RoomIcon
                style={{
                  color:
                    this.props.selectedId === this.state.data.id
                      ? '#FFFFFF'
                      : '#2CC63E',
                }}
              ></RoomIcon>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data.descripcion}
          />
          <CardContent>
            <span>
              {this.state.data.direccion} {this.state.data.ciudaddescripcion}(
              {this.state.data.departamentodescripcion})
            </span>
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'contacts') {
      return (
        <Card
          className={
            this.props.selectedId === this.state.data.id
              ? classes.rootchoose
              : classes.rootsel
          }
          onClick={() => this.state.functionSel(this.state.data.id)}
        >
          <CardHeader
            avatar={
              <PersonIcon
                style={{
                  color:
                    this.props.selectedId === this.state.data.id
                      ? '#FFFFFF'
                      : '#2CC63E',
                }}
              ></PersonIcon>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data.nombre}
          />
          <CardContent className={classes.content}>
            <div className={classes.wordBreak}>
              <span>
                {' '}
                {this.state.data.telefono} / {this.state.data.email}{' '}
              </span>
            </div>
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'charges') {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              <LibraryAddIcon style={{ color: '#2CC63E' }}></LibraryAddIcon>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data.descripcion}
          />
          <CardContent className={classes.content}>
            <>
              {this.state.data.tipocargo === 1 ? (
                <FormatDecimal value={this.state.data.valor} prefix={'$'} />
              ) : (
                <FormatDecimal value={this.state.data.valor} suffix={'%'} />
              )}
              {this.state.data.cantidad > 1 && (
                <FormatDecimal
                  value={this.state.data.cantidad}
                  prefix={'('}
                  suffix={')'}
                />
              )}
            </>
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'discounts') {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              this.state.data.modelodescuento === 1 ? (
                <LocalOfferIcon style={{ color: '#2CC63E' }}></LocalOfferIcon>
              ) : (
                <AccountBalanceIcon
                  style={{ color: '#2CC63E' }}
                ></AccountBalanceIcon>
              )
            }
            action={
              <IconButton aria-label='settings' className={classes.iconButton}>
                <CustomTooltip message={this.props.tooltipMessage} />
              </IconButton>
            }
            classes={{
              title: classes.title,
            }}
            title={
              this.state.data.modelodescuento === 1 ? (
                <b>Descuento Comercial</b>
              ) : (
                <b>Descuento Financiero</b>
              )
            }
          />
          <CardContent className={classes.content}>
            {this.state.data.tipodescuento === 1 ? (
              <span style={{ color: '#F5D13D' }}>
                <FormatDecimal value={this.state.data.valor} prefix={'$'} />
              </span>
            ) : (
              <FormatDecimal value={this.state.data.valor} suffix={'%'} />
            )}
            &nbsp;
            {this.state.data.tipodescuento === 2 && (
              <span style={{ color: '#F5D13D' }}>
                <FormatDecimal
                  value={this.state.data.valoradescontar}
                  prefix={'($'}
                  suffix={')'}
                />
              </span>
            )}
            <br />
            {`Motivo: ${this.state.data.motivo}`}
            <br />
            {this.state.data.descripcion}
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'notes') {
      return (
        <Card className={classes.root} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={<EditIcon style={{ color: '#2CC63E' }}></EditIcon>}
            classes={{
              title: classes.title,
            }}
            action={
              <div style={{ display: 'flex' }}>
                {this.state.tipoestadodocumentoid === 2 ? (
                  <Tooltip
                    title={
                      <MessageSecundary
                        title={'Pendiente de sincronización con DIAN'}
                        message={
                          'Este Ítem esta pendiente de sincronización con DIAN'
                        }
                      />
                    }
                    placement='bottom-end'
                    interactive
                    enterTouchDelay={1000}
                  >
                    <IconButton className={classes.iconButton}>
                      <img src={cloudIcon} alt={'cloud'} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {this.state.tipoestadodocumentoid === 3 ? (
                  <Tooltip
                    title={
                      <MessageSecundary
                        title={`Error DIAN - ${this.state.data.descripcion}`}
                        message={
                          'El documento presenta una inconsistencia ante la DIAN.'
                        }
                      />
                    }
                    placement='bottom-end'
                    interactive
                    enterTouchDelay={1000}
                  >
                    <IconButton className={classes.iconButton}>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}

                {!this.state.hideActionsView && (
                  <Tooltip title={'Ver'}>
                    {this.state.loadingGetNoteId === this.state.data.id ? (
                      <CustomProgress size={15} />
                    ) : (
                      <VisibilityIcon
                        className={classes.iconAction}
                        onClick={() =>
                          this.props.handleShowDetailNote(this.props?.data?.id)
                        }
                      />
                    )}
                  </Tooltip>
                )}
                {!this.state.hideActionsSend && (
                  <Tooltip title={'Enviar'} style={{ marginLeft: 10 }}>
                    <EmailIcon
                      className={classes.iconAction}
                      onClick={() =>
                        this.state.openMailModal(
                          this.state.data.correoelectronicocliente,
                          false,
                          this.props.tipodocumentoentidad,
                          this.props.documentompresa,
                          this.state.data.codigounico,
                          this.state.data.numero,
                          this.props.razonsocialempresa,
                          this.state.data.razonsocialcliente,
                          this.props.diantipomodalidadid,
                          this.state.data.id,
                          this.state.data
                        )
                      }
                    />
                  </Tooltip>
                )}
              </div>
            }
            title={
              <b>
                Nota{' '}
                {this.state.data.diantipodocumentoelectronicoid === 2
                  ? 'Debito'
                  : this.state.data.diantipodocumentoelectronicoid === 6
                    ? 'Ajuste'
                    : 'Crédito'}
              </b>
            }
          />
          <CardContent className={classes.content}>
            <span>Número Nota: {this.state.data.numero}</span> <br></br>
            <span>Concepto: {this.state.data.notaconcepto}</span>
            <br></br>
            <span>
              Fecha:{' '}
              {formatDate(
                new Date(this.state.data.fecha),
                'dd/MM/yyyy hh:mm aaaa'
              )}
            </span>
            <br></br>
            {this.state.data.estadovpid === 1 ? (
              <img
                src={imageCloud}
                onClick=''
                className='buttonInvoiceBehavior'
                onMouseOver={this.props.handleOpenModalWarning}
                alt=''
              ></img>
            ) : null}
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'docref') {
      return (
        <Card className={classes.root} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              <AccountTreeIcon style={{ color: '#2CC63E' }}></AccountTreeIcon>
            }
            classes={{
              title: classes.title,
            }}
            title={<b>{this.state.data.tipodocumentodescripcion}</b>}
          />
          <CardContent className={classes.content}>
            <span>Número: {this.state.data.numero}</span> <br></br>
            <span>
              Fecha: {formatDate(new Date(this.state.data.fecha), 'dd/MM/yyyy')}
            </span>
            <br></br>
            {this.state.data.tipodocumentoid?.toString() === '1' && (
              <span>
                <FormatDecimal
                  value={this.state.data.valorapagar}
                  prefix={'$'}
                />
              </span>
            )}
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'chargesReceived') {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              <LibraryAddIcon style={{ color: '#2CC63E' }}></LibraryAddIcon>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data.Descripcion}
          />
          <CardContent className={classes.content}>
            <span>
              &nbsp;
              <FormatDecimal value={this.state.data.Valor} prefix={'$'} />
            </span>
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'discountsReceived') {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              this.state.data.modelodescuento === 1 ? (
                <LocalOfferIcon style={{ color: '#2CC63E' }}></LocalOfferIcon>
              ) : (
                <AccountBalanceIcon
                  style={{ color: '#2CC63E' }}
                ></AccountBalanceIcon>
              )
            }
            classes={{
              title: classes.title,
            }}
            title={<b>{this.state.data.Descripcion}</b>}
          />
          <CardContent className={classes.content}>
            <FormatDecimal value={this.state.data.Valor} prefix={'$'} />
            <br></br>
          </CardContent>
        </Card>
      );
    }

    if (this.state.type === 'noteReceived') {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              <img
                src={imageObs}
                className='buttonInvoiceBehavior'
                onMouseOver={this.props.handleOpenModalWarning}
                alt=''
              ></img>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data}
          />
          <CardActions className={classes.actions}>
            <IconButton
              className={classes.expandIcon}
              onClick={() => this.handleExpandClick()}
              aria-expanded={this.state.expanded}
            >
              <Typography variant='caption' className={classes.icon}>
                {this.state.expanded ? 'Ver menos' : 'Ver más'}
              </Typography>
              <ExpandMore
                className={clsx(
                  classes.expand,
                  {
                    [classes.expandOpen]: this.state.expanded,
                  },
                  classes.icon
                )}
              />
            </IconButton>
          </CardActions>
        </Card>
      );
    }

    if (this.state.type === 'taxesReceived' && this.state.data.ValorTotal > 0) {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              <img
                src={imagePorcentaje}
                className='buttonInvoiceBehavior'
                onMouseOver={this.props.handleOpenModalWarning}
                alt=''
              ></img>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data.Tributo.Nombre}
          />
          {this.state.data.SubtotalPorcentaje.map((d, i) =>
            d.Valor > 0 ? (
              <CardContent className={classes.content}>
                <span>
                  &nbsp;
                  {`${d.Porcentaje}%`}
                  <span style={{ color: '#EC005F' }}>
                    <FormatDecimal value={d.Valor} prefix={'$'} />
                  </span>
                </span>
              </CardContent>
            ) : (
              ''
            )
          )}
        </Card>
      );
    } else if (
      this.state.type === 'withholdingsReceived' &&
      this.state.data.ValorTotal > 0
    ) {
      return (
        <Card className={classes.rootwocard} style={{ color: '#FFFFFF' }}>
          <CardHeader
            avatar={
              <img
                src={imageRetencion}
                className='buttonInvoiceBehavior'
                onMouseOver={this.props.handleOpenModalWarning}
                alt=''
              ></img>
            }
            classes={{
              title: classes.title,
            }}
            title={this.state.data.Tributo.Nombre}
          />
          {this.state.data.SubtotalPorcentaje.map((d, i) =>
            d.Valor > 0 ? (
              <CardContent className={classes.content}>
                <span>
                  &nbsp;
                  {`${d.Porcentaje}%`}
                  <span style={{ color: '#EC005F' }}>
                    <FormatDecimal value={d.Valor} prefix={'$'} />
                  </span>
                </span>
              </CardContent>
            ) : (
              ''
            )
          )}
        </Card>
      );
    } else {
      return '';
    }
  }
}

CustomCardAditionalData.propTypes = {
  data: PropTypes.object,
};

export default withStyles(useStyles)(CustomCardAditionalData);
