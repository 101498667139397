import React from 'react';
import { makeStyles } from '@material-ui/styles';
import imgstep3 from '../../images/wizard/wizard_3.png'
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        height: 'auto'
    },
    message: {
        width: '24vw',
        textAlign: 'center',
        position: 'absolute',
        bottom: '5%',
        right: '70%',
        backgroundColor: 'rgba(65, 65, 65, 0.95)',
        color: theme.palette.primarycolor,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '2vw',
        fontSize: '1.1vw',
        padding: '1vw',
        fontStyle: 'italic',
    },
    containerImage: {
        width: '45%',
        margin: '0 auto',
        position: 'relative'
    }

}));

const Step3 = () => {
    const classes = useStyles();

    return (
        <div className={classes.containerImage}>
            <img alt='' src={imgstep3} className={classes.image}></img>
            <Typography className={classes.message}>
                Podrás facturar de manera <span className="linkColfactura">ágil y sencilla</span> teniendo a la mano tu
              catálogo de artículos, clientes, cargos y descuentos, todo al
              alcance de un clic sin complicados procesos ni configuraciones
              adicionales.
          </Typography>
        </div >
    );
}

export default Step3;