import React, { Component, Fragment } from "react";

import {
  changeStepStatusAction,
  getTemplate,
  saveTemplateDataAction,
} from "../../../actions/templateActions";
import { getCompanyAction } from "../../../actions/basicDataActions";
import {
  SuccessAlert,
  WarningAlert,
  ConfirmAlert,
} from "../../../helpers/alert.helpers";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TemplateCarousel from "../../../components/carrousel/templateCarrousel";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import imageDefault from "../../../../src/images/image.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./template.view.css";

import { bytesToSize, getComponentName } from "../../../utils/general";
import SimpleModal from "../../../components/modal/simpleModal.component";
import { imageSize } from "../../../config/config";
import LogoCropper from "../../../components/customCropper/logoCropper.component";
import ButtonUpload from "../../../components/button/buttonUpload.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";
import CustomProgress from "../../../components/Progress/progress.component";

import isEmpty from "../../../utils/isEmpty";
import {
  MESSAGE_DELETE_CONFIRM,
  TIME_UPDATE_REGISTER,
} from "../../../config/config";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.seventhBackgroundColor,
    borderRadius: 5,
    width: 540,
    outline: 0,
  },
  title: {
    background: "#f5d13d",
    textAlign: "center",
    fontWeight: "bold",
    borderRadius: 10,
  },
  imagePanel: {
    background: "#424242",
    borderRadius: 5,
    margin: "auto",
    width: "auto",
    textAlign: "center",
  },
  iconButtonFile: {
    borderRadius: "50%",
    fontSize: 50,
    position: "absolute",
    left: 130,
    top: -40,
  },
  crooper: {
    height: 274,
    width: "100%",
  },
  groupButtonModal: {
    paddingRight: 30,
  },
  btnModalImage: {
    borderRadius: 5,
    backgroundColor: theme.palette.thirdColor,
    width: "100%",
    height: 39,
    border: "none",
    cursor: "pointer",
    color: theme.palette.primaryColor,
    fontSize: 20,
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 15,
    marginLeft: 15,
  },
  uploadText: {
    color: "white",
    fontSize: 19,
    fontStyle: "italic",
    lineHeight: "17px",
    fontWeight: 600,
  },
  selectTemplate: {
    background: "#f5d13d",
    textAlign: "center",
    fontWeight: "bold",
    borderRadius: 10,
  },
  exitPanel: {
    textAlign: "center",
  },
  iconButtonCloseModal: {
    textAlign: "end",
  },
  iconCloseModal: {
    color: theme.palette.thirdColor,
    fontSize: 16,
    padding: 0,
  },
  buttonDelete: {
    textTransform: "none",
    color: theme.palette.primaryColor,
    backgroundColor: theme.palette.sixthColor,
    fontSize: 12,
    minWidth: 130,
  },
});

export class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        logo: "",
        plantillaid: "",
      },
      src: "",
      cropResult: null,
      stateModal: false,
      requestPostTemplate: {},
      templates: [],
      company: {},
      components: [],

      showActionsLogo: false,
      loadingSaveTemplate: false,
      menuMyAccount: {},
      loadingGetTemplate: false,
    };
  }

  componentDidMount() {
    this.props.getTemplate();
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.templateReducer.templatesList &&
      nextProps.templateReducer.templatesList !== state.templates
    )
      update.templates = nextProps.templateReducer.templatesList;

    if (
      !!nextProps.templateReducer.requestPostTemplate &&
      nextProps.templateReducer.requestPostTemplate !==
        state.requestPostTemplate
    )
      update.requestPostTemplate =
        nextProps.templateReducer.requestPostTemplate;

    if (
      !!nextProps.basicDataReducer.requestCompany &&
      nextProps.basicDataReducer.requestCompany !== state.company
    ) {
      update.company = nextProps.basicDataReducer.requestCompany;
      update.cropResult = nextProps.basicDataReducer.requestCompany.logo
        ? nextProps.basicDataReducer.requestCompany.logo
        : null;
      update.src = nextProps.basicDataReducer.requestCompany.logo;
      update.form = {
        plantillaid: nextProps.basicDataReducer.requestCompany.plantillaid,
        logo: nextProps.basicDataReducer.requestCompany.logo,
      };
    }

    if (
      !!nextProps.configReducer.components &&
      nextProps.configReducer.components !== state.components
    )
      update.components = nextProps.configReducer.components;

    if (
      !isEmpty(nextProps.basicDataReducer.loadingSaveTemplate) &&
      nextProps.basicDataReducer.loadingSaveTemplate !==
        state.loadingSaveTemplate
    )
      update.loadingSaveTemplate =
        nextProps.basicDataReducer.loadingSaveTemplate;

    if (
      !!nextProps.menuReducer.menuMyAccount &&
      nextProps.menuReducer.menuMyAccount !== state.menuMyAccount
    ) {
      update.menuMyAccount = nextProps.menuReducer.menuMyAccount;
    }

    if (
      !isEmpty(nextProps.templateReducer.loadingGetTemplate) &&
      nextProps.templateReducer.loadingGetTemplate !== state.loadingGetTemplate
    )
      update.loadingGetTemplate = nextProps.templateReducer.loadingGetTemplate;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!prevState.requestPostTemplate &&
      prevState.requestPostTemplate !== this.state.requestPostTemplate
    ) {
      if (this.state.requestPostTemplate.statusCode === "200") {
        this.cleanData();
        SuccessAlert(
          null,
          this.state.menuMyAccount.txtMessageConfigurationTemplate,
          TIME_UPDATE_REGISTER
        );
        this.props.getCompanyAction();
      }

      if (this.state.requestPostTemplate.statusCode === "400") {
        this.cleanData();
        WarningAlert(
          "Upss!!!",
          this.state.menuMyAccount.txtMessageErrorTemplate
        );
        this.props.getCompanyAction();
      }
    }
  }

  /**
   * Sincroniza Checkbox con state, seleccion plantilla
   * @param {*} e Evento
   * @param {*} id Identificador plantilla
   */
  syncCheckChange = (e, id) => {
    const name = e.target.name;
    const checked = e.target.checked;
    if (checked) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [name]: id,
        },
      }));
    }
  };

  /**
   * Limpia formulario
   */
  cleanData() {
    this.setState({
      form: {
        logo: "",
        plantillaid: "",
      },
      cropResult: null,
      src: null,
    });
  }

  /**
   * Evento, carga de imagen
   * @param {*} e Evento
   */
  onChange = (e) => {
    e.preventDefault();

    try {
      this.setState({
        showActionsLogo: false,
      });

      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
        var objetFile = files[0];
        if (!/\.(jpg|png)$/i.test(objetFile.name)) {
          WarningAlert("Formato no permitido. Formatos permitidos .jpg o .png");
          this.handleClose();
        } else if (objetFile.size >= imageSize) {
          WarningAlert(
            `El tamaño de la imagen no puede se superior a ${bytesToSize(
              imageSize
            )}`
          );
          this.handleClose();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({ src: reader.result });
          };
          reader.readAsDataURL(files[0]);
          this.handleOpen();
        }
      }
      e.target.value = null;
    } catch (error) {
      console.log("Error cargando imagen", error);
    }
  };

  onClickSave = async () => {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      this.setState({
        cropResult: canvas,
      });
    }
    this.handleClose();
  };

  setEditorRef = (editor) => (this.editor = editor);

  useDefaultImage = () => {
    this.setState({
      cropResult: this.state.src,
    });
  };

  selectTemplate() {
    if (!!this.form) {
      if (!!this.form.plantillaid !== "") {
        const plantilla = this.state.templates.filter(
          (template) => template.id === this.state.form.plantillaid
        );
        SuccessAlert(
          null,
          plantilla.nombre + " seleccionada",
          TIME_UPDATE_REGISTER
        );
      }
    }
  }

  /**
   * Guarda informacion de plantilla y logo
   * @param {*} e Evento
   */
  onSubmit = (e) => {
    e.preventDefault();

    if (!isEmpty(this.state.form.plantillaid)) {
      let data = {
        logo: this.state.cropResult,
        plantillaid: this.state.form.plantillaid,
      };
      this.props.saveTemplateDataAction(data);
    } else {
      WarningAlert(
        "Upss!!!",
        this.state.menuMyAccount.txtSelectTemplate //"Debes seleccionar la plantilla que utilizarás en la facturación"
      );
    }
  };

  /**
   * Abre modal recortar imagen
   */
  handleOpen = () => {
    this.setState({ stateModal: true });
  };

  /**
   * Cierra modal recortar imagen
   */
  handleClose = () => {
    this.setState({ stateModal: false });
  };

  /**
   *
   */
  buyTemplate = () => {};

  /**
   * Elimina logo cargado para la empresa
   * @param {object} e Evento
   */
  deleteLogo = (e) => {
    e.preventDefault();
    const deleteData = (confirmed) => {
      if (confirmed) {
        this.setState({
          cropResult: null,
          showActionsLogo: false,
        });
      }
    };
    ConfirmAlert(
      this.state.menuMyAccount.txtDeleteImage, //`¿Está seguro que desea eliminar este logo?`,
      deleteData,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * Evento, Escucha evento click sobre boton logo
   * y muestra opciones disponibles
   * @param {*} e
   */
  hangleClickLogo = (e) => {
    e.preventDefault();
    this.setState({
      showActionsLogo: !this.state.showActionsLogo,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <form onSubmit={(e) => this.onSubmit(e)} noValidate autoComplete="off">
          <div className="comunContainer">
            <div className="title-yellow">Logo Empresa</div>
          </div>

          <Grid
            container
            spacing={3}
            className={classes.imagePanel}
            alignItems={"center"}
          >
            <Grid item lg={3} xs={12} style={{ padding: 0 }}>
              <div className="container">
                <div
                  className={"container-img-logo"}
                  style={{
                    backgroundImage: `url( ${
                      isEmpty(this.state.cropResult)
                        ? imageDefault
                        : this.state.cropResult
                    })`,
                    backgroundPosition: "center center",
                  }}
                ></div>

                {isEmpty(this.state.cropResult) ? (
                  <Fragment>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      onChange={this.onChange}
                    />
                    <label htmlFor="contained-button-file">
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        className="btnLogo"
                      >
                        <img
                          src={require("./../../../images/template-input-button.png")}
                          alt={"inputButton"}
                        />
                      </IconButton>
                    </label>
                  </Fragment>
                ) : (
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    className="btnLogo"
                    onClick={this.hangleClickLogo}
                  >
                    <img
                      src={require("./../../../images/template-input-button.png")}
                      alt={"inputButton"}
                    />
                  </IconButton>
                )}
              </div>
            </Grid>

            <Grid item lg={2} xs={12} style={{ textAlign: "justify" }}>
              {this.state.showActionsLogo ? (
                <div style={{ paddingLeft: 20 }}>
                  <ButtonUpload text={"Cargar foto"} onChange={this.onChange} />
                  <br />
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.buttonDelete}
                    onClick={this.deleteLogo}
                  >
                    <DeleteIcon />
                    Eliminar
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Grid>

            <Grid item lg={7} xs={12}>
              <Typography
                component={"span"}
                gutterBottom
                className={classes.uploadText}
              >
                Carga aquí el logo de tu empresa, ten en cuenta que este es el
                que se mostrará en tus facturas.
              </Typography>
            </Grid>
          </Grid>

          <div className="comunContainer">
            <div className="title-yellow">Selección Plantilla</div>
          </div>

          {this.state.loadingGetTemplate === true ? (
            <Grid item lg={12} xs={12}>
              <Grid container alignItems="center" justify="center">
                <CustomProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12}>
                <TemplateCarousel
                  templates={this.state.templates}
                  syncCheckChange={this.syncCheckChange}
                  buyTemplate={this.buyTemplate}
                  checked={this.state.form.plantillaid}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3} justify="flex-end" alignItems="center">
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <ButtonPrimary
                text={getComponentName(this.state.components, 43, 126, "Listo")}
                loading={this.state.loadingSaveTemplate}
                type={"submit"}
              />
            </Grid>
          </Grid>
        </form>

        {/* Modal recorte imagen */}
        <SimpleModal
          onClose={this.handleClose}
          open={this.state.stateModal}
          title="Actualizar imagen de la empresa"
          component={() => {
            return (
              <LogoCropper
                onClickSave={this.onClickSave}
                handleClose={this.handleClose}
                image={this.state.src}
                setEditorRef={this.setEditorRef}
              />
            );
          }}
        />
      </Fragment>
    );
  }
}

Template.propTypes = {
  getTemplate: PropTypes.func.isRequired,
  changeStepStatusAction: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  templateReducer: state.templateReducer,
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {
  getTemplate,
  saveTemplateDataAction,
  changeStepStatusAction,
  getCompanyAction,
})(withStyles(useStyles)(Template));
