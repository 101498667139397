import deLocale from "date-fns/locale/es";
import { format } from "date-fns";

export const capitalizeUpperLetters = (text) => {
    const textTransform = text.toLowerCase()
    return textTransform.charAt(0).toUpperCase() + textTransform.slice(1)
} 

export const formatDate = (date, formatstr) => {
  if(!date)
    return "";
  
   return format(new Date(date), formatstr, {
    locale: deLocale
  })
}

export const getComponentName = (components, idComponent, idInfoComponent, defaultComponentName) => {
  const infoComponent = getInformationComponent(components, idComponent, idInfoComponent)
  return !!infoComponent ? infoComponent.text : defaultComponentName
}

export const getComponentUrl = (components, idComponent, idInfoComponent, defaultUrl) => {
  const infoComponent = getInformationComponent(components, idComponent, idInfoComponent)
  return !!infoComponent ? infoComponent.url : defaultUrl 
}

function getInformationComponent(components, idComponent, idInfoComponent) {
  const component = !!components && components.length ? components.find(component => component.id === idComponent) : null
  const infoComponent = !!component ? component.adcomponenteinformacion : null
  return !!infoComponent ? infoComponent.find(component => component.id === idInfoComponent) : null
}

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getRandomIntFromInterval = (min, max) => { 
  return Math.floor(Math.random() * (max - min + 1) + min);
}