
import React from 'react';
import { makeStyles } from '@material-ui/core';


import CustomTabs from '../../components/tabs/tabs.component';
import { CustomerManage } from './customerManage';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexFlow: 'column'
    },

}));


export const CustomerView = () => {
    const classes = useStyles();

    const getTabs = () => ([
        {
            title: 'Todos',
            component: <CustomerManage type={0} />
        },
        {
            title: 'Favoritos',
            component: <CustomerManage type={1} />
        },
        {
            title: 'Recientes',
            component: <CustomerManage type={2} />
        }
    ]);



    return (
        <div className={classes.container}>
            <CustomTabs tabs={getTabs()} />
        </div>
    )
}
