import React from "react";

const DocSupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6654 0.5H7.33203C6.31453 0.5 5.4987 1.31583 5.4987 2.33333V17C5.4987 18.0125 6.31951 18.8333 7.33203 18.8333H18.332C19.3495 18.8333 20.1654 18.0175 20.1654 17V6L14.6654 0.5ZM13.7487 6.91667H18.7904L13.7487 1.875V6.91667ZM3.66536 4.16667V20.6667H18.332V22.5H3.66536C2.65703 22.5 1.83203 21.675 1.83203 20.6667V4.16667H3.66536ZM15.582 15.1667V13.3333H7.33203V15.1667H15.582ZM18.332 9.66667V11.5H7.33203V9.66667H18.332Z"
        fill="white"
      />
    </svg>
  );
};

export default DocSupportIcon;
