import { axiosApiInstance } from "../config/axios-instance";
import {
  API_ENDPOINT_DASHBOARD,
  API_ENDPOINT_DISCHARGE,
  defaultHeaders,
} from "../config/config";
import {
  authHeader,
  enterpriseIdHeader,
  enterpriseDocumentType,
  enterpriseDocumentNumber,
} from "./authActions";
import { types } from "../types/types";

const urlConfiguration = `${API_ENDPOINT_DASHBOARD}/dashboard/api`;
const urlConfigurationDischarge = `${API_ENDPOINT_DISCHARGE}/license/api`;

let quantityBilling = 0;

/**
 * Method to load available clients and invoices in infoCard Licence
 * @param {*}
 */
export const getBillClient = () => async (dispatch) => {
  const url = `${urlConfigurationDischarge}${"/license/invoiceCustomers"}`;
  let data = {
    documentType: `${enterpriseDocumentType()}`,
    documentNumber: `${enterpriseDocumentNumber()}`,
  };
  try {
    await axiosApiInstance.post(url, data, defaultHeaders()).then((res) => {
      quantityBilling = res.data.result.records.map((item) => {
        return item.billings;
      })[0];

      dispatch({
        type: types.GET_BILL_CLIENT,
        payload: res.data.result.records,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar la cantidad de facturas disponibles.",
        error: err,
      },
    });
  }
};
/**
 * Top 10 best sellers
 */
export const getrakingCollaborators = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetCollaboratoraking"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        empresaId: `${enterpriseIdHeader()}`,
      },
    };

    await axiosApiInstance.get(url, config).then((res) => {
      dispatch({
        type: types.GET_COLLABORATOR_RANKING,
        payload: res.data.result.records,
        payload1: res.data.statusCode,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar el ranking de colaboradores.",
        error: err,
      },
    });
  }
};

/**
 * Method to billing
 * @param {*}
 */
export const getBilling = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetMonthlyBilling"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        empresaId: `${enterpriseIdHeader()}`,
      },
    };
    await axiosApiInstance.get(url, config).then((res) => {
      var getBillings = [];
      let totalSold;
      let quantityInvoice;
      for (let i in res.data.result.records) {
        getBillings.push(res.data.result.records[i].billingExpired);
        getBillings.push(res.data.result.records[i].billingPaidout);
        getBillings.push(res.data.result.records[i].billingPending);
        totalSold = res.data.result.records[i].totalSold;
        quantityInvoice = res.data.result.records[i].quantityInvoice;
      }

      dispatch({
        type: types.GET_BILLING,
        payload: getBillings,
        payload1: totalSold,
        payload2: res.data.statusCode,
        payload3: quantityInvoice,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar los datos de facturación.",
        error: err,
      },
    });
  }
};

/**
 * Method to billing
 * @param {*}
 */
export const getIncome = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetMonthlyIncome"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        empresaId: `${enterpriseIdHeader()}`,
      },
    };
    await axiosApiInstance.get(url, config).then((res) => {
      var getIncomes = [];
      for (let i in res.data.result.records) {
        getIncomes.push(res.data.result.records[i].january);
        getIncomes.push(res.data.result.records[i].february);
        getIncomes.push(res.data.result.records[i].march);
        getIncomes.push(res.data.result.records[i].april);
        getIncomes.push(res.data.result.records[i].may);
        getIncomes.push(res.data.result.records[i].june);
        getIncomes.push(res.data.result.records[i].july);
        getIncomes.push(res.data.result.records[i].august);
        getIncomes.push(res.data.result.records[i].september);
        getIncomes.push(res.data.result.records[i].october);
        getIncomes.push(res.data.result.records[i].november);
        getIncomes.push(res.data.result.records[i].december);
      }

      dispatch({
        type: types.GET_INCOME,
        payload: getIncomes,
        payload1: res.data.statusCode,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar los valores de ingresos.",
        error: err,
      },
    });
  }
};

/**
 * Method to billing
 * @param {*}
 */
export const getCustomer = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetClientRaking"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        empresaId: `${enterpriseIdHeader()}`,
      },
    };
    await axiosApiInstance.get(url, config).then((res) => {
      let customer = [];
      //const styleTotalsOLD = { style: 'currency', currency: 'USD', maximumSignificantDigits:20};
      //const numberFormat = new Intl.NumberFormat('en-US', styleTotalsOLD);
      customer =
        res.data.result.records === ""
          ? ""
          : res.data.result.records.map((item) => {
              return {
                clientId: item.id,
                nombreCliente: item.razonsocial,
                Valor_Total: item.valortotal,
                Ingresos: item.valortotal,
              };
            });
      dispatch({
        type: types.GET_BEST_CUSTOMER,
        payload: customer,
        payload1: res.data.statusCode,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar el ranking de clientes.",
        error: err,
      },
    });
  }
};
/**
 * Top 10 best sellers
 */
export const getrakingBranchOffice = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetBranchOfficeRaking"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        empresaId: `${enterpriseIdHeader()}`,
        //'empresaId' : 100
      },
    };
    await axiosApiInstance.get(url, config).then((res) => {
      dispatch({
        type: types.GET_BRANCH_RAKING,
        payload: res.data.result.records,
        payload1: res.data.statusCode,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar el ranking de sucursales.",
        error: err,
      },
    });
  }
};

export const getrakingArticle = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetArticle"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        empresaId: `${enterpriseIdHeader()}`,
      },
    };

    await axiosApiInstance.get(url, config).then((res) => {
      dispatch({
        type: types.GET_RAKING_ITEMS,
        payload: res.data.result.records,
        payload1: res.data.statusCode,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido cargar el ranking de articulos",
        error: err,
      },
    });
  }
};

export const issuedInvoices = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/dashboard/GetBillings"}`;
    const config = {
      headers: {
        Authorization: `${authHeader()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        businessId: `${enterpriseIdHeader()}`,
      },
    };
    await axiosApiInstance.get(url, config).then((res) => {
      let invoiceIssued = res.data.result.records.map((item) => {
        return item.countBilling;
      })[0];
      let result = quantityBilling - invoiceIssued;

      dispatch({
        type: types.GET_ISSUED_INVOICE,
        payload: result < 0 ? 0 : result,
      });
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando facturas emitidas.",
        error: err,
      },
    });
  }
};
