import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import CustomProgress from "../../components/Progress/progress.component";
import CollapsePanel from "../../components/collapse/collapsestyles.component";

import ArticlesDocuSupport from "./detailSupportComponet/ArticlesDocuSupport";
import FooterButtonsSupport from "./detailSupportComponet/FooterButtonsSupport";
import DetailSupportInfo from "./detailSupportComponet/DetailSupportInfo";
import DetailGetInfoAditional from "./detailSupportComponet/DetailGetInfoAditional";

import { getDocumentSupportDetailAction } from "../../actions/documentSupportAction";
import { ButtonCreateDoc } from "../../components/button/ButtonCreateDoc";
import { READING_MODE } from "../../config/config";

import { cleanDataConfigAction } from "../../actions/configAction";

const useStyles = makeStyles({
  containerLoading: {
    paddingTop: 200,
  },
  showLayer: {
    display: "block",
  },
  hideLayer: {
    display: "none",
  },
  colorIcon: {
    color: "#2CC63E",
  },
});

const CastDetailSupport = ({
  handleCreate,
  setDocumentSelected,
  documentIdSelected,
  handleEdit,
  handleShowDetailNote,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalError, setmodalError] = useState(null);

  const {
    detailDocumentSupport,
    loadingGetDocumentSupportDetail,
    loadingUpdateStatusDocSupport,
    loadingUpdateStatusDocSupportFavorite,
    documentSupportFilter,
  } = useSelector((state) => state.documentSupportReducer);

  /**
   * Consulta detalle de  documento seleccionado
   */
  useEffect(() => {
    if (documentIdSelected !== null && documentIdSelected !== undefined) {
      dispatch(getDocumentSupportDetailAction(documentIdSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentIdSelected]);

  const openCreate = () => {
    handleCreate(false, true);
    dispatch(cleanDataConfigAction());
  };

  return (
    <div className="spacingDetail">
      <div className="spacingInvoice" />
      <ButtonCreateDoc
        isReadingMode={READING_MODE}
        onClick={!READING_MODE ? openCreate : undefined}
        title={"Crear nuevo documento soporte"}
      />
      {!detailDocumentSupport && !loadingGetDocumentSupportDetail ? (
        <div className="spacingInvoice" />
      ) : null}
      {loadingGetDocumentSupportDetail ? (
        <>
          <div className="spacingInvoice" />
          <CustomProgress />
        </>
      ) : (
        <div
          style={{
            alignItems: "center",
          }}
          className={
            detailDocumentSupport?.consecutivo === undefined
              ? classes.hideLayer
              : classes.showLayer
          }
        >
          {detailDocumentSupport ? (
            <>
              <div className="spacingInvoice" />
              <div
                className="cardDetailSupportFullData"
                style={{
                  overflow: "auto",
                  height: "70.9vh",
                  padding: "3%",
                }}
              >
                <DetailSupportInfo
                  detailDocumentSupport={detailDocumentSupport}
                  documentSupportFilter={documentSupportFilter}
                />
                <div className="spacingInvoice" />
                <div className="greenTitleSupport">
                  Servicios ({" "}
                  {detailDocumentSupport?.opdetalledocumento?.length} )
                </div>
                <div className="spacingInvoice"></div>
                {detailDocumentSupport?.opdetalledocumento?.map(
                  (detail, index) => (
                    <ArticlesDocuSupport detail={detail} key={index} />
                  )
                )}
                <div className="spacingInvoice"></div>
                <CollapsePanel
                  component={() =>
                    DetailGetInfoAditional(handleShowDetailNote, setmodalError)
                  }
                  title={"Información Adicional"}
                  titleOpen={"Información Adicional"}
                  backgroundColorSummary={{
                    backgroundColor: "#2CC63E",
                    color: "#000000",
                  }}
                  backgroundColorDetail={{}}
                  type="showinfo"
                />
              </div>
              <div className="spacingInvoice"></div>
              <FooterButtonsSupport
                loadingUpdateStatusDocSupport={loadingUpdateStatusDocSupport}
                loadingUpdateStatusDocSupportFavorite={
                  loadingUpdateStatusDocSupportFavorite
                }
                detailDocumentSupportid={detailDocumentSupport?.id}
                setDocumentSelected={setDocumentSelected}
                handleEdit={handleEdit}
                detailDocumentSupport={detailDocumentSupport}
                modalError={modalError}
                setmodalError={setmodalError}
              />
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CastDetailSupport;
