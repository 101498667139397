import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  colorIcon: {
    color: "#2CC63E",
  },
  iconsDetail: {
    display: "flex",
    alignItems: "center",
  },
  wordBreak: {
    wordBreak: "break-all",
    fontFamily: "Muli",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.32px",
  },
});

const DetailNewDocument = () => {
  const classes = useStyles();
  const { providerData } = useSelector((state) => state.documentSupportReducer);
  const {
    getSupportDocumentType: typesDocument,
    getRegimeTypesResponse: typesRegime,
  } = useSelector((state) => state.configReducer);

  const {
    diantipodocumentoid,
    numerodocumento,
    razonsocial,
    diantiporegimenid,
    correoelectronico,
    telefono,
    direccion,
    nombre,
    apellido,
  } = providerData;

  const textRegime = typesRegime.find(
    (reg) => reg.id === Number(diantiporegimenid)
  );

  const textDoc = typesDocument?.find((doc) => doc.id === diantipodocumentoid);

  return (
    <>
      <div
        className="constumerNameDetailSupport"
        style={{ wordBreak: "break-word" }}
      >
        {diantipodocumentoid !== 3 ? nombre + " " + apellido : razonsocial}
      </div>
      <div className="detailSupportInfo">
        {textDoc?.descripcion} {numerodocumento}
      </div>
      <div className="detailInvoiceInfo">{textRegime?.descripcion}</div>
      <div className="spacingInvoice"></div>
      <Grid
        container
        style={{
          marginTop: "12px",
        }}
      >
        <Grid item xs={12} className={classes.iconsDetail}>
          <EmailIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{correoelectronico}</div>
        </Grid>

        <Grid item xs={12} className={classes.iconsDetail}>
          <PhoneIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{telefono}</div>
        </Grid>

        <Grid item xs={12} className={classes.iconsDetail}>
          <RoomIcon className={classes.colorIcon} />
          &nbsp;&nbsp;
          <div className={classes.wordBreak}>{direccion}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailNewDocument;
