import ResultTest from "./ResultTest";
import {ColumnsFile ,Rules} from "./Rules";

export default class ValidateField {
  constructor(validations) {
    this.validations = validations;
  }

  /**
   * Meotod encargado de delegar la validacion de la celda
   * 
   * @param {valor del campo} value 
   * @param {posicion del campo a validar} position 
   * @param {numero de registro dentro del archivo} line 
   * @param {nombre de la columna} key 
   */
  validate = (value, position, line, key, newValue) => {
    return this.validateField(value, this.validations[position], line, key, newValue,key);
  };

  testValue = (value, validation, line, key) => {
    const test = validation.pattern.value.test(value);
    if (!test) {
      const validationError = new ResultTest(validation.pattern.message + " [" + value + "]", line, key);
      return validationError;
    }
    return null;
  }

  /**
   * Metodo encargado de validar si cumple con la expresion regular
   * 
   * @param {*} value 
   * @param {*} validation 
   * @param {*} line 
   * @param {*} key 
   */
  validateField = (value, validation, line, key , newValue,position) => {
    const valuenew =  !!newValue ? newValue.trim().split("-") : '';
    if(!!valuenew){
      if(position === ColumnsFile.numeroDocumentoColumn.name && valuenew[0].replace(/\s/g, '') !== 'P' && !!isNaN(value) ){
        const validationError = new ResultTest(Rules.numeroDocumentoNumberColumn.pattern.message + " [" + value + "]", line, key);
        return validationError;
      }
    }

    if (validation) {
      if (validation.required) {
        //validacion de campos obligatorios
        if (value === '') {
          const validationError = new ResultTest(validation.required, line, key);
          return validationError;
        } else {
          //validacion de expresiones regulares
          return this.testValue(value, validation, line, key)
        }
      } else {
        if (value !== '') {
          //validacion de expresiones regulares
          return this.testValue(value, validation, line, key)
        }
      }
    }
   
    return null;
  };
}
