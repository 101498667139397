import React, { useRef, useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import SimpleReactValidator from "simple-react-validator";

import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { CartItemContainer } from "./itemCartSections";

import InputForm from "../../../components/input/input.component";

import {
  updateItemAction,
  deleteItemAction,
} from "../../../actions/documentCartAction";

import { useFormComplete } from "../../../hooks/useFormComplete";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config.js";

import { ConfirmAlert } from "../../../helpers/alert.helpers";
import { getDocumentItemSections, getSectionsItemsToShow } from "./utils";

const useStyles = makeStyles((theme) => ({
  styleCard: {
    backgroundColor: theme.palette.ninethBackgroundColor,
    border: "none",
    borderRadius: "5px",
    color: theme.palette.primaryColor,
    margin: 10,
    "& .MuiCardContent-root": {
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  title: {
    "& .MuiInputBase-input": {
      color: theme.palette.primaryColor,
      fontSize: 18,
      fontWeight: 600,
    },
  },
  content: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  icon: {
    color: theme.palette.primaryColor,
  },
  headerCard: {
    paddingBottom: 0,
    paddingTop: 5,
  },
}));

export const CartItemCard = React.memo(
  ({ data, handleOpenModalRteFuente, handleEditRteFuente, typedocument }) => {
    const classes = useStyles();

    const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
      .current;

    const [, forceUpdate] = useState();

    const dispatch = useDispatch();

    const {
      asumeresponsabilidad,
      cantidad,
      descripcion,
      esregalo,
      id,
      nombrearticulo,
      porcentajedescuento,
      tipodescuento,
      valordescuento,
      valorneto,
      valorreferencia,
    } = data;

    const { values, handleInputChange, handleUpdateForm } = useFormComplete({
      description: descripcion,
      discountType: tipodescuento.toString(),
      discountValue:
        tipodescuento?.toString() === "1"
          ? valordescuento ?? 0
          : porcentajedescuento ?? 0,
      isGift: esregalo,
      quantity: cantidad,
      referencePrice: valorreferencia,
      responsibility: asumeresponsabilidad.toString(),
      unitValue: valorneto,
    });

    const { description } = values;

    useEffect(() => {
      validator.showMessageFor("discountValue");
      validator.showMessageFor("unitValue");
      validator.showMessageFor("responsibility");
      validator.showMessageFor("referencePrice");
      forceUpdate(1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * * Maneja el evento onBlur del campo de descripción.
     * * Actualiza el formulario y el estado del artículo con la nueva descripción.
     * @param {Event} event - El evento onBlur.
     */
    const handleOnBlurDescription = ({ target }) => {
      let newdescription =
        target.value.trim().length === 0 ? nombrearticulo : target.value.trim();
      handleUpdateForm({
        description: newdescription,
      });

      let newdata = {
        ...data,
        nombrearticulo: newdescription,
        descripcion: newdescription,
      };
      dispatch(updateItemAction(newdata));
    };

    /**
     * * Maneja la eliminación de un elemento del carrito.
     */
    const handleDelete = () => {
      const deleteData = (confirmed) => {
        if (confirmed) {
          dispatch(deleteItemAction(id));
        }
      };
      ConfirmAlert(
        `¿Estás seguro de eliminar ${descripcion}?`,
        deleteData,
        MESSAGE_DELETE_CONFIRM
      );
    };

    const sections = getDocumentItemSections({
      formValues: values,
      handleEditRteFuente,
      handleInputChange,
      handleOpenModalRteFuente,
      handleUpdateForm,
      itemDoc: data,
      typedocument,
      validator,
    });

    return (
      <Card
        className={`${classes.styleCard} animate__animated animate__fadeIn animate__faster`}
      >
        <CardHeader
          action={
            <IconButton onClick={handleDelete}>
              <DeleteIcon className={classes.icon} />
            </IconButton>
          }
          className={classes.headerCard}
          classes={{
            title: classes.title,
          }}
          title={
            <InputForm
              label={""}
              maxLength={250}
              name={"description"}
              onBlur={handleOnBlurDescription}
              onChange={handleInputChange}
              value={description}
              variant={"standard"}
            />
          }
        />

        <CardContent className={classes.content}>
          <CartItemContainer
            {...sections}
            formValues={values}
            itemDoc={data}
            showSections={getSectionsItemsToShow(typedocument)}
          />
        </CardContent>
      </Card>
    );
  }
);
