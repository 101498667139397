/**
 * * Construye una dirección combinando las partes proporcionadas.
 * @function
 * @param {string} customerAddress - La dirección del cliente.
 * @param {string} customerCity - La ciudad del cliente.
 * @param {string} customerDepartment - El departamento del cliente.
 * @returns {string} La dirección completa construida.
 */
export const buildAddress = (
  customerAddress,
  customerCity,
  customerDepartment
) => {
  const addressParts = [
    customerAddress,
    customerCity,
    customerDepartment,
  ].filter(Boolean);
  return addressParts.join(" ");
};
