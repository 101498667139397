import React, { useEffect, useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Grid } from "@material-ui/core";

import BigSelect from "../../../../components/select/bigSelect.component";

import { addRteIvaAllItemsAction } from "../../../../actions/documentCartAction";
import { getListReteIVAAction } from "../../../../actions/invoiceActions";

export const RetentionIVASection = () => {
  const dispatch = useDispatch();

  const [rteIVA, setRteIVA] = useState("");

  const { listReteIVA } = useSelector((state) => state.invoiceReducer);

  useEffect(() => {
    dispatch(getListReteIVAAction());
  }, [dispatch]);

  const { retiva } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  /**
   * * Carga el reteIVA seleccionado en el estado local.
   * @function
   * @name loadSelectedRteIVA
   * @returns {void}
   */
  useEffect(() => {
    setRteIVA(!!retiva ? retiva.id : "");
  }, [retiva]);

  /**
   * * Actualiza el estado local con el nuevo valor del impuesto de retención de IVA.
   * @param {*} event - El evento que desencadenó el cambio.
   * @returns {void}
   */
  const handleChangeRteIva = ({ target }) => {
    let tax = listReteIVA.find((c) => c.id === target.value);
    dispatch(addRteIvaAllItemsAction(!!tax ? tax : null));
  };

  return (
    <Grid item lg={12} xs={12}>
      <BigSelect
        name={"retIVA"}
        label={"Rete - IVA"}
        options={listReteIVA}
        value={rteIVA}
        onChange={handleChangeRteIva}
      />
    </Grid>
  );
};
