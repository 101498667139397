import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#5E5E5E',
        color: theme.palette.primaryColor,
        boxShadow: 'none',
        '& .MuiCardHeader-avatar': {
            marginRight: 10
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: 0,
        }
    },
    header: {
        padding: 0
    },
    title: {
        color: theme.palette.primaryColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    icon: {
        color: theme.palette.secundaryColor
    },
    content: {
        textTransform: 'capitalize',
    },
    cardContent: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 14,
        marginLeft: 15
    }

}));

export const AddressCard = React.memo(({
    descripcion,
    direccion,
    departamentodescripcion,
    ciudaddescripcion
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header}
                avatar={
                    <LocationOnIcon
                        fontSize="small"
                        className={classes.icon} />
                }
                classes={{
                    title: classes.title,
                    subheader: classes.title
                }}
                title={descripcion}
                subheader={''}
            />
            <CardContent className={classes.cardContent}>
                <Grid container>
                    <Grid item lg={12} xs={12}>
                        {direccion}
                    </Grid>
                    <Grid item lg={12} xs={12} >
                        <div className={classes.content}>
                            {`${departamentodescripcion.toLowerCase()} - ${ciudaddescripcion.toLowerCase()}`}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
)
