import React , {Component, Fragment}from "react";
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, LabelList } from "recharts";
import { Card } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles} from "@material-ui/core/styles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import CustomProgress from "../../../components/Progress/progress.component";

import "../Components/dashboard.css";

//Identifica tamño de la pantalla


const useStyles = (theme) => ({
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  divMessage:{
    textAlign:'center',
    fontFamily: theme.typography.fontFamily,
    color:theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600
  }
});

const renderCustomizedLabel = (props) => {
  const {y, width, value , x} = props;
  return width >= 170 && value.length < 30 ? 
    <g>
      <text x={x+5} y={y + 15} fill="#fff">
        {value}
      </text>
    </g> :'';
};


const CustomTooltip = ({ active, payload, label }) => {
  const options = { style: 'currency', currency: 'USD', maximumSignificantDigits:10 };
  const numberFormat = new Intl.NumberFormat('en-US', options);

  if (active) {
    return (
      <div style={{background:'#fff', marginLeft:'12px', borderRadius:5}} className="custom-tooltip">
        <p className="label">{label}</p>
        <p style={{color:payload[0].fill}} className="intro">{`${"Valor total"} : ${numberFormat.format(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};

class BestCustomChart extends Component {

  getGridListCols = () => {
    if (isWidthUp('xl', this.props.width))
      return 690;

    if (isWidthUp('lg', this.props.width))
      return 450;

    if (isWidthUp('md', this.props.width))
      return 850;

    return 450;
  }

  formatter = (value) => {
    const options = { style: 'currency', currency: 'USD',maximumSignificantDigits:10};
    const numberFormat = new Intl.NumberFormat('en-US', options);
    return numberFormat.format(value)
  } 
  render() {
    const { classes, dataClient,statusCode} = this.props;
    return (
      
      <Card className={classes.colorCard} style={{width:'100%' , height:'100%'}}>
        <CardHeader title="Mejores Clientes" className={"titleCard"} />
       {this.getGridListCols() !== 450  ?<Fragment><br/> <br/> <br/></Fragment>: ''} 
        
        
        {dataClient.length === 0 && statusCode === undefined ? <CustomProgress/> : 
            (dataClient.length === 0 && statusCode === 0)  || (dataClient.length === 0 && statusCode === 200) || (dataClient === "")? 
            <div className={classes.divMessage}>No tiene facturas registradas</div>
         : 
        
            <ComposedChart
            layout="vertical"
            width={this.getGridListCols()}
            height={168}
            data={dataClient}
            style={{marginLeft:'-50px'}}
          >
            <XAxis tickFormatter={this.formatter} type="number" tick={{ stroke: "#FFFFFF" }} />
            <YAxis
              type="category"
              tick={false}
              padding={{ bottom: 5 }}
              dataKey="nombreCliente"
            />
            <Tooltip cursor={false} content={CustomTooltip} />
            <Bar dataKey="Valor_Total" barSize={200} fill="#0091BB" >
              <LabelList
                dataKey="nombreCliente"
                fill={"#FFFFFF"}
                content={renderCustomizedLabel}
              />
            </Bar>
          </ComposedChart>
        }
      </Card>
    );
  }
}

export default (withWidth() (withStyles(useStyles) (BestCustomChart)));
