/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputAdornment} from '@material-ui/core';
import imageDefault from "../../../../src/images/filter.png";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),

  },
  iconCategory :{
      color:'white'
  }
}));



const CustomSelect = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#509b34',
     // height: 50 //verde
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'gray',
     // height: 10 //verde
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
      height: '20px',
      with:9
    },
    

    '& .MuiSelect-icon' : {
        display: 'none'
    },
    '& .MuiInputBase-fullWidth': {
        height: '50%'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'gray', //gris
      //height: 40
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'gray',
      //height: 40 //gris
    },
    '& .MuiInput-underline:focus': {
      borderBottomColor: 'gray',
      //height: 40 //gris
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: ' rgba(255,255,255,0.3)',
        //height: 40 //borde de la caja
      },
      '&:hover fieldset': {
        borderColor: 'gray',
        //height: 40
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray',
      // height: 40 //verde
      },
    },

    '& .MuiInputLabel-outlined': {
      marginTop: '-10px',
      fontSize: 12,
    }

  },
})(TextField);


const SelectFilter = ({ ...props }) => {
  const classes = useStyles();
  const { id, onChange, values, options, label, value,  hidden, name, disabled, search } = props;
  const optionsSelect = options ? options.map((option, index) => ({
    key: index,
    value: option.value,
    text: option.text
  }))
    : values.map((item, index) => ({
      key: index,
      text: item,
      value: item
    })); 

  
  if (hidden) return null;


  return (
    <div className={classes.root}>
      <CustomSelect
      fullWidth
        defaultValue={5}
        id={id}
        value={value}
        select
        name={name}
        label={label}
        onChange={onChange}
        disabled={ disabled === true ? true : false}
        variant="outlined"
        InputProps={{
            endAdornment:
            search !== "category" ?
              <InputAdornment position="end" >
                <img src={imageDefault} />
              </InputAdornment>
            :
                <InputAdornment  position="end" >
                      <CollectionsBookmarkIcon  className={classes.iconCategory} />
                </InputAdornment>,
        }}
      >
    
     {optionsSelect.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
       
      </CustomSelect>
    </div>
  )
};

SelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default SelectFilter
